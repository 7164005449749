import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { range } from "src/helpers";

const LoadingBannerList = () => {
  return range(0, 3).map((key) => (
    <div key={key} className="w-100 flex-between py-3 border-bottom-1 bc-grey3">
      <div>
        <div className="fs-14 semi-bold">
          <Shimmer width="200px" />
        </div>
        <div className="fs-13 fc-grey1 mt-2">
          <Shimmer width="100px" />
        </div>
      </div>
      <div className="fs-18 semi-bold">
        <Shimmer width="50px" height="30px" />
      </div>
    </div>
  ));
};

export default LoadingBannerList;
