import PropTypes from "prop-types";
import React from "react";
import { Route as ReactRoute, Redirect } from "react-router-dom";
import { Page, pagePropTypes } from "src/components/layouts";

const resolvePath = (path, nestedRoutes) => {
  if (nestedRoutes?.length) {
    return new RegExp(
      `${path}/?(${nestedRoutes
        .map((route) => route.split(`${path}/`).pop())
        .join("|")})?`
    );
  }
  return path;
};

function Route(props) {
  const {
    exact = false,
    hasAccess = true,
    redirect,
    isPage,
    path,
    nestedRoutes,
    page,
    children,
  } = props;
  if (!hasAccess) return <Redirect to={redirect} />;

  return (
    <ReactRoute exact={exact} path={resolvePath(path, nestedRoutes)}>
      {page ? <Page {...{ ...page, isPage }}>{children}</Page> : children}
    </ReactRoute>
  );
}

Route.propTypes = {
  exact: PropTypes.bool,
  hasAccess: PropTypes.bool,
  redirect: PropTypes.string,
  isPage: PropTypes.bool,
  path: PropTypes.string.isRequired,
  nestedRoutes: PropTypes.arrayOf(PropTypes.string),
  page: pagePropTypes,
  children: PropTypes.node,
};

Route.defaultProps = {
  exact: false,
  isPage: false,
  page: null,
};

export default Route;
