import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgCarter = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      data-name="Group 11075"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill="none"
        d="M.001 0H14v13.999H.001z"
        data-name="Rectangle 2773"
      />
      <path
        fill={color}
        d="M13.027 12.032a4.9 4.9 0 0 0-1.917-7.394 5.6 5.6 0 1 0-9.927 4.4L.211 10a.7.7 0 0 0 .5 1.2h3.98A4.9 4.9 0 0 0 9.1 14h4.2a.7.7 0 0 0 .5-1.2ZM4.206 9.1a4.64 4.64 0 0 0 .056.7H2.394l.245-.238a.7.7 0 0 0 0-.993A4.148 4.148 0 0 1 1.407 5.6a4.2 4.2 0 0 1 4.2-4.2 4.155 4.155 0 0 1 3.95 2.8H9.1a4.9 4.9 0 0 0-4.9 4.9Zm7.373 3.5.035.035H9.1a3.5 3.5 0 1 1 2.476-1.021.689.689 0 0 0 0 .986Z"
      />
    </svg>
  );
};

SvgCarter.defaultProps = {
  color: COLORS.GREY_1,
  height: 14,
  width: 14,
};
export default SvgCarter;
