import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";
import { SwitchVariant, ToggleSwitch } from "src/components/common/atoms";
import { classNames } from "src/helpers";
import { SvgInactiveUser } from "src/icons";

export const hideInactiveUsersProps = {
  value: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

function HideInactiveUsers({ className, value, handleClick, showNote = true }) {
  return (
    <div className={classNames(["flex", className])}>
      <SvgInactiveUser width={16} height={16} />
      <div className="ml-2">
        <div className="flex">
          <div className="fs-13 mr-2">
            {t("MANAGED_GOALS.LABELS.HIDE_INACTIVE_USERS")}
          </div>
          <ToggleSwitch
            value={value}
            offLabel={t("COMMON.LABELS.NO")}
            onLabel={t("COMMON.LABELS.YES")}
            onClick={handleClick}
            {...{
              ...SwitchVariant,
              labelStyle: {
                ...SwitchVariant.labelStyle,
                fontWeight: 700,
              },
              offButtonStyle: {
                ...SwitchVariant.offButtonStyle,
                background: "#A0AEC0",
              },
            }}
          />
        </div>
        {showNote ? (
          <div className="fs-12 fc-grey2">
            <Trans i18nKey="MANAGED_GOALS.MESSAGES.HIDE_INACTIVE_USERS_NOTE">
              Filter out inactive users for a streamlined <br /> list.
            </Trans>
          </div>
        ) : null}
      </div>
    </div>
  );
}

HideInactiveUsers.propTypes = HideInactiveUsers;

export default HideInactiveUsers;
