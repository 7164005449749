import { gql, useMutation } from "src/api/client";

const DOWNLOAD_CUSTOM_REWARD_RAW_EXCEL = gql`
  mutation downloadCustomRewardRawExcel($action: String!) {
    downloadCustomRewardRawExcel(action: $action) {
      message
      response {
        url
        fileId
      }
    }
  }
`;

export const useDownloadCustomRewardRawExcel = () => {
  return useMutation(DOWNLOAD_CUSTOM_REWARD_RAW_EXCEL);
};
