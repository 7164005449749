import React from "react";

const SvgAvatar28 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="lw7l__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .lw7l__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="lw7l__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="lw7l__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.085)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="lw7l__cls-2"
        />
        <path
          d="M116.18 115.8c-.16.05-.32.07-.48.05-8.38-.62-11.6-6.89-14.44-12.42-2.09-4.06-3.9-7.58-7.34-7.83-.41-.03-.78 0-1.12.11-1.22.38-1.97 1.49-3.52 4.12-2.56 4.34-6.07 10.29-16.56 13.53-6.85 2.12-15.88 2.78-26.83 1.96-.45-.03-.85-.29-1.05-.7-.21-.4-.19-.88.04-1.26l5.16-8.66 1.01-13.59c.04-.49.34-.92.8-1.11l18.47-7.58.86-11.52-21.93 2.72c-.39.05-.77-.08-1.05-.35a1.3 1.3 0 0 1-.4-1.03l.34-4.63a33.62 33.62 0 0 1 9.37-20.88l.73-.75-9.23-8.58a1.307 1.307 0 0 1-.01-1.89 47.473 47.473 0 0 1 18.79-10.99 47.494 47.494 0 0 1 17.61-2l.32.02c12.58 1.03 24.04 6.86 32.3 16.44 8.32 9.66 12.39 21.98 11.46 34.69-.03.35-.18.66-.45.89-.13.12-.29.2-.46.25-.16.05-.32.07-.48.05l-12.6-.94-.07 1.02c-1.01 13.93-1.74 30.96 1.61 39.13.17.42.12.89-.15 1.25-.16.23-.4.4-.67.48ZM92.01 93.23c.64-.2 1.33-.27 2.11-.21 4.89.36 7.11 4.67 9.45 9.23 2.08 4.05 4.24 8.24 8.43 10.08l1.87.82-.46-1.99c-2.5-10.87-1.16-28.99-.43-38.72.82-11.09-4.37-21.86-13.56-28.12-7.9-5.38-17.66-6.84-26.78-4.02-1.48.46-2.95 1.03-4.35 1.71-10.01 4.83-16.74 14.72-17.57 25.8l-.23 3.06 21.93-2.72c.39-.05.77.08 1.05.35.28.27.43.65.4 1.04l-1.03 13.9c-.04.49-.34.92-.8 1.1l-18.47 7.58-.97 13.1c-.01.2-.08.4-.18.57l-4.24 7.11 1.71.08c9.05.41 16.24-.26 21.98-2.03 9.59-2.97 12.82-8.43 15.17-12.42 1.39-2.36 2.7-4.59 4.96-5.28ZM71.94 37.79a33.19 33.19 0 0 1 12.41-1.39c8.58.65 16.51 4.49 22.33 10.82 5.82 6.33 9 14.55 8.94 23.15v.96l11.35.84.02-1.08c.26-11.72-3.97-22.92-11.9-31.54-7.93-8.62-18.74-13.77-30.43-14.49-5.46-.34-10.88.31-16.11 1.93a44.888 44.888 0 0 0-15.88 8.71l-.88.75 8.4 7.8.69-.56c3.3-2.66 7.03-4.64 11.08-5.89Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar28.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar28;
