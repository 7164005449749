import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import { FormTooltip } from "src/components/common/formElements";
import { Calendar, WeeklyDropdown } from "src/components/common/molecules";
import { RECURRENCE_PERIOD } from "src/constants";
import {
  CYCLES_TYPES,
  highlightCustomCycle,
  highlightCycle,
} from "src/helpers";

const FirstCycleDropdown = (props) => {
  const {
    label,
    today,
    onChange,
    disabled,
    disabledTip,
    startDate,
    isDistinct,
    cycleDuration,
    recurrencePeriod,
    gapDuration,
  } = props;

  const [hoverDate, setHoverDate] = useState(null);

  const highlightDates = useMemo(
    () =>
      recurrencePeriod === "Custom"
        ? cycleDuration > 1 &&
          highlightCustomCycle({
            hoverDate,
            days: cycleDuration,
          })
        : highlightCycle({
            recurrencePeriod,
            hoverDate,
            selectedDate: startDate,
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoverDate, startDate]
  );

  const onEnter = (date) => setHoverDate(date);
  const onExit = () => setHoverDate(new Date(null));

  return (
    <>
      {label && (
        <Form.Label className="semi-bold fs-14 pl-0 mb-13">{label}</Form.Label>
      )}
      <FormTooltip
        id="first-cycle-range-tip"
        disable={!disabledTip}
        text={disabledTip}
        placement="right"
      >
        <div className="center cycle-date-picker">
          {isDistinct && recurrencePeriod === "Weekly" ? (
            <WeeklyDropdown
              id="first-cycle-range"
              inputId="first-cycle-range-input"
              today={today}
              disabled={disabled}
              onChange={onChange}
              selected={startDate}
              dateStart={startDate}
              dateEnd={CYCLES_TYPES[recurrencePeriod]?.startDateEnd({
                startDate,
              })}
              minDate={CYCLES_TYPES[recurrencePeriod]?.startMinDate({
                today,
                isDistinct,
              })}
              placeholder={CYCLES_TYPES[recurrencePeriod]?.label({ t })}
            />
          ) : (
            <Calendar
              id="first-cycle-range"
              inputId="first-cycle-range-input"
              today={today}
              onExit={onExit}
              onEnter={onEnter}
              onChange={onChange}
              disabled={disabled}
              highlightDates={highlightDates}
              recurrencePeriod={recurrencePeriod}
              selected={startDate}
              dateStart={startDate}
              dateEnd={
                ["Daily", "Infinite"].includes(recurrencePeriod)
                  ? null
                  : CYCLES_TYPES[recurrencePeriod]?.startDateEnd({
                      startDate,
                      cycleDuration,
                    })
              }
              placeholder={CYCLES_TYPES[recurrencePeriod]?.label({ t })}
              minDate={CYCLES_TYPES[recurrencePeriod]?.startMinDate({
                today,
                isDistinct,
                cycleDuration,
                gapDuration,
              })}
              showMonthYearPicker={isDistinct && recurrencePeriod === "Monthly"}
              showQuarterYearPicker={recurrencePeriod === "Quarterly"}
              showYearPicker={isDistinct && recurrencePeriod === "Yearly"}
            />
          )}
        </div>
      </FormTooltip>
    </>
  );
};

FirstCycleDropdown.propTypes = {
  recurrencePeriod: PropTypes.oneOf(RECURRENCE_PERIOD),
  startDate: PropTypes.instanceOf(Date),
  today: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  isDistinct: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
FirstCycleDropdown.defaultProps = {
  today: new Date(),
  startDate: null,
  disabled: false,
  isDistinct: false,
};

export default FirstCycleDropdown;
