import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_GOAL_DETAILS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query GetGoalDetails($GoalId: ID!) {
    goal(GoalId: $GoalId) {
      __typename
      ... on SingleGoalResponse {
        message
        success
        response {
          Goal {
            validations
            isIncentiveLinkedWithGoal
            Status
            Name
            Description
            GoalId
            CurrentRecurrenceCycle
            ExportEnabled
            GoalAggConfig
            GoalValue
            RecurrenceDefinition
            RecurrenceDueText
            TotalCycles
            IsRollUp
            IsDynamicTablePresent
            RollupType
            LastUpdatedOn
            GoalOwner {
              FullName
              UserID
            }
            Template {
              Name
              Status
              TemplateId
              DrilldownStatus
              GoalAggregateFunction
              GroupBy
            }
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetGoalDetails = (queryOptions) => {
  return useQuery(GET_GOAL_DETAILS_QUERY, queryOptions);
};
