const ValueOutOfCell = ({ value, column, row: { original } }) => {
  const newValue = column.formatValue ? column.formatValue(original) : value;
  return (
    <div className="fs-12 regular">
      <span className="fc-black">{newValue.currentValue}</span>
      <span className="fc-grey mx-1">/</span>
      <span className="fc-grey">{newValue.maxValue}</span>
    </div>
  );
};

export default ValueOutOfCell;
