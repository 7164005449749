import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MobileConditionViewer, Truncate } from "src/components/common/atoms";
import { classNames } from "src/helpers";
import "./mobileConditionModal.scss";

const MobileConditionsModal = (props) => {
  const { t } = useTranslation();
  const { conditionModal, showConditionModal, goalRule, ruleName } = props;

  const getCriteriaString = () => {
    const patternString = goalRule?.CriteriaPattern;
    const patternArray = patternString.replace(/([0-9]+)/g, " $1 ").split(" ");
    const pattern = patternArray.map((element, idx) => {
      return isNaN(element) ? (
        <span key={idx} className="fc-grey mr-1">
          {element}
        </span>
      ) : (
        <span key={idx} className="fc-black mr-1">
          {element}
        </span>
      );
    });
    return pattern;
  };

  return (
    <Modal
      show={conditionModal}
      onHide={() => showConditionModal(false)}
      aria-labelledby="conditions-modal"
      className="mobile-conditions-modal"
      backdropClassName="mobile-conditions-modal-backdrop"
      dialogClassName="mobile-conditions-modal-dialog"
      centered
    >
      <Modal.Header closeButton className="semi-bold">
        <div className="center">
          <Truncate text={ruleName} maxWidth="200px" />
          <span className="mr-1">:</span>
          {t("RULEBOOK.LABELS.CONDITIONS")}
        </div>
      </Modal.Header>
      <Modal.Body className="fs-13 p-3">
        <div className="condition-pattern-mobile">
          {goalRule?.Conditions?.length > 1 ? (
            <div>
              <span className="fs-12">
                Achieve below conditions based on condition pattern.
              </span>
              <div className="w-100 fs-12 mt-2 p-2 primary-text-grey condition-pattern-mobile-body">
                {getCriteriaString()}
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={classNames([
            "condition-viewer mb-2",
            goalRule?.Conditions?.length >= 2 ? "mt-3" : "",
          ])}
        >
          <MobileConditionViewer
            conditions={goalRule?.Conditions}
            hideButtons
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MobileConditionsModal;
