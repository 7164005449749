import * as Badges from "src/images/svgs/leaderboardBadges";

export const LEADERBOARD_BADGES = {
  DAILY: {
    RANKED: {
      GOLD: Badges.GoldDaily,
      SILVER: Badges.SilverDaily,
      BRONZE: Badges.BronzeDaily,
      GENERIC: Badges.GenericDaily,
    },
    UNRANKED: {
      GOLD: Badges.GoldDailyWithoutRank,
      SILVER: Badges.SilverDailyWithoutRank,
      BRONZE: Badges.BronzeDailyWithoutRank,
      GENERIC: Badges.GenericDaily,
    },
  },
  WEEKLY: {
    RANKED: {
      GOLD: Badges.GoldWeekly,
      SILVER: Badges.SilverWeekly,
      BRONZE: Badges.BronzeWeekly,
      GENERIC: Badges.GenericWeekly,
    },
    UNRANKED: {
      GOLD: Badges.GoldWeeklyWithoutRank,
      SILVER: Badges.SilverWeeklyWithoutRank,
      BRONZE: Badges.BronzeWeeklyWithoutRank,
      GENERIC: Badges.GenericWeekly,
    },
  },
  BIWEEKLY: {
    RANKED: {
      GOLD: Badges.GoldWeekly,
      SILVER: Badges.SilverWeekly,
      BRONZE: Badges.BronzeWeekly,
      GENERIC: Badges.GenericWeekly,
    },
    UNRANKED: {
      GOLD: Badges.GoldWeeklyWithoutRank,
      SILVER: Badges.SilverWeeklyWithoutRank,
      BRONZE: Badges.BronzeWeeklyWithoutRank,
      GENERIC: Badges.GenericWeekly,
    },
  },
  MONTHLY: {
    RANKED: {
      GOLD: Badges.GoldMonthly,
      SILVER: Badges.SilverMonthly,
      BRONZE: Badges.BronzeMonthly,
      GENERIC: Badges.GenericMonthly,
    },
    UNRANKED: {
      GOLD: Badges.GoldMonthlyWithoutRank,
      SILVER: Badges.SilverMonthlyWithoutRank,
      BRONZE: Badges.BronzeMonthlyWithoutRank,
      GENERIC: Badges.GenericMonthly,
    },
  },
  QUARTERLY: {
    RANKED: {
      GOLD: Badges.GoldQuarterly,
      SILVER: Badges.SilverQuarterly,
      BRONZE: Badges.BronzeQuarterly,
      GENERIC: Badges.GenericQuarterly,
    },
    UNRANKED: {
      GOLD: Badges.GoldQuarterlyWithoutRank,
      SILVER: Badges.SilverQuarterlyWithoutRank,
      BRONZE: Badges.BronzeQuarterlyWithoutRank,
      GENERIC: Badges.GenericQuarterly,
    },
  },
  YEARLY: {
    RANKED: {
      GOLD: Badges.GoldYearly,
      SILVER: Badges.SilverYearly,
      BRONZE: Badges.BronzeYearly,
      GENERIC: Badges.GenericYearly,
    },
    UNRANKED: {
      GOLD: Badges.GoldYearlyWithoutRank,
      SILVER: Badges.SilverYearlyWithoutRank,
      BRONZE: Badges.BronzeYearlyWithoutRank,
      GENERIC: Badges.GenericYearly,
    },
  },
};
