import React from "react";
import { useTranslation } from "react-i18next";
import { TENANT_STAGE_STATUS_TYPES } from "src/constants";
import { useClarityScript, useNewRelicScript, useViewport } from "src/hooks";
import { StayTuned, TrialExpired } from "src/images";
import { MobilePage, WebPage } from "..";
import IllustrationContainer from "./IllustrationContainer";

const TenantStage = ({
  status,
  orgCode,
  userId,
  orgDisplayName,
  orgUniqueName,
  role,
}) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  useNewRelicScript();
  useClarityScript({
    OrgCode: orgCode,
    UserId: userId,
    Role: role,
    OrgDisplayName: orgDisplayName,
    OrgUniqueName: orgUniqueName,
  });

  switch (status) {
    case TENANT_STAGE_STATUS_TYPES.IN_PROGRESS:
      return (
        <IllustrationContainer
          image={<StayTuned className="w-100" />}
          title={`${t("DEMO.STAY_TUNED")}!`}
          subTitle={t("DEMO.ACE_ENABLEMENT")}
          isMobile={isMobile}
        />
      );
    case TENANT_STAGE_STATUS_TYPES.TRIAL_EXPIRED:
      return (
        <IllustrationContainer
          image={<TrialExpired className="w-100" />}
          isMobile={isMobile}
          title={`${t("DEMO.TRIAL_EXPIRY")}!`}
          subTitle={t("DEMO.CONTACT_MANAGER")}
        />
      );
    default:
      return isMobile ? <MobilePage /> : <WebPage />;
  }
};

export default TenantStage;
