import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { COLORS } from "src/constants";
import { FormButton } from "../../formElements";
import "./previewModal.scss";
import {
  ITEMS_FOR_PREVIEW,
  PLATFORMS_FOR_PREVIEW,
  ROLES_FOR_PREVIEW,
} from "./testData";

const SelectableCard = ({ text, icon, iconSelected, isSelected = false }) => {
  return (
    <div className={`fs-14 py-2 px-3 ${isSelected ? "bg-blue" : ""}`}>
      <FormButton
        id={`previewModal__card__${text}`}
        className={isSelected ? "fc-blue-primary" : "fc-grey"}
        label={text}
        variant="link"
        icon={isSelected ? iconSelected : icon}
      />
    </div>
  );
};

const ShowRolesToPreview = ({ setSelectedRole, selectedRole }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="mr-2 fs-14 fc-dark-grey">
        {t("NUDGES.MESSAGES.PREVIEW_FOR")} :{" "}
      </div>
      <div className="d-flex cursor-pointer br-4 previewModal__rolesToPreview">
        {Object.keys(ROLES_FOR_PREVIEW)?.map((role) => {
          const Icon = ROLES_FOR_PREVIEW[role].Icon;
          return (
            <FormButton
              className="previewModal__role"
              onClick={() => setSelectedRole(role)}
              variant="link"
            >
              <SelectableCard
                text={ROLES_FOR_PREVIEW[role].Label}
                icon={<Icon />}
                iconSelected={<Icon color={COLORS.PRIMARY_BLUE} />}
                isSelected={selectedRole === role}
              />
            </FormButton>
          );
        })}
      </div>
    </div>
  );
};

const ShowPlatformsToPreview = ({ selectedPlatform, setSelectedPlatform }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="mr-2 fs-14 fc-dark-grey">
        {t("NUDGES.MESSAGES.PLATFORMS")} :{" "}
      </div>
      <div className="d-flex cursor-pointer br-4 previewModal__platformsToPreview">
        {Object.keys(PLATFORMS_FOR_PREVIEW)?.map((platform) => {
          const Icon = PLATFORMS_FOR_PREVIEW[platform].Icon;
          return (
            <FormButton
              className="previewModal__platform"
              onClick={() => setSelectedPlatform(platform)}
              variant="link"
            >
              <SelectableCard
                text={PLATFORMS_FOR_PREVIEW[platform].Label}
                icon={<Icon />}
                iconSelected={<Icon color={COLORS.PRIMARY_BLUE} />}
                isSelected={selectedPlatform === platform}
              />
            </FormButton>
          );
        })}
      </div>
    </div>
  );
};

const RenderPreviewImageAndDescription = ({
  selectedPlatform,
  selectedRole,
}) => {
  const Image = ITEMS_FOR_PREVIEW[selectedRole][selectedPlatform];

  return (
    <div className="d-flex flex-column align-items-center mb-5">
      <div className="my-2 mx-auto images">
        <Image />
      </div>
      <div className="fc-black-secondary fs-16 mb-2">
        {ITEMS_FOR_PREVIEW[selectedRole].Text}
      </div>
      <div className="fc-dark-grey fs-12">
        {ITEMS_FOR_PREVIEW[selectedRole].Description}
      </div>
    </div>
  );
};

const PreviewModal = (props) => {
  const { show, modalTitle, handleClose } = props;

  const [selectedRole, setSelectedRole] = useState("Manager");
  const [selectedPlatform, setSelectedPlatform] = useState("Mobile");

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="previewModal__container"
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4 flex-between bg-grey">
          <ShowRolesToPreview
            setSelectedRole={setSelectedRole}
            selectedRole={selectedRole}
          />
          <ShowPlatformsToPreview
            selectedPlatform={selectedPlatform}
            setSelectedPlatform={setSelectedPlatform}
          />
        </div>
        <RenderPreviewImageAndDescription
          selectedPlatform={selectedPlatform}
          selectedRole={selectedRole}
        />
      </Modal.Body>
    </Modal>
  );
};

PreviewModal.propTypes = {
  show: PropTypes.bool,
  modalTitle: PropTypes.string,
  handleClose: PropTypes.func,
};

export default PreviewModal;
