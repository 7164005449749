import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const VALIDATE_BULK_APPROVAL_IMPORT_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation validateBulkApprovalImport(
    $programId: ID!
    $recurrenceId: ID!
    $GroupId: ID
    $fileId: Float!
    $query: JSON
  ) {
    validateBulkApprovalImport(
      programId: $programId
      recurrenceId: $recurrenceId
      GroupId: $GroupId
      fileId: $fileId
      query: $query
    ) {
      ... on ValidateBulkApprovalImportResponse {
        message
        success
        response {
          downloadUrl
          errorCount
          isApprovalAllowed
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useValidateBulkApprovalImport = () => {
  return useMutation(VALIDATE_BULK_APPROVAL_IMPORT_MUTATION);
};
