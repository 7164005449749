import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useGetSubGoalFilters } from "src/api/useGoal";
import {
  useGetIncentiveMetricProgramDetails,
  useGetIncentiveMetricPrograms,
  useGetIncentiveMetricRecurrence,
} from "src/api/useIncentive";
import { INITIAL_INCENTIVE_SUBGOAL_STATE } from "src/apps/incentives/data/types";
import { FormButton, FormInput, GroupByModal, SearchBar } from "src/components";
import {
  CONDITION_TYPES_OPTIONS,
  FORMULA_METRIC_TYPE,
  GOAL_ACHIEVEMENT_TYPE,
  REPORTEES_TYPE,
} from "src/constants";
import { checkValue } from "src/helpers";
import { useParams } from "src/router";
import { getSubGoalEntityValuePair } from "../utils";
import {
  RecurrenceItem,
  RenderBreadcrumb,
  RenderCombinedList,
  RenderOptions,
  ShimmerList,
} from "./data";
import "./formulaMenuMetric.scss";

const Step1 = (props) => {
  const {
    setStep,
    setBreadcrumbList,
    tokenType,
    incentiveDetails,
    selectedRecurrence,
  } = props;

  const { programId } = useParams();
  const { loading, data } = useGetIncentiveMetricPrograms({
    variables: { ProgramId: programId },
  });
  const { loading: currentProgramLoading, data: CurrentProgramData } =
    useGetIncentiveMetricProgramDetails({
      variables: { ProgramId: programId },
    });
  const [search, setSearch] = useState("");

  const incentivePrograms = data?.getMetricPrograms?.response || [];
  const currentProgram =
    CurrentProgramData?.getMetricProgramDetails?.response || [];

  if (loading || currentProgramLoading) return <ShimmerList />;

  const searchFilter = (options, labelKey, searchText, key) => {
    if (!options?.length) return [];
    return options
      .filter((option) => {
        const label = option[labelKey];
        return label.toLowerCase().includes(searchText.toLowerCase());
      })
      .map((option) => ({
        ...option,
        label: option[labelKey],
        isSameProgram:
          key === "INCENTIVE_PROGRAMS" && option?.ProgramId === programId,
        hasFutherSteps: true,
      }));
  };

  const combinedData = [
    {
      groupLabel: t("RULEBOOK.LABELS.RULEBOOKS"),
      key: "RULESETS",
      options: searchFilter(
        currentProgram?.SelectedTemplates,
        "Name",
        search,
        "RULESETS"
      ),
    },
    {
      groupLabel: t("COMMON.BUTTONS.GOALS"),
      key: "GOALS",
      options: searchFilter(
        currentProgram?.SelectedGoals,
        "GoalName",
        search,
        "GOALS"
      ),
    },
    {
      groupLabel: t("INCENTIVES.COMMON.LABELS.INCENTIVE_PROGRAMS"),
      key: "INCENTIVE_PROGRAMS",
      options: searchFilter(
        incentivePrograms,
        "Name",
        search,
        "INCENTIVE_PROGRAMS"
      ),
    },
  ];

  const handleOnClick = ({ option, id }) => {
    switch (id) {
      case "RULESETS": {
        const groupByEntities = incentiveDetails?.SelectedTemplates?.find(
          (template) => template.TemplateId === option?.TemplateId
        )?.GroupBy;
        const templateConfig = {
          tokenType,
          value: option?.TemplateId,
          fieldName: "TemplateId",
          templateName: option?.Name,
          groupByEntities: groupByEntities,
          enableGroupBy: Boolean(groupByEntities),
        };
        setStep("STEP_5");
        setBreadcrumbList([
          { ...templateConfig, label: option?.Name, key: "STEP_1" },
          { ...templateConfig, label: "Condition Type", key: "STEP_5" },
        ]);

        break;
      }
      case "GOALS": {
        setStep("STEP_4");

        const [groupByEntities, selectedGoalsEntityValuePair] =
          getSubGoalEntityValuePair(
            selectedRecurrence?.SubGoalFilters?.find(
              (goal) => goal?.GoalId === option?.GoalId
            )?.SubGoals
          );

        const goalConfig = {
          tokenType,
          value: option?.GoalId,
          fieldName: "GoalId",
          goalName: option?.GoalName,
          hasFutherSteps: true,
          groupByEntities,
          groupByValues: selectedGoalsEntityValuePair,
          enableGroupBy:
            Boolean(groupByEntities) && Boolean(selectedGoalsEntityValuePair),
        };
        setBreadcrumbList([
          { ...goalConfig, label: option?.GoalName, key: "STEP_1" },
          { ...goalConfig, label: "Sub Metric", key: "STEP_4" },
        ]);

        break;
      }
      case "INCENTIVE_PROGRAMS": {
        setStep("STEP_2");
        setBreadcrumbList([
          {
            ...option,
            label: option?.Name,
            hasFutherSteps: true,
            key: "STEP_1",
          },
          { label: "Type", key: "STEP_2" },
        ]);
        break;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <div className="px-3 mt-3">
        <SearchBar {...{ search, setSearch }} placeholder="Search" />
      </div>
      <RenderCombinedList {...{ data: combinedData, handleOnClick, t }} />
    </>
  );
};

const Step2 = ({ setStep, breadcrumbList, setBreadcrumbList }) => {
  const ProgramId = breadcrumbList[0]?.ProgramId;
  const { loading, data: CurrentProgramData } =
    useGetIncentiveMetricProgramDetails({ variables: { ProgramId } });

  const currentProgram =
    CurrentProgramData?.getMetricProgramDetails?.response || [];

  if (loading) return <ShimmerList />;

  const combinedData = [
    {
      groupLabel: "Reward Type & Reportees",
      key: "CALCULATED_INCENTIVE",
      options: Object.entries(FORMULA_METRIC_TYPE).map(([value, label]) => ({
        label,
        value,
        hasFutherSteps: true,
      })),
    },
    {
      groupLabel: "Rulesets",
      key: "RULESETS",
      options: currentProgram?.SelectedTemplates?.map((option) => ({
        ...option,
        label: option?.Name,
        hasFutherSteps: true,
        groupByEntities: option?.GroupBy,
      })),
    },
    {
      groupLabel: "Goals",
      key: "GOALS",
      options: currentProgram?.SelectedGoals?.map((option) => ({
        ...option,
        label: option?.GoalName,
        hasFutherSteps: true,
      })),
    },
  ];

  const handleOnClick = ({ option, id }) => {
    if (
      id === "CALCULATED_INCENTIVE" &&
      option?.value === Object.keys(FORMULA_METRIC_TYPE)[2]
    ) {
      setStep("STEP_6");
      setBreadcrumbList((prev) => [
        ...prev,
        {
          type: option?.value,
          label: "Input Value",
          key: "STEP_6",
        },
      ]);
    } else {
      setStep("STEP_3");
      setBreadcrumbList((prev) => [
        ...prev,
        {
          type:
            id === "RULESETS"
              ? option?.TemplateId
              : id === "GOALS"
              ? option?.GoalId
              : option?.value,
          typeName:
            id === "RULESETS"
              ? { templateName: option?.Name }
              : id === "GOALS"
              ? { goalName: option?.GoalName }
              : null,
          label: "Cycles",
          groupByEntities: id === "RULESETS" && option?.groupByEntities,
          key: "STEP_3",
        },
      ]);
    }
  };

  return <RenderCombinedList {...{ data: combinedData, handleOnClick, t }} />;
};

const Step3 = ({
  setStep,
  breadcrumbList,
  handleClose,
  handleClick,
  tokenType,
  setBreadcrumbList,
  recurrenceId,
}) => {
  const ProgramId = breadcrumbList[0]?.ProgramId;

  const GoalId = breadcrumbList[2]?.typeName?.goalName
    ? breadcrumbList[2]?.type
    : undefined;

  const TemplateId = breadcrumbList[2]?.typeName?.templateName
    ? breadcrumbList[2]?.type
    : undefined;

  const { loading, data: CurrentRecurrenceData } =
    useGetIncentiveMetricRecurrence({
      variables: { ProgramId, GoalId, RecurrenceId: recurrenceId },
    });

  const recurrences =
    CurrentRecurrenceData?.getMetricRecurrences?.response?.Recurrences || [];

  const options = recurrences.map((rec, idx) => ({
    ...rec,
    type: breadcrumbList[2]?.type,
    typeName: breadcrumbList[2]?.typeName,
    idx: idx + 1,
    isDisabled:
      Boolean(rec?.IsDisabled) ||
      (GoalId && !Boolean(rec?.GoalRecurrenceExist)),
    disabledText:
      GoalId && !Boolean(rec?.GoalRecurrenceExist)
        ? "This incentive program cycle is not part of selected goal."
        : "This incentive program cycle is not processed yet.",
    label: <RecurrenceItem {...{ ...rec, idx: idx + 1 }} />,
    hasFutherSteps: Boolean(GoalId) || Boolean(TemplateId),
    groupByEntities: TemplateId && breadcrumbList[2]?.groupByEntities,
    enableGroupBy: TemplateId && Boolean(breadcrumbList[2]?.groupByEntities),
    ReporteesIncentiveValue: breadcrumbList[3]?.ReporteesIncentiveValue,
  }));

  const handleOnClick = ({ option }) => {
    if (GoalId || TemplateId) {
      setStep(TemplateId ? "STEP_5" : "STEP_4");
      setBreadcrumbList((prev) => [
        ...prev,
        {
          value: option?.RecurrenceId,
          cycle: {
            idx: option?.idx,
            RecurrenceStartDate: option?.RecurrenceStartDate,
            RecurrenceEndDate: option?.RecurrenceEndDate,
          },
          fieldName: "RecurrenceId",
          programName: breadcrumbList[0]?.Name,
          tokenType,
          type: option?.type,
          label: TemplateId ? "Condition Type" : "Sub Metric",
          ...option?.typeName,
          key: TemplateId ? "STEP_5" : "STEP_4",
          groupByEntities: option?.groupByEntities,
          groupByValues: option?.groupByValues,
          enableGroupBy: option?.enableGroupBy,
          GoalRecurrenceId: GoalId && option?.GoalRecurrenceId,
        },
      ]);
    } else {
      handleClick({
        value: option?.RecurrenceId,
        cycle: {
          idx: option?.idx,
          RecurrenceStartDate: option?.RecurrenceStartDate,
          RecurrenceEndDate: option?.RecurrenceEndDate,
        },
        fieldName: "RecurrenceId",
        programName: breadcrumbList[0]?.Name,
        tokenType,
        type: option?.type,
        ReporteesIncentiveValue: option?.ReporteesIncentiveValue,
        ...option?.typeName,
      });
      handleClose();
    }
  };

  if (loading) return <ShimmerList />;

  return (
    <div className="formula_menu_metric_tab_list px-3 space-y-8">
      <RenderOptions
        {...{ options, handleOnClick, t, disabledClassName: "mt-2" }}
      />
    </div>
  );
};

const Step4 = ({
  handleClose,
  handleClick,
  breadcrumbList,
  setBreadcrumbList,
  setStep,
}) => {
  const { data: subGoalFilters, loading: subGoalFiltersLoading } =
    useGetSubGoalFilters({
      variables: { RecurrenceId: breadcrumbList[3]?.GoalRecurrenceId },
      skip: !breadcrumbList[3]?.GoalRecurrenceId,
      fetchPolicy: "cache-and-network",
    });

  const [groupByEntities, selectedGoalsEntityValuePair] =
    getSubGoalEntityValuePair(subGoalFilters?.getSubGoalFilters?.response);

  const data = [
    {
      groupLabel: t("INCENTIVES.PROGRAM.LABELS.GOAL_ACHIEVEMENT_TYPE"),
      key: "GOAL_ACHIEVEMENT_TYPE",
      options: Object.entries(GOAL_ACHIEVEMENT_TYPE).map(([value, label]) => {
        return {
          label,
          value,
          hasFutherSteps: true,
        };
      }),
    },
    {
      groupLabel: t("COMMON.LABELS.REPORTEES"),
      key: "REPORTEES",
      options: Object.entries(REPORTEES_TYPE).map(([value, label]) => {
        return {
          label,
          value,
          hasFutherSteps: true,
        };
      }),
    },
  ];
  const handleOnClick = ({ option }) => {
    const previousData = breadcrumbList[breadcrumbList?.length - 1];
    if (option?.hasFutherSteps) {
      setStep("STEP_5");
      setBreadcrumbList((prev) => [
        ...prev,
        {
          ...previousData,
          GoalAchievementType: option?.value,
          enableGroupBy: previousData?.GoalRecurrenceId
            ? Boolean(groupByEntities) && Boolean(selectedGoalsEntityValuePair)
            : previousData?.enableGroupBy,
          groupByEntities: previousData?.GoalRecurrenceId
            ? groupByEntities
            : previousData?.groupByEntities,
          groupByValues: previousData?.GoalRecurrenceId
            ? selectedGoalsEntityValuePair
            : previousData?.groupByValues,
          label: "Condition Type",
          key: "STEP_5",
        },
      ]);
    } else {
      handleClick({
        ...previousData,
        GoalAchievementType: option?.value,
      });
      handleClose();
    }
  };

  if (breadcrumbList[3]?.GoalRecurrenceId && subGoalFiltersLoading)
    return <ShimmerList />;
  return (
    <div className="formula_menu_metric_tab_list space-y-8">
      <RenderCombinedList {...{ data, handleOnClick }} />
    </div>
  );
};

const Step5 = ({ handleClose, handleClick, breadcrumbList }) => {
  const [showSubGoalModal, setShowSubGoalModal] = useState(false);
  const previousData = breadcrumbList[breadcrumbList?.length - 1];
  const data = [
    {
      groupLabel: t("INCENTIVES.PROGRAM.LABELS.SELECT_GROUP_BY_FIELD"),
      key: "Condition Type",
      options: CONDITION_TYPES_OPTIONS.map((option) => ({
        ...option,
        isDisabled: !previousData?.enableGroupBy && option?.value === "GROUPBY",
        disabledText:
          breadcrumbList[0]?.fieldName === "GoalId" ||
          previousData?.hasOwnProperty("goalName")
            ? t("COMMON.MESSAGES.GOAL_DOESNT_SUPPORT_GROUPBY")
            : t("COMMON.MESSAGES.RULESET_DOESNT_SUPPORT_GROUPBY"),
      })),
    },
  ];
  const handleOnClick = ({ option }) => {
    if (option?.value === "GROUPBY") {
      setShowSubGoalModal(true);
    } else {
      handleClick({ ...previousData });
      handleClose();
    }
  };

  return (
    <div className="formula_menu_metric_tab_list space-y-8">
      <RenderCombinedList {...{ data, handleOnClick }} />
      {showSubGoalModal && (
        <GroupByModal
          show={showSubGoalModal}
          setShowModal={setShowSubGoalModal}
          modalTitle={t("INCENTIVES.COMMON.LABELS.ADD_GROUP_BY_CONDITION")}
          modalSubTitle={t("INCENTIVES.COMMON.LABELS.GROUP_BY_CONDITION")}
          modalDescription={
            <Trans i18nkey={"INCENTIVES.COMMON.LABELS.GROUP_BY_DESCRIPTION"}>
              Measure and attain sub-goals that contribute to the accomplishment
              of a primary objective.
              <br /> To employ multiple groupings, simply click on 'AND'.
            </Trans>
          }
          subGoals={INITIAL_INCENTIVE_SUBGOAL_STATE}
          setSubGoals={(SubGoal) => {
            handleClick({ ...previousData, ...SubGoal });
            handleClose();
          }}
          isCreating
          groupByEntities={previousData?.groupByEntities}
          groupByValues={previousData?.groupByValues}
        />
      )}
    </div>
  );
};

const Step6 = ({ breadcrumbList, setStep, setBreadcrumbList }) => {
  const [value, setValue] = useState("");
  const handleOnClick = () => {
    setStep("STEP_3");
    setBreadcrumbList((prev) => [
      ...prev,
      {
        type: breadcrumbList[2]?.type,
        label: "Cycles",
        key: "STEP_3",
        ReporteesIncentiveValue: value,
      },
    ]);
  };

  return (
    <div className="px-3 pb-3">
      <span className="fs-12 fc-grey1 semi-bold ">
        {t("COMMON.LABELS.INCENTIVE_AMOUNT")}
      </span>
      <FormInput
        placeholder="Enter Incentive Amount"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="mt-2"
      />
      <div className="mt-5 center justify-content-end">
        <FormButton
          onClick={handleOnClick}
          disabled={!checkValue(Number(value))}
          className="px-3"
        >
          {t("COMMON.BUTTONS.NEXT")}
        </FormButton>
      </div>
    </div>
  );
};

const STEPS = {
  STEP_1: {
    label: "Program Selection",
    key: "STEP_1",
    Component: Step1,
  },
  STEP_2: {
    label: "Type",
    key: "STEP_2",
    Component: Step2,
  },
  STEP_3: {
    label: "Cycles",
    key: "STEP_3",
    Component: Step3,
  },
  STEP_4: {
    label: "Sub Metric",
    key: "STEP_4",
    Component: Step4,
  },
  STEP_5: {
    label: "Condition Type",
    key: "STEP_5",
    Component: Step5,
  },
  STEP_6: {
    label: "Static",
    key: "STEP_6",
    Component: Step6,
  },
};

const FormulaMenuMetric = ({
  handleClose,
  handleClick,
  recurrenceId,
  tokenType,
  setTokenType,
  incentiveDetails,
  selectedRecurrence,
}) => {
  const [breadcrumbList, setBreadcrumbList] = useState([]);
  const [step, setStep] = useState("STEP_1");

  const Component = useMemo(() => STEPS[step].Component, [step]);

  const handleBreadcrumbClick = (key, idx) => {
    setStep(key);
    if (idx === 0) setBreadcrumbList([]);
    else {
      setBreadcrumbList((prev) => prev.slice(0, idx + 1));
    }
  };
  return (
    <div>
      <RenderBreadcrumb
        breadcrumbList={breadcrumbList}
        onClick={handleBreadcrumbClick}
      />
      <Component
        {...{
          setStep,
          breadcrumbList,
          setBreadcrumbList,
          handleClick,
          handleClose,
          recurrenceId,
          tokenType,
          setTokenType,
          incentiveDetails,
          selectedRecurrence,
        }}
      />
    </div>
  );
};

export default FormulaMenuMetric;
