import React from "react";
import { Shimmer } from "src/components/common/atoms";

const LoadingMobileHeader = () => (
  <div className="border-b center py-4 px-3">
    <div>
      <Shimmer width="20px" />
    </div>
    <div className="w-100 ml-2">
      <Shimmer width="40%" />
    </div>
  </div>
);

export default LoadingMobileHeader;
