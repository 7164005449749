import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'leaderboards.web.requestDemoPage' */ "./web")
);

const MobilePage = lazy(() =>
  import(
    /* webpackChunkName: 'leaderboards.mobile.requestDemoPage' */ "./mobile"
  )
);

export const RequestDemoPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "requestDemo",
  },
};
