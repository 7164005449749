import { classNames } from "src/helpers";

function StatsItem({ title, className, children }) {
  return (
    <div className={classNames(["stats-item", className])}>
      <div className="fs-13 fc-grey semi-bold">{title}</div>
      {children}
    </div>
  );
}

export default StatsItem;
