import React from "react";

const SvgAvatar37 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="lrq5__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .lrq5__cls-1{fill:#fff}.lrq5__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="lrq5__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4c0-8.73 1.37-17.14 3.93-25.02.3-.99.64-1.96 1-2.93a80.83 80.83 0 0 1 13.39-23.49c.63-.79 1.29-1.56 1.97-2.32C35.19 10.69 57.05 0 81.4 0c44.96 0 81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.75}
          className="lrq5__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.07)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="lrq5__cls-2"
        />
        <path
          d="M81.4 55.8c-2.64 0-5.13-1.03-7-2.9s-2.9-4.36-2.9-7 1.03-5.13 2.9-7 4.36-2.9 7-2.9 5.13 1.03 7 2.9 2.9 4.36 2.9 7-1.03 5.13-2.9 7-4.36 2.9-7 2.9Zm0-17.24c-1.96 0-3.8.76-5.19 2.15-1.37 1.37-2.15 3.26-2.15 5.19s.76 3.8 2.15 5.19c1.39 1.39 3.23 2.15 5.19 2.15s3.8-.76 5.19-2.15c1.39-1.39 2.15-3.23 2.15-5.19s-.78-3.83-2.15-5.19a7.284 7.284 0 0 0-5.19-2.15Z"
          className="lrq5__cls-1"
        />
        <path
          d="M47.22 66.15c-2.27 0-4.41-.88-6.02-2.49a8.545 8.545 0 0 1-2.49-6.04c0-1.62.64-3.15 1.79-4.3a6.096 6.096 0 0 1 4.3-1.79h6v-2.17c0-3.71.65-7.35 1.95-10.81.02-.05.05-.11.07-.16.1-.26.2-.5.3-.75 1.54-3.73 3.78-7.08 6.63-9.94 3.56-3.56 8-6.22 12.83-7.68l.14-.04v-4.15c0-.71.57-1.28 1.28-1.28h14.79a1.263 1.263 0 0 1 1.27 1.27V20l.14.04a30.81 30.81 0 0 1 11.67 6.55c3.62 3.26 6.45 7.38 8.17 11.94 1.31 3.48 1.96 7.11 1.95 10.8v2.18h6c1.62 0 3.15.63 4.3 1.78a6.128 6.128 0 0 1 1.78 4.3c0 2.28-.89 4.42-2.51 6.03a8.523 8.523 0 0 1-6.03 2.51H47.22Zm-2.43-12.07c-.94 0-1.83.37-2.49 1.03-.66.66-1.03 1.57-1.03 2.49 0 1.58.63 3.12 1.74 4.24a5.923 5.923 0 0 0 4.21 1.74h68.32c1.6 0 3.1-.62 4.23-1.75a5.937 5.937 0 0 0 1.75-4.23c0-.93-.37-1.83-1.04-2.48a3.466 3.466 0 0 0-2.47-1.05H94.02l-.06.09a15.061 15.061 0 0 1-5.04 4.78 15.092 15.092 0 0 1-7.53 2.02c-2.64 0-5.25-.7-7.54-2.02a15.092 15.092 0 0 1-5.05-4.78l-.06-.09H44.79Zm36.6-20.7c-4.45 0-8.61 2.4-10.84 6.25-1.1 1.9-1.68 4.06-1.68 6.26s.58 4.36 1.69 6.26c1.47 2.55 3.79 4.5 6.55 5.51 1.38.5 2.82.76 4.28.76s2.9-.25 4.28-.76c2.76-1 5.08-2.96 6.55-5.5 1.1-1.9 1.68-4.06 1.68-6.26v-.39c-.07-2.04-.65-4.05-1.68-5.82-.04-.07-.07-.14-.1-.22a12.561 12.561 0 0 0-4.48-4.42 12.58 12.58 0 0 0-6.25-1.67Zm14.1 7.18c.59 1.55.91 3.17.97 4.84v.5c.02 1.84-.31 3.64-.97 5.36l-.1.27h14.05v-2.18c0-3.04-.5-6.04-1.48-8.92l-.05-.14H95.39l.1.27Zm-40.68-.15c-.97 2.89-1.47 5.9-1.46 8.95v2.17h14.06l-.1-.27c-.66-1.72-.99-3.52-.99-5.36s.33-3.63.99-5.35l.1-.27H54.87l-.05.14Zm35.25-6.83.08.06a14.95 14.95 0 0 1 3.82 3.99l.06.09h12.9l-.13-.29c-1.73-3.69-4.3-7.02-7.44-9.62a27.956 27.956 0 0 0-9.03-5.03l-.26-.09v10.89ZM72.45 22.75a28.717 28.717 0 0 0-10.89 6.76c-2.31 2.3-4.18 4.97-5.56 7.92l-.13.29h12.86l.06-.09c1.03-1.57 2.32-2.92 3.84-4l.08-.06V22.66l-.26.09Zm8.93 8.07c1.76 0 3.5.31 5.15.91l.69.27.28.12v-15H75.27v14.99l.28-.12c.22-.09.45-.18.68-.27 1.66-.6 3.39-.91 5.15-.91Z"
          className="lrq5__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar37.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar37;
