import { useContext } from "react";
import { GlobalContext } from "src/context";

const useIsLoggedIn = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useIsLoggedIn must be used within a GlobalProvider");
  }
  return context;
};

export default useIsLoggedIn;
