import PropTypes from "prop-types";
import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { range } from "src/helpers";

const LoadingTabs = ({ tabs }) => (
  <div className="page-container mt-4">
    <div className="center border-b pb-4">
      {range(1, tabs).map((i) => (
        <div key={i} className={`mt-3 ${i !== 0 ? "pl-3" : ""}`}>
          <Shimmer width="75px" height="15px" />
        </div>
      ))}
    </div>
  </div>
);

LoadingTabs.propTypes = {
  tabs: PropTypes.number,
};

LoadingTabs.defaultProps = {
  tabs: 3,
};

export default LoadingTabs;
