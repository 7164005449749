import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConditionViewer, Truncate } from "src/components/common/atoms";

const ConditionsModal = (props) => {
  const { t } = useTranslation();
  const { conditionModal, showConditionModal, goalRule, ruleName } = props;

  const getCriteriaString = () => {
    const patternString = goalRule?.CriteriaPattern;
    const patternArray = patternString.replace(/([0-9]+)/g, " $1 ").split(" ");
    const pattern = patternArray.map((element, idx) => {
      return isNaN(element) ? (
        <span key={idx} className="fc-grey mr-1">
          {element}
        </span>
      ) : (
        <span key={idx} className="fc-black mr-1">
          {element}
        </span>
      );
    });
    return pattern;
  };
  return (
    <Modal
      show={conditionModal}
      onHide={() => showConditionModal(false)}
      aria-labelledby="conditions-modal"
      dialogClassName="conditions-modal"
      centered
    >
      <Modal.Header closeButton className="semi-bold">
        <div className="center ">
          {t("COMMON.LABELS.RULE")}
          <div className="mx-1">
            <Truncate text={ruleName} maxWidth="200px" />
          </div>
          <span className="mr-1">:</span>
          {t("RULEBOOK.LABELS.CONDITIONS")}
        </div>
      </Modal.Header>
      <Modal.Body className="fs-13 p-3">
        <div className="condition-viewer mb-2">
          <ConditionViewer conditions={goalRule?.Conditions} hideButtons />
        </div>
        <div className="condition-pattern-footer">
          {goalRule?.Conditions?.length > 1 ? (
            <div className="border-t pt-2">
              <span className="semi-bold">
                {t("RULEBOOK.LABELS.CONDITION_PATTERN")}
              </span>
              <div className="w-100 fs-12 mt-2 p-2 primary-text-grey condition-definition">
                {getCriteriaString()}
              </div>
            </div>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConditionsModal;
