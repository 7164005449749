import React from "react";
import { useTranslation } from "react-i18next";
import { FormBadge } from "src/components";
import { classNames, isEmpty } from "src/helpers";

const MobileIncentiveStatusBadge = (props) => {
  const { isProgramYetToStart, programData, className } = props;

  const { t } = useTranslation();
  const completed =
    isEmpty(programData?.CurrentRecurrenceCycle) &&
    isEmpty(programData?.RecurrenceTimeLeft) &&
    programData?.Status === "PUBLISHED";

  const computeBadgeText = () => {
    let text;
    if (programData?.Status === "UNPUBLISHED") {
      return (
        <span>
          {t("INCENTIVES.COMMON.MESSAGES.PROGRAM_UNPUBLISHED")}
          <b>{t("COMMON.LABELS.UNPUBLISHED")}</b>
        </span>
      );
    }
    if (completed) {
      text = (
        <span>
          <b>{t("COMMON.LABELS.ALL_CYCLES_COMPLETED")}</b>
        </span>
      );
    } else if (isProgramYetToStart) {
      text = (
        <span>
          <b>&nbsp;{programData?.CurrentRecurrenceCycle?.split(" ")[0]}</b>
          <span>&nbsp; {t("COMMON.LABELS.CYCLE_STARTS_IN")} &nbsp;</span>
          {programData?.RecurrenceDueText}
        </span>
      );
    } else {
      text = (
        <span>
          <b>{programData?.CurrentRecurrenceCycle?.split(" ")[0]}</b>{" "}
          {t("MANAGED_GOALS.LABELS.CYCLE")}
          <span className="text-lowercase pl-1">
            {t("MANAGED_GOALS.MESSAGES.IN_PROGRESS")}
          </span>
        </span>
      );
    }
    return text;
  };
  return (
    <div>
      <FormBadge
        className={classNames([
          "bg-secondary-blue fc-grey",
          className || "h-40",
        ])}
      >
        {computeBadgeText()}
      </FormBadge>
    </div>
  );
};

export default MobileIncentiveStatusBadge;
