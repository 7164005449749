import React from "react";
import { Shimmer } from "src/components/common/atoms";

const LoadingTemplateList = () => {
  return (
    <>
      <div className="px-4 pt-4">
        <Shimmer width="100px" height="10px" />
        <div className="mt-4">
          <Shimmer width="250px" height="10px" />
        </div>
        <div className="mt-2">
          <Shimmer width="200px" height="10px" />
        </div>
      </div>
      <div className="px-4 mt-5">
        <Shimmer width="100px" height="10px" />
        <div className="mt-4">
          <Shimmer width="250px" height="10px" />
        </div>
        <div className="mt-2">
          <Shimmer width="200px" height="10px" />
        </div>
      </div>
      <div className="px-4 mt-5">
        <Shimmer width="100px" height="10px" />
        <div className="mt-4">
          <Shimmer width="250px" height="10px" />
        </div>
        <div className="mt-2">
          <Shimmer width="200px" height="10px" />
        </div>
      </div>
      <div className="px-4 mt-5">
        <Shimmer width="100px" height="10px" />
        <div className="mt-4">
          <Shimmer width="250px" height="10px" />
        </div>
        <div className="mt-2">
          <Shimmer width="200px" height="10px" />
        </div>
      </div>
    </>
  );
};
export default LoadingTemplateList;
