import PropTypes from "prop-types";
import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import { classNames } from "src/helpers";

const FormInput = React.forwardRef((props, ref) => {
  const {
    id,
    placeholder,
    as,
    className,
    maxLength,
    onChange,
    type,
    disabled,
    ...rest
  } = props;
  const inputRef = useRef();
  return (
    <Form.Control
      {...rest}
      ref={ref?.current ? ref : inputRef}
      type={type}
      as={as}
      id={id}
      className={classNames(["fs-13", className || ""])}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={(e) => {
        onChange(e);
        ref?.current?.focus() || inputRef.current.focus();
      }}
      disabled={disabled}
    />
  );
});

FormInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  as: PropTypes.oneOf(["input", "textarea"]),
  className: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

FormInput.defaultProps = {
  as: "input",
};

export default FormInput;
