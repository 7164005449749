import React from "react";

const SvgAvatar34 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="y46t__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .y46t__cls-1{fill:#fff}.y46t__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="y46t__Layer_1-2">
        <path
          d="M162.8 81.4c0 21.54-8.37 41.12-22.05 55.68-.33.36-.67.71-1.01 1.06-.01.02-.03.04-.05.06-.35.35-.7.71-1.05 1.06l-1.05 1.02c-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05l-.61-.64c-.35-.36-.69-.73-1.02-1.09-.34-.38-.68-.75-1.01-1.13-.96-1.08-1.88-2.18-2.77-3.31-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <path
          d="M139.7 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="y46t__cls-2"
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="y46t__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.075)"
        />
        <path
          d="M87.71 56.44c-.83 0-1.5-.67-1.5-1.5V40.51c-2.75-.54-7.07-.2-9.61.12v14.32c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V39.33c0-.74.54-1.37 1.26-1.48.37-.06 9-1.41 13.34.1.6.21 1.01.78 1.01 1.42v15.59c0 .83-.67 1.5-1.5 1.5Z"
          className="y46t__cls-1"
        />
        <path
          d="M81.41 71.05c-.38 0-.77-.15-1.06-.44L65.73 56a1.505 1.505 0 0 1 1.06-2.57h8.31c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-4.69l11 10.99L92.4 56.43h-4.69c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h8.31c.61 0 1.15.37 1.39.93s.1 1.21-.33 1.63L82.47 70.6c-.29.29-.68.44-1.06.44ZM82.01 125.81c-2.09 0-3.03-2.68-4.96-8.75-.82-2.57-1.66-5.22-2.41-6.57-.58-1.04-5.31-1.79-8.44-2.28-5.69-.9-11.58-1.82-13.9-5.05-1.03-1.43-1.01-2.69-.81-3.51.38-1.59 1.72-2.96 4.1-4.18.74-.38 1.64-.09 2.02.65.38.74.09 1.64-.65 2.02-2.08 1.07-2.48 1.9-2.55 2.21-.03.12-.11.44.33 1.05 1.59 2.21 7.32 3.11 11.93 3.84 5.2.82 9.3 1.47 10.59 3.79.9 1.61 1.75 4.29 2.65 7.12.51 1.59 1.18 3.7 1.77 5.17.1-.53.19-1.1.27-1.59.68-4.13 1.6-9.79 5.24-11.65 3.06-1.57 6.19-2 8.27-2.09 2.65-.11 5.12-.55 7.34-1.32 3.75-1.3 5.92-2.9 5.81-4.28-.08-1.05-.75-1.92-2.05-2.68-.71-.42-.96-1.34-.54-2.05s1.33-.96 2.05-.54c2.71 1.58 3.41 3.61 3.53 5.03.24 3-2.39 5.48-7.81 7.36-2.5.87-5.25 1.37-8.2 1.49-1.78.07-4.45.44-7.03 1.76-2.28 1.17-3.1 6.16-3.64 9.46-.55 3.37-.92 5.6-2.9 5.6Zm.54-2.83Z"
          className="y46t__cls-1"
        />
        <path
          d="M81.5 135.08c-.19 0-.38-.04-.55-.11l-26.36-10.48a1.51 1.51 0 0 1-.82-.79c-.24-.55-5.92-13.59-2.3-23.93.27-.78 1.13-1.2 1.91-.92.78.27 1.19 1.13.92 1.91-2.84 8.1.98 18.66 1.98 21.18l25.21 10.02 25.12-10.25c5.32-10.07 2.09-20.89 2.05-21-.24-.79.2-1.63.99-1.88.79-.24 1.63.2 1.88.99.16.5 3.75 12.46-2.55 23.8-.17.3-.43.53-.75.66l-26.18 10.68c-.18.07-.37.11-.57.11Zm26.18-12.18Z"
          className="y46t__cls-1"
        />
        <path
          d="M81.05 116.46c-2.07 0-3.37-.25-3.45-.26-.81-.16-1.34-.95-1.19-1.76s.94-1.35 1.76-1.19c.05 0 2.57.46 6.14-.01.81-.12 1.58.46 1.69 1.29.11.82-.47 1.58-1.29 1.69-1.37.18-2.61.25-3.65.25ZM54.12 120.44c-.19.06-19.42 6.21-31.68 17.07-.35-.36-.69-.73-1.02-1.09-.34-.38-.68-.75-1.01-1.13h.01c12.75-11.31 31.99-17.45 32.8-17.71a1.498 1.498 0 1 1 .9 2.86ZM140.75 137.08c-.33.36-.67.71-1.01 1.06-.01.02-.03.04-.05.06-.35.35-.7.71-1.05 1.06-.08-.07-.14-.15-.2-.23-7.32-11.12-29.26-18.52-29.48-18.6a1.5 1.5 0 0 1-.95-1.89c.27-.79 1.11-1.22 1.9-.95.93.31 22.77 7.68 30.84 19.49Z"
          className="y46t__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar34.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar34;
