import React, { lazy } from "react";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

const MobilePage = lazy(() =>
  import(
    /* webpackChunkName: 'leaderboard.mobile.dashboardUserDetailViewPage' */ "./mobile"
  )
);

export const DashboardUserDetailViewPage = {
  web: () => <Redirect to={APPS.ACE.ROUTES.ROOT.path} />,
  mobile: MobilePage,
  meta: {
    title: "dashboard-userview-details",
  },
};
