import { useEffect } from "react";

const usePendoScript = (pendodata) => {
  useEffect(() => {
    if (pendodata && pendodata !== "") {
      const script = document.createElement("script");
      script.async = true;
      script.append(`try {
                (function (apiKey) {
                    (function (p, e, n, d, o) {
                        var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || [];
                        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w) (function (m) {
                            o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
                        })(v[w]);
                        y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + '${
                          pendodata.apikey
                        }' + '/pendo.js';
                        z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
                    })(window, document, 'script', 'pendo');
                    
                    pendo.initialize(${JSON.stringify(pendodata)});

                  })('${pendodata.apikey}');
            }
            catch(e) { console.log(e.message);}`);
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [pendodata]);
};

export default usePendoScript;
