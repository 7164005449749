import { RotatingWords } from "src/components/common/atoms";

function FormulaPlaceholder() {
  return (
    <div
      className="fc-grey1 center"
      style={{
        position: "absolute",
        zIndex: -1,
        top: 10,
        left: 10,
      }}
    >
      <span className="mr-2">Type</span>
      <RotatingWords
        words={[
          "rule name",
          "mathematical operators",
          "parenthesis",
          "numbers",
        ]}
      />
    </div>
  );
}

export default FormulaPlaceholder;
