import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_USERS_FOR_APPROVERS = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getUsersForLeaderboardApprovers($Search: String!) {
    getUsersForLeaderboardApprovers(Search: $Search) {
      ... on UsersForLeaderboardApproversSelectionResponse {
        success
        message
        response {
          Name
          EmailAddress
          StatusCode
          UserID
          __typename
        }
        __typename
      }
      ...errorResponseFragment
      __typename
    }
  }
`;

export const useGetApprovers = (queryOptions) => {
  return useQuery(GET_USERS_FOR_APPROVERS, queryOptions);
};
