import {
  AddFirstSlab,
  AddOrRemoveSlab,
  SlabIndex,
  SlabValue,
  SlabWeightage,
} from "./slabComponents";

const SlabItem = ({
  slabs,
  activeSlabIndex,
  slabActions,
  weightagePlaceholder,
  options,
  showPercentage,
}) => {
  return (
    <div className="slab-item">
      {slabs.length === 0 ? (
        <AddFirstSlab addSlab={slabActions.addSlab} />
      ) : null}
      {slabs.map((slab, slabIndex) => {
        return (
          <div key={slabIndex} className="slab center mb-3">
            <SlabIndex value={slabIndex + 1} />
            <div className="slab-input flex-between mx-2">
              <SlabValue
                activeSlabIndex={activeSlabIndex}
                slabIndex={slabIndex}
                minValue={slab.minValue}
                maxValue={slab.maxValue}
                setMinValue={slabActions.setMinValue}
                setMaxValue={slabActions.setMaxValue}
                options={options}
                showPercentage={showPercentage}
              />
              <SlabWeightage
                slabIndex={slabIndex}
                weightage={slab.weightage}
                setWeightage={slabActions.setWeightage}
                placeholder={weightagePlaceholder}
              />
            </div>
            <div className="ml-2">
              {activeSlabIndex === slabIndex ? (
                <AddOrRemoveSlab
                  slabIndex={slabIndex}
                  removeSlab={slabActions.removeSlab}
                  addSlab={slabActions.addSlab}
                  isSlabItemComplete={slabActions.isSlabItemComplete}
                  isSlabStructureComplete={slabActions.isSlabStructureComplete}
                />
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SlabItem;
