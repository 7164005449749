import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { classNames } from "src/helpers";
import { Transition } from "../transitions";
import "./styles.scss";

const SlideDrawer = ({ show, direction = "right", children, className }) => {
  const drawerContentRef = useRef();

  const container = document.body;

  useEffect(() => {
    container.style.overflow = "hidden";
    return () => {
      container.style.overflow = "auto";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(
    <Transition in={show} nodeRef={drawerContentRef} timeout={150}>
      <div className={classNames(["slide-drawer", className])}>
        <div
          ref={drawerContentRef}
          className={classNames(["drawer-content", direction])}
        >
          {children}
        </div>
      </div>
    </Transition>,
    container
  );
};

export default SlideDrawer;
