import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const IMPORT_PROGRAM_UPLOAD_URL_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation getImportProgramUploadUrl($ProgramId: ID!) {
    getImportProgramUploadUrl(ProgramId: $ProgramId) {
      ... on ProgramUrlResponse {
        success
        response {
          url
          fileId
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetUploadUrl = () => {
  return useMutation(IMPORT_PROGRAM_UPLOAD_URL_MUTATION);
};
