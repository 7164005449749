import { t } from "i18next";
import React, { useState } from "react";
import { useGetUserAttributes } from "src/api/useUser";
import { SearchBar } from "src/components";
import { RenderCombinedList, ShimmerList } from "./data";

const FormulaMenuField = ({ handleClick, handleClose, tokenType }) => {
  const { data: usersAttribute, loading: usersAttributeLoading } =
    useGetUserAttributes();
  const [search, setSearch] = useState("");

  if (usersAttributeLoading) return <ShimmerList />;

  const userAttributeList =
    usersAttribute?.getUserAttributesData?.UserAttributes;

  const attributeArray =
    userAttributeList
      ?.filter(({ DisplayName }) =>
        DisplayName.toLowerCase().includes(search.toLowerCase())
      )
      .map(({ DisplayName, SchemaName }) => ({
        label: DisplayName,
        SchemaName,
      })) || [];

  const data = [
    {
      groupLabel: "User Table",
      key: "User Table",
      options: attributeArray,
    },
  ];

  const handleOnClick = ({ option }) => {
    handleClick({
      tokenType,
      value: option?.SchemaName,
      fieldName: option?.label,
    });
    handleClose();
  };

  return (
    <>
      <div className="px-3 mt-3">
        <SearchBar {...{ search, setSearch }} placeholder="Search" />
      </div>
      <RenderCombinedList {...{ data, handleOnClick, t }} />
    </>
  );
};

export default FormulaMenuField;
