import React from "react";
import { Navigation } from "src/components/layouts/navbar/variants";
import { classNames } from "src/helpers";
import ProductMenuModal from "./ProductMenuModal";

const PageHeader = ({
  title,
  icon,
  onClick,
  position,
  backgroundColor = "#F2F8FF",
  className,
  children,
  showBackButton,
}) => {
  return (
    <Navigation
      {...{
        title,
        icon,
        onClick,
        position,
        children,
        showBackButton,
        backgroundColor,
      }}
      className={classNames(["h-48", className])}
    >
      <ProductMenuModal />
    </Navigation>
  );
};

export default PageHeader;
