import { gql, useMutation } from "src/api/client";

const DELETE_CUSTOM_REWARD = gql`
  mutation deleteCustomReward($RewardId: ID) {
    deleteCustomReward(RewardId: $RewardId) {
      ... on CustomRewardResponse {
        success
        message
        response {
          RewardId
          RewardName
          RewardValue
          RewardLink
          Description
          FileId
          FileName
          RewardCategory
          IsActive
          AwardCount
          ModulesUsedCount
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useDeleteCustomReward = () => {
  return useMutation(DELETE_CUSTOM_REWARD);
};
