import {
  AVERAGE_COLUMN_HEADING,
  GOAL_AGGREGATION_TYPES,
  GOAL_AGG_FUNCTION,
} from "src/constants";
import { getCycleIntervalString, range } from "src/helpers";

export const getCustomRowRange = (end, cellNumber, start = 0) => {
  const alpha = Array.from(Array(end)).map((e, i) => i + 65 + start);
  return alpha.map((x) => `${String.fromCharCode(x)}${cellNumber}`);
};

export const getCustomColumnRange = (end, columnChar, start = 0) => {
  return range(start, end).map((x) => `${columnChar}${x}`);
};

export const getCellChar = (columnNumber) =>
  String.fromCharCode(65 + columnNumber - 1);

export const joinString = (classes) => {
  return classes.filter((className) => className).join("");
};

export const columnFormatter = ({
  accessor,
  Header,
  recurrence,
  index,
  goalAggConfig,
}) => {
  const FIXED_CYCLE_INTERVAL_STRING = [
    "Cycle ",
    index + 1,
    ":\n",
    getCycleIntervalString(recurrence?.startDate, recurrence?.endDate),
    "\n",
  ];

  switch (accessor) {
    case "FullName": {
      return "User Name";
    }
    case "GoalDistribution": {
      switch (goalAggConfig) {
        case GOAL_AGGREGATION_TYPES.TARGET_BASED: {
          return joinString([...FIXED_CYCLE_INTERVAL_STRING, "Goal value"]);
        }
        case GOAL_AGGREGATION_TYPES.MAINTAIN_GREATER_THAN_EQUAL:
        case GOAL_AGGREGATION_TYPES.MAINTAIN_LESS_THAN_EQUAL: {
          return joinString([
            ...FIXED_CYCLE_INTERVAL_STRING,
            GOAL_AGG_FUNCTION[goalAggConfig].value,
          ]);
        }
        case GOAL_AGGREGATION_TYPES.AVG_GREATER_THAN_EQUAL:
        case GOAL_AGGREGATION_TYPES.AVG_LESS_THAN_EQUAL: {
          return joinString([
            ...FIXED_CYCLE_INTERVAL_STRING,
            AVERAGE_COLUMN_HEADING[goalAggConfig].desktopHeading,
          ]);
        }
        default: {
          return null;
        }
      }
    }
    case "GoalAchieved": {
      return joinString([...FIXED_CYCLE_INTERVAL_STRING, "Achieved value"]);
    }
    case "Achievement": {
      return joinString([...FIXED_CYCLE_INTERVAL_STRING, "Achieved  %"]);
    }
    default:
      return Header;
  }
};

export const getHeader = ({ columns, recurrence, index, goalAggConfig }) =>
  columns.map(({ accessor, Header }) => ({
    header: columnFormatter({
      accessor,
      Header,
      recurrence,
      index,
      goalAggConfig,
    }),
    key: accessor,
    width: 30,
  }));

export const getRow = ({ rowData, isIndianTimezone, userId }) => {
  const isGoalOwner = rowData?.UserID === userId;
  const achievementData = isGoalOwner
    ? rowData.GoalAchieved
    : rowData?.RolledUpAchievement
    ? rowData.RolledUpAchievement
    : rowData.GoalAchieved;
  return {
    ...rowData,
    CreatedOn: rowData?.CreatedOn ? rowData.CreatedOn?.split("T")[0] : "",
    GoalDistribution:
      rowData?.GoalDistribution !== null
        ? Number(rowData.GoalDistribution)
        : "No goal value set",
    GoalAchieved:
      rowData?.GoalDistribution !== null
        ? Number(achievementData)
        : "No goal value set",
    Achievement:
      rowData?.GoalDistribution === null
        ? "No goal value set"
        : rowData?.GoalDistribution === 0
        ? "Goal value set to 0"
        : `${(
            ((achievementData || 0) / rowData?.GoalDistribution) *
            100
          ).toFixed(2)}%`,
  };
};

export const applyStyles = (worksheet, styles) => {
  Object.keys(styles).forEach((style) => {
    worksheet[style] = styles[style];
  });
  return worksheet;
};

export const GLOBAL_STYLES = {
  border: {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  },
};

export const HEADER_STYLES = {
  font: {
    bold: true,
  },
  alignment: {
    vertical: "middle",
    wrapText: true,
  },
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "e5f6ff" },
  },
};

export const ALIGN_LEFT = {
  alignment: {
    horizontal: "left",
    vertical: "middle",
  },
};
