import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_NON_MONETARY_REWARDS_LIST = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query nonMonetaryRewardsList(
    $Query: RewardsListQuery
    $Before: String
    $After: String
    $Limit: Float
  ) {
    nonMonetaryRewardsList(
      Query: $Query
      Before: $Before
      After: $After
      Limit: $Limit
    ) {
      ... on NonMonetaryRewardsListResponse {
        response {
          rewardsList {
            RewardId
            RewardName
            Description
            RewardCategory
            RewardValue
            CustomRewardImageUrl
            RewardLink
            RewardsCount
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
          __typename
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetNonMonetaryRewardsList = (queryOptions) => {
  return useQuery(GET_NON_MONETARY_REWARDS_LIST, queryOptions);
};
