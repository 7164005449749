import { isEqual } from "src/helpers";

export const isActiveFilter = ({ filtersState, filter }) => {
  if (filter.filters) {
    return filter.filters.some((filter) => {
      return !isEqual(filtersState[filter.key], filter.defaultValue);
    });
  }

  return !isEqual(filtersState[filter.key], filter.defaultValue);
};
