import React from "react";
import { Image } from "src/components/common";
import LinkedProgramCardSelectedIconImg from "./LinkedProgramCardSelectedIcon.jpg";

const LinkedProgramCardSelectedIcon = (props) => (
  <Image
    src={LinkedProgramCardSelectedIconImg}
    alt="LinkedProgramCardSelectedIconImg"
    {...props}
  />
);

export default LinkedProgramCardSelectedIcon;
