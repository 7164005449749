import { gql, useQuery } from "src/api/client";

// FeatureType -> either LEADERBOARD or INCENTIVE

const GET_TOTAL_PENDING_PAYOUT = gql`
  query totalPendingPayout(
    $recurrencePeriod: [String]
    $timeFrequency: TimeFrequency
    $FeatureType: String!
  ) {
    totalPendingPayout(
      recurrencePeriod: $recurrencePeriod
      timeFrequency: $timeFrequency
      FeatureType: $FeatureType
    ) {
      ... on TotalPendingPayoutResponse {
        success
        message
        response {
          totalPendingPayout
          totalPendingPayoutRewards {
            RewardCount
            RewardCategory
          }
        }
      }
    }
  }
`;

export const useGetTotalPendingPayout = (queryOptions) => {
  return useQuery(GET_TOTAL_PENDING_PAYOUT, queryOptions);
};
