import PropTypes from "prop-types";
import * as RecurrenceStatusIcons from "src/icons/recurrenceStatus";

const VARIANTS = {
  large: "Lg",
  small: "Sm",
};

const RECURRENCE_STATUS_MAP = {
  YETTOSTART: "CycleYetToStart",
  INPROGRESS: "CycleInProgress",
  COMPLETED: "CycleCompleted",
  BUFFERDAYS: "CycleBufferDays",
};

const RecurrenceStatus = ({
  recurrenceStatus = "COMPLETED",
  variant = "large",
  iconProps,
}) => {
  const RecurrenceIcon =
    RecurrenceStatusIcons[
      `${RECURRENCE_STATUS_MAP[recurrenceStatus]}${VARIANTS[variant]}`
    ];

  return <RecurrenceIcon {...iconProps} />;
};

RecurrenceStatus.propTypes = {
  recurrenceStatus: PropTypes.oneOf(Object.keys(RECURRENCE_STATUS_MAP))
    .isRequired,
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
  iconProps: PropTypes.shape({}),
};

RecurrenceStatus.defaultProps = {
  variant: "large",
  iconProps: PropTypes.shape({}),
};

export default RecurrenceStatus;
