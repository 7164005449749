import { gql, useQuery } from "src/api/client";

const GET_DASHBOARD_PROGRAM_USER_ACHIEVEMENTS = gql`
  query dashboardProgramUserAchievements(
    $UserId: ID
    $ProgramId: ID
    $RecurrenceId: ID
    $limit: Float
    $after: String
    $query: PayoutUsersQuery
  ) {
    dashboardProgramUserAchievements(
      UserId: $UserId
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      limit: $limit
      after: $after
      query: $query
    ) {
      ... on DashboardUserAchievementResponse {
        success
        message
        response {
          showDrilldown
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
          dashboardUserAchievements {
            UserID
            FullName
            EmailAddress
            Templates
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetDashboardProgramUserAchievements = (queryOptions) => {
  return useQuery(GET_DASHBOARD_PROGRAM_USER_ACHIEVEMENTS, queryOptions);
};
