import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'rulesets.web.rulesetCrudPage' */ "./web")
);

const MobilePage = lazy(() =>
  import(/* webpackChunkName: 'rulesets.mobile.rulesetCrudPage' */ "./mobile")
);

export const RulesetCrudPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "View Ruleset",
  },
};
