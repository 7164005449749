import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgLock = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l633__a">
          <path fill={color} d="M0 0h16v16H0Z" data-name="Path 643" />
        </clipPath>
      </defs>
      <g clipPath="url(#l633__a)">
        <path
          fill={color}
          d="M8 11.334A1.333 1.333 0 1 0 6.667 10 1.337 1.337 0 0 0 8 11.334Zm4-6h-.667V4a3.334 3.334 0 0 0-6.667 0v1.334H4a1.337 1.337 0 0 0-1.333 1.333v6.667A1.337 1.337 0 0 0 4 14.667h8a1.337 1.337 0 0 0 1.333-1.333V6.667A1.337 1.337 0 0 0 12 5.334ZM5.934 4a2.067 2.067 0 0 1 4.133 0v1.334H5.934Zm6.067 9.333h-8V6.667h8Z"
          data-name="Path 642"
        />
      </g>
    </svg>
  );
};

SvgLock.defaultProps = {
  color: COLORS.GREY_2,
  height: 16,
  width: 16,
};
export default SvgLock;
