import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgOverviewStatus = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M.804 2.814h2.412v5.33a3.216 3.216 0 0 0 .804 6.327c.197 0 .393-.02.587-.056.112.037.23.056.35.056.871 0 .871-.804 1.74-.804.868 0 .872.804 1.74.804s.872-.804 1.745-.804c.872 0 .868.804 1.74.804.627 0 .804-.402 1.178-.647a1.206 1.206 0 0 0 .567-1.025V2.009A2.01 2.01 0 0 0 11.657 0H2.01A2.01 2.01 0 0 0 0 2.01a.804.804 0 0 0 .804.804Zm.804 8.441A2.412 2.412 0 0 1 3.59 8.883h.052c.124-.023.25-.037.378-.04a2.412 2.412 0 1 1-2.412 2.412ZM11.657.804a1.206 1.206 0 0 1 1.206 1.206v10.789a.4.4 0 0 1-.19.341c-.15.1-.291.212-.421.338-.201.18-.217.189-.33.189-.112 0-.128 0-.325-.19a1.942 1.942 0 0 0-2.814 0c-.197.182-.217.19-.326.19-.108 0-.124 0-.321-.19-.35-.368-.827-.588-1.335-.614.126-.224.226-.461.298-.708l.024-.096c.024-.094.043-.19.056-.285a.948.948 0 0 0 0-.113 3.12 3.12 0 0 0 .056-.406c0-.414-.08-.823-.237-1.206h4.659a.402.402 0 0 0 0-.804h-5.04a.425.425 0 0 0-.077 0 3.264 3.264 0 0 0-.965-.804h6.082a.402.402 0 1 0 0-.804H5.226a.402.402 0 0 0-.402.402c.001.039.01.077.024.113a3.144 3.144 0 0 0-.828-.113V2.01c0-.416-.092-.828-.27-1.205h7.907Zm-9.96.04h.084a1.23 1.23 0 0 1 .229-.04c.319 0 .625.125.852.35.166.17.28.383.33.615.012.08.02.16.024.24H.804A1.206 1.206 0 0 1 1.696.845Z"
      />
    </svg>
  );
};
SvgOverviewStatus.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 15,
  width: 14,
};
export default SvgOverviewStatus;
