import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT, PAGE_INFO_FRAGMENT } from "src/api/fragments";
import { useIsOpenAceUser } from "src/hooks";

const GET_USER_DETAILS_QUERY = gql`
  query getUsersDetails($UserIds: [ID], $userAttributesNames: [String]) {
    getUsersDetails(
      UserIds: $UserIds
      userAttributesNames: $userAttributesNames
    ) {
      node
    }
  }
`;

export const useGetUserDetails = (queryOptions) => {
  return useQuery(GET_USER_DETAILS_QUERY, queryOptions);
};

const GET_ATTRIBUTE_VALUES = gql`
  query getAttributeValues($Attribute: String) {
    getAttributeValues(Attribute: $Attribute)
  }
`;

export const useGetAttributeValues = (queryOptions) => {
  return useQuery(GET_ATTRIBUTE_VALUES, queryOptions);
};

const GET_USER_ATTRIBUTES_QUERY = gql`
  query getUserAttributesData {
    getUserAttributesData {
      TotalUserAttributes
      UserAttributes {
        DisplayName
        SchemaName
        DataType
      }
    }
  }
`;
const GET_OACE_USER_ATTRIBUTES_QUERY = gql`
  query getUserBaseData {
    getUserBaseData {
      UserAttributes {
        DisplayName
        DataType
        SchemaName
      }
    }
  }
`;

export const useGetUserAttributes = (queryOptions) => {
  const { isOpenAceUser } = useIsOpenAceUser();

  const query = isOpenAceUser
    ? GET_OACE_USER_ATTRIBUTES_QUERY
    : GET_USER_ATTRIBUTES_QUERY;

  const queryResult = useQuery(query, {
    ...queryOptions,
    skip: queryOptions?.skip,
  });

  const apiName = isOpenAceUser ? "getUserBaseData" : "getUserAttributesData";

  return {
    ...queryResult,
    data: {
      ...queryResult?.data,
      getUserAttributesData: queryResult?.data?.[apiName],
    },
  };
};

export const useGetUserBaseData = (queryOptions) => {
  return useQuery(GET_OACE_USER_ATTRIBUTES_QUERY, queryOptions);
};

const GET_MANAGING_USERS_QUERY = gql`
  query getManagingUsers($userAttributesNames: [String]) {
    getManagingUsers(userAttributesNames: $userAttributesNames) {
      node
    }
  }
`;
export const useGetManagingUser = (queryOptions) => {
  return useQuery(GET_MANAGING_USERS_QUERY, queryOptions);
};

// src/modules/goalCrud/distributeGoal/DistributeGoal.js
export const GET_RECURRENCE_USERS = gql`
  ${PAGE_INFO_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query getRecurrenceUsers(
    $query: JSON
    $Before: String
    $After: String
    $Limit: Float
    $RecurrenceId: ID
    $UserAttributes: [String]
    $ManagerUserId: ID
  ) {
    getRecurrenceUsers(
      Before: $Before
      After: $After
      Limit: $Limit
      RecurrenceId: $RecurrenceId
      UserAttributes: $UserAttributes
      query: $query
      ManagerUserId: $ManagerUserId
    ) {
      ... on RecurrenceUsersResponse {
        response {
          UsersDetails {
            node
          }
          pageInfo {
            ...pageInfoFragment
          }
          QueryCount
          OtherUsersCount
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetRecurrenceUsers = (queryOptions) => {
  return useQuery(GET_RECURRENCE_USERS, queryOptions);
};
