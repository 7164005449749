import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const VALIDATE_GROUPS_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation validateGroups($LeaderboardId: ID!, $RecurrenceId: ID!) {
    validateGroups(LeaderboardId: $LeaderboardId, RecurrenceId: $RecurrenceId) {
      ... on ValidateGroupsResponse {
        success
        message
        response
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useValidateGroups = () => {
  return useMutation(VALIDATE_GROUPS_MUTATION);
};
