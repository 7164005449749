import PropTypes from "prop-types";
import React from "react";
import "./bannerCard.scss";

const BannerCard = (props) => {
  const { header, body, footer } = props;
  return (
    <div className="banner-card d-flex flex-column">
      <div className="banner-card-header">{header}</div>
      <div className="banner-card-list h-100">{body}</div>
      <div className="banner-card-footer mt-auto">{footer}</div>
    </div>
  );
};

BannerCard.propTypes = {
  headerIcon: PropTypes.node,
  title: PropTypes.string,
  endTitle: PropTypes.string,
};

export default BannerCard;
