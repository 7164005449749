import React from "react";
import { LEADERBOARD_BADGES } from "src/constants/leaderboardBadges";
import { classNames } from "src/helpers";
import "./trophyCount.scss";

const getRank = (rank) => {
  if (rank === 1) return "GOLD";
  if (rank === 2) return "SILVER";
  if (rank === 3) return "BRONZE";
  return "GENERIC";
};

const TrophyCount = (props) => {
  const { count, recurrencePeriod = "DAILY", rank, variant = "normal" } = props;

  const Component =
    LEADERBOARD_BADGES[recurrencePeriod.toUpperCase()].UNRANKED[
      getRank(parseInt(rank))
    ];

  return (
    <span className={classNames(["leaderboard-trophy", `${variant}-type`])}>
      <Component height="34px" />
      <span className={classNames(["count"])}>X {count}</span>
    </span>
  );
};

export default TrophyCount;
