import { gql, useQuery } from "src/api/client";

const GET_PAYOUT_REWARD_REPORT = gql`
  query payoutRewardReport(
    $RewardId: ID!
    $after: String
    $limit: Float
    $query: JSON
  ) {
    payoutRewardReport(
      RewardId: $RewardId
      after: $after
      limit: $limit
      query: $query
    ) {
      ... on PayoutRewardReportResponse {
        success
        message
        response {
          pageInfo {
            hasNextPage
            endCursor
            startCursor
          }
          totalCount
          programs {
            ProgramId
            RecurrenceId
            UserId
            RecurrenceStartDate
            RecurrenceEndDate
            ProgramName
            RewardName
            EmailAddress
            FullName
            FeatureType
            GivenDate
          }
        }
        __typename
      }
      ... on ErrorResponse {
        message
      }
      __typename
    }
  }
`;

export const useGetPayoutRewardReport = (queryOptions) => {
  return useQuery(GET_PAYOUT_REWARD_REPORT, queryOptions);
};
