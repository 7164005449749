import { gql, useQuery } from "src/api/client";

const GET_GOAL_BASED_INCENTIVE_USERS = gql`
  query getGoalBasedIncentiveUsers(
    $ProgramId: ID!
    $userAttributesNames: [String]!
    $Limit: Float
    $After: Float
    $Search: String
  ) {
    getGoalBasedIncentiveUsers(
      ProgramId: $ProgramId
      userAttributesNames: $userAttributesNames
      Limit: $Limit
      After: $After
      Search: $Search
    ) {
      ... on goalBasedUsersResponse {
        success
        message
        response {
          Users
          After
          TotalUsers
          NextPageExists
          __typename
        }
      }
      __typename
    }
  }
`;

export const useGetGoalBasedIncentiveUsers = (queryOptions) => {
  return useQuery(GET_GOAL_BASED_INCENTIVE_USERS, queryOptions);
};
