import { classNames } from "src/helpers";

function TagsSelectorInput({ className, disabled, children }) {
  return (
    <div
      className={classNames([
        "p-2 br-4 formula-text-area center flex-wrap",
        disabled ? "cursor-not-allowed" : "",
        className,
      ])}
    >
      {children}
    </div>
  );
}

export default TagsSelectorInput;
