import { gql, useMutation } from "src/api/client";

// src/components/templates/create/CreateGoalTemplateModal.js -done
// src/modules/templates/rules/goalRuleModal/AddGoalRuleModal.js

export const UPDATE_TEMPLATE_MUTATION = gql`
  mutation UpdateTemplate(
    $TemplateId: ID!
    $Name: NotEmpty
    $Description: String
    $Status: StatusEnum
    $AuditType: AuditTypeEnum
    $IsCustomFormula: Boolean
    $Formula: String
    $DrilldownStatus: DrillDownStatusEnum
  ) {
    updateTemplate(
      Name: $Name
      Description: $Description
      TemplateId: $TemplateId
      Status: $Status
      AuditType: $AuditType
      IsCustomFormula: $IsCustomFormula
      Formula: $Formula
      DrilldownStatus: $DrilldownStatus
    ) {
      ... on SingleTemplateResponse {
        response {
          Template {
            Name
            Description
            Status
            AuditType
            DrilldownStatus
          }
        }
        success
        message
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useUpdateTemplate = () => {
  return useMutation(UPDATE_TEMPLATE_MUTATION);
};
