import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { FormButton, FormInput } from "src/components";
import {
  MAXIMUM_INCENTIVE_CONDITION_VALUE,
  MINIMUM_INCENTIVE_CONDITION_VALUE,
} from "src/constants";
import { TOKEN_TYPE, formulaNamespace } from "../formulaParser";
import { RenderBreadcrumb, RenderCombinedList } from "./data";

const { OPERATOR_TOKEN_TYPE, FORMULA_OPERATORS, FORMULA_OPERATOR_TYPE } =
  formulaNamespace;

const data = [
  {
    groupLabel: "Paranthesis",
    groupValue: OPERATOR_TOKEN_TYPE.PARENTHESIS,
    options: [
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.OPEN_PAREN].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.OPEN_PAREN].value,
        value: FORMULA_OPERATOR_TYPE.OPEN_PAREN,
      },
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.CLOSE_PAREN].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.CLOSE_PAREN].value,
        value: FORMULA_OPERATOR_TYPE.CLOSE_PAREN,
      },
    ],
  },
  {
    groupLabel: "Mathematical Operators",
    groupValue: OPERATOR_TOKEN_TYPE.MATHEMATICAL_OPERATOR,
    options: [
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.PLUS].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.PLUS].value,
        value: FORMULA_OPERATOR_TYPE.PLUS,
      },
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.MINUS].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.MINUS].value,
        value: FORMULA_OPERATOR_TYPE.MINUS,
      },
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.MULTIPLY].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.MULTIPLY].value,
        value: FORMULA_OPERATOR_TYPE.MULTIPLY,
      },
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.DIVIDE].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.DIVIDE].value,
        value: FORMULA_OPERATOR_TYPE.DIVIDE,
      },
    ],
  },
  {
    groupLabel: "Comparison Operators",
    groupValue: OPERATOR_TOKEN_TYPE.COMPARISON_OPERATOR,
    options: [
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.EQUAL].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.EQUAL].value,
        value: FORMULA_OPERATOR_TYPE.EQUAL,
      },
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.NOT_EQUAL].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.NOT_EQUAL].value,
        value: FORMULA_OPERATOR_TYPE.NOT_EQUAL,
      },
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.GREATER_THAN].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.GREATER_THAN].value,
        value: FORMULA_OPERATOR_TYPE.GREATER_THAN,
      },
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.LESS_THAN].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.LESS_THAN].value,
        value: FORMULA_OPERATOR_TYPE.LESS_THAN,
      },
      {
        label:
          FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL]
          .value,
        value: FORMULA_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
      },
      {
        label:
          FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.LESS_THAN_OR_EQUAL].label,
        icon: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.LESS_THAN_OR_EQUAL].value,
        value: FORMULA_OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
      },
    ],
  },
  {
    groupLabel: "Functions",
    groupValue: OPERATOR_TOKEN_TYPE.FUNCTION,
    options: [
      {
        label: FORMULA_OPERATORS[FORMULA_OPERATOR_TYPE.BETWEEN].label,
        value: FORMULA_OPERATOR_TYPE.BETWEEN,
        hasFutherSteps: true,
      },
    ],
  },
];

const Step1 = ({
  tokenType,
  setStep,
  handleClose,
  setBreadcrumbList,
  handleClick,
}) => {
  const handleOnClick = ({ option, groupValue }) => {
    if (option?.value === FORMULA_OPERATOR_TYPE.BETWEEN) {
      handleClick({
        tokenType,
        operatorTokenType: OPERATOR_TOKEN_TYPE.FUNCTION,
        value: FORMULA_OPERATOR_TYPE.BETWEEN,
      });
      setStep("STEP_2");
      setBreadcrumbList([
        { ...option, label: option?.label, key: "STEP_1" },
        { label: "Values" },
      ]);
    } else {
      handleClick({
        tokenType,
        operatorTokenType: groupValue,
        value: option?.value,
      });
      handleClose();
    }
  };
  return <RenderCombinedList {...{ data, handleOnClick, t }} />;
};

const Step2 = ({ tokenType, handleClick, handleClose }) => {
  const [startsAt, setStartsAt] = useState("");
  const [endsAt, setEndsAt] = useState("");

  const handleOnClick = () => {
    handleClick({
      tokenType: TOKEN_TYPE.STATIC,
      value: `${startsAt} AND ${endsAt}`,
      fieldName: FORMULA_OPERATOR_TYPE.BETWEEN,
    });
    handleClose();
  };

  const validateValue = (value) => {
    const startsAt = parseInt(value.startsAt);
    const endsAt = parseInt(value.endsAt);

    const validStart =
      MINIMUM_INCENTIVE_CONDITION_VALUE < startsAt &&
      startsAt < MAXIMUM_INCENTIVE_CONDITION_VALUE;
    const validEnd =
      MINIMUM_INCENTIVE_CONDITION_VALUE < endsAt &&
      endsAt < MAXIMUM_INCENTIVE_CONDITION_VALUE;
    const validRange = startsAt < endsAt;

    return validStart && validEnd && validRange;
  };

  return (
    <div className="p-3">
      <div className="flex-between space-x-16">
        <FormInput
          name="startsAt"
          type="number"
          placeholder="Enter Value"
          onChange={(e) => setStartsAt(e.target.value)}
          value={startsAt}
        />
        <FormInput
          name="endsAt"
          type="number"
          placeholder="Enter Value"
          onChange={(e) => setEndsAt(e.target.value)}
          value={endsAt}
        />
      </div>
      <div className="center justify-content-end mt-3">
        <FormButton
          onClick={handleOnClick}
          disabled={!validateValue({ startsAt, endsAt })}
        >
          Submit
        </FormButton>
      </div>
    </div>
  );
};

const STEPS = {
  STEP_1: {
    label: "Program Selection",
    key: "STEP_1",
    Component: Step1,
  },
  STEP_2: {
    label: "Values",
    key: "STEP_2",
    Component: Step2,
  },
};

const FormulaMenuOperator = ({
  handleClick,
  handleClose,
  tokenType,
  setTokenType,
}) => {
  const [breadcrumbList, setBreadcrumbList] = useState([]);
  const [step, setStep] = useState("STEP_1");
  const Component = useMemo(() => STEPS[step].Component, [step]);
  const handleBreadcrumbClick = (key, idx) => {
    setStep(key);
    if (idx === 0) setBreadcrumbList([]);
    else {
      setBreadcrumbList((prev) => prev.slice(0, idx + 1));
    }
  };

  return (
    <>
      <RenderBreadcrumb
        breadcrumbList={breadcrumbList}
        onClick={handleBreadcrumbClick}
      />
      <Component
        {...{
          setStep,
          breadcrumbList,
          setBreadcrumbList,
          handleClick,
          tokenType,
          setTokenType,
          handleClose,
        }}
      />
    </>
  );
};

export default FormulaMenuOperator;
