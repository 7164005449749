import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgCycle = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="re33__a">
          <path d="M0 0h26v26H0z" />
        </clipPath>
      </defs>
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        clipPath="url(#re33__a)"
        data-name="Goal cycle"
      >
        <path
          strokeMiterlimit={10}
          d="M1.5 4h23v5h-23z"
          data-name="Rectangle 2400"
        />
        <path strokeMiterlimit={10} d="M8.5 1v3" data-name="Line 220" />
        <path strokeMiterlimit={10} d="M18.5 1v3" data-name="Line 221" />
        <path
          strokeMiterlimit={10}
          d="M24.5 9.387h-23v15.612h15.06l7.94-7.712v-7.9Z"
          data-name="Path 908"
        />
        <path d="M17.112 17.193H24.5l-7.388 7.806Z" data-name="Path 909" />
      </g>
    </svg>
  );
};

SvgCycle.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 26,
  width: 26,
};
export default SvgCycle;
