import { gql, useQuery } from "src/api/client";

const GET_MY_SHOWCASE_LIST_DETAILS = gql`
  query getMyShowcaseListDetails(
    $limit: Float
    $after: String
    $UserId: ID
    $ProgramId: ID
    $Rank: JSON
    $CycleType: String
  ) {
    getMyShowcaseListDetails(
      limit: $limit
      after: $after
      UserId: $UserId
      ProgramId: $ProgramId
      Rank: $Rank
      CycleType: $CycleType
    ) {
      ... on MyShowcaseListDetailsResponse {
        response {
          myShowcaseList
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            __typename
          }
        }
      }
    }
  }
`;

export const useGetMyShowcaseListDetails = (queryOptions) => {
  return useQuery(GET_MY_SHOWCASE_LIST_DETAILS, queryOptions);
};
