import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const COPY_LEADERBOARD_RECURRENCE_RULE_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation copyLeaderboardRecurrenceRule(
    $LeaderboardId: ID!
    $SourceRecurrenceId: ID!
    $DestinationRecurrenceIdList: [ID]!
    $Ranks: Boolean!
    $TieBreaker: Boolean!
    $WinnerConfiguration: Boolean!
    $GroupTarget: Boolean!
    $mandatoryTarget: Boolean!
    $minTarget: Boolean!
  ) {
    copyLeaderboardRecurrenceRule(
      LeaderboardId: $LeaderboardId
      SourceRecurrenceId: $SourceRecurrenceId
      DestinationRecurrenceIdList: $DestinationRecurrenceIdList
      Ranks: $Ranks
      TieBreaker: $TieBreaker
      WinnerConfiguration: $WinnerConfiguration
      GroupTarget: $GroupTarget
      mandatoryTarget: $mandatoryTarget
      minTarget: $minTarget
    ) {
      ... on CopyLeaderboardRecurrenceRuleResponse {
        success
        message
        response
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useCopyLeaderboardRecurrenceRule = () => {
  return useMutation(COPY_LEADERBOARD_RECURRENCE_RULE_MUTATION);
};
