import PropTypes from "prop-types";
import { classNames } from "src/helpers";
import { SvgTick } from "src/icons";
import { Truncate } from "../../truncate";
import "./styles.scss";

function SelectableCard({
  Icon,
  title,
  description,
  selected,
  value,
  onClick,
  className,
  disabled,
}) {
  const handleClick = () => {
    onClick(value);
  };
  const selectedClassName = selected ? "selected" : "";

  return (
    <button
      type="button"
      className={classNames([
        "selectable-card p-3",
        selectedClassName,
        className,
        disabled ? "disabled" : "",
      ])}
      onClick={handleClick}
    >
      <div className="fs-14 semi-bold fc-black text center">
        {Icon}
        <Truncate id="selectable-card-text" text={title} showTooltip={false} />
      </div>
      {description ? (
        <div className="fs-12 fc-grey desc mt-2">{description}</div>
      ) : null}
      {selected ? <SvgTick className="tick" /> : null}
    </button>
  );
}

SelectableCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  selected: PropTypes.bool,
};

SelectableCard.defaultProps = {
  description: "",
  selected: false,
};

export default SelectableCard;
