import React from "react";
import { classNames } from "src/helpers";
import TagItem from "./TagItem";

function SelectedTags({
  tags,
  renderTag = TagItem,
  removeTag,
  disabled,
  children,
}) {
  return (
    <div
      className={classNames([
        "selected-tags center flex-wrap",
        disabled ? "cursor-not-allowed" : "",
      ])}
    >
      {tags.map((item, index) => {
        return (
          <div key={index} className="mr-2">
            {renderTag({ item, index, removeTag })}
          </div>
        );
      })}
      {children}
    </div>
  );
}

export default SelectedTags;
