import { gql, useMutation } from "src/api/client";

const DOWNLOAD_EXCEL_REPORT = gql`
  mutation downloadGoalReport($GoalId: ID!) {
    downloadGoalReport(GoalId: $GoalId) {
      message
      range
      urlData {
        url
        fileId
      }
    }
  }
`;

export const useDownloadGoalReport = () => {
  return useMutation(DOWNLOAD_EXCEL_REPORT);
};
