import React from "react";
import { useViewport } from "src/hooks";

const OnMobile = ({ children }) => {
  const { isMobile } = useViewport();

  return <>{isMobile ? children : null}</>;
};

export default OnMobile;
