import React from "react";
import { APPS } from "src/constants";
import { Redirect, Route, Switch } from "src/router";
import { useLeaderboardsRbac } from "./hooks";
import { Pages } from "./pages";

export function Routes() {
  const { leaderboardsRbac, DEFAULT_LOGGED_IN_USER_REDIRECTION } =
    useLeaderboardsRbac();

  return (
    <Switch>
      <Route
        path={APPS.LEADERBOARDS.ROUTES.LANDING.path}
        page={Pages.LandingPage}
        nestedRoutes={[
          APPS.LEADERBOARDS.ROUTES.RULESETS.path,
          APPS.LEADERBOARDS.ROUTES.MANAGE_LEADERBOARD.path,
          APPS.LEADERBOARDS.ROUTES.APPROVAL.path,
          APPS.LEADERBOARDS.ROUTES.PAYOUT.path,
          APPS.LEADERBOARDS.ROUTES.DASHBOARD.path,
          APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path,
        ]}
        isPage
        {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.LANDING.path)}
      >
        <div className="page-container">
          <Switch>
            <Route
              path={APPS.LEADERBOARDS.ROUTES.RULESETS.path}
              page={Pages.RulesetsPage}
              exact
              {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.RULESETS.path)}
            />
            <Route
              path={APPS.LEADERBOARDS.ROUTES.MANAGE_LEADERBOARD.path}
              page={Pages.ManageLeaderboardPage}
              exact
              {...leaderboardsRbac(
                APPS.LEADERBOARDS.ROUTES.MANAGE_LEADERBOARD.path
              )}
            />
            <Route
              path={APPS.LEADERBOARDS.ROUTES.APPROVAL.path}
              page={Pages.ApprovalPage}
              exact
              {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.APPROVAL.path)}
            />
            <Route
              path={APPS.LEADERBOARDS.ROUTES.PAYOUT.path}
              page={Pages.PayoutPage}
              exact
              {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.PAYOUT.path)}
            />
            <Route
              path={APPS.LEADERBOARDS.ROUTES.DASHBOARD.path}
              page={Pages.DashboardPage}
              exact
              {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.DASHBOARD.path)}
            />
            <Route
              path={APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path}
              page={Pages.MyBoardsPage}
              exact
              {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path)}
            />
            <Route path="*">
              <Redirect to={DEFAULT_LOGGED_IN_USER_REDIRECTION} />
            </Route>
          </Switch>
        </div>
      </Route>
      <Route
        path={APPS.LEADERBOARDS.ROUTES.LEADERBOARD_CRUD.path}
        page={Pages.LeaderboardCrudPage}
        isPage
        exact
        {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.LEADERBOARD_CRUD.path)}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.PUBLISHED_LEADERBOARDS_VIEW.path}
        page={Pages.PublishedLeaderboardsViewPage}
        isPage
        exact
        {...leaderboardsRbac(
          APPS.LEADERBOARDS.ROUTES.PUBLISHED_LEADERBOARDS_VIEW.path
        )}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.APPROVAL_DETAIL_VIEW.path}
        page={Pages.ApprovalDetailViewPage}
        isPage
        exact
        {...leaderboardsRbac(
          APPS.LEADERBOARDS.ROUTES.APPROVAL_DETAIL_VIEW.path
        )}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.PAYOUT_DETAIL_VIEW.path}
        page={Pages.PayoutDetailViewPage}
        isPage
        exact
        {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.PAYOUT_DETAIL_VIEW.path)}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.MY_BOARDS_DETAIL_VIEW.path}
        page={Pages.MyBoardsDetailViewPage}
        isPage
        exact
        {...leaderboardsRbac(
          APPS.LEADERBOARDS.ROUTES.MY_BOARDS_DETAIL_VIEW.path
        )}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.PAYOUT_REQUEST_PAGE.path}
        page={Pages.PayoutRequestPage}
        isPage
        exact
        {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.PAYOUT_REQUEST_PAGE.path)}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.DASHBOARD_DETAILS_PAGE.path}
        page={Pages.DashboardLeaderboardDetailsPage}
        isPage
        exact
        {...leaderboardsRbac(
          APPS.LEADERBOARDS.ROUTES.DASHBOARD_DETAILS_PAGE.path
        )}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.DASHBOARD_BOARD_DETAIL_VIEW_PAGE.path}
        page={Pages.DashboardBoardDetailViewPage}
        isPage
        exact
        {...leaderboardsRbac(
          APPS.LEADERBOARDS.ROUTES.DASHBOARD_BOARD_DETAIL_VIEW_PAGE.path
        )}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path}
        page={Pages.DashboardUserDetailViewPage}
        isPage
        exact
        {...leaderboardsRbac(
          APPS.LEADERBOARDS.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path
        )}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.APPROVAL_REQUEST_PAGE.path}
        page={Pages.ApprovalRequestPage}
        isPage
        exact
        {...leaderboardsRbac(
          APPS.LEADERBOARDS.ROUTES.APPROVAL_REQUEST_PAGE.path
        )}
      />
      <Route
        path={APPS.LEADERBOARDS.ROUTES.REQUEST_DEMO.path}
        page={Pages.RequestDemoPage}
        isPage
        exact
        {...leaderboardsRbac(APPS.LEADERBOARDS.ROUTES.REQUEST_DEMO.path)}
      />
      <Route path="*">
        <Redirect to={APPS.LEADERBOARDS.ROUTES.LANDING.path} />
      </Route>
    </Switch>
  );
}
