import React from "react";
import { FormButton } from "../../formElements";

const CriteriaListItem = (props) => {
  const { active, icon, title, subtitle, index, onClick } = props;

  const handleClick = () => {
    onClick(index);
  };

  return (
    <FormButton
      className={`p-3 flex-between criteria-list-item w-100 text-start normal ${
        active ? "bg-secondary-blue" : ""
      }`}
      onClick={handleClick}
      variant="link"
    >
      <div>
        <div className={`fs-14 semi-bold ${active ? "fc-primary-blue" : ""}`}>
          {title}
        </div>
        <div className="fs-12 fc-grey1">{subtitle}</div>
      </div>
      {icon && <div>{icon}</div>}
    </FormButton>
  );
};

export default CriteriaListItem;
