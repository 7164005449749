import { PayoutViewListFilters } from "src/apps/incentives/components";
import { SearchBar, Shimmer } from "src/components";
import { NoResults } from "src/images";

function PayoutViewListContainer({
  title,
  totalCount,
  filters,
  selectedFilter,
  handleClick,
  handleSearchChange,
  children,
  loading,
  searchPlaceholder,
  search,
}) {
  return (
    <div style={{ minWidth: 336, display: "flex", flexDirection: "column" }}>
      <div>
        <div className="flex-between py-4 pr-3">
          <div className="fs-16 semi-bold">
            {loading ? (
              <Shimmer width="61px" height="24px" />
            ) : (
              <>
                {title} <span className="fc-grey">({totalCount || 0})</span>
              </>
            )}
          </div>
          <PayoutViewListFilters
            handleClick={handleClick}
            filters={filters}
            selectedFilter={selectedFilter}
          />
        </div>
        <hr />
        <div className="py-3 pr-3">
          <SearchBar
            search={search}
            setSearch={handleSearchChange}
            placeholder={searchPlaceholder}
          />
        </div>
        <hr />
      </div>
      {!loading && !totalCount ? (
        <div className="flex-column-center h-100">
          <NoResults />
          <div className="fs-14 fc-grey mt-4 text-center">
            No results available for the <br /> selected filter
          </div>
        </div>
      ) : null}
      {children}
    </div>
  );
}

export default PayoutViewListContainer;
