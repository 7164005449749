import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { classNames } from "src/helpers";
import { useToggle } from "src/hooks";
import "./formField.scss";

const FormField = (props) => {
  const {
    error,
    errorText,
    className,
    startAdornment,
    startAdornmentClassName,
    endAdornment,
    endAdornmentClassName,
    onFocus,
    onBlur,
    ...formControlProps
  } = props;

  const [focus, toggleFocus] = useToggle();

  const handleFocus = () => {
    toggleFocus();
    onFocus && onFocus();
  };

  const handleBlur = () => {
    toggleFocus();
    onBlur && onBlur();
  };

  return (
    <>
      <div
        className={classNames([
          "form-field center",
          className || "",
          focus && "focus",
          error && "error",
        ])}
      >
        {startAdornment ? (
          <div
            className={classNames([
              "start-adornment fc-grey",
              startAdornmentClassName || "",
            ])}
          >
            {startAdornment}
          </div>
        ) : null}
        <Form.Control
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...formControlProps}
        />
        {endAdornment ? (
          <div
            className={classNames([
              "end-adornment fc-grey",
              endAdornmentClassName || "",
            ])}
          >
            {endAdornment}
          </div>
        ) : null}
      </div>
      {error && <div className="fs-12 text-danger">{errorText}</div>}
    </>
  );
};

FormField.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
  className: PropTypes.string,
  startAdornment: PropTypes.node,
  startAdornmentClassName: PropTypes.string,
  endAdornment: PropTypes.node,
  endAdornmentClassName: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default FormField;
