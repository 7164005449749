import PropTypes from "prop-types";
import React, { useLayoutEffect, useState } from "react";
import { FormTooltip } from "src/components/common/formElements";
import "./truncate.scss";

const Truncate = (props) => {
  const {
    text,
    id,
    width,
    maxWidth,
    noOfLine,
    truncPattern,
    truncType,
    tooltipPlacement,
    showTooltip,
    as,
  } = props;

  const [isTruncated, setIsTruncated] = useState(false);
  const [node, setNode] = useState(null);
  const Component = as;
  useLayoutEffect(() => {
    if (node !== null) {
      const parent = node.parentNode;
      const child = node.childNodes[0];
      const txtToEllipse = child;
      if (node && parent && child && txtToEllipse) {
        ellipse(node, child, txtToEllipse, noOfLine, truncPattern, truncType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, text]);

  const ellipse = (
    parentNode,
    childNode,
    txtNode,
    noOfLine,
    truncPattern,
    truncType
  ) => {
    const gutterWidth = 2;
    const childWidth = childNode.offsetWidth;
    let containerWidth = parentNode.offsetWidth + gutterWidth;
    const txtWidth = txtNode.offsetWidth;
    const targetWidth = childWidth > txtWidth ? childWidth : txtWidth;
    if (noOfLine) {
      containerWidth *= noOfLine;
      parentNode.style.wordBreak = "break-word";
      txtNode.style.whiteSpace = "initial";
    }
    txtNode.setAttribute("data-original", txtNode.textContent);
    if (targetWidth > containerWidth) {
      const str = txtNode.textContent.replace(/\s+/g, " ");
      const txtChars = str.length;
      const ellipsisChars = truncPattern.length;
      setIsTruncated(true);
      if (truncType === "middle") {
        const avgLetterSize = txtWidth / txtChars;
        const canFit = containerWidth / avgLetterSize;
        const truncChars = txtChars + ellipsisChars - canFit + gutterWidth;
        const delEachSide = Math.ceil(truncChars !== 0 ? truncChars / 2 : 0);
        const endLeft = Math.floor(txtChars / 2 - delEachSide);
        const startRight = Math.ceil(txtChars / 2 + delEachSide);
        txtNode.textContent =
          str.substr(0, endLeft) + truncPattern + str.substr(startRight);
      } else if (truncType === "end") {
        const maxChars = Math.floor(
          (containerWidth / (targetWidth + ellipsisChars)) * txtChars
        );
        const maxCharsWithEllipsis = maxChars;
        txtNode.textContent =
          str.substring(0, maxCharsWithEllipsis) + truncPattern;
      }
    }
  };

  return (
    <FormTooltip
      id={id}
      text={text}
      placement={tooltipPlacement}
      disable={!(isTruncated && showTooltip)}
    >
      <Component
        ref={setNode}
        style={{
          ...(width && { width }),
          ...(maxWidth && { maxWidth }),
        }}
        className="truncate-container"
        id={id}
      >
        <span style={{ whiteSpace: "nowrap" }}>{text}</span>
      </Component>
    </FormTooltip>
  );
};

Truncate.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  noOfLine: PropTypes.number,
  truncPattern: PropTypes.string,
  truncType: PropTypes.oneOf(["middle", "end"]),
  tooltipPlacement: PropTypes.string,
  showTooltip: PropTypes.bool,
  as: PropTypes.string,
};

Truncate.defaultProps = {
  truncPattern: "...",
  truncType: "middle",
  tooltipPlacement: "bottom",
  showTooltip: true,
  as: "div",
};

export default Truncate;
