import React from "react";

const SvgPartiallyApproved = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="lsr3__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#lsr3__a)" data-name="Partially Approved">
        <g data-name="Group 12979">
          <g data-name="Group 12816">
            <g
              fill={backgroundColor}
              stroke={color}
              strokeDasharray={4}
              strokeLinecap="round"
              strokeWidth={2}
              data-name="Ellipse 193"
            >
              <circle cx={12} cy={12} r={12} stroke="none" />
              <circle cx={12} cy={12} r={11} fill="none" />
            </g>
          </g>
          <g fill={color} data-name="Group 3570">
            <path
              d="M9.943 16.556 6.297 12.91a.154.154 0 0 1 0-.223l1.353-1.351a.154.154 0 0 1 .223 0l2.177 2.183 6.075-6.075a.154.154 0 0 1 .223 0l1.356 1.352a.154.154 0 0 1 0 .223l-7.538 7.537a.154.154 0 0 1-.223 0Z"
              data-name="Path 580"
            />
            <path
              d="M9.943 16.556 6.297 12.91a.154.154 0 0 1 0-.223l1.353-1.351a.154.154 0 0 1 .223 0l2.177 2.183 6.075-6.075a.154.154 0 0 1 .223 0l1.356 1.352a.154.154 0 0 1 0 .223l-7.538 7.537a.154.154 0 0 1-.223 0Z"
              data-name="Path 7922"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgPartiallyApproved.defaultProps = {
  backgroundColor: "#effacc",
  color: "#a29c00",
  height: 24,
  width: 24,
};
export default SvgPartiallyApproved;
