import { RotatingWords } from "src/components/common/atoms";

function FormulaPlaceholder() {
  return (
    <div
      className="fc-grey1 center"
      style={{
        position: "absolute",
        zIndex: 0,
        top: 10,
        left: 10,
      }}
    >
      <span className="mr-2">Select</span>
      <RotatingWords words={["metrics", "fields", "operators"]} />
    </div>
  );
}

export default FormulaPlaceholder;
