import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { REGEX_FOR_NUMBER_CHECK } from "src/constants";
import { classNames } from "src/helpers";
import { FormButton } from "../formButton";
import { FormTooltip } from "../tooltips";
import "./formNumberInput.scss";

const FormNumberInput = (props) => {
  const {
    value,
    min,
    max,
    error,
    disabled,
    disabledClassName,
    disabledTooltipProps,
    handleViewOnlyModeClick,
    handleChange,
    handleBlur,
    beforeInputClassName,
    beforeInputText,
    beforeInputProps,
    afterInputClassName,
    afterInputText,
    afterInputProps,
    placeholder,
    className,
    wrapperClassName,
  } = props;

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={classNames(["center", wrapperClassName])}>
      {beforeInputText && (
        <span
          className={classNames(["fs-12 semi-bold ml-3", beforeInputClassName])}
          {...beforeInputProps}
        >
          {beforeInputText}
        </span>
      )}
      {disabled ? (
        <FormTooltip {...disabledTooltipProps}>
          <FormButton
            className={classNames([
              "form-control duration-input bg-grey5 p-2 text-start",
              disabledClassName,
            ])}
            onClick={!disabled ? () => handleViewOnlyModeClick() : undefined}
            variant="link"
          >
            <span className="fc-black">{inputValue}</span>
          </FormButton>
        </FormTooltip>
      ) : (
        <input
          className={classNames([
            "form-control duration-input ",
            error && "error",
            classNames && className,
          ])}
          value={inputValue}
          type="number"
          min={min}
          max={max}
          placeholder={placeholder}
          onChange={(e) => {
            const value = e.target.valueAsNumber;
            if (
              Number.isNaN(value) ||
              (REGEX_FOR_NUMBER_CHECK.test(value) &&
                value >= min &&
                value <= max)
            ) {
              setInputValue(value);
              if (handleChange) handleChange(e.target.valueAsNumber);
            }
          }}
          onBlur={(e) => {
            if (handleBlur) {
              setInputValue(value);
              handleBlur(e.target.valueAsNumber);
            }
          }}
        />
      )}
      {afterInputText && (
        <span
          className={classNames(["fs-12 semi-bold ml-3", afterInputClassName])}
          {...afterInputProps}
        >
          {afterInputText}
        </span>
      )}
    </div>
  );
};

FormNumberInput.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledClassName: PropTypes.string,
  disabledTooltipProps: PropTypes.object,
  handleViewOnlyModeClick: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  beforeInputClassName: PropTypes.string,
  beforeInputText: PropTypes.string,
  beforeInputProps: PropTypes.object,
  afterInputClassName: PropTypes.string,
  afterInputText: PropTypes.string,
  afterInputProps: PropTypes.object,
};

FormNumberInput.defaultProps = {
  value: 0,
  min: 0,
  error: false,
  disabled: false,
  disabledClassName: "",
  disabledTooltipProps: {},
  handleViewOnlyModeClick: () => {},
  handleChange: () => {},
  handleBlur: () => {},
  beforeInputClassName: "",
  beforeInputText: "",
  beforeInputProps: {},
  afterInputClassName: "",
  afterInputText: "",
  afterInputProps: {},
};

export default FormNumberInput;
