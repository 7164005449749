import { t } from "i18next";
import { useEffect, useState } from "react";
import FileInput from "src/components/common/atoms/filePicker/FileInput";
import { useImagePicker } from "src/components/common/atoms/filePicker/useImagePicker";
import { SvgUpload } from "src/icons";
import * as FileTransition from "./FileTransition";

const MAX_ALLOWED_DIMENSIONS = 480;

const ImageDropZone = ({
  fileName,
  imageUrl,
  accept,
  maxSize,
  handleUpload,
  handleRemove,
  OnLoadProps,
  isOnSuccess,
  handleCancel,
  className,
  onError,
}) => {
  const [isNewUpload, setIsNewUpload] = useState(!fileName);
  const filePicker = useImagePicker({
    noClick: true,
    maxSize: maxSize * 1000000,
    multiple: false,
    accept: accept.join(","),
    imageDimensions: {
      maxWidth: MAX_ALLOWED_DIMENSIONS,
      maxHeight: MAX_ALLOWED_DIMENSIONS,
    },
  });
  const { acceptedFiles } = filePicker;

  const removeFile = () => {
    handleRemove();
    filePicker.removeFile();
  };

  const handleCancelUpload = () => {
    handleCancel();
    filePicker.removeFile();
  };

  useEffect(() => {
    if (filePicker.screens.isOnUpload && handleUpload) {
      setIsNewUpload(true);
      handleUpload({ acceptedFiles: [...acceptedFiles] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePicker.screens.isOnUpload]);

  useEffect(() => {
    if (isOnSuccess) {
      filePicker.removeFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnSuccess]);

  useEffect(() => {
    if (filePicker.screens.isOnError) {
      onError && onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePicker.screens.isOnError]);

  return (
    <FileInput
      rootProps={filePicker.getRootProps()}
      inputProps={filePicker.getInputProps()}
      className={className}
    >
      {!isOnSuccess && filePicker.screens.isOnLoad ? (
        <FileTransition.OnLoad
          {...OnLoadProps}
          Icon={<SvgUpload />}
          handleClick={filePicker.open}
        />
      ) : null}

      {filePicker.screens.isOnDrop ? (
        <FileTransition.OnDrop
          hintText={t("MANAGED_GOALS.BUTTONS.DROP_FILE")}
        />
      ) : null}

      {filePicker.screens.isOnUpload ? (
        <FileTransition.OnUpload
          fileName={filePicker.acceptedFiles[0].name}
          handleCancel={handleCancelUpload}
        />
      ) : null}

      {filePicker.screens.isOnError ? (
        <FileTransition.OnError
          fileName={filePicker.fileRejections[0]?.file.name}
          error={filePicker.fileError}
          handleRemove={removeFile}
        />
      ) : null}

      {!filePicker.screens.isOnDrop &&
      !filePicker.screens.isOnError &&
      isOnSuccess ? (
        <FileTransition.OnSuccess
          isNewUpload={isNewUpload}
          fileName={fileName}
          imageUrl={imageUrl}
          handleRemove={removeFile}
        />
      ) : null}
    </FileInput>
  );
};

export default ImageDropZone;
