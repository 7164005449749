import {
  GoalsLogo,
  IncentivesLogo,
  LeaderboardsLogo,
  RulesetsLogo,
} from "src/images";
import { APPS } from "./appsConstants";

export const WEB_PRODUCTS_MENU_LIST = [
  {
    label: "COMMON.BUTTONS.GOALS",
    icon: GoalsLogo,
    route: APPS.GOALS.ROUTES.ROOT.path,
    baseRoute: APPS.GOALS.ROUTES.ROOT.path,
  },
  {
    label: "INCENTIVES.COMMON.LABELS.INCENTIVES",
    icon: IncentivesLogo,
    route: APPS.INCENTIVES.ROUTES.ROOT.path,
    baseRoute: APPS.INCENTIVES.ROUTES.ROOT.path,
  },
  {
    label: "LEADERBOARD.LABELS.LEADERBOARDS",
    icon: LeaderboardsLogo,
    route: APPS.LEADERBOARDS.ROUTES.ROOT.path,
    baseRoute: APPS.LEADERBOARDS.ROUTES.ROOT.path,
  },
];

export const MOBILE_PRODUCT_MENU_LIST = [
  {
    label: "RULEBOOK.LABELS.RULEBOOKS",
    icon: RulesetsLogo,
    baseRoute: APPS.RULESETS.ROUTES.ROOT.path,
    route: APPS.RULESETS.ROUTES.ROOT.path,
  },
  {
    label: "COMMON.BUTTONS.GOALS",
    icon: GoalsLogo,
    baseRoute: APPS.GOALS.ROUTES.ROOT.path,
    route: APPS.GOALS.ROUTES.MANAGED_BY_ME.path,
  },
  {
    label: "INCENTIVES.COMMON.LABELS.INCENTIVES",
    icon: IncentivesLogo,
    baseRoute: APPS.INCENTIVES.ROUTES.ROOT.path,
    route: APPS.INCENTIVES.ROUTES.DASHBOARD.path,
  },
  {
    label: "LEADERBOARD.LABELS.LEADERBOARDS",
    icon: LeaderboardsLogo,
    baseRoute: APPS.LEADERBOARDS.ROUTES.ROOT.path,
    route: APPS.LEADERBOARDS.ROUTES.DASHBOARD.path,
  },
];
