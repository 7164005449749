import { gql, useMutation } from "src/api/client";

const CLONE_LEADERBOARD_MUTATION = gql`
  mutation copyLeaderboard($LeaderboardId: ID!, $Name: String!) {
    copyLeaderboard(LeaderboardId: $LeaderboardId, Name: $Name) {
      ... on CopyLeaderboardProgramResponse {
        success
        message
        response
        __typename
      }
      __typename
    }
  }
`;
export const useCloneLeaderboard = () => {
  return useMutation(CLONE_LEADERBOARD_MUTATION);
};
