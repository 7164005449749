import { t } from "i18next";
import React from "react";
import Select from "react-select";
import "./selectFilter.scss";

const styles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    height: "40px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#309AFC" : "",
    backgroundColor: state.isSelected ? "#F5F8FA" : "",
  }),
};

const highlightedStyles = {
  ...styles,
  control: (provided, state) => ({
    ...provided,
    background: "#f2faff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#309AFC",
  }),
};

const SelectFilter = (props) => {
  const {
    id,
    label,
    className,
    highlight,
    defaultValue,
    onChange,
    options,
    value,
    placeholder,
    optionLabelKey,
    optionValueKey,
    isOptionDisabled,
    formatOptionLabel,
    handleCreate,
    formatCreateLabel,
    disabled = false,
    customStyles,
    components,
    customProps,
    selectRef,
  } = props;

  const updatedStyles = highlight
    ? { ...highlightedStyles, ...customStyles }
    : { ...styles, ...customStyles };

  const getOptionLabel = optionLabelKey
    ? {
        getOptionLabel: (option) => option[optionLabelKey],
      }
    : {};
  const getOptionValue = optionValueKey
    ? {
        getOptionValue: (option) => option[optionValueKey],
      }
    : {};

  return (
    <>
      {label ? (
        <label className="fs-12 semi-bold mb-8" htmlFor={id}>
          {t(label)}
        </label>
      ) : null}
      <Select
        id={id}
        ref={selectRef}
        placeholder={placeholder}
        className={className}
        components={components && components}
        classNamePrefix="drop-select"
        styles={updatedStyles}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e)}
        options={options}
        value={value}
        isDisabled={disabled}
        isOptionDisabled={isOptionDisabled}
        formatOptionLabel={formatOptionLabel}
        onCreateOption={handleCreate}
        formatCreateLabel={formatCreateLabel}
        {...getOptionLabel}
        {...getOptionValue}
        {...customProps}
      />
    </>
  );
};

export default SelectFilter;
