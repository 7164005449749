import React from "react";
import { Image } from "src/components/common";
import LinkedProgramCardDefaultIconImg from "./LinkedProgramCardDefaultIcon.jpg";

const LinkedProgramCardDefaultIcon = (props) => (
  <Image
    src={LinkedProgramCardDefaultIconImg}
    alt="LinkedProgramCardDefaultIconImg"
    {...props}
  />
);

export default LinkedProgramCardDefaultIcon;
