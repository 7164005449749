import React, { useState } from "react";
// import PropTypes from 'prop-types'
import { classNames } from "src/helpers";
import { FormButton } from "../../formElements";
import "./durationInput.scss";

const DurationInput = (props) => {
  const {
    min,
    title,
    description,
    afterInputText,
    handleClick,
    handleBlur,
    errorMessage,
    viewOnly,
    value,
    isDisabled,
    setEditMode,
  } = props;

  const [inputValue, setInputValue] = useState(value);
  const handleChange = (val) => setInputValue(val);

  return (
    <>
      <div className="flex-between">
        <div>
          <h4 className="fs-14 semi-bold">{title}</h4>
          <p className="fs-12 fc-grey1">{description}</p>
        </div>
        <div className="flex-center">
          {viewOnly || isDisabled ? (
            <FormButton
              className={classNames([
                "form-control duration-input bg-grey5 text-start px-2 py-1",
                isDisabled ? "cursor-not-allowed" : "cursor-pointer",
              ])}
              onClick={!isDisabled ? () => handleClick() : undefined}
              variant="link"
              disabled={isDisabled}
            >
              <span className="fc-black">{inputValue}</span>
            </FormButton>
          ) : (
            <input
              className={`form-control duration-input ${
                errorMessage ? "error" : ""
              }`}
              value={inputValue}
              type="digit"
              min={min}
              onChange={(e) =>
                handleChange(e.target.value.replace(/[^0-9]/gi, ""))
              }
              onBlur={(e) => {
                if (setEditMode) setEditMode((prevState) => !prevState);
                let num = parseInt(e.target.value.replace(/[^0-9]/gi, ""), 10);
                if (isNaN(num)) num = "";
                setInputValue(num.toString());
                handleBlur(num.toString());
              }}
            />
          )}
          {afterInputText && (
            <span className="fs-12 semi-bold ml-3">{afterInputText}</span>
          )}
        </div>
      </div>
      {errorMessage && <div className="fs-13">{errorMessage}</div>}
    </>
  );
};

DurationInput.propTypes = {};
DurationInput.defaultProps = {};

export default DurationInput;
