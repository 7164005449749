import React from "react";
import { Accordion } from "react-bootstrap";
import { classNames } from "src/helpers";
import { BaseCard } from "../card";

// ? Wrap the component within Accordion from react-bootstrap

const BaseCardAccordion = ({
  baseCardClassName,
  active,
  eventKey,
  handleClick,
  customToggle,
  customCollapse,
}) => {
  return (
    <BaseCard className={baseCardClassName} active={active}>
      <Accordion.Toggle
        eventKey={String(eventKey)}
        className={classNames(["w-100 h-100 bg-white br-8 border-0 p-0"])}
        variant="link"
        onClick={() => handleClick(eventKey)}
      >
        <div>{customToggle}</div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={String(eventKey)}>
        <div>{customCollapse}</div>
      </Accordion.Collapse>
    </BaseCard>
  );
};

export default BaseCardAccordion;
