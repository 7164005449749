import { t } from "i18next";
import { Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import { FormButton } from "src/components";

const ChangeProgramTypeModal = ({ show, handleClose, handleChangeType }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="semi-bold fs-16" closeButton>
        {t("INCENTIVES.PROGRAM.LABELS.CHANGE_PROGRAM_TYPE")}
      </Modal.Header>
      <Modal.Body className="mx-4 my-3 fs-13">
        <div className="mb-2">
          <Trans
            t={t}
            i18nKey="INCENTIVES.PROGRAM.LABELS.CHANGE_PROGRAM_TYPE_WARN"
          >
            Are you sure you want to change the <strong>Program Type ? </strong>
          </Trans>
        </div>
        <div className="mb-2">
          {t("INCENTIVES.PROGRAM.LABELS.CHANGE_PROGRAM_WARNING")}
        </div>
        <div className="bg-grey p-3 fs-13 mt-4 br-4">
          <Trans
            t={t}
            i18nKey="INCENTIVES.PROGRAM.LABELS.CHANGE_PROGRAM_TYPE_WARN"
          >
            Program will be saved in <strong>Draft state.</strong>
          </Trans>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <FormButton
            label={t("COMMON.BUTTONS.CANCEL")}
            onClick={handleClose}
            id="incentive-click-away-cancel"
            className="mr-3"
          />
          <FormButton
            label={t("INCENTIVES.PROGRAM.LABELS.YES_CHANGE_PROGRAM_TYPE")}
            variant="outline-primary"
            id="incentive-click-away-discard"
            onClick={handleChangeType}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeProgramTypeModal;
