import axios from "axios";
import { APPS, CLIENT_ID, GET_TOKEN_URL, REDIRECT_URI } from "src/constants";
import { getOrgCode, verifyToken } from "src/helpers";
import { useIsLoggedIn } from "src/hooks";
import { useHistory } from "src/router";
import useLogout from "./useLogout";

export const useRefreshToken = () => {
  const { setIsLoggedIn } = useIsLoggedIn();
  const logout = useLogout();
  const { history } = useHistory();

  const refresh = async () => {
    try {
      const refresh_token = localStorage.getItem("refresh_token") || "";
      if (refresh_token) {
        if (!verifyToken(refresh_token)) {
          logout();
          history.push(APPS.ACE.ROUTES.ERROR.path);
        }

        const orgCode = getOrgCode(localStorage.getItem("refresh_token"));

        const queryParams = new URLSearchParams({
          client_id: CLIENT_ID,
          refresh_token,
          grant_type: "refresh_token",
          org_code: orgCode,
        });

        const { data } = await axios
          .create({ baseURL: REDIRECT_URI })
          .post(GET_TOKEN_URL, queryParams.toString(), {
            withCredentials: true,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          });

        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("id_token", data.id_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        setIsLoggedIn(true);

        return data;
      }
      throw new Error("No refresh token found");
    } catch (err) {
      console.error("Error while refreshing token:", err);
    }
  };
  return refresh;
};
