import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { ConfirmationModal, DurationInput } from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import {
  COLORS,
  MAX_EACH_CYCLE_DURATION,
  MAX_GAP_DURATION,
  MIN_EACH_CYCLE_DURATION,
  MIN_GAP_DURATION,
} from "src/constants";
import { isEmpty, isNull } from "src/helpers";
import { SvgInfo } from "src/icons";

const UniformDuration = (props) => {
  const {
    isDistinct,
    totalCycles,
    gapDuration,
    handleSwitch,
    cycleDuration,
    selectedTemplate,
    durationViewOnly,
    onGapDurationChange,
    durationInputDisabled,
    onEachCycleDurationChange,
    handleDurationModalConfirm,
  } = props;

  const [cycleDurationError, setCycleDurationError] = useState("");
  const [gapDurationError, setGapDurationError] = useState("");
  const [showModal, setShowModal] = useState({
    show: false,
    value: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [recurrenceSwitch, setRecurrenceSwitch] = useState(null);

  useEffect(() => {
    const switchToOtherCycleMessage = (recurrencePeriod) => (
      <p className="fs-13">
        <div className="fc-red">
          {t("COMMON.MESSAGES.RESEMBLES_OTHER_CYCLE", {
            cycle: recurrencePeriod?.toLowerCase(),
          })}
        </div>
        <div className="fc-grey1 d-flex">
          <span className="mr-1">
            {t("COMMON.MESSAGES.TO_RETAIN_THE_SELECTED_CONFIGURATION")}
          </span>
          <FormButton
            variant="link"
            onClick={() => {
              setRecurrenceSwitch(null);
              handleSwitch(recurrencePeriod);
            }}
            label={t("COMMON.MESSAGES.SWITCH_TO_OTHER_CYCLE", {
              cycle: recurrencePeriod,
            })}
          />
        </div>
      </p>
    );

    if (parseInt(gapDuration) === 0) {
      switch (parseInt(cycleDuration)) {
        case 1:
          return setRecurrenceSwitch(switchToOtherCycleMessage("Daily"));
        case 7:
          return isDistinct
            ? setRecurrenceSwitch(null)
            : setRecurrenceSwitch(switchToOtherCycleMessage("Weekly"));
        case 14:
          return isDistinct
            ? setRecurrenceSwitch(null)
            : setRecurrenceSwitch(switchToOtherCycleMessage("Biweekly"));
        default: {
          setRecurrenceSwitch(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, cycleDuration, gapDuration, editMode]);

  const cycleDurationErrorMessage = (
    <p className="fc-red">
      {t("COMMON.MESSAGES.CYCLE_CANNOT_HAVE_MORE_THAN")}{" "}
      <span className="semi-bold">
        {MAX_EACH_CYCLE_DURATION} {t("COMMON.LABELS.DAYS")}.
      </span>
    </p>
  );

  const minCycleDurationErrorMessage = (
    <p className="fc-red">
      {t("COMMON.MESSAGES.CYCLE_SHOULD_HAVE_ATLEAST")}{" "}
      <span className="semi-bold">
        {MIN_EACH_CYCLE_DURATION} {t("COMMON.LABELS.DAY")}.
      </span>
    </p>
  );

  const gapDurationErrorMessage = (
    <p className="fc-red">
      {t("COMMON.MESSAGES.CYCLE_CANNOT_HAVE_MORE_THAN")}{" "}
      <span className="semi-bold">
        {MAX_GAP_DURATION} {t("COMMON.LABELS.GAPS")}.
      </span>
    </p>
  );

  const handleCycleDurationBlur = (value) => {
    if (value < MIN_EACH_CYCLE_DURATION) {
      setCycleDurationError(minCycleDurationErrorMessage);
      setRecurrenceSwitch(null);
      onEachCycleDurationChange(null);
    } else if (value > MAX_EACH_CYCLE_DURATION) {
      setCycleDurationError(cycleDurationErrorMessage);
      setRecurrenceSwitch(null);
      onEachCycleDurationChange(null);
    } else {
      setCycleDurationError("");
      setRecurrenceSwitch(null);
      if (isEmpty(value) || parseInt(value, 10) < MIN_EACH_CYCLE_DURATION) {
        onEachCycleDurationChange(null);
      } else onEachCycleDurationChange(parseInt(value));
    }
  };

  const handleGapDurationChange = (value) => {
    if (value > MAX_GAP_DURATION) {
      setRecurrenceSwitch(null);
      setGapDurationError(gapDurationErrorMessage);
      onGapDurationChange(null);
    } else {
      setGapDurationError("");
      setRecurrenceSwitch(null);
      if (isEmpty(value) || parseInt(value, 10) < MIN_GAP_DURATION) {
        onGapDurationChange(null);
      } else {
        onGapDurationChange(parseInt(value));
      }
    }
  };

  const ModalBody = () => (
    <div>
      <h3 className="fs-14 semi-bold">
        {t("COMMON.MESSAGES.CONFIRM_TO_EDIT_DURATION", {
          value: showModal.value,
        })}
      </h3>
      <p className="fs-13">
        {t(
          "COMMON.MESSAGES.WILL_RESULT_IN_LOSS_OF_SELECTED_FIRST_AND_LAST_DATE"
        )}
      </p>
    </div>
  );

  const CustomCycleDisplayNote = () => {
    if (!isNull(cycleDuration) && !isNull(gapDuration) && !isNull(totalCycles))
      return (
        <div className="p-3 bg-secondary-blue fs-12 fc-grey1">
          <div className="center">
            <SvgInfo backgroundColor="none" color={COLORS.GREY_1} />
            <span className="ml-2 semi-bold">
              {t("MANAGED_GOALS.MESSAGES.NOTE")}
            </span>
            <p className="ml-2">
              {t("LEADERBOARD.LABELS.MAXIMUM_OF")}{" "}
              <strong>
                {totalCycles} {t("COMMON.MESSAGES.UNIFORM")}{" "}
                {totalCycles > 1
                  ? t("COMMON.LABELS.CYCLES")
                  : t("MANAGED_GOALS.LABELS.CYCLE")}
              </strong>{" "}
              {t("COMMON.LABELS.CAN_BE_CREATED")}
            </p>
          </div>
        </div>
      );

    return null;
  };

  return (
    <>
      <div className="mb-4">
        <DurationInput
          title={t("COMMON.LABELS.EACH_CYCLE_DURATION")}
          description={t("COMMON.LABELS.DAYS_COUNT_IN_EACH_CUSTOM_CYCLE")}
          afterInputText="days"
          handleClick={() =>
            setShowModal({
              show: true,
              value: "each cycle",
            })
          }
          isDisabled={durationInputDisabled}
          min={MIN_EACH_CYCLE_DURATION}
          value={cycleDuration}
          setEditMode={setEditMode}
          handleBlur={handleCycleDurationBlur}
          errorMessage={cycleDurationError}
          viewOnly={durationViewOnly}
        />
      </div>
      <div className="mb-4">
        <DurationInput
          title={t("COMMON.LABELS.GAP_DURATION")}
          description={t("COMMON.LABELS.DAYS_INTERVAL_BETWEEN_EACH_CYCLE")}
          afterInputText="days"
          handleClick={() =>
            setShowModal({
              show: true,
              value: "gap",
            })
          }
          isDisabled={durationInputDisabled}
          min={MIN_GAP_DURATION}
          value={gapDuration}
          setEditMode={setEditMode}
          handleBlur={handleGapDurationChange}
          errorMessage={gapDurationError}
          viewOnly={durationViewOnly}
        />
      </div>
      {!cycleDurationError && !gapDurationError && (
        <>{recurrenceSwitch || <CustomCycleDisplayNote />}</>
      )}
      {showModal?.show && (
        <ConfirmationModal
          show={showModal?.show}
          title={t("COMMON.LABELS.EDIT_DURATION")}
          body={<ModalBody />}
          illustration={null}
          onClose={() => {
            setShowModal({ show: false, value: "" });
          }}
          onConfirm={() => {
            handleDurationModalConfirm();
            setShowModal({ show: false, value: "" });
          }}
          confirmButtonText={t("COMMON.BUTTONS.YES_EDIT_DURATION")}
          cancelButton
        />
      )}
    </>
  );
};

UniformDuration.propTypes = {};
UniformDuration.defaultProps = {
  onCycleDurationChange: () => {},
  onGapDurationChange: () => {},
};

export default UniformDuration;
