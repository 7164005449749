import { gql, useQuery } from "src/api/client";

export const GET_LEAD_FIELDS = gql`
  query getLeadMetadata {
    getLeadMetadata {
      SchemaName
      DisplayName
      DataType
      RenderTypeTextValue
      Options
      FieldProperties
      Disabled
    }
  }
`;

export const useGetLeadFields = (queryOptions) => {
  return useQuery(GET_LEAD_FIELDS, queryOptions);
};
