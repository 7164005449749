import React from "react";
import "./styles.scss";

const SlideDrawerModal = (props) => {
  const getDrawerClasses = () => {
    let drawerClasses = "side-drawer-modal close";
    if (props.show) {
      drawerClasses = "side-drawer-modal open";
    }
    return drawerClasses;
  };
  return <div className={getDrawerClasses()}>{props.children}</div>;
};

export default SlideDrawerModal;
