import { gql, useQuery } from "src/api/client";
import {
  CORE_LEADERBOARD_PROGRAM_FIELDS_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
  PAGE_INFO_FRAGMENT,
} from "src/api/fragments";

const GET_ALL_LEADERBOARD_PROGRAMS_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${CORE_LEADERBOARD_PROGRAM_FIELDS_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}

  query getLeaderboardList($Limit: Float, $Query: JSON, $After: String) {
    getLeaderboardList(Limit: $Limit, Query: $Query, After: $After) {
      __typename
      ... on GetAllLeaderboardResponse {
        response {
          totalCount
          pageInfo {
            ...pageInfoFragment
            __typename
          }
          Leaderboards {
            ...coreLeaderboardFields
            UpdatedAt
            InGaps
            FirstRecurrenceRange {
              RecurrenceStartDate
              RecurrenceEndDate
              __typename
            }
            LastRecurrenceRange {
              RecurrenceStartDate
              RecurrenceEndDate
              __typename
            }
            ModifiedByUser {
              FirstName
              LastName
              __typename
            }
            __typename
          }
          __typename
        }
        message
        success
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetAllLeaderboardPrograms = (queryOptions) => {
  return useQuery(GET_ALL_LEADERBOARD_PROGRAMS_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
