import React, { lazy } from "react";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'settings.web.customRewardsPage' */ "./web")
);

export const CustomRewardsPage = {
  web: WebPage,
  mobile: () => <Redirect to={APPS.ACE.ROUTES.ROOT.path} />,

  meta: {
    title: "Custom Rewards",
  },
};
