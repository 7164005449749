import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgNight = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10.119 10.119"
      role="img"
      {...rest}
    >
      <path
        id="Color_Off"
        data-name="Color=Off"
        d="M13.942,10.365a5.039,5.039,0,0,0,.1-2.147,2.969,2.969,0,0,1-.8,1.277v0a2.99,2.99,0,1,1-2.335-5.148,5.115,5.115,0,0,0-.548-.18,5.058,5.058,0,1,0,3.576,6.195Zm-1.073.489a4.215,4.215,0,1,1-4.775-5.9q-.1.131-.2.272a3.891,3.891,0,0,0,4.762,5.727Q12.766,10.905,12.869,10.854Zm.343-4.911-.221-.68-.221.68h-.715l.579.42-.221.68.579-.42.579.42-.221-.68.579-.42Zm-2.75-.68.221.68H11.4l-.579.42.221.68-.579-.42-.579.42.221-.68-.579-.42h.715Zm1.486,2.366-.221-.68-.221.68H10.79l.579.42-.221.68.579-.42.579.42-.221-.68.579-.42Z"
        transform="translate(-3.996 -3.996)"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgNight.defaultProps = {
  width: 10.119,
  height: 10.119,
  color: COLORS.GREY_2,
};

export default SvgNight;
