import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const VALIDATE_LEADERBOARD_IMPORT_FILE_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation validateLeaderboardImportFile($LeaderboardId: ID!, $FileId: Float!) {
    validateLeaderboardImportFile(
      LeaderboardId: $LeaderboardId
      FileId: $FileId
    ) {
      ... on LeaderboardValidateResponse {
        success
        message
        response {
          downloadUrl
          errorCount
          __typename
        }
        __typename
      }
      ...errorResponseFragment
      __typename
    }
  }
`;

export const useValidateLeaderboardFile = () => {
  return useMutation(VALIDATE_LEADERBOARD_IMPORT_FILE_MUTATION);
};
