import PropTypes from "prop-types";
import React from "react";
import { classNames } from "src/helpers";
import "./styles.scss";

export const NavItem = ({ className, disabled, active, onClick, children }) => {
  const disabledClassName = disabled ? " disabled" : " default";
  const activeClassName = active ? " active" : "";
  return (
    <button
      className={classNames([
        "nav-item",
        disabledClassName,
        activeClassName,
        className,
      ])}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const NavBar = ({ variant, className, style, position, children }) => {
  return (
    <div
      style={style}
      className={classNames(["app-bar", variant, position, className])}
    >
      {children}
    </div>
  );
};

NavBar.propTypes = {
  variant: PropTypes.oneOf(["elevated", "outlined", "default"]),
  position: PropTypes.oneOf(["sticky"]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

NavBar.defaultProps = {
  variant: "outlined",
  disabled: false,
  className: "",
};
