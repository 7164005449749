import { t } from "i18next";
import * as React from "react";
import { FIELD_NAMES } from "src/constants/nonMonetaryConstants";
import {
  SvgApproved,
  SvgAttachment,
  SvgClock,
  SvgForward,
  SvgPartiallyApproved,
  SvgRejected,
  SvgSentBack,
} from "src/icons";
import { NonMonetaryLabel } from "../monetaryAndNonMonetaryLabel";
import { MultilineTruncate } from "../multilineTruncate";
import { Truncate } from "../truncate";
import formatTimelineDate from "./formatTimelineDate";

export const data = [
  {
    status: "pending",
    currentStage: 3,
    timestamp: "",
    approver: {
      name: "Narayan Kumar",
      stage: 3,
      approvedAmount: "",
      rejectedAmount: "",
      comments: "",
      attachment: "",
    },
  },
  {
    status: "approved",
    currentStage: 3,
    timestamp: new Date(),
    approver: {
      name: "Narayan Kumar",
      stage: 3,
      approvedAmount: 6000,
      rejectedAmount: 4000,
      comments:
        "This person deserves a better incentive. The performance has been constantly improving. This person deserves a better incentive. The performance has been constantly improving. ",
      attachment: "",
    },
  },
  {
    status: "rejected",
    currentStage: 3,
    timestamp: new Date(),
    approver: {
      name: "Narayan Kumar",
      stage: 3,
      approvedAmount: 0,
      rejectedAmount: 10000,
      comments: "Everything is good to go..",
      attachment: "",
    },
  },
  {
    status: "partially_approved",
    currentStage: 3,
    timestamp: new Date(),
    approver: {
      name: "Narayan Kumar",
      stage: 3,
      approvedAmount: 6000,
      rejectedAmount: 4000,
      comments: "Approving partially.",
      attachment: "",
    },
  },
  {
    status: "forwarded",
    currentStage: 3,
    timestamp: new Date(),
    approver: {
      name: "Jaivardhan Rathod",
      stage: 2,
      approvedAmount: 6000,
      rejectedAmount: 4000,
      comments:
        "This person deserves a better incentive. The performance has been constantly improving.",
      attachment: "stage1_incentive.pdf",
    },
  },
  {
    status: "sent_back",
    currentStage: 2,
    timestamp: new Date(),
    approver: {
      name: "Narayan Kumar",
      stage: 3,
      approvedAmount: 0,
      rejectedAmount: 0,
      comments:
        "This person deserves a better incentive. The performance has been constantly improving. This person deserves a better incentive. The performance has been constantly improving. ",
      attachment: "",
    },
  },
];

const renderAmountCommitted = (item, payload) => {
  return (
    <div className="d-flex py-12 br-4 mb-3 fs-13 timeline-item-amount-committed">
      <div className="d-block align-items-start pl-12 pr-4 timeline-item-approved-amount-committed w-50">
        {t("INCENTIVES.MY_INCENTIVES.LABELS.APPROVED")}:{" "}
        <div className="fs-13" style={{ color: lookup.approved.textColor }}>
          <NonMonetaryLabel
            amount={item.approver.approvedAmount}
            nonMonetaryRewards={item.approver.approvedNonMonetaryReward}
            rewardCount={item.approver.approvedNonMonetaryRewardCount}
            rewardModalTitle={t("INCENTIVES.MY_INCENTIVES.LABELS.APPROVED")}
            payload={{
              ...payload,
              fieldName: FIELD_NAMES.APPROVED,
            }}
          />
        </div>
      </div>
      <div className="d-block align-items-start pl-4 timeline-item-rejected-amount-committed w-50">
        {t("INCENTIVES.MY_INCENTIVES.LABELS.REJECTED")}:{" "}
        <div
          className="ml-2 fs-13"
          style={{ color: lookup.rejected.textColor }}
        >
          <NonMonetaryLabel
            amount={item.approver.rejectedAmount}
            nonMonetaryRewards={item.approver.rejectedNonMonetaryReward}
            rewardCount={item.approver.rejectedNonMonetaryRewardCount}
            rewardModalTitle={t("INCENTIVES.MY_INCENTIVES.LABELS.REJECTED")}
            payload={{
              ...payload,
              fieldName: FIELD_NAMES.REJECTED,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const renderApproverDetails = (item) => {
  return (
    <>
      {!item.approver.isApprovedByAdmin ? (
        <div className="d-flex mb-12 fs-13">
          <div className="mr-12 timeline-item-description">
            {item.approver.name}{" "}
            {item.status === "sent_back" && item.isLoggedInUser ? (
              <span className="fs-13 regular fc-grey">
                ({t("COMMON.LABELS.YOU")})
              </span>
            ) : null}
          </div>
          <div className="timeline-item-description fc-grey">
            - {t("INCENTIVES.COMMON.LABELS.STAGE")} {item.approver.stage}
          </div>
        </div>
      ) : null}
      {item.approver.isApprovedByAdmin ? (
        <div className="semi-bold fs-13 mb-12">
          {t("INCENTIVES.MY_INCENTIVES.MESSAGES.ADMIN")}{" "}
          <span className="fc-grey regular">
            {t("INCENTIVES.MY_INCENTIVES.MESSAGES.ON_BEHALF_OF")}
          </span>{" "}
          {item.approver.name}
        </div>
      ) : null}
      <div className="mb-12 timeline-item-comments">
        <MultilineTruncate>
          <p className="long-text-break">{`“${item.approver.comments}”`}</p>
        </MultilineTruncate>
      </div>
      {item.approver.attachment.fileName && (
        <div className="d-flex fc-blue-primary">
          <div className="mr-8">
            <SvgAttachment />
          </div>
          <a href={item.approver.attachment.url}>
            <Truncate
              text={item.approver.attachment.fileName}
              maxWidth="200px"
            />
          </a>
        </div>
      )}
    </>
  );
};

// for approved, rejected, partially approved statuses
const getContentForResolvedStatuses = (item, payload) => {
  return (
    <div className="pb-3">
      <div className="flex-between mb-12">
        <div
          className="fs-14 timeline-item-title"
          style={{ color: lookup[item.status].textColor }}
        >
          {item.status === "approved"
            ? t(
                "INCENTIVES.MY_INCENTIVES.MESSAGES.APPROVED_AND_MOVED_TO_PAYOUT"
              )
            : item.status === "rejected"
            ? t("INCENTIVES.MY_INCENTIVES.LABELS.REJECTED")
            : t(
                "INCENTIVES.MY_INCENTIVES.MESSAGES.PARTIALLY_APPROVED_AND_MOVED_TO_PAYOUT"
              )}
        </div>
        <div className="fs-12 fc-grey-primary">
          {formatTimelineDate(item.timestamp)}
        </div>
      </div>
      {renderAmountCommitted(item, payload)}
      {renderApproverDetails(item)}
    </div>
  );
};

export const getContent = (item, payloadForRewardsList) => {
  const payload = {
    ...payloadForRewardsList,
    historyId: item.historyId,
  };
  switch (item.status) {
    case "approved": {
      return getContentForResolvedStatuses(item, payload);
    }
    case "rejected": {
      return getContentForResolvedStatuses(item, payload);
    }
    case "partially_approved": {
      return getContentForResolvedStatuses(item, payload);
    }
    case "pending": {
      return (
        <div className="py-3 bg-blue rounded-right">
          <div
            className="fs-14 fc-blue-primary mb-12 timeline-item-title"
            style={{ color: lookup[item.status].textColor }}
          >
            {t("INCENTIVES.COMMON.LABELS.STAGE")} {item.currentStage}{" "}
            {t("INCENTIVES.MY_INCENTIVES.MESSAGES.PENDING")}
          </div>
          <div className="d-flex fs-13">
            <div className="mr-1 timeline-item-description">
              {item.approver.name}{" "}
              {item.approver.isLoggedInUser ? (
                <span className="fs-13 regular fc-grey">
                  ({t("COMMON.LABELS.YOU")})
                </span>
              ) : null}
            </div>
            <div className="timeline-item-description fc-grey">
              - {t("INCENTIVES.COMMON.LABELS.STAGE")} {item.approver.stage}
            </div>
          </div>
        </div>
      );
    }
    case "sent_back": {
      return (
        <div className="pb-3">
          <div className="flex-between mb-12">
            <div
              className="fs-14 timeline-item-title"
              style={{ color: lookup[item.status].textColor }}
            >
              {t("INCENTIVES.MY_INCENTIVES.MESSAGES.SENT_BACK_TO_STAGE")}{" "}
              {item.currentStage - 1}
            </div>
            <div className="fs-12 fc-grey-primary">
              {formatTimelineDate(item.timestamp)}
            </div>
          </div>
          {renderApproverDetails(item)}
        </div>
      );
    }
    case "forwarded": {
      return (
        <div className="pb-3">
          <div className="flex-between mb-3">
            <div
              className="fs-14 timeline-item-title"
              style={{ color: lookup[item.status].textColor }}
            >
              {t("INCENTIVES.MY_INCENTIVES.MESSAGES.FORWARDED_TO_STAGE")}{" "}
              {item.currentStage + 1}
            </div>
            <div className="fs-12 fc-grey-primary">
              {formatTimelineDate(item.timestamp)}
            </div>
          </div>
          {renderAmountCommitted(item, payload)}
          {renderApproverDetails(item)}
        </div>
      );
    }
    case "payout_reject": {
      return (
        <div className="pb-3">
          <div className="flex-between mb-12">
            <div
              className="fs-14 timeline-item-title"
              style={{ color: lookup[item.status].textColor }}
            >
              {t("INCENTIVES.MY_INCENTIVES.MESSAGES.SENT_BACK_FROM_PAYOUT")}{" "}
            </div>
            <div className="fs-12 fc-grey-primary">
              {formatTimelineDate(item.timestamp)}
            </div>
          </div>
          <div className="d-flex mb-12 fs-13">
            <div className="timeline-item-description mr-1">
              {item.approver.name}{" "}
              {item.approver.isLoggedInUser ? (
                <span className="fs-13 regular fc-grey">
                  ({t("COMMON.LABELS.YOU")})
                </span>
              ) : null}
            </div>
            <div className="timeline-item-description fc-grey">
              - {t("INCENTIVES.MY_INCENTIVES.MESSAGES.ADMIN")}
            </div>
          </div>
          <div className="mb-12 timeline-item-comments">
            <MultilineTruncate>
              <p className="long-text-break">{`“${item.approver.comments}”`}</p>
            </MultilineTruncate>{" "}
          </div>
          {item.approver.attachment.fileName && (
            <div className="d-flex fc-blue-primary">
              <div className="mr-8">
                <SvgAttachment />
              </div>
              <a href={item.approver.attachment.url}>
                <Truncate
                  text={item.approver.attachment.fileName}
                  maxWidth="200px"
                />
              </a>
            </div>
          )}
        </div>
      );
    }
    default: {
      return null;
    }
  }
};
export const lookup = {
  approved: {
    status: "approved",
    icon: <SvgApproved />,
    textColor: "#27AE60",
  },
  rejected: {
    status: "rejected",
    icon: <SvgRejected />,
    textColor: "#ED2121",
  },
  partially_approved: {
    status: "partially_approved",
    icon: <SvgPartiallyApproved />,
    textColor: "#A29C00",
  },
  pending: {
    status: "pending",
    icon: <SvgClock />,
    textColor: "#309AFC",
  },
  sent_back: {
    status: "sent_back",
    icon: <SvgSentBack />,
    textColor: "#000000",
  },
  forwarded: {
    status: "forwarded",
    icon: <SvgForward />,
    textColor: "#000000",
  },
  payout_reject: {
    status: "sent_back",
    icon: <SvgSentBack />,
    textColor: "#000000",
  },
};
