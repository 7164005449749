import { gql, useMutation } from "src/api/client";

const GET_ANALYTICS_CONFIG_IMPORT_PRESIGNED_URL = gql`
  mutation getAnalyticsConfigImportPresignedUrl {
    getAnalyticsConfigImportPresignedUrl {
      message
      response {
        url
        fileId
      }
    }
  }
`;

export const useAnalyticsConfigImportPresignedUrl = () => {
  return useMutation(GET_ANALYTICS_CONFIG_IMPORT_PRESIGNED_URL);
};
