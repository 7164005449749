import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const VALIDATE_PAYOUT_IMPORT_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation validatePayoutImport(
    $fileId: Float!
    $timeFrequency: TimeFrequency
    $programId: ID
  ) {
    validatePayoutImport(
      fileId: $fileId
      timeFrequency: $timeFrequency
      programId: $programId
    ) {
      ... on validatePayoutImportResponse {
        message
        success
        response {
          downloadUrl
          errorCount
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useValidatePayoutImport = () => {
  return useMutation(VALIDATE_PAYOUT_IMPORT_MUTATION);
};
