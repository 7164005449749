import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_USER_PREFERENCES_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query userPreferences {
    userPreferences {
      ... on UserPreferencesResponse {
        success
        message
        response {
          templateConfig
          goalsConfig
          incentivesConfig
          leaderboardConfig
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetUserPreferences = (queryOptions) => {
  return useQuery(GET_USER_PREFERENCES_QUERY, queryOptions);
};
