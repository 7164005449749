import { gql, useQuery } from "src/api/client";

const GET_DASHBOARD_PROGRAM_SUMMARY = gql`
  query dashboardProgramSummary(
    $UserId: ID
    $ProgramId: ID
    $limit: Float
    $after: String
    $query: PayoutUsersQuery
    $Status: String
  ) {
    dashboardProgramSummary(
      UserId: $UserId
      ProgramId: $ProgramId
      limit: $limit
      after: $after
      query: $query
      Status: $Status
    ) {
      ... on DashboardUsersResponse {
        success
        message
        response {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
          totalUsersCount
          dashboardUsers {
            userId
            fullName
            emailAddress
            totalApprovedAmount
            totalEligibleAmount
            totalPendingPayout
            totalPaidOut
            Status
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetDashboardLeaderboardSummary = (queryOptions) => {
  return useQuery(GET_DASHBOARD_PROGRAM_SUMMARY, queryOptions);
};
