import React from "react";
import { Image } from "src/components";
import WinnersBannerBackgroundImg from "./WinnersBannerBackground.png";

const WinnersBannerBackground = (props) => (
  <Image
    src={WinnersBannerBackgroundImg}
    alt="WinnersBannerBackgroundImg"
    {...props}
  />
);

export default WinnersBannerBackground;
