import React from "react";

const SvgAvatar49 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="p6xn__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .p6xn__cls-1{fill:#fff}.p6xn__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="p6xn__Layer_1-2">
        <path
          d="M162.8 81.4c0 14.25-3.66 27.63-10.1 39.28a74.65 74.65 0 0 1-3.42 5.63c-2.39 3.6-5.05 7-7.96 10.16-.52.57-1.05 1.12-1.58 1.67-.01.02-.03.04-.05.06-.37.38-.75.76-1.13 1.14l-.97.94a82.466 82.466 0 0 1-6.66 5.71l-1.93 1.44-.03.02c-.43.31-.85.61-1.28.91-.04.03-.08.06-.12.08a76.006 76.006 0 0 1-5.5 3.47c-.54.31-1.08.62-1.63.91a80.317 80.317 0 0 1-32.6 9.72c-1.04.08-2.1.15-3.16.18-.6.03-1.2.05-1.8.06-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-.6-.01-1.2-.03-1.8-.05a64.91 64.91 0 0 1-3.15-.2c-11.75-.9-22.8-4.32-32.62-9.7a79.68 79.68 0 0 1-7.13-4.39c-.5-.35-1-.7-1.49-1.06l-.21-.16-1.77-1.32a81.52 81.52 0 0 1-6.55-5.62c-.32-.3-.63-.61-.95-.92-.39-.39-.77-.77-1.15-1.16l-.05-.05c-.53-.55-1.05-1.1-1.57-1.66C8.14 121.98 0 102.66 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="p6xn__cls-2"
        />
        <path
          d="m140.04 132.84-3.31-4.95a6.517 6.517 0 0 0-3-2.44l-8.51-3.4-.21-.68c-.69-2.19-2.41-3.82-4.62-4.37l-16.44-4.11v-9.78c0-4.75-13.83-6.51-22.55-6.51s-22.55 1.76-22.55 6.51v9.78L42.41 117c-2.21.55-3.93 2.18-4.62 4.37l-.21.68-8.51 3.4c-1.23.5-2.27 1.34-3 2.44l-3.31 4.95a7.89 7.89 0 0 0-1.28 3.65c.52.56 1.04 1.11 1.57 1.66l.05.05c.38.39.76.77 1.15 1.16-.3-.68-.46-1.42-.46-2.17 0-1.09.32-2.14.92-3.04l3.16-4.74 9.52 17.14-2.17 1.89a79.68 79.68 0 0 0 7.13 4.39 24.043 24.043 0 0 1-2.53-10.72v-18.79c0-1.92 1.3-3.58 3.17-4.05l16.27-4.07.6.58c3.7 3.59 15.71 4.54 21.54 4.54s17.84-.95 21.54-4.54l.6-.58 16.28 4.07c1.86.47 3.16 2.13 3.16 4.05v18.79c0 3.73-.88 7.41-2.54 10.71.55-.29 1.09-.6 1.63-.91 1.89-1.08 3.72-2.24 5.5-3.47l-2.17-1.89 9.53-17.14 3.15 4.73c.61.91.93 1.96.93 3.05 0 .75-.15 1.48-.45 2.15.38-.38.76-.76 1.13-1.14.02-.02.04-.04.05-.06.53-.55 1.06-1.1 1.58-1.67a7.854 7.854 0 0 0-1.28-3.63Zm-102.56 9.04-7.85-14.13 7.85-3.13v17.26Zm42.75-23.92-1.56-.06c-8.06-.31-14.91-1.99-16.86-3.44l-.61-.45v-7.72l2.03.78c4.77 1.81 11.8 2.34 15.56 2.49l1.44.06v8.34Zm21.37-3.95-.61.45c-1.95 1.45-8.8 3.13-16.86 3.44l-1.56.06v-8.34l1.44-.06c3.76-.15 10.78-.68 15.56-2.49l2.03-.78v7.72Zm-1.91-9.52c-3.21 1.39-10.11 2.79-18.29 2.79s-15.08-1.4-18.29-2.79c-1.2-.52-1.2-2.23 0-2.75 3.21-1.39 10.11-2.8 18.29-2.8s15.08 1.41 18.29 2.8c1.2.52 1.21 2.23 0 2.75Zm25.63 37.38v-17.25l7.85 3.13-7.85 14.12Z"
          className="p6xn__cls-1"
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="p6xn__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path d="M81.57 124.71h2v38.09h-2z" className="p6xn__cls-1" />
      </g>
    </svg>
  );
};
SvgAvatar49.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar49;
