import React from "react";

const SvgAvatar24 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="re8j__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 163.98"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .re8j__cls-2{fill:var(--avatar-dark)}.re8j__cls-4{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}\n        "
          }
        </style>
      </defs>
      <g id="re8j__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8a81.318 81.318 0 0 1-26.98 18.35c-.18.08-.37.15-.55.22-8.92 3.66-18.64 5.76-28.82 6-.65.02-1.29.03-1.94.03-.8 0-1.59-.01-2.39-.04a80.833 80.833 0 0 1-29.31-6.37c-.17-.06-.34-.14-.51-.21A81.59 81.59 0 0 1 23.1 138.2C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.75}
          className="re8j__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.07)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="re8j__cls-2"
        />
        <path
          d="M90.37 94.76c-8.03 0-14.57-6.54-14.57-14.57v-1.5h29.14v1.5c0 8.03-6.54 14.57-14.57 14.57ZM78.9 81.69c.74 5.67 5.6 10.07 11.47 10.07s10.73-4.4 11.47-10.07H78.9Z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          d="M75.8 119.17H87l-5.6 11.2-5.6-11.2zM89.38 162.4l-7.98-32.03-7.19 32.1.01.01"
          className="re8j__cls-4"
        />
      </g>
    </svg>
  );
};
SvgAvatar24.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar24;
