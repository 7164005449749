import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";
import { FormButton } from "src/components/common/formElements";
import { COLORS, MONTH_OPTIONS, YEAR_OPTIONS_COUNT } from "src/constants";
import {
  addMonths,
  addYears,
  getDate,
  getMonth,
  getYear,
  range,
  subMonths,
  subYears,
} from "src/helpers";
import { SvgArrow } from "src/icons";
import "./calendarHeader.scss";

const CalendarHeader = (props) => {
  const {
    date,
    today,
    changeMonth,
    changeYear,
    decreaseMonth,
    increaseMonth,
    increaseYear,
    decreaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    showMonthYearPicker,
    showQuarterYearPicker,
    showYearPicker,
    setCurrMonth,
    openToDate,
    maxDate,
    minDate,
  } = props;

  // # Handle Month Change
  // * This function is used to handle the month change in the calendar Header
  const handleMonthChange = ({ date, value, changeMonth }) => {
    if (setCurrMonth)
      setCurrMonth(new Date(getYear(date), value, getDate(date)));
    if (changeMonth) changeMonth(value);
  };

  // # Handle Year Change
  // * This function is used to handle the year change in the calendar Header
  const handleYearChange = ({ date, value, changeYear }) => {
    if (setCurrMonth)
      setCurrMonth(new Date(value, getMonth(date), getDate(date)));
    if (changeYear) changeYear(value);
  };

  const handlePrevious = ({ date, decreaseYear, decreaseMonth }) => {
    let prevDate;
    if (showMonthYearPicker || showQuarterYearPicker) {
      prevDate = subYears(date, 1);
      if (decreaseYear) decreaseYear(date);
    } else {
      prevDate = subMonths(date, 1);
      if (decreaseMonth) decreaseMonth(date);
    }
    if (setCurrMonth) setCurrMonth(prevDate);
  };

  const handleNext = ({ date, increaseYear, increaseMonth }) => {
    let nextDate;
    if (showMonthYearPicker || showQuarterYearPicker) {
      nextDate = addYears(date, 1);
      if (increaseYear) increaseYear(date);
    } else {
      nextDate = addMonths(date, 1);
      if (increaseMonth) increaseMonth(date);
    }
    if (setCurrMonth) setCurrMonth(nextDate);
  };

  const curr_year = minDate ? getYear(minDate) : getYear(today) - 1;
  const max_year = getYear(maxDate) || curr_year + YEAR_OPTIONS_COUNT - 1;

  const yearOptions = range(curr_year, max_year).map((year) => {
    return {
      value: year,
      label: year,
      isDisabled:
        maxDate && openToDate
          ? getYear(openToDate) > year || year > getYear(maxDate)
          : false,
    };
  });

  const disabledPrev =
    showMonthYearPicker || showQuarterYearPicker
      ? prevYearButtonDisabled
      : prevMonthButtonDisabled;

  const disabledNext =
    showMonthYearPicker || showQuarterYearPicker
      ? nextYearButtonDisabled
      : nextMonthButtonDisabled;

  return (
    <div className="flex-between">
      <FormButton
        variant="link"
        id="cal-prev-btn"
        disabled={disabledPrev}
        onClick={() =>
          handlePrevious({
            date,
            showMonthYearPicker,
            showQuarterYearPicker,
            decreaseYear,
            decreaseMonth,
          })
        }
        icon={<SvgArrow rotate={90} color={COLORS.GREY_1} />}
        disabledIcon={disabledPrev && <SvgArrow rotate={90} color="#b5becc" />}
      />
      <div className="d-flex">
        {showMonthYearPicker ||
          (!showYearPicker && !showQuarterYearPicker && (
            <div className="cal-select cal-month-select mr-2">
              <ReactSelect
                isSearchable={false}
                classNamePrefix="cal-select"
                options={MONTH_OPTIONS}
                value={MONTH_OPTIONS[getMonth(date)]}
                onChange={({ value }) =>
                  handleMonthChange({ date, value, changeMonth })
                }
              />
            </div>
          ))}
        <div className="cal-select cal-year-select">
          <ReactSelect
            isSearchable={false}
            options={yearOptions}
            classNamePrefix="cal-select"
            value={yearOptions.find((option) => option.value === getYear(date))}
            onChange={({ value }) =>
              handleYearChange({ date, value, changeYear })
            }
          />
        </div>
      </div>
      <FormButton
        variant="link"
        id="cal-next-btn"
        disabled={disabledNext}
        onClick={() =>
          handleNext({
            date,
            showMonthYearPicker,
            showQuarterYearPicker,
            increaseYear,
            increaseMonth,
          })
        }
        icon={<SvgArrow rotate={-90} color={COLORS.GREY_1} />}
        disabledIcon={disabledNext && <SvgArrow rotate={-90} color="#b5becc" />}
      />
    </div>
  );
};

CalendarHeader.propTypes = {
  date: PropTypes.instanceOf(Date),
  today: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  openToDate: PropTypes.instanceOf(Date),
  setCurrMonth: PropTypes.func,
  changeMonth: PropTypes.func,
  changeYear: PropTypes.func,
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
  increaseYear: PropTypes.func,
  decreaseYear: PropTypes.func,
  prevYearButtonDisabled: PropTypes.bool.isRequired,
  nextYearButtonDisabled: PropTypes.bool.isRequired,
  prevMonthButtonDisabled: PropTypes.bool.isRequired,
  nextMonthButtonDisabled: PropTypes.bool.isRequired,
  showMonthYearPicker: PropTypes.bool.isRequired,
  showQuarterYearPicker: PropTypes.bool.isRequired,
  showYearPicker: PropTypes.bool,
};

CalendarHeader.defaultProps = {
  date: new Date(),
  today: new Date(),
  showMonthYearPicker: false,
  showQuarterYearPicker: false,
  showYearPicker: false,
};

export default CalendarHeader;
