import React from "react";
import { Trans } from "react-i18next";
import { MAX_CYCLES, MAX_CYCLE_DAYS, MAX_DATE } from "src/constants";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  differenceInDays,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  getDate,
  getDay,
  getMonth,
  getYear,
  isBefore,
  isExists,
  isFirstDayOfMonth,
  isLeapYear,
  isSameDay,
  setDay,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from "src/helpers/vendors";
import { range } from "./utils";

export const addAWeek = (date) => addDays(date, 6);
export const subAWeek = (date) => subDays(date, 6);

export const addABiweek = (date) => addDays(date, 13);
export const subABiweek = (date) => subDays(date, 13);

export const addAMonth = (date) => {
  if (isFirstDayOfMonth(date)) return startOfDay(endOfMonth(date));
  let newDate = getDate(date);
  const newMonth = getMonth(date) < 11 ? getMonth(date) + 1 : 0;
  let newYear = getYear(date);
  if (getMonth(date) + 1 > 11) newYear += 1;

  while (!isExists(newYear, newMonth, newDate - 1)) {
    newDate -= 1;
  }
  return startOfDay(new Date(newYear, newMonth, newDate - 1));
};
export const subAMonth = (startDate, endDate) => {
  if (isSameDay(startOfMonth(startDate), startDate))
    return new Date(getYear(endDate), getMonth(endDate), 1);
  const prevMonth = subMonths(endDate, 1);
  if (isExists(getYear(prevMonth), getMonth(prevMonth), getDate(startDate))) {
    return new Date(
      getYear(prevMonth),
      getMonth(prevMonth),
      getDate(startDate)
    );
  }
  return new Date(getYear(endDate), getMonth(endDate), 1);
};
export const setSelectedEndDateForMonthly = (startDate, endDate) => {
  const endDateStart = subAMonth(startDate, endDate);
  if (getDate(startDate) === getDate(endDateStart)) return endDateStart;
  return subDays(endDateStart, 1);
};
export const setEndDateForMonthly = (startDate, calendarDate) => {
  if (
    isExists(getYear(calendarDate), getMonth(calendarDate), getDate(startDate))
  )
    return addAMonth(
      new Date(
        getYear(calendarDate),
        getMonth(calendarDate),
        getDate(startDate)
      )
    );
  let newDate = getDate(startDate) - 1;
  while (
    !isExists(getYear(calendarDate), getMonth(calendarDate) + 1, newDate)
  ) {
    newDate -= 1;
  }
  return new Date(getYear(calendarDate), getMonth(calendarDate) + 1, newDate);
};

export const addAQuarter = (date) => subDays(addQuarters(date, 1), 1);
export const subAQuarter = (date) =>
  addDays(startOfDay(endOfMonth(subQuarters(date, 1))), 1);

export const addAYear = (date) => {
  if (isSameDay(startOfYear(date), date)) return startOfDay(endOfYear(date));
  let newDate = getDate(date);
  while (!isExists(getYear(date) + 1, getMonth(date), getDate(newDate))) {
    newDate -= 1;
  }
  return startOfDay(new Date(getYear(date) + 1, getMonth(date), newDate - 1));
};
export const subAYear = (startDate, endDate) => {
  if (isSameDay(startOfYear(startDate), startDate))
    return new Date(getYear(endDate), 0, 1);
  const prevYear = subYears(endDate, 1);
  if (isExists(getYear(prevYear), getMonth(startDate), getDate(startDate))) {
    return new Date(getYear(prevYear), getMonth(startDate), getDate(startDate));
  }
  return new Date(getYear(prevYear), getMonth(endDate) + 1, 1);
};
export const setSelectedEndDateForYearly = (startDate, endDate) => {
  const endDateStart = subAYear(startDate, endDate);
  if (getDate(startDate) === getDate(endDateStart)) return endDateStart;
  return subDays(endDateStart, 1);
};
export const setEndDateForYearly = (startDate, calenderDate) => {
  if (isExists(getYear(calenderDate), getMonth(startDate), getDate(startDate)))
    return addAYear(
      new Date(getYear(calenderDate), getMonth(startDate), getDate(startDate))
    );
  let newDate = getDate(startDate) - 1;
  while (!isExists(getYear(calenderDate) + 1, getMonth(startDate), newDate)) {
    newDate -= 1;
  }
  return new Date(getYear(calenderDate) + 1, getMonth(startDate), newDate);
};

export const addCustomDays = (date, cycleDuration) =>
  addDays(date, cycleDuration - 1);
export const subCustomDays = (date, cycleDuration) =>
  subDays(date, cycleDuration - 1);

export const nextSelectableYear = (date) => {
  // * if leap year then check cuurent date is before 10 April
  if (isLeapYear(date)) {
    if (isBefore(date, new Date(getYear(date), 3, 10)))
      return startOfYear(date);
    return addYears(startOfYear(date), 1);
  }
  // * if non leap year then check cuurent date is before 11 April

  if (isBefore(date, new Date(getYear(date), 3, 11))) return startOfYear(date);
  return addYears(startOfYear(date), 1);
};
export const nthDayOfMonth = (date, day) => {
  const start = startOfMonth(date);
  return setDay(start, day, {
    weekStartsOn: getDay(start),
  });
};

export const filterWeek = ({ date, startDate }) => {
  const day = getDay(date);
  return day === getDay(new Date(startDate));
};
export const filterBiweek = ({ date, startDate }) => {
  const diff = differenceInDays(startOfDay(date), startOfDay(startDate));
  const day = getDay(date);
  return diff % 14 === 0 && day === getDay(new Date(startDate));
};
export const filterCustom = ({ date, startDate, cycleDuration }) =>
  differenceInDays(date, startDate) % cycleDuration === 0;

export const CYCLES_TYPES = {
  Daily: {
    startMinDate: ({ today }) => startOfMonth(subYears(today, 1)),
    maxDate: ({ startDate }) =>
      startDate ? addDays(startDate, MAX_CYCLES.Daily - 1) : MAX_DATE,
    minDate: ({ startDate }) => startDate || null,
    selectedEndDate: ({ endDate }) => endDate || null,
    setEndDate: ({ date }) => date || null,
    endDateStart: ({ endDate }) => endDate || null,
    label: ({ t }) => t("MANAGED_GOALS.LABELS.SELECT_DATE"),
  },
  Weekly: {
    startMinDate: ({ today }) =>
      subDays(
        startOfMonth(subYears(today, 1)),
        (getDay(startOfMonth(subYears(today, 1))) + 6) % 7
      ),
    maxDate: ({ startDate }) => addWeeks(startDate, MAX_CYCLES.Weekly - 1),
    minDate: ({ startDate }) => startDate || null,
    selectedEndDate: ({ endDate }) => (endDate ? subAWeek(endDate) : null),
    setEndDate: ({ date }) => (date ? addAWeek(date) : null),
    startDateEnd: ({ startDate }) => (startDate ? addAWeek(startDate) : null),
    endDateStart: ({ endDate }) => (endDate ? subAWeek(endDate) : null),
    filterWeekDay: ({ startDate }) => startDate,
    filterDate: ({ date, startDate }) =>
      startDate ? filterWeek({ date, startDate }) : null,
    label: ({ t }) => t("MANAGED_GOALS.LABELS.SELECT_WEEK"),
    distinctCountText: (
      <div>
        <Trans i18nKey="MANAGED_GOALS.LABELS.WEEKLY_DISTINCT_TEXT">
          Weekly cycles will start on <strong>Mondays</strong> since the Ruleset
          has <strong>Distinct Count</strong> aggregation function.
        </Trans>
      </div>
    ),
  },
  Biweekly: {
    startMinDate: ({ today }) =>
      subDays(
        startOfMonth(subYears(today, 1)),
        (getDay(startOfMonth(subYears(today, 1))) + 6) % 7
      ),
    maxDate: ({ startDate }) =>
      addWeeks(startDate, MAX_CYCLES.Biweekly * 2 - 1),
    minDate: ({ startDate }) => startDate || null,
    selectedEndDate: ({ endDate }) => (endDate ? subABiweek(endDate) : null),
    setEndDate: ({ date }) => (date ? addABiweek(date) : null),
    startDateEnd: ({ startDate }) => (startDate ? addABiweek(startDate) : null),
    endDateStart: ({ endDate }) => (endDate ? subABiweek(endDate) : null),
    filterWeekDay: ({ startDate }) => startDate || null,
    filterDate: ({ date, startDate }) =>
      date && startDate ? filterBiweek({ date, startDate }) : null,
    label: ({ t }) => t("MANAGED_GOALS.LABELS.SELECT_WEEKS"),
  },
  Monthly: {
    startMinDate: ({ today }) => startOfMonth(subYears(today, 1)),
    maxDate: ({ startDate }) =>
      startDate
        ? addMonths(endOfMonth(startDate), MAX_CYCLES.Monthly - 1)
        : null,
    minDate: ({ startDate }) => (startDate ? startOfMonth(startDate) : null),
    selectedEndDate: ({ startDate, endDate }) =>
      startDate && endDate
        ? setSelectedEndDateForMonthly(startDate, endDate)
        : null,
    setEndDate: ({ startDate, date }) =>
      startDate && date ? setEndDateForMonthly(startDate, date) : null,
    startDateEnd: ({ startDate }) => (startDate ? addAMonth(startDate) : null),
    endDateStart: ({ startDate, endDate }) =>
      startDate && endDate ? subAMonth(startDate, endDate) : null,
    label: ({ t }) => t("MANAGED_GOALS.LABELS.SELECT_MONTH"),
    distinctCountText: (
      <div>
        <Trans i18nKey="MANAGED_GOALS.LABELS.MONTHLY_DISTINCT_TEXT">
          Monthly cycles will start on the <strong>First day of month</strong>{" "}
          since the Ruleset has <strong>Distinct Count</strong> aggregation
          function.
        </Trans>
      </div>
    ),
  },
  Quarterly: {
    startMinDate: ({ today }) => startOfQuarter(subYears(today, 1)),
    maxDate: ({ startDate }) =>
      startDate
        ? addQuarters(endOfQuarter(startDate), MAX_CYCLES.Quarterly - 1)
        : null,
    minDate: ({ startDate }) => (startDate ? startOfQuarter(startDate) : null),
    selectedEndDate: ({ endDate }) => endDate || null,
    setEndDate: ({ date }) => (date ? addAQuarter(date) : null),
    startDateEnd: ({ startDate }) =>
      startDate ? addAQuarter(startDate) : null,
    endDateStart: ({ endDate }) => (endDate ? subAQuarter(endDate) : null),
    label: ({ t }) => t("MANAGED_GOALS.LABELS.SELECT_QUARTER"),
  },
  Yearly: {
    startMinDate: ({ today, isDistinct }) =>
      isDistinct
        ? nextSelectableYear(subYears(today, 1))
        : subMonths(startOfMonth(subYears(today, 1)), 2),
    maxDate: ({ startDate }) =>
      startDate ? addYears(startDate, MAX_CYCLES.Yearly - 1) : null,
    minDate: ({ startDate }) => (startDate ? startOfYear(startDate) : null),
    selectedEndDate: ({ startDate, endDate }) =>
      startDate && endDate
        ? setSelectedEndDateForYearly(startDate, endDate)
        : null,
    setEndDate: ({ startDate, date }) =>
      startDate && date ? setEndDateForYearly(startDate, date) : null,
    startDateEnd: ({ startDate }) => (startDate ? addAYear(startDate) : null),
    endDateStart: ({ startDate, endDate }) =>
      startDate && endDate ? subAYear(startDate, endDate) : null,
    label: ({ t }) => t("MANAGED_GOALS.LABELS.SELECT_YEAR"),
    distinctCountText: (
      <div>
        <Trans i18nKey="MANAGED_GOALS.LABELS.YEARLY_DISTINCT_TEXT">
          Yearly cycles will start on <strong>1st of January</strong> since the
          Ruleset has <strong>Distinct Count</strong>
          aggregation function.
        </Trans>
      </div>
    ),
  },
  Infinite: {
    label: "Select Date",
    startMinDate: ({ today }) => startOfMonth(subYears(today, 1)),
  },
  Custom: {
    startMinDate: ({ today, isDistinct, cycleDuration, gapDuration }) =>
      isDistinct && gapDuration === 0 && [7, 14].includes(cycleDuration)
        ? subDays(
            startOfMonth(subYears(today, 1)),
            (getDay(startOfMonth(subYears(today, 1))) + 6) % 7
          )
        : startOfMonth(subYears(today, 1)),
    maxDate: ({ startDate, cycleDuration, gapDuration }) => {
      if (!startDate) return null;
      if (
        parseInt(gapDuration, 10) === 0 &&
        [7, 14].includes(parseInt(cycleDuration, 10))
      ) {
        return cycleDuration === 7
          ? addWeeks(startDate, MAX_CYCLES.Weekly - 1)
          : addWeeks(startDate, MAX_CYCLES.Biweekly * 2 - 1);
      }
      return addDays(startDate, MAX_CYCLE_DAYS - 1);
    },
    minDate: ({ startDate }) => startDate || null,
    selectedEndDate: ({ endDate, cycleDuration }) =>
      endDate ? subCustomDays(endDate, cycleDuration) : null,
    setEndDate: ({ date, cycleDuration }) =>
      date ? addCustomDays(date, cycleDuration) : null,
    startDateEnd: ({ startDate, cycleDuration }) =>
      startDate ? addCustomDays(startDate, cycleDuration) : null,
    endDateStart: ({ endDate, cycleDuration }) =>
      endDate ? subCustomDays(endDate, cycleDuration) : null,
    filterDate: ({ date, startDate, cycleDuration }) =>
      startDate ? filterCustom({ date, startDate, cycleDuration }) : null,
    label: ({ t }) => t("MANAGED_GOALS.LABELS.SELECT_PERIOD"),
  },
};

// # It is used to define the Highlighted Cycle Array which gives proper classnames to highlight the range in UI

export const highlightCycle = ({
  recurrencePeriod,
  hoverDate,
  selectedDate,
}) => {
  const highlightedCycles = [];
  const firstHoverDay = new Date(hoverDate);
  const firstSelectedDay = new Date(selectedDate);
  const hover = [firstHoverDay];
  const selected = [firstSelectedDay];
  let i;
  let j;

  let highlightedHoverRange;
  let highlightedSelectedRange;

  switch (recurrencePeriod) {
    case "Biweekly": {
      highlightedHoverRange = 13;
      highlightedSelectedRange = 13;
      break;
    }
    case "Monthly": {
      highlightedHoverRange = differenceInCalendarDays(
        addAMonth(firstHoverDay),
        firstHoverDay
      );
      highlightedSelectedRange = differenceInCalendarDays(
        addAMonth(firstSelectedDay),
        firstSelectedDay
      );
      break;
    }
    case "Weekly": {
      highlightedHoverRange = 6;
      highlightedSelectedRange = 6;
      break;
    }
    default:
      break;
  }

  if (["Weekly", "Biweekly", "Monthly"].includes(recurrencePeriod)) {
    range(1, highlightedHoverRange).forEach((obj) => {
      hover.push(addDays(firstHoverDay, obj));
      i = obj + 1;
    });

    range(1, highlightedSelectedRange).forEach((obj) => {
      selected.push(addDays(firstSelectedDay, obj));
      j = obj + 1;
    });

    highlightedCycles.push(
      { hover },
      { hover_start: [firstHoverDay] },
      { hover_end: [addDays(firstHoverDay, i - 1)] },
      { selected },
      { selected_start: [firstSelectedDay] },
      { selected_end: [addDays(firstSelectedDay, j - 1)] }
    );
  }

  return highlightedCycles;
};

export const highlightCustomCycle = ({ hoverDate, days }) => {
  if (!hoverDate && days <= 1) return [];
  const highlightedCycles = [];
  const firstHoverDay = new Date(hoverDate);
  const hover = [firstHoverDay];
  let i;
  range(1, days - 1).forEach((obj) => {
    hover.push(addDays(firstHoverDay, obj));
    i = obj + 1;
  });

  highlightedCycles.push(
    { hover },
    { hover_start: [firstHoverDay] },
    { hover_end: [addDays(firstHoverDay, i - 1)] }
  );
  return highlightedCycles;
};

export const getDayClassName = ({ day, calendarStartDay, today }) => {
  let className = "";
  if (isSameDay(day, today)) className += "cal_today ";
  if (getDay(day) === calendarStartDay) className += "weekstart";
  if (getDay(day) === (calendarStartDay + 6) % 7) className += "weekend";
  return className;
};

export const getWeekDayClassName = ({ day, filterWeekDay }) => {
  if (typeof filterWeekDay !== "undefined") {
    if (getDay(filterWeekDay) === getDay(day)) return "weekday--selected";
    return "weekday--disabled";
  }
  return "";
};

export const getDays = (milliseconds) => {
  return milliseconds / (60 * 60 * 24 * 1000);
};

export const getNonUniformDefaultRecurrences = (today) => [
  {
    recurrenceStartDate: null,
    recurrenceEndDate: null,
    editMode: true,
    add: true,
    minDate: startOfMonth(subYears(today, 1)),
  },
];

export const isCycleDurationChanged = (oldRecurrences, newRecurrences) => {
  let isChanged = false;
  if (newRecurrences.length === oldRecurrences.length) {
    newRecurrences.forEach(
      ({ RecurrenceStartDate, RecurrenceEndDate }, index) => {
        if (
          !isSameDay(
            new Date(RecurrenceStartDate),
            new Date(oldRecurrences[index].RecurrenceStartDate)
          ) ||
          !isSameDay(
            new Date(RecurrenceEndDate),
            new Date(oldRecurrences[index].RecurrenceEndDate)
          )
        ) {
          isChanged = true;
        }
      }
    );
  } else {
    isChanged = true;
  }
  return isChanged;
};
