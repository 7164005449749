import React from "react";
import { APPS } from "src/constants";
import { Redirect, Route, Switch } from "src/router";
import { useSettingsRbac } from "./hooks";
import { Pages } from "./pages";

export function Routes() {
  const { settingsRbac } = useSettingsRbac();

  return (
    <Switch>
      <Route
        path={APPS.SETTINGS.ROUTES.CUSTOM_REWARDS.path}
        page={Pages.CustomRewardsPage}
        isPage
        exact
        {...settingsRbac(APPS.SETTINGS.ROUTES.CUSTOM_REWARDS.path)}
      />
      <Route path="*">
        <Redirect to={APPS.SETTINGS.ROUTES.CUSTOM_REWARDS.path} />
      </Route>
    </Switch>
  );
}
