import { t } from "i18next";
import { addDays, getDays, getTodayAsPerTimezone } from "src/helpers";
import RecurrenceStatus from "../recurrenceStatus/RecurrenceStatus";
import { Timer } from "../timer";
import "./recurrenceStatusTimer.scss";

const RECURRENCE_TEXT = {
  YETTOSTART: "LEADERBOARD.LABELS.CYCLE_STATUS_YET_TO_START",
  INPROGRESS: "LEADERBOARD.LABELS.CYCLE_STATUS_INPROGRESS",
  COMPLETED: "LEADERBOARD.LABELS.CYCLE_STATUS_COMPLETE",
};

const RecurrencePeriod = ({
  recurrenceStatus,
  recurrenceStartDate,
  recurrenceEndDate,
}) => {
  const today = getTodayAsPerTimezone();
  const duration = Math.abs((recurrenceEndDate - today) / 1000);
  const recurrenceStartsIn =
    Math.floor(Math.abs(getDays(recurrenceStartDate - today))) + 1;
  return (
    <div className="flex">
      {recurrenceStatus === "YETTOSTART" ? (
        <span className="fs-13 semi-bold">
          {t(RECURRENCE_TEXT[recurrenceStatus], {
            time: `${recurrenceStartsIn} Days`,
          })}
        </span>
      ) : null}
      {recurrenceStatus === "INPROGRESS" ? (
        <div className="flex flex-column">
          <span className="fs-13 semi-bold">
            {t(RECURRENCE_TEXT[recurrenceStatus])}
          </span>
          <Timer duration={duration} />
        </div>
      ) : null}
      {recurrenceStatus === "COMPLETED" ? (
        <span className="fs-13 semi-bold">
          {t(RECURRENCE_TEXT[recurrenceStatus])}
        </span>
      ) : null}
    </div>
  );
};

const BufferPeriod = ({
  recurrenceEndDate,
  bufferDays,
  recurrenceStatus,
  isBufferCompleted,
  lastUpdatedAt,
}) => {
  const today = getTodayAsPerTimezone();
  const duration = (addDays(recurrenceEndDate, bufferDays) - today) / 1000;

  return (
    <span className="fc-grey fs-12 semi-bold buffer-period center">
      {recurrenceStatus === "COMPLETED" && isBufferCompleted ? (
        <span>{t("LEADERBOARD.LABELS.CYCLE_ACHIEVEMENT_FROZEN_ON")}</span>
      ) : null}
      {!isBufferCompleted ? (
        <>
          <span className="mr-1">{t("LEADERBOARD.LABELS.BUFFER_PERIOD")}</span>
          {recurrenceStatus === "COMPLETED" && duration > 0 ? (
            <Timer duration={duration} />
          ) : (
            `+${bufferDays}d`
          )}
        </>
      ) : null}
    </span>
  );
};

function RecurrenceStatusTimer({
  recurrenceStatus,
  bufferPeriodText,
  bufferDays,
  isBufferCompleted,
  recurrenceEndDate,
  recurrenceStartDate,
  lastUpdatedAt,
}) {
  return (
    <div className="recurrence-status-timer center">
      <RecurrenceStatus
        recurrenceStatus={
          recurrenceStatus === "COMPLETED" && !isBufferCompleted
            ? "BUFFERDAYS"
            : recurrenceStatus
        }
      />
      <div className="ml-3">
        <RecurrencePeriod
          recurrenceStatus={recurrenceStatus}
          recurrenceStartDate={recurrenceStartDate}
          recurrenceEndDate={recurrenceEndDate}
        />
        <BufferPeriod
          isBufferCompleted={isBufferCompleted}
          recurrenceStatus={recurrenceStatus}
          bufferDays={bufferDays}
          recurrenceEndDate={recurrenceEndDate}
          lastUpdatedAt={lastUpdatedAt}
        />
      </div>
    </div>
  );
}

export default RecurrenceStatusTimer;
