import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgAttachment = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="x6n3__a">
          <path d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
      <g
        fill="none"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        clipPath="url(#xl6n3__a)"
      >
        <g strokeLinecap="round">
          <path d="M1 0v12.117h10V3.636L7.364 0Z" />
          <path
            fill={color}
            d="M2 1v10.117h8V4.05L6.95 1H2M1 0h6.364L11 3.636v8.48H1V0Z"
          />
        </g>
        <path stroke={color} d="M7.364.568v3.068h3.068" />
        <path
          stroke={color}
          strokeLinecap="round"
          d="M3.386 6.136h5.227V9.73H3.386Zm1.7 0V9.73m-1.7-1.704h5.227"
        />
      </g>
    </svg>
  );
};

SvgAttachment.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 16,
  width: 13,
};
export default SvgAttachment;
