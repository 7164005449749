import React from "react";
import { useTranslation } from "react-i18next";
import { Truncate } from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import {
  COLORS,
  CUSTOM_CYCLE_TYPES,
  DATE_FORMAT_WITH_FULL_WEEKDAY,
} from "src/constants";
import { capitalize, format } from "src/helpers";
import { SvgEdit, SvgUnpublish } from "src/icons";

const PopoverContent = (props) => {
  const {
    goal,
    desc,
    name,
    hideDetails,
    setTemplateDetailModalshow,
    type,
    hideEditOption,
    templateDetailsResults,
    templateDetailModalShow,
    setTemplateCreateModal,
    setGoalCreateModalShow,
    recurrenceList,
  } = props;
  const { t } = useTranslation();
  let cycleEndDate;
  if (goal.RecurrenceType && recurrenceList) {
    cycleEndDate = format(
      new Date(
        parseInt(recurrenceList[recurrenceList?.length - 1].RecurrenceEndDate)
      ),
      DATE_FORMAT_WITH_FULL_WEEKDAY
    );
  }

  return (
    <div className="goal-template-info-popover">
      <div className="fs-12 px-3 pt-3 pb-2 long-text-break">{name}</div>
      <p className={`${hideDetails ? "pb-3" : ""} fs-11 long-text-break px-3`}>
        {desc}
      </p>
      {hideDetails || hideEditOption ? null : (
        <FormButton
          id="change-info"
          variant="link"
          className="fs-12 fc-primary-blue mx-3 my-2"
          onClick={() =>
            type === "template"
              ? setTemplateCreateModal(true)
              : setGoalCreateModalShow(true)
          }
          icon={<SvgEdit color={COLORS.PRIMARY_BLUE} className="pr-1" />}
        >
          <span className="fc-primary-blue">
            {`${t("COMMON.BUTTONS.EDIT")} ${
              type === "template"
                ? t("RULEBOOK.LABELS.RULEBOOK")
                : t("COMMON.BUTTONS.GOAL")
            } ${t("RULEBOOK.LABELS.INFO")} `}
          </span>
        </FormButton>
      )}
      <hr />
      {type === "template" && (
        <div className="p-3 d-flex justify-content-between">
          <div className="fs-11">
            <p className="fc-grey pb-1">
              {t("RULEBOOK.LABELS.AGGREGATE_FUNCTION")}
            </p>
            <p className="fs-12">
              {goal.GoalAggregateFunction ? (
                capitalize(goal.GoalAggregateFunction)
              ) : (
                <span className="fc-grey">-</span>
              )}
            </p>
          </div>
          <div className="fs-11">
            <p className="fc-grey pb-1">{t("RULEBOOK.LABELS.NO_OF_RULES")} </p>
            <p className="fs-12">{goal?.GoalRules.length}</p>
          </div>
        </div>
      )}
      {goal.RecurrenceType ? (
        <>
          <div className="p-3 d-flex justify-content-between">
            <div className="fs-11">
              <p className="fc-grey pb-1">
                {t("MANAGED_GOALS.LABELS.ROLL_UP_CYCLE")}
              </p>
              <p className="fs-12">
                {capitalize(goal.RecurrenceType)}{" "}
                {goal.RecurrenceType === "CUSTOM" &&
                  `: ${
                    CUSTOM_CYCLE_TYPES[goal?.RecurrenceDefinition?.CustomType]
                  }`}
              </p>
            </div>
            <div className="fs-11">
              <p className="fc-grey pb-1">
                {t("MANAGED_GOALS.LABELS.TOTAL_NO_OF_CYCLES")}{" "}
              </p>
              <p className="fs-12">{goal?.TotalCycles?.split(" ")[0]}</p>
            </div>
          </div>
          <hr />
          <div className="p-3">
            <div className="fs-11 fc-grey pb-1">
              {t("MANAGED_GOALS.LABELS.GOAL_ENDS_ON")}
            </div>
            <div className="fs-12">{cycleEndDate}</div>
          </div>
        </>
      ) : null}
      <hr />
      {goal?.GoalOwner && (
        <>
          <div className="p-3">
            <div className="fs-11 fc-grey pb-1">
              {t("MANAGED_GOALS.LABELS.GOAL_OWNER")}
            </div>
            <div className="fs-12">
              {goal?.GoalOwner?.FirstName} {goal?.GoalOwner?.LastName}
            </div>
          </div>
          <hr />
        </>
      )}
      {hideDetails || !goal.RecurrenceType ? null : (
        <div className="p-3">
          <div className="fs-11 fc-grey pb-1">
            {t("MANAGED_GOALS.LABELS.TEMPLATE_USED")}
          </div>
          {templateDetailsResults?.Status === "PUBLISHED" ? (
            <FormButton
              id="popover-content-template-name"
              variant="link"
              className="fs-12"
              onClick={() =>
                setTemplateDetailModalshow(!templateDetailModalShow)
              }
            >
              <span className="fc-primary-blue flex-start">
                <Truncate
                  id="popover-published-template"
                  tooltipPlacement="top"
                  maxWidth="216px"
                  noOfLine={2}
                  text={templateDetailsResults?.Name}
                />
              </span>
            </FormButton>
          ) : (
            <div>
              <div className="fs-12 semi-bold fc-grey long-text-break">
                <Truncate
                  id="popover-unpublished-template"
                  tooltipPlacement="top"
                  maxWidth="216px"
                  noOfLine={2}
                  text={templateDetailsResults?.Name}
                />
              </div>
              <div className="fs-11 pt-2 fc-grey normal font-italic">
                <SvgUnpublish color={COLORS.GREY_1} />{" "}
                {capitalize(templateDetailsResults?.Status)}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PopoverContent;
