import React from "react";
import { WebRulesetLandingPage as WebPage } from "src/components";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

export const RulesetsPage = {
  web: WebPage,
  mobile: () => <Redirect to={APPS.RULESETS.ROUTES.ROOT.path} />,
  meta: {
    title: "Goals Rulesets",
  },
};
