import React from "react";
const SvgGoalsOnCard = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#p0lq__a)">
        <mask
          id="p0lq__b"
          width={24}
          height={24}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "alpha",
          }}
        >
          <path fill="#D9D9D9" d="M0 0h24v24H0z" />
        </mask>
        <g mask="url(#p0lq__b)">
          <path
            fill="url(#p0lq__c)"
            d="M11.02 8.613c.446-.09.803-.624.625-1.07-.09-.445-.624-.801-1.07-.623-2.406.535-4.01 2.673-4.01 5.168 0 2.94 2.406 5.347 5.347 5.347 2.762 0 5.08-2.139 5.347-4.901.089-.446-.357-.891-.803-.98-.445-.09-.89.356-.98.802-.178 1.782-1.782 3.297-3.564 3.297a3.575 3.575 0 0 1-3.564-3.565c0-1.693 1.069-3.119 2.673-3.475"
          />
          <path
            fill="url(#p0lq__d)"
            d="M19.93 11.198c-.534 0-.89.356-.89.89 0 3.921-3.208 7.13-7.13 7.13-3.92 0-7.128-3.209-7.128-7.13 0-3.742 2.94-6.86 6.683-7.128.535 0 .891-.446.802-.98 0-.535-.445-.891-.98-.802C6.653 3.534 3 7.366 3 12.088 3 16.99 7.01 21 11.91 21c4.902 0 8.912-4.01 8.912-8.911a.893.893 0 0 0-.891-.892"
          />
          <path
            fill="url(#p0lq__e)"
            d="m13.603 9.148-2.317 2.317a.861.861 0 0 0 0 1.247.962.962 0 0 0 .624.268c.178 0 .445-.09.623-.268l2.317-2.316 1.515.445h.267c.268 0 .446-.089.624-.267l3.475-3.565c.179-.178.268-.534.268-.802 0-.267-.268-.534-.535-.623l-1.604-.535-.534-1.604c-.09-.267-.357-.535-.624-.535a.719.719 0 0 0-.802.268l-3.475 3.564c-.268.267-.357.624-.179.891zm3.475-3.653.267.623c.09.268.268.446.535.535l.624.267-2.139 2.139-1.07-.357-.356-1.069z"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="p0lq__c"
          x1={11.918}
          x2={11.918}
          y1={6.873}
          y2={17.435}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C4CF7" />
          <stop offset={1} stopColor="#F254A7" />
        </linearGradient>
        <linearGradient
          id="p0lq__d"
          x1={11.911}
          x2={11.911}
          y1={3.164}
          y2={21}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C4CF7" />
          <stop offset={1} stopColor="#F254A7" />
        </linearGradient>
        <linearGradient
          id="p0lq__e"
          x1={16.009}
          x2={16.009}
          y1={2.877}
          y2={12.98}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C4CF7" />
          <stop offset={1} stopColor="#F254A7" />
        </linearGradient>
        <clipPath id="p0lq__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
SvgGoalsOnCard.defaultProps = {
  color: "",
  height: 24,
  width: 24,
};
export default SvgGoalsOnCard;
