import React from "react";
import {
  Bar,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
} from "recharts";
import { COLORS } from "src/constants";
import {
  getGreaterThanMarkerColor,
  getLesserThanMarkerColor,
} from "src/helpers";

const GreaterThanValue = () => (
  <linearGradient id="greaterThanValue" x1={1} y1={0} x2={0} y2={0}>
    <stop offset="0%" stopColor={COLORS.CARD_GREEN} />
    <stop offset="50%" stopColor={COLORS.CARD_GREEN} />
    <stop offset="50%" stopColor={COLORS.CARD_YELLOW_GREEN} />
    <stop offset="66.67%" stopColor={COLORS.CARD_YELLOW} />
    <stop offset="83.34%" stopColor={COLORS.CARD_ORANGE} />
    <stop offset="100%" stopColor={COLORS.CARD_RED} />
  </linearGradient>
);

const LesserThanValue = () => (
  <linearGradient id="lesserThanValue" x1={1} y1={0} x2={0} y2={0}>
    <stop offset="0%" stopColor={COLORS.CARD_RED} />
    <stop offset="16.6%" stopColor={COLORS.CARD_ORANGE} />
    <stop offset="33.4%" stopColor={COLORS.CARD_YELLOW} />
    <stop offset="50%" stopColor={COLORS.CARD_YELLOW_GREEN} />
    <stop offset="50%" stopColor={COLORS.CARD_GREEN} />
    <stop offset="100%" stopColor={COLORS.CARD_GREEN} />
  </linearGradient>
);

const AverageProgressBar = (props) => {
  const {
    percentage,
    markerColor,
    reverseOrder,
    barHeight = 20,
    animationDuration = 1500,
  } = props;
  const data = [
    {
      value: 100,
      x: Math.min(Math.max(percentage / 2, 0), 100),
    },
  ];

  return (
    <ResponsiveContainer
      width="100%"
      height={barHeight}
      className="flex align-items-start"
    >
      <ComposedChart
        data={data}
        layout="vertical"
        margin={{ top: 0, right: 8, left: 8, bottom: 0 }}
        className="center"
      >
        <defs>{reverseOrder ? <LesserThanValue /> : <GreaterThanValue />}</defs>
        <XAxis type="number" dataKey="x" domain={[0, 100]} hide />
        <YAxis type="category" hide />
        <Bar
          dataKey="value"
          fill={
            reverseOrder ? "url(#lesserThanValue)" : "url(#greaterThanValue)"
          }
          radius={[3, 3, 3, 3]}
          barSize={7}
          animationDuration={animationDuration}
        />
        <Scatter dataKey="x">
          <Cell
            cy={9.5}
            r={6}
            fill={COLORS.WHITE}
            stroke={
              markerColor || reverseOrder
                ? getLesserThanMarkerColor(percentage)
                : getGreaterThanMarkerColor(percentage)
            }
            strokeWidth={3}
          />
        </Scatter>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default AverageProgressBar;
