import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormButton } from "src/components/common/formElements";
import {
  SvgAggregate,
  SvgCondition,
  SvgDate,
  SvgMeasure,
  SvgUser,
} from "src/icons";

const RuleCardBody = (props) => {
  const {
    entityContent,
    activityDisplayName,
    parentActivityDisplayName,
    opportunityDisplayName,
    aggregateField,
    aggregateFieldDisplayName,
    aggregateFieldRollupType,
    userFieldDisplayName,
    userFieldRollupType,
    dateFieldDisplayName,
    dateFieldRollupType,
    conditionsCount,
    disableConditionClick,
    showConditionModal,
    border,
    taskDisplayName,
  } = props;
  const { t } = useTranslation();

  const RuleCardBodyList = (props) => {
    const { icon, title, text, border = true } = props;
    return (
      <li className={`d-flex fs-12 py-2 ${border ? "border-b" : ""}`}>
        <div className="pr-3">{icon}</div>
        <div className="w-100">
          <h3 className="fs-13 fc-grey semi-bold">{title}</h3>
          <p className="fc-grey long-text-break">{text}</p>
        </div>
      </li>
    );
  };

  const getFieldText = (rollupType) => {
    switch (rollupType) {
      case "Activity":
        return activityDisplayName;
      case "ParentActivity":
        return parentActivityDisplayName;
      case "Opportunity":
        return opportunityDisplayName;
      case "Task":
        return taskDisplayName;
      default:
        return "Lead";
    }
  };

  const Content = ({ displayName, rollUpType }) => (
    <>
      <span className="fc-black mr-1 long-text-break">{displayName}</span>
      <span className="mr-1">{t("RULEBOOK.LABELS.FIELD_OF")}</span>
      <span className="fc-black">{getFieldText(rollUpType)}</span>
    </>
  );

  const conditionContent = (
    <FormButton
      id="condition-btn"
      variant="link"
      disabled={disableConditionClick}
      onClick={() =>
        !disableConditionClick ? showConditionModal((prev) => !prev) : undefined
      }
    >
      <span className="fc-blue">{`${conditionsCount} ${
        conditionsCount > 1
          ? t("RULEBOOK.LABELS.CONDITIONS")
          : t("RULEBOOK.LABELS.CONDITION")
      }`}</span>
    </FormButton>
  );

  return (
    <ul className="p-3">
      {entityContent && (
        <RuleCardBodyList
          icon={<SvgMeasure />}
          title={t("RULEBOOK.LABELS.MEASURE_ACHIEVEMENT_WHEN")}
          text={entityContent}
          border={border}
        />
      )}
      {aggregateFieldDisplayName && (
        <RuleCardBodyList
          icon={<SvgAggregate />}
          title={aggregateField}
          text={
            <Content
              displayName={aggregateFieldDisplayName}
              rollUpType={aggregateFieldRollupType}
            />
          }
          border={border}
        />
      )}
      {userFieldDisplayName && (
        <RuleCardBodyList
          icon={<SvgUser />}
          title={t("RULEBOOK.LABELS.IDENTIFY_THE_USER_BY")}
          text={
            <Content
              displayName={userFieldDisplayName}
              rollUpType={userFieldRollupType}
            />
          }
          border={border}
        />
      )}
      {dateFieldDisplayName && (
        <RuleCardBodyList
          icon={<SvgDate />}
          title={t("RULEBOOK.LABELS.IDENTIFY_THE_DATE_BY")}
          text={
            <Content
              displayName={dateFieldDisplayName}
              rollUpType={dateFieldRollupType}
            />
          }
          border={conditionsCount > 0 && border}
        />
      )}
      {conditionsCount > 0 && (
        <RuleCardBodyList
          icon={<SvgCondition />}
          title={t("RULEBOOK.LABELS.CONDITIONS")}
          text={conditionContent}
          border={false}
        />
      )}
    </ul>
  );
};

RuleCardBody.propTypes = {
  entityContent: PropTypes.node,
  activityDisplayName: PropTypes.string,
  parentActivityDisplayName: PropTypes.string,
  opportunityDisplayName: PropTypes.string,
  aggregateField: PropTypes.string,
  aggregateFieldDisplayName: PropTypes.string,
  aggregateFieldRollupType: PropTypes.string,
  userFieldDisplayName: PropTypes.string,
  userFieldRollupType: PropTypes.string,
  dateFieldDisplayName: PropTypes.string,
  dateFieldRollupType: PropTypes.string,
  conditionsCount: PropTypes.number,
  disableConditionClick: PropTypes.bool,
  showConditionModal: PropTypes.func,
  border: PropTypes.bool,
};

RuleCardBody.defaultProps = {
  border: false,
  disableConditionClick: true,
};

export default RuleCardBody;
