import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgPendingPayout = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <g>
        <path fill="none" d="M0 .042h18v18H0z" />
        <g fill={color} stroke={color}>
          <path
            strokeWidth={0.3}
            d="M8.848 3.604a5.139 5.139 0 1 0 5.138 5.139 5.139 5.139 0 0 0-5.138-5.139Zm0 9.335a4.2 4.2 0 1 1 4.194-4.2 4.2 4.2 0 0 1-4.194 4.2Z"
          />
          <path
            strokeWidth={0.6}
            d="M14.795 14.6A8.4 8.4 0 0 0 5.753.9l.26.669a7.679 7.679 0 0 1 8.266 12.533l-.51-.5a.379.379 0 0 0-.644.277l.019 1.351a.379.379 0 0 0 .384.374l1.351-.016a.379.379 0 0 0 .26-.651Z"
          />
          <path
            strokeWidth={0.6}
            d="M5.93 15.67a7.674 7.674 0 0 1-2.393-12.4l.455.436a.379.379 0 0 0 .644-.277l-.019-1.351a.378.378 0 0 0-.384-.374l-1.351.016a.379.379 0 0 0-.261.653l.4.394a8.4 8.4 0 0 0 9.042 13.7l-.26-.669a7.628 7.628 0 0 1-5.873-.128Z"
          />
          <path
            strokeWidth={0.5}
            d="M7.967 10.497a1.518 1.518 0 0 0 .619.164v.374a.133.133 0 0 0 0 .094.109.109 0 0 0 .087 0h.314a.109.109 0 0 0 .087 0 .133.133 0 0 0 0-.094v-.375a1.439 1.439 0 0 0 .845-.351.983.983 0 0 0 .313-.78.987.987 0 0 0-.125-.521.941.941 0 0 0-.386-.335 3.324 3.324 0 0 0-.728-.242 3.694 3.694 0 0 1-.415-.109.646.646 0 0 1-.248-.171.374.374 0 0 1-.08-.242.372.372 0 0 1 .189-.366.732.732 0 0 1 .437-.109.692.692 0 0 1 .379.109.436.436 0 0 1 .2.288.145.145 0 0 0 .146.094h.473a.106.106 0 0 0 .1-.109.9.9 0 0 0-.131-.405 1.152 1.152 0 0 0-.357-.366 1.319 1.319 0 0 0-.517-.171V6.49a.122.122 0 0 0-.031-.1.1.1 0 0 0-.093-.033h-.338a.109.109 0 0 0-.087 0 .133.133 0 0 0 0 .094v.374a1.269 1.269 0 0 0-.772.351.976.976 0 0 0-.284.709.9.9 0 0 0 .277.709 2.057 2.057 0 0 0 .867.4q.357.1.539.171a.719.719 0 0 1 .277.171.34.34 0 0 1 .083.244.405.405 0 0 1-.175.343.9.9 0 0 1-.524.125.842.842 0 0 1-.488-.125.57.57 0 0 1-.233-.3.237.237 0 0 0-.066-.078.15.15 0 0 0-.095 0h-.481a.1.1 0 0 0-.08 0 .116.116 0 0 0 0 .078.947.947 0 0 0 .142.457 1.1 1.1 0 0 0 .36.417Z"
          />
        </g>
      </g>
    </svg>
  );
};

SvgPendingPayout.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 18,
  width: 18,
};
export default SvgPendingPayout;
