import { APPS, ROLES, USER_ROLES } from "src/constants";
import { useRoleBasedContext } from "src/context";
import { useViewport } from "src/hooks";

const useIncentivesRbac = () => {
  const {
    userDetails: { role, isIncentiveApprover, isManagingUsers },
    isFeatureEnabled,
  } = useRoleBasedContext();

  const { isMobile } = useViewport();

  const DEFAULT_REDIRECTION = {
    [USER_ROLES.ADMIN]: isMobile
      ? APPS.INCENTIVES.ROUTES.DASHBOARD.path
      : APPS.INCENTIVES.ROUTES.RULESETS.path,
    [USER_ROLES.SALES_MANAGER_AS_APPROVER]: isMobile
      ? APPS.INCENTIVES.ROUTES.DASHBOARD.path
      : APPS.INCENTIVES.ROUTES.APPROVAL.path,
    [USER_ROLES.SALES_MANAGER]: APPS.INCENTIVES.ROUTES.DASHBOARD.path,
    [USER_ROLES.SALES_USER_AS_APPROVER]: isMobile
      ? APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path
      : APPS.INCENTIVES.ROUTES.APPROVAL.path,
    [USER_ROLES.SALES_USER]: APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path,
  };

  const getRole = () => {
    if (role === ROLES.ADMIN) {
      return USER_ROLES.ADMIN;
    }

    if (isManagingUsers) {
      if (isIncentiveApprover) {
        return USER_ROLES.SALES_MANAGER_AS_APPROVER;
      }
      return USER_ROLES.SALES_MANAGER;
    }

    if (isIncentiveApprover) {
      return USER_ROLES.SALES_USER_AS_APPROVER;
    }

    return USER_ROLES.SALES_USER;
  };

  const ACCESS_ROUTES = {
    [USER_ROLES.SALES_USER]: [
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path,
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.path,
      APPS.INCENTIVES.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.INCENTIVES.ROUTES.PAYOUT_REQUEST_PAGE.path,
    ],
    [USER_ROLES.SALES_USER_AS_APPROVER]: [
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path,
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.path,
      APPS.INCENTIVES.ROUTES.APPROVAL.path,
      APPS.INCENTIVES.ROUTES.PUBLISHED_INCENTIVES_VIEW.path,
      APPS.INCENTIVES.ROUTES.APPROVAL_DETAIL_VIEW.path,
      APPS.INCENTIVES.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.INCENTIVES.ROUTES.PAYOUT_REQUEST_PAGE.path,
    ],
    [USER_ROLES.SALES_MANAGER]: [
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path,
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD.path,
      APPS.INCENTIVES.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.INCENTIVES.ROUTES.PAYOUT_REQUEST_PAGE.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD_DETAILS_PAGE.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD_PROGRAM_DETAIL_VIEW_PAGE.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path,
    ],
    [USER_ROLES.SALES_MANAGER_AS_APPROVER]: [
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path,
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD.path,
      APPS.INCENTIVES.ROUTES.APPROVAL.path,
      APPS.INCENTIVES.ROUTES.PUBLISHED_INCENTIVES_VIEW.path,
      APPS.INCENTIVES.ROUTES.APPROVAL_DETAIL_VIEW.path,
      APPS.INCENTIVES.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.INCENTIVES.ROUTES.PAYOUT_REQUEST_PAGE.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD_DETAILS_PAGE.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD_PROGRAM_DETAIL_VIEW_PAGE.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path,
    ],
    [USER_ROLES.ADMIN]: [
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path,
      APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD.path,
      APPS.INCENTIVES.ROUTES.APPROVAL.path,
      APPS.INCENTIVES.ROUTES.APPROVAL_DETAIL_VIEW.path,
      APPS.INCENTIVES.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.INCENTIVES.ROUTES.PAYOUT.path,
      APPS.INCENTIVES.ROUTES.PAYOUT_DETAIL_VIEW.path,
      APPS.INCENTIVES.ROUTES.MANAGE_PROGRAM.path,
      APPS.INCENTIVES.ROUTES.INCENTIVE_CRUD.path,
      APPS.INCENTIVES.ROUTES.PUBLISHED_INCENTIVES_VIEW.path,
      APPS.INCENTIVES.ROUTES.RULESETS.path,
      APPS.INCENTIVES.ROUTES.PAYOUT_REQUEST_PAGE.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD_DETAILS_PAGE.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD_PROGRAM_DETAIL_VIEW_PAGE.path,
      APPS.INCENTIVES.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path,
    ],
  };

  const incentivesRbac = (route) => {
    switch (route) {
      case APPS.INCENTIVES.ROUTES.ROOT.path:
      case APPS.INCENTIVES.ROUTES.LANDING.path: {
        return {
          hasAccess: isFeatureEnabled.Incentives,
          redirect: APPS.ACE.ROUTES.ROOT.path,
        };
      }

      default: {
        return {
          hasAccess: ACCESS_ROUTES[getRole()].includes(route),
          redirect: DEFAULT_REDIRECTION[getRole()],
        };
      }
    }
  };

  return {
    incentivesRbac,
    DEFAULT_LOGGED_IN_USER_REDIRECTION: DEFAULT_REDIRECTION[getRole()],
  };
};

export default useIncentivesRbac;
