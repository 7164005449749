import { t } from "i18next";
import React from "react";
import { Truncate } from "src/components/common/atoms";
import { COLORS } from "src/constants";
import { SvgClock, SvgInProgress, SvgTick, SvgUnpublish } from "src/icons";

const AccordianContent = (props) => {
  const { data, contentTitle } = props;
  const statusIcon = {
    UNPUBLISHED: {
      label: t("COMMON.LABELS.UNPUBLISHED"),
      icon: <SvgUnpublish color={COLORS.GREY_2} />,
    },
    COMPLETED: {
      label: t("COMMON.LABELS.COMPLETED"),
      icon: (
        <SvgTick
          width="20"
          height="20"
          color={COLORS.PRIMARY_BLUE}
          backgroundColor="none"
        />
      ),
    },
    YETTOSTART: {
      label: t("COMMON.LABELS.YET_TO_START"),
      icon: (
        <SvgClock
          width="18"
          height="18"
          backgroundColor="none"
          color={COLORS.GREY_2}
          className="mr-1"
        />
      ),
    },
    INPROGRESS: {
      label: t("MANAGED_GOALS.MESSAGES.IN_PROGRESS"),
      icon: <SvgInProgress color="#2ecc71" className="mr-1" />,
    },
    DRAFT: {
      label: t("MANAGED_GOALS.LABELS.DRAFT"),
      icon: (
        <SvgClock
          width="18"
          height="18"
          backgroundColor="none"
          color={COLORS.GREY_2}
          className="mr-1"
        />
      ),
    },
    INGAPS: {
      label: t("COMMON.LABELS.GAP_PHASE"),
      icon: (
        <SvgClock
          width="18"
          height="18"
          backgroundColor="none"
          color={COLORS.GREY_2}
          className="mr-1"
        />
      ),
    },
    PUBLISHED: {
      label: t("COMMON.LABELS.PUBLISHED"),
      icon: <SvgInProgress color="#2ecc71" className="mr-1" />,
    },
  };

  return (
    <div className="mx-4">
      <table className="w-100">
        <tr className="border-b">
          <td className=" py-2 mb-1 fs-12 fc-grey semi-bold w-300">
            {contentTitle}
          </td>
          <td className="py-2 mb-1 fs-12 fc-grey semi-bold">Status</td>
        </tr>
        {data?.map((item) => (
          <tr className="fs-12">
            <td className="d-flex fc-grey py-1 w-280">
              <Truncate
                id="template-id"
                maxWidth="250px"
                text={item.Name}
                truncType="end"
              />
            </td>
            <td className="pl-1 fs-12 w-100">
              <div className="d-flex align-items-center">
                {statusIcon[item.Status]?.icon}
                <p className="pl-1 d-inline-flex">
                  {statusIcon[item.Status]?.label}
                </p>
              </div>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default AccordianContent;
