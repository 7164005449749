import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ButtonShimmer, RecurrenceList } from "src/components/common/atoms";
import {
  FormBadge,
  FormButton,
  FormCheck,
} from "src/components/common/formElements";
import { MONTH_OPTIONS } from "src/constants";
import {
  addDays,
  differenceInDays,
  endOfDay,
  formatRecurrencePeriod,
  formatToString,
  getIndex,
  getPluralText,
  isAfter,
  isBefore,
  isSameDay,
  range,
  startOfDay,
  startOfYear,
} from "src/helpers";
import "./copyToAllModal.scss";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "130px",
    paddingLeft: "1rem",
    paddingTop: "0px !important",
    fontSize: "12px",
    marginLeft: "-4px",
  }),
  menu: (provided) => ({
    ...provided,
    borderTop: "1px solid #309afc",
    marginTop: "-1px !important",
    width: "114px",
  }),
  singleValue: (provided) => ({
    ...provided,
    height: "20px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "black",
    cursor: "pointer",
    backgroundColor: state.isFocused ? "#f1fafe" : "",
    padding: "12px 16px",
  }),
};

const handleDisabledPastCycles = (rec) => rec?.RecurrenceStatus === "COMPLETED";

export const CopyToAllModalProps = {
  show: PropTypes.bool,
  copyText: PropTypes.node,
  modalTitle: PropTypes.node,
  handleSave: PropTypes.func,
  copySubText: PropTypes.node,
  handleClose: PropTypes.func,
  recurrences: PropTypes.array,
  loading: PropTypes.bool,
  disabledSaveBtn: PropTypes.bool,
  saveBtnText: PropTypes.node,
  selectedRecurrence: PropTypes.object,
};

export const CopyToAllModalDefaultProps = {
  show: false,
  copyText: "",
  modalTitle: "",
  handleSave: () => {},
  copySubText: "",
  handleClose: () => {},
  recurrences: [],
  loading: false,
  disabledSaveBtn: false,
  saveBtnText: "",
  selectedRecurrence: {},
};

const CopyToAllModal = (props) => {
  const {
    show,
    copyText,
    modalTitle,
    copySubText,
    handleClose,
    recurrences,
    loading,
    handleSave,
    disabledSaveBtn,
    saveBtnText,
    selectedRecurrence,
    customType,
    selectedRecurrenceCopyText,
    disablePastCycles = false,
    handleDisableCycles,
  } = props;

  const { t } = useTranslation();

  const [recurrenceList, setRecurrenceList] = useState({});
  const [filterYear, setFilterYear] = useState(null);
  const [filterMonth, setFilterMonth] = useState(null);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkedRecurrences, setCheckedRecurrences] = useState([]);

  const getTotalRecurrenceCount = () => {
    if (disablePastCycles) {
      return recurrences.filter(
        (rec) =>
          !handleDisabledPastCycles(rec) &&
          rec.RecurrenceId !== selectedRecurrence?.RecurrenceId
      ).length;
    }
    if (handleDisableCycles) {
      return recurrences.filter(
        (rec) =>
          !handleDisableCycles(rec, selectedRecurrence) &&
          rec.RecurrenceId !== selectedRecurrence?.RecurrenceId
      ).length;
    }
    return recurrences.length - 1;
  };

  useEffect(() => {
    if (recurrences) {
      const recurrencesList = {};
      recurrences.forEach((recurrence) => {
        recurrencesList[recurrence.RecurrenceId] =
          recurrence?.RecurrenceId === selectedRecurrence?.RecurrenceId;
      });
      setRecurrenceList({ ...recurrencesList });
    }
  }, [recurrences, selectedRecurrence?.RecurrenceId]);

  useEffect(() => {
    const totalRecurrences = getTotalRecurrenceCount();
    if (totalRecurrences === checkedRecurrences.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedRecurrences, recurrences]);

  const disabledMonth = (obj) => {
    if (
      filterYear ===
        new Date(recurrences[0]?.RecurrenceStartDate).getFullYear() &&
      new Date(recurrences[0]?.RecurrenceStartDate).getMonth() > obj &&
      obj !== null
    ) {
      return true;
    }
    if (
      filterYear ===
        new Date(
          recurrences[recurrences?.length - 1]?.RecurrenceStartDate
        ).getFullYear() &&
      new Date(
        recurrences[recurrences?.length - 1]?.RecurrenceStartDate
      ).getMonth() < obj
    ) {
      return true;
    }
  };
  const monthOptions = [{ value: null, label: "All" }, ...MONTH_OPTIONS].map(
    (obj) => ({
      ...obj,
      isDisabled: disabledMonth(obj.value),
    })
  );
  const curr_year = new Date(recurrences[0]?.RecurrenceStartDate).getFullYear();
  const max_year = new Date(
    recurrences[recurrences?.length - 1]?.RecurrenceStartDate
  ).getFullYear();
  const yearOptions = [{ value: null, label: "All" }];
  range(curr_year, max_year).map((year) => {
    return yearOptions.push({
      value: year,
      label: year,
    });
  });

  const itemCheckbox = (props) => {
    const { RecurrenceId } = props;
    const checkDisabled = () => {
      const index = getIndex(recurrences, { RecurrenceId });
      if (disablePastCycles) {
        return (
          handleDisabledPastCycles(recurrences[index]) ||
          RecurrenceId === selectedRecurrence?.RecurrenceId
        );
      }
      if (handleDisableCycles) {
        return (
          handleDisableCycles(recurrences[index], selectedRecurrence) ||
          RecurrenceId === selectedRecurrence?.RecurrenceId
        );
      }
      return RecurrenceId === selectedRecurrence?.RecurrenceId;
    };
    const handleClick = () => {
      const newRecurrenceList = { ...recurrenceList };
      newRecurrenceList[RecurrenceId] = !newRecurrenceList[RecurrenceId];
      setRecurrenceList(newRecurrenceList);
      if (newRecurrenceList[RecurrenceId]) {
        setCheckedRecurrences([...checkedRecurrences, RecurrenceId]);
      } else {
        setCheckedRecurrences(
          checkedRecurrences.filter((id) => id !== RecurrenceId)
        );
      }
    };
    return (
      <FormCheck
        id={RecurrenceId}
        disabled={checkDisabled()}
        checked={recurrenceList[RecurrenceId]}
        onClick={handleClick}
      />
    );
  };

  const formatOptionLabelAfter = ({ RecurrenceId }) =>
    RecurrenceId === selectedRecurrence?.RecurrenceId ? (
      <p className="fs-12 normal">
        {selectedRecurrenceCopyText}
        {t("MANAGED_GOALS.LABELS.COPYING_FROM_CYCLE")}
      </p>
    ) : (
      <></>
    );

  const optionLabelColor = ({ RecurrenceId }) =>
    RecurrenceId === selectedRecurrence?.RecurrenceId ? "fc-grey1" : "";

  const filterMonthCheck = (recurrence) => {
    if (filterMonth === null) return true;
    return new Date(recurrence.RecurrenceStartDate).getMonth() === filterMonth;
  };

  const filterYearCheck = (recurrence) => {
    if (filterYear === null) return true;
    const startDate = new Date(recurrence.RecurrenceStartDate);
    const endDate = new Date(recurrence.RecurrenceEndDate);
    const yearStart = startOfYear(new Date(filterYear?.toString()));
    return (
      startDate.getFullYear() === filterYear ||
      ((isSameDay(startDate, yearStart) || isAfter(yearStart, startDate)) &&
        (isSameDay(endDate, yearStart) || isBefore(yearStart, endDate)))
    );
  };

  const filterRecurrences = () =>
    recurrences.filter(
      (recurrence) =>
        filterYearCheck(recurrence) && filterMonthCheck(recurrence)
    );

  const handleSelectAll = () => {
    const newRecurrenceList = { ...recurrenceList };
    let newCheckedRecurrences = [];
    if (disablePastCycles) {
      Object.keys(newRecurrenceList).forEach((key) => {
        if (
          key !== selectedRecurrence?.RecurrenceId &&
          !handleDisabledPastCycles(
            recurrences[getIndex(recurrences, { RecurrenceId: key })]
          )
        ) {
          newRecurrenceList[key] = !isCheckAll;
        }
      });
      newCheckedRecurrences = !isCheckAll
        ? Object.keys(newRecurrenceList).filter((id) => {
            return (
              id !== selectedRecurrence?.RecurrenceId &&
              !handleDisabledPastCycles(
                recurrences[getIndex(recurrences, { RecurrenceId: id })]
              )
            );
          })
        : [];
    } else if (handleDisableCycles) {
      Object.keys(newRecurrenceList).forEach((key) => {
        if (
          key !== selectedRecurrence?.RecurrenceId &&
          !handleDisableCycles(
            recurrences[getIndex(recurrences, { RecurrenceId: key })],
            selectedRecurrence
          )
        ) {
          newRecurrenceList[key] = !isCheckAll;
        }
      });
      newCheckedRecurrences = !isCheckAll
        ? Object.keys(newRecurrenceList).filter((id) => {
            return (
              id !== selectedRecurrence?.RecurrenceId &&
              !handleDisableCycles(
                recurrences[getIndex(recurrences, { RecurrenceId: id })],
                selectedRecurrence
              )
            );
          })
        : [];
    } else {
      Object.keys(newRecurrenceList).forEach((key) => {
        if (key !== selectedRecurrence?.RecurrenceId) {
          newRecurrenceList[key] = !isCheckAll;
        }
      });
      newCheckedRecurrences = !isCheckAll
        ? Object.keys(newRecurrenceList).filter(
            (id) => id !== selectedRecurrence?.RecurrenceId
          )
        : [];
    }
    setCheckedRecurrences(newCheckedRecurrences);
    setRecurrenceList(newRecurrenceList);
    setIsCheckAll(!isCheckAll);
  };

  const handleCancel = () => {
    setRecurrenceList({});
    setCheckedRecurrences([]);
    setIsCheckAll(false);
    handleClose();
  };

  if (recurrences && selectedRecurrence)
    return (
      <Modal
        show={show}
        onHide={handleCancel}
        centered
        scrollable
        dialogClassName="copy-distribute-container"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="fs-13 center">
            <span className="mr-1">{copyText}</span>
            <span className="semi-bold">
              <span className="fc-grey1 mr-1">
                #{getIndex(recurrences, selectedRecurrence) + 1}:
              </span>
              <span>{formatToString(selectedRecurrence)}</span>
            </span>
          </div>
          <div className="center my-3">{copySubText}</div>
          <div className="center mb-3">
            <div className="center semi-bold">
              <FormCheck
                id="select-all"
                label={`${t(
                  "MANAGED_GOALS.LABELS.SELECT_ALL"
                )} ${getTotalRecurrenceCount()} ${formatRecurrencePeriod({
                  recurrencePeriod: selectedRecurrence?.RecurrenceType,
                  customType,
                })} ${t("MANAGED_GOALS.MESSAGES.CYCLES")}`}
                checked={isCheckAll}
                onClick={handleSelectAll}
              />
            </div>
            {selectedRecurrence?.RecurrenceType === "CUSTOM" &&
              customType === "UNIFORM" && (
                <div className="center bg-grey5 fs-11 ml-3 fc-grey1 semi-bold br-13 px-2 py-1">
                  {getPluralText(
                    differenceInDays(
                      endOfDay(
                        addDays(selectedRecurrence?.RecurrenceEndDate, 1)
                      ),
                      startOfDay(selectedRecurrence?.RecurrenceStartDate)
                    ),
                    t("INCENTIVES.COMMON.MESSAGES.DAY"),
                    t("INCENTIVES.COMMON.MESSAGES.DAYS")
                  )}
                </div>
              )}
            <div className="ml-auto center fs-12 semi-bold">
              <span className="mr-2">{t("COMMON.LABELS.FILTER")}</span>
              <span className="mr-2">
                <Select
                  placeholder="Year"
                  classNamePrefix="drop-select"
                  isSearchable={false}
                  className="cal-dropdown"
                  options={yearOptions}
                  styles={customStyles}
                  defaultValue={yearOptions[0]}
                  value={
                    yearOptions[
                      filterYear === null
                        ? 0
                        : yearOptions.findIndex(
                            (option) => option.value === filterYear
                          )
                    ]
                  }
                  onChange={(e) => {
                    setFilterYear(e.value);
                    setFilterMonth(null);
                  }}
                />
              </span>
              {["DAILY", "WEEKLY", "BIWEEKLY"].includes(
                selectedRecurrence?.RecurrenceType
              ) && (
                <span>
                  <Select
                    placeholder="Month"
                    classNamePrefix="drop-select"
                    isSearchable={false}
                    styles={customStyles}
                    className="cal-dropdown"
                    isDisabled={filterYear === null}
                    options={monthOptions}
                    defaultValue={monthOptions[0]}
                    value={
                      monthOptions[filterMonth === null ? 0 : filterMonth + 1]
                    }
                    onChange={(e) => setFilterMonth(e.value)}
                  />
                </span>
              )}
            </div>
          </div>
          <div className="copy-distribution-list bg-grey6 py-3 px-4">
            <RecurrenceList
              as="li"
              recurrences={filterRecurrences()}
              recurrenceType={selectedRecurrence?.RecurrenceType}
              formatOptionLabelBefore={itemCheckbox}
              formatOptionLabelAfter={formatOptionLabelAfter}
              optionLabelColor={optionLabelColor}
              customType={customType}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="center">
            <FormBadge className="bg-grey5 fc-grey1 mr-auto">
              {checkedRecurrences.length > 0 && (
                <>
                  <span className="semi-bold mr-1">
                    {checkedRecurrences.length}
                  </span>{" "}
                  {checkedRecurrences.length > 1
                    ? t("COMMON.BUTTONS.CYCLES")
                    : t("MANAGED_GOALS.LABELS.ROLL_UP_CYCLE")}{" "}
                  {t("LEADERBOARD.BUTTONS.SELECTED")}
                </>
              )}
            </FormBadge>
          </div>
          <div className="ml-auto center">
            <FormButton
              id="copy-distribution-close-btn"
              className="mr-3"
              variant="outline-primary"
              onClick={handleCancel}
              disabled={loading}
              label={t("COMMON.BUTTONS.CLOSE")}
            />
            {!loading ? (
              <FormButton
                id="distribute-selected-cycle"
                onClick={() => {
                  handleSave(checkedRecurrences);
                  setRecurrenceList({});
                  setCheckedRecurrences([]);
                  setIsCheckAll(false);
                }}
                disabled={checkedRecurrences.length === 0 || disabledSaveBtn}
                label={saveBtnText}
              />
            ) : (
              <ButtonShimmer text={saveBtnText} />
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  return <></>;
};

CopyToAllModal.propTypes = CopyToAllModalProps;

CopyToAllModal.defaultProps = CopyToAllModalDefaultProps;

export default CopyToAllModal;
