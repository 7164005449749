import React from "react";
import Modal from "react-bootstrap/Modal";
import { ButtonShimmer, CreateInput } from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import { CREATE_PROGRAM_REGEX } from "src/constants";

const CreateModal = (props) => {
  const {
    show,
    handleCancel,
    handleSubmit,
    disabledSubmit,
    submitCtaText,
    cancelCtaText,
    nameValue,
    setNameValue,
    nameWarning,
    setNameWarning,
    nameLabel,
    namePlaceholder,
    descriptionValue,
    setDescriptionValue,
    descriptionLabel,
    descriptionPlaceholder,
    modalTitle,
    loading,
    loadingCtaText,
  } = props;

  return (
    <Modal
      dialogClassName="create-goal-modal"
      show={show}
      onHide={handleCancel}
      centered
    >
      <Modal.Header closeButton className="fs-18 semi-bold">
        {modalTitle}
      </Modal.Header>
      <Modal.Body className="modal_body">
        <div className="p-4">
          <CreateInput
            disabled
            id="ace-create-Name-input"
            label={nameLabel}
            placeholder={namePlaceholder}
            height="60px"
            maxLength={100}
            warning={nameWarning}
            regex={CREATE_PROGRAM_REGEX}
            setWarning={setNameWarning}
            value={nameValue}
            setValue={setNameValue}
          />
          <CreateInput
            disabled
            as="textarea"
            id="ace-create-description-input"
            label={descriptionLabel}
            placeholder={descriptionPlaceholder}
            regex={false}
            maxLength={500}
            height="100px"
            value={descriptionValue}
            setValue={setDescriptionValue}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          onClick={handleCancel}
          id="cancel-goal-btn"
          label={cancelCtaText}
          variant="outline-primary"
        />

        <div className="d-inline-block">
          {loading ? (
            <ButtonShimmer
              className="br-2 fs-14"
              height={40}
              width="auto"
              text={loadingCtaText}
            />
          ) : (
            <FormButton
              id="create-goal-btn"
              className="fs-14"
              disabled={disabledSubmit}
              onClick={handleSubmit}
              type="submit"
              label={submitCtaText}
            />
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateModal;
