import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const INSERT_PROGRAM_IMPORT_RECORDS_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation insertProgramImportRecords($ProgramId: ID!, $FileId: Float!) {
    insertProgramImportRecords(ProgramId: $ProgramId, FileId: $FileId) {
      ... on insertImportRecordsResponse {
        success
        message
        response
      }
      ...errorResponseFragment
    }
  }
`;

export const useInsertProgramImportRecords = () => {
  return useMutation(INSERT_PROGRAM_IMPORT_RECORDS_MUTATION);
};
