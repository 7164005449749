import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_METRIC_RECURRENCE = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getMetricRecurrences($ProgramId: ID!, $RecurrenceId: ID!, $GoalId: ID) {
    getMetricRecurrences(
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      GoalId: $GoalId
    ) {
      ... on metricRecurrencesResponse {
        success
        message
        response {
          Recurrences {
            RecurrenceId
            RecurrenceStartDate
            RecurrenceEndDate
            IsDisabled
            GoalRecurrenceExist
            GoalRecurrenceId
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetIncentiveMetricRecurrence = (queryOptions) => {
  return useQuery(GET_METRIC_RECURRENCE, {
    fetchPolicy: "no-cache",
    ...queryOptions,
  });
};
