import { useCallback, useReducer, useRef } from "react";
import { isEmpty, isEqual } from "src/helpers";
import { FILTERS } from "../data";
import { generateRandomKey } from "../utils";

const INITIAL_STATE = ({ showDrawer, filters, selectedFilterIndex }) => {
  return {
    filters: filters?.[0] ? filters : FILTERS,
    selectedFilterIndex: selectedFilterIndex || 0,
    selectedConditionIndex: 0,
    drawerOpen: showDrawer,
  };
};

const CONSTRAINTS = {
  MAX_CONDITION_COUNT: 10,
  MAX_FILTERS_COUNT: 10,
};

const ACTIONS = {
  ADD_FILTER: "ADD_FILTER",
  UPDATE_FILTER: "UPDATE_FILTER",
  DELETE_FILTER: "DELETE_FILTER",
  RENAME_FILTER: "RENAME_FILTER",
  SET_SELECTED_FILTER_INDEX: "SET_SELECTED_FILTER_INDEX",
  ADD_CONDITION: "ADD_CONDITION",
  DELETE_CONDITION: "DELETE_CONDITION",
  UPDATE_CONDITION: "UPDATE_CONDITION",
  SET_CONDITION_PATTERN: "SET_CONDITION_PATTERN",
  SET_SELECTED_CONDITION_INDEX: "SET_SELECTED_CONDITION_INDEX",
  SET_DRAWER_OPEN: "SET_DRAWER_OPEN",
  SET_FILTER_EDIT_STATUS: "SET_FILTER_EDIT_STATUS",
};

const conditionReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.ADD_FILTER: {
      const { filter, handleSaveFilterAsUserPreference, savedFilters } =
        payload;
      const { filters, selectedFilterIndex } = state;
      const updatedFilters = JSON.parse(JSON.stringify(filters));
      let filterIndexToUpdate = 0;
      if (!updatedFilters[selectedFilterIndex]?.FilterName) {
        updatedFilters[selectedFilterIndex] = {
          ...filter,
          id: generateRandomKey(),
          isFilterEdited: false,
        };
        filterIndexToUpdate = selectedFilterIndex;
      } else if (updatedFilters.length < CONSTRAINTS.MAX_FILTERS_COUNT) {
        updatedFilters[selectedFilterIndex] = savedFilters?.find(
          ({ id }) => id === updatedFilters[selectedFilterIndex]?.id
        );
        updatedFilters.push({
          ...filter,
          id: generateRandomKey(),
          isFilterEdited: false,
        });
        filterIndexToUpdate = updatedFilters?.length - 1;
      }

      handleSaveFilterAsUserPreference({
        type: ACTIONS.ADD_FILTER,
        filterState: updatedFilters,
        index: filterIndexToUpdate,
      });
      return {
        ...state,
        selectedFilterIndex: filterIndexToUpdate,
        filters: updatedFilters,
      };
    }

    case ACTIONS.UPDATE_FILTER: {
      const { index, handleSaveFilterAsUserPreference } = payload;
      const { filters } = state;
      const updatedFilters = JSON.parse(JSON.stringify(filters));

      updatedFilters[index] = {
        ...updatedFilters[index],
        isFilterEdited: false,
      };

      handleSaveFilterAsUserPreference({
        type: ACTIONS.UPDATE_FILTER,
        index,
        filterState: updatedFilters,
      });
      return {
        ...state,
        selectedFilterIndex: index,
        filters: updatedFilters,
      };
    }

    case ACTIONS.DELETE_FILTER: {
      const { index, handleSaveFilterAsUserPreference } = payload;
      const { filters, selectedFilterIndex } = state;
      const updatedFilters = JSON.parse(JSON.stringify(filters));
      const selectedFilterId = filters?.[selectedFilterIndex]?.id;
      const removedFilter = updatedFilters?.splice(index, 1);
      if (!updatedFilters?.[0]?.FilterConditions) {
        updatedFilters[0] = FILTERS[0];
      }
      handleSaveFilterAsUserPreference({
        type: ACTIONS.DELETE_FILTER,
        index,
      });

      const filterIndex =
        removedFilter?.id === selectedFilterId
          ? -1
          : filters?.findIndex(({ id }) => id === selectedFilterId);

      return {
        ...state,
        filters: updatedFilters,
        selectedFilterIndex: filterIndex,
        selectedConditionIndex: -1,
      };
    }

    case ACTIONS.RENAME_FILTER: {
      const { index, FilterName, handleSaveFilterAsUserPreference } = payload;
      const { filters } = state;
      const updatedFilter = JSON.parse(JSON.stringify(filters));
      updatedFilter[index].FilterName = FilterName;

      handleSaveFilterAsUserPreference({
        type: ACTIONS.RENAME_FILTER,
        index,
        filterState: updatedFilter,
      });
      return {
        ...state,
        filters: updatedFilter,
      };
    }

    case ACTIONS.SET_SELECTED_FILTER_INDEX: {
      const { index } = payload;
      return {
        ...state,
        selectedFilterIndex: index,
      };
    }

    case ACTIONS.ADD_CONDITION: {
      const { condition, index } = payload;
      const { filters, selectedFilterIndex } = state;
      const updatedFilter = JSON.parse(JSON.stringify(filters));
      const conditions = updatedFilter[selectedFilterIndex]?.FilterConditions;
      if (conditions?.length < CONSTRAINTS.MAX_CONDITION_COUNT) {
        conditions?.splice(index, 0, condition);
      }

      updatedFilter[selectedFilterIndex] = {
        ...updatedFilter[selectedFilterIndex],
        FilterConditions: conditions,
        isFilterEdited: isEmpty(condition)
          ? updatedFilter[selectedFilterIndex]?.isFilterEdited
          : true,
      };

      return {
        ...state,
        selectedConditionIndex: index,
        filters: updatedFilter,
      };
    }

    case ACTIONS.DELETE_CONDITION: {
      const { index, removingDummyCondition } = payload;
      const { filters, selectedFilterIndex } = state;
      const updatedFilters = JSON.parse(JSON.stringify(filters));

      const conditions =
        updatedFilters[selectedFilterIndex]?.FilterConditions || [];
      if (conditions[index]) conditions.splice(index, 1);

      updatedFilters[selectedFilterIndex] = {
        ...updatedFilters[selectedFilterIndex],
        ConditionPattern:
          conditions?.length < 2 && removingDummyCondition
            ? ""
            : updatedFilters[selectedFilterIndex]?.ConditionPattern,
        FilterConditions: conditions,
        isFilterEdited: removingDummyCondition
          ? updatedFilters[selectedFilterIndex]?.isFilterEdited
          : true,
      };

      return {
        ...state,
        filters: updatedFilters,
        selectedConditionIndex: -1,
      };
    }

    case ACTIONS.UPDATE_CONDITION: {
      const { index, condition } = payload;
      const { filters, selectedFilterIndex } = state;
      const updatedFilters = JSON.parse(JSON.stringify(filters));
      const conditions = updatedFilters[selectedFilterIndex]?.FilterConditions;
      conditions[index] = condition;
      updatedFilters[selectedFilterIndex] = {
        ...updatedFilters[selectedFilterIndex],
        FilterConditions: conditions,
        isFilterEdited: true,
      };

      return {
        ...state,
        selectedConditionIndex: index,
        filters: updatedFilters,
      };
    }
    case ACTIONS.SET_SELECTED_CONDITION_INDEX: {
      const { index } = payload;
      return {
        ...state,
        selectedConditionIndex: index,
      };
    }

    case ACTIONS.SET_CONDITION_PATTERN: {
      const { conditionPattern } = payload;
      const { filters, selectedFilterIndex } = state;
      const updatedFilters = JSON.parse(JSON.stringify(filters));
      if (
        isEqual(
          updatedFilters[selectedFilterIndex]?.ConditionPattern,
          conditionPattern
        ) ||
        !updatedFilters
      ) {
        return {
          ...state,
        };
      }
      updatedFilters[selectedFilterIndex] = {
        ...updatedFilters[selectedFilterIndex],
        ConditionPattern: conditionPattern,
        isFilterEdited: true,
      };
      return {
        ...state,
        filters: updatedFilters,
      };
    }

    case ACTIONS.SET_FILTER_EDIT_STATUS: {
      const { index } = payload;
      const { filters } = state;
      const updatedFilters = JSON.parse(JSON.stringify(filters));
      updatedFilters[index] = {
        ...updatedFilters[index],
        isFilterEdited: false,
      };
      return {
        ...state,
        filters: updatedFilters,
      };
    }
    case ACTIONS.SET_DRAWER_OPEN: {
      const { drawerOpen } = payload;
      return { ...state, drawerOpen };
    }
    default:
      return { ...state };
  }
};

export function useAdvanceConditions({
  showDrawer,
  filters,
  selectedFilterIndex,
  updateGoalsConfig,
  userPreferences,
}) {
  const [state, dispatch] = useReducer(conditionReducer, {
    ...INITIAL_STATE({ showDrawer, filters, selectedFilterIndex }),
  });

  const handleSaveFilterAsUserPreference = async ({
    index,
    type,
    filterState,
  }) => {
    let filters = [];
    if (userPreferences?.goalsConfig?.PublishedGoalView?.AdvancedFilters) {
      filters = JSON.parse(
        JSON.stringify(
          userPreferences?.goalsConfig?.PublishedGoalView?.AdvancedFilters
        )
      );
    }
    switch (type) {
      case ACTIONS.ADD_FILTER: {
        filters.push({ ...filterState[index], isFilterEdited: false });
        break;
      }
      case ACTIONS.UPDATE_FILTER: {
        filters[index] = { ...filterState[index], isFilterEdited: false };
        break;
      }
      case ACTIONS.RENAME_FILTER: {
        if (filters?.[index]?.FilterName) {
          filters[index].FilterName = filterState[index]?.FilterName;
        }
        break;
      }
      case ACTIONS.DELETE_FILTER: {
        if (filters?.[index]) {
          filters.splice(index, 1);
        }
        break;
      }
      default:
        return filters;
    }
    updateGoalsConfig({
      PublishedGoalView: {
        ...userPreferences?.goalsConfig?.PublishedGoalView,
        AdvancedFilters: filters,
      },
    }).then(() => {
      dispatch({
        type: ACTIONS.SET_FILTER_EDIT_STATUS,
        payload: { index },
      });
    });
  };

  const addFilter = ({ filter, index }) => {
    dispatch({
      type: ACTIONS.ADD_FILTER,
      payload: {
        filter,
        index,
        handleSaveFilterAsUserPreference,
        savedFilters:
          userPreferences?.goalsConfig?.PublishedGoalView?.AdvancedFilters,
      },
    });
  };

  const updateFilter = ({ index }) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      payload: { index, handleSaveFilterAsUserPreference },
    });
  };

  const deleteFilter = ({ index }) => {
    dispatch({
      type: ACTIONS.DELETE_FILTER,
      payload: { index, handleSaveFilterAsUserPreference },
    });
  };

  const renameFilter = ({ index, FilterName }) => {
    dispatch({
      type: ACTIONS.RENAME_FILTER,
      payload: { index, FilterName, handleSaveFilterAsUserPreference },
    });
  };

  const setSelectedFilterIndex = ({ index }) => {
    dispatch({ type: ACTIONS.SET_SELECTED_FILTER_INDEX, payload: { index } });
  };

  const addCondition = ({ condition, index }) => {
    dispatch({
      type: ACTIONS.ADD_CONDITION,
      payload: { condition, index },
    });
  };

  const updateCondition = ({ index, condition }) => {
    dispatch({
      type: ACTIONS.UPDATE_CONDITION,
      payload: { index, condition },
    });
  };

  const deleteCondition = ({ index, removingDummyCondition = false }) => {
    dispatch({
      type: ACTIONS.DELETE_CONDITION,
      payload: { index, removingDummyCondition },
    });
  };

  const setSelectedConditionIndex = ({ index }) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_CONDITION_INDEX,
      payload: { index },
    });
  };

  const setConditionPattern = useCallback(({ conditionPattern }) => {
    dispatch({
      type: ACTIONS.SET_CONDITION_PATTERN,
      payload: { conditionPattern },
    });
  }, []);

  const setdrawerOpen = (drawerOpen) => {
    dispatch({
      type: ACTIONS.SET_DRAWER_OPEN,
      payload: { drawerOpen },
    });
  };
  const handleClose = ({ index, removingDummyCondition = true }) => {
    if (
      isEmpty(
        state?.filters[state?.selectedFilterIndex]?.FilterConditions?.[index]
      )
    )
      deleteCondition({ index, removingDummyCondition });
    setdrawerOpen(false);
  };

  const handleAddCondition = ({ condition, index }) => {
    handleClose({ index, removingDummyCondition: false });
    addCondition({ condition, index });
    setdrawerOpen(false);
  };

  const handleUpdateCondition = ({ condition, index }) => {
    updateCondition({ condition, index });
    setdrawerOpen(false);
  };

  const handleAddClick = ({ index }) => {
    addCondition({ condition: {}, index });
    setdrawerOpen(true);
  };

  const handleUpdateClick = ({ index }) => {
    setSelectedConditionIndex({ index });
    setdrawerOpen(true);
  };

  const disableAdd =
    state?.filters?.[state?.selectedFilterIndex]?.FilterConditions?.length >=
    CONSTRAINTS.MAX_CONDITION_COUNT;

  const filterRef = useRef(null);

  return {
    state: { ...state, disableAdd, filterRef },
    actions: {
      filterActions: {
        addFilter,
        updateFilter,
        deleteFilter,
        renameFilter,
        setSelectedFilterIndex,
        handleSaveFilterAsUserPreference,
      },
      conditionActions: {
        addCondition,
        deleteCondition,
        updateCondition,
        setConditionPattern,
        setSelectedConditionIndex,
        setdrawerOpen,
        handleAddCondition,
        handleUpdateClick,
        handleAddClick,
        handleClose,
        handleUpdateCondition,
      },
    },
  };
}

export default useAdvanceConditions;
