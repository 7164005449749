import { Dropdown } from "react-bootstrap";
import { Truncate } from "src/components/common/atoms";
import { classNames } from "src/helpers";
import "./formulaMenu.scss";

const FormulaMenu = ({ menuList = [], handleClick }) => {
  return (
    <div className="formula-menu">
      {menuList.map((token, index) => (
        <div className={classNames(["border-b", token.type.toLowerCase()])}>
          <span className="fs-10 p-12 fc-grey1">{token.label}</span>
          {token.description ? (
            <div className="fs-12 px-12 fc-grey font-italic">
              {token.description}
            </div>
          ) : null}
          {token.values?.map(({ label, value, icon }) => (
            <Dropdown.Item
              onClick={() => {
                handleClick(value);
              }}
              className="fs-12 px-12 py-6 flex-start"
            >
              {icon && <span className="mr-3 fc-black">{icon}</span>}
              {label && (
                <span className={classNames(["fc-grey1 menu-label"])}>
                  <Truncate
                    tooltipPlacement="left"
                    maxWidth="150px"
                    text={label}
                  />
                </span>
              )}
            </Dropdown.Item>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FormulaMenu;
