import { gql, useQuery } from "src/api/client";

export const GET_ACTIVITY_FIELDS = gql`
  query getProspectActivitydata {
    getProspectActivitydata {
      EventType
      ActivityEvent
      DisplayName
      Fields
      AuditActivity
    }
  }
`;
export const useGetActivityFields = (queryOptions) => {
  return useQuery(GET_ACTIVITY_FIELDS, queryOptions);
};
