import {
  AmountLabel,
  FormTooltip,
  GroupByLabel,
  RewardIcon,
  Truncate,
} from "src/components";
import {
  CONDITION_OPERATOR,
  REWARD_SUBCATEGORIES,
  REWARD_TYPE,
} from "src/constants";
import { NumFormatter, checkValue } from "src/helpers";
import { FixedReward, MultiplierReward, PercentageReward } from "src/images";
import { groupByConditionValid } from "./utils";

const baseIndex = 65;
export const getCriteriaAlphabet = (criteriaIndex) => {
  return String.fromCharCode(baseIndex + criteriaIndex);
};

export const rewardValueFormatter = {
  [REWARD_TYPE.FIXED.type]: ({ value }) => ({
    value: <AmountLabel amount={value} />,
    text: "",
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <FixedReward width={24} height={24} />,
  }),
  [REWARD_TYPE.PERCENTAGE.type]: ({ value, templateName, subGoal }) => ({
    value: `${value}%`,
    text: `Of ${templateName}`,
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <PercentageReward width={24} height={24} />,
    groupByLabel: groupByConditionValid(subGoal) && (
      <GroupByLabel groupByCondition={subGoal} />
    ),
  }),
  [REWARD_TYPE.MULTIPLIER.type]: ({ value, templateName, subGoal }) => ({
    value,
    text: `Multiplier Of ${templateName}`,
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <MultiplierReward width={24} height={24} />,
    groupByLabel: groupByConditionValid(subGoal) && (
      <GroupByLabel groupByCondition={subGoal} />
    ),
  }),
  [REWARD_TYPE.PERCENTAGE_SLAB.type]: ({ value, templateName, subGoal }) => ({
    value: `${value} Percentage Slabs - ${templateName}`,
    text: "",
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <PercentageReward width={24} height={24} />,
    groupByLabel: groupByConditionValid(subGoal) && (
      <GroupByLabel groupByCondition={subGoal} />
    ),
  }),
  [REWARD_TYPE.MULTIPLIER_SLAB.type]: ({ value, templateName, subGoal }) => ({
    value: `${value} Multiplier Slabs - ${templateName}`,
    text: "",
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <MultiplierReward width={24} height={24} />,
    groupByLabel: groupByConditionValid(subGoal) && (
      <GroupByLabel groupByCondition={subGoal} />
    ),
  }),
  [REWARD_TYPE.CUSTOM_REWARD.type]: ({ customReward }) => ({
    value: <Truncate text={customReward?.RewardName} maxWidth={150} />,
    text: "",
    subText: REWARD_SUBCATEGORIES.NON_MONETARY.text,
    icon:
      customReward?.CustomRewardImageUrl || customReward?.RewardCategory ? (
        <RewardIcon
          src={customReward?.CustomRewardImageUrl}
          width={24}
          height={24}
          rewardCategory={customReward.RewardCategory}
        />
      ) : (
        <></>
      ),
  }),
};

export const conditionValueFormatter = {
  [CONDITION_OPERATOR.BETWEEN.Key]: (value) =>
    `Between ${value.startsAt} - ${value.endsAt}`,
  [CONDITION_OPERATOR.GREATER_THAN.Key]: (value) => `Greater than ${value}`,
  [CONDITION_OPERATOR.LESS_THAN.Key]: (value) => `Less than ${value}`,
  [CONDITION_OPERATOR.GREATER_THAN_OR_EQUAL_TO.Key]: (value) =>
    `Greater than or equals to ${value}`,
  [CONDITION_OPERATOR.LESS_THAN_OR_EQUAL_TO.Key]: (value) =>
    `Less than or equals to ${value}`,
  [CONDITION_OPERATOR.IS.Key]: (value) => `Equals to ${value}`,
};

export const rewardTextFormatter = {
  [REWARD_TYPE.FIXED.type]: (value) => ({
    value: <AmountLabel amount={value} className="semi-bold" />,
    text: "",
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <FixedReward width={24} height={24} />,
  }),
  [REWARD_TYPE.PERCENTAGE.type]: (value, templateName) => ({
    value: <FormTooltip text={`${value}%`}>{NumFormatter(value)}%</FormTooltip>,
    text: "Of",
    templateName: <Truncate maxWidth="100px" text={templateName} />,
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <PercentageReward width={24} height={24} />,
  }),
  [REWARD_TYPE.MULTIPLIER.type]: (value, templateName) => ({
    value: (
      <div className="semi-bold">
        <FormTooltip text={value}>{NumFormatter(value)}</FormTooltip>
      </div>
    ),
    text: "Multiplier Of",
    templateName: <Truncate maxWidth="100px" text={templateName} />,
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <MultiplierReward width={24} height={24} />,
  }),
  [REWARD_TYPE.PERCENTAGE_SLAB.type]: (value) => ({
    value,
    text: "",
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <PercentageReward width={24} height={24} />,
  }),
  [REWARD_TYPE.MULTIPLIER_SLAB.type]: (value) => ({
    value,
    text: "",
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <MultiplierReward width={24} height={24} />,
  }),
  [REWARD_TYPE.CUSTOM_REWARD.type]: (value, templateName, customReward) => ({
    value: <Truncate text={customReward?.RewardName} maxWidth={150} />,
    text: "",
    subText: REWARD_SUBCATEGORIES.NON_MONETARY.text,
    icon:
      customReward?.CustomRewardImageUrl || customReward?.RewardCategory ? (
        <RewardIcon
          src={customReward?.CustomRewardImageUrl}
          width={24}
          height={24}
          rewardCategory={customReward.RewardCategory}
        />
      ) : (
        <></>
      ),
  }),
};
export const mobileRewardTextFormatter = {
  [REWARD_TYPE.FIXED.type]: (value) => ({
    value: <AmountLabel amount={value} className="semi-bold" />,
    text: "",
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <FixedReward width={26} height={26} />,
  }),
  [REWARD_TYPE.PERCENTAGE.type]: (value, templateName) => ({
    value: <FormTooltip text={`${value}%`}>{NumFormatter(value)}%</FormTooltip>,
    text: "Of",
    templateName: <Truncate maxWidth="50px" text={templateName} />,
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <PercentageReward width={26} height={26} />,
  }),
  [REWARD_TYPE.MULTIPLIER.type]: (value, templateName) => ({
    value: (
      <div className="">
        <FormTooltip text={value}>{NumFormatter(value)}</FormTooltip>
      </div>
    ),
    text: "Multiplier Of",
    templateName: <Truncate maxWidth="100px" text={templateName} />,
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <MultiplierReward width={26} height={26} />,
  }),
  [REWARD_TYPE.PERCENTAGE_SLAB.type]: (value) => ({
    value,
    text: "",
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <PercentageReward width={26} height={26} />,
  }),
  [REWARD_TYPE.MULTIPLIER_SLAB.type]: (value) => ({
    value,
    text: "",
    subText: REWARD_SUBCATEGORIES.MONETARY.text,
    icon: <MultiplierReward width={26} height={26} />,
  }),
  [REWARD_TYPE.CUSTOM_REWARD.type]: (value, templateName, customReward) => ({
    value: <Truncate text={customReward?.RewardName} maxWidth={50} />,
    text: "",
    subText: REWARD_SUBCATEGORIES.NON_MONETARY.text,
    icon:
      customReward?.CustomRewardImageUrl || customReward?.RewardCategory ? (
        <RewardIcon
          src={customReward?.CustomRewardImageUrl}
          width={26}
          height={26}
          rewardCategory={customReward.RewardCategory}
        />
      ) : (
        <></>
      ),
  }),
};

export const isConditionFulfilled = (condition) => {
  if (condition?.IsFormula) {
    return Boolean(condition?.Formula?.length);
  }
  switch (condition.DataType) {
    case "Number":
      return checkValue(condition.Value);
    case "Object":
      return Boolean(
        checkValue(condition.Value?.startsAt) &&
          condition.Value.endsAt &&
          condition.Value.startsAt < condition.Value.endsAt
      );

    default:
      return false;
  }
};

export const isSlabTypeReward = (rewardType) => {
  return [
    REWARD_TYPE.PERCENTAGE_SLAB.type,
    REWARD_TYPE.MULTIPLIER_SLAB.type,
  ].includes(rewardType);
};

export const isRewardFulfilled = (reward) => {
  if (reward?.IsFormula) {
    return Boolean(reward?.Formula?.length);
  }
  return isSlabTypeReward(reward.RewardType)
    ? reward.Slabs.length
    : checkValue(reward.Value);
};
