import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { FormButton } from "src/components";
import { useToggle } from "src/hooks";

const CreateProgramModal = lazy(() =>
  import(
    /*
    webpackChunkName: "incentives.components.createProgramModal",
    webpackPrefetch: true
    */
    "../createOrUpdateProgram/CreateProgramModal"
  )
);

const CreateProgramButton = () => {
  const { t } = useTranslation();
  const [show, toggleShow] = useToggle(false);

  return (
    <>
      <FormButton
        id={t("INCENTIVES.COMMON.BUTTONS.CREATE_INCENTIVE")}
        label={t("INCENTIVES.COMMON.BUTTONS.CREATE_INCENTIVE")}
        onClick={toggleShow}
      />
      <Suspense fallback="">
        {show ? (
          <CreateProgramModal show={show} handleClose={toggleShow} />
        ) : null}
      </Suspense>
    </>
  );
};

export default CreateProgramButton;
