import { useApolloClient } from "@apollo/client";

export const useCache = () => {
  const client = useApolloClient();
  const cache = client.cache;

  const generateAPIKey = (apiName, variables) => {
    const variableString = variables ? `(${JSON.stringify(variables)})` : "";
    const apiString = apiName + variableString;
    return apiString;
  };

  const clearCacheBasedOnApiNames = (apiNames) => {
    const cacheKeysToClear = Object.keys(cache.extract().ROOT_QUERY).filter(
      (key) => apiNames.some((apiName) => key.includes(apiName))
    );

    if (cacheKeysToClear.length) {
      cacheKeysToClear.forEach((cacheKey) => {
        cache.evict({ fieldName: cacheKey });
      });
    }

    cache.gc();
  };

  const isPresentInCache = ({ apiName, variables }) => {
    const apiString = generateAPIKey(apiName, variables);
    return Boolean(
      Object.keys(cache.extract().ROOT_QUERY).find((key) => key === apiString)
    );
  };

  const readFromCache = ({ query, apiName, variables }) => {
    return isPresentInCache({ apiName, variables })
      ? cache.readQuery({ query, variables: { ...variables } })
      : null;
  };

  const writeToCache = ({ query, apiName, variables, data }) => {
    const storedcachedData = readFromCache({ query, apiName, variables });
    cache.writeQuery({
      query,
      variables: { ...variables },
      data: {
        ...storedcachedData?.[apiName],
        response: {
          ...storedcachedData?.response,
          ...data,
        },
      },
    });
  };
  return {
    clearCacheBasedOnApiNames,
    isPresentInCache,
    generateAPIKey,
    readFromCache,
    writeToCache,
  };
};
