import React from "react";

const SvgAvatar21 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="xptj__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .xptj__cls-1{fill:#fff}.xptj__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="xptj__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="xptj__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.085)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="xptj__cls-2"
        />
        <path
          d="M111.73 42.01c-1.24 0-2.51-.38-3.67-1.14-.89-.57-1.59-1.49-2.08-2.73-2.27-5.84-8.92-8.94-14.11-10.54-6.73-2.05-13.65-2.03-20 .14-8.65 2.92-13.03 8.38-14.86 11.35a5.53 5.53 0 0 1-6.19 2.46c-1.68-.46-3-1.62-3.7-3.22-.68-1.59-.59-3.4.24-4.95 3.84-6.95 11.03-12.41 20.81-15.76C78.71 14 90.01 14.4 99.98 18.73c7.73 3.35 13.13 8.4 16.57 15.49.81 1.68.78 3.59-.08 5.08-.73 1.3-1.95 2.19-3.38 2.54-.43.11-.89.16-1.35.16ZM81.92 23.39c3.57 0 7.19.54 10.73 1.62 8.24 2.51 13.73 6.7 15.84 12.13.27.7.62 1.19 1.03 1.43.97.62 2.03.84 2.95.62.73-.16 1.3-.62 1.68-1.27.41-.73.41-1.7 0-2.57-3.13-6.46-8.11-11.08-15.22-14.19-9.35-4.05-19.94-4.43-29.86-1.03-9.27 3.16-15.76 8.05-19.3 14.51-.43.81-.49 1.73-.14 2.57a2.88 2.88 0 0 0 1.89 1.65c1.24.32 2.51-.16 3.19-1.27 2.03-3.3 6.84-9.32 16.3-12.51a36.22 36.22 0 0 1 10.92-1.7ZM52.59 89.92h-9c-1.59 0-2.86-1.3-2.86-2.86V62.74c0-1.59 1.3-2.86 2.86-2.86h9c.76 0 1.35.59 1.35 1.35s-.59 1.35-1.35 1.35h-9l-.16 24.48 9.16.16c.76 0 1.35.59 1.35 1.35 0 .76-.59 1.35-1.35 1.35Z"
          className="xptj__cls-1"
        />
        <path
          d="M42.08 84h-5.54c-1.59 0-2.86-1.3-2.86-2.86V68.57c0-1.59 1.3-2.86 2.86-2.86h5.54c.76 0 1.35.59 1.35 1.35s-.59 1.35-1.35 1.35h-5.54l-.16 12.73 5.7.16c.76 0 1.35.59 1.35 1.35S42.84 84 42.08 84ZM120.46 89.92h-9c-.76 0-1.35-.59-1.35-1.35s.59-1.35 1.35-1.35h9l.16-24.48-9.16-.16c-.76 0-1.35-.59-1.35-1.35s.59-1.35 1.35-1.35h9c1.59 0 2.86 1.3 2.86 2.86v24.32c0 1.57-1.3 2.86-2.86 2.86Z"
          className="xptj__cls-1"
        />
        <path
          d="M127.51 84h-5.54c-.76 0-1.35-.59-1.35-1.35s.59-1.35 1.35-1.35h5.54l.16-12.73-5.7-.16c-.76 0-1.35-.59-1.35-1.35s.59-1.35 1.35-1.35h5.54c1.59 0 2.86 1.3 2.86 2.86v12.57c0 1.57-1.3 2.86-2.86 2.86ZM105.38 106.38h-4.11c-.76 0-1.35-.59-1.35-1.35s.59-1.35 1.35-1.35h4.11c5.51 0 10-4.49 10-10v-5.13c0-.76.59-1.35 1.35-1.35s1.35.59 1.35 1.35v5.13c0 7-5.7 12.7-12.7 12.7Z"
          className="xptj__cls-1"
        />
        <path
          d="M97.94 110.25h-5.78a4.68 4.68 0 0 1-4.68-4.68v-1.05a4.68 4.68 0 0 1 4.68-4.68h5.78a4.68 4.68 0 0 1 4.68 4.68v1.05c0 2.57-2.11 4.68-4.68 4.68Zm-5.78-7.7c-1.08 0-1.97.89-1.97 1.97v1.05c0 1.08.89 1.97 1.97 1.97h5.78c1.08 0 1.97-.89 1.97-1.97v-1.05c0-1.08-.89-1.97-1.97-1.97h-5.78ZM47.35 62.57c-.59 0-1.14-.41-1.3-1-1.68-6.11-.87-13.46 2.54-22.43.27-.7 1.05-1.05 1.73-.78.7.27 1.05 1.05.78 1.73-3.19 8.43-4 15.22-2.46 20.76.19.73-.22 1.46-.95 1.68-.14.03-.25.05-.35.05ZM52.59 89.72c-.83 0-1.5-.67-1.5-1.5V61.97c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v26.25c0 .83-.67 1.5-1.5 1.5ZM111.55 89.72c-.83 0-1.5-.67-1.5-1.5V61.97c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v26.25c0 .83-.67 1.5-1.5 1.5ZM117.56 62.57c-.11 0-.24-.03-.35-.05-.73-.19-1.14-.95-.95-1.68 1.54-5.54.65-12.92-2.54-21.32-.27-.7.08-1.49.78-1.73.7-.24 1.49.08 1.73.78 3.38 8.97 4.3 16.92 2.59 23-.14.6-.68 1-1.27 1Z"
          className="xptj__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar21.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar21;
