import React from "react";
import { Image } from "src/components";
import WallOfLegendsEmptyStateImg from "./WallOfLegendsEmptyState.png";

const WallOfLegendsEmptyState = (props) => (
  <Image
    src={WallOfLegendsEmptyStateImg}
    alt="WallOfLegendsEmptyStateImg"
    {...props}
  />
);

export default WallOfLegendsEmptyState;
