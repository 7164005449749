import React, { Suspense } from "react";
import { AceApp as AceAppWrapper } from "src/ace-app";
import { AceApp } from "src/apps/ace";
import { GoalsApp } from "src/apps/goals";
import { IncentivesApp } from "src/apps/incentives";
import { LeaderboardsApp } from "src/apps/leaderboards";
import { NudgesApp } from "src/apps/nudges";
import { RulesetsApp } from "src/apps/rulesets";
import { SettingsApp } from "src/apps/settings";
import { AppShell } from "src/components";
import { APPS } from "src/constants";
import { Redirect, Route, Switch } from "src/router";

export default function AceApps() {
  return (
    <AppShell.Provider>
      <AppShell.Container>
        <Suspense fallback="">
          <AceAppWrapper>
            <Switch>
              <Route path={APPS.ACE.ROUTES.ROOT.path}>
                <AceApp />
              </Route>
              <Route path={APPS.RULESETS.ROUTES.ROOT.path}>
                <RulesetsApp />
              </Route>
              <Route path={APPS.SETTINGS.ROUTES.ROOT.path}>
                <SettingsApp />
              </Route>
              <Route path={APPS.NUDGES.ROUTES.ROOT.path}>
                <NudgesApp />
              </Route>
              <Route path={APPS.GOALS.ROUTES.ROOT.path}>
                <GoalsApp />
              </Route>
              <Route path={APPS.INCENTIVES.ROUTES.ROOT.path}>
                <IncentivesApp />
              </Route>
              <Route path={APPS.LEADERBOARDS.ROUTES.ROOT.path}>
                <LeaderboardsApp />
              </Route>
              <Route path="*">
                <Redirect to={APPS.ACE.ROUTES.ROOT.path} />
              </Route>
            </Switch>
          </AceAppWrapper>
        </Suspense>
      </AppShell.Container>
    </AppShell.Provider>
  );
}
