import React from "react";

const SvgAvatar7 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="o07g__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .o07g__cls-1{fill:#fff}.o07g__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="o07g__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08a81.128 81.128 0 0 1-18.79 13.43c-.81.43-1.63.83-2.46 1.23a80.46 80.46 0 0 1-26.28 7.39c-.81.09-1.63.17-2.45.22-1.56.13-3.14.2-4.73.23-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-1.58-.03-3.16-.1-4.72-.23-.82-.05-1.64-.13-2.46-.22a80.472 80.472 0 0 1-26.27-7.39c-.83-.39-1.65-.8-2.46-1.23a81.243 81.243 0 0 1-18.81-13.43c-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="o07g__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.41 72.08)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="o07g__cls-2"
        />
        <path
          d="M93.82 38.48h-24.8c-6.24 0-12.21 2.48-16.63 6.89a23.545 23.545 0 0 0-6.9 16.62v22.22c0 6.24 2.49 12.21 6.9 16.62a23.559 23.559 0 0 0 16.63 6.89h24.8c6.24 0 12.21-2.48 16.63-6.89a23.545 23.545 0 0 0 6.9-16.62V61.99c0-6.24-2.49-12.21-6.9-16.62a23.559 23.559 0 0 0-16.63-6.89Zm19.84 45.73c-.01 5.26-2.1 10.3-5.82 14.01a19.823 19.823 0 0 1-14.02 5.81h-24.8c-5.26 0-10.3-2.09-14.02-5.81a19.87 19.87 0 0 1-5.82-14.01V61.99c.01-5.26 2.1-10.3 5.82-14.01 3.72-3.72 8.76-5.81 14.02-5.81h24.8c5.26 0 10.3 2.09 14.02 5.81a19.87 19.87 0 0 1 5.82 14.01v22.22Z"
          className="o07g__cls-1"
        />
        <path
          d="M83.93 72.56h-3.21c-2.34 0-4.77-.18-6.34 1.33-.86.88-1.32 2.08-1.25 3.32a8.305 8.305 0 0 0 6.44 8.14v9.43c0 1.02.83 1.84 1.84 1.84s1.84-.83 1.84-1.84v-9.43a8.324 8.324 0 0 0 4.63-2.91 8.342 8.342 0 0 0 1.82-5.16c.12-1.34-.32-2.68-1.22-3.68a5.82 5.82 0 0 0-4.57-1.03Zm-2.51 9.21a4.63 4.63 0 0 1-4.61-4.61c-.02-.23.02-.46.13-.66.68-.3 1.43-.42 2.17-.33h6.8c.12.33.16.68.13 1.03 0 2.55-2.07 4.63-4.62 4.63v-.06ZM38.18 40.87c0-2.93 1.16-5.74 3.23-7.82 2.07-2.07 4.88-3.24 7.82-3.24 2.93 0 5.74 1.16 7.82 3.23.72.71 1.88.71 2.6 0 .71-.72.71-1.88 0-2.6a14.717 14.717 0 0 0-10.82-4.32c-4.05.11-7.88 1.88-10.58 4.89A14.733 14.733 0 0 0 40 52.35c.33.26.74.41 1.16.41.77 0 1.46-.5 1.72-1.22.26-.73.03-1.54-.56-2.04-2.61-2.1-4.13-5.27-4.13-8.62ZM113.61 26.13a14.63 14.63 0 0 0-10.41 4.31c-.71.72-.71 1.88 0 2.6.72.71 1.88.71 2.6 0a11.062 11.062 0 0 1 15.95.54c2.01 2.24 3.02 5.21 2.79 8.22-.23 3-1.67 5.78-4 7.7-.6.49-.82 1.31-.56 2.04a1.832 1.832 0 0 0 2.88.81c3.18-2.56 5.16-6.34 5.45-10.41.3-4.08-1.12-8.09-3.9-11.09s-6.68-4.7-10.77-4.71h-.04ZM74.97 62.04c0-3.56 0-6.61-1.84-8.46a5.088 5.088 0 0 0-3.68-1.4c-4.51 0-16.58 6.26-16.58 12.9 0 6.1 4.13 11.06 9.21 11.06s12.9-6.8 12.9-12.9v-1.2Zm-3.69 0v1.2c0 4.15-6.17 9.21-9.21 9.21s-5.53-3.3-5.53-7.37c0-3.56 9.21-9.21 12.9-9.21.81 0 1.05.24 1.12.31.76.75.74 3.37.72 5.82v.04ZM93.4 52.19c-1.37-.04-2.69.46-3.68 1.4-1.84 1.84-1.84 4.9-1.84 8.46v1.2c0 6.1 7.7 12.9 12.9 12.9s9.21-4.96 9.21-11.06c0-6.63-11.98-12.9-16.58-12.9Zm7.37 20.27c-3.04 0-9.21-5.07-9.21-9.21v-1.24c0-2.49 0-5.07.72-5.82 0 0 .31-.31 1.12-.31 3.68 0 12.9 5.66 12.9 9.21 0 4.07-2.47 7.37-5.53 7.37Z"
          className="o07g__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar7.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar7;
