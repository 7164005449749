import React from "react";
import { Image } from "src/components/common";
import LinkedProgramEmptyStateImg from "./LinkedProgramEmptyState.svg";

const LinkedProgramEmptyState = (props) => (
  <Image
    src={LinkedProgramEmptyStateImg}
    alt="LinkedProgramEmptyStateImg"
    {...props}
  />
);

export default LinkedProgramEmptyState;
