import React from "react";
import { useGetUserPreferences } from "src/api/useUserPreferences/query/useGetUserPreferences";
import { useGetPendoDataQuery } from "src/api/userQuery";
import AppRouter from "./AppRouter";

const AppRouterWrapper = ({
  pendoURLData,
  maxAllowedGoalRules,
  isFeatureEnabled,
  isOpenAceUser,
  isLoggedIn,
  orgCode,
  role,
  userId,
  orgDisplayName,
  orgUniqueName,
  children,
}) => {
  const { data: { getUserPendoDetails } = { getUserPendoDetails: {} } } =
    useGetPendoDataQuery(pendoURLData);

  const { data: { userPreferences } = { userPreferences: {} } } =
    useGetUserPreferences();

  return (
    <AppRouter
      pendoDetails={getUserPendoDetails?.pendoConfig}
      maxAllowedGoalRules={maxAllowedGoalRules}
      isFeatureEnabled={isFeatureEnabled}
      {...{
        isOpenAceUser,
        isLoggedIn,
        userId,
        orgCode,
        role,
        orgDisplayName,
        orgUniqueName,
        userPreferences,
      }}
    >
      {children}
    </AppRouter>
  );
};

export default AppRouterWrapper;
