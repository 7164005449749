import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { components } from "react-select";
import {
  CRITERIA_CONDITION_PROPTYPES,
  INITIAL_INCENTIVE_SUBGOAL_STATE,
  STANDARD_CRITERIA_CONDITION,
} from "src/apps/incentives/data/types";
import {
  filterOption,
  findOptionFromSelectedGoals,
  formatSelectedGoals,
  groupByConditionValid,
} from "src/apps/incentives/utils";
import {
  CreateInput,
  FormButton,
  FormInput,
  FormTooltip,
  GroupByLabel,
  GroupByModal,
  GroupReactSelect,
  SelectFilter,
} from "src/components";
import {
  COLORS,
  CONDITION_OPERATOR,
  CONDITION_TYPES_OPTIONS,
  INCENTIVE_PROGRAM_TYPES,
  MINIMUM_INCENTIVE_CONDITION_VALUE,
  REGEX_FOR_NAME,
} from "src/constants";
import { checkValue, classNames, isEmpty } from "src/helpers";
import { SvgTip, SvgUnderlineEdit } from "src/icons";
import { getSubGoalEntityValuePair } from "../formulaBuilder/utils";
import "./styles.scss";
import "./updateOrCreateCondition.scss";

const Input = ({
  name,
  type,
  placeholder,
  className,
  value,
  onChange,
  unit,
}) => {
  return (
    <div className="center mt-3">
      <FormInput
        name={name}
        type={type}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        value={value}
        min={MINIMUM_INCENTIVE_CONDITION_VALUE}
        step="any"
      />
      {unit ? (
        <div className="fc-grey fs-14 semi-bold" style={{ marginLeft: 10 }}>
          {unit}
        </div>
      ) : null}
    </div>
  );
};

const RangeInput = ({ value, handleChange, unit }) => {
  return (
    <div className="center">
      <div style={{ marginRight: 24 }}>
        <Input
          name="startsAt"
          type="number"
          placeholder="Enter Value"
          onChange={handleChange}
          value={value?.startsAt}
          unit={unit}
        />
      </div>
      <div>
        <Input
          name="endsAt"
          type="number"
          placeholder="Enter Value"
          onChange={handleChange}
          value={value?.endsAt}
          unit={unit}
          step="any"
        />
      </div>
    </div>
  );
};

const validateValue = (value) => {
  if (value && typeof value === "object") {
    if (
      value.startsAt >= MINIMUM_INCENTIVE_CONDITION_VALUE &&
      value.endsAt >= MINIMUM_INCENTIVE_CONDITION_VALUE &&
      value.startsAt < value.endsAt
    )
      return true;
    return false;
  }
  if (checkValue(value) && value >= MINIMUM_INCENTIVE_CONDITION_VALUE) {
    return true;
  }
  return false;
};

function UpdateOrCreateCondition({
  index,
  templates,
  goals,
  type,
  condition,
  onUpdate,
  onClose,
  isValueInMinMaxRange,
  selectedRecurrence,
}) {
  const operation =
    condition?.TemplateId || condition?.GoalId
      ? t("COMMON.BUTTONS.EDIT")
      : "Add";

  const [state, setState] = useState({
    ...STANDARD_CRITERIA_CONDITION,
    ...condition,
    ConditionType: groupByConditionValid(condition?.SubGoal)
      ? CONDITION_TYPES_OPTIONS[1]
      : CONDITION_TYPES_OPTIONS[0],
    Name:
      operation !== "Add" && !condition.Name
        ? `Condition ${index}`
        : condition.Name,
  });

  const [showSubGoalModal, setShowSubGoalModal] = useState(false);
  const [warning, setWarning] = useState(false);

  const operatorOptions = Object.keys(CONDITION_OPERATOR).map((operatorKey) => {
    return CONDITION_OPERATOR[operatorKey];
  });

  const selectedTemplate = templates.find(
    (template) => template.TemplateId === state.TemplateId
  );

  const selectedGoal = goals?.find((goal) => goal.GoalId === state.GoalId);

  const [selectedGoalsGroupByEntities, selectedGoalsEntityValuePair] =
    getSubGoalEntityValuePair(
      selectedRecurrence?.SubGoalFilters?.find(
        (goal) => goal.GoalId === state.GoalId
      )?.SubGoals
    );

  const handleChange = ({ name, value }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRuleChange = ({
    TemplateId,
    value,
    optionType,
    Name,
    Category,
  }) => {
    handleChange({ name: "GoalId", value: null });
    handleChange({ name: "GoalName", value: null });
    const newState = [];
    if (type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value) {
      if (optionType === "Goal" && value) {
        newState.push({ name: "GoalId", value: value });
        newState.push({ name: "GoalName", value: Name });
      }
      newState.push({ name: "TemplateId", value: TemplateId });
      newState.push({ name: "TemplateName", value: Category });
    } else {
      newState.push({ name: "TemplateId", value: TemplateId });
      newState.push({ name: "TemplateName", value: Name });
    }

    newState.push({ name: "ConditionType", value: CONDITION_TYPES_OPTIONS[0] });
    newState.push({ name: "SubGoal", value: {} });
    newState.map((item) => handleChange(item));
  };

  const handleOperatorChange = (operator) => {
    handleChange({ name: "Operator", value: operator });
    if (operator.Key === "between") {
      handleChange({ name: "Value", value: {} });
      handleChange({ name: "DataType", value: "Object" });
    } else {
      handleChange({ name: "Value", value: "" });
      handleChange({ name: "DataType", value: "Number" });
    }
  };

  const handleRangeChange = (e) => {
    const { name, value: valueAsNumber } = e.target;
    const prevValue = typeof state.Value === "object" ? state.Value : {};
    handleChange({
      name: "Value",
      value: {
        ...prevValue,
        [name]: valueAsNumber !== "" ? Number(valueAsNumber) : undefined,
      },
    });
  };

  const handleValueChange = (e) => {
    const { value: valueAsNumber } = e.target;
    handleChange({
      name: "Value",
      value: valueAsNumber !== "" ? Number(valueAsNumber) : undefined,
    });
  };
  const disableGroupBy =
    state?.GoalId && state?.TemplateId
      ? !Boolean(selectedGoalsGroupByEntities) || !selectedGoalsEntityValuePair
      : state?.TemplateId
      ? !selectedTemplate?.GroupBy
      : null;

  const formatConditionTypeOptionLabel = ({ label, key }) => {
    return (
      <FormTooltip
        text={
          state?.GoalId
            ? t("COMMON.MESSAGES.GOAL_DOESNT_SUPPORT_GROUPBY")
            : t("COMMON.MESSAGES.RULESET_DOESNT_SUPPORT_GROUPBY")
        }
        disable={key !== CONDITION_TYPES_OPTIONS[1].key || !disableGroupBy}
        placement="bottom"
      >
        <div className={"flex align-items-center"}>
          <div className="mr-2">{label}</div>
        </div>
      </FormTooltip>
    );
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;

    const values = getValue();
    let valueLabel = "";
    let groupLabel = "";

    if (values.length > 0) {
      valueLabel += props.selectProps.getOptionLabel(values[0]);
      groupLabel = props.selectProps.getGroupLabel
        ? props.selectProps.getGroupLabel(values[0])
        : values[0].Category;
    }

    const childrenToRender = React.Children.toArray(children).filter(
      (child) =>
        ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );
    return (
      <components.ValueContainer {...props}>
        {childrenToRender}
        {!props.selectProps.inputValue && valueLabel ? (
          <span className="value-container-label">
            <>
              {groupLabel && (
                <span className="group-label">{groupLabel} - </span>
              )}
              {valueLabel && (
                <span className="value-label">{values[0].Name}</span>
              )}
            </>
          </span>
        ) : (
          false
        )}
      </components.ValueContainer>
    );
  };
  const handleTipsChange = (newTip) => {
    handleChange({ name: "Tips", value: newTip });
  };

  const handleAdd = () => {
    onUpdate(state);
  };

  const ruleOption =
    type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value ? goals : templates;
  const rulePlaceholder =
    type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value
      ? "Select Goal"
      : "Select Template";
  const selectedRule =
    type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value
      ? state?.GoalId
        ? selectedGoal
        : selectedTemplate
      : selectedTemplate;
  const optionValueKey =
    type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value ? "GoalId" : "TemplateId";
  const optionLabelKey =
    type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value ? "GoalName" : "Name";

  const inputUnit =
    type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value && state?.GoalId
      ? "%"
      : "";

  const formattedRuleLabel = (option) => {
    return (
      <div
        className={classNames([
          option.optionType === "Template" ? "semi-bold rulesetName" : "ml-2",
        ])}
      >
        {option.Name}
      </div>
    );
  };

  const disableSubmitButton =
    !validateValue(state.Value) ||
    (state.ConditionType?.key === CONDITION_TYPES_OPTIONS[1].key &&
      !groupByConditionValid(state?.SubGoal)) ||
    !state.Name ||
    warning;

  return (
    <div className="update-create-condition">
      <div className="fs-16 fc-black semi-bold py-3 px-4">
        {operation} Condition
      </div>
      <hr />
      <form className="p-4">
        <CreateInput
          placeholder={t("COMMON.LABELS.ENTER_CONDITION_NAME")}
          className="mb-4 w-100 condition-name"
          setValue={(value) => handleChange({ name: "Name", value })}
          value={state?.Name}
          regex={REGEX_FOR_NAME}
          warning={warning}
          setWarning={setWarning}
          maxLength={100}
          label={null}
        />
        <hr className="mb-4" />
        {type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value ? (
          <div className="select-goals">
            <GroupReactSelect
              defaultValue={findOptionFromSelectedGoals({
                goals,
                templates,
                templateId: selectedRule?.TemplateId,
                goalId: state?.GoalId,
              })}
              placeholder={t("COMMON.LABELS.SELECT_RULESET_OR_GOAL")}
              components={{ ValueContainer }}
              handleOnChange={handleRuleChange}
              options={formatSelectedGoals(goals, templates)}
              getGroupLabel={() => false}
              getOptionLabel={formattedRuleLabel}
              filterOption={filterOption}
            />
          </div>
        ) : null}
        {type === INCENTIVE_PROGRAM_TYPES.TEMPLATE_BASED.value ? (
          <SelectFilter
            options={ruleOption}
            placeholder={rulePlaceholder}
            onChange={handleRuleChange}
            value={selectedRule}
            optionLabelKey={optionLabelKey}
            optionValueKey={optionValueKey}
          />
        ) : null}
        {state.TemplateId || state.GoalId ? (
          <SelectFilter
            options={CONDITION_TYPES_OPTIONS}
            placeholder={t("INCENTIVES.PROGRAM.LABELS.SELECT_CONDITION_TYPE")}
            className="mt-3"
            onChange={(conditionType) => {
              handleChange({ name: "ConditionType", value: conditionType });
              handleChange({ name: "SubGoal", value: {} });
              handleOperatorChange({});
            }}
            value={
              (groupByConditionValid(state?.SubGoal) &&
                state?.ConditionType.key === CONDITION_TYPES_OPTIONS[1].key &&
                CONDITION_TYPES_OPTIONS[1]) ||
              (state.ConditionType?.key && state.ConditionType)
            }
            formatOptionLabel={formatConditionTypeOptionLabel}
            isOptionDisabled={({ key }) =>
              disableGroupBy && key === CONDITION_TYPES_OPTIONS[1].key
            }
          />
        ) : null}
        {state.ConditionType?.key === CONDITION_TYPES_OPTIONS[1].key &&
        !groupByConditionValid(state?.SubGoal) ? (
          <FormButton
            label={t("INCENTIVES.PROGRAM.LABELS.SELECT_GROUP_BY_FIELD")}
            className="group-by-button mt-3 flex justify-content-center fc-blue"
            onClick={() => setShowSubGoalModal(true)}
            variant="normal-link"
          />
        ) : null}
        {groupByConditionValid(state?.SubGoal) &&
          state.ConditionType?.key === CONDITION_TYPES_OPTIONS[1].key && (
            <div className="fs-13 mt-3 flex flex-column py-2 px-3 group-by-edit w-100  center position-relative">
              <GroupByLabel groupByCondition={state?.SubGoal} splitToLines />
              <FormButton
                icon={
                  <SvgUnderlineEdit
                    color={COLORS.GREY_2}
                    width="16"
                    height="16"
                  />
                }
                className="mx-2 p-1 px-2 right-0 position-absolute"
                onClick={() => setShowSubGoalModal(true)}
                variant="only-icon"
              />
            </div>
          )}

        {(state.TemplateId || state.GoalId) &&
        (state.ConditionType?.key === CONDITION_TYPES_OPTIONS[0].key ||
          groupByConditionValid(state?.SubGoal)) ? (
          <SelectFilter
            options={operatorOptions}
            placeholder="Select Operator"
            className="mt-3"
            onChange={handleOperatorChange}
            value={state.Operator.Key ? state.Operator : undefined}
            optionLabelKey="Label"
            optionValueKey="Key"
          />
        ) : null}
        {state.Operator.Value && state.Operator.Key === "between" ? (
          <RangeInput
            handleChange={handleRangeChange}
            value={state.Value}
            unit={inputUnit}
          />
        ) : null}
        {state.Operator.Value && state.Operator.Key !== "between" ? (
          <Input
            name="value"
            type="number"
            placeholder={t("INCENTIVES.PROGRAM.LABELS.ENTER_VALUE")}
            onChange={handleValueChange}
            value={state.Value}
            unit={inputUnit}
          />
        ) : null}
        {validateValue(state.Value) ? (
          <div className="tips mt-3">
            <CreateInput
              name="Tips"
              as="textarea"
              regex={false}
              maxLength={500}
              placeholder={t("INCENTIVES.PROGRAM.LABELS.CONDITION_PLACEHOLDER")}
              height="98px"
              setValue={handleTipsChange}
              value={state.Tips}
            />
            <span className="fs-12 fc-grey center mt-2">
              <SvgTip className="mr-2" /> {t("INCENTIVES.PROGRAM.LABELS.TIPS")}
            </span>
          </div>
        ) : null}
        <div className="flex-end mt-4">
          <FormButton
            variant="outline-primary"
            label={t("COMMON.BUTTONS.CLOSE")}
            className="mr-3"
            onClick={onClose}
          />
          <FormButton
            label={`${operation} ${t("RULEBOOK.LABELS.CONDITIONS")}`}
            onClick={handleAdd}
            disabled={disableSubmitButton}
          />
        </div>
      </form>

      {showSubGoalModal && (
        <GroupByModal
          show={showSubGoalModal}
          setShowModal={setShowSubGoalModal}
          modalTitle={t("INCENTIVES.COMMON.LABELS.ADD_GROUP_BY_CONDITION")}
          modalSubTitle={t("INCENTIVES.COMMON.LABELS.GROUP_BY_CONDITION")}
          modalDescription={
            <Trans i18nkey={"INCENTIVES.COMMON.LABELS.GROUP_BY_DESCRIPTION"}>
              Measure and attain sub-goals that contribute to the accomplishment
              of a primary objective.
              <br /> To employ multiple groupings, simply click on 'AND'.
            </Trans>
          }
          subGoals={
            !isEmpty(state?.SubGoal)
              ? state?.SubGoal
              : INITIAL_INCENTIVE_SUBGOAL_STATE
          }
          setSubGoals={(subGoals) =>
            handleChange({ name: "SubGoal", value: subGoals })
          }
          isCreating={!(condition?.TemplateId || condition?.GoalId)}
          groupByEntities={
            state?.GoalId
              ? selectedGoalsGroupByEntities
              : selectedTemplate?.GroupBy
          }
          groupByValues={selectedGoalsEntityValuePair}
        />
      )}
    </div>
  );
}

UpdateOrCreateCondition.propTypes = {
  index: PropTypes.number.isRequired,
  templates: PropTypes.shape([]),
  condition: CRITERIA_CONDITION_PROPTYPES,
};

export default UpdateOrCreateCondition;
