import React from "react";

const SvgAvatar44 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="ywlb__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .ywlb__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="ywlb__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="ywlb__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="ywlb__cls-2"
        />
        <path
          d="M78.41 75.4c-.98 0-1.9-.38-2.6-1.08a3.713 3.713 0 0 1-1.08-2.6v-5.67h-6.67v5.67a3.688 3.688 0 0 1-3.68 3.68H50.36c-.98 0-1.9-.38-2.6-1.08a3.713 3.713 0 0 1-1.08-2.6v-9.35c0-.98.38-1.9 1.08-2.6a3.67 3.67 0 0 1 2.6-1.08h14.02c.98 0 1.9.38 2.6 1.08a3.67 3.67 0 0 1 1.08 2.6v1h6.67v-1c0-.98.38-1.9 1.08-2.6a3.67 3.67 0 0 1 2.6-1.08h14.02c.98 0 1.9.38 2.6 1.08a3.67 3.67 0 0 1 1.08 2.6v9.35a3.688 3.688 0 0 1-3.68 3.68H78.41Zm-1-2.67h16.02V61.38H77.41v11.35Zm-28.05 0h16.02V61.38H49.36v11.35Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar44.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar44;
