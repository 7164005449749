import PropTypes from "prop-types";
import React from "react";
import Select, { components } from "react-select";
import { FormButton } from "src/components";
import {
  addDays,
  differenceInDays,
  endOfDay,
  getPluralText,
  groupByTimePeriod,
  startOfDay,
} from "src/helpers";
import { CustomTitle } from "../recurrenceDropdown/RecurrenceDropdown";
import "./recurrenceTable.scss";

const CONTROL_WIDTH_IN_PIXEL = 325;
const MENU_WIDTH_IN_PIXEL = 500;

const RecurrenceTable = (props) => {
  const {
    recurrences,
    columns,
    customType,
    customTitle,
    backgroundColor,
    selectedCycle,
    hoverable = true,
    clickable = true,
    handleClick,
    recurrenceType,
    formatOptionLabelBefore,
    formatTitleIcon,
    optionLabelColor,
    optionBadgeColor,
    width,
    alignRight,
    menuStyles,
    getOptionLabel,
  } = props;

  const objPeriod = groupByTimePeriod(recurrences, recurrenceType);
  const periods = Object.keys(objPeriod)
    .map((i) => parseFloat(i))
    .sort();

  const controlWidth = width || CONTROL_WIDTH_IN_PIXEL;
  const menuWidth = menuStyles.width || MENU_WIDTH_IN_PIXEL;

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontSize: "12px",
      height: "70px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px !important",
      height: "72px",
    }),
    control: (provided) => ({
      ...provided,
      width: controlWidth,
      borderRadius: "0 !important",
      backgroundColor: backgroundColor || "#f1f5f8",
      borderStyle: "none",
      ".down-grey": {
        display: "block",
      },
      ".down-blue": {
        display: "none",
      },
      "&:hover": {
        backgroundColor: "#ffffff",
        ".down-grey": {
          display: "none",
        },
        ".down-blue": {
          display: "block",
        },
      },
      ".recurrence-dropdown-title": {
        ".recurrence-dropdown-title__icon": {
          width: "1.4375rem",
        },
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "-1px !important",
      marginBottom: "0px",
      borderTop: "1px solid #309afc",
      borderRadius: "0 4px 4px 4px !important",
      width: menuWidth,
      marginLeft: alignRight ? controlWidth - menuWidth : 0,
      ...menuStyles,
    }),
    menuList: (provided) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black",
      cursor: "pointer",
      backgroundColor: state.isFocused ? "#f1fafe" : "",
      padding: "0 0.5rem",
    }),
  };

  const Menu = (props) => {
    return (
      <components.Menu {...props}>
        <div className="d-flex bg-grey17 h-100 fs-10 semi-bold fc-grey1 text-nowrap p-3 border-bottom-0">
          {columns.map((column, index) => (
            <div
              key={column.accessor}
              className={index === 0 ? "pl-3" : ""}
              style={{ width: column.headerWidth }}
            >
              {column.Header}
            </div>
          ))}
        </div>
        <div>{props.children}</div>
      </components.Menu>
    );
  };

  const Input = (props) => {
    return (
      <div className="h-0">
        <components.Input {...props} readOnly />
      </div>
    );
  };

  const ValueContainer = ({ children, ...props }) => {
    const childrenToRender = React.Children.toArray(children).filter(
      (child) => ["Input"].indexOf(child.type.name) >= 0
    );
    return (
      <components.ValueContainer {...props}>
        {childrenToRender}
        <div className="w-100 h-100 py-1 px-12 recurrence-dropdown__dropdown">
          {customTitle ? (
            customTitle({
              recurrences,
              selectedCycle,
              formatTitleIcon,
              customType,
            })
          ) : (
            <CustomTitle
              className="h-100"
              recurrences={recurrences}
              selectedCycle={selectedCycle}
              formatTitleIcon={formatTitleIcon}
              customType={customType}
              showIndicator
            />
          )}
        </div>
      </components.ValueContainer>
    );
  };

  const formatOptionLabel = (item) => {
    return (
      <FormButton
        key={item.idx}
        className={`m-0 p-0 text-nowrap recurrence-list__item${
          hoverable ? "--hover" : ""
        } ${clickable ? "cursor-pointer" : "cursor-default"} center flex-start`}
        onClick={
          clickable
            ? () => {
                handleClick(item);
              }
            : undefined
        }
        variant="normal-link"
      >
        {formatOptionLabelBefore && (
          <div className="recurrence-list__item-icon flex-center">
            {formatOptionLabelBefore({ ...item })}
          </div>
        )}
        <div
          className={`d-flex w-100 fs-12 recurrence-list__item-text py-12 pr-12 ${
            optionLabelColor ? optionLabelColor({ ...item }) : ""
          }`}
        >
          {columns.map((column) => {
            if (column.accessor === "RecurrenceStartDate-RecurrenceEndDate") {
              return (
                <div
                  key={`${item.Name} ${column.accessor}`}
                  className="px-0 center recurrence-list__item-wrapper"
                  style={{ width: column.width }}
                >
                  <div className="recurrence-list__item-index ml-2">
                    #{item.idx}:
                  </div>
                  <div className="ml-2 w-180">{item.value}</div>
                  {recurrenceType?.toUpperCase() === "CUSTOM" &&
                    customType === "NONUNIFORM" && (
                      <div
                        className={`fs-11 ml-3 br-13 px-2 py-1 non_uniform_days_badge ${
                          optionBadgeColor ? optionBadgeColor({ ...item }) : ""
                        }`}
                      >
                        {getPluralText(
                          differenceInDays(
                            endOfDay(addDays(item.RecurrenceEndDate, 1)),
                            startOfDay(item.RecurrenceStartDate)
                          ),
                          "day",
                          "days"
                        )}
                      </div>
                    )}
                </div>
              );
            }
            return (
              <div
                key={`${item.Name} ${column.accessor}`}
                className={`fs-14 semi-bold ${column.className}`}
                style={{ width: column.width }}
              >
                {item[column.accessor] ? (
                  <span className="fc-primary-black">
                    {item[column.accessor]} {column.label}
                  </span>
                ) : (
                  "--"
                )}
              </div>
            );
          })}
        </div>
      </FormButton>
    );
  };

  return (
    <Select
      classNamePrefix="drop-select"
      styles={customStyles}
      components={{
        Menu,
        Input,
        ValueContainer,
        DropdownIndicator: () => null,
      }}
      id="recurrence-dropdown-table"
      defaultValue={null}
      onChange={(e) => {}}
      options={periods.map((obj) => objPeriod[obj].map((item) => item)).flat(1)}
      formatOptionLabel={(item) =>
        getOptionLabel
          ? getOptionLabel({
              ...props,
              item,
            })
          : formatOptionLabel(item)
      }
    />
  );
};

RecurrenceTable.propTypes = {
  as: PropTypes.string,
  recurrences: PropTypes.array.isRequired,
  recurrenceType: PropTypes.string,
  clickable: PropTypes.bool,
  hoverable: PropTypes.bool,
  handleClick: PropTypes.func,
  optionLabelColor: PropTypes.func,
  formatOptionLabelBefore: PropTypes.func,
  columns: PropTypes.array,
  formatTitleIcon: PropTypes.func,
  optionBadgeColor: PropTypes.func,
  selectedCycle: PropTypes.object,
  customType: PropTypes.string,
  menuStyles: PropTypes.object,
  alignRight: PropTypes.bool,
};

RecurrenceTable.defaultProps = {
  clickable: true,
  hoverable: true,
  columns: [],
  recurrences: [],
  customType: "",
  selectedCycle: {
    RecurrenceId: "3e8e79ff-1d71-48fb-b933-7373e446268c",
    RecurrenceType: "DAILY",
    RecurrenceStartDate: 1651881600000,
    RecurrenceEndDate: 1651881600000,
    RecurrenceGoalValue: 1180,
    GoalAchieved: 0,
    GoalId: "9762a1d1-6ce3-43b1-a15f-acf86be8edcd",
    __typename: "RecurrencesAchievement",
  },
  formatTitleIcon: () => {},
  optionLabelColor: () => {},
  optionBadgeColor: () => {},
  menuStyles: {},
  alignRight: false,
};

export default RecurrenceTable;
