import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgLeafUserDeactivated = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 8714">
        <path fill="none" d="M0 0h16v16H0Z" data-name="Path 4790" />
        <g data-name="Group 8705">
          <path fill="none" d="M0 0h16v16H0z" data-name="Rectangle 2966" />
          <path
            fill={color}
            d="M8.335 12.669a4.334 4.334 0 1 1 4.336-4.335 4.34 4.34 0 0 1-4.336 4.335Zm0-3.376a.819.819 0 1 0 .82.819.818.818 0 0 0-.82-.819Zm0-3.542a.925.925 0 0 0-.924.925 1.053 1.053 0 0 0 .065.346l.74 1.817a.132.132 0 0 0 .119.08.149.149 0 0 0 .127-.08c.039-.098.733-1.815.739-1.839a.859.859 0 0 0 .063-.326.926.926 0 0 0-.929-.923Z"
            data-name="Subtraction 36"
          />
        </g>
      </g>
    </svg>
  );
};

SvgLeafUserDeactivated.defaultProps = {
  color: COLORS.PRIMARY_ORANGE,
  height: 16,
  width: 16,
};
export default SvgLeafUserDeactivated;
