import React from "react";

const SvgAvatar1 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="fv1e__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .fv1e__cls-1{fill:#fff}.fv1e__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="fv1e__Layer_1-2">
        <path
          d="M120.27 152.93c.98-.53 1.95-1.09 2.91-1.66a82.005 82.005 0 0 0 16.51-13.07c14.31-14.68 23.11-34.7 23.11-56.8C162.8 36.44 126.36 0 81.4 0S0 36.44 0 81.4c0 22.1 8.8 42.12 23.1 56.8A81.308 81.308 0 0 0 42.67 153c.96.53 1.93 1.03 2.91 1.51 9 4.42 18.95 7.23 29.45 8.04 2.1.17 4.23.25 6.37.25s4.26-.08 6.36-.25l32.51-9.62Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="fv1e__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.07)"
        />
        <path
          d="M139.69 138.2a81.305 81.305 0 0 1-31.93 20.23c-.56.2-1.13.38-1.69.57-.6.18-1.2.37-1.8.54-.56.16-1.12.32-1.68.47a81.328 81.328 0 0 1-21.19 2.79c-22.86 0-43.52-9.43-58.3-24.6 16.3-12.06 36.47-19.19 58.3-19.19 7.27 0 14.37.79 21.19 2.3.56.12 1.12.25 1.68.39.6.13 1.2.28 1.8.44.57.14 1.13.3 1.69.46a97.512 97.512 0 0 1 31.93 15.6Z"
          className="fv1e__cls-2"
        />
        <path
          d="M87.85 149.92H77.1h.01-16.75c-.41 0-.78.25-.95.62-.16.38-.08.82.2 1.11l2.11 2.22c1.67 1.75 4.13 2.76 6.76 2.76h28.05c2.63 0 5.09-1 6.76-2.76l2.11-2.22c.28-.29.36-.73.2-1.11s-.54-.62-.95-.62H87.86Zm13.96 2.53c-1.28 1.35-3.2 2.11-5.27 2.11H68.49c-2.07 0-3.99-.77-5.27-2.11l-.45-.47h39.49l-.45.47ZM73.41 116.07h-4.88a4.78 4.78 0 0 1-4.78-4.78c0-1.53-1.25-2.78-2.78-2.78h-6.68c-.55 0-1-.45-1-1s.45-1 1-1h6.68a4.78 4.78 0 0 1 4.78 4.78c0 1.53 1.25 2.78 2.78 2.78h4.88c.55 0 1 .45 1 1s-.45 1-1 1ZM85.7 116.07h-4.88a4.78 4.78 0 0 1-4.78-4.78c0-1.53-1.25-2.78-2.78-2.78h-6.68c-.55 0-1-.45-1-1s.45-1 1-1h6.68a4.78 4.78 0 0 1 4.78 4.78c0 1.53 1.25 2.78 2.78 2.78h4.88c.55 0 1 .45 1 1s-.45 1-1 1ZM114.52 124.58c-.46-1.97-1.75-3.28-3.64-3.7-2.36-.51-4.64.16-6.55 1.13.03-1.54-.05-2.5-.05-2.55a1.02 1.02 0 0 0-1.01-.92H59.8c-.53 0-.96.4-1 .92-.02.21-1.51 18.99 11.95 26.29 3.59 1.73 7.16 2.54 10.33 2.55 6.44.02 11.25-2.55 11.25-2.55 3.22-1.74 5.58-4.13 7.32-6.79.89.22 1.77.32 2.65.32 2.5 0 4.91-.87 7.06-2.6 3.77-3.04 6.04-8.35 5.17-12.09Zm-11.14 5.34c3.6-3.27 4.78-3.46 5.16-3.29.24.11.4.99-.16 2.35-.8 1.99-2.95 4.38-6.02 4.44.42-1.19.75-2.36 1.01-3.51Zm-16.56 15.83c-5.07 1.38-10.56 0-10.56 0-15.33-3.97-15.66-20.96-15.5-25.19h41.55c.11 4.17-.36 21.26-15.49 25.19Zm21.27-10.64c-1.65 1.34-4.15 2.58-7.35 2.02.3-.56.58-1.13.83-1.7 4.47.39 7.58-2.94 8.69-5.69.94-2.33.6-4.28-.88-4.95-1.54-.7-3.49.42-5.42 1.96.11-.83.2-1.62.26-2.35 1.79-1.09 4.03-2.02 6.23-1.53 1.11.25 1.82.98 2.1 2.19.61 2.62-.92 7.2-4.47 10.06Z"
          className="fv1e__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar1.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar1;
