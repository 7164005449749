import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const DOWNLOAD_RAW_EXCEL_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation downloadIncentiveRawExcel($ProgramId: ID!) {
    downloadIncentiveRawExcel(ProgramId: $ProgramId) {
      ... on ProgramUrlResponse {
        success
        response {
          url
          fileId
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useDownloadIncentiveRawExcel = () => {
  return useMutation(DOWNLOAD_RAW_EXCEL_MUTATION);
};
