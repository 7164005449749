import PropTypes from "prop-types";
import React from "react";
import { RECURRENCE_PERIOD_CAPS } from "src/constants";
import { classNames, getAvatar, getRankColor } from "src/helpers";
import Avatar from "../../Avatar";
import TrophyBadge from "../trophyBadge/TrophyBadge";
import "./userTrophyAvatar.scss";

const UserTrophyAvatar = ({
  userId,
  imgProps,
  showBadge,
  position,
  recurrencePeriod,
  borderWidth,
  borderColor,
  badgeClassName,
  size,
}) => {
  const {
    AvatarIcon,
    color: { backgroundColor, avatarColor },
  } = getAvatar(userId);

  return (
    <Avatar
      src={
        <div
          style={{
            "--avatar-color": borderColor,
            "--avatar-width": borderWidth,
          }}
          className={classNames(["outline-badge", getRankColor(position)])}
        >
          <AvatarIcon
            backgroundColor={backgroundColor}
            avatarColor={avatarColor}
            style={{ "--avatar-dark": avatarColor }}
            {...imgProps}
          />
        </div>
      }
      badgeProps={{
        showBadge: showBadge,
        badge: <TrophyBadge {...{ position, recurrencePeriod, size }} />,
        position: "bottom-end",
        badgeClassName: badgeClassName,
      }}
    />
  );
};

UserTrophyAvatar.propTypes = {
  userId: PropTypes.string.isRequired,
  imgProps: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  showBadge: PropTypes.bool,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  position: PropTypes.number,
  recurrencePeriod: PropTypes.oneOf(RECURRENCE_PERIOD_CAPS),
};

UserTrophyAvatar.defaultProps = {
  userId: "",
  imgProps: {
    width: "40px",
    height: "40px",
  },
  showBadge: true,
  position: 1,
  recurrencePeriod: "DAILY",
  borderWidth: "6px",
  borderColor: "#000000",
};

export default UserTrophyAvatar;
