import { useState } from "react";
import { FormButton } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { SvgDownload } from "src/icons";
import ExportGridConfirmationModal from "./ExportGridConfirmationModal";
import { downloadExcel } from "./downloadExcel";
import "./exportGrid.scss";

const ExportGrid = (props) => {
  const { index, fileName, disabled, renderButton, exportVariables } = props;
  const [toggleModal, setToggleModal] = useState(false);

  const handleClick = () => {
    setToggleModal(true);
  };

  return (
    <>
      {toggleModal && (
        <ExportGridConfirmationModal
          {...{
            toggleModal,
            setToggleModal,
            index,
            exportVariables,
            fileName,
          }}
        />
      )}
      {renderButton ? (
        renderButton({ onClick: handleClick })
      ) : (
        <FormButton
          variant="outline-primary"
          onClick={handleClick}
          icon={
            <SvgDownload
              color={disabled ? COLORS.GREY_3 : COLORS.PRIMARY_BLUE}
            />
          }
          disabled={disabled}
        >
          <div className="min-w-max ml-2">Download As Excel</div>
        </FormButton>
      )}
    </>
  );
};

ExportGrid.defaultProps = {
  handleDownload: downloadExcel,
};

export default ExportGrid;
