import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormButton } from "src/components/common/formElements";
import { classNames } from "src/helpers";
import "./loadMoreButton.scss";

const LoadMoreButton = (props) => {
  const {
    id,
    className,
    loading,
    disabled,
    onClick,
    itemsLoaded,
    totalItems,
    start,
    variant,
  } = props;
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className="fc-black fs-12 px-4 py-2 bg-grey align-self-center d-flex justify-content-center">
        {t("COMMON.MESSAGES.LOADING")}
      </div>
    );
  }

  return (
    <FormButton
      disabled={disabled}
      id={id}
      className={classNames(["loading-button", variant, className])}
      variant="link"
      onClick={onClick}
    >
      <p className="loading-button__content center fc-grey fs-12 px-2">
        {t("COMMON.MESSAGES.SHOWING")}
        <span className="fc-black mx-1">
          {start}-{itemsLoaded}
        </span>
        {t("COMMON.MESSAGES.OF")}
        <span className="fc-black mx-1">{totalItems}</span>
        {t("COMMON.MESSAGES.RESULTS")}
        <span className="fc-primary-blue semi-bold ml-2">
          {t("COMMON.MESSAGES.SHOW_MORE")}
        </span>
      </p>
    </FormButton>
  );
};

LoadMoreButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  start: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  itemsLoaded: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};
LoadMoreButton.defaultProps = {
  start: 1,
  disabled: false,
  loading: false,
  variant: "primary",
};

export default LoadMoreButton;
