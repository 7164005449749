import { gql, useMutation } from "src/api/client";

const PROCESS_BULK_APPROVAL_IMPORT_MUTATION = gql`
  mutation processBulkApprovalImport(
    $programId: ID!
    $recurrenceId: ID!
    $GroupId: ID
    $fileId: Float!
    $query: JSON
  ) {
    processBulkApprovalImport(
      programId: $programId
      recurrenceId: $recurrenceId
      GroupId: $GroupId
      fileId: $fileId
      query: $query
    ) {
      ... on ProcessBulkApprovalImportResponse {
        success
        message
        response
      }
    }
  }
`;

export const useProcessBulkApprovalImport = () => {
  return useMutation(PROCESS_BULK_APPROVAL_IMPORT_MUTATION);
};
