import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { Accordion } from "react-bootstrap";
import { NavBar, NavItem } from "src/components/layouts";
import { SvgArrow } from "src/icons";
import { Link, useLocation } from "src/router";
import "./sidebarMenuItem.scss";

const AccordianTitle = ({ menu, isSelected }) => {
  if (!menu) return null;

  return (
    <div className="center w-100 accordion-title">
      <div className="d-flex mr-12">
        {isSelected ? menu.iconSelected : menu.icon}
      </div>
      <div>{menu.label}</div>
      {isSelected ? (
        <div className="up-arrow" data-testid={`up-arrow-${menu.label}`}>
          <SvgArrow rotate={180} />
        </div>
      ) : null}
    </div>
  );
};

const AccordionContent = ({ subMenus, basePath }) => {
  const { pathname } = useLocation();

  return (
    <NavBar className="flex-column align-items-start ml-4 mb-4 mt-2 mr-3 border-left-1 bc-grey4 border-bottom-0 submenu-list">
      {subMenus?.map(({ label, path, disabled, totalCount = null }) => {
        const linkPath = `/${basePath}/${path}`;
        const active = linkPath === pathname;
        return (
          <div
            data-testid={`submenu-${label}`}
            className={`d-flex justify-content-between fs-12 w-100 submenu pl-3 ${
              active ? "border-left-2 bc-primary-blue" : ""
            }`}
            key={`submenu-${label}`}
          >
            <div className="d-flex">
              <Link to={linkPath} key={linkPath}>
                <NavItem
                  className={`semi-bold p-0 h-auto ${
                    disabled ? "fc-secondary-black" : ""
                  }`}
                  active={active}
                  disabled={disabled}
                >
                  {label}
                </NavItem>
              </Link>
              {disabled && (
                <div className="fc-grey1 font-italic ml-1">
                  {t("COMMON.MESSAGES.COMING_SOON")}
                </div>
              )}
            </div>
            {active && (
              <div className="semi-bold fc-primary-blue">{totalCount}</div>
            )}
          </div>
        );
      })}
    </NavBar>
  );
};

const SidebarMenuItem = ({
  menu,
  className,
  index,
  selectedItem,
  handleClick,
}) => {
  return (
    <div className="menu-item w-100">
      <Accordion.Toggle
        variant="link"
        eventKey={String(index)}
        data-testid={`menu-item-${menu.label}`}
        className={`w-100 fs-13 semi-bold px-3 border-0 ${
          index === selectedItem
            ? "fc-primary-blue bg-grey6 py-3"
            : "fc-secondary-black bg-white pt-12 pb-12"
        } ${className}`}
        onClick={() => handleClick(index)}
      >
        <AccordianTitle menu={menu} isSelected={index === selectedItem} />
      </Accordion.Toggle>
      <Accordion.Collapse
        data-testid={`submenu-list-${menu.label}`}
        eventKey={String(index)}
      >
        <AccordionContent subMenus={menu?.subMenus} basePath={menu?.basePath} />
      </Accordion.Collapse>
    </div>
  );
};

SidebarMenuItem.propTypes = {
  menu: PropTypes.object,
  index: PropTypes.number,
  selectedItem: PropTypes.number,
  handleClick: PropTypes.func,
  className: PropTypes.string,
};

SidebarMenuItem.defaultProps = {
  menu: null,
  index: 0,
  selectedItem: 0,
  handleClick: () => {},
  className: "",
};

export default SidebarMenuItem;
