import { LoadingPayoutViewList } from "src/components";

function PayoutViewList({ loading, children }) {
  if (loading) {
    return <LoadingPayoutViewList />;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        height: "max-content",
      }}
    >
      {children}
    </div>
  );
}

export default PayoutViewList;
