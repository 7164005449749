import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgIncentive = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 13003">
        <path
          fill={color}
          d="M12 9.444a.88.88 0 0 1 .879.878.8.8 0 0 0 1.6 0A2.476 2.476 0 0 0 12.8 7.987v-.454a.8.8 0 1 0-1.6 0v.454a2.472 2.472 0 0 0 .8 4.814.879.879 0 1 1-.878.879.8.8 0 1 0-1.6 0 2.473 2.473 0 0 0 1.677 2.334v.454a.8.8 0 1 0 1.6 0v-.454a2.471 2.471 0 0 0-.8-4.812.879.879 0 0 1 0-1.757Zm6.7 7a.8.8 0 0 0 1.08-.334 8.8 8.8 0 1 0-3.731 3.7.8.8 0 1 0-.739-1.419 7.091 7.091 0 0 1-3.314.807 7.2 7.2 0 1 1 6.37-3.84.8.8 0 0 0 .333 1.082ZM12 0a11.959 11.959 0 0 0-6.506 1.916.8.8 0 1 0 .869 1.344 10.4 10.4 0 1 1-3.132 3.148.8.8 0 0 0-1.348-.863A12 12 0 1 0 12 0Z"
          data-name="Path 7919"
        />
      </g>
    </svg>
  );
};

SvgIncentive.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 24,
  width: 24,
};
export default SvgIncentive;
