import React from "react";
import { COLORS } from "src/constants";

const SvgInProgressSm = (props) => {
  const {
    height,
    width,
    fill = COLORS.PRIMARY_BLUE,
    fillOpacity,
    ...rest
  } = props;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 15 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <ellipse
        cx={7.009}
        cy={7}
        fill={fill}
        fillOpacity={fillOpacity}
        rx={7.009}
        ry={7}
      />
      <ellipse cx={7.012} cy={7} fill={fill} rx={4.988} ry={4.982} />
    </svg>
  );
};
SvgInProgressSm.defaultProps = {
  height: 14,
  width: 14,
  fillOpacity: 0.4,
};
export default SvgInProgressSm;
