import { t } from "i18next";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BaseCardAccordion, Truncate } from "src/components/common/atoms";
import { FormBadge, FormButton } from "src/components/common/formElements";
import {
  COLORS,
  FORMAT_AGGREGATE_FUNCTION,
  SALES_ACTIVITY_EVENT,
} from "src/constants";
import { classNames, isEmpty } from "src/helpers";
import { SvgArrow, SvgError } from "src/icons";
import RuleCardBody from "../RuleCardBody";
import MobileConditionsModal from "./MobileConditionsModal";
import "./mobileRuleCard.scss";

const CustomToggle = ({
  goalRule,
  ruleName,
  goalAggregateFunction,
  active,
  getSalesActivityStatus,
  alertProps,
  templateDetails,
}) => {
  const [showModal, setShowModal] = useState(false);

  const auditActivityStatus = templateDetails?.AuditActivityStatus;

  const ruleContainsSalesActivity =
    goalRule?.Entity?.activity?.ActivityEvent === SALES_ACTIVITY_EVENT ||
    goalRule?.Entity?.parentActivity?.ActivityEvent === SALES_ACTIVITY_EVENT;

  const showTooltipForSalesActivity =
    (getSalesActivityStatus?.RetrictUsersFromNewActivity ||
      getSalesActivityStatus?.RestrictCompleteAccess) &&
    ruleContainsSalesActivity;

  const disableEditForSalesActivity =
    getSalesActivityStatus?.RestrictCompleteAccess && ruleContainsSalesActivity;

  const isAuditLogsDisabledForEntity = (auditActivityStatus, goalRule) => {
    return auditActivityStatus?.find((auditLogDetail) => {
      return (
        auditLogDetail.RuleId === goalRule.RuleId &&
        auditLogDetail.hasOwnProperty("AuditActivity") &&
        !auditLogDetail.AuditActivity
      );
    });
  };

  const showTooltipForEntityWithoutAuditLogs = isAuditLogsDisabledForEntity(
    auditActivityStatus,
    goalRule
  );

  const getShowAlert = () => {
    const defaultShowAlert =
      showTooltipForSalesActivity || showTooltipForEntityWithoutAuditLogs;
    if (alertProps && "showAlert" in alertProps)
      return alertProps.showAlert || defaultShowAlert;
    return defaultShowAlert;
  };
  const getAlertTooltipText = () => {
    if (showTooltipForSalesActivity) {
      if (disableEditForSalesActivity)
        return t("RULEBOOK.MESSAGES.SALES_ACTIVITY_UNAVAILABLE");
      return t("RULEBOOK.MESSAGES.SALES_ACTIVITY_RESTRICTION");
    }
    return t("RULEBOOK.MESSAGES.ENABLE_THE_AUDIT_LOG_FOR_ENTITY");
  };

  const text = getAlertTooltipText();

  return (
    <>
      <div
        className={classNames([
          "mx-3 py-3 flex-between",
          active ? "border-b" : "",
        ])}
      >
        <div className="w-100 flex-between flex-wrap gap-2">
          <div className="fs-14 semi-bold center">
            <Truncate text={ruleName} maxWidth="260px" />
            {getShowAlert() && (
              <FormButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(true);
                }}
                variant="link"
                className="center ml-2"
              >
                <SvgError color="#F0B840" />
              </FormButton>
            )}
          </div>
          <div className="">
            <FormBadge className="bg-secondary-blue fc-grey1">
              {FORMAT_AGGREGATE_FUNCTION[goalAggregateFunction]?.displayFormat}{" "}
              Function
            </FormBadge>
          </div>
        </div>
        <div className="ml-3">
          <SvgArrow
            className="transition-all"
            color={COLORS.GREY_2}
            rotate={active ? 180 : 0}
          />
        </div>
      </div>
      {showModal && (
        <Modal
          centered
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          className="rulecard-toggle-modal"
        >
          <Modal.Header className="fs-14 semi-bold" closeButton>
            Sales Activity restricted
          </Modal.Header>
          <Modal.Body className="p-3 fs-12">{text}</Modal.Body>
        </Modal>
      )}
    </>
  );
};

const CustomCollapse = ({ goalRule }) => {
  const [conditionModal, showConditionModal] = useState(false);

  const aggregateField =
    FORMAT_AGGREGATE_FUNCTION[goalRule.GoalAggregateFunction]?.displayFormat;
  const rollUpData = goalRule?.RollUpFields;

  const getLabel = (label, cropText) => {
    if (label.toLowerCase().endsWith(cropText)) {
      return label.slice(0, label.toLowerCase().indexOf(cropText));
    }
    return label;
  };

  // # Activity Display Name
  const activityDisplayName = !isEmpty(goalRule?.Entity?.activity)
    ? `${getLabel(
        goalRule?.Entity?.activity?.DisplayName,
        "activity"
      )} Activity`
    : null;

  // # Parent Activity Display Name
  const parentActivityDisplayName = !isEmpty(
    goalRule?.Entity?.parentActivity?.DisplayName
  )
    ? `${getLabel(
        goalRule.Entity?.parentActivity.DisplayName,
        "activity"
      )} Activity`
    : null;

  // # Opportunity Display Name
  const opportunityDisplayName = !isEmpty(goalRule?.Entity?.opportunity)
    ? goalRule?.Entity?.opportunity.length > 1
      ? "Core Opportunities"
      : `${getLabel(
          goalRule?.Entity?.opportunity[0]?.DisplayName,
          "opportunity"
        )} Opportunity`
    : null;

  // # Aggregate Field
  const aggregateFieldDisplayName = rollUpData?.AggregateField?.DisplayName;
  const aggregateFieldRollupType = rollUpData?.AggregateField?.rollUpType;

  // # User Field
  const userFieldDisplayName = rollUpData?.UserField?.DisplayName;
  const userFieldRollupType = rollUpData?.UserField?.rollUpType;

  // # Date Field
  const dateFieldDisplayName = rollUpData?.DateField?.DisplayName;
  const dateFieldRollupType = rollUpData?.DateField?.rollUpType;

  // # Condition
  const conditionsCount = goalRule?.Conditions?.length;

  const getOpportunityLabel = (opportunity) => {
    return opportunity?.length > 1 ? (
      <>
        {t("RULEBOOK.LABELS.ANY_OF_THE")}{" "}
        <span className="fc-black">
          {t("RULEBOOK.LABELS.OPPORTUNITIES_SELECTED")}
        </span>
      </>
    ) : (
      <span className="fc-black long-text-break">
        {getLabel(opportunity[0]?.DisplayName, "opportunity")}{" "}
        {t("RULEBOOK.LABELS.OPPORTUNITY")}
      </span>
    );
  };

  const getEntityContent = (title) => {
    switch (title) {
      case "Opportunity":
        return (
          <>
            <span className="fc-black">
              {!isEmpty(goalRule?.Entity?.opportunity)
                ? getOpportunityLabel(goalRule?.Entity?.opportunity)
                : null}
            </span>{" "}
            {t("RULEBOOK.MESSAGES.IS")}
            <span className="fc-black mx-1">
              {t("RULEBOOK.LABELS.CREATED_OR_UPDATED")}{" "}
            </span>
          </>
        );
      case "Activity":
        return goalRule?.Entity?.entityAction === "Activity on Activity" ||
          goalRule?.Entity?.entityAction === "Activity on Opportunity" ? (
          <>
            <span className="fc-black">
              {!isEmpty(goalRule?.Entity?.activity?.DisplayName)
                ? getLabel(goalRule?.Entity?.activity.DisplayName, "activity")
                : null}{" "}
            </span>
            {t("RULEBOOK.MESSAGES.IS")}
            <span className="fc-black mx-1">
              {t("RULEBOOK.LABELS.CREATED_OR_UPDATED")}{" "}
            </span>{" "}
            {t("RULEBOOK.MESSAGES.ON")}{" "}
            {goalRule?.Entity?.entityAction === "Activity on Activity" ? (
              <span className="fc-black">
                {getLabel(
                  goalRule?.Entity?.parentActivity.DisplayName,
                  "activity"
                )}{" "}
                {t("RULEBOOK.LABELS.ACTIVITY")}
              </span>
            ) : (
              <span className="fc-black">
                {getOpportunityLabel(goalRule?.Entity?.opportunity)}
              </span>
            )}
          </>
        ) : (
          <>
            <span className="fc-black">
              {" "}
              {!isEmpty(goalRule?.Entity?.activity?.DisplayName)
                ? getLabel(goalRule?.Entity?.activity.DisplayName, "activity")
                : null}{" "}
              {goalRule?.Entity?.entityAction}
            </span>{" "}
            {t("RULEBOOK.LABELS.IS_CREATED_OR_UPDATED")}
          </>
        );
      case "Lead":
        return (
          <span className="fc-black">{goalRule?.Entity?.entityAction}</span>
        );
      case "Task":
        return goalRule?.Entity?.entityAction === "Task on Opportunity" ? (
          <>
            <span className="fc-black">
              {!isEmpty(goalRule?.Entity?.task?.Name)
                ? getLabel(goalRule?.Entity?.task.Name, "task")
                : null}
              {" Task "}
            </span>
            {t("RULEBOOK.MESSAGES.IS")}
            <span className="fc-black mx-1">
              {t("RULEBOOK.LABELS.CREATED_OR_UPDATED")}{" "}
            </span>{" "}
            {t("RULEBOOK.MESSAGES.ON")}{" "}
            <span className="fc-black">
              {getOpportunityLabel(goalRule?.Entity?.opportunity)}
            </span>
          </>
        ) : (
          <>
            <span className="fc-black">
              {!isEmpty(goalRule?.Entity?.task?.Name)
                ? getLabel(goalRule?.Entity?.task?.Name, "task")
                : null}{" "}
              {goalRule?.Entity?.entityAction === "Task on Lead"
                ? goalRule?.Entity?.entityAction
                : "Task"}{" "}
            </span>
            {t("RULEBOOK.LABELS.IS_CREATED_OR_UPDATED")}
          </>
        );
      default:
        return <></>;
    }
  };
  const entityContent = (
    <p>{getEntityContent(goalRule?.Entity?.selectedEntity?.title)}</p>
  );

  return (
    <>
      <RuleCardBody
        entityContent={entityContent}
        activityDisplayName={activityDisplayName}
        taskDisplayName={
          !isEmpty(goalRule?.Entity?.task)
            ? `${getLabel(goalRule?.Entity?.task?.Name, "task")} Task`
            : null
        }
        parentActivityDisplayName={parentActivityDisplayName}
        opportunityDisplayName={opportunityDisplayName}
        aggregateField={aggregateField}
        aggregateFieldDisplayName={aggregateFieldDisplayName}
        aggregateFieldRollupType={aggregateFieldRollupType}
        userFieldDisplayName={userFieldDisplayName}
        userFieldRollupType={userFieldRollupType}
        dateFieldDisplayName={dateFieldDisplayName}
        dateFieldRollupType={dateFieldRollupType}
        conditionsCount={conditionsCount}
        disableConditionClick={false}
        showConditionModal={showConditionModal}
        border
      />
      <MobileConditionsModal
        goalRule={goalRule}
        conditionModal={conditionModal}
        showConditionModal={showConditionModal}
        ruleName={goalRule.RuleName}
      />
    </>
  );
};

const MobileRuleCard = ({
  goalRule,
  index,
  handleClick,
  active,
  getSalesActivityStatus,
  templateDetails,
}) => {
  const { RuleName, GoalAggregateFunction } = goalRule;

  return (
    <BaseCardAccordion
      active={active}
      eventKey={index}
      handleClick={handleClick}
      baseCardClassName="mobile-rule-card"
      customToggle={
        <CustomToggle
          goalRule={goalRule}
          active={active}
          ruleName={RuleName}
          goalAggregateFunction={GoalAggregateFunction}
          eventKey={index}
          getSalesActivityStatus={getSalesActivityStatus}
          templateDetails={templateDetails}
        />
      }
      customCollapse={
        <CustomCollapse goalRule={goalRule} templateDetails={templateDetails} />
      }
    />
  );
};

export default MobileRuleCard;
