import { t } from "i18next";
import PropTypes from "prop-types";
import { FormButton, FormTooltip } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgMinus, SvgPlus } from "src/icons";

const addOrRemoveSlabPropTypes = {
  slabIndex: PropTypes.number.isRequired,
  removeSlab: PropTypes.func.isRequired,
  addSlab: PropTypes.func.isRequired,
  isSlabComplete: PropTypes.func.isRequired,
};

export const AddOrRemoveSlab = ({
  slabIndex,
  removeSlab,
  addSlab,
  isSlabItemComplete,
  isSlabStructureComplete,
}) => {
  const isSlabComplete = isSlabItemComplete(slabIndex);
  return (
    <div className="add-remove-slab flex">
      <FormButton
        id="remove-btn"
        className="add-item mr-2"
        variant="text"
        icon={<SvgMinus color={COLORS.GREY_2} />}
        onClick={removeSlab}
      />
      <FormTooltip
        disable={isSlabComplete && !isSlabStructureComplete()}
        text={
          isSlabStructureComplete()
            ? t("INCENTIVES.PROGRAM.MESSAGES.MAX_SLABS_LIMIT_REACHED_TOOLTIP")
            : t("INCENTIVES.PROGRAM.MESSAGES.COMPLETE_SLAB_TOOLTIP")
        }
      >
        <div
          style={{
            cursor:
              !isSlabComplete || isSlabStructureComplete()
                ? "not-allowed"
                : "pointer",
          }}
        >
          <FormButton
            id="add-btn"
            className={classNames([
              "add-item",
              (!isSlabComplete || isSlabStructureComplete()) && "pointer-none",
            ])}
            variant="text"
            icon={<SvgPlus color={COLORS.GREY_2} />}
            onClick={addSlab}
            disabled={!isSlabComplete || isSlabStructureComplete()}
          />
        </div>
      </FormTooltip>
    </div>
  );
};

AddOrRemoveSlab.propTypes = addOrRemoveSlabPropTypes;
