import { gql, useQuery } from "src/api/client";

const GET_TEMPLATES_BASED_INCENTIVES_ACHIEVEMENT_QUERY = gql`
  query fetchTemplateBasedAchievement(
    $TemplateId: ID
    $RecurrenceId: ID
    $ManagerId: ID
  ) {
    fetchTemplateBasedAchievement(
      TemplateId: $TemplateId
      RecurrenceId: $RecurrenceId
      ManagerId: $ManagerId
    ) {
      ... on TemplateBasedAchievementResponse {
        success
        message
        response
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetTemplateBasedAchievement = (queryOptions) => {
  return useQuery(
    GET_TEMPLATES_BASED_INCENTIVES_ACHIEVEMENT_QUERY,
    queryOptions
  );
};
