import { FEATURE_TYPES } from "./generalConstants";
import { RULESET_TYPES } from "./templateConstants";
import { RULESET_AGGREGATION_TYPES } from "./types";

export const RULESET_CHANGE_VALIDATION = {
  [RULESET_AGGREGATION_TYPES.COUNT]: (aggregateFunction) =>
    ![RULESET_AGGREGATION_TYPES.COUNT, RULESET_AGGREGATION_TYPES.SUM].includes(
      aggregateFunction
    ),
  [RULESET_AGGREGATION_TYPES.SUM]: (aggregateFunction) =>
    ![RULESET_AGGREGATION_TYPES.COUNT, RULESET_AGGREGATION_TYPES.SUM].includes(
      aggregateFunction
    ),
  [RULESET_AGGREGATION_TYPES.DISTINCT_COUNT]: (aggregateFunction) =>
    ![
      RULESET_AGGREGATION_TYPES.SUM,
      RULESET_AGGREGATION_TYPES.COUNT,
      RULESET_AGGREGATION_TYPES.DISTINCT_COUNT,
    ].includes(aggregateFunction),
  [RULESET_AGGREGATION_TYPES.AVERAGE]: (aggregateFunction) =>
    ![RULESET_AGGREGATION_TYPES.AVERAGE].includes(aggregateFunction),
  [RULESET_AGGREGATION_TYPES.CUSTOM]: () => true,
  [RULESET_TYPES.ADVANCED]: () => true,
};

export const AGGREGATE_FUNCTIONS_WITH_ROLLUP_DISABLED = {
  [FEATURE_TYPES.GOAL]: [],
  [FEATURE_TYPES.INCENTIVE]: [RULESET_AGGREGATION_TYPES.DISTINCT_COUNT],
  [FEATURE_TYPES.LEADERBOARD]: [],
};
