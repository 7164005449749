import { t } from "i18next";
import React from "react";
import { getTemplateId } from "src/apps/incentives/utils";
import { classNames, getGoalName } from "src/helpers";
import ConditionListAccordion from "./ConditionListAccordion";
import ConditionListItem from "./ConditionListItem";
import "./conditionList.scss";

const ConditionList = (props) => {
  const {
    loading,
    emptyState,
    data,
    templates,
    goals,
    onTemplateSelection,
    showDrillDown,
    mobileProgramDetails,
    headerClassName,
    programId,
    userId,
    recurrenceId,
    as,
  } = props;
  if (loading) return <div>Loading...</div>;
  if (emptyState || !data || data.length === 0) return <div>Empty state</div>;

  const Component =
    as === "Accordion" ? ConditionListAccordion : ConditionListItem;

  return (
    <div className="condition-list-tab">
      <div
        className={classNames([
          "fs-12 semi-bold fc-grey1 condition-list-header center bg-grey5 pl-12 pr-4 py-3",
          headerClassName,
        ])}
      >
        <div className="condition-list-header__condition">
          {t("RULEBOOK.LABELS.CONDITIONS")}
        </div>
      </div>
      {data.map((item, idx) => (
        <Component
          {...item}
          index={idx + 1}
          onTemplateSelection={onTemplateSelection}
          templateId={
            templates &&
            templates.find(
              ({ TemplateId }) => TemplateId === getTemplateId(item)
            )?.TemplateId
          }
          mobileProgramDetails={mobileProgramDetails}
          showDrillDown={showDrillDown}
          condition={{
            ...item,
            goalName: item?.GoalId
              ? item?.goalName
                ? item?.goalName
                : getGoalName({ goalId: item?.GoalId, goals })
              : null,
          }}
          programId={programId}
          userId={userId}
          recurrenceId={recurrenceId}
          templates={templates}
        />
      ))}
    </div>
  );
};

export default ConditionList;
