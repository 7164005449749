import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { FormButton } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { classNames, debounce } from "src/helpers";
import { SvgCross, SvgSearch } from "src/icons";
import "./searchBar.scss";

const SearchBar = (props) => {
  const {
    id,
    placeholder,
    search,
    setSearch,
    secondarySearchIcon,
    primarySearchIcon,
    variant,
    label,
    height,
    backgroundColor,
    width,
    isMobile,
    onSearchOpen,
    disableIcon,
    ruleUsageText,
    className,
    resetSearchOnChange = false,
  } = props;
  const [searchText, setSearchText] = useState(search);
  const [searchOpen, setSearchOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((str) => setSearch(str), 500),
    [setSearch]
  );
  const handleSearchOpen = () => {
    setSearchOpen(true);
    onSearchOpen();
  };
  const handleClose = () => {
    setSearch("");
    setSearchText("");
  };
  const onChange = (e) => {
    setSearchText(e.target.value);
    handleChange(e.target.value);
  };

  useEffect(() => {
    if (resetSearchOnChange) {
      setSearchText("");
    }
  }, [resetSearchOnChange, search]);

  if (isMobile && !searchOpen && variant === "primary") {
    return (
      <div className="d-flex">
        <FormButton
          id="search-icon-btn"
          className="filter-btn br-4 cursor-pointer"
          variant="link"
          icon={<SvgSearch className="m-11" />}
          onClick={handleSearchOpen}
        />
        {!searchOpen && ruleUsageText && (
          <div className=" fs-12 d-flex flex-column ml-auto">
            <p className=" d-flex justify-content-end semi-bold fc-dark-grey">
              {ruleUsageText}
            </p>

            <p className="fc-dark-grey">
              {t("RULEBOOK.MESSAGES.RULES_PUBLISHED")}
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames([variant, className])}
      style={{ width: `${width ? `${width}px` : "100%"}` }}
    >
      {label && (
        <Form.Label className="fs-12 semi-bold mb-8" htmlFor="search">
          {label}
        </Form.Label>
      )}
      <div
        style={{
          height: `${height}px`,
          backgroundColor: variant === "primary" ? "#fff" : backgroundColor,
        }}
        className={`${variant}-input-group center`}
      >
        {!disableIcon && (
          <div className={`${variant}-icon center`}>
            {primarySearchIcon && <SvgSearch className="search-icon mx-2" />}
            {secondarySearchIcon && (
              <SvgSearch
                color={COLORS.PRIMARY_BLUE}
                className="search-blue-icon mx-2"
              />
            )}
          </div>
        )}
        <Form.Control
          className={`fs-13 ${variant}-input`}
          style={{
            backgroundColor: variant === "primary" ? "#fff" : backgroundColor,
          }}
          placeholder={placeholder}
          value={searchText}
          id={id}
          onChange={onChange}
        />
        <FormButton
          id="clear-btn"
          variant="link"
          className={`mr-2 ${
            searchText ? "clear-btn--show" : "clear-btn--hide"
          }`}
          icon={<SvgCross />}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  secondarySearchIcon: PropTypes.bool,
  primarySearchIcon: PropTypes.bool,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary", "translucent"]),
  onSearchOpen: PropTypes.func,
};

SearchBar.defaultProps = {
  onSearchOpen: () => {},
  isMobile: false,
  placeholder: "Enter text",
  variant: "primary",
  primarySearchIcon: true,
  height: 40,
  backgroundColor: "#f1f5f8",
  disableIcon: false,
};

export default SearchBar;
