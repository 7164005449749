import { COLORS, GOAL_AGGREGATION_TYPES } from "src/constants";
import {
  getColor,
  getGreaterThanMarkerColor,
  getLesserThanMarkerColor,
  range,
} from "src/helpers";
import {
  ALIGN_LEFT,
  applyStyles,
  getCellChar,
  getCustomColumnRange,
} from "./data";

export const excelRowStyles = ({
  data,
  worksheet,
  goalAggConfig,
  columns,
  userId,
}) => {
  const getStyles = ({ argb }) => ({
    ...ALIGN_LEFT,
    font: {
      color: {
        argb,
      },
    },
  });

  const goalAchievedStyles = ({ key, idx, getColor }) => {
    const cell = worksheet.getCell(key);
    const isGoalOwner = data[idx]?.UserID === userId;
    const achievementData = isGoalOwner
      ? data[idx].GoalAchieved
      : data[idx]?.RolledUpAchievement
      ? data[idx].RolledUpAchievement
      : data[idx].GoalAchieved;
    const goalValue = data[idx]?.GoalDistribution;

    const color =
      typeof goalValue !== "number" || goalValue === 0
        ? COLORS.GREY_1
        : achievementData < 0
        ? COLORS.RED
        : getColor((((achievementData || 0) / goalValue) * 100).toFixed(2));

    const argb = color.slice(1);

    applyStyles(cell, getStyles({ argb }));

    return cell;
  };

  const goalValueStyles = ({ key, idx, defaultColor }) => {
    const cell = worksheet.getCell(key);
    const goalValue = data[idx]?.GoalDistribution;
    const color =
      typeof goalValue !== "number" || goalValue === 0
        ? COLORS.GREY_1
        : defaultColor;
    const argb = color.slice(1);

    applyStyles(cell, getStyles({ argb }));

    return cell;
  };

  switch (goalAggConfig) {
    case GOAL_AGGREGATION_TYPES.TARGET_BASED: {
      // Goal value
      getCustomColumnRange(
        data.length + 1,
        getCellChar(columns.length - 2),
        2
      ).map((key, idx) =>
        goalValueStyles({ key, idx, defaultColor: COLORS.PRIMARY_BLUE })
      );

      // Goal Achieved & Achievements
      return range(columns.length - 1, columns.length).map((columnNumber) => {
        return getCustomColumnRange(
          data.length + 1,
          getCellChar(columnNumber),
          2
        ).map((key, idx) => goalAchievedStyles({ key, idx, getColor }));
      });
    }
    case GOAL_AGGREGATION_TYPES.AVG_GREATER_THAN_EQUAL:
    case GOAL_AGGREGATION_TYPES.MAINTAIN_GREATER_THAN_EQUAL: {
      // Goal value
      getCustomColumnRange(
        data.length + 1,
        getCellChar(columns.length - 2),
        2
      ).map((key, idx) =>
        goalValueStyles({ key, idx, defaultColor: COLORS.BLACK })
      );

      return getCustomColumnRange(
        data.length + 1,
        getCellChar(columns.length),
        2
      ).map((key, idx) =>
        goalAchievedStyles({ key, idx, getColor: getGreaterThanMarkerColor })
      );
    }
    case GOAL_AGGREGATION_TYPES.AVG_LESS_THAN_EQUAL:
    case GOAL_AGGREGATION_TYPES.MAINTAIN_LESS_THAN_EQUAL: {
      // Goal value
      getCustomColumnRange(
        data.length + 1,
        getCellChar(columns.length - 2),
        2
      ).map((key, idx) =>
        goalValueStyles({ key, idx, defaultColor: COLORS.BLACK })
      );

      return getCustomColumnRange(
        data.length + 1,
        getCellChar(columns.length),
        2
      ).map((key, idx) =>
        goalAchievedStyles({ key, idx, getColor: getLesserThanMarkerColor })
      );
    }
    default: {
      return null;
    }
  }
};
