import PropTypes from "prop-types";
import React from "react";
import { BaseCard, FormButton, Truncate } from "src/components";
import { STATUSES, statusClasses } from "src/constants";
import { classNames } from "src/helpers";
import "./userOrProgramCard.scss";

const UserOrProgramCard = ({
  title,
  subtitle,
  status,
  onClick,
  list,
  userId,
  className,
  disableClick,
  children,
  selectedTemplates,
  onGoalUserChange,
  setSelectedTemplateId,
  showDrillDown,
}) => {
  return (
    <BaseCard
      className={classNames([
        "border-1 bc-grey4 bg-white fs-12 p-3 br-8 mb-3",
        className,
      ])}
    >
      <div className="flex-between mb-3">
        <FormButton
          onClick={!disableClick ? onClick : undefined}
          disabled={disableClick}
          variant="link"
          label={
            <span
              className={classNames([
                "fs-14 semi-bold text-start",
                !disableClick ? "fc-primary-blue cursor-pointer" : "fc-black",
              ])}
            >
              <Truncate text={title} maxWidth="250px" />
              <div className="fc-grey fs-12 font-weight-normal">{subtitle}</div>
            </span>
          }
        />

        {status && (
          <div
            className={`fs-12 semi-bold br-4 status status-badge-cell ${
              statusClasses[status] || "default"
            }`}
          >
            {STATUSES[status]?.label}
          </div>
        )}
      </div>
      <hr className="w-100 mb-2" />
      <div className="d-flex flex-wrap w-100">
        {list?.map((item, idx) => (
          <div className="w-50 mb-1">
            <FormButton
              className={classNames([
                " fs-12 fc-grey d-flex justify-content-start normal",
                onGoalUserChange && showDrillDown && item?.isDrillDownEnabled
                  ? "text-underline"
                  : "",
              ])}
              onClick={() => {
                if (showDrillDown && item?.isDrillDownEnabled) {
                  setSelectedTemplateId(
                    selectedTemplates.find((template) => {
                      return template.Name === item.label.props.text;
                    }).TemplateId
                  );
                  onGoalUserChange({ FullName: "All", UserID: "" });
                }
              }}
              variant="link"
            >
              {item.label}
            </FormButton>
            <FormButton
              onClick={() => {
                if (showDrillDown && item?.isDrillDownEnabled) {
                  setSelectedTemplateId(
                    selectedTemplates.find((template) => {
                      return template.Name === item.label.props.text;
                    }).TemplateId
                  );
                  onGoalUserChange({
                    FullName: title,
                    UserID: userId,
                    Achievement: item.value,
                  });
                }
              }}
              variant="link"
              label={
                <span
                  className={classNames([
                    "fs-12 fc-black semi-bold d-flex justify-content-start ",
                    onGoalUserChange &&
                    showDrillDown &&
                    item?.isDrillDownEnabled
                      ? "text-underline"
                      : "",
                  ])}
                >
                  {item.value}
                </span>
              }
            />
          </div>
        ))}
      </div>
      {children}
    </BaseCard>
  );
};

UserOrProgramCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  eligible: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  eligibleRewards: PropTypes.array,
  approved: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  approvedRewards: PropTypes.array,
  paidout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paidoutRewards: PropTypes.array,
  pendingPaidout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pendingPaidoutRewards: PropTypes.array,
  status: PropTypes.string,
  onClick: PropTypes.func,
  rewardCategoryList: PropTypes.bool,
  disableClick: PropTypes.bool,
};

UserOrProgramCard.defaultProps = {
  title: "",
  subtitle: "",
  status: "",
  disableClick: false,
  onClick: () => {},
};

export default UserOrProgramCard;
