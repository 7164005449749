import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'goals.web.publishedGoalViewPage' */ "./web")
);

const MobilePage = lazy(() =>
  import(
    /* webpackChunkName: 'goals.mobile.publishedGoalViewPage' */ "./mobile"
  )
);

export const PublishedGoalViewPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "Published",
  },
};
