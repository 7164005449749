import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_APPROVERS_LIST = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderboardDetails($LeaderboardId: ID!) {
    getLeaderboardDetails(LeaderboardId: $LeaderboardId) {
      __typename
      ... on SingleLeaderboardResponse {
        response {
          Leaderboard {
            LeaderboardId
            Name
            Description
            LeaderboardOwner {
              FirstName
              LastName
              EmailAddress
              UserID
              StatusCode
            }
            RecurrencesCount
            CurrentRecurrenceCycle
            RecurrenceTimeLeft
            RecurrenceDueText
            Status
            InGaps
            RecurrenceDefinition {
              FirstRecurrenceStartDate
              LastRecurrenceEndDate
              RecurrencePeriod
              RecurrenceCycleDuration
              CustomType
              CustomGap
              Recurrences {
                RecurrenceStartDate
                RecurrenceEndDate
              }
            }
            Type
            IsRollup
            RollupType
            DefaultBufferDays
            BufferDays
            SelectedUsersCount
            SelectedTemplates {
              TemplateId
              Name
              GoalAggregateFunction
              __typename
            }
            SelectedApprovers {
              LeaderboardId
              UserId
              Level
              Stage
              Type
              StatusCode
              EmailAddress
              FirstName
              LastName
            }
            __typename
          }
          __typename
        }
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetApproversList = (queryOptions) => {
  return useQuery(GET_APPROVERS_LIST, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
