import { gql, useQuery } from "src/api/client";

const GET_LEADERBOARD_ACHIEVEMENT_CONFIG = gql`
  query getLeaderboardAchievementsConfig(
    $LeaderboardId: ID!
    $RecurrenceId: ID!
    $GroupId: ID
  ) {
    getLeaderboardAchievementsConfig(
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
      GroupId: $GroupId
    ) {
      ... on LeaderboardAchievementsConfigResponse {
        success
        response {
          LeaderboardAchievementsConfig {
            CycleType
            BadgeType
            CustomType
            Status
            Type
            Name
            LastUpdated
            IsGroupBased
            GroupDetails {
              GroupId
              GroupName
              GroupTarget
              GroupAchieved
              MandatoryGroupTarget
            }
            GoalValue
            MinimumTarget
            Rank
            UserAchieved
            IsUserPresentInGroup
            Rewards
            GroupBasedRewards
            RankBasedRewards
          }
        }
      }
    }
  }
`;

export const useLeaderboardAchievementConfig = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_ACHIEVEMENT_CONFIG, queryOptions);
};
