import React from "react";
import { Image } from "src/components";
import ConditionsEmptyStateImg from "./ConditionsEmptyState.svg";

const ConditionsEmptyState = (props) => (
  <Image
    src={ConditionsEmptyStateImg}
    alt="ConditionsEmptyStateImg"
    {...props}
  />
);

export default ConditionsEmptyState;
