import { MAX_DUPLICATE_INCENTIVE_CONDITIONS } from "src/constants";
import { isEmpty, range } from "src/helpers";

export const repeatChar = (count, ch) => {
  let txt = "(";
  for (let i = 1; i < count + 1; i++) {
    txt += i;
    if (i < count) {
      txt += ch;
    }
  }
  txt += ")";
  return txt;
};

export const createPattern = (data) => {
  return repeatChar(data.length, " OR ");
};

export const addSpace = (e) => {
  const addSpaceNumber = e.replace(/([0-9]+)/g, " $1 ");
  const addSpaceAND = addSpaceNumber.replace(/(AND)/g, " $1 ");
  const addSpaceOR = addSpaceAND.replace(/(OR)/g, " $1 ");
  return addSpaceOR;
};

export const allowedOperator = (e) => {
  const uppercase = e.toUpperCase();
  const removeOperators = uppercase.replace(/(?:AND|OR)/g, "");
  const removeBracketAndDigit = removeOperators.replace(/[()\d]/g, "");
  const removeSpace = removeBracketAndDigit.replace(/ /g, "");
  if (!isEmpty(removeSpace)) return true;
  return false;
};

export const operatorMisplaced = (e) => {
  const format = /\s*(?:AND|OR)\s*(?:AND|OR)\s*/g;
  const matches = e.trim().toUpperCase().replace(/[()]/g, "");
  if (format.test(matches)) return true;
  return false;
};

export const specialCharacter = (e) => {
  const format = /[!@#$%^&*_+\-=;':"\]}><[{\\|,./?]+/g;
  if (format.test(e)) return true;
  return false;
};

const configuredConditions = (e) => {
  const removeBracket = e.trim().replace(/()/g, "");
  const matchingArray =
    !isEmpty(removeBracket) && removeBracket.match(/\d+/g)?.map(Number);
  return matchingArray || [];
};

export const errorConditions = (e, conditions) => {
  const conditionObj = { missing: false, invalid: false, duplicate: false };
  const conditionArr = configuredConditions(e);
  const n = conditions.length;
  let count = 0;
  let duplicateCount = 1;

  conditionArr.sort((a, b) => a - b);

  if (conditionArr[0] > n || conditionArr[0] === 0) conditionObj.invalid = true;
  else count++;

  for (const i of range(1, conditionArr.length)) {
    if (conditionArr[i] > n || conditionArr[i] === 0)
      conditionObj.invalid = true;
    if (conditionArr[i] === conditionArr[i - 1]) duplicateCount++;
    else if (conditionArr[i] <= n) count++;
  }

  if (count < n) conditionObj.missing = true;
  if (duplicateCount >= MAX_DUPLICATE_INCENTIVE_CONDITIONS)
    conditionObj.duplicate = true;
  return conditionObj;
};

export const isBalanced = (input) => {
  const brackets = "()";
  const stack = [];
  for (const bracket of input) {
    const bracketsIndex = brackets.indexOf(bracket);
    if (bracketsIndex === -1) {
      continue;
    }
    if (bracketsIndex % 2 === 0) {
      stack.push(bracketsIndex + 1);
    } else if (stack.pop() !== bracketsIndex) {
      return false;
    }
  }
  return stack.length === 0;
};

export const invalidFormat = (e) => {
  const str = e.trim().toUpperCase();
  const reg1 = /(?:AND|OR)\s{0,}\)/g; // # AND/OR )
  const reg2 = /\(\s{0,}(?:AND|OR)/g; // # ( AND/OR
  const reg3 = /(?:AND|OR)\s{0,}$/g; // # AND/OR at the end
  const reg4 = /\(\s{0,}\)/g; // # Empty Parenthesis
  const reg5 = /[0-9]+(?:\s{1,}|\s{0,}\(\s{0,}|\s{0,}\)\s{0,})[0-9]+/; // # two numbers together or joined by space,(,)
  if (
    str.match(reg1) ||
    str.match(reg2) ||
    str.match(reg3) ||
    str.match(reg4) ||
    str.match(reg5)
  )
    return true;
  return false;
};

export const replaceSpecialCharacter = (conditionPattern) => {
  const format = /[!@#$%^&*_+\-=;':"\]}><[{\\|,./?]+/g;
  const updatedConditionPattern = conditionPattern.replace(format, ""); // # removed special characters
  return updatedConditionPattern;
};
