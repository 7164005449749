import React from "react";
import {
  DailyGoldTrophy,
  MonthlyGoldTrophy,
  QuarterlyGoldTrophy,
  WeeklyGoldTrophy,
  YearlyGoldTrophy,
} from "src/images";

export const CYCLES_BUTTONS = [
  {
    id: "YEARLY",
    icon: () => <YearlyGoldTrophy width="44px" height="40px" />,
  },
  {
    id: "QUARTERLY",
    icon: () => <QuarterlyGoldTrophy width="34px" height="40px" />,
  },
  {
    id: "MONTHLY",
    icon: () => <MonthlyGoldTrophy width="40px" height="44px" />,
  },
  {
    id: "WEEKLY",
    icon: () => <WeeklyGoldTrophy width="55px" height="43px" />,
  },
  {
    id: "DAILY",
    icon: () => <DailyGoldTrophy width="41px" height="40px" />,
  },
];

export const ITEMS_TO_SHOW = 3;
