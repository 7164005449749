import React from "react";

const SvgSuccessToast = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l633pla">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l633pla)" data-name="Group 7897">
        <circle
          cx={16}
          cy={16}
          r={16}
          fill={backgroundColor}
          data-name="Ellipse 152"
        />
        <g
          fill="none"
          stroke={color}
          strokeWidth={1.5}
          data-name="Group 7081"
          transform="translate(8.93 10.007)"
        >
          <circle
            cx={7}
            cy={7}
            r={7}
            data-name="Ellipse 146"
            transform="translate(.071 -1.007)"
          />
          <path
            strokeLinecap="round"
            d="m4.64 6.765 1.3 1.333 3.508-3.343"
            data-name="Path 3911"
          />
        </g>
      </g>
    </svg>
  );
};

SvgSuccessToast.defaultProps = {
  backgroundColor: "#ebffe5",
  color: "#27ae60",
  height: 32,
  width: 32,
};
export default SvgSuccessToast;
