import PropTypes from "prop-types";
import React from "react";
import {
  FilterBar,
  FilterMenu,
  FilterMenuItem,
  SearchBar,
  SelectFilter,
} from "src/components/common/atoms";
import { SortGrid } from "../sortGrid";
import "./filterMenuBar.scss";

const FilterMenuBar = (props) => {
  const {
    searchProps,
    filters,
    customFilters,
    hideSearchBar,
    sortOptions,
    selectedSort,
    setSelectedSort,
  } = props;
  return (
    <FilterBar
      className={`my-4 ${hideSearchBar ? "flex-row" : "flex-row-reverse"}`}
    >
      <span className="center">
        {sortOptions && setSelectedSort && selectedSort && (
          <SortGrid
            className="mx-2"
            sortOptions={sortOptions}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
          />
        )}
        {!hideSearchBar && (
          <div className="mobile-search-bar">
            <SearchBar {...searchProps} width={200} />
          </div>
        )}
      </span>
      <FilterMenu>
        {filters?.map((filter, idx) => (
          <FilterMenuItem
            className={filter?.menuItemClassName || ""}
            key={filter?.id}
          >
            <SelectFilter {...filter} />
          </FilterMenuItem>
        ))}
        {customFilters && <FilterMenuItem>{customFilters}</FilterMenuItem>}
      </FilterMenu>
    </FilterBar>
  );
};

FilterMenuBar.propTypes = {
  searchProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
  }),
  hideSearchBar: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      highlight: PropTypes.bool,
      value: PropTypes.string.isRequired,
      onchange: PropTypes.func.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

FilterMenuBar.defaultProps = {
  searchProps: {},
  createButton: false,
  filters: [],
  hideSearchBar: false,
};

export default FilterMenuBar;
