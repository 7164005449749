import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_LEADERBOARD_CYCLE_DETAILS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderboardRecurrenceSummary($LeaderboardId: ID!) {
    getLeaderboardRecurrenceSummary(LeaderboardId: $LeaderboardId) {
      __typename
      ... on LeaderboardRecurrenceSummaryResponse {
        success
        message
        response {
          Recurrences {
            LeaderboardId
            RecurrenceId
            RecurrenceStartDate
            RecurrenceEndDate
            RecurrenceType
            TotalUsers
            SelectedUsersCount
            UserIdentifier
            GroupsCount
            RecurrenceStatus
            __typename
          }
          CurrentRecurrence {
            LeaderboardId
            RecurrenceEndDate
            RecurrenceId
            RecurrenceStartDate
            RecurrenceType
            CustomType
            TotalUsers
            SelectedUsersCount
            UserIdentifier
            GroupsCount
            RecurrenceStatus
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;

export const useGetLeaderboardRecurrenceSummary = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_CYCLE_DETAILS_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
