import React from "react";
import { Shimmer } from "src/components/common/atoms";

const LoadingPublishedTabs = () => {
  return (
    <div className="page-container py-3 flex-between align-items-center border-b">
      <div className=" flex-column">
        <Shimmer width="100px" height="10px" />
        <div className="pt-3">
          <Shimmer width="200px" height="10px" />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="mr-4">
          <Shimmer width="200px" height="25px" />
        </div>
        <div className="mr-3">
          <Shimmer width="40px" height="40px" />
        </div>
        <div className="mr-3">
          <Shimmer width="75px" height="40px" />
        </div>
        <div>
          <Shimmer width="125px" height="40px" />
        </div>
      </div>
    </div>
  );
};

export default LoadingPublishedTabs;
