import { gql, useQuery } from "src/api/client";

const GET_LEADERBOARD_DASHBOARD_TOTAL = gql`
  query leaderboardDashboardTotal($query: LeaderboardDashboardTotalQuery!) {
    leaderboardDashboardTotal(query: $query) {
      ... on LeaderboardDashboardTotalResponse {
        success
        response {
          LeaderboardDashboardTotal {
            TotalRewardsRecievedAmount
            TotalApprovalRequestsAmount
            TotalPayoutRequestsAmount
            TotalPayoutRequestsRewards {
              RewardCount
              RewardCategory
            }
            TotalRecievedRewards {
              RewardCount
              RewardCategory
            }
            TotalApprovalRequestsRewards {
              RewardCount
              RewardCategory
            }
            __typename
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useLeaderboardDashboardTotals = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_DASHBOARD_TOTAL, queryOptions);
};
