export const TOKEN_TYPE = {
  METRIC: "METRIC",
  FIELD: "FIELD",
  OPERATOR: "OPERATOR",
  STATIC: "STATIC",
  PARENTHESIS: "PARENTHESIS",
};

export const OPERATOR_TOKEN_TYPE = {
  PARENTHESIS: "PARENTHESIS",
  MATHEMATICAL_OPERATOR: "MATHEMATICAL_OPERATOR",
  COMPARISON_OPERATOR: "COMPARISON_OPERATOR",
  FUNCTION: "FUNCTION",
};

const FORMULA_MATHEMATICAL_OPERATOR_TYPE = {
  PLUS: "PLUS",
  MINUS: "MINUS",
  MULTIPLY: "MULTIPLY",
  DIVIDE: "DIVIDE",
};

const FORMULA_COMPARISON_OPERATOR_TYPE = {
  EQUAL: "EQUAL",
  NOT_EQUAL: "NOT_EQUAL",
  GREATER_THAN: "GREATER_THAN",
  LESS_THAN: "LESS_THAN",
  GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
  LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
};

const FORMULA_PARENTHESES_TYPE = {
  OPEN_PAREN: "OPEN_PAREN",
  CLOSE_PAREN: "CLOSE_PAREN",
};

const FORMULA_OPERATOR_FUNCTION_TYPES = {
  BETWEEN: "BETWEEN",
};

export const FORMULA_OPERATOR_TYPE = {
  ...FORMULA_PARENTHESES_TYPE,
  ...FORMULA_MATHEMATICAL_OPERATOR_TYPE,
  ...FORMULA_COMPARISON_OPERATOR_TYPE,
  ...FORMULA_OPERATOR_FUNCTION_TYPES,
};

export const FORMULA_OPERATORS = {
  [FORMULA_OPERATOR_TYPE.OPEN_PAREN]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.PARENTHESIS,
    label: "Left Round Bracket",
    value: "(",
  },
  [FORMULA_OPERATOR_TYPE.CLOSE_PAREN]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.PARENTHESIS,
    label: "Right Round Bracket",
    value: ")",
  },
  [FORMULA_OPERATOR_TYPE.PLUS]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.MATHEMATICAL_OPERATOR,
    label: "Addition",
    value: "+",
    precedence: 1,
  },
  [FORMULA_OPERATOR_TYPE.MINUS]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.MATHEMATICAL_OPERATOR,
    label: "Subtraction",
    value: "-",
    precedence: 1,
  },
  [FORMULA_OPERATOR_TYPE.MULTIPLY]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.MATHEMATICAL_OPERATOR,
    label: "Multiplication",
    value: "*",
    precedence: 2,
  },
  [FORMULA_OPERATOR_TYPE.DIVIDE]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.MATHEMATICAL_OPERATOR,
    label: "Division",
    value: "/",
    precedence: 2,
  },
  [FORMULA_OPERATOR_TYPE.EQUAL]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.COMPARISON_OPERATOR,
    label: "Equal",
    value: "=",
    precedence: 0,
  },
  [FORMULA_OPERATOR_TYPE.NOT_EQUAL]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.COMPARISON_OPERATOR,
    label: "Not Equal",
    value: "≠",
    precedence: 0,
  },
  [FORMULA_OPERATOR_TYPE.GREATER_THAN]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.COMPARISON_OPERATOR,
    label: "Greater Than",
    value: ">",
    precedence: 0,
  },
  [FORMULA_OPERATOR_TYPE.LESS_THAN]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.COMPARISON_OPERATOR,
    label: "Less Than",
    value: "<",
    precedence: 0,
  },
  [FORMULA_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.COMPARISON_OPERATOR,
    label: "Greater Than or Equal",
    value: "≥",
    precedence: 0,
  },
  [FORMULA_OPERATOR_TYPE.LESS_THAN_OR_EQUAL]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.COMPARISON_OPERATOR,
    label: "Less Than or Equal",
    value: "≤",
    precedence: 0,
  },
  [FORMULA_OPERATOR_TYPE.BETWEEN]: {
    operatorTokenType: OPERATOR_TOKEN_TYPE.FUNCTION,
    label: "Between",
    value: "BETWEEN",
    precedence: 0,
  },
};

export const FORMULA_OPERATOR_SYMBOLS_MAP = {
  "+": FORMULA_OPERATOR_TYPE.PLUS,
  "-": FORMULA_OPERATOR_TYPE.MINUS,
  "*": FORMULA_OPERATOR_TYPE.MULTIPLY,
  "/": FORMULA_OPERATOR_TYPE.DIVIDE,
  "=": FORMULA_OPERATOR_TYPE.EQUAL,
  "!=": FORMULA_OPERATOR_TYPE.NOT_EQUAL,
  ">": FORMULA_OPERATOR_TYPE.GREATER_THAN,
  "<": FORMULA_OPERATOR_TYPE.LESS_THAN,
  ">=": FORMULA_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  "<=": FORMULA_OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  "(": FORMULA_OPERATOR_TYPE.OPEN_PAREN,
  ")": FORMULA_OPERATOR_TYPE.CLOSE_PAREN,
  BETWEEN: FORMULA_OPERATOR_TYPE.BETWEEN,
};

export const FORMULA_TOKENS = {
  [TOKEN_TYPE.OPERATOR]: {
    values: FORMULA_OPERATORS,
  },
  [TOKEN_TYPE.STATIC]: {},
  [TOKEN_TYPE.METRIC]: {},
};

export const formulaNamespace = {
  TOKEN_TYPE,
  OPERATOR_TOKEN_TYPE,
  FORMULA_OPERATOR_TYPE,
  FORMULA_OPERATOR_SYMBOLS_MAP,
  FORMULA_OPERATORS,
  FORMULA_TOKENS,
};

export default formulaNamespace;
