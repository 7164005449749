import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_INCENTIVES_METRIC_PROGRAM_DETAILS = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getMetricProgramDetails($ProgramId: ID!) {
    getMetricProgramDetails(ProgramId: $ProgramId) {
      ... on metricProgramDetailsResponse {
        success
        message
        response {
          SelectedTemplates {
            TemplateId
            Name
            GroupBy
          }
          SelectedGoals {
            GoalId
            GoalName
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetIncentiveMetricProgramDetails = (queryOptions) => {
  return useQuery(GET_INCENTIVES_METRIC_PROGRAM_DETAILS, {
    fetchPolicy: "no-cache",
    ...queryOptions,
  });
};
