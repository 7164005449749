import React from "react";
import { Image } from "src/components";
import GoldDailyImg from "./GoldDaily.svg";
import GoldDailyWithoutRankImg from "./GoldDailyWithoutRank.svg";
import GoldMonthlyImg from "./GoldMonthly.svg";
import GoldMonthlyWithoutRankImg from "./GoldMonthlyWithoutRank.svg";
import GoldQuarterlyImg from "./GoldQuarterly.svg";
import GoldWeeklyImg from "./GoldWeekly.svg";
import GoldWeeklyWithoutRankImg from "./GoldWeeklyWithoutRank.svg";
import GoldYearlyImg from "./GoldYearly.svg";
import GoldYearlyWithoutRankImg from "./GoldYearlyWithoutRank.svg";

export const GoldDaily = (props) => (
  <Image src={GoldDailyImg} alt="GoldDailyImg" {...props} />
);

export const GoldWeekly = (props) => (
  <Image src={GoldWeeklyImg} alt="GoldWeeklyImg" {...props} />
);

export const GoldMonthly = (props) => (
  <Image src={GoldMonthlyImg} alt="GoldMonthlyImg" {...props} />
);

export const GoldQuarterly = (props) => (
  <Image src={GoldQuarterlyImg} alt="GoldQuarterlyImg" {...props} />
);

export const GoldYearly = (props) => (
  <Image src={GoldYearlyImg} alt="GoldYearlyImg" {...props} />
);

export const GoldDailyWithoutRank = (props) => (
  <Image
    src={GoldDailyWithoutRankImg}
    alt="GoldDailyWithoutRankImg"
    {...props}
  />
);

export const GoldWeeklyWithoutRank = (props) => (
  <Image
    src={GoldWeeklyWithoutRankImg}
    alt="GoldWeeklyWithoutRankImg"
    {...props}
  />
);

export const GoldMonthlyWithoutRank = (props) => (
  <Image
    src={GoldMonthlyWithoutRankImg}
    alt="GoldMonthlyWithoutRankImg"
    {...props}
  />
);

export const GoldQuarterlyWithoutRank = (props) => (
  <Image src={GoldQuarterlyImg} alt="GoldQuarterlyWithoutRankImg" {...props} />
);

export const GoldYearlyWithoutRank = (props) => (
  <Image
    src={GoldYearlyWithoutRankImg}
    alt="GoldYearlyWithoutRankImg"
    {...props}
  />
);
