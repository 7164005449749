import React from "react";
import { Shimmer } from "src/components/common/atoms";

const LoadingSideBannerCard = () => {
  return (
    <div className="row mb-3 mx-0 border-1 bc-grey3 br-8">
      <div className="w-100 col-3 p-0">
        <Shimmer width="100%" height="270px" />
      </div>
      <div className="w-100 col-9 p-0">
        <div className="py-3 pl-4 pr-0 border-bottom-1 bc-grey8 side-banner-card__right--header flex-between">
          <Shimmer width="150px" />
        </div>
        <div className="py-3 px-4 space-y-16">
          <Shimmer width="100%" height="40px" />
          <Shimmer width="100%" height="40px" />
          <Shimmer width="100%" height="40px" />
        </div>
      </div>
    </div>
  );
};

export default LoadingSideBannerCard;
