import React from "react";
import { classNames } from "src/helpers";
import AceMenuListItem from "./AceMenuListItem";
import "./aceMenuList.scss";

const AceMenuList = ({ menu, activeRoute, variant = "web" }) => {
  return (
    <div className={classNames(["ace-menu-list", variant])}>
      <div className={classNames(["ace-menu-list-content", variant])}>
        {menu.map(({ label, route, baseRoute, isBeta, icon }) => {
          const isActive = baseRoute === activeRoute.baseRoute;
          return (
            <AceMenuListItem
              {...{ label, route, icon, isActive, isBeta, variant }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AceMenuList;
