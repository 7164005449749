import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useUpdateProgramFile } from "src/api/useIncentive";
import { FormButton, toaster } from "src/components";
import ProgramDescription from "./ProgramDescription";
import ProgramInformation from "./ProgramInformation";
import ProgramName from "./ProgramName";

function UpdateProgramModal({ show, handleClose, programDetails, refetch }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [updateProgramFile] = useUpdateProgramFile();
  const [state, setState] = useState({
    Name: programDetails.Name,
    Description: programDetails.Description,
    fileDetails: {
      FileName: programDetails.FileName,
    },
  });

  useEffect(() => {
    setState({
      Name: programDetails.Name,
      Description: programDetails.Description,
      fileDetails: {
        FileName: programDetails.FileName,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleChange = ({ name, value }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const variables = {
      ProgramId: programDetails.ProgramId,
      Name: state.Name,
      Description: state.Description,
    };
    if (!state.fileDetails.FileName) {
      variables.FileId = null;
      variables.FileName = null;
    }
    if (state.fileDetails.FileName) {
      variables.FileName = state.fileDetails.FileName;
    }
    if (state.fileDetails.FileId) {
      variables.FileId = state.fileDetails.FileId;
    }
    updateProgramFile({
      variables,
    }).then((result) => {
      refetch && refetch();
      toaster({
        message: "Program updated successfully.",
        type: "info",
      });
      handleClose();
    });
  };

  const isDisabled = Boolean(
    disabled || !state.Name || state.Name.trim() === ""
  );

  return (
    <Modal
      show={show}
      dialogClassName="cu-incentive-modal"
      onHide={handleClose}
    >
      <Modal.Header closeButton className="fs-18 semi-bold">
        {t("INCENTIVES.COMMON.LABELS.UPDATE_INCENTIVE_PROGRAM")}
      </Modal.Header>
      <Modal.Body className="p-4">
        <form id="incentive-form" onSubmit={handleSubmit}>
          <ProgramName
            name="Name"
            value={state.Name}
            handleChange={handleChange}
            label={t("INCENTIVES.COMMON.LABELS.INCENTIVE_PROGRAM")}
            placeholder={t(
              "INCENTIVES.COMMON.LABELS.ENTER_INCENTIVE_PROGRAM_NAME"
            )}
            setDisable={setDisabled}
          />
          <ProgramDescription
            name="Description"
            value={state.Description}
            handleChange={handleChange}
            label={t("INCENTIVES.COMMON.LABELS.INCENTIVE_DESCRIPTION")}
            placeholder={t(
              "INCENTIVES.COMMON.LABELS.ENTER_INCENTIVE_PROGRAM_DESCRIPTION"
            )}
          />
          <ProgramInformation
            programId={programDetails.ProgramId}
            name="fileDetails"
            value={state.fileDetails}
            label={t("INCENTIVES.COMMON.LABELS.UPLOAD_INCENTIVE_INFORMATION")}
            tip={t("INCENTIVES.COMMON.LABELS.UPLOAD_INCENTIVE_INFORMATION_TIP")}
            setDisable={setDisabled}
            setFileDetails={handleChange}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          id="close-incentive-form"
          variant="outline-primary"
          label={t("COMMON.BUTTONS.CANCEL")}
          onClick={handleClose}
          className="mr-3"
        />
        <FormButton
          id="create-incentive-form"
          label={t("COMMON.BUTTONS.UPDATE")}
          type="submit"
          form="incentive-form"
          disabled={isDisabled}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateProgramModal;
