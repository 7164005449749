import { gql, useQuery } from "src/api/client";
import {
  CORE_TEMPLATE_DETAILS_FRAGMENT,
  CORE_TEMPLATE_GOAL_RULE_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
} from "src/api/fragments";

// src/components/goals/template_selection/TemplateSelectModal.js --done!!
export const GET_SELECTION_TEMPLATES_QUERY = gql`
  ${CORE_TEMPLATE_DETAILS_FRAGMENT}
  ${CORE_TEMPLATE_GOAL_RULE_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query getTemplates(
    $Limit: Float
    $Query: JSON
    $After: String
    $TemplateId: ID
    $IncludeTemplates: [ID]
    $ExcludeTemplates: [ID]
    $ExcludeGoalAggregateFunction: [String]
  ) {
    getTemplates(
      Limit: $Limit
      Query: $Query
      After: $After
      TemplateId: $TemplateId
      IncludeTemplates: $IncludeTemplates
      ExcludeTemplates: $ExcludeTemplates
      ExcludeGoalAggregateFunction: $ExcludeGoalAggregateFunction
    ) {
      __typename
      ... on GetAllTemplateResponse {
        response {
          totalCount
          hasTemplates
          Templates {
            ...coreTemplateDetailsFragment
            GoalRules {
              ...coreTemplateGoalRuleFragment
            }
            GroupBy
          }
          pageInfo {
            endCursor
          }
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetSelectionTemplates = (queryOptions) => {
  return useQuery(GET_SELECTION_TEMPLATES_QUERY, queryOptions);
};
