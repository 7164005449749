import React from "react";
import FilterButton from "../FilterButton";
import FilterButtonGroup from "../FilterButtonGroup";
import "./filterBadgeButtons.scss";

const FilterBadgeButtons = ({ filters, activeFilter, onClick, className }) => {
  return (
    <FilterButtonGroup className={className}>
      {filters.map((filter) => (
        <FilterButton
          active={filter.key === activeFilter.key}
          onClick={() => onClick(filter)}
        >
          {filter.Icon}
          {filter.label}{" "}
          {filter.count !== null ? (
            <span className="badge">{filter.count}</span>
          ) : null}
        </FilterButton>
      ))}
    </FilterButtonGroup>
  );
};

export default FilterBadgeButtons;
