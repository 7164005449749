import { gql, useMutation } from "src/api/client";

// src/goalCrud/distributeGoal/importModal/importFooters/DragDrop.js
export const GOAL_VALIDATION_REPORT_MUTATION = gql`
  mutation importGoal($GoalId: ID!, $FileId: Float!) {
    importGoal(GoalId: $GoalId, FileId: $FileId) {
      message
      DownloadUrl
      ErrorCount
    }
  }
`;

export const useGoalValidationReport = () => {
  return useMutation(GOAL_VALIDATION_REPORT_MUTATION);
};
