import PropTypes from "prop-types";
import { classNames } from "src/helpers";
import { Shimmer } from "../shimmer";
import "./aspectRatio.scss";

const getAspectRatio = ({ ratio }) => {
  let width;
  let height;
  if (typeof ratio === "object") {
    width = ratio.width;
    height = ratio.height;
  } else if (typeof ratio === "string") {
    const dimentions = ratio.split(":");
    width = Number(dimentions[0]);
    height = Number(dimentions[1]);
  }
  return (height / width) * 100;
};

const aspectRatioPropTypes = {
  ratio: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
  ]).isRequired,
  loading: PropTypes.bool,
};

const defaultAspectRatioProps = {
  loading: false,
};

const AspectRatio = ({ ratio, className, loading, children }) => {
  const newRatio = getAspectRatio({ ratio });
  return (
    <div
      className={classNames(["aspect-ratio", className])}
      style={{ "--ratio": `${newRatio}%` }}
    >
      <div className="aspect-ratio-content">
        {loading ? <Shimmer height="100%" /> : children}
      </div>
    </div>
  );
};

AspectRatio.propTypes = aspectRatioPropTypes;
AspectRatio.defaultProps = defaultAspectRatioProps;
export default AspectRatio;
