import { gql, useMutation } from "src/api/client";

const PROCESS_PAYOUT_IMPORT_MUTATION = gql`
  mutation processPayoutImport(
    $programId: ID
    $timeFrequency: TimeFrequency
    $fileId: Float!
  ) {
    processPayoutImport(
      programId: $programId
      timeFrequency: $timeFrequency
      fileId: $fileId
    ) {
      ... on ProcessPayoutImportResponse {
        message
        success
        response
      }
    }
  }
`;

export const useProcessPayoutImport = () => {
  return useMutation(PROCESS_PAYOUT_IMPORT_MUTATION);
};
