import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgCalendar = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 18"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.333 1.778h.889A1.783 1.783 0 0 1 16 3.556V16a1.783 1.783 0 0 1-1.778 1.778H1.778A1.783 1.783 0 0 1 0 16V3.556a1.783 1.783 0 0 1 1.778-1.778h.889V0h1.777v1.778h7.112V0h1.777v1.778ZM1.778 16h12.444V6.222H1.778V16Zm1.607-8.332h5v5h-5v-5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

SvgCalendar.defaultProps = {
  color: COLORS.GREY_2,
  height: 18,
  width: 16,
};
export default SvgCalendar;
