import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'incentives.web.approvalRequestPage' */ "./web")
);
const MobilePage = lazy(() =>
  import(
    /* webpackChunkName: 'incentives.mobile.approvalRequestPage' */ "./mobile"
  )
);

export const ApprovalRequestPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "approval-request-page",
  },
};
