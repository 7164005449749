import { gql, useQuery } from "src/api/client";

const GET_PAYOUT_PROGRAMS_FOR_USERS_QUERY = gql`
  query payoutProgramsForUser(
    $userId: ID
    $limit: Float
    $query: JSON
    $after: String
    $FeatureType: String!
  ) {
    payoutProgramsForUser(
      userId: $userId
      limit: $limit
      query: $query
      after: $after
      FeatureType: $FeatureType
    ) {
      ... on PayoutProgramsForUserResponse {
        success
        message
        response {
          totalCount
          totalProgramsCount
          pageInfo {
            endCursor
            hasNextPage
          }
          payoutPrograms {
            programId
            programName
            recurrenceId
            cycle
            payoutDone
            payoutPending
            payoutStatus
            PaidOutRewards {
              RewardName
            }
            PendingRewards {
              RewardName
            }
            PendingRewardsCount
            PaidOutRewardsCount
          }
        }
      }
    }
  }
`;

export const useGetPayoutProgramsForUser = (queryOptions) => {
  return useQuery(GET_PAYOUT_PROGRAMS_FOR_USERS_QUERY, queryOptions);
};
