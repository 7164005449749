import React from "react";
import { COLORS } from "src/constants/generalConstants";
const SvgRoundedAdd = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M5.4 9h1.2V6.6H9V5.4H6.6V3H5.4v2.4H3v1.2h2.4zm.6 3c-.83 0-1.61-.158-2.34-.473a6.06 6.06 0 0 1-1.905-1.282A6.06 6.06 0 0 1 .473 8.34 5.843 5.843 0 0 1 0 6c0-.83.158-1.61.473-2.34a6.06 6.06 0 0 1 1.282-1.905A6.06 6.06 0 0 1 3.66.473 5.843 5.843 0 0 1 6 0c.83 0 1.61.158 2.34.473a6.06 6.06 0 0 1 1.905 1.282 6.06 6.06 0 0 1 1.283 1.905C11.842 4.39 12 5.17 12 6c0 .83-.158 1.61-.473 2.34a6.06 6.06 0 0 1-1.282 1.905 6.06 6.06 0 0 1-1.905 1.283C7.61 11.842 6.83 12 6 12m0-1.2c1.34 0 2.475-.465 3.405-1.395C10.335 8.475 10.8 7.34 10.8 6c0-1.34-.465-2.475-1.395-3.405C8.475 1.665 7.34 1.2 6 1.2c-1.34 0-2.475.465-3.405 1.395C1.665 3.525 1.2 4.66 1.2 6c0 1.34.465 2.475 1.395 3.405.93.93 2.065 1.395 3.405 1.395"
      />
    </svg>
  );
};
SvgRoundedAdd.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 12,
  width: 12,
};
export default SvgRoundedAdd;
