import { t } from "i18next";
import { Modal } from "react-bootstrap";
import { FormButton } from "src/components/common/formElements";
import { SvgInfo } from "src/icons";
import SlabItem from "./SlabItem";
import "./addSlabsModal.scss";
import useSlabStructure from "./useSlabStructure";

const AddSlabsModal = ({
  show,
  handleClose,
  title,
  note,
  slabs,
  handleSave,
  minSlabs,
  maxSlabs,
  minSlabValue,
  maxSlabValue,
  weightagePlaceholder,
  minSlabWeightage,
  allowChange,
  showPercentage,
}) => {
  const slabStructure = useSlabStructure(slabs, {
    minSlabs,
    maxSlabs,
    minSlabValue,
    maxSlabValue,
    minSlabWeightage,
    allowChange,
  });
  return (
    <Modal
      dialogClassName="add-slabs-modal-dialog"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className="py-3 px-4" closeButton>
        <Modal.Title className="fs-18 semi-bold">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {slabStructure.slabs.length ? (
          <div className="fs-12 fc-grey mb-4 note py-3 px-2 br-4 flex">
            <SvgInfo className="mt-n2 icon" />
            <div>{note}</div>
          </div>
        ) : null}
        <SlabItem
          slabs={slabStructure.slabs}
          activeSlabIndex={slabStructure.activeSlabIndex}
          slabActions={slabStructure.slabActions}
          weightagePlaceholder={weightagePlaceholder}
          options={slabStructure.options}
          showPercentage={showPercentage}
        />
      </Modal.Body>
      <Modal.Footer>
        {slabStructure.slabs.length ? (
          <div className="fs-12 mr-auto fc-grey slab-count">
            <span className="semi-bold fc-black">
              {slabStructure.slabs.length}
            </span>
            /
            {t("INCENTIVES.PROGRAM.MESSAGES.NUMBER_OF_SLABS_ADDED", {
              totalSlabs: maxSlabs,
            })}
          </div>
        ) : null}
        <FormButton variant="outline-primary" onClick={handleClose}>
          {t("COMMON.BUTTONS.CANCEL")}
        </FormButton>
        <FormButton
          disabled={!slabStructure.slabActions.isSlabStructureFulfilled()}
          onClick={() => {
            handleSave(slabStructure.slabs);
            handleClose();
          }}
        >
          {t("COMMON.BUTTONS.SAVE")}
        </FormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSlabsModal;
