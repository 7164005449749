import PropTypes from "prop-types";
import React from "react";
import { Counter, RewardIcon, Truncate } from "src/components/common/atoms";
import { FormCheck } from "src/components/common/formElements";
import { capitalize, classNames } from "src/helpers";
import "./rewardCard.scss";

const RewardCard = ({
  isChecked,
  handleRewardCheck,
  handleCounterChanges,
  reward,
  className,
  currCount,
  disabled,
}) => {
  const nameFormatter = (reward) => {
    const rewardImageUrl = reward?.CustomRewardImageUrl || reward?.ImageUrl;
    return (
      <div className="reward-name center">
        {(rewardImageUrl || reward?.RewardCategory) && (
          <RewardIcon
            src={rewardImageUrl}
            alt="reward-image"
            rewardCategory={reward.RewardCategory}
            className="mr-12"
          />
        )}
        <div className="d-flex flex-column">
          <div className="fs-13 fc-secondary-black semi-bold">
            <Truncate maxWidth="130px" text={reward.RewardName} />
          </div>
          <div className="fs-12 fc-grey1">
            <Truncate
              maxWidth="130px"
              text={capitalize(reward.RewardCategory)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames([
        "center w-100 p-3 reward-card",
        isChecked ? "bg-blue6" : "",
        className,
      ])}
    >
      <div className="d-flex">
        <FormCheck
          id={reward.RewardId}
          data-testid={`${reward.RewardId}-checkbox`}
          disabled={disabled}
          checked={isChecked}
          onClick={(e) => handleRewardCheck(reward.RewardId)}
        />
      </div>
      <div className="ml-1 w-40">{nameFormatter(reward)}</div>
      <div className="fs-13 semi-bold fc-secondary-black w-30">
        {reward.Count}
      </div>
      <div className="flex-grow-1 flex-end">
        <Counter
          disabled={disabled || !isChecked}
          count={currCount}
          maxCount={reward.Count}
          handleAddButtonClick={() => {
            if (isChecked && currCount < reward.Count) {
              const newCount = currCount + 1;
              handleCounterChanges(newCount);
            }
          }}
          handleMinusButtonClick={() => {
            if (isChecked && currCount > 1) {
              const newCount = currCount - 1;
              handleCounterChanges(newCount);
            }
          }}
        />
      </div>
    </div>
  );
};

RewardCard.propTypes = {
  isChecked: PropTypes.bool,
  handleRewardCheck: PropTypes.func,
  reward: PropTypes.object,
};

RewardCard.defaultProps = {};

export default RewardCard;
