import { t } from "i18next";
import React, { useState } from "react";
import {
  BaseCard,
  FormButton,
  FormTooltip,
  MobileRecurrenceDropdown,
  Shimmer,
  Truncate,
} from "src/components";
import { RulesetDetailView } from "src/components/pages";
import { APPS, COLORS, GOAL_AGGREGATION_TYPES } from "src/constants";
import {
  NumFormatter,
  getColor,
  getPercentage,
  getUserTimezone,
} from "src/helpers";
import { ModalContainer } from "../../modalContainer";
import MobileRulesDropDown from "./MobileRulesDropdown";
import "./mobileAchievementBreakDownHeader.scss";
const UserDropDownShimmer = () => {
  return (
    <div className="user-dropdown bg-white">
      <div className="center fs-12  ml-2 p-2">
        <span className="semi-bold">
          {t("MANAGED_GOALS.LABELS.GOAL_USER")}:
        </span>
        <div className="d-inline-flex align-items-center ml-1">
          <Shimmer width="100px" height="20px" />
        </div>
      </div>
    </div>
  );
};
const MobileAchievementBreakDownHeader = ({
  recurrenceList,
  selectedRecurrence,
  setSelectedRecurrence,
  selectedGoalUser,
  usersAchievementLoading,
  templateLoading,
  templateDetails,
  setSelectedRule,
  goalValue,
  goalAchieved,
  customType,
  selectedRule,
  goalAggConfig,
  moduleName,
  hideUserDropDown,
  formatOptionLabelAfter = () => false,
}) => {
  const isIndianTimezone = getUserTimezone();
  const [showRuleModal, setShowRuleModal] = useState(false);

  const showGoalAchieved = goalAchieved !== null && goalAchieved !== undefined;
  const showGoalValue =
    goalValue !== null &&
    goalValue !== undefined &&
    goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED;

  return (
    <div>
      <MobileRecurrenceDropdown
        id="recurrence-select"
        recurrences={recurrenceList}
        selectedRecurrence={selectedRecurrence}
        handleChange={setSelectedRecurrence}
        customType={customType}
        formatOptionLabelAfter={formatOptionLabelAfter}
        readOnly={
          moduleName === APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.name
        }
      />

      <div className="mt-3 achievement-breakdown-user-achievement">
        {!hideUserDropDown && !usersAchievementLoading ? (
          <div className="px-3 py-2 w-100 center bg-white">
            <span className="fs-12 center semi-bold">
              {t("MANAGED_GOALS.LABELS.GOAL_USER")}:
            </span>
            <div className="fs-14 ml-2 semi-bold fc-blue">
              <Truncate text={selectedGoalUser.FullName} maxWidth="180px" />
            </div>
          </div>
        ) : (
          <UserDropDownShimmer />
        )}

        <div className="px-3 py-2 center">
          <span className="fs-12 semi-bold fc-grey1">
            {t("COMMON.LABELS.ACHIEVEMENT")}:
          </span>
          <span className="ml-2 semi-bold fs-16">
            {usersAchievementLoading ? (
              <Shimmer width="90px" height="20px" />
            ) : (
              <>
                {showGoalAchieved ? (
                  <FormTooltip text={goalAchieved} placement="bottom">
                    <span
                      style={{
                        color:
                          goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED
                            ? getColor(getPercentage(goalAchieved, goalValue))
                            : COLORS.BLACK,
                      }}
                    >
                      {NumFormatter(goalAchieved, isIndianTimezone)}
                    </span>
                  </FormTooltip>
                ) : (
                  <span>--</span>
                )}
                {showGoalValue && (
                  <FormTooltip text={goalValue} placement="bottom">
                    <span className="fc-grey">
                      /{NumFormatter(goalValue, isIndianTimezone)}
                    </span>
                  </FormTooltip>
                )}
              </>
            )}{" "}
          </span>
        </div>
      </div>
      <div className="mt-3">
        <BaseCard className="p-3">
          <div className="center pb-2 border-bottom-1 bc-grey4">
            <span className="fs-12 semi-bold fc-grey1">
              {t("RULEBOOK.LABELS.RULEBOOK")} :
            </span>
            {templateLoading ? (
              <Shimmer width="150px" height="20px" className="ml-2" />
            ) : (
              <>
                <FormButton
                  onClick={() => setShowRuleModal(true)}
                  className="ml-2 fs-14 semi-bold fc-primary-blue"
                  variant="link"
                >
                  <Truncate
                    maxWidth="250px"
                    truncType="middle"
                    text={templateDetails?.Name}
                    tooltipPlacement="bottom"
                    showTooltip
                  />
                </FormButton>
                {showRuleModal && (
                  <ModalContainer
                    title={t("RULEBOOK.LABELS.RULESET_USED")}
                    disableFooter
                    show={showRuleModal}
                    onHide={() => setShowRuleModal(false)}
                  >
                    <RulesetDetailView
                      showRulesetBadge={false}
                      loading={templateLoading}
                      templateDetailsResults={templateDetails}
                    />
                  </ModalContainer>
                )}
              </>
            )}
          </div>
          <MobileRulesDropDown
            {...{
              rules: templateDetails?.GoalRules || [],
              setSelectedRule,
              loading: templateLoading,
              selectedRule,
            }}
          />
        </BaseCard>
      </div>
    </div>
  );
};

export default MobileAchievementBreakDownHeader;
