import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_ALL_LEADERBOARD_USERS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getAllLeaderboardUsers(
    $LeaderboardId: ID!
    $RecurrenceId: ID!
    $GroupId: ID
    $userAttributesNames: [String]!
    $query: getUserDetails
    $limit: Float
    $after: String
  ) {
    getAllLeaderboardUsers(
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
      GroupId: $GroupId
      userAttributesNames: $userAttributesNames
      query: $query
      limit: $limit
      after: $after
    ) {
      ... on AllUsersLeaderboardResponse {
        success
        message
        response {
          Users
          totalCount
          totalLeaderboardUsers
          AllLeaderboardUsers
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useGetAllLeaderboardUsers = (queryOptions) => {
  return useQuery(GET_ALL_LEADERBOARD_USERS_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
