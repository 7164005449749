import { gql, useQuery } from "src/api/client";

const GET_RECURRENCE_TOTALS = gql`
  query recurrenceTotals($recurrenceId: ID!, $programId: ID!, $UserId: ID!) {
    recurrenceTotals(
      recurrenceId: $recurrenceId
      programId: $programId
      UserId: $UserId
    ) {
      ... on RecurrenceTotalsResponse {
        message
        success
        response {
          totalEligibleAmount
          totalApprovedAmount
          totalApprovalRejected
          totalApprovalPending
          totalPaidOut
          totalPayoutPending
          TotalEligibleRewards {
            RewardId
            RewardName
          }
          TotalApprovedRewards {
            RewardId
            RewardName
          }
          TotalApprovalRejectedRewards {
            RewardId
            RewardName
          }
          TotalApprovalPendingRewards {
            RewardId
            RewardName
          }
          TotalPaidOutRewards {
            RewardId
            RewardName
          }
          TotalPayoutPendingRewards {
            RewardId
            RewardName
          }
          TotalEligibleRewardsCount
          TotalApprovedRewardsCount
          TotalApprovalRejectedRewardsCount
          TotalApprovalPendingRewardsCount
          TotalPaidOutRewardsCount
          TotalPayoutPendingRewardsCount
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetRecurrenceTotals = (queryOptions) => {
  return useQuery(GET_RECURRENCE_TOTALS, queryOptions);
};
