import React from "react";
import { FormTooltip } from "src/components/common/formElements/tooltips";
import { classNames, getUserTimezone, NumFormatter } from "src/helpers";

export const MAXIMUM_FRACTION_DIGITS = 2;

export function getAmountMaximumBase(
  maximumFractionDigits = MAXIMUM_FRACTION_DIGITS
) {
  return 10 ** maximumFractionDigits;
}

export function formatAmount(
  amount,
  maximumFractionDigits = MAXIMUM_FRACTION_DIGITS
) {
  if (maximumFractionDigits === 0) {
    return amount;
  }
  const base = getAmountMaximumBase();
  return Math.floor(amount * base) / base;
}

const AmountLabel = ({ className, amount }) => {
  const isIndianTimezone = getUserTimezone();
  const amountType = !isIndianTimezone ? "en-US" : "en-IN";
  const amountSymbol = !isIndianTimezone ? "$" : "₹";

  if (amount === null || amount === undefined) {
    return <span>-</span>;
  }

  const formattedValue = formatAmount(amount).toLocaleString(amountType, {
    roundingPriority: "morePrecision",
  });

  return (
    <span className={classNames(["amount-label cursor-pointer", className])}>
      <FormTooltip text={`${amountSymbol} ${formattedValue}`}>
        <span>
          <span className="currency">{amountSymbol}</span>
          <span>{NumFormatter(formatAmount(amount), isIndianTimezone)}</span>
        </span>
      </FormTooltip>
    </span>
  );
};

export default AmountLabel;
