import PropTypes from "prop-types";
import React from "react";
import Badge from "react-bootstrap/Badge";
import { classNames } from "src/helpers";
import "./formBadge.scss";

const FormBadge = (props) => {
  const { id, variant, children, onClick, className, ...rest } = props;

  return (
    <Badge
      tabIndex={onClick && 0}
      {...rest}
      id={id}
      onClick={onClick || undefined}
      onKeyDown={onClick || undefined}
      pill
      className={classNames([
        "flex-center cursor-pointer regular",
        className || "",
      ])}
    >
      {children}
    </Badge>
  );
};

FormBadge.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default FormBadge;
