import React from "react";
import { APPS } from "src/constants";
import { Redirect, Route, Switch } from "src/router";
import { useRulesetsRbac } from "./hooks";
import { Pages } from "./pages";

export function Routes() {
  const { rulesetsRbac } = useRulesetsRbac();

  return (
    <Switch>
      <Route
        path={APPS.RULESETS.ROUTES.ROOT.path}
        page={Pages.LandingPage}
        isPage
        exact
        {...rulesetsRbac(APPS.RULESETS.ROUTES.ROOT.path)}
      />
      <Route
        path={APPS.RULESETS.ROUTES.RULESET_CRUD.path}
        isPage
        exact
        page={Pages.RulesetCrudPage}
        {...rulesetsRbac(APPS.RULESETS.ROUTES.RULESET_CRUD.path)}
      />
      <Route path="*">
        <Redirect to={APPS.ACE.ROUTES.ROOT.path} />
      </Route>
    </Switch>
  );
}
