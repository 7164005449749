import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgManager = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      data-name="Group 11072"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill="none" d="M0 0h14v14H0z" data-name="Rectangle 2966" />
      <path
        fill={color}
        d="M8.579 14Zm0 0H1.501a1 1 0 0 1-1-1v-1a3.994 3.994 0 0 1 3.283-3.939l.658 1.214a3.517 3.517 0 0 0-2.1 3.28h3.511l.859-2.9a1.013 1.013 0 0 1-.76-.955c0-.553 2.166-.553 2.166 0a1.018 1.018 0 0 1-.807.968l.846 2.887h3.862a3.652 3.652 0 0 0-2.448-3.3l.644-1.192a4 4 0 0 1 3.286 3.934v1a1 1 0 0 1-1 1H8.579ZM3.026 3.863A3.914 3.914 0 0 1 7.001.014a3.914 3.914 0 0 1 3.974 3.849 3.914 3.914 0 0 1-3.974 3.851 3.913 3.913 0 0 1-3.975-3.851Zm1.324 0a2.613 2.613 0 0 0 2.651 2.566 2.612 2.612 0 0 0 2.648-2.566 2.612 2.612 0 0 0-2.648-2.566A2.613 2.613 0 0 0 4.35 3.863Z"
        data-name="Union 79"
      />
    </svg>
  );
};

SvgManager.defaultProps = {
  color: COLORS.GREY_1,
  height: 14,
  width: 14,
};
export default SvgManager;
