import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import Overlay from "react-bootstrap/Overlay";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgGoalTemplateInfo } from "src/icons";
import "./popover.scss";

const Popover = (props) => {
  const {
    id,
    popperId,
    popoverBody,
    width,
    closedStateIcon = null,
    openedStateIcon = null,
    placement,
    popoverClassName,
    rootClose,
    children,
    hideOnClick,
    className,
    disabled,
  } = props;

  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  const openedState = openedStateIcon || (
    <SvgGoalTemplateInfo color={COLORS.PRIMARY_BLUE} />
  );

  const closedState = closedStateIcon || <SvgGoalTemplateInfo />;

  return (
    <>
      <button
        id={id}
        ref={target}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setShowPopover((prev) => !prev);
        }}
        className={classNames([
          "flex-center btn btn-link ml-2 p-0 h-40",
          className,
        ])}
        type="button"
        disabled={disabled}
      >
        {showPopover ? openedState : closedState}
      </button>

      {showPopover && (
        <Overlay
          target={target.current}
          show={showPopover}
          transition={false}
          placement={placement}
          rootClose={rootClose}
          onExited={() => setShowPopover(false)}
          onHide={() => setShowPopover(false)}
          onToggle={() => setShowPopover((prev) => !prev)}
        >
          {(props) => {
            const { ref, style, placement } = props;

            return (
              <div
                id={popperId}
                data-placement={placement}
                style={{
                  ...style,
                  width,
                }}
                className={classNames([
                  "header-popover br-4 m-0 p-0 bg-white",
                  popoverClassName,
                ])}
                ref={ref}
                onClick={hideOnClick ? () => setShowPopover(false) : null}
              >
                {popoverBody || children}
              </div>
            );
          }}
        </Overlay>
      )}
    </>
  );
};

Popover.propTypes = {
  id: PropTypes.string,
  popoverBody: PropTypes.node,
  width: PropTypes.string,
  placement: PropTypes.string,
  rootClose: PropTypes.bool,
};
Popover.defaultProps = {
  id: "popover",
  width: "250px",
  placement: "bottom-start",
  rootClose: true,
  hideOnClick: false,
  disabled: false,
};

export default Popover;
