import React, { useState } from "react";
import { FormButton, FormInput } from "src/components";

const FormulaMenuStatic = ({ tokenType, handleClose, handleClick }) => {
  const [value, setValue] = useState("");

  const handleOnClick = () => {
    handleClick({
      tokenType,
      value,
    });
    handleClose();
  };

  return (
    <div className="p-3">
      <FormInput
        placeholder="Enter Value"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="mt-3 center justify-content-end">
        <FormButton onClick={handleOnClick}>Submit</FormButton>
      </div>
    </div>
  );
};

export default FormulaMenuStatic;
