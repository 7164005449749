import PropTypes from "prop-types";
import React from "react";
import { useSalesActivityStatus } from "src/api/useTemplate";
import { RuleCard } from "src/components/common/molecules";
import { range } from "src/helpers";
import { LoadingRuleCard } from "../../loading";
import "./rulesGrid.scss";

const RulesGrid = (props) => {
  const {
    onShowClick,
    showButtons,
    handleDeleteRule,
    templateDetails,
    onSelectedGoalRule,
    loadingDelete,
    deleteProps,
    editProps,
    alertProps,
    tempFormula,
    isCustomFormula,
  } = props;
  const {
    loading,
    data: { getSalesActivityStatus } = { getSalesActivityStatus: {} },
  } = useSalesActivityStatus();

  if (loading)
    return (
      <div className="rules-grid py-5">
        {range(0, 2).map((i, idx) => (
          <div className="shorter-card">
            <LoadingRuleCard key={idx} />
          </div>
        ))}
      </div>
    );

  if (templateDetails && templateDetails?.GoalRules) {
    return (
      <div className="rules-grid py-5">
        {templateDetails.GoalRules.map((goalRule, idx) => (
          <div key={idx}>
            <RuleCard
              index={idx + 1}
              goalRule={goalRule}
              loadingDelete={loadingDelete}
              onShowClick={onShowClick}
              templateDetails={templateDetails}
              showButtons={templateDetails?.Status === "DRAFT" || showButtons}
              handleDeleteRule={handleDeleteRule}
              onSelectedGoalRule={onSelectedGoalRule}
              getSalesActivityStatus={getSalesActivityStatus}
              deleteProps={deleteProps}
              editProps={editProps}
              alertProps={alertProps}
              tempFormula={tempFormula}
              isCustomFormula={isCustomFormula}
            />
          </div>
        ))}
      </div>
    );
  }
  return <></>;
};

RulesGrid.propTypes = {
  templateDetails: PropTypes.object,
  showButtons: PropTypes.bool,
  handleDeleteRule: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default RulesGrid;
