import {
  FORMULA_OPERATOR_TYPE,
  TOKEN_TYPE,
} from "src/apps/incentives/components/formulaBuilder/formulaParser/formulaNamespace";
import {
  endOfDay,
  getSystemOffset,
  getTodayAsPerTimezone,
  startOfDay,
} from "src/helpers";

export const getFormSubmitButton = (form) => {
  return Array.from(form).find((elm) => elm?.type === "submit");
};

export const isDistinctCountTemplate = (selectedTemplates) => {
  const isDistinct = selectedTemplates.find(
    (template, idx) => template.GoalAggregateFunction === "DISTINCT_COUNT"
  );
  return !!isDistinct;
};

export const getPublishedProgramStatus = ({
  startDate,
  endDate,
  status,
  inGaps,
}) => {
  const today = getTodayAsPerTimezone() || new Date();
  if (
    startDate &&
    endDate &&
    today >= startOfDay(Date.parse(startDate)) &&
    today <= endOfDay(Date.parse(endDate))
  ) {
    if (inGaps) return "INGAPS";
    return "INPROGRESS";
  }
  if (startDate && endDate && today && today > endOfDay(Date.parse(endDate))) {
    return "COMPLETED";
  }
  if (startDate && endDate && today < startOfDay(Date.parse(startDate))) {
    return "YETTOSTART";
  }
  return status;
};

export const getTimeFrequency = (timeFrequency) => {
  if (!timeFrequency.startsAt || !timeFrequency.endsAt) {
    return null;
  }
  return {
    timeFrequency: {
      startsAt: timeFrequency.startsAt,
      endsAt: timeFrequency.endsAt,
    },
  };
};

export const getRecurrenceStatus = (recurrence) => {
  const today = getTodayAsPerTimezone();

  let status = "";
  if (
    recurrence &&
    today >= startOfDay(recurrence.RecurrenceStartDate - getSystemOffset()) &&
    today <= endOfDay(recurrence.RecurrenceEndDate - getSystemOffset())
  ) {
    status = "ongoing";
  } else if (
    recurrence &&
    today < startOfDay(recurrence.RecurrenceStartDate - getSystemOffset())
  ) {
    status = "yetToStart";
  } else {
    status = "completed";
  }
  return status;
};

export const formatSelectedGoals = (selectedGoals, selectedTemplates) => {
  const templateMap = new Map();
  const groupedSelectedGoals = {};

  selectedTemplates.forEach((selectedTemplate) => {
    const templateId = selectedTemplate.TemplateId;
    templateMap.set(templateId, {
      Category: selectedTemplate.Name,
      Name: selectedTemplate.Name,
      value: templateId,
      TemplateId: templateId,
      optionType: "Template",
    });

    groupedSelectedGoals[templateId] = [];
  });

  selectedGoals.forEach((selectedGoal) => {
    const templateId = selectedGoal.TemplateId;
    if (groupedSelectedGoals[templateId]) {
      groupedSelectedGoals[templateId].push({
        Category: templateMap.get(templateId).Name,
        Name: selectedGoal.GoalName,
        value: selectedGoal.GoalId,
        TemplateId: templateId,
        optionType: "Goal",
      });
    }
  });

  const result = Object.keys(groupedSelectedGoals).map((templateId) => {
    const selectedGoalTemplate = templateMap.get(templateId);
    const optionArray = groupedSelectedGoals[templateId];

    return {
      Category: selectedGoalTemplate?.Name,
      Name: selectedGoalTemplate?.Name,
      value: templateId,
      options: [selectedGoalTemplate, ...optionArray],
    };
  });

  return result;
};

export const filterOption = (option, inputValue) =>
  option?.data?.Name?.toLowerCase().includes(inputValue.toLowerCase());

export const findOptionFromSelectedGoals = ({
  goals,
  templates,
  templateId,
  goalId,
}) => {
  const selectedTemplateId = templateId;
  const selectedGoalId = goalId;

  if (!selectedTemplateId) return null;

  const formattedSelectedGoals = formatSelectedGoals(goals, templates);

  for (const option of formattedSelectedGoals) {
    if (option.value === selectedTemplateId) {
      const goalOption = option.options.find(
        ({ value }) => value === selectedGoalId
      );
      if (goalOption) return goalOption;

      const templateOption = option.options.find(
        ({ value }) => value === selectedTemplateId
      );
      if (templateOption) return templateOption;
    }
  }

  return null;
};
const checkIfValueIsParenthesis = (value) => {
  return [
    FORMULA_OPERATOR_TYPE.OPEN_PAREN,
    FORMULA_OPERATOR_TYPE.CLOSE_PAREN,
  ].includes(value);
};
const formulaStructure = (Formula) => {
  return {
    IsFormula: true,
    Formula: Formula?.map((formula) => ({
      Operand: checkIfValueIsParenthesis(formula?.Value)
        ? "PARENTHESIS"
        : formula?.Operand,
      Value: formula?.Value,
      FieldName: formula?.FieldName,
      Cycle: formula?.Cycle,
      Type: formula?.Type,
      GoalAchievementType: formula?.GoalAchievementType,
      SubGoal: formula?.SubGoal,
      ReporteesIncentiveValue: Number(formula?.ReporteesIncentiveValue),
    })),
  };
};

export const modifiedCriteriaList = (criteriaList) => {
  const updatedCriteriaList = criteriaList?.map(
    ({ Conditions, Rewards, ...criteria }) => {
      const conditions = Conditions?.map(
        ({
          GoalName,
          TemplateName,
          IsFormula,
          Formula,
          ConditionType,
          SubGoal,
          ...rest
        }) => {
          const newCondition = IsFormula
            ? {
                ...formulaStructure(Formula),
                ...(rest?.Name && { Name: rest?.Name }),
              }
            : {
                ...rest,
                ...(groupByConditionValid(SubGoal) && {
                  SubGoal: {
                    ConditionPattern: SubGoal?.ConditionPattern,
                    SubGoalConditions: SubGoal?.SubGoalConditions,
                  },
                }),
              };
          return newCondition;
        }
      );

      const rewards = Rewards?.map(
        ({ ConditionType, IsFormula, Formula, SubGoal, ...rest }) => {
          return IsFormula
            ? formulaStructure(Formula)
            : {
                ...rest,
                ...(groupByConditionValid(SubGoal) && {
                  SubGoal: {
                    ConditionPattern: SubGoal?.ConditionPattern,
                    SubGoalConditions: SubGoal?.SubGoalConditions,
                  },
                }),
              };
        }
      );

      return {
        Conditions: conditions,
        Rewards: rewards,
        ...criteria,
      };
    }
  );
  return updatedCriteriaList;
};

export const groupByConditionValid = (groupByCondition) =>
  groupByCondition?.SubGoalConditions?.filter(
    (condition) =>
      condition?.Key === "" ||
      condition?.Value?.trim() === "" ||
      isNaN(condition?.ConditionId)
  )?.length === 0 &&
  (groupByCondition?.SubGoalConditions?.length > 1
    ? groupByCondition?.ConditionPattern !== ""
    : true);

export const getMetricsFromCondition = (conditions) => {
  return conditions.filter((condition) =>
    [TOKEN_TYPE.METRIC, TOKEN_TYPE.FIELD].includes(condition.tokenType)
  );
};

export const getTemplateId = (condition) => {
  if (condition?.IsFormula) {
    return condition?.Formula?.find(
      ({ Operand, FieldName }) =>
        Operand === TOKEN_TYPE.METRIC && FieldName === "TemplateId"
    )?.Value;
  }
  return condition?.TemplateId;
};
