import React from "react";

const SvgYetToStartLg = (props) => {
  const { height, width, ...rest } = props;
  return (
    <svg
      width={height}
      height={width}
      fill="none"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx={18} cy={18} r={18} fill="#F9B16F" opacity={0.7} />
      <circle cx={17.997} cy={18.091} r={13.091} fill="#E28D0C" />
      <path
        fill="#fff"
        d="M11.37 18.79a6.737 6.737 0 1 0 13.472 0 6.737 6.737 0 0 0-13.473 0Zm6.736-5.053a5.053 5.053 0 1 1 0 10.105 5.053 5.053 0 0 1 0-10.105ZM19.79 8.684h-3.368v1.684h3.368V8.684ZM12.876 10.192l-2.524 2.525 1.19 1.19 2.525-2.524-1.19-1.19Z"
      />
      <path fill="#fff" d="M18.948 15.42h-1.684v4.21h1.684v-4.21Z" />
    </svg>
  );
};
SvgYetToStartLg.defaultProps = {
  height: 36,
  width: 36,
};
export default SvgYetToStartLg;
