import { useState } from "react";

export const useUploadFileToS3 = () => {
  const [controller, setController] = useState(() => new AbortController());

  const cancelUpload = () => {
    controller.abort();
    setController(new AbortController());
  };

  const uploadFileToS3 = ({ presignedUrl, file }) => {
    return new Promise((resolve, reject) => {
      if (!controller.signal.aborted) {
        const headers = new Headers();
        headers.append("Accept", "*/*");
        headers.append("Content-Type", file.type);
        return resolve(
          fetch(presignedUrl, {
            method: "PUT",
            headers,
            body: file,
            signal: controller.signal,
          })
        );
      }
      reject();
    });
  };

  return {
    uploadFileToS3,
    cancelUpload,
  };
};
