import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteIncentive } from "src/api/useIncentive";
import { getPublishedProgramStatus } from "src/apps/incentives/utils";
import { ConfirmationModal, FormButton, toaster } from "src/components";
import { APPS } from "src/constants";
import { errorText } from "src/helpers";
import { SvgDelete } from "src/icons";
import { useHistory } from "src/router";

const DeleteIncentiveButton = (props) => {
  const { programId, programName, startDate, endDate, status } = props;
  const newStatus = getPublishedProgramStatus({ startDate, endDate, status });
  const history = useHistory();
  const { t } = useTranslation();
  const [toggleModal, setToggleModal] = useState(false);
  const [deleteProgram, { loading: loadingDelete, error: deleteProgramError }] =
    useDeleteIncentive();

  if (deleteProgramError) {
    toaster({ message: errorText.DELETE_PROGRAM.ERROR, type: "error" });
    return <></>;
  }

  const onDeleteClick = () => {
    deleteProgram({
      variables: {
        ProgramId: programId,
      },
    }).then(
      (result) => {
        history.push(APPS.INCENTIVES.ROUTES.MANAGE_PROGRAM.path);
      },
      (error) => {
        console.error("Create error: ", error);
      }
    );
  };
  const ConfirmationModalContent = (
    <div>
      <div className="fs-13 mb-24">
        {t("INCENTIVES.COMMON.MESSAGES.DELETE_PROGRAM_DESCRIPTION")}
        <span className="semi-bold ml-1 long-text-break">{programName} ?</span>
      </div>
    </div>
  );

  if (["COMPLETED", "INPROGRESS"].includes(newStatus) && status !== "DRAFT") {
    return null;
  }

  return (
    <div>
      <FormButton
        id="delete-goal"
        variant="outline-primary"
        icon={<SvgDelete />}
        onClick={() => setToggleModal(!toggleModal)}
      />
      <ConfirmationModal
        show={toggleModal}
        shimmerText={t("COMMON.BUTTONS.DELETING")}
        onClose={() => setToggleModal(!toggleModal)}
        title={t("INCENTIVES.COMMON.LABELS.DELETE_INCENTIVE_PROGRAM")}
        body={ConfirmationModalContent}
        onConfirm={onDeleteClick}
        confirmButtonText={t("INCENTIVES.COMMON.BUTTONS.YES_DELETE_PROGRAM")}
        cancelButton
        loading={loadingDelete}
      />
    </div>
  );
};

export default DeleteIncentiveButton;
