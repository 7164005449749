import { useState, useEffect, useLayoutEffect } from "react";

export const TRANSITIONS = { ENTER: "enter", EXIT: "exit" };

let timeoutId;

export const useTransition = ({ in: inn, timeout }) => {
  const [transition, setTransition] = useState(null);

  const handleEnter = (element) => {
    element.classList.remove("exit");
    element.classList.add("enter");
  };

  const handleExit = (element) => {
    element.classList.remove("enter");
    element.classList.add("exit");
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setTransition(null);
    }, timeout);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useLayoutEffect(() => {
    let newTransition;
    if (inn && !transition) {
      newTransition = TRANSITIONS.ENTER;
    } else if (!inn && transition === TRANSITIONS.ENTER) {
      newTransition = TRANSITIONS.EXIT;
    }
    newTransition && setTransition(newTransition);
  }, [inn, transition]);

  return { state: transition, handleEnter, handleExit };
};
