import { useEffect, useState } from "react";
import {
  formulaEvaluator as evaluator,
  formulaTokenizer as tokenizer,
  formulaValidator as validator,
} from "./formulaParser";
import { formulaToPayload, payloadToFormula, tokenToString } from "./utils";

const useFormulaBuilder = ({
  initialFormula = [],
  onSave,
  onEdit,
  disabled,
  name,
}) => {
  const [formula, changeFormula] = useState(
    initialFormula.map(payloadToFormula)
  );
  const [error, setError] = useState();
  const [tokens, setTokens] = useState([]);
  const [formulaName, setFormulaName] = useState(name);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    if (initialFormula?.length === 0) {
      changeFormula([]);
      setTokens([]);
      setError(null);
      return;
    }
    const newTokens = [];
    const newInitialFormula = initialFormula.map(payloadToFormula);

    changeFormula(newInitialFormula);
    newInitialFormula.forEach((value, index) => {
      const token = tokenizer.getToken({
        value,
        index,
      });
      newTokens.push(token);
    });
    if (newTokens.length) {
      const expressionError = validator.validateExpression(newTokens);
      setError(expressionError || null);
    }
    setTokens(newTokens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormula.join("")]);

  const pushFormula = (value) => {
    const token = tokenizer.getToken({
      value,
      index: tokens.length,
    });

    const tokenError = validator.validateToken({
      prevTokens: tokens,
      lastToken: tokens[tokens.length - 1],
      nextToken: token,
    });

    if (tokenError) {
      setError(tokenError);
      return false;
    }

    setTokens((prevTokens) => [...prevTokens, token]);
    changeFormula((prevFormula) => [...prevFormula, token]);
    setError(null);
    return true;
  };

  const popFormula = () => {
    const newFormula = [...formula];
    const newTokens = [...tokens];
    const removedItem = newFormula.pop();
    newTokens.pop();
    changeFormula(newFormula);
    setTokens(newTokens);
    return tokenToString(removedItem);
  };

  const handleSave = () => {
    const expressionError = validator.validateExpression(tokens);

    if (expressionError) {
      setError(expressionError);
      return false;
    }

    const tempTokens = structuredClone(tokens);

    const evaluationError = evaluator.evaluatePostfix({
      tokens: evaluator.toPostfix(tempTokens),
    });

    if (evaluationError) {
      setError(evaluationError);
      return false;
    }
    setError(null);
    onSave(
      {
        IsFormula: true,
        Name: formulaName,
        Formula: formula.map(formulaToPayload),
      },
      setError
    );
  };

  const handleEdit = () => {
    onEdit();
  };

  return {
    error,
    setError,
    formula,
    disabled,
    formulaName,
    warning,
    setWarning,
    actions: {
      pushFormula,
      popFormula,
      handleSave,
      handleEdit,
      setFormulaName,
    },
  };
};

export default useFormulaBuilder;
