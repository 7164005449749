import React from "react";
import { Truncate } from "src/components";

const CardHeader = (props) => {
  const {
    icon,
    amount,
    description,
    title,
    cycleCount,
    recurrenceTimeLeft,
    status,
    linkedProgramCount,
  } = props;
  return (
    <div className="">
      <div className=" p-0 side-banner-card__right">
        <div className="pt-2 pl-3 pr-0 bc-grey8 side-banner-card__right--header flex-between">
          {" "}
          <div className="fs-16 fc-blue  semi-bold ">
            <Truncate
              id="program-name"
              maxWidth="200px"
              text={title}
              truncType="end"
            />
          </div>
          {recurrenceTimeLeft && (
            <div className="ribbon">
              <span className="p-2 fs-13 semi-bold fc-primary-blue">
                {recurrenceTimeLeft}
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center pl-3 fc-grey fs-12 br-4 ">
          {cycleCount} | {status} {linkedProgramCount}
        </div>
      </div>

      <div className="d-flex align-items-center pl-3 pt-2 w-100">
        <div className="py-2">{icon}</div>
        <div className=" ml-2">
          <div className="fs-14">{description}</div>
          <div className="fs-28 bold">{amount}</div>
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
