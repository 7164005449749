import React from "react";

const SvgAvatar46 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="yk47__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .yk47__cls-1{fill:#fff}.yk47__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="yk47__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="yk47__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="yk47__cls-2"
        />
        <path
          d="M105.41 43.48s0 .01-.01.02c-.04.06-.09.12-.13.18-.03.05 0 .02.14-.19Z"
          className="yk47__cls-1"
        />
        <path
          d="M126.34 79.82c-1.23-.22-2.47-.45-3.7-.67.7-11.43-2.66-22.97-9.53-32.16a50.334 50.334 0 0 0-5.56-6.29c.7-.91 1.41-1.82 2.12-2.73.23-.29.3-.62.28-.92 0-.38-.16-.76-.53-1.03-14.09-10.55-33.48-12.1-49.07-3.96-13.59 7.1-22.89 20.84-24.53 36.06-1.09 10.12 1.27 20.43 6.58 29.1-2.82 1.24-4.87 3.92-5.06 7.22-.29 4.83 3.73 8.75 8.45 8.81 3.76.05 7.52 0 11.28 0H117.84c5.84-.22 9.89-6.25 7.53-11.71-.87-2.01-2.41-3.43-4.26-4.24 3-4.89 5.09-10.29 6.12-15.94.02-.12.03-.23.02-.33.06-.54-.19-1.07-.89-1.2Zm-6.51-10.07c.41 2.96.51 5.97.35 8.96-7.23-1.3-14.46-2.61-21.69-3.91-1.3-.23-2.6-.47-3.9-.7.23-2.64-.34-5.34-1.76-7.69a12.75 12.75 0 0 0-2.4-2.9c4.95-6.6 9.98-13.15 14.83-19.83.02-.03.08-.11.13-.18.02-.02.03-.04.05-.06-.01.02-.02.03-.03.05.02-.03.04-.05.05-.07.15-.2.3-.39.46-.59l.1-.13c7.44 7.22 12.39 16.76 13.81 27.07Zm-29.97-3.28c2.75 3.36 3.06 8.23.83 11.94-2.21 3.68-6.64 5.7-10.88 4.86-4.9-.97-8.41-5.26-8.47-10.24.05-4.31 2.73-8.34 6.85-9.8 4.16-1.47 8.86-.19 11.66 3.23Zm33.4 40.28c-.79 2.42-3.09 3.97-5.59 4.02H48.21c-.84 0-1.68.01-2.52 0-2.5-.04-4.8-1.61-5.59-4.02-.79-2.4.04-5.11 2.09-6.62 1.51-1.11 3.17-1.14 4.92-1.14h70.38c4.03.02 7.05 3.84 5.77 7.76Zm-6.09-10.25H57.68c-3.94 0-7.89-.02-11.83 0-.32 0-.64.02-.95.06-8.84-13.98-9.11-32.14-.48-46.29 7.55-12.37 21.1-20.37 35.59-20.92 9.66-.37 19.13 2.49 27.02 8.02-5.54 7.17-10.94 14.44-16.37 21.7l-2.22 2.97a13.112 13.112 0 0 0-9.16-1.66c-6.04 1.12-10.36 6.6-10.43 12.67.07 5.39 3.38 10.18 8.42 12.1s11.03.29 14.43-3.88c1.15-1.41 1.97-3.02 2.44-4.72l24.44 4.41c1.99.36 3.97.72 5.96 1.07a44.446 44.446 0 0 1-5.96 14.58c-.46-.06-.94-.1-1.42-.1Z"
          className="yk47__cls-1"
        />
        <path
          d="M80.72 67.13c-2.82.47-4.77 2.92-4.95 5.69 0 .07-.01.14 0 .21 0 .09 0 .18.02.26.16 2.38 1.59 4.48 3.87 5.35 2.36.9 5.11.17 6.72-1.77 3.51-4.24-.26-10.64-5.65-9.74Zm4.56 5.9c0 1.87-1.51 3.43-3.37 3.51-2.02.09-3.6-1.54-3.66-3.51.06-1.92 1.55-3.51 3.51-3.51 1.92 0 3.51 1.6 3.51 3.51Z"
          className="yk47__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar46.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar46;
