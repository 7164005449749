import React from "react";
import { Accordion } from "react-bootstrap";
import { Truncate } from "src/components";
import { FormButton, FormCheck } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgArrow } from "src/icons";
import "./filterAccordionItem.scss";

const FilterAccodionItem = ({
  active,
  eventKey,
  filter,
  handleClick,
  handleOnChange,
  option,
  children,
}) => {
  return (
    <div className="border-b">
      <Accordion.Toggle
        eventKey={String(eventKey)}
        className={classNames(["w-100 h-100 bg-white br-8 border-0 p-0"])}
        variant="link"
        onClick={() => handleClick(eventKey)}
      >
        <div
          className={classNames([
            "p-4 center transition-all",
            !active ? "bg-secondary-blue" : "bg-white",
          ])}
        >
          <div className="fs-14 semi-bold">{option.label}</div>
          <div className="fs-14 ml-2 fc-grey1">: {filter.label}</div>
          <div className="ml-auto">
            <SvgArrow
              className="transition-all"
              color={COLORS.GREY_2}
              rotate={active ? 180 : 0}
            />
          </div>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={String(eventKey)}>
        <div className="px-4 pt-0 pb-4 w-100">
          {option?.options.map((subOption, index) => {
            return (
              <FormButton
                variant="link"
                onClick={() => {
                  handleOnChange({
                    key: [option.key],
                    value: subOption,
                  });
                }}
                className="filter-accordion-item"
              >
                <div
                  className={classNames([
                    "fs-14",
                    filter.value === subOption.value ? "fc-blue semi-bold" : "",
                  ])}
                >
                  <Truncate text={subOption.label} maxWidth="280px" />
                </div>
                <div className="flex-end">
                  <FormCheck
                    id={`${option.label}-${subOption.label}-${index}`}
                    type="radio"
                    disabled={subOption.disabled}
                    checked={filter.value === subOption.value}
                    onClick={() => {
                      handleOnChange({
                        key: [option.key],
                        value: subOption,
                      });
                    }}
                  />
                </div>
              </FormButton>
            );
          })}
          {children}
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default FilterAccodionItem;
