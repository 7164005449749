import { gql, useQuery } from "src/api/client";

const GET_INCENTIVE_DASHBOARD_TOTAL = gql`
  query incentiveDashboardTotal(
    $userId: ID
    $query: getDetailsQuery
    $FeatureType: String!
  ) {
    incentiveDashboardTotal(
      userId: $userId
      query: $query
      FeatureType: $FeatureType
    ) {
      ... on DetailsResponse {
        success
        message
        response {
          TotalEligibleAmount
          TotalApprovedAmount
          TotalPaidOut
          TotalPayoutPending
          TotalEligibleRewards {
            RewardCategory
            RewardCount
          }
          TotalApprovedRewards {
            RewardCategory
            RewardCount
          }
          TotalPayoutPendingRewards {
            RewardCategory
            RewardCount
          }
          TotalPaidOutRewards {
            RewardCategory
            RewardCount
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetIncentiveDashboardTotal = (queryOptions) => {
  return useQuery(GET_INCENTIVE_DASHBOARD_TOTAL, queryOptions);
};
