import { classNames } from "src/helpers";
import "./badgeCell.scss";

const BadgeCell = ({ value, column, row: { original } }) => {
  const newValue = column.formatValue ? column.formatValue(original) : value;
  const { className } = column;
  if (newValue) {
    return (
      <div
        className={classNames([
          "badge-cell fs-12 semi-bold",
          className(original),
        ])}
      >
        {newValue}
      </div>
    );
  }
  return null;
};

export default BadgeCell;
