import { useState } from "react";
import { Waypoint } from "react-waypoint";

const LazyLoad = ({
  children,
  container,
  onEnter,
  onLeave,
  topOffset,
  bottomOffset,
  horizontal,
  placeholder,
  fireOnRapidScroll = true,
}) => {
  const [entered, setEntered] = useState();

  const handleOnEnter = () => {
    setEntered(true);
    onEnter && onEnter();
  };

  const handleOnLeave = () => {
    setEntered(false);
    onLeave && onLeave();
  };

  return (
    <Waypoint
      scrollableAncestor={container}
      topOffset={topOffset}
      bottomOffset={bottomOffset}
      onEnter={handleOnEnter}
      onLeave={handleOnLeave}
      horizontal={horizontal}
      fireOnRapidScroll={fireOnRapidScroll}
    >
      {entered ? children : <div>{placeholder}</div> || null}
    </Waypoint>
  );
};

export default LazyLoad;
