import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgAverage = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 28 23"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={2}
        d="M3 8.948S3.332.626 9.123 1.013c5.791.387 4.288 21.505 9.723 20.977 5.435-.528 5.836-13.595 5.836-13.595"
      />
      <path
        stroke={color}
        strokeDasharray="3 3"
        strokeLinecap="round"
        d="M.5 13.072h27"
      />
    </svg>
  );
};
SvgAverage.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 23,
  width: 28,
};
export default SvgAverage;
