import { gql, useMutation } from "src/api/client";

// src/modules/goalCrud/distributeGoal/importModal/DownloadModal.js
// src/modules/goalCrud/distributeGoal/importModal/ImportModal.js
const GOAL_SAMPLE_FILE_MUTATION = gql`
  mutation downloadRawExcel($GoalId: ID!, $isAssignedToMe: Boolean!) {
    downloadRawExcel(GoalId: $GoalId, isAssignedToMe: $isAssignedToMe) {
      message
      urlData {
        url
        fileId
      }
    }
  }
`;

export const useGoalSampleFile = () => {
  return useMutation(GOAL_SAMPLE_FILE_MUTATION);
};
