import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgDragAndDrop = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 18"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l633__e">
          <path d="M0 0h11v18H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l633__e)" data-name="Drag and drop icon">
        <g fill={color} data-name="Group 7427">
          <g data-name="Group 2279" transform="translate(.001)">
            <circle
              cx={2}
              cy={2}
              r={2}
              data-name="Ellipse 1"
              transform="translate(-.001)"
            />
            <circle
              cx={2}
              cy={2}
              r={2}
              data-name="Ellipse 23"
              transform="translate(-.001 7)"
            />
            <circle
              cx={2}
              cy={2}
              r={2}
              data-name="Ellipse 24"
              transform="translate(-.001 14)"
            />
          </g>
          <g data-name="Group 4424" transform="translate(7.001)">
            <circle
              cx={2}
              cy={2}
              r={2}
              data-name="Ellipse 1"
              transform="translate(-.001)"
            />
            <circle
              cx={2}
              cy={2}
              r={2}
              data-name="Ellipse 23"
              transform="translate(-.001 7)"
            />
            <circle
              cx={2}
              cy={2}
              r={2}
              data-name="Ellipse 24"
              transform="translate(-.001 14)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgDragAndDrop.defaultProps = {
  color: COLORS.GREY_3,
  height: 18,
  width: 11,
};
export default SvgDragAndDrop;
