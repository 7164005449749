import { MobileRulesetLandingPage as MobilePage } from "src/components";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

export const LandingPage = {
  web: () => <Redirect to={APPS.ACE.ROUTES.ROOT.path} />,
  mobile: MobilePage,
  meta: {
    title: "Ruleset",
  },
};
