import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgReward = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22.2 21.2"
      role="img"
      {...rest}
    >
      <g
        id="Icons_Set-26"
        data-name="Icons Set-26"
        transform="translate(0.245 0.224)"
      >
        <rect
          id="Rectangle_4541"
          data-name="Rectangle 4541"
          width={21}
          height={4.403}
          transform="translate(0.354 3.982)"
          fill="none"
          stroke={color}
          strokeWidth={1.2}
        />
        <path
          id="Path_9563"
          data-name="Path 9563"
          d="M12.025,3.98A29.949,29.949,0,0,1,15.081.752,1.573,1.573,0,0,1,16.146.38a1.893,1.893,0,0,1,1.512,2.166,2.663,2.663,0,0,1-.968,1.433"
          transform="translate(-0.675 0)"
          fill="none"
          stroke={color}
          strokeWidth={1.2}
        />
        <path
          id="Path_9564"
          data-name="Path 9564"
          d="M10.675,3.98A29.949,29.949,0,0,0,7.619.752,1.573,1.573,0,0,0,6.554.38,1.893,1.893,0,0,0,5.042,2.547,2.663,2.663,0,0,0,6.01,3.98"
          transform="translate(-0.269 0)"
          fill="none"
          stroke={color}
          strokeWidth={1.2}
        />
        <line
          id="Line_1050"
          data-name="Line 1050"
          y1={16.499}
          transform="translate(10.877 3.878)"
          fill="none"
          stroke={color}
          strokeWidth={1.2}
        />
        <rect
          id="Rectangle_4542"
          data-name="Rectangle 4542"
          width={17.62}
          height={11.982}
          transform="translate(2.039 8.395)"
          fill="none"
          stroke={color}
          strokeWidth={1.2}
        />
      </g>
    </svg>
  );
};

SvgReward.defaultProps = {
  width: 22.2,
  height: 21.2,
  color: COLORS.PRIMARY_BLUE,
};

export default SvgReward;
