import React from "react";
import { FormButton } from "src/components";
import { SvgClose } from "src/icons";
import "./tagItem.scss";

function TagItem({ index, item, removeTag, allowRemove = false }) {
  const handleRemoveTag = () => {
    removeTag(index);
  };
  return (
    <div className="tag-item center">
      <span className="fs-14">{item}</span>
      {allowRemove ? (
        <FormButton
          className="cursor-pointer ml-2 my-auto"
          onClick={handleRemoveTag}
        >
          <SvgClose width="10px" height="10px" />
        </FormButton>
      ) : null}
    </div>
  );
}

export default TagItem;
