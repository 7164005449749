import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'leaderboards.web.landingPage' */ "./web")
);

const MobilePage = lazy(() =>
  import(/* webpackChunkName: 'leaderboards.mobile.landingPage' */ "./mobile")
);

export const LandingPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "Leaderboards Landing",
  },
};
