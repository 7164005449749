import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { NetworkStatus } from "src/api/client";
import { useGetMyShowcaseListDetails } from "src/api/useLeaderboardEndUserFlow";
import {
  FormTooltip,
  LeaderboardBadge,
  LoadMoreButton,
  LoadingTable,
  MobileFilterBarFilters,
  MobileFilterBarIcons,
  ModalContainer,
  Truncate,
  getAmountMaximumBase,
  useFilter,
} from "src/components";
import { COLORS, DATE_FORMAT, PAGINATION_LIMIT } from "src/constants";
import {
  NumFormatter,
  format,
  getColor,
  getCustomCycleIntervalString,
  getCycleIntervalString,
  getPercentage,
  getSystemOffset,
  getUserTimezone,
} from "src/helpers";
import { SvgUser } from "src/icons";
import { NoResults } from "src/images";
import { MY_SHOWCASE_FILTER } from "../../../apps/leaderboards/components/modals/data";
const formatRecurrenceValue = (
  RecurrenceStartDate,
  RecurrenceEndDate,
  recurrencePeriod
) => {
  const period = recurrencePeriod && recurrencePeriod.toLowerCase();
  let value = "";
  if (period === "infinite") {
    value = `${format(
      new Date(RecurrenceStartDate - getSystemOffset()),
      DATE_FORMAT
    )} - Forever`;
  } else if (period === "custom") {
    value = `${getCustomCycleIntervalString(
      RecurrenceStartDate,
      RecurrenceEndDate
    )} (Custom Cycle)`;
  } else {
    value = getCycleIntervalString(
      RecurrenceStartDate,
      RecurrenceEndDate,
      period
    );
  }
  return value;
};
const CollectibleFormatter = ({ position, badgeType }) => {
  return (
    <LeaderboardBadge
      position={position}
      recurrencePeriod={badgeType}
      className="h-40"
    />
  );
};

const RecurrenceFormatter = (props) => {
  const {
    RecurrenceStartDate,
    RecurrenceEndDate,
    RecurrenceType,
    RecurrenceCount,
  } = props;
  return (
    <div className="px-0 center recurrence-list__item-wrapper fs-12 fc-grey">
      <div className="recurrence-list__item-index mr-2 semi-bold">
        #{RecurrenceCount}:
      </div>
      <div className="text-nowrap">
        {formatRecurrenceValue(
          RecurrenceStartDate,
          RecurrenceEndDate,
          RecurrenceType
        )}
      </div>
    </div>
  );
};
const AchievementFormatter = ({
  achievements,
  achievement,
  goalDistribution,
}) => {
  const isIndianTimezone = getUserTimezone();
  const Achievements = achievements ?? getAmountMaximumBase(achievement);
  const GoalDistribution =
    goalDistribution ?? getAmountMaximumBase(goalDistribution);

  const getTooltipText = (value) => {
    return value.toLocaleString(!isIndianTimezone ? "en-US" : "en-IN", {
      roundingPriority: "morePrecision",
    });
  };
  return (
    <div className="fs-12 semi-bold d-flex flex-between">
      <span className="fc-grey font-weight-light">
        {t("LEADERBOARD.COMMON.LABELS.ACHIEVEMENT_PER_MIN_TARGET")}
      </span>
      <div>
        <span
          style={{
            color: getColor(getPercentage(achievement, goalDistribution)),
          }}
        >
          {Achievements ? (
            <FormTooltip text={getTooltipText(Achievements)}>
              {NumFormatter(Achievements, isIndianTimezone)}
            </FormTooltip>
          ) : (
            "- -"
          )}{" "}
          /
        </span>{" "}
        {GoalDistribution ? (
          <FormTooltip text={getTooltipText(GoalDistribution)}>
            {NumFormatter(GoalDistribution, isIndianTimezone)}
          </FormTooltip>
        ) : (
          "No Target"
        )}
      </div>
    </div>
  );
};
const MobileMyShowcaseModal = ({
  userId,
  show,
  handleClose,
  title,
  isDashboardUserView,
  userName,
  leaderboardCount,
  emailAddress,
}) => {
  const { t } = useTranslation();
  const ShimmerRef = useRef();
  const filters = MY_SHOWCASE_FILTER({ t });
  const { filtersState, ...rest } = useFilter(filters);
  const {
    data,
    loading,
    // refetch,
    networkStatus,
    fetchMore,
  } = useGetMyShowcaseListDetails({
    variables: {
      limit: PAGINATION_LIMIT,
      after: null,
      Rank: filtersState?.rank?.value,
      CycleType:
        filtersState?.cycleType?.value === "ALL"
          ? undefined
          : filtersState?.cycleType?.value,
      UserId: userId,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const getMyShowcaseListDetails = data?.getMyShowcaseListDetails;
  const myShowcaseList = getMyShowcaseListDetails?.response?.myShowcaseList;
  const totalCount = getMyShowcaseListDetails?.response?.totalCount;

  const onFetchMore = () => {
    const { endCursor } = getMyShowcaseListDetails?.response?.pageInfo;
    fetchMore({
      variables: {
        limit: PAGINATION_LIMIT,
        after: endCursor,
        Rank: filtersState?.rank?.value,
        Cycle: filtersState?.cycleType?.value,
        UserId: userId,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        fetchMoreResult.getMyShowcaseListDetails.response.myShowcaseList = [
          ...prev.getMyShowcaseListDetails.response.myShowcaseList,
          ...fetchMoreResult.getMyShowcaseListDetails.response.myShowcaseList,
        ];
        return fetchMoreResult;
      },
    });
  };
  const disableLoadMore =
    myShowcaseList?.length === 0 ||
    !Number.isInteger(totalCount) ||
    myShowcaseList?.length === totalCount;
  return (
    <ModalContainer
      show={show}
      onHide={handleClose}
      dialogClassName="my-trophies-modal w-100 h-100"
      disableFooter
      title={title}
      showCloseButton
      showBackButton={false}
    >
      <div className="h-100 w-100">
        {isDashboardUserView && (
          <div className="d-flex flex-between py-3 px-4 border bc-grey1">
            <div className="d-flex">
              <SvgUser
                color={COLORS.PRIMARY_BLUE}
                height="18"
                width="18"
                className="mt-2"
              />
              <div className="ml-2">
                <div className="fs-14 semi-bold">
                  <Truncate text={userName} width="150px" />
                </div>
                <div className="fs-12 fc-grey">
                  <Truncate text={emailAddress} width="150px" />
                </div>
              </div>
            </div>
            <div>
              <div className="semi-bold fs-14">{leaderboardCount}</div>
              <div className="fc-grey fs-12">
                {t("LEADERBOARD.LABELS.LEADERBOARDS")}
              </div>
            </div>
          </div>
        )}
        <div className="px-4 pb-4">
          <div className="d-flex flex-between">
            <div className="semi-bold fs-14">
              {t("LEADERBOARD.MY_BOARDS.LABELS.COLLECTIBLES")}
            </div>
            <div>
              <MobileFilterBarIcons
                loading={loading}
                filters={filters}
                filtersState={filtersState}
                {...rest}
              />

              <MobileFilterBarFilters
                filters={filters}
                filtersState={filtersState}
                {...rest}
              />
            </div>
          </div>
          {networkStatus !== NetworkStatus.ready &&
          networkStatus !== NetworkStatus.fetchMore ? (
            <LoadingTable rows={4} height="300px" />
          ) : myShowcaseList?.length > 0 ? (
            myShowcaseList.map((item) => (
              <div className=" border br-8 p-2 mt-3">
                <div className="d-flex ">
                  <span className="h-40">
                    <CollectibleFormatter
                      position={item.LeaderboardRank}
                      badgeType={item.BadgeType}
                    />
                  </span>
                  <span>
                    <div className="fs-14 semi-bold">{item.Name}</div>
                    <div>
                      <RecurrenceFormatter
                        RecurrenceStartDate={item.RecurrenceStartDate}
                        RecurrenceEndDate={item.RecurrenceEndDate}
                        RecurrenceType={item.RecurrenceType}
                        RecurrenceCount={item.RecurrenceCount}
                      />
                    </div>
                  </span>
                </div>
                <div className="mt-4 pt-3 pb-2 border-top">
                  <AchievementFormatter
                    goalDistribution={item.GoalDistribution}
                    achievements={item.Achievements}
                    achievement={item.Achievement}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="flex-column-center mt-5">
              <NoResults />
              <div className="fs-14 fc-grey mt-2 text-center">
                {t("LEADERBOARD.COMMON.LABELS.NO_RESULTS_TO_SHOW")}
              </div>
            </div>
          )}
          {!disableLoadMore && (
            <div
              className="d-flex w-100 align-items-center justify-content-center mt-3"
              ref={ShimmerRef}
            >
              <LoadMoreButton
                disabled={myShowcaseList.length === totalCount}
                id="my-showcase-grid-show-more"
                onClick={onFetchMore}
                itemsLoaded={myShowcaseList.length}
                totalItems={totalCount}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

MobileMyShowcaseModal.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  isGoalBased: PropTypes.bool,
};

MobileMyShowcaseModal.defaultProps = {
  show: false,
  handleClose: () => {},
};

export default MobileMyShowcaseModal;
