import React from "react";

const IllustrationContainer = ({ isMobile, image, title, subTitle }) => {
  return (
    <div className="pt-5 px-2 pb-2 flex-center flex-column">
      <div className="pt-5 flex-center">{image}</div>
      <div className="flex-center flex-column">
        <p
          className={`${
            isMobile ? "fs-24 pt-1" : "fs-30 pt-4"
          } bold fc-darker-grey`}
        >
          {title}
        </p>
        <p className={`${isMobile ? "fs-18" : "fs-22"} fc-blue semi-bold`}>
          {subTitle}
        </p>
      </div>
    </div>
  );
};

export default IllustrationContainer;
