import { t } from "i18next";
import { lazy, Suspense, useEffect, useState } from "react";
import {
  useBulkApprovalFileUploadUrl,
  useDownloadBulkApprovalRawExcel,
  useProcessBulkApprovalImport,
  useValidateBulkApprovalImport,
} from "src/api/useIncentive";
import { useUploadFileToS3 } from "src/api/useUploadFileToS3";
import { FormButton, StatsItem, toaster } from "src/components";
import { classNames } from "src/helpers";
import { useToggle } from "src/hooks";
import { SvgAttachment, SvgDownload } from "src/icons";
import "./approvalUsingExcel.scss";

const BulkAction = lazy(() =>
  import(
    /*
    webpackChunkName: "bulk.action",
    webpackPrefetch: true
    */
    "src/components/common/atoms/bulkAction"
  )
);

function ApprovalUsingExcel(props) {
  const {
    className,
    disabled,
    totalUsers,
    programId,
    selectedRecurrence,
    query,
    refetch,
    bulkApprovalPlaceholder,
    bulkApprovalDescription,
    groupId,
  } = props;

  const [modal, showAndHideModal] = useToggle();
  const [downloadBulkApprovalRawExcel] = useDownloadBulkApprovalRawExcel();
  const [bulkApprovalFileUploadUrl] = useBulkApprovalFileUploadUrl();
  const [validateBulkApprovalImport, { data }] =
    useValidateBulkApprovalImport();
  const [processBulkApprovalImport] = useProcessBulkApprovalImport();
  const { uploadFileToS3, cancelUpload } = useUploadFileToS3();
  const [fileDetails, setFileDetails] = useState({
    fileId: null,
    fileName: null,
    fileError: "",
  });
  const [downloadUrl, setDownloadUrl] = useState("");

  useEffect(() => {
    if (modal) {
      downloadBulkApprovalRawExcel({
        variables: {
          programId,
          recurrenceId: selectedRecurrence?.RecurrenceId,
          GroupId: groupId,
          query,
        },
      }).then((result) => {
        setDownloadUrl(
          result?.data?.downloadBulkApprovalRawExcel.response?.url
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const handleRemove = () => {
    setFileDetails({
      fileId: null,
      fileName: null,
      fileError: "",
    });
  };

  const handleUpload = ({ acceptedFiles }) => {
    if (fileDetails.fileName || fileDetails.fileError) {
      handleRemove();
    }
    bulkApprovalFileUploadUrl({
      variables: {
        programId,
        recurrenceId: selectedRecurrence?.RecurrenceId,
      },
    }).then(async (result) => {
      const presignedUrl =
        result?.data?.bulkApprovalFileUploadUrl?.response?.url;
      await uploadFileToS3({
        presignedUrl,
        file: acceptedFiles[0],
      }).then(() => {
        validateBulkApprovalImport({
          variables: {
            programId,
            fileId: result?.data?.bulkApprovalFileUploadUrl?.response?.fileId,
            recurrenceId: selectedRecurrence?.RecurrenceId,
            GroupId: groupId,
            query,
          },
        })
          .then((validationResult) => {
            if (
              validationResult.data.validateBulkApprovalImport.__typename ===
              "ErrorResponse"
            ) {
              setFileDetails({
                fileName: null,
                fileId: null,
                fileError:
                  validationResult.data.validateBulkApprovalImport.message,
              });
            } else {
              toaster({
                message: "File uploaded successfully.",
                type: "info",
              });
              setFileDetails({
                fileName: acceptedFiles[0].name,
                fileId:
                  result?.data?.bulkApprovalFileUploadUrl?.response?.fileId,
                fileError: "",
              });
            }
          })
          .catch((error) => {});
      });
    });
  };

  const handleProcessPayout = () => {
    processBulkApprovalImport({
      variables: {
        programId,
        recurrenceId: selectedRecurrence?.RecurrenceId,
        GroupId: groupId,
        fileId: fileDetails.fileId,
        query,
      },
    }).then(() => {
      refetch && refetch();
      toaster({
        message: "Approval processed successfully.",
        type: "info",
      });
      showAndHideModal();
    });
  };

  return (
    <div className={classNames([className, "payout-using-excel"])}>
      <FormButton
        className="action-btn"
        variant="outline-primary"
        onClick={showAndHideModal}
        disabled={disabled}
      >
        <SvgAttachment className="mr-2" /> Approve Using Excel
      </FormButton>
      <Suspense fallback="...">
        {modal && downloadUrl ? (
          <BulkAction
            className="payout-using-excel-modal"
            show={Boolean(modal)}
            handleClose={showAndHideModal}
            title={t("INCENTIVES.PAYOUT.LABELS.APPROVAL_USING_EXCEL")}
            handleUpload={handleUpload}
            isOnSuccess={Boolean(fileDetails.fileName)}
            fileError={fileDetails.fileError}
            isOnError={Boolean(
              data?.validateBulkApprovalImport?.response?.errorCount
            )}
            placeholder={bulkApprovalPlaceholder}
            handleCancel={cancelUpload}
            errorProps={{
              handleBulkAction: handleProcessPayout,
              errorCount:
                data?.validateBulkApprovalImport?.response?.errorCount,
              erroredFileUrl:
                data?.validateBulkApprovalImport?.response?.downloadUrl,
              totalCount: totalUsers,
              buttonDisabled:
                !data?.validateBulkApprovalImport?.response?.isApprovalAllowed,
              buttonText: t("MANAGED_GOALS.BUTTONS.DISCARD_ERRORS_APPROVAL"),
            }}
            filePickerSuccessProps={{
              handleBulkAction: handleProcessPayout,
              buttonText: "Complete Approval",
            }}
          >
            <div className="center justify-content-center mt-32">
              <div className="fs-13 w-50">{bulkApprovalDescription}</div>
              <div className="center border-l">
                <StatsItem title={"Users"} className="ml-4">
                  <div className="fs-14 semi-bold">{totalUsers}</div>
                </StatsItem>
                <FormButton
                  variant="outline-primary"
                  className="ml-4"
                  as="a"
                  href={downloadUrl}
                  download
                >
                  <SvgDownload className="mr-2" />
                  {t("INCENTIVES.PAYOUT.LABELS.DOWNLOAD_EXCEL_FILE")}
                </FormButton>
              </div>
            </div>
          </BulkAction>
        ) : null}
      </Suspense>
    </div>
  );
}

export default ApprovalUsingExcel;
