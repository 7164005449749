import { gql, useMutation } from "src/api/client";

const COPY_CRITERIA_TO_RECURRENCES_MUTATION = gql`
  mutation copyCriteriaToRecurrences(
    $SourceRecurrenceId: ID!
    $DestinationRecurrenceIds: [ID]!
  ) {
    copyCriteriaToRecurrences(
      sourceRecurrenceId: $SourceRecurrenceId
      destinationRecurrenceIds: $DestinationRecurrenceIds
    ) {
      ... on copyCriteriaToCyclesResponse {
        success
        response
      }
    }
  }
`;

export const useCopyCriteriaToRecurrences = () => {
  return useMutation(COPY_CRITERIA_TO_RECURRENCES_MUTATION);
};
