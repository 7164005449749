import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationModal, Truncate } from "src/components/common/atoms";
import { FormButton, FormTooltip } from "src/components/common/formElements";
import { SvgAlert, SvgClose, SvgEdit } from "src/icons";

const RuleCardHeader = (props) => {
  const {
    goalRuleId,
    showButtons,
    index,
    onEditClick,
    loadingDelete,
    handleDeleteRule,
    ruleName,
    showAlert,
    disableAlertTooltip,
    alertTooltipText,
    disableDelete,
    disableDeleteTooltip,
    deleteTooltipText,
    disableEdit,
    disableEditTooltip,
    editTooltipText,
    tempFormula,
    isCustomFormula,
  } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { t } = useTranslation();

  const ConfirmationModalBody = ({ index }) => (
    <>
      <div className="fs-14 semi-bold fc-black-secondary flex">
        {t("RULEBOOK.MESSAGES.WANT_TO_REMOVE")}{" "}
        <span className="fc-black ml-1">
          <Truncate text={ruleName} maxWidth="200px" />
        </span>
        ?
      </div>
      <p className="fs-13 pt-14 pb-4">
        {t("RULEBOOK.MESSAGES.ACTION_CANNOT_BE_DONE")}
      </p>
    </>
  );

  const handleConfirm = () => {
    handleDeleteRule(goalRuleId);
    setShowDeleteModal(false);
  };

  const disableDeleteCustom =
    disableDelete === false
      ? isCustomFormula && tempFormula?.includes(goalRuleId)
      : disableDelete;

  return (
    <>
      <div
        className={`p-3 rule-card__header ${
          showButtons ? "flex-between" : "center"
        }`}
      >
        <div className="fs-14 d-flex align-items-center">
          <Truncate text={ruleName} maxWidth="200px" />
          {showAlert && (
            <FormTooltip
              id="achievement-card-tooltip"
              placement="bottom"
              text={alertTooltipText}
              disable={disableAlertTooltip}
            >
              <div className="icon-background ml-1">
                <SvgAlert
                  backgroundColor={false}
                  className="cursor-pointer align-baseline w-100"
                />
              </div>
            </FormTooltip>
          )}
        </div>
        {showButtons && (
          <div className="center">
            <FormTooltip
              id="rule-card-header-tooltip"
              placement="bottom"
              text={editTooltipText}
              disable={disableEditTooltip}
            >
              <FormButton
                id={`edit-rule-${index}`}
                className={
                  disableEdit ? "cursor-not-allowed" : "cursor-pointer"
                }
                variant="only-icon"
                icon={<SvgEdit />}
                onClick={!disableEdit ? () => onEditClick() : undefined}
              />
            </FormTooltip>
            <FormTooltip
              id="rule-card-header-tooltip"
              placement="bottom"
              text={
                disableDeleteTooltip && disableDeleteCustom
                  ? t("COMMON.MESSAGES.RULES_NOT_USED")
                  : deleteTooltipText
              }
              disable={disableDeleteCustom ? false : disableDeleteTooltip}
            >
              <FormButton
                id={`delete-rule-${index}`}
                className={
                  disableDeleteCustom ? "cursor-not-allowed" : "cursor-pointer"
                }
                variant="only-icon"
                icon={<SvgClose />}
                onClick={
                  !disableDeleteCustom
                    ? () => setShowDeleteModal(true)
                    : undefined
                }
              />
            </FormTooltip>
          </div>
        )}
      </div>
      {showDeleteModal && (
        <ConfirmationModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          title={t("RULEBOOK.LABELS.REMOVE_RULES")}
          body={<ConfirmationModalBody index={index} />}
          onConfirm={handleConfirm}
          confirmButtonText={t("RULEBOOK.MESSAGES.YES_REMOVE_RULE")}
          cancelButton
          loading={loadingDelete}
          shimmerText={t("COMMON.BUTTONS.DELETING")}
        />
      )}
    </>
  );
};

RuleCardHeader.propTypes = {
  showButtons: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  loading: PropTypes.bool,
  deleteDisable: PropTypes.bool,
  index: PropTypes.number,
};
RuleCardHeader.defaultProps = {
  showButtons: false,
  deleteDisable: false,
};

export default RuleCardHeader;
