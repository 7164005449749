import { APPS, ROLES, USER_ROLES } from "src/constants";
import { useRoleBasedContext } from "src/context";
import { useViewport } from "src/hooks";

const useLeaderboardsRbac = () => {
  const {
    userDetails: { role, isLeaderboardApprover, isManagingUsers },
    isFeatureEnabled,
  } = useRoleBasedContext();

  const { isMobile } = useViewport();

  const DEFAULT_REDIRECTION = {
    [USER_ROLES.ADMIN]: isMobile
      ? APPS.LEADERBOARDS.ROUTES.DASHBOARD.path
      : APPS.LEADERBOARDS.ROUTES.RULESETS.path,
    [USER_ROLES.SALES_MANAGER_AS_APPROVER]: isMobile
      ? APPS.LEADERBOARDS.ROUTES.DASHBOARD.path
      : APPS.LEADERBOARDS.ROUTES.APPROVAL.path,
    [USER_ROLES.SALES_MANAGER]: APPS.LEADERBOARDS.ROUTES.DASHBOARD.path,
    [USER_ROLES.SALES_USER_AS_APPROVER]: isMobile
      ? APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path
      : APPS.LEADERBOARDS.ROUTES.APPROVAL.path,
    [USER_ROLES.SALES_USER]: APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path,
  };

  const getRole = () => {
    if (role === ROLES.ADMIN) {
      return USER_ROLES.ADMIN;
    }

    if (isManagingUsers) {
      if (isLeaderboardApprover) {
        return USER_ROLES.SALES_MANAGER_AS_APPROVER;
      }
      return USER_ROLES.SALES_MANAGER;
    }

    if (isLeaderboardApprover) {
      return USER_ROLES.SALES_USER_AS_APPROVER;
    }

    return USER_ROLES.SALES_USER;
  };

  const ACCESS_ROUTES = {
    [USER_ROLES.SALES_USER]: [
      APPS.LEADERBOARDS.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS_DETAIL_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.PAYOUT_REQUEST_PAGE.path,
    ],
    [USER_ROLES.SALES_USER_AS_APPROVER]: [
      APPS.LEADERBOARDS.ROUTES.APPROVAL.path,
      APPS.LEADERBOARDS.ROUTES.APPROVAL_DETAIL_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.PUBLISHED_LEADERBOARDS_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS_DETAIL_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.PAYOUT_REQUEST_PAGE.path,
    ],
    [USER_ROLES.SALES_MANAGER]: [
      APPS.LEADERBOARDS.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD_DETAILS_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS_DETAIL_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.PAYOUT_REQUEST_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD_BOARD_DETAIL_VIEW_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path,
    ],
    [USER_ROLES.SALES_MANAGER_AS_APPROVER]: [
      APPS.LEADERBOARDS.ROUTES.APPROVAL.path,
      APPS.LEADERBOARDS.ROUTES.APPROVAL_DETAIL_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.PUBLISHED_LEADERBOARDS_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD_DETAILS_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS_DETAIL_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.PAYOUT_REQUEST_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD_BOARD_DETAIL_VIEW_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path,
    ],
    [USER_ROLES.ADMIN]: [
      APPS.LEADERBOARDS.ROUTES.APPROVAL.path,
      APPS.LEADERBOARDS.ROUTES.APPROVAL_DETAIL_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.APPROVAL_REQUEST_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD_DETAILS_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.LEADERBOARD_CRUD.path,
      APPS.LEADERBOARDS.ROUTES.MANAGE_LEADERBOARD.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS.path,
      APPS.LEADERBOARDS.ROUTES.MY_BOARDS_DETAIL_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.PAYOUT.path,
      APPS.LEADERBOARDS.ROUTES.PAYOUT_DETAIL_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.PAYOUT_REQUEST_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.PUBLISHED_LEADERBOARDS_VIEW.path,
      APPS.LEADERBOARDS.ROUTES.RULESETS.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD_BOARD_DETAIL_VIEW_PAGE.path,
      APPS.LEADERBOARDS.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path,
    ],
  };

  const leaderboardsRbac = (route) => {
    switch (route) {
      case APPS.LEADERBOARDS.ROUTES.ROOT.path:
      case APPS.LEADERBOARDS.ROUTES.LANDING.path: {
        return {
          hasAccess: isFeatureEnabled.Leaderboard,
          redirect: APPS.ACE.ROUTES.ROOT.path,
        };
      }

      default: {
        return {
          hasAccess: ACCESS_ROUTES[getRole()].includes(route),
          redirect: DEFAULT_REDIRECTION[getRole()],
        };
      }
    }
  };

  return {
    leaderboardsRbac,
    DEFAULT_LOGGED_IN_USER_REDIRECTION: DEFAULT_REDIRECTION[getRole()],
  };
};

export default useLeaderboardsRbac;
