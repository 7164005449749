import { DATE_FORMAT } from "src/constants";
import { format, getSystemOffset, getUserTimezoneOffset } from "src/helpers";

const DateCell = ({ value, column }) => {
  const userTimezoneOffset = getUserTimezoneOffset();
  if (value) {
    const epoc = parseInt(Date.parse(value), 10) ? Date.parse(value) : value;
    const date = new Date(
      parseInt(epoc, 10) - getSystemOffset() + userTimezoneOffset
    );
    return format(date, column.dateFormat || DATE_FORMAT);
  }
  return "";
};

export default DateCell;
