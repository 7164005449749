import { gql, useMutation } from "src/api/client";

// src/components/templates/create/CreateGoalTemplateModal.js
const CREATE_TEMPLATE_MUTATION = gql`
  mutation CreateTemplate($Name: NotEmpty!, $Description: String) {
    createTemplate(Name: $Name, Description: $Description) {
      ... on SingleTemplateResponse {
        response {
          Template {
            TemplateId
            Name
            Description
          }
        }
        message
        success
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useCreateTemplate = () => {
  return useMutation(CREATE_TEMPLATE_MUTATION);
};
