import React from "react";
import PropTypes from "prop-types";

const TextBesideLine = (props) => {
  const { children, reverse, prefixContent } = props;
  return (
    <div className={`center pt-2 ${reverse ? "flex-row-reverse" : ""}`}>
      <div className={`fs-12 white-space-wrap ${reverse ? "ml-2" : "mr-2"}`}>
        {children}
      </div>
      <div className="w-100 border-top-1 bc-grey3" />
      <div className="fs-12 white-space-wrap px-1">{prefixContent}</div>
    </div>
  );
};

TextBesideLine.propTypes = {
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
};

TextBesideLine.defaultProps = {
  reverse: false,
};

export default TextBesideLine;
