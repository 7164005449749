import { gql, useQuery } from "src/api/client";
import {
  CORE_TEMPLATE_DETAILS_FRAGMENT,
  CORE_TEMPLATE_GOAL_RULE_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
} from "src/api/fragments";

// src/modules/goalCrud/goalAttributes/GoalAttribute.js -done
// src/modules/publishedGoalView/goalTemplatePopover/index.js --done
export const GET_GOAL_TEMPLATE_DETAILS_QUERY = gql`
  ${CORE_TEMPLATE_DETAILS_FRAGMENT}
  ${CORE_TEMPLATE_GOAL_RULE_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query GetTemplateDetails($TemplateId: ID!) {
    template(TemplateId: $TemplateId) {
      ... on SingleTemplateResponse {
        response {
          Template {
            ...coreTemplateDetailsFragment
            ModifiedBy
            GoalRules {
              ...coreTemplateGoalRuleFragment
            }
          }
          validations {
            isAggregateFunctionAllowed
            isUnpublishedAllowed
            isDeleteAllowed
          }
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetGoalTemplateDetails = (queryOptions) => {
  return useQuery(GET_GOAL_TEMPLATE_DETAILS_QUERY, queryOptions);
};
