import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { range } from "src/helpers";
import { LoadingPublishedTabs } from "../loadingPublishedTabs";
import { LoadingTabCards } from "../loadingTabCards";

const LoadingPublishedLeaderboardView = () => (
  <div className="page-container mt-4">
    <LoadingPublishedTabs />
    <div className="my-4 flex-between">
      <LoadingTabCards />
      <div className="center">
        <div className="mr-2">
          <Shimmer width="60px" height="30px" />
        </div>
        <Shimmer width="100px" height="30px" />
      </div>
    </div>
    <div className="w-100 center pb-4 border-b">
      <div className="mt-3">
        <Shimmer width="100px" height="15px" />
      </div>
      <div className="mt-3 pl-3">
        <Shimmer width="100px" height="15px" />
      </div>
    </div>
    {range(0, 2).map((i) => (
      <div key={i} className="flex-between mt-3">
        <Shimmer width="100px" height="40px" />
        <Shimmer width="100px" height="15px" />
      </div>
    ))}
  </div>
);

export default LoadingPublishedLeaderboardView;
