import PropTypes from "prop-types";
import { SvgClose } from "src/icons";
import { Truncate } from "../../truncate";
import "./styles.scss";

const ViewCard = ({ title, children, className, onClick, onRemoveClick }) => {
  const handleRemove = (event) => {
    event.stopPropagation();
    onRemoveClick && onRemoveClick();
  };
  return (
    <button
      type="button"
      id="view-card"
      onClick={onClick}
      className={`view-card ${className}`}
    >
      <div className="fs-14 semi-bold">
        <Truncate id="view-card-text" text={title} maxWidth="215px" />
      </div>
      {children ? <div className="fs-12 fc-grey">{children}</div> : null}
      {onRemoveClick ? (
        <button
          className="bg-transparent"
          type="button"
          id="remove-card"
          onClick={handleRemove}
        >
          <SvgClose width={12} height={12} />
        </button>
      ) : null}
    </button>
  );
};

ViewCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

ViewCard.defaultProps = {
  children: null,
  className: "",
};

export default ViewCard;
