import React from "react";

const SvgAvatar3 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="eopc__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .eopc__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="eopc__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.71-.68-1.41-1.38-2.1-2.08l-.05-.05C8.78 123.47 0 103.48 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="eopc__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="eopc__cls-2"
        />
        <path
          d="M41.01 64.61a21.02 21.02 0 0 1 2.84-10.07c.78 3 3.3 5.3 6.42 5.75v.09h62.08v-.09c3.77-.55 6.68-3.79 6.68-7.71 0-4.21-3.41-9.1-7.8-9.1h-3.06c-2.38-19.16-13.54-32.95-26.85-32.95-27.03 0-49.04 23.53-49.4 52.61-3.61 1.32-6.19 4.78-6.19 8.84 0 5.2 4.23 9.43 9.43 9.43s9.43-4.23 9.43-9.43c0-2.98-1.39-5.63-3.55-7.36Zm70.2-18.54c2.8 0 5.2 3.58 5.2 6.5s-2.33 5.2-5.2 5.2H51.39c-2.87 0-5.2-2.33-5.2-5.2s2.4-6.5 5.2-6.5h59.82ZM81.3 13.12c11.71 0 21.95 12.95 24.23 30.35H57.72c3.19-10.29 12.65-17.34 23.58-17.34.72 0 1.3-.58 1.3-1.3s-.58-1.3-1.3-1.3c-12.34 0-22.99 8.16-26.28 19.94h-3.63c-3.43 0-6.24 2.98-7.32 6.28-.05.04-.1.07-.14.12-3.19 3.9-5.05 8.46-5.45 13.29a9.48 9.48 0 0 0-3.34-.62c-.21 0-.41.02-.62.03.63-27.38 21.37-49.45 46.79-49.45ZM35.13 78.79c-3.76 0-6.83-3.06-6.83-6.83s3.06-6.83 6.83-6.83 6.83 3.06 6.83 6.83-3.06 6.83-6.83 6.83Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar3.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar3;
