import React from "react";
import { capitalize, classNames } from "src/helpers";
import { AmountLabel } from "../amountLabel";

export const MonetaryAndNonMonetaryDetails = (props) => {
  // use this component to get detailed listing of items without truncation/tooltip
  const {
    amount,
    nonMonetaryRewards = [],
    className,
    nonMonetaryClassName,
  } = props;

  const formatRewardsList = (rewards) => {
    return (
      <div className="d-flex">
        {rewards?.map((reward, idx) => (
          <div
            className={classNames([
              "align-items-center mr-1",
              nonMonetaryClassName,
            ])}
          >
            {reward.RewardCount} {capitalize(reward.RewardCategory)}
            {idx === rewards.length - 1 ? null : ","}
          </div>
        ))}
      </div>
    );
  };
  return (
    <div>
      <div className={classNames(["align-items-center", className])}>
        {amount !== null && amount !== undefined ? (
          <span className="center">
            <AmountLabel amount={amount} className="pr-1" />
            {nonMonetaryRewards?.length > 0 ? "&" : null}&nbsp;
          </span>
        ) : (
          "--"
        )}
        {formatRewardsList(nonMonetaryRewards)}
      </div>
    </div>
  );
};
