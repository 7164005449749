import PropTypes from "prop-types";
import React from "react";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import "./dotBadge.scss";

const outlinePropsType = {
  outline: PropTypes.bool,
  outlineWidth: PropTypes.string,
  outlineColor: PropTypes.string,
};
const defaultOutlineProps = {
  outline: true,
  outlineWidth: "2px",
  outlineColor: COLORS.WHITE,
};

const DotBadgeProps = {
  bg: PropTypes.bool,
  size: PropTypes.string,
  backgroundColor: PropTypes.string,
  outlineProps: PropTypes.shape(outlinePropsType),
  className: PropTypes.string,
};
const defaultDotBadgeProps = {
  bg: true,
  size: "5px",
  backgroundColor: COLORS.PRIMARY_BLUE,
  outlineProps: defaultOutlineProps,
  className: "",
};

const DotBadge = (props) => {
  const { size, bg, backgroundColor, outlineProps, className, children } =
    props;
  const { outline, outlineWidth, outlineColor } = outlineProps;

  return (
    <div
      style={{
        "--dot-badge-size": size,
        "--dot-badge-bg-color": backgroundColor,
        "--dot-badge-outline-width": outlineWidth,
        "--dot-badge-outline-color": outlineColor,
      }}
      className={classNames([
        "dot-badge",
        bg ? "dot-badge-bg" : "",
        outline ? "dot-badge-outline" : "",
        className,
      ])}
    >
      {children}
    </div>
  );
};

DotBadge.propTypes = DotBadgeProps;
DotBadge.defaultProps = defaultDotBadgeProps;

export default DotBadge;
