import { t } from "i18next";
import React from "react";
import { ConfirmationModal, FormButton } from "src/components/common";
import { SvgLink } from "src/icons";

const CustomButtonLayout = ({
  confirmationButtonText,
  handleConfirm,
  handleOnClose,
}) => (
  <div className="flex-between mt-3 w-100">
    <FormButton
      id="view-dependency-btn"
      className="mr-3"
      variant="link"
      label={
        <div className="d-flex align-items-center">
          <span className="mr-2">{confirmationButtonText}</span>
          <SvgLink height={15} width={15} />
        </div>
      }
      onClick={handleConfirm}
    />
    <FormButton
      id="primary-btn"
      variant="primary"
      label={t("COMMON.BUTTONS.CLOSE")}
      onClick={handleOnClose}
    />
  </div>
);

const TemplateErrorModal = (props) => {
  const {
    handleConfirm,
    handleOnClose,
    toggleModal,
    confirmationModalBody,
    confirmationButtonText,
    title,
  } = props;

  return (
    <div>
      <ConfirmationModal
        show={toggleModal}
        customButtonLayout={
          <CustomButtonLayout
            {...{ confirmationButtonText, handleConfirm, handleOnClose }}
          />
        }
        dialogClassName="confirm-delete-template"
        onClose={handleOnClose}
        title={title}
        body={confirmationModalBody}
      />
    </div>
  );
};

export default TemplateErrorModal;
