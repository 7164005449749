import { useEffect } from "react";
import { useGetTemplateBasedAchievement } from "src/api/useIncentive";
import { useGetDashboardProgramUserAchievements } from "src/api/useIncentiveDashboard";
import { useGetUserDetails } from "src/api/useUser";
import { FIXED_USER_ATTRIBUTES } from "src/config/userAttributes";
import { PAGINATION_LIMIT } from "src/constants";
import { getFullName, isEmpty } from "src/helpers";

const useIncentivesDashboardAB = ({
  managerUserId,
  Recurrence,
  templateId,
  setGoalUser,
  ShouldFetch,
  programId,
  setShouldFetch,
  User,
}) => {
  const {
    data: { dashboardProgramUserAchievements } = {
      dashboardProgramUserAchievements: [],
    },
    loading: dashboardProgramUserAchievementsLoading,
    fetchMore,
  } = useGetDashboardProgramUserAchievements({
    variables: {
      limit: PAGINATION_LIMIT,
      ProgramId: programId,
      UserId: managerUserId,
      RecurrenceId: Recurrence?.RecurrenceId,
      after: null,
      query: {},
    },
    notifyOnNetworkStatusChange: true,
  });

  const usersAchievementDetails =
    dashboardProgramUserAchievements?.response?.dashboardUserAchievements;

  const {
    data: allUsersAchievementData,
    loading: usersAchievementLoading,
    error: usersAchievementError,
  } = useGetTemplateBasedAchievement({
    variables: {
      RecurrenceId: Recurrence?.RecurrenceId,
      TemplateId: templateId,
      ManagerId: managerUserId,
    },
    skip: !Recurrence?.RecurrenceId || !templateId,
  });

  const allUserAchievement =
    allUsersAchievementData?.fetchTemplateBasedAchievement?.response?.[0]
      .Achievement;

  const { loading: usersDetailsLoading, data: usersDetailsData } =
    useGetUserDetails({
      variables: {
        UserIds: usersAchievementDetails?.map((user) => user.UserID),
        userAttributesNames: FIXED_USER_ATTRIBUTES,
      },
      notifyOnNetworkStatusChange: true,
      skip: isEmpty(usersAchievementDetails),
    });

  const usersList = [];
  if (usersDetailsData && !isEmpty(usersDetailsData?.getUsersDetails)) {
    const userDetailsMap = new Map();
    for (const userDetails of usersDetailsData?.getUsersDetails) {
      const user = userDetails?.node;
      if (user) {
        const FullName = getFullName(user?.FirstName, user?.LastName);
        userDetailsMap.set(user.UserID, {
          UserID: user.UserID,
          FullName,
        });
      }
    }
    if (usersDetailsData?.getUsersDetails.length > 1)
      usersList.push({
        FullName: "All",
        UserID: "",
      });

    for (const achievementDetail of usersAchievementDetails) {
      const userID = achievementDetail.UserID;
      if (userDetailsMap.has(userID)) {
        const mergedUser = {
          ...userDetailsMap.get(userID),
          achievement: achievementDetail.Templates[templateId],
        };
        usersList.push(mergedUser);
      }
    }
  }

  const getAchievement = (user) => {
    if (!user?.UserID) {
      return allUserAchievement;
    }
    return user?.achievement;
  };

  const usersListWithAchievements = usersList.map((user) => {
    return {
      FullName: user?.FullName,
      UserID: user?.UserID,
      Achievement: getAchievement(user),
    };
  });

  useEffect(() => {
    if (!User?.UserID) {
      setGoalUser({
        FullName: User?.FullName,
        UserID: null,
        Achievement: allUserAchievement,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsersAchievementData, Recurrence?.RecurrenceId]);

  useEffect(() => {
    if (ShouldFetch && !isEmpty(usersListWithAchievements)) {
      const currentGoalUser = usersListWithAchievements.find(
        (userListWithAchievements) =>
          userListWithAchievements.UserID === User?.UserID
      );
      setGoalUser(
        currentGoalUser ? currentGoalUser : usersListWithAchievements[0]
      );
      setShouldFetch(false);
    }
  }, [
    ShouldFetch,
    User?.UserID,
    setGoalUser,
    setShouldFetch,
    usersListWithAchievements,
  ]);

  const onFetchMore = () => {
    const { endCursor } = dashboardProgramUserAchievements?.response?.pageInfo;
    fetchMore({
      variables: {
        limit: PAGINATION_LIMIT,
        ProgramId: programId,
        UserId: managerUserId,
        RecurrenceId: Recurrence?.RecurrenceId,
        after: endCursor,
        query: {},
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        fetchMoreResult.dashboardProgramUserAchievements.response.dashboardUserAchievements =
          [
            ...prev.dashboardProgramUserAchievements?.response
              ?.dashboardUserAchievements,
            ...fetchMoreResult.dashboardProgramUserAchievements?.response
              ?.dashboardUserAchievements,
          ];
        return fetchMoreResult;
      },
    });
  };

  const hasMoreUsers =
    dashboardProgramUserAchievements?.response?.pageInfo?.hasNextPage;

  return {
    usersListWithAchievements,
    hasMoreUsers,
    onFetchMoreUserList: onFetchMore,
    usersListWithAchievementsLoading:
      usersAchievementLoading ||
      usersDetailsLoading ||
      dashboardProgramUserAchievementsLoading,
    usersListWithAchievementsError: usersAchievementError,
  };
};

export default useIncentivesDashboardAB;
