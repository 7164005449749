import { t } from "i18next";
import React, { useState } from "react";
import { DisplayFormula } from "src/apps/incentives/components";
import { standardToCustomCondition } from "src/apps/incentives/components/formulaBuilder/utils";
import { COLORS } from "src/constants";
import { SvgArrow } from "src/icons";
import { FormButton } from "../../formElements";
import { ConditionBreakdown } from "../conditionBreakdown";
import { Truncate } from "../truncate";

const ConditionListAccordion = (props) => {
  const {
    index,
    title,
    subtitle,
    showDrillDown,
    mobileProgramDetails,
    condition,
    Name,
    programId,
    userId,
    recurrenceId,
    onTemplateSelection,
    templates,
  } = props;

  const [showAchievementDetails, setShowAchievementDetails] = useState(false);

  const isUserPartOfGoal =
    condition?.GoalId && Boolean("goalValue" in condition);

  const allowDrillDown =
    showDrillDown && (condition?.GoalId ? isUserPartOfGoal : true);

  const modifiedCondition = standardToCustomCondition({ condition });

  return (
    <div className="fs-12 semi-bold border-1 bc-grey12 fc-grey1 condition-list-item justify-content-center px-12 py-3 d-flex flex-column br-8 mt-3">
      <span className="fc-black fs-14 pb-2 px-2">
        {index}. {Name || `Condition ${index}`}
      </span>
      <hr className="w-100 fc-grey py-2" />
      <div className="d-flex center pb-3">
        {title || subtitle ? (
          <div>
            <div className="fc-black">
              <Truncate text={title} width="160px" />
            </div>
            <div>{subtitle}</div>
          </div>
        ) : (
          <DisplayFormula
            condition={modifiedCondition}
            className="fc-black"
            variant={"conditionList"}
          />
        )}
      </div>
      <hr className="w-100 fc-grey pt-2" />
      <FormButton
        onClick={() => setShowAchievementDetails(!showAchievementDetails)}
        variant="normal-link"
        label={
          <div className="flex justify-content-between fc-grey1 view-achievement-details">
            <span>
              {showAchievementDetails
                ? t("INCENTIVES.COMMON.LABELS.HIDE")
                : t("RULEBOOK.LABELS.VIEW")}{" "}
              {t("INCENTIVES.COMMON.LABELS.ACHIEVEMENT_DETAILS")}
            </span>
            <SvgArrow
              rotate={showAchievementDetails ? 0 : -90}
              color={COLORS.GREY_1}
            />
          </div>
        }
      />
      {showAchievementDetails && (
        <ConditionBreakdown
          condition={modifiedCondition.Formula}
          programId={programId}
          userId={userId}
          recurrenceId={recurrenceId}
          onTemplateSelection={onTemplateSelection}
          allowDrillDown={allowDrillDown}
          mobileProgramDetails={mobileProgramDetails}
          isUserPartOfGoal={isUserPartOfGoal}
          goalValue={condition?.goalValue}
          isFormula={modifiedCondition?.IsFormula}
          templates={templates}
        />
      )}
    </div>
  );
};

export default ConditionListAccordion;
