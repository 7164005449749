import React from "react";
import { AmountLabel, Truncate, UserOrProgramCard } from "src/components";
import { capitalize, getCustomCycleIntervalString } from "src/helpers";
import "./rewardsDetailsCard.scss";

const RewardsDetailsCard = (props) => {
  const {
    ProgramName,
    RecurrenceEndDate,
    RecurrenceStartDate,
    EmailAddress,
    UserName,
    RewardName,
    RewardCategory,
    RewardValue,
    RewardsCount,
  } = props;

  const list = [
    {
      label: "Reward",
      value: (
        <div className="center">
          <Truncate text={RewardName} maxWidth="100px" />
          {Boolean(RewardValue) && (
            <div className="ml-1 fc-grey1 center regular">
              (<AmountLabel amount={RewardValue} />)
            </div>
          )}
        </div>
      ),
    },
    {
      label: "Category",
      value: <Truncate maxWidth="140px" text={capitalize(RewardCategory)} />,
    },
    {
      label: "Name",
      value: <Truncate text={UserName} maxWidth="140px" />,
    },
    {
      label: "Email",
      value: <Truncate text={EmailAddress} maxWidth="140px" />,
    },
  ];

  return (
    <UserOrProgramCard
      title={ProgramName}
      subtitle={getCustomCycleIntervalString(
        RecurrenceStartDate,
        RecurrenceEndDate
      )}
      list={list}
      disableClick
      className="position-relative"
    >
      <div className="fs-12 semi-bold px-2 rewards-deatils-card-count">
        x{RewardsCount}
      </div>
    </UserOrProgramCard>
  );
};

export default RewardsDetailsCard;
