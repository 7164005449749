import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { NetworkStatus } from "src/api/client";
import {
  useGetNonMonetaryRewardsDropdownList,
  useGetNonMonetaryRewardsProgramDetails,
} from "src/api/useCustomRewardConfig";
import { LoadMoreButton, Truncate, toaster } from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import { FilterMenuBar } from "src/components/common/molecules";
import {
  AceGrid,
  AmountCell,
  DefaultCell,
} from "src/components/common/organisms";
import {
  DATE_FORMAT,
  FORMAT_RECURRENCE_PERIOD,
  PAGINATION_LIMIT,
} from "src/constants";
import {
  capitalize,
  errorText,
  format,
  getCustomCycleIntervalString,
  getCycleIntervalString,
  getSystemOffset,
} from "src/helpers";
import { NoResults } from "src/images";
import { LoadingTable } from "../../loading";
import "./rewardDetails.scss";

const RewardDetails = ({
  show,
  handleClose,
  title,
  payload,
  isUserSpecific,
}) => {
  const {
    fieldName,
    userId,
    programId,
    recurrenceId,
    featureType,
    AllDateRange,
    TimeFrequency,
    cycleType,
    IsUserIdManager,
  } = payload || {};
  const allOption = { RewardId: "ALL", RewardName: "All" };
  const [reward, setReward] = useState(allOption);
  const [search, setSearch] = useState("");
  const ShimmerRef = useRef();

  const getQuery = (isDropdown = false) => {
    const queryDetails = {
      FieldName: fieldName,
      UserId: userId,
      ProgramId: programId,
      RecurrenceId: recurrenceId,
      FeatureType: featureType,
      AllDateRange,
      TimeFrequency,
      CycleType: cycleType,
      IsUserIdManager,
    };

    return isDropdown
      ? queryDetails
      : {
          ...queryDetails,
          RewardId: reward?.RewardId === "ALL" ? undefined : reward?.RewardId,
          Search: search,
        };
  };

  const {
    data: { nonMonetaryRewardsDropdownList } = {
      nonMonetaryRewardsDropdownList: {},
    },
  } = useGetNonMonetaryRewardsDropdownList({
    variables: {
      Query: getQuery(true),
    },
    skip: !show,
    fetchPolicy: "network-only",
  });

  let customRewardsList =
    nonMonetaryRewardsDropdownList?.response?.rewardsList || [];
  customRewardsList = [allOption, ...customRewardsList];

  const filters = [
    {
      id: "rewards-dropdown",
      label: "LEADERBOARD.LABELS.REWARD",
      highlight: true,
      defaultValue: reward,
      value: reward,
      onChange: setReward,
      options: customRewardsList,
      optionLabelKey: "RewardName",
      optionValueKey: "RewardId",
    },
  ];

  const searchProps = {
    id: "search-rewards",
    placeholder: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.SEARCH_NAME"),
    search,
    setSearch,
  };

  const {
    data: { nonMonetaryRewardsProgramDetails } = {
      nonMonetaryRewardsProgramDetails: {},
    },
    loading = false,
    error = false,
    networkStatus = NetworkStatus.ready,
    fetchMore = () => {},
  } = useGetNonMonetaryRewardsProgramDetails({
    variables: {
      Limit: PAGINATION_LIMIT,
      Query: getQuery(),
      After: null,
    },
    skip: !show,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const rewardsList = nonMonetaryRewardsProgramDetails?.response?.rewardsList;
  const totalCount = nonMonetaryRewardsProgramDetails?.response?.totalCount;

  const formatRecurrenceValue = (
    RecurrenceStartDate,
    RecurrenceEndDate,
    recurrencePeriod
  ) => {
    const period = recurrencePeriod && recurrencePeriod.toLowerCase();
    let value = "";
    if (period === FORMAT_RECURRENCE_PERIOD.INFINITE.lowercase) {
      value = `${format(
        new Date(RecurrenceStartDate - getSystemOffset()),
        DATE_FORMAT
      )} - Forever`;
    } else if (period === FORMAT_RECURRENCE_PERIOD.CUSTOM.lowercase) {
      value = getCustomCycleIntervalString(
        RecurrenceStartDate,
        RecurrenceEndDate
      );
    } else {
      value = getCycleIntervalString(
        RecurrenceStartDate,
        RecurrenceEndDate,
        period
      );
    }
    return value;
  };

  const onFetchMore = () => {
    const { endCursor } = nonMonetaryRewardsProgramDetails?.response?.pageInfo;
    fetchMore({
      variables: {
        Limit: PAGINATION_LIMIT,
        After: endCursor,
        Query: getQuery(),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        fetchMoreResult.nonMonetaryRewardsProgramDetails.response.rewardsList =
          [
            ...prev.nonMonetaryRewardsProgramDetails.response.rewardsList,
            ...fetchMoreResult.nonMonetaryRewardsProgramDetails.response
              .rewardsList,
          ];
        return fetchMoreResult;
      },
    });
  };

  const recurrenceFormatter = ({ row: { original } }) => {
    const { RecurrenceStartDate, RecurrenceEndDate, RecurrenceType } = original;
    return (
      <div className="px-0 center recurrence-list__item-wrapper">
        <div className="text-nowrap fs-12">
          {formatRecurrenceValue(
            RecurrenceStartDate,
            RecurrenceEndDate,
            RecurrenceType
          )}
        </div>
      </div>
    );
  };

  const basicColumns = [
    {
      Header: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.PROGRAM"),
      accessor: "ProgramName",
      width: 170,
      Cell: DefaultCell,
      formatValue: ({ ProgramName }) => (
        <Truncate maxWidth="140px" text={ProgramName} />
      ),
      sticky: "left",
    },
    {
      Header: t("MANAGED_GOALS.LABELS.ROLL_UP_CYCLE"),
      accessor: "RecurrenceStartDate",
      width: 170,
      Cell: recurrenceFormatter,
    },
    {
      Header: t("LEADERBOARD.LABELS.REWARD"),
      accessor: "RewardName",
      width: 150,
      Cell: DefaultCell,
      formatValue: ({ RewardName }) => (
        <Truncate maxWidth="100px" text={RewardName} />
      ),
    },
    {
      Header: t("NON_MONETARY.LABELS.QUANTITY"),
      accessor: "RewardsCount",
      width: 150,
      Cell: DefaultCell,
      formatValue: ({ RewardsCount }) => (
        <Truncate maxWidth="100px" text={RewardsCount} />
      ),
    },
    {
      Header: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.CATEGORY"),
      accessor: "RewardCategory",
      width: 130,
      Cell: DefaultCell,
      formatValue: ({ RewardCategory }) => (
        <Truncate maxWidth="100px" text={capitalize(RewardCategory)} />
      ),
    },
    {
      Header: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.REWARD_VALUE"),
      accessor: "RewardValue",
      width: 130,
      Cell: AmountCell,
    },
  ];

  const userColumns = [
    {
      Header: t("COMMON.BUTTONS.NAME"),
      accessor: "UserName",
      width: 150,
      Cell: DefaultCell,
      formatValue: ({ UserName }) => (
        <Truncate maxWidth="130px" text={UserName} />
      ),
    },
    {
      Header: t("MANAGED_GOALS.LABELS.EMAIL"),
      accessor: "EmailAddress",
      width: 150,
      Cell: DefaultCell,
      formatValue: ({ EmailAddress }) => (
        <Truncate maxWidth="130px" text={EmailAddress} />
      ),
    },
  ];

  const columns = isUserSpecific
    ? basicColumns
    : [...basicColumns, ...userColumns];

  if (nonMonetaryRewardsProgramDetails?.__typename === "ErrorResponse") {
    toaster({
      message: nonMonetaryRewardsProgramDetails?.message,
      type: "error",
    });
    return <></>;
  }

  if (error) {
    toaster({
      message: errorText.FETCHING_CUSTOM_REWARD_HISTORY.ERROR,
      type: "error",
    });
    return <></>;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="reward-history-modal max-w-820"
    >
      <Modal.Header className="fs-18 semi-bold" closeButton>
        {title}
      </Modal.Header>
      <Modal.Body className="h-490">
        <div className="px-4 pb-4">
          <FilterMenuBar filters={filters} searchProps={searchProps} />
          {networkStatus !== NetworkStatus.ready &&
          networkStatus !== NetworkStatus.fetchMore ? (
            <LoadingTable rows={4} height="300px" />
          ) : rewardsList?.length > 0 ? (
            <AceGrid
              className="reward-history-grid"
              columns={columns}
              data={rewardsList}
              sorting={false}
              height="300px"
            />
          ) : (
            <div className="flex-column-center mt-5">
              <NoResults />
              <div className="fs-14 fc-grey mt-2 text-center">
                {t("LEADERBOARD.COMMON.LABELS.NO_RESULTS_TO_SHOW")}
              </div>
            </div>
          )}
          {rewardsList?.length !== 0 &&
            Number.isInteger(totalCount) &&
            rewardsList?.length !== totalCount && (
              <div
                className="d-flex w-100 align-items-center justify-content-center pt-2"
                ref={ShimmerRef}
              >
                {!loading ? (
                  <LoadMoreButton
                    disabled={rewardsList.length === totalCount}
                    id="reward-history-grid-show-more"
                    onClick={onFetchMore}
                    itemsLoaded={rewardsList.length}
                    totalItems={totalCount}
                  />
                ) : (
                  <div className="fc-black fs-12 px-4 py-2 bg-grey align-self-center d-flex justify-content-center">
                    {t("COMMON.MESSAGES.LOADING")}
                  </div>
                )}
              </div>
            )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          id="close-btn"
          variant="outline-primary"
          onClick={handleClose}
          label={t("COMMON.BUTTONS.CLOSE")}
        />
      </Modal.Footer>
    </Modal>
  );
};

RewardDetails.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  payload: PropTypes.object,
  isUserSpecific: PropTypes.bool,
};

RewardDetails.defaultProps = {
  title: "Reward Details",
  show: false,
  handleClose: () => {},
  payload: {},
  isUserSpecific: false,
};

export default RewardDetails;
