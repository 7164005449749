import React from "react";
import { Modal } from "react-bootstrap";
import { FormButton } from "src/components/common/formElements";
import { classNames } from "src/helpers";
import { useIsOpenAceUser, useRbac, useToggle } from "src/hooks";
import { MobileMenu } from "src/images";
import { AceMenuList } from "../aceMenuList";
import "./productMenuModal.scss";

const ProductMenuModal = () => {
  const [show, toggleModal] = useToggle();
  const { mobileMenu, mobileActiveRoute } = useRbac();
  const { isOpenAceUser } = useIsOpenAceUser();

  return (
    <>
      <FormButton
        variant="link"
        id="product-menu-button"
        onClick={toggleModal}
        className="flex-center btn btn-link ml-2 p-0 h-40"
      >
        <MobileMenu />
      </FormButton>
      <Modal
        backdropClassName="product-menu-modal-backdrop"
        className="product-menu-modal"
        dialogClassName={classNames([
          "product-menu-modal-dialog",
          isOpenAceUser ? "open-ace-user" : "",
        ])}
        show={show}
        onHide={toggleModal}
      >
        <Modal.Body className="product-menu-modal-content">
          <AceMenuList
            menu={mobileMenu}
            activeRoute={mobileActiveRoute}
            variant="mobile"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductMenuModal;
