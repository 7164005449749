import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgMinus = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill={color} d="M14 7v2H2V7Z" data-name="Union 16" />
    </svg>
  );
};

SvgMinus.defaultProps = {
  color: COLORS.GREY_3,
  height: 16,
  width: 16,
};
export default SvgMinus;
