import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  ConfirmationModal,
  CycleDurationDropdown,
} from "src/components/common/atoms";
import { ToggleCards, UniformDuration } from "src/components/common/molecules";
import {
  BufferDaysCycleSummary,
  FirstCycleDropdown,
  LastCycleDropdown,
  NonUniformDuration,
} from "src/components/common/organisms";
import { MAX_CYCLES, MAX_CYCLE_DAYS, MAX_DATE } from "src/constants";
import {
  CYCLES_TYPES,
  getCustomRecurrences,
  getRecurrences,
  getSystemOffset,
  getTodayAsPerTimezone,
  isNull,
} from "src/helpers";

const ModalBody = () => (
  <div>
    <div className="fs-14 semi-bold">
      {t("COMMON.MESSAGES.CONFIRM_TO_CHANGE_CYCLE_TYPE")}
    </div>
    <div className="fs-13">
      {t("COMMON.MESSAGES.ENTERED_DATA_WILL_BE_LOST")}
    </div>
  </div>
);

const CustomCycleRange = (props) => {
  const {
    startDate,
    endDate,
    isDraft,
    lastCycleMinDate,
    gapDuration,
    cycleDuration,
    recurrences,
    handleNonUniformSave,
    onEachCycleDurationChange,
    handleDurationModalConfirm,
    onGapDurationChange,
    recurrencePeriod,
    handleSwitch,
    title,
    isDistinct,
    durationInputDisabled,
    disabledToggleCards,
    onCycleDurationChange,
    onStartDateChange,
    onEndDateChange,
    cycleDurationLabel,
    disabledCycleDuration,
    disabledCycleDurationTip,
    displayNote,
    firstCycleDropdownLabel,
    disabledFirstCycleDropdown,
    disabledFirstCycleDropdownTip,
    lastCycleDropdownLabel,
    disabledLastCycleDropdown,
    disabledLastCycleDropdownTip,
    selectedCustomCycleType,
    handleCustomCycleTypeClick,
    bufferDaysProps,
    copyModalProps,
    cycleDurationOptions,
    selectedTemplate,
    moduleName,
  } = props;

  const cards = [
    {
      cardTitle: t("COMMON.LABELS.UNIFORM"),
      cardDescription: t("COMMON.LABELS.CREATE_CONTINUOUS_CYCLES"),
    },
    {
      cardTitle: t("COMMON.LABELS.NON_UNIFORM"),
      cardDescription: t("COMMON.LABELS.CREATE_CYCLES_WITH_GAPS"),
    },
  ];

  const today = getTodayAsPerTimezone();
  const [durationViewOnly, setDurationViewOnly] = useState(false);
  const [recurrenceDetailModalShow, setRecurrenceDetailModalShow] =
    useState(true);
  const [showModal, setShowModal] = useState({
    show: false,
    value: selectedCustomCycleType === "UNIFORM" ? 0 : 1,
  });
  const maxCycles =
    gapDuration === 0 && [7, 14].includes(parseInt(cycleDuration))
      ? MAX_CYCLES[cycleDuration === 7 ? "Weekly" : "Biweekly"]
      : Math.ceil(MAX_CYCLE_DAYS / (cycleDuration + gapDuration));

  const isOtherCycle =
    parseInt(gapDuration) === 0 &&
    (parseInt(cycleDuration) === 1 ||
      (isDistinct ? false : parseInt(cycleDuration) === 7) ||
      (isDistinct ? false : parseInt(cycleDuration) === 14));

  const hideCalendar =
    selectedCustomCycleType === "NONUNIFORM" ||
    (selectedCustomCycleType === "UNIFORM" && isOtherCycle);

  useEffect(() => {
    if (startDate) {
      setDurationViewOnly(true);
    }
  }, [startDate]);

  const handleCycleDurationChange = (value) => {
    if (onCycleDurationChange) {
      onCycleDurationChange(value);
      setDurationViewOnly(false);
      setRecurrenceDetailModalShow(false);
    }
  };

  const handleStartDateChange = (date) => {
    if (onStartDateChange) {
      onStartDateChange(date);
      setRecurrenceDetailModalShow(true);
    }
    if (recurrencePeriod === "Custom") {
      setDurationViewOnly(true);
    }
  };

  const handleEndDateChange = (date) => {
    if (onEndDateChange) {
      onEndDateChange(
        CYCLES_TYPES[recurrencePeriod]?.setEndDate({
          startDate,
          date,
          cycleDuration,
        })
      );
    }
  };

  const totalCycles =
    recurrences &&
    recurrences.length === 1 &&
    isNull(recurrences[0]?.recurrenceStartDate)
      ? 0
      : recurrences.length;

  const getRecurrencesList = () => {
    if (recurrencePeriod === "Infinite" && startDate)
      return [
        {
          id: 1,
          RecurrenceStartDate: startDate.getTime() + getSystemOffset(),
          RecurrenceEndDate: MAX_DATE.getTime() + getSystemOffset(),
          RecurrenceType: "INFINITE",
        },
      ];
    let recurrencesList;
    if (recurrencePeriod === "Custom") {
      if (selectedCustomCycleType === "UNIFORM") {
        if (startDate && !endDate)
          recurrencesList = getCustomRecurrences(
            startDate,
            CYCLES_TYPES[recurrencePeriod]?.setEndDate({
              startDate,
              date: startDate,
              cycleDuration,
            }),
            cycleDuration,
            gapDuration
          );
        else
          recurrencesList = getCustomRecurrences(
            startDate,
            endDate,
            cycleDuration,
            gapDuration
          );
      } else {
        recurrencesList = recurrences.map(
          ({ recurrenceStartDate, recurrenceEndDate }, idx) => ({
            id: idx + 1,
            RecurrenceStartDate: recurrenceStartDate
              ? recurrenceStartDate.getTime() + getSystemOffset()
              : null,
            RecurrenceEndDate: recurrenceEndDate
              ? recurrenceEndDate.getTime() + getSystemOffset()
              : null,
            RecurrenceType: "CUSTOM",
          })
        );
      }
    } else if (startDate && !endDate)
      recurrencesList = getRecurrences(
        startDate,
        CYCLES_TYPES[recurrencePeriod]?.setEndDate({
          startDate,
          date: startDate,
        }),
        recurrencePeriod
      );
    else recurrencesList = getRecurrences(startDate, endDate, recurrencePeriod);
    return recurrencesList;
  };

  const recurrencesList = getRecurrencesList();

  return (
    <div className="d-flex p-0 mb-15">
      <div className="d-flex flex-column">
        <div className="mt-30 mb-30 w-336">{title}</div>
        <div className="w-336 mb-4">
          <CycleDurationDropdown
            id="cycle_duration_dropdown"
            recurrencePeriod={recurrencePeriod}
            disabled={disabledCycleDuration}
            disabledCycleDurationTip={disabledCycleDurationTip}
            displayNote={recurrencePeriod && displayNote}
            onChange={handleCycleDurationChange}
            cycleDurationLabel={cycleDurationLabel}
            cycleDurationOptions={cycleDurationOptions}
          />
        </div>
        {recurrencePeriod === "Custom" && (
          <>
            <div className="w-688 mb-4">
              <ToggleCards
                disabled={disabledToggleCards}
                title={t("COMMON.LABELS.SELECT_CUSTOM_CYCLE_TYPE")}
                cards={cards}
                selected={selectedCustomCycleType === "UNIFORM" ? 0 : 1}
                handleClick={(value) => {
                  if (cycleDuration || gapDuration) {
                    setShowModal({ show: true, value });
                  } else {
                    handleCustomCycleTypeClick(value);
                    setDurationViewOnly(false);
                  }
                }}
              />
              {showModal?.show && (
                <ConfirmationModal
                  show={showModal?.show}
                  title={t("COMMON.LABELS.CHANGE_CYCLE_TYPE")}
                  body={<ModalBody />}
                  illustration={null}
                  onClose={() => {
                    setShowModal((prev) => ({
                      ...prev,
                      show: false,
                    }));
                  }}
                  onConfirm={() => {
                    handleCustomCycleTypeClick(showModal?.value);
                    setDurationViewOnly(false);
                    setShowModal((prev) => ({ ...prev, show: false }));
                  }}
                  confirmButtonText={t("COMMON.BUTTONS.YES_CHANGE_CYCLE_TYPE")}
                  cancelButton
                />
              )}
            </div>
            {selectedCustomCycleType === "UNIFORM" && (
              <div className="w-500 mb-4">
                <UniformDuration
                  totalCycles={maxCycles}
                  gapDuration={gapDuration}
                  handleSwitch={(recurrencePeriod) => {
                    handleSwitch(recurrencePeriod);
                    setDurationViewOnly(false);
                  }}
                  isDistinct={isDistinct}
                  durationInputDisabled={durationInputDisabled}
                  cycleDuration={cycleDuration}
                  durationViewOnly={durationViewOnly}
                  onGapDurationChange={onGapDurationChange}
                  onEachCycleDurationChange={onEachCycleDurationChange}
                  handleDurationModalConfirm={() => {
                    setDurationViewOnly(false);
                    handleDurationModalConfirm();
                  }}
                  selectedTemplate={selectedTemplate}
                />
              </div>
            )}
            {selectedCustomCycleType === "NONUNIFORM" && (
              <NonUniformDuration
                totalCycles={totalCycles}
                today={today}
                cycles={recurrences}
                handleSave={handleNonUniformSave}
                isDraft={isDraft}
                moduleName={moduleName}
              />
            )}
          </>
        )}
        {displayNote && <div className="w-336 mb-4">{displayNote}</div>}
        {!hideCalendar && (
          <>
            <div className="w-336 mb-4">
              <FirstCycleDropdown
                recurrencePeriod={recurrencePeriod}
                startDate={startDate}
                endDate={endDate}
                today={today}
                onChange={handleStartDateChange}
                cycleDuration={cycleDuration}
                gapDuration={gapDuration}
                disabled={disabledFirstCycleDropdown}
                disabledTip={disabledFirstCycleDropdownTip}
                label={firstCycleDropdownLabel}
                isDistinct={isDistinct}
              />
            </div>
            {recurrencePeriod !== "Infinite" && (
              <div className="w-336 mb-4">
                <LastCycleDropdown
                  recurrencePeriod={recurrencePeriod}
                  startDate={startDate}
                  endDate={endDate}
                  today={today}
                  lastCycleMinDate={lastCycleMinDate}
                  cycleDuration={cycleDuration}
                  gapDuration={gapDuration}
                  onChange={handleEndDateChange}
                  disabled={disabledLastCycleDropdown}
                  disabledTip={disabledLastCycleDropdownTip}
                  label={lastCycleDropdownLabel}
                  isDistinct={isDistinct}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="ml-5 mt-30">
        {(totalCycles > 0 ||
          (recurrenceDetailModalShow && recurrencePeriod && startDate)) && (
          <BufferDaysCycleSummary
            startDate={startDate}
            endDate={endDate}
            recurrencePeriod={recurrencePeriod}
            selectedCustomCycleType={selectedCustomCycleType}
            isDraft={isDraft}
            recurrences={recurrencesList}
            copyModalProps={copyModalProps}
            bufferDaysProps={bufferDaysProps}
            moduleName={moduleName}
          />
        )}
      </div>
    </div>
  );
};

CustomCycleRange.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  recurrencePeriod: PropTypes.string,
  title: PropTypes.node,
  isDistinct: PropTypes.bool,
  onCycleDurationChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  cycleDurationLabel: PropTypes.node,
  disabledCycleDuration: PropTypes.bool,
  displayNote: PropTypes.bool,
  firstCycleDropdownLabel: PropTypes.node,
  disabledFirstCycleDropdown: PropTypes.bool,
  lastCycleDropdownLabel: PropTypes.node,
  disabledLastCycleDropdown: PropTypes.bool,
};

export default CustomCycleRange;
