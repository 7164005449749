import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_DRILLDOWN_USER_ACHIEVEMENT_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getUserReport(
    $GoalId: ID!
    $RecurrenceId: ID
    $IsRecurrenceBased: Boolean
    $Sort: GoalContributionSort
    $directReportOnly: Boolean
    $UserId: ID
    $userAttributes: [String]
    $Query: userReportQueryInput
    $UserIds: [ID]
  ) {
    getUserReport(
      GoalId: $GoalId
      RecurrenceId: $RecurrenceId
      IsRecurrenceBased: $IsRecurrenceBased
      Sort: $Sort
      UserId: $UserId
      directReportOnly: $directReportOnly
      userAttributes: $userAttributes
      Query: $Query
      UserIds: $UserIds
    ) {
      ... on UserReportResponse {
        message
        success
        response {
          userReport
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetDrilldownUserAchievement = (queryOptions) => {
  return useQuery(GET_DRILLDOWN_USER_ACHIEVEMENT_QUERY, queryOptions);
};
