import { t } from "i18next";
import React from "react";
import Select, { components } from "react-select";
import { Shimmer, Truncate } from "src/components/common";
import { COLORS } from "src/constants";
import { SvgArrow } from "src/icons";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    paddingTop: "0px !important",
    fontSize: "13px",
    marginLeft: "-4px",
    border: "none",
    backgroundColor: "transparent",
  }),
  menu: (provided) => ({
    ...provided,
    borderTop: "1px solid #309afc",
    borderRadius: "0px !important",
    marginTop: "-1px !important",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "0px",
    backgroundColor: "transparent",
    height: "80px",
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
  singleValue: (provided) => ({
    ...provided,
    height: "20px",
    color: "#309afc",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? "#309AFC" : "",
    backgroundColor: state.isFocused ? "#F5F8FA" : "",
  }),
};

const ValueContainer = ({ children, ...props }) => {
  const { getValue } = props;

  const values = getValue();
  let valueLabel = "";

  if (values.length > 0)
    valueLabel += props.selectProps.getOptionLabel(values[0]).props.text;
  const childrenToRender = React.Children.toArray(children).filter(
    (child) =>
      ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
  );
  return (
    <components.ValueContainer {...props}>
      <div className="w-100 ml-1 fs-13 fc-grey">
        {t("RULEBOOK.LABELS.GOAL_RULE")}
      </div>
      <div className="d-inline-flex align-items-center">
        {childrenToRender}
        {!props.selectProps.inputValue && valueLabel ? (
          <div className=" fs-14 semi-bold">
            <Truncate text={valueLabel} width="200px" />
          </div>
        ) : (
          false
        )}
      </div>
    </components.ValueContainer>
  );
};

const DropdownIndicator = (props) => {
  return (
    <div className="d-flex mt-3 mr-2">
      {props.isFocused ? (
        <SvgArrow />
      ) : (
        <>
          <SvgArrow color={COLORS.GREY_2} />
        </>
      )}
    </div>
  );
};

const defaultValue = {
  RuleName: "All",
  RuleId: "",
};

const RulesDropDown = (props) => {
  const { rules, setSelectedRule, selectedRule, loading } = props;

  const rulesOptions = [defaultValue, ...rules];

  return rulesOptions.length > 2 ? (
    <div className="w-200">
      <Select
        classNamePrefix="drop-select"
        className="manager-dropdown mr-2 "
        options={rulesOptions}
        components={{ ValueContainer, DropdownIndicator }}
        value={selectedRule}
        styles={customStyles}
        onChange={setSelectedRule}
        getOptionLabel={(option) => {
          return <Truncate text={option.RuleName} width="200px" />;
        }}
      />
    </div>
  ) : (
    <div className="align-self-center fs-13 ">
      <span className="fc-grey1 flex-start mb-1">
        {t("RULEBOOK.LABELS.GOAL_RULE")}
      </span>
      {rulesOptions[1] && (
        <span className="semi-bold fs-14">
          {loading ? (
            <Shimmer width="180px" height="20px" />
          ) : (
            <Truncate text={rulesOptions[1]?.RuleName} width="180px" />
          )}
        </span>
      )}
    </div>
  );
};
export default RulesDropDown;
