import { gql, useQuery } from "src/api/client";

const GET_APPROVAL_USERS_LIST_QUERY = gql`
  query assignedApprovalsDetails(
    $programId: ID
    $status: [String]
    $search: String
    $limit: Float
    $after: String
    $FeatureType: String!
  ) {
    assignedApprovalsDetails(
      programId: $programId
      status: $status
      search: $search
      limit: $limit
      after: $after
      FeatureType: $FeatureType
    ) {
      ... on AssignedApprovalsDetailsResponse {
        success
        response {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          approvalsDetails {
            programId
            recurrenceId
            programName
            cycle
            eligibleBenefit
            approvedBenefit
            rejectedBenefit
            EligibleRewards {
              RewardId
              RewardName
              RewardCategory
              ImageUrl
            }
            ApprovedRewards {
              RewardId
              RewardName
              RewardCategory
              ImageUrl
            }
            RejectedRewards {
              RewardId
              RewardName
              RewardCategory
              ImageUrl
            }
            ApprovedRewardsCount
            RejectedRewardsCount
            EligibleRewardsCount
            Status
          }
        }
      }
    }
  }
`;

export const useAssignedApprovalsDetails = (queryOptions) => {
  return useQuery(GET_APPROVAL_USERS_LIST_QUERY, queryOptions);
};
