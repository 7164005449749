import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgInfo = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="ld33c_a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#ld33c_a)" data-name="Group 7896">
        <g data-name="Group 7076">
          <g data-name="Group 6901" transform="translate(1 1)">
            <circle
              cx={16}
              cy={16}
              r={16}
              fill={backgroundColor}
              data-name="Ellipse 152"
              transform="translate(-1 -1)"
            />
          </g>
        </g>
        <g
          stroke={color}
          data-name="Group 7081"
          transform="translate(8.929 10.007)"
        >
          <circle
            cx={7}
            cy={7}
            r={7}
            fill="none"
            strokeWidth={1.5}
            data-name="Ellipse 146"
            transform="translate(.071 -1.007)"
          />
          <path
            fill={color}
            strokeWidth={0.4}
            d="M7.573 3.255a.514.514 0 0 1-.158.377.514.514 0 0 1-.377.158.5.5 0 0 1-.372-.158.521.521 0 0 1-.154-.377.521.521 0 0 1 .154-.377.5.5 0 0 1 .372-.158.517.517 0 0 1 .381.154.517.517 0 0 1 .154.381Zm.633 5.225v.787h-2.27V8.48h.7V5.561h-.7v-.788h1.586v3.71Z"
            data-name="Path 4004"
          />
        </g>
      </g>
    </svg>
  );
};

SvgInfo.defaultProps = {
  backgroundColor: "#e5f2ff",
  color: COLORS.PRIMARY_BLUE,
  height: 32,
  width: 32,
};
export default SvgInfo;
