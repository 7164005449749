import { gql, useQuery } from "src/api/client";
import { useIsOpenAceUser } from "src/hooks";

export const GET_SALES_ACTIVITY_STATUS = gql`
  query getSalesActivityStatus {
    getSalesActivityStatus {
      RetrictUsersFromNewActivity
      RestrictCompleteAccess
    }
  }
`;
export const useSalesActivityStatus = (queryOptions) => {
  const { isOpenAceUser } = useIsOpenAceUser();
  return useQuery(GET_SALES_ACTIVITY_STATUS, {
    ...queryOptions,
    skip: queryOptions?.skip || isOpenAceUser,
  });
};
