import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Shimmer } from "src/components/common/atoms";
import AceGrid from "src/components/common/organisms/aceGrid/AceGrid";
import { range } from "src/helpers";

const RowShimmer = () => (
  <div className="py-2 px-4 w-100">
    <Shimmer width="100%" height="10px" />
  </div>
);

const HeaderShimmer = () => (
  <div className="py-1 px-4 w-100">
    <Shimmer width="50%" height="10px" />
  </div>
);

const LoadingTable = (props) => {
  const { columns, rows, height } = props;
  const getColumns = useMemo(() => {
    const column = [];
    range(0, columns - 1).forEach((index) => {
      column.push({
        Header: <HeaderShimmer />,
        accessor: `dummy${index}`,
        width: 200,
        Cell: () => <RowShimmer />,
      });
    });
    return column;
  }, [columns]);

  return (
    <AceGrid
      className="simple-grid"
      columns={getColumns}
      data={range(0, rows - 1)}
      sorting={false}
      height={height}
    />
  );
};

LoadingTable.propTypes = {
  columns: PropTypes.number,
  rows: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

LoadingTable.defaultProps = {
  columns: 4,
  rows: 4,
  height: "auto",
};

export default LoadingTable;
