import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import {
  useBlockLayout,
  useColumnOrder,
  useExpanded,
  useFilters,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { classNames } from "src/helpers";
import { SvgSortAsc, SvgSortBoth } from "src/icons";
import { NoUsers } from "src/images";
import "./aceGrid.scss";

const AceGrid = forwardRef((props, ref) => {
  const {
    data,
    columns,
    className,
    desc,
    defaultSortBy,
    sorting,
    id,
    height,
    updateMyData,
    showModalConfirmation,
    userNotFound,
    noResultsIcon,
    allCellsStyles,
    rowStyles = (props) => ({}),
    hiddenColumns,
    showElementOnHover = false,
    hoverElementClassName,
    customFunctions,
    customVariables,
    noResultsIconClassName,
  } = props;
  const instance = useTable(
    {
      columns,
      data,
      disableSortBy: !sorting,
      disableSortRemove: true,
      initialState: {
        sortBy: sorting ? [{ id: defaultSortBy, desc }] : [],
        hiddenColumns,
      },
      autoResetExpanded: false,
      updateMyData,
      showModalConfirmation,
      ...customFunctions,
      ...customVariables,
    },
    useFilters,
    useSortBy,
    useExpanded,
    useColumnOrder,
    useSticky,
    useBlockLayout,
    useRowSelect
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="position-relative">
      {rows?.length > 0 ? (
        <>
          <div id={id} className="ace-grid">
            <table
              {...getTableProps()}
              className={`${className || ""} table sticky`}
              style={{ maxHeight: height }}
              data-testid="table"
              ref={ref}
            >
              <thead className="thead semi-bold">
                {headerGroups?.map((headerGroup, idx) => (
                  <tr
                    key={idx}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr fs-13 fc-grey"
                  >
                    {headerGroup?.headers?.map((column, idx) => (
                      <th
                        key={idx}
                        {...column.getHeaderProps(
                          column?.getSortByToggleProps()
                        )}
                        className="th"
                        data-testid="th"
                        tabIndex={0}
                      >
                        {column.render("Header")}
                        {sorting && (
                          <span>
                            {column.isSorted ? (
                              column?.isSortedDesc ? (
                                <SvgSortAsc />
                              ) : (
                                <SvgSortAsc rotate={180} />
                              )
                            ) : (
                              <span className="sort-both">
                                <SvgSortBoth />
                              </span>
                            )}
                          </span>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()} className="tbody">
                {rows?.map((row, idx) => {
                  prepareRow(row);

                  return (
                    <tr
                      key={idx}
                      {...{
                        ...row.getRowProps({
                          style: rowStyles && rowStyles(row),
                        }),
                      }}
                      data-testid="tr"
                      className={classNames([
                        "tr fs-13 fc-black",
                        showElementOnHover && hoverElementClassName,
                      ])}
                    >
                      {row?.cells?.map((cell, idx) => {
                        const column = cell?.column;
                        if (column?.show && !column?.show(row)) return null;

                        return (
                          <td
                            key={idx}
                            data-testid="td"
                            className={classNames([
                              "td",
                              column?.className || "",
                            ])}
                            {...cell?.getCellProps({
                              style: allCellsStyles
                                ? allCellsStyles({ cell, row })
                                : column.style
                                ? column.style({ cell, row })
                                : {},
                            })}
                          >
                            {cell?.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div
          className={classNames([
            "flex-center flex-column h-100",
            noResultsIconClassName ? noResultsIconClassName : "",
          ])}
        >
          {noResultsIcon || <NoUsers />}
          <div className="fc-grey mt-3">{userNotFound}</div>
        </div>
      )}
    </div>
  );
});

AceGrid.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  className: PropTypes.string,
  desc: PropTypes.bool,
  defaultSortBy: PropTypes.string,
  sorting: PropTypes.bool,
  fieldsOrder: PropTypes.array,
  setFieldsOrder: PropTypes.func,
  height: PropTypes.string,
  usersAttributeList: PropTypes.array,
  handleDoneButton: PropTypes.func,
  hiddenColumns: PropTypes.array,
};

AceGrid.defaultProps = {
  desc: false,
  defaultSortBy: "FullName",
  sorting: true,
  id: "",
  handleDoneButton: () => {},
  hiddenColumns: [],
};

export default AceGrid;
