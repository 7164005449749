import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const CREATE_INCENTIVE_PROGRAM_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation createProgram($Name: NotEmpty!, $Description: String) {
    createProgram(Name: $Name, Description: $Description) {
      __typename
      ... on SingleProgramResponse {
        response {
          Program {
            ProgramId
          }
        }
        message
        success
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useCreateIncentiveProgram = () => {
  return useMutation(CREATE_INCENTIVE_PROGRAM_MUTATION);
};

const CREATE_PROGRAM_FILE_UPLOAD_URL = gql`
  mutation createProgramFileUploadUrl($ProgramId: ID!) {
    createProgramFileUploadUrl(ProgramId: $ProgramId) {
      ... on ProgramUrlResponse {
        message
        response {
          url
          fileId
        }
      }
    }
  }
`;

export const useCreateProgramFileUploadURL = () => {
  return useMutation(CREATE_PROGRAM_FILE_UPLOAD_URL);
};
