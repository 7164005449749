import { Shimmer } from "src/components/common/atoms";
import { range } from "src/helpers";

const LoadingPayoutViewList = () => {
  return (
    <>
      {range(0, 3).map((idx) => (
        <div key={idx} className="py-3 mr-3 border-bottom-1 bc-grey3">
          <Shimmer width="70px" height="8px" />
          <div className="my-2">
            <Shimmer width="120px" height="8px" />
          </div>
          <div className="center w-100">
            <div>
              <Shimmer width="100px" height="8px" />
              <div className="my-2">
                <Shimmer width="70px" height="8px" />
              </div>
            </div>
            <div className="ml-auto">
              <Shimmer width="100px" height="8px" />
              <div className="my-2">
                <Shimmer width="70px" height="8px" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default LoadingPayoutViewList;
