import { t } from "i18next";
import React from "react";
import { COLORS } from "src/constants";
import { SvgDraft, SvgTick, SvgUnpublish } from "src/icons";
import "./rulesetStatusBadge.scss";

const getRulesetStatusBadgeProps = (status) => {
  switch (status) {
    case "PUBLISHED": {
      return {
        icon: <SvgTick width={12} height={12} backgroundColor="#2ECC71" />,
        text: t("COMMON.LABELS.PUBLISHED"),
        backgroundColor: "#EDFEED",
        color: "#2EBC6A",
      };
    }
    case "UNPUBLISHED": {
      return {
        icon: <SvgUnpublish width={12} height={12} color={COLORS.GREY_2} />,
        text: t("COMMON.LABELS.UNPUBLISHED"),
        backgroundColor: COLORS.GREY_5,
        color: COLORS.GREY_1,
      };
    }
    default: {
      return {
        icon: <SvgDraft width={12} height={12} color={COLORS.GREY_2} />,
        text: t("MANAGED_GOALS.LABELS.DRAFT"),
        backgroundColor: COLORS.GREY_5,
        color: COLORS.GREY_2,
      };
    }
  }
};

const RulesetStatusBadge = ({ status }) => {
  const { icon, text, backgroundColor, color } =
    getRulesetStatusBadgeProps(status);

  return (
    <span
      className="br-4 center ruleset-status-badge"
      style={{ backgroundColor }}
    >
      {icon}
      <span style={{ color }} className="ml-1 fs-12 semi-bold">
        {text}
      </span>
    </span>
  );
};

export default RulesetStatusBadge;
