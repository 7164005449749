import { gql, useMutation } from "src/api/client";

const APPROVE_SINGLE_PROGRAM_MUTATION = gql`
  mutation approveSingleProgram(
    $programId: ID
    $recurrenceId: ID
    $userId: ID
    $approverId: ID
    $approvedAmount: Float
    $fileName: FileName
    $fileId: Float
    $remarks: String
    $approvedRewards: [ID]
    $action: ApprovalActionEnum
  ) {
    approveSingleProgram(
      programId: $programId
      recurrenceId: $recurrenceId
      userId: $userId
      approverId: $approverId
      approvedAmount: $approvedAmount
      approvedRewards: $approvedRewards
      fileName: $fileName
      fileId: $fileId
      remarks: $remarks
      action: $action
    ) {
      response
    }
  }
`;

export const useApproveSingleProgram = () => {
  return useMutation(APPROVE_SINGLE_PROGRAM_MUTATION);
};
