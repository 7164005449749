import PropTypes from "prop-types";
import React from "react";
import { classNames } from "src/helpers";
import { BlueBanner } from "src/images";
import "./heroBackground.scss";

const HeroBackground = (props) => {
  const { image, imgStyle, className, children } = props;
  const Image = image || BlueBanner;
  return (
    <div className={classNames(["hero-background", className])}>
      <Image className="hero-background-img" style={imgStyle} />
      {children}
    </div>
  );
};

HeroBackground.propTypes = {
  image: PropTypes.node.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

HeroBackground.defaultProps = {
  image: BlueBanner,
  className: "",
};

export default HeroBackground;
