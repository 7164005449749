import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgRejected = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="ls33_da">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#ls33_da)" data-name="Group 12981">
        <g data-name="Group 12814">
          <g
            fill={backgroundColor}
            stroke={color}
            strokeWidth={2}
            data-name="Ellipse 193"
          >
            <circle cx={12} cy={12} r={12} stroke="none" />
            <circle cx={12} cy={12} r={11} fill="none" />
          </g>
        </g>
        <path
          fill={color}
          fillRule="evenodd"
          stroke={color}
          d="m16 8.8-.8-.8-3.2 3.2L8.8 8l-.8.8 3.2 3.2L8 15.2l.8.8 3.2-3.2 3.2 3.2.8-.8-3.2-3.2Z"
          data-name="close"
        />
      </g>
    </svg>
  );
};

SvgRejected.defaultProps = {
  backgroundColor: "#ffebeb",
  color: COLORS.RED,
  height: 24,
  width: 24,
};
export default SvgRejected;
