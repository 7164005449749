import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import "./loader.scss";

const Loader = ({ loaderText }) => (
  <Modal show centered dialogClassName="spinner-modal">
    <div className="bg-secondary-blue br-8 fc-grey fs-18 flex-center semi-bold py-4">
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        className="mr-2 spinner-background"
      />
      {loaderText}
    </div>
  </Modal>
);

export default Loader;
