import { useQuery } from "react-query";
import { USER_PROFILE_URL } from "src/constants";
import { useIsLoggedIn } from "src/hooks";
import useAxiosPrivate from "./useAxiosPrivate";

export const useGetUserProfile = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const axiosPrivate = useAxiosPrivate();

  const getUserProfile = async () => {
    try {
      const response = await axiosPrivate.get(USER_PROFILE_URL);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const queryData = useQuery({
    queryKey: ["userProfile"],
    queryFn: () => getUserProfile(),
    enabled: isLoggedIn,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return queryData;
};
