import React from "react";

const SvgAvatar18 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="aey7__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .aey7__cls-1{fill:#fff}.aey7__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="aey7__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="aey7__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.085)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="aey7__cls-2"
        />
        <path
          d="M124.59 74.66c-.82-.51-1.77-1.11-2.81-1.79a1.472 1.472 0 0 0-2.36 1.17v17.22a7.218 7.218 0 0 0-4.34-1.43c-1.94 0-3.77.76-5.14 2.13-1.37 1.38-2.13 3.2-2.13 5.14s.76 3.77 2.13 5.14c1.37 1.37 3.2 2.13 5.14 2.13s3.77-.76 5.14-2.13a7.232 7.232 0 0 0 2.13-5.14V76.73l.72.45c3.37 2.1 3.5 2.18 1.8 5.19-.19.34-.24.73-.14 1.11.1.38.35.69.69.88.22.12.47.19.72.19.53 0 1.01-.28 1.27-.74 3-5.29 2.16-6.04-2.82-9.14Zm-5.17 22.42c0 1.16-.45 2.25-1.27 3.07-.82.82-1.91 1.27-3.07 1.27s-2.25-.45-3.07-1.27c-.82-.82-1.27-1.91-1.27-3.07s.45-2.25 1.27-3.07c.82-.82 1.91-1.27 3.07-1.27s2.15.41 3.11 1.31c.79.81 1.23 1.89 1.23 3.03ZM147.85 67.99l-.09-.05c-.82-.51-1.77-1.1-2.73-1.74-.26-.21-.58-.32-.91-.32-.81 0-1.47.66-1.47 1.46v17.22a7.218 7.218 0 0 0-4.34-1.43c-1.94 0-3.77.76-5.14 2.13-1.37 1.38-2.13 3.2-2.13 5.14s.76 3.77 2.13 5.14c1.38 1.37 3.2 2.13 5.14 2.13s3.57-.67 5.14-2.13a7.232 7.232 0 0 0 2.13-5.14V70.03l.72.45c3.37 2.1 3.5 2.18 1.79 5.19-.4.7-.15 1.6.55 2 .22.12.47.19.72.19.53 0 1.01-.28 1.27-.74 3-5.29 2.16-6.04-2.79-9.12Zm-12.6 19.34c.82-.82 1.91-1.27 3.07-1.27s2.15.41 3.11 1.31c.79.81 1.23 1.89 1.23 3.03s-.41 2.15-1.31 3.11a4.346 4.346 0 0 1-6.1-.04c-.82-.82-1.27-1.91-1.27-3.07s.45-2.25 1.27-3.07ZM96.58 93.68c-3.16 0-5.74-2.57-5.74-5.74a5.74 5.74 0 1 1 5.74 5.74Zm0-8.47c-1.51 0-2.74 1.23-2.74 2.74s1.23 2.74 2.74 2.74 2.74-1.23 2.74-2.74-1.23-2.74-2.74-2.74Z"
          className="aey7__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar18.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar18;
