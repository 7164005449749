import React from "react";

const SvgAvatar11 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="mgrj__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 165.42"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .mgrj__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="mgrj__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08a81.128 81.128 0 0 1-18.79 13.43c-.81.43-1.63.83-2.46 1.23a80.46 80.46 0 0 1-26.28 7.39c-.81.09-1.63.17-2.45.22-1.56.13-3.14.2-4.73.23-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-1.58-.03-3.16-.1-4.72-.23-.82-.05-1.64-.13-2.46-.22a80.472 80.472 0 0 1-26.27-7.39c-.83-.39-1.65-.8-2.46-1.23a81.243 81.243 0 0 1-18.81-13.43c-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="mgrj__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.41 72.08)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="mgrj__cls-2"
        />
        <path
          d="M77.14 124.22c0-1.52-1.03-2.8-2.44-3.17-.1-1.99-.54-3.93-1.31-5.76-.85-2.02-2.08-3.83-3.63-5.39a16.8 16.8 0 0 0-5.39-3.63c-.91-.39-1.86-.69-2.81-.91l.04-7.88c0-.66.44-1.23 1.07-1.4h.03l6.84-2.12c.82-.22 1.51-.75 1.94-1.48a3.242 3.242 0 0 0-1.15-4.45 3.26 3.26 0 0 0-2.46-.35h-.02l-8.95 2.57a5.535 5.535 0 0 0-4.3 5.4v9.59c-1.16.22-2.3.57-3.4 1.03-2.02.85-3.83 2.08-5.39 3.63a16.8 16.8 0 0 0-3.63 5.39c-.78 1.83-1.22 3.77-1.31 5.76-1.4.38-2.44 1.66-2.44 3.17 0 1.63 1.2 2.99 2.76 3.24l2.31 34.62c.06 1.87 1.31 3.33 2.87 3.33H69.3c1.51-.07 2.71-1.52 2.75-3.37v-.06l2.3-34.51c1.59-.23 2.82-1.6 2.82-3.25ZM56.47 95.65c0-1.7 1.2-3.2 2.86-3.56.02 0 .03 0 .05-.01l8.95-2.57c.35-.09.71-.04 1.02.15a1.37 1.37 0 0 1 .48 1.86c-.18.31-.48.53-.83.62h-.04l-6.84 2.12a3.351 3.351 0 0 0-2.43 3.21s-.03 7.57-.03 7.57c-.62-.07-1.26-.11-1.89-.11-.44 0-.87.02-1.3.05v-9.34ZM55.5 107h.03c.1 0 .2-.02.29-.05.64-.08 1.29-.13 1.96-.13 7.98 0 14.54 6.25 15.03 14.11H71.2c-.16-3.26-1.67-6.3-4.2-8.4-.4-.33-1-.28-1.33.12-.33.4-.28 1 .12 1.33a9.684 9.684 0 0 1 3.51 6.94H42.75c.44-7.1 5.83-12.89 12.75-13.94Zm-15.2 17.22c0-.77.62-1.39 1.39-1.39h32.16c.77 0 1.39.62 1.39 1.39s-.62 1.39-1.39 1.39H41.69c-.77 0-1.39-.62-1.39-1.39Zm5.06 37.76v-.02l-2.29-34.46h29.35l-2.29 34.46v.13c-.05.86-.53 1.44-.97 1.44H46.35c-.45 0-.95-.61-.98-1.52v-.02Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar11.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar11;
