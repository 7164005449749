import React from "react";
import { Image } from "src/components";
import BronzeDailyImg from "./BronzeDaily.svg";
import BronzeDailyWithoutRankImg from "./BronzeDailyWithoutRank.svg";
import BronzeMonthlyImg from "./BronzeMonthly.svg";
import BronzeMonthlyWithoutRankImg from "./BronzeMonthlyWithoutRank.svg";
import BronzeQuarterlyImg from "./BronzeQuarterly.svg";
import BronzeWeeklyImg from "./BronzeWeekly.svg";
import BronzeWeeklyWithoutRankImg from "./BronzeWeeklyWithoutRank.svg";
import BronzeYearlyImg from "./BronzeYearly.svg";
import BronzeYearlyWithoutRankImg from "./BronzeYearlyWithoutRank.svg";

export const BronzeDaily = (props) => (
  <Image src={BronzeDailyImg} alt="BronzeDailyImg" {...props} />
);

export const BronzeWeekly = (props) => (
  <Image src={BronzeWeeklyImg} alt="BronzeWeeklyImg" {...props} />
);

export const BronzeMonthly = (props) => (
  <Image src={BronzeMonthlyImg} alt="BronzeMonthlyImg" {...props} />
);

export const BronzeQuarterly = (props) => (
  <Image src={BronzeQuarterlyImg} alt="BronzeQuarterlyImg" {...props} />
);

export const BronzeYearly = (props) => (
  <Image src={BronzeYearlyImg} alt="BronzeYearlyImg" {...props} />
);

export const BronzeDailyWithoutRank = (props) => (
  <Image
    src={BronzeDailyWithoutRankImg}
    alt="BronzeDailyWithoutRankImg"
    {...props}
  />
);

export const BronzeWeeklyWithoutRank = (props) => (
  <Image
    src={BronzeWeeklyWithoutRankImg}
    alt="BronzeWeeklyWithoutRankImg"
    {...props}
  />
);

export const BronzeMonthlyWithoutRank = (props) => (
  <Image
    src={BronzeMonthlyWithoutRankImg}
    alt="BronzeMonthlyWithoutRankImg"
    {...props}
  />
);

export const BronzeQuarterlyWithoutRank = (props) => (
  <Image
    src={BronzeQuarterlyImg}
    alt="BronzeQuarterlyWithoutRankImg"
    {...props}
  />
);

export const BronzeYearlyWithoutRank = (props) => (
  <Image
    src={BronzeYearlyWithoutRankImg}
    alt="BronzeYearlyWithoutRankImg"
    {...props}
  />
);
