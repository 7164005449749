import React from "react";
const SvgArrowBlue = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 46 46"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M43.665 7.418a.75.75 0 1 0 .67-1.342zM22.844 18.622l-.473.582zM5.438 42.572a.75.75 0 0 0 1.058.066l5.06-4.468a.75.75 0 1 0-.992-1.124l-4.498 3.971-3.971-4.498a.75.75 0 0 0-1.125.993zM44.335 6.076C37.02 2.42 28.021 2.55 22.557 5.103c-2.727 1.274-4.753 3.247-5.011 5.828-.258 2.575 1.284 5.395 4.825 8.273l.946-1.163c-3.397-2.763-4.46-5.138-4.279-6.96.182-1.817 1.64-3.444 4.154-4.62 5.021-2.345 13.538-2.51 20.473.957zM22.371 19.204c2.519 2.048 4.297 3.14 5.456 3.492.538.163 1.22.25 1.72-.167.536-.445.49-1.13.386-1.57-.223-.944-1.018-2.175-2.081-3.339-1.087-1.189-2.538-2.402-4.195-3.284-1.655-.88-3.555-1.452-5.516-1.294-1.98.16-3.951 1.059-5.728 2.99-3.505 3.81-6.27 11.646-7.162 25.997l1.498.093c.888-14.297 3.636-21.671 6.768-25.074 1.541-1.676 3.17-2.383 4.745-2.51 1.594-.13 3.205.332 4.69 1.123 1.484.79 2.803 1.888 3.792 2.97 1.012 1.108 1.596 2.108 1.73 2.674.074.316-.048.206.114.071.13-.107.141.026-.326-.116-.852-.258-2.433-1.177-4.945-3.22z"
      />
    </svg>
  );
};
SvgArrowBlue.defaultProps = {
  color: "#309AFC",
  height: 46,
  width: 46,
};
export default SvgArrowBlue;
