import React from "react";
import { classNames } from "src/helpers";
import "./glassBanner.scss";

const GlassBanner = (props) => {
  const { className, children, ...rest } = props;
  return (
    <div className={classNames(["br-8 glass-banner", className])} {...rest}>
      {children}
    </div>
  );
};

export default GlassBanner;
