import { gql, useQuery } from "src/api/client";

export const GET_GOAL_USERS_COUNT = gql`
  query getGoalUsersCount($Recurrences: [ID]!) {
    getGoalUsersCount(Recurrences: $Recurrences) {
      ... on GoalUsersCountResponse {
        success
        message
        response
      }
    }
  }
`;

export const useGetGoalUsersCount = (queryOptions) => {
  return useQuery(GET_GOAL_USERS_COUNT, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
