import PropTypes from "prop-types";
import React, { useState } from "react";
import { CycleDurationDropdown } from "src/components/common/atoms";
import {
  BufferDaysCycleSummary,
  FirstCycleDropdown,
  LastCycleDropdown,
} from "src/components/common/organisms";
import {
  CYCLES_TYPES,
  getRecurrences,
  getTodayAsPerTimezone,
} from "src/helpers";

const CycleRange = (props) => {
  const {
    startDate,
    endDate,
    lastCycleMinDate,
    recurrencePeriod,
    title,
    isDistinct,
    onCycleDurationChange,
    onStartDateChange,
    onEndDateChange,
    cycleDurationLabel,
    disabledCycleDuration,
    disabledCycleDurationTip,
    displayNote,
    firstCycleDropdownLabel,
    disabledFirstCycleDropdown,
    disabledFirstCycleDropdownTip,
    lastCycleDropdownLabel,
    disabledLastCycleDropdown,
    disabledLastCycleDropdownTip,
    isDraft,
    bufferDaysProps,
    copyModalProps,
    isIncentive = true,
    cycleDurationOptions,
    moduleName,
  } = props;

  const today = getTodayAsPerTimezone();
  const [recurrenceDetailModalShow, setRecurrenceDetailModalShow] =
    useState(true);

  const handleCycleDurationChange = (value) => {
    if (onCycleDurationChange) {
      onCycleDurationChange(value);
      setRecurrenceDetailModalShow(false);
    }
  };

  const handleStartDateChange = (date) => {
    if (onStartDateChange) {
      onStartDateChange(date);
      setRecurrenceDetailModalShow(true);
    }
  };

  const handleEndDateChange = (date) => {
    if (onEndDateChange) {
      onEndDateChange(
        CYCLES_TYPES[recurrencePeriod]?.setEndDate({
          startDate,
          date,
        })
      );
    }
  };

  const getRecurrencesList = () => {
    let recurrences;
    if (startDate && !endDate)
      recurrences = getRecurrences(
        startDate,
        CYCLES_TYPES[recurrencePeriod]?.setEndDate({
          startDate,
          date: startDate,
        }),
        recurrencePeriod
      );
    else recurrences = getRecurrences(startDate, endDate, recurrencePeriod);

    return recurrences;
  };

  const recurrences = getRecurrencesList();

  return (
    <div className="d-flex p-0 mb-15">
      <div className="d-flex flex-column">
        <div className="mt-30 mb-30 w-336">{title}</div>
        <div className="d-flex">
          <div className="w-336">
            <div className="mb-4">
              <div className="pl-0 w-336">
                <CycleDurationDropdown
                  id="cycle_duration_dropdown"
                  recurrencePeriod={recurrencePeriod}
                  disabled={disabledCycleDuration}
                  disabledCycleDurationTip={disabledCycleDurationTip}
                  displayNote={recurrencePeriod && displayNote}
                  onChange={handleCycleDurationChange}
                  cycleDurationLabel={cycleDurationLabel}
                  cycleDurationOptions={cycleDurationOptions}
                />
              </div>
            </div>
            <div className="mb-4">{displayNote && displayNote}</div>
            <div className="mb-4">
              <FirstCycleDropdown
                recurrencePeriod={recurrencePeriod}
                startDate={startDate}
                endDate={endDate}
                today={today}
                onChange={handleStartDateChange}
                disabled={disabledFirstCycleDropdown}
                disabledTip={disabledFirstCycleDropdownTip}
                label={firstCycleDropdownLabel}
                isDistinct={isDistinct}
                isIncentive={isIncentive}
              />
            </div>
            {recurrencePeriod !== "Infinite" && (
              <div className="mb-4">
                <LastCycleDropdown
                  recurrencePeriod={recurrencePeriod}
                  startDate={startDate}
                  endDate={endDate}
                  today={today}
                  lastCycleMinDate={lastCycleMinDate}
                  onChange={handleEndDateChange}
                  disabled={disabledLastCycleDropdown}
                  disabledTip={disabledLastCycleDropdownTip}
                  label={lastCycleDropdownLabel}
                  isDistinct={isDistinct}
                  isIncentive={isIncentive}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="ml-5 mt-30">
        {recurrenceDetailModalShow && recurrencePeriod && startDate && (
          <BufferDaysCycleSummary
            recurrencePeriod={recurrencePeriod}
            isDraft={isDraft}
            recurrences={recurrences}
            copyModalProps={copyModalProps}
            bufferDaysProps={bufferDaysProps}
            moduleName={moduleName}
          />
        )}
      </div>
    </div>
  );
};

CycleRange.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  recurrencePeriod: PropTypes.string,
  title: PropTypes.node,
  isDistinct: PropTypes.bool,
  onCycleDurationChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  cycleDurationLabel: PropTypes.node,
  disabledCycleDuration: PropTypes.bool,
  displayNote: PropTypes.bool,
  firstCycleDropdownLabel: PropTypes.node,
  disabledFirstCycleDropdown: PropTypes.bool,
  lastCycleDropdownLabel: PropTypes.node,
  disabledLastCycleDropdown: PropTypes.bool,
};

export default CycleRange;
