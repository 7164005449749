import { gql, useMutation } from "src/api/client";

const DOWNLOAD_PAYOUT_RAW_EXCEL_MUTATION = gql`
  mutation downloadPayoutRawExcel(
    $programId: ID
    $timeFrequency: TimeFrequency
    $FeatureType: String!
  ) {
    downloadPayoutRawExcel(
      programId: $programId
      timeFrequency: $timeFrequency
      FeatureType: $FeatureType
    ) {
      ... on PayoutUrlResponse {
        success
        message
        response {
          url
          fileId
        }
      }
    }
  }
`;

export const useDownloadPayoutRawExcel = () => {
  return useMutation(DOWNLOAD_PAYOUT_RAW_EXCEL_MUTATION);
};
