import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgRefresh = (props) => {
  const { color, height, width, rotate, styles, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 10"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...styles,
        transform: `rotate(${rotate}deg)`,
        transformOrigin: "50% 50%",
      }}
      {...rest}
    >
      <path
        fill={color}
        d="M11.78 3.72a.75.75 0 0 0-1.06 0l-.327.326A4.504 4.504 0 0 0 6 .5a4.444 4.444 0 0 0-2.703.92.75.75 0 0 0 .905 1.195A2.958 2.958 0 0 1 6 2a2.995 2.995 0 0 1 2.834 2.06l-.496-.396a.75.75 0 1 0-.935 1.172l1.88 1.5a.749.749 0 0 0 .997-.056l1.5-1.5a.749.749 0 0 0 0-1.06ZM7.798 7.385A2.958 2.958 0 0 1 6 8a2.995 2.995 0 0 1-2.834-2.06l.496.396a.75.75 0 1 0 .935-1.172l-1.88-1.5a.75.75 0 0 0-.997.056l-1.5 1.5a.75.75 0 0 0 1.06 1.06l.327-.326A4.504 4.504 0 0 0 6 9.5a4.444 4.444 0 0 0 2.703-.92.75.75 0 1 0-.905-1.195Z"
      />
    </svg>
  );
};
SvgRefresh.defaultProps = {
  color: COLORS.GREY_2,
  height: 10,
  width: 12,
};
export default SvgRefresh;
