import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'leaderboards.web.myBoardsPage' */ "./web")
);
const MobilePage = lazy(() =>
  import(/* webpackChunkName: 'leaderboards.mobile.myBoardsPage' */ "./mobile")
);

export const MyBoardsPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "My Boards",
  },
};
