import { gql, useMutation } from "src/api/client";

const TOGGLE_GOAL_EXPORT = gql`
  mutation toggleGoalExport(
    $GoalId: ID!
    $UserId: ID
    $EnableExport: Boolean!
  ) {
    toggleGoalExport(
      GoalId: $GoalId
      UserId: $UserId
      EnableExport: $EnableExport
    ) {
      message
    }
  }
`;

export const useToggleGoalExport = (queryDetails) => {
  return useMutation(TOGGLE_GOAL_EXPORT, queryDetails);
};
