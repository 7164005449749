import React from "react";
import { Image } from "src/components";
import ApprovalRequestMobileImg from "./ApprovalRequestMobile.png";

const ApprovalRequestMobile = (props) => (
  <Image
    src={ApprovalRequestMobileImg}
    alt="ApprovalRequestMobileImg"
    {...props}
  />
);

export default ApprovalRequestMobile;
