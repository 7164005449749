import React from "react";
import { COLORS } from "src/constants/generalConstants";
const SvgSubGoal = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
      >
        <path d="M4 5h5M3 2h1.572M3.788 2v8.643a.786.786 0 0 0 .786.786H9M12.509 3.573H9.562c-.27 0-.49.352-.49.786v1.572c0 .434.22.785.49.785h2.947c.271 0 .491-.351.491-.785V4.359c0-.434-.22-.786-.491-.786M12.509 9.859H9.562c-.27 0-.49.351-.49.785v1.572c0 .434.22.786.49.786h2.947c.271 0 .491-.352.491-.786v-1.572c0-.434-.22-.785-.491-.785" />
      </g>
    </svg>
  );
};
SvgSubGoal.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 16,
  width: 16,
};
export default SvgSubGoal;
