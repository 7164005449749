import { t } from "i18next";
import React from "react";
import { useGetSystemConfigDetails } from "src/api/useSystem";
import { Shimmer } from "src/components/common/atoms";
import { useRoleBasedContext } from "src/context";
import { RulesetCircularProgressBar } from "../rulesetCircularProgressBar";

const RulesetLicenseHeader = () => {
  const { maxAllowedGoalRules } = useRoleBasedContext();
  const { data: configDetails, loading } = useGetSystemConfigDetails({
    fetchPolicy: "network-only",
  });

  const currentRulesCount =
    configDetails?.getSystemConfigDetails?.goalRulesCount;

  const percentage = currentRulesCount
    ? parseInt((currentRulesCount / maxAllowedGoalRules) * 100)
    : 0;

  if (loading) return <Shimmer width="100%" />;

  return (
    <>
      <RulesetCircularProgressBar percentage={percentage} />
      <div className="ml-2 fs-14 fc-grey1">
        {currentRulesCount}/{maxAllowedGoalRules}{" "}
        {t("RULEBOOK.MESSAGES.RULES_PUBLISHED")}
      </div>
    </>
  );
};

export default RulesetLicenseHeader;
