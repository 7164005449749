import PropTypes from "prop-types";
import React from "react";
import LoadingTimeline from "./LoadingTimeline";
import { data, getContent, lookup } from "./data";
import "./timeline.scss";

const Timeline = ({
  loading,
  data,
  payloadForRewardsList,
  getContent,
  lookup,
}) => {
  if (loading) {
    return <LoadingTimeline />;
  }
  return (
    <>
      {data?.map((item, idx) => {
        const iconObject = lookup[item.status];
        return (
          <div key={`${item.status}_${idx}`} style={{ position: "relative" }}>
            <div className="d-flex br-4">
              <div className="d-flex flex-column align-items-center">
                <div className={item.status === "pending" ? "mt-3" : ""}>
                  {iconObject?.icon}
                </div>
                {item.status === "pending" && (
                  <div className="ml-2 bg-blue rounded-left timeline-pending-background" />
                )}
                <div
                  className={
                    idx === data.length - 1
                      ? "timeline-hide-divider"
                      : "timeline-divider"
                  }
                />
              </div>
              <div className="ml-3 fs-11 w-100 timeline-data-container">
                {getContent(item, payloadForRewardsList)}
                <hr className="mb-3" />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
Timeline.propTypes = {
  data: PropTypes.array,
  getContent: PropTypes.func,
  lookup: PropTypes.shape({}),
  isPayoutHistory: PropTypes.bool,
};

Timeline.defaultProps = {
  getContent,
  data,
  lookup,
  isPayoutHistory: false,
};

export default Timeline;
