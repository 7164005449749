import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgPendingAmount = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <g>
        <path fill="none" d="M0 0h20v20H0z" />
        <g>
          <path
            fill={color}
            d="M9.13 14.069a5.437 5.437 0 1 1 5.437 5.436 5.437 5.437 0 0 1-5.437-5.436Zm1.209 0a4.229 4.229 0 1 0 4.228-4.229 4.233 4.233 0 0 0-4.228 4.229Zm3.778 2.507v-.255a1.388 1.388 0 0 1-.941-1.309.45.45 0 1 1 .9 0 .493.493 0 1 0 .492-.494 1.388 1.388 0 0 1-.449-2.7v-.255a.45.45 0 0 1 .9 0v.255a1.39 1.39 0 0 1 .942 1.31.45.45 0 1 1-.9 0 .493.493 0 1 0-.494.494 1.387 1.387 0 0 1 .449 2.7v.255a.45.45 0 1 1-.9 0ZM.002 8.634a8.114 8.114 0 0 1 1.275-4.378.543.543 0 0 1 .914.585 7.054 7.054 0 0 0 5.948 10.847c.2 0 .4-.009.6-.025a6.092 6.092 0 0 0 .378 1.052 8.3 8.3 0 0 1-.976.059 8.149 8.149 0 0 1-8.139-8.14Zm2.171 0a5.969 5.969 0 0 1 11.919-.448 5.979 5.979 0 0 0-1.076.18 4.884 4.884 0 1 0-4.877 5.152c.149 0 .3-.007.447-.021a6.092 6.092 0 0 0-.037.671c0 .139 0 .28.014.419-.147.01-.29.015-.425.015a5.976 5.976 0 0 1-5.967-5.968Zm7.879 1.566H8.018a.7.7 0 0 1-.7-.7V5.7a.7.7 0 1 1 1.393 0v3.1h1.626a.7.7 0 0 1 .672.515 6.051 6.051 0 0 0-.963.877Zm6.224-1.779a6.008 6.008 0 0 0-1.1-.219 7.053 7.053 0 0 0-10.863-5.5.55.55 0 0 1-.294.086.542.542 0 0 1-.3-1 8.143 8.143 0 0 1 12.55 6.625Z"
          />
        </g>
      </g>
    </svg>
  );
};

SvgPendingAmount.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 20,
  width: 20,
};
export default SvgPendingAmount;
