import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  RearrangeColumns,
  SearchBar,
  Truncate,
} from "src/components/common/atoms";
import {
  FormButton,
  FormCheck,
  FormTooltip,
} from "src/components/common/formElements";
import { FIXED_USER_ATTRIBUTES } from "src/config/userAttributes";
import { COLORS } from "src/constants";
import { areArraysEqual, isEmpty } from "src/helpers";
import { SvgConfigure } from "src/icons";
import { NoGoals } from "src/images";
import "./configureColumn.scss";

const ConfigureColumns = ({
  usersAttributeList,
  fieldsOrder,
  setFieldsOrder,
  handleDoneButton,
}) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [initialFields, setInitialFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [columns, setColumns] = useState({});
  const [showColumnFields, setShowColumnFields] = useState(false);

  useEffect(
    () => {
      if (!isEmpty(usersAttributeList)) {
        setColumns({ ...usersAttributeList });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!isEmpty(usersAttributeList)) {
      const selectedList = fieldsOrder?.map((field) => {
        return {
          id: field,
          Header: usersAttributeList[field]?.DisplayName,
        };
      });
      setInitialFields(selectedList);
      setSelectedFields(selectedList);
    }
  }, [fieldsOrder, usersAttributeList]);

  const TooltipOverlay = ({ isGreater, isLess, children }) =>
    isGreater ? (
      <FormTooltip
        id="is-greater-tooltip"
        placement="bottom"
        text={t("COMMON.MESSAGES.UPTO_TEN_COLUMNS")}
      >
        {children}
      </FormTooltip>
    ) : isLess ? (
      <FormTooltip
        id="is-less-tooltip"
        placement="bottom"
        text={t("COMMON.MESSAGES.ATLEAST_ONE_COLUMN")}
      >
        {children}
      </FormTooltip>
    ) : (
      children
    );

  const handleDone = () => {
    const initialArr = initialFields?.map((field) => field.id);
    const selectedArr = selectedFields?.map((field) => field.id);
    if (fieldsOrder && !areArraysEqual(selectedArr, initialArr)) {
      setFieldsOrder(selectedArr);
      handleDoneButton(selectedArr);
    }
    setShowColumnFields(false);
  };

  const selectColumn = (field) => {
    const isVisible = !columns[field].isVisible;
    setColumns({
      ...columns,
      [field]: { ...columns[field], isVisible },
    });
    if (isVisible && fieldsOrder) {
      setSelectedFields([
        ...selectedFields,
        { id: field, Header: columns[field].DisplayName },
      ]);
    } else {
      setSelectedFields(selectedFields?.filter((item) => item.id !== field));
    }
  };

  const newFilteredColumns =
    columns &&
    Object.keys(columns)
      .sort((a, b) => {
        if (columns[a].isVisible) return -1;
        if (columns[b].isVisible) return 1;
        return columns[a].DisplayName.localeCompare(columns[b].DisplayName);
      })
      .filter((field) => {
        if (FIXED_USER_ATTRIBUTES.includes(field)) return false;
        if (searchQuery) {
          return columns[field].DisplayName.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
        return true;
      });

  return (
    <>
      <div className="publish-goal-add-column">
        <FormButton
          className="pointer"
          id="rearrange-cols"
          variant="only-icon"
          icon={
            showColumnFields ? (
              <SvgConfigure />
            ) : (
              <SvgConfigure color={COLORS.GREY_9} />
            )
          }
          onClick={() => setShowColumnFields(!showColumnFields)}
        />
      </div>
      {showColumnFields && (
        <div className="publish-goal-select-user-dropdown">
          <div className="select-user-dropdown-content">
            <Tabs
              className="configure-columns-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              mountOnEnter
            >
              <Tab
                eventKey={0}
                title={
                  <div className="fs-12 semi-bold">
                    {t("COMMON.LABELS.SELECT_COLUMNS")}
                  </div>
                }
              >
                <div className="p-3">
                  <div className="mb-3">
                    <SearchBar
                      search={searchQuery}
                      placeholder={`${t(
                        "MANAGED_GOALS.LABELS.SEARCH_FIELD"
                      )}...`}
                      setSearch={setSearchQuery}
                    />
                  </div>
                  <ul className="column-modal">
                    {newFilteredColumns.length > 0 ? (
                      newFilteredColumns.map((field) => {
                        const isGreater =
                          !columns[field]?.isVisible &&
                          selectedFields?.length >= 10;
                        const isLess =
                          columns[field]?.isVisible &&
                          selectedFields?.length < 2;
                        return (
                          <TooltipOverlay isGreater={isGreater} isLess={isLess}>
                            <li key={field}>
                              <FormCheck
                                id={`${columns[field]?.DisplayName}`}
                                label={
                                  <div
                                    className={`fs-12 ml-2 ${
                                      isGreater || isLess ? "fc-grey" : ""
                                    }`}
                                  >
                                    <Truncate
                                      id={`configure-${columns[field]?.DisplayName}`}
                                      maxWidth="200px"
                                      truncType="end"
                                      showTooltip={false}
                                      text={columns[field]?.DisplayName}
                                    />
                                  </div>
                                }
                                onClick={() => selectColumn(field)}
                                disabled={isGreater || isLess}
                                checked={columns[field]?.isVisible}
                              />
                            </li>
                          </TooltipOverlay>
                        );
                      })
                    ) : (
                      <div className="flex-center flex-column h-100">
                        <NoGoals />
                        <div className="fs-13 fc-grey mt-3">No Fields</div>
                      </div>
                    )}
                  </ul>
                </div>
              </Tab>
              <Tab
                eventKey={1}
                title={
                  <div className="fs-12 semi-bold">
                    {t("COMMON.LABELS.REARRANGE_COLUMNS")}
                  </div>
                }
              >
                <div className="p-3">
                  <RearrangeColumns
                    items={selectedFields}
                    setItems={setSelectedFields}
                  />
                </div>
              </Tab>
            </Tabs>
            <hr />
            <div className="py-2 px-3 d-flex justify-content-between align-items-center">
              <div
                className={`fs-13 ${
                  selectedFields?.length >= 10 ? "fc-orange" : "fc-grey"
                }`}
              >
                {t("MANAGED_GOALS.LABELS.SELECTED")} {selectedFields?.length}
                /10
              </div>
              <FormButton
                variant="outline-primary"
                id="select-column-done"
                onClick={handleDone}
                label={t("COMMON.BUTTONS.DONE")}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ConfigureColumns.propTypes = {
  usersAttributeList: PropTypes.array,
  fieldsOrder: PropTypes.array,
  setFieldsOrder: PropTypes.func,
  handleDoneButton: PropTypes.func,
};

ConfigureColumns.defaultProps = {
  handleDoneButton: () => {},
};

export default ConfigureColumns;
