import { gql, useQuery } from "src/api/client";

const GET_ASSIGNED_PROGRAMS_FOR_USER = gql`
  query assignedProgramsForSelectedUser(
    $userId: ID
    $timeFrequency: TimeFrequency
    $limit: Float
    $after: String
    $FeatureType: String!
  ) {
    assignedProgramsForSelectedUser(
      userId: $userId
      limit: $limit
      timeFrequency: $timeFrequency
      after: $after
      FeatureType: $FeatureType
    ) {
      ... on AssignedProgramsForSelectedUserResponse {
        success
        message
        response {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            __typename
          }
          programs {
            programName
            programId
            totalCycles
            cycleType
            currentRecurrenceStatus
            eligibleAmount
            approvedAmount
            paidOutAmount
            payoutPendingAmount
            badges
            badgesCount
            groupId
            groupName
            PaidOutRewards {
              RewardCategory
              RewardCount
            }
            EligibleRewards {
              RewardCategory
              RewardCount
            }
            ApprovedRewards {
              RewardCategory
              RewardCount
            }
            PayoutPendingRewards {
              RewardCategory
              RewardCount
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const useGetAssignedLeaderboardsForSelectedUser = (queryOptions) => {
  return useQuery(GET_ASSIGNED_PROGRAMS_FOR_USER, queryOptions);
};
