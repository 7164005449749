import { useEffect, useRef, useState } from "react";
import { Menu, useMenu } from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import { classNames } from "src/helpers";
import { SvgEdit } from "src/icons";
import TagsSelector from "../tagsSelector/TagsSelector";
import FormulaItem from "./FormulaItem";
import FormulaMenu from "./FormulaMenu";
import FormulaPlaceholder from "./FormulaPlaceholder";

function FormulaBuilder({ menuList, actions, formula, disabled, error }) {
  const menu = useMenu();
  const inputRef = useRef();
  const [edit, setEdit] = useState(false);
  const [isInputPresent, setIsInputPresent] = useState(false);

  const handleChange = () => {
    if (inputRef.current.value.trim() === "") {
      inputRef.current.value = "";
      setIsInputPresent(false);
      return;
    }
    if (inputRef.current.value) {
      return setIsInputPresent(true);
    }
    setIsInputPresent(false);
  };

  const handleEnter = (value) => {
    const isPushed = actions.pushFormula(value);
    inputRef.current.focus();
    setIsInputPresent(false);
    return isPushed;
  };

  const handleMenuClick = (value) => {
    actions.pushFormula(value);
    menu.handleClose();
    inputRef.current.focus();
  };

  const handleDelete = (value) => {
    if (formula.length !== 0) {
      inputRef.current.value = actions.popFormula();
    }
    inputRef.current.focus();
  };

  useEffect(() => {
    if (formula.length === 0) {
      setEdit(false);
    }
  }, [formula.length]);

  return (
    <div className={classNames(["row br-8 flex-between"])}>
      <div
        ref={menu.containerRef}
        onClick={() =>
          edit || disabled ? undefined : inputRef.current.focus()
        }
        style={{ position: "relative" }}
        className={classNames([
          "br-4 border-1 col-11 p-0",
          edit || disabled ? "bc-grey3 bg-grey" : "border-transparent",
        ])}
        variant="link"
      >
        <TagsSelector
          className="w-100 fc-black normal"
          disabled={disabled || edit}
          inputRef={inputRef}
          tags={formula}
          renderTag={FormulaItem}
          onEnter={handleEnter}
          onFocus={menu.handleClick}
          onDelete={handleDelete}
          onChange={handleChange}
          showPlaceholder={
            !menu.el &&
            (inputRef.current ? inputRef.current?.value.length === 0 : true) &&
            formula.length === 0
          }
          placeholder={<FormulaPlaceholder />}
        />
      </div>
      {!disabled ? (
        <div className="col-1 p-0 pl-3">
          {edit ? (
            <FormButton
              className="p-2 bg-grey rounded-circle center cursor-pointer"
              style={{ width: 28, height: 28 }}
              onClick={() => {
                actions.handleEdit();
                setEdit(false);
              }}
              variant="link"
            >
              <SvgEdit />
            </FormButton>
          ) : null}
          {!edit ? (
            <FormButton
              type="button"
              disabled={!formula.length || isInputPresent}
              onClick={() => {
                actions.handleSave();
                setEdit(true);
              }}
              variant="outline-primary"
            >
              Done
            </FormButton>
          ) : null}
        </div>
      ) : null}

      <Menu el={menu.el} container={menu.containerRef.current}>
        <FormulaMenu menuList={menuList} handleClick={handleMenuClick} />
      </Menu>
    </div>
  );
}

export default FormulaBuilder;
