import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationModal } from "src/components/common/atoms";
import {
  FormBadge,
  FormButton,
  FormTooltip,
} from "src/components/common/formElements";
import { SvgAlert } from "src/icons";

const TemplateSelectButton = (props) => {
  const {
    disableUseTemplateClick,
    handleUseTemplateButtonClick,
    showDistinctCountCheck,
    maxAllowedTemplates,
    selectedTemplateCount,
    showConfirmationModal,
    isMultiSelect,
  } = props;
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const TooltipText = () => (
    <>
      <span>{t("MANAGED_GOALS.MESSAGES.LEARN_MORE_DESC")}</span>
      <br />
      <span>{t("MANAGED_GOALS.MESSAGES.LEARN_MORE_SUGG")}</span>
    </>
  );

  const ConfirmationModalBody = () => (
    <>
      <div className="fs-14 semi-bold">
        {t("RULEBOOK.MESSAGES.CONFIRM_TEMPLATE_WARNING")}
      </div>
      <div className="fs-13 mt-2 mb-4 normal">
        {t("RULEBOOK.MESSAGES.CONFIRM_TEMPLATE_DESC")}
      </div>
    </>
  );

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      {isMultiSelect && (
        <FormBadge id="primary-badge" className="bg-secondary-blue fc-black">
          <div className="fc-grey">
            <span className="fc-black semi-bold">{selectedTemplateCount}</span>/{" "}
            <span className="fc-grey semi-bold">
              {maxAllowedTemplates}{" "}
              {selectedTemplateCount === 1
                ? t("RULEBOOK.LABELS.RULEBOOK")
                : t("RULEBOOK.LABELS.RULEBOOKS")}
            </span>{" "}
            {t("MANAGED_GOALS.LABELS.SELECTED")}
          </div>
        </FormBadge>
      )}

      <div className="d-flex justify-content-end ml-auto">
        {showDistinctCountCheck && (
          <div className="d-flex justify-content-between align-items-center fc-grey p-12 bg-blue br-4 mr-4">
            <SvgAlert backgroundColor={false} className="mr-2" />
            <div className="fs-12 mr-3">
              {t("RULEBOOK.LABELS.CANNOT_USE_TEMPLATE")}{" "}
              <span className="semi-bold">
                {t("MANAGED_GOALS.MESSAGES.DISTINCT_COUNT")}
              </span>{" "}
              {t("MANAGED_GOALS.MESSAGES.FUNCTION")}{" "}
              <span className="semi-bold">
                {t("MANAGED_GOALS.MESSAGES.FOR_THIS_GOAL")}.
              </span>
            </div>
            <FormTooltip
              id="template-select-tooltip"
              placement="top"
              text={<TooltipText />}
            >
              <div className="fs-12 fc-blue learn-more">
                {t("MANAGED_GOALS.MESSAGES.LEARN_MORE")}
              </div>
            </FormTooltip>
          </div>
        )}
        <FormButton
          id="use-template-btn"
          className="fs-14"
          onClick={() =>
            showConfirmationModal
              ? setShowConfirmModal(true)
              : handleUseTemplateButtonClick()
          }
          disabled={disableUseTemplateClick}
          label={
            isMultiSelect
              ? t("MANAGED_GOALS.BUTTONS.USE_SELECTED_RULESETS")
              : t("MANAGED_GOALS.BUTTONS.USE_THIS_RULEBOOK")
          }
        />
      </div>
      {showConfirmModal && (
        <ConfirmationModal
          show
          onClose={() => setShowConfirmModal(false)}
          dialogClassName="confirm-select-template"
          title={t("RULEBOOK.LABELS.CONFIRM_TEMPLATE")}
          body={<ConfirmationModalBody />}
          onConfirm={() => handleUseTemplateButtonClick()}
          confirmButtonText={t("RULEBOOK.MESSAGES.YES_CHANGE_TEMPLATE")}
          cancelButton
        />
      )}
    </div>
  );
};

export default TemplateSelectButton;
