import React from "react";
import { ErrorPage as AceErrorPage, OAceLandingPage } from "src/components";
import { APPS } from "src/constants";
import { Redirect, Route, Switch } from "src/router";

const OpenAceFallback = () => {
  return (
    <Switch>
      <Route
        path={APPS.ACE.ROUTES.ERROR.path}
        page={{
          web: AceErrorPage,
          mobile: AceErrorPage,
          meta: {
            title: "Error Page",
          },
        }}
        isPage
        exact
      />
      <Route
        path={APPS.ACE.ROUTES.ROOT.path}
        page={{
          web: () => <OAceLandingPage />,
          mobile: () => <OAceLandingPage />,
          meta: {
            title: "Open Ace Landing Page",
          },
        }}
        isPage
        exact
      />
      <Route path="*">
        <Redirect to={APPS.ACE.ROUTES.ROOT.path} />
      </Route>
    </Switch>
  );
};

export default OpenAceFallback;
