import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_PROGRAM = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getProgram($ProgramId: ID!, $RecurrenceId: ID!) {
    program(ProgramId: $ProgramId, RecurrenceId: $RecurrenceId) {
      __typename
      ... on SingleProgramResponse {
        response {
          Program {
            validations
            ProgramId
            VisibilitySettings {
              ShowIncentiveHistory
              ShowCriteriaDetails
            }
            Name
            Description
            Status
            InGaps
            RecurrenceDefinition {
              CustomGap
              CustomType
              FirstRecurrenceStartDate
              LastRecurrenceEndDate
              RecurrenceCycleDuration
              RecurrencePeriod
              Recurrences {
                RecurrenceStartDate
                RecurrenceEndDate
              }
              __typename
            }
            Type
            IsRollup
            RollupType
            UserSelectionType
            FileName
            CreatedBy
            ModifiedBy
            CreatedBy
            CurrentRecurrenceCycle
            RecurrenceTimeLeft
            RecurrenceDueText
            RecurrencesCount
            DisableApprovalPayout
            SelectedTemplates {
              TemplateId
              Name
              GoalAggregateFunction
              DrilldownStatus
              GroupBy
            }
            SelectedGoals {
              GoalId
              GoalName
              AggregateFunction
              TemplateId
              Recurrences
            }
            SelectedApprovers {
              ProgramId
              UserId
              Level
              Stage
              Type
              EmailAddress
              FirstName
              LastName
              StatusCode
            }
            ProgramOwner {
              FirstName
              LastName
              EmailAddress
              UserID
              StatusCode
            }
            Criterias {
              Conditions
              ConditionPattern
              Rewards
            }
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetProgram = (queryOptions) => {
  return useQuery(GET_PROGRAM, queryOptions);
};
