import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React from "react";

const Sortable = ({
  sortableItems,
  children,
  collisionDetection,
  handleDragEnd,
  useDragOverlay,
  dragOverlayChildren,
  sortingStrategy,
  activationConstraint,
  coordinateGetter,
  modifiers,
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragEnd={handleDragEnd}
      modifiers={
        modifiers || [
          restrictToVerticalAxis,
          restrictToWindowEdges,
          restrictToFirstScrollableAncestor,
        ]
      }
    >
      <SortableContext items={sortableItems} strategy={sortingStrategy}>
        <ul>{children}</ul>
      </SortableContext>
      {useDragOverlay && <DragOverlay>{dragOverlayChildren}</DragOverlay>}
    </DndContext>
  );
};

Sortable.defaultProps = {
  activationConstraint: {
    delay: 100,
    tolerance: 100,
  },
  sortingStrategy: verticalListSortingStrategy,
  collisionDetection: closestCenter,
  useDragOverlay: false,
};

export default Sortable;
