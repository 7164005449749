import { gql, useQuery } from "src/api/client";

const GET_CUSTOM_REWARD_BY_ID = gql`
  query getCustomRewardById($RewardId: ID!) {
    getCustomRewardById(RewardId: $RewardId) {
      ... on CustomRewardResponse {
        success
        message
        response {
          RewardId
          RewardName
          RewardValue
          RewardLink
          Description
          FileId
          FileName
          CustomRewardImageUrl
          RewardCategory
          IsActive
          AwardCount
          ModulesUsedCount
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useGetCustomRewardById = (queryOptions) => {
  return useQuery(GET_CUSTOM_REWARD_BY_ID, queryOptions);
};
