import React from "react";
import { GOAL_AGGREGATION_TYPES } from "src/constants";
import MobileAchievementCardLeft from "./MobileAchievementCardLeft";
import MobileAchievementCardRight from "./MobileAchievementCardRight";
import "./mobileAchievementCard.scss";

const MobileAchievementCard = (props) => {
  const {
    recurrenceStatus,
    status,
    users,
    days,
    isAssignedToMeView,
    goalAggConfig,
    goalValue,
    goalAchieved,
    goalUsersCount,
    usersAchievedCount,
    isUserPresent,
    isRollUp,
    RollupType,
    onGoalUserChange,
    showDrillDown,
    setShowSubGoalModal,
  } = props;
  const usersCount =
    goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED && !isAssignedToMeView
      ? users
      : null;

  const daysRemaining =
    status === "PUBLISHED" && recurrenceStatus === "INPROGRESS" ? days : null;

  return (
    <div className="br-4 center achievement-card">
      <div className="w-100 flex align-items-start flex-column achievement-card-left">
        <MobileAchievementCardLeft
          {...{
            status,
            goalValue,
            goalAchieved,
            recurrenceStatus,
            goalAggConfig,
            isAssignedToMeView,
            goalUsersCount,
            usersAchievedCount,
            isUserPresent,
            isRollUp,
            RollupType,
            onGoalUserChange,
            showDrillDown,
            setShowSubGoalModal,
          }}
        />
      </div>
      {(usersCount !== null || daysRemaining !== null) && (
        <div className="border-left-1 bc-grey9 center achievement-card-right">
          <MobileAchievementCardRight
            {...{
              usersCount,
              daysRemaining,
              goalAggConfig,
              isAssignedToMeView,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MobileAchievementCard;
