import React from "react";
import SelectedTags from "./SelectedTags";
import TagInput from "./TagInput";
import TagsSelectorInput from "./TagsSelectorInput";

function TagsSelector({
  className,
  disabled,
  inputRef,
  tags,
  renderTag,
  onEnter,
  onDelete,
  onFocus,
  onChange,
  placeholder,
  showPlaceholder,
}) {
  return (
    <TagsSelectorInput disabled={disabled} className={className}>
      <SelectedTags disabled={disabled} tags={tags} renderTag={renderTag}>
        {!disabled ? (
          <TagInput
            inputRef={inputRef}
            onEnter={onEnter}
            onDelete={onDelete}
            onFocus={onFocus}
            onChange={onChange}
            showPlaceholder={showPlaceholder}
            placeholder={placeholder}
          />
        ) : null}
      </SelectedTags>
    </TagsSelectorInput>
  );
}

export default TagsSelector;
