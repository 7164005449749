import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgClock = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l633_ds">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g
        stroke={color}
        strokeWidth={2}
        clipPath="url(#l633_ds)"
        data-name="Group 12978"
      >
        <g fill={backgroundColor} data-name="Ellipse 193">
          <circle cx={12} cy={12} r={12} stroke="none" />
          <circle cx={12} cy={12} r={11} fill="none" />
        </g>
        <path fill="none" d="M11.36 5.8v8h6" data-name="Path 4703" />
      </g>
    </svg>
  );
};

SvgClock.defaultProps = {
  backgroundColor: COLORS.SECONDARY_BLUE,
  color: COLORS.PRIMARY_BLUE,
  height: 24,
  width: 24,
};
export default SvgClock;
