import { gql, useQuery } from "src/api/client";

const GET_APPROVAL_HISTORY_QUERY = gql`
  query userApprovalHistory(
    $programId: ID!
    $recurrenceId: ID!
    $recurrenceStatus: String
    $userId: ID!
  ) {
    userApprovalHistory(
      programId: $programId
      recurrenceId: $recurrenceId
      recurrenceStatus: $recurrenceStatus
      userId: $userId
    ) {
      ... on UserApprovalHistoryResponse {
        response {
          historyId
          isLoggedInUser
          stage
          approverName
          approvedAmount
          rejectedAmount
          remarks
          fileName
          downloadUrl
          approvedTime
          action
          status
          isApprovedByAdmin
          ApprovedRewards {
            RewardName
          }
          RejectedRewards {
            RewardName
          }
          ApprovedRewardsCount
          RejectedRewardsCount
        }
      }
    }
  }
`;

export const useGetApprovalHistory = (queryOptions) => {
  return useQuery(GET_APPROVAL_HISTORY_QUERY, queryOptions);
};
