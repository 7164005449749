import React from "react";
const SvgSave = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={height}
      height={width}
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M18 4v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 16 18H2c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 0 16V2C0 1.45.196.98.588.588A1.926 1.926 0 0 1 2 0h12zm-2 .85L13.15 2H2v14h14zM9 15c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 12 12c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 9 9c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 6 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 9 15M3 7h9V3H3zM2 4.85V16 2z"
      />
    </svg>
  );
};
SvgSave.defaultProps = {
  color: "#309AFC",
  height: "18",
  width: "18",
};
export default SvgSave;
