import { t } from "i18next";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { VALID_FILE_NAME_REGEX } from "src/constants";

export const FILE_FORMATS = {
  JPEG: "image/jpeg",
  PNG: "image/png",
  SVG: "image/svg+xml",
  PDF: "application/pdf",
  DOC: ".doc,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLSX: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPT: "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation",
};

export const FILE_ERROR_CODES = {
  FILE_TOO_LARGE: "file-too-large",
  FILE_INVALID_TYPE: "file-invalid-type",
  FILE_DIMENSION_MISMATCH: "dimension-mismatch",
  DOUBLE_DOT_EXTENSION: "double-dot-extension",
  FILE_NAME_TOO_LARGE: "file-name-too-large",
};

const ERRORS = {
  [FILE_ERROR_CODES.FILE_TOO_LARGE]: ({ maxSize }) => {
    return {
      code: FILE_ERROR_CODES.FILE_TOO_LARGE,
      message: `File exceeds the ${maxSize / 1000000}mb limit.`,
    };
  },
  [FILE_ERROR_CODES.FILE_INVALID_TYPE]: ({ accept }) => {
    const allFormats = Object.keys(FILE_FORMATS);
    const formatsAllowed = allFormats
      .filter((format) => {
        const formatValue = FILE_FORMATS[format];
        return accept.includes(formatValue);
      })
      .reduce((currentFormat, nextFormat) => {
        return `${currentFormat.toLowerCase()}.${nextFormat.toLowerCase()}, `;
      }, "");
    return {
      code: FILE_ERROR_CODES.FILE_INVALID_TYPE,
      message: `Wrong file format. Only ${formatsAllowed} and related formats are supported.`,
    };
  },
  [FILE_ERROR_CODES.FILE_DIMENSION_MISMATCH]: () => {
    return {
      code: FILE_ERROR_CODES.FILE_DIMENSION_MISMATCH,
      message: t(
        "SETTINGS.CUSTOM_REWARDS_CONFIG.MESSAGES.DIMENSIONS_ARE_INCORRECT"
      ),
    };
  },
  [FILE_ERROR_CODES.DOUBLE_DOT_EXTENSION]: () => {
    return {
      code: FILE_ERROR_CODES.DOUBLE_DOT_EXTENSION,
      message: t("COMMON.MESSAGES.DOUBLE_DOT_EXTENSION_ERROR_MESSAGE"),
    };
  },
  [FILE_ERROR_CODES.FILE_NAME_TOO_LARGE]: () => {
    return {
      code: FILE_ERROR_CODES.FILE_NAME_TOO_LARGE,
      message: t("COMMON.MESSAGES.FILE_NAME_TOO_LARGE"),
    };
  },
};

export const useFilePicker = (initialState) => {
  const [state, setState] = useState({
    isAtStart: true,
    fileError: null,
  });

  const { onDrop: initialOnDrop } = initialState;
  initialState.errors = initialState.errors || {};

  const getFileError = useCallback(
    (errors) => {
      return (
        initialState.errors[errors[0].code] ||
        ERRORS[errors[0].code](initialState)
      );
    },
    [initialState]
  );

  initialState.onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      const newState = {};
      if (rejectedFiles.length) {
        newState.fileError = getFileError(rejectedFiles[0].errors);
      } else {
        newState.fileError = null;
      }
      setState((prevState) => ({
        ...prevState,
        ...newState,
      }));
      initialOnDrop && initialOnDrop(acceptedFiles, rejectedFiles);
    },
    [initialOnDrop, getFileError]
  );

  const doubleDotExtensionvalidator = (file) => {
    const fileName = file.name;
    if (fileName.match(VALID_FILE_NAME_REGEX)) return null;
    else {
      return {
        code: FILE_ERROR_CODES.DOUBLE_DOT_EXTENSION,
        message: t("COMMON.MESSAGES.DOUBLE_DOT_EXTENSION_ERROR_MESSAGE"),
      };
    }
  };

  const dimensionMismatchValidator = (file, initialState) => {
    // You can access width/height properties
    if (
      file.width > initialState.imageDimensions?.maxWidth ||
      file.height > initialState.imageDimensions?.maxHeight
    ) {
      return {
        code: FILE_ERROR_CODES.FILE_DIMENSION_MISMATCH,
        message: t(
          "SETTINGS.CUSTOM_REWARDS_CONFIG.MESSAGES.DIMENSIONS_ARE_INCORRECT"
        ),
      };
    }
    return null;
  };

  const fileNameTooLargeValidator = (file) => {
    if (file.name.length >= 50) {
      return {
        code: FILE_ERROR_CODES.FILE_NAME_TOO_LARGE,
        message: t("COMMON.MESSAGES.FILE_NAME_TOO_LARGE"),
      };
    }
    return null;
  };

  const validator = (file) => {
    if (dimensionMismatchValidator(file, initialState))
      return dimensionMismatchValidator(file, initialState);
    if (doubleDotExtensionvalidator(file))
      return doubleDotExtensionvalidator(file);
    if (fileNameTooLargeValidator(file)) return fileNameTooLargeValidator(file);
    return null;
  };

  const dropzone = useDropzone({
    ...initialState,
    validator,
  });

  const removeFile = () => {
    dropzone.acceptedFiles.pop();
    setState((prevState) => ({
      ...prevState,
      isAtStart: true,
      fileError: null,
    }));
  };

  const isOnLoad =
    state.isAtStart &&
    !state.fileError &&
    !dropzone.isDragActive &&
    dropzone.acceptedFiles.length === 0;

  const isOnDrop = !isOnLoad && dropzone.isDragActive;

  const isOnUpload = !isOnDrop && dropzone.acceptedFiles.length !== 0;

  const isOnError = !isOnDrop && state.fileError;

  return {
    ...dropzone,
    removeFile,
    fileError: state.fileError,
    isAtStart: state.isAtStart,
    screens: {
      isOnLoad,
      isOnDrop,
      isOnUpload,
      isOnError,
    },
  };
};
