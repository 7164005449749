import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { TOKEN_TYPE } from "../formulaParser";
import { FORMULA_MENU_LIST } from "./data";
import "./formulaMenu.scss";

const FormulaMenu = (props) => {
  const {
    handleClick,
    handleClose,
    recurrenceId,
    incentiveDetails,
    selectedRecurrence,
  } = props;

  const [tokenType, setTokenType] = useState(TOKEN_TYPE.METRIC);

  return (
    <div className="formula-menu">
      <Tabs
        activeKey={tokenType}
        onSelect={(key) => setTokenType(key)}
        className="formula-menu__tabs"
      >
        {FORMULA_MENU_LIST.map(({ Component, key, label }) => {
          return (
            <Tab id={key} key={key} eventKey={key} title={label}>
              <Component
                {...{
                  tokenType,
                  setTokenType,
                  handleClick,
                  handleClose,
                  recurrenceId,
                  incentiveDetails,
                  selectedRecurrence,
                }}
              />
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default FormulaMenu;
