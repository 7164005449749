import { t } from "i18next";
import React, { useState } from "react";
import { useFilter } from "src/components/base";
import { FormButton } from "src/components/common";
import { Calendar } from "src/components/common/molecules";
import { DATE_FORMAT, DATE_FORMAT_PAYLOAD } from "src/constants";
import { format } from "src/helpers";
import { ModalContainer } from "../modalContainer";
import "./calendarSelection.scss";

const InlineCalendar = ({
  show,
  onHide,
  placeholder,
  selected,
  onApplyFilterClick,
  title,
}) => {
  const [date, setDate] = useState(selected);

  return (
    <ModalContainer
      show={show}
      onHide={onHide}
      title={title}
      showBackButton={false}
      showCloseButton
      applyText={t("COMMON.BUTTONS.DONE")}
      onApplyFilterClick={() => onApplyFilterClick(date)}
    >
      <div className="calendar-inline-container">
        <Calendar
          placeholder={placeholder}
          selected={selected}
          onChange={(value) => setDate(value)}
          inline
        />
      </div>
    </ModalContainer>
  );
};

const CalendarSelection = ({ customKey, today, selected, setSelected }) => {
  const CUSTOM_FILTER = [
    {
      label: "INCENTIVES.DASHBOARD.LABELS.SELECT_START_DATE",
      key: "startsAt",
      defaultValue:
        selected[customKey]?.startsAt ?? format(today, DATE_FORMAT_PAYLOAD),
      modalTitle: "MANAGED_GOALS.LABELS.START_DATE",
    },
    {
      label: "INCENTIVES.DASHBOARD.LABELS.SELECT_END_DATE",
      key: "endsAt",
      defaultValue:
        selected[customKey]?.endsAt ?? format(today, DATE_FORMAT_PAYLOAD),
      modalTitle: "MANAGED_GOALS.LABELS.END_DATE",
    },
  ];

  const { filtersState, filtersToggleState, handleChange, toggleFilter } =
    useFilter(CUSTOM_FILTER);

  return (
    <div className="fs-14 space-y-16">
      {CUSTOM_FILTER.map(({ label, key, modalTitle }) => (
        <div key={key}>
          <div className="flex-between">
            <div>{t(label)}</div>
            <FormButton
              className="fs-13 normal"
              onClick={() => toggleFilter(key)}
              variant="link"
            >
              {format(new Date(filtersState[key]), DATE_FORMAT)}
            </FormButton>
          </div>
          <InlineCalendar
            show={filtersToggleState[key]}
            onHide={() => toggleFilter(key)}
            title={t(modalTitle)}
            placeholder={t(label)}
            selected={new Date(filtersState[key])}
            onApplyFilterClick={(date) => {
              handleChange({ key, value: format(date, DATE_FORMAT_PAYLOAD) });
              setSelected((prevState) => {
                return {
                  ...prevState,
                  [customKey]: {
                    ...prevState[customKey],
                    ...filtersState,
                    [key]: format(date, DATE_FORMAT_PAYLOAD),
                  },
                };
              });
              toggleFilter(key);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default CalendarSelection;
