import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgGift = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.063 11.268c.575 0 1.042.467 1.042 1.043v8.119h12.69v-8.12a1.042 1.042 0 1 1 2.084 0v9.162c0 .576-.466 1.043-1.042 1.043H5.063a1.042 1.042 0 0 1-1.043-1.043v-9.161c0-.576.467-1.043 1.043-1.043Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M1.958 7.155c0-.575.466-1.042 1.042-1.042h18.904c.575 0 1.042.467 1.042 1.042v5.156c0 .576-.467 1.042-1.042 1.042H3a1.042 1.042 0 0 1-1.042-1.042V7.155Zm2.084 1.043v3.07h16.82v-3.07H4.041Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.45 4.965c.575 0 1.042.467 1.042 1.042v15.465a1.042 1.042 0 1 1-2.085 0V6.007c0-.575.466-1.042 1.042-1.042Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.47 3.042a1.535 1.535 0 0 0 0 3.07h2.787a7.452 7.452 0 0 0-.375-1.121C10.387 3.844 9.65 3.042 8.47 3.042Zm4.03 4.113c1.04-.084 1.039-.084 1.039-.085V7.06l-.002-.017a10.262 10.262 0 0 0-.136-.897 9.968 9.968 0 0 0-.605-1.982C12.18 2.733 10.902.958 8.471.958a3.62 3.62 0 0 0 0 7.24H12.5a1.042 1.042 0 0 0 1.04-1.127l-1.04.084Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.146 4.181c.59-1.437 1.84-3.223 4.28-3.223a3.62 3.62 0 0 1 0 7.24H12.5a1.043 1.043 0 0 1-1.04-1.107l1.04.064-1.04-.064V7.082l.001-.017a3.975 3.975 0 0 1 .024-.247c.018-.16.047-.384.095-.651.094-.53.263-1.251.566-1.986Zm1.572 1.932h2.708a1.535 1.535 0 0 0 0-3.07c-1.17 0-1.883.79-2.352 1.931a7.32 7.32 0 0 0-.356 1.139Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

SvgGift.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 16,
  width: 16,
};
export default SvgGift;
