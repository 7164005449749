import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgUnpublish = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 2326" />
      <path fill="none" d="M0 3h14v14H0Z" data-name="Path 799" />
      <path
        fill={color}
        d="M3.56 17.971a1.135 1.135 0 0 1 0-1.605L4.625 15.3h-.25a3.811 3.811 0 0 1-.041-7.621 5.439 5.439 0 0 1 4.985-3.268 5.112 5.112 0 0 1 .573.031 5.415 5.415 0 0 1 3.637 1.955l2.063-2.064a1.135 1.135 0 1 1 1.6 1.605L5.159 17.971a1.134 1.134 0 0 1-1.6 0Zm-.294-7.637a1.632 1.632 0 0 0 1.154 2.787h2.384l5.173-5.172a3.263 3.263 0 0 0-2.292-1.342 3.222 3.222 0 0 0-.363-.02 3.282 3.282 0 0 0-3.181 2.551 1.091 1.091 0 0 1-1.062.85 1.06 1.06 0 0 1-.271-.035 3.77 3.77 0 0 0-.389-.1 1.62 1.62 0 0 0-1.153.481Zm6.837 4.965 2.178-2.178h2.485a1.089 1.089 0 1 0 0-2.178h-.307l1.811-1.812a3.268 3.268 0 0 1-1.5 6.168Z"
        data-name="Subtraction 19"
      />
    </svg>
  );
};

SvgUnpublish.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 20,
  width: 20,
};
export default SvgUnpublish;
