import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { range } from "src/helpers";
import { LoadingRuleCard } from "../loadingRuleCard";

const LoadingTemplateBody = () => {
  return (
    <div className="pl-45 pr-45 mt-5">
      <Shimmer width="100px" height="10px" />
      <div className="mt-4">
        <Shimmer width="350px" height="10px" />
      </div>
      <div className="mt-2">
        <Shimmer width="200px" height="10px" />
      </div>
      <div className="mt-5 small-box-border">
        <Shimmer width="100%" />
      </div>
      <div className="mt-5 flex-between rules-grid">
        {range(0, 2).map((i, idx) => (
          <LoadingRuleCard key={idx} />
        ))}
      </div>
    </div>
  );
};

export default LoadingTemplateBody;
