import PropTypes from "prop-types";
import React, { Suspense, useEffect } from "react";
import { Redirect } from "src/router";
import { useAppShell } from "./useAppShell";

export const pagePropTypes = {
  isPage: PropTypes.bool,
  meta: PropTypes.shape({
    title: PropTypes.string,
  }),
  mobile: PropTypes.node,
  web: PropTypes.node,
};

export const pageDefaultProps = {
  isPage: false,
  meta: undefined,
  mobile: null,
  web: null,
};

function Page({ isPage, web, mobile, meta, children }) {
  const appShell = useAppShell();
  const Component = appShell.isMobile ? mobile : web;

  useEffect(() => {
    if (isPage && meta) {
      appShell.setMeta(meta);
      return () => {
        appShell.setMeta({});
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (appShell.isMobile && !Component) {
    return <Redirect to="/" />;
  }

  return (
    <Suspense fallback="">
      {isPage ? (
        <>
          <Component />
          {children}
        </>
      ) : (
        <>
          <Component />
          {children}
        </>
      )}
    </Suspense>
  );
}

Page.propTypes = pagePropTypes;
Page.defaultProps = pageDefaultProps;

export default Page;
