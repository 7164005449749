import { t } from "i18next";
import { Truncate } from "src/components";
import { REWARD_TYPE } from "src/constants";
import { useToggle } from "src/hooks";
import { SvgLink } from "src/icons";
import SlabRewardSummaryModal from "./SlabRewardSummaryModal";

const SLAB_TYPE = ({ t }) => [
  {
    [REWARD_TYPE.PERCENTAGE_SLAB.type]: t(
      "INCENTIVES.PROGRAM.LABELS.PERCENTAGE_SLAB"
    ),
    [REWARD_TYPE.MULTIPLIER_SLAB.type]: t(
      "INCENTIVES.PROGRAM.LABELS.MULTIPLIER_SLAB"
    ),
  },
];

const ViewSlabRewardSummary = ({
  totalSlabs,
  slabType,
  templateName,
  reward,
  achievement,
  achievedValue,
}) => {
  const [showModal, showOrHideModal] = useToggle();
  return (
    <>
      <button
        onClick={(event) => {
          event.preventDefault();
          showOrHideModal();
        }}
        className="fs-13 fc-primary-blue border-0 bg-transparent center"
      >
        <span className="semi-bold">{totalSlabs}</span>
        <span className="semi-bold mx-1">{SLAB_TYPE({ t })[0][slabType]}</span>
        Of{" "}
        <span className="ml-1">
          <Truncate text={templateName} maxWidth="120px" />
        </span>
        <SvgLink className="ml-2" />
      </button>
      <SlabRewardSummaryModal
        show={showModal}
        handleClose={showOrHideModal}
        title={SLAB_TYPE({ t })[0][slabType]}
        templateName={templateName}
        templateType={
          reward?.GoalId
            ? t("COMMON.BUTTONS.GOAL")
            : t("RULEBOOK.LABELS.RULEBOOK")
        }
        totalSlabs={totalSlabs}
        slabs={reward.Slabs}
        rewardType={slabType}
        slabsAchieved={reward.SlabsAchieved}
        achievedValue={reward.AchievedValue || achievedValue}
        totalReward={reward.TotalReward}
        achievement={achievement}
        goalTarget={reward?.GoalTarget}
      />
    </>
  );
};

export default ViewSlabRewardSummary;
