import PropTypes from "prop-types";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { SidebarMenuItem } from "src/components/common/atoms";
import "./sidebar.scss";

const Sidebar = ({ className, menuList, handleMenuItemClick }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleClick = (index) => {
    setSelectedItem((prev) => (prev === index ? null : index));
  };

  return (
    <div className={`sidebar br-4 ${className || ""}`}>
      <Accordion
        defaultActiveKey="0"
        className="w-100"
        activeKey={String(selectedItem)}
      >
        {menuList?.map((menu, index) => (
          <SidebarMenuItem
            {...{
              menu,
              index,
              selectedItem,
              handleClick: handleMenuItemClick || handleClick,
            }}
          />
        ))}
      </Accordion>
    </div>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  menuList: PropTypes.array,
  handleMenuItemClick: PropTypes.func,
};

export default Sidebar;
