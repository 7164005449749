import PropTypes from "prop-types";
import React from "react";
import { FormButton } from "src/components/common/formElements";
import { classNames } from "src/helpers";
import "./baseCard.scss";

const BaseCardProps = {
  shadow: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5]),
  shadowColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
const defaultBaseCardProps = {
  shadowColor: "#1017310a",
  active: false,
  shadow: 1,
  className: "",
  children: null,
};

const BaseCard = ({
  className,
  active,
  onclick,
  shadow,
  shadowColor,
  children,
}) => {
  return (
    <FormButton
      style={{
        "--shadow-size": `${shadow}px`,
        "--shadow-color": `${shadowColor}`,
      }}
      className={classNames([
        "ace-card transition-all w-100 pl-1 normal text-start",
        active ? "active" : "",
        className,
      ])}
      onClick={onclick}
      variant="normal-link"
    >
      {children}
    </FormButton>
  );
};

BaseCard.propTypes = BaseCardProps;
BaseCard.defaultProps = defaultBaseCardProps;

export default BaseCard;
