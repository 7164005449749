import React from "react";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgEdit } from "src/icons";
import "./addSlabsButton.scss";

const AddSlabsButton = ({ onClick, children, edit }) => {
  return (
    <button
      id="add-slab"
      type="button"
      onClick={onClick}
      className={classNames([
        "add-slabs-btn w-100 mt-3 border-0 br-4 py-2 px-3 text-primary fs-13 h-40 semi-bold",
        edit ? "flex-between" : "",
      ])}
    >
      {children}
      {edit ? (
        <SvgEdit color={COLORS.PRIMARY_BLUE} width="12px" height="12px" />
      ) : null}
    </button>
  );
};

export default AddSlabsButton;
