import { useEffect, useRef } from "react";
import { NetworkStatus } from "src/api/client";
import { useGetGoalTemplateDetails } from "src/api/useTemplate";
import { useGetUserAttributes } from "src/api/useUser";
import {
  DEFAULT_DRILLDOWN_ATTRIBUTES,
  EXCLUDE_DRILLDOWN_ATTRIBUTES,
  STANDARD_RULESET_DRILLDOWN_ATTRIBUTES,
} from "src/config/userAttributes";
import {
  APPS,
  PROGRAM_STATUS_TYPES,
  ROLES,
  RULESET_TYPES,
} from "src/constants";
import { useRoleBasedContext } from "src/context";
import useAchievementBreakdownReducer from "./useAchievementBreakdownReducer";
import useGetAchievementDrilldown from "./useGetAchievementDrilldown";
import useGoalsAssignedViewAB from "./useGoalsAssignedViewAB";
import useGoalsPublishedViewAB from "./useGoalsPublishedViewAB";
import useIncentivesDashboardAB from "./useIncentivesDashboardAB";
import useMyIncentivesAB from "./useMyIncentivesAB";
import { getApiName, getVariables } from "./utils";

const GET_USERS_ACHIEVEMENTS = {
  [APPS.GOALS.ROUTES.PUBLISHED_GOAL_VIEW.name]: useGoalsPublishedViewAB,
  [APPS.GOALS.ROUTES.ASSIGNED_GOAL_VIEW.name]: useGoalsAssignedViewAB,
  [APPS.INCENTIVES.ROUTES.DASHBOARD_PROGRAM_DETAIL_VIEW_PAGE.name]:
    useIncentivesDashboardAB,
  [APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.name]: useMyIncentivesAB,
};

const useAchievementbreakdown = ({
  selectedGoalUser,
  currentRecurrence,
  templateId,
  templates,
  isIncentive,
  managerUserId,
  managersDropdownList,
  isDrillDown,
  moduleName,
  programId,
  isRollUp,
}) => {
  const apiName = getApiName({ isIncentive });
  const loadMoreButtonRef = useRef();
  const modalRef = useRef(null);

  const {
    data: tempalteData,
    loading: templateDetailsLoading,
    error: templateDetailsError,
  } = useGetGoalTemplateDetails({
    skip: !templateId || templates,
    variables: { TemplateId: templateId },
  });

  const templateDetails =
    templates || tempalteData?.template?.response?.Template || [];

  const { alreadyFetched, actions, attributes } =
    useAchievementBreakdownReducer({
      initialState: {
        Recurrence: currentRecurrence,
        User: selectedGoalUser,
      },
    });

  const { Recurrence, User, Rule, FieldsOrder, ShouldFetch } = attributes;

  const { setSelectedRule, setShouldFetch, setGoalUser } = actions;

  const {
    userDetails: { role, isManagingUsers, isIncentiveApprover },
  } = useRoleBasedContext();
  const isSalesUser = role !== ROLES.ADMIN && !isManagingUsers;
  const {
    usersListWithAchievementsLoading,
    usersListWithAchievements,
    onFetchMoreUserList,
    usersListWithAchievementsError,
    hasMoreUsers = false,
  } = GET_USERS_ACHIEVEMENTS[moduleName]({
    managersDropdownList,
    managerUserId,
    Recurrence,
    isDrillDown,
    setGoalUser,
    ShouldFetch,
    setShouldFetch,
    templateId,
    programId,
    User,
    isRollUp,
    isSalesUser,
    isIncentiveApprover,
  });

  const hideBreakdownTable =
    (Recurrence?.Status || Recurrence?.RecurrenceStatus) ===
      PROGRAM_STATUS_TYPES.YETTOSTART && isIncentive;
  const {
    data: usersAttribute,
    loading: usersAttributeLoading,
    error: usersAttributeError,
  } = useGetUserAttributes({ skip: isSalesUser });
  const {
    data: drilldownData,
    loading: drilldownLoading,
    error: drilldownError,
    refetch,
    fetchMore,
    networkStatus,
  } = useGetAchievementDrilldown(
    {
      variables: getVariables({
        TemplateId: templateId,
        ProgramId: programId,
        isIncentive,
        After: 0,
        managerUserId,
        ...attributes,
      }),
      skip: !Recurrence || !User || !Rule || hideBreakdownTable,
      notifyOnNetworkStatusChange: true,
    },
    {
      isIncentive,
    }
  );

  useEffect(() => {
    if (templateDetails?.GoalRules?.length === 1) {
      setSelectedRule(templateDetails?.GoalRules[0]);
    } else if (templateDetails?.GoalRules?.length > 1 && !Rule) {
      setSelectedRule({ RuleName: "All", RuleId: "" });
    }
  }, [setSelectedRule, templateDetails?.GoalRules, Rule]);

  useEffect(() => {
    if (ShouldFetch && !alreadyFetched && !usersListWithAchievementsLoading) {
      refetch(
        getVariables({
          TemplateId: templateId,
          ProgramId: programId,
          isIncentive,
          After: 0,
          managerUserId,
          ...attributes,
        })
      );
      setShouldFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ShouldFetch, attributes]);

  const usersAttributeList = {};

  const attributeArray = usersAttribute?.getUserAttributesData?.UserAttributes
    ?.length
    ? [
        ...usersAttribute?.getUserAttributesData?.UserAttributes,
        ...DEFAULT_DRILLDOWN_ATTRIBUTES,
      ]
    : DEFAULT_DRILLDOWN_ATTRIBUTES;

  const setUserAttribute = ({ SchemaName, DisplayName }) => {
    const shouldExclude =
      templateDetails?.RulesetType === RULESET_TYPES.ADVANCED
        ? STANDARD_RULESET_DRILLDOWN_ATTRIBUTES.includes(SchemaName)
        : false;

    if (!EXCLUDE_DRILLDOWN_ATTRIBUTES.includes(SchemaName) && !shouldExclude)
      usersAttributeList[SchemaName] = {
        DisplayName,
        isVisible: FieldsOrder?.includes(SchemaName),
      };
  };
  attributeArray.forEach(setUserAttribute);

  const achievementDrilldownData = drilldownData?.[apiName]?.response;
  const newFilteredTableData =
    achievementDrilldownData?.Users?.map((user) => {
      const { RuleId } = user;
      const rule = templateDetails?.GoalRules?.find((r) => r.RuleId === RuleId);
      const ifOpportunity = rule?.Entity?.opportunity;
      let ifOpportunityDisplayName = null;
      if (ifOpportunity) {
        ifOpportunityDisplayName =
          ifOpportunity.length === 1
            ? ifOpportunity[0]?.DisplayName
            : "Multiple Opportunities";
      }
      const ifActivity = rule?.Entity?.activity?.DisplayName ?? null;
      return {
        ...user,
        ruleId: rule?.RuleId,
        ruleName: rule?.RuleName,
        ifOpportunity: ifOpportunityDisplayName,
        ifActivity,
      };
    }) || [];

  const handleDoneButton = (selectedArr) => {
    refetch(
      getVariables({
        TemplateId: templateId,
        ProgramId: programId,
        isIncentive,
        After: 0,
        managerUserId,
        ...attributes,
        FieldsOrder: selectedArr,
      })
    );
  };

  const onFetchMore = () => {
    const { After } = achievementDrilldownData;
    fetchMore({
      variables: getVariables({
        TemplateId: templateId,
        ProgramId: programId,
        isIncentive,
        After,
        managerUserId,
        ...attributes,
      }),
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        const { response: prevResponse } = prevResult[apiName];
        const { response: fetchMoreResponse } = fetchMoreResult[apiName];
        fetchMoreResult[apiName].response.Users = [
          ...prevResponse.Users,
          ...fetchMoreResponse.Users,
        ];
        return fetchMoreResult;
      },
    });

    if (loadMoreButtonRef.current)
      loadMoreButtonRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const disabledLoadMore = !achievementDrilldownData?.NextPageExists;
  const hideUserDropDown =
    isSalesUser &&
    (moduleName === APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.name
      ? !isIncentiveApprover
      : true);
  return {
    attributes: {
      ...attributes,
      FieldsOrder: FieldsOrder?.filter((field) =>
        templateDetails?.RulesetType === RULESET_TYPES.ADVANCED
          ? !STANDARD_RULESET_DRILLDOWN_ATTRIBUTES.includes(field)
          : true
      ),
    },
    actions,
    usersListWithAchievements: {
      data: usersListWithAchievements,
      loading: usersListWithAchievementsLoading,
      onFetchMore: onFetchMoreUserList,
      error: usersListWithAchievementsError,
      hasMoreUsers,
      hideUserDropDown,
    },
    userAttributes: {
      data: usersAttributeList,
      loading: usersAttributeLoading,
      error: usersAttributeError,
      handleDoneButton,
    },
    templateDetails: {
      data: templateDetails,
      loading: templateDetailsLoading,
      error: templateDetailsError,
    },
    achievementDrilldown: {
      data: newFilteredTableData,
      dataNotAvailable: !Object.keys(achievementDrilldownData || {}).includes(
        "Users"
      ),
      userNotPartOfRecurrence: usersListWithAchievements?.GoalValue === null,
      firstFetchLoading:
        (networkStatus !== NetworkStatus.ready &&
          networkStatus !== NetworkStatus.fetchMore) ||
        templateDetailsLoading,
      nextFetchLoading: drilldownLoading || templateDetailsLoading,
      error: drilldownError,
      totalItems: achievementDrilldownData?.TotalUsers,
      itemsLoaded: achievementDrilldownData?.Users?.length,
      lastUpdated: achievementDrilldownData?.LastUpdated,
      onFetchMore,
      disabledLoadMore,
      loadMoreButtonRef,
      hideBreakdownTable,
    },
    isSalesUser,
    modalRef,
  };
};

export default useAchievementbreakdown;
