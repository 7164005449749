import React from "react";

const SvgAvatar12 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="btmx__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .btmx__cls-1{fill:#fff}.btmx__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="btmx__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.01-.03.03-.05.06-.69.7-1.39 1.4-2.1 2.08a81.644 81.644 0 0 1-16.29 12.08c-.93.52-1.87 1.03-2.83 1.51a80.79 80.79 0 0 1-25.71 8.14c-.99.13-1.98.25-2.98.35-2.27.24-4.57.38-6.9.42-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-12.71-.23-24.72-3.37-35.37-8.79-.45-.22-.9-.45-1.34-.69-2.4-1.28-4.73-2.67-6.97-4.18-.04-.03-.08-.05-.12-.08-.41-.28-.82-.56-1.23-.85-.6-.41-1.19-.83-1.78-1.28-.2-.13-.39-.28-.59-.44-.2-.14-.41-.29-.61-.45-.5-.39-1-.78-1.5-1.18a80.54 80.54 0 0 1-5.21-4.56c-.71-.68-1.41-1.38-2.1-2.08-.02-.03-.03-.03-.05-.05C8.78 123.47 0 103.48 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="btmx__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="btmx__cls-2"
        />
        <path
          d="M113.31 48.88c-.51-2.04-1.18-4.04-1.96-5.89-.04-.2-.12-.39-.2-.48-3.77-8.68-12-19.03-29.75-19.03S55.46 33.79 51.68 42.47c-.12.18-.21.38-.25.59-.79 1.89-1.44 3.85-1.93 5.83-6.21 3.41-9.63 7.82-9.63 12.43 0 4.21 2.72 8.14 7.87 11.37l.29.31.23-3.35-.36-.23c-2.77-1.79-4.78-4.36-4.78-8.1 0-8.63 17.53-15.92 38.28-15.92s38.28 7.29 38.28 15.92c0 3.71-2.01 6.11-4.79 7.91l-.36.23.23 3.54.29-.31c5.15-3.26 7.88-7.19 7.88-11.38 0-4.61-3.42-9.02-9.63-12.43Zm-58.99-4.41c1.84-1.1 9.86-5.48 22.04-6.31l-2.16 4.26c-7.85.6-15 2.19-20.8 4.59.28-.86.58-1.71.92-2.54Zm52.01-4.42c-7.14-2.9-14.38-4.62-21.54-5.12l-1.94-3.83c-.26-.52-.82-.84-1.45-.84s-1.19.32-1.45.84l-1.89 3.72c-9.69.36-17.09 2.89-21.31 4.76 5.19-8.53 13.47-12.85 24.64-12.85s19.77 4.48 24.93 13.33ZM81.4 35.43l3.44 6.78a97.241 97.241 0 0 0-6.87 0l3.44-6.78Zm28.01 11.59c-5.8-2.41-12.96-3.99-20.81-4.6l-2.07-4.07c7.33.76 14.72 2.84 21.99 6.19.32.81.62 1.64.89 2.47ZM34.89 148.19a1.5 1.5 0 0 1 2.07-.39c.67.45 1.35.89 2.04 1.32.89.56 1.79 1.11 2.71 1.64v-26.11c0-.26-.02-.51-.08-.75-.07-.48-.25-.94-.51-1.36a3.66 3.66 0 0 0-.61-.8c-.15-.15-.3-.28-.46-.39-.72-.55-1.58-.84-2.49-.84a4.15 4.15 0 0 0-4.15 4.14v21.37s0 .08-.01.11c-.02.29-.12.56-.29.78l-.59-.44c-.2-.14-.41-.29-.61-.45-.5-.39-1-.78-1.5-1.18v-20.19c0-3.93 3.21-7.14 7.15-7.14 1.91 0 3.71.75 5.05 2.09.42.41.79.88 1.08 1.4.66 1.05 1.02 2.33 1.02 3.65v28.65c0 .25-.06.48-.16.69-.45-.22-.9-.45-1.34-.69-2.4-1.28-4.73-2.67-6.97-4.18l-1.35-.93Z"
          className="btmx__cls-1"
        />
        <path
          d="M31.92 129.21c-.52 0-1.03-.27-1.3-.76-.41-.72-.16-1.64.56-2.05l9.26-5.28c.72-.41 1.63-.16 2.05.56.41.72.16 1.64-.56 2.05l-9.26 5.28c-.23.13-.49.2-.74.2ZM31.91 135.14c-.67 0-1.29-.46-1.46-1.14-.2-.8.3-1.62 1.1-1.81l11.3-2.77c.8-.2 1.62.29 1.81 1.1.2.8-.3 1.62-1.1 1.81l-11.3 2.77c-.12.03-.24.04-.36.04ZM32.62 142.41c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h10.59c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H32.62ZM34.89 148.19c-.6-.41-1.19-.83-1.78-1.28l-.59-.44c.24-.21.54-.33.88-.34h9.81c.83 0 1.5.68 1.5 1.5s-.67 1.5-1.5 1.5h-6.97l-1.35-.93ZM118.47 153.87c-.11-.2-.17-.44-.17-.68V142.4H92.76v19.61l-2.98.35c-.02-.05-.02-.1-.02-.16v-21.3c0-.82.67-1.5 1.5-1.5h28.54c.83 0 1.5.68 1.5 1.5v11.46l-2.83 1.51Z"
          className="btmx__cls-1"
        />
        <path
          d="M108.34 149.12H91.26c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h17.08c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5Z"
          className="btmx__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar12.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar12;
