import React from "react";
import "./criteriaList.scss";
import CriteriaListItem from "./CriteriaListItem";
import LoadingCriteriaList from "./LoadingCriteriaList";

const CriteriaList = (props) => {
  const { loading, emptyState, data, icon, onClick } = props;

  if (loading) return <LoadingCriteriaList />;

  if (emptyState || !data || data.length === 0) return <div>Empty state</div>;

  return (
    <div className="criteria-list">
      {data.map((item, idx) => (
        <CriteriaListItem key={idx} icon={icon} onClick={onClick} {...item} />
      ))}
    </div>
  );
};

export default CriteriaList;
