import React from "react";
import { Image } from "src/components";
import DoodleWhiteBackgroundImg from "./DoodleWhiteBackground.png";

const DoodleWhiteBackground = (props) => (
  <Image
    src={DoodleWhiteBackgroundImg}
    alt="DoodleWhiteBackgroundImg"
    {...props}
  />
);

export default DoodleWhiteBackground;
