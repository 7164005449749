import CryptoJS from "crypto-js";

// Redirect the user to the specified URL
export const redirectToUrl = (url) => {
  window.location.href = url.toString();
};

// Encode a Uint8Array to base64 URL format
export const base64UrlEncode = (array) => {
  const base64 = btoa(String.fromCharCode(...array));
  return base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
};

// Generate a random code verifier using CryptoJS
export const generateRandomVerifier = () => {
  const randomBytes = CryptoJS.lib.WordArray.random(16);
  return randomBytes.toString(CryptoJS.enc.Base64);
};

export const generateRandomState = () => {
  const randomBytes = CryptoJS.lib.WordArray.random(16);
  return randomBytes.toString(CryptoJS.enc.Hex);
};

// Calculate the SHA-256 hash of the input using CryptoJS
export const calculateSHA256 = (input) => {
  const hash = CryptoJS.SHA256(input);
  return hash.toString();
};

// verify jwt token
export const verifyToken = (token) => {
  const base64Url = token?.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  const payload = JSON.parse(atob(base64));
  const exp = payload.exp;
  const now = Date.now() / 1000;
  return now < exp;
};

export const decodeBase64 = (data) => {
  return Buffer.from(data, "base64").toString("ascii");
};

export const getOrgCode = (token) => {
  const base64Url = token?.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  const payload = JSON.parse(atob(base64));
  const orgCode = payload.org_code;
  return orgCode;
};

export const getInitials = ({ firstName, lastName }) => {
  const firstInitial = firstName?.charAt(0) || "";
  const lastInitial = lastName?.charAt(0) || "";
  return `${firstInitial}${lastInitial}`;
};
