import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_GOAL_MANAGERS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getGoalManagers {
    getGoalManagers {
      ... on GoalManagersResponse {
        success
        message
        response {
          ManagerName
          EmailAddress
          UserID
          Reportees
          __typename
        }
        __typename
      }
      ...errorResponseFragment
      __typename
    }
  }
`;

export const useGetGoalManagers = (queryOptions) => {
  return useQuery(GET_GOAL_MANAGERS_QUERY, queryOptions);
};
