import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { useGetNonMonetaryRewardsDropdownList } from "src/api/useCustomRewardConfig";
import {
  BackToTop,
  MobileFilterBarFilters,
  MobileFilterBarIcons,
  useFilter,
} from "src/components";
import { FilterContainer } from "src/components/container/filterContainer";
import { ModalContainer } from "src/components/container/modalContainer";
import { SvgFilterModern } from "src/icons";
import RewardCardBody from "./RewardCardBody";
import { REWARDS_FILTERS_SEARCH } from "./data";

const RewardsDetailModal = (props) => {
  const { show, handleClose, payload } = props;
  const modalRef = useRef(null);

  const {
    fieldName,
    userId,
    programId,
    recurrenceId,
    featureType,
    AllDateRange,
    TimeFrequency,
    cycleType,
    IsUserIdManager,
  } = payload || {};

  const defaultRewardValue = {
    label: "All",
    value: "ALL",
  };
  const { filtersState, ...rest } = useFilter([
    REWARDS_FILTERS_SEARCH,
    {
      key: "reward",
      defaultValue: defaultRewardValue,
    },
  ]);

  const getQuery = (isDropdown = false) => {
    const queryDetails = {
      FieldName: fieldName,
      UserId: userId,
      ProgramId: programId,
      RecurrenceId: recurrenceId,
      FeatureType: featureType,
      AllDateRange,
      TimeFrequency,
      CycleType: cycleType,
      IsUserIdManager,
    };
    return isDropdown
      ? queryDetails
      : {
          ...queryDetails,
          RewardId:
            filtersState.reward.value === defaultRewardValue.value
              ? undefined
              : filtersState.reward.value,
          Search: filtersState.search,
        };
  };

  const {
    data: { nonMonetaryRewardsDropdownList } = {
      nonMonetaryRewardsDropdownList: {},
    },
  } = useGetNonMonetaryRewardsDropdownList({
    variables: {
      Query: getQuery(true),
    },
    skip: !show,
    fetchPolicy: "network-only",
  });

  const customRewardsList =
    nonMonetaryRewardsDropdownList?.response?.rewardsList || [];

  const rewardsList = [
    defaultRewardValue,
    ...customRewardsList.map(({ RewardId, RewardName }) => ({
      label: RewardName,
      value: RewardId,
    })),
  ];

  const REWARDS_FILTERS = [
    REWARDS_FILTERS_SEARCH,
    {
      label: "Filter",
      key: "filter",
      icon: SvgFilterModern,
      component: FilterContainer,
      props: ({
        filter,
        filtersState,
        filtersToggleState,
        toggleFilter,
        handleBulkUpdate,
      }) => ({
        title: "Filters",
        show: filtersToggleState[filter.key],
        onHide: () => toggleFilter(filter.key),
        options: filter.filters,
        filter: filtersState,
        setFilter: (value) => {
          handleBulkUpdate(value);
        },
      }),
      filters: [
        {
          label: "Reward",
          defaultValue: defaultRewardValue,
          key: "reward",
          options: rewardsList,
        },
      ],
    },
  ];

  return (
    <ModalContainer
      show={show}
      onHide={handleClose}
      title="Reward Details"
      showBackButton={false}
      showCloseButton
      disableFooter
    >
      <Modal.Body className="p-3 overflow-y-auto mh-100" ref={modalRef}>
        <div className="flex-between">
          <div className="fs-14 semi-bold">Rewards</div>
          <div>
            <MobileFilterBarIcons
              filters={REWARDS_FILTERS}
              filtersState={filtersState}
              {...rest}
            />
          </div>
        </div>
        <div>
          <MobileFilterBarFilters
            filters={REWARDS_FILTERS}
            filtersState={filtersState}
            {...rest}
          />
        </div>
        <RewardCardBody {...{ show, getQuery }} />
        <BackToTop modalRef={modalRef} />
      </Modal.Body>
    </ModalContainer>
  );
};

export default RewardsDetailModal;
