import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormInput } from "src/components/common/formElements";
import "./createInput.scss";

const CreateInput = (props) => {
  const {
    id,
    label,
    placeholder,
    as,
    maxLength,
    regex,
    required,
    height,
    value,
    setValue,
    warning,
    setWarning,
    name,
    onBlur,
    readOnly,
    warningMessage,
    className,
  } = props;
  useEffect(() => {
    if (setWarning)
      setWarning(as === "input" && value && !value.match(regex) && !readOnly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, readOnly, as === "input"]);
  const remainingChar = maxLength - value?.length;
  return (
    <div>
      {label && (
        <label className="semi-bold fs-14 w-100">
          <div className={`pb-3 ${remainingChar <= 15 ? "flex-between" : ""}`}>
            <span>{label}</span>
            {remainingChar <= 15 && (
              <span
                className={`fs-12 fc-grey-primary ${
                  remainingChar === 0 ? "text-danger" : ""
                }`}
              >
                {remainingChar} {t("COMMON.LABELS.CHARACTERS_REMAINING")}
              </span>
            )}
          </div>
        </label>
      )}
      <div data-testid="input-click" className={className} style={{ height }}>
        <FormInput
          as={as}
          id={id}
          className={
            as === "textarea"
              ? "text-area-height"
              : warning
              ? "border-danger"
              : ""
          }
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
          required={required}
          name={name}
          disabled={readOnly}
        />
        {warning && (
          <div className="text-danger fs-12">
            {warningMessage || t("RULEBOOK.MESSAGES.UNSUPPORTED_CHARACTER")}
          </div>
        )}
      </div>
    </div>
  );
};
CreateInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  as: PropTypes.oneOf(["input", "textarea"]),
  maxLength: PropTypes.number,
  regex: PropTypes.instanceOf(RegExp),
  required: PropTypes.bool,
  height: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  warning: PropTypes.bool,
  setWarning: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  warningMessage: PropTypes.string,
  className: PropTypes.string,
};
CreateInput.defaultProps = {
  id: "input-field",
  label: "Label",
  placeholder: "Placeholder",
  as: "input",
  maxLength: 100,
  required: false,
  onBlur: () => {},
  readOnly: false,
  warningMessage: null,
  className: "pb-3",
};
export default CreateInput;
