import React from "react";
import { GlassBanner } from "src/components/common/atoms";
import { classNames } from "src/helpers";

const Overlay = ({ className, children }) => (
  <GlassBanner className={classNames(["leaderboard-grid-overlay", className])}>
    {children}
  </GlassBanner>
);

export default Overlay;
