import React from "react";
import { range } from "src/helpers";
import { Shimmer } from "../shimmer";

const LoadingCriteriaList = () => {
  return (
    <div className="criteria-list">
      {range(0, 5).map((item, idx) => (
        <div className="p-3 flex-between">
          <div>
            <div className="my-2">
              <Shimmer width="200px" />
            </div>
            <div className="my-2">
              <Shimmer width="100px" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoadingCriteriaList;
