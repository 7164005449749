import React from "react";

const SvgAvatar43 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="l7qp__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .l7qp__cls-1{fill:#fff}.l7qp__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="l7qp__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="l7qp__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="l7qp__cls-2"
        />
        <path
          d="M101.28 35.62H62.76c-2.14 0-4.19.86-5.7 2.37a8.113 8.113 0 0 0-2.37 5.7v7.49a12.432 12.432 0 0 0 17.87 10.46c3.74-1.83 6.32-5.4 6.87-9.53a3.94 3.94 0 0 1 2.8-.99c.88.07 1.7.42 2.37.99.57 4.11 3.15 7.67 6.88 9.49 3.73 1.82 8.12 1.65 11.71-.44 3.58-2.09 5.89-5.83 6.15-9.98v-7.49c0-2.14-.85-4.19-2.37-5.7a8.113 8.113 0 0 0-5.7-2.37Zm6.2 14.77v.53c-.18 3.56-2.14 6.8-5.22 8.6-3.08 1.8-6.86 1.93-10.06.33a10.565 10.565 0 0 1-5.78-8.23c0-.27-.12-.53-.33-.71a5.99 5.99 0 0 0-3.74-1.69c-1.67-.08-3.29.56-4.44 1.76a.92.92 0 0 0-.24.54c-.37 3.57-2.52 6.72-5.72 8.35-3.2 1.63-7.01 1.52-10.12-.29-3.1-1.81-5.07-5.07-5.23-8.66v-7.24c0-1.64.66-3.22 1.82-4.38a6.209 6.209 0 0 1 4.38-1.82h38.48c1.64 0 3.22.66 4.38 1.82a6.209 6.209 0 0 1 1.82 4.38v6.7Z"
          className="l7qp__cls-1"
        />
        <path
          d="M82.2 67.11c-2.93 0-5.76 1.09-7.94 3.06a11.88 11.88 0 0 0-3.87 7.58c-6.38.69-14.07 0-17.44-3.22-3.09-2.92-4.97-5.09-4.97-10.69V32.61c0-1.17-.62-2.25-1.63-2.83a3.29 3.29 0 0 0-3.27 0 3.259 3.259 0 0 0-1.63 2.83v31.23c0 8.11 3.31 11.92 7.01 15.43 4.59 4.34 11.98 5.26 17.37 5.26 1.91 0 3.82-.11 5.72-.34 1.25 2.55 3.37 4.56 5.97 5.68a11.853 11.853 0 0 0 14.75-4.63 11.877 11.877 0 0 0-2.34-15.29c-2.15-1.85-4.9-2.86-7.73-2.86v.02ZM65.82 82.67c-5.05 0-11.93-.83-16.09-4.75-3.51-3.32-6.42-6.68-6.42-14.06V32.61c0-.77.63-1.4 1.4-1.4s1.4.63 1.4 1.4v31.23c0 6.29 2.3 8.95 5.56 12.04 4 3.74 12.35 4.39 18.68 3.74.04.95.2 1.89.47 2.8-1.66.17-3.32.26-4.99.26Zm16.38 6.38c-2.65 0-5.2-1.05-7.08-2.92a9.966 9.966 0 0 1-2.94-7.07c0-2.65 1.04-5.2 2.91-7.08s4.41-2.94 7.06-2.95 5.2 1.04 7.08 2.9c1.88 1.87 2.95 4.41 2.96 7.06 0 2.66-1.04 5.22-2.91 7.1a9.997 9.997 0 0 1-7.09 2.95Z"
          className="l7qp__cls-1"
        />
        <path
          d="M82.2 72.91c-1.61 0-3.15.64-4.29 1.78a6.063 6.063 0 0 0 0 8.58 6.063 6.063 0 0 0 8.58 0 6.063 6.063 0 0 0 0-8.58 6.063 6.063 0 0 0-4.29-1.78Zm0 10.27a4.201 4.201 0 1 1 4.2-4.2c0 1.11-.45 2.18-1.23 2.97-.79.79-1.85 1.23-2.97 1.23Z"
          className="l7qp__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar43.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar43;
