import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import OpenAceApp from "./OpenAceApp";

const OpenAceAppWrapper = ({ isOpenAceUser, children }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <OpenAceApp isOpenAceUser={isOpenAceUser}>{children}</OpenAceApp>
    </QueryClientProvider>
  );
};

export default OpenAceAppWrapper;
