import React from "react";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SvgAlert,
  SvgClose,
  SvgErrorToast,
  SvgInfo,
  SvgSuccessToast,
} from "src/icons";
import "./toaster.scss";

const ToasterConfigurations = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  closeButton: false,
  transition: Slide,
};

toast.configure();

const type = {
  success: { borderColor: "#27AE60", icon: <SvgSuccessToast /> },
  info: { borderColor: "#309AFC", icon: <SvgInfo /> },
  warning: { borderColor: "#F39C29", icon: <SvgAlert /> },
  error: { borderColor: "#ED4141", icon: <SvgErrorToast /> },
};

const ToastContainer = (props) => (
  <div
    className="toaster-container"
    style={{ borderColor: type[props.type].borderColor }}
  >
    <div className="d-flex justify-content-start align-items-center fs-16">
      <div>{type[props.type].icon}</div>
      <p className="toast-message semi-bold fs-13 long-text-break">
        {props.message}
      </p>
      <div className="ml-auto">
        <SvgClose />
      </div>
    </div>
  </div>
);

const toaster = ({ message, type }) =>
  toast(<ToastContainer message={message} type={type} />, {
    ...ToasterConfigurations,
    toastId: message,
  });

export default toaster;
