import { gql, useQuery } from "src/api/client";

const GET_MY_LEADERBOARD_LIST = gql`
  query getMyLeaderboardsList(
    $limit: Float
    $after: String
    $query: MyLeaderboardsListQuery
  ) {
    getMyLeaderboardsList(limit: $limit, after: $after, query: $query) {
      ... on MyLeaderboardsListResponse {
        success
        response {
          Leaderboards {
            LeaderboardId
            Name
            Type
            IsGroupBased
            GroupDetails {
              GroupId
              GroupName
              GroupTarget
              GroupAchieved
            }
            Status
            RecurrenceDefinition {
              FirstRecurrenceStartDate
              LastRecurrenceEndDate
              RecurrencePeriod
            }
            RecurrenceTimeLeft
            CurrentRecurrence
            CurrentRecurrenceCycle
            TotalCycles
            CycleType
            CustomType
            UserRank
            GoalValue
            UserAchieved
            UsersAchievement {
              Name
              Rank
              UserId
              GoalValue
              UserAchieved
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetLeaderboardList = (queryOptions) => {
  return useQuery(GET_MY_LEADERBOARD_LIST, queryOptions);
};
