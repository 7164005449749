import PropTypes from "prop-types";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { CalendarHeader, CustomInput } from "src/components/common/atoms";
import { DATE_FORMAT_WITH_WEEKDAY, YEAR_OPTIONS_COUNT } from "src/constants";
import {
  getDayClassName,
  getWeekDayClassName,
  getYear,
  isBefore,
} from "src/helpers";

const Calendar = (props) => {
  const {
    id,
    inputId,
    today,
    minDate,
    maxDate,
    openToDate,
    selected,
    dateStart,
    dateEnd,
    filterWeekDay,
    recurrencePeriod,
    showYearPicker,
    showMonthYearPicker,
    showQuarterYearPicker,
    inputFormat,
    filterDate,
    disabled,
    onChange,
    className,
    popperClassName,
    calendarStartDay,
    highlightDates,
    onEnter,
    onExit,
    onDayMouseEnter,
    handleMonthLeave,
    onMonthMouseLeave,
    onCalendarClose,
    calPlacement,
    placeholder,
    ...rest
  } = props;
  const curr_year = getYear(today);
  const max_year = curr_year + YEAR_OPTIONS_COUNT - 1;
  const MAX_DATE = new Date(max_year, 11, 31);

  // # Custom Day Component
  const renderDayContents = (day) => {
    return <span className="cal-day">{day}</span>;
  };

  // # Custom Calendar Header
  const renderCustomHeader = (props) => (
    <CalendarHeader
      {...props}
      today={today}
      showMonthYearPicker={showMonthYearPicker}
      showQuarterYearPicker={showQuarterYearPicker}
      showYearPicker={showYearPicker}
      minDate={minDate}
      maxDate={maxDate}
      openToDate={openToDate}
    />
  );

  return (
    <ReactDatePicker
      {...rest}
      // Fixed Datepicker Props
      dayClassName={(day) => getDayClassName({ day, calendarStartDay, today })}
      weekDayClassName={(day) =>
        getWeekDayClassName({
          day,
          filterWeekDay,
        })
      }
      popperModifiers={[{ name: "flip", enabled: false }]}
      onDayMouseEnter={(date) => {
        onEnter && onEnter(date);
        onDayMouseEnter && onDayMouseEnter(date);
      }}
      handleMonthLeave={() => {
        onExit && onExit();
        handleMonthLeave();
      }}
      onMonthMouseLeave={() => {
        onExit && onExit();
        onMonthMouseLeave();
      }}
      onCalendarClose={() => {
        onExit && onExit();
        onCalendarClose();
      }}
      showPopperArrow={false}
      showFullMonthYearPicker
      fixedHeight
      calendarStartDay={calendarStartDay}
      yearItemNumber={50}
      renderCustomHeader={showYearPicker ? () => <></> : renderCustomHeader}
      renderDayContents={renderDayContents}
      highlightDates={highlightDates}
      openToDate={selected || openToDate}
      readOnly={disabled}
      // Calendar Props
      id={id}
      className={className}
      popperClassName={popperClassName}
      popperPlacement={calPlacement}
      selected={selected}
      minDate={minDate}
      maxDate={
        maxDate ? (isBefore(maxDate, MAX_DATE) ? maxDate : MAX_DATE) : MAX_DATE
      }
      customInput={
        <CustomInput
          inputId={inputId}
          dateStart={dateStart}
          dateEnd={dateEnd}
          dateValue={selected}
          inputFormat={inputFormat}
          isDisabled={disabled}
          recurrencePeriod={recurrencePeriod}
          placeholderText={placeholder}
        />
      }
      showMonthYearPicker={showMonthYearPicker}
      showQuarterYearPicker={showQuarterYearPicker}
      showYearPicker={showYearPicker}
      onChange={onChange}
      filterDate={filterDate}
    />
  );
};

Calendar.propTypes = {
  id: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  today: PropTypes.instanceOf(Date),
  filterWeekDay: PropTypes.instanceOf(Date),
  selected: PropTypes.instanceOf(Date),
  dateStart: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  openToDate: PropTypes.instanceOf(Date),
  recurrencePeriod: PropTypes.oneOf([
    "Daily",
    "Weekly",
    "Biweekly",
    "Monthly",
    "Quarterly",
    "Yearly",
  ]),
  className: PropTypes.string,
  popperClassName: PropTypes.string,
  calendarStartDay: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  highlightDates: PropTypes.instanceOf(Array),
  showMonthYearPicker: PropTypes.bool,
  showQuarterYearPicker: PropTypes.bool,
  showYearPicker: PropTypes.bool,
  disabled: PropTypes.bool,
  inputFormat: PropTypes.string,
  onChange: PropTypes.func,
  filterDate: PropTypes.func,
  onDayMouseEnter: PropTypes.func,
  handleMonthLeave: PropTypes.func,
  onMonthMouseLeave: PropTypes.func,
  onCalendarClose: PropTypes.func,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
};

Calendar.defaultProps = {
  id: "cal",
  today: new Date(),
  calendarStartDay: 1,
  inputFormat: DATE_FORMAT_WITH_WEEKDAY,
  disabled: false,
  showMonthYearPicker: false,
  showQuarterYearPicker: false,
  showYearPicker: false,
  onDayMouseEnter: () => {},
  handleMonthLeave: () => {},
  onMonthMouseLeave: () => {},
  onCalendarClose: () => {},
};

export default Calendar;
