import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgEditBackground = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <defs>
        <clipPath id="5qiw__a">
          <path d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#5qiw__a)">
        <g>
          <path fill="none" d="M0 0h14v14H0z" />
          <g>
            <path
              fill="#dbeffd"
              d="M9.867 1.443 1.878 9.278l-.405 3.238 3.295-.412 7.8-7.91Z"
            />
            <path
              fill={color}
              d="m12.628 3.182-1.81-1.809a1.156 1.156 0 0 0-1.635 0L1.608 8.947a.465.465 0 0 0-.132.266l-.442 3.221a.465.465 0 0 0 .523.523l3.225-.438a.465.465 0 0 0 .266-.132l7.574-7.574a1.156 1.156 0 0 0 0-1.635Zm-8.121 8.442-2.465.335.335-2.465 5.367-5.368 2.13 2.13Zm7.464-7.463-1.437 1.438-2.13-2.13L9.84 2.034a.227.227 0 0 1 .321 0l1.809 1.809a.227.227 0 0 1 0 .321Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
SvgEditBackground.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 14,
  width: 14,
};
export default SvgEditBackground;
