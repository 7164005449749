import { gql, useQuery } from "src/api/client";

const GET_PAYOUT_PROGRAM_RECURRENCE_SUMMARY_QUERY = gql`
  query payoutProgramRecurrenceSummary($programId: ID) {
    payoutProgramRecurrenceSummary(programId: $programId) {
      ... on PayoutProgramRecurrenceSummaryResponse {
        success
        message
        response {
          recurrences {
            recurrenceId
            cycle
            pendingRequests
            IsGroupBased
          }
        }
      }
    }
  }
`;

export const useGetPayoutProgramRecurrenceSummary = (queryOptions) => {
  return useQuery(GET_PAYOUT_PROGRAM_RECURRENCE_SUMMARY_QUERY, queryOptions);
};
