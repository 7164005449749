import { t } from "i18next";
import React, { Fragment } from "react";
import { useGetUserMetricAchievements } from "src/api/useIncentiveDashboard";
import { DisplayFormula } from "src/apps/incentives/components";
import { TOKEN_TYPE } from "src/apps/incentives/components/formulaBuilder/formulaParser/formulaNamespace";
import { getMetricsFromCondition } from "src/apps/incentives/utils";
import { FormButton, FormTooltip } from "src/components";
import { GOAL_ACHIEVEMENT_TYPE } from "src/constants";
import {
  NumFormatter,
  checkValue,
  classNames,
  getUserTimezone,
} from "src/helpers";
import { SvgLink } from "src/icons";
import { Shimmer } from "../shimmer";
import "./styles.scss";

const MetricAchievement = ({
  metric,
  index,
  programId,
  userId,
  recurrenceId,
  onTemplateSelection,
  allowDrillDown,
  mobileProgramDetails,
  TemplateId,
  isUserPartOfGoal,
  goalValue,
  isFormula,
}) => {
  const isIndianTimezone = getUserTimezone();

  const goalAchievementType =
    !isFormula && metric?.fieldName === "GoalId"
      ? Object.keys(GOAL_ACHIEVEMENT_TYPE)[1]
      : metric?.GoalAchievementType;

  const { data, loading } = useGetUserMetricAchievements({
    variables: {
      Value: metric?.value,
      FieldName: metric?.fieldName,
      ProgramId: programId,
      RecurrenceId: recurrenceId,
      UserId: userId,
      Type: metric?.type,
      GoalAchievementType: goalAchievementType,
      ReporteesIncentiveValue: metric?.ReporteesIncentiveValue,
      SubGoal: {
        ConditionPattern: metric?.ConditionPattern,
        SubGoalConditions: metric?.SubGoalConditions,
        Id: metric?.Id,
      },
      Operand: metric?.tokenType,
    },
    skip: !recurrenceId || !userId || !programId,
    notifyOnNetworkStatusChange: true,
  });

  const achievedValue =
    data?.userMetricAchievements?.response?.userMetricAchievement;

  return (
    <Fragment key={index}>
      <div className="flex justify-content-between p-2 px-3 pr-5 h-40 fc-black">
        <DisplayFormula
          condition={{ Formula: [metric] }}
          variant="conditionList"
        />
        <div>
          {!loading ? (
            <>
              <FormButton
                variant="link"
                disabled={!allowDrillDown || !Boolean(achievedValue)}
                onClick={() => {
                  if (TemplateId) {
                    onTemplateSelection({
                      achievedValue,
                      templateId: TemplateId,
                      metric,
                      isFormula,
                    });
                  }
                }}
                className="metric-data flex center"
              >
                <FormTooltip
                  text={achievedValue}
                  disable={!checkValue(achievedValue)}
                  placement="bottom"
                >
                  <span
                    className={classNames([
                      "fc-black",
                      allowDrillDown && Boolean(achievedValue)
                        ? mobileProgramDetails
                          ? "text-underline"
                          : "condition-achieved-value"
                        : "",
                    ])}
                  >
                    {checkValue(achievedValue) ? (
                      <span className="semi-bold">
                        {NumFormatter(achievedValue, isIndianTimezone)}
                      </span>
                    ) : (
                      "-"
                    )}
                  </span>
                </FormTooltip>
                {!mobileProgramDetails &&
                  allowDrillDown &&
                  Boolean(achievedValue) && (
                    <SvgLink
                      height={"11px"}
                      width={"11px"}
                      className=" ml-1 view-condition-achievement-breakdown"
                    />
                  )}
              </FormButton>
            </>
          ) : (
            <Shimmer height="20px" width="50px" />
          )}
        </div>
      </div>
      <hr className="fc-grey w-100 my-3" />
    </Fragment>
  );
};

const ConditionBreakdown = ({
  condition,
  programId,
  userId,
  recurrenceId,
  onTemplateSelection,
  allowDrillDown,
  mobileProgramDetails,
  isUserPartOfGoal,
  goalValue,
  isFormula,
  templates,
  TemplateId,
}) => {
  const metrics = getMetricsFromCondition(condition);
  return (
    <div>
      <div className="fc-grey1 bg-grey5 p-2 px-3 flex justify-content-between fs-12 semi-bold mt-2">
        <span>{t("INCENTIVES.COMMON.LABELS.CONDITION_BREAKDOWN")}</span>
        <span className="mr-5">{t("INCENTIVES.COMMON.LABELS.VALUE")}</span>
      </div>
      <div className="flex flex-column fs-12 semi-bold mt-2">
        {metrics.map((metric, index) => {
          const metricTemplateId =
            metric?.tokenType === TOKEN_TYPE.METRIC &&
            metric?.fieldName === "TemplateId";
          const templateId =
            templates &&
            metricTemplateId &&
            templates.find(({ TemplateId }) => TemplateId === metric?.value)
              ?.TemplateId;

          return (
            <MetricAchievement
              metric={metric}
              index={index}
              programId={programId}
              userId={userId}
              recurrenceId={recurrenceId}
              onTemplateSelection={onTemplateSelection}
              allowDrillDown={
                allowDrillDown &&
                !metric?.SubGoalConditions &&
                (templateId || TemplateId)
              }
              TemplateId={templateId || TemplateId}
              mobileProgramDetails={mobileProgramDetails}
              isUserPartOfGoal={isUserPartOfGoal}
              goalValue={goalValue}
              isFormula={isFormula}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ConditionBreakdown;
