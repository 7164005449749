import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { LoadingTable } from "../loadingTable";

const LoadingSelectUsersLeaderboard = () => (
  <div className="mx-5 mt-45">
    <div className="flex-between">
      <div>
        <Shimmer width="150px" height="10px" />
        <div className="center mt-3">
          <div className="mr-3">
            <Shimmer width="200px" height="100px" />
          </div>
          <Shimmer width="200px" height="100px" />
        </div>
      </div>
      <div>
        <Shimmer width="150px" height="10px" />
        <div className="center mt-3">
          <div className="mr-3">
            <Shimmer width="200px" height="100px" />
          </div>
          <Shimmer width="200px" height="100px" />
        </div>
      </div>
    </div>
    <div className="border-b w-100 my-4" />
    <div className="center mt-3">
      <div className="mr-3">
        <Shimmer width="100px" height="40px" />
      </div>
      <div className="mr-3">
        <Shimmer width="100px" height="40px" />
      </div>
    </div>
    <div className="mt-3">
      <LoadingTable />
    </div>
  </div>
);
export default LoadingSelectUsersLeaderboard;
