import { gql, useQuery } from "src/api/client";

const GET_DASHBOARD_USERS_VIEW = gql`
  query dashboardUserView(
    $UserId: ID
    $limit: Float
    $query: PayoutUsersQuery
    $sortBy: SortBy
    $after: String
    $FeatureType: String!
  ) {
    dashboardUserView(
      UserId: $UserId
      limit: $limit
      query: $query
      sortBy: $sortBy
      after: $after
      FeatureType: $FeatureType
    ) {
      ... on DashboardUsersResponse {
        message
        success
        response {
          pageInfo {
            endCursor
            hasNextPage
            __typename
          }
          totalCount
          dashboardUsers {
            userId
            fullName
            emailAddress
            totalPendingPayout
            totalApprovedAmount
            totalEligibleAmount
            totalPaidOut
            TotalPaidOutRewards {
              RewardCount
              RewardCategory
            }
            TotalApprovedRewards {
              RewardCount
              RewardCategory
            }
            TotalEligibleRewards {
              RewardCount
              RewardCategory
            }
            TotalPendingPayoutRewards {
              RewardCount
              RewardCategory
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const useGetDashboardUserView = (queryOptions) => {
  return useQuery(GET_DASHBOARD_USERS_VIEW, queryOptions);
};
