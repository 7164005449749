import { CalendarContainer } from "src/components/container/calendarContainer";
import { getStartAndEndDate } from "src/helpers/programUtils";
import { SvgFilterModern } from "src/icons";

export const DASHBOARD_LANDING_PAGE_FILTERS = [
  {
    label: "Owner",
    key: "owner",
    defaultValue: null,
  },
  {
    label: "Filter",
    key: "filter",
    icon: SvgFilterModern,
    component: CalendarContainer,
    props: ({
      filter,
      filtersState,
      filtersToggleState,
      toggleFilter,
      handleBulkUpdate,
    }) => {
      return {
        title: "Filters",
        show: filtersToggleState[filter.key],
        onHide: () => toggleFilter(filter.key),
        options: filter.filters,
        filter: filtersState,
        setFilter: (value) => {
          handleBulkUpdate(value);
        },
      };
    },
    filters: [
      {
        label: "Time Frequency",
        defaultValue: {
          label: "Last 30 Days",
          value: 30,
          ...getStartAndEndDate(30),
        },
        key: "timeFrequency",
        options: [
          {
            label: "Last 30 Days",
            value: 30,
            ...getStartAndEndDate(30),
          },
          {
            label: "Last 90 Days",
            value: 90,
            ...getStartAndEndDate(90),
          },
          {
            label: "Last 180 Days",
            value: 180,
            ...getStartAndEndDate(180),
          },
          {
            label: "All Time",
            value: "ALL",
            ...getStartAndEndDate("ALL"),
          },
          {
            label: "Custom",
            value: "CUSTOM",
          },
        ],
      },
    ],
  },
];

export const DASHBOARD_VIEW = {
  USER_VIEW: "user_view",
  PROGRAM_VIEW: "program_view",
};

export const DASHBOARD_FILTER_BUTTONS = (isIncentive) => [
  {
    key: DASHBOARD_VIEW.USER_VIEW,
    label: "INCENTIVES.DASHBOARD.LABELS.USER_VIEW",
  },
  {
    key: DASHBOARD_VIEW.PROGRAM_VIEW,
    label: isIncentive
      ? "INCENTIVES.DASHBOARD.LABELS.PROGRAM_VIEW"
      : "LEADERBOARD.LABELS.BOARD_VIEW",
  },
];
