import { gql, useQuery } from "src/api/client";

export const GET_INCENTIVE_CYCLE_DETAILS_QUERY = gql`
  query getRecurrenceSummary($ProgramId: ID!, $FetchSubGoals: Boolean) {
    getRecurrenceSummary(ProgramId: $ProgramId, FetchSubGoals: $FetchSubGoals) {
      __typename
      ... on RecurrenceSummaryResponse {
        success
        message
        response {
          Recurrences {
            ProgramId
            RecurrenceId
            RecurrenceStartDate
            RecurrenceEndDate
            RecurrenceType
            TotalEligibleBenefit
            TotalUsers
            TotalCriteriaCount
            PendingApprovals
            TotalApprovals
            RecurrenceStatus
            Processed
            IsBufferCompleted
            IsCalculationProcessed
            InProgressFormulaChildProgram
            SubGoalFilters
            LinkedProgramsCount
            PayoutSchedule
            AdvancedFilters
            UpdatedAt
          }
          CurrentRecurrence {
            ProgramId
            RecurrenceEndDate
            RecurrenceId
            RecurrenceStartDate
            RecurrenceType
            CustomType
            TotalCriteriaCount
            TotalEligibleBenefit
            TotalUsers
            RecurrenceStatus
            Processed
            IsBufferCompleted
            IsCalculationProcessed
            InProgressFormulaChildProgram
            SubGoalFilters
            LinkedProgramsCount
            PayoutSchedule
            AdvancedFilters
            UpdatedAt
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetRecurrenceSummary = (queryOptions) => {
  return useQuery(GET_INCENTIVE_CYCLE_DETAILS_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
