import { gql, useQuery } from "src/api/client";

export const GET_APPROVAL_RECURRENCE_SUMMARY = gql`
  query approvalRecurrenceSummary($ProgramId: ID!) {
    approvalRecurrenceSummary(ProgramId: $ProgramId) {
      __typename
      ... on ApprovalRecurrenceSummaryResponse {
        success
        message
        response {
          Recurrences {
            ProgramId
            RecurrenceId
            RecurrenceStartDate
            RecurrenceEndDate
            RecurrenceType
            TotalEligibleBenefit
            TotalUsers
            IsGroupBased
            TotalCriteriaCount
            PendingApprovals
            TotalApprovals
            RecurrenceStatus
            Processed
            IsBufferCompleted
            IsCalculationProcessed
            __typename
          }
          CurrentRecurrence {
            ProgramId
            RecurrenceEndDate
            RecurrenceId
            RecurrenceStartDate
            RecurrenceType
            TotalCriteriaCount
            IsGroupBased
            TotalEligibleBenefit
            TotalUsers
            RecurrenceStatus
            Processed
            IsBufferCompleted
            IsCalculationProcessed
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
    }
  }
`;

export const useGetApprovalRecurrenceSummary = (queryOptions) => {
  return useQuery(GET_APPROVAL_RECURRENCE_SUMMARY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
