import { Shimmer } from "../../atoms";

const LoadingProgramDetailsView = () => (
  <>
    <div className="page-container">
      <div className="flex-column">
        <div className="d-flex justify-content-between py-3 ">
          <Shimmer width="200px" height="60px" />
          <Shimmer Shimmer width="300px" height="40px" />
        </div>
        <hr className="mt-2" />
        <Shimmer width="100%" height="100px" className="mt-4" />
        <Shimmer width="100%" height="100px" className="mt-4" />
        <div className="d-flex mt-4">
          <Shimmer width="150px" height="25px" />
          <Shimmer width="150px" height="25px" className="ml-4" />
        </div>
        <hr className="mt-2" />
      </div>
    </div>
  </>
);
export default LoadingProgramDetailsView;
