import React, { useState } from "react";
import {
  FormButton,
  FormTooltip,
  NonMonetaryLabel,
  RewardDetails,
  Shimmer,
} from "src/components";
import { capitalize } from "src/helpers";
import "./amountStatsCard.scss";

const AmountStatsCard = ({
  bgColor,
  text,
  amount,
  icon,
  loading,
  onClick,
  rewardsList,
  payloadForRewardsGrid,
}) => {
  const [showRewardGridModal, setShowRewardGridModal] = useState(false);
  const tooltipContent = (rewards) => {
    return (
      <div className="d-flex flex-wrap">
        {rewards.map((reward, index) => (
          <div className="d-flex mr-1">
            {reward.RewardCount} {capitalize(reward.RewardCategory)}
            {index === rewards.length - 1 ? "" : ","}
          </div>
        ))}
      </div>
    );
  };

  const formatNonMonetary = (rewards) => {
    if (rewards.length === 1) {
      return (
        <FormButton
          className="pl-1 truncate-rewards fc-blue pointer text-decorate max-w-200 fs-22"
          onClick={(e) => {
            e.stopPropagation();
            setShowRewardGridModal(true);
          }}
          variant="link"
        >
          {rewards[0].RewardCount} {capitalize(rewards[0].RewardCategory)}
        </FormButton>
      );
    }
    return (
      <FormButton
        className="pl-1 fc-blue pointer center text-nowrap text-decorate fs-22"
        onClick={(e) => {
          e.stopPropagation();
          setShowRewardGridModal(true);
        }}
        variant="link"
      >
        <span>
          {`${rewards[0].RewardCount} ${capitalize(
            rewards[0].RewardCategory
          )}`.substring(0, 15)}
        </span>
        <span>... +{rewards.length - 1}</span>
      </FormButton>
    );
  };

  return (
    <FormButton
      onClick={onClick}
      className="p-3 br-6 amount-stats-card h-100 w-100 text-left normal"
      style={{ backgroundColor: bgColor }}
      variant="link"
    >
      <div className="fs-14 fc-grey1">{text}</div>
      <div className="flex-between center">
        <div className="fs-22 semi-bold fc-black">
          {loading ? (
            <Shimmer width="180px" height="30px" />
          ) : (
            <NonMonetaryLabel
              amount={amount}
              nonMonetaryRewards={rewardsList || []}
              rewardCategoryList
              formatNonMonetary={() => (
                <FormTooltip text={tooltipContent(rewardsList)}>
                  {formatNonMonetary(rewardsList)}
                </FormTooltip>
              )}
            />
          )}
        </div>
        <RewardDetails
          show={showRewardGridModal}
          handleClose={() => setShowRewardGridModal(false)}
          payload={payloadForRewardsGrid}
        />
        <div className="icon">{icon}</div>
      </div>
    </FormButton>
  );
};

export default AmountStatsCard;
