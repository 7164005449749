import { t } from "i18next";
import { Autocomplete, Truncate } from "src/components";

function ProgramFilter({
  options,
  value,
  handleChange,
  startAdornment = t("INCENTIVES.COMMON.LABELS.PROGRAM"),
}) {
  return (
    <div>
      <label className="fs-12 semi-bold mb-8">{startAdornment}</label>
      <Autocomplete
        className="mr-3"
        options={options}
        value={value}
        searchKey="programName"
        disableCloseIcon
        isOptionEqualToValue={(option) => option.programId === value.programId}
        handleChange={handleChange}
        columns={[
          {
            label: startAdornment,
            key: "programName",
            style: { minWidth: "200px" },
            template: ({ value }) => {
              return (
                <div className="regular">
                  <Truncate text={value} maxWidth="200px" />
                </div>
              );
            },
          },
          {
            label: t("INCENTIVES.COMMON.LABELS.PENDING_REQUESTS"),
            key: "pendingRequests",
            style: { minWidth: "40px" },
            template: ({ value }) => {
              return <div className="semi-bold">{value}</div>;
            },
          },
        ]}
      />
    </div>
  );
}

export default ProgramFilter;
