import { gql, useMutation } from "src/api/client";

const DOWNLOAD_RECURRENCE_GOAL_EXPORT = gql`
  mutation downloadRecurrenceGoalExport(
    $GoalId: ID!
    $RecurrenceId: ID
    $ManagerUserId: ID
    $userAttributes: [AttributesData]
    $Query: userReportQueryInput
  ) {
    downloadRecurrenceGoalExport(
      GoalId: $GoalId
      RecurrenceId: $RecurrenceId
      ManagerUserId: $ManagerUserId
      userAttributes: $userAttributes
      Query: $Query
    ) {
      message
      urlData {
        url
        fileId
      }
    }
  }
`;

export const useDownloadRecurrenceGoalExport = () => {
  return useMutation(DOWNLOAD_RECURRENCE_GOAL_EXPORT);
};
