import React from "react";
const SvgAdvancedFilters = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M8.222 16v-4.667h1.556v1.556H16v1.555H9.778V16zM2 14.444V12.89h4.667v1.555zm3.111-3.11V9.777H2V8.222h3.111V6.667h1.556v4.666zm3.111-1.556V8.222H16v1.556zm3.111-3.111V2h1.556v1.556H16V5.11h-3.111v1.556zM2 5.11V3.556h7.778V5.11z"
      />
    </svg>
  );
};
SvgAdvancedFilters.defaultProps = {
  color: "#309AFC",
  height: "18",
  width: "18",
};
export default SvgAdvancedFilters;
