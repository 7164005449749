import { gql, useQuery } from "src/api/client";

const GET_ALL_PROGRAMS = gql`
  query allPrograms($FeatureType: String!) {
    allPrograms(FeatureType: $FeatureType) {
      ... on allProgramsResponse {
        success
        message
        response {
          Name
          ProgramId
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetAllLeaderboards = (queryOptions) => {
  return useQuery(GET_ALL_PROGRAMS, queryOptions);
};
