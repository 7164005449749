import PropTypes from "prop-types";
import React from "react";
import { getBadge } from "src/components/common/atoms/leaderboardBadge/data";
import { RECURRENCE_PERIOD_CAPS } from "src/constants";
import "./trophyBadge.scss";

const TrophyBadgeProps = {
  size: PropTypes.string,
  position: PropTypes.number,
  recurrencePeriod: PropTypes.oneOf(RECURRENCE_PERIOD_CAPS),
};
const defaultTrophyBadgeProps = {
  size: "48px",
  position: 1,
  recurrencePeriod: "DAILY",
};

const TrophyBadge = (props) => {
  const { size, position, recurrencePeriod } = props;
  return (
    <div style={{ "--trophy-badge-size": size }} className="trophy-badge">
      {getBadge({ position, recurrencePeriod })}
    </div>
  );
};

TrophyBadge.propTypes = TrophyBadgeProps;
TrophyBadge.defaultProps = defaultTrophyBadgeProps;

export default TrophyBadge;
