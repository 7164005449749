import { t } from "i18next";
import React from "react";
import { components } from "react-select";
import { FormButton, Truncate } from "src/components/common";
import { COLORS } from "src/constants";
import { SvgDelete, SvgEdit } from "src/icons";
import { FILTERS_MODEL, FILTER_DATA_TYPES, OPERATORS } from "./data";

export const mapFilters = (selectedFilter, selectedFilterIndex) => {
  return {
    label: selectedFilter?.FilterName || null,
    value: selectedFilterIndex,
    conditions: selectedFilter?.FilterConditions,
    conditionPattern: selectedFilter?.ConditionPattern,
    id: selectedFilter?.id,
  };
};

export const generateRandomKey = () => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

export const formatOptionLabel = ({
  label,
  setShowDeleteModal,
  setShowRenameModal,
  value,
  filterRef,
}) => {
  return (
    <div className="flex center justify-content-between filter-option">
      <span>{label}</span>
      <span className="flex center gap-2 crud-filter">
        <FormButton
          id="edit-btn"
          variant="only-icon"
          icon={<SvgEdit color={COLORS.GREY_2} />}
          className="p-0"
          onClick={(event) => {
            event.stopPropagation();
            filterRef.current.blur();
            setShowRenameModal({ show: true, index: value });
          }}
        />
        <FormButton
          id="delete-btn"
          variant="only-icon"
          icon={<SvgDelete color={COLORS.GREY_2} width={16} height={16} />}
          className="p-0"
          onClick={(event) => {
            event.stopPropagation();
            filterRef.current.blur();
            setShowDeleteModal({ show: true, index: value });
          }}
        />
      </span>
    </div>
  );
};

export const ValueContainer = ({ children, selectProps, ...props }) => {
  const { getValue } = props;
  const values = getValue();
  const { supportMultipleFilters, inputValue } = selectProps;

  const childrenToRender = React.Children.toArray(children).filter(
    (child) =>
      ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
  );
  return (
    <components.ValueContainer {...props}>
      {childrenToRender}
      {!inputValue && values ? (
        <span className="value-container-label">
          <>
            {values && (
              <span className="value-label">
                {supportMultipleFilters && !values[0]?.label ? (
                  <p className="fc-grey">{t("COMMON.LABELS.SELECT_FILTER")}</p>
                ) : (
                  <Truncate text={values[0].label} maxWidth="165px" />
                )}
              </span>
            )}
          </>
        </span>
      ) : (
        false
      )}
    </components.ValueContainer>
  );
};

const getPayloadValue = ({ payload }) => {
  switch (payload?.DataType) {
    case FILTER_DATA_TYPES.DATE: {
      if (Boolean(payload?.SubOperator)) return payload?.Value;
      return null;
    }
    case FILTER_DATA_TYPES.BOOLEAN:
      return null;
    default:
      return payload?.Value;
  }
};

const getOperator = ({ payload, Attribute }) => {
  switch (Attribute?.DataType) {
    case FILTER_DATA_TYPES.BOOLEAN:
      return FILTERS_MODEL[Attribute?.DataType][payload?.Value];
    default:
      return FILTERS_MODEL[Attribute?.DataType][payload?.Operator];
  }
};

export const payloadToCondition = (payload, userFieldOptions) => {
  const Attribute = userFieldOptions?.find(
    (option) => option?.SchemaName === payload?.Attribute
  );

  const Operator = getOperator({ payload, Attribute });

  const SubOperator =
    Attribute?.DataType === FILTER_DATA_TYPES.DATE
      ? Boolean(payload?.SubOperator)
        ? Operator?.options[payload?.SubOperator] // both payload?.SubOperator and payload?.Value are present
        : Operator?.options[payload?.Value] // payload?.Value are present
      : null;

  const Value = getPayloadValue({ payload });

  return {
    Attribute,
    Operator,
    SubOperator,
    Value,
  };
};

const getValue = ({ condition, dataType }) => {
  switch (dataType) {
    case FILTER_DATA_TYPES.BOOLEAN:
      return condition?.Operator?.value;
    case FILTER_DATA_TYPES.STRING: {
      if (
        [OPERATORS.IS_EMPTY, OPERATORS.IS_NOT_EMPTY].includes(
          condition?.Operator?.value
        )
      ) {
        return null;
      } else {
        return condition?.Value;
      }
    }
    default:
      return condition?.Value;
  }
};

const getConditionToPayloadOperator = (condition) => {
  switch (condition?.Attribute?.DataType) {
    case FILTER_DATA_TYPES.BOOLEAN:
      return null;
    default: {
      return condition?.Operator?.value;
    }
  }
};

export const conditionToPayload = (condition) => {
  return {
    Attribute: condition?.Attribute?.SchemaName,
    Operator: getConditionToPayloadOperator(condition),
    SubOperator: condition?.SubOperator?.value || null,
    Value: getValue({
      condition,
      dataType: condition?.Attribute?.DataType,
    }),
    DataType: condition?.Attribute?.DataType,
  };
};

//This function is created to filter the user field options, because Platform has many attributes which are not part of our table

export const getUserFieldDisplayName = (userFieldOptions) => {
  const displayNameSchemeNameMap = userFieldOptions?.reduce((acc, current) => {
    acc[current?.SchemaName] = current?.DisplayName;
    return acc;
  }, {});
  return displayNameSchemeNameMap;
};

export const getFilteredUserFieldOptions = (
  displayNameSchemeNameMap,
  userBaseData
) => {
  return userBaseData?.map((attribute) => ({
    ...attribute,
    DisplayName:
      displayNameSchemeNameMap?.[attribute?.SchemaName] ||
      attribute?.SchemaName,
  }));
};
