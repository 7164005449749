import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgTip = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <g>
        <g
          fill={color}
          style={{
            isolation: "isolate",
          }}
        >
          <path d="M8 3.822A4.357 4.357 0 0 0 3.821 7.98a4.356 4.356 0 0 0 1.2 2.963 2.367 2.367 0 0 1 .893 1.716.521.521 0 0 0 .522.52H9.57a.521.521 0 0 0 .522-.52 2.315 2.315 0 0 1 .883-1.705 4.081 4.081 0 0 0 1.207-2.974 4.238 4.238 0 0 0-1.275-3.031A4.017 4.017 0 0 0 8 3.822Zm2.267 6.384a3.569 3.569 0 0 0-1.175 1.934H6.908a3.636 3.636 0 0 0-1.149-1.929 3.324 3.324 0 0 1-.893-2.23A3.278 3.278 0 0 1 8 4.862a2.949 2.949 0 0 1 2.157.832 3.2 3.2 0 0 1 .977 2.288 3.075 3.075 0 0 1-.883 2.225Z" />
          <path d="M9.911 13.816H6.09a.637.637 0 0 0 0 1.274h3.821a.637.637 0 0 0 0-1.274Z" />
          <path d="M9.273 15.726H6.726a.637.637 0 0 0 0 1.274h2.547a.637.637 0 0 0 0-1.274Z" />
          <path d="M8 2.547a.637.637 0 0 0 .637-.637V.637a.637.637 0 0 0-1.274 0v1.274A.637.637 0 0 0 8 2.547Z" />
          <path d="M1.911 7.301H.637a.637.637 0 0 0 0 1.274h1.274a.637.637 0 0 0 0-1.274Z" />
          <path d="M15.364 7.301H14.09a.637.637 0 1 0 0 1.274h1.274a.637.637 0 0 0 0-1.274Z" />
          <path d="M13.389 2.611a.637.637 0 0 0-.9 0l-.9.9a.639.639 0 0 0 .9.9l.9-.9a.637.637 0 0 0 0-.9Z" />
          <path d="M4.413 4.413a.637.637 0 0 0 0-.9l-.9-.9a.637.637 0 0 0-.9.9l.9.9a.646.646 0 0 0 .9 0Z" />
        </g>
      </g>
    </svg>
  );
};

SvgTip.defaultProps = {
  color: COLORS.GREY_1,
  height: 17,
  width: 16,
};
export default SvgTip;
