import React from "react";
import { classNames } from "src/helpers";
import "./filterButtons.scss";

function FilterButtonGroup({ className, children }) {
  return (
    <div className={classNames(["filter-buttons flex", className])}>
      {children}
    </div>
  );
}

export default FilterButtonGroup;
