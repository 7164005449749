export const INCENTIVE_PROGRAM_TYPES = {
  TEMPLATE_BASED: {
    value: "TEMPLATE_BASED",
  },
  GOAL_BASED: {
    value: "GOAL_BASED",
  },
};

// export const INCENTIVE_PROGRAM_TYPES = ["TEMPLATE_BASED", "GOAL_BASED"];

export const MAX_APPROVER_STAGE_COUNT = 3;

export const INCENTIVE_CALCULATION_TYPES = {
  NON_ROLL_UP: {
    key: "NON_ROLL_UP",
  },
  ROLL_UP: {
    key: "ROLL_UP",
  },
};

export const REWARD_SUBCATEGORIES = {
  NON_MONETARY: {
    key: "NON_MONETARY",
    text: "Non-Monetary",
    value: "Non-Monetary Rewards",
    id: "NonMonetary",
  },
  MONETARY: {
    key: "MONETARY",
    text: "Monetary",
    value: "Monetary Rewards",
    id: "Monetary",
  },
};
export const DEFAULT_INCENTIVE_CALCULATION_TYPE =
  INCENTIVE_CALCULATION_TYPES.NON_ROLL_UP.key;

export const CONDITION_OPERATOR = {
  BETWEEN: {
    Key: "between",
    Label: "Between",
    Value: "BETWEEN",
  },
  GREATER_THAN: {
    Key: "greater_than",
    Label: "Greater than",
    Value: ">",
  },
  LESS_THAN: {
    Key: "less_than",
    Label: "Less than",
    Value: "<",
  },
  GREATER_THAN_OR_EQUAL_TO: {
    Key: "greater_than_or_equal_to",
    Label: "Greater than equals to",
    Value: ">=",
  },
  LESS_THAN_OR_EQUAL_TO: {
    Key: "less_than_or_equal_to",
    Label: "Less than equals to",
    Value: "<=",
  },
  IS: {
    Key: "is",
    Label: "Equals to",
    Value: "=",
  },
};

export const REWARD_TYPE = {
  FIXED: {
    type: "FIXED",
    label: "Fixed",
    Category: REWARD_SUBCATEGORIES.MONETARY.key,
  },
  PERCENTAGE: {
    type: "PERCENTAGE",
    label: "Percentage",
    Category: REWARD_SUBCATEGORIES.MONETARY.key,
  },
  PERCENTAGE_SLAB: {
    type: "PERCENTAGE_SLAB",
    label: "Percentage Slab",
    Category: REWARD_SUBCATEGORIES.MONETARY.key,
  },
  MULTIPLIER: {
    type: "MULTIPLIER",
    label: "Multiplier",
    Category: REWARD_SUBCATEGORIES.MONETARY.key,
  },
  MULTIPLIER_SLAB: {
    type: "MULTIPLIER_SLAB",
    label: "Multiplier Slab",
    Category: REWARD_SUBCATEGORIES.MONETARY.key,
  },
  CUSTOM_REWARD: {
    type: "CUSTOM_REWARD",
    label: "Custom Reward",
    Category: REWARD_SUBCATEGORIES.NON_MONETARY.key,
  },
};

export const APPROVAL_STATUS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Pending",
    value: "PENDING",
  },
  {
    label: "Approved",
    value: "APPROVED",
  },
  {
    label: "Partially Approved",
    value: "PARTIALLYAPPROVED",
  },
  {
    label: "Rejected",
    value: "REJECTED",
  },
];
export const APPROVAL_STATUSES = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
  },
  APPROVED: {
    label: "Approved",
    value: "APPROVED",
  },
  PARTIALLYAPPROVED: {
    label: "Partially Approved",
    value: "PARTIALLYAPPROVED",
  },
  REJECTED: {
    label: "Rejected",
    value: "REJECTED",
  },
};
export const HISTORY_ITEM_ACTIONS = {
  FORWARD: {
    label: "Forward",
    value: "FORWARD",
  },
  BACKWARD: {
    label: "Backward",
    value: "BACKWARD",
  },
  PAYOUTREJECT: {
    label: "Payout Reject",
    value: "PAYOUTREJECT",
  },
};
export const APPROVAL_STATUSES_FOR_DASHBOARD = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
  },
  APPROVED: {
    label: "Approved",
    value: "APPROVED",
  },
  PARTIALLYAPPROVED: {
    label: "Partially Approved",
    value: "PARTIALLYAPPROVED",
  },
  REJECTED: {
    label: "Rejected",
    value: "REJECTED",
  },
};

export const CONDITION_TYPES_OPTIONS = [
  { key: "NONGROUPBY", label: "Non-Group-By", value: "NONGROUPBY" },
  { key: "GROUPBY", label: "Group-By", value: "GROUPBY" },
];

export const APPROVAL_STATUS_LIST = [
  "PENDING",
  "APPROVED",
  "PARTIALLYAPPROVED",
  "REJECTED",
];
export const MAX_DUPLICATE_INCENTIVE_CONDITIONS = 10;

export const MAX_LEVELS_IN_HIERARCHY = 100;

export const MIN_LEVELS_IN_HIERARCHY = 1;

export const MAXIMUM_INCENTIVE_RULESETS_ALLOWED = 5;

export const MAXIMUM_INCENTIVE_CRITERIA_ALLOWED = 50;

export const MAXIMUM_INCENTIVE_CRITERIA_ALLOWED_FOR_DAILY_CYCLE = 30;

export const MAXIMUM_INCENTIVE_CRITERIA_CONDITIONS_ALLOWED = 10;

export const MAXIMUM_INCENTIVE_CRITERIA_REWARDS_ALLOWED = 10;

export const MINIMUM_INCENTIVE_SLABS_ALLOWED = 2;

export const MAXIMUM_INCENTIVE_SLABS_ALLOWED = 10;

export const MINIMUM_INCENTIVE_SLAB_VALUE = 0.0001;

export const MAXIMUM_INCENTIVE_SLAB_VALUE = 999999999;

export const MINIMUM_INCENTIVE_SLAB_WEIGHTAGE = 0.0001;

export const MINIMUM_INCENTIVE_REWARD_VALUE = 0.0001;

export const MAXIMUM_INCENTIVE_REWARD_VALUE = 999999999;

export const MINIMUM_INCENTIVE_CONDITION_VALUE = 0.0001;

export const MAXIMUM_INCENTIVE_CONDITION_VALUE = 999999999;

export const INITIAL_GOALS_TO_DISPLAY = 3;

export const SHOW_MORE_GOALS_LIMIT = 3;

export const MAX_GROUP_BY_CONDITION = 2;

export const INCENTIVE_PROGRAM_STATUS = [
  {
    label: "In Progress",
    value: "INPROGRESS",
  },
  {
    label: "Gap Phase",
    value: "INGAPS",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Yet to Start",
    value: "YETTOSTART",
  },
  {
    label: "Draft",
    value: "DRAFT",
  },
  {
    label: "Unpublished",
    value: "UNPUBLISHED",
  },
  {
    label: "All",
    value: "ALL",
  },
];

export const PAYOUT_STATUSES = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
  },
  PAIDOUT: {
    label: "Paid Out",
    value: "PAIDOUT",
  },
  PARTIALLYPAIDOUT: {
    label: "Pending",
    value: "PARTIALLYPAIDOUT",
  },
};

export const STATUSES = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
  },
  APPROVED: {
    label: "Approved",
    value: "APPROVED",
  },
  PARTIALLYAPPROVED: {
    label: "Partially Approved",
    value: "PARTIALLYAPPROVED",
  },
  REJECTED: {
    label: "Rejected",
    value: "REJECTED",
  },
  PAIDOUT: {
    label: "Paid Out",
    value: "PAIDOUT",
  },
  PARTIALLYPAIDOUT: {
    label: "Pending",
    value: "PARTIALLYPAIDOUT",
  },
  PENDING_FOR_PAYOUT: {
    label: "Pending Payout",
    value: "PENDING_FOR_PAYOUT",
  },
  "Stage 1 Pending": {
    label: "Stage 1 Pending",
    value: "PENDING1",
  },
  "Stage 2 Pending": {
    label: "Stage 2 Pending",
    value: "PENDING2",
  },
  "Stage 3 Pending": {
    label: "Stage 3 Pending",
    value: "PENDING3",
  },
};

export const PAYOUT_STATUS = [
  {
    label: "Pending",
    value: "PENDING",
  },
  {
    label: "Paid Out",
    value: "PAIDOUT",
  },
  {
    label: "Pending",
    value: "PARTIALLYPAIDOUT",
  },
];
export const PAYOUT_STATUS_LIST = ["PENDING", "PAIDOUT", "PARTIALLYPAIDOUT"];

export const PAYOUT_STATUS_CLASSES = {
  [PAYOUT_STATUSES.PENDING.value]: "primary",
  [PAYOUT_STATUSES.PAIDOUT.value]: "success",
  [PAYOUT_STATUSES.PARTIALLYPAIDOUT.value]: "primary",
};
export const INCENTIVE_CYCLES_OPTIONS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Daily",
    value: "DAILY",
  },
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Biweekly",
    value: "BIWEEKLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
  {
    label: "Quarterly",
    value: "QUARTERLY",
  },
  {
    label: "Yearly",
    value: "YEARLY",
  },
  {
    label: "Custom: Uniform",
    value: "UNIFORM",
  },
  {
    label: "Custom: Non-Uniform",
    value: "NONUNIFORM",
  },
];

export const INCENTIVES_PERIOD_OPTIONS = [
  {
    label: "Daily",
    value: "Daily",
  },
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Biweekly",
    value: "Biweekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Quarterly",
    value: "Quarterly",
  },
  { label: "Yearly", value: "Yearly" },
  {
    label: "Custom",
    value: "Custom",
  },
];

export const maxCharLimit = 500;

export const DASHBOARD_VIEW = {
  USER_VIEW: "user_view",
  PROGRAM_VIEW: "program_view",
};

export const statusClasses = {
  PENDING: "primary",
  PAIDOUT: "success",
  PARTIALLYPAIDOUT: "primary",
  PARTIALLYAPPROVED: "info",
  REJECTED: "danger",
  APPROVED: "success",
};

export const GOAL_ACHIEVEMENT_TYPE = {
  TARGET_VALUE: "Goal Target Value",
  ACHIEVED_VALUE: "Goal Achieved Value",
  ACHIEVED_PERCENTAGE: "Goal Achieved Percentage",
};

export const REPORTEES_TYPE = {
  DIRECT_REPORTEES_COUNT_IN_GOAL: "Total Number of Reportees",
  DIRECT_REPORTEES_ACHIEVING_GOAL_TARGET_COUNT:
    "No.Of Reportees Achieving Goal",
};

export const FORMULA_METRIC_TYPE = {
  CALCULATED_INCENTIVE: "Calculated Incentive",
  REPORTEES_INCENTIVE: "Calculated Incentive Of Reportees",
  REPORTEES_ACHIEVING_INCENTIVE_COUNT:
    "No.Of Reportees Achieving Incentive Amount",
  TOTAL_INCENTIVE_REPORTEES_COUNT: "Total Number of Reportees",
};

export const USER_SELECTION_TYPES = {
  MANUAL_SELECTION: "MANUAL",
  CONDITION_BASED_SELECTION: "CONDITION",
  AUTOMATED_SELECTION: "AUTO",
};

export const USER_SELECTION_TYPES_LABEL = {
  MANUAL: "INCENTIVES.PROGRAM.LABELS.MANUAL_SELECTION",
  CONDITION: "INCENTIVES.COMMON.LABELS.CONDITION_BASED_SELECTION",
};
