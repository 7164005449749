import { t } from "i18next";
import React from "react";
import {
  DateCell,
  FormTooltip,
  LoadingBreakdownModalMobile,
  Truncate,
} from "src/components/common";
import { NumFormatter, checkValue, getFullName } from "src/helpers";
import { NoResults } from "src/images";

const LeadNameFormatter = ({ user }) => {
  return (
    <>
      <div className="fc-grey1">{t("COMMON.LABELS.LEAD_NAME")}</div>
      <div className="semi-bold">
        <Truncate
          text={getFullName(user.ProspectFirstName, user.ProspectLastName)}
          width="150px"
        />
      </div>
    </>
  );
};

const FieldFormatter = ({ user }) => (
  <>
    <div className="fc-grey1">{t("COMMON.LABELS.FIELD")}</div>
    <div className="semi-bold">
      <FormTooltip text={user.EntityValue}>
        {NumFormatter(user.EntityValue)}
      </FormTooltip>
    </div>
  </>
);

const DateFormatter = ({ user }) => (
  <>
    <div className="fc-grey1">{t("COMMON.LABELS.DATE")}</div>
    <div className="semi-bold">
      {DateCell({
        value: user.EntityDate,
        column: {},
      })}
    </div>
  </>
);

const UsersFieldCardContainer = (props) => {
  const {
    id,
    data,
    gridLoading,
    usersAttributeList,
    fieldsOrder,
    isSalesUser,
    dataNotAvailable,
    hideBreakdownTable,
    userNotPartOfRecurrence,
  } = props;

  return (
    <>
      {gridLoading ? (
        <LoadingBreakdownModalMobile />
      ) : (
        <>
          {dataNotAvailable || data.length === 0 || hideBreakdownTable ? (
            <div className="flex-center flex-column h-100">
              <NoResults />
              <div className="fc-grey mt-3">
                {userNotPartOfRecurrence
                  ? t("MANAGED_GOALS.LABELS.NOT_PART_OF_RECURRENCE")
                  : dataNotAvailable
                  ? t("COMMON.MESSAGES.CONTACT_DATA_TEAM")
                  : t("COMMON.MESSAGES.DATA_NOT_FOUND")}
              </div>
            </div>
          ) : (
            data.map((user, index) => {
              return (
                <div
                  id={`${id}-${index}`}
                  className=" bg-white mb-4 user-card-container px-3 pt-3"
                >
                  <div className="pb-3 flex-column align-items-center">
                    <div className="flex-column fs-14 w-100 pb-2 border-bottom-1 bc-grey4">
                      <Truncate
                        width="150px"
                        text={getFullName(
                          user.UserFirstName,
                          user.UserLastName
                        )}
                      />
                      <div className="fs-12 fc-grey">
                        <Truncate
                          id="achievement-drill-down-goal-name"
                          maxWidth="400px"
                          truncType="end"
                          text={user.EmailAddress}
                        />
                      </div>
                    </div>
                    {fieldsOrder.length > 0 ? (
                      <>
                        <div className="py-3 d-flex flex-wrap">
                          <div className="w-50 fs-13">
                            <LeadNameFormatter user={user} />
                          </div>
                          <div className="w-50 fs-13">
                            <FieldFormatter user={user} />
                          </div>
                          <div className="w-50 fs-13">
                            <DateFormatter user={user} />
                          </div>
                          {!isSalesUser &&
                            fieldsOrder.map((field) =>
                              usersAttributeList[field]?.isVisible ? (
                                <div className="w-50 fs-13">
                                  <div className="fc-grey">
                                    {usersAttributeList[field]?.DisplayName}
                                  </div>
                                  <div className="fs-13 semi-bold fc-black">
                                    <Truncate
                                      text={
                                        checkValue(user[field])
                                          ? `${user[field]}`
                                          : "-"
                                      }
                                      width="180px"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )
                            )}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              );
            })
          )}
        </>
      )}
    </>
  );
};

export default UsersFieldCardContainer;
