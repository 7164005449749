import { t } from "i18next";
import React, { Suspense, lazy, useState } from "react";
import { FormTooltip, MobileTabItem, MobileTabs } from "src/components";
import { classNames } from "src/helpers";
import { MY_PROGRAM_VIEW, MY_PROGRAM_VIEW_FILTER_BUTTONS } from "./data";
import "./mobileProgramDetails.scss";

const ProgramOverview = lazy(() =>
  import(
    /*
    webpackChunkName: "my-incentives.program-overview",
    webpackPrefetch: true
    */
    "./programOverView"
  )
);

const CriteriaAndAchievements = lazy(() =>
  import(
    /*
    webpackChunkName: "my-incentives.criteria-and-achievements",
    webpackPrefetch: true
    */
    "./criteriaAndAchievements"
  )
);
const ProgramHistory = lazy(() =>
  import(
    /*
    webpackChunkName: "my-incentives.program-history",
    webpackPrefetch: true
    */
    "./programHistory"
  )
);

const MobileProgramDetails = ({
  programDetails,
  programId,
  recurrenceId,
  recurrenceStatus,
  recurrences,
  selectedRecurrence,
  userId,
  status,
  incentiveCriteria,
  isCalculationProcessed,
  isBufferCompleted,
  hideIncentiveHistory,
}) => {
  const [tab, setTab] = useState(MY_PROGRAM_VIEW.PROGRAM_OVERVIEW);

  return (
    <>
      <div className="center bg-white my-4">
        <MobileTabs className="w-100 mobile-program-detail-tab">
          {MY_PROGRAM_VIEW_FILTER_BUTTONS.map(({ key, label }) => {
            const disableIncentiveHistory =
              key === MY_PROGRAM_VIEW.PROGRAM_HISTORY && hideIncentiveHistory;
            return (
              <MobileTabItem
                className={classNames([
                  "min-w-max mobile-program-detail-tab-item ",
                  disableIncentiveHistory && "hide-incentive-history",
                ])}
                active={tab === key}
                onClick={() => setTab(key)}
                disabled={disableIncentiveHistory}
              >
                <FormTooltip
                  disable={!disableIncentiveHistory}
                  text={t(
                    "INCENTIVES.COMMON.MESSAGES.INCENTIVE_HISTORY_HAS_BEEN_HIDDEN"
                  )}
                  placement="bottom"
                >
                  <p>{t(label)}</p>
                </FormTooltip>
              </MobileTabItem>
            );
          })}
        </MobileTabs>
      </div>
      <Suspense fallback={<></>}>
        {tab === MY_PROGRAM_VIEW.PROGRAM_OVERVIEW ? (
          <ProgramOverview
            programId={programId}
            recurrenceId={recurrenceId}
            userId={userId}
            status={status}
            isCalculationProcessed={isCalculationProcessed}
            isBufferCompleted={isBufferCompleted}
          />
        ) : tab === MY_PROGRAM_VIEW.PROGRAM_CRITERIAS ? (
          <CriteriaAndAchievements
            programDetails={programDetails}
            incentiveCriteria={incentiveCriteria}
            recurrences={recurrences}
            selectedRecurrence={selectedRecurrence}
            userId={userId}
          />
        ) : (
          <ProgramHistory
            programDetails={programDetails}
            programId={programId}
            recurrenceId={recurrenceId}
            recurrenceStatus={recurrenceStatus}
            userId={userId}
          />
        )}
      </Suspense>
    </>
  );
};

export default MobileProgramDetails;
