import { t } from "i18next";
import React from "react";
import {
  DisplayFormula,
  FormulaBuilder,
} from "src/components/common/molecules";
import SwitchToCustomFormula from "./SwitchToCustomFormula";

function StandardFormula({
  editStatus,
  formulaBuilderProps,
  switchToCustomProps,
}) {
  return (
    <div className="ruleset-formula-standard">
      <div className="flex-between mb-3">
        <div className="fs-16 semi-bold type-title">
          {t("COMMON.MESSAGES.STANDART_OUTPUT")}
        </div>
        {editStatus ? <SwitchToCustomFormula {...switchToCustomProps} /> : null}
      </div>
      <div className="ml-3">
        {editStatus ? (
          <FormulaBuilder {...formulaBuilderProps} />
        ) : (
          <DisplayFormula formula={formulaBuilderProps.formula} />
        )}
      </div>
    </div>
  );
}

export default StandardFormula;
