import React from "react";
import { Shimmer } from "src/components/common/atoms";
import "./loadingGoalCard.scss";

const LoadingCard = () => (
  <div className="col-12 col-md-6 col-xl-4 goal-card-grid">
    <div className="shimmer-card card p-4 mb-30 bg-white h-200 rounded">
      <div className="card-title pb-2">
        <div className="flex-between">
          <Shimmer className="mb-3" width="20%" />
          <Shimmer className="mb-3" width="60%" />
        </div>
        <div className="border-b" />
      </div>
      <div className="my-3">
        <Shimmer width="100%" />
        <Shimmer className="mt-3" width="100%" />
      </div>
      <div className="mt-3 flex-between">
        <Shimmer width="20%" />
        <Shimmer width="20%" />
      </div>
      <div className="mt-3">
        <Shimmer width="100%" />
      </div>
    </div>
  </div>
);

export default LoadingCard;
