import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_GOAL_BASED_LEADERBOARD_USERS = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getGoalBasedLeaderboardUsers(
    $Limit: Float
    $After: Float
    $LeaderboardId: ID!
    $GroupId: ID
    $RecurrenceId: ID!
    $userAttributesNames: [String]
    $Search: String
  ) {
    goalBasedLeaderboardUsers(
      Limit: $Limit
      After: $After
      LeaderboardId: $LeaderboardId
      GroupId: $GroupId
      RecurrenceId: $RecurrenceId
      userAttributesNames: $userAttributesNames
      Search: $Search
    ) {
      __typename
      ... on goalBasedUsersResponse {
        response {
          Users
          After
          NextPageExists
          TotalUsers
          __typename
        }
        message
        success
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetGoalBasedLeaderboardUsers = (queryOptions) => {
  return useQuery(GET_GOAL_BASED_LEADERBOARD_USERS, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
