import {
  AGGREGATE_FUNCTIONS_WITH_ROLLUP_DISABLED,
  COLORS,
  DATE_FORMAT,
  DATE_FORMAT_WITH_AFTER_TIME,
  FEATURE_TYPES,
  PAYOUT_STATUS_TYPES,
  ROLLUP_TYPES,
  RULESET_AGGREGATION_TYPES,
} from "src/constants";
import { format, isNull } from "src/helpers/vendors";
import { getSystemOffset, getUserTimezoneOffset } from "./timezoneUtils";

export const getGreaterThanMarkerColor = (percentageData) => {
  const percentage = percentageData / 2;
  switch (true) {
    case percentage >= 50:
      return COLORS.CARD_GREEN;
    case percentage >= 37.5 && percentage < 50:
      return COLORS.CARD_YELLOW_GREEN;
    case percentage >= 25 && percentage < 37.5:
      return COLORS.CARD_YELLOW;
    case percentage >= 12.5 && percentage < 25:
      return COLORS.CARD_ORANGE;
    default:
      return COLORS.CARD_RED;
  }
};

export const getLesserThanMarkerColor = (percentageData) => {
  const percentage = percentageData / 2;
  switch (true) {
    case percentage <= 50:
      return COLORS.CARD_GREEN;
    case percentage <= 62.5 && percentage > 50:
      return COLORS.CARD_YELLOW_GREEN;
    case percentage <= 75 && percentage > 62.5:
      return COLORS.CARD_YELLOW;
    case percentage <= 87.5 && percentage > 75:
      return COLORS.CARD_ORANGE;
    default:
      return COLORS.CARD_RED;
  }
};

export const getColor = (percentage) => {
  switch (true) {
    case percentage < 25:
      return COLORS.CARD_RED;
    case percentage >= 25 && percentage < 50:
      return COLORS.CARD_ORANGE;
    case percentage >= 50 && percentage < 75:
      return COLORS.CARD_YELLOW;
    case percentage >= 75 && percentage < 100:
      return COLORS.CARD_YELLOW_GREEN;
    default:
      return COLORS.CARD_GREEN;
  }
};

export const isAppLoadedWithinAnIframe = () => {
  return window.location !== window.parent.location;
};

export const removeLoadingIconFromParent = () => {
  if (isAppLoadedWithinAnIframe()) {
    window.parent.postMessage(
      {
        Type: "SPA",
        Action: "hideLoaderImg",
      },
      "*"
    );
  }
};

export const range = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const mergeArrObjects = (arr1, arr2, key1, key2) => {
  const merged = [];

  arr1.forEach((item, idx) => {
    merged.push({
      ...item,
      ...arr2.find((itmInner) => itmInner[key2] === arr1[idx][key1]),
    });
  });
  return merged;
};

export const handleKeyStroke = (keyEvent) => {
  if (
    (keyEvent.charCode || keyEvent.keyCode) === 13 ||
    keyEvent.code === "Enter" ||
    keyEvent.code === "NumpadEnter"
  ) {
    return keyEvent.preventDefault();
  }
};

export const isKeyEventEnter = (keyEvent) => {
  return (
    (keyEvent.charCode || keyEvent.keyCode) === 13 ||
    ["Enter", "NumpadEnter"].includes(keyEvent.code || keyEvent.key)
  );
};

export const isKeyEventBackspace = (keyEvent) => {
  return (
    keyEvent.keyCode === 8 ||
    ["Backspace", "Delete"].includes(keyEvent.code || keyEvent.key)
  );
};

export const getFullName = (firstName, lastName) => {
  return `${firstName || ""} ${lastName || ""}`.trim();
};

// !
export const getFormatedTimeDate = (dateTime) => {
  const userTimezoneOffset = getUserTimezoneOffset();

  if (dateTime) {
    return format(
      new Date(parseInt(dateTime) - getSystemOffset() + userTimezoneOffset),
      DATE_FORMAT_WITH_AFTER_TIME
    );
  }
};

export const getFloatValue = (value, fixed = 2) => {
  if (isNull(value)) return "- -";
  if (Number.isInteger(value)) return value;
  return value?.toFixed(fixed);
};

export const getPercentage = (num, deno, fixed = 2) => {
  if (isNull(deno) || deno === 0) return 0;
  const percentage = (num / deno) * 100;
  if (Number.isInteger(percentage)) return percentage;
  return percentage.toFixed(fixed);
};

// !
export const getRankColor = (position) => {
  switch (position) {
    case 1:
      return "gold";
    case 2:
      return "silver";
    case 3:
      return "bronze";
    default:
      return "shield";
  }
};

export const doesCustomFormulahaveDistinctCount = (rules) =>
  rules?.find(
    (rule) =>
      rule?.GoalAggregateFunction === RULESET_AGGREGATION_TYPES.DISTINCT_COUNT
  );

export const isRollUpDisabled = (
  selectedTemplates,
  featureType = FEATURE_TYPES.GOAL
) => {
  if (
    selectedTemplates[0]?.GoalAggregateFunction ===
      RULESET_AGGREGATION_TYPES.CUSTOM &&
    featureType === FEATURE_TYPES.GOAL
  ) {
    return Boolean(
      doesCustomFormulahaveDistinctCount(selectedTemplates[0]?.GoalRules)
    );
  }
  const isRollUpDisabled = selectedTemplates.find((selectedTemplate) =>
    AGGREGATE_FUNCTIONS_WITH_ROLLUP_DISABLED[featureType].includes(
      selectedTemplate?.GoalAggregateFunction
    )
  );
  return Boolean(isRollUpDisabled);
};

export const filteredRollupTypes = (
  aggregateFunctionType,
  featureType = FEATURE_TYPES.GOAL
) => {
  return ROLLUP_TYPES.map((rollupType) => {
    const disabledRollupTypes = rollupType?.disabledfor[featureType];
    return disabledRollupTypes?.includes(aggregateFunctionType) ||
      disabledRollupTypes?.includes("ALL")
      ? { ...rollupType, isDisabled: true, subText: "(Coming Soon)" }
      : { ...rollupType };
  });
};
export const getDefaultRollupType = (optionsForRollupType) =>
  optionsForRollupType.find((option) => !option.isDisabled);

export const getPayoutStatus = (key) => {
  switch (key) {
    case PAYOUT_STATUS_TYPES.PAIDOUT:
      return [PAYOUT_STATUS_TYPES.PAIDOUT];
    case PAYOUT_STATUS_TYPES.PENDING:
      return [
        PAYOUT_STATUS_TYPES.PENDING,
        PAYOUT_STATUS_TYPES.PARTIALLY_PAIDOUT,
      ];
    default:
      return [
        PAYOUT_STATUS_TYPES.PENDING,
        PAYOUT_STATUS_TYPES.PARTIALLY_PAIDOUT,
        PAYOUT_STATUS_TYPES.PAIDOUT,
      ];
  }
};

export const groupBy = function (values, groupByKey) {
  return values.reduce((reducedvalues, value) => {
    (reducedvalues[value[groupByKey]] =
      reducedvalues[value[groupByKey]] || []).push(value);
    return reducedvalues;
  }, {});
};

export const getPluralText = (count, singular, plural, onlyUnit = false) => {
  if (onlyUnit) return count <= 1 ? singular : plural;
  return `${count} ${count <= 1 ? singular : plural}`;
};

export const formatToString = (recurrence) => {
  if (recurrence?.RecurrenceType === "DAILY") {
    return format(
      new Date(parseInt(recurrence.RecurrenceStartDate) - getSystemOffset()),
      DATE_FORMAT
    );
  }
  return `${format(
    new Date(parseInt(recurrence.RecurrenceStartDate) - getSystemOffset()),
    DATE_FORMAT
  )} - ${format(
    new Date(parseInt(recurrence.RecurrenceEndDate) - getSystemOffset()),
    DATE_FORMAT
  )}`;
};

export const getOrdinal = (n, onlyOrdinal = false) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;

  if (onlyOrdinal) return s[(v - 20) % 10] || s[v] || s[0];

  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const classNames = (classes) => {
  return classes.filter((className) => className).join(" ");
};

export const isRouteActive = ({ pathname, route }) =>
  Boolean(pathname.match(new RegExp(`${route}(/.+)*(\\?.*)?$`)));

export const getLeadDetailsUrl = (leadId) =>
  `${process.env.REACT_APP_PLATFORM_URL}LeadManagement/LeadDetails?LeadID=${leadId}`;

export const checkValue = (value) => {
  return value || value === 0 || value === false;
};

export const checkCookie = (cookieName) => {
  // Split the cookie string into individual cookies
  const cookies = document.cookie.split("; ");

  // Loop through the cookies to check if the specified cookie exists
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [name] = cookie.split("=");

    // Trim whitespace from the cookie name
    const trimmedName = name.trim();

    // If the cookie name matches the specified name, return true
    if (trimmedName === cookieName) {
      return true;
    }
  }

  // If the cookie was not found, return false
  return false;
};
export const getAlphabetForCriteria = (index) => {
  if (index < 0 || index >= 26 * 26) return null;
  const firstLetterIndex = Math.floor(index / 26);
  const secondLetterIndex = index % 26;
  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (firstLetterIndex === 0) {
    return alphabets[secondLetterIndex];
  } else {
    return alphabets[firstLetterIndex - 1] + alphabets[secondLetterIndex];
  }
};

export const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removeDuplicatesFromArrayOfObjects = (array, key) => {
  return array.filter(
    (obj, index, self) => index === self.findIndex((o) => o[key] === obj[key])
  );
};

export const generateKeyValuePair = (array) => {
  return array.map((entity) => {
    return {
      Key: entity,
      Value: entity,
    };
  });
};

export function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}
