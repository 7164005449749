import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { FormButton, HeroBackground, NonMonetaryLabel } from "src/components";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgArrow } from "src/icons";
import {
  ApprovalRequests,
  ApprovedIncentive,
  PayoutRequests,
  TotalPayout,
} from "src/images";
import "./pendingRequestsCard.scss";

const PendingRequestsCard = (props) => {
  const {
    variant,
    className,
    title,
    amount,
    rewards,
    footerText,
    onFooterClick,
    formatNonMonetary,
  } = props;

  const dataAsPerVariant = {
    APPROVAL_PENDING: {
      mainBg: ApprovalRequests,
      rightIcon: <TotalPayout width="40" height="40" />,
      footerClassName: "bg-purple",
    },
    PAYOUT_PENDING: {
      mainBg: PayoutRequests,
      rightIcon: <ApprovedIncentive width="40" height="40" />,
      footerClassName: "bg-green",
    },
  };

  const { mainBg, rightIcon, footerClassName } = dataAsPerVariant[variant];

  return (
    <HeroBackground
      image={mainBg}
      className={classNames([
        "pending-requests-card br-t-4 br-b-4 fc-white",
        className,
      ])}
    >
      <div className="d-flex justify-content-between p-3">
        <div>
          <div className="semi-bold">{title}</div>
          <div className="mt-2">
            <NonMonetaryLabel
              amount={amount}
              nonMonetaryRewards={rewards}
              className="fs-14 semi-bold"
              nonMonetaryClassName="fs-12 text-underline fc-white mt-1"
              rewardCategoryList
              formatNonMonetary={formatNonMonetary}
            />
          </div>
        </div>
        <div>{rightIcon}</div>
      </div>
      <FormButton
        className={classNames([
          footerClassName,
          "d-flex justify-content-between pointer fs-11 semi-bold p-2 fc-white br-b-4  w-100",
        ])}
        onClick={onFooterClick}
        variant="link"
      >
        <div className="fc-white">{footerText}</div>
        <div className="center">
          <div className="mr-2 fc-white">
            {t("INCENTIVES.MY_INCENTIVES.LABELS.VIEW_ALL")}
          </div>
          <div>
            <SvgArrow
              rotate={270}
              color={COLORS.WHITE}
              width={12}
              height={12}
            />
          </div>
        </div>
      </FormButton>
    </HeroBackground>
  );
};

PendingRequestsCard.propTypes = {
  variant: PropTypes.oneOf(["APPROVAL_PENDING", "PAYOUT_PENDING"]),
  className: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rewards: PropTypes.array,
  rewardCount: PropTypes.number,
  footerText: PropTypes.string,
  onFooterClick: PropTypes.func,
};

PendingRequestsCard.defaultProps = {
  variant: "APPROVAL_PENDING",
  className: "",
  title: "Approval Requests",
  amount: "4580",
  rewards: [
    {
      RewardCategory: "Vouchers",
      RewardCount: 14,
    },
    {
      RewardCategory: "Travel",
      RewardCount: 2,
    },
    {
      RewardCategory: "Gadget",
      RewardCount: 1,
    },
    {
      RewardCategory: "Learning",
      RewardCount: 1,
    },
  ],
  footerText: "Total pending amount from 12 approval requests",
  onFooterClick: () => {},
};

export default PendingRequestsCard;
