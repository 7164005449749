import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_NON_MONETARY_REWARDS_PROGRAM_DETAILS = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query nonMonetaryRewardsProgramDetails(
    $Query: RewardsProgramDetailsQuery
    $Before: String
    $After: String
    $Limit: Float
  ) {
    nonMonetaryRewardsProgramDetails(
      Query: $Query
      Before: $Before
      After: $After
      Limit: $Limit
    ) {
      ... on NonMonetaryRewardsProgramDetailsResponse {
        response {
          rewardsList {
            ProgramId
            RewardId
            RecurrenceId
            ProgramName
            RecurrenceStartDate
            RecurrenceEndDate
            RewardName
            RewardCategory
            RewardValue
            RewardsCount
            UserId
            UserName
            EmailAddress
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
          __typename
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetNonMonetaryRewardsProgramDetails = (queryOptions) => {
  return useQuery(GET_NON_MONETARY_REWARDS_PROGRAM_DETAILS, queryOptions);
};
