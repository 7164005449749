import { gql, useMutation } from "src/api/client";

export const UPDATE_GOAL_RULE = gql`
  mutation updateGoalRule(
    $RuleId: ID!
    $TemplateId: ID!
    $Entity: Entity!
    $RollUpFields: RollUpFields!
    $Conditions: [Condition]
    $CriteriaPattern: String!
    $RuleName: String!
    $GoalAggregateFunction: GoalAggregateFunctionEnum!
  ) {
    updateGoalRule(
      RuleId: $RuleId
      TemplateId: $TemplateId
      Entity: $Entity
      RollUpFields: $RollUpFields
      Conditions: $Conditions
      CriteriaPattern: $CriteriaPattern
      RuleName: $RuleName
      GoalAggregateFunction: $GoalAggregateFunction
    ) {
      ... on SingleGoalRuleResponse {
        response {
          GoalRule {
            RuleId
            TemplateId
            Entity
            RollUpFields
            Conditions
            CriteriaPattern
          }
        }
        message
        success
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useUpdateGoalRule = () => {
  return useMutation(UPDATE_GOAL_RULE);
};
