import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgGoal = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l633bfh">
          <path d="M0 0h26v26H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l633bfh)" data-name="Goal template">
        <g
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2}
          data-name="Group 7521"
          transform="translate(11790.2 19069.1)"
        >
          <path d="m-11777.218-19056.127 4.549-4.549" data-name="Line 227" />
          <path
            d="M-11765.344-19064.484h-3.515v-3.516l-3.516 3.516v3.515h3.516Z"
            data-name="Path 4610"
          />
          <circle
            cx={5}
            cy={5}
            r={5}
            data-name="Ellipse 173"
            transform="translate(-11782.666 -19060.688)"
          />
          <path
            d="M-11767.975-19061.865a11.166 11.166 0 0 1 1.752 6.132 11.389 11.389 0 1 1-11.388-11.388 11.166 11.166 0 0 1 6.132 1.752"
            data-name="Path 4611"
          />
        </g>
      </g>
    </svg>
  );
};

SvgGoal.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 26,
  width: 26,
};
export default SvgGoal;
