import { classNames } from "src/helpers";

const FileInput = ({ rootProps, inputProps, children, className }) => {
  return (
    <div className={classNames(["h-100", className])} {...rootProps}>
      <input {...inputProps} />
      {children}
    </div>
  );
};

export default FileInput;
