import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgNoon = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      role="img"
      {...rest}
    >
      <defs>
        <clipPath id="clip-Noon">
          <rect width={width} height={height} />
        </clipPath>
      </defs>
      <g id="Noon" clipPath="url(#clip-Noon)">
        <g id="Noon_1_" data-name="Noon (1)" transform="translate(0 -0.306)">
          <rect
            id="Rectangle_4229"
            data-name="Rectangle 4229"
            width={0.9}
            height={1.285}
            transform="translate(5.55 1.306)"
            fill={color}
          />
          <rect
            id="Rectangle_4230"
            data-name="Rectangle 4230"
            width={0.9}
            height={1.286}
            transform="translate(2.61 2.335) rotate(-30.031)"
            fill={color}
          />
          <rect
            id="Rectangle_4231"
            data-name="Rectangle 4231"
            width={0.9}
            height={1.286}
            transform="translate(0.579 4.694) rotate(-59.969)"
            fill={color}
          />
          <rect
            id="Rectangle_4232"
            data-name="Rectangle 4232"
            width={6.88}
            height={0.9}
            transform="translate(0.001 6.855)"
            fill={color}
          />
          <rect
            id="Rectangle_4233"
            data-name="Rectangle 4233"
            width={5.622}
            height={0.9}
            transform="translate(0 8.821)"
            fill={color}
          />
          <rect
            id="Rectangle_4234"
            data-name="Rectangle 4234"
            width={0.9}
            height={1.287}
            transform="translate(9.857 10.051) rotate(-59.991)"
            fill={color}
          />
          <rect
            id="Rectangle_4235"
            data-name="Rectangle 4235"
            width={1.286}
            height={0.9}
            transform="translate(10.714 6.854)"
            fill={color}
          />
          <rect
            id="Rectangle_4236"
            data-name="Rectangle 4236"
            width={1.287}
            height={0.9}
            transform="translate(9.857 4.558) rotate(-29.961)"
            fill={color}
          />
          <rect
            id="Rectangle_4237"
            data-name="Rectangle 4237"
            width={1.286}
            height={0.9}
            transform="translate(7.966 2.997) rotate(-59.931)"
            fill={color}
          />
          <path
            id="Path_7940"
            data-name="Path 7940"
            d="M6,3.415a3.894,3.894,0,0,0-3.86,3.44h.91a2.987,2.987,0,0,1,5.94.45,2.933,2.933,0,0,1-.65,1.85l.693.538A3.831,3.831,0,0,0,9.891,7.3,3.9,3.9,0,0,0,6,3.415Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

SvgNoon.defaultProps = {
  width: 12,
  height: 12,
  color: COLORS.GREY_2,
};

export default SvgNoon;
