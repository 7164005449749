import { Breadcrumb } from "react-bootstrap";
import { FormTooltip, Shimmer, Truncate } from "src/components";
import { COLORS } from "src/constants";
import { classNames, getCycleIntervalString, range } from "src/helpers";
import { SvgArrow } from "src/icons";
import { TOKEN_TYPE } from "../formulaParser";
import FormulaMenuField from "./FormulaMenuField";
import FormulaMenuMetric from "./FormulaMenuMetric";
import FormulaMenuOperator from "./FormulaMenuOperator";
import FormulaMenuStatic from "./FormulaMenuStatic";

export const FORMULA_MENU_LIST = [
  {
    label: "Metric",
    key: TOKEN_TYPE.METRIC,
    Component: FormulaMenuMetric,
  },
  {
    label: "Field",
    key: TOKEN_TYPE.FIELD,
    Component: FormulaMenuField,
  },
  {
    label: "Operator",
    key: TOKEN_TYPE.OPERATOR,
    Component: FormulaMenuOperator,
  },
  {
    label: "Static",
    key: TOKEN_TYPE.STATIC,
    Component: FormulaMenuStatic,
  },
];

export const RenderBreadcrumb = ({ breadcrumbList, onClick }) => {
  if (!breadcrumbList.length) return null;

  return (
    <Breadcrumb className="formula_menu_metric">
      {breadcrumbList.map(({ label, key }, idx) => {
        return (
          <Breadcrumb.Item
            className="fs-13 semi-bold"
            onClick={() => onClick(key, idx)}
          >
            <Truncate maxWidth="100px" text={label} truncType="end" />
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export const ShimmerList = () => (
  <div className="space-y-16 p-3">
    {range(0, 2).map(() => (
      <div className="space-y-16">
        <Shimmer width="30%" />
        {range(0, 2).map(() => (
          <Shimmer width="100%" />
        ))}
      </div>
    ))}
  </div>
);

export const RenderOptions = ({
  options,
  id,
  groupValue,
  handleOnClick,
  t,
  disabledClassName,
}) => {
  return (
    <>
      {options.map((option) => {
        return (
          <FormTooltip
            text={option?.disabledText}
            disable={!option?.isDisabled}
          >
            <div
              className={classNames([
                "fs-13 cursor-pointer center formula_menu_item",
                option?.isDisabled &&
                  `fc-grey cursor-not-allowed ${disabledClassName}`,
              ])}
              onClick={() =>
                !option?.isDisabled
                  ? handleOnClick({ option, id, groupValue })
                  : null
              }
            >
              {option?.icon && <span className="w-26">{option?.icon}</span>}
              {option?.label && (
                <Truncate
                  maxWidth={option?.isSameProgram ? "150px" : "350px"}
                  text={option?.label}
                />
              )}
              {option?.isSameProgram && (
                <span className="ml-2 font-italic fc-grey1">
                  ( {t("INCENTIVES.COMMON.LABELS.CURRENT_PROGRAM")} )
                </span>
              )}
              {option?.hasFutherSteps && (
                <SvgArrow
                  color={COLORS.GREY_2}
                  rotate={-90}
                  className="ml-auto w-16"
                />
              )}
            </div>
          </FormTooltip>
        );
      })}
    </>
  );
};

export const RenderCombinedList = ({ data, handleOnClick, t }) => {
  return (
    <div className="formula_menu_metric_tab_list p-3">
      {data.map(({ groupLabel, groupValue, key, options }) => {
        if (!options?.length) return null;
        return (
          <div className="formula_menu_metric_tab_list_item mb-3">
            {groupLabel && (
              <div className="fs-13 fc-grey1 semi-bold">{groupLabel}</div>
            )}
            <div className="mt-12 space-y-8">
              <RenderOptions
                {...{ options, groupValue, id: key, handleOnClick, t }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const RecurrenceItem = ({
  idx,
  RecurrenceStartDate,
  RecurrenceEndDate,
}) => {
  return (
    <span>
      <span className="fs-13 semi-bold fc-grey1 mr-1">#{idx} :</span>
      <span>
        {getCycleIntervalString(RecurrenceStartDate, RecurrenceEndDate)}
      </span>
    </span>
  );
};
