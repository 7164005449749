import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import { classNames } from "src/helpers";
import "./formButton.scss";

export const variants = [
  "primary",
  "outline-primary",
  "link",
  "only-icon",
  "normal-link",
];

const FormButton = (props) => {
  const {
    id,
    as,
    disabled,
    className,
    variant,
    style,
    label,
    icon,
    disabledIcon,
    onClick,
    reverse,
    type,
    children,
    ...rest
  } = props;

  return (
    <Button
      {...rest}
      id={id}
      variant={variant === "only-icon" ? "link" : variant}
      type={type || "button"}
      as={as}
      style={style}
      onClick={onClick}
      disabled={disabled}
      className={classNames([
        icon ? "flex-center" : "",
        reverse ? "flex-row-reverse" : "",
        variant === "only-icon" ? "btn-only-icon " : "",
        disabled && "pointer-none",
        className || "",
      ])}
    >
      {icon && (
        <span
          className={classNames(["center", label && !reverse ? "mr-2" : ""])}
        >
          {disabledIcon || icon}
        </span>
      )}
      {icon && !label && <span className="sr-only">Only Icon</span>}
      {label && (
        <span className={classNames(["fs-13", icon && reverse ? "mr-2" : ""])}>
          {label}
        </span>
      )}
      {children}
    </Button>
  );
};

FormButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  as: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(variants).isRequired,
  buttonStyle: PropTypes.object,
  icon: PropTypes.node,
  disabledIcon: PropTypes.node,
  reverse: PropTypes.bool,
  onClick: PropTypes.func,
};

FormButton.defaultProps = {
  variant: "primary",
  disabled: false,
  reverse: false,
  onClick: () => {},
};

export default FormButton;
