import { gql, useQuery } from "src/api/client";

const GET_LEADERBOARD_MANAGERS = gql`
  query incentiveManagers {
    incentiveManagers {
      ... on incentiveManagersResponse {
        success
        message
        response {
          Managers
          EmailAddress
          UserID
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetLeaderboardManagers = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_MANAGERS, queryOptions);
};
