import React from "react";
import { COLORS } from "src/constants/generalConstants";
const SvgContribution = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M1.4 12 0 10.746l6-5.373 6 5.373L10.6 12 6 7.903zm0-5.373L0 5.373 6 0l6 5.373-1.4 1.254L6 2.53z"
      />
    </svg>
  );
};
SvgContribution.defaultProps = {
  color: COLORS.GREY_2,
  height: 12,
  width: 12,
};
export default SvgContribution;
