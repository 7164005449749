import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgHistory = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 13004">
        <path
          fill={color}
          d="M7.614 21.253H1.5A1.511 1.511 0 0 1 0 19.735V1.519A1.511 1.511 0 0 1 1.5.001h16A1.511 1.511 0 0 1 19 1.519v6h-1.5v-6h-16v18.216h6.114v1.516Z"
          data-name="Subtraction 41"
        />
        <path
          fill={color}
          fillRule="evenodd"
          d="M16.714 9.549a7.172 7.172 0 0 0-6.289 3.651l-1.764-1.751v4.946h4.985l-2.147-2.13a5.846 5.846 0 0 1 5.215-3.195 5.77 5.77 0 0 1 5.752 5.7 5.772 5.772 0 0 1-11.2 1.9H9.661a7.328 7.328 0 0 0 7.056 5.325 7.253 7.253 0 0 0 7.286-7.226 7.3 7.3 0 0 0-7.289-7.22Zm-1.153 3.8v3.883l3.6 2.13.614-.989-3.061-1.824v-3.2Z"
        />
        <path
          fill={color}
          d="M4.186 4.046h9.947v1.5H4.186z"
          data-name="Rectangle 4125"
        />
        <path
          fill={color}
          d="M4.186 7.08h9.947v1.5H4.186z"
          data-name="Rectangle 4127"
        />
        <path
          fill={color}
          d="M4.141 10.114h2.947v1.5H4.141z"
          data-name="Rectangle 4129"
        />
      </g>
    </svg>
  );
};

SvgHistory.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 24,
  width: 24,
};
export default SvgHistory;
