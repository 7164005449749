import React from "react";
import { COLORS } from "src/constants";

const SvgConnect = (props) => {
  const { color = COLORS.GREY_2, height = 12, width = 12, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="2s6j__a">
          <path d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#2s6j__a)">
        <path fill="none" d="M0 .001h12v12H0z" />
        <path
          fill={color}
          fillRule="evenodd"
          d="M7.733 2.397a1.32 1.32 0 0 1 1.865 1.87L7.62 6.245a1.319 1.319 0 0 1-1.867 0 .659.659 0 0 0-.932.932 2.637 2.637 0 0 0 3.73 0l1.979-1.98a2.638 2.638 0 0 0-3.73-3.73l-.989.989a.659.659 0 1 0 .932.932Zm-3.297 3.3a1.319 1.319 0 0 1 1.865 0 .659.659 0 1 0 .932-.932 2.637 2.637 0 0 0-3.73 0L1.525 6.744a2.638 2.638 0 0 0 3.73 3.73l.989-.989a.659.659 0 1 0-.932-.932l-.989.989a1.32 1.32 0 0 1-1.87-1.865Z"
        />
      </g>
    </svg>
  );
};

export default SvgConnect;
