import PropTypes from "prop-types";
import React from "react";
import { HeroBackground, Shimmer } from "src/components/common/atoms";

const BannerCardHeader = (props) => {
  const { loading, image, headerIcon, title, endTitle } = props;

  return (
    <HeroBackground image={image} className="p-4 banner-card-header-image">
      <div className="center">
        <div className="mr-3">{headerIcon}</div>
        <div>{title}</div>
        {loading ? (
          <Shimmer className="ml-auto" width="50px" height="40px" />
        ) : (
          <div className="ml-auto">{endTitle}</div>
        )}
      </div>
    </HeroBackground>
  );
};

BannerCardHeader.propTypes = {
  backgroundImage: PropTypes.string,
  headerIcon: PropTypes.node,
  title: PropTypes.node,
  endTitle: PropTypes.node,
};

export default BannerCardHeader;
