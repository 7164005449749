import React from "react";
import { APPS } from "src/constants";
import { Redirect, Route, Switch } from "src/router";
import { useIncentivesRbac } from "./hooks";
import { Pages } from "./pages";

export function Routes() {
  const { incentivesRbac, DEFAULT_LOGGED_IN_USER_REDIRECTION } =
    useIncentivesRbac();
  return (
    <Switch>
      <Route
        path={APPS.INCENTIVES.ROUTES.LANDING.path}
        page={Pages.LandingPage}
        nestedRoutes={[
          APPS.INCENTIVES.ROUTES.RULESETS.path,
          APPS.INCENTIVES.ROUTES.MANAGE_PROGRAM.path,
          APPS.INCENTIVES.ROUTES.APPROVAL.path,
          APPS.INCENTIVES.ROUTES.PAYOUT.path,
          APPS.INCENTIVES.ROUTES.DASHBOARD.path,
          APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path,
        ]}
        isPage
        {...incentivesRbac(APPS.INCENTIVES.ROUTES.LANDING.path)}
      >
        <div className="page-container">
          <Switch>
            <Route
              path={APPS.INCENTIVES.ROUTES.RULESETS.path}
              page={Pages.RulesetsPage}
              exact
              {...incentivesRbac(APPS.INCENTIVES.ROUTES.RULESETS.path)}
            />
            <Route
              path={APPS.INCENTIVES.ROUTES.MANAGE_PROGRAM.path}
              page={Pages.ManageProgramsPage}
              exact
              {...incentivesRbac(APPS.INCENTIVES.ROUTES.MANAGE_PROGRAM.path)}
            />
            <Route
              path={APPS.INCENTIVES.ROUTES.APPROVAL.path}
              page={Pages.ApprovalPage}
              exact
              {...incentivesRbac(APPS.INCENTIVES.ROUTES.APPROVAL.path)}
            />
            <Route
              path={APPS.INCENTIVES.ROUTES.PAYOUT.path}
              page={Pages.PayoutPage}
              exact
              {...incentivesRbac(APPS.INCENTIVES.ROUTES.PAYOUT.path)}
            />
            <Route
              path={APPS.INCENTIVES.ROUTES.DASHBOARD.path}
              page={Pages.DashboardPage}
              exact
              {...incentivesRbac(APPS.INCENTIVES.ROUTES.DASHBOARD.path)}
            />
            <Route
              path={APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path}
              page={Pages.MyIncentivesPage}
              exact
              {...incentivesRbac(APPS.INCENTIVES.ROUTES.MY_INCENTIVES.path)}
            />
            <Route path="*">
              <Redirect to={DEFAULT_LOGGED_IN_USER_REDIRECTION} />
            </Route>
          </Switch>
        </div>
      </Route>
      <Route
        path={APPS.INCENTIVES.ROUTES.INCENTIVE_CRUD.path}
        page={Pages.IncentiveCrudPage}
        isPage
        exact
        {...incentivesRbac(APPS.INCENTIVES.ROUTES.INCENTIVE_CRUD.path)}
      />
      <Route
        path={APPS.INCENTIVES.ROUTES.PUBLISHED_INCENTIVES_VIEW.path}
        page={Pages.PublishedIncentiveViewPage}
        isPage
        exact
        {...incentivesRbac(
          APPS.INCENTIVES.ROUTES.PUBLISHED_INCENTIVES_VIEW.path
        )}
      />
      <Route
        path={APPS.INCENTIVES.ROUTES.APPROVAL_DETAIL_VIEW.path}
        page={Pages.ApprovalDetailViewPage}
        isPage
        exact
        {...incentivesRbac(APPS.INCENTIVES.ROUTES.APPROVAL_DETAIL_VIEW.path)}
      />
      <Route
        path={APPS.INCENTIVES.ROUTES.PAYOUT_DETAIL_VIEW.path}
        page={Pages.PayoutDetailViewPage}
        isPage
        exact
        {...incentivesRbac(APPS.INCENTIVES.ROUTES.PAYOUT_DETAIL_VIEW.path)}
      />
      <Route
        path={APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.path}
        page={Pages.EndUserFlowPage}
        isPage
        exact
        {...incentivesRbac(
          APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.path
        )}
      />
      <Route
        path={APPS.INCENTIVES.ROUTES.PAYOUT_REQUEST_PAGE.path}
        page={Pages.PayoutRequestPage}
        isPage
        exact
        {...incentivesRbac(APPS.INCENTIVES.ROUTES.PAYOUT_REQUEST_PAGE.path)}
      />

      <Route
        path={APPS.INCENTIVES.ROUTES.DASHBOARD_DETAILS_PAGE.path}
        page={Pages.DashboardIncentiveDetailsPage}
        isPage
        exact
        {...incentivesRbac(APPS.INCENTIVES.ROUTES.DASHBOARD_DETAILS_PAGE.path)}
      />
      <Route
        path={APPS.INCENTIVES.ROUTES.DASHBOARD_PROGRAM_DETAIL_VIEW_PAGE.path}
        page={Pages.DashboardProgramDetailViewPage}
        isPage
        exact
        {...incentivesRbac(
          APPS.INCENTIVES.ROUTES.DASHBOARD_PROGRAM_DETAIL_VIEW_PAGE.path
        )}
      />
      <Route
        path={APPS.INCENTIVES.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path}
        page={Pages.DashboardUserDetailViewPage}
        isPage
        exact
        {...incentivesRbac(
          APPS.INCENTIVES.ROUTES.DASHBOARD_USER_DETAIL_VIEW_PAGE.path
        )}
      />

      <Route
        path={APPS.INCENTIVES.ROUTES.APPROVAL_REQUEST_PAGE.path}
        page={Pages.ApprovalRequestPage}
        isPage
        exact
        {...incentivesRbac(APPS.INCENTIVES.ROUTES.APPROVAL_REQUEST_PAGE.path)}
      />
      <Route
        path={APPS.INCENTIVES.ROUTES.REQUEST_DEMO.path}
        page={Pages.RequestDemoPage}
        isPage
        exact
        {...incentivesRbac(APPS.INCENTIVES.ROUTES.REQUEST_DEMO.path)}
      />
      <Route path="*">
        <Redirect to={APPS.INCENTIVES.ROUTES.LANDING.path} />
      </Route>
    </Switch>
  );
}
