export const RULESET_SORTING_OPTIONS = {
  RULESET_NAME_ASC: {
    key: "Name",
    order: "ASC",
    label: "Ruleset Name (A-Z)",
  },
  RULESET_NAME_DESC: {
    key: "Name",
    order: "DESC",
    label: "Ruleset Name (Z-A)",
    showBorder: true,
  },
  RULESET_TYPE_ASC: {
    key: "AuditType",
    order: "ASC",
    label: "Ruleset Type (A-Z)",
  },
  RULESET_TYPE_DESC: {
    key: "AuditType",
    order: "DESC",
    label: "Ruleset Type (Z-A)",
    showBorder: true,
  },
  MODIFIED_DATE_ASC: {
    key: "updatedAt",
    order: "ASC",
    label: "Last Modified (Oldest First)",
  },
  MODIFIED_DATE_DESC: {
    key: "updatedAt",
    order: "DESC",
    label: "Last Modified (Latest First)",
  },
};

export const DRILLDOWN_SORT_OPTIONS = {
  USER_NAME_ASC: {
    key: "FullName",
    order: "ASC",
    label: "User Name A-Z",
  },
  USER_NAME_DESC: {
    key: "FullName",
    order: "DESC",
    label: "User Name Z-A",
    showBorder: true,
  },
  CONTRIBUTION_ASC: {
    key: "Achievement",
    order: "ASC",
    label: "Contribution (Lowest First)",
  },
  CONTRIBUTION_DESC: {
    key: "Achievement",
    order: "DESC",
    label: "Contribution (Highest First)",
    showBorder: true,
  },
};

export const ASSIGNED_GOAL_VIEW_SORT_OPTIONS = {
  USER_NAME_ASC: {
    key: "FullName",
    order: "ASC",
    label: "User Name A-Z",
  },
  USER_NAME_DESC: {
    key: "FullName",
    order: "DESC",
    label: "User Name Z-A",
    showBorder: true,
  },
  GOAL_DISTRIBUTION_DESC: {
    key: "GoalDistribution",
    order: "DESC",
    label: "Goal Value (Highest First)",
  },
  GOAL_DISTRIBUTION_ASC: {
    key: "GoalDistribution",
    order: "ASC",
    label: "Goal Value (Lowest First)",
    showBorder: true,
  },
  ACHIEVED_PERCENTAGE_DESC: {
    key: "AchievedPercentage",
    order: "DESC",
    label: "Achieved % (highest first)",
  },
  ACHIEVED_PERCENTAGE_ASC: {
    key: "AchievedPercentage",
    order: "ASC",
    label: "Achieved % (lowest first)",
    showBorder: true,
  },
  ACHIEVEMENT_DESC: {
    key: "Achievement",
    order: "DESC",
    label: "Achievement (Highest First)",
  },
  ACHIEVEMENT_ASC: {
    key: "Achievement",
    order: "ASC",
    label: "Achievement (Lowest First)",
    showBorder: true,
  },
};

export const MANAGED_GOAL_VIEW_SORT_OPTIONS = {
  USER_NAME_ASC: {
    key: "FullName",
    order: "ASC",
    label: "User Name A-Z",
  },
  USER_NAME_DESC: {
    key: "FullName",
    order: "DESC",
    label: "User Name Z-A",
    showBorder: true,
  },
  GOAL_DISTRIBUTION_DESC: {
    key: "GoalDistribution",
    order: "DESC",
    label: "Goal Value (Highest First)",
  },
  GOAL_DISTRIBUTION_ASC: {
    key: "GoalDistribution",
    order: "ASC",
    label: "Goal Value (Lowest First)",
    showBorder: true,
  },
  ACHIEVED_PERCENTAGE_DESC: {
    key: "AchievedPercentage",
    order: "DESC",
    label: "Achieved % (highest first)",
  },
  ACHIEVED_PERCENTAGE_ASC: {
    key: "AchievedPercentage",
    order: "ASC",
    label: "Achieved % (lowest first)",
    showBorder: true,
  },
  ACHIEVEMENT_DESC: {
    key: "Achievement",
    order: "DESC",
    label: "Achievement (Highest First)",
  },
  ACHIEVEMENT_ASC: {
    key: "Achievement",
    order: "ASC",
    label: "Achievement (Lowest First)",
    showBorder: true,
  },
};

export const INCENTIVES_SORT_OPTIONS = {
  USER_NAME_ASC: {
    key: "FullName",
    order: "ASC",
    label: "User Name A-Z",
  },
  USER_NAME_DESC: {
    key: "FullName",
    order: "DESC",
    label: "User Name Z-A",
    showBorder: true,
  },
  ACHIEVEMENTS_COUNT_DESC: {
    label: "No. of Criteria Achieved (highest first)",
    key: "AchievementsCount",
    order: "DESC",
  },
  ACHIEVEMENTS_COUNT_ASC: {
    label: "No. of Criteria Achieved (lowest first)",
    key: "AchievementsCount",
    order: "ASC",
    showBorder: true,
  },
  ELIGIBLE_BENEFIT_DESC: {
    label: "Eligible Incentive (highest first)",
    key: "EligibleBenefit",
    order: "DESC",
  },
  ELIGIBLE_BENEFIT_ASC: {
    label: "Eligible Incentive (lowest first)",
    key: "EligibleBenefit",
    order: "ASC",
    showBorder: true,
  },
  APPROVED_BENEFIT_DESC: {
    label: "Approved Incentive (highest first)",
    key: "ApprovedBenefit",
    order: "DESC",
  },
  APPROVED_BENEFIT_ASC: {
    label: "Approved Incentive (lowest first)",
    key: "ApprovedBenefit",
    order: "ASC",
    showBorder: true,
  },
  REJECTED_INCENTIVE_DESC: {
    label: "Rejected Incentive (highest first)",
    key: "RejectedBenefit",
    order: "DESC",
  },
  REJECTED_INCENTIVE_ASC: {
    label: "Rejected Incentive (lowest first)",
    key: "RejectedBenefit",
    order: "ASC",
    showBorder: true,
  },
};

export const LEADERBOARD_SORT_OPTIONS = {
  USER_NAME_ASC: {
    key: "FullName",
    order: "ASC",
    label: "User Name A-Z",
  },
  USER_NAME_DESC: {
    key: "FullName",
    order: "DESC",
    label: "User Name Z-A",
    showBorder: true,
  },
  RANK_ASC: {
    label: `Rank (highest first)`,
    key: "Rank",
    order: "ASC",
  },
  RANK_DESC: {
    label: `Rank (lowest first)`,
    key: "Rank",
    order: "DESC",
    showBorder: true,
  },
  RANK_BASED_REWARD_DESC: {
    label: `Rank Based Reward (highest first)`,
    key: "RankBasedReward",
    order: "DESC",
  },
  RANK_BASED_REWARD_ASC: {
    label: `Rank Based Reward (lowest first)`,
    key: "RankBasedReward",
    order: "ASC",
    showBorder: true,
  },
  GROUP_REWARD_DESC: {
    label: `Group Reward (highest first)`,
    key: "GroupReward",
    order: "DESC",
  },
  GROUP_REWARD_ASC: {
    label: `Group Reward (lowest first)`,
    key: "GroupReward",
    order: "ASC",
    showBorder: true,
  },
  APPROVED_BENEFIT_DESC: {
    label: `Approved (highest first)`,
    key: "ApprovedBenefit",
    order: "DESC",
  },
  APPROVED_BENEFIT_ASC: {
    label: `Approved (lowest first)`,
    key: "ApprovedBenefit",
    order: "ASC",
    showBorder: true,
  },
  REJECTED_BENEFIT_DESC: {
    label: `Rejected (highest first)`,
    key: "RejectedBenefit",
    order: "DESC",
  },
  REJECTED_BENEFIT_ASSC: {
    label: `Rejected (lowest first)`,
    key: "RejectedBenefit",
    order: "ASC",
    showBorder: true,
  },
};
