import { useEffect } from "react";
import { Menu, useMenu } from "src/components/common/atoms";
import { classNames } from "src/helpers";
import { useToggle } from "src/hooks";
import { SvgArrow } from "src/icons";
import "./glassDropdown.scss";

export const GlassDropdownItem = ({ onClick, children, handleMenuClose }) => {
  return (
    <button onClick={onClick} className="glass-dropdown-item">
      <div className="glass-dropdown-item-content">{children}</div>
    </button>
  );
};

function GlassDropdown({
  className,
  hideButton = false,
  customMenu = false,
  toggleText,
  items,
  children,
}) {
  const menu = useMenu();
  const [clicked, toggle] = useToggle();

  const handleToggleClick = (event) => {
    toggle();
    menu.handleClick(event);
  };

  const handleClose = () => {
    toggle();
    menu.handleClose();
  };

  useEffect(() => {
    if (!menu.el && clicked) {
      toggle();
    }
  }, [menu.el, clicked, toggle]);

  const open = menu.el && clicked;

  return (
    <div
      ref={menu.containerRef}
      className={classNames(["glass-dropdown", open ? "open" : "", className])}
    >
      <button className="toggle" onClick={handleToggleClick}>
        <div className="toggle-text">{toggleText}</div>
        <SvgArrow width="24px" height="24px" rotate={open ? 180 : 0} />
      </button>
      <Menu el={open} container={menu.containerRef.current}>
        <div className="menu-content">
          {customMenu ? (
            customMenu({ handleClose })
          ) : (
            <div className="menu-list">
              {items.map((item, index) => {
                return children({ item, index, handleClose });
              })}
            </div>
          )}
        </div>
      </Menu>
    </div>
  );
}

export default GlassDropdown;
