import { gql, useQuery } from "src/api/client";

const GET_PAYOUT_PROGRAMS_FOR_USER_QUERY = gql`
  query payoutProgramsForUser(
    $userId: ID!
    $query: JSON
    $limit: Float
    $after: String
    $programId: ID
    $FeatureType: String!
  ) {
    payoutProgramsForUser(
      userId: $userId
      query: $query
      limit: $limit
      after: $after
      programId: $programId
      FeatureType: $FeatureType
    ) {
      ... on PayoutProgramsForUserResponse {
        response {
          totalCount
          payoutPrograms {
            programId
            recurrenceId
            programName
            cycle
            payoutDone
            approvedBenefit
            payoutStatus
            payoutPending
            PaidOutRewards {
              RewardName
            }
            PendingRewards {
              RewardName
            }
            ApprovedRewards {
              RewardName
            }
            ApprovedRewardsCount
            PendingRewardsCount
            PaidOutRewardsCount
          }
          pageInfo {
            endCursor
            __typename
          }
        }
      }
    }
  }
`;

export const usePayoutLeaderboardsForUser = (queryOptions) => {
  return useQuery(GET_PAYOUT_PROGRAMS_FOR_USER_QUERY, queryOptions);
};
