export const FIELD_NAMES = {
  ELIGIBLE: "ELIGIBLE",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  APPROVAL_PENDING: "APPROVAL_PENDING",
  PAYOUT_PENDING: "PAYOUT_PENDING",
  PAIDOUT: "PAIDOUT",
  RANKBASED: "RANKBASED",
  GROUPBASED: "GROUPBASED",
};
