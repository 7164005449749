import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const UPDATE_INCENTIVE_ATTRIBUTES_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation updateProgram(
    $ProgramId: ID!
    $RecurrenceDefinition: RecurrenceDefinition
    $Type: ProgramTypeEnum
    $IsRollup: Boolean
    $Templates: [ID]
    $BufferDays: [Float]
    $SelectedGoals: [SelectedGoalInput]
    $RollupType: RollupTypeEnum
    $DisableApprovalPayout: Boolean
    $VisibilitySettings: VisibilitySettingsInput
  ) {
    updateProgram(
      ProgramId: $ProgramId
      RecurrenceDefinition: $RecurrenceDefinition
      Type: $Type
      IsRollup: $IsRollup
      Templates: $Templates
      BufferDays: $BufferDays
      SelectedGoals: $SelectedGoals
      RollupType: $RollupType
      DisableApprovalPayout: $DisableApprovalPayout
      VisibilitySettings: $VisibilitySettings
    ) {
      __typename
      ... on SingleProgramResponse {
        response {
          Program {
            ProgramId
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;

export const useUpdateIncentiveAttributes = () => {
  return useMutation(UPDATE_INCENTIVE_ATTRIBUTES_MUTATION);
};

const UPDATE_INCENTIVE_CRITERIA_MUTATION = gql`
  mutation addIncentiveCriteria(
    $ProgramId: ID!
    $Criteria: LZStringJSON!
    $RecurrenceId: ID!
    $Type: String!
  ) {
    addIncentiveCriteria(
      Criteria: $Criteria
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      Type: $Type
    ) {
      ... on AddCriteriaResponse {
        message
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useUpdateIncentiveCriteria = () => {
  return useMutation(UPDATE_INCENTIVE_CRITERIA_MUTATION);
};

export const UPDATE_INCENTIVE_STATUS_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation updateProgram($ProgramId: ID!, $Status: StatusEnum) {
    updateProgram(ProgramId: $ProgramId, Status: $Status) {
      __typename
      ... on SingleProgramResponse {
        message
        response {
          Program {
            ProgramId
            Status
          }
          __typename
        }
        __typename
      }
      ...errorResponseFragment
    }
  }
`;
export const useUpdateIncentiveStatus = (queryOptions) => {
  return useMutation(UPDATE_INCENTIVE_STATUS_MUTATION, queryOptions);
};

const UPDATE_INCENTIVE_PROGRAM_FILE_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation updateProgram(
    $ProgramId: ID!
    $Name: NotEmpty
    $Description: String
    $FileName: FileName
    $FileId: Float
  ) {
    updateProgram(
      ProgramId: $ProgramId
      Name: $Name
      Description: $Description
      FileName: $FileName
      FileId: $FileId
    ) {
      __typename
      ... on SingleProgramResponse {
        response {
          Program {
            ProgramId
            FileName
          }
          __typename
        }
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useUpdateProgramFile = () => {
  return useMutation(UPDATE_INCENTIVE_PROGRAM_FILE_MUTATION);
};
