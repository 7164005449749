import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const COPY_LEADERBOARD_GROUP_RULES = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation copyLeaderboardGroupRules(
    $SourceGroupId: ID!
    $DestinationGroupIds: [ID]!
  ) {
    copyLeaderboardGroupRules(
      SourceGroupId: $SourceGroupId
      DestinationGroupIds: $DestinationGroupIds
    ) {
      ... on CopyLeaderboardGroupRulesResponse {
        success
        message
        response
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useCopyLeaderboardGroupRules = () => {
  return useMutation(COPY_LEADERBOARD_GROUP_RULES);
};
