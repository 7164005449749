import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { range } from "src/helpers";

const LoadingSetAttributeLeaderboard = () => (
  <div className="mx-5 mt-45">
    <Shimmer width="150px" height="10px" />
    <div className="center mt-3">
      <div className="mr-3">
        <Shimmer width="300px" height="100px" />
      </div>
      <Shimmer width="300px" height="100px" />
    </div>
    <div className="border-b w-75 my-4" />
    <Shimmer width="150px" height="10px" />
    <div className="center mt-3">
      <div className="mr-3">
        <Shimmer width="250px" height="50px" />
      </div>
      <div className="mr-3">
        <Shimmer width="250px" height="50px" />
      </div>
      <Shimmer width="250px" height="50px" />
    </div>
    <div className="border-b w-75 mt-32" />
    <div className="w-50 center">
      <div className="w-50 mt-32">
        <div>
          <Shimmer width="50%" height="10px" />
        </div>
        <div className="mt-3">
          <Shimmer width="100%" height="10px" />
        </div>
        <div className="mt-2">
          <Shimmer width="75%" height="10px" />
        </div>
        {range(0, 2).map((i, idx) => (
          <div key={idx}>
            <div className="mt-5">
              <Shimmer width="50%" height="10px" />
            </div>
            <div className="mt-4">
              <Shimmer width="100%" height="40px" />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default LoadingSetAttributeLeaderboard;
