import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const SAVE_LEADERBOARD_RULE = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation saveLeaderboardRule(
    $LeaderboardId: ID!
    $RecurrenceId: ID!
    $Rule: JSON!
  ) {
    saveLeaderboardRule(
      Rule: $Rule
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
    ) {
      ... on AddRuleResponse {
        message
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useSaveLeaderboardRule = () => {
  return useMutation(SAVE_LEADERBOARD_RULE);
};
