import { gql, useMutation } from "src/api/client";

// src/modules/templates/headerContainer/PublishTemplate.js
export const UPDATE_TEMPLATE_STATUS_MUTATION = gql`
  mutation UpdateTemplate(
    $TemplateId: ID!
    $Status: StatusEnum
    $IsCustomFormula: Boolean
    $Formula: String
  ) {
    updateTemplate(
      TemplateId: $TemplateId
      Status: $Status
      IsCustomFormula: $IsCustomFormula
      Formula: $Formula
    ) {
      ... on SingleTemplateResponse {
        response {
          Template {
            Name
            Description
            Status
          }
        }
        success
        message
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useUpdateTemplateStatus = () => {
  return useMutation(UPDATE_TEMPLATE_STATUS_MUTATION);
};
