import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgCopy = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="lc633_a">
          <path d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
      <g fill={color} clipPath="url(#lc633_a)" data-name="Copy ">
        <path
          d="M10.037 14H.62a.62.62 0 0 1-.62-.62V3.968a.62.62 0 0 1 .62-.62h9.417a.62.62 0 0 1 .62.62v9.412a.62.62 0 0 1-.62.62Zm-8.8-1.241h8.177V4.588H1.239Z"
          data-name="Path 4533"
        />
        <path
          d="M13.381 10.652h-3.344a.62.62 0 0 1 0-1.241h2.724v-8.17H4.584v2.727a.62.62 0 1 1-1.239 0V.62a.62.62 0 0 1 .62-.62h9.417a.62.62 0 0 1 .62.62v9.412a.62.62 0 0 1-.621.62Z"
          data-name="Path 4534"
        />
      </g>
    </svg>
  );
};

SvgCopy.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 14,
  width: 14,
};
export default SvgCopy;
