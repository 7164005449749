import { t } from "i18next";
import { Modal } from "react-bootstrap";
import { FormButton } from "src/components";
import "./completeProgramModal.scss";

const CompleteProgramModal = ({
  show,
  handleClose,
  handleDiscard,
  heading,
  children,
}) => {
  return (
    <Modal
      className="complete-program-modal"
      backdropClassName="complete-program-modal-backdrop"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className="semi-bold fs-16" closeButton>
        {heading}
      </Modal.Header>
      <Modal.Body className="mx-4 my-3 fs-13">
        {children}
        <div className="mt-4 d-flex">
          <FormButton
            label={t("MANAGED_GOALS.BUTTONS.COMPLETE_DETAILS")}
            onClick={handleClose}
            id="incentive-click-away-cancel"
            className="mr-3"
          />
          <FormButton
            label={t("MANAGED_GOALS.BUTTONS.DISCARD_AND_PROCEED")}
            variant="outline-primary"
            id="incentive-click-away-discard"
            onClick={handleDiscard}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompleteProgramModal;
