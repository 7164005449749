export const MY_PROGRAM_VIEW = {
  PROGRAM_OVERVIEW: "overview",
  PROGRAM_CRITERIAS: "program_criterias",
  PROGRAM_HISTORY: "program_history",
};

export const MY_PROGRAM_VIEW_FILTER_BUTTONS = [
  {
    key: MY_PROGRAM_VIEW.PROGRAM_OVERVIEW,
    label: "INCENTIVES.MY_INCENTIVES.LABELS.OVERVIEW",
  },
  {
    key: MY_PROGRAM_VIEW.PROGRAM_CRITERIAS,
    label: "INCENTIVES.MY_INCENTIVES.LABELS.CRITERIA_AND_ACHIEVEMENTS",
  },
  {
    key: MY_PROGRAM_VIEW.PROGRAM_HISTORY,
    label: "INCENTIVES.MY_INCENTIVES.LABELS.INCENTIVES_HISTORY",
  },
];
