import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgTrophy = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      id="Group_16145"
      data-name="Group 16145"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 21"
      role="img"
      {...rest}
    >
      <g id="Group_16144" data-name="Group 16144" transform="translate(0 0)">
        <g id="Group_16143" data-name="Group 16143">
          <path
            id="Path_9449"
            data-name="Path 9449"
            d="M72.173,2.092a3.189,3.189,0,0,0-2.188-.813h-.21Q69.8.83,69.8.373A.428.428,0,0,0,69.335,0H55.353a.428.428,0,0,0-.468.373q0,.457.027.907H54.7a3.188,3.188,0,0,0-2.187.812,1.96,1.96,0,0,0-.628,1.858l.63,2.878a2.75,2.75,0,0,0,2.816,1.887h2.4a6.674,6.674,0,0,0,3.008,1.993v2.677H58.727a.428.428,0,0,0-.468.373v2.07h-1.18a.428.428,0,0,0-.468.373v4.425a.428.428,0,0,0,.468.373H67.608a.428.428,0,0,0,.468-.373V16.2a.428.428,0,0,0-.468-.373h-1.18v-2.07a.428.428,0,0,0-.468-.373H63.943V10.71a6.674,6.674,0,0,0,3.009-1.994h2.4A2.749,2.749,0,0,0,72.17,6.829L72.8,3.951A1.96,1.96,0,0,0,72.173,2.092Zm-2.65,1.176h.462a.391.391,0,0,1,.28.1.243.243,0,0,1,.079.235l-.631,2.879a.349.349,0,0,1-.358.239H68.322A12.686,12.686,0,0,0,69.523,3.269ZM56.365,6.727H55.332a.35.35,0,0,1-.359-.24l-.63-2.879a.245.245,0,0,1,.08-.237.39.39,0,0,1,.278-.1h.462A12.687,12.687,0,0,0,56.365,6.727ZM55.332,7.97h0A1.85,1.85,0,0,1,53.438,6.7l-.63-2.878a1.319,1.319,0,0,1,.422-1.25A2.145,2.145,0,0,1,54.7,2.026h.273q.027.25.063.5H54.7a1.417,1.417,0,0,0-.994.369.89.89,0,0,0-.286.845l.63,2.878a1.249,1.249,0,0,0,1.28.858H56.8c.063.1.127.2.192.293q.071.1.144.2h-1.8Zm11.808,8.6v3.68H57.547v-3.68Zm-1.647-2.442v1.7h-6.3v-1.7Zm-3.814-.746v-2.48a5.409,5.409,0,0,0,1.329,0v2.48Zm5.2-5.978A5.637,5.637,0,0,1,62.344,10.2a5.637,5.637,0,0,1-4.533-2.794A12.4,12.4,0,0,1,55.825.746H68.863A12.405,12.405,0,0,1,66.877,7.408Zm5-3.585L71.249,6.7A1.849,1.849,0,0,1,69.356,7.97h-1.8q.073-.1.144-.2c.066-.1.13-.194.192-.293h1.466a1.248,1.248,0,0,0,1.279-.857l.631-2.879a.888.888,0,0,0-.286-.843,1.414,1.414,0,0,0-1-.37H69.65q.035-.247.063-.5h.273a2.146,2.146,0,0,1,1.472.547A1.318,1.318,0,0,1,71.88,3.822Z"
            transform="translate(-51.844 0)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

SvgTrophy.defaultProps = {
  width: 21,
  height: 21,
  color: COLORS.PRIMARY_BLUE,
};

export default SvgTrophy;
