import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecurrenceList } from "src/components/common/atoms";
import {
  FormNumberInput,
  FormTooltip,
} from "src/components/common/formElements";
import {
  CopyToAllModal,
  CopyToAllModalDefaultProps,
  CopyToAllModalProps,
} from "src/components/common/molecules";
import {
  CYCLE_BUFFER_DAYS,
  DEFAULT_BUFFER_DAYS,
  FEATURE_TYPES,
  MAX_CYCLE_DAYS,
  RECURRENCE_PERIOD,
} from "src/constants";
import {
  addDays,
  classNames,
  debounce,
  getDays,
  getRecurrenceStatus,
  getTodayAsPerTimezone,
} from "src/helpers";
import "./bufferDaysCycleSummary.scss";

const BufferDaysCycleSummaryPropTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  recurrences: PropTypes.arrayOf(PropTypes.object),
  selectedCustomCycleType: PropTypes.string,
  recurrencePeriod: PropTypes.oneOf(RECURRENCE_PERIOD),
  copyModalProps: PropTypes.shape(CopyToAllModalProps),
  bufferDaysProps: PropTypes.shape({
    bufferContainerTitle: PropTypes.func,
    disableBufferDaysInput: PropTypes.bool,
    bufferNote: PropTypes.string,
    bufferDays: PropTypes.number,
    bufferDaysFeature: PropTypes.bool, // to turn on/off buffer days feature
    bufferDaysPayload: PropTypes.arrayOf(PropTypes.string), // parent state, will be coming from API
    handleBufferDays: PropTypes.func, // to set the parent state for the payload
  }),
};

const BufferDaysCycleSummaryDefaultProps = {
  startDate: null,
  endDate: null,
  recurrences: [],
  selectedCustomCycleType: "",
  recurrencePeriod: "",
  copyModalProps: CopyToAllModalDefaultProps,
  bufferDaysProps: {
    bufferContainerTitle: () => {},
    disableBufferDaysInput: false,
    bufferNote: "",
    bufferDays: DEFAULT_BUFFER_DAYS,
    bufferDaysFeature: false,
    bufferDaysPayload: [],
    handleBufferDays: () => {},
    defaultBufferDays: 2,
  },
  moduleName: FEATURE_TYPES.INCENTIVE,
};

const BufferDaysCycleSummary = (props) => {
  const {
    isDraft,
    startDate,
    endDate,
    recurrencePeriod,
    selectedCustomCycleType,
    recurrences,
    copyModalProps,
    bufferDaysProps,
    moduleName,
  } = props;

  const {
    bufferContainerTitle,
    disableBufferDaysInput,
    bufferNote,
    defaultBufferDays,
    bufferDaysFeature,
    bufferDaysPayload,
    handleBufferDays,
  } = bufferDaysProps;

  const { t } = useTranslation();
  const today = getTodayAsPerTimezone();

  const getMinBufferdays = (recurrence) => {
    const minBufferDays = getDays(
      recurrence?.RecurrenceEndDate - today.getTime()
    );
    return minBufferDays >= CYCLE_BUFFER_DAYS[recurrencePeriod]?.MIN_BUFFER_DAYS
      ? CYCLE_BUFFER_DAYS[recurrencePeriod]?.MIN_BUFFER_DAYS
      : Math.floor(Math.abs(minBufferDays));
  };

  const computedBufferDays = recurrences.map((recurrence) => {
    if (isDraft && getRecurrenceStatus(recurrence) === "COMPLETED") {
      return bufferDaysPayload?.[recurrence.id - 1] >= 0
        ? bufferDaysPayload?.[recurrence.id - 1]
        : getMinBufferdays(recurrence);
    }

    if (bufferDaysPayload?.length) {
      return bufferDaysPayload?.[recurrence.id - 1] >= 0
        ? bufferDaysPayload?.[recurrence.id - 1]
        : defaultBufferDays;
    }

    return defaultBufferDays;
  });

  const [bufferDays, setBufferDays] = useState(computedBufferDays);

  const [showCopyModal, setShowCopyModal] = useState({
    id: 0,
    show: false,
  });

  useEffect(() => {
    if (bufferDaysFeature && recurrences.length) {
      setBufferDays(computedBufferDays);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrences.length]);

  useEffect(() => {
    if (handleBufferDays) handleBufferDays(bufferDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bufferDays]);

  const getRecurrenceColor = (recurrence) => {
    const { recurrenceStatus } = recurrence;
    switch (recurrenceStatus) {
      case "INPROGRESS":
        return "fc-black semi-bold";
      case "COMPLETED":
        return "fc-grey-primary";
      default:
        return "";
    }
  };

  const getBadgeColor = (recurrence) => {
    const { recurrenceStatus } = recurrence;
    switch (recurrenceStatus) {
      case "INPROGRESS":
        return "bg-blue2 semi-bold fc-black";
      case "COMPLETED":
        return "bg-grey6 fc-grey1";
      default:
        return "bg-blue2 fc-black";
    }
  };

  const copyRecurrences = recurrences.map((recurrence) => ({
    ...recurrence,
    RecurrenceId: recurrence.id.toString(),
    RecurrenceStatus: getRecurrenceStatus(recurrence),
  }));

  const handleDisableCycles = (recurrence, selectedRecurrence) => {
    if (
      recurrence?.recurrenceStatus === "COMPLETED" ||
      recurrence?.RecurrenceStatus === "COMPLETED"
    )
      return true;

    let disabledInput = true;
    if (disableBufferDaysInput) {
      disabledInput = disableBufferDaysInput(recurrence);
    }

    const minBufferDays = getMinBufferdays(recurrence);

    // if selected recrrence buffer days is less than minimum buffer days
    const lessThanMinimumBufferDays = selectedRecurrence
      ? bufferDays[selectedRecurrence.id - 1] < minBufferDays
      : false;

    // Recurrence last date including the buffer days.
    const recurrenceLastDate = addDays(
      recurrence?.RecurrenceEndDate,
      bufferDays[recurrence.id - 1]
    );
    // Days between the today and recurrenceLastDate
    const daysBetweenTodayAndBufferDays = getDays(
      today.getTime() - recurrenceLastDate.getTime()
    );
    /**
     * disable when: currentDate > endDate + bufferDay
     * if daysBetweenTodayAndBufferDays is greater than 1.
     */

    return (
      disabledInput &&
      (daysBetweenTodayAndBufferDays >= 1 || lessThanMinimumBufferDays)
    );
  };

  const disabled = (recurrence, selectedRecurrence) => {
    let disabledInput = true;
    if (disableBufferDaysInput) {
      disabledInput = disableBufferDaysInput(recurrence);
    }

    const minBufferDays = getMinBufferdays(recurrence);

    // if selected recrrence buffer days is less than minimum buffer days
    const lessThanMinimumBufferDays = selectedRecurrence
      ? bufferDays[selectedRecurrence.id - 1] < minBufferDays
      : false;

    // Recurrence last date including the buffer days.
    const recurrenceLastDate = addDays(
      recurrence?.RecurrenceEndDate,
      bufferDays[recurrence.id - 1]
    );
    // Days between the today and recurrenceLastDate
    const daysBetweenTodayAndBufferDays = getDays(
      today.getTime() - recurrenceLastDate.getTime()
    );
    /**
     * disable when: currentDate > endDate + bufferDay
     * if daysBetweenTodayAndBufferDays is greater than 1.
     */

    return (
      disabledInput &&
      (daysBetweenTodayAndBufferDays >= 1 || lessThanMinimumBufferDays)
    );
  };

  const formatOptionLabelAfter = (recurrence) => {
    const { id, recurrenceStatus } = recurrence;
    const {
      modalTitle,
      copyText,
      loading,
      disabledSaveBtn,
      copySubText,
      saveBtnText,
    } = copyModalProps;

    const handleChange = debounce(
      (val) => {
        const newBufferDays = [...bufferDays];
        newBufferDays[id - 1] = Number.isNaN(val) ? bufferDays[id - 1] : val;
        setBufferDays(newBufferDays);
      },
      [250]
    );

    const selectedRecurrenceCopyText = (
      <span className="px-4 mx-4 border-x-1 bc-grey2">
        {t("COMMON.BUTTONS.BUFFER_DAYS")}:{" "}
        <b className="fc-black">{bufferDays[id - 1]}</b>
      </span>
    );

    return (
      <>
        <div className="w-50">
          <FormNumberInput
            value={bufferDays[id - 1]}
            handleChange={handleChange}
            min={getMinBufferdays(recurrence)}
            max={
              recurrenceStatus === "COMPLETED"
                ? MAX_CYCLE_DAYS
                : CYCLE_BUFFER_DAYS[recurrencePeriod]?.MAX_BUFFER_DAYS({
                    startDate,
                    endDate,
                  })
            }
            disabled={isDraft ? false : disabled(recurrence)}
            disabledClassName="cursor-not-allowed"
            disabledTooltipProps={{
              text: t("INCENTIVES.COMMON.MESSAGES.DISABLED_BUFFER_TOOLTIP"),
              placement: "bottom",
            }}
            afterInputProps={{
              onClick:
                recurrences.length === 1 ||
                recurrenceStatus === "COMPLETED" ||
                recurrencePeriod === "Infinite"
                  ? () => {}
                  : () =>
                      setShowCopyModal({
                        id: id - 1,
                        show: true,
                      }),
            }}
            afterInputText={
              <FormTooltip
                text={t(
                  "COMMON.MESSAGES.ONLY_ONE_CYCLE_PRESENT_IN_THIS_PROGRAM",
                  {
                    value:
                      moduleName === FEATURE_TYPES.GOAL
                        ? moduleName.toLowerCase()
                        : `${moduleName.toLowerCase()} ${t(
                            "COMMON.LABELS.PROGRAM"
                          )}`,
                  }
                )}
                placement="bottom"
                disable={
                  recurrences.length !== 1 ||
                  recurrenceStatus !== "COMPLETED" ||
                  recurrencePeriod !== "Infinite"
                }
              >
                {t("COMMON.BUTTONS.COPY")}
              </FormTooltip>
            }
            afterInputClassName={classNames([
              "after-input-text",
              "fc-primary-blue cursor-pointer",
              recurrences.length === 1 ||
              recurrenceStatus === "COMPLETED" ||
              recurrencePeriod === "Infinite"
                ? "disable-btn"
                : "",
            ])}
          />
        </div>
        {recurrences &&
          recurrence &&
          showCopyModal?.show &&
          id - 1 === showCopyModal?.id && (
            <CopyToAllModal
              show={showCopyModal}
              handleSave={(checkedRecurrences) => {
                const newBufferDays = [...bufferDays];
                checkedRecurrences.forEach((rec) => {
                  newBufferDays[Number(rec) - 1] = disabled(
                    copyRecurrences[rec - 1]
                  )
                    ? newBufferDays[Number(rec) - 1]
                    : bufferDays[id - 1];
                });
                setBufferDays(newBufferDays);
                setShowCopyModal(false);
              }}
              modalTitle={modalTitle}
              copyText={copyText}
              loading={loading}
              disabledSaveBtn={disabledSaveBtn}
              copySubText={copySubText}
              saveBtnText={saveBtnText}
              recurrences={copyRecurrences}
              selectedRecurrence={{
                ...recurrence,
                RecurrenceId: id.toString(),
              }}
              selectedRecurrenceCopyText={selectedRecurrenceCopyText}
              handleClose={() => !loading && setShowCopyModal(false)}
              handleDisableCycles={handleDisableCycles}
              customType={selectedCustomCycleType}
            />
          )}
      </>
    );
  };

  return (
    <div
      className="br-4 cycle-details-container-content"
      style={{ width: bufferDaysFeature ? "450px" : "368px" }}
    >
      {recurrences && bufferContainerTitle && bufferContainerTitle(recurrences)}
      <hr />
      <div className="recurrence-list-container">
        {bufferDaysFeature && bufferNote && (
          <div className="fs-12 mx-3 py-3 border-bottom-1 bc-grey4">
            {bufferNote}
          </div>
        )}
        {bufferDaysFeature && (
          <div className="px-4 pt-3 flex-between">
            <div className="fs-13 semi-bold">{t("COMMON.BUTTONS.CYCLES")}</div>
            <div
              className={classNames([
                "fs-13 semi-bold",
                recurrencePeriod === "Infinite" ? "mr-3" : "mr-50",
              ])}
            >
              {t("COMMON.BUTTONS.BUFFER_DAYS")}
            </div>
          </div>
        )}
        <RecurrenceList
          as="li"
          recurrences={recurrences}
          optionBadgeColor={getBadgeColor}
          customType={selectedCustomCycleType}
          recurrenceType={recurrencePeriod}
          optionLabelColor={getRecurrenceColor}
          formatOptionLabelAfter={bufferDaysFeature && formatOptionLabelAfter}
          customClassName={
            bufferDaysFeature &&
            recurrencePeriod !== "Infinite" &&
            "buffer-days"
          }
        />
      </div>
    </div>
  );
};

BufferDaysCycleSummary.propTypes = BufferDaysCycleSummaryPropTypes;

BufferDaysCycleSummary.defaultProps = BufferDaysCycleSummaryDefaultProps;

export default BufferDaysCycleSummary;
