import React from "react";

const SvgAvatar5 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="sh9x__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .sh9x__cls-1{fill:#fff}.sh9x__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="sh9x__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="sh9x__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.085)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="sh9x__cls-2"
        />
        <path
          d="M127.61 20.88a5.873 5.873 0 0 0-3.22-3.18c-.71-.29-1.46-.44-2.22-.44s-1.56.16-2.3.47a5.908 5.908 0 0 0-3.18 3.24l-7.15 18.3a5.71 5.71 0 0 0-.32 2.92l.39 2.5-1.67-2.75c-.24-.4-.37-.86-.38-1.32l-.33-19.63c0-1.25.78-2.38 1.89-2.74.34-.11.68-.17 1.02-.17.62 0 1.22.19 1.71.55.76.55 1.2 1.41 1.2 2.35a1.505 1.505 0 0 0 2.57 1.05c.28-.29.44-.66.43-1.06 0-1.88-.92-3.66-2.45-4.76a5.889 5.889 0 0 0-3.47-1.12c-.65 0-1.31.11-1.95.31-2.37.77-3.96 3.03-3.96 5.64l.33 19.63c.02 1 .3 1.97.81 2.82l2.8 4.62H52.27c-.65 0-1.3.19-1.88.54l-.46.28c-.38.24-.72.53-1 .88-.4.49-.73 1.05-1.01 1.71l-.57 1.97-1.77 7.26c-.21.86-.06 1.77.4 2.49.48.74 1.25 1.18 2.06 1.18h67.02c.66 0 1.27-.25 1.73-.71.75-.75 1.05-1.93.77-3.01l-2.14-8.26a7.22 7.22 0 0 0-.86-2.01l5.04-4.8a5.57 5.57 0 0 0 1.4-2.14l6.63-18.1a5.9 5.9 0 0 0-.03-4.52Zm-15.87 30.51c.53.46.93 1.13 1.13 1.89l2.13 8.2H48.19l1.98-8.14c.19-.77.59-1.45 1.13-1.93.53-.47 1.16-.72 1.81-.72h56.85c.64 0 1.26.24 1.78.69Zm6.68-14.01c.28-.78-.12-1.64-.91-1.92-.16-.06-.33-.09-.5-.09-.63 0-1.2.4-1.41.99l-2.99 8.14-.43-2.77c-.07-.46-.02-.93.15-1.37l7.14-18.28a2.896 2.896 0 0 1 2.69-1.82c.39 0 .74.07 1.1.22.72.29 1.28.85 1.59 1.56.31.73.31 1.51 0 2.28l-6.65 18.15c-.14.38-.36.72-.66 1l-2.09 2 2.97-8.09ZM59.46 80.04l-10.73-5.08a1.517 1.517 0 0 0-2 .72c-.35.75-.03 1.64.71 2l10.73 5.08a1.517 1.517 0 0 0 2-.72c.35-.75.03-1.64-.71-2ZM59.46 85.13l-10.73-5.08a1.517 1.517 0 0 0-2 .72c-.35.75-.03 1.64.71 2l10.73 5.08a1.517 1.517 0 0 0 2-.72c.35-.75.03-1.64-.71-2ZM115.39 74.82c-.22 0-.44.05-.64.14l-10.73 5.08c-.75.35-1.07 1.25-.71 2a1.517 1.517 0 0 0 2 .72l10.73-5.08c.75-.35 1.07-1.25.71-2-.25-.52-.78-.86-1.36-.86ZM115.39 79.9c-.22 0-.44.05-.64.14l-10.73 5.08c-.75.35-1.07 1.25-.71 2a1.517 1.517 0 0 0 2 .72l10.73-5.08c.75-.35 1.07-1.25.71-2-.25-.52-.78-.86-1.36-.86Z"
          className="sh9x__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar5.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar5;
