import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgDownloadExcel = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      xmlSpace="preserve"
      width={width}
      height={height}
      style={{
        enableBackground: "new 0 0 16 16",
      }}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.8 10.41v3.1M14.05 12.25l-1.25 1.26-1.26-1.26"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <circle
        cx={12.8}
        cy={11.96}
        r={2.98}
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M8.91.25v4.76h4.7"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M11.1 15.64H.93V.25H8.91l4.7 4.7V8"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M8.6 12.18H3.76V7.4h7.03v.78M6.12 7.4v4.78M3.76 9.86H8.6"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  );
};
SvgDownloadExcel.defaultProps = {
  color: COLORS.GREY_2,
  height: 24,
  width: 24,
};
export default SvgDownloadExcel;
