import React from "react";
import { Image } from "src/components";
import AssignedToMeEmptyStateImg from "./AssignedToMeEmptyState.svg";

const AssignedToMeEmptyState = (props) => (
  <Image
    src={AssignedToMeEmptyStateImg}
    alt="AssignedToMeEmptyStateImg"
    {...props}
  />
);

export default AssignedToMeEmptyState;
