import { gql, useQuery } from "src/api/client";

const GET_PAYOUT_USERS_QUERY = gql`
  query payoutUsers(
    $limit: Float
    $query: PayoutUsersQuery
    $sortBy: SortBy
    $after: String
    $FeatureType: String!
  ) {
    payoutUsers(
      limit: $limit
      query: $query
      sortBy: $sortBy
      after: $after
      FeatureType: $FeatureType
    ) {
      ... on PayoutUsersResponse {
        message
        success
        response {
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
          totalPendingRequests
          payoutUsers {
            userId
            fullName
            emailAddress
            pendingRequests
            pendingPayout
            PendingPayoutRewards {
              RewardCount
              RewardCategory
            }
          }
        }
      }
    }
  }
`;

export const useGetPayoutUsers = (queryOptions) => {
  return useQuery(GET_PAYOUT_USERS_QUERY, queryOptions);
};
