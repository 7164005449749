import React from "react";
import { Image } from "src/components";
import UnextLearningPrivateLimitedPng from "./UnextLearningPrivateLimited.png";

const UnextLearningPrivateLimited = (props) => (
  <Image
    src={UnextLearningPrivateLimitedPng}
    alt="UnextLearningPrivateLimited"
    {...props}
  />
);
export default UnextLearningPrivateLimited;
