/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import { useEffect, useState } from "react";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgArrow, SvgCross } from "src/icons";
import { FormButton } from "../../formElements";
import { Menu, useMenu } from "../menu";
import { Truncate } from "../truncate";
import "./styles.scss";

/**
 * Goal is to remove react-select.
 * TODO:
 * Improve the API for better DX.
 * Abstract out the logic to useAutocomplete hook.
 * Add support for groupBy.
 */

const AutocompleteInput = ({
  placeholder,
  search,
  onChange,
  clearSearch,
  clicked,
  disableCloseIcon,
  handleClose,
  searchable,
}) => {
  const handleExpandClick = (event) => {
    if (clicked) {
      event.stopPropagation();
      handleClose();
    }
  };
  return (
    <div
      className={classNames([
        clicked && "active",
        "h-100 center input-container fc-black",
      ])}
      // onClick={onClick}
    >
      <input
        className="input fs-13"
        type="text"
        value={search}
        onChange={onChange}
        placeholder={placeholder}
        autoFocus={searchable}
        disabled={!searchable}
      />
      <div className="ml-auto center">
        {(!disableCloseIcon || !search) && (
          <SvgCross onClick={clearSearch} className="mr-1" />
        )}
        <SvgArrow
          color={COLORS.GREY_1}
          className="expand"
          onClick={handleExpandClick}
        />
      </div>
    </div>
  );
};

const AutocompleteColumns = ({ columns }) => {
  return (
    <div className="bg-grey px-3 py-2 columns flex">
      {columns.map((column) => {
        return (
          <div className="fs-12 fc-grey" style={column.style}>
            {column.label}
          </div>
        );
      })}
    </div>
  );
};

const AutocompleteOption = ({ children, option, onClick }) => {
  const handleClick = () => {
    onClick(option);
  };
  return (
    <button
      className="fs-12 w-100 autocomplete-option flex"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export const Autocomplete = ({
  placeholder,
  value,
  options = [],
  loadingOptions,
  columns,
  getOptionLabel,
  renderOption,
  renderInput,
  renderInputPlaceholder,
  handleSearch,
  handleChange,
  searchKey,
  className,
  isOptionEqualToValue,
  disableCloseIcon = false,
  initialSearch = false,
  searchable = true,
}) => {
  const [search, setSearch] = useState(value[searchKey]);
  const [searching, setSearching] = useState(initialSearch);
  const [newOptions, setNewOptions] = useState(options);
  const menu = useMenu();
  const clicked = Boolean(menu.el);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setSearching(searchable);
  };

  const clearSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSearch("");
    setNewOptions([]);
  };

  useEffect(() => {
    if (searching)
      if (handleSearch) {
        handleSearch(search);
      } else {
        const regex = new RegExp(search, "gmi");
        const filteredOptions = options.filter((option) => {
          const newSearch = `${option[searchKey] || option}`;
          return newSearch.match(regex);
        });
        setNewOptions(search ? filteredOptions : options);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searching, search]);

  useEffect(() => {
    if (!clicked) {
      setNewOptions(options);
      setSearch(value[searchKey]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked]);

  useEffect(() => {
    setNewOptions(options);
  }, [options?.length]);

  useEffect(() => {
    setSearch(value[searchKey]);
    setSearching(false);
  }, [value]);

  const handleSelectOption = (option) => {
    handleChange(option);
    menu.handleClose();
  };

  return (
    <div
      className={classNames(["auto-complete", className])}
      ref={menu.containerRef}
    >
      <FormButton
        className="h-100 text-start normal w-100"
        onClick={menu.handleClick}
        variant="link"
      >
        {renderInputPlaceholder && clicked ? (
          <AutocompleteInput
            placeholder={placeholder}
            search={search}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            clicked={Boolean(menu.el)}
            disableCloseIcon={disableCloseIcon}
            handleClose={menu.handleClose}
            searchable={searchable}
          />
        ) : (
          <span className="fc-black">
            {renderInputPlaceholder && renderInputPlaceholder()}
          </span>
        )}
        {!renderInputPlaceholder ? (
          <AutocompleteInput
            placeholder={placeholder}
            search={search}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            clicked={Boolean(menu.el)}
            disableCloseIcon={disableCloseIcon}
            handleClose={menu.handleClose}
            searchable={searchable}
          />
        ) : null}
      </FormButton>

      <Menu el={menu.el} container={menu.containerRef.current}>
        <AutocompleteColumns columns={columns} />
        <div className="options flex-column-start">
          {!loadingOptions && newOptions.length === 0 ? (
            <div className="flex-column-center fs-12 fc-grey no-result">
              {t("MANAGED_GOALS.BUTTONS.NO_MATCHES_FOUND")}
            </div>
          ) : null}
          {loadingOptions && newOptions.length === 0 ? (
            <div className="flex-column-center fs-12 fc-grey no-result">
              {t("COMMON.MESSAGES.LOADING")}
            </div>
          ) : null}
          {newOptions.map((option) => {
            const isSelectedOption = isOptionEqualToValue(option);
            if (renderOption) return renderOption(option);
            return (
              <AutocompleteOption
                key={option.label}
                option={option}
                onClick={handleSelectOption}
              >
                {!columns.length ? <span>{option.label}</span> : null}
                {columns.length
                  ? columns.map((column) => {
                      return (
                        <div
                          className={classNames([
                            column.key?.toLowerCase(),
                            column.key === searchKey ? "searchKey" : "",
                            isSelectedOption && column.key === searchKey
                              ? "active"
                              : "",
                          ])}
                          style={column.style}
                        >
                          {column.template ? (
                            column.template({ value: option[column.key] })
                          ) : (
                            <Truncate
                              text={option[column.key]}
                              maxWidth={column.style.minWidth}
                            />
                          )}
                        </div>
                      );
                    })
                  : null}
              </AutocompleteOption>
            );
          })}
        </div>
      </Menu>
    </div>
  );
};
