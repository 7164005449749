import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ConfirmationModal } from "src/components/common/atoms";
import { FormBadge, FormButton } from "src/components/common/formElements";
import { MAX_CYCLE_DAYS } from "src/constants";
import {
  addDays,
  getPluralText,
  isSameDay,
  startOfMonth,
  subDays,
} from "src/helpers";
import { getCycleInterval } from "src/helpers/getCycleInterval";
import { SvgLink } from "src/icons";
import { NonUniformCycleItem } from "./nonUniformCycleItem";
import "./nonUniformDuration.scss";

const NonUniformDuration = (props) => {
  const { today, totalCycles, cycles, handleSave, isDraft, moduleName } = props;
  const [recurrences, setRecurrences] = useState(cycles.map((a) => ({ ...a })));
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState({
    index: null,
    show: false,
  });
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [globalMode, setGlobalMode] = useState(false);

  const isChanged = () => {
    let isChanged = false;
    if (recurrences.length === cycles.length) {
      recurrences.forEach(
        ({ recurrenceStartDate, recurrenceEndDate }, index) => {
          if (
            !isSameDay(
              recurrenceStartDate,
              cycles[index].recurrenceStartDate
            ) ||
            !isSameDay(recurrenceEndDate, cycles[index].recurrenceEndDate)
          ) {
            isChanged = true;
            setShowUnsavedModal(true);
          }
        }
      );
    } else {
      isChanged = true;
      setShowUnsavedModal(true);
    }
    if (!isChanged) {
      setRecurrences(cycles.map((a) => ({ ...a })));
      setShowModal(false);
      setGlobalMode(false);
    }
  };

  const handleDelete = (index) => {
    const newRecurrences = recurrences.map((a) => ({ ...a }));
    if (newRecurrences[index + 1]) {
      if (index === 0) newRecurrences[index + 1].minDate = startOfMonth(today);
      else newRecurrences[index + 1].minDate = newRecurrences[index].minDate;
    }
    if (index !== 0 && newRecurrences[index - 1]) {
      newRecurrences[index - 1].maxDate = newRecurrences[index].maxDate;
      newRecurrences[index - 1].add = true;
    }
    if (
      isSameDay(
        addDays(recurrences[0].recurrenceStartDate, MAX_CYCLE_DAYS - 1),
        newRecurrences[recurrences.length - 1].recurrenceEndDate
      )
    ) {
      recurrences[0].minDate = startOfMonth(today);
    }
    newRecurrences.splice(index, 1);
    newRecurrences[newRecurrences.length - 1].maxDate = addDays(
      newRecurrences[0].recurrenceStartDate,
      MAX_CYCLE_DAYS - 1
    );
    if (
      !isSameDay(
        newRecurrences[newRecurrences.length - 1].recurrenceStartDate,
        newRecurrences[newRecurrences.length - 1].maxDate
      )
    ) {
      newRecurrences[newRecurrences.length - 1].add = true;
    }
    setRecurrences(newRecurrences);
    setGlobalMode(false);
  };

  const handleAdd = (index) => {
    const newRecurrences = recurrences.map((a) => ({ ...a }));
    newRecurrences.splice(index + 1, 0, {
      recurrenceStartDate: null,
      recurrenceEndDate: null,
      editMode: true,
      add: true,
      minDate: addDays(newRecurrences[index].recurrenceEndDate, 1),
      maxDate: newRecurrences[index + 1]
        ? subDays(newRecurrences[index + 1].recurrenceStartDate, 1)
        : addDays(newRecurrences[0].recurrenceStartDate, MAX_CYCLE_DAYS - 1),
    });
    setRecurrences(newRecurrences);
    setGlobalMode(true);
  };

  const handleDone = ({ index, startDate, endDate }) => {
    const newRecurrences = recurrences.map((a) => ({ ...a }));
    newRecurrences[index].recurrenceStartDate = startDate;
    newRecurrences[index].recurrenceEndDate = endDate;
    newRecurrences[index].editMode = false;
    if (newRecurrences[index - 1]) {
      newRecurrences[index - 1].maxDate = subDays(startDate, 1);
      if (
        isSameDay(
          newRecurrences[index - 1].recurrenceEndDate,
          subDays(startDate, 1)
        )
      ) {
        newRecurrences[index - 1].add = false;
      } else newRecurrences[index - 1].add = true;
    }
    if (newRecurrences[index + 1]) {
      newRecurrences[index + 1].minDate = addDays(endDate, 1);
      if (
        isSameDay(
          newRecurrences[index + 1].recurrenceStartDate,
          addDays(endDate, 1)
        )
      ) {
        newRecurrences[index].add = false;
      } else newRecurrences[index].add = true;
    }
    if (
      isSameDay(
        addDays(recurrences[0].recurrenceStartDate, MAX_CYCLE_DAYS - 1),
        endDate
      )
    ) {
      recurrences[0].minDate = recurrences[0].recurrenceStartDate;
      newRecurrences[index].add = false;
    }
    if (isSameDay(endDate, newRecurrences[index].maxDate)) {
      newRecurrences[index].add = false;
    } else {
      newRecurrences[index].add = true;
    }

    if (index === 0) {
      newRecurrences[newRecurrences.length - 1].maxDate = addDays(
        newRecurrences[0].recurrenceStartDate,
        MAX_CYCLE_DAYS - 1
      );
      if (
        !isSameDay(
          newRecurrences[newRecurrences.length - 1].recurrenceStartDate,
          newRecurrences[newRecurrences.length - 1].maxDate
        )
      ) {
        newRecurrences[newRecurrences.length - 1].add = true;
      }
    }
    if (index === newRecurrences.length - 1 && index !== 0) {
      newRecurrences[0].minDate = subDays(
        newRecurrences[newRecurrences.length - 1]?.recurrenceEndDate,
        MAX_CYCLE_DAYS - 1
      );
    }

    setRecurrences(newRecurrences);
    setGlobalMode(false);
  };

  const handleEdit = (index) => {
    const newRecurrences = recurrences.map((a) => ({ ...a }));
    newRecurrences[index].editMode = true;
    if (index === 0) {
      newRecurrences[recurrences.length - 1].maxDate = null;
    }
    setRecurrences(newRecurrences);
    setGlobalMode(true);
  };

  const RemoveModalBody = () => (
    <>
      <p className="fs-14 semi-bold">
        {t("MANAGED_GOALS.LABELS.REMOVE_CYCLE_CONFIRM")}{" "}
        <span className="fc-grey1">
          #{showRemoveModal?.index + 1}:{" "}
          {getCycleInterval(
            recurrences[showRemoveModal?.index]?.recurrenceStartDate,
            recurrences[showRemoveModal?.index]?.recurrenceEndDate
          )}{" "}
        </span>
        ?
      </p>
      <p className="fs-13">{t("RULEBOOK.MESSAGES.ACTION_CANNOT_BE_DONE")}</p>
    </>
  );
  const UnsavedModalBody = () => (
    <>
      <p className="fs-14 semi-bold">
        {t("MANAGED_GOALS.LABELS.CLOSE_THE_CONFIGURATION")}
      </p>
      <p className="fs-13">{t("MANAGED_GOALS.LABELS.DATA_LOST")}</p>
    </>
  );

  const isBeforeCycle = (index) => {
    if (!isDraft && index < cycles.length - 1) return true;
    return null;
  };

  const isLastCycle = (index) => {
    if (!isDraft && index === cycles.length - 1) return true;
    return null;
  };

  return (
    <>
      <div className="mb-4">
        <h2 className="fs-14 semi-bold">
          {t("MANAGED_GOALS.LABELS.CYCLE_DATE_RANGE_TITLE")}
        </h2>
        <div className="center">
          <p className="fs-13 fc-grey1 w-336 pr-3 border-right-1 bc-grey10">
            {t("MANAGED_GOALS.LABELS.CONFIGURE_CYCLE", {
              moduleName: moduleName.toLowerCase(),
            })}
          </p>
          <div className="ml-3">
            <div className="fs-12">
              {t("MANAGED_GOALS.LABELS.CYCLES_CREATED")}
            </div>
            <div className="fs-14 semi-bold">{totalCycles}</div>
          </div>
          <div className="ml-auto">
            <FormButton
              variant="outline-primary"
              icon={<SvgLink />}
              reverse
              onClick={() => setShowModal(true)}
            >
              <span className="mr-2 fs-14 semi-bold fc-primary-blue">
                {totalCycles > 0
                  ? t("COMMON.BUTTONS.EDIT")
                  : t("MANAGED_GOALS.LABELS.CONFIGURE")}{" "}
                {t("COMMON.BUTTONS.CYCLES")}
              </span>
            </FormButton>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => isChanged()}
          scrollable
          centered
          dialogClassName="non-uniform-duration-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="center">
              <h1 className="fs-18 semi-bold">
                {t("MANAGED_GOALS.LABELS.CYCLE_CONFIGURATION")}
              </h1>
              {totalCycles > 0 && (
                <FormBadge
                  className="bg-grey5 fc-grey1 semi-bold ml-3"
                  id="Secondary-badge"
                >
                  {getPluralText(
                    totalCycles,
                    t("MANAGED_GOALS.LABELS.CYCLE"),
                    t("MANAGED_GOALS.MESSAGES.CYCLES")
                  )}
                </FormBadge>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="non-uniform-duration-modal-body p-4 space-y-16">
            {recurrences.map(
              (
                {
                  recurrenceStartDate,
                  recurrenceEndDate,
                  editMode,
                  add,
                  minDate,
                  maxDate,
                },
                index
              ) => (
                <NonUniformCycleItem
                  isDraft={isDraft}
                  recurrenceStartDate={recurrenceStartDate}
                  recurrenceEndDate={recurrenceEndDate}
                  programEndDate={
                    totalCycles > 0 &&
                    cycles[cycles.length - 1].recurrenceEndDate
                  }
                  editMode={editMode}
                  minDate={minDate}
                  maxDate={maxDate}
                  index={index + 1}
                  disableAdd={!add}
                  maxAdd={isSameDay(
                    addDays(
                      recurrences[0].recurrenceStartDate,
                      MAX_CYCLE_DAYS - 1
                    ),
                    recurrenceEndDate
                  )}
                  disabledMinDelete={recurrences.length === 1}
                  handleAdd={handleAdd}
                  handleDone={handleDone}
                  handleDelete={(index) =>
                    setShowRemoveModal({
                      index,
                      show: true,
                    })
                  }
                  handleEditDelete={handleDelete}
                  handleEdit={handleEdit}
                  globalEditMode={globalMode}
                  isBeforeCycle={isBeforeCycle(index)}
                  isLastCycle={isLastCycle(index)}
                  moduleName={moduleName}
                />
              )
            )}
          </Modal.Body>
          <Modal.Footer>
            <FormButton
              disabled={
                globalMode ||
                (!recurrences[0]?.recurrenceStartDate &&
                  !recurrences[0]?.recurrenceEndDate)
              }
              onClick={() => {
                handleSave(recurrences);
                setShowModal(false);
              }}
            >
              {t("COMMON.BUTTONS.DONE")}
            </FormButton>
          </Modal.Footer>
        </Modal>
      )}
      {showRemoveModal?.show && (
        <ConfirmationModal
          show={showRemoveModal?.show}
          onClose={() => setShowRemoveModal({ index: null, show: false })}
          dialogClassName="confirm-remove-modal"
          title={t("MANAGED_GOALS.LABELS.REMOVE_CYCLE")}
          body={<RemoveModalBody />}
          onConfirm={() => {
            handleDelete(showRemoveModal.index);
            setShowRemoveModal({ index: null, show: false });
          }}
          confirmButtonText={t("MANAGED_GOALS.LABELS.YES_REMOVE_CYCLE")}
          cancelButton
        />
      )}
      {showUnsavedModal && (
        <ConfirmationModal
          show={showUnsavedModal}
          onClose={() => setShowUnsavedModal(false)}
          dialogClassName="confirm-remove-modal"
          title={t("MANAGED_GOALS.LABELS.CLOSE_CONFIGURATION")}
          body={<UnsavedModalBody />}
          onConfirm={() => {
            setRecurrences(cycles.map((a) => ({ ...a })));
            setShowUnsavedModal(false);
            setShowModal(false);
            setGlobalMode(false);
          }}
          confirmButtonText={t("MANAGED_GOALS.LABELS.DISCARD_CHANGES_CLOSE")}
          cancelButton
        />
      )}
    </>
  );
};

NonUniformDuration.propTypes = {
  recurrences: PropTypes.arrayOf(
    PropTypes.shape({
      recurrenceStartDate: PropTypes.instanceOf(Date),
      recurrenceEndDate: PropTypes.instanceOf(Date),
      editMode: PropTypes.bool.isRequired,
      add: PropTypes.bool.isRequired,
    })
  ).isRequired,
  setRecurrences: PropTypes.func.isRequired,
  today: PropTypes.instanceOf(Date),
};

NonUniformDuration.defaultProps = {
  recurrences: [],
  setRecurrences: () => {},
  today: new Date(),
};

export default NonUniformDuration;
