import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgDraft = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M14.169 6.364 7.45 13.08v.01l-2.95-.002.002.043-1.999.004V8.134L10.636.002l4.95 4.95Zm-1.415-1.415-2.12-2.121L4.5 8.96v2.121h2.121Z"
        data-name="Union 55"
      />
    </svg>
  );
};

SvgDraft.defaultProps = {
  color: COLORS.GREY_2,
  height: 16,
  width: 16,
};
export default SvgDraft;
