import PropTypes from "prop-types";
import React from "react";
import { classNames } from "src/helpers";
import "./slabIndex.scss";

const slabIndexPropTypes = {
  value: PropTypes.number.isRequired,
};

export const SlabIndex = ({ value, className }) => {
  return (
    <div
      className={classNames([
        "slab-index fs-13 fc-grey semi-bold flex-center",
        className,
      ])}
    >
      {value}
    </div>
  );
};

SlabIndex.propTypes = slabIndexPropTypes;
