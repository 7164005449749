import React from "react";

const SvgExportExcel = (props) => {
  const { height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        stroke="#788AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.088}
        d="M12.298 10.366v2.811M13.431 12.035l-1.133 1.142-1.143-1.142M8.77.6v4.316h4.262"
      />
      <path
        stroke="#788AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.088}
        d="M12.298 14.474a2.702 2.702 0 1 0 0-5.405 2.702 2.702 0 0 0 0 5.405Z"
      />
      <path
        stroke="#788AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.088}
        d="M10.65 15.109H1V.6h7.572l4.461 4.43v2.876"
      />
      <path
        stroke="#788AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.088}
        d="M8.148 11.79H3.76V7.457h6.375v.708M5.9 7.456v4.335M3.76 9.687h4.388"
      />
    </svg>
  );
};
SvgExportExcel.defaultProps = {
  height: 16,
  width: 16,
};
export default SvgExportExcel;
