import { gql, useMutation } from "src/api/client";

const SAVE_USER_PREFERENCES_MUTATION = gql`
  mutation saveUserPreferences(
    $templateConfig: JSON
    $goalsConfig: JSON
    $incentivesConfig: JSON
    $leaderboardConfig: JSON
  ) {
    saveUserPreferences(
      templateConfig: $templateConfig
      goalsConfig: $goalsConfig
      incentivesConfig: $incentivesConfig
      leaderboardConfig: $leaderboardConfig
    ) {
      ... on UserPreferencesResponse {
        success
        message
        response {
          templateConfig
          goalsConfig
          incentivesConfig
          leaderboardConfig
          __typename
        }
      }
    }
  }
`;

export const useSaveUserPreferences = (queryOptions) => {
  return useMutation(SAVE_USER_PREFERENCES_MUTATION, queryOptions);
};
