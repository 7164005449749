export const RULES_REDUCER_ACTIONS = {
  EDIT_WINNERS: "editWinners",
  SET_RANKS: "setRanks",
  EDIT_TIE_BREAKERS: "editTieBreaker",
  DELETE_TIE_BREAKERS: "deleteTieBreaker",
  EDIT_GROUP_TARGETS: "editGroupTargets",
  EDIT_LEADERBOARD_TARGET: "editLeaderboardTarget",
  DELETE_GROUP_TARGETS: "deleteGroupTargets",
  DELETE_LEADERBOARD_TARGET: "deleteLeaderboardTarget",
  CREATE_RANK: "createRank",
  SET_SELECTED_RANK_INDEX: "setSelectedRankIndex",
  GET_RANK: "getRank",
  GET_ALL_RANKS: "getAllRanks",
  EDIT_RANK: "editRank",
  DELETE_RANK: "deleteRank",
  EDIT_RECURRENCE: "editRecurrence",
  EDIT_MANDATORY_TARGET: "editMandatoryTarget",
  EDIT_MIN_TARGET: "editMinTarget",
};

export const ruleReducer = (state, action) => {
  switch (action.type) {
    case RULES_REDUCER_ACTIONS.EDIT_WINNERS: {
      return {
        ...state,
        winners: action.payload,
        isEdited: true,
      };
    }
    case RULES_REDUCER_ACTIONS.EDIT_MANDATORY_TARGET: {
      return {
        ...state,
        mandatoryTarget: action.payload,
        isEdited: true,
      };
    }
    case RULES_REDUCER_ACTIONS.EDIT_MIN_TARGET: {
      return {
        ...state,
        minTarget: action.payload,
        isEdited: true,
      };
    }
    case RULES_REDUCER_ACTIONS.EDIT_TIE_BREAKERS: {
      return {
        ...state,
        tieBreakers: action.payload,
      };
    }
    case RULES_REDUCER_ACTIONS.DELETE_TIE_BREAKERS: {
      return {
        ...state,
        tieBreakers: [],
      };
    }
    case RULES_REDUCER_ACTIONS.EDIT_GROUP_TARGETS: {
      return {
        ...state,
        groupTargets: action.payload,
      };
    }
    case RULES_REDUCER_ACTIONS.EDIT_LEADERBOARD_TARGET: {
      return {
        ...state,
        leaderboardTarget: action.payload,
      };
    }
    case RULES_REDUCER_ACTIONS.DELETE_GROUP_TARGETS: {
      return {
        ...state,
        groupTargets: [],
      };
    }
    case RULES_REDUCER_ACTIONS.DELETE_LEADERBOARD_TARGET: {
      return {
        ...state,
        leaderboardTarget: null,
      };
    }
    case RULES_REDUCER_ACTIONS.CREATE_RANK: {
      return {
        ...state,
        ranks: [...state.ranks, action.payload],
      };
    }
    case RULES_REDUCER_ACTIONS.SET_RANKS: {
      return {
        ...state,
        ranks: action.payload,
      };
    }
    case RULES_REDUCER_ACTIONS.SET_SELECTED_RANK_INDEX: {
      return {
        ...state,
        selectedRankIndex: action.payload,
      };
    }
    case RULES_REDUCER_ACTIONS.GET_RANK: {
      return {
        ...state,
      };
    }
    case RULES_REDUCER_ACTIONS.GET_ALL_RANKS: {
      return {
        ...state,
      };
    }
    case RULES_REDUCER_ACTIONS.EDIT_RANK: {
      const newRanks = [...state.ranks];
      newRanks[action.payload.index] = action.payload.rankInfo;
      return {
        ...state,
        ranks: newRanks,
      };
    }
    case RULES_REDUCER_ACTIONS.DELETE_RANK: {
      const newRanks = [...state.ranks];
      newRanks.splice(action.payload, 1);
      return {
        ...state,
        ranks: newRanks,
      };
    }
    case RULES_REDUCER_ACTIONS.EDIT_RECURRENCE: {
      return {
        ...state,
        selectedRecurrence: action.payload,
        groupTargets: [],
        leaderboardTarget: null,
      };
    }
    default:
      return state;
  }
};
