import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgSearch = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l634__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l634__a)" data-name="Search Icon">
        <path
          fill={color}
          d="m12.971 14.593-2.547-2.547a6.088 6.088 0 1 1 1.9-2.072l2.632 2.632a1.442 1.442 0 0 1 .049 2.038 1.365 1.365 0 0 1-.971.4 1.5 1.5 0 0 1-1.063-.451ZM2.79 7.007a4.216 4.216 0 0 0 7.151 3.027 1.351 1.351 0 0 1 .2-.256 1.325 1.325 0 0 1 .153-.133 4.216 4.216 0 1 0-7.5-2.638Z"
          data-name="Union 32"
        />
        <path fill="none" d="M.5.5h15v15H.5z" data-name="Rectangle 2099" />
      </g>
    </svg>
  );
};

SvgSearch.defaultProps = {
  color: COLORS.GREY_3,
  height: 16,
  width: 16,
};
export default SvgSearch;
