import { gql, useQuery } from "src/api/client";
import {
  CORE_TEMPLATE_DETAILS_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
} from "src/api/fragments";

// src/modules/landingPage/template/Template.js
export const GET_ALL_TEMPLATES_QUERY = gql`
  ${CORE_TEMPLATE_DETAILS_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query getTemplates(
    $Limit: Float
    $Query: JSON
    $After: String
    $Sort: TemplateSortOptions
  ) {
    getTemplates(Limit: $Limit, Query: $Query, After: $After, Sort: $Sort) {
      __typename
      ... on GetAllTemplateResponse {
        response {
          totalCount
          hasTemplates
          Templates {
            ...coreTemplateDetailsFragment
            GoalsCount
            updatedAt
            AuditType
            GoalRules {
              RuleId
            }
            GroupBy
          }
          pageInfo {
            endCursor
          }
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetAllTemplates = (queryOptions) => {
  return useQuery(GET_ALL_TEMPLATES_QUERY, queryOptions);
};
