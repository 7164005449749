import PropTypes from "prop-types";
import React from "react";
import { FormButton, FormCheck } from "src/components/common/formElements";
import { SvgTick } from "src/icons";
import "./highlightCard.scss";

const HighlightCard = (props) => {
  const {
    index,
    disabled,
    cardTitle,
    cardDescription,
    isSelected,
    setSelected,
    handleClick,
    selectionType,
  } = props;

  const showCardTitle = () => {
    if (!cardTitle) {
      return null;
    }

    return (
      <h3
        className={`fs-14 semi-bold ${
          isSelected ? "fc-primary-blue " : "fc-black"
        }`}
      >
        {cardTitle}
      </h3>
    );
  };

  return (
    <FormButton
      className={`${
        disabled ? "cursor-not-allowed" : "pointer"
      } position-relative br-4 border-2 p-3 h-100 normal text-start ${
        isSelected
          ? "bc-primary-blue bg-secondary-blue"
          : "bc-transparent bg-grey6"
      }`}
      onClick={!disabled ? () => handleClick() : undefined}
      variant="link"
    >
      {isSelected && selectionType === "checkbox" && (
        <div className="highlight_card_check">
          <SvgTick />
        </div>
      )}
      {selectionType === "radio" ? (
        <div className="d-flex mb-2">
          <div>
            <FormCheck
              id={`highlight_card_radio_${index}`}
              type={selectionType}
              checked={isSelected}
              onClick={() => setSelected(index)}
            />
          </div>
          <div>{showCardTitle()}</div>
        </div>
      ) : (
        showCardTitle()
      )}
      {cardDescription && (
        <p className={`fs-12 ${isSelected ? "fc-black" : "fc-grey1"}`}>
          {cardDescription}
        </p>
      )}
    </FormButton>
  );
};

HighlightCard.propTypes = {
  cardTitle: PropTypes.string,
  cardDescription: PropTypes.string,
  isSelected: PropTypes.bool,
  selectionType: PropTypes.string,
};

HighlightCard.defaultProps = {
  selectionType: "checkbox",
};

export default HighlightCard;
