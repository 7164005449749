import { useEffect, useState } from "react";
import { useGetToken, useRefreshToken } from "src/api/useOpenAceAuth";
import { APPS } from "src/constants";
import { verifyToken } from "src/helpers";
import { useHistory } from "src/router";
import useIsLoggedIn from "./useIsLoggedIn";

const useLogin = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const refresh = useRefreshToken();
  const getToken = useGetToken();
  const history = useHistory();
  const { isLoggedIn, setIsLoggedIn } = useIsLoggedIn();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get("state") ?? "";
    const code = queryParams.get("code") ?? "";
    const loginState = localStorage.getItem("login_state");

    const verifyGetToken = async () => {
      try {
        await getToken();
        localStorage.removeItem("login_state");
        localStorage.removeItem("code_verifier");
        history.push(APPS.ACE.ROUTES.ROOT.path);
        setIsPageLoading(false);
      } catch (err) {
        console.error("Error Getting Token", err);
        history.push(APPS.ACE.ROUTES.ERROR.path);
        localStorage.removeItem("login_state");
        localStorage.removeItem("code_verifier");
        setIsPageLoading(false);
      }
    };

    // Avoids unwanted call to verifyGetToken
    if (state && code && loginState && state === loginState) {
      verifyGetToken();
    } else if (state && code) {
      history.push(APPS.ACE.ROUTES.ROOT.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get("state") ?? "";
    const logoutState = localStorage.getItem("logout_state");

    // Avoids unwanted call to verifyLogout
    if (state && logoutState && state === logoutState) {
      localStorage.removeItem("logout_state");
      history.push(APPS.ACE.ROUTES.ROOT.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const access_token = localStorage.getItem("access_token") || "";
    const refresh_token = localStorage.getItem("refresh_token") || "";
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get("state") ?? "";

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error("Error refreshing token", err);
        history.push(APPS.ACE.ROUTES.ERROR.path);
      } finally {
        setIsPageLoading(false);
      }
    };

    // Avoids unwanted call to verifyRefreshToken
    if (
      refresh_token &&
      (!access_token || (access_token && !verifyToken(access_token)))
    ) {
      verifyRefreshToken();
    } else {
      setIsLoggedIn(!!access_token && verifyToken(access_token));
      if (!state) setIsPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isPageLoading,
    isLoggedIn,
  };
};

export default useLogin;
