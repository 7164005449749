import React from "react";
import { useUserQuery } from "src/api/userQuery";
import { TenantStage } from "src/components";
import { TENANT_STAGE_STATUS_TYPES } from "src/constants";
import { useAuth } from "src/hooks";
import AppRouterWrapper from "./AppRouterWrapper";

export const AuthGate = ({
  pendoURLData,
  isOpenAceUser,
  isLoggedIn,
  children,
}) => {
  const { loading, error, data } = useUserQuery({
    skip: isOpenAceUser ? !isLoggedIn : false,
  });
  const { auth } = useAuth();

  const userAttributes = auth.userAttributesForContext
    ? JSON.parse(auth.userAttributesForContext)
    : {};

  if (loading) return null;
  if (error) return null;
  if (data) {
    const isAnyFeatureEnabled = Object.values(data?.me?.isFeatureEnabled).some(
      (value) => value
    );
    if (
      data?.me?.status === TENANT_STAGE_STATUS_TYPES.ENABLED &&
      isAnyFeatureEnabled
    ) {
      return (
        <AppRouterWrapper
          pendoURLData={pendoURLData}
          maxAllowedGoalRules={data?.me?.maxAllowedGoalRules}
          isFeatureEnabled={data?.me?.isFeatureEnabled}
          role={userAttributes?.role}
          orgCode={userAttributes?.OrgCode}
          userId={userAttributes?.UserId}
          orgDisplayName={userAttributes?.OrgDisplayName}
          orgUniqueName={userAttributes?.OrgUniqueName}
          {...{ isOpenAceUser, isLoggedIn }}
        >
          {children}
        </AppRouterWrapper>
      );
    }
    return (
      <TenantStage
        status={data?.me?.status}
        orgCode={userAttributes?.OrgCode}
        userId={userAttributes?.UserId}
        orgDisplayName={userAttributes?.OrgDisplayName}
        orgUniqueName={userAttributes?.OrgUniqueName}
        role={userAttributes?.role}
      />
    );
  }
  return null;
};
