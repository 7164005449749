import { arrayMove } from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Sortable } from "src/components/base";
import { SearchBar, Truncate } from "src/components/common/atoms";
import ColumnItems from "src/components/common/atoms/rearrangeColumns/ColumnItems";
import { FormButton, FormCheck } from "src/components/common/formElements";
import { isEmpty, xor } from "src/helpers";
import { useViewport } from "src/hooks";
import { NoGoals } from "src/images";
import "./configureColumn.scss";

const ConfigureColumnModal = ({
  onClose,
  usersAttributeList,
  fieldsOrder,
  setFieldsOrder,
  handleDoneButton,
  configureModal,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const { isMobile } = useViewport();
  const [initialFields, setInitialFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (!isEmpty(usersAttributeList)) {
      const selectedList = fieldsOrder.map((field) => {
        return {
          id: field,
          Header: usersAttributeList[field]?.DisplayName,
        };
      });
      setInitialFields(selectedList);
      setSelectedFields(selectedList);
      setColumns(
        Object.keys(usersAttributeList).map((field) => {
          return {
            id: field,
            Header: usersAttributeList[field]?.DisplayName,
            isVisible: usersAttributeList[field]?.isVisible,
          };
        })
      );
    }
  }, [fieldsOrder, usersAttributeList]);

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setColumns((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleDoneModal = () => {
    const selectedArr = selectedFields.map((field) => field.id);
    const initialArr = initialFields.map((field) => field.id);
    setFieldsOrder(
      columns.filter((field) => field.isVisible).map((field) => field.id)
    );
    if (
      xor(selectedArr, initialArr).some((field) => !initialArr.includes(field))
    ) {
      handleDoneButton(selectedArr);
    }
    onClose();
  };

  const selectColumnModal = (id, Header, visible) => {
    const isVisible = !visible;
    setColumns([
      ...columns.map((column) => {
        if (column.id === id) {
          return { ...column, isVisible };
        }
        return column;
      }),
    ]);
    if (isVisible) {
      setSelectedFields([...selectedFields, { id, Header }]);
    } else {
      setSelectedFields(selectedFields.filter((item) => item.id !== id));
    }
  };

  const ConfigureColumnItems = ({ id, Header, isVisible }) => {
    const isGreater = !isVisible && selectedFields.length >= 10;
    const isLess = isVisible && selectedFields.length < 2;

    return (
      <ColumnItems
        activationConstraint={{
          delay: 500,
          tolerance: 5,
        }}
        className={"center"}
        id={id}
        labelComponent={
          <FormCheck
            id={`${Header}`}
            disabled={isGreater || isLess}
            checked={isVisible}
            label={
              <div className="flex-between">
                <div
                  className={`fs-12 ml-2 ${
                    isGreater || isLess ? "fc-grey" : ""
                  }`}
                >
                  <Truncate
                    id={id}
                    maxWidth="200px"
                    truncType="end"
                    showTooltip={false}
                    text={Header}
                  />
                </div>
              </div>
            }
            onClick={() => selectColumnModal(id, Header, isVisible)}
          />
        }
      />
    );
  };

  const newFilteredColumns =
    columns &&
    columns.filter((column) =>
      column.Header.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <Modal show={configureModal} onHide={onClose} centered animation={false}>
      <Modal.Header closeButton>
        <Modal.Title className={`${isMobile ? "fs-16" : "fs-18"}`}>
          {t("MANAGED_GOALS.LABELS.CONFIGURE_COLUMNS")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal_body d-flex flex-column">
        <div className="px-3 pt-3">
          <SearchBar
            search={searchQuery}
            placeholder={`${t("MANAGED_GOALS.LABELS.SEARCH_FIELD")}...`}
            setSearch={setSearchQuery}
          />
        </div>
        <div className="configure-column-mobile d-flex flex-column w-100 p-3">
          <Sortable
            sortableItems={columns.map((item) => item.id)}
            handleDragEnd={handleDragEnd}
          >
            {newFilteredColumns.length > 0 ? (
              newFilteredColumns.map((field, idx) => (
                <ConfigureColumnItems key={idx} {...field} />
              ))
            ) : (
              <div className="flex-center flex-column h-100">
                <NoGoals />
                <div className="fs-13 fc-grey mt-3">No Fields</div>
              </div>
            )}
          </Sortable>
        </div>
        <div className="p-3">
          <div
            className={`fs-13 my-3 ${
              selectedFields.length >= 10 ? "fc-orange" : "fc-grey"
            }`}
          >
            {t("MANAGED_GOALS.LABELS.SELECTED")} {selectedFields.length}/10
          </div>
          <FormButton
            variant="outline-primary"
            id="select-column-done"
            onClick={handleDoneModal}
            label={t("COMMON.BUTTONS.DONE")}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfigureColumnModal;
