import React from "react";
import { useGetUserProfile, useLogout } from "src/api/useOpenAceAuth";
import { FormButton, Popover, Shimmer } from "src/components";
import { APPS } from "src/constants";
import { classNames, getFullName, getInitials } from "src/helpers";
import { useViewport } from "src/hooks";
import { AceLogoHorizontal } from "src/images";
import { useLocation } from "src/router";
import "./style.scss";

const UserAvatar = ({ userData }) => {
  return (
    <div className="bg-grey4 semi-bold user-avatar fs-16 center fc-purple justify-content-center">
      {getInitials({
        firstName: userData?.FirstName,
        lastName: userData?.LastName,
      })}
    </div>
  );
};

const LoadingSkeleton = () => (
  <div className="d-flex align-items-center px-4 w-20">
    <Shimmer width="40px" height="40px" className="rounded-circle" />
    <Shimmer width="100px" height="15px" className="my-2 ml-4" />
  </div>
);

const PopoverContent = ({ userData }) => {
  const logout = useLogout();

  return (
    <div className="p-3 fs-13">
      <div className="d-flex center  pb-3 border-bottom bc-b-grey3">
        <UserAvatar {...{ userData }} />
        <div className="d-flex flex-column ml-2">
          <div className="text-base">
            {getFullName(userData?.FirstName || "", userData?.LastName || "")}
          </div>
          <span className="fs-11 fc-grey1">{userData?.EmailAddress}</span>
        </div>
      </div>
      <div className=" border-b bc-b-grey3">
        <div className="d-flex flex-column my-3">
          {userData?.Designation && (
            <div>
              <span className="text-sm">Designation: </span>
              <span className="text-sm semi-bold">{userData?.Designation}</span>
            </div>
          )}
          {userData?.Role && (
            <div>
              <span className="text-sm">Role: </span>
              <span className="text-sm semi-bold">{userData?.Role}</span>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-column mt-3 ">
        <FormButton
          id="open-ace-logout-btn"
          className=" text-white"
          onClick={logout}
        >
          Logout
        </FormButton>
      </div>
    </div>
  );
};

const LoggedIn = () => {
  const { data, isLoading } = useGetUserProfile();
  const { isMobile } = useViewport();

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  const userData = data?.data;

  const Icon = () => (
    <div className="center">
      <UserAvatar {...{ userData }} />
      {!isMobile && (
        <span className="fc-black normal ml-2 fs-16">
          {getFullName(userData?.FirstName, userData?.LastName)}
        </span>
      )}
    </div>
  );

  return (
    <Popover
      id="login-popover"
      popoverBody={<PopoverContent userData={userData} />}
      width="300px"
      placement="bottom-end"
      closedStateIcon={<Icon />}
      openedStateIcon={<Icon />}
    />
  );
};

export const UserStatusIndicator = ({ isPageLoading, isLoggedIn }) => {
  const { pathname } = useLocation();
  if (isPageLoading || (isLoggedIn && pathname === APPS.ACE.ROUTES.ROOT.path)) {
    return <LoadingSkeleton />;
  }

  if (isLoggedIn) {
    return <LoggedIn />;
  }

  return null;
};

const Header = ({ isPageLoading, isLoggedIn }) => {
  const { isMobile } = useViewport();
  const { pathname } = useLocation();

  const isErrorPage = pathname === APPS.ACE.ROUTES.ERROR.path;

  return (
    <div
      className={classNames([
        "w-100 d-flex justify-content-between align-items-center bc-b-grey3 open-ace-header",
        isMobile ? "p-2" : "page-container pb-0 h-60",
        pathname !== APPS.ACE.ROUTES.ROOT.path ? "ace-header" : "",
      ])}
    >
      <AceLogoHorizontal width="120px" />
      {!isErrorPage && (
        <UserStatusIndicator {...{ isPageLoading, isLoggedIn }} />
      )}
    </div>
  );
};

export default Header;
