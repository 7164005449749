import { t } from "i18next";
import React, { useState } from "react";
import { ConfirmationModal, FormButton } from "src/components/common";
import { COLORS } from "src/constants";
import { classNames, getPluralText, isEmpty } from "src/helpers";
import { SvgDelete, SvgEdit, SvgPlus } from "src/icons";
import {
  FILTERS_MODEL,
  FILTER_DATA_TYPES,
  OPERATORS,
  SUB_OPERATORS,
} from "./data";

const getValue = (value) => {
  if (typeof value === "object") {
    const [startValue, endValue] = Object.values(value);
    return (
      <>
        <span>{startValue}</span>
        <span className="fc-grey1">and</span>
        <span>{endValue}</span>
      </>
    );
  }
  return <span>{value}</span>;
};

const SubOperatorValue = ({ condition, operator }) => {
  switch (condition?.DataType) {
    case FILTER_DATA_TYPES.STRING: {
      if (
        [OPERATORS.IS_EMPTY, OPERATORS.IS_NOT_EMPTY].includes(operator?.value)
      ) {
        return null;
      } else {
        return getValue(condition?.Value);
      }
    }
    case FILTER_DATA_TYPES.NUMBER: {
      return getValue(condition?.Value);
    }

    case FILTER_DATA_TYPES.BOOLEAN: {
      const operator = FILTERS_MODEL?.[condition?.DataType]?.[condition?.Value];
      return <span className="fc-grey">{operator?.label}</span>;
    }

    case FILTER_DATA_TYPES.DATE: {
      if (condition?.SubOperator && condition?.Value) {
        switch (condition?.SubOperator) {
          case SUB_OPERATORS.CUSTOM_DATE:
          case SUB_OPERATORS.CUSTOM_DATE_RANGE: {
            return getValue(condition?.Value);
          }

          case SUB_OPERATORS.N_DAYS: {
            return (
              <span>{getPluralText(condition?.Value, "Day", "Days")}</span>
            );
          }

          case SUB_OPERATORS.WITHIN_LAST_N_DAYS: {
            const value = `Within Last ${getPluralText(
              condition?.Value,
              "Day",
              "Days"
            )}`;
            return <span>{value}</span>;
          }
          case SUB_OPERATORS.WITHIN_NEXT_N_DAYS: {
            const value = `Within Next ${getPluralText(
              condition?.Value,
              "Day",
              "Days"
            )}`;

            return <span>{value}</span>;
          }

          case SUB_OPERATORS.LESS_THAN_CYCLE_START_DATE:
          case SUB_OPERATORS.LESS_THAN_CYCLE_END_DATE:
          case SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_START_DATE:
          case SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_END_DATE:
          case SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_END_DATE:
          case SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_START_DATE:
          case SUB_OPERATORS.GREATER_THAN_CYCLE_START_DATE:
          case SUB_OPERATORS.GREATER_THAN_CYCLE_END_DATE: {
            const value =
              operator?.options?.[condition?.SubOperator]?.label?.split(
                " Cycle "
              );
            const [suboperator, group] = value;
            return (
              <>
                <span className="fc-grey">{suboperator}</span>
                <span>
                  {getPluralText(condition?.Value, "Day", "Days")} of Cycle
                </span>
                <span>{group}</span>
              </>
            );
          }

          default: {
            return null;
          }
        }
      }

      return <span>{operator?.options?.[condition?.SubOperator]?.label}</span>;
    }

    default: {
      return null;
    }
  }
};

const DisplayCondition = ({
  conditions,
  setdrawerOpen,
  handleDelete,
  handleUpdate,
  handleAdd,
  selectedConditionIndex,
  disableAdd,
  displayNameSchemeNameMap,
  readOnly,
}) => {
  const [deleteModal, setDeleteModal] = useState({ show: false, index: null });
  return (
    <div className="w-100 mt-4 display-condition-list">
      {conditions?.map((condition, index) => {
        const operator =
          FILTERS_MODEL?.[condition?.DataType]?.[condition?.Operator];
        return !isEmpty(condition) ? (
          <div className="mb-3 flex center">
            <span className="conditon-index fs-13 fc-grey1">{index + 1}</span>
            <div className="bg-grey5 px-2 fs-13 w-100 mx-3 semi-bold flex justify-content-between center display-condition">
              <div className="space-x-6">
                <span>
                  {displayNameSchemeNameMap?.[condition?.Attribute] ||
                    condition?.Attribute}
                </span>
                {operator?.label && (
                  <span className="fc-grey">{operator?.label}</span>
                )}
                <SubOperatorValue condition={condition} operator={operator} />
              </div>
              <div
                className={classNames([
                  "center crud-btn space-x-3",
                  readOnly ? "not-visible" : "",
                ])}
              >
                <FormButton
                  id="edit-btn"
                  variant="only-icon"
                  icon={<SvgEdit color={COLORS.GREY_2} />}
                  className="my-2 h-40"
                  onClick={() => handleUpdate({ index })}
                />
                <FormButton
                  id="delete-btn"
                  variant="only-icon"
                  className="my-2 h-40"
                  icon={
                    <SvgDelete color={COLORS.GREY_2} width={18} height={18} />
                  }
                  onClick={() => {
                    setDeleteModal({ show: true, index });
                  }}
                />
              </div>
            </div>
            {!disableAdd ? (
              <FormButton
                id="add-btn"
                className="add-item"
                variant="text"
                icon={<SvgPlus color={COLORS.GREY_2} />}
                onClick={() => handleAdd({ index: index + 1 })}
              />
            ) : null}
          </div>
        ) : (
          <div className="align-self-start center w-100 fs-13 mb-3">
            <span className="conditon-index fc-grey1">
              {selectedConditionIndex + 1}
            </span>
            <div className="bg-blue flex fc-blue ml-3 p-2 w-100">
              {t("RULEBOOK.LABELS.CONDITION_ADD_HERE")}
            </div>
          </div>
        );
      })}
      {deleteModal.show ? (
        <ConfirmationModal
          title={t("INCENTIVES.PROGRAM.LABELS.DELETE_CONDITION")}
          body={
            <>
              {" "}
              <div className="fs-14 semi-bold center">
                <div>
                  {t("INCENTIVES.PROGRAM.LABELS.DELETE_WARNING")}{" "}
                  {Number(deleteModal.index) + 1}?
                </div>
              </div>
              <div className="fs-13 mt-2">
                {t("INCENTIVES.PROGRAM.LABELS.CONDITION_RESET")}
              </div>
            </>
          }
          show={deleteModal.show}
          onConfirm={() => {
            handleDelete({ index: deleteModal.index });
            setDeleteModal({ show: false, index: null });
          }}
          onClose={() => setDeleteModal({ show: false, index: null })}
          confirmButtonText={t("MANAGED_GOALS.BUTTONS.YES_DELETE")}
          cancelButton={t("COMMON.BUTTONS.CANCEL")}
        />
      ) : null}
    </div>
  );
};

export default DisplayCondition;
