import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCreateTemplate, useUpdateTemplate } from "src/api/useTemplate";
import {
  ButtonShimmer,
  CreateInput,
  toaster,
} from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import { APPS, CREATE_PROGRAM_REGEX } from "src/constants";
import { errorText } from "src/helpers";
import { withRouter } from "src/router";

const CreateGoalTemplateModal = (props) => {
  const {
    status,
    onSuccess,
    onShowClick,
    onCancelClick,
    match,
    history,
    refetch,
    templateName,
    templateStatus,
    templateDescription,
  } = props;
  const [createTemplate, { loading, error }] = useCreateTemplate();
  const [UpdateTemplate, { loading: updationLoading, error: updationError }] =
    useUpdateTemplate();
  const { t } = useTranslation();
  const [nameValue, setNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [warning, setWarning] = useState(false);
  useEffect(() => {
    setNameValue(templateName && templateName);
    setDescriptionValue(templateDescription && templateDescription);
  }, [templateDescription, templateName]);

  if (error) {
    toaster({ message: errorText.CREATE_TEMPLATE.ERROR, type: "error" });
    return <></>;
  }
  if (updationError) {
    toaster({ message: errorText.UPDATE_TEMPLATE.ERROR, type: "error" });
    return <></>;
  }

  const onSubmit = () => {
    status !== "update"
      ? createTemplate({
          variables: {
            Name: nameValue,
            Description: descriptionValue || "",
          },
        }).then(
          (result) => {
            if (
              result.data?.createTemplate?.__typename ===
              "SingleTemplateResponse"
            ) {
              toaster({
                message: result.data.createTemplate.message,
                type: "info",
              });
              onSuccess();
              history.push(
                APPS.RULESETS.ROUTES.RULESET_CRUD.link({
                  params: {
                    id: result.data.createTemplate?.response?.Template
                      .TemplateId,
                  },
                })
              );
            } else {
              toaster({
                message: result.data?.createTemplate?.message,
                type: "error",
              });
            }
          },
          (error) => {
            console.error("Create error: ", error);
            toaster({
              message: errorText.CREATE_TEMPLATE.ERROR,
              type: "error",
            });
          }
        )
      : UpdateTemplate({
          variables: {
            Name: nameValue,
            Description: descriptionValue,
            TemplateId: match.params.id,
            Status: templateStatus,
          },
        }).then(
          (result) => {
            if (
              result.data?.updateTemplate?.__typename ===
              "SingleTemplateResponse"
            ) {
              toaster({
                message: result.data.updateTemplate.message,
                type: "info",
              });
              onSuccess();
              refetch({
                variables: {
                  Name: nameValue,
                  Description: descriptionValue || "",
                  TemplateId: match.params.id,
                },
              });
            } else {
              toaster({
                message: result.data?.updateTemplate?.message,
                type: "error",
              });
            }
          },
          (error) => {
            console.error("Create error: ", error);
            toaster({
              message: errorText.UPDATE_TEMPLATE.ERROR,
              type: "error",
            });
          }
        );
  };
  const disableValidation = () => {
    if (warning || !nameValue?.trim()) return true;

    if (
      status === "update" &&
      templateName === nameValue?.trim() &&
      templateDescription === descriptionValue.trim()
    )
      return true;

    return false;
  };
  return (
    <Modal
      show={onShowClick}
      onHide={onCancelClick}
      centered
      dialogClassName="create-template-modal"
    >
      <Modal.Header closeButton>
        <span className="fs-18 semi-bold" id="create-template-text">
          {status !== "update"
            ? t("RULEBOOK.BUTTONS.CREATE_RULEBOOK")
            : t("RULEBOOK.BUTTONS.UPDATE_RULEBOOK")}
        </span>
      </Modal.Header>
      <Modal.Body className="modal_body">
        <div className="p-4">
          <CreateInput
            disabled
            id="ace-create-Name-input"
            label={t("RULEBOOK.BUTTONS.CREATE_RULEBOOK")}
            placeholder={t("RULEBOOK.LABELS.ENTER_RULEBOOK_NAME")}
            height="60px"
            regex={CREATE_PROGRAM_REGEX}
            maxLength={100}
            warning={warning}
            setWarning={setWarning}
            value={nameValue}
            setValue={setNameValue}
          />
          <CreateInput
            disabled
            as="textarea"
            id="ace-create-description-input"
            label={t("RULEBOOK.LABELS.RULEBOOK_DESCRIPTION")}
            placeholder={t("RULEBOOK.LABELS.ENTER_RULEBOOK_DESCRIPTION")}
            regex={false}
            maxLength={500}
            height="100px"
            value={descriptionValue}
            setValue={setDescriptionValue}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          id="template-cancel-btn"
          variant="outline-primary"
          onClick={onCancelClick}
          label={t("COMMON.BUTTONS.CANCEL")}
        />
        {loading || updationLoading ? (
          <>
            {" "}
            <ButtonShimmer
              className="br-2"
              height={40}
              width="auto"
              text={
                status !== "update"
                  ? t("COMMON.BUTTONS.CREATING")
                  : t("COMMON.BUTTONS.UPDATING")
              }
            />
          </>
        ) : (
          <FormButton
            id="template-done-btn"
            disabled={disableValidation()}
            onClick={onSubmit}
            label={
              status !== "update"
                ? `${t("COMMON.BUTTONS.CREATE")} ${t(
                    "RULEBOOK.LABELS.RULEBOOK"
                  )}`
                : `${t("COMMON.BUTTONS.UPDATE")} ${t(
                    "RULEBOOK.LABELS.RULEBOOK"
                  )}`
            }
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(CreateGoalTemplateModal);
