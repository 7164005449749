import { t } from "i18next";
import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { FormButton } from "src/components";
import { UserTrophyAvatar } from "src/components/base";
import { HeroBackground, Truncate } from "src/components/common/atoms";
import { capitalize, classNames, range } from "src/helpers";
import {
  Spotlight,
  WallOfLegendsBackground,
  WallOfLegendsEmptyState,
} from "src/images";
import "./wallOfLegends.scss";

const WallOfLegends = (props) => {
  const { data = [], isMobile } = props;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const sortPodium = (users) => {
    users.sort((a, b) => b.position - a.position);
    return (
      ([users[users.length - 1], users[users.length - 2]] = [
        users[users.length - 2],
        users[users.length - 1],
      ]),
      users
    );
  };
  const getSortedUsers = (UserData) => {
    const getUsersPosition = UserData.map((item, idx) => {
      if (UserData.length <= 2) {
        if (UserData[0]?.IsEqual) {
          return { ...item, position: 1 };
        }
        return { ...item, position: idx + 1 };
      }
      if (
        UserData[0]?.IsEqual &&
        UserData[1]?.IsEqual &&
        UserData[2]?.IsEqual
      ) {
        // 111 => 111
        return { ...item, position: 1 };
      }

      if (
        !UserData[0]?.IsEqual &&
        UserData[1]?.IsEqual &&
        UserData[2]?.IsEqual
      ) {
        // 011 => 122

        return { ...item, position: idx === 0 ? 1 : 2 };
      }
      if (
        UserData[0]?.IsEqual &&
        UserData[1]?.IsEqual &&
        !UserData[2]?.IsEqual
      ) {
        // 110 => 112

        return { ...item, position: idx === 2 ? 2 : 1 };
      }
      return { ...item, position: idx + 1 };
    });
    return sortPodium(getUsersPosition);
  };

  const filteredData = data?.filter(
    ({ CycleType }) => CycleType !== "BIWEEKLY"
  );
  filteredData?.reverse();

  const filteredResponse = filteredData.map((item) =>
    item.Users?.length > 1
      ? {
          ...item,
          Users: getSortedUsers(item?.Users),
        }
      : { ...item }
  );

  return (
    <HeroBackground
      image={WallOfLegendsBackground}
      className={classNames(["py-4 wall-of-legends"])}
    >
      <div className="h-100 fc-white flex-center flex-column">
        <div
          className={classNames([
            "fs-20 semi-bold wall-of-legends-title",
            isMobile && "fs-16",
          ])}
        >
          {t("LEADERBOARD.LABELS.WALL_OF_LEGENDS")}
        </div>
        <div className="fs-13 px-4 wall-of-legends-title-desc">
          {t(
            "LEADERBOARD.LABELS.TOP_PERFORMERS_OF_EACH_CYCLE_ACROSS_LEADERBOARDS"
          )}
        </div>
        {data && data.length > 0 ? (
          <Carousel
            indicators={false}
            controls={false}
            activeIndex={index}
            onSelect={handleSelect}
            className="w-100 h-100"
          >
            {filteredResponse &&
              filteredResponse?.length > 0 &&
              filteredResponse?.map(({ CycleType, Users }) => (
                <Carousel.Item className={classNames(["px-4"])}>
                  {CycleType && (
                    <div className="mt-4 semi-bold flex-center line-with-text">
                      {capitalize(CycleType)}
                    </div>
                  )}
                  <div
                    className={classNames([
                      "people-badges d-flex justify-content-between",
                      isMobile && "h-100 w-100",
                    ])}
                  >
                    {CycleType && Users && Users.length > 0 ? (
                      Users.map(({ Name, UserId, position }, idx) => (
                        <div
                          className={classNames([
                            "flex-column-center",
                            `item item-${position}`,
                          ])}
                          key={UserId}
                        >
                          <span className="spotlight">
                            <Spotlight />
                          </span>
                          <UserTrophyAvatar
                            userId={UserId}
                            imgProps={{
                              width:
                                idx === 0
                                  ? isMobile
                                    ? "50px"
                                    : "110px"
                                  : isMobile
                                  ? "60px"
                                  : "100px",
                              height:
                                idx === 0
                                  ? isMobile
                                    ? "50px"
                                    : "110px"
                                  : isMobile
                                  ? "60px"
                                  : "100px",
                            }}
                            position={position}
                            recurrencePeriod={CycleType}
                            size={isMobile && "40px"}
                          />
                          {Name && (
                            <span
                              className={
                                isMobile &&
                                "fs-11 flex-center wall-of-legends-name"
                              }
                            >
                              <Truncate
                                text={Name}
                                maxWidth={isMobile ? "120px" : "100%"}
                              />
                            </span>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="w-100 pt-1 flex-center flex-column wall-of-legends-empty-state">
                        <WallOfLegendsEmptyState
                          height="137px"
                          width="205px"
                          className="wall-of-legends-empty-state-img"
                        />
                        <span
                          className={classNames([
                            "fs-13 semi-bold fc-white",
                            isMobile ? "mt-1" : "mt-3",
                          ])}
                        >
                          {t("LEADERBOARD.MESSAGES.NO_TOP_PERFORMERS_TO_SHOW")}
                        </span>
                        {!isMobile && (
                          <span className="fs-12 fc-white mt-2 wall-of-legends-empty-state-desc">
                            {t(
                              "LEADERBOARD.MESSAGES.TOP_THREE_PERFORMERS_ACROSS_ALL_LEADERBOARDS_DISPLAYED_HERE"
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </Carousel.Item>
              ))}
          </Carousel>
        ) : (
          <div className={"people-badges-loading-state"}>
            <div
              className={classNames([
                "pt-5 flex-center flex-column wall-of-legends-empty-state",
                isMobile && "mt-4",
              ])}
            >
              <WallOfLegendsEmptyState height="137px" width="205px" />
              <span
                className={classNames([
                  "fs-13 semi-bold fc-white",
                  isMobile ? "mt-1" : "mt-3",
                ])}
              >
                {t("LEADERBOARD.MESSAGES.NO_TOP_PERFORMERS_TO_SHOW")}
              </span>
              <span className="fs-12 fc-white mt-2 wall-of-legends-empty-state-descb">
                {t(
                  "LEADERBOARD.MESSAGES.TOP_THREE_PERFORMERS_ACROSS_ALL_LEADERBOARDS_DISPLAYED_HERE"
                )}
              </span>
            </div>
          </div>
        )}

        {data && data.length > 0 && (
          <div className="mt-3 center wall-of-legends-carousel-indicators">
            {range(
              0,
              data.filter(({ CycleType }) => CycleType !== "BIWEEKLY").length -
                1
            ).map((i) => {
              return (
                <div className="flex-center wall-of-legends-carousel-indicator-wrapper">
                  <FormButton
                    className={classNames([
                      "indicator cursor-pointer p-0",
                      index === i ? "active" : "",
                    ])}
                    onClick={() => setIndex(i)}
                    variant="normal-link"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </HeroBackground>
  );
};

export default WallOfLegends;
