import PropTypes from "prop-types";
import React from "react";
import { BaseCard } from "src/components/common/atoms/card/baseCard";
import { classNames } from "src/helpers";
import "./mobileTabs.scss";

const MobileTabsProps = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const defaultMobileTabsProps = {
  className: "",
};

const MobileTabs = (props) => {
  const { className, children } = props;

  return (
    <BaseCard
      shadow={false}
      className={classNames(["center mobile-tabs", className])}
    >
      {children}
    </BaseCard>
  );
};

MobileTabs.propTypes = MobileTabsProps;
MobileTabs.defaultProps = defaultMobileTabsProps;

export default MobileTabs;
