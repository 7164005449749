import { gql, useLazyQuery } from "src/api/client";

const GET_APPROVAL_USERS_LIST_QUERY = gql`
  query allUsersForProgram(
    $programId: ID!
    $recurrenceId: ID
    $recurrenceStatus: String
    $Search: String
  ) {
    allUsersForProgram(
      programId: $programId
      recurrenceId: $recurrenceId
      recurrenceStatus: $recurrenceStatus
      Search: $Search
    ) {
      ... on AllUsersForProgramResponse {
        success
        message
        response {
          userId
          fullName
          emailAddress
          approvalStatus
          __typename
        }
      }
    }
  }
`;

export const useGetApprovalUsersList = (queryOptions) => {
  return useLazyQuery(GET_APPROVAL_USERS_LIST_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
