import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgBetaTag = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 12"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <g>
        <g transform="translate(-210 -122.394)">
          <rect
            width={30}
            height={12}
            fill={color}
            rx={6}
            transform="translate(210 122.394)"
          />
        </g>
        <path
          fill="#fff"
          d="M4.817 9.081V2.92H7.06a2.8 2.8 0 0 1 1.586.378 1.216 1.216 0 0 1 .556 1.064 1.262 1.262 0 0 1-.337.872 1.773 1.773 0 0 1-.862.52v.017a1.708 1.708 0 0 1 1.051.486 1.358 1.358 0 0 1 .393.984 1.645 1.645 0 0 1-.608 1.344 2.536 2.536 0 0 1-1.655.5Zm1.385-5.14v1.461h.61a1.017 1.017 0 0 0 .677-.208.71.71 0 0 0 .25-.574q0-.679-1.014-.679Zm0 2.493v1.625h.752a1.158 1.158 0 0 0 .754-.223.747.747 0 0 0 .276-.616.7.7 0 0 0-.269-.58 1.188 1.188 0 0 0-.75-.211Zm7.954 2.647h-3.7V2.92h3.558v1.13h-2.166v1.37h2.016v1.126h-2.016v1.41h2.308Zm5.394-5.032h-1.758v5.032h-1.39V4.049H14.65V2.92h4.9Zm5.634 5.032h-1.513l-.438-1.371h-2.192l-.439 1.371h-1.5l2.244-6.161h1.646Zm-2.269-2.437-.662-2.071a2.7 2.7 0 0 1-.1-.554h-.038a2.351 2.351 0 0 1-.107.537l-.67 2.089Z"
        />
      </g>
    </svg>
  );
};

SvgBetaTag.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 12,
  width: 30,
};
export default SvgBetaTag;
