import React from "react";
// import { useAppShell } from "./useAppShell";

function Container({ children }) {
  // const { meta, isMobile } = useAppShell();
  return (
    <div>
      {/* AppBar */}
      {children}
    </div>
  );
}

export default Container;
