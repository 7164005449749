import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgSearchThin = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M6.866 12.732A5.866 5.866 0 1 0 6.866 1a5.866 5.866 0 0 0 0 11.732ZM15.078 15.078l-4.067-4.067"
      />
    </svg>
  );
};
SvgSearchThin.defaultProps = {
  color: COLORS.GREY_2,
  height: 16,
  width: 16,
};
export default SvgSearchThin;
