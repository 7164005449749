import React from "react";
import { UserRankAvatar } from "src/components/base";
import { GlassBanner, Truncate } from "src/components/common/atoms";
import "./recognitionBoardCard.scss";

const RecognitionBoardCard = (props) => {
  const { currentCycle, name, groupName, lastUpdated, users, isMobile } = props;

  return (
    <GlassBanner className="flex-between recognition-board-card">
      <div className="bg-white p-2 br-8 recognition-board-card-title">
        <div className="flex-between">
          <div className="fs-14">Cycle {currentCycle}</div>
          <span className="fs-14 ml-1" style={{ color: "#ed6001" }}>
            {lastUpdated}
          </span>
        </div>
        <p className="fs-16 semi-bold">
          {!isMobile ? (
            <Truncate text={name} maxWidth="160px" />
          ) : (
            <div className="fc-blue">{name}</div>
          )}
          {groupName && groupName !== "NON_GROUP" && (
            <span className="fc-grey1">{groupName}</span>
          )}
        </p>
      </div>
      {users && users.length > 0 && (
        <div className="flex-between recognition-board-card-users">
          {users.map(({ Name, Rank, UserId }) => (
            <div className="w-100 flex-center flex-column recognition-board-card-user">
              <UserRankAvatar userId={UserId} rank={Rank} />
              {Name && (
                <span className="fs-14 semi-bold mt-2 text-center flex-center">
                  <Truncate text={Name} maxWidth="120px" />
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </GlassBanner>
  );
};

export default RecognitionBoardCard;
