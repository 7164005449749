import {
  ALL_RECURRENCE_PERIODS,
  CUSTOM_CYCLE_TYPES,
  DATE_FORMAT,
  DATE_FORMAT_PAYLOAD,
  FORMAT_RECURRENCE_PERIOD,
  MAX_CYCLE_DAYS,
} from "src/constants";
import {
  getSystemOffset,
  getTodayAsPerTimezone,
} from "src/helpers/timezoneUtils";
import {
  add,
  addDays,
  format,
  getDate,
  getDaysInMonth,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isExists,
  isSameDay,
  isSameMonth,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from "src/helpers/vendors";

export const getRecurrenceStatus = (recurrence) => {
  const today = getTodayAsPerTimezone() || new Date();
  const startDate = isNaN(parseInt(recurrence?.RecurrenceStartDate))
    ? new Date(recurrence?.RecurrenceStartDate)
    : new Date(
        parseInt(recurrence?.RecurrenceStartDate, 10) - getSystemOffset()
      );

  const endDate = isNaN(parseInt(recurrence?.RecurrenceEndDate))
    ? new Date(recurrence?.RecurrenceEndDate)
    : new Date(parseInt(recurrence?.RecurrenceEndDate, 10) - getSystemOffset());

  if (
    (isAfter(today, startDate) || isSameDay(today, startDate)) &&
    (isBefore(today, endDate) || isSameDay(today, endDate))
  ) {
    return "INPROGRESS";
  }
  if (isAfter(startDate, today)) {
    return "YETTOSTART";
  }
  return "COMPLETED";
};

export const getCycleIntervalString = (
  startDate,
  endDate,
  recurrencePeriod,
  dateFormat = DATE_FORMAT
) => {
  const period = recurrencePeriod?.toLowerCase();
  const startDateString = format(
    new Date(parseInt(startDate, 10) - getSystemOffset()),
    dateFormat
  );
  const isSameDate =
    period === "daily" ||
    (Number.isNaN(parseInt(startDate, 10))
      ? isSameDay(new Date(startDate), new Date(endDate))
      : isSameDay(
          new Date(parseInt(startDate, 10)),
          new Date(parseInt(endDate, 10))
        ));

  if (isSameDate) return startDateString;

  const endDateString = format(
    new Date(parseInt(endDate, 10) - getSystemOffset()),
    dateFormat
  );

  return `${startDateString} - ${endDateString}`;
};

export const getCustomCycleIntervalString = (
  startDate,
  endDate,
  dateformat = DATE_FORMAT
) => {
  const startDateString = format(
    new Date(parseInt(startDate, 10) - getSystemOffset()),
    dateformat
  );
  const endDateString = format(
    new Date(parseInt(endDate, 10) - getSystemOffset()),
    dateformat
  );
  if (isSameDay(startDate, endDate)) return startDateString;
  return `${startDateString} - ${endDateString}`;
};

export const heading = (
  recurrence,
  yearBifurcatedFormat = "yyyy",
  monthBifurcatedFormat = "MMMM yyyy"
) => {
  const period = recurrence && recurrence?.RecurrenceType?.toLowerCase();
  if (["monthly", "quarterly", "yearly", "infinite"].includes(period))
    return format(
      new Date(
        parseInt(recurrence.RecurrenceStartDate, 10) - getSystemOffset()
      ),
      yearBifurcatedFormat
    );
  return format(
    new Date(parseInt(recurrence.RecurrenceStartDate, 10) - getSystemOffset()),
    monthBifurcatedFormat
  );
};

export const customHeading = (str, monthBifurcatedFormat = "MMMM yyyy") => {
  const dates = str.toString().split(".");
  let customHeadingString = "";
  dates.forEach((i, idx) => {
    customHeadingString += `${idx >= 1 ? " - " : ""}${format(
      new Date(
        parseInt(i.substring(0, 4), 10),
        parseInt(i.substring(4).padEnd(2, "0"), 10)
      ),
      monthBifurcatedFormat
    )}`;
  });
  return customHeadingString;
};

export const getIndex = (recurrences, selectedRecurrence) => {
  const index = recurrences?.findIndex(
    (eachRecurrence) =>
      eachRecurrence?.RecurrenceId === selectedRecurrence?.RecurrenceId
  );
  return index;
};

export const groupByTimePeriod = (obj, recurrencePeriod) => {
  const objPeriod = {};
  const period = recurrencePeriod && recurrencePeriod.toLowerCase();
  if (period === "infinite") {
    obj.forEach((item, idx) => {
      const date = new Date(item.RecurrenceStartDate - getSystemOffset());
      const key = getYear(date);
      objPeriod[key] = objPeriod[key] || [];
      const value = `${format(
        new Date(item.RecurrenceStartDate - getSystemOffset()),
        DATE_FORMAT
      )} - Forever`;
      const recurrencesStatus = "INPROGRESS";
      objPeriod[key].push({
        ...item,
        idx: idx + 1,
        value,
        recurrencesStatus,
      });
    });
  } else if (period === "custom") {
    obj.forEach((item, idx) => {
      const startDate = new Date(
        parseInt(item.RecurrenceStartDate - getSystemOffset(), 10)
      );
      const endDate = new Date(
        parseInt(item.RecurrenceEndDate - getSystemOffset(), 10)
      );
      const key = isSameMonth(startDate, endDate)
        ? parseFloat(
            `${getYear(startDate)}${getMonth(startDate)
              .toString()
              .padStart(2, "0")}`
          )
        : parseFloat(
            `${getYear(startDate)}${getMonth(startDate)
              .toString()
              .padStart(2, "0")}.${getYear(endDate)}${getMonth(endDate)
              .toString()
              .padStart(2, "0")}`
          );
      objPeriod[key] = objPeriod[key] || [];
      const value = getCustomCycleIntervalString(
        item.RecurrenceStartDate,
        item.RecurrenceEndDate
      );
      const recurrenceStatus = getRecurrenceStatus(item);
      objPeriod[key].push({ ...item, idx: idx + 1, value, recurrenceStatus });
    });
  } else {
    obj.forEach((item, idx) => {
      const date = new Date(item.RecurrenceStartDate - getSystemOffset());
      let key;
      if (period === "monthly" || period === "quarterly") {
        key = getYear(date);
      } else {
        key = (getYear(date) - 1970) * 12 + getMonth(date);
      }
      objPeriod[key] = objPeriod[key] || [];
      const value = getCycleIntervalString(
        item.RecurrenceStartDate,
        item.RecurrenceEndDate,
        period
      );
      const recurrenceStatus = getRecurrenceStatus(item);
      objPeriod[key].push({ ...item, idx: idx + 1, value, recurrenceStatus });
    });
  }
  return objPeriod;
};

export const formatRecurrencePeriod = ({
  recurrencePeriod,
  customType,
  caseType = "capitalized",
}) => {
  if (
    !recurrencePeriod ||
    !ALL_RECURRENCE_PERIODS.includes(recurrencePeriod?.toUpperCase()) ||
    !["lowercase", "capitalized", "uppercase"].includes(caseType)
  )
    return "";

  const period = recurrencePeriod.toUpperCase();
  if (period === "CUSTOM") {
    if (
      !customType &&
      !Object.keys(CUSTOM_CYCLE_TYPES).includes(customType.toUpperCase())
    )
      return "";
    return FORMAT_RECURRENCE_PERIOD[period][customType.toUpperCase()][caseType];
  }
  return FORMAT_RECURRENCE_PERIOD[period][caseType];
};

export const recurrenceType = {
  HOURLY: "HOURLY",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  BIWEEKLY: "BIWEEKLY",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  YEARLY: "YEARLY",
  CUSTOM: "CUSTOM",
};

export const getRecurrenceType = (RecurrencePeriod) => {
  if (RecurrencePeriod)
    switch (RecurrencePeriod.toUpperCase()) {
      case "WEEKLY":
        return "weeks";
      case "BIWEEKLY":
        return "biweeks";
      case "MONTHLY":
        return "months";
      case "DAILY":
        return "days";
      default:
        return "";
    }
};

export const getDaysInRecurrence = (RecurrenceCycle) => {
  switch (RecurrenceCycle) {
    case "weeks":
      return 6;
    case "biweek":
      return 13;
    case "months":
      return 29;
    case "days":
      return 1;
    default:
      return 0;
  }
};

export const getDailyRecurrences = (
  startDate,
  endDate,
  RecurrencePeriodValue,
  index
) => {
  endDate = addDays(endDate, 1);
  const DailyRecurrences = [];
  while (isAfter(endDate, startDate)) {
    DailyRecurrences.push({
      id: index++,
      RecurrenceStartDate: startDate.getTime() + getSystemOffset(),
      RecurrenceEndDate: startDate.getTime() + getSystemOffset(),
      RecurrenceType: RecurrencePeriodValue,
      RecurrenceTestDate: startDate,
      RecurrenceTestEnd: startDate,
    });
    startDate = addDays(startDate, 1);
  }
  return DailyRecurrences;
};

export const getBiWeeklyRecurrences = (
  startDate,
  endDate,
  RecurrencePeriodValue,
  index
) => {
  const recurrences = [];
  while (startDate < endDate) {
    const newEndDate = add(startDate, { days: 13 });
    if (endDate < newEndDate) break;
    recurrences.push({
      id: index++,
      RecurrenceStartDate: startDate.getTime() + getSystemOffset(),
      RecurrenceEndDate: newEndDate.getTime() + getSystemOffset(),
      RecurrenceType: RecurrencePeriodValue,
    });
    startDate = addDays(newEndDate, 1);
  }
  return recurrences;
};

export const getMonthlyRecurrences = (
  startDate,
  endDate,
  RecurrencePeriodValue,
  index
) => {
  const recurrencesList = [];
  let newEndDate;
  let newStartDate;
  let initialStartDayCopy;
  let initialStartMonthCopy;
  let initialStartYearCopy;
  let endDateDayInitialCopy;
  // Run the loop before startDate exceeds endDate
  while (isBefore(startDate, endDate)) {
    newStartDate = startDate;
    // Take unchanged startDate if exists
    const startDateDay = initialStartDayCopy || getDate(startDate);
    const startDateMonth = initialStartMonthCopy || getMonth(startDate);
    const startDateYear = initialStartYearCopy || getYear(startDate);
    // Take the endDateDay copy
    let endDateDay = (endDateDayInitialCopy = startDateDay);
    let endDateMonth = startDateMonth < 11 ? startDateMonth + 1 : 0;
    let endDateYear = startDateYear;
    // Increment endDateYear if startDateMonth is last month
    if (startDateMonth === 11) {
      endDateYear += 1;
    }
    // endDateDay is always 1 less than the startDateDay
    // if endDateDay is 1 then take last month endDate
    if (endDateDay === 1) {
      endDateDay = getDaysInMonth(new Date(startDateYear, startDateMonth));
      endDateMonth = startDateMonth;
      endDateYear = startDateYear;
    } else {
      endDateDay -= 1;
    }
    // if invalid endDate is found,
    // then keep decrementing until reaches valid date
    while (
      !isExists(endDateYear, endDateMonth, endDateDay) &&
      endDateDay >= 1
    ) {
      endDateDay -= 1;
    }
    // Create newEndDate
    newEndDate = new Date(endDateYear, endDateMonth, endDateDay);
    // Check the unchanged endDateDay if exist and add one day
    if (isExists(endDateYear, endDateMonth, endDateDayInitialCopy)) {
      startDate = addDays(newEndDate, 1); // normal startDay
      initialStartDayCopy = "";
      initialStartMonthCopy = "";
      initialStartYearCopy = "";
    } else {
      initialStartDayCopy = endDateDayInitialCopy; // keep the unchanged startDate
      initialStartMonthCopy = endDateMonth;
      initialStartYearCopy = endDateYear;
      if (endDateMonth === 11) {
        endDateYear += 1;
      }
      endDateMonth = endDateMonth < 11 ? endDateMonth + 1 : 0;
      startDate = new Date(endDateYear, endDateMonth, 1);
    }
    if (isAfter(new Date(newEndDate), endDate)) {
      break;
    }
    recurrencesList.push({
      id: index++,
      RecurrenceStartDate: newStartDate.getTime() + getSystemOffset(),
      RecurrenceEndDate: newEndDate.getTime() + getSystemOffset(),
      RecurrenceType: RecurrencePeriodValue,
    });
  }
  return recurrencesList || [];
};

export const getQuarterlyRecurrences = (
  startDate,
  endDate,
  RecurrencePeriodValue,
  index
) => {
  const recurrences = [];
  while (startDate < endDate) {
    if (startDate < startOfYear(startDate)) {
      return;
    }
    const allowedMonths = [0, 3, 6, 9];
    const month = getMonth(startDate);
    const day = getDate(startDate);
    if (!allowedMonths.includes(month) || day !== 1) {
      return;
    }
    const newEndDate = subDays(add(startDate, { months: 3 }), 1);
    if (endDate < newEndDate) break;
    recurrences.push({
      id: index++,
      RecurrenceStartDate: startDate.getTime() + getSystemOffset(),
      RecurrenceEndDate: newEndDate.getTime() + getSystemOffset(),
      RecurrenceType: RecurrencePeriodValue,
    });
    startDate = addDays(newEndDate, 1);
  }
  return recurrences;
};

export const getYearlyRecurrences = (
  startDate,
  endDate,
  RecurrencePeriodValue,
  index
) => {
  const recurrences = [];
  if (startDate < startOfYear(startDate)) {
    const lastDateAllowed = subMonths(startOfDay(new Date()), 2);
    if (startDate < lastDateAllowed) return;
  }
  let newEndDate;
  let initialStartDayCopy;
  let initialStartMonthCopy;
  let initialStartYearCopy;
  let endDateDayInitialCopy;
  while (isBefore(startDate, endDate)) {
    const newStartDate = startDate;
    const startDateDay = initialStartDayCopy || getDate(startDate);
    const startDateMonth = initialStartMonthCopy || getMonth(startDate);
    const startDateYear = initialStartYearCopy || getYear(startDate);
    // Take the endDateDay copy
    let endDateDay = (endDateDayInitialCopy = startDateDay);
    const endDateMonth = startDateMonth;
    const endDateYear = startDateYear + 1;
    endDateDay -= 1;
    while (
      !isExists(endDateYear, endDateMonth, endDateDay) &&
      endDateDay >= 1
    ) {
      endDateDay -= 1;
    }
    newEndDate = new Date(endDateYear, endDateMonth, endDateDay);
    if (isExists(endDateYear, endDateMonth, endDateDayInitialCopy)) {
      startDate = addDays(newEndDate, 1); // normal startDay
      initialStartDayCopy = "";
      initialStartMonthCopy = "";
      initialStartYearCopy = "";
    } else {
      initialStartDayCopy = endDateDayInitialCopy; // keep the unchanged startDate
      initialStartMonthCopy = endDateMonth;
      initialStartYearCopy = endDateYear;
      startDate = new Date(endDateYear, endDateMonth, endDateDay + 1);
    }
    if (isAfter(new Date(newEndDate), endDate)) {
      break;
    }
    recurrences.push({
      id: index++,
      RecurrenceStartDate: newStartDate.getTime() + getSystemOffset(),
      RecurrenceEndDate: newEndDate.getTime() + getSystemOffset(),
      RecurrenceType: RecurrencePeriodValue,
    });
  }
  return recurrences;
};

export const getCustomRecurrences = (
  startDate,
  endDate,
  cycleDuration,
  gapDuration
) => {
  const recurrences = [];
  let index = 1;
  if (!startDate || !endDate) return [];
  while (isBefore(startDate, endDate) || isSameDay(startDate, endDate)) {
    let newEndDate = add(startDate, { days: cycleDuration - 1 });
    if (endDate < newEndDate) {
      newEndDate = endDate;
    }
    recurrences.push({
      id: index++,
      RecurrenceStartDate: startDate.getTime() + getSystemOffset(),
      RecurrenceEndDate: newEndDate.getTime() + getSystemOffset(),
      RecurrenceType: "CUSTOM",
    });
    startDate = addDays(newEndDate, gapDuration + 1);
  }
  return recurrences;
};

// TODO: Add Custom Cycle Support to this Function
export const getRecurrences = (
  FirstRecurrenceStartDate,
  LastRecurrenceEndDate,
  RecurrencePeriod
) => {
  let startDate = new Date(Date.parse(FirstRecurrenceStartDate));
  const endDate = new Date(Date.parse(LastRecurrenceEndDate));
  const period = getRecurrenceType(RecurrencePeriod);
  const RecurrencePeriodValue =
    recurrenceType[RecurrencePeriod && RecurrencePeriod.toUpperCase()] || null;
  let recurrences;
  let index = 1;

  if (RecurrencePeriodValue && RecurrencePeriodValue === "MONTHLY") {
    recurrences = getMonthlyRecurrences(
      startDate,
      endDate,
      RecurrencePeriodValue,
      index
    );
  } else if (RecurrencePeriodValue && RecurrencePeriodValue === "YEARLY") {
    recurrences = getYearlyRecurrences(
      startDate,
      endDate,
      RecurrencePeriodValue,
      index
    );
  } else if (RecurrencePeriodValue && RecurrencePeriodValue === "BIWEEKLY") {
    recurrences = getBiWeeklyRecurrences(
      startDate,
      endDate,
      RecurrencePeriodValue,
      index
    );
  } else if (RecurrencePeriodValue && RecurrencePeriodValue === "QUARTERLY") {
    recurrences = getQuarterlyRecurrences(
      startDate,
      endDate,
      RecurrencePeriodValue,
      index
    );
  } else if (RecurrencePeriodValue && RecurrencePeriodValue === "DAILY") {
    recurrences = getDailyRecurrences(
      startDate,
      endDate,
      RecurrencePeriodValue,
      index
    );
  } else {
    const NumberOfDays = getDaysInRecurrence(period);
    recurrences = [];
    while (startDate < endDate) {
      let newEndDate = add(startDate, { days: NumberOfDays || 1 });
      if (endDate < newEndDate) {
        newEndDate = endDate;
      }
      recurrences.push({
        id: index++,
        RecurrenceStartDate: startDate.getTime() + getSystemOffset(),
        RecurrenceEndDate: newEndDate.getTime() + getSystemOffset(),
        RecurrenceType: RecurrencePeriodValue,
        RecurrenceTestDate: startDate,
        RecurrenceTestEnd: newEndDate,
      });
      startDate = addDays(newEndDate, 1);
    }
  }
  return recurrences;
};

export const getRecurrenceDefination = ({
  recurrencePeriod,
  customCycleType,
  startDate,
  endDate,
  cycleDuration,
  gapDuration,
  recurrences,
}) => {
  switch (recurrencePeriod) {
    case "Custom": {
      if (customCycleType === "UNIFORM") {
        const Recurrences = getCustomRecurrences(
          new Date(startDate),
          new Date(endDate),
          cycleDuration,
          gapDuration
        ).map(({ RecurrenceStartDate, RecurrenceEndDate }) => ({
          RecurrenceStartDate,
          RecurrenceEndDate,
        }));
        return {
          RecurrencePeriod: recurrencePeriod?.toString().toUpperCase(),
          FirstRecurrenceStartDate:
            startDate && format(startDate, DATE_FORMAT_PAYLOAD),
          LastRecurrenceEndDate:
            endDate && format(endDate, DATE_FORMAT_PAYLOAD),
          RecurrenceCycleDuration: cycleDuration,
          CustomType: customCycleType,
          CustomGap: gapDuration,
          Recurrences,
        };
      }
      const Recurrences = recurrences.map(
        ({ recurrenceStartDate, recurrenceEndDate }) => ({
          RecurrenceStartDate: recurrenceStartDate
            ? format(recurrenceStartDate, DATE_FORMAT_PAYLOAD)
            : null,
          RecurrenceEndDate: recurrenceEndDate
            ? format(recurrenceEndDate, DATE_FORMAT_PAYLOAD)
            : null,
        })
      );

      const FirstRecurrenceStartDate =
        startDate && format(startDate, DATE_FORMAT_PAYLOAD);
      const LastRecurrenceEndDate =
        endDate && format(endDate, DATE_FORMAT_PAYLOAD);

      return {
        RecurrencePeriod: recurrencePeriod?.toString().toUpperCase(),
        Recurrences,
        FirstRecurrenceStartDate,
        LastRecurrenceEndDate,
        CustomType: customCycleType,
      };
    }
    default:
      return {
        RecurrencePeriod: recurrencePeriod?.toString().toUpperCase(),
        FirstRecurrenceStartDate:
          startDate && format(startDate, DATE_FORMAT_PAYLOAD),
        LastRecurrenceEndDate: endDate && format(endDate, DATE_FORMAT_PAYLOAD),
      };
  }
};

export const getNonUniformRecurrences = ({ recurrences, status, today }) => {
  const newRecurrences = recurrences.map((recurrence, i) => {
    const recurrenceStartDate = new Date(
      new Date(recurrence?.RecurrenceStartDate).getTime() - getSystemOffset()
    );
    const recurrenceEndDate = new Date(
      new Date(recurrence?.RecurrenceEndDate).getTime() - getSystemOffset()
    );

    return {
      recurrenceStartDate,
      recurrenceEndDate,
      editMode: false,
      add: recurrences[i + 1]
        ? !isSameDay(
            new Date(
              new Date(recurrences[i]?.RecurrenceEndDate).getTime() -
                getSystemOffset()
            ),
            subDays(
              new Date(
                new Date(recurrences[i + 1]?.RecurrenceStartDate).getTime() -
                  getSystemOffset()
              ),
              1
            )
          )
        : !isSameDay(
            addDays(
              new Date(
                new Date(recurrences[0]?.RecurrenceStartDate).getTime() -
                  getSystemOffset()
              ),
              MAX_CYCLE_DAYS - 1
            ),
            new Date(
              new Date(recurrences[i]?.RecurrenceEndDate).getTime() -
                getSystemOffset()
            )
          ),
      minDate: recurrences[i - 1]
        ? addDays(
            new Date(
              new Date(recurrences[i - 1]?.RecurrenceEndDate).getTime() -
                getSystemOffset()
            ),
            1
          )
        : startOfMonth(subYears(today, 1)),
      maxDate: recurrences[i + 1]
        ? subDays(
            new Date(
              new Date(recurrences[i + 1]?.RecurrenceStartDate).getTime() -
                getSystemOffset()
            ),
            1
          )
        : addDays(
            new Date(
              new Date(recurrences[0]?.RecurrenceStartDate).getTime() -
                getSystemOffset()
            ),
            MAX_CYCLE_DAYS - 1
          ),
    };
  });

  return newRecurrences;
};
