import { t } from "i18next";
import { FormButton, FormTooltip } from "src/components";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgDelete, SvgEdit, SvgGroupBy, SvgPlus } from "src/icons";
import "./styles.scss";

export const Item = ({ isEmpty, children, className }) => {
  const emptyClassName = isEmpty ? "empty" : "";
  return (
    <div
      className={classNames(["item flex-between", emptyClassName, className])}
    >
      {children}
    </div>
  );
};

export const ItemBody = ({
  handleEdit,
  handleDelete,
  children,
  className,
  hideButtons,
  showGroupBy,
}) => {
  return (
    <div className={classNames(["item-body flex-between", className])}>
      <div className="center">{children}</div>
      <div className="center">
        {!hideButtons && (
          <div className="center">
            <FormButton
              id="edit-btn"
              variant="text"
              icon={<SvgEdit color={COLORS.GREY_2} />}
              onClick={handleEdit}
            />
            <FormButton
              id="delete-btn"
              variant="text"
              icon={<SvgDelete color={COLORS.GREY_2} width={18} height={18} />}
              onClick={handleDelete}
            />
          </div>
        )}
        {showGroupBy && (
          <FormTooltip text={t("COMMON.LABELS.GROUP_BY_CONDITION")}>
            <SvgGroupBy className="ml-2 center" width={14} height={14} />
          </FormTooltip>
        )}
      </div>
    </div>
  );
};

export const EmptyItem = ({ children, className }) => {
  return (
    <div className={classNames(["item empty flex-between", className])}>
      <div className="center">{children}</div>
    </div>
  );
};

export const ItemIndex = ({ index, name }) => {
  return (
    <FormTooltip text={name || `Condition ${index}`} placement="bottom">
      <div className="item-index fs-13 fc-grey semi-bold flex-center">
        {index}
      </div>
    </FormTooltip>
  );
};

export const AddItem = ({ handleAdd, disabled, toolTipText }) => {
  return (
    <FormTooltip disable={!disabled} text={toolTipText}>
      <div style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
        <FormButton
          id="add-btn"
          className={classNames(["add-item", disabled && "pointer-none"])}
          variant="text"
          icon={<SvgPlus color={COLORS.GREY_2} />}
          onClick={handleAdd}
        />
      </div>
    </FormTooltip>
  );
};

export const ItemCount = ({
  fulfilledItems,
  maximumLimit,
  itemName,
  className,
}) => {
  const limitReached = fulfilledItems.length === maximumLimit;
  return (
    <div className={classNames(["item-count fs-14 fc-grey", className])}>
      <span className={classNames([limitReached ? "limit-reached" : ""])}>
        <span className="semi-bold filfilled-items">
          {fulfilledItems.length}
        </span>
        <span className="semi-bold total-items">/{maximumLimit}</span>
      </span>
      {itemName ? ` ${itemName}` : null}
    </div>
  );
};
