import React, { useState } from "react";
import { FormButton } from "src/components/common/formElements";
import { SvgLink } from "src/icons";
import TemplateDependencyModal from "../templateDependencyModal/TemplateDependencyModal";

const TemplateDependencyButton = ({
  templateId,
  name,
  text,
  status,
  onClose,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <FormButton
        id="view-dependency-btn"
        className="mr-3"
        variant="link"
        label={
          <div className="d-flex align-items-center">
            <span className="mr-2">{text}</span>
            <SvgLink height={15} width={15} />
          </div>
        }
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <TemplateDependencyModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            if (onClose) onClose();
          }}
          templateId={templateId}
          name={name}
          status={status}
        />
      )}
    </>
  );
};

export default TemplateDependencyButton;
