import React from "react";

const SvgAvatar23 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="wlze__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .wlze__cls-1{fill:#fff}.wlze__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="wlze__Layer_1-2">
        <path
          d="M162.8 81.4c0 14.25-3.66 27.63-10.1 39.28a74.65 74.65 0 0 1-3.42 5.63 81.27 81.27 0 0 1-9.47 11.76c-.03.02-.05.05-.07.07-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-.11.1-.22.21-.33.31-1.3 1.23-2.64 2.42-4.03 3.57-.83.69-1.69 1.36-2.55 2.01-.98.76-1.98 1.49-2.99 2.19-.35.24-.71.48-1.06.72-.84.56-1.68 1.11-2.54 1.63-.67.41-1.34.81-2.02 1.2-1.62.94-3.27 1.82-4.96 2.64-.98.49-1.97.95-2.97 1.38a80.698 80.698 0 0 1-31.26 6.85c-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-10.9-.19-21.28-2.53-30.72-6.61-.99-.42-1.96-.86-2.92-1.32A81.22 81.22 0 0 1 23.1 138.2l-.05-.05C8.78 123.47 0 103.48 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="wlze__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="wlze__cls-2"
        />
        <path
          d="M117.11 154.55c-.98.49-1.97.95-2.97 1.38l-5.98-5.18v-30.22c0-.16-.12-.31-.28-.34l-7.04-1.36c-.12-.03-.22.03-.28.07a.33.33 0 0 0-.12.27v19.63c0 4.28-3.49 7.77-7.77 7.77H70.12c-4.28 0-7.76-3.49-7.76-7.77v-18.96c0-.35-.2-.55-.31-.65a.83.83 0 0 0-.71-.16l-4.68 1.04c-.39.08-.66.42-.66.81v28.49l-6.8 6.8c-.99-.42-1.96-.86-2.92-1.32l6.72-6.72v-27.25c0-1.81 1.24-3.35 3.01-3.74l4.68-1.04c1.15-.25 2.32.02 3.23.75.92.73 1.44 1.82 1.44 2.99v18.96c0 2.63 2.14 4.77 4.76 4.77h22.55c2.63 0 4.77-2.14 4.77-4.77v-19.63c0-1 .44-1.94 1.21-2.58.77-.64 1.78-.89 2.76-.7l7.04 1.36c1.57.3 2.71 1.68 2.71 3.28v28.85l5.95 5.17ZM142 88.69a2.936 2.936 0 0 0-3.86.44c-.48.53-.74 1.22-.75 1.93l-.02 8.38c0 .41-.19.79-.52 1.05l-3.93 3.14c-.5.39-1.21.39-1.71 0l-3.92-3.16c-.32-.26-.51-.65-.52-1.06l.02-8.38c0-.71-.26-1.4-.74-1.94-.48-.53-1.13-.87-1.84-.95-.72-.08-1.43.1-2.02.51a17.028 17.028 0 0 0-7.16 14.15c.07 3.06.96 6.04 2.57 8.65 1.6 2.6 3.88 4.73 6.58 6.16l-.09 33.1c.86-.52 1.7-1.07 2.54-1.63l.09-32.25c0-.49-.28-.94-.72-1.15a14.671 14.671 0 0 1-8.43-12.91 14.49 14.49 0 0 1 6.09-12.05c.12-.08.27-.09.39-.02.12.06.2.19.21.33l-.03 8.37c0 1.18.54 2.3 1.45 3.04l3.94 3.17c.69.55 1.55.85 2.43.86.89 0 1.75-.3 2.44-.85l3.94-3.14c.93-.73 1.48-1.85 1.48-3.04l.02-8.37c0-.15.08-.28.21-.34.11-.07.26-.06.37.02a14.51 14.51 0 0 1 5.98 13.2c-.5 5.15-3.71 9.65-8.42 11.8-.45.2-.74.65-.74 1.15l-.07 23.69c.11-.1.22-.21.33-.31.71-.68 1.41-1.38 2.1-2.08.02-.02.04-.04.05-.06.02-.02.04-.05.07-.07l.06-20.38c5.27-2.72 8.74-7.99 9.18-13.9.43-5.92-2.24-11.64-7.05-15.1Z"
          className="wlze__cls-1"
        />
        <path
          d="m133.29 124.88-.06 19.28c-.83.69-1.69 1.36-2.55 2.01l.07-21.29c.01-.7.58-1.25 1.27-1.25s1.26.56 1.27 1.25Z"
          className="wlze__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar23.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar23;
