import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormButton } from "src/components/common/formElements";
import { TemplateDetailContent } from "src/components/common/organisms";
import "./templateDetailModal.scss";

const TemplateDetailModal = (props) => {
  const {
    name,
    goalAggregateFunction,
    description,
    goalRules,
    onShowClick,
    onCancelClick,
    isCustomFormula,
    formula,
    loading,
    isAdvancedRuleset,
    groupBy,
  } = props;
  const { t } = useTranslation();
  return (
    <Modal
      show={onShowClick}
      onHide={onCancelClick}
      centered
      dialogClassName="template-detail-container"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("MANAGED_GOALS.LABELS.RULESET_DETAILS")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal_body">
        <TemplateDetailContent
          name={name}
          goalAggregateFunction={goalAggregateFunction}
          description={description}
          goalRules={goalRules}
          isCustomFormula={isCustomFormula}
          formula={formula}
          loading={loading}
          isAdvancedRuleset={isAdvancedRuleset}
          groupBy={groupBy}
        />
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          id="template-detail-cancel-btn"
          onClick={onCancelClick}
          variant="outline-primary"
          label={t("COMMON.BUTTONS.CANCEL")}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateDetailModal;
