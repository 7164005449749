import { t } from "i18next";
import React, { Suspense, lazy } from "react";
import { MobileTabItem, MobileTabs } from "src/components";
import { APPS } from "src/constants";
import { useGetSearchParams } from "src/hooks";
import { useHistory } from "src/router";
import { DASHBOARD_FILTER_BUTTONS, DASHBOARD_VIEW } from "./data";

const DashboardUserView = lazy(() =>
  import(
    /*
    webpackChunkName: "dashboard.program.view",
    webpackPrefetch: true
    */
    "./dashboardUserView/DashboardUserView"
  )
);

const DashboardProgramView = lazy(() =>
  import(
    /*
    webpackChunkName: "dashboard.program.view",
    webpackPrefetch: true
    */
    "./dashboardProgramView/DashboardProgramView"
  )
);

const MobileDashboardBody = ({ payloadUserId, filtersState, isIncentive }) => {
  const history = useHistory();
  const { type } = useGetSearchParams({
    type: DASHBOARD_VIEW.USER_VIEW,
  });
  const isUserView = !type ? true : type === DASHBOARD_VIEW.USER_VIEW;

  const handleFilterButtonClick = (type) => {
    history.push(
      isIncentive
        ? APPS.INCENTIVES.ROUTES.DASHBOARD.link({
            search: { type },
          })
        : APPS.LEADERBOARDS.ROUTES.DASHBOARD.link({
            search: { type },
          })
    );
  };

  return (
    <>
      <div className="incentives-mobile-tabs center justify-content-between w-fit-content">
        <MobileTabs>
          {DASHBOARD_FILTER_BUTTONS(isIncentive).map(({ key, label }) => {
            return (
              <MobileTabItem
                className="min-w-max"
                active={type ? key === type : key === DASHBOARD_VIEW.USER_VIEW}
                onClick={() => handleFilterButtonClick(key)}
              >
                {t(label)}
              </MobileTabItem>
            );
          })}
        </MobileTabs>
      </div>
      <Suspense fallback={<></>}>
        {isUserView ? (
          <DashboardUserView
            managerId={payloadUserId}
            timeFrequency={filtersState.timeFrequency}
            isIncentive={isIncentive}
          />
        ) : (
          <DashboardProgramView
            managerId={payloadUserId}
            timeFrequency={filtersState.timeFrequency}
            isIncentive={isIncentive}
          />
        )}
      </Suspense>
    </>
  );
};

export default MobileDashboardBody;
