import { t } from "i18next";
import React, { useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import { COLORS } from "src/constants";
import { useRbac } from "src/hooks";
import { SvgArrow, SvgBetaTag } from "src/icons";
import { AceMenuList } from "../aceMenuList";
import "./aceMenu.scss";

const AceMenu = () => {
  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);
  const { webMenu, webActiveRoute } = useRbac();
  const { label, icon: Icon, isBeta } = webActiveRoute;

  return (
    <>
      <button
        id="ace-menu-btn"
        ref={target}
        onClick={() => setShowPopover((prev) => !prev)}
        className="btn btn-link center space-x-16"
      >
        <Icon width="22px" height="22px" />
        <div className="center">
          <span className="fc-black fs-20 semi-bold">{t(label)}</span>
          {isBeta && <SvgBetaTag className="ml-2" />}
        </div>
        <SvgArrow color={COLORS.GREY_1} />
      </button>
      <Overlay
        target={target.current}
        show={showPopover}
        transition={false}
        placement="bottom-start"
        rootClose
        onExited={() => setShowPopover(false)}
        onHide={() => setShowPopover(false)}
        onToggle={() => setShowPopover((prev) => !prev)}
      >
        {(props) => {
          const { ref, style, placement } = props;
          return (
            <div
              id="ace-menu-popover"
              data-placement={placement}
              style={style}
              className="ace-menu-popover-header br-8 mt-3 p-0 bg-white"
              ref={ref}
            >
              <AceMenuList menu={webMenu} activeRoute={webActiveRoute} />
            </div>
          );
        }}
      </Overlay>
    </>
  );
};

export default AceMenu;
