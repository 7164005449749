import React from "react";
import { classNames } from "src/helpers";

const Header = ({ className, children }) => (
  <div className={classNames(["leaderboard-grid-header", className])}>
    {children}
  </div>
);

export default Header;
