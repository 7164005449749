import { gql, useMutation } from "src/api/client";

const PAYOUT_TOTAL_USER_AMOUNT_MUTATION = gql`
  mutation payoutTotalUserAmount(
    $userId: ID
    $referenceId: String
    $timeFrequency: TimeFrequency
  ) {
    payoutTotalUserAmount(
      userId: $userId
      referenceId: $referenceId
      timeFrequency: $timeFrequency
    ) {
      ... on payoutTotalUserAmountResponse {
        message
        success
        response
      }
    }
  }
`;

export const usePayoutTotalUserAmount = () => {
  return useMutation(PAYOUT_TOTAL_USER_AMOUNT_MUTATION);
};
