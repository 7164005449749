import { t } from "i18next";
import React from "react";
import { SvgEligibleAmount } from "src/icons";
import AmountStats from "./AmountStats";

export const EligibleAmount = ({
  className,
  title = t("INCENTIVES.MY_INCENTIVES.LABELS.ELIGIBLE"),
  amount,
  rewards,
  rewardCount,
  payloadForRewardsList,
}) => {
  return (
    <AmountStats
      className={className}
      title={title}
      amount={amount}
      rewards={rewards}
      rewardCount={rewardCount}
      Icon={<SvgEligibleAmount className="mr-1" />}
      payloadForRewardsList={payloadForRewardsList}
    />
  );
};
