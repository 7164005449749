import { gql, useQuery } from "src/api/client";

const GET_MANUALLY_SELECTED_USERS = gql`
  query manuallySelectedUsers(
    $RecurrenceId: ID!
    $userAttributesNames: [String]!
    $Search: String
    $Limit: Float
    $After: String
  ) {
    manuallySelectedUsers(
      RecurrenceId: $RecurrenceId
      userAttributesNames: $userAttributesNames
      Search: $Search
      Limit: $Limit
      After: $After
    ) {
      ... on ManuallySelectedUsersResponse {
        success
        message
        response {
          Users
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
        }
      }
    }
  }
`;

export const useManuallySelectedUsers = (queryOptions) => {
  return useQuery(GET_MANUALLY_SELECTED_USERS, queryOptions);
};
