import { Truncate } from "src/components/common/atoms";
import { NUMBER_REGEX, OPERATOR_REGEX } from "src/constants";
import { classNames } from "src/helpers";
import { TOKEN_TYPE } from "./formulaParser";

const formulaItemStyles = (item) => {
  if (["(", ")"].includes(item)) {
    return {
      styles: {
        backgroundColor: "#E6EC9F",
      },
      type: TOKEN_TYPE.PARENTHESIS,
    };
  }
  if (item.match(OPERATOR_REGEX)) {
    return {
      styles: {
        backgroundColor: "#B4E3C8",
      },
      type: TOKEN_TYPE.OPERATOR,
    };
  }
  if (item.match(NUMBER_REGEX)) {
    return {
      styles: {
        backgroundColor: "#E8EEF3",
      },
      type: TOKEN_TYPE.NUMBER,
    };
  }
  return {
    styles: {
      backgroundColor: "#E3F1FF",
    },
    type: TOKEN_TYPE.VARIABLE,
  };
};

const FormulaItem = ({ item, className }) => {
  const { styles, type } = formulaItemStyles(item);
  return (
    <div
      style={styles}
      className={classNames(["fs-14 px-2 py-1 br-8 m-1", className])}
    >
      {type === TOKEN_TYPE.VARIABLE ? (
        <Truncate text={item} maxWidth="200px" />
      ) : (
        item
      )}
    </div>
  );
};

export default FormulaItem;
