import PropTypes from "prop-types";
import React from "react";
import { classNames } from "src/helpers";
import "./avatar.scss";

const avatarBorderProps = {
  border: PropTypes.bool,
  borderWidth: PropTypes.string,
  borderColor: PropTypes.string,
};
const defaultAvatarBorderProps = {
  border: false,
  borderWidth: "1px",
  borderColor: "black",
};

const avatarBadgeProps = {
  badge: PropTypes.node,
  postition: PropTypes.oneOf([
    "top-start",
    "top-end",
    "bottom-start",
    "bottom-end",
  ]),
};
const defaultAvatarBadgeProps = {
  showBadge: false,
  badge: null,
  position: "bottom-end",
};

const avatarProps = {
  src: PropTypes.node.isRequired,
  className: PropTypes.string,
  borderProps: PropTypes.shape(avatarBorderProps),
  badgeProps: PropTypes.shape(avatarBadgeProps),
};
const defaultAvatarProps = {
  className: "",
  borderProps: defaultAvatarBorderProps,
  badgeProps: defaultAvatarBadgeProps,
};

const Avatar = (props) => {
  const { className, borderProps, src, badgeProps } = props;
  const { border, borderWidth, borderColor } = borderProps;
  const { showBadge, badge, position, badgeClassName } = badgeProps;
  return (
    <div
      style={{
        "--avatar-border-color": borderColor,
        "--avatar-border-width": borderWidth,
      }}
      className={classNames([
        "avatar",
        border ? "avatar-border" : "",
        className,
      ])}
    >
      <div className="avatar-img">{src}</div>
      {showBadge && (
        <div className={classNames(["avatar-badge", position, badgeClassName])}>
          {badge}
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = avatarProps;
Avatar.defaultProps = defaultAvatarProps;

export default Avatar;
