import { formulaValidator as validator } from ".";
import formulaNamespace from "./formulaNamespace";
import { isOperator, isVariable } from "./formulaValidator";

const { TOKEN_TYPE, FORMULA_OPERATORS, FORMULA_OPERATOR_TYPE } =
  formulaNamespace;

const orderOperators = (operators, nextToken) => {
  if (operators.length === 0) {
    return false;
  }
  const lastOperator = operators[operators.length - 1];
  return (
    FORMULA_OPERATORS[lastOperator.value]?.precedence >=
    FORMULA_OPERATORS[nextToken.value]?.precedence
  );
};

const operate = ({ left, operator, right }) => {
  switch (operator) {
    case FORMULA_OPERATOR_TYPE.PLUS:
      return left + right;
    case FORMULA_OPERATOR_TYPE.MINUS:
      return left - right;
    case FORMULA_OPERATOR_TYPE.MULTIPLY:
      return left * right;
    case FORMULA_OPERATOR_TYPE.DIVIDE:
      return left / right;
    case FORMULA_OPERATOR_TYPE.EQUAL:
      return left === right;
    case FORMULA_OPERATOR_TYPE.NOT_EQUAL:
      return left !== right;
    case FORMULA_OPERATOR_TYPE.GREATER_THAN:
      return left > right;
    case FORMULA_OPERATOR_TYPE.LESS_THAN:
      return left < right;
    case FORMULA_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL:
      return left >= right;
    case FORMULA_OPERATOR_TYPE.LESS_THAN_OR_EQUAL:
      return left <= right;
    default:
      break;
  }
};

export const toPostfix = (tokens) => {
  const operators = [];
  const output = [];

  for (let scanner = 0; scanner < tokens.length; scanner++) {
    const currentToken = tokens[scanner];

    if (
      currentToken.tokenType === TOKEN_TYPE.STATIC ||
      isVariable({ token: currentToken })
    ) {
      output.push(currentToken);
      continue;
    }

    if (isOperator({ token: currentToken })) {
      while (orderOperators(operators, currentToken)) {
        output.push(operators.pop());
      }
      operators.push(currentToken);
      continue;
    }

    if (currentToken.value === FORMULA_OPERATOR_TYPE.OPEN_PAREN) {
      operators.push(currentToken);
      continue;
    }

    if (currentToken.value === FORMULA_OPERATOR_TYPE.CLOSE_PAREN) {
      while (
        operators[operators.length - 1].value !==
        FORMULA_OPERATOR_TYPE.OPEN_PAREN
      ) {
        output.push(operators.pop());
      }
      operators.pop();
      continue;
    }
  }
  output.push(...operators.reverse());
  return output;
};

const isOperable = ({ tokens, left, operator, right }) => {
  const tokenError =
    validator.validateToken({
      prevTokens: tokens.filter((token) => token.index < left.index),
      lastToken: left,
      nextToken: operator,
    }) ||
    validator.validateToken({
      prevTokens: tokens.filter((token) => token.index < operator.index),
      lastToken: operator,
      nextToken: right,
    });

  if (tokenError) {
    tokenError.message = `Evaluation Error: ${tokenError.message}`;
    return tokenError;
  }
  return false;
};

const assignIfVariable = (token, weights = []) => {
  if (isVariable({ token }) && typeof token.value === "string") {
    const variableWeightIdx = weights.indexOf(token.value);
    if (variableWeightIdx === -1) {
      weights.push(token.value);
      token.value = weights.length;
    } else {
      token.value = variableWeightIdx + 1;
    }
  }
  return token;
};

export const evaluatePostfix = ({ tokens, handleOperation = operate }) => {
  const stack = [];
  const weights = [];
  for (let scanner = 0; scanner < tokens.length; scanner++) {
    const currentToken = tokens[scanner];
    if (isOperator({ token: currentToken })) {
      let right = stack.pop();
      let left = stack.pop();
      left = assignIfVariable(left, weights);
      right = assignIfVariable(right, weights);
      if (
        isVariable({ token: left }) &&
        right.tokenType === TOKEN_TYPE.STATIC &&
        left.value === right.value
      ) {
        left.value = right.value * (left.value + 1);
      }
      if (
        left.tokenType === TOKEN_TYPE.STATIC &&
        isVariable({ token: right }) &&
        right.value === left.value
      ) {
        right.value = left.value * (right.value + 1);
      }
      const error = isOperable({
        tokens,
        left,
        operator: currentToken,
        right,
      });

      if (error) {
        return error;
      }
      const result = {
        tokenType: TOKEN_TYPE.STATIC,
        value: handleOperation({
          left: left.value,
          operator: currentToken.value,
          right: right.value,
        }),
      };
      if (isVariable({ token: left }) || isVariable({ token: right })) {
        result.tokenType = TOKEN_TYPE.METRIC;
        if (left.value !== right.value) {
          result.value = `${left.value}${currentToken.value}${right.value}`;
        }
      }
      stack.push(result);
      continue;
    }

    stack.push(currentToken);
  }
  return false;
};

const formulaEvaluator = {
  toPostfix,
  evaluatePostfix,
};

export default formulaEvaluator;
