import React from "react";
const SvgIncentivesOnCard = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#p0lq__a)">
        <mask
          id="p0lq__b"
          width={24}
          height={24}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "alpha",
          }}
        >
          <path fill="#D9D9D9" d="M0 0h24v24H0z" />
        </mask>
        <g mask="url(#p0lq__b)">
          <path
            fill="url(#p0lq__c)"
            d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9m.9 13.424v.976h-1.8v-.972c-2.105-.33-2.7-1.802-2.7-2.628h1.8c.01.129.143.9 1.8.9 1.242 0 1.8-.526 1.8-.9 0-.292 0-.9-1.8-.9-3.132 0-3.6-1.692-3.6-2.7 0-1.16.926-2.326 2.7-2.623V6.61h1.8v.998c1.56.369 2.16 1.668 2.16 2.591h-.9l-.9.016c-.013-.342-.194-.916-1.26-.916-1.17 0-1.8.464-1.8.9 0 .337 0 .9 1.8.9 3.132 0 3.6 1.692 3.6 2.7 0 1.16-.926 2.326-2.7 2.624"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="p0lq__c"
          x1={12}
          x2={12}
          y1={3}
          y2={21}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1274F6" />
          <stop offset={0.486} stopColor="#A831F4" />
          <stop offset={1} stopColor="#FCA46E" />
        </linearGradient>
        <clipPath id="p0lq__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
SvgIncentivesOnCard.defaultProps = {
  color: "",
  height: 24,
  width: 24,
};
export default SvgIncentivesOnCard;
