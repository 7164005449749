import { useQuery } from "src/api/client";
import { GET_GOAL_ACHIEVEMENT_DRILLDOWN_QUERY } from "src/api/useGoal";
import { GET_INCENTIVES_ACHIEVEMENT_DRILLDOWN_QUERY } from "src/api/useIncentive";

const useGetAchievementDrilldown = (queryOptions, { isIncentive = false }) => {
  const query = isIncentive
    ? GET_INCENTIVES_ACHIEVEMENT_DRILLDOWN_QUERY
    : GET_GOAL_ACHIEVEMENT_DRILLDOWN_QUERY;
  return useQuery(query, queryOptions);
};

export default useGetAchievementDrilldown;
