import PropTypes from "prop-types";
import React from "react";
import { classNames } from "src/helpers";
import { FormButton } from "../../formElements";
import "./counter.scss";

const Counter = ({
  disabled,
  count,
  maxCount,
  handleMinusButtonClick,
  handleAddButtonClick,
  className,
}) => {
  const minusSignDisabled = count === 1 || disabled;
  const plusSignDisabled = count === maxCount || disabled;
  const signClassName = "flex-center px-2 sign";

  return (
    <div
      className={classNames([
        "center br-4 border-1 bc-grey12 w-92 fs-13 semi-bold bg-white h-32",
        className,
      ])}
    >
      <FormButton
        data-testid="minus-sign"
        className={`${signClassName} br-0 br-l-4 h-100 ${
          minusSignDisabled ? "disabled opacity-50 bg-grey3" : "pointer"
        }`}
        onClick={
          !minusSignDisabled ? () => handleMinusButtonClick() : undefined
        }
        variant="link"
      >
        -
      </FormButton>
      <div className="flex-center fc-secondary-black w-100 h-100 border-left-1 border-right-1 bc-grey12">
        {count}
      </div>
      <FormButton
        data-testid="plus-sign"
        className={`${signClassName} br-0 br-r-4 h-100 ${
          plusSignDisabled ? "disabled opacity-50 bg-grey3" : "pointer"
        }`}
        onClick={!plusSignDisabled ? () => handleAddButtonClick() : undefined}
        variant="link"
      >
        +
      </FormButton>
    </div>
  );
};

Counter.propTypes = {
  count: PropTypes.number,
  maxCount: PropTypes.number,
  handleMinusButtonClick: PropTypes.func,
  handleAddButtonClick: PropTypes.func,
  className: PropTypes.string,
};

export default Counter;
