import { gql, useMutation } from "src/api/client";

const SAVE_MANUAL_SELECTION_MUTATION = gql`
  mutation saveManualUserSelection(
    $SelectedUsers: [ID]
    $DeselectedUsers: [ID]
    $ProgramId: ID!
    $RecurrenceId: ID!
    $AdvancedFilters: JSON
    $UserSelectionType: UserSelectionEnum
  ) {
    saveManualUserSelection(
      SelectedUsers: $SelectedUsers
      DeselectedUsers: $DeselectedUsers
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      AdvancedFilters: $AdvancedFilters
      UserSelectionType: $UserSelectionType
    ) {
      ... on saveManualUserSelectionResponse {
        success
        message
        response
        __typename
      }
    }
  }
`;

export const useSaveManualSelection = () => {
  return useMutation(SAVE_MANUAL_SELECTION_MUTATION);
};
