import { arrayMove } from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Sortable } from "src/components/base";
import { SearchBar, Truncate } from "src/components/common/atoms";
import ColumnItems from "src/components/common/atoms/rearrangeColumns/ColumnItems";
import { FormButton, FormCheck } from "src/components/common/formElements";
import { MOBILE_CONFIGURE_COLUMNS } from "src/constants";
import { isEmpty, isEqual, xor } from "src/helpers";
import { NoGoals } from "src/images";
import { ModalContainer } from "../modalContainer";

const ConfigureColumnContainer = (props) => {
  const {
    show,
    onHide,
    setFilter,
    title,
    usersAttributeList,
    fieldsOrder,
    setFieldsOrder,
    handleDoneButton,
    defaultValue,
  } = props;

  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [initialFields, setInitialFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (!isEmpty(usersAttributeList)) {
      const selectedList = fieldsOrder.map((field) => {
        return {
          id: field,
          Header: usersAttributeList[field]?.DisplayName,
        };
      });
      setInitialFields(selectedList);
      setSelectedFields(selectedList);
      setColumns(
        Object.keys(usersAttributeList).map((field) => {
          return {
            id: field,
            Header: usersAttributeList[field]?.DisplayName,
            isVisible: usersAttributeList[field]?.isVisible,
          };
        })
      );
    }
  }, [fieldsOrder, usersAttributeList]);

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setColumns((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleDoneModal = () => {
    const selectedArr = selectedFields.map((field) => field.id);
    const initialArr = initialFields.map((field) => field.id);
    setFieldsOrder(
      columns.filter((field) => field.isVisible).map((field) => field.id)
    );
    if (
      xor(selectedArr, initialArr).some((field) => !initialArr.includes(field))
    ) {
      handleDoneButton(selectedArr);
    }
    setFilter(!isEqual(selectedArr, defaultValue));
    onHide();
  };

  const selectColumnModal = (id, Header, visible) => {
    const isVisible = !visible;
    setColumns([
      ...columns.map((column) => {
        if (column.id === id) {
          return { ...column, isVisible };
        }
        return column;
      }),
    ]);
    if (isVisible) {
      setSelectedFields([...selectedFields, { id, Header }]);
    } else {
      setSelectedFields(selectedFields.filter((item) => item.id !== id));
    }
  };

  const ConfigureColumnItems = ({ id, Header, isVisible }) => {
    const isGreater =
      !isVisible && selectedFields.length >= MOBILE_CONFIGURE_COLUMNS.MAX;
    const isLess =
      isVisible && selectedFields.length < MOBILE_CONFIGURE_COLUMNS.MIN;

    return (
      <ColumnItems
        id={id}
        activationConstraint={{
          delay: 500,
          tolerance: 5,
        }}
        labelComponent={
          <FormCheck
            id={`${Header}`}
            disabled={isGreater || isLess}
            checked={isVisible}
            label={
              <div className="flex-between">
                <div
                  className={`fs-12 ml-2 ${
                    isGreater || isLess ? "fc-grey" : ""
                  }`}
                >
                  <Truncate
                    id={id}
                    maxWidth="200px"
                    truncType="end"
                    showTooltip={false}
                    text={Header}
                  />
                </div>
              </div>
            }
            onClick={() => selectColumnModal(id, Header, isVisible)}
          />
        }
      />
    );
  };

  const newFilteredColumns =
    columns &&
    columns.filter((column) =>
      column.Header.toLowerCase().includes(searchQuery.toLowerCase())
    );
  return (
    <ModalContainer show={show} onHide={onHide} title={title} disableFooter>
      <div className="px-3 pt-3">
        <SearchBar
          search={searchQuery}
          placeholder={`${t("MANAGED_GOALS.LABELS.SEARCH_FIELD")}...`}
          setSearch={setSearchQuery}
        />
      </div>
      <div className="configure-column-mobile d-flex flex-column w-100 mt-3 px-3">
        <Sortable
          handleDragEnd={handleDragEnd}
          sortableItems={columns.map((item) => item.id)}
        >
          {newFilteredColumns.length > 0 ? (
            newFilteredColumns.map((field, idx) => (
              <ConfigureColumnItems key={idx} {...field} />
            ))
          ) : (
            <div className="flex-center flex-column h-100">
              <NoGoals />
              <div className="fs-13 fc-grey mt-3">No Fields</div>
            </div>
          )}
        </Sortable>
      </div>
      <div className="px-3">
        <div
          className={`w-100 fs-13 my-3 ${
            selectedFields.length >= MOBILE_CONFIGURE_COLUMNS.MAX
              ? "fc-orange"
              : "fc-grey"
          }`}
        >
          <div className="w-100 flex-center">
            {t("MANAGED_GOALS.LABELS.SELECTED")} {selectedFields.length}/
            {MOBILE_CONFIGURE_COLUMNS.MAX}
          </div>
        </div>
        <FormButton
          className="w-100"
          id="select-column-done"
          onClick={handleDoneModal}
          label={t("COMMON.BUTTONS.DONE")}
        />
      </div>
    </ModalContainer>
  );
};

export default ConfigureColumnContainer;
