import React from "react";
import "./Footer.scss";
const Footer = ({ shortCode }) => {
  return (
    <div className="position-fixed bottom-0 fs-12 fc-white bg-black w-100 flex center open-ace-footer">
      Your OAce Account number is <b className="ml-1"> {shortCode}</b>
    </div>
  );
};

export default Footer;
