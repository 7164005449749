import { useEffect } from "react";
import { ENVIRONMENT } from "src/constants";

const useNewRelicScript = () => {
  useEffect(() => {
    const env = process.env.REACT_APP_ENV;
    const script = document.createElement("script");
    try {
      script.type = "text/javascript";
      script.async = true;
      script.id = `new-relic-${env}`;
      script.src = `/scripts/${env}.new-relic.js`;
    } catch (e) {
      console.log("Error While Loading the New Relic Script", e);
    }
    if (
      [
        ENVIRONMENT.OACE_PROD_MUMBAI,
        ENVIRONMENT.PROD_MUMBAI,
        ENVIRONMENT.PROD_SGP,
        ENVIRONMENT.PROD_US,
      ].includes(env)
    ) {
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);
};

export default useNewRelicScript;
