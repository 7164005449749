import React from "react";
import { useRoleBasedAccessQuery } from "src/api/userQuery";
import { LoadingTabs } from "src/components";
import { RoleBasedProvider } from "src/context";
import { useNewRelicScript, usePendoScript, useViewport } from "src/hooks";

const AppRouter = ({
  pendoDetails,
  maxAllowedGoalRules,
  isFeatureEnabled,
  isOpenAceUser,
  isLoggedIn,
  orgCode,
  role,
  userId,
  orgDisplayName,
  orgUniqueName,
  children,
}) => {
  const {
    loading,
    error,
    data: { getRoleBasedAccess } = { getRoleBasedAccess: {} },
  } = useRoleBasedAccessQuery({ skip: isOpenAceUser ? !isLoggedIn : false });
  const { isMobile } = useViewport();
  useNewRelicScript();
  usePendoScript(!isOpenAceUser && pendoDetails);

  if (loading) return <LoadingTabs tabs={isMobile ? 3 : 4} />;
  if (error) return <p>Error</p>;

  return (
    <RoleBasedProvider
      userDetails={getRoleBasedAccess}
      maxAllowedGoalRules={maxAllowedGoalRules}
      isFeatureEnabled={isFeatureEnabled}
      {...{ orgCode, role, userId, orgDisplayName, orgUniqueName }}
    >
      {children}
    </RoleBasedProvider>
  );
};

export default AppRouter;
