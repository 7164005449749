import { gql, useQuery } from "@apollo/client";
import { isEmpty } from "src/helpers";

const userQueryGQL = gql`
  query me {
    me {
      id
      name
      username
      status
      maxAllowedGoalRules
      isFeatureEnabled
    }
  }
`;

export const roleBasedAccess = gql`
  query getRoleBasedAccess {
    getRoleBasedAccess {
      id
      isManagingUsers
      role
      hasPublishedGoal
      isIncentiveApprover
      isLeaderboardApprover
    }
  }
`;
export const GET_PENDODATA = gql`
  query getUserPendoDetails($pendoData: String) {
    getUserPendoDetails(pendoData: $pendoData) {
      message
      pendoConfig
    }
  }
`;

export const CALL_JIRA_API = gql`
  query callJiraAPI {
    callJiraAPI {
      message
    }
  }
`;

export const useGetPendoDataQuery = (pendoData) =>
  useQuery(GET_PENDODATA, {
    variables: { pendoData },
    fetchPolicy: "network-only",
    skip: isEmpty(pendoData),
  });
export const useUserQuery = () => useQuery(userQueryGQL);
export const useRoleBasedAccessQuery = () => useQuery(roleBasedAccess);
export const useJiraQuery = (triggerApi) =>
  useQuery(CALL_JIRA_API, {
    skip: !triggerApi,
  });
