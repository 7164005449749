import React from "react";

const CriteriaConditionsName = ({ conditions }) => {
  return (
    <div className="bg-blue p-3 mt-2">
      {conditions.map((condition, index) => (
        <div>
          {index + 1}. {condition?.Name || `Condition ${index + 1}`}
          {index + 1 < conditions?.length && <hr className="fc-grey1 my-3" />}
        </div>
      ))}
    </div>
  );
};

export default CriteriaConditionsName;
