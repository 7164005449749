import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgLink = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l633__a">
          <path d="M0 0h10v10H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l633__a)">
        <path
          fill={color}
          d="M.185 9.815a.627.627 0 0 1 0-.888l2.688-2.681H.629A.625.625 0 1 1 .629 5h3.749a.632.632 0 0 1 .238.05.62.62 0 0 1 .337.338.632.632 0 0 1 .047.233v3.75a.625.625 0 1 1-1.25 0V7.127L1.073 9.815a.627.627 0 0 1-.888 0Zm6.69-1.065a.625.625 0 0 1 0-1.25H8.75V1.25H2.5v1.875a.625.625 0 1 1-1.25 0v-2.5A.625.625 0 0 1 1.876 0h7.5A.625.625 0 0 1 10 .625v7.5a.625.625 0 0 1-.625.625Z"
          data-name="Union 91"
        />
      </g>
    </svg>
  );
};

SvgLink.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 10,
  width: 10,
};
export default SvgLink;
