export const TEMPLATE_STATUS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Published",
    value: "PUBLISHED",
  },
  {
    label: "Draft",
    value: "DRAFT",
  },
  {
    label: "Unpublished",
    value: "UNPUBLISHED",
  },
];

export const AGGREGATE_FUNCTION_OPTIONS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Count",
    value: "COUNT",
  },
  {
    label: "Sum",
    value: "SUM",
  },
  {
    label: "Distinct Count",
    value: "DISTINCT_COUNT",
  },
  {
    label: "Average",
    value: "AVERAGE",
  },
  {
    label: "Multiple",
    value: "CUSTOM",
  },
];

export const AGGREGATE_FUNCTION = [
  {
    label: "Count",
    value: "COUNT",
    subText: "DROPDOWN.SUBTEXT.AGGREGATE_FUNCTION.COUNT",
  },
  {
    label: "Distinct Count",
    value: "DISTINCT_COUNT",
    subText: "DROPDOWN.SUBTEXT.AGGREGATE_FUNCTION.DISTINCT_COUNT",
  },
  {
    label: "Sum",
    value: "SUM",
    subText: "DROPDOWN.SUBTEXT.AGGREGATE_FUNCTION.SUM",
  },
  {
    label: "Average",
    value: "AVERAGE",
    subText: "DROPDOWN.SUBTEXT.AGGREGATE_FUNCTION.AVERAGE",
  },
];

export const FORMAT_AGGREGATE_FUNCTION = {
  COUNT: {
    displayFormat: "Count",
    valueFormat: "Count",
    upperCaseFormat: "COUNT",
    text: "Countable",
  },
  DISTINCT_COUNT: {
    displayFormat: "Distinct Count",
    valueFormat: "DistinctCount",
    upperCaseFormat: "DISTINCT COUNT",
    text: "Countable",
  },
  SUM: {
    displayFormat: "Sum",
    valueFormat: "Sum",
    upperCaseFormat: "SUM",
    text: "Summable",
  },
  AVERAGE: {
    displayFormat: "Average",
    valueFormat: "Average",
    upperCaseFormat: "AVERAGE",
    text: "Countable",
  },
  CUSTOM: {
    displayFormat: "Custom",
    valueFormat: "Custom",
    upperCaseFormat: "CUSTOM",
    text: "Custom",
  },
};

export const ENTITY_OPTIONS = [
  {
    id: 1,
    title: "Lead",
    tableName: "Prospect_Base",
    subText: "DROPDOWN.SUBTEXT.ENTITY_SELECTION.LEAD",
    isDisabled: false,
    data: [
      {
        id: 1,
        title: "Lead Created or Updated",
        subText: "DROPDOWN.SUBTEXT.RULE_ENTITY_LOCATION.LEAD_CREATED",
        isDisabled: false,
      },
      {
        id: 2,
        title: "Lead Added to the list",
        subText: "DROPDOWN.SUBTEXT.RULE_ENTITY_LOCATION.LEAD_ADDED",
        isDisabled: true,
      },
    ],
  },
  {
    id: 2,
    title: "Activity",
    tableName: "ProspectActivity_Base",
    subText: "DROPDOWN.SUBTEXT.ENTITY_SELECTION.ACTIVITY",
    isDisabled: false,
    data: [
      {
        id: 1,
        title: "Activity on Lead", // never change this as this is used by BE team
        subText: "DROPDOWN.SUBTEXT.RULE_ENTITY_LOCATION.ACTIVITY_ON_LEAD",
        isDisabled: false,
      },
      {
        id: 2,
        title: "Activity on Activity", // never change this as this is used by BE team
        subText: "DROPDOWN.SUBTEXT.RULE_ENTITY_LOCATION.ACTIVITY_ON_ACTIVITY",
        isDisabled: false,
      },
      {
        id: 3,
        title: "Activity on Opportunity", // never change this as this is used by BE team
        subText:
          "DROPDOWN.SUBTEXT.RULE_ENTITY_LOCATION.ACTIVITY_ON_OPPORTUNITY",
      },
    ],
  },
  {
    id: 3,
    title: "Opportunity",
    tableName: "ProspectActivity_Base",
    subText: "DROPDOWN.SUBTEXT.ENTITY_SELECTION.OPPORTUNITY",
    data: [],
  },
  {
    id: 4,
    title: "Task",
    subText: "DROPDOWN.SUBTEXT.ENTITY_SELECTION.TASK",
    tableName: "UserTask_Base",
    isDisabled: true,
    data: [
      {
        id: 1,
        title: "Independent Task", // never change this as this is used by BE team
        subText: "DROPDOWN.SUBTEXT.RULE_ENTITY_LOCATION.INDEPENDENT_TASK",
        isDisabled: false,
      },
      {
        id: 2,
        title: "Task on Lead", // never change this as this is used by BE team
        subText: "DROPDOWN.SUBTEXT.RULE_ENTITY_LOCATION.TASK_ON_LEAD",
        isDisabled: false,
      },
      {
        id: 3,
        title: "Task on Opportunity", // never change this as this is used by BE team
        subText: "DROPDOWN.SUBTEXT.RULE_ENTITY_LOCATION.TASK_ON_OPPORTUNITY",
      },
    ],
  },

  {
    id: 5,
    title: "User",
    subText: "DROPDOWN.SUBTEXT.ENTITY_SELECTION.USER",
    comingSoon: true,
    isDisabled: true,
  },
];

export const ALLOWED_GOAL_RULES = 20;

export const ALLOWED_CUSTOM_FORMULA_GOAL_RULES = 10;

export const CONDITION_LIMIT = 30;

export const SALES_ACTIVITY_EVENT = "30";

export const ADDITIONAL_USER_FIELDS = ["OwnerId", "CreatedBy", "ModifiedBy"];

export const ADDITIONAL_DEFAULT_FIELDS = [
  {
    SchemaName: "CreatedBy",
    DisplayName: "Created By",
    DataType: "Text",
    RenderTypeTextValue: "Textbox",
    Options: null,
    FieldProperties: null,
    isCustomField: false,
    Disabled: false,
    customId: "Created_By_Id_CreatedBy",
  },
  {
    SchemaName: "CreatedOn",
    DisplayName: "Created On",
    DataType: "Date",
    RenderTypeTextValue: "Datetime",
    Options: null,
    FieldProperties: null,
    Disabled: false,
    isCustomField: false,
    customId: "Created_On_CreatedOn",
  },
  {
    SchemaName: "ModifiedBy",
    DisplayName: "Modified By",
    DataType: "Text",
    RenderTypeTextValue: "Textbox",
    Options: null,
    FieldProperties: null,
    Disabled: false,
    isCustomField: false,
    customId: "Modified_By_Id_ModifiedBy",
  },
  {
    SchemaName: "ModifiedOn",
    DisplayName: "Modified On",
    DataType: "Date",
    RenderTypeTextValue: "Datetime",
    Options: null,
    FieldProperties: null,
    Disabled: false,
    isCustomField: false,
    customId: "Modified_On_Modified",
  },
];

export const ADDITIONAL_ACTIVITY_FIELDS = [
  {
    SchemaName: "ProspectActivityId",
    DisplayName: "Prospect Activity ID",
    DataType: "Text",
    RenderTypeTextValue: "Textbox",
    Options: null,
    FieldProperties: null,
    Disabled: false,
    isCustomField: false,
    customId: "Activity_ProspectActivityID_ProspectActivityId",
  },
  {
    SchemaName: "RelatedProspectId",
    DisplayName: "Related Prospect ID",
    DataType: "Text",
    RenderTypeTextValue: "Textbox",
    Options: null,
    FieldProperties: null,
    Disabled: false,
    isCustomField: false,
    customId: "RelatedProspectIDRelated Prospect ID",
  },
  ...ADDITIONAL_DEFAULT_FIELDS,
];

export const ADDITIONAL_OPPORTUNITY_FIELDS = [
  {
    SchemaName: "ProspectActivityId",
    DisplayName: "Opportunity ID",
    DataType: "Text",
    RenderTypeTextValue: "Textbox",
    Options: null,
    FieldProperties: null,
    Disabled: false,
    isCustomField: false,
    customId: "Activity_ProspectActivityID_ProspectActivityId",
  },
  {
    SchemaName: "RelatedProspectId",
    DisplayName: "Related Prospect ID",
    DataType: "Text",
    RenderTypeTextValue: "Textbox",
    Options: null,
    FieldProperties: null,
    Disabled: false,
    isCustomField: false,
    customId: "RelatedProspectIDRelated Prospect ID",
  },
];

export const MULTISELECT_OPPORTUNITY_FIELDS = {
  Aggregation_Fields: [
    "Status",
    "Owner",
    "mx_Custom_1",
    "mx_Custom_3",
    "mx_Custom_5",
    "mx_Custom_6",
    "mx_Custom_7",
    "mx_Custom_8",
    "mx_Custom_9",
    "mx_Custom_10",
    "mx_Custom_11",
    "ModifiedOn",
    "ModifiedBy",
    "CreatedOn",
    "CreatedBy",
    "ProspectActivityId",
    "RelatedProspectId",
  ],
  User_Fields: ["Owner", "ModifiedBy", "CreatedBy"],
  Date_Fields: ["ModifiedOn", "CreatedOn", "mx_Custom_9", "mx_Custom_8"],
};

export const ALLOWED_INCENTIVE_RULESETS = 5;

export const RULESET_TYPES = {
  STANDARD: "STANDARD",
  ADVANCED: "ADVANCED",
};

export const DATE_ENTITY_THIS_CYCLE = "$thiscycle$";

export const RULE_NOT_USED = "RULE_NOT_USED";

export const RULESET_TYPE_OPTIONS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Standard Ruleset",
    value: "STANDARD",
    description: "RULEBOOK.MESSAGES.STANDARD_RULESET_DESCRIPTION",
  },
  {
    label: "Advanced Ruleset",
    value: "ADVANCED",
    description: "RULEBOOK.MESSAGES.ADVANCED_RULESET_DESCRIPTION",
  },
];
