import React, { useState } from "react";
import { DATE_FORMAT_PAYLOAD } from "src/constants";
import { format, getTodayAsPerTimezone, isEqual } from "src/helpers";
import { FilterAccordion } from "../filterContainer";
import { ModalContainer } from "../modalContainer";
import CalendarSelection from "./CalendarSelection";

const CalendarContainer = (props) => {
  const { show, onHide, options, filter, title, setFilter } = props;
  const [selected, setSelected] = useState(filter);
  const today = getTodayAsPerTimezone();

  const handleOnChange = ({ key, value }) => {
    setSelected((prevState) => {
      if (value.value === "CUSTOM") {
        return {
          ...prevState,
          [key]: {
            ...value,
            startsAt: filter[options[0].key]?.startsAt
              ? format(
                  new Date(filter[options[0].key].startsAt),
                  DATE_FORMAT_PAYLOAD
                )
              : format(today, DATE_FORMAT_PAYLOAD),
            endsAt: filter[options[0].key]?.endsAt
              ? format(
                  new Date(filter[options[0].key].endsAt),
                  DATE_FORMAT_PAYLOAD
                )
              : format(today, DATE_FORMAT_PAYLOAD),
          },
        };
      }
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const onApplyFilterClick = () => {
    setFilter(selected);
    onHide();
  };

  const getFilterValues = () => {
    const defaultFilters = {};
    const selectedFilters = {};
    const appliedFilterValues = {};
    options.forEach((option) => {
      selectedFilters[option.key] = selected[option.key];
      defaultFilters[option.key] = option.defaultValue;
      appliedFilterValues[option.key] = filter[option.key];
    });
    return {
      defaultFilters,
      selectedFilters,
      appliedFilterValues,
    };
  };
  const filterData = getFilterValues();
  const onResetClick = () => {
    setSelected((prevState) => {
      return {
        ...prevState,
        ...filterData.defaultFilters,
      };
    });
  };

  const disableResetButton = isEqual(
    filterData.defaultFilters,
    filterData.selectedFilters
  );

  const disableApplyFilterClick = isEqual(
    filterData.selectedFilters,
    filterData.appliedFilterValues
  );

  return (
    <ModalContainer
      show={show}
      onHide={onHide}
      title={title}
      onApplyFilterClick={onApplyFilterClick}
      disableApplyFilterClick={disableApplyFilterClick}
      onResetClick={onResetClick}
      disableReset={disableResetButton}
    >
      <FilterAccordion
        {...{
          options,
          filter: selected,
          handleOnChange,
        }}
      >
        {selected[options[0].key].value === "CUSTOM" && (
          <CalendarSelection
            selected={selected}
            customKey={options[0].key}
            today={today}
            setSelected={setSelected}
          />
        )}
      </FilterAccordion>
    </ModalContainer>
  );
};

export default CalendarContainer;
