import { gql, useQuery } from "src/api/client";

export const GET_SUB_GOAL_FILTERS = gql`
  query getSubGoalFilters($RecurrenceId: ID!) {
    getSubGoalFilters(RecurrenceId: $RecurrenceId) {
      ... on SubGoalFiltersResponse {
        response {
          Entity
          Values
        }
      }
    }
  }
`;

export const useGetSubGoalFilters = (queryOptions) => {
  return useQuery(GET_SUB_GOAL_FILTERS, queryOptions);
};
