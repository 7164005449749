import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormButton } from "src/components";
import { MAX_DUPLICATE_INCENTIVE_CONDITIONS } from "src/constants";
import { isEmpty, range } from "src/helpers";
import { SvgEdit } from "src/icons";
import { ErrorModal } from "./ErrorModal";
import "./incentivesRuleConfig.scss";

const ConditionPatternConfig = ({
  condition,
  conditionPattern,
  setConditionPattern,
  disabled,
}) => {
  const [conditions, setconditionData] = useState([]);
  const [errorModal, showErrorModal] = useState(false);
  const [pattern, setPattern] = useState(conditionPattern || "(1)");
  const [editAllowed, setEditAllowed] = useState(true);
  const [lastUpdatedPattern, setLastUpdatedPattern] = useState(
    conditionPattern || "(1)"
  );
  const [error, setError] = useState([]);
  const [initialPattern, setInitialPattern] = useState(
    conditionPattern || null
  );
  const { t } = useTranslation();
  const createPattern = useCallback((data) => {
    return repeatChar(data.length, " OR ");
  }, []);

  useEffect(() => {
    if (error.length === 0) {
      if (condition.length > 0) {
        setconditionData(condition);
        editAllowed && setConditionPattern(pattern);
      }
    }
  }, [
    condition,
    condition.length,
    createPattern,
    error.length,
    setConditionPattern,
    pattern,
    editAllowed,
  ]);

  useEffect(() => {
    if (initialPattern !== conditionPattern) {
      setPattern(createPattern(conditions));
      setLastUpdatedPattern(createPattern(conditions));
    } else {
      return () => {
        setInitialPattern(null);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions, setPattern, createPattern]);

  useEffect(() => {
    if (editAllowed) {
      setConditionPattern(pattern);
    }
  }, [pattern, editAllowed, conditionPattern, setConditionPattern]);

  const handleEdit = () => {
    setConditionPattern("");
    setEditAllowed(!editAllowed);
  };

  const repeatChar = (count, ch) => {
    let txt = "(";
    for (let i = 1; i < count + 1; i++) {
      txt += i;
      if (i < count) {
        txt += ch;
      }
    }
    txt += ")";
    return txt;
  };

  const inputChange = (e) => {
    const format = /[!@#$%^&*_+\-=;':"\]}><[{\\|,./?]+/g;
    e = e.replace(format, ""); // # removed special characters
    setPattern(e);
    if (conditionPattern) {
      setConditionPattern("");
    }
  };

  const onInputCancel = () => {
    setEditAllowed(true);
    setPattern(lastUpdatedPattern);
    setConditionPattern(lastUpdatedPattern);
  };

  const onUpdatePattern = () => {
    const e = pattern;
    const error = [];
    if (isEmpty(e.trim())) {
      error.push(t("RULEBOOK.ERRORS.ERROR_1"));
    } else {
      const errConditions = errorConditions(e);

      if (errConditions.missing) {
        error.push(t("RULEBOOK.ERRORS.ERROR_2"));
      }
      if (errConditions.duplicate) {
        error.push(t("RULEBOOK.ERRORS.ERROR_3"));
      }
      if (errConditions.invalid) {
        error.push(t("RULEBOOK.ERRORS.ERROR_4"));
      }
      if (!isBalanced(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_5"));
      }
      if (specialCharacter(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_6"));
      }
      if (allowedOperator(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_7"));
      }
      if (operatorMisplaced(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_8"));
      }
      if (invalidFormat(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_9"));
      }
    }
    setError(error);

    if (error.length > 0) {
      showErrorModal(true);
      setConditionPattern("");
      return;
    }
    const modifiedPattern = addSpace(pattern)
      .trim()
      .toUpperCase()
      .replace(/\s{2,}/g, " ");
    setPattern(modifiedPattern);
    setConditionPattern(modifiedPattern);
    setEditAllowed(!editAllowed);
    setLastUpdatedPattern(modifiedPattern);
  };

  const addSpace = (e) => {
    const addSpaceNumber = e.replace(/([0-9]+)/g, " $1 ");
    const addSpaceAND = addSpaceNumber.replace(/(AND)/g, " $1 ");
    const addSpaceOR = addSpaceAND.replace(/(OR)/g, " $1 ");
    return addSpaceOR;
  };

  const allowedOperator = (e) => {
    const uppercase = e.toUpperCase();
    const removeOperators = uppercase.replace(/(?:AND|OR)/g, "");
    const removeBracketAndDigit = removeOperators.replace(/[()\d]/g, "");
    const removeSpace = removeBracketAndDigit.replace(/ /g, "");
    if (!isEmpty(removeSpace)) return true;
    return false;
  };

  const operatorMisplaced = (e) => {
    const format = /\s*(?:AND|OR)\s*(?:AND|OR)\s*/g;
    const matches = e.trim().toUpperCase().replace(/[()]/g, "");
    if (format.test(matches)) return true;
    return false;
  };

  const specialCharacter = (e) => {
    const format = /[!@#$%^&*_+\-=;':"\]}><[{\\|,./?]+/g;
    if (format.test(e)) return true;
    return false;
  };

  const configuredConditions = (e) => {
    const removeBracket = e.trim().replace(/()/g, "");
    const matchingArray =
      !isEmpty(removeBracket) && removeBracket.match(/\d+/g)?.map(Number);
    return matchingArray || [];
  };

  const errorConditions = (e) => {
    const conditionObj = { missing: false, invalid: false, duplicate: false };
    const conditionArr = configuredConditions(e);
    const n = conditions.length;
    let count = 0;
    let duplicateCount = 1;

    conditionArr.sort((a, b) => a - b);

    if (conditionArr[0] > n || conditionArr[0] === 0)
      conditionObj.invalid = true;
    else count++;

    for (const i of range(1, conditionArr.length)) {
      if (conditionArr[i] > n || conditionArr[i] === 0)
        conditionObj.invalid = true;
      if (conditionArr[i] === conditionArr[i - 1]) duplicateCount++;
      else if (conditionArr[i] <= n) count++;
    }

    if (count < n) conditionObj.missing = true;
    if (duplicateCount >= MAX_DUPLICATE_INCENTIVE_CONDITIONS)
      conditionObj.duplicate = true;
    return conditionObj;
  };

  const isBalanced = (input) => {
    const brackets = "()";
    const stack = [];
    for (const bracket of input) {
      const bracketsIndex = brackets.indexOf(bracket);
      if (bracketsIndex === -1) {
        continue;
      }
      if (bracketsIndex % 2 === 0) {
        stack.push(bracketsIndex + 1);
      } else if (stack.pop() !== bracketsIndex) {
        return false;
      }
    }
    return stack.length === 0;
  };

  const invalidFormat = (e) => {
    const str = e.trim().toUpperCase();
    const reg1 = /(?:AND|OR)\s{0,}\)/g; // # AND/OR )
    const reg2 = /\(\s{0,}(?:AND|OR)/g; // # ( AND/OR
    const reg3 = /(?:AND|OR)\s{0,}$/g; // # AND/OR at the end
    const reg4 = /\(\s{0,}\)/g; // # Empty Parenthesis
    const reg5 = /[0-9]+(?:\s{1,}|\s{0,}\(\s{0,}|\s{0,}\)\s{0,})[0-9]+/; // # two numbers together or joined by space,(,)
    if (
      str.match(reg1) ||
      str.match(reg2) ||
      str.match(reg3) ||
      str.match(reg4) ||
      str.match(reg5)
    )
      return true;
    return false;
  };

  return (
    <div>
      {conditions.length > 1 ? (
        <div className="form-group fs-13 flex-column">
          <div className="center">
            <input
              value={pattern}
              disabled={editAllowed}
              type="text"
              id="criteriaInput"
              className="form-control footer-input shadow-none fs-12"
              onChange={(e) => inputChange(e.target.value)}
            />
            {!disabled && editAllowed && (
              <FormButton
                variant="link"
                id="edit-pattern-btn"
                onClick={() => handleEdit()}
                className="ml-2"
                icon={<SvgEdit />}
                label={`${t("COMMON.BUTTONS.EDIT")} ${t(
                  "RULEBOOK.LABELS.PATTERN"
                )}`}
              />
            )}
            {!editAllowed && (
              <>
                <FormButton
                  variant="link"
                  id="update-pattern-btn"
                  onClick={(e) => onUpdatePattern(e)}
                  label={t("COMMON.BUTTONS.UPDATE")}
                  className="px-3"
                />
                <FormButton
                  variant="link"
                  id="cancel-pattern-btn"
                  onClick={() => onInputCancel()}
                  label={t("COMMON.BUTTONS.CANCEL")}
                />
              </>
            )}
          </div>
          <ErrorModal
            errorModal={errorModal}
            showErrorModal={showErrorModal}
            errors={error}
            cancelButtonOff
          />
        </div>
      ) : null}
    </div>
  );
};

export default ConditionPatternConfig;
