import { gql, useQuery } from "src/api/client";

const GET_APPROVAL_LIST_QUERY = gql`
  query programsForApproval(
    $after: String
    $limit: Float
    $query: JSON
    $FeatureType: String!
  ) {
    programsForApproval(
      after: $after
      limit: $limit
      query: $query
      FeatureType: $FeatureType
    ) {
      ... on ErrorResponse {
        message
        __typename
      }
      ... on ProgramsForApprovalResponse {
        success
        message
        response {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            __typename
          }
          programs {
            ProgramId
            Name
            Status
            InGaps
            RecurrenceDefinition {
              FirstRecurrenceStartDate
              RecurrencePeriod
              RecurrenceCycleDuration
              CustomType
              CustomGap
              LastRecurrenceEndDate
              __typename
            }
            Type
            UpdatedAt
            RecurrencesCount
            RecurrencesCompleted
            FirstRecurrenceRange {
              RecurrenceStartDate
              RecurrenceEndDate
              __typename
            }
            LastRecurrenceRange {
              RecurrenceStartDate
              RecurrenceEndDate
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const useGetApprovalList = (queryOptions) => {
  return useQuery(GET_APPROVAL_LIST_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
