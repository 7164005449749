import React from "react";
import { COLORS } from "src/constants";

const SvgFormulaCondition = (props) => {
  const {
    color = COLORS.PRIMARY_BLUE,
    height = 20,
    width = 20,
    ...rest
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="vxu86__a">
          <path d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#vxu86__a)">
        <path fill="none" d="M.001 0h20v20h-20z" />
        <path
          fill={color}
          d="M11.663.958a2.3 2.3 0 0 0-1.285-.345 1.526 1.526 0 0 0-.376.031A4.247 4.247 0 0 0 7.15 3.308c-.251.564-.47 1.16-.69 1.724-.094.282-.219.533-.313.815a2.979 2.979 0 0 1-.188.564h-1.41a.345.345 0 1 0 0 .69h1.222l-.69 2.946c-.69 3.291-1.63 7.052-1.849 7.711-.219.69-.533 1.034-.972 1.034a.453.453 0 0 1-.219-.063.172.172 0 0 1-.094-.157.51.51 0 0 1 .125-.282.689.689 0 0 0 .125-.407.806.806 0 0 0-.846-.846 1 1 0 0 0-.627.251 4.282 4.282 0 0 0-.125.721 1.261 1.261 0 0 0 .47.972 1.779 1.779 0 0 0 1.254.407 2.811 2.811 0 0 0 2.163-1.034 5.521 5.521 0 0 0 .815-1.379 28.96 28.96 0 0 0 1.473-4.858q.611-2.492 1.128-4.984H9.25a.345.345 0 1 0 0-.69H7.998a25.321 25.321 0 0 1 1.63-4.639q.376-.658.846-.658c.125 0 .188.031.219.094a.53.53 0 0 1 .063.157.791.791 0 0 1-.125.282.885.885 0 0 0-.125.47.785.785 0 0 0 .251.564.833.833 0 0 0 1.191 0 .9.9 0 0 0 .251-.658 1.254 1.254 0 0 0-.536-1.097Zm6.269 7.9a1.57 1.57 0 0 0 1.473-1.724c0-1.316-.94-1.379-1.254-1.379-.564 0-1.16.407-1.661 1.285s-1.066 1.881-1.066 1.881h-.031c-.125-.627-.219-1.128-.282-1.379a2.166 2.166 0 0 0-2.037-1.724 5.343 5.343 0 0 0-2.508.752.764.764 0 0 0-.376.658.776.776 0 0 0 .784.784.632.632 0 0 0 .345-.094s1-.564 1.191 0c.063.157.125.345.188.533.251.846.47 1.849.69 2.758l-.79 1.28a6.31 6.31 0 0 0-1.473-.345 1.57 1.57 0 0 0-1.473 1.724c0 1.316.94 1.379 1.254 1.379.564 0 1.16-.407 1.661-1.285s1.066-1.881 1.066-1.881a14.359 14.359 0 0 0 .407 1.724 2.063 2.063 0 0 0 2.069 1.473 4.891 4.891 0 0 0 2.225-.69.822.822 0 0 0 .5-.721.776.776 0 0 0-.784-.784.632.632 0 0 0-.345.094s-.846.47-1.16.094a6.782 6.782 0 0 1-.533-1.6c-.125-.6-.282-1.285-.407-1.943l.878-1.254a4.232 4.232 0 0 0 1.448.383Z"
        />
      </g>
    </svg>
  );
};

export default SvgFormulaCondition;
