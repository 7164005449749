import {
  AUTH_URL,
  CLIENT_ID,
  CODE_CHALLENGE_METHOD,
  REDIRECT_URI,
} from "src/constants";
import {
  calculateSHA256,
  generateRandomState,
  generateRandomVerifier,
  redirectToUrl,
} from "src/helpers";

const constructAuthUrl = (challenge) => {
  const state = generateRandomState();
  localStorage.setItem("login_state", state);
  const authUrl = new URL(AUTH_URL);
  const queryParams = new URLSearchParams({
    response_type: "code",
    client_id: CLIENT_ID,
    state,
    redirect_uri: encodeURI(REDIRECT_URI),
    code_challenge: challenge,
    code_challenge_method: CODE_CHALLENGE_METHOD,
    scope: "openid+profile",
  });
  authUrl.search = queryParams.toString();
  return authUrl;
};

export const login = () => {
  // Generate a random code verifier
  const verifier = generateRandomVerifier();
  localStorage.setItem("code_verifier", verifier);

  // Calculate the code challenge using SHA-256
  const challenge = calculateSHA256(verifier);

  // Construct the authorization URL
  const authUrl = constructAuthUrl(challenge);

  // Redirect to the authorization URL
  redirectToUrl(authUrl);
};
