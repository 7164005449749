import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgLeafUserNoHierarchy = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 8714">
        <path fill="none" d="M0 0h16v16H0Z" data-name="Path 4790" />
        <g data-name="Group 8705">
          <path fill="none" d="M0 0h16v16H0z" data-name="Rectangle 2966" />
          <g data-name="351983_error_icon">
            <path fill="none" d="M3 3h10v10H3Z" data-name="Path 4785" />
            <g fill="none" data-name="Subtraction 36">
              <path d="M7.999 3.667A4.333 4.333 0 1 0 12.332 8a4.333 4.333 0 0 0-4.333-4.333Z" />
              <path
                fill={color}
                d="M7.999 5.167A2.836 2.836 0 0 0 5.166 8 2.836 2.836 0 0 0 8 10.833 2.836 2.836 0 0 0 10.832 8a2.836 2.836 0 0 0-2.833-2.833m0-1.5a4.333 4.333 0 1 1 0 8.666 4.333 4.333 0 0 1 0-8.666Z"
              />
            </g>
            <path
              fill={color}
              d="m10.098 5.634 1 1.732-5.196 3-1-1.732z"
              data-name="Rectangle 3691"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgLeafUserNoHierarchy.defaultProps = {
  color: COLORS.RED,
  height: 16,
  width: 16,
};
export default SvgLeafUserNoHierarchy;
