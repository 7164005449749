import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgDownload = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M6.755.702v4.981l1.189-1.106a.8.8 0 0 1 1.066 0 .666.666 0 0 1 0 .991l-2.477 2.3a.779.779 0 0 1-1.057 0l-2.486-2.3a.665.665 0 0 1 0-.991.8.8 0 0 1 1.066 0l1.19 1.106V.702a.757.757 0 0 1 1.509 0Zm4.322 9.453a.923.923 0 1 1 0 1.846H.923a.923.923 0 1 1 0-1.846Z"
        data-name="Union 38"
      />
    </svg>
  );
};

SvgDownload.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 12,
  width: 12,
};
export default SvgDownload;
