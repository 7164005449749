import { Truncate } from "src/components/common/atoms";

const DefaultCell = ({ value, column, row: { original } }) => {
  const newValue = column.formatValue ? column.formatValue(original) : value;
  return (
    <div className="fs-12 regular default-cell">
      {newValue ? (
        <Truncate text={newValue} maxWidth={`${column.minWidth - 24}px`} />
      ) : (
        "- -"
      )}
    </div>
  );
};

export default DefaultCell;
