import React from "react";
const SvgCopyModern = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 11 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M3.882 11.2a1.2 1.2 0 0 1-.914-.411 1.405 1.405 0 0 1-.38-.989V1.4c0-.385.127-.715.38-.989C3.222.137 3.526 0 3.882 0h5.824c.356 0 .66.137.914.411.253.274.38.604.38.989v8.4c0 .385-.127.715-.38.989a1.201 1.201 0 0 1-.914.411zm0-1.4h5.824V1.4H3.882zM1.294 14a1.2 1.2 0 0 1-.914-.411A1.405 1.405 0 0 1 0 12.6V2.8h1.294v9.8h7.118V14z"
      />
    </svg>
  );
};
SvgCopyModern.defaultProps = {
  color: "#A0AEC0",
  height: 14,
  width: 11,
};
export default SvgCopyModern;
