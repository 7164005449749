import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { LoadingTable } from "../loadingTable";
import "./loadingDistribution.scss";

const LoadingDistribution = () => (
  <div>
    <div className="page-container mt-4 flex-between mx-5">
      <div className="pt-23 w-25">
        <Shimmer width="250px" height="10px" />
        <div className="mt-2 center">
          <div className="w-50">
            <Shimmer width="100px" height="30px" />
          </div>
          <div className="w-50">
            <Shimmer width="100px" height="30px" />
          </div>
        </div>
      </div>
      <div className="shim-box pt-23 pb-23 pl-3 pr-3 center">
        <Shimmer width="300px" height="50px" />
        <div className="ml-3">
          <Shimmer width="100px" height="50px" />
        </div>
      </div>
    </div>
    <div className="mt-3 mx-5">
      <LoadingTable />
    </div>
  </div>
);

export default LoadingDistribution;
