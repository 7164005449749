import React from "react";
import { NonMonetaryLabel, StatsItem } from "src/components";
import { classNames } from "src/helpers";
import "./amountStats.scss";

const AmountStats = ({
  className,
  title,
  amount,
  Icon,
  rewards,
  rewardCount,
  rewardCategoryList,
  nonMonetaryClassName,
  payloadForRewardsList,
}) => {
  return (
    <StatsItem title={title} className={classNames(["amount", className])}>
      <div className="center mt-1 d-flex align-items-start">
        <div className="pt-1">{Icon}</div>
        <div className="fs-18 bold">
          <NonMonetaryLabel
            amount={amount}
            nonMonetaryRewards={rewards || []}
            rewardCount={rewardCount}
            rewardCategoryList={rewardCategoryList || false}
            nonMonetaryClassName={nonMonetaryClassName}
            payload={payloadForRewardsList}
            rewardModalTitle={title}
          />
        </div>
      </div>
    </StatsItem>
  );
};

export default AmountStats;
