import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Overlay from "react-bootstrap/Overlay";
import { FormButton } from "src/components";
import { CalendarHeader, CustomInput } from "src/components/common/atoms";
import { DATE_FORMAT_WITH_WEEKDAY } from "src/constants";
import {
  addDays,
  addMonths,
  format,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  nthDayOfMonth,
  startOfDay,
  startOfMonth,
  subMonths,
} from "src/helpers";

const WeeklyDropdown = (props) => {
  const {
    today,
    selected,
    disabled,
    onChange,
    minDate,
    maxDate,
    id,
    inputId,
    popperId,
    inputFormat,
    dateStart,
    dateEnd,
    openToDate,
    placeholder,
  } = props;

  const [currMonth, setCurrMonth] = useState(today);
  const [showPopover, setShowPopover] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const target = useRef(null);

  useEffect(() => {
    const weeks = [];
    let firstMonday = nthDayOfMonth(currMonth, 1);
    const month = getMonth(firstMonday);

    while (month === getMonth(firstMonday)) {
      weeks.push({
        startDate: firstMonday,
        endDate: addDays(firstMonday, 6),
        isDisabled:
          isBefore(startOfDay(firstMonday), startOfDay(minDate)) ||
          isAfter(startOfDay(firstMonday), startOfDay(maxDate)),
      });
      firstMonday = addDays(firstMonday, 7);
    }
    setWeeks(weeks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currMonth]);

  useEffect(() => {
    if (isValid(selected)) {
      setCurrMonth(selected);
    } else {
      setCurrMonth(today);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  const prevYearButtonDisabled = getYear(currMonth) < getYear(minDate);
  const nextYearButtonDisabled = getYear(currMonth) > getYear(maxDate);
  const prevMonthButtonDisabled = isBefore(
    subMonths(startOfMonth(currMonth), 1),
    startOfMonth(minDate)
  );
  const nextMonthButtonDisabled = isAfter(
    addMonths(startOfMonth(currMonth), 1),
    startOfMonth(maxDate)
  );

  return (
    <>
      <div id={id} className="react-datepicker-wrapper">
        <div className="react-datepicker__input-container">
          <CustomInput
            inputId={inputId}
            dateStart={dateStart}
            dateEnd={dateEnd}
            inputFormat={inputFormat}
            isDisabled={disabled}
            className={`cal-input fs-13 ${
              showPopover ? "react-datepicker-ignore-onclickoutside" : ""
            }`}
            ref={target}
            onClick={() => setShowPopover(true)}
            recurrencePeriod="Weekly"
            placeholderText={placeholder}
          />
        </div>
      </div>
      {showPopover && (
        <div className="react-datepicker__tab-loop">
          <div className="react-datepicker__tab-loop__start" tabIndex="0" />
          <Overlay
            target={target.current}
            show={showPopover}
            transition={false}
            placement="bottom-start"
            rootClose
            onExited={() => setShowPopover(false)}
            onHide={() => setShowPopover(false)}
            onToggle={() => setShowPopover((prev) => !prev)}
          >
            {(props) => {
              const { ref, style, placement } = props;
              return (
                <div
                  id={popperId}
                  data-placement={placement}
                  style={style}
                  className="react-datepicker-popper"
                  ref={ref}
                >
                  <div className="react-datepicker">
                    <div className="react-datepicker__month-container">
                      <div className="react-datepicker__header react-datepicker__header--custom">
                        <CalendarHeader
                          date={currMonth}
                          today={today}
                          prevYearButtonDisabled={prevYearButtonDisabled}
                          nextYearButtonDisabled={nextYearButtonDisabled}
                          prevMonthButtonDisabled={prevMonthButtonDisabled}
                          nextMonthButtonDisabled={nextMonthButtonDisabled}
                          setCurrMonth={setCurrMonth}
                          maxDate={maxDate}
                          openToDate={openToDate}
                        />
                      </div>
                      <div
                        className="react-datepicker__month react-datepicker__weekPicker row"
                        aria-label="month 2022-05"
                        role="listbox"
                      >
                        {weeks.length > 0 &&
                          weeks.map((week, idx) => (
                            <div
                              key={idx}
                              className="react-datepicker__week-wrapper col-6 my-2"
                            >
                              <FormButton
                                className={`react-datepicker__week-text p-1 text-center ${
                                  week?.isDisabled
                                    ? "react-datepicker__week-text--disabled cursor-default"
                                    : "cursor-pointer"
                                } ${
                                  isSameDay(week?.startDate, selected)
                                    ? "react-datepicker__week-text--selected"
                                    : ""
                                }`}
                                onClick={
                                  !week?.isDisabled
                                    ? () => {
                                        if (onChange) onChange(week?.startDate);
                                        setShowPopover(false);
                                      }
                                    : undefined
                                }
                                variant="normal-link"
                              >
                                {`${format(week.startDate, "MMM d")} - ${format(
                                  week.endDate,
                                  "MMM d"
                                )}`}
                              </FormButton>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Overlay>
          <div className="react-datepicker__tab-loop__end" tabIndex="0" />
        </div>
      )}
    </>
  );
};

WeeklyDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  popperId: PropTypes.string.isRequired,
  selected: PropTypes.instanceOf(Date),
  today: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  dateStart: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  inputFormat: PropTypes.string,
  onChange: PropTypes.func,
};

WeeklyDropdown.defaultProps = {
  id: "weekly-dropdown",
  inputId: "weekly-dropdown-input",
  popperId: "weekly-dropdown-popper",
  today: new Date(),
  disabled: false,
  inputFormat: DATE_FORMAT_WITH_WEEKDAY,
};

export default WeeklyDropdown;
