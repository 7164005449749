import { gql, useQuery } from "src/api/client";

const GET_MY_SHOWCASE_LIST = gql`
  query getMyShowcaseList($ProgramId: ID!) {
    getMyShowcaseList(ProgramId: $ProgramId) {
      ... on MyShowcaseListResponse {
        success
        response {
          myShowcaseList {
            BadgeType
            Count
          }
          totalCount
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetMyShowcaseList = (queryOptions) => {
  return useQuery(GET_MY_SHOWCASE_LIST, queryOptions);
};
