import React, { lazy } from "react";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

const WebPage = lazy(() =>
  import(
    /* webpackChunkName: 'incentives.web.publishedIncentiveViewPage' */ "./web"
  )
);

export const PublishedIncentiveViewPage = {
  web: WebPage,
  mobile: () => <Redirect to={APPS.INCENTIVES.ROUTES.LANDING.path} />,
  meta: {
    title: "Published",
  },
};
