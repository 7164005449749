import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgPublish = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill="none" d="M.001 0h20v20h-20z" data-name="Rectangle 2324" />
      <path fill="none" d="M.003 3.001h14v14h-14Z" data-name="Path 799" />
      <path
        fill={color}
        d="m13.864 15.579-2-2.406a1.202 1.202 0 1 1 1.847-1.539l1.081 1.3 3.087-3.7a1.2 1.2 0 1 1 1.842 1.534l-4.01 4.812a1.2 1.2 0 0 1-1.847 0Zm-4.242.433H4.201a4.214 4.214 0 0 1-4.2-4.152 4.2 4.2 0 0 1 4.152-4.248 5.99 5.99 0 0 1 5.493-3.6 5.918 5.918 0 0 1 .632.033 5.99 5.99 0 0 1 5.091 4.167h-2.6a3.61 3.61 0 0 0-2.716-1.774 3.756 3.756 0 0 0-.4-.023 3.619 3.619 0 0 0-3.51 2.808 1.209 1.209 0 0 1-.542.759 1.192 1.192 0 0 1-.626.177 1.207 1.207 0 0 1-.3-.038 4.064 4.064 0 0 0-.429-.109 1.8 1.8 0 0 0 0 3.6h5.377v2.4Z"
        data-name="Union 39"
      />
    </svg>
  );
};

SvgPublish.defaultProps = {
  color: COLORS.WHITE,
  height: 20,
  width: 20,
};
export default SvgPublish;
