import React from "react";

const SvgAvatar16 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="q9pg__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .q9pg__cls-1{fill:#fff}.q9pg__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="q9pg__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-9.77 10.04-22.12 17.56-35.98 21.5-.71.2-1.42.4-2.14.57-.76.2-1.52.39-2.29.55-.7.17-1.41.31-2.12.45-5.1 1-10.37 1.53-15.76 1.53-22.86 0-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="q9pg__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2a81.305 81.305 0 0 1-31.93 20.23c-.56.2-1.13.38-1.69.57-.6.18-1.2.37-1.8.54-.56.16-1.12.32-1.68.47a81.328 81.328 0 0 1-21.19 2.79c-22.86 0-43.52-9.43-58.3-24.6 16.3-12.06 36.47-19.19 58.3-19.19 7.27 0 14.37.79 21.19 2.3.56.12 1.12.25 1.68.39.6.13 1.2.28 1.8.44.57.14 1.13.3 1.69.46a97.512 97.512 0 0 1 31.93 15.6Z"
          className="q9pg__cls-2"
        />
        <path
          d="M110.61 26.7c-4.34-3.05-9.99-3.99-15.2-2.55l-30.86 8.39c-6.05 1.67-11.36 5.1-15.14 9.78-3.78 4.68-5.84 10.37-5.86 16.23 0 1.52.65 2.97 1.81 4.04 1.16 1.07 2.72 1.67 4.36 1.68h57.65c2.7 0 5.28-1 7.19-2.77 1.91-1.77 2.98-4.17 2.98-6.67V39.5c.02-5.05-2.54-9.8-6.91-12.81Zm2.38 33c-1.45 1.46-3.49 2.29-5.63 2.29H57.3l3.14-2.29 3.14-2.29 5.65-4.11c.54-.39.63-1.11.21-1.61-.42-.5-1.2-.58-1.73-.19l-8.11 5.91h-.02l-3.84 2.81c-1.68 1.1-3.68 1.71-5.73 1.76-.52.01-1.04-.02-1.53-.19-1.54-.52-2.46-1.84-2.46-3.24.02-5.36 1.91-10.57 5.37-14.86 3.46-4.29 8.33-7.43 13.86-8.96l30.86-8.39c1.37-.37 2.78-.56 4.2-.56 3.92.02 7.67 1.47 10.43 4.04 2.76 2.57 4.32 6.06 4.32 9.69v15.32c0 .88-.18 1.75-.52 2.57l-1.55 2.29Z"
          className="q9pg__cls-1"
        />
        <path
          d="M99.64 31.1c-6.46 0-11.71 5.39-11.71 12.01s5.26 12.01 11.71 12.01 11.71-5.39 11.71-12.01-5.26-12.01-11.71-12.01Zm0 21.73c-5.1 0-9.25-4.36-9.25-9.72s4.15-9.72 9.25-9.72 9.25 4.36 9.25 9.72-4.15 9.72-9.25 9.72Z"
          className="q9pg__cls-1"
        />
        <path
          d="M101.08 36.35c-.2-.41-.64-.68-1.13-.68s-.93.27-1.13.68l-1.54 3.2-3.39.5c-.45.07-.82.36-.97.76s-.05.84.26 1.15l2.53 2.53-.6 3.59c-.07.44.13.87.52 1.12.39.25.9.27 1.32.04l3-1.62 3 1.62c.41.22.92.2 1.31-.04.39-.25.6-.68.52-1.12l-.6-3.59 2.53-2.53c.31-.31.41-.75.26-1.15-.15-.4-.52-.69-.97-.76l-3.39-.5-1.54-3.2Zm.9 6.99c-.26.26-.37.61-.31.95l.3 1.77-1.39-.75a1.3 1.3 0 0 0-1.24 0l-1.39.75.3-1.77c.06-.35-.06-.7-.32-.95l-1.36-1.36 1.77-.27c.41-.06.76-.31.93-.66l.69-1.43.69 1.43c.17.35.52.6.93.66l1.77.27-1.37 1.36ZM112.89 147.95c-.19 0-.37-.04-.52-.13l-5.31-2.87-5.31 2.87c-.16.08-.34.13-.52.13-.21 0-.41-.06-.58-.17-.29-.19-.44-.49-.39-.79l1.05-6.29-4.43-4.43a.754.754 0 0 1-.19-.8c.11-.3.41-.52.77-.58l5.99-.89 2.71-5.64c.15-.32.51-.52.91-.52s.76.21.91.52l2.71 5.63 5.99.89c.37.05.66.28.77.58.1.28.03.58-.19.8l-4.43 4.43 1.05 6.29c.05.3-.1.61-.39.79-.17.11-.37.17-.59.17Zm-5.84-4.84c.18 0 .36.04.52.13l4.04 2.17-.81-4.86c-.04-.24.04-.48.23-.67l3.61-3.59-4.83-.72c-.33-.05-.62-.25-.74-.51l-2.01-4.17-2 4.17c-.13.27-.41.46-.75.51l-4.82.72 3.58 3.6c.19.19.27.43.23.67l-.82 4.86 4.04-2.17c.16-.09.34-.13.52-.13Z"
          className="q9pg__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar16.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar16;
