import { t } from "i18next";
import { useRef, useState } from "react";
import { FormButton, TagsSelector, useMenu } from "src/components";
import { classNames } from "src/helpers";
import FormulaItem from "./FormulaItem";
import FormulaPlaceholder from "./FormulaPlaceholder";
import { CustomMenu } from "./customMenu";
import FormulaMenu from "./formulaMenu/FormulaMenu";

function FormulaBuilder({
  actions,
  formula,
  disabled,
  formulaName,
  onHide,
  error,
  recurrenceId,
  addBtnText,
  incentiveDetails,
  selectedRecurrence,
  isRewardBuilder,
  warning,
}) {
  const menu = useMenu();
  const inputRef = useRef();
  const [isInputPresent, setIsInputPresent] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChange = () => {
    if (inputRef.current.value.trim() === "") {
      inputRef.current.value = "";
      setIsInputPresent(false);
      return;
    }
    if (inputRef.current.value) {
      return setIsInputPresent(true);
    }
    setIsInputPresent(false);
  };

  const handleEnter = (value) => {
    const isPushed = actions.pushFormula(value);
    inputRef.current.focus();
    setIsInputPresent(false);
    return isPushed;
  };

  const handleMenuClick = (value) => {
    actions.pushFormula(value);
    inputRef.current.focus();
  };

  const handleClose = () => {
    setIsMenuOpen(false);
    menu.handleClose();
    inputRef.current.focus();
  };

  const handleDelete = (value) => {
    if (formula.length !== 0) {
      inputRef.current.value = actions.popFormula();
    }
    inputRef.current.focus();
  };

  const getStyles = (el) => {
    if (!el) {
      return {
        position: "absolute",
      };
    }
    return {
      position: "absolute",
      top: el.offsetTop + el.clientHeight + 16,
      left: el.offsetLeft > 700 ? el.offsetLeft - 300 : el.offsetLeft,
    };
  };

  return (
    <>
      <div className="px-4 py-2 w-100 br-8">
        <p className="fs-13 bold py-2 mb-2">
          {t("COMMON.LABELS.FORMULA_BUILDER")}
        </p>
        <div
          ref={menu.containerRef}
          onClick={
            disabled
              ? undefined
              : () => {
                  inputRef.current.focus();
                  setIsMenuOpen((prev) => !prev);
                }
          }
          style={{ position: "relative" }}
          className={classNames([
            "w-100 br-4 border-1 p-0",
            disabled ? "bc-grey3 bg-grey" : "border-transparent",
          ])}
        >
          <TagsSelector
            className="w-100"
            disabled={disabled}
            inputRef={inputRef}
            tags={formula}
            renderTag={FormulaItem}
            onEnter={handleEnter}
            onFocus={menu.handleClick}
            onDelete={handleDelete}
            onChange={handleChange}
            showPlaceholder={
              !menu.el &&
              (inputRef.current
                ? inputRef.current?.value.length === 0
                : true) &&
              formula.length === 0
            }
            placeholder={<FormulaPlaceholder />}
          />
        </div>
        {error?.message && (
          <span className="fs-12 mt-3 fc-red">{error?.message}</span>
        )}
      </div>
      <div className="border-top-1 bc-grey8 p-3 center">
        <span className="fs-12 center">
          Type and press <b>“Enter”</b> to add.
        </span>
        <div className="ml-auto space-x-16">
          <FormButton type="button" onClick={onHide} variant="outline-primary">
            Cancel
          </FormButton>
          <FormButton
            type="button"
            disabled={
              !formula.length ||
              isInputPresent ||
              (!isRewardBuilder && (!formulaName || warning))
            }
            onClick={actions.handleSave}
          >
            {addBtnText}
          </FormButton>
        </div>
      </div>
      <CustomMenu
        el={menu.el}
        container={menu.containerRef.current}
        applyStyles={() => getStyles(menu.el)}
        isOpen={isMenuOpen}
      >
        <FormulaMenu
          handleClick={handleMenuClick}
          handleClose={handleClose}
          recurrenceId={recurrenceId}
          incentiveDetails={incentiveDetails}
          selectedRecurrence={selectedRecurrence}
        />
      </CustomMenu>
    </>
  );
}

export default FormulaBuilder;
