import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const VALIDATE_PROGRAM_IMPORT_FILE_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation validateProgramImportFile($ProgramId: ID!, $FileId: Float!) {
    validateProgramImportFile(ProgramId: $ProgramId, FileId: $FileId) {
      ... on ProgramValidateResponse {
        success
        message
        response {
          downloadUrl
          errorCount
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useValidateProgramFile = () => {
  return useMutation(VALIDATE_PROGRAM_IMPORT_FILE_MUTATION);
};
