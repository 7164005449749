import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { range } from "src/helpers";
import "./loadingRuleCard.scss";

const ShimmerTab = () => (
  <>
    <div className="center">
      <Shimmer className="br-2" width="16px" height="16px" />
    </div>
    <div className="w-100 ml-3">
      <Shimmer width="30%" height="10px" />
      <div className="mt-3 mb-3">
        <Shimmer width="100%" height="10px" />
      </div>
    </div>
  </>
);

const LoadingRuleCard = () => (
  <div className="loading-achievement-card bg-white br-4 w-100">
    {range(0, 3).map((i) => (
      <div
        key={i}
        className={`d-flex align-items-start mb-3 ${i < 3 ? "border-b" : ""}`}
      >
        <ShimmerTab />
      </div>
    ))}
  </div>
);

export default LoadingRuleCard;
