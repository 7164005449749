import { classNames } from "src/helpers";

const GroupByLabel = ({ groupByCondition, splitToLines = false }) => {
  if (!groupByCondition?.SubGoalConditions) return <></>;
  return groupByCondition?.SubGoalConditions?.map((item, index) => (
    <div
      className={classNames([
        "ml-1 center",
        splitToLines && "w-100 flex align-items-start flex-column gap-2",
      ])}
    >
      <div>
        {item?.Key} - <b className="ml-1">{item?.Value}</b>
      </div>
      {groupByCondition?.SubGoalConditions?.length > 1 && index % 2 === 0 && (
        <div className="fc-grey1 bg-grey4 br-8 ml-1 text-center px-2 w-fit-content">
          {groupByCondition?.ConditionPattern?.split(" ")[1]}
        </div>
      )}
    </div>
  ));
};
export default GroupByLabel;
