import React from "react";
import { COLORS } from "src/constants";
const SvgUnderlineEdit = (props) => {
  const {
    color = COLORS.PRIMARY_BLUE,
    height = 12,
    width = 12,
    ...rest
  } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M.5 12V9.818h11V12zm2.2-4.364h.77l4.29-4.24L7.361 3l-.385-.382L2.7 6.873zM1.6 8.727V6.41L7.76.314A1.094 1.094 0 0 1 8.53 0c.147 0 .289.027.426.082a1.1 1.1 0 0 1 .372.245l.756.764c.11.1.19.218.24.354.05.137.076.278.076.423 0 .137-.025.27-.076.402a1.02 1.02 0 0 1-.24.362L3.938 8.727zm6.16-5.332L7.361 3l-.385-.382z"
      />
    </svg>
  );
};

export default SvgUnderlineEdit;
