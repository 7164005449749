import React from "react";
import { Image } from "src/components";
import AngelBrokingLimited from "./AngelBrokingLimited.png";

const AngelBroking = (props) => (
  <Image
    src={AngelBrokingLimited}
    alt="AchievementCardBackgroundImg"
    {...props}
  />
);
export default AngelBroking;
