import React from "react";
import { FormCheck } from "src/components/common/formElements";
import { classNames, isEqual } from "src/helpers";
import ModalContainer from "../modalContainer/ModalContainer";
import "./sortContainer.scss";

const SortContainer = (props) => {
  const { show, onHide, options, defaultValue, filter, title, setFilter } =
    props;

  const onSortSelectionClick = (option) => {
    setFilter(option);
    onHide();
  };

  const onResetClick = () => {
    setFilter(defaultValue);
    onHide();
  };
  return (
    <div>
      <ModalContainer
        show={show}
        onHide={onHide}
        title={title}
        disableReset={isEqual(filter, defaultValue)}
        onResetClick={onResetClick}
        disableFooter
      >
        <div className="p-4 sort-filter-list">
          {options.map((subOption, index) => {
            const isOptionSelected = isEqual(subOption, filter);
            return (
              <div className="sort-list-item flex-between py-12">
                <div
                  className={classNames([
                    "fs-14",
                    isOptionSelected ? "fc-blue semi-bold" : "",
                  ])}
                >
                  {subOption.label}
                </div>
                <div className="flex-end">
                  <FormCheck
                    id={`${subOption.label}-${index}`}
                    type="radio"
                    disabled={subOption.disabled}
                    checked={isOptionSelected}
                    onClick={() => {
                      onSortSelectionClick(subOption);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </ModalContainer>
    </div>
  );
};

export default SortContainer;
