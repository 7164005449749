export const GOAL_AGGREGATION_TYPES = {
  TARGET_BASED: "TargetBased",
  MAINTAIN_GREATER_THAN_EQUAL: "MaintainGreaterThanEqual",
  MAINTAIN_LESS_THAN_EQUAL: "MaintainLessThanEqual",
  AVG_GREATER_THAN_EQUAL: "AvgGreaterThanEqual",
  AVG_LESS_THAN_EQUAL: "AvgLessThanEqual",
};

export const RULESET_AGGREGATION_TYPES = {
  COUNT: "COUNT",
  SUM: "SUM",
  DISTINCT_COUNT: "DISTINCT_COUNT",
  AVERAGE: "AVERAGE",
  CUSTOM: "CUSTOM",
};

export const ROLES = {
  ADMIN: "Administrator",
  SALES_MANAGER: "Sales_Manager",
  SALES_USER: "Sales_User",
};

export const USER_ROLES = {
  ADMIN: "Administrator",
  SALES_MANAGER: "SalesManager",
  SALES_USER: "SalesUser",
  SALES_MANAGER_AS_APPROVER: "ManagerAsApprover",
  SALES_USER_AS_APPROVER: "SalesUserAsApprover",
};

export const PAYOUT_STATUS_TYPES = {
  PAIDOUT: "PAIDOUT",
  PENDING: "PENDING",
  PARTIALLY_PAIDOUT: "PARTIALLYPAIDOUT",
};

export const CUSTOM_CYCLE_TYPES = {
  UNIFORM: "Uniform",
  NONUNIFORM: "Non-Uniform",
};

export const PROGRAM_STATUS_TYPES = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  INPROGRESS: "INPROGRESS",
  COMPLETED: "COMPLETED",
  INGAPS: "INGAPS",
  YETTOSTART: "YETTOSTART",
};

export const TENANT_STAGE_STATUS_TYPES = {
  IN_PROGRESS: "IN_PROGRESS",
  TRIAL_EXPIRED: "TRIAL_EXPIRED",
  ENABLED: "ENABLED",
};

export const DRILLDOWN_STATUS_TYPES = {
  ENABLED: "Y",
  DISABLED: "N",
};

export const INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES = {
  STANDARD: "STANDARD",
  FORMULA: "FORMULA",
};
