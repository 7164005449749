import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgLateEvening = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      role="img"
      width={width}
      height={height}
      {...rest}
    >
      <defs>
        <style>
          {`\n      .cls-1 {\n        fill: ${color};\n      }\n    `}
        </style>
      </defs>
      <g>
        <rect
          className="cls-1"
          x={5.5499}
          y={0.001}
          width={0.9004}
          height={1.2852}
        />
        <rect
          className="cls-1"
          x={2.8717}
          y={0.7184}
          width={0.9005}
          height={1.2859}
          transform="translate(-.2354 1.8453) rotate(-30.0309)"
        />
        <rect
          className="cls-1"
          x={0.9112}
          y={2.6784}
          width={0.8995}
          height={1.2868}
          transform="translate(-2.1962 2.839) rotate(-59.9908)"
        />
        <rect
          className="cls-1"
          x={0.0001}
          y={5.5498}
          width={1.2861}
          height={0.9004}
        />
        <rect
          className="cls-1"
          x={0.7176}
          y={8.2285}
          width={1.2868}
          height={0.8995}
          transform="translate(-4.1521 1.8394) rotate(-29.9609)"
        />
        <rect
          className="cls-1"
          x={2.679}
          y={10.1884}
          width={1.2859}
          height={0.9005}
          transform="translate(-7.5495 8.1832) rotate(-59.9314)"
        />
        <rect
          className="cls-1"
          x={5.5499}
          y={10.7139}
          width={0.9004}
          height={1.2852}
        />
        <rect
          className="cls-1"
          x={8.2281}
          y={9.9957}
          width={0.9005}
          height={1.2859}
          transform="translate(-4.1593 5.7714) rotate(-30.0309)"
        />
        <rect
          className="cls-1"
          x={10.1886}
          y={8.0353}
          width={0.9005}
          height={1.2859}
          transform="translate(-2.1988 13.5457) rotate(-59.9691)"
        />
        <rect
          className="cls-1"
          x={10.713}
          y={5.5498}
          width={1.2861}
          height={0.9004}
        />
        <rect
          className="cls-1"
          x={9.9958}
          y={2.8715}
          width={1.2859}
          height={0.9005}
          transform="translate(-.2326 5.7774) rotate(-30.0686)"
        />
        <rect
          className="cls-1"
          x={8.0354}
          y={0.9111}
          width={1.2859}
          height={0.9005}
          transform="translate(3.1521 8.1897) rotate(-59.9314)"
        />
      </g>
      <path
        className="cls-1"
        d="M6.0001,9.9229c-2.1436,0-3.8877-1.7441-3.8877-3.8877,0-1.7168,1.1611-3.248,2.8232-3.7227l.7822-.2236-.2256,.7812c-.0889,.3066-.1299,.5654-.1299,.8154,0,1.6475,1.3398,2.9873,2.9873,2.9873,.249,0,.5088-.041,.8164-.1289l.7793-.2227-.2227,.7793c-.4756,1.6611-2.0059,2.8223-3.7227,2.8223Zm-1.5322-6.4346c-.8828,.5293-1.4551,1.4922-1.4551,2.5469,0,1.6475,1.3398,2.9873,2.9873,2.9873,1.0547,0,2.0176-.5713,2.5479-1.4551-2.2354,.126-4.0859-1.6729-4.0859-3.8818,0-.0654,.002-.1318,.0059-.1973Z"
      />
    </svg>
  );
};

SvgLateEvening.defaultProps = {
  width: 12,
  height: 12,
  color: COLORS.GREY_2,
};

export default SvgLateEvening;
