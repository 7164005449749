import { Shimmer } from "../..";

const LoadingBreakdownModalMobile = () => (
  <div className="col-12 col-md-6 col-xl-4 goal-card-grid">
    <div className="shimmer-card card p-4 mb-30 bg-white h-200 rounded">
      <div className="card-title pb-2">
        <div>
          <Shimmer className="mb-3" width="60%" />
          <Shimmer className="mb-3" width="60%" />
        </div>
        <div className="border-b" />
      </div>

      <div className="mt-3 flex-between">
        <Shimmer width="30%" />
        <Shimmer width="30%" />
      </div>
      <div className="mt-3 flex-between">
        <Shimmer width="40%" />
        <Shimmer width="40%" />
      </div>

      <div className="mt-3 flex-between">
        <Shimmer width="30%" />
        <Shimmer width="30%" />
      </div>
      <div className="mt-3 flex-between">
        <Shimmer width="40%" />
        <Shimmer width="40%" />
      </div>
    </div>
    <div className="shimmer-card card p-4 mb-30 bg-white h-200 rounded">
      <div className="card-title pb-2">
        <div>
          <Shimmer className="mb-3" width="60%" />
          <Shimmer className="mb-3" width="60%" />
        </div>
        <div className="border-b" />
      </div>

      <div className="mt-3 flex-between">
        <Shimmer width="30%" />
        <Shimmer width="30%" />
      </div>
      <div className="mt-3 flex-between">
        <Shimmer width="40%" />
        <Shimmer width="40%" />
      </div>

      <div className="mt-3 flex-between">
        <Shimmer width="30%" />
        <Shimmer width="30%" />
      </div>
      <div className="mt-3 flex-between">
        <Shimmer width="40%" />
        <Shimmer width="40%" />
      </div>
    </div>
  </div>
);
export default LoadingBreakdownModalMobile;
