import PropTypes from "prop-types";
import React from "react";
import { range } from "src/helpers";
import { Shimmer } from "../shimmer";
import { data, getMobileContent, lookup } from "./data";
import "./timeline.scss";

export const LoadingMobileTimeline = () => {
  return (
    <>
      {range(0, 1).map((i) => {
        return (
          <div style={{ position: "relative", opacity: i % 2 === 0 ? 0.4 : 1 }}>
            <div className="d-flex br-4">
              <div className="d-flex flex-column align-items-center">
                <Shimmer
                  width="24px"
                  height="26.58px"
                  className="rounded-circle"
                />
                <div className="timeline-divider" />
              </div>
              <div className="ml-3 w-100 timeline-data-container">
                <div className="flex-between mb-12">
                  <Shimmer width="113px" height="22px" />
                  <Shimmer width="91px" height="20px" />
                </div>
                <Shimmer className="mb-3" height="45px" width="100%" />
                <Shimmer className="mb-12" height="21px" width="224px" />
                <Shimmer className="mb-12" height="80px" width="100%" />
                <Shimmer className="mb-12" height="16px" width="100px" />
                <hr className="mb-3" />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const MobileTimeline = ({
  loading,
  data,
  payloadForRewardsList,
  getContent,
  lookup,
}) => {
  if (loading) {
    return <LoadingMobileTimeline />;
  }
  return (
    <>
      {data?.map((item, idx) => {
        const iconObject = lookup[item.status];
        return (
          <div key={`${item.status}_${idx}`} style={{ position: "relative" }}>
            <div className="d-flex br-4">
              <div className="d-flex flex-column align-items-center">
                <div className={item.status === "pending" ? "mt-3" : ""}>
                  {iconObject?.icon}
                </div>
                {item.status === "pending" && (
                  <div className="ml-2 bg-blue rounded-left timeline-pending-background" />
                )}
                <div
                  className={
                    idx === data.length - 1
                      ? "timeline-hide-divider"
                      : "timeline-divider"
                  }
                />
              </div>
              <div className="ml-2 fs-11 w-100 timeline-data-container">
                {getContent(item, payloadForRewardsList)}
                <hr className="mb-3" />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
MobileTimeline.propTypes = {
  data: PropTypes.array,
  getContent: PropTypes.func,
  lookup: PropTypes.shape({}),
  isPayoutHistory: PropTypes.bool,
};

MobileTimeline.defaultProps = {
  getContent: getMobileContent,
  data,
  lookup,
  isPayoutHistory: false,
};

export default MobileTimeline;
