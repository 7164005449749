import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgProgramView = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g>
        <path fill="none" d="M0 0h16v16H0z" />
        <g>
          <g
            fill="none"
            stroke={color}
            strokeWidth={1.7}
            transform="translate(1 1)"
          >
            <circle cx={7} cy={7} r={7} stroke="none" />
            <circle cx={7} cy={7} r={6.15} />
          </g>
          <path
            fill={color}
            d="M7.999 6.246a.647.647 0 0 1 .682.6.625.625 0 0 0 1.241 0 1.73 1.73 0 0 0-1.3-1.6v-.311a.625.625 0 0 0-1.241 0v.311a1.729 1.729 0 0 0-1.3 1.6 1.822 1.822 0 0 0 1.922 1.7.607.607 0 1 1-.681.6.625.625 0 0 0-1.241 0 1.728 1.728 0 0 0 1.3 1.6v.311a.625.625 0 0 0 1.241 0v-.303a1.729 1.729 0 0 0 1.3-1.6 1.823 1.823 0 0 0-1.923-1.7.607.607 0 1 1 0-1.2Z"
          />
        </g>
      </g>
    </svg>
  );
};

SvgProgramView.defaultProps = {
  color: COLORS.GREY_2,
  height: 16,
  width: 16,
};
export default SvgProgramView;
