import React, { lazy } from "react";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'goals.web.goalCrudPage' */ "./web")
);

export const GoalCrudPage = {
  web: WebPage,
  mobile: () => <Redirect to={APPS.GOALS.ROUTES.MANAGED_BY_ME.path} />,
  meta: {
    title: "Goal Crud",
  },
};
