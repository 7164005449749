import React from "react";
const SvgGetintouch = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill="#fff"
        d="m17.807 18.414 1.113-12.79a1.172 1.172 0 0 0-.077-.532 1.039 1.039 0 0 0-.3-.419.875.875 0 0 0-.446-.196.838.838 0 0 0-.473.078l-10.51 5.1c-.3.15-.56.385-.753.683a2.27 2.27 0 0 0-.35 1.017 2.35 2.35 0 0 0 .145 1.066c.129.335.333.627.593.846l3.299 2.723 1.13-1.742a.924.924 0 0 1 .61-.42.853.853 0 0 1 .694.192c.2.164.333.412.37.688.039.276-.022.557-.168.783l-1.102 1.7 3.261 2.69a1.68 1.68 0 0 0 1.809.188c.304-.137.572-.362.774-.653.203-.29.334-.636.38-1.002M7.688 16.064l-.532 4.778c.006.158.044.312.11.45.066.14.159.26.272.353a.883.883 0 0 0 .38.185.83.83 0 0 0 .413-.018l3.876-2.02z"
      />
    </svg>
  );
};
SvgGetintouch.defaultProps = {
  color: "",
  height: 26,
  width: 26,
};
export default SvgGetintouch;
