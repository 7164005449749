import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgSentBack = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="s633__x">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#s633__x)" data-name="Send Back">
        <g data-name="Group 12982">
          <g data-name="Group 12729">
            <g
              fill={backgroundColor}
              stroke={color}
              strokeWidth={2}
              data-name="Ellipse 193"
              transform="rotate(180 12 12)"
            >
              <circle cx={12} cy={12} r={12} stroke="none" />
              <circle cx={12} cy={12} r={11} fill="none" />
            </g>
          </g>
          <path
            fill={color}
            d="m12.748 16.221-.5.5a.533.533 0 0 1-.757 0L7.157 12.38a.533.533 0 0 1 0-.757L11.5 7.284a.533.533 0 0 1 .757 0l.5.5a.536.536 0 0 1-.009.766l-2.698 2.558h6.414a.534.534 0 0 1 .536.535v.714a.534.534 0 0 1-.536.536H10.05l2.689 2.562a.532.532 0 0 1 .009.766Z"
            data-name="Icon awesome-arrow-right"
          />
        </g>
      </g>
    </svg>
  );
};

SvgSentBack.defaultProps = {
  backgroundColor: COLORS.GREY_5,
  color: COLORS.GREY_1,
  height: 24,
  width: 24,
};
export default SvgSentBack;
