import { forwardRef } from "react";
import { classNames } from "src/helpers";
import "./marquee.scss";

const getStyle = ({ playing, duration }) => {
  return {
    "--animation-play-state": playing ? "running" : "paused",
    "--animation-duration": duration,
  };
};

const Marquee = forwardRef(
  ({ className, children, playing, duration = "60s" }, ref) => {
    const style = getStyle({ playing, duration });

    return (
      <div
        ref={ref}
        className={classNames(["marquee", className])}
        style={style}
      >
        <div className="marquee-content">{children}</div>
        <div className="marquee-content" aria-hidden="true">
          {children}
        </div>
      </div>
    );
  }
);

export default Marquee;
