import React from "react";
import { Image } from "src/components";
import WallOfLegendsBackgroundImg from "./WallOfLegendsBackground.png";

const WallOfLegendsBackground = (props) => (
  <Image
    src={WallOfLegendsBackgroundImg}
    alt="WallOfLegendsBackgroundImg"
    {...props}
  />
);

export default WallOfLegendsBackground;
