import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FormBadge } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { SvgArrow } from "src/icons";
import AccordianContent from "./AccordianContent";
import "./styles.scss";

const DependencyAccordion = (props) => {
  const { index, data, title, contentTitle, active, setActive } = props;

  const isHighlighted = active.index === index && active.open;

  return (
    <div className="my-2">
      <Accordion.Toggle
        variant="link"
        eventKey={String(index)}
        className={`w-100 px-16 py-1 d-flex align-items-center br-4 ${
          isHighlighted
            ? "br-b-0 border-bottom-0 bg-white border-1 bc-primary-blue"
            : "bg-grey6 border-transparent"
        }`}
        onClick={() =>
          setActive((prev) => {
            if (prev.index === index) {
              return { ...prev, open: !active.open };
            }
            return { index, open: true };
          })
        }
      >
        <div className="d-flex align-items-baseline justify-content-between w-100">
          <div className="d-flex align-items-baseline">
            <span className="fs-14 semi-bold mx-2">{title}</span>
            <FormBadge
              className="bg-secondary-blue fs-12 fc-blue semi-bold py-1 mb-1"
              id="template-dependency-list"
            >
              <div className="fs-12 fc-grey normal">{data?.length}</div>
            </FormBadge>
          </div>
          <div className="ml-auto">
            <SvgArrow
              color={isHighlighted ? COLORS.PRIMARY_BLUE : COLORS.GREY_2}
              rotate={isHighlighted ? 180 : 0}
            />
          </div>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse
        className={`bg-white border-1  br-4 br-t-0 dependency-accordian-content${
          isHighlighted
            ? "bg-white border-1 bc-primary-blue br-4 br-t-0 dependency-accordian-content"
            : "bg-grey6 border-transparent"
        }`}
        eventKey={String(index)}
      >
        <div className="dependency-accordian-content">
          {isHighlighted && (
            <AccordianContent data={data} contentTitle={contentTitle} />
          )}
        </div>
      </Accordion.Collapse>
    </div>
  );
};
export default DependencyAccordion;
