import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePayoutSingleUser } from "src/api/usePayout";
import {
  AmountLabel,
  ConfirmationModal,
  FormButton,
  NonMonetaryRewardDetails,
  PendingPayoutCard,
  RewardCardWithReferenceId,
  SchedulePayoutViewModal,
  formatAmount,
  toaster,
  usePendingPayout,
} from "src/components";
import {
  COLORS,
  FEATURE_TYPES,
  FIELD_NAMES,
  REWARD_SUBCATEGORIES,
} from "src/constants";
import { getPluralText, successText } from "src/helpers";
import {
  SvgInfo,
  SvgMonetaryRewardApproval,
  SvgNonMonetaryRewardApproval,
} from "src/icons";
import { SendBackToApproval } from "..";

const CONFIRMATION_TYPES = {
  SEND_BACK_FOR_APPROVAL: "SEND_BACK_FOR_APPROVAL",
  PAYOUT: "PAYOUT",
};

const CONFIRMATION_BODY_TITLE = {
  PAYOUT: "INCENTIVES.COMMON.MESSAGES.PROCEED_WITH_INCENTIVE_REQUEST",
};

const CONFIRMATION_BODY_DESCRIPTION = {
  PAYOUT: "INCENTIVES.COMMON.MESSAGES.TEMPLATE_PROCEED_MESSAGE",
};
const payoutFormatter = (amount, rewardsLength) => {
  if (amount && rewardsLength > 0) {
    return (
      <span className="bold space-x-3">
        <span>{t("LEADERBOARD.LABELS.AMOUNT")}</span>
        <AmountLabel className="fc-black fs-14 semi-bold" amount={amount} />
        <span className="space-x-3">
          <span>&</span>
          <span>
            {getPluralText(
              rewardsLength,
              t("NON_MONETARY.LABELS.NON_MONETARY_REWARD"),
              t("LEADERBOARD.LABELS.NON_MONETARY_REWARDS")
            )}
          </span>
        </span>
      </span>
    );
  }
  if (rewardsLength < 1 && amount) {
    return (
      <span className="bold space-x-3">
        <span className="normal">{t("LEADERBOARD.LABELS.AMOUNT")}</span>
        <AmountLabel className="fc-black fs-14 semi-bold" amount={amount} />
      </span>
    );
  }
  return (
    <span className="fs-12 bold space-x-3">
      {getPluralText(
        rewardsLength,
        t("NON_MONETARY.LABELS.NON_MONETARY_REWARD"),
        t("LEADERBOARD.LABELS.NON_MONETARY_REWARDS")
      )}
    </span>
  );
};

const ConfirmationModalBody = ({
  confirmationType,
  approvedAmount,
  rewards,
  isMultipleSchedule,
}) => {
  const payoutRewardIdsCount = rewards.reduce(
    (acc, elm) => acc + elm.paidOutCount,
    0
  );

  const { t } = useTranslation();
  if (confirmationType === CONFIRMATION_TYPES.PAYOUT) {
    return (
      <div className="pb-4">
        <div className="fs-14 semi-bold fc-primary-black mb-3">
          {t("INCENTIVES.COMMON.MESSAGES.PROCEED_WITH_PAYOUT_REQUEST")}
        </div>
        <p className="fs-13 fc-primary-black space-x-3">
          {Boolean(approvedAmount) && <span>The</span>}
          {payoutFormatter(approvedAmount, payoutRewardIdsCount)}
          {Boolean(isMultipleSchedule) && (
            <>
              <span>from</span>
              <span>different schedules</span>
            </>
          )}
          <span>will be</span>
          <span className="fc-black fs-13 semi-bold">paid out.</span>
        </p>
      </div>
    );
  }
  return (
    <div className="pb-4">
      <div className="fs-14 semi-bold fc-primary-black mb-3">
        {t(CONFIRMATION_BODY_TITLE[confirmationType])}
      </div>
      <div className="fs-13 fc-primary-black">
        {t(CONFIRMATION_BODY_DESCRIPTION[confirmationType])}
      </div>
    </div>
  );
};

function PayoutIncentiveForm({
  userId,
  programId,
  recurrenceId,
  eligibleIncentive,
  payoutDone,
  handleSuccess,
  pendingRewardItems,
  paidOutRewards,
  formattedPendingRewards,
  payoutSchedule,
  currentRecurrence,
}) {
  const initialState = {
    payoutAmount: {
      value: NaN,
      error: "",
    },
    referenceId: {
      value: "",
      error: "",
    },
    pendingRewards: {
      value: formattedPendingRewards || [],
      showError: false,
    },
  };
  const [confirmationType, setConfirmationType] = useState("");
  const [showRewardDetailsModal, setShowRewardDetailsModal] = useState(false);
  const { t } = useTranslation();
  const [form, setForm] = useState(initialState);
  const [payoutSingleUser, { loading }] = usePayoutSingleUser();
  const [show, setShow] = useState(false);

  const rewardsList = Object.values(form.pendingRewards.value);
  const selectedRewards = rewardsList.filter((item) => item.isSelected);

  const pendingAmtWithRewardsPresent =
    eligibleIncentive > 0 && pendingRewardItems.length > 0;

  const {
    scheduleForm,
    handleAmountChange,
    handleReferenceIdChange,
    validateMonetaryPayout,
    isMonetaryPayoutSelected,
    isValidMonetaryPayout,
  } = usePendingPayout(payoutSchedule);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        value,
      },
    }));
  };

  const validateForm = () => {
    const pendingRewards = { ...form.pendingRewards };

    const isMonetaryPayoutDirty = isMonetaryPayoutSelected();

    const isNonMonetaryPayoutDirty = selectedRewards.length > 0;

    const isValidMonetary = isValidMonetaryPayout();

    const isValidNonMonetary =
      pendingRewardItems.length > 0
        ? selectedRewards.length > 0 &&
          !selectedRewards.find((item) => !item.referenceId)
        : true;

    const rewardsError =
      selectedRewards.length > 0 &&
      selectedRewards.find((item) => !item.referenceId);

    // Only Non-Monetary Rewards
    if (!isMonetaryPayoutDirty && isNonMonetaryPayoutDirty) {
      if (isValidNonMonetary) return true;
      if (rewardsError) {
        setForm({
          pendingRewards: { ...pendingRewards, showError: true },
        });
      }
      return false;
    }

    // Only Monetary Rewards
    if (isMonetaryPayoutDirty && !isNonMonetaryPayoutDirty) {
      if (isValidMonetary) return true;
      validateMonetaryPayout();
      return false;
    }

    // Both Monetary and Non-Monetary Rewards
    if (isMonetaryPayoutDirty && isNonMonetaryPayoutDirty) {
      if (isValidMonetary && isValidNonMonetary) return true;
      if (rewardsError) {
        setForm({
          pendingRewards: { ...pendingRewards, showError: true },
        });
      }
      validateMonetaryPayout();
      return false;
    }

    validateMonetaryPayout();

    return false;
  };

  const getPayoutPayload = () => {
    const nonMonetaryReferenceIdPayload = [];
    selectedRewards.forEach((elm) => {
      [...Array(elm.paidOutCount)].forEach(() =>
        nonMonetaryReferenceIdPayload.push({
          Type: REWARD_SUBCATEGORIES.NON_MONETARY.id,
          RewardId: elm.RewardId,
          RewardName: elm.RewardName,
          ReferenceId: elm.referenceId,
        })
      );
    });

    const payoutRewardIds = nonMonetaryReferenceIdPayload.map(
      (elm) => elm.RewardId
    );

    const monetaryPayload = Object.keys(scheduleForm).filter(
      (item) =>
        Boolean(scheduleForm[item].payoutAmount.value) &&
        Boolean(scheduleForm[item].referenceId.value)
    );

    const payout = Boolean(monetaryPayload?.length)
      ? monetaryPayload.map((item, idx) => {
          return {
            type: REWARD_SUBCATEGORIES.MONETARY.id,
            payoutRecurrence: item,
            payoutAmount: formatAmount(scheduleForm[item].payoutAmount.value),
            payoutRewards: idx === 0 ? payoutRewardIds : [],
            referenceId:
              idx === 0
                ? [
                    {
                      Type: REWARD_SUBCATEGORIES.MONETARY.id,
                      RewardId: null,
                      RewardName: null,
                      ReferenceId: scheduleForm[item].referenceId.value,
                    },
                    ...nonMonetaryReferenceIdPayload,
                  ]
                : [
                    {
                      Type: REWARD_SUBCATEGORIES.MONETARY.id,
                      RewardId: null,
                      RewardName: null,
                      ReferenceId: scheduleForm[item].referenceId.value,
                    },
                  ],
          };
        })
      : [
          {
            payoutRewards: payoutRewardIds,
            payoutRecurrence: recurrenceId,
            referenceId: nonMonetaryReferenceIdPayload,
          },
        ];

    return {
      payout,
      userId,
      programId,
      recurrenceId,
    };
  };

  const handlePayout = () => {
    payoutSingleUser({
      variables: { ...getPayoutPayload() },
    }).then(() => {
      handleSuccess({
        sentBack: false,
        PayoutDone: formatAmount(
          Object.values(scheduleForm)
            .map((item) => item.payoutAmount)
            .reduce((acc, item) => acc + Number(item.value), 0)
        ),
      });
      setConfirmationType("");
      setForm(initialState);
      toaster({
        type: "info",
        message: successText.PAYOUT.SUCCESS,
      });
    });
  };

  const handleSendBackToApproval = ({
    remarks,
    fileName,
    fileId,
    handleClose,
  }) => {
    payoutSingleUser({
      variables: {
        userId,
        programId,
        recurrenceId,
        remarks,
        fileName,
        fileId,
        moveToApproval: true,
      },
    }).then(() => {
      handleSuccess({
        sentBack: true,
      });
      handleClose();
      toaster({
        type: "info",
        message: successText.PAYOUT.SENT_BACK_TO_APPROVAL,
      });
    });
  };

  const handleNonMonetaryChange = (
    pos,
    isSelected,
    referenceId = "",
    count = 1
  ) => {
    const pendingRewardsObj = { ...form.pendingRewards.value };
    pendingRewardsObj[pos] = {
      ...pendingRewardsObj[pos],
      isSelected,
      paidOutCount: count,
      referenceId,
    };
    handleChange({
      target: { name: "pendingRewards", value: pendingRewardsObj },
    });
  };

  const payloadForRewardsList = {
    programId,
    recurrenceId,
    userId,
    featureType: FEATURE_TYPES.INCENTIVE,
    fieldName: FIELD_NAMES.PAYOUT_PENDING,
  };

  const cardsHeader = () => (
    <div className="w-100 center fs-12 fc-grey1 semi-bold reward-cards-header border-bottom-1 bc-grey3 bg-grey6 py-12">
      <div className="pl-3 w-40 mr-4">
        {t("INCENTIVES.COMMON.LABELS.REWARD")}
      </div>
      <div className="w-30 mr-4">
        {t("INCENTIVES.MY_INCENTIVES.MESSAGES.PENDING")}
      </div>
      <div className="ml-2">{t("INCENTIVES.COMMON.LABELS.PAYOUT")}</div>
    </div>
  );

  const renderRewardList = () => {
    return rewardsList?.map((reward, index) => {
      return (
        <div
          className={
            index === rewardsList.length - 1 ? "" : "border-bottom-1 bc-grey12"
          }
        >
          <RewardCardWithReferenceId
            handleNonMonetaryChange={handleNonMonetaryChange}
            reward={reward}
            showError={form.pendingRewards.showError}
          />
        </div>
      );
    });
  };

  const cards = () => (
    <div className="border-1 bc-grey3 br-4">
      {cardsHeader()}
      {renderRewardList()}
    </div>
  );

  const showPendingToActionErrorMessage =
    pendingAmtWithRewardsPresent &&
    form?.pendingRewards?.showError &&
    (Number.isNaN(form?.payoutAmount?.value) ||
      form?.payoutAmount?.value === 0) &&
    selectedRewards?.length < 1;

  return (
    <div>
      {showPendingToActionErrorMessage && (
        <div className="center fs-12 fc-red-warn bg-secondary-red px-1 py-1 br-44">
          <SvgInfo backgroundColor={COLORS.SECONDARY_RED} color={COLORS.RED} />
          {t("INCENTIVES.PAYOUT.ERRORS.ERROR_6")}
        </div>
      )}
      <form className="mt-2">
        {eligibleIncentive && eligibleIncentive !== 0 ? (
          <>
            <div className="flex-between">
              <div className="mt-4 center fs-14 semi-bold mb-3">
                <SvgMonetaryRewardApproval />
                <div className="fs-14 semi-bold ml-2">
                  {t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.MONETARY_REWARDS")}
                </div>
              </div>
              <div
                onClick={() => setShow(true)}
                className="fs-13 fc-blue cursor-pointer"
              >
                {t("COMMON.LABELS.SHOW_SCHEDULED")}
              </div>
              {show && (
                <SchedulePayoutViewModal
                  show={show}
                  onHide={() => setShow(false)}
                  selectedRecurrence={payoutSchedule}
                  currentRecurrence={currentRecurrence}
                />
              )}
            </div>
            <div className="space-y-16">
              {payoutSchedule.map((schedule, index) => {
                if (schedule?.PendingPayout === 0) return null;
                return (
                  <PendingPayoutCard
                    key={index}
                    {...schedule}
                    index={index + 1}
                    payoutAmount={
                      scheduleForm?.[schedule?.RecurrenceId]?.payoutAmount
                    }
                    referenceId={
                      scheduleForm[schedule?.RecurrenceId]?.referenceId
                    }
                    handleAmountChange={handleAmountChange}
                    handleReferenceIdChange={handleReferenceIdChange}
                    validateForm={validateForm}
                    isValidPayout={isValidMonetaryPayout}
                  />
                );
              })}
            </div>
          </>
        ) : null}
        {pendingRewardItems?.length > 0 && (
          <div className="pt-4">
            <div className="d-flex justify-content-between mb-3">
              <div className="center">
                <SvgNonMonetaryRewardApproval />
                <div className="fs-14 semi-bold ml-2">
                  {t(
                    "SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.NON_MONETARY_REWARDS"
                  )}
                </div>
              </div>
              <FormButton
                variant="link"
                label={t("LEADERBOARD.APPROVAL.LABELS.SHOW_REWARD_DETAILS")}
                className="normal"
                onClick={() => setShowRewardDetailsModal(true)}
              />
            </div>
            <div className="fs-13 semi-bold">
              {t("INCENTIVES.PAYOUT.LABELS.PENDING_REWARDS")}
            </div>
            {selectedRewards.length < 1 &&
              form.pendingRewards.showError &&
              !pendingAmtWithRewardsPresent && (
                <div className="fs-12 fc-red-warn mt-2">
                  {t("INCENTIVES.PAYOUT.ERRORS.ERROR_5")}
                </div>
              )}
            <div className="mt-3">{cards()}</div>
          </div>
        )}
        <div className="center mt-5">
          {!payoutDone && paidOutRewards?.length < 1 ? (
            <SendBackToApproval
              disabled={
                isMonetaryPayoutSelected() || selectedRewards.length > 0
              }
              handleSubmit={handleSendBackToApproval}
              programId={programId}
              loading={loading}
            />
          ) : null}
          <FormButton
            className="px-4"
            onClick={() => {
              if (validateForm()) {
                setConfirmationType(CONFIRMATION_TYPES.PAYOUT);
              }
            }}
          >
            {t("INCENTIVES.PAYOUT.LABELS.PROCEED_WITH_PAYOUT")}
          </FormButton>
        </div>
      </form>
      <ConfirmationModal
        loading={loading}
        shimmerText={t("INCENTIVES.COMMON.MESSAGES.YES_PROCEED_TO_PAYOUT")}
        show={Boolean(confirmationType)}
        onClose={() => setConfirmationType("")}
        dialogClassName="confirm-select-template"
        title={t("INCENTIVES.PAYOUT.MESSAGES.PROCEED_INCENTIVE_PAYOUT")}
        body={
          <ConfirmationModalBody
            confirmationType={confirmationType}
            approvedAmount={Object.values(scheduleForm)
              .map((item) => item.payoutAmount)
              .reduce((acc, item) => acc + Number(item.value), 0)}
            isMultipleSchedule={
              Object.values(scheduleForm).filter(
                (item) => item.payoutAmount.value > 0
              )?.length > 1
            }
            rewards={selectedRewards || []}
          />
        }
        onConfirm={handlePayout}
        confirmButtonText={
          confirmationType === CONFIRMATION_TYPES.PAYOUT
            ? t("INCENTIVES.COMMON.MESSAGES.YES_PROCEED_TO_PAYOUT")
            : t("INCENTIVES.COMMON.MESSAGES.YES_PROCEED")
        }
        cancelButton
      />
      <NonMonetaryRewardDetails
        show={showRewardDetailsModal}
        handleClose={() => setShowRewardDetailsModal(false)}
        payload={payloadForRewardsList}
      />
    </div>
  );
}

export default PayoutIncentiveForm;
