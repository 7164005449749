import { CreateInput } from "src/components";

const ProgramDescription = ({
  name,
  value,
  handleChange,
  label,
  placeholder,
}) => {
  const onChange = (value) => {
    handleChange({ name, value });
  };

  return (
    <CreateInput
      id="incentive-create-description-input"
      as="textarea"
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      setValue={onChange}
      regex={false}
      height="124px"
      maxLength={500}
    />
  );
};

export default ProgramDescription;
