import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const COPY_USERS_TO_OTHER_DISTRIBUTION_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation copyLeaderboardUsersAndGroups(
    $SourceRecurrenceId: ID!
    $DestinationRecurrenceIds: [ID]!
  ) {
    copyLeaderboardUsersAndGroups(
      SourceRecurrenceId: $SourceRecurrenceId
      DestinationRecurrenceIds: $DestinationRecurrenceIds
    ) {
      ... on CopyLeaderboardUsersAndGroupsResponse {
        success
        message
        response
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useCopyUserstoOtherCycles = () => {
  return useMutation(COPY_USERS_TO_OTHER_DISTRIBUTION_MUTATION);
};
