import React from "react";

const SvgAvatar39 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="x2xk__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .x2xk__cls-1{fill:#fff}.x2xk__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="x2xk__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="x2xk__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="x2xk__cls-2"
        />
        <path
          d="M37.54 90.8c-.44.25-.81.62-1.06 1.06-.25.44-.39.94-.39 1.45v10.14c0 .24.06.48.17.7l5.12 9.49c.68 1.26 1.84 2.2 3.21 2.62 1.37.41 2.86.27 4.13-.4 7.49-3.98 28.63-14.09 62.91-21.91 3.07-.68 5.73-2.58 7.37-5.27 3.52-5.93 4.04-14.17 4.04-18.56a40.607 40.607 0 0 0-11.9-28.68 40.607 40.607 0 0 0-28.68-11.9c-10.29 0-25.74 4.37-35.43 16.7-.77.98-1.06 2.26-.78 3.48.16.7.49 1.35.96 1.88-6.62 10.67-9.55 22.53-9.67 39.19Zm2.92-.75c-.08-8.22 1.07-16.4 3.4-24.27h23.8c2.98 0 5.77 1.48 7.45 3.95a8.997 8.997 0 0 1 .92 8.38 9.01 9.01 0 0 1-6.42 5.47l-29.16 6.48Zm-1.47 7.61h5.8v4.35h-5.8v-4.35Zm0-4.35c.11 0 .21-.03.31-.06v.03l30.94-6.88c3.85-.85 7.03-3.56 8.48-7.23s1-7.82-1.22-11.08a11.895 11.895 0 0 0-9.85-5.21h-22.8c1.34-3.55 3.04-6.94 5.07-10.14h12.24c.77 0 1.51.31 2.05.85s.85 1.28.85 2.05v2.9a1.451 1.451 0 0 0 2.9 0v-2.9c0-1.54-.61-3.01-1.7-4.1s-2.56-1.7-4.1-1.7H49.58c-.26-.18-.44-.45-.52-.75-.09-.37 0-.76.23-1.06 9.05-11.5 23.52-15.58 33.15-15.58 9.99.01 19.57 3.98 26.63 11.05a37.725 37.725 0 0 1 11.05 26.63c0 3.29-.35 11.55-3.65 17.08a8.603 8.603 0 0 1-5.52 3.92c-34.61 7.89-56.02 18.13-63.62 22.17-.59.31-1.28.38-1.92.19-.64-.19-1.18-.63-1.5-1.22s-3.97-7.36-3.97-7.36h4.82c.77 0 1.51-.31 2.05-.85s.85-1.28.85-2.05v-4.35c0-.77-.31-1.51-.85-2.05-.54-.54-1.28-.85-2.05-.85h-5.8v-1.45Z"
          className="x2xk__cls-1"
        />
        <path
          d="M72.29 70.17c-.95-.95-2.24-1.49-3.59-1.49s-2.64.53-3.59 1.49c-.95.95-1.49 2.24-1.49 3.59 0 1.35.53 2.64 1.49 3.59.95.95 2.24 1.49 3.59 1.49s2.64-.53 3.59-1.49c.95-.95 1.49-2.24 1.49-3.59 0-1.35-.53-2.64-1.49-3.59Zm-5.76 3.59c0-1.2.97-2.17 2.17-2.17a2.172 2.172 0 1 1-.83 4.18 2.18 2.18 0 0 1-1.34-2.01Z"
          className="x2xk__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar39.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar39;
