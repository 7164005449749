import { gql, useMutation } from "src/api/client";

const SCHEDULE_PAYOUT = gql`
  mutation schedulePayout(
    $ProgramId: ID!
    $RecurrenceId: ID!
    $PayoutSchedule: JSON!
  ) {
    schedulePayout(
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      PayoutSchedule: $PayoutSchedule
    ) {
      message
      success
    }
  }
`;

export const useIncentiveSchedulePayout = () => {
  return useMutation(SCHEDULE_PAYOUT);
};
