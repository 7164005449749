import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useSalesActivityStatus } from "src/api/useTemplate";
import { BaseCard, Shimmer } from "src/components/common/atoms";
import { MobileRuleCard } from "src/components/common/molecules";
import { range } from "src/helpers";

const MobileRulesGrid = (props) => {
  const { templateDetails } = props;
  const [activeKey, setActiveKey] = useState(0);
  const {
    loading,
    data: { getSalesActivityStatus } = { getSalesActivityStatus: {} },
  } = useSalesActivityStatus({
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div className="space-y-16 pb-3">
        <BaseCard className="flex-column">
          <div className="m-3">
            <Shimmer width="50%" />
          </div>
          {range(0, 4).map((i) => (
            <div className="m-3 pt-3 border-t">
              <Shimmer width="20%" />
              <Shimmer width="70%" className="mt-2" />
            </div>
          ))}
        </BaseCard>
        {range(0, 2).map(() => {
          return (
            <BaseCard className="center">
              <Shimmer width="50%" className="m-3" />
            </BaseCard>
          );
        })}
      </div>
    );

  return (
    <Accordion defaultActiveKey={String(activeKey)} className="space-y-16 pb-3">
      {templateDetails.GoalRules.map((goalRule, index) => (
        <MobileRuleCard
          goalRule={goalRule}
          index={index}
          handleClick={(idx) =>
            setActiveKey((prev) => (prev === idx ? null : idx))
          }
          active={activeKey === index}
          getSalesActivityStatus={getSalesActivityStatus}
          templateDetails={templateDetails}
        />
      ))}
    </Accordion>
  );
};

export default MobileRulesGrid;
