import { useCache } from "src/api/client";
import { useSaveUserPreferences } from "src/api/useUserPreferences/mutations/useSaveUserPreferences";
import { GET_USER_PREFERENCES_QUERY } from "src/api/useUserPreferences/query/useGetUserPreferences";

const useUpdateUserPreferences = () => {
  const { readFromCache } = useCache();
  const [updateUserPreferences] = useSaveUserPreferences({
    update: (cache, { data }) => {
      if (data?.saveUserPreferences?.__typename === "UserPreferencesResponse") {
        const { userPreferences } = cache.readQuery({
          query: GET_USER_PREFERENCES_QUERY,
        });
        cache.writeQuery({
          query: GET_USER_PREFERENCES_QUERY,
          data: {
            userPreferences: {
              ...userPreferences,
              response: {
                ...userPreferences.response,
                ...data.saveUserPreferences.response,
              },
            },
          },
        });
      }
    },
  });

  const data = readFromCache({
    apiName: "userPreferences",
    query: GET_USER_PREFERENCES_QUERY,
  });

  const userPreferences = data?.userPreferences?.response;

  const handleUpdateUserPreferences = async (config) => {
    await updateUserPreferences({
      variables: {
        ...config,
      },
    });
  };

  const updateTemplateConfig = (newTemplateConfig) => {
    const config = {
      ...userPreferences,
      templateConfig: {
        ...userPreferences?.templateConfig,
        ...newTemplateConfig,
      },
    };
    handleUpdateUserPreferences(config);
  };

  const updateGoalsConfig = (newGoalsConfig) => {
    const config = {
      ...userPreferences,
      goalsConfig: { ...userPreferences?.goalsConfig, ...newGoalsConfig },
    };

    return handleUpdateUserPreferences(config);
  };

  const updateIncentivesConfig = (newIncentivesConfig) => {
    const config = {
      ...userPreferences,
      incentivesConfig: {
        ...userPreferences?.incentivesConfig,
        ...newIncentivesConfig,
      },
    };
    handleUpdateUserPreferences(config);
  };

  const updateLeaderboardConfig = (newLeaderboardConfig) => {
    const config = {
      ...userPreferences,
      leaderboardConfig: {
        ...userPreferences?.leaderboardConfig,
        ...newLeaderboardConfig,
      },
    };
    handleUpdateUserPreferences(config);
  };

  return {
    updateTemplateConfig,
    updateGoalsConfig,
    updateIncentivesConfig,
    updateLeaderboardConfig,
    userPreferences,
  };
};
export { useUpdateUserPreferences };
