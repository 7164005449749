import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgGroups = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23.592 19.72"
      role="img"
      {...rest}
    >
      <g
        id="Leaderboard_Groups"
        data-name="Leaderboard Groups"
        transform="translate(0.16 -2.36)"
      >
        <path
          id="Path_8864"
          data-name="Path 8864"
          d="M8.116,18.042H6.45V16.221a5.514,5.514,0,0,1,5.511-5.511h0a5.514,5.514,0,0,1,5.511,5.511v1.821h-1.5"
          transform="translate(-0.189 0.756)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
        />
        <path
          id="Path_8865"
          data-name="Path 8865"
          d="M3.762,18.07H.44v-1.6A4.848,4.848,0,0,1,5.292,11.62h0"
          transform="translate(0 0.727)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
        />
        <circle
          id="Ellipse_257"
          data-name="Ellipse 257"
          cx={1.714}
          cy={1.714}
          r={1.714}
          transform="translate(16.266 6.253)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
        />
        <circle
          id="Ellipse_258"
          data-name="Ellipse 258"
          cx={1.714}
          cy={1.714}
          r={1.714}
          transform="translate(3.578 6.253)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
        />
        <path
          id="Path_8866"
          data-name="Path 8866"
          d="M20.08,18.07H23.4v-1.6A4.848,4.848,0,0,0,18.55,11.62h0"
          transform="translate(-0.57 0.727)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
        />
        <path
          id="Path_8867"
          data-name="Path 8867"
          d="M12.027,2.96l1.065,2.169,2.4.349L13.761,7.163l.407,2.383-2.14-1.123L9.887,9.546l.407-2.383L8.56,5.478l2.392-.349Z"
          transform="translate(-0.256)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
        />
        <g
          id="Group_15166"
          data-name="Group 15166"
          transform="translate(10.561 17.587)"
        >
          <line
            id="Line_373"
            data-name="Line 373"
            y2={2.412}
            transform="translate(1.211)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.2}
          />
          <line
            id="Line_374"
            data-name="Line 374"
            x1={2.421}
            transform="translate(0 1.211)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.2}
          />
        </g>
        <circle
          id="Ellipse_259"
          data-name="Ellipse 259"
          cx={2.683}
          cy={2.683}
          r={2.683}
          transform="translate(9.089 16.115)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
        />
      </g>
    </svg>
  );
};

SvgGroups.defaultProps = {
  width: 23.592,
  height: 19.72,
  color: COLORS.PRIMARY_BLUE,
};

export default SvgGroups;
