export const REGEX_FOR_NUMBERS = /^\d+$/g;
export const CREATE_PROGRAM_REGEX = /^[A-Za-z0-9_\- ]+$/g;
export const REGEX_FOR_POSITIVE_NUMBERS = /\\D+/g;
export const REGEX_FOR_NUMBER_UPTO_TWO_DECIMAL_PLACES_ONLY =
  /^(\d*(.[0-9]{1,2})?)$/gm;
export const REGEX_FOR_MINIMUM_FRACTIONAL_VALUE = /0\.0{5}[0-9]{2,}/gm;
export const REGEX_FOR_CREATE_NUMBERS = /^-?(\d*(\.[0-9]{1,2})?)$/gm;
export const REGEX_FOR_VALID_RULE_NAME = /[A-Za-z]/g;
export const REGEX_FOR_OPTIONAL_RULE_NAME_CHARACTERS = /^[A-Za-z0-9_\- ]+$/g;
export const REGEX_FOR_NUMBER_CHECK = /^\d*$/;
export const REGEX_FOR_VALID_VARIABLE_NAME = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/gm;
export const PARANTHESIS_REGEX = /[()]/g;
export const OPERATOR_REGEX = /^[?:+*/-]$/g;
export const NUMBER_REGEX = /^\d$/g;
export const REWARD_LINK_REGEX =
  /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/gi;
export const REGEX_FOR_TRAILING_SLASH = /\/+$/;
export const REGEX_FOR_VALID_UUID =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gim;
export const REGEX_FOR_NAME = /^[A-Za-z0-9_\- ]+$/g;
export const STATIC_REGEX_FOR_BETWEEN_FUNCTION =
  /\d+(\.\d+)?\s*and\s*\d+(\.\d+)?/gi;
export const VALID_FILE_NAME_REGEX = /^[a-zA-Z0-9, _-]*[.]{1}[a-zA-Z]*$/g;
