import { gql, useQuery } from "src/api/client";

const GET_LEADERBOARD_TOTALS = gql`
  query programTotals($programId: ID!, $UserId: ID) {
    programTotals(programId: $programId, UserId: $UserId) {
      ... on ProgramTotalsResponse {
        message
        success
        response {
          totalApprovedAmount
          TotalApprovedRewards {
            RewardCount
            RewardCategory
          }

          totalPaidOut
          TotalPaidOutRewards {
            RewardCount
            RewardCategory
          }

          totalEligibleAmount
          TotalEligibleRewards {
            RewardCount
            RewardCategory
          }

          totalPayoutPending
          TotalPayoutPendingRewards {
            RewardCount
            RewardCategory
          }

          totalApprovalPending
          TotalApprovalPendingRewards {
            RewardCount
            RewardCategory
          }

          totalApprovalRejected
          TotalApprovalRejectedRewards {
            RewardCount
            RewardCategory
          }
        }
      }
    }
  }
`;

export const useGetLeaderboardTotals = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_TOTALS, queryOptions);
};
