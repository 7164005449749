import React from "react";
import { Image } from "src/components";
import ExportGoalIllustrationImg from "./ExportGoalIllustration.svg";

const ExportGoalIllustration = (props) => (
  <Image
    src={ExportGoalIllustrationImg}
    alt="ExportGoalIllustrationImg"
    {...props}
  />
);

export default ExportGoalIllustration;
