import { t } from "i18next";
import React, { useState } from "react";
import {
  FormButton,
  FormTooltip,
  RecurrenceDropdown,
  SearchBar,
  Shimmer,
  TemplateDetailModal,
  Truncate,
} from "src/components";
import {
  APPS,
  COLORS,
  GOAL_AGGREGATION_TYPES,
  RULESET_TYPES,
} from "src/constants";
import {
  NumFormatter,
  getColor,
  getPercentage,
  getUserTimezone,
} from "src/helpers";
import RulesDropDown from "./RulesDropDown";
const UserDropDownShimmer = () => {
  return (
    <div className="w-200">
      <div className="ml-1 fs-13 fc-grey mt-3 ml-3">
        {t("COMMON.LABELS.USER")}
      </div>
      <div className="d-inline-flex align-items-center ml-3 mt-1">
        <Shimmer width="100px" height="20px" />
      </div>
    </div>
  );
};
const AchievementBreakDownHeader = (props) => {
  const {
    recurrencesList,
    selectedRecurrence,
    setSelectedRecurrence,
    setSearch,
    templateDetails,
    setSelectedRule,
    goalValue,
    goalAchieved,
    selectedGoalUser,
    customType = false,
    templateLoading,
    usersAchievementLoading,
    selectedRule,
    formatOptionLabelAfter = () => false,
    goalAggConfig,
    hideUserDropDown,
    moduleName,
  } = props;

  const isIndianTimezone = getUserTimezone();
  const [showRuleModal, setShowRuleModal] = useState(false);

  const showGoalAchieved = goalAchieved !== null && goalAchieved !== undefined;
  const showGoalValue =
    goalValue !== null &&
    goalValue !== undefined &&
    goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED;

  return (
    <div className="px-4 flex-between h-80 bg-grey5">
      <div className="d-flex mr-24 justify-content-between">
        {recurrencesList && selectedRecurrence && (
          <div className="align-self-center">
            <RecurrenceDropdown
              id="breakdown-cycledropdown"
              recurrences={recurrencesList}
              selectedRecurrence={selectedRecurrence}
              handleChange={setSelectedRecurrence}
              customType={customType}
              formatOptionLabelAfter={formatOptionLabelAfter}
              readOnly={
                moduleName ===
                APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.name
              }
            />
          </div>
        )}

        {!hideUserDropDown &&
          (!usersAchievementLoading ? (
            <div className="flex flex-column align-self-center ml-2">
              <div className="w-200 fs-13 fc-grey">
                {t("COMMON.LABELS.USER")}
              </div>
              <div className="d-inline-flex align-items-center">
                <div className="fc-blue fs-14 semi-bold">
                  <Truncate text={selectedGoalUser.FullName} width="160px" />
                </div>
              </div>
            </div>
          ) : (
            <UserDropDownShimmer />
          ))}

        <div
          className={`align-self-center fs-13 mr-24 w-90 ${
            hideUserDropDown && "ml-4"
          }`}
        >
          <div className="fc-grey1 flex-start mb-1">
            {t("COMMON.LABELS.ACHIEVEMENT")}
          </div>
          <span className=" fs-14 semi-bold">
            {usersAchievementLoading ? (
              <Shimmer width="90px" height="20px" />
            ) : (
              <>
                {showGoalAchieved ? (
                  <FormTooltip text={goalAchieved} placement="bottom">
                    <span
                      style={{
                        color:
                          goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED
                            ? getColor(getPercentage(goalAchieved, goalValue))
                            : COLORS.BLACK,
                      }}
                    >
                      {NumFormatter(goalAchieved, isIndianTimezone)}
                    </span>
                  </FormTooltip>
                ) : (
                  <span className="fc-grey">--</span>
                )}
                {showGoalValue && (
                  <FormTooltip text={goalValue} placement="bottom">
                    <span className="fc-grey">
                      /{NumFormatter(goalValue, isIndianTimezone)}
                    </span>
                  </FormTooltip>
                )}
              </>
            )}
          </span>
        </div>
        <div className="align-self-center fs-13 mr-24">
          <span className="fc-grey1 flex-start mb-1">
            {t("RULEBOOK.LABELS.RULEBOOK")}
          </span>
          {!templateLoading ? (
            <span className=" fs-14 semi-bold ">
              <FormButton
                variant="link"
                label={
                  <Truncate
                    width="180px"
                    truncType="end"
                    text={templateDetails?.Name}
                    showTooltip
                  />
                }
                onClick={() => setShowRuleModal(true)}
              />
            </span>
          ) : (
            <Shimmer width="180px" height="20px" />
          )}
        </div>
        <RulesDropDown
          rules={templateDetails?.GoalRules || []}
          setSelectedRule={setSelectedRule}
          loading={templateLoading}
          selectedRule={selectedRule}
        />
      </div>
      <div className="d-flex align-items-center mr-24 ">
        <SearchBar
          className="achievement-contribution"
          placeholder={t("MANAGED_GOALS.LABELS.SEARCH_USERS")}
          setSearch={setSearch}
          variant="primary"
          backgroundColor="#fff"
          height={40}
          width={150}
        />
      </div>
      {showRuleModal && (
        <TemplateDetailModal
          name={templateDetails?.Name}
          goalAggregateFunction={templateDetails?.GoalAggregateFunction}
          description={templateDetails?.Description}
          goalRules={templateDetails?.GoalRules}
          isCustomFormula={templateDetails?.IsCustomFormula}
          formula={templateDetails?.Formula}
          onShowClick={showRuleModal}
          onCancelClick={() => setShowRuleModal(false)}
          groupBy={templateDetails?.GroupBy}
          isAdvancedRuleset={
            templateDetails?.RulesetType === RULESET_TYPES.ADVANCED
          }
        />
      )}
    </div>
  );
};

export default AchievementBreakDownHeader;
