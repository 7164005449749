import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgCustomFormula = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 13"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M4.801.54h-4a.8.8 0 0 0-.8.8v4a.8.8 0 0 0 .8.8h4a.8.8 0 0 0 .8-.8v-4a.8.8 0 0 0-.8-.8Zm0 3.2h-4v-.8h4v.8Zm6.4-3.2h-4a.8.8 0 0 0-.8.8v10.4a.8.8 0 0 0 .8.8h4a.8.8 0 0 0 .8-.8V1.34a.8.8 0 0 0-.8-.8Zm0 7.2h-4v-.8h4v.8Zm0-2.4h-4v-.8h4v.8Zm-6.4 1.6h-4a.8.8 0 0 0-.8.8v4a.8.8 0 0 0 .8.8h4a.8.8 0 0 0 .8-.8v-4a.8.8 0 0 0-.8-.8Zm0 3.2h-1.6v1.6h-.8v-1.6h-1.6v-.8h1.6v-1.6h.8v1.6h1.6v.8Z"
      />
    </svg>
  );
};
SvgCustomFormula.defaultProps = {
  color: COLORS.GREY_2,
  height: 13,
  width: 12,
};
export default SvgCustomFormula;
