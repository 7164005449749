import { t } from "i18next";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { groupByConditionValid } from "src/apps/incentives/utils";
import { FormButton, FormInput, SelectFilter } from "src/components";
import { MAX_GROUP_BY_CONDITION } from "src/constants";
import { generateKeyValuePair, isNumber } from "src/helpers";
import { SvgDelete } from "src/icons";
import "./styles.scss";

const GroupByInput = ({
  groupByEntities,
  groupByValues,
  index,
  handleChange,
  groupByCondition,
  handleDelete,
  handleAddCondition,
  disableAddCondition,
  isEditable,
}) => {
  const groupByValue = groupByEntities.find(
    (item) => item.Key === groupByCondition.Key
  );
  const groupByOptionValue =
    groupByValues &&
    (groupByValues?.find((item) => item.Key === groupByCondition.Value) ||
      null);

  const handleInputChange = ({ Key, Value }) => {
    handleChange({ ...groupByCondition, Key, Value, ConditionId: index });
  };

  return (
    <div className="flex w-100 mt-4 p-3 group-by-input">
      <SelectFilter
        placeholder={t("INCENTIVES.COMMON.LABELS.SELECT_GROUP_BY")}
        options={groupByEntities}
        optionLabelKey={"Key"}
        optionValueKey={"Value"}
        onChange={({ Key }) => handleInputChange({ Key, Value: "" })}
        value={groupByValue}
        className="w-30 h-40"
        disabled={!isEditable}
      />
      {groupByValues ? (
        <SelectFilter
          options={groupByValues}
          placeholder={
            groupByCondition.Key
              ? `${t("RULEBOOK.LABELS.SELECT")} ${groupByCondition.Key}`
              : t("RULEBOOK.LABELS.SELECT")
          }
          onChange={({ Key }) =>
            handleInputChange({ Value: Key, Key: groupByCondition.Key })
          }
          value={groupByOptionValue}
          className="ml-3 w-50 h-40"
          optionLabelKey={"Key"}
          optionValueKey={"Value"}
          disabled={!groupByCondition.Key || !isEditable}
        />
      ) : (
        <FormInput
          placeholder={
            groupByCondition.Key
              ? `${t("RULEBOOK.LABELS.SELECT")} ${groupByCondition.Key}`
              : t("COMMON.LABELS.TEXT")
          }
          onChange={(e) => {
            handleInputChange({
              Value: e.target.value,
              Key: groupByCondition.Key,
            });
          }}
          value={groupByCondition?.Value}
          className="ml-3 w-50 h-40"
          disabled={!groupByCondition.Key || !isEditable}
        />
      )}
      {isEditable && index !== 1 && (
        <FormButton
          icon={<SvgDelete />}
          className="delete-button ml-3 p-1 px-2"
          onClick={() => handleDelete(index)}
          variant="outline-primary"
        />
      )}
      {groupByCondition?.Key &&
      groupByCondition?.Value?.trim() &&
      !disableAddCondition &&
      isEditable ? (
        <FormButton
          className="delete-button ml-2 p-1 px-3"
          onClick={() => handleAddCondition(index, "AND")}
          variant="outline-primary"
          label="AND"
        />
      ) : null}
    </div>
  );
};

const GroupByModal = ({
  show,
  setShowModal,
  modalTitle,
  modalSubTitle,
  modalDescription,
  subGoals,
  setSubGoals,
  isEditable = true,
  isCreating = false,
  groupByEntities,
  groupByValues,
}) => {
  const [groupByFields, setGroupByFields] = useState({
    SubGoalConditions: [...subGoals?.SubGoalConditions],
    ConditionPattern: subGoals?.ConditionPattern?.replace(/[()]/g, "").split(
      " "
    ),
  });

  const enableSubmit = groupByConditionValid(groupByFields);

  const handleChange = (groupByField) => {
    const tempGroupByFields = { ...groupByFields };
    tempGroupByFields.SubGoalConditions[groupByField.ConditionId - 1] =
      groupByField;
    setGroupByFields(tempGroupByFields);
  };

  const handleAddCondition = (index, connector) => {
    const tempGroupByFields = { ...groupByFields };
    if (tempGroupByFields.SubGoalConditions.length < MAX_GROUP_BY_CONDITION) {
      tempGroupByFields.SubGoalConditions[index] = {
        Key: "",
        Value: "",
        ConditionId: index + 1,
      };
      tempGroupByFields["ConditionPattern"].push(connector, index + 1);
      setGroupByFields(tempGroupByFields);
    }
  };

  const handleDelete = (index) => {
    if (index > 1) {
      const tempSubGoals = { ...groupByFields };
      tempSubGoals.SubGoalConditions.splice(index - 1, 1);
      tempSubGoals.ConditionPattern.splice(index - 1, 2);
      setGroupByFields(tempSubGoals);
    }
  };

  const handleSubmit = () => {
    const tempGroupByFields = { ...groupByFields };
    tempGroupByFields.ConditionPattern = `(${tempGroupByFields.ConditionPattern.join(
      " "
    )})`;
    setSubGoals({
      ...tempGroupByFields,
      SubGoalConditions: tempGroupByFields?.SubGoalConditions?.map(
        ({ Key, Value, ConditionId }) => ({
          Key,
          Value: Value?.trim(),
          ConditionId,
        })
      ),
      enableSubmit,
    });

    setShowModal(false);
  };
  const conditions = groupByFields?.SubGoalConditions?.reduce(
    (acc, subGoal) => {
      acc[subGoal.ConditionId] = {
        Key: subGoal.Key,
        Value: subGoal.Value,
        ConditionId: subGoal.ConditionId,
      };
      return acc;
    },
    {}
  );

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      centered
      dialogClassName="group-by-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="group-by-modal-body p-4">
        <div className="flex flex-column">
          <span className="fs-14 semi-bold">{modalSubTitle}</span>
          <span className="fs-13 fc-grey1 mt-2">{modalDescription}</span>
        </div>
        <div>
          {groupByFields?.ConditionPattern?.length > 0 &&
            groupByFields?.ConditionPattern?.map((condition) => {
              if (isNumber(Number(condition)) && conditions[condition]) {
                return (
                  <GroupByInput
                    handleChange={handleChange}
                    index={Number(condition)}
                    groupByCondition={conditions[condition]}
                    handleDelete={handleDelete}
                    handleAddCondition={handleAddCondition}
                    disableAddCondition={
                      Number(condition) <
                        Number(groupByFields?.ConditionPattern?.slice(-1)[0]) ||
                      Number(groupByFields?.ConditionPattern?.slice(-1)[0]) >=
                        MAX_GROUP_BY_CONDITION
                    }
                    groupByEntities={generateKeyValuePair(
                      groupByEntities.split(",")
                    )}
                    groupByValues={
                      conditions[condition]?.Key &&
                      groupByValues &&
                      generateKeyValuePair(
                        groupByValues[conditions[condition]?.Key]
                      )
                    }
                    isEditable={isEditable}
                  />
                );
              }
              return (
                <div className="position-relative h-40">
                  <div className="top-border">|</div>
                  <div className="condition-pattern fc-grey1 fs-12 p-1 px-2">
                    {condition}
                  </div>
                  <div className="bottom-border">|</div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      {isEditable && (
        <Modal.Footer>
          <FormButton
            variant="outline-primary"
            onClick={() => setShowModal(false)}
            className="px-3"
          >
            {t("COMMON.BUTTONS.CANCEL")}
          </FormButton>
          <FormButton
            className="px-4 ml-4"
            onClick={handleSubmit}
            disabled={!enableSubmit}
          >
            {isCreating ? t("COMMON.BUTTONS.ADD") : t("COMMON.BUTTONS.UPDATE")}
          </FormButton>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default GroupByModal;
