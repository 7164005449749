import React from "react";
import { Image } from "src/components";
import MyShowcaseEmptyStateImg from "./MyShowcaseEmptyState.png";

const MyShowcaseEmptyState = (props) => (
  <Image
    src={MyShowcaseEmptyStateImg}
    alt="MyShowcaseEmptyStateImg"
    {...props}
  />
);

export default MyShowcaseEmptyState;
