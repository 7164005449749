import React from "react";

const SvgAvatar26 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="ts4a__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .ts4a__cls-1{fill:#fff}.ts4a__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="ts4a__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8a81.173 81.173 0 0 1-26.58 18.18c-.57.25-1.15.49-1.73.71-.4.16-.79.32-1.19.47-.57.22-1.15.43-1.74.63-1.56.55-3.14 1.05-4.74 1.51-.71.2-1.42.4-2.14.57-.76.2-1.52.39-2.29.55-.7.17-1.41.31-2.12.45-5.1 1-10.37 1.53-15.76 1.53-22.86 0-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="ts4a__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2a81.305 81.305 0 0 1-31.93 20.23c-.56.2-1.13.38-1.69.57-.6.18-1.2.37-1.8.54-.56.16-1.12.32-1.68.47a81.328 81.328 0 0 1-21.19 2.79c-22.86 0-43.52-9.43-58.3-24.6 16.3-12.06 36.47-19.19 58.3-19.19 7.27 0 14.37.79 21.19 2.3.56.12 1.12.25 1.68.39.6.13 1.2.28 1.8.44.57.14 1.13.3 1.69.46a97.512 97.512 0 0 1 31.93 15.6Z"
          className="ts4a__cls-2"
        />
        <path
          d="M78.08 64.7c2.18 0 4.7-.58 7.48-1.73.28-.11.47-.36.52-.65a.87.87 0 0 0-.27-.79l-.37-.34c-2.22-2.03-5.25-4.81-9.08-4.81-1.99 0-4 .75-5.97 2.24-.29.22-.41.58-.31.93.02.05 1.53 5.15 8.01 5.15Zm-1.72-6.59c2.83 0 5.25 1.95 7.25 3.75-2.05.73-3.91 1.1-5.53 1.1-4 0-5.59-2.27-6.11-3.34 1.5-1.02 2.94-1.51 4.39-1.51Z"
          className="ts4a__cls-1"
        />
        <path
          d="M63.19 62c.39.12.82-.06 1.01-.42a.89.89 0 0 0-.21-1.08c-1.05-.87-2.48-2.51-3.75-5.53.62.12 1.39.21 2.33.21 1.95 0 4.19-.37 6.66-1.11l.74-.22c2.81-.84 5.23-1.57 7.63-1.57 2.71 0 6.46.83 11.53 7.28.16.21.42.33.68.33s.52-.12.68-.33c5.07-6.45 8.82-7.28 11.53-7.28 2.4 0 4.82.73 7.63 1.57l.74.22c2.47.74 4.71 1.11 6.66 1.11.94 0 1.72-.09 2.33-.21-1.27 3.02-2.7 4.66-3.75 5.53-.32.26-.4.71-.21 1.08.19.36.62.54 1.01.42 1.21-.36 2.15-.65 2.87-.89-1.62 2.98-4.29 6.51-8.48 8.75 0 0-.01 0-.02.01-.38.2-.77.39-1.17.56-.07.03-.14.06-.2.09-2.24.96-4.87 1.55-7.95 1.55-.86 0-1.75-.05-2.64-.14-4.41-.44-8.35-4.7-8.39-4.74a.863.863 0 0 0-.64-.28c-.24 0-.48.1-.64.28-.04.04-3.97 4.3-8.39 4.74-.9.09-1.78.14-2.64.14-9.96 0-15.24-6.19-17.83-10.96.72.24 1.66.54 2.87.89Zm58.47-3.11a.882.882 0 0 0-.99-.15c0 .01-.43.22-1.71.65.93-1.32 1.89-3.12 2.72-5.56a.85.85 0 0 0-.29-.95.872.872 0 0 0-1.01-.05c-.01 0-.99.61-3.31.61-1.78 0-3.86-.35-6.17-1.04l-.74-.22c-2.81-.84-5.47-1.64-8.13-1.64-4.36 0-8.15 2.2-12.21 7.1-4.06-4.9-7.85-7.1-12.21-7.1-2.66 0-5.32.8-8.13 1.64l-.74.22c-2.31.69-4.39 1.04-6.17 1.04-2.31 0-3.3-.6-3.3-.6a.887.887 0 0 0-1.02.02.88.88 0 0 0-.29.97c.83 2.44 1.79 4.24 2.72 5.56-1.28-.43-1.7-.64-1.71-.64a.851.851 0 0 0-.99.14c-.27.25-.35.64-.2.98 2.3 5.21 8.01 13.94 20.37 13.94.92 0 1.86-.05 2.82-.15 3.98-.4 7.46-3.32 8.85-4.65 1.39 1.33 4.87 4.25 8.86 4.65.95.1 1.9.15 2.81.15 2.61 0 4.92-.39 6.96-1.06v85.44c.59-.2 1.17-.41 1.74-.63V72.1l.02-.01c.4-.17.79-.35 1.17-.55v85.55c.58-.22 1.16-.46 1.73-.71V70.54c4.67-3.02 7.35-7.5 8.75-10.67.15-.34.07-.73-.2-.98Z"
          className="ts4a__cls-1"
        />
        <path
          d="M94.08 62.97c2.78 1.15 5.3 1.73 7.48 1.73 6.48 0 7.99-5.1 8.01-5.15.1-.34-.03-.71-.31-.93-1.98-1.49-3.99-2.24-5.97-2.24-3.83 0-6.86 2.78-9.08 4.8l-.37.34a.866.866 0 0 0 .25 1.44Zm9.2-4.85c1.45 0 2.89.49 4.39 1.51-.53 1.08-2.12 3.34-6.11 3.34-1.62 0-3.47-.37-5.53-1.1 2-1.8 4.41-3.75 7.25-3.75Z"
          className="ts4a__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar26.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar26;
