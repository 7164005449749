import { Shimmer } from "src/components/common/atoms";
import { LoadingTable } from "../loadingTable";

const LoadingPayoutGrid = () => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-end mb-3 p-3 bg-secondary-blue">
        <div>
          <Shimmer width="70px" height="10px" />
          <div className="mt-2">
            <Shimmer width="150px" height="40px" />
          </div>
        </div>
        <div className="mx-3">
          <Shimmer width="150px" height="10px" />
          <div className="mt-2">
            <Shimmer width="100px" height="30px" />
          </div>
        </div>
        <div className="ml-auto">
          <div className="mt-2">
            <Shimmer width="150px" height="40px" />
          </div>
        </div>
      </div>
      <div className="center px-3 mb-4">
        <Shimmer width="70px" height="10px" />
        <Shimmer width="70px" height="10px" />
        <Shimmer width="70px" height="10px" />
      </div>
      <div className="ml-3">
        <LoadingTable />
      </div>
    </div>
  );
};

export default LoadingPayoutGrid;
