import { gql, useMutation } from "src/api/client";

export const DELETE_GOAL_RULE = gql`
  mutation deleteGoalRule($TemplateId: ID!, $RuleId: ID!) {
    deleteGoalRule(TemplateId: $TemplateId, RuleId: $RuleId) {
      ... on SingleGoalRuleResponse {
        response {
          GoalRule {
            RuleId
          }
        }
        success
        message
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useDeleteGoalRule = () => {
  return useMutation(DELETE_GOAL_RULE);
};
