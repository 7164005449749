import React from "react";

const SvgAvatar4 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="ah0o__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .ah0o__cls-1{fill:none}.ah0o__cls-2{fill:#fff}.ah0o__cls-3{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="ah0o__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08a81.128 81.128 0 0 1-18.79 13.43c-.81.43-1.63.83-2.46 1.23a80.46 80.46 0 0 1-26.28 7.39c-.81.09-1.63.17-2.45.22-1.56.13-3.14.2-4.73.23-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-1.58-.03-3.16-.1-4.72-.23-.82-.05-1.64-.13-2.46-.22a80.472 80.472 0 0 1-26.27-7.39c-.83-.39-1.65-.8-2.46-1.23a81.243 81.243 0 0 1-18.81-13.43c-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="ah0o__cls-3"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.41 72.08)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="ah0o__cls-3"
        />
        <path
          d="m75.53 140.26 1.4-30.39c.06-1.36-.42-2.65-1.36-3.63-.94-.98-2.2-1.52-3.56-1.52H50.42c-1.39 0-2.67.56-3.62 1.58a4.919 4.919 0 0 0-1.3 3.73l.16 2.08 1.48 17.35c.06.68-.44 1.27-1.12 1.33h-.11c-.63 0-1.17-.49-1.23-1.13l-1.34-15.79-1.87.62a6.582 6.582 0 0 0-3.87 3.39 6.584 6.584 0 0 0-.29 5.13l6.71 18.82c.33.92.89 1.72 1.63 2.33h27.93a5.384 5.384 0 0 0 1.95-3.9ZM125.49 123c.6-1.69.5-3.52-.29-5.13a6.582 6.582 0 0 0-3.87-3.39l-1.87-.62-1.34 15.79c-.06.64-.59 1.13-1.23 1.13h-.11c-.68-.06-1.18-.65-1.12-1.33l1.48-17.35.16-2.08c.11-1.38-.36-2.71-1.3-3.73a4.874 4.874 0 0 0-3.62-1.58H90.79c-1.36 0-2.63.54-3.56 1.52-.94.98-1.42 2.27-1.36 3.63l1.4 30.39a5.33 5.33 0 0 0 1.95 3.9h27.93c.74-.62 1.3-1.42 1.63-2.33l6.71-18.82Z"
          className="ah0o__cls-1"
        />
        <path
          d="M75.2 145.86c.11-.05.21-.11.3-.17.4-.26.72-.64.99-1.03 1.8-2.53 1.57-5.71 1.7-8.67l.41-8.78.75-16.17c.02-.36.03-.71.05-1.07a7.35 7.35 0 0 0-2.04-5.45 7.358 7.358 0 0 0-5.35-2.29H50.42c-2.05 0-4.03.87-5.42 2.37a7.387 7.387 0 0 0-1.95 5.59l.09 1.13-2.45.81a9.02 9.02 0 0 0-5.3 4.65 9.023 9.023 0 0 0-.39 7.04c1.1 3.08 2.19 6.15 3.29 9.23.76 2.15 1.53 4.29 2.29 6.44.72 2.01 1.25 4.83 3.08 6.1 1.05.73 2.41.81 3.7.85 8.03.25 16.08.07 24.11.07 1.05 0 2.72-.16 3.74-.66Zm-29.54-1.7c-.74-.62-1.3-1.42-1.63-2.33l-6.71-18.82c-.6-1.69-.5-3.52.29-5.13a6.582 6.582 0 0 1 3.87-3.39l1.87-.62 1.34 15.79c.05.64.59 1.13 1.23 1.13h.11c.68-.06 1.18-.65 1.12-1.33l-1.48-17.35-.16-2.08c-.11-1.38.36-2.71 1.3-3.73a4.874 4.874 0 0 1 3.62-1.58h21.59c1.36 0 2.63.54 3.56 1.52.94.98 1.42 2.27 1.36 3.63l-1.4 30.39a5.33 5.33 0 0 1-1.95 3.9H45.66ZM118.8 145.99a7.706 7.706 0 0 0 2.3-3.34l6.71-18.82c.83-2.32.69-4.82-.39-7.04a9.02 9.02 0 0 0-5.3-4.65l-2.45-.81.09-1.13c.16-2.05-.56-4.08-1.95-5.59a7.425 7.425 0 0 0-5.42-2.37H90.8c-2.04 0-3.94.82-5.35 2.29a7.35 7.35 0 0 0-2.04 5.45l1.41 30.39c.1 2.19 1.09 4.19 2.74 5.6l6.91.55h18.49l5.83-.53Zm-1.65-1.83H89.22a5.384 5.384 0 0 1-1.95-3.9l-1.4-30.39c-.06-1.36.42-2.65 1.36-3.63.94-.98 2.2-1.52 3.56-1.52h21.59c1.39 0 2.67.56 3.62 1.58.94 1.02 1.4 2.34 1.3 3.73l-.16 2.08-1.48 17.35c-.06.68.44 1.27 1.12 1.33h.11c.63 0 1.17-.49 1.23-1.13l1.34-15.79 1.87.62c1.71.57 3.08 1.77 3.87 3.39s.89 3.44.29 5.13l-6.71 18.82c-.33.92-.89 1.72-1.63 2.33Z"
          className="ah0o__cls-2"
        />
        <path
          d="M52.69 157.59v-12.34h-2V156.8l2 .79zM70.06 162.02v-16.77h-2v16.46l2 .31zM95.47 161.59v-16.34h-2V161.92l2-.33zM112.84 156.51v-11.26h-2V157.31l2-.8z"
          className="ah0o__cls-2"
        />
      </g>
    </svg>
  );
};
SvgAvatar4.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar4;
