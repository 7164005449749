import { isEmpty } from "src/helpers";
import useSearchParams from "./useSearchParams";

const useGetSearchParams = (defaultValue = {}) => {
  const searchParams = useSearchParams();
  const searchParamsKeys = {};
  if (searchParams.entries().next().done) return defaultValue;
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    const decodedObject = JSON.parse(decodeURIComponent(value));
    if (decodedObject && decodedObject.hasOwnProperty(param)) {
      searchParamsKeys[param] = !isEmpty(decodedObject)
        ? decodedObject[param]
        : defaultValue[param] || "";
    } else {
      searchParamsKeys[param] = !isEmpty(decodedObject)
        ? decodedObject
        : defaultValue[param] || "";
    }
  }

  return { ...defaultValue, ...searchParamsKeys };
};

export default useGetSearchParams;
