import React from "react";
import { Image } from "src/components";
import PublishTemplateConfimationImg from "./PublishTemplateConfimation.svg";

const PublishTemplateConfimation = (props) => (
  <Image
    src={PublishTemplateConfimationImg}
    alt="PublishTemplateConfimationImg"
    {...props}
  />
);

export default PublishTemplateConfimation;
