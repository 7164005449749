import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FORMAT_AGGREGATE_FUNCTION, SALES_ACTIVITY_EVENT } from "src/constants";
import { classNames, isEmpty } from "src/helpers";
import ConditionsModal from "./ConditionsModal";
import RuleCardBody from "./RuleCardBody";
import RuleCardHeader from "./RuleCardHeader";
import "./ruleCard.scss";

const RuleCard = (props) => {
  const {
    index,
    goalRule,
    onShowClick,
    showButtons,
    loadingDelete,
    handleDeleteRule,
    onSelectedGoalRule,
    getSalesActivityStatus,
    templateDetails,
    deleteProps,
    editProps,
    alertProps,
    tempFormula,
    isCustomFormula,
  } = props;

  const [conditionModal, showConditionModal] = useState(false);
  const { t } = useTranslation();

  const auditActivityStatus = templateDetails?.AuditActivityStatus;
  const aggregateField =
    FORMAT_AGGREGATE_FUNCTION[goalRule.GoalAggregateFunction]?.displayFormat;
  const rollUpData = goalRule?.RollUpFields;
  const goalRuleId = goalRule?.RuleId;

  const getLabel = (label, cropText) => {
    if (label.toLowerCase().endsWith(cropText)) {
      return label.slice(0, label.toLowerCase().indexOf(cropText));
    }
    return label;
  };

  const onEditClick = () => {
    onShowClick();
    onSelectedGoalRule(goalRule, index);
  };

  // # Activity Display Name
  const activityDisplayName = !isEmpty(goalRule?.Entity?.activity)
    ? `${getLabel(
        goalRule?.Entity?.activity?.DisplayName,
        "activity"
      )} Activity`
    : null;

  // # Parent Activity Display Name
  const parentActivityDisplayName = !isEmpty(
    goalRule?.Entity?.parentActivity?.DisplayName
  )
    ? `${getLabel(
        goalRule.Entity?.parentActivity.DisplayName,
        "activity"
      )} Activity`
    : null;

  // # Opportunity Display Name
  const opportunityDisplayName = !isEmpty(goalRule?.Entity?.opportunity)
    ? goalRule?.Entity?.opportunity.length > 1
      ? "Core Opportunities"
      : `${getLabel(
          goalRule?.Entity?.opportunity[0]?.DisplayName,
          "opportunity"
        )} Opportunity`
    : null;

  // # Aggregate Field
  const aggregateFieldDisplayName = rollUpData?.AggregateField?.DisplayName;
  const aggregateFieldRollupType = rollUpData?.AggregateField?.rollUpType;

  // # User Field
  const userFieldDisplayName = rollUpData?.UserField?.DisplayName;
  const userFieldRollupType = rollUpData?.UserField?.rollUpType;

  // # Date Field
  const dateFieldDisplayName = rollUpData?.DateField?.DisplayName;
  const dateFieldRollupType = rollUpData?.DateField?.rollUpType;

  // # Condition
  const conditionsCount = goalRule?.Conditions?.length;

  const getOpportunityLabel = (opportunity) => {
    return opportunity?.length > 1 ? (
      <>
        {t("RULEBOOK.LABELS.ANY_OF_THE")}{" "}
        <span className="fc-black">
          {t("RULEBOOK.LABELS.OPPORTUNITIES_SELECTED")}
        </span>
      </>
    ) : (
      <span className="fc-black long-text-break">
        {getLabel(opportunity[0]?.DisplayName, "opportunity")}{" "}
        {t("RULEBOOK.LABELS.OPPORTUNITY")}
      </span>
    );
  };

  const getEntityContent = (title) => {
    switch (title) {
      case "Opportunity":
        return (
          <>
            <span className="fc-black">
              {!isEmpty(goalRule?.Entity?.opportunity)
                ? getOpportunityLabel(goalRule?.Entity?.opportunity)
                : null}
            </span>{" "}
            {t("RULEBOOK.MESSAGES.IS")}
            <span className="fc-black mx-1">
              {t("RULEBOOK.LABELS.CREATED_OR_UPDATED")}{" "}
            </span>
          </>
        );
      case "Activity":
        return goalRule?.Entity?.entityAction === "Activity on Activity" ||
          goalRule?.Entity?.entityAction === "Activity on Opportunity" ? (
          <>
            <span className="fc-black">
              {!isEmpty(goalRule?.Entity?.activity?.DisplayName)
                ? getLabel(goalRule?.Entity?.activity.DisplayName, "activity")
                : null}{" "}
            </span>
            {t("RULEBOOK.MESSAGES.IS")}
            <span className="fc-black mx-1">
              {t("RULEBOOK.LABELS.CREATED_OR_UPDATED")}{" "}
            </span>{" "}
            {t("RULEBOOK.MESSAGES.ON")}{" "}
            {goalRule?.Entity?.entityAction === "Activity on Activity" ? (
              <span className="fc-black">
                {getLabel(
                  goalRule?.Entity?.parentActivity.DisplayName,
                  "activity"
                )}{" "}
                {t("RULEBOOK.LABELS.ACTIVITY")}
              </span>
            ) : (
              <span className="fc-black">
                {getOpportunityLabel(goalRule?.Entity?.opportunity)}
              </span>
            )}
          </>
        ) : (
          <>
            <span className="fc-black">
              {" "}
              {!isEmpty(goalRule?.Entity?.activity?.DisplayName)
                ? getLabel(goalRule?.Entity?.activity.DisplayName, "activity")
                : null}{" "}
              {goalRule?.Entity?.entityAction}
            </span>{" "}
            {t("RULEBOOK.LABELS.IS_CREATED_OR_UPDATED")}
          </>
        );
      case "Lead":
        return (
          <span className="fc-black">{goalRule?.Entity?.entityAction}</span>
        );
      case "Task":
        return goalRule?.Entity?.entityAction === "Task on Opportunity" ? (
          <>
            <span className="fc-black">
              {!isEmpty(goalRule?.Entity?.task?.Name)
                ? getLabel(goalRule?.Entity?.task.Name, "task")
                : null}
              {" Task "}
            </span>
            {t("RULEBOOK.MESSAGES.IS")}
            <span className="fc-black mx-1">
              {t("RULEBOOK.LABELS.CREATED_OR_UPDATED")}{" "}
            </span>{" "}
            {t("RULEBOOK.MESSAGES.ON")}{" "}
            <span className="fc-black">
              {getOpportunityLabel(goalRule?.Entity?.opportunity)}
            </span>
          </>
        ) : (
          <>
            <span className="fc-black">
              {!isEmpty(goalRule?.Entity?.task?.Name)
                ? getLabel(goalRule?.Entity?.task?.Name, "task")
                : null}{" "}
              {goalRule?.Entity?.entityAction === "Task on Lead"
                ? goalRule?.Entity?.entityAction
                : "Task"}{" "}
            </span>
            {t("RULEBOOK.LABELS.IS_CREATED_OR_UPDATED")}
          </>
        );
      default:
        return <></>;
    }
  };
  const entityContent = (
    <p>{getEntityContent(goalRule?.Entity?.selectedEntity?.title)}</p>
  );

  const ruleContainsSalesActivity =
    goalRule?.Entity?.activity?.ActivityEvent === SALES_ACTIVITY_EVENT ||
    goalRule?.Entity?.parentActivity?.ActivityEvent === SALES_ACTIVITY_EVENT;

  const showTooltipForSalesActivity =
    (getSalesActivityStatus?.RetrictUsersFromNewActivity ||
      getSalesActivityStatus?.RestrictCompleteAccess) &&
    ruleContainsSalesActivity;

  const isAuditLogsDisabledForEntity = (auditActivityStatus, goalRule) => {
    return auditActivityStatus?.find((auditLogDetail) => {
      return (
        auditLogDetail.RuleId === goalRule.RuleId &&
        auditLogDetail.hasOwnProperty("AuditActivity") &&
        !auditLogDetail.AuditActivity
      );
    });
  };

  const showTooltipForEntityWithoutAuditLogs = isAuditLogsDisabledForEntity(
    auditActivityStatus,
    goalRule
  );

  const disableEditForSalesActivity =
    getSalesActivityStatus?.RestrictCompleteAccess && ruleContainsSalesActivity;

  // Alert
  const getShowAlert = () => {
    const defaultShowAlert =
      showTooltipForSalesActivity || showTooltipForEntityWithoutAuditLogs;
    if (alertProps && "showAlert" in alertProps)
      return alertProps.showAlert || defaultShowAlert;
    return defaultShowAlert;
  };
  const getDisableAlertTooltip = () => {
    const defaultDisableAlertTooltip =
      !showTooltipForSalesActivity && !showTooltipForEntityWithoutAuditLogs;

    if (alertProps && "disableAlertTooltip" in alertProps)
      return !alertProps.disableAlertTooltip
        ? false
        : defaultDisableAlertTooltip;
    return defaultDisableAlertTooltip;
  };
  const getAlertTooltipText = () => {
    if (
      alertProps &&
      "disableAlertTooltip" in alertProps &&
      !alertProps.disableAlertTooltip &&
      alertProps?.alertTooltipText
    )
      return alertProps.alertTooltipText;
    if (showTooltipForSalesActivity) {
      if (disableEditForSalesActivity)
        return t("RULEBOOK.MESSAGES.SALES_ACTIVITY_UNAVAILABLE");
      return t("RULEBOOK.MESSAGES.SALES_ACTIVITY_RESTRICTION");
    }
    return t("RULEBOOK.MESSAGES.ENABLE_THE_AUDIT_LOG_FOR_ENTITY");
  };

  // Edit
  const getDisableEdit = () => {
    if (editProps && "disableEdit" in editProps)
      return editProps.disableEdit || disableEditForSalesActivity;
    return disableEditForSalesActivity;
  };
  const getDisableEditTooltip = () => {
    if (editProps && "disableEditTooltip" in editProps)
      return !editProps.disableEditTooltip
        ? false
        : !disableEditForSalesActivity;
    return !disableEditForSalesActivity;
  };
  const getEditTooltipText = () => {
    if (
      editProps &&
      "disableEditTooltip" in editProps &&
      !editProps.disableEditTooltip &&
      editProps?.editTooltipText
    )
      return editProps.editTooltipText;
    return t("RULEBOOK.MESSAGES.CANNOT_EDIT_RULES");
  };

  // Delete
  const getDisableDelete = () => {
    if (deleteProps && "disableDelete" in deleteProps)
      return (
        deleteProps.disableDelete || templateDetails.GoalRules.length === 1
      );
    return templateDetails.GoalRules.length === 1;
  };
  const getDisableDeleteTooltip = () => {
    if (deleteProps && "disableDeleteTooltip" in deleteProps)
      return !deleteProps.disableDeleteTooltip
        ? false
        : templateDetails.GoalRules.length !== 1;
    return templateDetails.GoalRules.length !== 1;
  };
  const getDeleteTooltipText = () => {
    if (
      deleteProps &&
      "disableDeleteTooltip" in deleteProps &&
      !deleteProps.disableDeleteTooltip &&
      deleteProps?.deleteTooltipText
    )
      return deleteProps.deleteTooltipText;
    return t("RULEBOOK.MESSAGES.MIN_RULESET");
  };

  return (
    <>
      <div
        className={classNames([
          "rule-card br-8",
          showButtons ? "rule-card--hover" : "",
        ])}
      >
        <RuleCardHeader
          showButtons={showButtons}
          onEditClick={onEditClick}
          loadingDelete={loadingDelete}
          handleDeleteRule={handleDeleteRule}
          ruleName={goalRule.RuleName}
          index={index}
          goalRuleId={goalRuleId}
          showAlert={getShowAlert()}
          disableAlertTooltip={getDisableAlertTooltip()}
          alertTooltipText={getAlertTooltipText()}
          disableDelete={getDisableDelete()}
          disableDeleteTooltip={getDisableDeleteTooltip()}
          deleteTooltipText={getDeleteTooltipText()}
          disableEdit={getDisableEdit()}
          disableEditTooltip={getDisableEditTooltip()}
          editTooltipText={getEditTooltipText()}
          tempFormula={tempFormula}
          isCustomFormula={isCustomFormula}
        />
        <RuleCardBody
          entityContent={entityContent}
          activityDisplayName={activityDisplayName}
          taskDisplayName={
            !isEmpty(goalRule?.Entity?.task)
              ? `${getLabel(goalRule?.Entity?.task?.Name, "task")} Task`
              : null
          }
          parentActivityDisplayName={parentActivityDisplayName}
          opportunityDisplayName={opportunityDisplayName}
          aggregateField={aggregateField}
          aggregateFieldDisplayName={aggregateFieldDisplayName}
          aggregateFieldRollupType={aggregateFieldRollupType}
          userFieldDisplayName={userFieldDisplayName}
          userFieldRollupType={userFieldRollupType}
          dateFieldDisplayName={dateFieldDisplayName}
          dateFieldRollupType={dateFieldRollupType}
          conditionsCount={conditionsCount}
          disableConditionClick={false}
          showConditionModal={showConditionModal}
          border
        />
      </div>
      <ConditionsModal
        goalRule={goalRule}
        conditionModal={conditionModal}
        showConditionModal={showConditionModal}
        ruleName={goalRule.RuleName}
      />
    </>
  );
};

RuleCard.propTypes = {
  index: PropTypes.number,
  goalRule: PropTypes.object,
  templateId: PropTypes.string,
  onShowClick: PropTypes.func,
  showButtons: PropTypes.bool,
  deleteDisable: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  aggregateField: PropTypes.string,
  onSelectedGoalRule: PropTypes.func,
};

RuleCard.defaultProps = {
  showButtons: false,
  onShowClick: false,
};

export default RuleCard;
