import React from "react";
import { GOAL_AGGREGATION_TYPES } from "./types";

export const GOALS_RECURRENCE_PERIOD_OPTIONS = [
  {
    label: "Daily",
    value: "Daily",
  },
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Biweekly",
    value: "Biweekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Quarterly",
    value: "Quarterly",
  },
  { label: "Yearly", value: "Yearly" },
  {
    label: "Custom",
    value: "Custom",
  },
];

export const CYCLES_OPTIONS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Daily",
    value: "DAILY",
  },
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Biweekly",
    value: "BIWEEKLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
  {
    label: "Quarterly",
    value: "QUARTERLY",
  },
  {
    label: "Yearly",
    value: "YEARLY",
  },
  {
    label: "Custom: Uniform",
    value: "UNIFORM",
  },
  {
    label: "Custom: Non-Uniform",
    value: "NONUNIFORM",
  },
];

export const CYCLES = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Daily",
    value: "DAILY",
  },
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Biweekly",
    value: "BIWEEKLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
  {
    label: "Quarterly",
    value: "QUARTERLY",
  },
  {
    label: "Yearly",
    value: "YEARLY",
  },
];

export const MANAGED_GOAL_STATUS_OPTIONS = [
  {
    label: "In Progress",
    value: "INPROGRESS",
  },
  {
    label: "Gap Phase",
    value: "INGAPS",
  },
  {
    label: "Yet to start",
    value: "YETTOSTART",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Draft",
    value: "DRAFT",
  },
  {
    label: "Unpublished",
    value: "UNPUBLISHED",
  },
  {
    label: "All",
    value: "ALL",
  },
];

export const ASSIGNED_GOAL_STATUS_OPTIONS = [
  {
    label: "In Progress",
    value: "INPROGRESS",
  },
  {
    label: "Gap Phase",
    value: "INGAPS",
  },
  {
    label: "Yet to start",
    value: "YETTOSTART",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Unpublished",
    value: "UNPUBLISHED",
  },
  {
    label: "All",
    value: "ALL",
  },
];

export const MAX_GOAL_NAME_LENGTH = 100;
export const MAX_GOAL_DESCRIPTION_LENGTH = 500;
export const GREY_COLOR = "#788AA0";
export const MAX_USER_DISTRIBUTION_VALUE = 999999999999.99;

export const GOAL_AGG_FUNCTION = {
  [GOAL_AGGREGATION_TYPES.TARGET_BASED]: {
    key: GOAL_AGGREGATION_TYPES.TARGET_BASED,
    value: "Achieve Equal to or More",
    notation: <span>&ge;</span>,
  },
  [GOAL_AGGREGATION_TYPES.MAINTAIN_GREATER_THAN_EQUAL]: {
    key: GOAL_AGGREGATION_TYPES.MAINTAIN_GREATER_THAN_EQUAL,
    value: "Maintain Greater than or equal to",
    notation: <span>&ge;</span>,
  },
  [GOAL_AGGREGATION_TYPES.MAINTAIN_LESS_THAN_EQUAL]: {
    key: GOAL_AGGREGATION_TYPES.MAINTAIN_LESS_THAN_EQUAL,
    value: "Maintain Less than or equal to",
    notation: <span>&le;</span>,
  },
  [GOAL_AGGREGATION_TYPES.AVG_GREATER_THAN_EQUAL]: {
    key: GOAL_AGGREGATION_TYPES.AVG_GREATER_THAN_EQUAL,
    value: "Average Greater than or equal to",
    notation: <span>&ge;</span>,
  },
  [GOAL_AGGREGATION_TYPES.AVG_LESS_THAN_EQUAL]: {
    key: GOAL_AGGREGATION_TYPES.AVG_LESS_THAN_EQUAL,
    value: "Average Less than or equal to",
    notation: <span>&le;</span>,
  },
};

export const MOBILE_CONFIGURE_COLUMNS = {
  MIN: 0,
  MAX: 6,
};

export const AVERAGE_COLUMN_HEADING = {
  [GOAL_AGGREGATION_TYPES.AVG_GREATER_THAN_EQUAL]: {
    value: GOAL_AGGREGATION_TYPES.AVG_GREATER_THAN_EQUAL,
    desktopHeading: "Minimum Average",
    mobileHeading: "Min. Avg",
    title: "Greater than equal to average",
  },
  [GOAL_AGGREGATION_TYPES.AVG_LESS_THAN_EQUAL]: {
    value: GOAL_AGGREGATION_TYPES.AVG_LESS_THAN_EQUAL,
    desktopHeading: "Maximum Average",
    mobileHeading: "Max. Avg",
    title: "Less than equal to average",
  },
};

export const ROLLUP_VALUES = ["SELECTEDUSERS", "ALLUSERS"];

export const INITIAL_SUBGOAL_STATE = {
  SubGoal: [{ Entity: "", Value: "", id: 1 }],
  SubGoalCondition: "1",
};
