import { createRoute } from "src/router";

export const APPS = {
  ACE: {
    ROUTES: {
      get ROOT() {
        return createRoute({ path: "/ace" });
      },
      get ERROR() {
        return createRoute({ path: `${this.ROOT.path}/error` });
      },
    },
  },
  RULESETS: {
    ROUTES: {
      get ROOT() {
        return createRoute({ path: "/rulesets" });
      },
      get RULESET_CRUD() {
        return createRoute({
          path: `${this.ROOT.path}/configuregoaltemplate/:id`,
        });
      },
    },
  },
  SETTINGS: {
    ROUTES: {
      get ROOT() {
        return createRoute({ path: "/settings" });
      },
      get CUSTOM_REWARDS() {
        return createRoute({
          path: `${this.ROOT.path}/custom-rewards`,
        });
      },
    },
  },
  NUDGES: {
    ROUTES: {
      get ROOT() {
        return createRoute({ path: "/nudges" });
      },
      get LANDING() {
        return createRoute({ path: `${this.ROOT.path}/landing` });
      },
      get PROGRESS_NUDGE() {
        return createRoute({
          path: `${this.LANDING.path}/progress-nudge`,
        });
      },
      get NO_PROGRESS_NUDGE() {
        return createRoute({
          path: `${this.LANDING.path}/no-progress-nudge`,
        });
      },
      get MILESTONE_NUDGE() {
        return createRoute({
          path: `${this.LANDING.path}/milestone-nudge`,
        });
      },
      get REQUEST_DEMO() {
        return createRoute({
          path: `${this.ROOT.path}/request-demo`,
        });
      },
    },
  },
  GOALS: {
    ROUTES: {
      get ROOT() {
        return createRoute({ path: "/goals" });
      },
      get LANDING() {
        return createRoute({ path: `${this.ROOT.path}/landing` });
      },
      get RULESETS() {
        return createRoute({ path: `${this.LANDING.path}/rulesets` });
      },
      get MANAGED_BY_ME() {
        return createRoute({
          path: `${this.LANDING.path}/managed-by-me`,
        });
      },
      get ASSIGNED_TO_ME() {
        return createRoute({
          path: `${this.LANDING.path}/assigned-to-me`,
        });
      },
      get GOAL_CRUD() {
        return createRoute({
          path: `${this.ROOT.path}/configuregoal/:id/:userId?`,
        });
      },
      get PUBLISHED_GOAL_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/teamgoaldetails/:id/:userId?`,
          name: "GOALS_PUBLISHED_GOAL_VIEW",
        });
      },
      get ASSIGNED_GOAL_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/mygoaldetails/:id`,
          name: "GOALS_ASSIGNED_GOAL_VIEW",
        });
      },
      get REQUEST_DEMO() {
        return createRoute({
          path: `${this.ROOT.path}/request-demo`,
        });
      },
    },
  },
  INCENTIVES: {
    ROUTES: {
      get ROOT() {
        return createRoute({ path: "/incentives" });
      },
      get LANDING() {
        return createRoute({ path: `${this.ROOT.path}/landing` });
      },
      get RULESETS() {
        return createRoute({ path: `${this.LANDING.path}/rulesets` });
      },
      get MANAGE_PROGRAM() {
        return createRoute({
          path: `${this.LANDING.path}/manage-program`,
        });
      },
      get APPROVAL() {
        return createRoute({
          path: `${this.LANDING.path}/approval`,
          name: "INCENTIVE_APPROVAL",
        });
      },
      get PAYOUT() {
        return createRoute({
          path: `${this.LANDING.path}/payout`,
        });
      },
      get DASHBOARD() {
        return createRoute({
          path: `${this.LANDING.path}/dashboard`,
        });
      },
      get MY_INCENTIVES() {
        return createRoute({
          path: `${this.LANDING.path}/my-incentives`,
        });
      },
      get DASHBOARD_DETAILS_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/dashboard-incentive-details/:programId/:userId`,
        });
      },
      get DASHBOARD_PROGRAM_DETAIL_VIEW_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/dashboard-program-detail-view/:programId`,
          name: "INCENTIVES_DASHBOARD_PROGRAM_DETAIL_VIEW_PAGE",
        });
      },
      get DASHBOARD_USER_DETAIL_VIEW_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/dashboard-user-detail-view/:userId`,
        });
      },
      get INCENTIVE_CRUD() {
        return createRoute({
          path: `${this.ROOT.path}/configure-incentives/:programId`,
        });
      },
      get PUBLISHED_INCENTIVES_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/published-incentives/:programId`,
        });
      },
      get APPROVAL_DETAIL_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/approve-incentives/:programId`,
        });
      },
      get PAYOUT_DETAIL_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/payout-incentive/:programId`,
        });
      },
      get MY_INCENTIVES_DETAIL_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/my-incentives/:programId`,
          name: "INCENTIVES_MY_INCENTIVES_DETAIL_VIEW",
        });
      },
      get PAYOUT_REQUEST_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/payout-requests`,
        });
      },
      get APPROVAL_REQUEST_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/approval-requests`,
        });
      },
      get REQUEST_DEMO() {
        return createRoute({
          path: `${this.ROOT.path}/request-demo`,
        });
      },
    },
  },
  LEADERBOARDS: {
    ROUTES: {
      get ROOT() {
        return createRoute({ path: "/leaderboard" });
      },
      get LANDING() {
        return createRoute({ path: `${this.ROOT.path}/landing` });
      },
      get RULESETS() {
        return createRoute({ path: `${this.LANDING.path}/rulesets` });
      },
      get MANAGE_LEADERBOARD() {
        return createRoute({
          path: `${this.LANDING.path}/manage-leaderboard`,
        });
      },
      get APPROVAL() {
        return createRoute({
          path: `${this.LANDING.path}/approval`,
        });
      },
      get PAYOUT() {
        return createRoute({
          path: `${this.LANDING.path}/payout`,
        });
      },
      get DASHBOARD() {
        return createRoute({
          path: `${this.LANDING.path}/dashboard`,
        });
      },
      get MY_BOARDS() {
        return createRoute({
          path: `${this.LANDING.path}/my-boards`,
        });
      },
      get LEADERBOARD_CRUD() {
        return createRoute({
          path: `${this.ROOT.path}/configure-leaderboards/:programId`,
        });
      },
      get PUBLISHED_LEADERBOARDS_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/published-leaderboards/:id`,
        });
      },
      get APPROVAL_DETAIL_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/approve-leaderboards/:programId`,
        });
      },
      get PAYOUT_DETAIL_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/payout-leaderboards/:programId`,
        });
      },
      get DASHBOARD_DETAILS_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/dashboard-leaderboard-details/:programId/:userId?`,
        });
      },
      get DASHBOARD_BOARD_DETAIL_VIEW_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/dashboard-program-detail-view/:programId`,
        });
      },
      get DASHBOARD_USER_DETAIL_VIEW_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/dashboard-user-detail-view/:userId`,
        });
      },
      get MY_BOARDS_DETAIL_VIEW() {
        return createRoute({
          path: `${this.ROOT.path}/my-boards/:leaderboardId/:groupId?`,
        });
      },
      get PAYOUT_REQUEST_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/payout-requests`,
        });
      },
      get APPROVAL_REQUEST_PAGE() {
        return createRoute({
          path: `${this.ROOT.path}/approval-requests`,
        });
      },
      get REQUEST_DEMO() {
        return createRoute({
          path: `${this.ROOT.path}/request-demo`,
        });
      },
    },
  },
};
