import { Responsive } from "src/components/layouts";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { useToggle } from "src/hooks";
import { SvgFilter } from "src/icons";
import "./styles.scss";

export const FilterBar = ({ className, children }) => {
  return <div className={classNames(["filters", className])}>{children}</div>;
};

export const FilterMenuItem = ({ className, children }) => {
  return (
    <div className={classNames(["filter-item", className])}>{children}</div>
  );
};

export const FilterMenu = ({ className, children }) => {
  const [clicked, setClicked] = useToggle();
  return (
    <Responsive>
      <Responsive.OnMobile>
        <button
          id="filter-bar"
          className={`filter-btn mr-12 ${clicked ? "active" : ""}`}
          onClick={() => setClicked((prevValue) => !prevValue)}
        >
          <SvgFilter
            color={clicked ? COLORS.PRIMARY_BLUE : COLORS.GREY_3}
            className={`m-11 ${clicked ? "blue" : "normal"}`}
          />
        </button>
        {clicked ? <div className="on-mobile">{children}</div> : null}
      </Responsive.OnMobile>
      <Responsive.OnDesktop>
        <div className="on-desktop">{children}</div>
      </Responsive.OnDesktop>
    </Responsive>
  );
};
