import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_SELECTED_APPROVERS = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getProgram($ProgramId: ID!) {
    program(ProgramId: $ProgramId) {
      __typename
      ... on SingleProgramResponse {
        response {
          Program {
            ProgramId
            SelectedApprovers {
              ProgramId
              UserId
              Level
              Stage
              Type
              EmailAddress
              FirstName
              LastName
              StatusCode
            }
            ProgramOwner {
              FirstName
              LastName
              EmailAddress
              UserID
              StatusCode
            }
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetSelectedApprovers = (queryOptions) => {
  return useQuery(GET_SELECTED_APPROVERS, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
