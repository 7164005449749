import { gql, useQuery } from "src/api/client";

const GET_TOTAL_PENDING_PAYOUT = gql`
  query totalPendingPayout($isUserBased: Boolean, $FeatureType: String!) {
    totalPendingPayout(isUserBased: $isUserBased, FeatureType: $FeatureType) {
      ... on TotalPendingPayoutResponse {
        success
        message
        response {
          totalPendingPayout
          totalPayoutDone
          totalPaidOutRewards {
            RewardCount
            RewardCategory
          }
          totalPendingPayoutRewards {
            RewardCount
            RewardCategory
          }
        }
      }
    }
  }
`;

export const useGetTotalPendingPayoutEndUser = (queryOptions) => {
  return useQuery(GET_TOTAL_PENDING_PAYOUT, queryOptions);
};
