import { useToggle } from "src/hooks";

function useHideInactiveUsers() {
  const [hideInactiveUsers, setHideInactiveUsers] = useToggle();

  const isInactiveUser = (user) => {
    return user.IsRemovedFromHierarchy || user.StatusCode !== 0;
  };

  return {
    hideInactiveUsers,
    setHideInactiveUsers,
    isInactiveUser,
  };
}

export default useHideInactiveUsers;
