import PropTypes from "prop-types";
import React from "react";
import { FormButton, HeroBackground } from "src/components";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgArrow } from "src/icons";
import { ApprovalRequestMobile, TotalPayout } from "src/images";
import "./incentiveDetailsOverview.scss";

const IncentiveDetailsOverview = (props) => {
  const { className, title, subtitle, listItems, icon, showSideArrow } = props;

  const Icon = icon || TotalPayout;

  return (
    <HeroBackground
      className={classNames(["overview-requests-card p-3 fc-white", className])}
      image={ApprovalRequestMobile}
    >
      <div className="d-flex justify-content-between">
        <div>
          <div className="fs-16 semi-bold">{title}</div>
          <div className="fs-12 semi-bold">{subtitle}</div>
        </div>

        <div className="overview-right-icon">{<Icon />}</div>
      </div>
      <div className="mt-4" />
      {listItems?.map((item) => (
        <div className="flex-between mb-2">
          <div className="w-50 fs-12 semi-bold">{item.label}</div>
          <FormButton
            className="w-50 d-flex fs-12 flex-wrap fc-white align-items-center justify-content-end"
            onClick={() => item.onClickHandler(item)}
            variant="link"
          >
            <span className="fc-white center">
              {item.value}{" "}
              {showSideArrow && (
                <SvgArrow
                  rotate={270}
                  color={COLORS.WHITE}
                  width={12}
                  height={12}
                />
              )}
            </span>
          </FormButton>
        </div>
      ))}
    </HeroBackground>
  );
};

IncentiveDetailsOverview.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.element,
      isAmountStat: PropTypes.bool.isRequired,
      value: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      rewards: PropTypes.array,
      rewardCount: PropTypes.number,
    })
  ),
  icon: PropTypes.elementType,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showSideArrow: PropTypes.bool,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.element,
      value: PropTypes.element,
      onClickHandler: PropTypes.func,
    })
  ),
};

IncentiveDetailsOverview.defaultProps = {
  id: "IncentiveDetailsOverview",
  className: "",
  stats: [],
  title: "Payout Requests",
  subtitle: "Total rewards from all the payout requests.",
  showSideArrow: true,
};

export default IncentiveDetailsOverview;
