import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'goals.web.managedByMePage' */ "./web")
);

const MobilePage = lazy(() =>
  import(/* webpackChunkName: 'goals.mobile.managedByMePage' */ "./mobile")
);

export const ManagedByMePage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "Managed By Me",
  },
};
