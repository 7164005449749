import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

// src/modules/goalCRUD/tabView/headerContent/tabButtons/DeleteGoalButton.js
// src/modules/publishedGoalView/goalViewHeader/deleteGoal.js
const DELETE_GOAL_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation DeleteGoal($GoalId: ID!) {
    deleteGoal(GoalId: $GoalId) {
      __typename
      ... on SingleGoalResponse {
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useDeleteGoal = (queryOptions) => {
  return useMutation(DELETE_GOAL_MUTATION, queryOptions);
};
