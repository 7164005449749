import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgInProgress = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="m11.261 0 1.811 1.813-3.364 3.36L5.364.829 0 6.194l1.118 1.118 4.248-4.248L9.71 7.408l4.477-4.481L16 4.74V0Z"
      />
    </svg>
  );
};

SvgInProgress.defaultProps = {
  color: COLORS.GREY_2,
  height: 8,
  width: 16,
};
export default SvgInProgress;
