import { useRef, useState } from "react";
import useClickAwayListener from "src/hooks/useClickAwayListener";

export const useMenu = () => {
  const [el, setEl] = useState(null);
  const containerRef = useRef();

  const handleClick = (event) => {
    setEl(event.currentTarget);
  };

  const handleClose = () => {
    setEl(null);
  };

  useClickAwayListener(containerRef, handleClose);

  return {
    el,
    containerRef,
    handleClick,
    handleClose,
  };
};
