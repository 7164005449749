import { REWARD_SUBCATEGORIES } from "./incentiveConstants";

export const LEADERBOARD_REWARD_TYPE = {
  FIXED: {
    type: "FIXED",
    label: "Fixed",
    Category: REWARD_SUBCATEGORIES.MONETARY.key,
  },
  PERCENTAGE: {
    type: "PERCENTAGE",
    label: "Percentage of Achievement",
    Category: REWARD_SUBCATEGORIES.MONETARY.key,
  },
  MULTIPLIER: {
    type: "MULTIPLIER",
    label: "Multiplier",
    Category: REWARD_SUBCATEGORIES.MONETARY.key,
  },
  CUSTOM_REWARD: {
    type: "CUSTOM_REWARD",
    label: "Custom Reward",
    Category: REWARD_SUBCATEGORIES.NON_MONETARY.key,
  },
};

export const LEADERBOARD_PROGRAM_TYPES = {
  TEMPLATE_BASED: {
    value: "TEMPLATE_BASED",
  },
  GOAL_BASED: {
    value: "GOAL_BASED",
  },
};

export const LEADERBOARD_CALCULATION_TYPES = {
  NON_ROLL_UP: {
    key: "NON_ROLL_UP",
  },
  ROLL_UP: {
    key: "ROLL_UP",
  },
};

export const DEFAULT_LEADERBOARD_CALCULATION_TYPE =
  LEADERBOARD_CALCULATION_TYPES.NON_ROLL_UP.key;

export const MAX_DUPLICATE_LEADERBOARD_CONDITIONS = 10;

export const MAXIMUM_LEADERBOARD_RULESETS_ALLOWED = 5;

export const MAXIMUM_LEADERBOARD_CRITERIA_ALLOWED = 10;

export const MAXIMUM_LEADERBOARD_CRITERIA_CONDITIONS_ALLOWED = 10;

export const MAXIMUM_LEADERBOARD_CRITERIA_REWARDS_ALLOWED = 10;

export const INITIAL_GOALS_TO_DISPLAY = 3;

export const SHOW_MORE_GOALS_LIMIT = 3;

export const LEADERBOARD_PROGRAM_STATUS = [
  {
    label: "In Progress",
    value: "INPROGRESS",
  },
  {
    label: "Gap Phase",
    value: "INGAPS",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Yet to Start",
    value: "YETTOSTART",
  },
  {
    label: "Draft",
    value: "DRAFT",
  },
  {
    label: "Unpublished",
    value: "UNPUBLISHED",
  },
  {
    label: "All",
    value: "ALL",
  },
];

export const LEADERBOARD_CYCLES_OPTIONS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Daily",
    value: "DAILY",
  },
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Biweekly",
    value: "BIWEEKLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
  {
    label: "Quarterly",
    value: "QUARTERLY",
  },
  {
    label: "Yearly",
    value: "YEARLY",
  },
];
export const LEADERBOARD_GROUP_NAME_CHAR_LIMIT = 1024;
export const MONETARY_REWARD_TYPES = ["FIXED", "PERCENTAGE", "MULTIPLIER"];

export const REWARD_CATEGORIES = {
  MONETARY: "Monetary Reward",
  NON_MONETARY: "Non Monetary Reward",
};

export const REWARD_TEMPLATE = {
  RewardLabel: "",
  RewardType: "",
  Value: "",
};

export const LEADERBOARD_TIE_BREAKER_TYPES = {
  // HIGHEST_AVG_VALUE: "HIGHEST_AVG_VALUE",
  // LOWEST_AVG_VALUE: "LOWEST_AVG_VALUE",
  FASTEST_ACHIEVER: "FASTEST_ACHIEVER",
  RULESET: "RULESET",
};

export const basicTieBreakerCards = [
  // {
  //   Type: LEADERBOARD_TIE_BREAKER_TYPES.HIGHEST_AVG_VALUE,
  // },
  // {
  //   Type: LEADERBOARD_TIE_BREAKER_TYPES.LOWEST_AVG_VALUE,
  // },
  // removing these 2 options as they cater to only sum type of template
  {
    Type: LEADERBOARD_TIE_BREAKER_TYPES.FASTEST_ACHIEVER,
  },
];

export const LEADERBOARD_RECURRENCE_PERIOD_OPTIONS = [
  {
    label: "Daily",
    value: "Daily",
  },
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Biweekly",
    value: "Biweekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Quarterly",
    value: "Quarterly",
  },
  { label: "Yearly", value: "Yearly" },
  {
    label: "Custom",
    value: "Custom",
  },
  {
    label: "Infinite",
    value: "Infinite",
    isDisabled: true,
    subText: "Coming Soon",
  },
];

export const MAX_RANK = 500;

export const MINIMUM_LEADERBOARD_REWARD_VALUE = 0;

export const MAXIMUM_LEADERBOARD_REWARD_VALUE = 999999999;

export const MAX_REWARDS_ALLOWED = 10;
export const NON_DUPLICATIVE_REWARDS_CATEGORY_LIST = [
  "TRAVEL",
  "GADGET",
  "LEARNING",
];

export const RANKS = ({ t }) => [
  {
    label: `${t("LEADERBOARD.LABELS.RANK")} 1`,
    value: [1, 1],
  },
  {
    label: `${t("LEADERBOARD.LABELS.RANK")} 2`,
    value: [2, 2],
  },
  {
    label: `${t("LEADERBOARD.LABELS.RANK")} 3`,
    value: [3, 3],
  },
  {
    label: `${t("LEADERBOARD.LABELS.RANK")} 4  ${t(
      "LEADERBOARD.LABELS.TO"
    )} 10`,
    value: [4, 10],
  },
  {
    label: `${t("LEADERBOARD.LABELS.RANK")} 11 ${t(
      "LEADERBOARD.LABELS.TO"
    )} 20`,
    value: [11, 20],
  },
  {
    label: t("LEADERBOARD.LABELS.MORE_THAN_CURRENT_RANK", {
      value: 20,
    }),
    value: [21, 100000],
  },
];
