import { t } from "i18next";
import React from "react";
import { HeroBackground } from "src/components/common";
import { ACE_EMAIL_ID } from "src/constants";
import { classNames } from "src/helpers";
import { MobileBackground } from "src/images";

const RequestDemoMobile = ({
  logo,
  productImage,
  title,
  subTitle,
  demoLine,
  showEmail,
  orgInput,
}) => {
  return (
    <HeroBackground
      image={MobileBackground}
      className={classNames(["p-4 center flex-column desktop-video-container"])}
    >
      <div className="h-100 pt-4 flex-center flex-column">
        {logo}
        <p className="fs-24 semi-bold pt-4 fc-darker-grey">{title}</p>
        <p className="fs-16 pt-4">{subTitle}</p>
        {orgInput && <div className="mt-4">{orgInput}</div>}
        <div className="flex-center w-100 pt-4">{productImage}</div>
        <p className="fs-14 mt-1 py-1 fc-black semi-bold text-align-left w-100">
          {demoLine}
        </p>
        {showEmail && (
          <p className="fs-14  pt-2 pb-4 fc-grey w-100">
            {t("DEMO.REACH_OUT_TO_ACCOUNT_MANAGER")}
            <span className="fc-black fs-14 semi-bold ml-1">
              {ACE_EMAIL_ID}
            </span>
          </p>
        )}
      </div>
    </HeroBackground>
  );
};

export default RequestDemoMobile;
