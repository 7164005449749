import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'goals.web.assignedGoalViewPage' */ "./web")
);

const MobilePage = lazy(() =>
  import(/* webpackChunkName: 'goals.mobile.assignedGoalViewPage' */ "./mobile")
);

export const AssignedGoalViewPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "Assigned",
  },
};
