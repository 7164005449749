import React from "react";
import { Modal } from "react-bootstrap";
import { Note } from "src/components/common/atoms";
import { FormButton, FormTooltip } from "src/components/common/formElements";
import { classNames } from "src/helpers";
import { useToggle } from "src/hooks";
import { WhatIsCustomFormula } from "src/images";
import "./switchToCustomFormula.scss";

function SwitchToCustomFormula({
  disabled,
  tooltipText,
  switchToCustomFormula,
}) {
  const [openModal, openOrHideModal] = useToggle();

  return (
    <>
      <FormTooltip disable={!disabled} text={tooltipText}>
        <FormButton
          variant="link"
          onClick={!disabled ? openOrHideModal : undefined}
          className={classNames([disabled ? "cursor-not-allowed" : ""])}
        >
          Switch to Custom Formula
        </FormButton>
      </FormTooltip>

      <Modal
        show={openModal}
        onHide={openOrHideModal}
        className="switch-to-custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-16">Switch to Custom Formula</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <WhatIsCustomFormula />
          <Note className="fs-13">
            Note: You cannot revert to standard output once you have created
            custom formula.
          </Note>
        </Modal.Body>
        <Modal.Footer>
          <FormButton variant="outline-primary" onClick={openOrHideModal}>
            Cancel
          </FormButton>
          <FormButton onClick={switchToCustomFormula}>Done</FormButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SwitchToCustomFormula;
