import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const DOWNLOAD_RAW_EXCEL_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation downloadLeaderboardRawExcel($LeaderboardId: ID!) {
    downloadLeaderboardRawExcel(LeaderboardId: $LeaderboardId) {
      ... on LeaderboardUrlResponse {
        success
        response {
          url
          fileId
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useDownloadLeaderboardRawExcel = () => {
  return useMutation(DOWNLOAD_RAW_EXCEL_MUTATION);
};
