import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

// src/modules/goalCRUD/tabView/GoalTabView.js
const GET_TAB_VIEW_GOAL_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query GetGoalDetails($GoalId: ID!) {
    goal(GoalId: $GoalId) {
      __typename
      ... on SingleGoalResponse {
        message
        success
        response {
          Goal {
            Name
            validations
            isIncentiveLinkedWithGoal
            GoalAggConfig
            RecurrenceStartDate
            Description
            GoalId
            TemplateId
            IsRollUp
            RollupType
            RecurrenceDefinition
            Status
            Template {
              IsDelete
              Status
            }
            ModifiedBy
            GoalOwner {
              FirstName
              LastName
              UserID
            }
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetTabViewGoal = (queryOptions) => {
  return useQuery(GET_TAB_VIEW_GOAL_QUERY, queryOptions);
};

export const tabViewGoalMocks = [
  {
    request: {
      query: GET_TAB_VIEW_GOAL_QUERY,
      variables: {
        GoalId: "90d22bd0-3811-42ac-9fa8-3ca1bad12598",
      },
    },
    result: {
      data: {
        goal: {
          __typename: "SingleGoalResponse",
          message: "Goal fetched Successfully",
          success: true,
          response: {
            Goal: {
              Status: "PUBLISHED",
              Name: "goal name satiya 3",
              Description: "",
              GoalId: "90d22bd0-3811-42ac-9fa8-3ca1bad12598",
              __typename: "Goal",
            },
            __typename: "SingleGoal",
          },
        },
      },
    },
  },
];
