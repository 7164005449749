import { useEffect } from "react";
import { useGetDrilldownUserAchievement } from "src/api/useGoal";
import { ROLES } from "src/constants";
import { useRoleBasedContext } from "src/context";
import { isEmpty } from "src/helpers";
import { useParams } from "src/router";

const allManagersOption = {
  EmailAddress: "",
  ManagerName: "All Managers",
  RecurrenceId: "",
  UserID: null,
  level: 0,
};

const useGoalsPublishedViewAB = ({
  managersDropdownList,
  managerUserId,
  Recurrence,
  isDrillDown,
  User,
  setGoalUser,
  ShouldFetch,
  setShouldFetch,
}) => {
  const { userDetails } = useRoleBasedContext();
  const params = useParams();

  const getValidUserId = () => {
    const managersListForSelectedRecurrence =
      managersDropdownList?.managersInGoal?.response;

    const managersList =
      userDetails.role === ROLES.ADMIN
        ? managersListForSelectedRecurrence?.length
          ? [allManagersOption, ...managersListForSelectedRecurrence]
          : [allManagersOption]
        : managersListForSelectedRecurrence;

    if (
      !managersListForSelectedRecurrence ||
      !managerUserId ||
      !Recurrence?.RecurrenceId ||
      managersListForSelectedRecurrence?.length < 1
    ) {
      return managersList?.[0]?.UserID;
    }
    const id = managersList.find((user) => user.UserID === managerUserId);
    if (id === undefined) {
      return managersList[0].UserID;
    } else {
      return managerUserId;
    }
  };

  const {
    data: usersAchievementData,
    loading: usersAchievementLoading,
    error: usersAchievementError,
  } = useGetDrilldownUserAchievement({
    variables: {
      GoalId: params.id,
      RecurrenceId: Recurrence?.RecurrenceId,
      IsRecurrenceBased: true,
      UserId: isDrillDown ? managerUserId : getValidUserId(),
      directReportOnly: isDrillDown,
      UserIds: [User?.UserID],
    },
    skip: !User?.UserID,
    fetchPolicy: "no-cache",
  });

  const usersAchievementDetails =
    usersAchievementData?.getUserReport?.response?.userReport;

  let userDetailsWithAchievement;

  const getAchievement = (user) => {
    if (isDrillDown) {
      if (user?.UserID === "" && usersAchievementDetails) {
        const user = usersAchievementDetails.find(
          (userDetails) => userDetails.UserId === managerUserId
        );
        return user?.RolledUpAchievement || user?.GoalAchieved;
      }
      return user?.RolledUpAchievement || user?.GoalAchieved;
    }

    if (user?.FullName === "All") {
      return Recurrence?.GoalAchieved;
    }

    return user?.RolledUpAchievement || user?.GoalAchieved;
  };

  const getGoalValue = (user) => {
    if (user?.FullName === "All") return Recurrence?.GoalDistribution;
    return user?.GoalDistribution;
  };

  if (!usersAchievementLoading) {
    userDetailsWithAchievement = {
      FullName: User?.FullName,
      UserID: User?.UserID,
      Achievement:
        User?.UserID !== ""
          ? isEmpty(usersAchievementDetails)
            ? null
            : getAchievement(usersAchievementDetails[0])
          : getAchievement(User),
      GoalValue:
        User?.UserID !== ""
          ? isEmpty(usersAchievementDetails)
            ? null
            : getGoalValue(usersAchievementDetails[0])
          : getGoalValue(User),
    };
  }

  useEffect(() => {
    if (
      ShouldFetch &&
      !isEmpty(userDetailsWithAchievement) &&
      !usersAchievementLoading
    ) {
      setGoalUser(userDetailsWithAchievement);
      setShouldFetch(false);
    }
  }, [
    ShouldFetch,
    User.UserID,
    setGoalUser,
    User.FullName,
    setShouldFetch,
    usersAchievementLoading,
    userDetailsWithAchievement,
  ]);

  return {
    usersListWithAchievements: userDetailsWithAchievement,
    usersListWithAchievementsLoading: usersAchievementLoading,
    usersListWithAchievementsError: usersAchievementError,
  };
};

export default useGoalsPublishedViewAB;
