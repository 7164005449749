import { gql, useQuery } from "src/api/client";

export const GET_SELECTED_INCENTIVE_USERS = gql`
  query getSelectedIncentiveUsers($RecurrenceId: ID!) {
    getSelectedIncentiveUsers(RecurrenceId: $RecurrenceId) {
      ... on selectedIncentiveUsersResponse {
        success
        message
        response {
          SelectedUsers
          DeselectedUsers
        }
      }
    }
  }
`;

export const useGetSelectedIncentiveUsers = (queryOptions) => {
  return useQuery(GET_SELECTED_INCENTIVE_USERS, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
