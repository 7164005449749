import React from "react";
import { Image } from "src/components";
import ApprovalRequestEmptyStateImg from "./ApprovalRequestEmptyState.svg";

const ApprovalRequestEmptyState = (props) => (
  <Image
    src={ApprovalRequestEmptyStateImg}
    alt="ApprovalRequestEmptyStateImg"
    {...props}
  />
);

export default ApprovalRequestEmptyState;
