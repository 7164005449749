import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RULESET_CHANGE_VALIDATION, RULESET_TYPES } from "src/constants";
import { isEmpty } from "src/helpers";
import TemplateList from "./TemplateList";
import TemplateSelectButton from "./TemplateSelectButton";
import "./templateSelectModal.scss";

const TemplateSelectModal = (props) => {
  const {
    onShowClick,
    onCancelClick,
    isDistinct,
    goalStatus,
    setSelectedTemplate,
    selectedTemplate = [],
    isMultiSelect = false,
    maxAllowedTemplates = 5,
    isViewMode = false,
    handleSave,
    handleResetCalender,
    ExcludeGoalAggregateFunction,
    excludeTemplateDisclaimerText,
    templateDetails = [],
  } = props;

  const { t } = useTranslation();
  const [tempTemplate, setTempTemplate] = useState(
    isEmpty(selectedTemplate) ? null : selectedTemplate[0]
  );
  const [multipleTemplates, setMultipleTemplates] = useState(selectedTemplate);
  const onTemplateSelect = (template) => {
    setTempTemplate(template);
  };

  const resetCalender = (isDraft) => {
    if (
      selectedTemplate !== null &&
      isEmpty(selectedTemplate) &&
      tempTemplate
    ) {
      return false;
    }
    if (tempTemplate) {
      const GoalAggregateFunction =
        selectedTemplate[0]?.RulesetType === RULESET_TYPES.ADVANCED
          ? RULESET_TYPES.ADVANCED
          : selectedTemplate[0]?.GoalAggregateFunction;
      if (
        RULESET_CHANGE_VALIDATION[GoalAggregateFunction](
          tempTemplate.GoalAggregateFunction
        )
      ) {
        return isDraft;
      }
    }
    return false;
  };

  const handleUseTemplateButtonClick = () => {
    setSelectedTemplate(isMultiSelect ? multipleTemplates : [tempTemplate]);
    if (isMultiSelect) {
      !isDistinct && handleResetCalender();
    } else {
      handleSave && handleSave(tempTemplate);
      if (resetCalender(goalStatus === "DRAFT")) {
        handleResetCalender([tempTemplate]);
      }
    }
    onCancelClick();
  };

  const disableUseTemplateClick =
    tempTemplate === null ||
    resetCalender(goalStatus !== "DRAFT") ||
    (!isMultiSelect &&
      !isEmpty(selectedTemplate) &&
      selectedTemplate[0]?.TemplateId === tempTemplate?.TemplateId);

  return (
    <Modal
      show={onShowClick}
      onHide={onCancelClick}
      centered
      dialogClassName="select-template-container p-0"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-18">
          {isMultiSelect && !isViewMode
            ? t("MANAGED_GOALS.BUTTONS.TEMPLATES_SELECT_MODAL")
            : null}
          {isMultiSelect && isViewMode ? "Rulesets" : null}
          {!isMultiSelect && !isViewMode
            ? t("MANAGED_GOALS.BUTTONS.TEMPLATE_SELECT_MODAL")
            : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal_body">
        <div className="d-flex w-100">
          <TemplateList
            onTemplateSelect={onTemplateSelect}
            selectedTemplate={selectedTemplate}
            displayTemplate={
              tempTemplate || isEmpty(selectedTemplate)
                ? null
                : selectedTemplate[0]
            }
            isMultiSelect={isMultiSelect}
            tempTemplate={tempTemplate}
            multipleTemplates={multipleTemplates}
            setMultipleTemplates={setMultipleTemplates}
            maxAllowedTemplates={maxAllowedTemplates}
            isViewMode={isViewMode}
            ExcludeGoalAggregateFunction={ExcludeGoalAggregateFunction}
            excludeTemplateDisclaimerText={excludeTemplateDisclaimerText}
            templateDetails={templateDetails}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!isViewMode && (
          <TemplateSelectButton
            handleUseTemplateButtonClick={handleUseTemplateButtonClick}
            disableUseTemplateClick={
              isMultiSelect
                ? multipleTemplates?.length < 1
                : disableUseTemplateClick
            }
            showDistinctCountCheck={
              isMultiSelect ? false : resetCalender(goalStatus !== "DRAFT")
            }
            showConfirmationModal={
              isMultiSelect ? false : goalStatus !== "DRAFT"
            }
            maxAllowedTemplates={maxAllowedTemplates}
            selectedTemplateCount={multipleTemplates?.length}
            isMultiSelect={isMultiSelect}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateSelectModal;
