import React from "react";
import { Image } from "src/components";
import RecognitionBoardEmptyStateImg from "./RecognitionBoardEmptyState.png";

const RecognitionBoardEmptyState = (props) => (
  <Image
    src={RecognitionBoardEmptyStateImg}
    alt="RecognitionBoardEmptyStateImg"
    {...props}
  />
);

export default RecognitionBoardEmptyState;
