import { format } from "date-fns";
import {
  APPROVAL_STATUSES,
  DATE_FORMAT_PAYLOAD,
  HISTORY_ITEM_ACTIONS,
  INCENTIVE_PROGRAM_TYPES,
  PROGRAM_STATUS_TYPES,
} from "src/constants";
import {
  getSystemOffset,
  getTodayAsPerTimezone,
} from "src/helpers/timezoneUtils";
import { endOfDay, startOfDay } from "src/helpers/vendors";

export const isProgramCompleted = (program) => {
  const today = getTodayAsPerTimezone().getTime();
  const endDate = Date.parse(
    endOfDay(
      Date.parse(program?.RecurrenceDefinition?.LastRecurrenceEndDate) -
        getSystemOffset()
    )
  );

  return today > endDate;
};

export const isProgramYetToStart = (program) => {
  const today = getTodayAsPerTimezone().getTime();
  return (
    today <
    Date.parse(program?.RecurrenceDefinition?.FirstRecurrenceStartDate) -
      getSystemOffset()
  );
};

export const isProgramInProgress = (program) => {
  const today = getTodayAsPerTimezone().getTime();
  const startDate = Date.parse(
    program?.RecurrenceDefinition?.FirstRecurrenceStartDate
  );
  const endDate = Date.parse(
    endOfDay(
      Date.parse(program?.RecurrenceDefinition?.LastRecurrenceEndDate) -
        getSystemOffset()
    )
  );

  return today >= startDate && today <= endDate;
};

export const allowCycleDateRangeEdit = (program, isFirstDropdown) => {
  if (program.Status === "DRAFT") return true;
  if (isFirstDropdown) return isProgramYetToStart(program);
  return !isProgramCompleted(program);
};

export const getProgramStatus = (program) => {
  if (program.Status !== PROGRAM_STATUS_TYPES.PUBLISHED) {
    return program.Status;
  }

  const today = getTodayAsPerTimezone().getTime();
  const startDate = Date.parse(
    startOfDay(
      Date.parse(program?.RecurrenceDefinition?.FirstRecurrenceStartDate) -
        -getSystemOffset()
    )
  );
  const endDate = Date.parse(
    endOfDay(
      Date.parse(program?.RecurrenceDefinition?.LastRecurrenceEndDate) -
        getSystemOffset()
    )
  );

  if (today > endDate) return PROGRAM_STATUS_TYPES.COMPLETED;
  if (today < startDate) return PROGRAM_STATUS_TYPES.YETTOSTART;
  if (today >= startDate && today <= endDate) {
    if (program?.InGaps) return PROGRAM_STATUS_TYPES.INGAPS;
    return PROGRAM_STATUS_TYPES.INPROGRESS;
  }
};
const getApprovalStatus = (historyItem, status) => {
  if (status === APPROVAL_STATUSES.APPROVED.value) {
    return "approved";
  }
  if (status === APPROVAL_STATUSES.PARTIALLYAPPROVED.value) {
    return "partially_approved";
  }
  if (status === APPROVAL_STATUSES.REJECTED.value) {
    return "rejected";
  }
  if (historyItem.action === HISTORY_ITEM_ACTIONS.FORWARD.value) {
    return "forwarded";
  }
  if (historyItem.action === HISTORY_ITEM_ACTIONS.BACKWARD.value) {
    return "sent_back";
  }
  if (historyItem.action === HISTORY_ITEM_ACTIONS.PAYOUTREJECT.value) {
    return "payout_reject";
  }
};

export const formatApprovalHistory = (
  approvalHistory,
  currentStage,
  currentStageApprover,
  status = "Pending",
  id = ""
) => {
  const formatedHistory =
    approvalHistory?.map((item) => {
      return {
        historyId: item.historyId,
        status: getApprovalStatus(item, item.status),
        currentStage: item.stage,
        timestamp: item.approvedTime,
        approver: {
          name: item.approverName,
          isLoggedInUser: item.isLoggedInUser,
          stage: item.stage,
          approvedAmount: item.approvedAmount,
          approvedNonMonetaryReward: item.ApprovedRewards,
          rejectedNonMonetaryReward: item.RejectedRewards,
          approvedNonMonetaryRewardCount: item.ApprovedRewardsCount,
          rejectedNonMonetaryRewardCount: item.RejectedRewardsCount,
          rejectedAmount: item.rejectedAmount,
          comments: item.remarks,
          isApprovedByAdmin: item.isApprovedByAdmin,
          attachment: {
            fileName: item.fileName,
            url: item.downloadUrl,
          },
        },
      };
    }) || [];
  if (
    ![
      APPROVAL_STATUSES.PARTIALLYAPPROVED.label,
      APPROVAL_STATUSES.APPROVED.label,
      APPROVAL_STATUSES.REJECTED.label,
    ].includes(status)
  ) {
    return [
      {
        status: "pending",
        currentStage,
        timestamp: "",
        approver: {
          name: currentStageApprover?.fullName,
          isLoggedInUser: id === currentStageApprover?.userId,
          stage: currentStage,
          approvedAmount: "",
          rejectedAmount: "",
          approvedNonMonetaryReward: [],
          rejectedNonMonetaryReward: [],
          approvedNonMonetaryRewardCount: 0,
          rejectedNonMonetaryRewardCount: 0,
          comments: "",
          attachment: {},
        },
      },
      ...formatedHistory,
    ];
  }
  return formatedHistory;
};

export const getTemplateName = (templates, templateId) => {
  if (!templates) {
    return "";
  }
  const template = templates.find(
    (template) => template.TemplateId === templateId
  );
  return template ? template.Name : "";
};

export const getRewardOrCondtionName = ({
  templateName,
  goalName,
  selectedConditionorReward,
  selectedGoals,
  selectedTemplates,
  type,
}) => {
  const newTemplateName =
    templateName ||
    getTemplateName(selectedTemplates, selectedConditionorReward?.TemplateId);
  const newGoalName =
    goalName ||
    selectedGoals?.find(
      (goal) => goal.GoalId === selectedConditionorReward?.GoalId
    )?.GoalName;
  const ruleName =
    INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value === type
      ? selectedConditionorReward?.GoalId
        ? newGoalName
        : newTemplateName
      : newTemplateName;
  return ruleName;
};

export const isAttributeSelectionComplete = (state) => {
  return (
    state.RecurrencePeriod &&
    state.StartDate &&
    state.EndDate &&
    (state.SelectedGoals?.length ||
      state.Type === INCENTIVE_PROGRAM_TYPES.TEMPLATE_BASED.value)
  );
};

export const isUserSelectionComplete = (state) => {
  if (
    isAttributeSelectionComplete(state) &&
    state.Type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value
  ) {
    return true;
  }
  if (state.UsersCount > 0) {
    return true;
  }
  return false;
};

export const isCriteriaSelectionComplete = (state) => {
  if (state.CriteriaCount > 0) {
    return true;
  }
  return false;
};

export function getStartAndEndDate(duration) {
  if (duration === "ALL") {
    return {
      startsAt: new Date(null),
      endsAt: new Date(),
    };
  }
  const endDate = new Date();
  return {
    startsAt: format(
      new Date().setDate(endDate.getDate() - duration),
      DATE_FORMAT_PAYLOAD
    ),

    endsAt: format(endDate, DATE_FORMAT_PAYLOAD),
  };
}

export const getGoalName = ({ goalId, goals }) =>
  goals?.find((goal) => goal.GoalId === goalId)?.GoalName;
