import { useCallback, useState } from "react";

const useTabView = ({ activeTabIndex = 0, maxTabs }) => {
  const [state, setState] = useState({
    activeTabIndex,
    maxTabs,
    clickAwayTabIndex: -1,
    viewEdited: false,
    viewSubmittable: false,
    viewSubmitting: false,
  });

  const handleChange = ({ name, value }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const selectView = (tabIndex) => {
    const newState = {};
    if (!state.viewEdited && state.viewSubmittable) {
      newState.activeTabIndex = tabIndex;
    } else if (tabIndex < maxTabs) {
      newState.clickAwayTabIndex = tabIndex;
    }
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const nextView = () => {
    if (state.activeTabIndex < state.maxTabs) {
      setState((prevState) => ({
        ...prevState,
        activeTabIndex: prevState.activeTabIndex + 1,
      }));
    }
  };

  const clickAway = () => {
    if (state.clickAwayTabIndex > -1) {
      setState((prevState) => ({
        ...prevState,
        activeTabIndex: prevState.clickAwayTabIndex,
        viewEdited: false,
        viewSubmittable: false,
        clickAwayTabIndex: -1,
      }));
    }
  };

  const discardClickAway = () => {
    handleChange({ name: "clickAwayTabIndex", value: -1 });
  };

  const setViewEdited = useCallback((viewEdited) => {
    handleChange({ name: "viewEdited", value: viewEdited });
  }, []);

  const setViewSubmittable = useCallback((viewSubmittable) => {
    handleChange({ name: "viewSubmittable", value: viewSubmittable });
  }, []);

  const setViewSubmitting = useCallback((viewSubmitting) => {
    handleChange({ name: "viewSubmitting", value: viewSubmitting });
  }, []);

  return {
    ...state,
    actions: {
      selectView,
      nextView,
      clickAway,
      setViewEdited,
      setViewSubmittable,
      setViewSubmitting,
      discardClickAway,
    },
  };
};

export default useTabView;
