import { gql, useQuery } from "src/api/client";

export const GET_INCENTIVES_ACHIEVEMENT_DRILLDOWN_QUERY = gql`
  query fetchProgramDrilldown(
    $RecurrenceId: ID!
    $ProgramId: ID!
    $ManagerId: ID
    $UserId: ID
    $RuleId: ID
    $TemplateId: ID
    $After: Int
    $Limit: Int
    $Search: String
    $UserAttributesNames: [String]
  ) {
    fetchProgramDrilldown(
      RecurrenceId: $RecurrenceId
      ProgramId: $ProgramId
      TemplateId: $TemplateId
      UserId: $UserId
      RuleId: $RuleId
      ManagerId: $ManagerId
      After: $After
      Limit: $Limit
      Search: $Search
      UserAttributesNames: $UserAttributesNames
    ) {
      ... on ProgramDrillDownResponse {
        success
        message
        response {
          Users
          NextPageExists
          TotalUsers
          After
          LastUpdated
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetProgramDrilldown = (queryOptions) => {
  return useQuery(GET_INCENTIVES_ACHIEVEMENT_DRILLDOWN_QUERY, queryOptions);
};
