import { gql, useMutation } from "src/api/client";

// src/modules/templates/headerContainer/DeleteTemplate.js
export const DELETE_TEMPLATE_MUTATION = gql`
  mutation UpdateTemplate($TemplateId: ID!, $IsDelete: Boolean) {
    updateTemplate(TemplateId: $TemplateId, IsDelete: $IsDelete) {
      ... on SingleTemplateResponse {
        response {
          Template {
            Name
            Description
            Status
            IsDelete
          }
        }
        success
        message
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useDeleteTemplate = () => {
  return useMutation(DELETE_TEMPLATE_MUTATION);
};
