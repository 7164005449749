import React from "react";
import { Truncate } from "src/components/common/atoms";
import { COLORS } from "src/constants";
import { SvgConnect } from "src/icons";

const SideBannerCardLeft = (props) => {
  const { icon, amount, description, title, cycleCount, linkedProgramsCount } =
    props;
  return (
    <div className="side-banner-card-left">
      <div className="fs-22 semi-bold fc-white mb-3">
        <Truncate
          id="program-name"
          maxWidth="200px"
          text={title}
          truncType="end"
        />
      </div>
      <div className="space-x-16">
        <span className="glass-container fs-13 fc-white br-4 px-12 py-1">
          {cycleCount}
        </span>
        {Boolean(linkedProgramsCount) && (
          <span className="glass-container fs-13 fc-white br-4 px-12 py-1">
            <SvgConnect color={COLORS.WHITE} />
            <span className="ml-1 semi-bold">{linkedProgramsCount}</span>
          </span>
        )}
      </div>
      <div className="d-flex align-items-center mt-5 ">
        <div>{icon}</div>
        <div className="fc-white ml-2">
          <div className="fs-28 bold">{amount}</div>
          <div className="fs-14">{description}</div>
        </div>
      </div>
      <div className="glass-container" />
    </div>
  );
};

export default SideBannerCardLeft;
