import { t } from "i18next";
import { Modal } from "react-bootstrap";
import {
  FormButton,
  LoadMoreButton,
  Truncate,
  toaster,
} from "src/components/common";
import { errorText } from "src/helpers";
import useAchievementbreakdown from "../hooks/useAchievementbreakdown";
import AchievementBreakDownHeader from "./AchievementBreakDownHeader";
import AchievementBreakDownTable from "./AchievementBreakDownTable";
import "./achievementBreakdown.scss";

const AchievementBreakDown = (props) => {
  const {
    show,
    onHide,
    title,
    currentRecurrence,
    recurrenceList,
    templateId,
    templates,
    selectedGoalUser,
    customType = "",
    managerUserId,
    isIncentive,
    managersDropdownList,
    isDrillDown = false,
    moduleName,
    programId,
    formatOptionLabelAfter,
    goalAggConfig,
    isRollUp,
  } = props;
  const {
    attributes,
    actions,
    templateDetails,
    userAttributes,
    achievementDrilldown,
    usersListWithAchievements,
    isSalesUser,
  } = useAchievementbreakdown({
    selectedGoalUser,
    currentRecurrence,
    templateId,
    templates,
    managerUserId,
    isIncentive,
    managersDropdownList,
    isDrillDown,
    moduleName,
    programId,
    isRollUp,
  });
  const { Recurrence, User, FieldsOrder, Rule } = attributes;
  const {
    setFieldsOrder,
    setGoalUser,
    setSelectedRecurrence,
    setSelectedRule,
    setSearch,
  } = actions;

  if (usersListWithAchievements.error) {
    toaster({ message: errorText.GET_USERS_ACHIEVEMENTS.ERROR, type: "error" });
  }
  if (userAttributes.error) {
    toaster({ message: errorText.GET_USER_ATTRIBUTES.ERROR, type: "error" });
  }
  if (templateDetails.error) {
    toaster({ message: errorText.GET_TEMPLATE_DETAIL.ERROR, type: "error" });
  }
  if (achievementDrilldown.error) {
    toaster({ message: errorText.GET_DRILLDOWN_DATA.ERROR, type: "error" });
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      scrollable
      dialogClassName="achievement-break-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex flex-wrap fs-18">
            <span className="pr-1">
              {!title
                ? t("COMMON.LABELS.CONTRIBUTION_BREAKDOWN")
                : `${t("COMMON.LABELS.ACHIEVEMENT_BREAKDOWN")} - `}
            </span>
            <span className="fc-grey">
              <Truncate
                id="achievement-break-down-goal-name"
                maxWidth="400px"
                truncType="end"
                text={title}
              />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="achievement-break-down-body">
        <AchievementBreakDownHeader
          recurrencesList={recurrenceList}
          selectedRecurrence={Recurrence}
          setSelectedRecurrence={setSelectedRecurrence}
          usersDetailsList={usersListWithAchievements.data}
          isIncentive={isIncentive}
          selectedGoalUser={User}
          setGoalUser={setGoalUser}
          setSearch={setSearch}
          selectedRule={Rule}
          hasMoreUsers={usersListWithAchievements.hasMoreUsers}
          onFetchMore={usersListWithAchievements.onFetchMore}
          usersAchievementLoading={usersListWithAchievements.loading}
          templateLoading={templateDetails.loading}
          templateDetails={templateDetails.data}
          setSelectedRule={setSelectedRule}
          goalValue={User?.GoalValue}
          goalAchieved={User?.Achievement}
          customType={customType}
          formatOptionLabelAfter={formatOptionLabelAfter}
          goalAggConfig={goalAggConfig}
          hideUserDropDown={usersListWithAchievements.hideUserDropDown}
          moduleName={moduleName}
        />
        <AchievementBreakDownTable
          usersAttributeList={userAttributes.data}
          gridLoading={achievementDrilldown.firstFetchLoading}
          gridError={achievementDrilldown.error}
          userAttributesLoading={userAttributes.loading}
          fieldsOrder={FieldsOrder}
          setFieldsOrder={setFieldsOrder}
          tableData={achievementDrilldown.data}
          dataNotAvailable={achievementDrilldown.dataNotAvailable}
          userNotPartOfRecurrence={achievementDrilldown.userNotPartOfRecurrence}
          handleDoneButton={userAttributes.handleDoneButton}
          isSalesUser={isSalesUser}
          hideBreakdownTable={achievementDrilldown.hideBreakdownTable}
        />
        {!achievementDrilldown.disabledLoadMore &&
          !achievementDrilldown.hideBreakdownTable && (
            <div
              ref={achievementDrilldown.loadMoreButtonRef}
              className="w-100 my-3 flex-center"
            >
              <LoadMoreButton
                variant="secondary"
                loading={
                  achievementDrilldown.firstFetchLoading ||
                  achievementDrilldown.nextFetchLoading
                }
                disabled={achievementDrilldown.disabledLoadMore}
                id="goals-managed-by-me-show-more"
                onClick={achievementDrilldown.onFetchMore}
                itemsLoaded={achievementDrilldown.itemsLoaded}
                totalItems={achievementDrilldown.totalItems}
              />
            </div>
          )}
      </Modal.Body>
      <Modal.Footer className="w-100 center">
        {achievementDrilldown.lastUpdated && (
          <span className="fs-14 font-italic fc-grey1">
            {t("INCENTIVES.MY_INCENTIVES.MESSAGES.DATA_UPDATED")}{" "}
            {achievementDrilldown.lastUpdated}
          </span>
        )}
        <FormButton
          id="close-btn"
          className="ml-auto"
          variant="outline-primary"
          onClick={onHide}
          label={t("COMMON.BUTTONS.CLOSE")}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default AchievementBreakDown;
