import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const ADD_GROUPLIST_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation addGroupList(
    $LeaderboardId: ID!
    $RecurrenceId: ID!
    $UserIdentifier: String!
    $GroupName: [String]!
  ) {
    addGroupList(
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
      UserIdentifier: $UserIdentifier
      GroupName: $GroupName
    ) {
      ... on AddGroupListResponse {
        message
        success
        response {
          GroupId
          GroupName
          UserIdentifier
        }
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;

export const useAddGroupList = () => {
  return useMutation(ADD_GROUPLIST_MUTATION);
};
