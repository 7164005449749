import React from "react";

const SvgAvatar41 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="tckw__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .tckw__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="tckw__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="tckw__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="tckw__cls-2"
        />
        <path
          d="M82.44 74.51c-12.96 0-25.96-.95-38.66-2.83-13.15-2.38-20.41-6-20.41-10.19 0-5.38 11.73-9.71 32.18-11.88l.89-.09v-9.29c0-3.52 1.38-6.82 3.86-9.31a13.07 13.07 0 0 1 9.31-3.86h7.14c.69 0 1.27.52 1.36 1.2v.07l.03.07c.5 1.88 1.33 3.62 2.49 5.18l.8 1.09.8-1.09c1.15-1.56 1.99-3.31 2.49-5.18l.02-.07v-.07c.09-.68.67-1.2 1.36-1.2h7.14c3.52 0 6.82 1.38 9.31 3.86a13.07 13.07 0 0 1 3.86 9.31v9.28l.89.09c20.45 2.17 32.18 6.5 32.18 11.88 0 4.19-7.26 7.81-20.44 10.19-.16.03-14.58 2.82-36.61 2.82Zm-27.1-22.13c-19.2 2.09-29.24 6.16-29.24 9.11 0 4.36 21.05 10.45 55.33 10.45s55.33-6.09 55.33-10.45c0-2.95-10.04-7.02-29.24-9.11l-1.11-.12v6.87c0 .52-.29 1-.75 1.23a57.95 57.95 0 0 1-23.32 4.86h-1.79c-8.1 0-15.97-1.65-23.4-4.9-.42-.21-.71-.68-.71-1.2v-6.87l-1.11.12Zm26.45 10.11c7.3 0 14.46-1.34 21.26-3.97l.64-.25V47.06l-1.33.46a62.48 62.48 0 0 1-20.68 3.51h-.5a62.48 62.48 0 0 1-20.68-3.51l-1.33-.46v11.2l.64.25a58.938 58.938 0 0 0 21.32 3.98H81.79Zm.03-14.17c7.29 0 14.44-1.34 21.23-3.97l.64-.25v-3.87c0-2.79-1.09-5.41-3.06-7.39-1.98-1.97-4.6-3.06-7.39-3.06h-6.09l-.23.69c-.62 1.87-1.57 3.59-2.84 5.1a3.387 3.387 0 0 1-5.29.02 15.59 15.59 0 0 1-2.86-5.12l-.23-.69h-6.09c-2.79 0-5.41 1.09-7.39 3.06-1.97 1.97-3.06 4.6-3.06 7.39v3.86l.64.25c6.83 2.64 14 3.98 21.31 3.98h.7Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar41.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar41;
