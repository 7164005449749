import React from "react";

const SvgErrorToast = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l633r_a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l633r_a)" data-name="Group 7424">
        <g data-name="Group 6901" transform="translate(1 1)">
          <circle
            cx={16}
            cy={16}
            r={16}
            fill={backgroundColor}
            data-name="Ellipse 152"
            transform="translate(-1 -1)"
          />
          <g data-name="Group 7112" transform="translate(7.954 8.627)">
            <circle
              cx={7}
              cy={7}
              r={7}
              fill="none"
              stroke={color}
              strokeWidth={1.5}
              data-name="Ellipse 146"
              transform="translate(.046 -.627)"
            />
            <g data-name="Group 7114">
              <g
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeWidth={1.5}
                data-name="Group 6940"
              >
                <path d="m5.133 8.245 3.825-3.742" data-name="Path 3997" />
                <path d="m5.175 4.46 3.741 3.825" data-name="Path 3998" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgErrorToast.defaultProps = {
  backgroundColor: "#ffebeb",
  color: "#ed4141",
  height: 32,
  width: 32,
};
export default SvgErrorToast;
