import { t } from "i18next";
import React from "react";
import { CustomFormulaExamplesModal } from "src/components/base";
import {
  DisplayFormula,
  FormulaBuilder,
} from "src/components/common/molecules";
import { SvgCustomFormula } from "src/icons";
import { CustomFormulaExamples } from "src/images";

function CustomFormula({ editStatus, formulaBuilderProps }) {
  return (
    <div className="ruleset-formula-custom">
      <div className="flex-between mb-3">
        <div className="fs-16 center semi-bold type-title space-x-3">
          <SvgCustomFormula width="16" height="16" />
          <span>{t("COMMON.MESSAGES.CUSTOM_FORMULA")}</span>
        </div>
        {editStatus ? (
          <CustomFormulaExamplesModal
            text={t("COMMON.LABELS.SHOW_EXAMPLES")}
            img={<CustomFormulaExamples />}
          />
        ) : null}
      </div>
      <div>
        {editStatus ? (
          <FormulaBuilder {...formulaBuilderProps} />
        ) : (
          <DisplayFormula formula={formulaBuilderProps.formula} />
        )}
      </div>
    </div>
  );
}

export default CustomFormula;
