import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgForward = (props) => {
  const { backgroundColor, color, height, width, styles, rotate, ...rest } =
    props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...styles,
        transform: `rotate(${rotate}deg)`,
        transformOrigin: "50% 50%",
      }}
      {...rest}
    >
      <defs>
        <clipPath id="l633__x">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l633__x)" data-name="Group 12984">
        <g data-name="Group 12729" transform="translate(-941 -392)">
          <circle
            cx={12}
            cy={12}
            r={12}
            fill={backgroundColor}
            data-name="Ellipse 193"
            transform="translate(941 392)"
          />
        </g>
        <path
          fill={color}
          d="m11.252 7.779.5-.5a.533.533 0 0 1 .757 0l4.334 4.341a.533.533 0 0 1 0 .757L12.5 16.716a.533.533 0 0 1-.757 0l-.5-.5a.536.536 0 0 1 .009-.766l2.698-2.558H7.536A.534.534 0 0 1 7 12.357v-.714a.534.534 0 0 1 .536-.536h6.414l-2.689-2.562a.532.532 0 0 1-.009-.766Z"
          data-name="Icon awesome-arrow-right"
        />
      </g>
    </svg>
  );
};

SvgForward.defaultProps = {
  backgroundColor: COLORS.GREY_2,
  color: COLORS.WHITE,
  height: 24,
  width: 24,
  rotate: 0,
  styles: {},
};
export default SvgForward;
