import React, { lazy } from "react";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

const MobilePage = lazy(() =>
  import(
    /* webpackChunkName: 'leaderboard.mobile.dashboardBoardDetailViewPage' */ "./mobile"
  )
);

export const DashboardBoardDetailViewPage = {
  web: () => <Redirect to={APPS.LEADERBOARDS.ROUTES.DASHBOARD.path} />,
  mobile: MobilePage,
  meta: {
    title: "dashboard-boardview-details",
  },
};
