import {
  APPS,
  MOBILE_PRODUCT_MENU_LIST,
  ROLES,
  WEB_PRODUCTS_MENU_LIST,
} from "src/constants";
import { useRoleBasedContext } from "src/context";
import { isRouteActive } from "src/helpers";
import { useLocation } from "src/router";

const useRbac = () => {
  const PRODUCTS_MAP = {
    [APPS.SETTINGS.ROUTES.ROOT.path]: "Rulesets",
    [APPS.RULESETS.ROUTES.ROOT.path]: "Rulesets",
    [APPS.GOALS.ROUTES.ROOT.path]: "Goals",
    [APPS.NUDGES.ROUTES.ROOT.path]: "Goals",
    [APPS.INCENTIVES.ROUTES.ROOT.path]: "Incentives",
    [APPS.LEADERBOARDS.ROUTES.ROOT.path]: "Leaderboard",
  };

  const {
    userDetails: { role },
    isFeatureEnabled,
  } = useRoleBasedContext();
  const { pathname } = useLocation();

  const rbac = (route) => {
    switch (route) {
      case APPS.RULESETS.ROUTES.ROOT.path:
      case APPS.SETTINGS.ROUTES.ROOT.path: {
        return {
          hasAccess: role === ROLES.ADMIN,
        };
      }

      default: {
        const featureName = PRODUCTS_MAP[route];
        return {
          hasAccess: featureName ? isFeatureEnabled[featureName] : false,
        };
      }
    }
  };

  const mobileMenu = MOBILE_PRODUCT_MENU_LIST.filter(
    ({ disabled, baseRoute }) => !disabled && rbac(baseRoute).hasAccess
  );

  const mobileActiveRoute = mobileMenu.find(({ baseRoute: route }) =>
    isRouteActive({ pathname, route })
  );

  const webMenu = WEB_PRODUCTS_MENU_LIST.filter(
    ({ baseRoute }) => rbac(baseRoute).hasAccess
  );

  const webActiveRoute = webMenu.find(({ baseRoute: route }) =>
    isRouteActive({ pathname, route })
  );

  return {
    rbac,
    webMenu,
    webActiveRoute,
    mobileMenu,
    mobileActiveRoute,
  };
};

export default useRbac;
