import { gql, useQuery } from "src/api/client";
import {
  CORE_GOAL_DETAILS_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
} from "src/api/fragments";

// src/modules/landingPage/assignedToMe/AssignedGoals.js
const GET_ALL_ASSIGNED_GOALS_QUERY = gql`
  ${CORE_GOAL_DETAILS_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query getAllAssignedGoals($Limit: Float, $Query: JSON, $After: String) {
    getAllAssignedGoals(Limit: $Limit, Query: $Query, After: $After) {
      ... on AllAssignedGoalResponse {
        success
        message
        response {
          totalCount
          hasGoals
          Goals {
            ...coreGoalDetailsFragment
            GoalType
            GoalAchieved
            RecurrenceType
            RecurrenceDue
            RecurrenceDefinition
            FutureCycleDuration
            InGaps
            RecurrenceDueText
            CreatedBy
            GoalAggConfig
            IsUserPresent
            AvgSum
            AvgCount
            Template {
              GoalAggregateFunction
            }
          }
          pageInfo {
            endCursor
            hasPreviousPage
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetAllAssignedGoals = (queryOptions) => {
  return useQuery(GET_ALL_ASSIGNED_GOALS_QUERY, queryOptions);
};

export const allAssignedGoalsMocks = [
  {
    request: {
      query: GET_ALL_ASSIGNED_GOALS_QUERY,
      variables: {
        After: null,
        Limit: 9,
        Query: { GoalStatus: "INPROGRESS" },
      },
    },
    result: {
      data: {
        getAllAssignedGoals: {
          success: true,
          message: "Success",
          response: {
            totalCount: 1,
            hasGoals: true,
            Goals: [
              {
                Name: "testing assigned-to-me",
                GoalId: "93da5ac4-f778-4f21-b47e-795de0777055",
                Description: "",
                GoalType: "EFFORT",
                GoalAchieved: null,
                RecurrenceType: "WEEKLY",
                RecurrenceDue: null,
                Status: "PUBLISHED",
                RecurrenceDefinition: {
                  FirstRecurrenceStartDate: "2022-05-09",
                  LastRecurrenceEndDate: "2022-08-28",
                  RecurrencePeriod: "WEEKLY",
                },
                RecurrenceDueText: "1 day",
                CreatedBy: "30a25313-5989-11eb-95ed-0a5142c8570a",
                GoalValue: 0,
                TotalCycles: "16 Weekly cycles",
                CurrentRecurrenceCycle: "6th cycle in progress",
                RecurrenceTimeLeft: "1 day remaining",
                IsUserPresent: false,
                __typename: "Goal",
              },
            ],
            pageInfo: {
              endCursor:
                "WyIyMDIyLTA1LTEwVDE0OjE2OjEzLjU3OFoiLCI5M2RhNWFjNC1mNzc4LTRmMjEtYjQ3ZS03OTVkZTA3NzcwNTUiXQ==",
              hasPreviousPage: false,
              __typename: "PageInfo",
            },
            __typename: "GoalResponse",
          },
          __typename: "AllAssignedGoalResponse",
        },
      },
    },
  },
];
