import { t } from "i18next";
import React from "react";
import { BaseCard, Truncate } from "src/components/common/atoms";
import { APPS, FORMAT_AGGREGATE_FUNCTION } from "src/constants";
import { classNames, getPluralText } from "src/helpers";
import { useHistory } from "src/router";
import { RulesetStatusBadge } from "../rulesetStatusBadge";
import "./mobileRulesetCard.scss";

const MobileRulesetCard = ({
  Name,
  AuditType,
  Status,
  GoalRules,
  GoalAggregateFunction,
  TemplateId,
}) => {
  const history = useHistory();
  const auditType = AuditType === "NON_STICKY" ? "Non Sticky" : "Sticky";

  const ruleCount = GoalRules?.length;

  const aggregateFunction = GoalAggregateFunction
    ? GoalAggregateFunction === "CUSTOM"
      ? "Multiple Function"
      : `${FORMAT_AGGREGATE_FUNCTION[GoalAggregateFunction]?.displayFormat} Function`
    : null;

  const isDraft = Status === "DRAFT";

  return (
    <BaseCard
      onclick={() =>
        isDraft
          ? undefined
          : history.push(
              APPS.RULESETS.ROUTES.RULESET_CRUD.link({
                params: {
                  id: TemplateId,
                },
              })
            )
      }
      className="mt-3 bg-white flex-between mobile-ruleset-card h-100 p-2"
    >
      <div>
        <div className="fs-14 semi-bold center">
          <div
            className={classNames([
              !isDraft ? "fc-blue cursor-pointer" : "cursor-not-allowed",
            ])}
          >
            <Truncate text={Name} maxWidth="150px" />
          </div>
          {ruleCount ? (
            <span className="ml-2 normal fc-grey2">
              (
              {getPluralText(
                ruleCount,
                t("COMMON.LABELS.RULE"),
                t("COMMON.LABELS.RULES")
              )}
              )
            </span>
          ) : null}
        </div>
        <div className="fs-12 fc-grey1">
          <span>{auditType}</span>
          {aggregateFunction && (
            <>
              <span className="mx-1 fc-grey2">|</span>
              <span>{aggregateFunction}</span>
            </>
          )}
        </div>
      </div>
      <div>
        <RulesetStatusBadge status={Status} />
      </div>
    </BaseCard>
  );
};

export default MobileRulesetCard;
