import { gql, useMutation } from "src/api/client";

const BULK_APPROVE_PROGRAM_MUTATION = gql`
  mutation bulkApprovePrograms(
    $programId: ID!
    $recurrenceId: ID
    $users: [ID]
    $approverId: ID
    $fileId: Float
    $fileName: FileName
    $remarks: String
  ) {
    bulkApprovePrograms(
      programId: $programId
      recurrenceId: $recurrenceId
      users: $users
      approverId: $approverId
      fileId: $fileId
      fileName: $fileName
      remarks: $remarks
    ) {
      response
    }
  }
`;

export const useBulkApprovePrograms = () => {
  return useMutation(BULK_APPROVE_PROGRAM_MUTATION);
};
