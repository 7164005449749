import { useAuth, useIsOpenAceUser } from "src/hooks";

export const getUserTimezone = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { auth } = useAuth();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isOpenAceUser } = useIsOpenAceUser();

  const { userAttributesForContext } = auth;
  if (userAttributesForContext) {
    const userAttributesParsed = JSON.parse(userAttributesForContext);

    if (isOpenAceUser) {
      return userAttributesParsed.UserTimeZone === "Asia/Kolkata";
    }

    if (userAttributesParsed.UserTimeZoneName) {
      return userAttributesParsed.UserTimeZoneName === "India Standard Time";
    }
    return null;
  }
  return null;
};

export const getCurrency = () => {
  const isIndianTimezone = getUserTimezone();
  return !isIndianTimezone ? "$" : "₹";
};

export const NumFormatter = (number, timezone = null, digits = 2) => {
  const temp = number < 0;
  const num = Number(Math.abs(number));
  const InternationalFormat = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const IndianFormat = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e5, symbol: "L" },
    { value: 1e7, symbol: "Cr" },
    { value: 1e10, symbol: "KCr" },
    { value: 1e12, symbol: "LCr" },
  ];
  const isIndiantimezone = timezone === null ? getUserTimezone() : timezone;
  const lookup = isIndiantimezone ? IndianFormat : InternationalFormat;
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((format) => num >= format.value);

  const result = item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : num.toFixed(digits).replace(rx, "$1");
  return temp ? `-${result}` : result;
};
