import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgOwnerDeactivated = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill="none" d="M0 0h16v16H0Z" data-name="Path 4790" />
      <g data-name="Group 8712">
        <path
          fill={backgroundColor}
          d="M6.996 14.998H5.537v-.006H1.996a1 1 0 0 1-1-1v-1a3.989 3.989 0 0 1 3.053-3.889l.582 1.165a3.561 3.561 0 0 0-1.939 3.281h3.006a5.031 5.031 0 0 0 1.3 1.443ZM5.674 8.476a3.858 3.858 0 1 1 4.99-2.437 5.017 5.017 0 0 0-1.4.007 2.571 2.571 0 1 0-2.732 1.337 4.956 4.956 0 0 0-.861 1.093Z"
          data-name="Subtraction 37"
        />
        <g data-name="351983_error_icon">
          <path fill="none" d="M4.996 5.998h10v10h-10Z" data-name="Path 4785" />
          <path
            fill={color}
            d="M9.992 15.334a4.334 4.334 0 1 1 4.336-4.335 4.34 4.34 0 0 1-4.336 4.335Zm0-3.376a.819.819 0 1 0 .82.819.818.818 0 0 0-.82-.819Zm0-3.542a.925.925 0 0 0-.924.925 1.053 1.053 0 0 0 .065.346l.74 1.817a.132.132 0 0 0 .119.08.149.149 0 0 0 .127-.08c.039-.098.733-1.815.739-1.839a.859.859 0 0 0 .063-.326.926.926 0 0 0-.929-.923Z"
            data-name="Subtraction 36"
          />
        </g>
      </g>
    </svg>
  );
};

SvgOwnerDeactivated.defaultProps = {
  backgroundColor: COLORS.GREY_1,
  color: COLORS.PRIMARY_ORANGE,
  height: 16,
  width: 16,
};
export default SvgOwnerDeactivated;
