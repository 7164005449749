import PropTypes from "prop-types";
import React from "react";
import Select, { components } from "react-select";
import { Truncate } from "../truncate";

const ValueContainer = ({ children, ...props }) => {
  const { getValue } = props;

  const values = getValue();
  let valueLabel = "";
  let groupLabel = "";

  if (values.length > 0) {
    valueLabel += props.selectProps.getOptionLabel(values[0]);
    groupLabel = values[0].Category;
  }

  const childrenToRender = React.Children.toArray(children).filter(
    (child) =>
      ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
  );

  return (
    <components.ValueContainer {...props}>
      {childrenToRender}
      {!props.selectProps.inputValue && valueLabel ? (
        <span className="value-container-label center space-x-6">
          {groupLabel && (
            <span className="group-label center space-x-6">
              <Truncate text={groupLabel} maxWidth="280px" />
              <span>-</span>
            </span>
          )}
          {valueLabel && (
            <span className="value-label">
              {<Truncate text={valueLabel} maxWidth="280px" />}
            </span>
          )}
        </span>
      ) : (
        false
      )}
    </components.ValueContainer>
  );
};

const customStyles = {
  container: (provided) => ({
    ...provided,
    paddingTop: "0px !important",
    fontSize: "12px",
    marginLeft: "-4px",
  }),
  menu: (provided) => ({
    ...provided,
    borderTop: "1px solid #309afc",
    marginTop: "-1px !important",
  }),
  singleValue: (provided) => ({
    ...provided,
    height: "20px",
  }),
  group: (provided) => ({
    ...provided,
    padding: "0px",
  }),
  groupHeading: (provided) => ({
    ...provided,
    fontSize: "13px",
    fontWeight: "600",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "black",
    cursor: "pointer",
    backgroundColor: state.isFocused ? "#f1fafe" : "",
    padding: "12px 20px",
  }),
};
const formatGroupLabel = (data) => data?.Category;
const GroupReactSelect = (props) => {
  const {
    id,
    components,
    styles,
    placeholder,
    options,
    getOptionLabel,
    getGroupLabel,
    handleOnChange,
    defaultValue,
    optionValueKey,
    optionLabelKey,
  } = props;

  const formatOptionLabel = (option) => option?.Name;

  const optionLabel = optionLabelKey
    ? {
        getOptionLabel: (option) => option[optionLabelKey],
      }
    : { getOptionLabel };

  const optionValue = optionValueKey
    ? {
        getOptionValue: (option) => option[optionValueKey],
      }
    : {};

  return (
    <Select
      {...props}
      id={id}
      classNamePrefix="drop-select"
      placeholder={placeholder}
      components={components || { ValueContainer }}
      styles={styles || customStyles}
      defaultValue={defaultValue}
      onChange={(e) => handleOnChange(e)}
      options={options}
      getOptionLabel={optionLabel?.getOptionLabel || formatOptionLabel}
      formatGroupLabel={getGroupLabel || formatGroupLabel}
      {...optionValue}
    />
  );
};

GroupReactSelect.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
};

GroupReactSelect.defaultProps = {
  id: "group-select",
  placeholder: "Select Option",
};

export default GroupReactSelect;
