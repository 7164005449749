import { t } from "i18next";
import React from "react";
import { ConfirmationModal } from "src/components/common";

const DeleteFilterModal = ({
  selectedFilter,
  onClose,
  show,
  handleDelete,
  selectedIndex,
}) => {
  return (
    <ConfirmationModal
      title={t("COMMON.LABELS.DELETE_QUICK_FILTER")}
      body={
        <div>
          <div>
            {t("COMMON.MESSAGES.ARE_YOU_SURE_YOU_WANT_TO_DELETE")}
            <span className="semi-bold ml-1">
              {selectedFilter?.FilterName}?
            </span>
          </div>
          {t("RULEBOOK.MESSAGES.ACTION_CANNOT_BE_DONE")}
        </div>
      }
      confirmButtonText={t("COMMON.BUTTONS.YES_DELETE_FILTER")}
      show={show}
      onClose={onClose}
      onConfirm={() => {
        handleDelete({ index: selectedIndex });
        onClose();
      }}
      cancelButton
      dialogClassName="modal-filter-input"
      addButtonsInfooter
    />
  );
};

export default DeleteFilterModal;
