import { gql, useQuery } from "src/api/client";

export const GET_GOAL_BLOCKING_ITEMS_QUERY = gql`
  query getGoalCrudBlockingItems($GoalId: ID!, $Status: String!) {
    getGoalCrudBlockingItems(GoalId: $GoalId, Status: $Status) {
      ... on GoalCrudBlockingResponse {
        success
        message
        response {
          Incentives
          Leaderboard
          IncentiveCount
          LeaderboardCount
          TotalCount
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetGoalCrudBlockingItems = (queryOptions) => {
  return useQuery(GET_GOAL_BLOCKING_ITEMS_QUERY, queryOptions);
};
