import "./displayFormula.scss";
import FormulaItem from "./FormulaItem";

const DisplayFormula = ({ formula }) => {
  return (
    <div className="display-formula center">
      {formula?.map((formulaItem) => {
        return <FormulaItem item={formulaItem} />;
      })}
    </div>
  );
};

export default DisplayFormula;
