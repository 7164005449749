import React from "react";

const SvgAvatar14 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="f4p4__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .f4p4__cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:3px}.f4p4__cls-2{fill:#fff}.f4p4__cls-3{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="f4p4__Layer_1-2">
        <path
          d="M162.8 81.4c0 16.29-4.78 31.46-13.04 44.18a78.534 78.534 0 0 1-4.28 5.99 79.493 79.493 0 0 1-4.22 4.96c-.5.54-1 1.08-1.52 1.61-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-.2.19-.41.39-.61.56a69.97 69.97 0 0 1-4.23 3.7c-.24.2-.48.4-.73.59-.76.62-1.54 1.21-2.33 1.79-.4.3-.81.6-1.22.89-.5.36-1.01.72-1.53 1.05-.73.51-1.47.99-2.22 1.45-.12.08-.24.16-.36.23-1.4.88-2.83 1.71-4.28 2.5-.43.22-.85.45-1.28.67-.81.43-1.63.83-2.46 1.23a80.46 80.46 0 0 1-26.28 7.39c-.81.09-1.63.17-2.45.22-1.56.13-3.14.2-4.73.23-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-.73-.01-1.46-.04-2.19-.07-.85-.04-1.69-.09-2.53-.16-.65-.04-1.3-.1-1.95-.17-.17-.01-.34-.03-.51-.05a80.472 80.472 0 0 1-26.27-7.39c-.83-.39-1.65-.8-2.46-1.23a81.243 81.243 0 0 1-19.53-14.13c-.2-.2-.4-.39-.6-.59-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73-.39-.43-.77-.86-1.16-1.29-.9-1.03-1.77-2.08-2.62-3.15-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.77}
          className="f4p4__cls-3"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.41 72.09)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="f4p4__cls-3"
        />
        <path
          d="M138.02 102.13c0-6.79-8.53-16.18-8.9-16.57-.21-.23-.51-.37-.82-.37h-.01c-.31 0-.61.13-.83.35-.36.38-8.91 9.43-8.91 16.59 0 3.53 1.9 6.61 4.71 8.32-.89.62-1.48 1.66-1.48 2.83 0 1.31.74 2.43 1.82 3.02l1.12 34.01c.75-.46 1.49-.94 2.22-1.45l-1.06-32.13h4.81l-1 30.19c.79-.58 1.57-1.17 2.33-1.79l.95-28.83c1.08-.59 1.82-1.71 1.82-3.02 0-1.17-.59-2.2-1.48-2.83 2.82-1.71 4.71-4.79 4.71-8.32Zm-6.68 12.33h-6.1c-.66 0-1.19-.53-1.19-1.18s.53-1.19 1.19-1.19h6.1c.65 0 1.18.53 1.18 1.19s-.53 1.18-1.18 1.18Zm-3.05-4.87a7.46 7.46 0 0 1-7.46-7.46c0-5.05 5.34-11.68 7.44-14.09 2.1 2.48 7.48 9.3 7.48 14.09 0 4.12-3.35 7.46-7.46 7.46ZM20.26 135.13c-.69-3.82-.97-8.7 1.18-11.7.88-1.23 2.58-2.7 5.69-2.7 9.58 0 41.74 32.77 50.6 41.98-.85-.04-1.69-.09-2.53-.16-.65-.04-1.3-.1-1.95-.17-27.29-28.25-42.47-38.65-46.12-38.65-1.51 0-2.55.46-3.25 1.44-2.36 3.3-.41 11.38.6 14.41l-4.22-4.45Z"
          className="f4p4__cls-2"
        />
        <ellipse
          cx={32.23}
          cy={134.89}
          className="f4p4__cls-1"
          rx={3.48}
          ry={5.22}
          transform="rotate(-49.99 32.237 134.896)"
        />
        <ellipse
          cx={46.37}
          cy={146.76}
          className="f4p4__cls-1"
          rx={3.48}
          ry={5.22}
          transform="rotate(-49.99 46.38 146.764)"
        />
        <path
          d="M113.72 72.28c3.05-.2 5.95-1.74 7.8-4.44l.96-1.4c3.9-5.71 3.17-13.41-1.72-18.3s-11.54-11.53-11.54-11.53c-9.71-9.71-22.63-15.05-36.37-15.05h-2.51c-6.94 0-12.89 4.96-14.15 11.79l-.8 4.31c-.58 3.12.36 6.22 2.32 8.52l3.39 2.64 52.63 23.47Zm5.35-8.17-.95 1.4c-1.72 2.52-4.98 3.39-7.74 2.1L62.85 45.09a6.2 6.2 0 0 1-3.43-6.7l.8-4.31a10.3 10.3 0 0 1 10.11-8.42h2.51c12.67 0 24.53 4.93 33.46 13.84l11.54 11.53c3.5 3.49 4.01 8.99 1.23 13.07Z"
          className="f4p4__cls-2"
        />
        <path
          d="m95.56 28.38 3.53-12.21 2.92 2.06-4.38 10.98-2.07-.83z"
          className="f4p4__cls-2"
        />
      </g>
    </svg>
  );
};
SvgAvatar14.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar14;
