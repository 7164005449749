import React from "react";
import { Image } from "src/components";
import SelectTemplateModalEmptyStateImg from "./SelectTemplateModalEmptyState.svg";

const SelectTemplateModalEmptyState = (props) => (
  <Image
    src={SelectTemplateModalEmptyStateImg}
    alt="SelectTemplateModalEmptyStateImg"
    {...props}
  />
);

export default SelectTemplateModalEmptyState;
