import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgManagerNoHierarchy = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 8714">
        <path fill="none" d="M0 0h16v16H0Z" data-name="Path 4790" />
        <g data-name="Group 8705">
          <path fill="none" d="M0 0h16v16H0z" data-name="Rectangle 2966" />
          <g data-name="Group 8718">
            <path
              fill={backgroundColor}
              d="M5.198 12.5a.6.6 0 0 1-.6-.6.9.9 0 0 0-.9-.9h-.6a.9.9 0 0 0-.9.9.6.6 0 0 1-1.2 0 2.1 2.1 0 0 1 2.1-2.1h.6a2.1 2.1 0 0 1 2.1 2.1.6.6 0 0 1-.6.6Zm.836-3.121A.6.6 0 0 1 5.8 8.9a2.112 2.112 0 0 1 1.191-1.891 4.966 4.966 0 0 0-.955 2.37Zm-2.641-.378a2 2 0 1 1 2-2A2 2 0 0 1 3.398 9Zm0-2.8a.8.8 0 1 0 .8.8.8.8 0 0 0-.795-.801Zm11.6.8a5.025 5.025 0 0 0-2.768-1.847A2 2 0 0 1 14.998 7ZM7.999 5.993a2 2 0 1 1 1.844-.862 4.953 4.953 0 0 0-1.844.862ZM8.192 3.2a.8.8 0 1 0 .8.8.8.8 0 0 0-.794-.8Z"
              data-name="Subtraction 38"
            />
            <g data-name="351983_error_icon">
              <path fill="none" d="M5.998 5h10v10h-10Z" data-name="Path 4785" />
              <g fill="none" data-name="Subtraction 36">
                <path d="M10.997 5.667A4.333 4.333 0 1 0 15.33 10a4.333 4.333 0 0 0-4.333-4.333Z" />
                <path
                  fill={color}
                  d="M10.997 7.167A2.836 2.836 0 0 0 8.164 10a2.836 2.836 0 0 0 2.833 2.833A2.836 2.836 0 0 0 13.83 10a2.836 2.836 0 0 0-2.833-2.833m0-1.5a4.333 4.333 0 1 1 0 8.666 4.333 4.333 0 0 1 0-8.666Z"
                />
              </g>
              <path
                fill={color}
                d="m13.096 7.634 1 1.732-5.196 3-1-1.732z"
                data-name="Rectangle 3691"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgManagerNoHierarchy.defaultProps = {
  backgroundColor: COLORS.GREY_1,
  color: COLORS.RED,
  height: 16,
  width: 16,
};
export default SvgManagerNoHierarchy;
