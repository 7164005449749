import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgAfternoon = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      role="img"
      {...rest}
    >
      <path
        id="Color_Off"
        data-name="Color=Off"
        d="M7.571,3.286V2h.857V3.286Zm3.369,7.21A2.143,2.143,0,0,1,9.286,14H6.071A3.214,3.214,0,0,1,4.148,8.21q-.006-.1-.006-.21a3.857,3.857,0,1,1,6.8,2.5Zm-.666-.54a2.147,2.147,0,0,0-1.992.007,2.352,2.352,0,0,1,.148.823q0,.057,0,.114a1.286,1.286,0,1,1,.858,2.243H6.071a2.357,2.357,0,1,1,2.21-3.179,2.13,2.13,0,0,1,.824-.242,3.218,3.218,0,0,0-4.1-1.971,3,3,0,1,1,5.265,2.205ZM14,7.571H12.714v.857H14Zm-2.132-2.3,1.113-.643.429.742L12.3,6.014ZM10.629,2.59,9.986,3.7l.742.429.643-1.113ZM5.272,4.132,4.629,3.018l.742-.429L6.014,3.7ZM2.59,5.371,3.7,6.014l.429-.742L3.018,4.629ZM2,7.571H3.286v.857H2Zm9.868,3.157,1.113.643.429-.742L12.3,9.986Z"
        transform="translate(-2 -2)"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgAfternoon.defaultProps = {
  width: 12,
  height: 12,
  color: COLORS.GREY_2,
};

export default SvgAfternoon;
