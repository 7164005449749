import { gql, useQuery } from "src/api/client";

export const GET_DELETE_BLOCKING_ITEMS = gql`
  query getBlockingItems($TemplateId: ID!, $Status: String!) {
    getBlockingItems(TemplateId: $TemplateId, Status: $Status) {
      ... on DeleteBlockingResponse {
        success
        message
        response {
          Goals
          Incentives
          Leaderboard
          TotalCount
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetDeleteBlockingItems = (queryOptions) => {
  return useQuery(GET_DELETE_BLOCKING_ITEMS, queryOptions);
};
