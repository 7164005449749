import { t } from "i18next";
import React from "react";
import { RecurrenceList } from "src/components/common/atoms";
import { FormBadge } from "src/components/common/formElements";
import { CUSTOM_CYCLE_TYPES } from "src/constants";
import {
  CYCLES_TYPES,
  capitalize,
  format,
  getCustomRecurrences,
  getPluralText,
  getRecurrences,
  getSystemOffset,
} from "src/helpers";
import "./recurrenceListContainer.scss";

const RecurrenceListContainer = (props) => {
  const {
    startDate,
    endDate,
    recurrencePeriod,
    nonUniformrecurrences,
    selectedCustomCycleType,
    recurrenceSummaryLabel,
    cycleDuration,
    gapDuration,
  } = props;

  let recurrences;

  if (recurrencePeriod === "Custom") {
    if (selectedCustomCycleType === "UNIFORM") {
      if (startDate && !endDate)
        recurrences = getCustomRecurrences(
          startDate,
          CYCLES_TYPES[recurrencePeriod]?.setEndDate({
            startDate,
            date: startDate,
            cycleDuration,
          }),
          cycleDuration,
          gapDuration
        );
      else
        recurrences = getCustomRecurrences(
          startDate,
          endDate,
          cycleDuration,
          gapDuration
        );
    } else {
      recurrences = nonUniformrecurrences.map(
        ({ recurrenceStartDate, recurrenceEndDate }, idx) => ({
          id: idx + 1,
          RecurrenceStartDate:
            recurrenceStartDate.getTime() + getSystemOffset(),
          RecurrenceEndDate: recurrenceEndDate.getTime() + getSystemOffset(),
          RecurrenceType: "CUSTOM",
        })
      );
    }
  } else if (startDate && !endDate)
    recurrences = getRecurrences(
      startDate,
      CYCLES_TYPES[recurrencePeriod]?.setEndDate({
        startDate,
        date: startDate,
      }),
      recurrencePeriod
    );
  else recurrences = getRecurrences(startDate, endDate, recurrencePeriod);

  const Title = () => (
    <div className="d-block p-3">
      <div className="fs-14 fc-grey semi-bold">{recurrenceSummaryLabel}</div>
      {recurrencePeriod === "Infinite" ? (
        <div className="center semi-bold fs-12"> Infinite cycle</div>
      ) : (
        <div className="center">
          <div className="fs-12 pt-1 semi-bold">
            {recurrences.length}{" "}
            {recurrencePeriod === "Custom"
              ? CUSTOM_CYCLE_TYPES[selectedCustomCycleType]
              : capitalize(recurrencePeriod)}{" "}
            {recurrences.length > 1
              ? t("MANAGED_GOALS.MESSAGES.CYCLES")
              : t("MANAGED_GOALS.LABELS.CYCLE")}
          </div>{" "}
          {recurrencePeriod === "Custom" &&
            selectedCustomCycleType === "UNIFORM" && (
              <div className="ml-2">
                <FormBadge className="bg-secondary-blue fc-grey1">
                  <span className="semi-bold fs-12">
                    {getPluralText(
                      cycleDuration,
                      t("INCENTIVES.COMMON.MESSAGES.DAY"),
                      t("INCENTIVES.COMMON.MESSAGES.DAYS")
                    )}
                  </span>
                </FormBadge>
              </div>
            )}
        </div>
      )}
    </div>
  );

  const getRecurrenceColor = (recurrence) => {
    const { recurrenceStatus } = recurrence;
    switch (recurrenceStatus) {
      case "INPROGRESS":
        return "fc-black semi-bold";
      case "COMPLETED":
        return "fc-grey-primary";
      default:
        return "";
    }
  };

  const getBadgeColor = (recurrence) => {
    const { recurrenceStatus } = recurrence;
    switch (recurrenceStatus) {
      case "INPROGRESS":
        return "bg-blue2 semi-bold fc-black";
      case "COMPLETED":
        return "bg-grey6 fc-grey1";
      default:
        return "bg-blue2 fc-black";
    }
  };
  if (recurrencePeriod === "Infinite") {
    return (
      <div className="br-4 cycle-details-container-content ">
        {recurrences && recurrencePeriod && <Title />}
        <hr />
        <div className="recurrenceListContainer p-3">
          <div className="fc-grey semi-bold fs-12">
            {startDate.getFullYear()}
          </div>
          <div className="fc-black fs-12">
            {format(new Date(startDate), "do MMM yyyy")} - Forever
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="br-4 cycle-details-container-content ">
      {recurrences && recurrencePeriod && <Title />}
      <hr />
      <div className="recurrenceListContainer p-3">
        <RecurrenceList
          as="li"
          recurrences={recurrences}
          optionBadgeColor={getBadgeColor}
          customType={selectedCustomCycleType}
          recurrenceType={recurrencePeriod}
          optionLabelColor={getRecurrenceColor}
        />
      </div>
    </div>
  );
};

export default RecurrenceListContainer;
