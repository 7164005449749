import React, { lazy } from "react";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'leaderboards.web.payoutPage' */ "./web")
);

export const PayoutPage = {
  web: WebPage,
  mobile: () => <Redirect to={APPS.LEADERBOARDS.ROUTES.LANDING.path} />,
  meta: {
    title: "Payout",
  },
};
