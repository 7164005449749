import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import { LANGUAGES } from "src/constants";
import { GlobalProvider } from "src/context";
import { BrowserRouter } from "src/router";
import AceApps from "./AceApps";
import "./styles/index.scss";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    supportedLngs: LANGUAGES,
    fallbackLng: "en-US",
    lng: "en-US",
    backend: {
      loadPath: "/assets/locals/{{lng}}/translation.json",
      requestOptions: {
        cache: "no-store",
      },
    },
    react: { useSuspend: false, useSuspense: false },
  });

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <BrowserRouter>
        <AceApps />
      </BrowserRouter>
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById("root") || document.createElement("div")
);
