import React from "react";

const SvgAvatar29 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="ukv0__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .ukv0__cls-1{fill:var(--avatar-dark)}.ukv0__cls-3{fill:#fff;fill-rule:evenodd}\n        "
          }
        </style>
      </defs>
      <g id="ukv0__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.71-.68-1.41-1.38-2.1-2.08l-.05-.05C8.78 123.47 0 103.48 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="ukv0__cls-1"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="ukv0__cls-1"
        />
        <path
          d="M68.81 78.03c-1.67 0-3.09-.29-4.47-.92-4.74-1.97-7.53-6.58-7.14-11.75 0-.38.29-.79.76-1.15.16-.07.41-.13.7-.13.18 0 .4.02.65.07l17.84 7.55c.45.23.71.55.81 1.01.11.44 0 .93-.28 1.2-2.27 2.67-5.41 4.12-8.86 4.12Zm-8.46-9.26c.75 2.55 2.66 4.68 5.1 5.69.92.39 2.02.59 3.35.59 1.58 0 3.01-.34 4.23-1.03l.91-.5-13.89-5.75.29 1ZM93.99 78.03c-3.46 0-6.6-1.45-8.84-4.09-.29-.3-.41-.79-.31-1.21.1-.49.36-.81.84-1.05l17.86-7.55c.12-.03.34-.05.54-.05.31 0 .6.06.83.18.31.25.57.65.7 1.19.38 5.03-2.42 9.64-7.14 11.66-1.33.61-2.88.92-4.48.92Zm-5.07-4.54.82.51c1.1.69 2.53 1.04 4.25 1.04 1.26 0 2.25-.17 3.34-.58 2.49-1.03 4.4-3.21 4.99-5.72l.22-.95-13.63 5.7Z"
          className="ukv0__cls-3"
        />
        <path
          d="M81.54 96.9c-.28 0-.58-.06-.86-.13L53.63 83.31l-3.72-1.79-1.09-.55c-.53-.21-.82-.71-.82-1.34V9.33c0-.96.75-1.48 1.46-1.48.6 0 1.08.35 1.28.95l11.22 33.24.55-.38c5.52-3.86 12.04-5.89 18.85-5.89s13.37 2.04 18.96 5.9l.55.38 11.08-33.24c.2-.59.7-.95 1.33-.95.75 0 1.55.52 1.55 1.48v70.3c0 .63-.29 1.13-.79 1.32l-1.26.56-3.6 1.8-27.1 13.48c-.14.07-.32.11-.53.11Zm-30.7-75.6v57.4l30.49 15.1 30.49-15.1V21.3l-.97-.16-7.84 23.66c-.25.63-.81 1.04-1.45 1.04-.33 0-.65-.11-.94-.32-5.4-4.39-12.24-6.81-19.27-6.81s-13.84 2.42-19.18 6.82c-.26.2-.6.31-.95.31-.67 0-1.23-.4-1.42-1.03l-7.98-23.68-.97.16Z"
          className="ukv0__cls-3"
        />
      </g>
    </svg>
  );
};
SvgAvatar29.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar29;
