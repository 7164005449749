import React from "react";
import { Truncate } from "src/components";
import { getRewardOrCondtionName } from "src/helpers";
import { RewardFormula } from "src/images";
import { rewardValueFormatter } from "../../utils";
import { ViewSlabRewardSummary } from "../programDetails";
import DisplayFormula from "./DisplayFormula";
import { payloadToFormula } from "./utils";

const StandardReward = ({
  reward,
  isSlabTypeReward,
  selectedGoals,
  selectedTemplates,
  type,
  variant,
  isPublishedView,
  achievedValue,
  isCrudView,
}) => {
  const templateName = getRewardOrCondtionName({
    selectedGoals,
    selectedTemplates,
    selectedConditionorReward: reward,
    type,
  });

  const value = isSlabTypeReward ? reward.Slabs.length : reward.Value;

  const newValue = rewardValueFormatter[reward?.RewardType]({
    value,
    templateName,
    customReward: reward?.CustomReward,
    subGoal: reward?.SubGoal,
  });

  if (variant === "secondary") {
    return (
      <>
        <div className="fs-12 fc-black d-flex flex-wrap">
          <div className="mx-1">{newValue.value}</div>
          <Truncate maxWidth="150px" text={newValue.text} />
          {newValue?.groupByLabel ? <>{newValue?.groupByLabel}</> : null}
          {newValue?.subText ? (
            <div className="fs-12 fc-grey ml-1">({newValue.subText})</div>
          ) : null}
        </div>
      </>
    );
  }

  return (
    <>
      {newValue.icon}
      {isSlabTypeReward && !isCrudView ? (
        <ViewSlabRewardSummary
          totalSlabs={reward.Slabs.length}
          slabType={reward.RewardType}
          templateName={templateName}
          reward={reward}
          achievement={!isPublishedView}
          achievedValue={achievedValue}
        />
      ) : (
        <div className="center br-4 h-26 bg-grey4 mr-1 space-x-3 px-1">
          <span className="fs-12 fc-black semi-bold center">
            <Truncate maxWidth="250px" text={newValue.value} />
          </span>
          {newValue.text ? (
            <div className="fs-12 fc-black">
              <Truncate maxWidth="250px" text={newValue.text} />
            </div>
          ) : null}
          {newValue?.groupByLabel ? (
            <span className="fs-12 center">&gt;{newValue?.groupByLabel}</span>
          ) : null}
        </div>
      )}
      {newValue?.subText ? (
        <span className="font-italic fs-12 fc-grey">({newValue.subText})</span>
      ) : null}
    </>
  );
};

const DisplayReward = (props) => {
  const {
    reward,
    isSlabTypeReward,
    selectedGoals,
    selectedTemplates,
    type,
    variant = "default",
    isPublishedView,
    achievedValue,
    isCrudView,
  } = props;

  if (reward?.IsFormula) {
    return (
      <>
        {variant !== "secondary" && <RewardFormula width={24} height={24} />}
        <DisplayFormula
          condition={{
            IsFormula: reward?.IsFormula,
            Formula: reward?.Formula?.map(payloadToFormula),
          }}
          variant={variant}
          subText
        />
      </>
    );
  }

  return (
    <StandardReward
      {...{
        reward,
        isSlabTypeReward,
        selectedGoals,
        selectedTemplates,
        type,
        variant,
        isPublishedView,
        achievedValue,
        isCrudView,
      }}
    />
  );
};

export default DisplayReward;
