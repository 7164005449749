import { t } from "i18next";
import { Modal } from "react-bootstrap";
import { FormButton } from "src/components";
import { CompleteProgramModal } from "../modals";

const CloseProgramButton = ({
  show,
  onHide,
  onClick,
  formId,
  handleDiscard,
  disabled,
  viewSubmittable,
  viewSubmitting,
  activeStepName,
}) => {
  return (
    <>
      <FormButton
        variant="outline-primary"
        label={t("COMMON.BUTTONS.CLOSE")}
        className="ml-12"
        onClick={onClick}
        disabled={disabled}
      />
      {show && viewSubmittable && !viewSubmitting ? (
        <Modal show={show} onHide={onHide}>
          <Modal.Header className="semi-bold" closeButton>
            {t("INCENTIVES.PROGRAM.LABELS.CLOSE_PROGRAM")}
          </Modal.Header>
          <Modal.Body className="mx-4 my-3">
            <span className="fs-13">
              {t("INCENTIVES.PROGRAM.LABELS.CLOSE_DESCRIPTION")}
            </span>
          </Modal.Body>
          <Modal.Footer>
            <FormButton
              label={t("MANAGED_GOALS.BUTTONS.DISCARD_AND_PROCEED")}
              variant="outline-primary"
              id="incentive-click-away-discard"
              onClick={handleDiscard}
            />
            {formId ? (
              <FormButton
                type="submit"
                name="close"
                form={formId}
                label={t("MANAGED_GOALS.BUTTONS.SAVE_CHANGES")}
                variant="outline-primary"
                id="incentive-click-away-save"
              />
            ) : null}
          </Modal.Footer>
        </Modal>
      ) : null}
      {show && !viewSubmittable && !viewSubmitting ? (
        <CompleteProgramModal
          show={show}
          handleClose={onHide}
          handleDiscard={handleDiscard}
          heading={t("INCENTIVES.PROGRAM.LABELS.CLOSE_PROGRAM")}
        >
          {t("INCENTIVES.PROGRAM.LABELS.DETAILS_INCOMPLETE", {
            activeStepName: activeStepName?.replace("&", "and"),
          })}
        </CompleteProgramModal>
      ) : null}
    </>
  );
};

export default CloseProgramButton;
