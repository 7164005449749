import PropTypes from "prop-types";
import React from "react";
import { COLORS } from "src/constants";
import { SvgDelete, SvgEdit } from "src/icons";
import { FormButton } from "../../formElements";

const ConfigDetailsCard = ({
  icon,
  description,
  isEditable,
  isDeletionAllowed,
  onEditBtnClick,
  onDeleteBtnClick,
  isViewMode,
}) => {
  return (
    <div className="center justify-content-between p-2 pl-3 bg-grey5 br-4">
      <div className="center">
        {icon && <div className="mr-2">{icon}</div>}
        <div>{description}</div>
      </div>
      <div className="center">
        {isViewMode && (
          <FormButton
            className="fs-12 fc-blue semi-bold pointer"
            onClick={onEditBtnClick}
            variant="link"
          >
            View
          </FormButton>
        )}
        {isEditable && (
          <SvgEdit className="mr-12 cursor-pointer" onClick={onEditBtnClick} />
        )}
        {isDeletionAllowed && (
          <SvgDelete
            color={COLORS.GREY_2}
            width={20}
            height={20}
            className="cursor-pointer"
            onClick={onDeleteBtnClick}
          />
        )}
      </div>
    </div>
  );
};

ConfigDetailsCard.propTypes = {
  icon: PropTypes.element,
  description: PropTypes.element,
  isEditable: PropTypes.bool,
  isDeletionAllowed: PropTypes.bool,
  onEditBtnClick: PropTypes.func,
  onDeleteBtnClick: PropTypes.func,
};

ConfigDetailsCard.defaultProps = {
  isEditable: true,
  isDeletionAllowed: true,
  onEditBtnClick: () => {},
  onDeleteBtnClick: () => {},
};

export default ConfigDetailsCard;
