import React from "react";
import OnDesktop from "./OnDesktop";
import OnMobile from "./OnMobile";

export default Object.assign(Responsive, {
  OnMobile,
  OnDesktop,
});

function Responsive({ children }) {
  return <>{children}</>;
}
