import React from "react";
import { Image } from "src/components";
import ProgramViewEmptyStateImg from "./ProgramViewEmptyState.png";

const ProgramViewEmptyState = (props) => (
  <Image
    src={ProgramViewEmptyStateImg}
    alt="ProgramViewEmptyStateImg"
    {...props}
  />
);

export default ProgramViewEmptyState;
