import React from "react";
const SvgLeaderboard = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#p0lq__a)">
        <mask
          id="p0lq__b"
          width={20}
          height={20}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "alpha",
          }}
        >
          <path fill="#D9D9D9" d="M0 0h20v20H0z" />
        </mask>
        <g mask="url(#p0lq__b)">
          <path
            stroke="#309AFC"
            strokeWidth={1.5}
            d="M4 10.25h3.75v6.5h-4V10.5a.25.25 0 0 1 .25-.25ZM8.5 7.25H12a.25.25 0 0 1 .25.25v9.25h-4V7.5a.25.25 0 0 1 .25-.25ZM12.25 12.25H16a.25.25 0 0 1 .25.25v4.25h-4z"
          />
          <rect width={9} height={2} x={6} y={2.5} fill="#309AFC" rx={1} />
        </g>
      </g>
      <defs>
        <clipPath id="p0lq__a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
SvgLeaderboard.defaultProps = {
  color: "",
  height: 24,
  width: 24,
};
export default SvgLeaderboard;
