import { lazy, Suspense } from "react";
import { FormButton } from "src/components";
import { COLORS } from "src/constants";
import { useToggle } from "src/hooks";
import { SvgBack } from "src/icons";
import "./styles.scss";

const SendBackToApprovalModal = lazy(() =>
  import(
    /*
    webpackChunkName: "program.manage",
    webpackPrefetch: true
    */
    "./SendBackToApprovalModal"
  )
);

function SendBackToApproval({ disabled, programId, handleSubmit, loading }) {
  const [modal, showAndHideModal] = useToggle();

  return (
    <>
      <FormButton
        variant="outline-primary"
        className="mr-3 send-back-approval-button"
        onClick={showAndHideModal}
        disabled={disabled}
      >
        <SvgBack
          className="mr-3"
          color={disabled ? COLORS.GREY_3 : COLORS.PRIMARY_BLUE}
        />{" "}
        Send Back to Approval
      </FormButton>
      <Suspense>
        <SendBackToApprovalModal
          show={Boolean(modal)}
          handleClose={showAndHideModal}
          onSubmit={handleSubmit}
          programId={programId}
          loading={loading}
        />
      </Suspense>
    </>
  );
}

export default SendBackToApproval;
