import { t } from "i18next";
import React, { useState } from "react";
import { PAYOUT_HISTORY_LOOKUP } from "src/apps/incentives/data/MyIncentivesData";
import {
  AmountLabel,
  formatTimelineDate,
  FormButton,
  MultilineTruncate,
  NonMonetaryLabel,
  NonMonetaryRewardDetails,
  StatsItem,
  Timeline,
  Truncate,
} from "src/components";
import { REWARD_SUBCATEGORIES } from "src/constants";

const lineHeight = 21;
const numberOfItemsToShow = 10;
const heightToShowTenItems =
  lineHeight + // space for heading
  lineHeight * numberOfItemsToShow; // space for ten items

const renderReferenceIdDetail = ({
  referenceId,
  payoutDone,
  RewardId,
  RewardName,
  isMonetary = false,
  isNonMonetaryExists = false,
}) => {
  return (
    <div className="flex-between d-flex">
      <div className="fs-13 semi-bold">{referenceId}</div>
      <div
        className="fs-13 semi-bold"
        style={{
          color: isMonetary
            ? PAYOUT_HISTORY_LOOKUP.PAIDOUT.textColor
            : PAYOUT_HISTORY_LOOKUP.PAIDOUT.rewardColor,
        }}
      >
        {isMonetary && payoutDone ? (
          <div className="center">
            <AmountLabel amount={payoutDone} />
            {isNonMonetaryExists && <span className="mx-1">&</span>}
          </div>
        ) : (
          <NonMonetaryLabel
            nonMonetaryRewards={[
              {
                RewardId,
                RewardName,
              },
            ]}
            rewardCount={1}
            payload={{
              rewardIds: [RewardId],
            }}
          />
        )}
      </div>
    </div>
  );
};

const renderMonetaryDetails = (item) => {
  const referenceIdDetail =
    item?.referenceId?.find(
      (referenceId) => referenceId.Type === REWARD_SUBCATEGORIES.MONETARY.id
    ) || {};

  return renderReferenceIdDetail({
    referenceId: referenceIdDetail?.ReferenceId,
    payoutDone: item.payoutDone,
    isMonetary: true,
    isNonMonetaryExists: referenceIdDetail
      ? item.referenceId?.length > 1
      : item.referenceId?.length > 0,
  });
};

const renderNonMonetaryDetails = (item) => {
  const referenceIds = item.referenceId?.filter(
    (referenceId) => referenceId.Type !== REWARD_SUBCATEGORIES.MONETARY.id
  );

  return referenceIds?.map(({ ReferenceId, RewardId, RewardName }) =>
    renderReferenceIdDetail({
      referenceId: ReferenceId,
      RewardId,
      RewardName,
    })
  );
};

const formatNonMonetary = (rewards, setRewardDetailsModal) => {
  return (
    <div>
      {rewards.map((reward) => (
        <FormButton
          className="pl-1 fc-blue pointer center text-nowrap text-decorate"
          onClick={(e) => {
            e.stopPropagation();
            setRewardDetailsModal({
              show: true,
              details: {
                rewardId: reward.RewardId,
              },
            });
          }}
          variant="link"
          label={<Truncate text={reward.RewardName} maxWidth={"130px"} />}
        />
      ))}
    </div>
  );
};

export const getPayoutHistoryContent = (
  item,
  rewardDetailsModal,
  setRewardDetailsModal
) => {
  return (
    <div className="pb-3">
      <div className="flex-between mb-12">
        <div
          className="fs-14 timeline-item-title"
          style={{ color: PAYOUT_HISTORY_LOOKUP[item.status].textColor }}
        >
          {item.status === "PAIDOUT"
            ? t("LEADERBOARD.PAYOUT.LABELS.PAYOUT_COMPLETE")
            : t("LEADERBOARD.PAYOUT.LABELS.PAID_PARTIALLY")}
        </div>
        <div className="fs-12 fc-grey-primary">
          {formatTimelineDate(item.timeStamp)}
        </div>
      </div>
      <div className="bg-grey p-3">
        <MultilineTruncate height={heightToShowTenItems}>
          <p className="long-text-break">
            <div className="d-flex w-100">
              <div className="w-60">
                <div className="flex-between">
                  <div className="fs-13 semi-bold fc-grey">
                    {t("LEADERBOARD.PAYOUT.LABELS.REFERENCE_ID")}
                  </div>
                  <div className="fs-13 semi-bold fc-grey">
                    {t("LEADERBOARD.COMMON.LABELS.PAYOUT_DONE")}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  {renderMonetaryDetails(item)}
                  {renderNonMonetaryDetails(item)}
                </div>
              </div>
              <div className="border-left-1 bc-grey3 pl-3 ml-3 w-40">
                <StatsItem
                  title={t("INCENTIVES.PAYOUT.LABELS.PENDING_REWARDS")}
                  className="fs-13 semi-bold"
                >
                  <NonMonetaryLabel
                    amount={item.pendingPayout}
                    nonMonetaryRewards={item.PendingRewards}
                    rewardCount={item.PendingRewardsCount}
                    formatNonMonetary={() =>
                      formatNonMonetary(
                        item.PendingRewards,
                        setRewardDetailsModal
                      )
                    }
                  />
                </StatsItem>
              </div>
            </div>
          </p>
        </MultilineTruncate>
      </div>
      {rewardDetailsModal.show && (
        <NonMonetaryRewardDetails
          show={rewardDetailsModal.show}
          handleClose={() =>
            setRewardDetailsModal({
              show: false,
            })
          }
          payload={{
            rewardIds: [rewardDetailsModal.details?.rewardId],
          }}
          title={t("INCENTIVES.DASHBOARD.LABELS.PAYOUT_PENDING")}
        />
      )}
    </div>
  );
};

function PayoutTimeline({ loading, history }) {
  const [rewardDetailsModal, setRewardDetailsModal] = useState({
    show: false,
    details: {
      rewardId: "",
    },
  });

  return (
    <Timeline
      loading={loading}
      data={history}
      lookup={PAYOUT_HISTORY_LOOKUP}
      getContent={(item) =>
        getPayoutHistoryContent(item, rewardDetailsModal, setRewardDetailsModal)
      }
    />
  );
}

export default PayoutTimeline;
