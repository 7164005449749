import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgLive = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l63s3__a">
          <path d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
      <g fill={color} clipPath="url(#l63s3__a)">
        <circle cx={6} cy={6} r={6} data-name="Ellipse 50" opacity={0.3} />
        <circle
          cx={3}
          cy={3}
          r={3}
          data-name="Ellipse 51"
          transform="translate(3 3)"
        />
      </g>
    </svg>
  );
};

SvgLive.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 12,
  width: 12,
};
export default SvgLive;
