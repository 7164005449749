import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const CREATE_GOAL = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation CreateGoal($Name: NotEmpty!, $Description: String) {
    createGoal(Name: $Name, Description: $Description) {
      __typename
      ... on SingleGoalResponse {
        response {
          Goal {
            Name
            Description
            GoalId
          }
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useCreateGoal = (queryOptions) => {
  return useMutation(CREATE_GOAL, queryOptions);
};
