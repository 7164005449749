import React from "react";

const SvgAvatar25 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="orcx__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .orcx__cls-1{fill:#fff}.orcx__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="orcx__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8a81.318 81.318 0 0 1-26.98 18.35c-.18.08-.37.15-.55.22-8.92 3.66-18.64 5.76-28.82 6-.65.02-1.29.03-1.94.03-.8 0-1.59-.01-2.39-.04a80.833 80.833 0 0 1-29.31-6.37c-.17-.06-.34-.14-.51-.21A81.59 81.59 0 0 1 23.1 138.2C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.75}
          className="orcx__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.07)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="orcx__cls-2"
        />
        <path
          d="m98.55 74.82-7.87 2.24c-.8.23-1.26 1.06-1.04 1.86s1.06 1.26 1.86 1.04l7.87-2.24c.8-.23 1.26-1.06 1.04-1.86s-1.06-1.26-1.86-1.04ZM100.79 82.68l-7.87 2.24c-.8.23-1.26 1.06-1.04 1.86s1.06 1.26 1.86 1.04l7.87-2.24c.8-.23 1.26-1.06 1.04-1.86s-1.06-1.26-1.86-1.04Z"
          className="orcx__cls-1"
        />
        <path
          d="M148.74 66.35c-.76-2.7-2.46-5.03-4.8-6.58a11.699 11.699 0 0 0-7.93-1.83l-2.05-7.19a3.899 3.899 0 0 0-2.5-2.59 3.91 3.91 0 0 0-3.56.55l-21.04 15.22-24.34 6.93a5.57 5.57 0 0 0-3.35 2.66c-.72 1.3-.9 2.82-.49 4.25l4.48 15.74a5.57 5.57 0 0 0 2.66 3.35c1.3.72 2.83.9 4.25.49l2.9-.82 12.31 18.03a5.596 5.596 0 0 0 6.14 2.24l.44-.13c1.43-.41 2.64-1.37 3.36-2.67s.9-2.83.5-4.26l-5.19-18.22 3.86-1.1 25.9 1.93c.46.04.92 0 1.36-.14 1.01-.27 1.87-.94 2.39-1.85.52-.91.64-1.99.35-3l-2.05-7.19a11.64 11.64 0 0 0 5.78-5.73c1.17-2.54 1.39-5.43.61-8.12ZM86.07 92.7l-4.48-15.74c-.19-.66-.11-1.36.23-1.96.33-.6.89-1.04 1.55-1.23l23.18-6.6 5.9 20.71-23.18 6.6a2.575 2.575 0 0 1-3.19-1.79Zm26.77 17.89c.19.66.11 1.37-.23 1.97-.33.6-.89 1.04-1.55 1.23l-.46.13c-.52.14-1.07.11-1.57-.07-.5-.19-.93-.53-1.23-.97l-11.75-17.2 11.59-3.3 5.21 18.21Zm28.63-22.37c.08.29.02.59-.17.82a.92.92 0 0 1-.77.33l-25.11-1.88-6.14-21.58 20.35-14.82a.894.894 0 0 1 1.39.49l10.44 36.62Zm.04-10.96-4.66-16.37c2.69-.21 5.32.83 7.13 2.83a8.681 8.681 0 0 1-2.47 13.54Z"
          className="orcx__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar25.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar25;
