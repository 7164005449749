import { CLIENT_ID, LOG_OUT_URL, REDIRECT_URI } from "src/constants";
import { generateRandomState } from "src/helpers";

export const useLogout = () => {
  const logout = async () => {
    try {
      const state = generateRandomState();
      localStorage.setItem("logout_state", state);
      const logoutUrl = new URL(LOG_OUT_URL);
      const queryParams = new URLSearchParams({
        client_id: CLIENT_ID,
        state,
        redirect_uri: encodeURI(REDIRECT_URI),
      });
      logoutUrl.search = queryParams.toString();
      window.location.href = logoutUrl.toString();
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("login_state");
      localStorage.removeItem("code_verifier");
    } catch (err) {
      console.error("Error during logout:", err);
    }
  };

  return logout;
};

export default useLogout;
