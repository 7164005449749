import { t } from "i18next";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { FormButton } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { CYCLES_TYPES, format, isSameDay } from "src/helpers";
import { SvgCalendar } from "src/icons";

const CustomInput = forwardRef((customprops, ref) => {
  const {
    dateStart,
    dateEnd,
    className,
    recurrencePeriod,
    isDisabled,
    inputFormat,
    inputId,
    onClick: onCalClick,
    placeholderText,
    dateValue,
  } = customprops;

  const formatValue = (dateStart, dateEnd) => {
    if (dateStart) {
      let date = format(dateStart, inputFormat);
      if (dateEnd && !isSameDay(dateStart, dateEnd))
        date = `${date} - ${format(dateEnd, inputFormat)}`;
      return date;
    }
    return "";
  };

  const getColorClass = ({ isDisabled, date }) =>
    isDisabled ? "fc-grey1" : date ? "fc-black" : "fc-grey2";

  return (
    <div data-testid="calendar-custom-input" className="center">
      <input
        id={inputId}
        type="text"
        readOnly
        placeholder={
          placeholderText || CYCLES_TYPES[recurrencePeriod]?.label({ t })
        }
        className={`cal-input p-12 ${getColorClass({
          isDisabled,
          date: dateStart || dateValue,
        })} ${className}`}
        value={
          dateStart || dateEnd
            ? formatValue(dateStart, dateEnd)
            : dateValue
            ? format(dateValue, inputFormat)
            : ""
        }
        disabled={isDisabled}
        onClick={onCalClick}
        ref={ref}
      />
      <FormButton
        variant="link"
        id={`${inputId}-icon`}
        disabled={isDisabled}
        className={`cal-icon ${
          isDisabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={() => !isDisabled && onCalClick()}
        icon={
          <>
            <SvgCalendar className="normal" />
            <SvgCalendar color={COLORS.PRIMARY_BLUE} className="active" />
          </>
        }
      />
    </div>
  );
});

CustomInput.displayName = "CustomInput";
CustomInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  recurrencePeriod: PropTypes.string,
  dateValue: PropTypes.instanceOf(Date),
  dateStart: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  inputFormat: PropTypes.string,
  onClick: PropTypes.func,
  placeholderText: PropTypes.string,
};

CustomInput.defaultProps = {
  className: "",
  inputId: "cal-input",
  dateStart: null,
  dateEnd: null,
};

export default CustomInput;
