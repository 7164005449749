import { gql, useQuery } from "src/api/client";

const GET_PAYOUT_USERS_FOR_PROGRAM_QUERY = gql`
  query payoutUsersForProgram(
    $recurrenceId: ID
    $programId: ID!
    $limit: Float
    $after: String
    $query: JSON
    $GroupId: ID
  ) {
    payoutUsersForProgram(
      limit: $limit
      recurrenceId: $recurrenceId
      programId: $programId
      after: $after
      query: $query
      GroupId: $GroupId
    ) {
      ... on PayoutUsersForProgramResponse {
        success
        message
        response {
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
          totalUsersCount
          payoutUsers {
            userId
            fullName
            emailAddress
            pendingPayout
            payoutStatus
            payoutDone
            recurrenceId
            PendingRewards {
              RewardName
            }
            PaidOutRewards {
              RewardName
            }
            PendingRewardsCount
            PaidOutRewardsCount
          }
        }
      }
    }
  }
`;

export const useGetPayoutUsersForProgram = (queryOptions) => {
  return useQuery(GET_PAYOUT_USERS_FOR_PROGRAM_QUERY, queryOptions);
};
