import React from "react";
import { AuthProvider } from "src/context";
import { useIsOpenAceUser } from "src/hooks";
import AceApp from "./AceApp";
import OpenAceAppWrapper from "./OpenAceAppWrapper";

const App = ({ children }) => {
  const { isOpenAceUser } = useIsOpenAceUser();

  if (isOpenAceUser) {
    return (
      <OpenAceAppWrapper isOpenAceUser={isOpenAceUser}>
        {children}
      </OpenAceAppWrapper>
    );
  }

  return (
    <AuthProvider>
      <AceApp isOpenAceUser={isOpenAceUser}>{children}</AceApp>
    </AuthProvider>
  );
};

export default App;
