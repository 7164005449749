import { useEffect, useState } from "react";
import { ENVIRONMENT } from "src/constants";
import { checkValue } from "src/helpers";

const useClarityScript = (userData) => {
  const [scriptAppended, setScriptAppended] = useState(false);

  useEffect(() => {
    let script;

    const env = process.env.REACT_APP_ENV;
    const clarityId = process.env.REACT_APP_CLARITY_ID;

    try {
      if (!scriptAppended && ![ENVIRONMENT.LOCAL].includes(env)) {
        script = document.createElement("script");
        script.async = true;
        script.id = `clarity-${env}`;

        script.append(`(function (c, l, a, r, i, t, y) {
          c[a] =
            c[a] ||
            function () {
              (c[a].q = c[a].q || []).push(arguments);
            };
          t = l.createElement(r);
          t.async = 1;
          t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "${clarityId}");
        `);

        document.body.appendChild(script);
        setScriptAppended(true);
      }
    } catch (e) {
      console.log("Error While Loading the Clarity Script", e);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
        setScriptAppended(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scriptAppended) {
      Object.entries(userData).forEach(([key, value]) => {
        if (checkValue(value)) {
          window.clarity("set", key, value);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptAppended]);
};

export default useClarityScript;
