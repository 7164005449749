import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_ACHIEVMENT_GOAL_DETAILS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getUserReport(
    $GoalId: ID!
    $UserId: ID
    $RecurrenceId: ID
    $IsRecurrenceBased: Boolean
    $Sort: GoalContributionSort
    $directReportOnly: Boolean
    $Limit: Float
    $After: String
    $Query: userReportQueryInput
    $userAttributes: [String]
  ) {
    getUserReport(
      GoalId: $GoalId
      RecurrenceId: $RecurrenceId
      IsRecurrenceBased: $IsRecurrenceBased
      Sort: $Sort
      UserId: $UserId
      directReportOnly: $directReportOnly
      Limit: $Limit
      After: $After
      Query: $Query
      userAttributes: $userAttributes
    ) {
      ... on UserReportResponse {
        message
        success
        response {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            __typename
          }
          userReport
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetAchievmentGoalDetails = (queryOptions) => {
  return useQuery(GET_ACHIEVMENT_GOAL_DETAILS_QUERY, queryOptions);
};
