import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgStandardFormula = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 14 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M12.407 7.807A2.386 2.386 0 0 1 10.857 12H1.341a.579.579 0 0 1 0-1.157h9.515a1.229 1.229 0 0 0 0-2.457H1.341a.579.579 0 0 1 0-1.157h9.515a1.229 1.229 0 0 0 0-2.457H1.341a.58.58 0 1 1 0-1.157h9.515a1.229 1.229 0 0 0 0-2.457.579.579 0 0 1 0-1.157 2.386 2.386 0 0 1 1.55 4.193 2.384 2.384 0 0 1 0 3.614l.001-.001Z"
      />
    </svg>
  );
};
SvgStandardFormula.defaultProps = {
  color: COLORS.GREY_2,
  height: 12,
  width: 14,
};
export default SvgStandardFormula;
