import PropTypes from "prop-types";
import {
  CONDITION_OPERATOR,
  FEATURE_TYPES,
  INCENTIVE_PROGRAM_TYPES,
  RECURRENCE_PERIOD,
  REWARD_TYPE,
  ROLLUP_TYPES,
} from "src/constants";
import {
  capitalizeFirstChar,
  checkValue,
  getNonUniformDefaultRecurrences,
  getNonUniformRecurrences,
  isRollUpDisabled,
  startOfDay,
} from "src/helpers";

export const INCENTIVE_ATTRIBUTES = (initialState, today) => ({
  ProgramId: initialState.ProgramId || null,
  RecurrencePeriod: initialState.RecurrenceDefinition?.RecurrencePeriod
    ? capitalizeFirstChar(
        initialState.RecurrenceDefinition.RecurrencePeriod.toLowerCase()
      )
    : null,
  StartDate: initialState.RecurrenceDefinition?.FirstRecurrenceStartDate
    ? startOfDay(
        Date.parse(initialState.RecurrenceDefinition?.FirstRecurrenceStartDate)
      )
    : null,
  EndDate: initialState.RecurrenceDefinition?.LastRecurrenceEndDate
    ? startOfDay(
        Date.parse(initialState.RecurrenceDefinition?.LastRecurrenceEndDate)
      )
    : null,
  BufferDays: initialState.BufferDays || [],
  Type: initialState.Type || INCENTIVE_PROGRAM_TYPES.TEMPLATE_BASED.value,
  IsRollup: isRollUpDisabled(
    initialState?.SelectedTemplates,
    FEATURE_TYPES.INCENTIVE
  )
    ? false
    : initialState.IsRollup || false,
  SelectedTemplates: initialState.SelectedTemplates || [],
  IsGoalsEnabled: initialState.IsGoalsEnabled || false,
  SelectedGoals: initialState.SelectedGoals || [],
  CustomCycleType: initialState.RecurrenceDefinition
    ? initialState.RecurrenceDefinition.CustomType
    : "UNIFORM",
  GapDuration:
    initialState.RecurrenceDefinition?.CustomType === "UNIFORM"
      ? initialState.RecurrenceDefinition.CustomGap === null
        ? null
        : initialState.RecurrenceDefinition.CustomGap
      : null,
  CycleDuration:
    initialState.RecurrenceDefinition?.CustomType === "UNIFORM"
      ? initialState.RecurrenceDefinition.RecurrenceCycleDuration === null
        ? null
        : initialState.RecurrenceDefinition.RecurrenceCycleDuration
      : null,
  Recurrences:
    initialState.RecurrenceDefinition?.CustomType === "NONUNIFORM"
      ? getNonUniformRecurrences({
          recurrences: initialState.RecurrenceDefinition?.Recurrences,
          status: initialState.Status || "DRAFT",
          today,
        }) || getNonUniformDefaultRecurrences(today)
      : getNonUniformDefaultRecurrences(today),
  RollupType: initialState.RollupType || ROLLUP_TYPES[0].value,
  DisableApprovalPayout: initialState.DisableApprovalPayout || false,
  ShowIncentiveHistory: checkValue(
    initialState.VisibilitySettings.ShowIncentiveHistory
  )
    ? initialState.VisibilitySettings.ShowIncentiveHistory
    : true,
  HideCriteriaDetails:
    !initialState.VisibilitySettings.ShowCriteriaDetails || false,
});

export const INCENTIVE_ATTRIBUTES_PROPTYPES = {
  ProgramId: PropTypes.string.isRequired,
  RecurrencePeriod: PropTypes.oneOf(RECURRENCE_PERIOD).isRequired,
  StartDate: PropTypes.instanceOf(Date).isRequired,
  EndDate: PropTypes.instanceOf(Date).isRequired,
  BufferDays: PropTypes.arrayOf(PropTypes.string),
  Type: PropTypes.oneOf(Object.keys(INCENTIVE_PROGRAM_TYPES)).isRequired,
  IsRollup: PropTypes.bool.isRequired,
  SelectedTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      TemplateId: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      GoalAggregateFunction: PropTypes.oneOf([
        "COUNT",
        "SUM",
        "DISTINCT_COUNT",
        "AVERAGE",
        "CUSTOM",
      ]),
    })
  ).isRequired,
  DisableApprovalPayout: PropTypes.bool.isRequired,
};

export const INITIAL_INCENTIVE_SUBGOAL_STATE = {
  SubGoalConditions: [{ Key: "", Value: "", ConditionId: 1 }],
  ConditionPattern: "(1)",
};

export const STANDARD_CRITERIA_CONDITION = {
  TemplateId: null,
  GoalId: null,
  Operator: {
    Key: "",
    Label: "",
    Value: "",
  },
  DataType: "",
  ConditionType: { key: "", label: "", value: "" },
  Value: null,
  Tips: "",
};

export const FORMULA_CRITERIA_CONDITION_OR_REWARD = {
  IsFormula: true,
  Formula: [],
};

export const CRITERIA_CONDITION_PROPTYPES = {
  TemplateId: PropTypes.string.isRequired,
  GoalId: PropTypes.string.isRequired,
  Operator: PropTypes.shape({
    Key: PropTypes.oneOf(
      Object.keys(CONDITION_OPERATOR).map(
        (operator) => CONDITION_OPERATOR[operator].Key
      )
    ),
    Label: PropTypes.oneOf(
      Object.keys(CONDITION_OPERATOR).map(
        (operator) => CONDITION_OPERATOR[operator].Label
      )
    ),
    Value: PropTypes.oneOf(
      Object.keys(CONDITION_OPERATOR).map(
        (operator) => CONDITION_OPERATOR[operator].Value
      )
    ),
  }).isRequired,
  DataType: PropTypes.oneOf(["Number", "Object"]),
  Value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      startsAt: PropTypes.number.isRequired,
      endsAt: PropTypes.number.isRequired,
    }).isRequired,
  ]),
  Tips: PropTypes.string,
};

const REWARD_SLAB_PROPTYPES = {
  SlabId: PropTypes.number.isRequired,
  MinValue: PropTypes.number.isRequired,
  MaxValue: PropTypes.number.isRequired,
  RewardValue: PropTypes.number.isRequired,
};

export const rewardSlab = ({ slabIndex, minValue, maxValue, weightage }) => {
  return {
    SlabId: slabIndex,
    MinValue: minValue,
    MaxValue: maxValue,
    RewardWeightage: weightage,
  };
};

rewardSlab.propTypes = REWARD_SLAB_PROPTYPES;

export const CRITERIA_REWARD = {
  RewardId: null,
  Sequence: null,
  TemplateId: null,
  GoalId: null,
  RewardType: "",
  RewardLabel: "",
  Value: null,
  Slabs: [],
};

export const CRITERIA_REWARD_PROPTYPES = {
  RewardId: PropTypes.string,
  Sequence: PropTypes.number,
  TemplateId: PropTypes.string.isRequired,
  RewardType: PropTypes.oneOf(
    Object.keys(REWARD_TYPE).map((type) => REWARD_TYPE[type].type)
  ),
  RewardLabel: PropTypes.oneOf(
    Object.keys(REWARD_TYPE).map((type) => REWARD_TYPE[type].label)
  ),
  Value: PropTypes.number.isRequired,
  Slabs: PropTypes.arrayOf(PropTypes.shape(REWARD_SLAB_PROPTYPES)),
};

export const CRITERIA = {
  Conditions: [],
  ConditionPattern: "",
  Rewards: [],
};

// ! add a standard & formula propType
export const CRITERIA_PROPTYPES = {
  Conditions: PropTypes.shape([CRITERIA_CONDITION_PROPTYPES]),
  ConditionPattern: PropTypes.string.isRequired,
  Rewards: PropTypes.shape([CRITERIA_REWARD_PROPTYPES]),
};
