import { classNames } from "src/helpers";
import { formatTime, useTimer } from "src/hooks/useTimer";
import "./timer.scss";

const Timer = ({ duration, className }) => {
  const timer = useTimer({ duration });

  const days = formatTime(timer.days);
  const hours = formatTime(timer.hours);
  const minutes = formatTime(timer.minutes);
  const seconds = formatTime(timer.seconds);

  return (
    <div className={classNames(["timer fs-13 semi-bold", className])}>
      {days ? <span className="mr-1">{days}d</span> : null}
      {hours ? <span className="mr-1">{hours}h</span> : null}
      {minutes ? <span className="mr-1">{minutes}m</span> : null}
      {seconds ? <span>{seconds}s</span> : "00s"}
    </div>
  );
};

export default Timer;
