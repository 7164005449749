import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const ADD_APPROVERS_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation addLeaderboardApprovers(
    $LeaderboardId: ID!
    $LeaderboardApprovers: [LeaderboardApproverInput]!
  ) {
    addLeaderboardApprovers(
      LeaderboardApprovers: $LeaderboardApprovers
      LeaderboardId: $LeaderboardId
    ) {
      ... on AddLeaderboardApproverResponse {
        message
        __typename
      }
      ...errorResponseFragment
      __typename
    }
  }
`;

export const useAddApprovers = () => {
  return useMutation(ADD_APPROVERS_MUTATION);
};
