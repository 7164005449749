import {
  ApprovalRequestPage,
  DashboardUserDetailViewPage,
  PayoutRequestPage,
} from "src/components";
import { ApprovalPage } from "./approval";
import { ApprovalDetailViewPage } from "./approvalDetailView";
import { DashboardPage } from "./dashboard";
import { DashboardIncentiveDetailsPage } from "./dashboardIncentiveDetails";
import { DashboardProgramDetailViewPage } from "./dashboardProgramDetailView";
import { EndUserFlowPage } from "./endUserFlow";
import { IncentiveCrudPage } from "./incentiveCrud";
import { LandingPage } from "./landing";
import { ManageProgramsPage } from "./managePrograms";
import { MyIncentivesPage } from "./myIncentives";
import { PayoutPage } from "./payout";
import { PayoutDetailViewPage } from "./payoutDetailView";
import { PublishedIncentiveViewPage } from "./publishedIncentiveView";
import { RequestDemoPage } from "./requestDemo";
import { RulesetsPage } from "./rulesets";

export const Pages = {
  ApprovalDetailViewPage,
  ApprovalPage,
  ApprovalRequestPage,
  DashboardIncentiveDetailsPage,
  DashboardPage,
  DashboardProgramDetailViewPage,
  DashboardUserDetailViewPage,
  EndUserFlowPage,
  IncentiveCrudPage,
  LandingPage,
  ManageProgramsPage,
  MyIncentivesPage,
  PayoutDetailViewPage,
  PayoutPage,
  PayoutRequestPage,
  PublishedIncentiveViewPage,
  RequestDemoPage,
  RulesetsPage,
};
