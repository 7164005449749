import { gql, useQuery } from "src/api/client";

const GET_PROGRAM_RECURRENCE_TOTAL = gql`
  query programRecurrenceTotal(
    $ProgramId: ID
    $RecurrenceId: ID
    $UserId: ID
    $query: PayoutUsersQuery
    $Status: String
  ) {
    programRecurrenceTotal(
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      UserId: $UserId
      query: $query
      Status: $Status
    ) {
      ... on DetailsResponse {
        success
        message
        response {
          TotalEligibleAmount
          TotalApprovedAmount
          TotalPaidOut
          TotalPayoutPending
          TotalPaidOutRewards {
            RewardId
            RewardName
          }
          TotalEligibleRewards {
            RewardId
            RewardName
          }
          TotalApprovedRewards {
            RewardId
            RewardName
          }
          TotalPayoutPendingRewards {
            RewardId
            RewardName
          }
          TotalPaidOutRewardsCount
          TotalEligibleRewardsCount
          TotalApprovedRewardsCount
          TotalPayoutPendingRewardsCount
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetProgramRecurrenceTotal = (queryOptions) => {
  return useQuery(GET_PROGRAM_RECURRENCE_TOTAL, queryOptions);
};
