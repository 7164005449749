import { format, getSystemOffset } from "src/helpers";

const formatDate = (date, formatTo) => {
  return format(new Date(parseInt(date) - getSystemOffset()), formatTo);
};

const DateRangeCell = ({ value, column, row: { original } }) => {
  const newValue = column.formatValue(original);
  if (newValue.startDate && newValue.endDate) {
    const startDate = formatDate(newValue.startDate, column.dateFormat);
    const endDate = formatDate(newValue.endDate, column.dateFormat);
    return `${startDate} - ${endDate}`;
  }
  return "";
};
export default DateRangeCell;
