import { t } from "i18next";
import React from "react";
import { AverageProgressBar, ProgressBar } from "src/components/common/atoms";
import {
  FormButton,
  FormTooltip,
  NumFormatTooltip,
} from "src/components/common/formElements";
import { COLORS, GOAL_AGGREGATION_TYPES } from "src/constants";
import { useRoleBasedContext } from "src/context";
import {
  NumFormatter,
  classNames,
  getColor,
  getGreaterThanMarkerColor,
  getLesserThanMarkerColor,
  getPluralText,
  getUserTimezone,
} from "src/helpers";
import {
  SvgAlert,
  SvgLink,
  SvgSubGoal,
  SvgUser,
  SvgUserUnavailable,
} from "src/icons";

const TooltipUserText = ({ goalUsersCount, usersAchievedCount }) => (
  <div className="fs-12 fc-grey1">
    <span className="fc-black semi-bold mx-1">
      {usersAchievedCount !== null && usersAchievedCount >= 0
        ? usersAchievedCount
        : 0}
    </span>
    out of
    <span className="fc-black semi-bold mx-1">
      {goalUsersCount !== null && goalUsersCount >= 0 ? goalUsersCount : 0}
    </span>
    {getPluralText(goalUsersCount, "user", "users", true)} achieved
  </div>
);

const TargetBased = ({
  goalValue,
  goalAchieved,
  status,
  recurrenceStatus,
  isRollUp,
  RollupType,
  onGoalUserChange,
  showDrillDown,
  isAssignedToMeView,
  setShowSubGoalModal,
}) => {
  const isIndianTimezone = getUserTimezone();
  const { userDetails } = useRoleBasedContext();
  if (goalValue === null || goalValue === 0) {
    return (
      <div className="w-100">
        <div className="fs-16 center semi-bold">
          <div style={{ color: goalAchieved < 0 ? COLORS.RED : COLORS.BLACK }}>
            {goalAchieved ? NumFormatter(goalAchieved, isIndianTimezone) : 0}
          </div>

          <div className="fc-grey1 mr-1">/ - -</div>
          <FormTooltip
            id="achievement-card-tooltip"
            placement="bottom"
            text={t("MANAGED_GOALS.MESSAGES.GOAL_VALUE_NOT_SET")}
            disable={false}
          >
            <SvgAlert backgroundColor={false} className="cursor-pointer" />
          </FormTooltip>
          <div className="center mt-1 ml-1 space-x-16">
            {setShowSubGoalModal && (
              <div>
                <FormButton
                  id="contribution"
                  variant="link"
                  icon={<SvgSubGoal />}
                  onClick={() => {
                    if (setShowSubGoalModal) setShowSubGoalModal(true);
                  }}
                />
              </div>
            )}
            {onGoalUserChange && showDrillDown && (
              <div>
                <FormButton
                  id="contribution"
                  variant="link"
                  icon={<SvgLink />}
                  onClick={() => {
                    onGoalUserChange({
                      FullName: "All",
                      UserID: isAssignedToMeView ? userDetails.id : "",
                      Achievement: goalAchieved,
                      GoalValue: goalValue,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {recurrenceStatus === "YETTOSTART" ? (
          <div className="fs-12 fc-grey1">
            {status === "UNPUBLISHED"
              ? "Goal Unpublished"
              : "Cycle yet to start"}
          </div>
        ) : (
          <>
            {goalAchieved < 0 && (
              <div className="fs-12 fc-grey1">Negative Achievement</div>
            )}
          </>
        )}
      </div>
    );
  }

  const goalPercentage = (((goalAchieved || 0) / goalValue) * 100).toFixed(2);
  const achieved = NumFormatter(goalAchieved, isIndianTimezone) || "";
  const value = NumFormatter(goalValue, isIndianTimezone) || "";
  const percentage = NumFormatter(goalPercentage, isIndianTimezone) || "";
  const totalChars = achieved.length + value.length + percentage.length;

  return (
    <>
      <NumFormatTooltip
        achieved={goalAchieved || 0}
        goalValue={goalValue || 0}
        isIndianTimezone={isIndianTimezone}
        showPercentage={goalAchieved >= 0}
        showRollupInfo
        isRollUp={isRollUp}
        RollupType={RollupType}
        placement="top"
      >
        <div className="w-100">
          <div className="center">
            {recurrenceStatus !== "YETTOSTART" && goalAchieved >= 0 && (
              <div
                className={classNames([
                  totalChars < 18 ? "fs-22" : "fs-16",
                  "semi-bold border-right-1 bc-grey3 mr-1 pr-1",
                ])}
                style={{ color: getColor(goalPercentage) }}
              >
                {percentage}%
              </div>
            )}
            <div className="fs-16 center semi-bold">
              <div
                style={{
                  color: goalAchieved < 0 ? COLORS.RED : COLORS.BLACK,
                }}
              >
                {goalAchieved
                  ? NumFormatter(goalAchieved, isIndianTimezone)
                  : 0}
              </div>
              <div className="fc-grey1">
                /{NumFormatter(goalValue, isIndianTimezone)}
              </div>
            </div>{" "}
            <div className="center mt-1 ml-1 space-x-16">
              {setShowSubGoalModal && (
                <div>
                  <FormButton
                    id="contribution"
                    variant="link"
                    icon={<SvgSubGoal />}
                    onClick={() => {
                      if (setShowSubGoalModal) setShowSubGoalModal(true);
                    }}
                  />
                </div>
              )}
              {onGoalUserChange && showDrillDown && (
                <div>
                  <FormButton
                    id="contribution"
                    variant="link"
                    icon={<SvgLink />}
                    onClick={() => {
                      onGoalUserChange({
                        FullName: "All",
                        UserID: isAssignedToMeView ? userDetails.id : "",
                        Achievement: goalAchieved,
                        GoalValue: goalValue,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {recurrenceStatus === "YETTOSTART" ? (
            <div className="fs-12 fc-grey1">
              {status === "UNPUBLISHED"
                ? "Goal Unpublished"
                : "Cycle yet to start"}
            </div>
          ) : (
            <>
              {goalAchieved < 0 ? (
                <div className="fs-12 fc-grey1">Negative Achievement</div>
              ) : (
                <div className="achievement-card-progress-bar">
                  <ProgressBar percentage={goalPercentage} />
                </div>
              )}
            </>
          )}
        </div>
      </NumFormatTooltip>
    </>
  );
};

const NonTargetBasedManagedByMe = ({
  goalUsersCount,
  usersAchievedCount,
  recurrenceStatus,
  status,
  onGoalUserChange,
  showDrillDown,
  goalValue,
  goalAchieved,
}) => {
  const isIndianTimezone = getUserTimezone();

  if (goalUsersCount === null || goalUsersCount === 0) {
    return (
      <div className="w-100">
        <div className="fs-16 center semi-bold">
          <SvgUser width={16} height={16} className="mr-1" />
          <div>
            {usersAchievedCount
              ? NumFormatter(usersAchievedCount, isIndianTimezone)
              : 0}
          </div>
          <div className="fc-grey1 mr-1">/ - -</div>
          <FormTooltip
            id="achievement-card-tooltip"
            placement="bottom"
            text="User has not been set for this cycle."
            disable={false}
          >
            <SvgAlert backgroundColor={false} className="cursor-pointer" />
          </FormTooltip>
          {onGoalUserChange && showDrillDown && (
            <div className="mt-1  px-2">
              <FormButton
                id="contribution"
                variant="link"
                icon={<SvgLink />}
                onClick={() => {
                  onGoalUserChange({
                    FullName: "All",
                    UserID: "",
                    Achievement: goalAchieved,
                    GoalValue: goalValue,
                  });
                }}
              />
            </div>
          )}
        </div>
        {recurrenceStatus === "YETTOSTART" && (
          <div className="fs-12 fc-grey1">
            {status === "UNPUBLISHED"
              ? "Goal Unpublished"
              : "Cycle yet to start"}
          </div>
        )}
      </div>
    );
  }

  const percentage = (
    ((usersAchievedCount || 0) / goalUsersCount) *
    100
  ).toFixed(2);

  return (
    <>
      <FormTooltip
        text={<TooltipUserText {...{ goalUsersCount, usersAchievedCount }} />}
        placement="bottom"
      >
        <div className="w-100">
          <div className="fs-16 semi-bold center">
            <SvgUser width={16} height={16} className="mr-1" />
            <span style={{ color: getColor(percentage) }}>
              {NumFormatter(usersAchievedCount, isIndianTimezone)}
            </span>
            <span className="fc-grey1">
              /{NumFormatter(goalUsersCount, isIndianTimezone)}
            </span>
            {onGoalUserChange && showDrillDown && (
              <div className="mt-1  px-2">
                <FormButton
                  id="contribution"
                  variant="link"
                  icon={<SvgLink />}
                  onClick={() => {
                    onGoalUserChange({
                      FullName: "All",
                      UserID: "",
                      Achievement: goalAchieved,
                      GoalValue: goalValue,
                    });
                  }}
                />
              </div>
            )}
          </div>

          {recurrenceStatus === "YETTOSTART" ? (
            <div className="fs-12 fc-grey1">
              {status === "UNPUBLISHED"
                ? "Goal Unpublished"
                : "Cycle yet to start"}
            </div>
          ) : (
            <div className="achievement-card-progress-bar">
              <ProgressBar {...{ percentage }} />
            </div>
          )}
        </div>
      </FormTooltip>
    </>
  );
};

const NonTargetBasedAssignedToMe = ({
  goalValue,
  goalAchieved,
  status,
  recurrenceStatus,
  greaterThan,
  isRollUp,
  RollupType,
  onGoalUserChange,
  showDrillDown,
  setShowSubGoalModal,
}) => {
  const isIndianTimezone = getUserTimezone();
  const { userDetails } = useRoleBasedContext();

  if (goalValue === null || goalValue === 0) {
    return (
      <div className="w-100">
        <div className="fs-16 center semi-bold">
          <div className="mr-1 pr-1 border-right-1 bc-grey3">
            {goalAchieved ? NumFormatter(goalAchieved, isIndianTimezone) : 0}
          </div>
          <div className="fc-grey1 mr-1">Goal: - -</div>
          <FormTooltip
            id="achievement-card-tooltip"
            placement="bottom"
            text={t("MANAGED_GOALS.MESSAGES.GOAL_VALUE_NOT_SET")}
            disable={false}
          >
            <SvgAlert backgroundColor={false} className="cursor-pointer" />
          </FormTooltip>
          <div className="center mt-1 ml-1 space-x-16">
            {setShowSubGoalModal && (
              <div>
                <FormButton
                  id="contribution"
                  variant="link"
                  icon={<SvgSubGoal />}
                  onClick={() => {
                    if (setShowSubGoalModal) setShowSubGoalModal(true);
                  }}
                />
              </div>
            )}
            {onGoalUserChange && showDrillDown && (
              <div>
                <FormButton
                  id="contribution"
                  variant="link"
                  icon={<SvgLink />}
                  onClick={() => {
                    onGoalUserChange({
                      FullName: "All",
                      UserID: userDetails.id,
                      Achievement: goalAchieved,
                      GoalValue: goalValue,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {recurrenceStatus === "YETTOSTART" ? (
          <div className="fs-12 fc-grey1">
            {status === "UNPUBLISHED"
              ? "Goal Unpublished"
              : "Cycle yet to start"}
          </div>
        ) : (
          <>
            {goalAchieved < 0 && (
              <div className="fs-12 fc-grey1">Negative Achievement</div>
            )}
          </>
        )}
      </div>
    );
  }

  const percentage = (((goalAchieved || 0) / goalValue) * 100).toFixed(2);

  return (
    <NumFormatTooltip
      achieved={goalAchieved || 0}
      goalValue={goalValue || 0}
      isIndianTimezone={isIndianTimezone}
      showPercentage={false}
      showRollupInfo
      isRollUp={isRollUp}
      RollupType={RollupType}
      placement="top"
    >
      <div className="w-100">
        <div className="fs-16 center semi-bold">
          <div
            className="mr-1 pr-1 border-right-1 bc-grey3"
            style={{
              color:
                goalAchieved < 0
                  ? COLORS.RED
                  : greaterThan
                  ? getGreaterThanMarkerColor(percentage)
                  : getLesserThanMarkerColor(percentage),
            }}
          >
            {goalAchieved ? NumFormatter(goalAchieved, isIndianTimezone) : 0}
          </div>
          <div className="fc-grey1 mr-1">
            Goal:
            <span className="ml-1 fc-black">
              {greaterThan ? "≥" : "≤"}
              {NumFormatter(goalValue, isIndianTimezone)}
            </span>
          </div>
          <div className="center mt-1 ml-1 space-x-16">
            {setShowSubGoalModal && (
              <div>
                <FormButton
                  id="contribution"
                  variant="link"
                  icon={<SvgSubGoal />}
                  onClick={() => {
                    if (setShowSubGoalModal) setShowSubGoalModal(true);
                  }}
                />
              </div>
            )}
            {onGoalUserChange && showDrillDown && (
              <div>
                <FormButton
                  id="contribution"
                  variant="link"
                  icon={<SvgLink />}
                  onClick={() => {
                    onGoalUserChange({
                      FullName: "All",
                      UserID: userDetails.id,
                      Achievement: goalAchieved,
                      GoalValue: goalValue,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="fs-12 fc-grey1">
          {recurrenceStatus === "YETTOSTART" ? (
            <div className="fs-12 fc-grey1">
              {status === "UNPUBLISHED"
                ? "Goal Unpublished"
                : "Cycle yet to start"}
            </div>
          ) : (
            <>
              {goalAchieved < 0 ? (
                <div className="fs-12 fc-grey1">Negative Achievement</div>
              ) : (
                <div className="achievement-card-progress-bar">
                  <AverageProgressBar
                    {...{ percentage, reverseOrder: !greaterThan }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </NumFormatTooltip>
  );
};

const AchievementCardLeft = (props) => {
  const {
    status,
    goalValue,
    goalAchieved,
    recurrenceStatus,
    goalAggConfig,
    isAssignedToMeView,
    goalUsersCount,
    usersAchievedCount,
    isUserPresent,
    isRollUp,
    RollupType,
    onGoalUserChange,
    showDrillDown,
    setShowSubGoalModal,
  } = props;

  const cardView = isAssignedToMeView ? "ASSIGNED_TO_ME" : "MANAGED_BY_ME";
  switch (recurrenceStatus) {
    case "YETTOSTART":
      switch (cardView) {
        case "ASSIGNED_TO_ME": {
          if (!isUserPresent) {
            return (
              <div>
                <SvgUserUnavailable />
                <span className="fc-grey fs-13 semi-bold">
                  {t("MANAGED_GOALS.MESSAGES.NOT_PART_OF_CYCLE")}
                </span>
              </div>
            );
          }
          switch (goalAggConfig) {
            case GOAL_AGGREGATION_TYPES.TARGET_BASED: {
              return (
                <TargetBased
                  {...{
                    goalValue,
                    goalAchieved,
                    status,
                    recurrenceStatus,
                    isRollUp,
                    RollupType,
                    onGoalUserChange,
                    showDrillDown,
                    isAssignedToMeView,
                    setShowSubGoalModal,
                  }}
                />
              );
            }
            case GOAL_AGGREGATION_TYPES.MAINTAIN_GREATER_THAN_EQUAL:
            case GOAL_AGGREGATION_TYPES.AVG_GREATER_THAN_EQUAL: {
              return (
                <NonTargetBasedAssignedToMe
                  {...{
                    goalValue,
                    goalAchieved,
                    status,
                    recurrenceStatus,
                    isRollUp,
                    RollupType,
                    onGoalUserChange,
                    showDrillDown,
                    setShowSubGoalModal,
                  }}
                  greaterThan
                />
              );
            }
            case GOAL_AGGREGATION_TYPES.MAINTAIN_LESS_THAN_EQUAL:
            case GOAL_AGGREGATION_TYPES.AVG_LESS_THAN_EQUAL: {
              return (
                <NonTargetBasedAssignedToMe
                  {...{
                    goalValue,
                    goalAchieved,
                    status,
                    recurrenceStatus,
                    isRollUp,
                    RollupType,
                    onGoalUserChange,
                    showDrillDown,
                    setShowSubGoalModal,
                  }}
                  greaterThan={false}
                />
              );
            }
            default: {
              return null;
            }
          }
        }
        case "MANAGED_BY_ME": {
          if (goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED) {
            return (
              <TargetBased
                {...{
                  goalValue,
                  goalAchieved,
                  status,
                  recurrenceStatus,
                  isRollUp,
                  RollupType,
                  onGoalUserChange,
                  showDrillDown,
                }}
              />
            );
          }
          return (
            <NonTargetBasedManagedByMe
              {...{
                goalUsersCount,
                usersAchievedCount,
                status,
                recurrenceStatus,
                onGoalUserChange,
                showDrillDown,
                goalValue,
                goalAchieved,
              }}
            />
          );
        }
        default: {
          return null;
        }
      }
    case "COMPLETED":
    case "INPROGRESS": {
      switch (cardView) {
        case "ASSIGNED_TO_ME": {
          if (!isUserPresent) {
            return (
              <div>
                <SvgUserUnavailable />
                <span className="fc-grey fs-13 semi-bold">
                  {t("MANAGED_GOALS.MESSAGES.NOT_PART_OF_CYCLE")}
                </span>
              </div>
            );
          }
          switch (goalAggConfig) {
            case GOAL_AGGREGATION_TYPES.TARGET_BASED: {
              return (
                <TargetBased
                  {...{
                    goalValue,
                    goalAchieved,
                    status,
                    recurrenceStatus,
                    isRollUp,
                    RollupType,
                    onGoalUserChange,
                    showDrillDown,
                    isAssignedToMeView,
                    setShowSubGoalModal,
                  }}
                />
              );
            }
            case GOAL_AGGREGATION_TYPES.MAINTAIN_GREATER_THAN_EQUAL:
            case GOAL_AGGREGATION_TYPES.AVG_GREATER_THAN_EQUAL: {
              return (
                <NonTargetBasedAssignedToMe
                  {...{
                    goalValue,
                    goalAchieved,
                    status,
                    recurrenceStatus,
                    isRollUp,
                    RollupType,
                    onGoalUserChange,
                    showDrillDown,
                    setShowSubGoalModal,
                  }}
                  greaterThan
                />
              );
            }
            case GOAL_AGGREGATION_TYPES.MAINTAIN_LESS_THAN_EQUAL:
            case GOAL_AGGREGATION_TYPES.AVG_LESS_THAN_EQUAL: {
              return (
                <NonTargetBasedAssignedToMe
                  {...{
                    goalValue,
                    goalAchieved,
                    status,
                    recurrenceStatus,
                    isRollUp,
                    RollupType,
                    onGoalUserChange,
                    showDrillDown,
                    setShowSubGoalModal,
                  }}
                  greaterThan={false}
                />
              );
            }
            default: {
              return null;
            }
          }
        }
        case "MANAGED_BY_ME": {
          if (goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED) {
            return (
              <TargetBased
                {...{
                  goalValue,
                  goalAchieved,
                  status,
                  recurrenceStatus,
                  isRollUp,
                  RollupType,
                  onGoalUserChange,
                  showDrillDown,
                  isAssignedToMeView,
                }}
              />
            );
          }
          return (
            <NonTargetBasedManagedByMe
              {...{
                goalUsersCount,
                usersAchievedCount,
                status,
                recurrenceStatus,
                onGoalUserChange,
                showDrillDown,
                goalValue,
                goalAchieved,
              }}
            />
          );
        }
        default: {
          return <></>;
        }
      }
    }

    default: {
      return null;
    }
  }
};

export default AchievementCardLeft;
