import React from "react";

const SvgAvatar15 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="kl4z__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .kl4z__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="kl4z__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74 0 .02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-4.54 4.35-9.6 8.18-15.07 11.38-1.02.6-2.06 1.18-3.12 1.73a79.91 79.91 0 0 1-14.61 5.99c-1.09.33-2.2.64-3.32.91-1.56.41-3.13.76-4.73 1.06-1.04.2-2.1.38-3.16.53-2.29.35-4.62.6-6.97.74-1.23.09-2.48.14-3.73.16-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-1.24-.02-2.48-.07-3.71-.16-2.36-.14-4.69-.39-6.98-.74-1.08-.15-2.14-.33-3.2-.54-1.58-.3-3.15-.64-4.69-1.04-1.11-.28-2.22-.58-3.31-.91a81.306 81.306 0 0 1-32.83-19.11c-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="kl4z__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.085)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="kl4z__cls-2"
        />
        <path
          d="M148.15 93.69c-4.99-4.99-13.11-4.99-18.11 0l-1.92 1.93-17.03-17.04c-.59-.58-1.62-.58-2.21 0-4.99 4.99-4.99 13.12 0 18.11l1.92 1.92-17.03 17.04c-.61.6-.61 1.59 0 2.2 2.42 2.42 5.63 3.75 9.05 3.75s6.64-1.33 9.06-3.75l1.92-1.92 5.6 5.6v31.86c1.06-.55 2.1-1.13 3.12-1.73v-27.01l8.31 8.31a1.56 1.56 0 0 0 2.21 0c2.42-2.42 3.75-5.63 3.75-9.05s-1.33-6.64-3.75-9.05l-1.92-1.93 17.03-17.03c.61-.61.61-1.6 0-2.21Zm-38.08-11.71 15.84 15.84-.44.45c-1.32-.8-2.85-1.29-4.51-1.29-2.06 0-3.94.74-5.44 1.94l-4.43-4.44c-3.4-3.4-3.74-8.72-1.02-12.5Zm-.4 33.67a9.612 9.612 0 0 1-6.85 2.83c-2.06 0-4.02-.63-5.65-1.82l15.84-15.84.44.44c-.8 1.33-1.28 2.86-1.28 4.51 0 2.07.74 3.94 1.93 5.44l-4.43 4.44Zm11.29-15.55c3.13 0 5.67 2.55 5.67 5.67s-2.54 5.68-5.67 5.68-5.67-2.55-5.67-5.68 2.54-5.67 5.67-5.67Zm12.71 23.81c0 2.06-.64 4.02-1.82 5.66l-15.84-15.85.44-.44c1.32.8 2.85 1.29 4.51 1.29 2.06 0 3.94-.74 5.44-1.94l4.43 4.43a9.622 9.622 0 0 1 2.84 6.85Zm-4.76-13.18-.44-.44c.8-1.33 1.28-2.86 1.28-4.52 0-2.06-.74-3.93-1.93-5.43l1.4-1.41 3.03-3.03c3.4-3.4 8.72-3.73 12.5-1.01l-15.84 15.84Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar15.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar15;
