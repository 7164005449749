import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgPreview = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <defs>
        <clipPath id="5qiw__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#5qiw__a)">
        <path
          fill={color}
          d="M8 5.083a2.889 2.889 0 0 0-.814.13 1.443 1.443 0 0 1 .189.7 1.458 1.458 0 0 1-1.458 1.458 1.443 1.443 0 0 1-.7-.189 2.909 2.909 0 1 0 2.786-2.1Zm7.41 2.537A8.353 8.353 0 0 0 8 3 8.354 8.354 0 0 0 .591 7.62a.842.842 0 0 0 0 .76A8.353 8.353 0 0 0 8 13a8.354 8.354 0 0 0 7.409-4.62.842.842 0 0 0 0-.76ZM8 11.75A7.084 7.084 0 0 1 1.8 8 7.084 7.084 0 0 1 8 4.25 7.084 7.084 0 0 1 14.2 8 7.084 7.084 0 0 1 8 11.75Z"
        />
      </g>
    </svg>
  );
};
SvgPreview.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 16,
  width: 16,
};
export default SvgPreview;
