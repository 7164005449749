import { t } from "i18next";
import React, { useState } from "react";
import { ConfirmationModal, CreateInput } from "src/components/common";
import { CREATE_PROGRAM_REGEX } from "src/constants";

const RenameFilterModal = ({
  handleEdit,
  onClose,
  show,
  selectedFilter,
  selectedIndex,
}) => {
  const [name, setName] = useState(selectedFilter?.FilterName);
  const [warning, setWarning] = useState(false);

  return (
    <ConfirmationModal
      title={t("COMMON.LABELS.RENAME_QUICK_FILTER")}
      body={
        <CreateInput
          value={name}
          setValue={setName}
          label={t("COMMON.LABELS.FILTER_NAME")}
          placeholder={t("COMMON.LABELS.FILTER_NAME")}
          regex={CREATE_PROGRAM_REGEX}
          warning={warning}
          setWarning={setWarning}
        />
      }
      confirmButtonText={t("COMMON.BUTTONS.UPDATE")}
      show={show}
      onClose={onClose}
      onConfirm={() => {
        handleEdit({ FilterName: name, index: selectedIndex });
        onClose();
      }}
      cancelButton
      confirmButtonDisabled={!name || warning}
      showConfirmButtonToolTip={false}
      dialogClassName="modal-filter-input"
      addButtonsInfooter
    />
  );
};

export default RenameFilterModal;
