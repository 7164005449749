import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgGoalTemplateInfo = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...rest}
    >
      <g fill={color} transform="translate(19112 18702)">
        <path
          d="M-19107-18698h3v2h-3a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z"
          data-name="Rectangle 2455"
        />
        <path
          d="M-19107-18695h3v2h-3a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z"
          data-name="Rectangle 2761"
        />
        <path
          d="M-19107-18692h3v2h-3a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z"
          data-name="Rectangle 2762"
        />
        <rect
          width={16}
          height={2}
          data-name="Rectangle 2763"
          rx={1}
          transform="translate(-19112 -18688)"
        />
        <rect
          width={16}
          height={2}
          data-name="Rectangle 2766"
          rx={1}
          transform="translate(-19112 -18702)"
        />
        <rect
          width={16}
          height={2}
          data-name="Rectangle 2764"
          rx={1}
          transform="rotate(90 -204 -18906)"
        />
        <rect
          width={16}
          height={2}
          data-name="Rectangle 2765"
          rx={1}
          transform="rotate(90 -197 -18899)"
        />
        <path
          d="M-19100-18696.998a1 1 0 1 1-1-1 1 1 0 0 1 1 1Zm0 3v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 2 0Z"
          data-name="Union 53"
        />
      </g>
    </svg>
  );
};

SvgGoalTemplateInfo.defaultProps = {
  color: COLORS.GREY_2,
  height: 16,
  width: 16,
};
export default SvgGoalTemplateInfo;
