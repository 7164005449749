import React from "react";
import PropTypes from "prop-types";
import "./buttonShimmer.scss";

const DEFAULT_DURATION_MS = 1600;
const DEFAULT_HEIGHT = 40;

const calcShimmerStyle = (height, duration) => ({
  backgroundSize: `${1000}px ${height}px`,
  animationDuration: `${(duration / 1000).toFixed(1)}s`,
});

const ButtonShimmer = (props) => {
  const { text, height, className, duration } = props;
  const style = calcShimmerStyle(height, duration);
  return (
    <div
      className={`button-shimmer br-4 fs-14 fc-white ${className || ""}`}
      style={style}
    >
      <div className="button-text semi-bold fs-14">{text}</div>
    </div>
  );
};

ButtonShimmer.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  duration: PropTypes.number,
  height: PropTypes.number,
};
ButtonShimmer.defaultProps = {
  text: "Button",
  height: DEFAULT_HEIGHT,
  duration: DEFAULT_DURATION_MS,
};

export default ButtonShimmer;
