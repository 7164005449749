import React from "react";

const SvgCompletedSm = (props) => {
  const { height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 15 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <ellipse
        cx={7.009}
        cy={7}
        fill="#D0FDD7"
        opacity={0.7}
        rx={7.009}
        ry={7}
      />
      <ellipse cx={6.988} cy={7} fill="#57C96B" rx={4.988} ry={4.982} />
      <path
        stroke="#F5F5F5"
        strokeWidth={1.12}
        d="m3 6.763 2.469 2.468L9.936 4.77"
      />
    </svg>
  );
};
SvgCompletedSm.defaultProps = {
  height: 14,
  width: 14,
};
export default SvgCompletedSm;
