import React from "react";

const SvgAvatar35 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="ed85__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .ed85__cls-1{fill:#fff}.ed85__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="ed85__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="ed85__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="ed85__cls-2"
        />
        <path
          d="M93.83 79.32c-6.77 0-12.28-5.51-12.28-12.28s5.51-12.28 12.28-12.28 12.28 5.51 12.28 12.28-5.51 12.28-12.28 12.28Zm0-21.55c-5.11 0-9.28 4.16-9.28 9.28s4.16 9.28 9.28 9.28 9.28-4.16 9.28-9.28-4.16-9.28-9.28-9.28Z"
          className="ed85__cls-1"
        />
        <path
          d="M111.09 97.78h-8.5l3.18-3h5.32c2.74 0 4.97-2.23 4.97-4.97V76.85c0-4.58-3.73-8.31-8.31-8.31h-3.14v-3h3.14c6.23 0 11.31 5.07 11.31 11.31v12.96c0 4.39-3.57 7.97-7.97 7.97ZM59.22 123.03c-.37 0-.72-.13-1-.38-.32-.29-.5-.69-.5-1.12v-5.03c0-.43.18-.83.5-1.12.32-.28.74-.43 1.17-.37l22.18 2.51c.76.09 1.33.73 1.33 1.49s-.57 1.4-1.33 1.49l-22.18 2.52h-.17Zm1.5-4.85v1.67l7.38-.84-7.38-.84Z"
          className="ed85__cls-1"
        />
        <path
          d="M103.11 123.03h-.17l-21.71-2.52c-.76-.09-1.33-.73-1.33-1.49s.57-1.4 1.32-1.49l21.19-2.51c.4-.05.8.07 1.11.32.31.25.51.62.55 1.02l.52 5.03c.05.45-.11.9-.44 1.22-.28.28-.66.43-1.05.43Zm-8.84-4.04 7.15.83-.17-1.66-6.98.83Z"
          className="ed85__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar35.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar35;
