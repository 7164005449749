import { t } from "i18next";
import React from "react";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgBetaTag, SvgCheckMark } from "src/icons";
import { Link } from "src/router";
import "./aceMenuListItem.scss";

const AceMenuListItem = ({
  label,
  route,
  icon: Icon,
  isActive,
  isBeta,
  variant = "web",
}) => {
  return (
    <div
      key={label}
      className={classNames([
        "ace-menu-list-item",
        variant,
        isActive ? "active" : "",
      ])}
    >
      <Link to={route}>
        <div className="p-12 center">
          <div className={classNames(["ace-menu-list-item-icon", variant])}>
            {Icon && <Icon />}
          </div>
          <div
            className={classNames([
              "center ace-menu-list-item-label",
              variant,
              isActive ? "active" : "",
            ])}
          >
            <span>{t(label)}</span>
            {isBeta && <SvgBetaTag className="ml-2" />}
          </div>
          {isActive && (
            <div className="ml-auto">
              <SvgCheckMark color={COLORS.PRIMARY_BLUE} />
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default AceMenuListItem;
