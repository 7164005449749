import { t } from "i18next";
import { useState } from "react";
import { FormButton } from "src/components";
import {
  INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES,
  MAXIMUM_INCENTIVE_CRITERIA_CONDITIONS_ALLOWED,
} from "src/constants";
import { classNames } from "src/helpers";
import { useToggle } from "src/hooks";
import { NoResults } from "src/images";
import { groupByConditionValid } from "../../utils";
import { ConditionTypeModal } from "../conditionTypeModal";
import { DisplayFormula } from "../formulaBuilder";
import { standardToCustomCondition } from "../formulaBuilder/utils";
import { AddItem, Item, ItemBody, ItemIndex } from "../item";
import { DeleteConfirmationModal } from "../modals";
import "./styles.scss";

export const ConditionCount = ({ fulfilledCondtions }) => {
  const limitReached =
    fulfilledCondtions.length === MAXIMUM_INCENTIVE_CRITERIA_CONDITIONS_ALLOWED;
  return (
    <div className="condition-count fs-14 fc-grey">
      <span className={classNames([limitReached ? "limit-reached" : ""])}>
        <span className="semi-bold filfilled-conditions">
          {fulfilledCondtions.length}
        </span>
        <span className="semi-bold total-conditions">
          /{MAXIMUM_INCENTIVE_CRITERIA_CONDITIONS_ALLOWED}&nbsp;
        </span>
      </span>
      {t("RULEBOOK.LABELS.CONDITIONS")}
    </div>
  );
};

export const ConditionNotFound = ({ onAdd, criteriaIndex }) => {
  const [showConditionTypeModal, toggleConditionTypeModal] = useToggle(false);
  const [conditionType, setConditionType] = useState(
    INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
  );

  const handleClick = () => {
    onAdd({ criteriaIndex, conditionIndex: 0, conditionType });
  };

  return (
    <div className="condition404">
      <NoResults width="150px" />
      <span className="fc-grey fs-14 mb-4 mt-3">
        {t("INCENTIVES.PROGRAM.LABELS.ADD_CONDITIONS")}
      </span>
      <FormButton
        id="criteria-condition-add-btn"
        variant="outline-primary"
        type="button"
        onClick={toggleConditionTypeModal}
        className="fs-12"
        label={t("RULEBOOK.BUTTONS.ADD_CONDITION")}
      />
      <ConditionTypeModal
        show={showConditionTypeModal}
        onHide={toggleConditionTypeModal}
        selected={
          conditionType ===
          INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
            ? 0
            : 1
        }
        setSelected={setConditionType}
        handleClick={(index) =>
          setConditionType(
            index === 0
              ? INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
              : INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.FORMULA
          )
        }
        onDoneClick={() => {
          handleClick();
          toggleConditionTypeModal();
        }}
        title={t("INCENTIVES.COMMON.LABELS.SELECT_CONDITION_TYPE")}
        desc={t("INCENTIVES.COMMON.LABELS.CONDITION_TYPE")}
        isConditionType
      />
    </div>
  );
};

const EmptyCondition = ({ conditionIndex }) => {
  const isEmpty = true;
  return (
    <Item className="mb-3" isEmpty>
      <ItemIndex index={conditionIndex + 1} />
      <ItemBody isEmpty={isEmpty} className="ml-2 empty-condition">
        <div className="fs-12 fc-grey">
          {t("RULEBOOK.LABELS.CONDITION_ADD_HERE")}
        </div>
      </ItemBody>
    </Item>
  );
};

export const ConditionItem = ({
  condition,
  conditionIndex,
  criteriaIndex,
  conditionActions,
  selectedTemplates,
  selectedGoals,
  totalConditions,
  handleDeleteClick,
  hideButtons = true,
}) => {
  const [showConditionTypeModal, toggleConditionTypeModal] = useToggle(false);
  const [conditionType, setConditionType] = useState(
    INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
  );

  const handleEdit = () => {
    conditionActions.edit({ criteriaIndex, conditionIndex });
  };

  const handleDelete = () => {
    handleDeleteClick(conditionIndex);
  };

  const handleAdd = () => {
    if (
      totalConditions.length === MAXIMUM_INCENTIVE_CRITERIA_CONDITIONS_ALLOWED
    )
      return;
    conditionActions.add({
      criteriaIndex,
      conditionIndex: conditionIndex + 1,
      conditionType,
    });
  };

  if (!conditionActions?.isConditionFulfilled(condition)) {
    return <EmptyCondition conditionIndex={conditionIndex} />;
  }
  const subGoals = condition?.IsFormula
    ? condition?.Formula?.find((formula) => formula.hasOwnProperty("SubGoal"))
        ?.SubGoal
    : condition?.SubGoal;
  const isGroupBy = groupByConditionValid(subGoals);
  return (
    <Item className="mb-3">
      <ItemIndex index={conditionIndex + 1} name={condition?.Name} />
      <ItemBody
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        className="ml-2 mr-3"
        hideButtons={hideButtons}
        showGroupBy={isGroupBy}
      >
        <DisplayFormula
          condition={standardToCustomCondition({
            condition,
            selectedGoals,
            selectedTemplates,
          })}
        />
      </ItemBody>
      {!hideButtons && (
        <>
          <AddItem
            handleAdd={toggleConditionTypeModal}
            disabled={
              totalConditions.length ===
              MAXIMUM_INCENTIVE_CRITERIA_CONDITIONS_ALLOWED
            }
            toolTipText={t("INCENTIVES.PROGRAM.LABELS.MAX_CONDITION", {
              maxCondition: MAXIMUM_INCENTIVE_CRITERIA_CONDITIONS_ALLOWED,
            })}
          />
          <ConditionTypeModal
            show={showConditionTypeModal}
            onHide={toggleConditionTypeModal}
            selected={
              conditionType ===
              INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
                ? 0
                : 1
            }
            setSelected={setConditionType}
            handleClick={(index) =>
              setConditionType(
                index === 0
                  ? INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
                  : INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.FORMULA
              )
            }
            onDoneClick={() => {
              handleAdd();
              toggleConditionTypeModal();
            }}
            title={t("INCENTIVES.COMMON.LABELS.SELECT_CONDITION_TYPE")}
            desc={t("INCENTIVES.COMMON.LABELS.CONDITION_TYPE")}
            isConditionType
          />
        </>
      )}
    </Item>
  );
};

export const ConditionList = ({
  conditions,
  criteriaIndex,
  conditionActions,
  type,
  selectedTemplates,
  selectedGoals,
  disabled,
}) => {
  const [deleteConditionIndex, setDeleteConditionIndex] = useState(null);

  const handleClose = () => {
    setDeleteConditionIndex(null);
  };

  const handleDeleteClick = (rewardIndex) => {
    // casting to String because rewardIndex could be 0
    setDeleteConditionIndex(String(rewardIndex));
  };

  const handleDeleteCondition = () => {
    conditionActions.delete({
      criteriaIndex,
      conditionIndex: Number(deleteConditionIndex),
    });
    handleClose();
  };

  const totalConditions = conditions;

  return (
    <div className="condition-list py-3">
      {conditions.length === 0 ? (
        <ConditionNotFound
          onAdd={conditionActions.add}
          criteriaIndex={criteriaIndex}
        />
      ) : null}
      {conditions.map((condition, conditionIndex) => {
        return (
          <ConditionItem
            condition={condition}
            conditionIndex={conditionIndex}
            criteriaIndex={criteriaIndex}
            conditionActions={conditionActions}
            type={type}
            selectedTemplates={selectedTemplates}
            selectedGoals={selectedGoals}
            handleDeleteClick={handleDeleteClick}
            totalConditions={totalConditions}
            hideButtons={disabled}
          />
        );
      })}
      {deleteConditionIndex ? (
        <DeleteConfirmationModal
          show={Boolean(deleteConditionIndex)}
          handleClose={handleClose}
          heading={t("INCENTIVES.PROGRAM.LABELS.DELETE_CONDITION")}
          handleDelete={handleDeleteCondition}
        >
          <div className="fs-14 semi-bold center">
            <div>
              {t("INCENTIVES.PROGRAM.LABELS.DELETE_WARNING")}{" "}
              {Number(deleteConditionIndex) + 1}?
            </div>
          </div>
          <div className="fs-13 mt-2">
            {t("INCENTIVES.PROGRAM.LABELS.CONDITION_RESET")}
          </div>
        </DeleteConfirmationModal>
      ) : null}
    </div>
  );
};
