import { gql, useMutation } from "src/api/client";

const IMPORT_PAYOUT_UPLOAD_URL_MUTATION = gql`
  mutation importpayoutUploadUrl($programId: ID) {
    importPayoutUploadUrl(programId: $programId) {
      ... on PayoutUrlResponse {
        success
        message
        response {
          url
          fileId
        }
      }
    }
  }
`;

export const useImportPayoutUploadUrl = () => {
  return useMutation(IMPORT_PAYOUT_UPLOAD_URL_MUTATION);
};
