import { gql, useMutation } from "src/api/client";

const GET_CUSTOM_REWARD_IMAGE_UPLOAD_URL = gql`
  mutation getCustomRewardImageUploadUrl($RewardId: ID!) {
    getCustomRewardImageUploadUrl(RewardId: $RewardId) {
      message
      response {
        url
        fileId
      }
    }
  }
`;

export const useGetCustomRewardImageUploadUrl = () => {
  return useMutation(GET_CUSTOM_REWARD_IMAGE_UPLOAD_URL);
};
