import { arrayMove } from "@dnd-kit/sortable";
import React from "react";
import { Sortable } from "src/components/base";
import ColumnItems from "./ColumnItems";
import "./rearrangeColumns.scss";

const Item = ({ id, Header, idx }) => {
  return (
    <ColumnItems
      id={id}
      Header={Header}
      key={idx}
      labelComponent={<span className="pl-2">{Header}</span>}
      dragIconAtTheBack={false}
    />
  );
};

const RearrangeColumns = ({ items, setItems }) => {
  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };
  return (
    <div className="rearrange-tab">
      <Sortable
        handleDragEnd={handleDragEnd}
        sortableItems={items?.map((item) => item.id)}
      >
        {items?.map(({ id, Header }, idx) => (
          <Item id={id} Header={Header} key={idx} />
        ))}
      </Sortable>
    </div>
  );
};

export default RearrangeColumns;
