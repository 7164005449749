import React from "react";
import { Image } from "src/components";
import PayoutRequestEmptyStateImg from "./PayoutRequestEmptyState.svg";

const PayoutRequestEmptyState = (props) => (
  <Image
    src={PayoutRequestEmptyStateImg}
    alt="PayoutRequestEmptyStateImg"
    {...props}
  />
);

export default PayoutRequestEmptyState;
