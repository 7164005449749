import { gql, useQuery } from "src/api/client";
import {
  CORE_TEMPLATE_DETAILS_FRAGMENT,
  CORE_TEMPLATE_GOAL_RULE_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
} from "src/api/fragments";

// src/modules/templates/mobile/TemplateDetailMobileView.js-   done!!
export const GET_TEMPLATE_VIEW_DETAILS_QUERY = gql`
  ${CORE_TEMPLATE_DETAILS_FRAGMENT}
  ${CORE_TEMPLATE_GOAL_RULE_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query GetTemplateDetails($TemplateId: ID!) {
    template(TemplateId: $TemplateId) {
      ... on SingleTemplateResponse {
        response {
          Template {
            ...coreTemplateDetailsFragment
            AuditType
            ModifiedBy
            DrilldownStatus
            GoalRules {
              ...coreTemplateGoalRuleFragment
            }
          }
          validations {
            isAggregateFunctionAllowed
            isUnpublishedAllowed
            isDeleteAllowed
          }
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetTemplateViewDetails = (queryOptions) => {
  return useQuery(GET_TEMPLATE_VIEW_DETAILS_QUERY, queryOptions);
};
