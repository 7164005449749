import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgHome = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M7.788 4.132 2.667 8.224v6.345c0 .114.047.224.13.305.083.08.196.126.314.126l3.05-.008L8.487 15l.846-.008h.445l3.111.008c.118 0 .231-.045.315-.126.083-.081.13-.19.13-.305V8.22l-5.12-4.09a.345.345 0 0 0-.425 0Zm8.089 2.781-2.322-1.857V1.323a.319.319 0 0 0-.098-.228.339.339 0 0 0-.235-.095h-1.556a.339.339 0 0 0-.236.095.319.319 0 0 0-.097.228V3.28L8.846 1.295A1.359 1.359 0 0 0 8 1c-.31 0-.609.105-.847.295L.12 6.913a.325.325 0 0 0-.12.219.314.314 0 0 0 .076.236l.708.836a.331.331 0 0 0 .225.116.343.343 0 0 0 .244-.072l6.534-5.221a.345.345 0 0 1 .425 0l6.534 5.22a.336.336 0 0 0 .243.074.34.34 0 0 0 .226-.116l.708-.836a.32.32 0 0 0 .074-.237.315.315 0 0 0-.121-.219Z"
      />
    </svg>
  );
};
SvgHome.defaultProps = {
  color: COLORS.GREY_1,
  height: 16,
  width: 16,
};
export default SvgHome;
