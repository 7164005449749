import SearchBar from "src/components/common/atoms/searchBar/SearchBar";
import { ConfigureColumnContainer } from "src/components/container/configureColumnContainer";
import { MOBILE_DEFAULT_USER_ATTRIBUTES } from "src/config/userAttributes";
import { SvgConfigureColumn, SvgSearchThin } from "src/icons";

export const ACHIEVEMENT_BREAKDOWN_FILTERS = [
  {
    label: "Search",
    key: "Search",
    defaultValue: "",
    icon: SvgSearchThin,
    component: SearchBar,
    props: ({ filter, filtersState, setSearch }) => ({
      className: "my-3",
      placeholder: "Search Users",
      search: filtersState[filter.key],
      setSearch: (value) => setSearch(value),
    }),
  },
  {
    label: "Configure Columns",
    key: "filter",
    icon: SvgConfigureColumn,
    defaultValue: false,
    component: ConfigureColumnContainer,
    props: ({
      filter,
      filtersToggleState,
      toggleFilter,
      configureColumnProps,
      handleChange,
    }) => {
      return {
        title: "Configure Columns",
        show: filtersToggleState[filter.key],
        onHide: () => toggleFilter(filter.key),
        setFilter: (value) => handleChange({ key: filter.key, value }),
        defaultValue: MOBILE_DEFAULT_USER_ATTRIBUTES.map(
          ({ SchemaName }) => SchemaName
        ),
        ...configureColumnProps,
      };
    },
  },
];
