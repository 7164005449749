import React from "react";

const MobileFilterBarFilters = (props) => {
  const {
    filtersToggleState,
    filtersState,
    filters,
    handleChange,
    handleBulkUpdate,
    toggleFilter,
    ...rest
  } = props;

  return (
    <>
      {filters.map((filter, index) => {
        const Component = filter.component;
        return (
          <>
            {filtersToggleState[filter.key] && Component && (
              <Component
                key={index}
                {...filter.props({
                  filter,
                  filtersState,
                  handleChange,
                  handleBulkUpdate,
                  filtersToggleState,
                  toggleFilter,
                  ...rest,
                })}
              />
            )}
          </>
        );
      })}
    </>
  );
};

export default MobileFilterBarFilters;
