import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const DELETE_LEADERBOARD_GROUP_RULE_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation deleteLeaderboardRecurrenceGroupRule(
    $LeaderboardId: ID!
    $RecurrenceId: ID!
  ) {
    deleteLeaderboardRecurrenceGroupRule(
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
    ) {
      ... on DeleteLeaderboardRecurrenceGroupRuleResponse {
        success
        message
        response
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useDeleteLeaderboardGroupRule = () => {
  return useMutation(DELETE_LEADERBOARD_GROUP_RULE_MUTATION);
};
