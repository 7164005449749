import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import { useGetGoalCrudBlockingItems } from "src/api/useGoal";
import { Shimmer, toaster, Truncate } from "src/components/common/atoms";
import { FormBadge, FormButton } from "src/components/common/formElements";
import DependencyAccordion from "./DependencyAccordian";
import "./styles.scss";

const GoalDependencyModal = (props) => {
  const { show, onHide, goalID, name, status } = props;
  const {
    loading,
    error,
    data: { getGoalCrudBlockingItems } = { getGoalCrudBlockingItems: [] },
  } = useGetGoalCrudBlockingItems({
    variables: { GoalId: goalID, Status: status },
    fetchPolicy: "network-only",
  });
  const [active, setActive] = useState({
    index: "0",
    open: true,
  });
  const { t } = useTranslation();
  if (error) {
    toaster({
      message: "something went wrong in fetching",
      type: "error",
    });
    return <></>;
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        centered
        className="ruleset-dependency-modal"
        backdropClassName="ruleset-dependency-modal-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div> {t("COMMON.LABELS.DEPENDENT_ITEMS")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dependency-modal">
          <div className="p-4 h-50">
            <div className="fs-14 d-flex">
              {t("MANAGED_GOALS.MESSAGES.ALL_ITEMS_ASSOCIATED_WITH_GOAL")}{" "}
              <div className="px-1 semi-bold">
                <Truncate id="goal-id" maxWidth="100px" text={name} />
              </div>{" "}
              {t("COMMON.LABELS.ARE_LISTED_BELOW")}
            </div>
            <Accordion defaultActiveKey="0">
              {loading ? (
                <>
                  <Shimmer width="100%" height="40px" className="my-2" />
                  <Shimmer width="100%" height="40px" className="my-2" />
                </>
              ) : (
                <>
                  {getGoalCrudBlockingItems?.response?.Incentives?.length >
                    0 && (
                    <DependencyAccordion
                      data={getGoalCrudBlockingItems?.response?.Incentives}
                      title={t("INCENTIVES.COMMON.LABELS.INCENTIVES_PROGRAMS")}
                      index="0"
                      setActive={setActive}
                      active={active}
                      contentTitle={t(
                        "INCENTIVES.COMMON.LABELS.INCENTIVES_NAMES"
                      )}
                    />
                  )}
                  {getGoalCrudBlockingItems?.response?.Leaderboard?.length >
                    0 && (
                    <DependencyAccordion
                      data={getGoalCrudBlockingItems?.response?.Leaderboard}
                      title={t("LEADERBOARD.LABELS.LEADERBOARDS")}
                      index="1"
                      setActive={setActive}
                      active={active}
                      contentTitle={t(
                        "LEADERBOARD.PAYOUT.LABELS.LEADERBOARD_NAMES"
                      )}
                    />
                  )}
                </>
              )}
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex-between w-100">
            <FormBadge
              className="bg-secondary-blue fs-12 fc-blue semi-bold py-1 mb-1"
              id="template-dependency-list"
            >
              <div className="fs-12 fc-grey normal">
                {t("COMMON.LABELS.TOTAL_DEPENDENT_ITEMS")}:{" "}
                <span className="fc-black semi-bold">
                  {getGoalCrudBlockingItems?.response?.TotalCount}
                </span>
              </div>
            </FormBadge>
            <FormButton
              id="close-btn"
              variant="primary"
              onClick={onHide}
              label={t("COMMON.BUTTONS.CLOSE")}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GoalDependencyModal;
