import { t } from "i18next";
import React, { useState } from "react";
import { ConfirmationModal, CreateInput } from "src/components/common";
import { CREATE_PROGRAM_REGEX } from "src/constants";

const NameFilterModal = ({ handleSave, onClose, show, selectedFilter }) => {
  const [name, setName] = useState("");
  const [warning, setWarning] = useState(false);
  return (
    <ConfirmationModal
      title={t("COMMON.LABELS.SAVE_AS_QUICK_FILTER")}
      body={
        <CreateInput
          value={name}
          setValue={setName}
          label={t("COMMON.LABELS.FILTER_NAME")}
          placeholder={t("COMMON.LABELS.FILTER_NAME")}
          regex={CREATE_PROGRAM_REGEX}
          warning={warning}
          setWarning={setWarning}
        />
      }
      confirmButtonText={t("COMMON.BUTTONS.UPDATE")}
      show={show}
      onClose={onClose}
      onConfirm={() => {
        handleSave({ filter: { ...selectedFilter, FilterName: name } });
        onClose();
      }}
      cancelButton
      confirmButtonDisabled={!name || warning}
      showConfirmButtonToolTip={false}
      dialogClassName="modal-filter-input"
      addButtonsInfooter
    />
  );
};

export default NameFilterModal;
