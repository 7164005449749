export const FIXED_USER_ATTRIBUTES = [
  "FirstName",
  "LastName",
  "UserID",
  "StatusCode",
];

export const MOBILE_FIXED_USER_ATTRIBUTES = [
  ...FIXED_USER_ATTRIBUTES,
  "EmailAddress",
];

export const FIXED_USER_ATTRIBUTES_WITHOUT_STATUS_AND_USERID = [
  "FirstName",
  "LastName",
];

export const MANAGING_GOALS_ATTRIBUTES = [
  ...FIXED_USER_ATTRIBUTES,
  "EmailAddress",
];

export const DEFAULT_USER_ATTRIBUTES = [
  {
    DisplayName: "Email Address",
    SchemaName: "EmailAddress",
  },
  {
    DisplayName: "Designation",
    SchemaName: "Designation",
  },
  {
    DisplayName: "Sales Region",
    SchemaName: "SalesRegions",
  },
];

export const DEFAULT_LEADERBOARD_ATTRIBUTES = [
  {
    DisplayName: "Email Address",
    SchemaName: "EmailAddress",
  },
  {
    DisplayName: "Designation",
    SchemaName: "Designation",
  },
  {
    DisplayName: "Sales Region",
    SchemaName: "SalesRegions",
  },
  {
    DisplayName: "City",
    SchemaName: "City",
  },
];
export const USER_ATTRIBUTES = [
  ...FIXED_USER_ATTRIBUTES,
  ...DEFAULT_USER_ATTRIBUTES.map(({ SchemaName }) => SchemaName),
];

export const LEADERBOARD_USER_ATTRIBUTES = [
  ...FIXED_USER_ATTRIBUTES,
  ...DEFAULT_LEADERBOARD_ATTRIBUTES.map(({ SchemaName }) => SchemaName),
];

export const ATTRIBUTES_FORMATTERS = {
  CreatedOn: ({ value }) => <>{value?.split("T")[0]}</>,
};
export const MOBILE_DEFAULT_USER_ATTRIBUTES = [
  {
    DisplayName: "Email Address",
    SchemaName: "EmailAddress",
  },
  {
    DisplayName: "Designation",
    SchemaName: "Designation",
  },
  {
    DisplayName: "Department",
    SchemaName: "Department",
  },
  {
    DisplayName: "Sales Region",
    SchemaName: "SalesRegions",
  },
  {
    DisplayName: "City",
    SchemaName: "City",
  },
];

export const EXCLUDE_USER_ATTRIBUTES = ["EmailAddress"];

export const EXCLUDE_DRILLDOWN_ATTRIBUTES = [
  "EmailAddress",
  "FirstName",
  "LastName",
];

export const STANDARD_RULESET_DRILLDOWN_ATTRIBUTES = [
  "ifActivity",
  "ifOpportunity",
];

export const DRILLDOWN_ATTRIBUTES = [
  {
    SchemaName: "ruleName",
    DisplayName: "Rule Name",
  },
  {
    SchemaName: "ifActivity",
    DisplayName: "if Activity",
  },
  {
    SchemaName: "ifOpportunity",
    DisplayName: "if Opportunity",
  },
];

export const DEFAULT_DRILLDOWN_ATTRIBUTES = [
  ...DRILLDOWN_ATTRIBUTES,
  {
    DisplayName: "Designation",
    SchemaName: "Designation",
  },
  {
    DisplayName: "Sales Region",
    SchemaName: "SalesRegions",
  },
];
