import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const SAVE_LEADERBOARD_GROUP_RULE = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation saveLeaderboardGroupRule(
    $LeaderboardId: ID!
    $RecurrenceId: ID!
    $GroupId: ID
    $Rule: JSON!
  ) {
    saveLeaderboardGroupRule(
      Rule: $Rule
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
      GroupId: $GroupId
    ) {
      ... on AddGroupRuleResponse {
        message
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useSaveLeaderboardGroupRule = () => {
  return useMutation(SAVE_LEADERBOARD_GROUP_RULE);
};
