import { gql, useMutation } from "src/api/client";

const PAYOUT_SINGLE_USER_AMOUNT_MUTATION = gql`
  mutation payoutSingleUser(
    $userId: ID
    $programId: ID
    $recurrenceId: ID
    $payout: [payoutUserInput]
    $remarks: String
    $fileName: FileName
    $fileId: Float
    $moveToApproval: Boolean
  ) {
    payoutSingleUser(
      userId: $userId
      programId: $programId
      recurrenceId: $recurrenceId
      payout: $payout
      remarks: $remarks
      fileName: $fileName
      fileId: $fileId
      moveToApproval: $moveToApproval
    ) {
      ... on payoutSingleUserResponse {
        success
        message
        response
        __typename
      }
      __typename
    }
  }
`;

export const usePayoutSingleUser = () => {
  return useMutation(PAYOUT_SINGLE_USER_AMOUNT_MUTATION);
};
