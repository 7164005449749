import React from "react";

const SvgInactiveUser = (props) => {
  const { height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill="#788AA0"
        d="M6.929 15.006H5.365v-.007H1.571A1.071 1.071 0 0 1 .5 13.928v-1.072A4.274 4.274 0 0 1 3.77 8.69l.624 1.248a3.815 3.815 0 0 0-2.078 3.515h3.221c.36.601.833 1.126 1.394 1.546l-.002.007ZM3.603 6.593a4.136 4.136 0 0 1-.24-4.556 4.136 4.136 0 0 1 7.546.988c.217.782.2 1.61-.05 2.382a5.379 5.379 0 0 0-1.5.007 2.755 2.755 0 1 0-5.168-.898c.03.208.155.604.155.604s-.632.773-.743 1.473Z"
      />
      <path
        fill="#788AA0"
        stroke="#788AA0"
        strokeWidth={0.536}
        d="M5.36 5.905a.402.402 0 0 0-.039.524l.04.045 1.704 1.73a5.35 5.35 0 0 0-2.12 3.08.402.402 0 0 0 .78.196 4.548 4.548 0 0 1 1.918-2.697l.97.97a2.143 2.143 0 0 0 3.03 3.03L13.893 15a.402.402 0 0 0 .607-.523l-.04-.045-2.353-2.322-.642-.642L9.927 9.93h.001L8.385 8.387l-.607-.606-1.85-1.877a.402.402 0 0 0-.568 0Zm3.82 4.416 1.895 1.894A1.34 1.34 0 0 1 9.18 10.32Zm.963-3.089c-.536 0-1.056.08-1.548.228l.663.662a4.555 4.555 0 0 1 5.303 3.36.402.402 0 0 0 .78-.195 5.359 5.359 0 0 0-5.198-4.055Zm.104 1.88 2.037 2.036a2.144 2.144 0 0 0-2.037-2.036Z"
      />
    </svg>
  );
};
SvgInactiveUser.defaultProps = {
  height: 16,
  width: 16,
};
export default SvgInactiveUser;
