import React from "react";
import { classNames } from "src/helpers";
import "./rulesetCircularProgressBar.scss";

const RulesetCircularProgressBar = ({ percentage }) => {
  const percentageValue = Math.max(Math.min(percentage, 100), 0);

  return (
    <div
      style={{
        "--percentage": percentageValue,
      }}
      className="pie-wrapper"
    >
      <div
        className={classNames([
          "pie",
          percentageValue <= 50 ? "less-than-half" : "more-than-half",
          percentageValue >= 100 ? "full" : "",
        ])}
      >
        <div className="left-side half-circle" />
        <div className="right-side half-circle" />
      </div>
      <div className="shadow" />
    </div>
  );
};

export default RulesetCircularProgressBar;
