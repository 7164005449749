import { differenceInCalendarDays } from "src/helpers";

export const MAX_EACH_CYCLE_DURATION = 365;
export const MIN_EACH_CYCLE_DURATION = 1;
export const MAX_GAP_DURATION = 200;
export const MIN_GAP_DURATION = 0;
export const MAX_CYCLE_DAYS = 700;
export const YEAR_OPTIONS_COUNT = 30;
export const MIN_DATE = new Date(2022, 0, 1);
export const MAX_DATE = new Date(2050, 11, 31);
export const DEFAULT_BUFFER_DAYS = 0;
export const MIN_BUFFER_DAYS = 0;
export const CYCLE_BUFFER_DAYS = {
  Daily: {
    MIN_BUFFER_DAYS,
    MAX_BUFFER_DAYS: () => 30,
  },
  Weekly: {
    MIN_BUFFER_DAYS,
    MAX_BUFFER_DAYS: () => 30,
  },
  Biweekly: {
    MIN_BUFFER_DAYS,
    MAX_BUFFER_DAYS: () => 30,
  },
  Monthly: {
    MIN_BUFFER_DAYS,
    MAX_BUFFER_DAYS: () => 90,
  },
  Quarterly: {
    MIN_BUFFER_DAYS,
    MAX_BUFFER_DAYS: () => 90,
  },
  Yearly: {
    MIN_BUFFER_DAYS,
    MAX_BUFFER_DAYS: () => 90,
  },
  Custom: {
    MIN_BUFFER_DAYS,
    MAX_BUFFER_DAYS: ({ startDate, endDate }) =>
      differenceInCalendarDays(endDate, startDate) >= 15 ? 90 : 30,
  },
  Infinite: {
    MIN_BUFFER_DAYS,
    MAX_BUFFER_DAYS: 90,
  },
};
export const RECURRENCE_PERIOD = [
  "Daily",
  "Weekly",
  "Biweekly",
  "Monthly",
  "Quarterly",
  "Yearly",
];
export const RECURRENCE_PERIOD_CAPS = [
  "DAILY",
  "WEEKLY",
  "BIWEEKLY",
  "MONTHLY",
  "QUARTERLY",
  "YEARLY",
];
export const RECURRENCE_PERIOD_CAPS_WITH_CUSTOM = [
  "DAILY",
  "WEEKLY",
  "BIWEEKLY",
  "MONTHLY",
  "QUARTERLY",
  "YEARLY",
  "UNIFORM",
  "NONUNIFORM",
];
export const MAX_CYCLES = {
  Daily: 700,
  Biweekly: 52,
  Weekly: 104,
  Monthly: 24,
  Quarterly: 10,
  Yearly: 16,
};
export const MONTH_OPTIONS = [
  { value: 0, label: "January" },
  { value: 1, label: "February" },
  { value: 2, label: "March" },
  { value: 3, label: "April" },
  { value: 4, label: "May" },
  { value: 5, label: "June" },
  { value: 6, label: "July" },
  { value: 7, label: "August" },
  { value: 8, label: "September" },
  { value: 9, label: "October" },
  { value: 10, label: "November" },
  { value: 11, label: "December" },
];
