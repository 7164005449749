import { gql, useQuery } from "src/api/client";

const GET_IF_CUSTOM_REWARD_NAME_EXISTS = gql`
  query customRewardNameExists($RewardName: String!) {
    customRewardNameExists(RewardName: $RewardName) {
      ... on CustomRewardNameExistsResponse {
        success
        message
        response
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useCustomRewardNameExists = (queryOptions) => {
  return useQuery(GET_IF_CUSTOM_REWARD_NAME_EXISTS, queryOptions);
};
