import { APPS, ROLES, USER_ROLES } from "src/constants";
import { useRoleBasedContext } from "src/context";
import { useViewport } from "src/hooks";

const useGoalsRbac = () => {
  const {
    userDetails: { role, isManagingUsers, hasPublishedGoal },
    isFeatureEnabled,
  } = useRoleBasedContext();

  const { isMobile } = useViewport();

  const DEFAULT_REDIRECTION = {
    [USER_ROLES.ADMIN]: isMobile
      ? APPS.GOALS.ROUTES.MANAGED_BY_ME.path
      : APPS.GOALS.ROUTES.RULESETS.path,
    [USER_ROLES.SALES_MANAGER]: APPS.GOALS.ROUTES.MANAGED_BY_ME.path,
    [USER_ROLES.SALES_USER]: APPS.GOALS.ROUTES.ASSIGNED_TO_ME.path,
  };

  const getRole = () => {
    if (role === ROLES.ADMIN) {
      return USER_ROLES.ADMIN;
    }

    if (isManagingUsers || hasPublishedGoal) {
      return USER_ROLES.SALES_MANAGER;
    }

    return USER_ROLES.SALES_USER;
  };

  const ACCESS_ROUTES = {
    [USER_ROLES.SALES_USER]: [
      APPS.GOALS.ROUTES.ASSIGNED_TO_ME.path,
      APPS.GOALS.ROUTES.ASSIGNED_GOAL_VIEW.path,
    ],
    [USER_ROLES.SALES_MANAGER]: [
      APPS.GOALS.ROUTES.ASSIGNED_TO_ME.path,
      APPS.GOALS.ROUTES.ASSIGNED_GOAL_VIEW.path,
      APPS.GOALS.ROUTES.MANAGED_BY_ME.path,
      APPS.GOALS.ROUTES.GOAL_CRUD.path,
      APPS.GOALS.ROUTES.PUBLISHED_GOAL_VIEW.path,
    ],
    [USER_ROLES.ADMIN]: [
      APPS.GOALS.ROUTES.ASSIGNED_TO_ME.path,
      APPS.GOALS.ROUTES.ASSIGNED_GOAL_VIEW.path,
      APPS.GOALS.ROUTES.MANAGED_BY_ME.path,
      APPS.GOALS.ROUTES.GOAL_CRUD.path,
      APPS.GOALS.ROUTES.PUBLISHED_GOAL_VIEW.path,
      APPS.GOALS.ROUTES.RULESETS.path,
    ],
  };

  const goalsRbac = (route) => {
    switch (route) {
      case APPS.GOALS.ROUTES.ROOT.path:
      case APPS.GOALS.ROUTES.LANDING.path: {
        return {
          hasAccess: isFeatureEnabled.Goals,
          redirect: APPS.ACE.ROUTES.ROOT.path,
        };
      }

      default: {
        return {
          hasAccess: ACCESS_ROUTES[getRole()].includes(route),
          redirect: DEFAULT_REDIRECTION[getRole()],
        };
      }
    }
  };

  return {
    goalsRbac,
    DEFAULT_LOGGED_IN_USER_REDIRECTION: DEFAULT_REDIRECTION[getRole()],
  };
};

export default useGoalsRbac;
