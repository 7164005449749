import React, { useRef } from "react";
import { FormButton } from "src/components";
import { Menu, RotatingWords, useMenu } from "src/components/common/atoms";
import { COLORS } from "src/constants";
import { classNames, isKeyEventBackspace, isKeyEventEnter } from "src/helpers";
import { SvgClose } from "src/icons";
import "./customFormulaInput.scss";
import useTagSelector from "./useTagSelector";

const defaultChip = ({ tags, removeTag }) => {
  return tags.map((tag, idx) => {
    return (
      <span className="badge badge-secondary center">
        <span className="fs-14 mr-2">{tag}</span>
        <FormButton
          className="cursor-pointer"
          onClick={() => removeTag(idx)}
          variant="normal-link"
        >
          <SvgClose color={COLORS.WHITE} />
        </FormButton>
      </span>
    );
  });
};

const CustomFormulaInput = (props) => {
  const {
    customPlaceholder,
    customMenu,
    customDisplayTag,
    formulaArray = [],
  } = props;
  const menu = useMenu();
  const inputRef = useRef();
  const tagSelector = useTagSelector({
    tags: formulaArray,
  });

  const { tags, addTagAtEnd, removeTagAtEnd } = tagSelector;

  const Placeholder = () => (
    <div className="fc-grey1 center">
      <span className="mr-2">Type</span>
      <RotatingWords
        words={[
          "rule name",
          "mathematical operators",
          "parenthesis",
          "numbers",
        ]}
      />
    </div>
  );

  const onChange = (e) => {
    inputRef.current.style.width = `${10 + e.target.value.length}ch`;
    inputRef.current.style.height = "30px";
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
  };

  const onKeyDown = (e) => {
    if (isKeyEventEnter(e)) {
      e.preventDefault();
      if (e.target.value !== "") {
        addTagAtEnd(e.target.value);
        e.target.value = "";
        inputRef.current.style.width = "0ch";
        inputRef.current.style.height = "30px";
        menu.handleClose();
      }
    }
    if (isKeyEventBackspace(e) && e.target.value === "" && tags.length > 0) {
      e.target.value = tags[tags.length - 1];
      removeTagAtEnd();
      menu.handleClose();
    }
    if (e.target.value.length > 0) {
      menu.handleClick(e);
    }
  };
  return (
    <>
      <div
        ref={menu.containerRef}
        className={classNames([
          "p-2 br-4 formula-text-area center flex-wrap",
          menu.el ? "show" : "",
        ])}
        onClick={() => inputRef.current.focus()}
      >
        {/* Display Chip */}
        {tags.length > 0
          ? customDisplayTag
            ? customDisplayTag({ ...tagSelector })
            : defaultChip({ ...tagSelector })
          : null}
        {/* Chip Input */}
        <input
          ref={inputRef}
          onFocus={menu.handleClick}
          className="formula-input border-0"
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
        {/*  Chip Placeholder */}
        {!menu.el &&
          (inputRef.current ? inputRef.current?.value.length === 0 : true) &&
          tags.length === 0 && (
            <div className="placeholder">
              {customPlaceholder || <Placeholder />}
            </div>
          )}
      </div>
      {/* Custom Menu */}
      {customMenu && (
        <Menu el={menu.el} container={menu.containerRef.current}>
          {customMenu({
            menu,
            inputRef,
            tagSelector,
          })}
        </Menu>
      )}
    </>
  );
};

export default CustomFormulaInput;
