import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormButton, FormTooltip } from "src/components/common/formElements";
import { DATE_ENTITY_THIS_CYCLE, DATE_FORMAT } from "src/constants";
import { format } from "src/helpers";
import { SvgMinus, SvgPlus } from "src/icons";
import "./conditionViewer.scss";

const ConditionViewer = (props) => {
  const {
    conditions,
    removeCondition,
    addCondition,
    hideButtons,
    drawerOpen,
    showDisabled,
    setGoalRuleSummary,
  } = props;
  const { t } = useTranslation();
  const formatDate = (date) => format(date, DATE_FORMAT);
  const getSubEntity = (condition) => {
    return condition.subEntity ? condition.subEntity.DisplayName : "";
  };

  const getSubEntityOperator = (condition) => {
    return condition.subEntityOperator ? condition.subEntityOperator.label : "";
  };

  const getSubEntityValue = (condition) => {
    return condition.subEntityValue ? condition.subEntityValue.DisplayName : "";
  };

  const getField = (condition) => {
    return condition.field ? condition.field.DisplayName : "";
  };

  const getOperator = (condition) => {
    return condition.operator ? condition.operator.label : "";
  };

  const getValue = (condition) => {
    if (condition.value && condition.value.Value) {
      if (condition.value.Value === DATE_ENTITY_THIS_CYCLE)
        return condition.value.label;
      return condition.value.Value;
    }
    if (condition.value && condition.value.from && condition.value.to) {
      return `${condition.value.from} to ${condition.value.to}`;
    }
    if (
      condition.value &&
      condition.value.startDate &&
      condition.value.endDate
    ) {
      return `${formatDate(
        new Date(condition.value.startDate)
      )} to ${formatDate(new Date(condition.value.endDate))}`;
    }
    if (condition.value) {
      return condition.value;
    }
    return "";
  };

  const getSubValue = (condition) => {
    if (
      condition.subValue &&
      condition.subValue.from &&
      condition.subValue.to
    ) {
      return `${condition.subValue.from} & ${condition.subValue.to}`;
    }
    if (
      condition.subValue &&
      condition.subValue.startDate &&
      condition.subValue.endDate
    ) {
      return `${formatDate(
        new Date(condition.subValue.startDate)
      )} & ${formatDate(new Date(condition.subValue.endDate))}`;
    }
    if (condition.subValue && condition.value.field === "DatePicker") {
      return `${formatDate(new Date(condition.subValue))}`;
    }
    if (condition.subValue) {
      return `${condition.subValue}`;
    }
    return "";
  };

  const getEmptyCondition = (condition) => {
    if (condition.field === undefined) {
      return t("RULEBOOK.LABELS.CONDITION_ADD_HERE");
    }
  };

  const getConditionDefinition = (condition, index) => {
    if (condition) {
      return (
        <div key={index} className="flex-between">
          <div className="condition-number primary-text-grey fs-16 mt-2 py-2">
            <p className="text-center fs-13">{index + 1}</p>
          </div>
          <div
            className={`fs-12 mt-2 p-2 primary-text-grey w-100 ${
              condition.field
                ? "condition-definition"
                : "empty-condition-definition"
            }`}
          >
            <b>{getSubEntity(condition)}</b> {getSubEntityOperator(condition)}{" "}
            <b className="fc-black long-text-break">
              {getSubEntityValue(condition)}{" "}
            </b>
            <b>{`${getField(condition)}`} </b>
            {getOperator(condition)}{" "}
            <b className="fc-black long-text-break">{getValue(condition)} </b>
            <b className="fc-black long-text-break">{getSubValue(condition)}</b>
            {getEmptyCondition(condition)}
          </div>

          {hideButtons || !condition.field || drawerOpen ? null : (
            <div className="position-relative mt-2 px-3 center">
              <FormButton
                variant="outline-primary"
                id={`condition-minus-${index}`}
                className="condition-btn br-2"
                onClick={(e) => removeCondition(condition)}
                icon={<SvgMinus />}
              />
              <FormTooltip
                id="condition-viewer-tooltip"
                placement="bottom"
                text={t("RULEBOOK.MESSAGES.30_CONDITIONS_TO_RULE")}
                disable={!showDisabled}
              >
                <FormButton
                  variant="outline-primary"
                  id={`condition-plus-${index}`}
                  className={`ml-2 condition-btn br-2 ${
                    showDisabled ? "disabled" : ""
                  }`}
                  icon={<SvgPlus />}
                  onClick={(e) => {
                    if (!showDisabled) {
                      addCondition(index);
                      setGoalRuleSummary(false);
                    }
                  }}
                />
              </FormTooltip>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      {conditions.length > 0 &&
        conditions.map((condition, index) =>
          getConditionDefinition(condition, index)
        )}
    </>
  );
};
ConditionViewer.propTypes = {
  conditions: PropTypes.array,
  removeCondition: PropTypes.func,
  addCondition: PropTypes.func,
  hideButtons: PropTypes.bool,
  drawerOpen: PropTypes.bool,
  showDisabled: PropTypes.bool,
  setGoalRuleSummary: PropTypes.func,
};

ConditionViewer.defaultProps = {
  conditions: [],
  removeCondition: () => {},
  addCondition: () => {},
  hideButtons: false,
  drawerOpen: false,
  showDisabled: true,
};

export default ConditionViewer;
