import React from "react";
import { Image } from "src/components";
import HeroHousingFinancePng from "./HeroHousingFinance.png";

const HeroHousingFinance = (props) => (
  <Image
    src={HeroHousingFinancePng}
    alt="AchievementCardBackgroundImg"
    {...props}
  />
);
export default HeroHousingFinance;
