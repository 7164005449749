import SearchBar from "src/components/common/atoms/searchBar/SearchBar";
import { SvgSearchThin } from "src/icons";

export const REWARDS_FLAT_LIST_FILTERS = [
  {
    label: "Search",
    key: "search",
    defaultValue: "",
    icon: SvgSearchThin,
    component: SearchBar,
    props: ({ filter, filtersState, handleChange }) => ({
      className: "mt-3",
      placeholder: "Search Users",
      search: filtersState[filter.key],
      setSearch: (value) => handleChange({ key: filter.key, value }),
    }),
  },
];
