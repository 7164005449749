import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgConfigureColumn = (props) => {
  const { color = COLORS.GREY_2, height = 20, width = 20, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#xljv__a)">
        <path
          fill={color}
          fillRule="evenodd"
          d="M3.333 18.528a.75.75 0 0 1-.75-.75V2.222a.75.75 0 0 1 .75-.75H16.666a.75.75 0 0 1 .75.75v15.556a.75.75 0 0 1-.75.75H3.333Zm8.139-15.556v14.056H8.528V2.972h2.944Zm1.5 14.056h2.944V2.972h-2.944v14.056ZM4.083 2.972h2.945v14.056H4.083V2.972Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="xljv__a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgConfigureColumn;
