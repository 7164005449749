import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgDownloadCurrentCycleExcel = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      xmlSpace="preserve"
      width={width}
      height={height}
      style={{
        enableBackground: "new 0 0 16 16",
      }}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.91.25v4.76h4.7"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M11.1 15.64H.93V.25H8.91l4.7 4.7V8"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M8.6 12.18H3.76V7.4h7.03v.78M6.12 7.4v4.78M3.76 9.86H8.6M15.74 11.31l-.95-.05c-.05-.19-.12-.36-.22-.53l.63-.7c.03-.03.03-.08 0-.11l-.66-.66a.078.078 0 0 0-.11 0l-.7.63c-.17-.1-.35-.17-.53-.22l-.05-.94c0-.04-.04-.08-.08-.08h-.94c-.04 0-.08.03-.08.08l-.05.94c-.19.05-.36.12-.53.22l-.7-.63a.078.078 0 0 0-.11 0l-.66.66c-.03.03-.03.08 0 .11l.63.7c-.1.17-.17.35-.22.53l-.95.05c-.04 0-.07.04-.07.08v.94c0 .04.03.08.07.08l.95.05c.05.19.12.36.22.53l-.63.7c-.03.03-.03.08 0 .11l.66.66c.03.03.08.03.11 0l.7-.63c.17.1.35.17.53.22l.05.94c0 .04.04.08.08.08h.94c.04 0 .08-.03.08-.08l.05-.94c.19-.05.36-.12.53-.22l.7.63c.03.03.08.03.11 0l.66-.66c.03-.03.03-.08 0-.11l-.63-.7c.1-.17.17-.35.22-.53l.95-.05c.04 0 .07-.04.07-.08v-.94c.01-.04-.02-.08-.07-.08h0z"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <circle
        cx={12.61}
        cy={11.86}
        r={1.18}
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M4.86 2v3.09M6.12 3.84 4.86 5.09 3.6 3.84"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 0.307,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  );
};
SvgDownloadCurrentCycleExcel.defaultProps = {
  color: COLORS.GREY_2,
  height: 24,
  width: 24,
};
export default SvgDownloadCurrentCycleExcel;
