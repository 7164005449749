import { LazyLoad } from "../lazyLoad";
import "./infiniteLoad.scss";

const Loading = () => {
  return (
    <div className="loading">
      <div className="pulse pulse-1" />
      <div className="pulse pulse-2" />
      <div className="pulse pulse-3" />
    </div>
  );
};

const InfiniteLoad = ({
  container,
  loading,
  handleLoadMore,
  horizontal,
  topOffset,
  bottomOffset,
  loadingComponent = false,
}) => {
  return (
    <div className="infinite-load">
      <LazyLoad
        container={container}
        onEnter={handleLoadMore}
        horizontal={horizontal}
        topOffset={topOffset}
        bottomOffset={bottomOffset}
      >
        <div>{loading ? loadingComponent || <Loading /> : null}</div>
      </LazyLoad>
    </div>
  );
};

export default InfiniteLoad;
