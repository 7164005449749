import React from "react";
import Container from "./Container";
import Page, { pageDefaultProps, pagePropTypes } from "./Page";
import { ProvideAppShell, useAppShell } from "./useAppShell";

const RenderAppShell = ({ children }) => {
  return <>{children}</>;
};

const AppShell = Object.assign(RenderAppShell, {
  Provider: ProvideAppShell,
  Container,
  Page,
});

export { AppShell, useAppShell, Page, pagePropTypes, pageDefaultProps };
