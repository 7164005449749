import React from "react";
import { Image } from "src/components";
import PublishGoalConfirmationImg from "./PublishGoalConfirmation.svg";

const PublishGoalConfirmation = (props) => (
  <Image
    src={PublishGoalConfirmationImg}
    alt="PublishGoalConfirmationImg"
    {...props}
  />
);

export default PublishGoalConfirmation;
