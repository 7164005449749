import React from "react";
import { Image } from "src/components";
import AchievementCardBackgroundImg from "./AchievementCardBackground.png";

const AchievementCardBackground = (props) => (
  <Image
    src={AchievementCardBackgroundImg}
    alt="AchievementCardBackgroundImg"
    {...props}
  />
);

export default AchievementCardBackground;
