import { DRILLDOWN_ATTRIBUTES } from "src/config/userAttributes";
import { PAGINATION_LIMIT } from "src/constants";

const DRILLDOWN_API_NAMES = {
  GOALS: "fetchGoalDrilldown",
  INCENTIVES: "fetchProgramDrilldown",
  LEADERBOARDS: "fetchLeaderboardDrilldown",
};

export const getApiName = ({ isIncentive, isLeaderboard }) => {
  if (isIncentive) return DRILLDOWN_API_NAMES.INCENTIVES;
  if (isLeaderboard) return DRILLDOWN_API_NAMES.LEADERBOARDS;
  return DRILLDOWN_API_NAMES.GOALS;
};

const filterFieldsOrder = (item) =>
  !DRILLDOWN_ATTRIBUTES.map(({ SchemaName }) => SchemaName).includes(item);

export const getVariables = ({
  isIncentive,
  TemplateId,
  Recurrence,
  User,
  Rule,
  Search,
  FieldsOrder,
  After,
  managerUserId,
  ProgramId,
}) => ({
  ...(managerUserId !== null && {
    ManagerId: managerUserId,
  }),
  ...(isIncentive && { TemplateId }),
  RecurrenceId: Recurrence?.RecurrenceId,
  UserId: User?.UserID,
  RuleId: Rule?.RuleId,
  ProgramId,
  After,
  Limit: PAGINATION_LIMIT,
  Search,
  UserAttributesNames: ["EmailAddress", ...FieldsOrder].filter(
    filterFieldsOrder
  ),
  IndividualDrilldown: User?.IndividualDrilldown,
});
