import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgSort = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <g>
        <path fill="none" d="M0 0h18v18H0z" />
        <g
          fill={color}
          stroke={color}
          strokeWidth={0.5}
          style={{
            isolation: "isolate",
          }}
        >
          <path d="M5.839 15.722A.762.762 0 0 1 5.25 16a.731.731 0 0 1-.527-.22l-3-3.008a.75.75 0 0 1 1.059-1.061L4.5 13.444V3.75a.75.75 0 0 1 1.5 0v9.684l1.722-1.718a.75.75 0 1 1 1.059 1.061ZM13.339 2.278A.762.762 0 0 0 12.75 2a.731.731 0 0 0-.527.22l-3 3.008a.749.749 0 1 0 1.055 1.061L12 4.556v9.694a.75.75 0 1 0 1.5 0V4.566l1.722 1.718a.75.75 0 0 0 1.059-1.061Z" />
        </g>
      </g>
    </svg>
  );
};

SvgSort.defaultProps = {
  color: COLORS.GREY_2,
  height: 18,
  width: 18,
};
export default SvgSort;
