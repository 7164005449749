import React from "react";
import { Image } from "src/components";
import customFormulaExamples from "./CustomFormulaExamples.png";

const CustomFormulaExamples = (props) => (
  <Image
    src={customFormulaExamples}
    alt="Custom formula examples."
    {...props}
  />
);

export default CustomFormulaExamples;
