import React from "react";

const SvgUserUnavailable = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 8716">
        <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 3695" />
        <g data-name="Group 8715">
          <path
            fill={color}
            d="M12.092 21.991h-9.1a.994.994 0 0 1-1-.982v-2.072a5.118 5.118 0 0 1 5.117-5.105h3.641a7.04 7.04 0 0 0-.656 2H7.11a3.118 3.118 0 0 0-3.121 3.107v1.057h6.68a6.972 6.972 0 0 0 1.418 2Zm-.879-9.771a5.111 5.111 0 0 1-5.105-5.107 5.117 5.117 0 0 1 5.105-5.117 5.125 5.125 0 0 1 5.117 5.117 5.062 5.062 0 0 1-1.055 3.1 6.982 6.982 0 0 0-3.348 1.955 5.09 5.09 0 0 1-.718.048Zm0-8.215a3.111 3.111 0 0 0-3.109 3.107 3.111 3.111 0 0 0 3.109 3.107 3.115 3.115 0 0 0 3.117-3.107 3.115 3.115 0 0 0-3.121-3.108Z"
            data-name="Subtraction 41"
          />
          <path
            fill={color}
            d="M16.992 22.005a5.011 5.011 0 0 1-5.006-5 5.012 5.012 0 0 1 5.006-5.006 5.011 5.011 0 0 1 5 5.006 5.011 5.011 0 0 1-5 5Zm-2-6.006v2h4v-2Z"
            data-name="Subtraction 44"
          />
        </g>
      </g>
    </svg>
  );
};

SvgUserUnavailable.defaultProps = {
  color: "#b6c0cc",
  height: 24,
  width: 24,
};
export default SvgUserUnavailable;
