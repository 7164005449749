import React from "react";

const SvgSortBoth = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="najdkajk_3">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#najdkajk_3)">
        <circle
          cx={8}
          cy={8}
          r={8}
          fill={color}
          data-name="Ellipse 157"
          opacity={0.01}
        />
        <g
          data-name="Group 7445"
          style={{
            isolation: "isolate",
          }}
        >
          <g fill={color} data-name="Group 7446">
            <path d="m4.75 7.25 3.26-4 3.24 4Z" data-name="Path 4482" />
            <path d="m4.75 8.75 3.26 4 3.24-4Z" data-name="Path 4483" />
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgSortBoth.defaultProps = {
  color: "#a0aec0",
  height: 16,
  width: 16,
};
export default SvgSortBoth;
