import { useState } from "react";
import { useTranslation } from "react-i18next";
import { REGEX_FOR_NUMBER_UPTO_TWO_DECIMAL_PLACES_ONLY } from "src/constants";

const usePendingPayout = (payoutSchedule) => {
  const initialState = {};
  const { t } = useTranslation();

  payoutSchedule.forEach((payout) => {
    if (payout?.AvailableForPayout) {
      initialState[payout.RecurrenceId] = {
        payoutAmount: { value: "", error: "" },
        referenceId: { value: "", error: "" },
      };
    }
  });
  const [scheduleForm, setScheduleForm] = useState(initialState);

  const handleAmountChange = (e, recurrenceId) => {
    const value = e.target.value;
    const updatedForm = structuredClone(scheduleForm);
    if (
      !value ||
      (value > -1 &&
        value.toString().match(REGEX_FOR_NUMBER_UPTO_TWO_DECIMAL_PLACES_ONLY))
    ) {
      // update the value for the respective recurrenceId and reset the error for all the other fields
      setScheduleForm(
        Object.keys(updatedForm).reduce((acc, key) => {
          if (key === recurrenceId) {
            return {
              ...acc,
              [key]: {
                ...acc[key],
                payoutAmount: { value, error: "" },
                referenceId: { value: acc[key].referenceId.value, error: "" },
              },
            };
          }
          return {
            ...acc,
            [key]: {
              ...acc[key],
              payoutAmount: { value: acc[key].payoutAmount.value, error: "" },
              referenceId: { value: acc[key].referenceId.value, error: "" },
            },
          };
        }, updatedForm)
      );
    }
  };

  const handleReferenceIdChange = (e, recurrenceId) => {
    const value = e.target.value;
    const updatedForm = structuredClone(scheduleForm);

    if (value.length <= 30) {
      setScheduleForm(
        Object.keys(updatedForm).reduce((acc, key) => {
          if (key === recurrenceId) {
            return {
              ...acc,
              [key]: {
                ...acc[key],
                referenceId: { value, error: "" },
              },
            };
          }
          return {
            ...acc,
            [key]: {
              ...acc[key],
              referenceId: { value: acc[key].referenceId.value, error: "" },
            },
          };
        }, updatedForm)
      );
    }
  };

  const isValidAmount = (value, eligibleIncentive) => {
    return (
      !Number.isNaN(value) && Number(value) !== 0 && value <= eligibleIncentive
    );
  };

  const getMonetaryErrorMessage = (payoutAmount, eligibleIncentive) => {
    if (
      (Number.isNaN(payoutAmount) || payoutAmount === "") &&
      eligibleIncentive > 0
    ) {
      return t("INCENTIVES.PAYOUT.ERRORS.ERROR_2");
    }
    if (Number(payoutAmount) === 0 && eligibleIncentive > 0) {
      return t("INCENTIVES.PAYOUT.ERRORS.ERROR_3");
    }
    if (payoutAmount > eligibleIncentive) {
      return t("INCENTIVES.PAYOUT.ERRORS.ERROR_4");
    }
    return "";
  };

  const validateMonetaryPayout = () => {
    const updatedForm = { ...scheduleForm };

    const dirtyPayoutRecurrence = Object.entries(updatedForm).reduce(
      (acc, [key, value]) => {
        if (value.payoutAmount.value !== "" || value.referenceId.value !== "") {
          return { ...acc, [key]: value };
        }
        return acc;
      },
      {}
    );

    const newUpdatedForm = Object.keys(dirtyPayoutRecurrence).length
      ? dirtyPayoutRecurrence
      : updatedForm;

    for (const recurrenceId in newUpdatedForm) {
      const eligibleIncentive = payoutSchedule.find(
        (payout) => payout.RecurrenceId === recurrenceId
      ).PendingPayout;

      if (updatedForm[recurrenceId].referenceId.value === "") {
        updatedForm[recurrenceId].referenceId.error = t(
          "INCENTIVES.PAYOUT.ERRORS.ERROR_1"
        );
      }

      if (
        !(
          isValidAmount(
            updatedForm[recurrenceId].payoutAmount.value,
            eligibleIncentive
          ) && updatedForm[recurrenceId].payoutAmount.value !== ""
        )
      ) {
        updatedForm[recurrenceId].payoutAmount.error = getMonetaryErrorMessage(
          updatedForm[recurrenceId].payoutAmount.value,
          eligibleIncentive
        );
      }
    }

    setScheduleForm(updatedForm);
  };

  const isValidMonetaryPayout = () => {
    const updatedForm = { ...scheduleForm };

    const isValid = Object.keys(updatedForm).every((rec) => {
      if (
        updatedForm[rec].payoutAmount.value === "" &&
        updatedForm[rec].referenceId.value === ""
      ) {
        return true;
      }
      const eligibleIncentive = payoutSchedule.find(
        (payout) => payout.RecurrenceId === rec
      )?.PendingPayout;
      return (
        isValidAmount(updatedForm[rec].payoutAmount.value, eligibleIncentive) &&
        updatedForm[rec].payoutAmount.value !== "" &&
        updatedForm[rec].referenceId.value !== ""
      );
    });

    return isValid;
  };

  const isMonetaryPayoutSelected = () => {
    const updatedForm = { ...scheduleForm };

    return (
      Object.keys(updatedForm)?.length &&
      Object.keys(updatedForm).some((rec) => {
        return (
          updatedForm[rec].payoutAmount.value !== "" ||
          updatedForm[rec].referenceId.value !== ""
        );
      })
    );
  };

  return {
    scheduleForm,
    handleAmountChange,
    handleReferenceIdChange,
    validateMonetaryPayout,
    isValidMonetaryPayout,
    isMonetaryPayoutSelected,
  };
};

export default usePendingPayout;
