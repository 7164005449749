import { t } from "i18next";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { components } from "react-select";
import {
  CRITERIA_REWARD,
  INITIAL_INCENTIVE_SUBGOAL_STATE,
  rewardSlab,
} from "src/apps/incentives/data/types";
import {
  filterOption,
  findOptionFromSelectedGoals,
  formatSelectedGoals,
  groupByConditionValid,
} from "src/apps/incentives/utils";
import {
  FormButton,
  FormInput,
  FormTooltip,
  GroupByLabel,
  GroupByModal,
  GroupReactSelect,
  RewardIcon,
  SelectFilter,
  Truncate,
} from "src/components";
import {
  COLORS,
  CONDITION_TYPES_OPTIONS,
  INCENTIVE_PROGRAM_TYPES,
  MINIMUM_INCENTIVE_REWARD_VALUE,
  NON_DUPLICATIVE_REWARDS_CATEGORY_LIST,
  NON_MONETARY_CATEGORIES,
  REWARD_SUBCATEGORIES,
  REWARD_TYPE,
} from "src/constants";
import {
  capitalize,
  checkValue,
  classNames,
  groupBy,
  isEmpty,
} from "src/helpers";
import { SvgGift, SvgUnderlineEdit } from "src/icons";
import { getSubGoalEntityValuePair } from "../formulaBuilder/utils";
import AddRewardSlabs from "./AddRewardSlabs";

const formatTemplateOptionLabel = (option) => {
  return (
    <FormTooltip
      text={t("INCENTIVES.COMMON.MESSAGES.REWARD_TEMPLATE_OPTION_TOOLTIP")}
      disable={!option.disabled}
    >
      {option.Name}
    </FormTooltip>
  );
};

function UpdateOrCreateReward({
  index,
  templates,
  type,
  goals,
  reward,
  onUpdate,
  onClose,
  isSlabTypeReward,
  isValueInMinMaxRange,
  customRewardsList,
  rewardsList,
  selectedRecurrence,
}) {
  const [state, setState] = useState({
    ...CRITERIA_REWARD,
    ...reward,
    ConditionType: groupByConditionValid(reward?.SubGoal)
      ? CONDITION_TYPES_OPTIONS[1]
      : CONDITION_TYPES_OPTIONS[0],
  });
  const rewardTypes = Object.keys(REWARD_TYPE).map((key) => REWARD_TYPE[key]);
  const operation =
    checkValue(reward?.Value) ||
    reward.Slabs.length ||
    reward.RewardType === REWARD_TYPE?.CUSTOM_REWARD?.type
      ? "Edit"
      : "Add";

  const selectedTemplate = templates.find(
    (template) => template.TemplateId === state.TemplateId
  );
  const selectedGoal = goals?.find((goal) => goal.GoalId === state.GoalId);
  const selectedRule = state?.GoalId ? selectedGoal : selectedTemplate;

  const [selectedGoalsGroupByEntities, selectedGoalsEntityValuePair] =
    getSubGoalEntityValuePair(
      selectedRecurrence?.SubGoalFilters?.find(
        (goal) => goal.GoalId === state.GoalId
      )?.SubGoals
    );
  const [showSubGoalModal, setShowSubGoalModal] = useState(false);

  const handleChange = ({ name, value }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      Monetary: true,
      customReward: null,
    }));
  };

  const handleValueChange = (e) => {
    const { value: valueAsNumber } = e.target;
    handleChange({ name: "Value", value: valueAsNumber });
  };

  const handleRewardTypeChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      RewardType: e.value,
      RewardLabel: e.Name,
      TemplateId: null,
      GoalId: null,
      Value: null,
      Slabs: [],
      SubGoal: null,
      ConditionType: CONDITION_TYPES_OPTIONS[0],
    }));
  };
  const handleTemplateChange = ({ TemplateId, value, optionType }) => {
    handleChange({ name: "GoalId", value: null });
    const newState = [];
    if (type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value) {
      if (optionType === "Goal") {
        newState.push({ name: "GoalId", value: value });
      }
      newState.push({ name: "TemplateId", value: TemplateId });
    } else {
      newState.push({ name: "TemplateId", value: TemplateId });
    }
    newState.push({ name: "ConditionType", value: CONDITION_TYPES_OPTIONS[0] });
    newState.push({ name: "SubGoal", value: {} });
    newState.map((item) => handleChange(item));
  };

  const handleAdd = () => {
    onUpdate({
      ...state,
      Value:
        state.RewardType !== REWARD_TYPE?.CUSTOM_REWARD?.type && state.Value
          ? checkValue(state.Value)
          : state.Value,
    });
  };

  const handleAddSlabs = (slabs) => {
    const rewardSlabs = slabs.map((slab, slabIndex) => {
      return rewardSlab({ slabIndex: slabIndex + 1, ...slab });
    });
    handleChange({ name: "Slabs", value: rewardSlabs });
  };

  const isSlabType = isSlabTypeReward(state.RewardType);
  const isCustomReward = state.RewardType === REWARD_TYPE?.CUSTOM_REWARD?.type;
  const isComplete = () => {
    if (
      state?.ConditionType?.key === CONDITION_TYPES_OPTIONS[1].key &&
      !groupByConditionValid(state?.SubGoal)
    )
      return false;
    if (state.RewardType === REWARD_TYPE.FIXED.type) {
      return (
        checkValue(state.Value) &&
        state.Value >= MINIMUM_INCENTIVE_REWARD_VALUE &&
        state.RewardType
      );
    }
    if (isCustomReward) {
      return !isEmpty(state.Value);
    }
    if (isSlabType) {
      return state.Slabs.length;
    }
    return (
      checkValue(state.Value) &&
      state.Value >= MINIMUM_INCENTIVE_REWARD_VALUE &&
      state.RewardType &&
      (state.TemplateId || state.GoalId)
    );
  };

  const handleCustomRewardChange = (reward) => {
    const rewardObj = {
      CustomRewardImageUrl: reward.CustomRewardImageUrl,
      RewardCategory: reward.Category,
      RewardId: reward.value,
      RewardName: reward.Name,
    };

    setState((prevState) => ({
      ...prevState,
      Monetary: false,
      Value: rewardObj.RewardId,
      CustomReward: rewardObj,
    }));
  };

  const formatRewardTypes = (rewardTypes) => {
    const groupedRewardTypes = groupBy(rewardTypes, "Category");
    return Object.keys(groupedRewardTypes).map((reward) => {
      return {
        Category: REWARD_SUBCATEGORIES[reward].value,
        options: groupedRewardTypes[reward].map((reward) => {
          return {
            Name: reward.label,
            value: reward.type,
            Category: REWARD_SUBCATEGORIES[reward.Category].value,
          };
        }),
      };
    });
  };

  const getCustomRewardOptionLabel = (option) => {
    const isSelected = option.value === reward.CustomReward?.RewardId;
    const isDisabledOption = option?.isDisabled;
    return (
      <FormTooltip
        id="custom-reward-tooltip"
        placement="bottom"
        text={t("LEADERBOARD.MESSAGES.SAME_CATEGORY_CANNOT_BE_SELECTED")}
        disable={!isDisabledOption}
      >
        <div className={`d-flex w-100 ${isSelected ? "fc-primary-blue" : ""}`}>
          <div className="pr-2">
            {option.CustomRewardImageUrl || option.Category ? (
              <RewardIcon
                src={option.CustomRewardImageUrl}
                rewardCategory={option.Category}
                width={20}
                height={20}
                className={isDisabledOption ? "opacity-50" : ""}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="fs-13 normal">
            <Truncate text={option?.Name} maxWidth={230} />
          </div>
        </div>
      </FormTooltip>
    );
  };
  const disableGroupBy =
    state?.GoalId && state?.TemplateId
      ? !Boolean(selectedGoalsGroupByEntities) || !selectedGoalsEntityValuePair
      : state?.TemplateId
      ? !selectedTemplate?.GroupBy
      : null;

  const formatConditionTypeOptionLabel = ({ label, key }) => {
    return (
      <FormTooltip
        text={
          state?.GoalId
            ? t("COMMON.MESSAGES.GOAL_DOESNT_SUPPORT_GROUPBY")
            : t("COMMON.MESSAGES.RULESET_DOESNT_SUPPORT_GROUPBY")
        }
        disable={key !== CONDITION_TYPES_OPTIONS[1].key || !disableGroupBy}
        placement="bottom"
      >
        <div className={"flex align-items-center"}>
          <div className="mr-2">{label}</div>
        </div>
      </FormTooltip>
    );
  };

  const filteredCustomRewardList = (currentReward = null) => {
    const nonDuplicativeRewardCategories = currentReward
      ? NON_DUPLICATIVE_REWARDS_CATEGORY_LIST.filter(
          (rewardCategory) => rewardCategory !== currentReward?.RewardCategory
        )
      : NON_DUPLICATIVE_REWARDS_CATEGORY_LIST;

    const utilisedRewardCategories = rewardsList
      ?.filter((rewardElem) => {
        return (
          rewardElem?.RewardType === REWARD_TYPE?.CUSTOM_REWARD?.type &&
          rewardElem.Value !== "" &&
          nonDuplicativeRewardCategories.includes(
            rewardElem?.CustomReward?.RewardCategory
          )
        );
      })
      .map((elem) => elem?.CustomReward?.RewardCategory);

    const currentRewardIndex = currentReward
      ? customRewardsList.findIndex(
          (rewardListItem) => rewardListItem.RewardId === currentReward.RewardId
        )
      : true;
    let rewardList = customRewardsList;
    if (currentReward && currentRewardIndex === -1) {
      rewardList = [currentReward, ...customRewardsList];
    }

    const rewardListUpdated = rewardList.map((element) => {
      const isCategoryDisabled = utilisedRewardCategories.includes(
        element.RewardCategory
      );
      return {
        ...element,
        isDisabled: isCategoryDisabled,
      };
    });

    return rewardListUpdated.sort((a, b) => a.isDisabled - b.isDisabled);
  };

  const getFormattedLabel = (option) => {
    return (
      <div className="ml-1">
        <Truncate
          id={`reward-option-${option.Name}`}
          maxWidth="145px"
          truncType="end"
          text={
            option.value === REWARD_TYPE.CUSTOM_REWARD.type ? (
              <>
                {option.Name}
                <span className="ml-1">
                  ({filteredCustomRewardList(getCurrentCustomReward).length})
                </span>
              </>
            ) : (
              option.Name
            )
          }
        />
      </div>
    );
  };

  const formatCustomRewardGroupLabel = (data) =>
    `${capitalize(data?.Category)} (${data.options?.length})`;

  const formatCustomRewards = (customRewards) => {
    const groupedCustomRewards = groupBy(customRewards, "RewardCategory");
    return Object.keys(groupedCustomRewards).map((reward) => {
      return {
        Category: NON_MONETARY_CATEGORIES[reward],
        options: groupedCustomRewards[reward].map((reward) => {
          return {
            Name: reward.RewardName,
            value: reward.RewardId,
            Category: NON_MONETARY_CATEGORIES[reward.RewardCategory],
            CustomRewardImageUrl: reward.CustomRewardImageUrl,
            isDisabled: reward.isDisabled,
          };
        }),
      };
    });
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;

    const values = getValue();
    let valueLabel = "";

    if (values.length > 0) {
      valueLabel += props.selectProps.getOptionLabel(values[0]);
    }

    const childrenToRender = React.Children.toArray(children).filter(
      (child) =>
        ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );
    return (
      <components.ValueContainer {...props}>
        {childrenToRender}
        {!props.selectProps.inputValue && valueLabel ? (
          <span className="value-container-label">
            {props.selectProps.groupingByCategory ? (
              <div className="center">
                <RewardIcon
                  src={values[0].CustomRewardImageUrl}
                  rewardCategory={values[0].Category}
                  width={20}
                  height={20}
                  className="mr-2"
                />
                {valueLabel && (
                  <span className="value-label">
                    <Truncate text={values[0].Name} maxWidth={230} />
                  </span>
                )}
              </div>
            ) : (
              <>
                {valueLabel && (
                  <span className="value-label">{values[0].Name}</span>
                )}
              </>
            )}
          </span>
        ) : (
          false
        )}
      </components.ValueContainer>
    );
  };

  const getCurrentCustomReward =
    state?.RewardType === REWARD_TYPE?.CUSTOM_REWARD?.type && state.Value !== ""
      ? state.CustomReward
      : null;
  const formattedTemplates = templates.map((template) => {
    return {
      ...template,
      disabled:
        type === INCENTIVE_PROGRAM_TYPES.GOAL_BASED.value &&
        template.IsCustomFormula
          ? !goals.find((goal) => goal.TemplateId === template.TemplateId)
          : false,
    };
  });

  const customFilter = (option, searchText) => {
    return (
      option.data.Name?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Category?.toLowerCase().includes(searchText.toLowerCase())
    );
  };
  const formattedRuleLabel = (option) => {
    return (
      <div
        className={classNames([
          option.optionType === "Template" ? "semi-bold rulesetName" : "ml-2",
        ])}
      >
        {option.Name}
      </div>
    );
  };

  return (
    <>
      <div className="fs-16 fc-black semi-bold py-3 px-4 center">
        <SvgGift color={COLORS.GREY_1} className="mr-2" /> {operation}{" "}
        {t("INCENTIVES.COMMON.LABELS.REWARD")} {index}
      </div>
      <hr />
      <form className="p-4">
        <div className="mb-3">
          <GroupReactSelect
            defaultValue={
              state.RewardType
                ? formatRewardTypes([REWARD_TYPE[state.RewardType]])[0]
                    .options[0]
                : undefined
            }
            components={{ ValueContainer }}
            handleOnChange={handleRewardTypeChange}
            options={formatRewardTypes(rewardTypes)}
            getOptionLabel={getFormattedLabel}
            placeholder={t("INCENTIVES.COMMON.LABELS.SELECT_AWARD_TYPE")}
          />
        </div>

        {state.RewardType && !isSlabType && !isCustomReward ? (
          <FormInput
            name="Value"
            type="number"
            placeholder={t("INCENTIVES.COMMON.LABELS.ENTER_AWARD_VALUE")}
            onChange={handleValueChange}
            value={checkValue(state.Value) ? state.Value : ""}
            step="any"
          />
        ) : null}
        {isCustomReward ? (
          <GroupReactSelect
            id="custom-rewards-by-category"
            defaultValue={
              operation === "Edit" && state.CustomReward?.RewardId
                ? {
                    Name: state.CustomReward.RewardName,
                    value: state.CustomReward.RewardId,
                    Category: state.CustomReward.RewardCategory,
                    CustomRewardImageUrl:
                      state.CustomReward.CustomRewardImageUrl,
                  }
                : undefined
            }
            components={{ ValueContainer }}
            handleOnChange={handleCustomRewardChange}
            options={formatCustomRewards(
              filteredCustomRewardList(getCurrentCustomReward)
            )}
            isOptionDisabled={(option) => option.isDisabled}
            placeholder={t(
              "SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.CUSTOM_REWARDS"
            )}
            getOptionLabel={getCustomRewardOptionLabel}
            getGroupLabel={formatCustomRewardGroupLabel}
            filterOption={customFilter}
            groupingByCategory
          />
        ) : null}

        {(checkValue(state.Value) &&
          state.Value >= MINIMUM_INCENTIVE_REWARD_VALUE &&
          state.RewardType &&
          state.RewardType !== REWARD_TYPE.FIXED.type) ||
        isSlabType ? (
          type === INCENTIVE_PROGRAM_TYPES.TEMPLATE_BASED.value ? (
            <SelectFilter
              options={formattedTemplates}
              className="mt-3"
              placeholder={t("INCENTIVES.COMMON.LABELS.SELECT_TEMPLATE")}
              onChange={handleTemplateChange}
              value={selectedTemplate || ""}
              optionLabelKey="Name"
              optionValueKey="TemplateId"
              formatOptionLabel={formatTemplateOptionLabel}
              isOptionDisabled={(option) => {
                return option.disabled;
              }}
            />
          ) : (
            <div className="reward-option-dropdown">
              <GroupReactSelect
                className="mt-3"
                placeholder={t("COMMON.LABELS.SELECT_RULESET_OR_GOAL")}
                handleOnChange={handleTemplateChange}
                components={{ ValueContainer }}
                defaultValue={findOptionFromSelectedGoals({
                  goals,
                  templates,
                  templateId: selectedRule?.TemplateId,
                  goalId: state?.GoalId,
                })}
                options={formatSelectedGoals(goals, templates)}
                getGroupLabel={() => false}
                getOptionLabel={formattedRuleLabel}
                filterOption={filterOption}
              />
            </div>
          )
        ) : null}
        {state.TemplateId || state.GoalId ? (
          <SelectFilter
            options={CONDITION_TYPES_OPTIONS}
            placeholder={t("INCENTIVES.PROGRAM.LABELS.SELECT_CONDITION_TYPE")}
            className="mt-3"
            onChange={(conditionType) => {
              handleChange({ name: "ConditionType", value: conditionType });
              handleChange({ name: "SubGoal", value: {} });
            }}
            value={
              (groupByConditionValid(state?.SubGoal) &&
                state?.ConditionType.key === CONDITION_TYPES_OPTIONS[1].key &&
                CONDITION_TYPES_OPTIONS[1]) ||
              (state.ConditionType?.key && state.ConditionType)
            }
            formatOptionLabel={formatConditionTypeOptionLabel}
            isOptionDisabled={({ key }) =>
              disableGroupBy && key === CONDITION_TYPES_OPTIONS[1].key
            }
          />
        ) : null}
        {state.ConditionType?.key === CONDITION_TYPES_OPTIONS[1].key &&
        !groupByConditionValid(state?.SubGoal) ? (
          <FormButton
            label={t("INCENTIVES.PROGRAM.LABELS.SELECT_GROUP_BY_FIELD")}
            className="group-by-button mt-3 flex justify-content-center fc-blue"
            onClick={() => setShowSubGoalModal(true)}
            variant="normal-link"
          />
        ) : null}

        {groupByConditionValid(state?.SubGoal) &&
          state.ConditionType?.key === CONDITION_TYPES_OPTIONS[1].key && (
            <div className="fs-13 mt-3 flex flex-column py-2 px-3 group-by-edit w-100  center position-relative">
              <GroupByLabel groupByCondition={state?.SubGoal} splitToLines />
              <FormButton
                icon={
                  <SvgUnderlineEdit
                    color={COLORS.GREY_2}
                    width="16"
                    height="16"
                  />
                }
                className="mx-2 p-1 px-2 right-0 position-absolute"
                onClick={() => setShowSubGoalModal(true)}
                variant="only-icon"
              />
            </div>
          )}
        {isSlabType &&
        (state.TemplateId || state.GoalId) &&
        (state.ConditionType?.key === CONDITION_TYPES_OPTIONS[1].key
          ? groupByConditionValid(state?.SubGoal)
          : true) ? (
          <AddRewardSlabs
            handleAddSlabs={handleAddSlabs}
            slabs={state.Slabs}
            rewardType={state.RewardType}
            isValueInMinMaxRange={isValueInMinMaxRange}
            showPercentage={Boolean(state.GoalId)}
          />
        ) : null}
        <div className="flex-end mt-4">
          <FormButton
            id="close-btn"
            variant="outline-primary"
            label={t("COMMON.BUTTONS.CLOSE")}
            className="mr-3"
            onClick={onClose}
          />
          <FormButton
            id="add-reward-btn"
            label={`${operation} ${t("INCENTIVES.COMMON.LABELS.REWARD")}`}
            onClick={handleAdd}
            disabled={!isComplete()}
          />
        </div>
      </form>
      {showSubGoalModal && (
        <GroupByModal
          show={showSubGoalModal}
          setShowModal={setShowSubGoalModal}
          modalTitle={t("INCENTIVES.COMMON.LABELS.ADD_GROUP_BY_CONDITION")}
          modalSubTitle={t("INCENTIVES.COMMON.LABELS.GROUP_BY_CONDITION")}
          modalDescription={
            <Trans i18nkey={"INCENTIVES.COMMON.LABELS.GROUP_BY_DESCRIPTION"}>
              Measure and attain sub-goals that contribute to the accomplishment
              of a primary objective.
              <br /> To employ multiple groupings, simply click on 'AND'.
            </Trans>
          }
          subGoals={
            !isEmpty(state?.SubGoal)
              ? state?.SubGoal
              : INITIAL_INCENTIVE_SUBGOAL_STATE
          }
          setSubGoals={(subGoals) =>
            handleChange({ name: "SubGoal", value: subGoals })
          }
          isCreating={!(reward?.TemplateId || reward?.GoalId)}
          groupByEntities={
            state?.GoalId
              ? selectedGoalsGroupByEntities
              : selectedTemplate?.GroupBy
          }
          groupByValues={selectedGoalsEntityValuePair}
        />
      )}
    </>
  );
}

export default UpdateOrCreateReward;
