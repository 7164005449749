import { gql, useMutation } from "src/api/client";

const SAVE_CUSTOM_REWARD = gql`
  mutation saveCustomReward(
    $RewardId: ID
    $RewardName: String
    $RewardValue: Float
    $RewardLink: String
    $Description: String
    $FileId: Float
    $FileName: ImageFileName
    $RewardCategory: RewardCategoryEnum
    $IsActive: Boolean
  ) {
    saveCustomReward(
      RewardId: $RewardId
      RewardName: $RewardName
      RewardValue: $RewardValue
      RewardLink: $RewardLink
      Description: $Description
      FileId: $FileId
      FileName: $FileName
      RewardCategory: $RewardCategory
      IsActive: $IsActive
    ) {
      ... on CustomRewardResponse {
        success
        message
        response {
          RewardId
          RewardName
          RewardValue
          RewardLink
          Description
          FileId
          FileName
          RewardCategory
          IsActive
          AwardCount
          ModulesUsedCount
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useSaveCustomReward = () => {
  return useMutation(SAVE_CUSTOM_REWARD);
};

const UPDATE_CUSTOM_REWARD_FILE_MUTATION = gql`
  mutation saveCustomReward(
    $RewardId: ID
    $RewardName: String
    $Description: String
    $FileId: Float
    $FileName: ImageFileName
  ) {
    saveCustomReward(
      RewardId: $RewardId
      RewardName: $RewardName
      Description: $Description
      FileId: $FileId
      FileName: $FileName
    ) {
      ... on CustomRewardResponse {
        success
        message
        response {
          RewardId
          FileName
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useUpdateCustomRewardFile = () => {
  return useMutation(UPDATE_CUSTOM_REWARD_FILE_MUTATION);
};
