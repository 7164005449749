import PropTypes from "prop-types";
import { useReducer } from "react";
import {
  FEATURE_TYPES,
  LEADERBOARD_PROGRAM_TYPES,
  PROGRAM_STATUS_TYPES,
  ROLLUP_TYPES,
} from "src/constants";
import {
  getNonUniformDefaultRecurrences,
  getSystemOffset,
  getTodayAsPerTimezone,
  isRollUpDisabled,
} from "src/helpers";
import {
  LEADERBOARD_ATTRIBUTES,
  LEADERBOARD_ATTRIBUTES_PROPTYPES,
} from "../pages/leaderboardCrud/web/selectAttributes/data";

const ACTIONS = {
  SET_CALCULATION_TYPE: "setCalculationType",
  SET_PROGRAM_TYPE: "setProgramType",
  SET_RECURRENCE_PERIOD: "setRecurrencePeriod",
  SET_RECURRENCE_START_DATE: "setRecurrenceStartDate",
  SET_RECURRENCE_END_DATE: "setRecurrenceEndDate",
  SET_RECURRENCE_BUFFER_DAYS: "setRecurrenceBufferDays",
  SET_SELECTED_TEMPLATES: "setSelectedTemplates",
  SET_SELECTED_GOALS: "setSelectedGoals",
  SET_CUSTOM_CYCLE_TYPE: "setCustomCycleType",
  SET_GAP_DURATION: "setGapDuration",
  SET_CYCLE_DURATION: "setCycleDuration",
  SET_RECURRENCES: "setRecurrences",
  HANDLE_SWITCH_OTHER_RECURRENCE_PERIOD: "handleSwitch",
  HANDLE_DURATION_MODAL: "handleDurationModal",
  SET_ROLLUP_TYPE: "setRollupType",
};

const attributesReducer = (attributes, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_CALCULATION_TYPE: {
      return {
        ...attributes,
        attributesEdited: true,
        IsRollup: payload.IsRollup,
        SelectedGoals: [],
        RollupType: ROLLUP_TYPES[0].value,
      };
    }
    case ACTIONS.SET_PROGRAM_TYPE: {
      const newAttributes = { ...attributes };
      if (attributes.EndDate && attributes.IsGoalsEnabled) {
        newAttributes.Type =
          newAttributes.Type === LEADERBOARD_PROGRAM_TYPES.GOAL_BASED.value
            ? LEADERBOARD_PROGRAM_TYPES.TEMPLATE_BASED.value
            : LEADERBOARD_PROGRAM_TYPES.GOAL_BASED.value;
      }
      return {
        ...newAttributes,
        attributesEdited: true,
      };
    }
    case ACTIONS.SET_RECURRENCE_PERIOD: {
      return {
        ...attributes,
        attributesEdited: true,
        RecurrencePeriod: payload.RecurrencePeriod,
        StartDate: null,
        EndDate: null,
        BufferDays: [],
        SelectedGoals: [],
        Type: LEADERBOARD_PROGRAM_TYPES.TEMPLATE_BASED.value,
        CustomCycleType: "UNIFORM",
        GapDuration: null,
        CycleDuration: null,
        Recurrences: getNonUniformDefaultRecurrences(payload.today),
      };
    }
    case ACTIONS.SET_RECURRENCE_START_DATE: {
      return {
        ...attributes,
        attributesEdited: true,
        StartDate: payload.StartDate,
        EndDate: null,
        BufferDays: [],
        SelectedGoals: [],
        Type: LEADERBOARD_PROGRAM_TYPES.TEMPLATE_BASED.value,
      };
    }
    case ACTIONS.SET_RECURRENCE_END_DATE: {
      return {
        ...attributes,
        attributesEdited: true,
        EndDate: payload.EndDate,
        SelectedGoals: payload?.IsDraft ? [] : attributes.SelectedGoals,
        Type: payload?.IsDraft
          ? LEADERBOARD_PROGRAM_TYPES.TEMPLATE_BASED.value
          : payload?.Type,
      };
    }
    case ACTIONS.SET_RECURRENCE_BUFFER_DAYS: {
      return {
        ...attributes,
        attributesEdited: true,
        BufferDays: payload.BufferDays,
      };
    }
    case ACTIONS.SET_SELECTED_TEMPLATES: {
      return {
        ...attributes,
        attributesEdited: true,
        RecurrencePeriod: null,
        StartDate: null,
        EndDate: null,
        SelectedTemplates: payload.SelectedTemplates,
        SelectedGoals: [],
        Type: LEADERBOARD_PROGRAM_TYPES.TEMPLATE_BASED.value,
        Recurrences: getNonUniformDefaultRecurrences(payload.today),
        CustomCycleType: "UNIFORM",
        IsRollup: isRollUpDisabled(
          payload.SelectedTemplates,
          FEATURE_TYPES.LEADERBOARD
        )
          ? false
          : attributes.IsRollup,
        RollupType: ROLLUP_TYPES[0].value,
      };
    }
    case ACTIONS.SET_SELECTED_GOALS: {
      return {
        ...attributes,
        attributesEdited: true,
        SelectedGoals: payload.SelectedGoals,
      };
    }
    case ACTIONS.SET_CYCLE_DURATION: {
      return {
        ...attributes,
        attributesEdited: true,
        CycleDuration: payload.CycleDuration,
      };
    }
    case ACTIONS.SET_RECURRENCES: {
      return {
        ...attributes,
        attributesEdited: true,
        Recurrences: payload.Recurrences,
        StartDate: payload.Recurrences[0].recurrenceStartDate,
        EndDate:
          payload.Recurrences[payload.Recurrences.length - 1].recurrenceEndDate,
        Type: LEADERBOARD_PROGRAM_TYPES.TEMPLATE_BASED.value,
        SelectedGoals: [],
      };
    }
    case ACTIONS.SET_CUSTOM_CYCLE_TYPE: {
      return {
        ...attributes,
        attributesEdited: true,
        CustomCycleType:
          payload.CustomCycleType === 1 ? "NONUNIFORM" : "UNIFORM",
        StartDate: null,
        EndDate: null,
        GapDuration: null,
        CycleDuration: null,
        Recurrences: getNonUniformDefaultRecurrences(payload.today),
        Type: LEADERBOARD_PROGRAM_TYPES.TEMPLATE_BASED.value,
        SelectedGoals: [],
      };
    }
    case ACTIONS.SET_GAP_DURATION: {
      return {
        ...attributes,
        attributesEdited: true,
        GapDuration: payload.GapDuration,
      };
    }
    case ACTIONS.HANDLE_SWITCH_OTHER_RECURRENCE_PERIOD: {
      return {
        ...attributes,
        attributesEdited: true,
        RecurrencePeriod: payload.RecurrencePeriod,
        StartDate: payload.StartDate,
        EndDate: payload.EndDate,
        GapDuration: null,
        CycleDuration: null,
        CustomCycleType: "UNIFORM",
      };
    }
    case ACTIONS.HANDLE_DURATION_MODAL: {
      return {
        ...attributes,
        attributesEdited: true,
        StartDate: null,
        EndDate: null,
      };
    }
    case ACTIONS.SET_ROLLUP_TYPE: {
      return {
        ...attributes,
        attributesEdited: true,
        SelectedGoals: [],
        RollupType: payload.RollupType,
      };
    }
    default:
      return attributes;
  }
};

const useAttributes = ({ initialState }) => {
  const today = getTodayAsPerTimezone();
  const [attributes, dispatch] = useReducer(attributesReducer, {
    ...LEADERBOARD_ATTRIBUTES(initialState, today),
    attributesEdited: false,
  });

  const setCalculationType = (calculationType) => {
    dispatch({
      type: ACTIONS.SET_CALCULATION_TYPE,
      payload: {
        IsRollup: calculationType,
      },
    });
  };

  const setProgramType = () => {
    dispatch({
      type: ACTIONS.SET_PROGRAM_TYPE,
    });
  };

  const setRecurrencePeriod = (recurrencePeriod) => {
    dispatch({
      type: ACTIONS.SET_RECURRENCE_PERIOD,
      payload: {
        RecurrencePeriod: recurrencePeriod,
        today,
      },
    });
  };

  const setStartDate = (startDate) => {
    dispatch({
      type: ACTIONS.SET_RECURRENCE_START_DATE,
      payload: {
        StartDate: startDate,
      },
    });
  };

  const setEndDate = (endDate) => {
    dispatch({
      type: ACTIONS.SET_RECURRENCE_END_DATE,
      payload: {
        IsDraft: initialState?.Status === PROGRAM_STATUS_TYPES.DRAFT,
        Type: initialState?.Type,
        EndDate: endDate,
      },
    });
  };

  const setBufferDays = (bufferDays) => {
    dispatch({
      type: ACTIONS.SET_RECURRENCE_BUFFER_DAYS,
      payload: {
        BufferDays: bufferDays,
      },
    });
  };

  const setSelectedTemplates = (selectedTemplates) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_TEMPLATES,
      payload: {
        SelectedTemplates: selectedTemplates,
      },
    });
  };

  const setSelectedGoals = (selectedGoals) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_GOALS,
      payload: {
        SelectedGoals: selectedGoals,
      },
    });
  };

  const setCycleDuration = (cycleDuration) => {
    dispatch({
      type: ACTIONS.SET_CYCLE_DURATION,
      payload: {
        CycleDuration: cycleDuration,
      },
    });
  };

  const setRecurrences = (recurrences) => {
    dispatch({
      type: ACTIONS.SET_RECURRENCES,
      payload: {
        Recurrences: recurrences,
      },
    });
  };

  const setCustomCycleType = (value) => {
    dispatch({
      type: ACTIONS.SET_CUSTOM_CYCLE_TYPE,
      payload: {
        CustomCycleType: value,
        today,
      },
    });
  };

  const setGapDuration = (gapDuration) => {
    dispatch({
      type: ACTIONS.SET_GAP_DURATION,
      payload: {
        GapDuration: gapDuration,
      },
    });
  };

  const handleSwitch = (recurrencePeriod) => {
    dispatch({
      type: ACTIONS.HANDLE_SWITCH_OTHER_RECURRENCE_PERIOD,
      payload: {
        RecurrencePeriod: recurrencePeriod,
        StartDate:
          initialState?.RecurrenceDefinition?.FirstRecurrenceStartDate &&
          initialState?.Status !== PROGRAM_STATUS_TYPES.DRAFT
            ? Date.parse(
                initialState?.RecurrenceDefinition?.FirstRecurrenceStartDate
              ) - getSystemOffset()
            : null,
        EndDate:
          initialState?.RecurrenceDefinition?.FirstRecurrenceStartDate &&
          initialState?.Status !== PROGRAM_STATUS_TYPES.DRAFT
            ? Date.parse(
                initialState?.RecurrenceDefinition?.LastRecurrenceEndDate
              ) - getSystemOffset()
            : null,
      },
    });
  };

  const handleDurationModal = () => {
    dispatch({
      type: ACTIONS.HANDLE_DURATION_MODAL,
    });
  };

  const setRollupType = (RollupType) => {
    dispatch({
      type: ACTIONS.SET_ROLLUP_TYPE,
      payload: {
        RollupType,
      },
    });
  };

  return {
    attributes,
    actions: {
      setCalculationType,
      setProgramType,
      setRecurrencePeriod,
      setStartDate,
      setEndDate,
      setBufferDays,
      setSelectedTemplates,
      setSelectedGoals,
      setCycleDuration,
      setRecurrences,
      setCustomCycleType,
      setGapDuration,
      handleSwitch,
      handleDurationModal,
      setRollupType,
    },
  };
};

useAttributes.propTypes = {
  initialState: PropTypes.shape(LEADERBOARD_ATTRIBUTES_PROPTYPES),
};

export default useAttributes;
