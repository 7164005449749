import { t } from "i18next";
import React from "react";
import {
  BadgeCell,
  DefaultCell,
  LinkCell,
  NonMonetaryLabel,
} from "src/components";
import {
  APPROVAL_STATUS,
  APPROVAL_STATUSES,
  APPS,
  FEATURE_TYPES,
  FIELD_NAMES,
  PAYOUT_STATUSES,
  statusClasses,
} from "src/constants";
import { SvgApproved, SvgPartiallyApproved } from "src/icons";

const sortTemplateColProps = {
  sortType: (rowA, rowB, id, desc) => {
    if (!desc) {
      if (
        typeof rowA.original[id] !== "string" &&
        typeof rowB.original[id] !== "string"
      )
        return rowA.original[id] < rowB.original[id] ? -1 : 1;
      return String(rowA.original[id]).toLowerCase() <
        String(rowB.original[id]).toLowerCase()
        ? -1
        : 1;
    }
    if (desc) {
      if (
        typeof rowA.original[id] !== "string" &&
        typeof rowB.original[id] !== "string"
      )
        return rowA.original[id] > rowB.original[id] ? 1 : -1;
      return String(rowA.original[id]).toLowerCase() >
        String(rowB.original[id]).toLowerCase()
        ? 1
        : -1;
    }
  },
};

export const approvalFilters = ({ t }) => [
  {
    label: t("RULEBOOK.LABELS.STATUS"),
    defaultValue: "All",
    value: "ALL",
    key: "status",
    options: APPROVAL_STATUS,
  },
];

export const payoutColumns = (userId) => [
  {
    Header: t("INCENTIVES.COMMON.LABELS.INCENTIVE_PROGRAM"),
    accessor: "programName",
    sticky: "left",
    minWidth: 250,
    Cell: LinkCell,
    formatValue: ({ programId, programName }) => {
      const link = APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.link({
        params: {
          programId,
        },
      });

      return {
        link,
        linkText: programName,
      };
    },
    ...sortTemplateColProps,
  },
  {
    Header: t("MANAGED_GOALS.LABELS.ROLL_UP_CYCLE"),
    accessor: "cycle",
    minWidth: 180,
    Cell: DefaultCell,
    ...sortTemplateColProps,
  },
  {
    Header: t("INCENTIVES.COMMON.LABELS.APPROVED_INCENTIVE"),
    id: "approved-incentive",
    accessor: "approvedBenefit",
    minWidth: 150,
    Cell: DefaultCell,
    formatValue: ({
      approvedBenefit,
      ApprovedRewards,
      ApprovedRewardsCount,
      programId,
      recurrenceId,
    }) =>
      formatIncentiveCell(
        approvedBenefit,
        ApprovedRewards,
        ApprovedRewardsCount,
        programId,
        recurrenceId,
        userId,
        FIELD_NAMES.APPROVED,
        t("INCENTIVES.COMMON.LABELS.APPROVED_INCENTIVE")
      ),
    sortType: (rowA, rowB, id, desc) => {
      return rowA.original.payoutDone + rowA.original.payoutPending <
        rowB.original.payoutDone + rowB.original.payoutPending
        ? -1
        : 1;
    },
  },
  {
    Header: t("INCENTIVES.COMMON.LABELS.PAYOUT_DONE"),
    accessor: "payoutDone",
    minWidth: 150,
    Cell: DefaultCell,
    formatValue: ({
      payoutDone,
      PaidOutRewards,
      PaidOutRewardsCount,
      programId,
      recurrenceId,
    }) =>
      formatIncentiveCell(
        payoutDone,
        PaidOutRewards,
        PaidOutRewardsCount,
        programId,
        recurrenceId,
        userId,
        FIELD_NAMES.PAIDOUT,
        t("INCENTIVES.COMMON.LABELS.PAYOUT_DONE")
      ),
    ...sortTemplateColProps,
  },
  {
    Header: t("INCENTIVES.COMMON.LABELS.PENDING_PAYOUT"),
    accessor: "payoutPending",
    minWidth: 170,
    Cell: DefaultCell,
    formatValue: ({
      payoutPending,
      PendingRewards,
      PendingRewardsCount,
      programId,
      recurrenceId,
    }) =>
      formatIncentiveCell(
        payoutPending,
        PendingRewards,
        PendingRewardsCount,
        programId,
        recurrenceId,
        userId,
        FIELD_NAMES.PAYOUT_PENDING,
        t("INCENTIVES.COMMON.LABELS.PENDING_PAYOUT")
      ),
    ...sortTemplateColProps,
  },
  {
    Header: t("RULEBOOK.LABELS.STATUS"),
    accessor: "payoutStatus",
    minWidth: 200,
    Cell: BadgeCell,
    className: ({ payoutStatus }) => {
      return statusClasses[payoutStatus] || "default";
    },
    formatValue: ({ payoutStatus }) => {
      return PAYOUT_STATUSES[payoutStatus].label;
    },
    ...sortTemplateColProps,
  },
];

export const formatIncentiveCell = (
  value,
  nonMonetaryRewards,
  rewardCount,
  programId,
  recurrenceId,
  userId,
  fieldName,
  title
) => {
  return (
    <NonMonetaryLabel
      amount={value}
      nonMonetaryRewards={nonMonetaryRewards}
      rewardCount={rewardCount}
      rewardModalTitle={title}
      payload={{
        programId,
        recurrenceId,
        userId,
        fieldName,
        featureType: FEATURE_TYPES.INCENTIVE,
      }}
    />
  );
};
export const approvalColumns = (userId) => [
  {
    Header: t("INCENTIVES.COMMON.LABELS.INCENTIVE_PROGRAM"),
    accessor: "programName",
    sticky: "left",
    minWidth: 250,
    Cell: LinkCell,
    formatValue: ({ programId, programName }) => {
      const link = APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.link({
        params: {
          programId,
        },
      });

      return {
        link,
        linkText: programName,
      };
    },
    ...sortTemplateColProps,
  },
  {
    Header: t("MANAGED_GOALS.LABELS.ROLL_UP_CYCLE"),
    accessor: "cycle",
    minWidth: 180,
    Cell: DefaultCell,
    ...sortTemplateColProps,
  },
  {
    Header: t("INCENTIVES.COMMON.LABELS.ELIGIBLE_INCENTIVE"),
    accessor: "eligibleBenefit",
    minWidth: 150,
    Cell: DefaultCell,
    formatValue: ({
      eligibleBenefit,
      EligibleRewards,
      EligibleRewardsCount,
      programId,
      recurrenceId,
    }) =>
      formatIncentiveCell(
        eligibleBenefit,
        EligibleRewards,
        EligibleRewardsCount,
        programId,
        recurrenceId,
        userId,
        FIELD_NAMES.ELIGIBLE,
        t("INCENTIVES.COMMON.LABELS.ELIGIBLE_INCENTIVE")
      ),
    ...sortTemplateColProps,
  },
  {
    Header: t("INCENTIVES.COMMON.LABELS.APPROVED_INCENTIVE"),
    id: "approved-incentive",
    accessor: "approvedBenefit",
    minWidth: 150,
    Cell: DefaultCell,
    formatValue: ({
      approvedBenefit,
      ApprovedRewards,
      ApprovedRewardsCount,
      programId,
      recurrenceId,
    }) =>
      formatIncentiveCell(
        approvedBenefit,
        ApprovedRewards,
        ApprovedRewardsCount,
        programId,
        recurrenceId,
        userId,
        FIELD_NAMES.APPROVED,
        t("INCENTIVES.COMMON.LABELS.APPROVED_INCENTIVE")
      ),
    ...sortTemplateColProps,
  },
  {
    Header: t("INCENTIVES.COMMON.LABELS.REJECTED_INCENTIVE"),
    accessor: "rejectedBenefit",
    minWidth: 170,
    Cell: DefaultCell,
    formatValue: ({
      rejectedBenefit,
      RejectedRewards,
      RejectedRewardsCount,
      programId,
      recurrenceId,
    }) =>
      formatIncentiveCell(
        rejectedBenefit,
        RejectedRewards,
        RejectedRewardsCount,
        programId,
        recurrenceId,
        userId,
        FIELD_NAMES.REJECTED,
        t("INCENTIVES.COMMON.LABELS.REJECTED_INCENTIVE")
      ),
    ...sortTemplateColProps,
  },
  {
    Header: t("RULEBOOK.LABELS.STATUS"),
    accessor: "Status",
    minWidth: 200,
    Cell: BadgeCell,
    className: ({ Status }) => {
      return statusClasses[Status] || "default";
    },
    formatValue: ({ Status }) => {
      return APPROVAL_STATUSES[Status].label;
    },
    ...sortTemplateColProps,
  },
];

export const payoutData = [
  {
    programName: "JFM Quarter Program",
    cycle: "1 Aug 2022 - 30 Aug 2022",
    payoutDone: 300,
    payoutPending: 200,
    status: "PENDING",
  },
  {
    programName: "Pre-Sales Incentive Program",
    cycle: "22 Jul 2022 - 28 Jul 2022",
    payoutDone: 200,
    payoutPending: 0,
    status: "PAIDOUT",
  },
];

export const PAYOUT_HISTORY_LOOKUP = {
  PAIDOUT: {
    status: "PAIDOUT",
    icon: <SvgApproved />,
    textColor: "#27AE60",
    rewardColor: "#309AFC",
  },
  PARTIALLYPAIDOUT: {
    status: "PARTIALLYPAIDOUT",
    icon: <SvgPartiallyApproved />,
    textColor: "#A29C00",
  },
};
