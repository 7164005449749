import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { parse, print } from "graphql";
import fetch from "node-fetch";
import { useRefreshToken } from "src/api/useOpenAceAuth";
import { toaster } from "src/components";
import { APPS, AUTHENTICATION } from "src/constants";
import {
  compressGqlQuery,
  errorText,
  isEmpty,
  redirectToUrl,
  verifyToken,
} from "src/helpers";
import { useAuth } from "src/hooks";

const getDomainURL = () => {
  return process.env.REACT_APP_ANALYTICS_API_URL;
};

const httpLink = new HttpLink({ uri: getDomainURL(), fetch: fetch });

const calculateContentLength = (operation) => {
  const body = JSON.stringify({
    query: print(operation.query),
    variables: operation.variables,
  });
  return Buffer.from(body).length;
};

const timingLink = ({ auth }) =>
  new ApolloLink((operation, forward) => {
    const start = window.performance.now();
    return forward(operation).map((response) => {
      const end = window.performance.now();
      const duration = end - start;
      const userAttributes = auth.userAttributesForContext;
      const userData = userAttributes?.length ? JSON.parse(userAttributes) : {};

      const context = operation.getContext();
      const requestContentLength = calculateContentLength(operation);
      const responseContentLength = Number(
        context.response.headers.get("content-length")
      );

      if (window.newrelic && userData) {
        window.newrelic.addPageAction("graphql", {
          query: operation.query,
          operationName: operation.operationName,
          role: userData?.role,
          orgDisplayName: userData?.OrgDisplayName,
          orgUniqueName: userData?.OrgUniqueName,
          requestContentLength,
          responseContentLength,
          duration,
          userId: userData?.UserId,
          orgCode: userData?.OrgCode,
        });
      }
      return response;
    });
  });

//For test automation
const TestKey = localStorage.getItem(process.env.REACT_APP_AUTOMATION_TEST_KEY);

// Custom "middleware" to set the token
const authMiddleware = ({ auth, isOpenAceUser, refresh }) =>
  new ApolloLink(async (operation, forward) => {
    operation.setContext({
      fetchOptions: {
        credentials: "include",
      },
      headers: {
        lsqauth: TestKey ? `${TestKey}` : null,
      },
    });
    if (isOpenAceUser) {
      const access_token = localStorage.getItem("access_token") || "";
      if (!access_token || (access_token && !verifyToken(access_token))) {
        const data = await refresh();
        operation.setContext({
          headers: { Authorization: `${data?.id_token}` },
        });
      } else {
        const id_token = localStorage.getItem("id_token") || "";
        operation.setContext({ headers: { Authorization: `${id_token}` } });
      }
    } else if (!isEmpty(auth.authToken) || !isEmpty(auth.platform)) {
      operation.setContext({
        headers: {
          Authorization: auth.authToken ? `${auth.authToken}` : "",
          "X-Attributes": auth.userAttributes
            ? `${encodeURIComponent(auth.userAttributes)}`
            : "",
          OrgCode: auth.orgCode ? `${auth.orgCode}` : "",
        },
      });
    }

    operation.query = parse(compressGqlQuery(print(operation.query)));

    return forward(operation);
  });

// Custom "afterware" that checks each response and saves the token If it contains an 'Authorization' header
const afterwareLink = ({ auth, setAuth, isOpenAceUser }) =>
  new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const authToken = context.response.headers.get(
        AUTHENTICATION.AUTH_HEADER
      );
      const userAttributes = decodeURIComponent(
        context.response.headers.get(AUTHENTICATION.X_ATTRIBUTES)
      );
      const userAttributesForContext = decodeURIComponent(
        context.response.headers.get(AUTHENTICATION.USER_ATTRIBUTES)
      );
      const orgCode = context.response.headers.get(AUTHENTICATION.ORG_CODE);
      const platform = context.response.headers.get(AUTHENTICATION.PLATFORM);
      if (isEmpty(auth.authToken) && isEmpty(auth.platform)) {
        setAuth({
          authToken: isOpenAceUser ? "TEST_TOKEN" : authToken,
          userAttributes,
          orgCode,
          userAttributesForContext,
          platform,
        });
      }
      return response;
    });
  });

const errorLink = ({ isOpenAceUser, logout }) => {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        if (extensions.code === "FORBIDDEN") {
          return redirectToAccessDenied();
        }
        if (extensions.code === "INTERNAL_SERVER_ERROR") {
          return redirectToSomethingWentWrong();
        }
      });
      toaster({
        message: errorText.GRAPHQL.ERROR,
        type: "error",
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if (isOpenAceUser) {
        if (networkError.statusCode === 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("id_token");
          localStorage.removeItem("login_state");
          localStorage.removeItem("code_verifier");
          return redirectToUrl(APPS.ACE.ROUTES.ERROR.path);
        } else {
          toaster({
            message: errorText.GRAPHQL.ERROR,
            type: "error",
          });
          return null;
        }
      }
      switch (networkError.result?.platform) {
        case "IOS":
          window.onTokenRefreshed = () => {
            window.location.reload();
          };
          if (networkError.statusCode === 403) {
            redirectToAccessDenied();
          } else if (
            networkError.statusCode === 401 &&
            networkError?.result?.refreshToken
          ) {
            window.webkit?.messageHandlers?.aceRefreshToken?.postMessage(
              JSON.stringify({
                callback: "onTokenRefreshed",
                exception: networkError.result?.code,
              })
            );
          } else if (
            networkError.statusCode === 401 &&
            !networkError?.result?.refreshToken
          ) {
            window.webkit?.messageHandlers?.aceSessionExpired?.postMessage(
              JSON.stringify({ exception: networkError.result?.code })
            );
          } else {
            redirectToSomethingWentWrong();
          }
          break;
        case "ANDROID":
          window.onTokenRefreshed = () => {
            window.location.reload();
          };
          if (networkError.statusCode === 403) {
            redirectToAccessDenied();
          } else if (
            networkError.statusCode === 401 &&
            networkError.result.refreshToken
          ) {
            window.LSQAndroidClient?.onRefreshToken(
              JSON.stringify({
                callback: "onTokenRefreshed",
                exception: networkError.result.code,
              })
            );
          } else if (
            networkError.statusCode === 401 &&
            !networkError.result.refreshToken
          ) {
            window.LSQAndroidClient?.onSessionExpired(
              JSON.stringify({ exception: networkError.result.code })
            );
          } else {
            redirectToSomethingWentWrong();
          }
          break;
        case "MARVIN":
          break;
        default:
          if (networkError.statusCode === 403) {
            redirectToAccessDenied();
          } else if (networkError.statusCode === 401) {
            redirectToLogin();
          } else {
            redirectToSomethingWentWrong();
          }
      }
    }
  });
};

const cache = new InMemoryCache({});

export const useAppApolloClient = ({ isOpenAceUser = false }) => {
  const { auth, setAuth } = useAuth();

  const refresh = useRefreshToken();

  return new ApolloClient({
    cache,
    link: from([
      authMiddleware({ auth, isOpenAceUser, refresh }),
      afterwareLink({ auth, setAuth, isOpenAceUser }),
      errorLink({ isOpenAceUser }),
      timingLink({ auth }),
      httpLink,
    ]),
  });
};

const redirectToLogin = () => {
  let parentWindow = window.parent || window;
  parentWindow.postMessage(
    {
      Type: "SPA",
      Action: "redirect",
      RedirectURL: "/Home/SignOut",
    },
    "*"
  );
};
const redirectToAccessDenied = () => {
  let parentWindow = window.parent || window;
  parentWindow.postMessage(
    {
      Type: "SPA",
      Action: "redirect",
      RedirectURL: "/Error/AccessDenied",
    },
    "*"
  );
};
const redirectToSomethingWentWrong = () => {
  let parentWindow = window.parent || window;
  parentWindow.postMessage(
    {
      Type: "SPA",
      Action: "redirect",
      RedirectURL: "/Error/Error",
    },
    "*"
  );
};
