import React from "react";

const SvgThumbsDown = (props) => {
  const { color, height, width, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.5.5h-2.165v8.583H13.5V.5ZM10.814.5v8.314H9.23C6.694 9.45 6.159 13.5 6.159 13.5H4.83c-1.343-2.54.261-4.686.261-4.686H1.51C.953 8.814.5 8.338.5 7.754c0-.292.115-.556.295-.745a.992.992 0 0 1 .715-.31h.624c-.556 0-1.005-.471-1.005-1.055 0-.291.111-.556.295-.75.154-.16.364-.273.595-.305a1.063 1.063 0 0 1-.518-.92c0-.292.115-.556.295-.745a.992.992 0 0 1 .715-.31h.509c-.557 0-1.006-.471-1.006-1.055 0-.291.112-.556.295-.75A.973.973 0 0 1 3.02.5h7.795Z"
        fill={color}
      />
    </svg>
  );
};

SvgThumbsDown.defaultProps = {
  color: "#F44C4C",
  height: 16,
  width: 16,
};

export default SvgThumbsDown;
