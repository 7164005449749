import PropTypes from "prop-types";
import React from "react";
import "./rotatingWords.scss";

const RotatingWords = (props) => {
  const { words = [] } = props;

  return (
    <div className="words">
      {words.map((word) => (
        <span className="word">{word}</span>
      ))}
      {words.length > 2 && <span className="word">{words[0]}</span>}
    </div>
  );
};

RotatingWords.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string),
};

export default RotatingWords;
