import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_ALL_LEADERBOARD_USERS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderboardUsersAchievementDetails(
    $limit: Float
    $after: String
    $query: LeaderboardUsersAchievementQuery
  ) {
    getLeaderboardUsersAchievementDetails(
      limit: $limit
      after: $after
      query: $query
    ) {
      ... on LeaderboardUsersAchievementDetailsResponse {
        success
        response {
          UsersAchievementDetails {
            Name
            EligibleBenefit
            EligibleRewards {
              RewardId
              RewardName
              RewardCategory
              ImageUrl
            }
            EligibleRewardsCount
            Email
            Rank
            UserAchieved
            IsTieBroken
            Type
            MinimumTarget
            GoalValue
            UserId
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
      ... on ErrorResponse {
        ...errorResponseFragment
      }
    }
  }
`;

export const useGetLeaderboardUsersAchievementDetails = (queryOptions) => {
  return useQuery(GET_ALL_LEADERBOARD_USERS_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
