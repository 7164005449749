import { t } from "i18next";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { useGetAttributeValues } from "src/api/useUser";
import { DateRange } from "src/components/base";
import {
  Calendar,
  FormButton,
  FormInput,
  GroupReactSelect,
  SelectFilter,
  Shimmer,
} from "src/components/common";
import { DATE_FORMAT_PAYLOAD, MAX_DATE } from "src/constants";
import { capitalize, format, isEmpty } from "src/helpers";
import { FIELDS, FILTERS_MODEL } from "./data";
import useConditionReducer from "./hooks/useCondition";
import { conditionToPayload, payloadToCondition } from "./utils";

const RangeInput = ({ setter, initialValue, unit }) => {
  const [value, setValue] = useState({
    StartValue: initialValue?.StartValue ?? "",
    EndValue: initialValue?.EndValue ?? "",
  });

  const handleRangeChange = (e) => {
    const { name, value: valueAsNumber } = e.target;
    setValue((prev) => ({
      ...prev,
      [name]: valueAsNumber !== "" ? Number(valueAsNumber) : undefined,
    }));
    setter({
      ...value,
      [name]: valueAsNumber !== "" ? Number(valueAsNumber) : undefined,
    });
  };

  return (
    <div className="center">
      <div style={{ marginRight: 24 }}>
        <FormInput
          name="StartValue"
          type="number"
          placeholder="Enter Value"
          onChange={handleRangeChange}
          value={value?.StartValue}
          unit={unit}
          min={0}
        />
      </div>
      <div>
        <FormInput
          name="EndValue"
          type="number"
          placeholder="Enter Value"
          onChange={handleRangeChange}
          value={value?.EndValue}
          unit={unit}
          step="any"
          min={0}
        />
      </div>
    </div>
  );
};

const SearchableDropdown = ({ condition, actions }) => {
  return (
    <>
      <SelectFilter
        options={Object.values(condition?.Operator?.options)}
        value={condition?.SubOperator}
        onChange={actions.setSubOperator}
      />
      {Boolean(condition?.SubOperator?.field) && (
        <RenderField
          field={condition?.SubOperator?.field}
          condition={condition}
          actions={actions}
        />
      )}
    </>
  );
};

const DynamicDropdown = ({ condition, actions }) => {
  const { data, loading } = useGetAttributeValues({
    variables: {
      Attribute: condition?.Attribute?.SchemaName,
    },
    skip: !condition?.Attribute?.SchemaName,
  });

  if (loading) return <Shimmer width="100%" height="40px" />;

  if (!data?.getAttributeValues)
    return (
      <div className="fs-13 fc-red mt-2">
        {t("INCENTIVES.COMMON.MESSAGES.NO_AVAILABLE_FIELDS")}
      </div>
    );
  const options = data?.getAttributeValues?.map((value) => ({
    label: value,
    value,
  }));
  return (
    <SelectFilter
      options={options}
      value={options.find((option) => option.value === condition?.Value)}
      onChange={({ value }) => actions.setValue(value)}
      formatOptionLabel={(option) => {
        return capitalize(option?.label);
      }}
      placeholder={
        <Trans
          i18nKey="COMMON.LABELS.SELECT_USER_FIELD_VALUE"
          values={{ Type: condition?.Attribute?.DisplayName }}
        />
      }
    />
  );
};

const RenderField = ({ condition, field, actions }) => {
  switch (field) {
    case FIELDS.TEXT_BOX: {
      return (
        <FormInput
          value={condition?.Value === null ? "" : condition?.Value}
          onChange={(e) => actions.setValue(e.target.value)}
        />
      );
    }

    case FIELDS.DYNAMIC_DROP_DOWN: {
      return <DynamicDropdown condition={condition} actions={actions} />;
    }

    case FIELDS.NUMBER_BOX: {
      const handleNumberChange = (e) => {
        const { valueAsNumber } = e.target;
        actions.setValue(
          valueAsNumber !== "" ? Number(valueAsNumber) : undefined
        );
      };

      return (
        <FormInput
          type="number"
          value={condition?.Value === null ? "" : condition?.Value}
          onChange={handleNumberChange}
          min={0}
        />
      );
    }

    case FIELDS.NUMBER_BOX_RANGE: {
      return (
        <RangeInput initialValue={condition?.Value} setter={actions.setValue} />
      );
    }

    case FIELDS.SEARCHABLE_DROPDOWN: {
      return <SearchableDropdown condition={condition} actions={actions} />;
    }

    case FIELDS.DATE_PICKER: {
      return (
        <Calendar
          placeholder={t("INCENTIVES.DASHBOARD.LABELS.SELECT_START_DATE")}
          selected={condition.Value ? new Date(condition.Value) : ""}
          minDate={new Date(null)}
          onChange={(date) =>
            actions.setValue(format(date, DATE_FORMAT_PAYLOAD))
          }
          maxDate={MAX_DATE}
          wrapperClassName="w-100"
        />
      );
    }

    case FIELDS.DATE_RANGE_PICKER: {
      return (
        <DateRange
          startDate={condition?.Value?.StartValue}
          endDate={condition?.Value?.EndValue}
          onChange={(range) =>
            actions.setValue({
              StartValue: format(range.startDate, DATE_FORMAT_PAYLOAD),
              EndValue: format(range.endDate, DATE_FORMAT_PAYLOAD),
            })
          }
          showTo={false}
          minDate={new Date(null)}
          maxDate={MAX_DATE}
          rightCalendarClass="ml-2"
        />
      );
    }

    default: {
      return null;
    }
  }
};

const SubOperatorDropdown = ({ condition, actions }) => {
  const operatorField = condition?.Operator?.field;

  const field =
    typeof operatorField === "function"
      ? operatorField(condition?.Attribute?.SchemaName)
      : operatorField;

  return <RenderField field={field} condition={condition} actions={actions} />;
};

const AddorEditCondition = ({
  selectedCondition,
  conditionIndex,
  handleAdd,
  handleUpdate,
  handleClose,
  userFieldOptionsWithDisplayName,
}) => {
  const { actions, condition, validations } = useConditionReducer({
    initialState: isEmpty(selectedCondition)
      ? selectedCondition
      : payloadToCondition(selectedCondition, userFieldOptionsWithDisplayName),
  });
  return (
    <div className="w-100">
      <div className="flex align-items-center p-3 pl-4 fs-16 semi-bold border-bottom ">
        {!selectedCondition?.Attribute
          ? t("RULEBOOK.BUTTONS.ADD_CONDITION")
          : t("RULEBOOK.BUTTONS.EDIT_CONDITION")}{" "}
        {conditionIndex + 1}
      </div>
      <div className="space-y-24 p-4 w-100">
        <GroupReactSelect
          options={[{ options: userFieldOptionsWithDisplayName }]}
          value={condition?.Attribute?.SchemaName ? condition?.Attribute : null}
          optionLabelKey="DisplayName"
          optionValueKey="SchemaName"
          getGroupLabel={() => t("COMMON.LABELS.USER_FIELDS")}
          handleOnChange={actions.setAttribute}
          placeholder={t("COMMON.LABELS.SELECT_USER_FIELD")}
        />
        {validations?.isOperatorFieldAllowed() && (
          <SelectFilter
            options={Object.keys(
              FILTERS_MODEL[condition?.Attribute?.DataType]
            ).map((value) => ({
              label:
                FILTERS_MODEL[condition?.Attribute?.DataType][value]?.label,
              value,
            }))}
            value={condition?.Operator}
            onChange={({ value }) => {
              actions.setOperator(
                FILTERS_MODEL[condition?.Attribute?.DataType][value]
              );
            }}
            formatOptionLabel={(option) => {
              return capitalize(option?.label);
            }}
            placeholder={t("COMMON.LABELS.SELECT_TYPE")}
          />
        )}
        {validations?.isSubOperatorFieldAllowed() && (
          <SubOperatorDropdown condition={condition} actions={actions} />
        )}
        <div className="space-x-6 d-flex justify-content-end">
          <FormButton
            variant="outline-primary"
            label={t("COMMON.BUTTONS.CANCEL")}
            onClick={() => {
              handleClose({ index: conditionIndex });
            }}
          />
          <FormButton
            label={
              !selectedCondition?.Attribute
                ? t("RULEBOOK.BUTTONS.ADD_CONDITION")
                : t("RULEBOOK.BUTTONS.EDIT_CONDITION")
            }
            disabled={!validations?.isConditionValid()}
            onClick={() => {
              if (!selectedCondition?.Attribute) {
                handleAdd({
                  condition: conditionToPayload(condition),
                  index: conditionIndex,
                });
              } else {
                handleUpdate({
                  condition: conditionToPayload(condition),
                  index: conditionIndex,
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddorEditCondition;
