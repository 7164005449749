import React from "react";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { COLORS } from "src/constants";
import { SvgErrorToast } from "src/icons";
import "./errorToaster.scss";

const ToasterConfigurations = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  closeButton: false,
  transition: Slide,
};

toast.configure();

const ToastContainer = (props) => (
  <div className="center error-toast-container">
    <SvgErrorToast backgroundColor="none" color={COLORS.WHITE} />
    <div className="fs-12 fc-white error-toast-container__message">
      {props.message}
    </div>
  </div>
);

const errorToaster = ({ message }) =>
  toast(<ToastContainer message={message} />, {
    ...ToasterConfigurations,
    toastId: message,
  });

export default errorToaster;
