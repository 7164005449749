import PropTypes from "prop-types";
import React from "react";
import "./toggleSwitch.scss";

export const SwitchVariant = {
  buttonStyle: {
    height: "20px",
    width: "40px",
  },
  labelStyle: {
    fontSize: "0.5rem",
    transitionDuration: "0.2s",
  },
  offButtonStyle: {
    backgroundColor: "#d1dde8",
  },
  offLabelStyle: {
    color: "#fff",
    transform: "translateX(1.2rem)",
  },
  offSliderStyle: {
    color: "#000",
    transform: "translateX(-0.7rem)",
  },
  onButtonStyle: {
    backgroundColor: "#309afc",
  },
  onLabelStyle: {
    color: "#fff",
    transform: "translateX(0.2rem)",
  },
  onSliderStyle: {
    color: "#000",
    transform: "translateX(0.45rem)",
  },
  sliderStyle: {
    fontSize: "0.4rem",
    transitionDuration: "0.2s",
  },
};
const onSliderStyleforHidden = {
  color: "#000",
  transform: "translateX(1.2rem)",
};
const offSliderStyleforHidden = {
  color: "#000",
  transform: "translateX(0rem)",
};
const ToggleSwitch = (props) => {
  const {
    value,
    buttonStyle,
    onButtonStyle,
    offButtonStyle,
    onLabel,
    offLabel,
    labelStyle,
    onLabelStyle,
    offLabelStyle,
    onSliderLabel,
    offSliderLabel,
    sliderStyle,
    onSliderStyle,
    offSliderStyle,
    onClick,
    labelProps,
    buttonProps,
    sliderProps,
    disabled,
    hideToggleSwitchText = false,
  } = props;

  const handleClick = () => {
    onClick(!value);
  };

  const buttonStyles = value
    ? { ...buttonStyle, ...onButtonStyle }
    : { ...buttonStyle, ...offButtonStyle };

  const labelStyles = value
    ? { ...labelStyle, ...onLabelStyle }
    : { ...labelStyle, ...offLabelStyle };
  const onSliderStyles = hideToggleSwitchText
    ? onSliderStyleforHidden
    : onSliderStyle;
  const offSliderStyles = hideToggleSwitchText
    ? offSliderStyleforHidden
    : offSliderStyle;
  const sliderStyles = value
    ? { ...sliderStyle, ...onSliderStyles }
    : { ...sliderStyle, ...offSliderStyles };

  return (
    <button
      role="switch"
      tabIndex="0"
      type="button"
      id="toggle-switch-id"
      className="toggle-switch"
      aria-checked={value}
      onClick={handleClick}
      style={buttonStyles}
      disabled={disabled}
      {...buttonProps}
    >
      {!hideToggleSwitchText && (
        <span
          style={labelStyles}
          className="toggle-switch__text"
          {...labelProps}
        >
          {value ? onLabel : offLabel}
        </span>
      )}
      <span
        aria-hidden="true"
        className="toggle-switch__slider"
        style={sliderStyles}
        {...sliderProps}
      >
        {value ? onSliderLabel : offSliderLabel}
      </span>
    </button>
  );
};

ToggleSwitch.propTypes = {
  value: PropTypes.bool,
  buttonStyle: PropTypes.object,
  onButtonStyle: PropTypes.object,
  offButtonStyle: PropTypes.object,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  labelStyle: PropTypes.object,
  onLabelStyle: PropTypes.object,
  offLabelStyle: PropTypes.object,
  onSliderLabel: PropTypes.string,
  offSliderLabel: PropTypes.string,
  sliderStyle: PropTypes.object,
  onSliderStyle: PropTypes.object,
  offSliderStyle: PropTypes.object,
  onClick: PropTypes.func,
  labelProps: PropTypes.object,
  buttonProps: PropTypes.object,
  sliderProps: PropTypes.object,
};

ToggleSwitch.defaultProps = {
  onClick: () => {},
  labelProps: {},
  buttonProps: {},
  sliderProps: {},
  onSliderLabel: "",
  offSliderLabel: "",
  onLabel: "",
  offLabel: "",
};

export default ToggleSwitch;
