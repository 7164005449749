import { useEffect, useRef, useState } from "react";

const ACTIONS = ["BACK", "NEXT"];

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

const cancelAnimationFrame =
  window.cancelAnimationFrame || window.mozCancelAnimationFrame;

let requestId;

const useTicker = ({ itemCount }) => {
  const [state, setState] = useState({
    playable: false,
    action: -1,
    scrollOffset: 0,
    playing: false,
    isAtEnd: false,
    isAtStart: true,
  });
  const tickerRef = useRef();
  const tickerContentRef = useRef();
  const onBack = () => {
    setState((prevState) => ({ ...prevState, playing: false, action: 0 }));
  };

  const onNext = () => {
    setState((prevState) => ({ ...prevState, playing: false, action: 1 }));
  };

  const getCurrentScrollOffset = () => {
    return tickerRef.current.scrollLeft;
  };

  const handlePlay = () => {
    const scrollLeft = getCurrentScrollOffset();
    setState((prevState) => ({
      ...prevState,
      playing: true,
      scrollOffset: scrollLeft,
    }));
  };

  const handlePause = () => {
    const scrollLeft = getCurrentScrollOffset();
    setState((prevState) => ({
      ...prevState,
      playing: false,
      scrollOffset: scrollLeft,
    }));
  };

  const handleScroll = (scrollOffset) => {
    let currentScrollOffset = scrollOffset;
    function init() {
      tickerRef.current.scrollTo(currentScrollOffset + 0.5, 0);
      currentScrollOffset += 0.5;
      if (
        currentScrollOffset >
        tickerRef.current.scrollWidth - tickerRef.current.clientWidth
      ) {
        setState((prevState) => ({
          ...prevState,
          isAtEnd: true,
          scrollOffset: 0,
        }));
        cancelAnimationFrame(requestId);
      } else if (state.isAtEnd) {
        setState((prevState) => ({ ...prevState, isAtEnd: false }));
      }
      requestId = requestAnimationFrame(init);
    }
    requestId = requestAnimationFrame(init);
  };

  useEffect(() => {
    if (tickerRef?.current && state.playable) {
      tickerRef.current.addEventListener("mouseenter", handlePause);
      tickerRef.current.addEventListener("mouseleave", handlePlay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.playable]);

  useEffect(() => {
    if (tickerRef?.current && tickerContentRef?.current) {
      if (
        tickerContentRef.current.clientWidth > tickerRef.current.clientWidth
      ) {
        setState((prevState) => ({
          ...prevState,
          playable: true,
          playing: true,
        }));
      }
    }
  }, [itemCount]);

  useEffect(() => {
    if (state.playing && tickerRef?.current) {
      handleScroll(state.scrollOffset);
    }
    return () => cancelAnimationFrame(requestId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.playing, state.scrollOffset, state.isAtEnd]);

  useEffect(() => {
    if (!state.playing && tickerRef?.current && state.action !== -1) {
      const action = ACTIONS[state.action];
      let newScrollOffset = state.scrollOffset;
      switch (action) {
        case "BACK":
          tickerRef.current.scrollTo(tickerRef.current.scrollLeft - 300, 0);
          newScrollOffset = tickerRef.current.scrollLeft;
          break;
        case "NEXT":
          tickerRef.current.scrollTo(tickerRef.current.scrollLeft + 300, 0);
          newScrollOffset = tickerRef.current.scrollLeft;
          break;
        default:
          break;
      }
      setState((prevState) => ({
        ...prevState,
        scrollOffset: newScrollOffset,
        action: -1,
        playing: true,
      }));
    }
  }, [state.playing, state.action, state.scrollOffset]);

  return {
    ...state,
    tickerRef,
    tickerContentRef,
    onBack,
    onNext,
  };
};

export default useTicker;
