export const encodingSearchParams = ({ search }) => {
  const searchKeys = Object.keys(search);
  const searchParams = new URLSearchParams();
  if (searchKeys.length) {
    searchKeys.forEach((searchKey) => {
      let searchValue = {};
      if (typeof search[searchKey] === "object") {
        searchValue = search[searchKey];
      } else {
        searchValue = { [searchKey]: search[searchKey] };
      }
      searchParams.set(
        searchKey,
        encodeURIComponent(JSON.stringify(searchValue))
      );
    });
    return `?${searchParams.toString()}`;
  }
  return "";
};

export const createRoute = ({ path, name = "" }) => {
  const link = ({ params = {}, search = {} }) => {
    let newPath = path;

    Object.keys(params).forEach((param) => {
      newPath = newPath.replace(`:${param}`, encodeURIComponent(params[param]));
    });

    const searchKeys = Object.keys(search);

    if (searchKeys.length) {
      const searchParams = encodingSearchParams({ search });
      newPath = `${newPath}${searchParams}`;
    }

    return newPath;
  };

  return { path, link, name };
};
