import { t } from "i18next";
import React from "react";
import {
  MobileFilterBarFilters,
  MobileFilterBarIcons,
  useFilter,
} from "src/components/base";
import { EXCLUDE_USER_ATTRIBUTES } from "src/config/userAttributes";
import { omit } from "src/helpers";
import { UserPerformance } from "src/images";
import UsersFieldCardContainer from "./UsersFieldCardContainer";
import { ACHIEVEMENT_BREAKDOWN_FILTERS } from "./data";

const MobileAchievementBreakdownGrid = ({
  fieldsOrder,
  setFieldsOrder,
  usersAttributeLoading,
  tableData,
  usersAttributeList,
  search,
  setSearch,
  gridLoading,
  handleDoneButton,
  isSalesUser,
  dataNotAvailable,
  hideBreakdownTable,
  userNotPartOfRecurrence,
}) => {
  const { filtersState, ...rest } = useFilter(ACHIEVEMENT_BREAKDOWN_FILTERS);

  const filtersForBreakdown = ACHIEVEMENT_BREAKDOWN_FILTERS.filter((filter) =>
    isSalesUser ? filter.key !== "filter" : true
  );

  return (
    <div>
      <div className="mt-20 flex-between">
        <div className="center">
          <UserPerformance width="16px" height="16px" />
          <span className="fs-16 semi-bold ml-2">
            {t("COMMON.LABELS.USERS")}
          </span>
        </div>
        <div className="center">
          <MobileFilterBarIcons
            loading={usersAttributeLoading}
            filters={filtersForBreakdown}
            filtersState={filtersState}
            {...rest}
          />
        </div>
      </div>
      <MobileFilterBarFilters
        filters={filtersForBreakdown}
        filtersState={filtersState}
        setSearch={setSearch}
        configureColumnProps={{
          fieldsOrder,
          setFieldsOrder,
          handleDoneButton,
          usersAttributeList: omit(usersAttributeList, EXCLUDE_USER_ATTRIBUTES),
        }}
        {...rest}
      />

      <div className="mt-3">
        <UsersFieldCardContainer
          id="mobile-achievement-breakdown"
          data={tableData}
          gridLoading={gridLoading}
          fieldsOrder={fieldsOrder}
          usersAttributeList={usersAttributeList}
          isSalesUser={isSalesUser}
          dataNotAvailable={dataNotAvailable}
          hideBreakdownTable={hideBreakdownTable}
          userNotPartOfRecurrence={userNotPartOfRecurrence}
        />
      </div>
    </div>
  );
};

export default MobileAchievementBreakdownGrid;
