import { t } from "i18next";
import React from "react";
import { Modal } from "react-bootstrap";
import {
  CustomFormulaExamplesModal,
  FormButton,
  ToggleCards,
} from "src/components";
import { SvgFormulaCondition, SvgStandardCondition } from "src/icons";
import { ConditionFormulaExamples } from "src/images";
import "./conditionTypeModal.scss";

const CONDITION_TYPE_CARDS = ({ t, isConditionType }) => [
  {
    cardTitle: (
      <div className="space-x-8">
        <SvgStandardCondition />
        <span>{t("INCENTIVES.COMMON.LABELS.STANDARD")}</span>
        <span className="fs-12 normal fc-grey1">
          ({t("INCENTIVES.COMMON.LABELS.SIMPLE")})
        </span>
      </div>
    ),
    cardDescription: t("INCENTIVES.COMMON.LABELS.STANDARD_CARD_DESC", {
      type: isConditionType ? "condition" : "reward",
    }),
  },
  {
    cardTitle: (
      <div className="space-x-8">
        <SvgFormulaCondition />
        <span>{t("INCENTIVES.COMMON.LABELS.FORMULA")}</span>
      </div>
    ),
    cardDescription: t("INCENTIVES.COMMON.LABELS.FORMULA_CARD_DESC", {
      type: isConditionType ? "conditions" : "rewards",
    }),
  },
];

const ConditionTypeModal = (props) => {
  const {
    show,
    onHide,
    selected,
    setSelected,
    handleClick,
    onDoneClick,
    title,
    desc,
    isConditionType,
  } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      scrollable
      dialogClassName="condition-type-modal-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="flex-between">
          <div className="fs-16 semi-bold">{desc}</div>
          <CustomFormulaExamplesModal
            text={t("LEADERBOARD.LABELS.KNOW_MORE")}
            img={<ConditionFormulaExamples />}
          />
        </div>
        <div className="mt-3">
          <ToggleCards
            cards={CONDITION_TYPE_CARDS({ t, isConditionType })}
            selected={selected}
            setSelected={setSelected}
            handleClick={handleClick}
          />
          <div className="mt-3 fs-12 fc-grey1">
            {t("INCENTIVES.COMMON.LABELS.CARD_FOOTER_1", {
              type: isConditionType ? "condition" : "reward",
            })}{" "}
            <span className="semi-bold">
              {t("INCENTIVES.COMMON.LABELS.CARD_FOOTER_2")}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex-end space-x-16">
        <FormButton variant="outline-primary" label="Cancel" onClick={onHide} />
        <FormButton label="Done" onClick={onDoneClick} />
      </Modal.Footer>
    </Modal>
  );
};

export default ConditionTypeModal;
