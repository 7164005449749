import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { range } from "src/helpers";
import { LoadingRuleCard } from "../loadingRuleCard";

const LoadingTemplateDetails = () => {
  return (
    <div className="template-container w-100">
      <Shimmer width="100px" height="10px" />
      <div className="mt-4">
        <Shimmer width="250px" height="10px" />
      </div>
      <div className="mt-2">
        <Shimmer width="200px" height="10px" />
      </div>
      <div className="rules-grid py-5">
        {range(0, 2).map((i, idx) => (
          <div className="shorter-card">
            <LoadingRuleCard key={idx} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default LoadingTemplateDetails;
