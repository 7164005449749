import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgAllocate = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M9.804 9.321a3.469 3.469 0 1 1 4.161-4.51 11.38 11.38 0 0 1 2.273-1.083 5.975 5.975 0 1 0-7.323 7.952 11.4 11.4 0 0 1 .889-2.359Z"
        data-name="Path 4478"
      />
      <path
        fill={color}
        d="M10.764 21.273H2.5v-1.114a3.481 3.481 0 0 1 3.477-3.477h2.777a11.467 11.467 0 0 1-.21-2.159c0-.115.014-.227.017-.341H5.979a5.983 5.983 0 0 0-5.977 5.977v2.364a1.25 1.25 0 0 0 1.25 1.25h12.027a11.484 11.484 0 0 1-2.515-2.5Z"
        data-name="Path 4479"
      />
      <g data-name="Path 4471">
        <path
          fill={color}
          d="M19.979 23.773a9.25 9.25 0 1 1 9.25-9.25 9.26 9.26 0 0 1-9.25 9.25Zm0-16a6.75 6.75 0 1 0 6.75 6.75 6.758 6.758 0 0 0-6.75-6.75Z"
          data-name="Path 4480"
        />
      </g>
      <g data-name="Ellipse 159">
        <path
          fill={color}
          d="M19.979 18.773a4.318 4.318 0 1 1 4.318-4.318 4.324 4.324 0 0 1-4.318 4.318Zm0-6.136a1.818 1.818 0 1 0 1.818 1.818 1.82 1.82 0 0 0-1.818-1.818Z"
          data-name="Path 4481"
        />
      </g>
    </svg>
  );
};

SvgAllocate.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 30,
  width: 24,
};
export default SvgAllocate;
