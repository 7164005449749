export const TOKEN_TYPE = {
  OPERATOR: "OPERATOR",
  CONSTANT: "CONSTANT",
  VARIABLE: "VARIABLE",
  PARENTHESIS: "PARENTHESIS",
};

const FORMULA_OPERATOR_TYPE = {
  PLUS: "+",
  MINUS: "-",
  MULTIPLY: "*",
  DIVIDE: "/",
};

const FORMULA_OPERATORS = {
  [FORMULA_OPERATOR_TYPE.PLUS]: {
    value: "+",
    precedence: 1,
  },
  [FORMULA_OPERATOR_TYPE.MINUS]: {
    value: "-",
    precedence: 1,
  },
  [FORMULA_OPERATOR_TYPE.MULTIPLY]: {
    value: "*",
    precedence: 2,
  },
  [FORMULA_OPERATOR_TYPE.DIVIDE]: {
    value: "/",
    precedence: 2,
  },
};

const FORMULA_PARENTHESES_TYPE = {
  OPEN_PAREN: "(",
  CLOSE_PAREN: ")",
};

const FORMULA_PARENTHESES = {
  [FORMULA_PARENTHESES_TYPE.OPEN_PAREN]: {
    value: "(",
  },
  [FORMULA_PARENTHESES_TYPE.CLOSE_PAREN]: {
    value: ")",
  },
};

const FORMULA_TOKENS = {
  [TOKEN_TYPE.OPERATOR]: {
    values: FORMULA_OPERATORS,
  },
  [TOKEN_TYPE.PARENTHESIS]: {
    values: FORMULA_PARENTHESES,
  },
  [TOKEN_TYPE.CONSTANT]: {},
  [TOKEN_TYPE.VARIABLE]: {},
};

const formulaNamespace = {
  TOKEN_TYPE,
  FORMULA_OPERATOR_TYPE,
  FORMULA_OPERATORS,
  FORMULA_PARENTHESES_TYPE,
  FORMULA_PARENTHESES,
  FORMULA_TOKENS,
};

export default formulaNamespace;
