import React from "react";
import { Modal } from "react-bootstrap";
import { FormButton } from "src/components";
import { getCustomCycleIntervalString, getIndex } from "src/helpers";
import "./schedulePayoutModal.scss";

const SchedulePayoutViewItem = ({
  index,
  recurrenceIndex,
  cycleString,
  percentage,
}) => {
  return (
    <div className="fs-13 row m-0 px-4 py-3 border-bottom-1 bc-grey3">
      <div className="col-2 p-0">
        <div
          className="w-24 h-24 bg-grey6 rounded-circle fc-grey1 semi-bold flex-center"
          style={{
            border: "1px solid #E2F1FF",
          }}
        >
          {index}
        </div>
      </div>
      <div className="col-5 p-0">
        <span className="fc-grey1 mr-1">#{recurrenceIndex}:</span>
        {cycleString}
      </div>
      <div className="col-5 p-0">{percentage}%</div>
    </div>
  );
};

const SchedulePayoutViewModal = ({
  show,
  onHide,
  recurrences,
  selectedRecurrence,
  currentRecurrence,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      scrollable
      dialogClassName="schedule-payout-modal-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>Schedule Payout</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4 d-flex flex-column">
        <div className="center space-x-16">
          <div>
            <div className="fs-13 fc-grey1">Cycle</div>
            <div className="fs-13 semi-bold">
              <span className="fc-grey1 mr-1">
                #
                {currentRecurrence?.RecurrenceIndex ??
                  getIndex(recurrences, selectedRecurrence) + 1}
                :
              </span>
              {getCustomCycleIntervalString(
                currentRecurrence
                  ? currentRecurrence?.RecurrenceStartDate
                  : selectedRecurrence?.RecurrenceStartDate,
                currentRecurrence
                  ? currentRecurrence?.RecurrenceEndDate
                  : selectedRecurrence?.RecurrenceEndDate
              )}
            </div>
          </div>
          <div>
            <div className="fs-13 fc-grey1">No.Of Schedules</div>
            <div className="fs-13 semi-bold">
              {selectedRecurrence?.PayoutSchedule
                ? selectedRecurrence?.PayoutSchedule?.length || 1
                : selectedRecurrence?.length || 1}
            </div>
          </div>
        </div>
        <div className="fs-12 mt-5 row m-0 px-4 py-12 bg-grey6 fc-grey1 semi-bold">
          <div className="col-2 p-0">#</div>
          <div className="col-5 p-0">Cycle Range</div>
          <div className="col-5 p-0">Payout Percentage</div>
        </div>
        {recurrences?.length
          ? selectedRecurrence?.PayoutSchedule?.map((rec, idx) => {
              const currentRecurrence = recurrences.find(
                (recurrence) => recurrence.RecurrenceId === rec?.RecurrenceId
              );
              return (
                <SchedulePayoutViewItem
                  key={idx}
                  index={idx + 1}
                  recurrenceIndex={getIndex(recurrences, currentRecurrence) + 1}
                  cycleString={getCustomCycleIntervalString(
                    currentRecurrence?.RecurrenceStartDate,
                    currentRecurrence?.RecurrenceEndDate
                  )}
                  percentage={rec?.Percentage}
                />
              );
            })
          : selectedRecurrence.map((rec, idx) => {
              return (
                <SchedulePayoutViewItem
                  key={idx}
                  index={idx + 1}
                  recurrenceIndex={rec?.RecurrenceIndex}
                  cycleString={getCustomCycleIntervalString(
                    rec?.RecurrenceStartDate,
                    rec?.RecurrenceEndDate
                  )}
                  percentage={rec?.Percentage}
                />
              );
            })}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end space-x-16">
        <FormButton variant="primary" onClick={onHide} label="Close" />
      </Modal.Footer>
    </Modal>
  );
};

export default SchedulePayoutViewModal;
