import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

// src/modules/goalCRUD/distributeGoal/DistributeGoal.js
export const GET_GOAL_RECURRENCES_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getRecurrences($GoalId: ID!) {
    getRecurrences(GoalId: $GoalId) {
      ... on GoalRecurrencesResponse {
        success
        message
        response {
          recurrences {
            RecurrenceId
            GoalId
            RecurrenceType
            RecurrenceStartDate
            RecurrenceEndDate
            RecurrenceGoalValue
            CreatedBy
            RecurrenceDays
            SelectedUserCount
            Status
          }
          currentRecurrence {
            RecurrenceId
            GoalId
            RecurrenceType
            RecurrenceStartDate
            RecurrenceEndDate
            RecurrenceGoalValue
            CreatedBy
            RecurrenceDays
            SelectedUserCount
            Status
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetGoalRecurrences = (queryOptions) => {
  return useQuery(GET_GOAL_RECURRENCES_QUERY, queryOptions);
};
