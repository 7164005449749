import { t } from "i18next";
import React, { useEffect } from "react";
import {
  formulaNamespace,
  useFormulaBuilder,
} from "src/components/common/molecules/formulaBuilder";
import { RULE_NOT_USED } from "src/constants";
import {
  areAllRulesUsed,
  formatFormulaToTokens,
  getFormulaRuleCount,
  getRulesetFormula,
  getVariableRules,
} from "src/helpers";
import CustomFormula from "./CustomFormula";
import StandardFormula from "./StandardFormula";

const { FORMULA_OPERATOR_TYPE, FORMULA_PARENTHESES_TYPE, TOKEN_TYPE } =
  formulaNamespace;

const rulesetFormulaTokens = ({ rules }) => {
  return [
    {
      type: TOKEN_TYPE.VARIABLE,
      label: "Rule",
      values: rules,
    },
    {
      type: TOKEN_TYPE.OPERATOR,
      label: "Mathematical Operators",
      values: [
        {
          label: "Addition",
          type: FORMULA_OPERATOR_TYPE.PLUS,
          icon: "+",
        },
        {
          label: "Subtraction",
          type: FORMULA_OPERATOR_TYPE.MINUS,
          icon: "-",
        },
        {
          label: "Multiplication",
          type: FORMULA_OPERATOR_TYPE.MULTIPLY,
          icon: "*",
        },
        {
          label: "Division",
          type: FORMULA_OPERATOR_TYPE.DIVIDE,
          icon: "/",
        },
      ],
    },
    {
      type: TOKEN_TYPE.PARENTHESIS,
      label: "Parenthesis",
      values: [
        {
          label: "Left round bracket",
          type: FORMULA_PARENTHESES_TYPE.OPEN_PAREN,
          icon: "(",
        },
        {
          label: "Right round bracket",
          type: FORMULA_PARENTHESES_TYPE.CLOSE_PAREN,
          icon: ")",
        },
      ],
    },
    {
      type: TOKEN_TYPE.CONSTANT,
      label: "Numbers",
      description: "Type numbers like 10, 25, etc.",
      validations: {
        minValue: {
          inclusive: false,
          value: 0,
        },
        maxValue: {
          inclusive: true,
          value: 9999999,
        },
      },
    },
  ];
};

const validateRulesetFormula = ({ formula, rules }) => {
  if (rules.length === 1) {
    const formulaRuleCount = getFormulaRuleCount({ formula, rule: rules[0] });
    if (
      formulaRuleCount < 2 &&
      !formula.find((item) => !Number.isNaN(Number(item)))
    ) {
      return {
        message: "Formula must contain a number.",
      };
    }
  }
  if (!areAllRulesUsed({ formula, rules })) {
    return {
      TYPE: RULE_NOT_USED,
      message: t("COMMON.MESSAGES.RULE_NOT_USED_WARNING"),
    };
  }
  return false;
};

function RulesetFormula({
  handleUpdateTemplate,
  isCustomFormula,
  setIsCustomFormula,
  formula,
  setFormula,
  formulaRules,
  editStatus,
  isDeleteAllowed,
  templateDetails,
  setAllowSave,
  switchToCustomProps,
  isAdvancedRuleset,
}) {
  const isDisabled =
    (templateDetails.Status !== "DRAFT" && !editStatus) || !isDeleteAllowed;

  const variableRules = getVariableRules({ rules: formulaRules });

  const initialFormula = formatFormulaToTokens(
    formula,
    formulaRules,
    isCustomFormula
  );

  const handleSave = (formula, setError) => {
    const error = validateRulesetFormula({ formula, rules: formulaRules });
    if (error) {
      setError(error);
      if (error.TYPE !== RULE_NOT_USED) {
        return;
      }
    }
    setError(null);
    const rulesetFormula = getRulesetFormula(formula, formulaRules);
    setFormula(rulesetFormula);
    handleUpdateTemplate({
      variables: {
        TemplateId: templateDetails.TemplateId,
        IsCustomFormula: isCustomFormula,
        Formula: rulesetFormula,
      },
      onSuccess: () => {
        setAllowSave(true);
      },
    });
  };

  const handleEdit = () => {
    setAllowSave(false);
  };

  const formulaBuilder = useFormulaBuilder({
    initialFormula,
    config: rulesetFormulaTokens({ rules: variableRules }),
    onSave: handleSave,
    onEdit: handleEdit,
    disabled: isCustomFormula ? isDisabled : true,
  });

  useEffect(() => {
    if (
      formulaBuilder.error &&
      formulaBuilder?.error?.TYPE !== RULE_NOT_USED &&
      !isAdvancedRuleset
    ) {
      setAllowSave(false);
    } else if (
      (!formulaBuilder.error ||
        formulaBuilder?.error?.TYPE === RULE_NOT_USED) &&
      initialFormula?.join("") === formulaBuilder.formula?.join("")
    ) {
      setAllowSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaBuilder.error, formulaRules.length]);

  const isFormulaChanged = initialFormula?.join("");

  useEffect(() => {
    if (
      isCustomFormula &&
      initialFormula?.length &&
      formulaBuilder.formula?.length &&
      initialFormula?.length === formulaBuilder.formula?.length &&
      !formulaBuilder.error
    ) {
      const error = validateRulesetFormula({
        formula: initialFormula,
        rules: formulaRules,
      });
      formulaBuilder.setError(error || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formulaRules.length,
    isCustomFormula,
    formulaBuilder.error,
    formulaBuilder.formula,
    isFormulaChanged,
  ]);

  return (
    <div className="ruleset-formula">
      {isCustomFormula ? (
        <CustomFormula
          editStatus={editStatus || templateDetails.Status === "DRAFT"}
          formulaBuilderProps={formulaBuilder}
        />
      ) : (
        !isAdvancedRuleset && (
          <StandardFormula
            editStatus={editStatus || templateDetails.Status === "DRAFT"}
            formulaBuilderProps={formulaBuilder}
            switchToCustomProps={switchToCustomProps}
          />
        )
      )}
      {isCustomFormula && formulaBuilder.error && !isAdvancedRuleset ? (
        <div className="fs-13 fc-red">{formulaBuilder.error.message}</div>
      ) : null}
    </div>
  );
}

export default RulesetFormula;
