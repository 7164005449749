import { gql, useMutation } from "src/api/client";

const CLONE_INCENTIVE_PROGRAM_MUTATION = gql`
  mutation copyIncentiveProgram($ProgramId: ID!, $Name: String!) {
    copyIncentiveProgram(ProgramId: $ProgramId, Name: $Name) {
      ... on CopyIncentiveProgramResponse {
        success
        message
        response
        __typename
      }
      __typename
    }
  }
`;

export const useCloneIncentiveProgram = (queryOptions) => {
  return useMutation(CLONE_INCENTIVE_PROGRAM_MUTATION, queryOptions);
};
