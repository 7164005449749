import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgTieBreaker = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.783 28.816"
      role="img"
      {...rest}
    >
      <path
        id="_7055166_business_consultation_target_success_goal_icon"
        data-name="7055166_business_consultation_target_success_goal_icon"
        d="M78.8,57.771l-5.463-4.132h0l-3.961-3v-9.46l4.345-3.97v1.775a.3.3,0,1,0,.6,0V34.361l2.679-1.579A.317.317,0,0,0,77,32.25l-2.829-1.865a.287.287,0,0,0-.3-.009.312.312,0,0,0-.154.272v4.3a1.2,1.2,0,0,0-1.481.124l-2.911,2.66a2.656,2.656,0,0,0,.02-.317,2.4,2.4,0,1,0-4.793,0,2.656,2.656,0,0,0,.02.317l-2.911-2.66a1.214,1.214,0,0,0-.913-.32,1.229,1.229,0,0,0-.859.453,1.367,1.367,0,0,0,.126,1.857l4.51,4.119v9.461l-9.428,7.133a.319.319,0,0,0-.059.427.29.29,0,0,0,.408.074l5.842-4.418.033.124a2.424,2.424,0,0,0,1.4,1.634,2.273,2.273,0,0,0,2.094-.178l.62-.39.119.161a2.307,2.307,0,0,0,1.872.973h.005a2.31,2.31,0,0,0,1.872-.965l.1-.135.254.218a2.285,2.285,0,0,0,2.048.494,2.384,2.384,0,0,0,1.611-1.407l5.137,3.885a.288.288,0,0,0,.3.039.309.309,0,0,0,.178-.255.315.315,0,0,0-.129-.286ZM74.31,31.21,76.263,32.5,74.31,33.648Zm-9.167,6.206A1.866,1.866,0,0,1,66.474,35.6a1.772,1.772,0,0,1,2.027.867,1.937,1.937,0,0,1-.276,2.265l-.049.044a.322.322,0,0,0-.03.034,1.745,1.745,0,0,1-2.4,0,.321.321,0,0,0-.03-.034l-.048-.044a1.911,1.911,0,0,1-.52-1.319Zm-.027,3.617a.313.313,0,0,0,0-.034.062.062,0,0,0,0-.027.246.246,0,0,1-.009-.03.228.228,0,0,0-.024-.053.264.264,0,0,0-.015-.026l-.017-.022-.022-.025-.007-.007L60.4,36.6a.728.728,0,0,1-.065-.987.643.643,0,0,1,.449-.237.635.635,0,0,1,.478.167l4,3.652a2.323,2.323,0,0,0,3.364,0l4-3.652a.622.622,0,0,1,.476-.167.646.646,0,0,1,.451.238.727.727,0,0,1-.067.987L68.875,40.81l-.007.007-.022.025-.017.022a.265.265,0,0,0-.015.026.215.215,0,0,0-.014.026.212.212,0,0,0-.01.027c0,.01-.007.02-.009.03a.137.137,0,0,1,0,.027.314.314,0,0,0,0,.034v9.157l-1.534-1.157V46.062a.3.3,0,1,0-.6,0v2.972l-1.53,1.157V41.043s0-.006,0-.009Zm6.6,14.111a1.693,1.693,0,0,1-1.686-.323l-.492-.422a.285.285,0,0,0-.223-.069.3.3,0,0,0-.2.12l-.287.391a1.689,1.689,0,0,1-1.4.72,1.727,1.727,0,0,1-1.4-.728l-.278-.383a.29.29,0,0,0-.391-.078l-.848.534a1.7,1.7,0,0,1-1.566.133,1.812,1.812,0,0,1-1.045-1.222l-.092-.352,5.143-3.89,5.867,4.436a1.787,1.787,0,0,1-1.1,1.134Z"
        transform="translate(-54.545 -29.929)"
        fill="none"
        stroke={color}
        strokeWidth={0.8}
      />
    </svg>
  );
};

SvgTieBreaker.defaultProps = {
  width: 24.783,
  height: 28.816,
  color: COLORS.PRIMARY_BLUE,
};

export default SvgTieBreaker;
