import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { CreateInput } from "src/components";
import { CREATE_PROGRAM_REGEX } from "src/constants";

const ProgramName = ({
  name,
  value,
  handleChange,
  setDisable,
  label,
  placeholder,
}) => {
  const [warning, setWarning] = useState(false);

  useMemo(() => {
    setDisable(warning);
  }, [warning, setDisable]);

  const onChange = (value) => {
    handleChange({ name, value });
  };

  return (
    <CreateInput
      id="incentive-create-name-input"
      maxLength={100}
      warning={warning}
      regex={CREATE_PROGRAM_REGEX}
      setWarning={setWarning}
      label={label}
      placeholder={placeholder}
      name={name}
      value={value}
      setValue={onChange}
    />
  );
};

ProgramName.propTyes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default ProgramName;
