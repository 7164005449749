import React from "react";
import { useTranslation } from "react-i18next";
import { login } from "src/api/useOpenAceAuth";
import {
  BaseCard,
  FormButton,
  Marquee,
  ProgressBar,
} from "src/components/common";
import { COLORS } from "src/constants";
import {
  SvgArrowBlue,
  SvgArrowGreen,
  SvgGoalsOnCard,
  SvgIncentivesOnCard,
  SvgLandingPageUsers,
} from "src/icons";
import { EligibleIncentive, OpenAceLanding } from "src/images";
import { CUSTOMERS, LANDING_PAGE_PRODUCT_LIST } from "./data";
import "./styles.scss";

const OAceLandingPage = () => {
  const { t } = useTranslation();
  return (
    <div className="oace-landing-page">
      <OpenAceLanding className="bg-image" />
      <div className="flex flex-column center left-arrow-green">
        <SvgArrowBlue className="ml-5" />
        <BaseCard className="landing-page-goal-card p-2 mt-4">
          <div className="flex justify-content-between fs-14 semi-bold w-100">
            <span>Revenue Goal</span>
            <SvgGoalsOnCard />
          </div>
          <SvgLandingPageUsers />
          <div className="flex justify-content-between semi-bold w-100">
            <span className="fc-green">76%</span>
            <span>
              76<span className="fc-grey1">/100</span>
            </span>
          </div>
          <ProgressBar percentage={76} barColor={COLORS.CARD_GREEN} />
        </BaseCard>
      </div>
      <div className="flex flex-column center right-arrow-green">
        <SvgArrowGreen className="mr-5" />
        <BaseCard className="landing-page-goal-card p-2 mt-4">
          <div>
            <div className="flex justify-content-between fs-14 semi-bold w-100">
              <span>Sales Incentive Program</span>
              <SvgIncentivesOnCard />
            </div>
            <p className="fs-11 fc-grey1">
              6 Monthly Cycles | 4th cycle in progress
            </p>
          </div>
          <div className="flex semi-bold w-100 fs-14 space-x-6">
            <EligibleIncentive width="40px" height="40px" />
            <p>
              $250 &
              <p className="fc-blue fs-10">iPhone 15 Pro, Amazon Voucher</p>
            </p>
          </div>
        </BaseCard>
      </div>
      <div className="my-5 text-center semi-bold flex flex-column align-items-center">
        <span className="powerful fs-16">{t("COMMON.LABELS.POWERFUL")}</span>
        <span className="sales_performance">
          {t("COMMON.LABELS.SALES_PERFORMANCE")}
        </span>
        <span className="management_software">
          {t("COMMON.LABELS.MANAGEMENT_SOFTWARE")}
        </span>
        <span className="fs-16 fc-grey1 landing-page-description normal mt-4">
          {t("COMMON.MESSAGES.LANDING_PAGE_DESCRIPTIION")}
        </span>
        <div className="space-x-16 flex mt-4 flex-wrap justify-content-center">
          {LANDING_PAGE_PRODUCT_LIST.map(({ icon, title }) => {
            const Icon = icon;
            return (
              <span
                id="title"
                className="fc-grey1 product-badge fs-14 normal center"
              >
                <Icon className="mr-2" />
                {t(title)}
              </span>
            );
          })}
        </div>
        <FormButton
          className="my-4 px-4"
          onClick={login}
          label={t("COMMON.LABELS.GET_IN_TOUCH")}
        />
        <div className="mt-5">
          <span className="fc-grey1 normal fs-16">
            {t("COMMON.LABELS.LOVED_BY_SALES_TEAMS")}
          </span>
          <Marquee duration="30s" playing>
            <div className="flex mt-4 justify-content-center">
              {CUSTOMERS.map((Image) => (
                <Image height="56px" className="mx-3" />
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default OAceLandingPage;
