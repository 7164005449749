import { t } from "i18next";
import React from "react";
import { NonMonetaryLabel, Truncate } from "src/components";
import { classNames } from "src/helpers";
import "./payoutViewListItem.scss";

function PayoutViewListItem({
  heading,
  subHeading,
  pendingRequests,
  pendingPayoutAmount,
  totalCycles,
  onClick,
  active,
  maxWidth,
  pendingPayoutRewards,
}) {
  return (
    <button
      className={classNames([
        "payout-view-list-item p-3 w-100",
        active ? "active" : "",
      ])}
      onClick={onClick}
    >
      <div className="fs-14 semi-bold heading">
        <Truncate text={heading} maxWidth={maxWidth} />
      </div>
      <div className="fs-12 fc-grey center">{subHeading}</div>
      <div className="mt-3 w-100 payout-stats">
        <div>
          <div className="fs-12 fc-grey">
            {t("INCENTIVES.COMMON.LABELS.PENDING_REQUESTS")}
          </div>
          <div className="fs-14 semi-bold">
            {pendingRequests}
            {totalCycles ? (
              <span className="regular ml-1">from {totalCycles} cycles.</span>
            ) : null}
          </div>
        </div>
        <div>
          <div className="fs-12 fc-grey">
            {t("INCENTIVES.PAYOUT.LABELS.PENDING_PAYOUT")}
          </div>
          <div className="fs-14 semi-bold fc-black">
            <NonMonetaryLabel
              amount={pendingPayoutAmount}
              nonMonetaryRewards={pendingPayoutRewards || []}
              rewardCategoryList
              className="d-flex"
            />
          </div>
        </div>
      </div>
    </button>
  );
}

export default PayoutViewListItem;
