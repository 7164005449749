import React from "react";
import { Image } from "src/components/common";
import ConditionFormulaExamplesImg from "./ConditionFormulaExamples.png";

const ConditionFormulaExamples = (props) => (
  <Image
    src={ConditionFormulaExamplesImg}
    alt="ConditionFormulaExamplesImg"
    {...props}
  />
);

export default ConditionFormulaExamples;
