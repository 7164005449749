import { gql, useMutation } from "src/api/client";

// src/modules/goalCrud/distributeGoal/DistributeGoal.js
export const UPDATE_GOAL_DISTRIBUTION_MUTATION = gql`
  mutation createDistribution(
    $NewDistributions: [Distribution]
    $RecurrenceId: ID
    $GoalId: ID
    $RemovedDistributionUserIds: [ID]
    $RemovedSubGoalDistributionIds: [ID]
  ) {
    createDistribution(
      NewDistributions: $NewDistributions
      GoalId: $GoalId
      RecurrenceId: $RecurrenceId
      RemovedDistributionUserIds: $RemovedDistributionUserIds
      RemovedSubGoalDistributionIds: $RemovedSubGoalDistributionIds
    ) {
      success
      message
      response
    }
  }
`;
export const useUpdateGoalDistribution = () => {
  return useMutation(UPDATE_GOAL_DISTRIBUTION_MUTATION);
};
