import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgDate = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M2 16a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h2a1 1 0 0 1 2 0h4a1 1 0 0 1 2 0h2a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2Zm0-2h12V8H2V6h12V3h-2a1 1 0 0 1-2 0H6a1 1 0 0 1-2 0H2Z"
        data-name="Union 59"
      />
    </svg>
  );
};

SvgDate.defaultProps = {
  color: COLORS.GREY_1,
  height: 16,
  width: 16,
};
export default SvgDate;
