import { lazy } from "react";

export const WebPage = lazy(() =>
  import(/* webpackChunkName: 'goals.web.requestDemoPage' */ "./web")
);

export const MobilePage = lazy(() =>
  import(/* webpackChunkName: 'goals.mobile.requestDemoPage' */ "./mobile")
);

export const RequestDemoPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "Request Demo",
  },
};

export * from "./components";
