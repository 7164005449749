import React from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Shimmer } from "src/components/common/atoms";
import { LazyLoad } from "src/components/common/molecules";
import { COLORS } from "src/constants";
import { getColor } from "src/helpers";

const ResponsiveBarChart = (props) => {
  const {
    data,
    barColor,
    barContainerColor = COLORS.GREY_3,
    barHeight = 9,
    percentage,
    animationDuration = 1500,
  } = props;
  return (
    <ResponsiveContainer
      width="100%"
      height={barHeight}
      className="flex align-items-start"
    >
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        className="center"
      >
        <XAxis type="number" domain={[0, 100]} hide />
        <YAxis type="category" dataKey="name" hide />
        <Bar
          dataKey="value"
          fill={barColor || getColor(percentage)}
          radius={[3, 3, 3, 3]}
          background={{
            fill: barContainerColor,
            radius: [3, 3, 3, 3],
          }}
          animationDuration={animationDuration}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const ProgressBar = (props) => {
  const {
    percentage,
    barContainerColor = COLORS.GREY_3,
    barColor,
    barHeight = 9,
    container,
  } = props;

  const data = [{ value: Math.min(Math.max(percentage, 0), 100) }];

  if (container) {
    return (
      <LazyLoad
        container={container}
        fireOnRapidScroll={false}
        placeholder={<Shimmer width="100%" height={`${barHeight}px`} />}
      >
        <ResponsiveBarChart
          data={data}
          barColor={barColor}
          barContainerColor={barContainerColor}
          barHeight={barHeight}
          percentage={percentage}
        />
      </LazyLoad>
    );
  }

  return (
    <ResponsiveBarChart
      data={data}
      barColor={barColor}
      barContainerColor={barContainerColor}
      barHeight={barHeight}
      percentage={percentage}
    />
  );
};

export default ProgressBar;
