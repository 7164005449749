import React from "react";
import { classNames } from "src/helpers";

const MobileTabItem = ({ active, disabled, onClick, children, className }) => {
  return (
    <button
      variant="link"
      className={classNames([
        "tab col px-3 py-2 m-0 center fs-12 semi-bold flex-center br-4 position-relative",
        active ? "active" : "",
        disabled ? "disabled cursor-not-allowed" : "cursor-pointer",
        className,
      ])}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default MobileTabItem;
