import { t } from "i18next";
import React from "react";
import {
  AspectRatio,
  GlassBanner,
  LeaderboardBadge,
  Shimmer,
} from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import { classNames, getRankColor, range } from "src/helpers";
import { MyShowcaseEmptyState } from "src/images";
import "./myShowcase.scss";

const MyShowcase = (props) => {
  const { data = [], setShowModal, loading, allowedLength = 8 } = props;

  const trunctedData =
    data && data.length > 0
      ? data.length > allowedLength
        ? data.slice(0, allowedLength - 1)
        : data.length === allowedLength
        ? data.slice(0, allowedLength)
        : data
      : [];

  return (
    <GlassBanner className="p-4 my-showcase h-100">
      <AspectRatio ratio={{ width: 502, height: 362 }}>
        <div className="flex-between">
          <div className={"semi-bold my-showcase-heading"}>
            {t("LEADERBOARD.MY_BOARDS.LABELS.MY_SHOWCASE")}
          </div>
          {!loading ? (
            <>
              {data && data.length > 0 && (
                <FormButton
                  id="my-showcase-view-details"
                  variant="link"
                  className={"fc-blue cursor-pointer view-details fs-14"}
                  onClick={() => setShowModal(true)}
                >
                  {t("LEADERBOARD.MY_BOARDS.LABELS.VIEW_DETAILS")}
                </FormButton>
              )}
            </>
          ) : (
            <Shimmer width="60px" height="20px" />
          )}
        </div>

        {!loading ? (
          <div className={classNames([data && data.length > 0 ? "grid " : ""])}>
            {data && data.length > 0 ? (
              trunctedData.map((item) => {
                const { BadgeType, Count } = item;
                const badgeInfo = BadgeType?.split("_");
                return (
                  <div className="br-8 p-0" key={`${badgeInfo}_${Count}`}>
                    <GlassBanner className="br-8 my-showcase-glass">
                      <div
                        className={classNames([
                          "br-8 trophy-container flex-column-end",
                          getRankColor(parseInt(badgeInfo[1])),
                        ])}
                      >
                        <LeaderboardBadge
                          className="my-showcase-badge"
                          position={parseInt(badgeInfo[1])}
                          recurrencePeriod={badgeInfo[0]}
                        />
                        <span
                          className={"bold my-1 fs-16 showcase-badge-count"}
                        >
                          x {Count}
                        </span>
                      </div>
                    </GlassBanner>
                  </div>
                );
              })
            ) : (
              <div className="flex-center flex-column my-showcase-empty-state">
                <MyShowcaseEmptyState height="137px" width="205px" />
                <span className="fs-13 semi-bold fc-grey1 mt-3">
                  {t("LEADERBOARD.MY_BOARDS.LABELS.NO_COLLECTIBLES_TO_SHOW")}
                </span>
                <span className="fs-12 fc-grey1 mt-2">
                  {t(
                    "LEADERBOARD.MY_BOARDS.LABELS.EARN_COLLECTIBLES_BY_WINNING_YOUR_CYCLES"
                  )}
                </span>
              </div>
            )}
            {data && data.length > allowedLength && (
              <GlassBanner className="br-8 my-showcase-glass">
                <div className="fs-24 h-100 flex-center semi-bold fc-primary-blue">
                  +{data.length - allowedLength - 1}
                </div>
              </GlassBanner>
            )}
          </div>
        ) : (
          <div className={"grid"}>
            {range(0, allowedLength - 1).map((_, idx) => (
              <Shimmer
                className="my-showcase-shimmer"
                key={`myShowCaseShimmer-${idx}`}
                width={"100px"}
                height={"100px"}
              />
            ))}
          </div>
        )}
      </AspectRatio>
    </GlassBanner>
  );
};

export default MyShowcase;
