import { gql, useQuery } from "src/api/client";

const GET_REWARD_CRITERIA_AND_ACHIEVEMENTS_QUERY = gql`
  query userAchievements(
    $programId: ID
    $recurrenceId: ID
    $recurrenceStatus: String
    $userId: ID
    $userAssignedView: Boolean
  ) {
    userAchievements(
      programId: $programId
      recurrenceId: $recurrenceId
      recurrenceStatus: $recurrenceStatus
      userId: $userId
      userAssignedView: $userAssignedView
    ) {
      ... on UserAchievementsResponse {
        success
        message
        response {
          userId
          LastUpdatedBy
          userName
          achievementsCount
          totalEligibleBenefit
          achievementsCount
          type
          achievementsMetadata
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetRewardCriteriaAndAchievements = (queryOptions) => {
  return useQuery(GET_REWARD_CRITERIA_AND_ACHIEVEMENTS_QUERY, queryOptions);
};
