import { gql, useQuery } from "src/api/client";

const GET_CUSTOM_REWARD_LIST = gql`
  query getCustomRewardList(
    $Limit: Float
    $Query: JSON
    $After: String
    $Before: String
  ) {
    getCustomRewardList(
      Limit: $Limit
      Query: $Query
      After: $After
      Before: $Before
    ) {
      ... on CustomRewardListResponse {
        success
        message
        response {
          CustomRewardList {
            RewardId
            RewardName
            RewardValue
            RewardLink
            Description
            FileId
            FileName
            CustomRewardImageUrl
            RewardCategory
            IsActive
            AwardCount
            ModulesUsedCount
            isDeleteAllowed
          }
          totalCount
          CustomRewardListTotalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useGetCustomRewardList = (queryOptions) => {
  return useQuery(GET_CUSTOM_REWARD_LIST, queryOptions);
};

const GET_CUSTOM_REWARDS = gql`
  query getCustomRewardList(
    $Limit: Float
    $Query: JSON
    $After: String
    $Before: String
  ) {
    getCustomRewardList(
      Limit: $Limit
      Query: $Query
      After: $After
      Before: $Before
    ) {
      ... on CustomRewardListResponse {
        success
        message
        response {
          CustomRewardList {
            RewardId
            RewardName
            CustomRewardImageUrl
            RewardCategory
          }
          totalCount
          CustomRewardListTotalCount
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useGetCustomRewards = (queryOptions) => {
  return useQuery(GET_CUSTOM_REWARDS, queryOptions);
};
