import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormButton } from "../../formElements";
import "./weekDaysSelector.scss";

export const defaultWeekDaysSelectorOptions = [
  { label: "Checked in Days", value: "Checked in Days" },
  { label: "Custom Days", value: "Custom Days" },
];

export const defaultWeeksData = ["SUN", "MON", "TUE", "WED", "THU"];
export const allDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const WEEKS_DAYS = {
  SUN: {
    label: "Sunday",
    value: "SUN",
    singleChar: "S",
    doubleChar: "Su",
  },
  MON: {
    label: "Monday",
    value: "MON",
    singleChar: "M",
    doubleChar: "Mo",
  },
  TUE: {
    label: "Tuesday",
    value: "TUE",
    singleChar: "T",
    doubleChar: "Tu",
  },
  WED: {
    label: "Wednesday",
    value: "WED",
    singleChar: "W",
    doubleChar: "We",
  },
  THU: {
    label: "Thursday",
    value: "THU",
    singleChar: "T",
    doubleChar: "Th",
  },
  FRI: {
    label: "Friday",
    value: "FRI",
    singleChar: "F",
    doubleChar: "Fr",
  },
  SAT: {
    label: "Saturday",
    value: "SAT",
    singleChar: "S",
    doubleChar: "Sa",
  },
};

const WeekDaysSelector = ({
  weeksData,
  viewOnly,
  setNudgeDays,
  minSelection,
}) => {
  const [selectedWeek, setSelectedWeek] = useState([]);

  useEffect(() => {
    const days = [...weeksData];
    days.sort((day1, day2) => allDays.indexOf(day1) - allDays.indexOf(day2));
    setSelectedWeek(days);
  }, [weeksData]);

  const onDaySelection = (day) => {
    const days = [...selectedWeek];
    const currentIndex = days.findIndex((selectedDays) => selectedDays === day);
    if (currentIndex !== -1) {
      days.length > minSelection && days.splice(currentIndex, 1);
    } else {
      days.push(day);
    }
    setSelectedWeek(days);
    setNudgeDays(days);
  };
  return (
    <>
      {viewOnly && (
        <div className={`${viewOnly ? "pointer-none" : ""} tiles-container`}>
          <div className="tile-container__tile-list">
            {selectedWeek?.map((week, index) => {
              return (
                <>
                  {selectedWeek.includes(week) && (
                    <div key={index} className="tile-container__tile fs-12">
                      {WEEKS_DAYS[week]?.doubleChar}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}

      {!viewOnly && (
        <div className="week-list flex-between ml-2">
          {Object.keys(WEEKS_DAYS).map((day, idx) => (
            <FormButton
              key={idx}
              className={`week-list__tile flex-center mr-2 fs-14 ${
                selectedWeek.includes(day) ? "week-list__tile--selected" : ""
              }`}
              onClick={() => {
                onDaySelection(day);
              }}
              variant="normal-link"
            >
              {WEEKS_DAYS[day].doubleChar}
            </FormButton>
          ))}
        </div>
      )}
    </>
  );
};

WeekDaysSelector.propTypes = {
  weeksData: PropTypes.array.isRequired,
  minSelection: PropTypes.number,
};

WeekDaysSelector.defaultProps = {
  selectedWeek: defaultWeeksData,
  viewOnly: true,
  setNudgeDays: () => {},
  minSelection: 1,
};

export default WeekDaysSelector;
