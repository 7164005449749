import PropTypes from "prop-types";
import React from "react";
import { NonMonetaryLabel } from "src/components";
import { classNames } from "src/helpers";
import "./amountStatsOverview.scss";

const AmountStatsOverview = (props) => {
  const { stats, className } = props;

  return (
    <div className={classNames(["stats-overview br-4", className])}>
      {stats.map((stat, index) => {
        const {
          name,
          icon,
          isAmountStat,
          value,
          amount,
          rewards,
          rewardCount,
          payload,
        } = stat;

        if (isAmountStat) {
          return (
            <div
              key={`${name}-${index}`}
              className={classNames([
                "d-flex justify-content-between p-12 fs-12",
                index === stats.length - 1 ? "" : "border-bottom-1 bc-grey4",
              ])}
            >
              <div className="d-flex align-items-center">
                <div className="mr-2">{icon}</div>
                <div>{name}</div>
              </div>
              <NonMonetaryLabel
                amount={amount}
                nonMonetaryRewards={rewards}
                rewardCount={rewardCount}
                payload={payload}
                className="d-flex semi-bold"
                nonMonetaryClassName="stat-nonmonetary semi-bold"
                showMobileFlatlistModal
                rewardModalTitle={name}
              />
            </div>
          );
        }

        return (
          <div
            key={`${name}-${index}`}
            className={classNames([
              "d-flex justify-content-between p-12 fs-12",
              index === stats.length - 1 ? "" : "border-bottom-1 bc-grey4",
            ])}
          >
            <div className="d-flex align-items-center">
              <div className="mr-2">{icon}</div>
              <div>{name}</div>
            </div>
            <div className="semi-bold">{value}</div>
          </div>
        );
      })}
    </div>
  );
};

AmountStatsOverview.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.element,
      isAmountStat: PropTypes.bool.isRequired,
      value: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      rewards: PropTypes.array,
      rewardCount: PropTypes.number,
    })
  ),
};

AmountStatsOverview.defaultProps = {
  id: "amountStatsOverview",
  className: "",
  stats: [],
};

export default AmountStatsOverview;
