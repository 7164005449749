import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_LEADERBOARD_RECURRENCE_SUMMARY_HAVING_RULES_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderboardRecurrenceSummary($LeaderboardId: ID!) {
    getLeaderboardRecurrenceSummary(LeaderboardId: $LeaderboardId) {
      __typename
      ... on LeaderboardRecurrenceSummaryResponse {
        success
        message
        response {
          Recurrences {
            LeaderboardId
            RecurrenceId
            RecurrenceStartDate
            RecurrenceEndDate
            RecurrenceType
            TotalUsers
            IsGroupBased
            SelectedUsersCount
            BufferDays
            GroupsCount
            RankAndReward
            TieBreaker
            WinnerConfig
            IsGroupConfigured
            RecurrenceStatus
            UserIdentifier
            isProcessed
            IsCalculationProcessed
            __typename
          }
          CurrentRecurrence {
            LeaderboardId
            RecurrenceEndDate
            RecurrenceId
            RecurrenceStartDate
            RecurrenceType
            BufferDays
            TotalUsers
            SelectedUsersCount
            IsGroupBased
            GroupsCount
            RankAndReward
            TieBreaker
            WinnerConfig
            IsGroupConfigured
            RecurrenceStatus
            UserIdentifier
            isProcessed
            IsCalculationProcessed
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;

export const useGetLeaderboardRecurrenceSummaryWithRules = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_RECURRENCE_SUMMARY_HAVING_RULES_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
