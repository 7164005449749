import React from "react";
import BannerCardListItem from "./BannerCardListItem";
import LoadingBannerList from "./LoadingBannerList";

const BannerCardList = (props) => {
  const { data, loading, emptyState } = props;

  if (loading) return <LoadingBannerList />;

  if (!data || data.length === 0)
    return <div className="flex-center h-100">{emptyState}</div>;

  return (
    <>
      {data.map((item) => (
        <BannerCardListItem {...item} />
      ))}
    </>
  );
};

export default BannerCardList;
