import { useState } from "react";
import { isActiveFilter } from "./data";

function convertFiltersToObject(filters) {
  const result = {};
  for (const filter of filters) {
    if (filter.filters) {
      for (const subfilter of filter.filters) {
        result[subfilter.key] =
          typeof subfilter.defaultValue !== "undefined"
            ? subfilter.defaultValue
            : null;
      }
    } else {
      result[filter.key] =
        typeof filter.defaultValue !== "undefined" ? filter.defaultValue : null;
    }
  }
  return result;
}

function convertFilterKeysToObject(filters) {
  return filters.reduce((result, filter) => {
    result[filter.key] = false;
    return result;
  }, {});
}

const useFilter = (filters, defaultValue) => {
  const [filtersToggleState, setFiltersToggleState] = useState(
    convertFilterKeysToObject(filters)
  );
  const filtersObjectWithDefaultValues =
    defaultValue ?? convertFiltersToObject(filters);

  const [filtersState, setFiltersState] = useState(
    filtersObjectWithDefaultValues
  );

  const handleBulkUpdate = (selectedFilters) => {
    setFiltersState((prevState) => {
      return {
        ...prevState,
        ...selectedFilters,
      };
    });
  };

  const handleChange = ({ key, value }) => {
    setFiltersState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const toggleFilter = (key) => {
    setFiltersToggleState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const isFilterActive = filters.some((filter) =>
    isActiveFilter({ filtersState, filter })
  );

  return {
    isFilterActive,
    filtersToggleState,
    filtersState,
    handleChange,
    handleBulkUpdate,
    toggleFilter,
  };
};

export default useFilter;
