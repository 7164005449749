import * as React from "react";

const SvgCheckMark = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      id="Group_16615"
      data-name="Group 16615"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      role="img"
      {...rest}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_4148"
            data-name="Rectangle 4148"
            width={13.6}
            height={10.8}
          />
        </clipPath>
      </defs>
      <g id="Group_16614" data-name="Group 16614">
        <rect
          id="Rectangle_4147"
          data-name="Rectangle 4147"
          width={width}
          height={height}
          fill="none"
        />
        <g
          id="completed"
          transform="translate(1.2 2.6)"
          clipPath="url(#clip-path)"
        >
          <g id="Completed-Green" transform="translate(-0.176 0.197)">
            <g id="Group_3570" data-name="Group 3570">
              <path
                id="Path_580"
                data-name="Path 580"
                d="M29.783,49.909l-4.228-4.1a.17.17,0,0,1,0-.25l1.567-1.521a.183.183,0,0,1,.258,0L29.915,46.5l7.045-6.838a.183.183,0,0,1,.258,0l1.567,1.521a.17.17,0,0,1,0,.25l-8.746,8.48a.183.183,0,0,1-.255,0Z"
                transform="translate(-25.5 -39.605)"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgCheckMark.defaultProps = {
  width: 16,
  height: 16,
  color: "#2ecc71",
};

export default SvgCheckMark;
