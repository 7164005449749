import React from "react";
import { Image } from "src/components";
import RepublishGoalConfirmationImg from "./RepublishGoalConfirmation.svg";

const RepublishGoalConfirmation = (props) => (
  <Image
    src={RepublishGoalConfirmationImg}
    alt="RepublishGoalConfirmationImg"
    {...props}
  />
);

export default RepublishGoalConfirmation;
