import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { FormTooltip } from "src/components/common/formElements";
import { useRoleBasedContext } from "src/context";
import { useViewport } from "src/hooks";

const GoalOwnerDropdown = (props) => {
  const {
    id,
    data,
    onChangeHandler,
    defaultOwner,
    Owner = [],
    hideGoalCount = true,
    preSelect = true,
  } = props;
  const DefaultOwner = Owner.length !== 0 ? Owner : defaultOwner;
  const [ownerChange, setOwnerChange] = useState(false);
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  const { userDetails } = useRoleBasedContext();

  let customStylesOwner = {
    container: (provided) => ({
      ...provided,
      fontSize: "12px",
      height: "40px",
    }),
    control: (provided) => ({ ...provided, width: "275px" }),
    menu: (provided) => ({
      ...provided,
      marginTop: "-1px !important",
      borderTop: "1px solid #309afc",
      borderRadius: "0 4px 4px 4px !important",
      width: "500px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black",
      cursor: "pointer",
      backgroundColor: state.isFocused ? "#f1fafe" : "",
      padding: "12px 16px",
    }),
  };

  if (isMobile) {
    customStylesOwner = {
      ...customStylesOwner,
      control: (provided) => ({ ...provided, width: "auto" }),
      menu: (provided) => ({ ...provided, width: "100%" }),
    };
  }

  const formatOptionLabel = ({
    FullName,
    EmailAddress,
    GoalCount,
    UserID,
    IsManagingUsers,
    isDisabled = false,
  }) => (
    <FormTooltip
      id="goal-owner-dropdown-tooltip"
      placement="bottom"
      text={t("MANAGED_GOALS.MESSAGES.CANNOT_CREATE_GOALS")}
      disable={!isDisabled}
    >
      <div
        className={` ${
          UserID ===
            (DefaultOwner.length
              ? DefaultOwner[0].UserID
              : DefaultOwner.UserID) && (!preSelect ? ownerChange : true)
            ? "fc-blue"
            : "black"
        } ${
          isDisabled ? "not-allowed fc-grey" : ""
        } d-flex justify-content-between`}
      >
        {isMobile ? (
          <div className="w-75">
            <div className="d-inline-flex">
              <p className="semi-bold text-truncate">{FullName}</p>
              {userDetails.id === UserID ? (
                <span className="fc-grey pl-1">({t("COMMON.LABELS.YOU")})</span>
              ) : null}
            </div>
            <div className="text-truncate">{EmailAddress}</div>
          </div>
        ) : (
          <>
            <div className="d-inline-flex w-225">
              <p className="max-w-150 semi-bold text-truncate">{FullName}</p>
              {userDetails.id === UserID ? (
                <span className="fc-grey pl-1">({t("COMMON.LABELS.YOU")})</span>
              ) : null}
            </div>
            <div className="ml-1 text-truncate w-225">{EmailAddress}</div>
          </>
        )}

        {hideGoalCount ? (
          <div className={`${isMobile ? "w-25" : "w-51  ml-2"}`}>
            {GoalCount}
          </div>
        ) : null}
      </div>
    </FormTooltip>
  );
  const formatGroupLabel = (data) => (
    <div className="d-flex align-items-center">
      <div className="fs-12 fc-grey white-space-wrap mr-2 text-capitalize">
        <b>{data.label}</b>
      </div>
      <div className="w-100 border-b" />
    </div>
  );
  const Menu = (props) => {
    return (
      <components.Menu {...props}>
        <div>
          <div className="d-flex justify-content-between bg-grey px-3 py-2 br-4">
            {isMobile ? (
              <div className="fc-grey w-75">
                {t("MANAGED_GOALS.LABELS.OWNER")} &{" "}
                {t("MANAGED_GOALS.LABELS.EMAIL")}
              </div>
            ) : (
              <>
                <div className="fc-grey w-225">
                  {t("MANAGED_GOALS.LABELS.OWNER")}
                </div>
                <div className="fc-grey ml-1 w-225">
                  {t("MANAGED_GOALS.LABELS.EMAIL")}
                </div>
              </>
            )}
            {hideGoalCount ? (
              <div
                className={`fc-grey ${isMobile ? "w-25 mr-2" : "w-51 ml-1"}`}
              >
                {t("COMMON.BUTTONS.GOALS")}
              </div>
            ) : null}
          </div>
          <div>{props.children}</div>
        </div>
      </components.Menu>
    );
  };

  const customFilter = (option, searchText) => {
    if (option.data.FullName.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }
    return false;
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;

    const values = getValue();
    let valueLabel = "";

    if (values.length > 0)
      valueLabel += props.selectProps.getOptionLabel(values[0]);

    const childrenToRender = React.Children.toArray(children).filter(
      (child) =>
        ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );
    return (
      <components.ValueContainer {...props}>
        {childrenToRender}
        {!props.selectProps.inputValue && valueLabel ? (
          values[0].UserID === userDetails.id ? (
            <div className="d-flex mln-3">
              <p className="max-w-160 text-truncate">{valueLabel}</p>
              <span className="fc-grey pl-1">({t("COMMON.LABELS.YOU")})</span>
            </div>
          ) : (
            <div className="d-flex mln-3">
              <p className="max-w-160 text-truncate">{valueLabel}</p>
            </div>
          )
        ) : (
          false
        )}
      </components.ValueContainer>
    );
  };

  return (
    <Select
      classNamePrefix="drop-select"
      name="goalOwner"
      styles={customStylesOwner}
      components={{ Menu, ValueContainer }}
      id={id || "managing_users"}
      defaultValue={
        preSelect
          ? DefaultOwner.length
            ? DefaultOwner[0]
            : DefaultOwner
          : null
      }
      onChange={(e) => {
        onChangeHandler(e);
        setOwnerChange(true);
      }}
      options={data}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      filterOption={customFilter}
      getOptionLabel={(option) => `${option.FullName} `}
    />
  );
};
export default GoalOwnerDropdown;
