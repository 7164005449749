import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_GROUPLIST_WITH_TARGET = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getGroupList(
    $LeaderboardId: ID!
    $RecurrenceId: ID!
    $RestrictleaderboardGroups: Boolean
    $NonGroup: Boolean
  ) {
    getGroupList(
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
      RestrictleaderboardGroups: $RestrictleaderboardGroups
      NonGroup: $NonGroup
    ) {
      ... on GetGroupListResponse {
        message
        success
        response {
          GroupId
          UserIdentifier
          GroupName
          Target
          RewardCount
          UserCount
          PendingRequests
          PendingPayoutRequests
          TotalPayoutRequests
        }
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;

export const useGetGroupListWithTarget = (queryOptions) => {
  return useQuery(GET_GROUPLIST_WITH_TARGET, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
