import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_LINKED_PROGRAMS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLinkedPrograms($ProgramId: ID!, $RecurrenceId: ID!, $UserId: ID!) {
    linkedPrograms(
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      UserId: $UserId
    ) {
      ... on LinkedProgramsResponse {
        response {
          Programs {
            ProgramId
            RecurrenceId
            RecurrenceStartDate
            RecurrenceEndDate
            Name
            EligibleBenefit
            EligibleRewards
            LinkedProgramsCount
            IsUserPartOfRecurrence
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetLinkedPrograms = (queryOptions) => {
  return useQuery(GET_LINKED_PROGRAMS_QUERY, queryOptions);
};
