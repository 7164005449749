import React from "react";
import { APPS } from "src/constants";
import { Redirect, Route, Switch } from "src/router";
import { useNudgesRbac } from "./hooks";
import { Pages } from "./pages";

export function Routes() {
  const { nudgesRbac, DEFAULT_LOGGED_IN_USER_REDIRECTION } = useNudgesRbac();

  return (
    <Switch>
      <Route
        path={APPS.NUDGES.ROUTES.LANDING.path}
        page={Pages.LandingPage}
        nestedRoutes={[APPS.NUDGES.ROUTES.PROGRESS_NUDGE.path]}
        isPage
        {...nudgesRbac(APPS.NUDGES.ROUTES.LANDING.path)}
      >
        <div className="page-container">
          <Switch>
            <Route
              path={APPS.NUDGES.ROUTES.PROGRESS_NUDGE.path}
              page={Pages.ProgressNudgePage}
              exact
              {...nudgesRbac(APPS.NUDGES.ROUTES.PROGRESS_NUDGE.path)}
            />
            <Route path="*">
              <Redirect to={DEFAULT_LOGGED_IN_USER_REDIRECTION} />
            </Route>
          </Switch>
        </div>
      </Route>
      <Route
        path={APPS.NUDGES.ROUTES.REQUEST_DEMO.path}
        page={Pages.RequestDemoPage}
        isPage
        exact
        {...nudgesRbac(APPS.NUDGES.ROUTES.REQUEST_DEMO.path)}
      />
      <Route path="*">
        <Redirect to={APPS.NUDGES.ROUTES.LANDING.path} />
      </Route>
    </Switch>
  );
}
