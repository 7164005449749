import React from "react";
import { Pie, PieChart, ResponsiveContainer } from "recharts";
import { COLORS } from "src/constants";

const CircularProgressBar = (props) => {
  const {
    percentageText,
    percentage,
    backgroundColor = COLORS.WHITE,
    markerColor,
    fontColor,
  } = props;

  const angle = Math.min(Math.max(percentage, 0), 100);

  const data = [
    {
      name: "progress",
      value: angle,
      fill: markerColor,
    },
    {
      name: "remaining",
      value: 100 - angle,
      fill: backgroundColor,
    },
  ];

  return (
    <div className="flex-center position-relative circular-progress-bar">
      <div style={{ height: "68px", width: "68px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width="68px" height="68px">
            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={34}
              innerRadius={26}
              paddingAngle={0}
              startAngle={90}
              endAngle={-270}
              cornerRadius={10}
              key={Math.random()}
              stroke="none"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <span
        style={{ color: fontColor }}
        className="position-absolute fs-23 semi-bold"
      >
        {percentageText || percentage}%
      </span>
    </div>
  );
};

export default CircularProgressBar;
