import React, { createContext, useContext, useState } from "react";
import { useViewport } from "src/hooks";

const AppShellContext = createContext();

const useProvideAppShell = ({ routes }) => {
  const [shellState, setShellState] = useState({
    showAppbar: true,
    meta: {
      title: "",
    },
  });
  const { isMobile } = useViewport();

  const setMeta = (meta) => {
    setShellState((prevState) => ({ ...prevState, meta }));
  };

  return {
    ...shellState,
    isMobile,
    setMeta,
    routes,
  };
};

export const ProvideAppShell = ({ routes, children }) => {
  const appShell = useProvideAppShell({ routes });
  return (
    <AppShellContext.Provider value={appShell}>
      {children}
    </AppShellContext.Provider>
  );
};

export const useAppShell = () => {
  return useContext(AppShellContext);
};
