import { getTimezoneOffset, utcToZonedTime } from "src/helpers/vendors";
import { useAuth } from "src/hooks";

export const getTimezone = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { auth } = useAuth();
  const { userAttributesForContext } = auth;
  if (userAttributesForContext) {
    const userAttributesParsed = JSON.parse(userAttributesForContext);
    if (userAttributesParsed.UserTimeZone) {
      return userAttributesParsed.UserTimeZone;
    }
    if (userAttributesParsed.AccountTimeZone) {
      return userAttributesParsed.AccountTimeZone;
    }
    return "";
  }
  return "";
};

export const getTodayAsPerTimezone = () => {
  const today = new Date();
  const utcDate = today.toISOString();
  const date = utcToZonedTime(utcDate, getTimezone());
  return date;
};

export const getSystemOffset = () =>
  getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);

export const getUserTimezoneOffset = () => getTimezoneOffset(getTimezone());
