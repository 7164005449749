import React from "react";
import { Image } from "src/components";
import LeaderboardCardBackgroundImg from "./LeaderboardCardBackground.png";

const LeaderboardCardBackground = (props) => (
  <Image
    src={LeaderboardCardBackgroundImg}
    alt="LeaderboardCardBackgroundImg"
    {...props}
  />
);

export default LeaderboardCardBackground;
