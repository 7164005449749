import { gql, useMutation } from "src/api/client";

const DELETE_INCENTIVE_MUTATION = gql`
  mutation deleteProgram($ProgramId: ID!) {
    deleteProgram(ProgramId: $ProgramId) {
      ... on deleteProgramResponse {
        success
        message
        response
      }
    }
  }
`;

export const useDeleteIncentive = () => {
  return useMutation(DELETE_INCENTIVE_MUTATION);
};
