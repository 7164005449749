import { SvgCondition } from "src/icons";
import ConditionPatternConfig from "./ConditionPatternConfig";

const ConditionPattern = ({
  conditions,
  conditionPattern,
  setConditionPattern,
  disabled,
  criteriaIndex,
  hideConditionPattern,
}) => {
  return (
    <div>
      {!hideConditionPattern ? <hr className="mt-4 mb-3" /> : null}
      <div className="pr-3">
        {!hideConditionPattern ? (
          <>
            <SvgCondition className="mr-3 ml-2" />
            <span className="fs-13 fc-black semi-bold">Condition Pattern</span>
            <span className="fs-12 fc-grey ml-2">
              Interchange AND/OR operators, and group the conditions using
              braces.
            </span>
          </>
        ) : null}
        <div className="mt-3" style={{ marginLeft: 40 }}>
          <ConditionPatternConfig
            inputClassName="item-body"
            condition={conditions}
            conditionPattern={conditionPattern}
            setConditionPattern={setConditionPattern}
            disabled={disabled}
            criteriaIndex={criteriaIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default ConditionPattern;
