import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { FormButton, FormTooltip } from "src/components/common/formElements";
import { Calendar } from "src/components/common/molecules";
import { COLORS, MAX_CYCLE_DAYS } from "src/constants";
import {
  addDays,
  differenceInDays,
  endOfDay,
  getPluralText,
  getRecurrenceStatus,
  getTodayAsPerTimezone,
  startOfDay,
} from "src/helpers";
import { getCycleInterval } from "src/helpers/getCycleInterval";
import {
  SvgClock,
  SvgDelete,
  SvgEdit,
  SvgLive,
  SvgPlus,
  SvgTick,
} from "src/icons";
import "./nonUniformCycleItem.scss";
import { getNonUniformCycleItemValidation } from "./validation";

const NonUniformCycleItem = (props) => {
  const {
    index,
    minDate,
    maxDate,
    recurrenceStartDate,
    recurrenceEndDate,
    editMode,
    handleAdd,
    handleEdit,
    handleDone,
    handleDelete,
    handleEditDelete,
    disableAdd,
    maxAdd,
    globalEditMode,
    disabledMinDelete,
    isDraft,
    isBeforeCycle,
    isLastCycle,
    programEndDate,
    moduleName,
  } = props;
  const today = getTodayAsPerTimezone();
  const [startDate, setStartDate] = useState(recurrenceStartDate);
  const [endDate, setEndDate] = useState(recurrenceEndDate);

  const recurrenceStatus = getRecurrenceStatus({
    RecurrenceStartDate: startDate,
    RecurrenceEndDate: endDate,
  });

  useEffect(() => {
    setStartDate(recurrenceStartDate);
    setEndDate(recurrenceEndDate);
  }, [recurrenceStartDate, recurrenceEndDate]);

  const { EDIT, DELETE, ADD } = getNonUniformCycleItemValidation({
    programEndDate,
    endDate,
    disabledMinDelete,
    maxAdd,
    disableAdd,
    isDraft,
    isBeforeCycle,
    isLastCycle,
    moduleName,
  });

  if (editMode) {
    return (
      <div className="br-6 non-uniform-cycle-edit-mode d-flex px-4 py-3 space-x-24">
        <span>#{index}.</span>
        <div className="d-flex flex-column w-100">
          <div className="center">
            <span className="fs-14">
              Enter the date range for{" "}
              <span className="semi-bold">cycle {index}</span>
            </span>
            <div className="ml-auto center space-x-24">
              {startDate && endDate && (
                <FormButton
                  variant="link"
                  onClick={() =>
                    handleDone({ index: index - 1, startDate, endDate })
                  }
                >
                  <span className="fs-14 semi-bold fc-primary-blue">Done</span>
                </FormButton>
              )}
              <FormTooltip
                id="delete-tooltip"
                placement="bottom"
                text={DELETE.TOOLTIP_TEXT}
              >
                <FormButton
                  onClick={
                    DELETE.DISABLED
                      ? undefined
                      : () => handleEditDelete(index - 1)
                  }
                  variant="only-icon"
                  icon={<SvgDelete color={COLORS.GREY_2} />}
                />
              </FormTooltip>
            </div>
          </div>
          <div className="center mt-3">
            <div>
              <div className="fs-13 mb-1">Start Date</div>
              <div className="w-336">
                <Calendar
                  id="start-date-1"
                  inputId="value-input"
                  placeholder={t(
                    "INCENTIVES.DASHBOARD.LABELS.SELECT_START_DATE"
                  )}
                  selected={startDate || null}
                  onChange={(date) => {
                    setStartDate(date);
                    setEndDate(null);
                  }}
                  disabled={!isDraft && (isBeforeCycle || isLastCycle)}
                  minDate={minDate}
                  maxDate={maxDate}
                  today={today}
                />
              </div>
            </div>
            <div className="fs-12 fc-grey1 pt-4 mx-3">To</div>
            <div>
              <div className="fs-13 mb-1">End Date</div>
              <div className="w-336">
                <Calendar
                  id="start-date-1"
                  inputId="value-input"
                  placeholder={t("INCENTIVES.DASHBOARD.LABELS.SELECT_END_DATE")}
                  disabled={!startDate}
                  selected={endDate || null}
                  onChange={(date) => setEndDate(date)}
                  minDate={!isDraft && isLastCycle ? programEndDate : startDate}
                  maxDate={maxDate || addDays(startDate, MAX_CYCLE_DAYS - 1)}
                  today={today}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const getBadgeColor = () => {
    switch (recurrenceStatus) {
      case "INPROGRESS":
        return "non_uniform_days_badge bg-blue2 semi-bold fc-black";
      case "COMPLETED":
        return "non_uniform_days_badge bg-grey6 fc-grey1";
      default:
        return "non_uniform_days_badge bg-blue2 fc-black";
    }
  };

  return (
    <div
      className={`${
        !globalEditMode ? "non-uniform-cycle-item" : ""
      } br-6 border-1 bc-transparent center bg-grey6 px-4 py-3`}
    >
      {!isDraft && recurrenceStatus && (
        <span className="w-23 flex-center">
          {recurrenceStatus === "COMPLETED" ? (
            <SvgTick color="#2ecc71" backgroundColor="none" />
          ) : recurrenceStatus === "INPROGRESS" ? (
            <SvgLive width={14} height={14} />
          ) : (
            <SvgClock
              width={14}
              height={14}
              color={COLORS.GREY_2}
              backgroundColor="none"
            />
          )}
        </span>
      )}
      <span
        className={`${
          !isDraft && recurrenceStatus
            ? recurrenceStatus === "COMPLETED"
              ? "fc-grey"
              : recurrenceStatus === "INPROGRESS"
              ? "fc-black semi-bold"
              : "fc-black"
            : "fc-black"
        } mr-3`}
      >
        #{index}.
      </span>
      {startDate && endDate && (
        <>
          <span
            className={`${
              !isDraft && recurrenceStatus
                ? recurrenceStatus === "COMPLETED"
                  ? "fc-grey"
                  : recurrenceStatus === "INPROGRESS"
                  ? "fc-black semi-bold"
                  : "fc-black"
                : "fc-black"
            } w-300`}
          >
            {getCycleInterval(startDate, endDate)}
          </span>
          <span>
            <div className={`fs-11 ml-3 br-13 px-2 py-1 ${getBadgeColor()}`}>
              {getPluralText(
                differenceInDays(
                  endOfDay(addDays(endDate, 1)),
                  startOfDay(startDate)
                ),
                t("INCENTIVES.COMMON.MESSAGES.DAY"),
                t("INCENTIVES.COMMON.MESSAGES.DAYS")
              )}
            </div>
          </span>
        </>
      )}
      {!globalEditMode && (
        <span className="ml-auto center space-x-24 btn-group">
          <FormTooltip
            id="edit-tooltip"
            placement="bottom"
            text={EDIT.TOOLTIP_TEXT}
          >
            <FormButton
              variant="only-icon"
              icon={<SvgEdit />}
              className={`${
                EDIT.DISABLED ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={EDIT.DISABLED ? undefined : () => handleEdit(index - 1)}
            />
          </FormTooltip>
          <FormTooltip
            id="delete-tooltip"
            placement="bottom"
            text={DELETE.TOOLTIP_TEXT}
          >
            <FormButton
              variant="only-icon"
              icon={<SvgDelete color={COLORS.GREY_2} />}
              className={`${
                DELETE.DISABLED ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={
                DELETE.DISABLED ? undefined : () => handleDelete(index - 1)
              }
            />
          </FormTooltip>
          <FormTooltip
            id="add-tooltip"
            placement="bottom"
            text={ADD.TOOLTIP_TEXT}
          >
            <FormButton
              className={`${
                ADD.DISABLED ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              variant="only-icon"
              icon={<SvgPlus color={COLORS.GREY_2} />}
              onClick={ADD.DISABLED ? undefined : () => handleAdd(index - 1)}
            />
          </FormTooltip>
        </span>
      )}
    </div>
  );
};

NonUniformCycleItem.propTypes = {};

export default NonUniformCycleItem;
