import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_GOALS_FOR_TEMPLATES = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query goalsForTemplate(
    $TemplateId: ID!
    $FirstRecurrenceStartDate: String!
    $LastRecurrenceEndDate: String!
    $RecurrencePeriod: RecurrencePeriodEnum!
    $CustomType: CustomTypeEnum
    $IsRollUp: Boolean!
    $Recurrences: [RecurrenceCycles]
    $RollupType: String
  ) {
    goalsForTemplate(
      TemplateId: $TemplateId
      FirstRecurrenceStartDate: $FirstRecurrenceStartDate
      LastRecurrenceEndDate: $LastRecurrenceEndDate
      RecurrencePeriod: $RecurrencePeriod
      CustomType: $CustomType
      IsRollUp: $IsRollUp
      Recurrences: $Recurrences
      RollupType: $RollupType
    ) {
      __typename
      ... on GoalsForTemplateResponse {
        __typename
        success
        message
        response {
          GoalId
          Name
          GoalUsersCount
          GoalCycles
          StartDate
          EndDate
          Recurrences
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGoalsForTemplate = (queryOptions) => {
  return useQuery(GET_GOALS_FOR_TEMPLATES, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
