import PropTypes from "prop-types";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../tooltip.scss";

const FormTooltip = (props) => {
  const { disable, placement, wrapperClassName, text, children, id } = props;
  const renderTooltip = ({ style, ...rest }) => (
    <Tooltip id={id} style={{ ...style }} {...rest}>
      {text}
    </Tooltip>
  );

  return !disable ? (
    <OverlayTrigger
      transition={null}
      placement={placement}
      overlay={renderTooltip}
    >
      {({ ref, ...triggerHandler }) => (
        <span ref={ref} {...triggerHandler} className={wrapperClassName}>
          {children}
        </span>
      )}
    </OverlayTrigger>
  ) : (
    children
  );
};

FormTooltip.propTypes = {
  placement: PropTypes.oneOf([
    "top",
    "bottom",
    "left",
    "right",
    "bottom-start",
  ]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
FormTooltip.defaultProps = {
  placement: "top",
  id: "tooltip",
  disable: false,
};
export default FormTooltip;
