import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgEligibleAmount = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g>
        <path
          fill={color}
          d="M1.963 19.596a1.328 1.328 0 0 1-1.326-1.325L.619 1.724A1.325 1.325 0 0 1 1.945.404h11.3a1.324 1.324 0 0 1 1.324 1.32l.01 6.193a6.681 6.681 0 0 0-.672-.036 5.765 5.765 0 0 0-.729.044l.053-6.073a.125.125 0 0 0-.123-.129H2.094a.126.126 0 0 0-.125.129l-.137 16.29a.125.125 0 0 0 .125.129h7.926a6.042 6.042 0 0 0 2.361 1.325Zm6.512-5.717a5.453 5.453 0 0 1 5.451-5.455 5.454 5.454 0 0 1 5.455 5.455 5.453 5.453 0 0 1-5.455 5.451 5.452 5.452 0 0 1-5.452-5.451Zm1.215 0a4.245 4.245 0 0 0 4.236 4.241 4.247 4.247 0 0 0 4.246-4.241 4.247 4.247 0 0 0-4.246-4.241 4.245 4.245 0 0 0-4.237 4.241Zm-3.832 2.2a.58.58 0 0 1 .582-.578.577.577 0 0 1 .578.578.581.581 0 0 1-.578.582.584.584 0 0 1-.583-.586Zm-2.322 0a.582.582 0 0 1 .584-.578.577.577 0 0 1 .578.578.581.581 0 0 1-.578.582.586.586 0 0 1-.585-.586Zm9.986.062v-.231a1.247 1.247 0 0 1-.846-1.178.407.407 0 0 1 .406-.4.407.407 0 0 1 .4.4.442.442 0 0 0 .439.445.446.446 0 0 0 .449-.445.446.446 0 0 0-.449-.445 1.255 1.255 0 0 1-1.25-1.254 1.253 1.253 0 0 1 .846-1.183v-.227a.4.4 0 0 1 .4-.4.4.4 0 0 1 .4.4v.227a1.251 1.251 0 0 1 .85 1.183.4.4 0 0 1-.4.4.4.4 0 0 1-.4-.4.446.446 0 0 0-.449-.445.442.442 0 0 0-.439.445.442.442 0 0 0 .439.445 1.253 1.253 0 0 1 1.254 1.254 1.245 1.245 0 0 1-.85 1.178v.231a.4.4 0 0 1-.4.4.406.406 0 0 1-.403-.403Zm-7.664-2.379a.584.584 0 0 1 .582-.582.581.581 0 0 1 .578.582.577.577 0 0 1-.578.578.58.58 0 0 1-.583-.581Zm-2.322 0a.586.586 0 0 1 .584-.582.581.581 0 0 1 .578.582.577.577 0 0 1-.578.578.582.582 0 0 1-.585-.581Zm2.322-2.321a.58.58 0 0 1 .582-.578.577.577 0 0 1 .578.578.577.577 0 0 1-.578.578.58.58 0 0 1-.583-.581Zm-2.322 0a.582.582 0 0 1 .584-.578.577.577 0 0 1 .578.578.577.577 0 0 1-.578.578.582.582 0 0 1-.585-.581ZM6.36 9.703a.5.5 0 0 1-.5-.5v-.156a.5.5 0 0 1 .5-.5h1.316a.5.5 0 0 1 .5.5v.156a.5.5 0 0 1-.5.5Zm-2.824-.582a.582.582 0 0 1 .584-.578.577.577 0 0 1 .578.578.581.581 0 0 1-.578.582.586.586 0 0 1-.585-.585Zm-.021-1.716v-4.1h8.162v4.1Zm1.2-1.2h5.766v-1.7H4.715Z"
        />
        <g>
          <path fill="none" d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgEligibleAmount.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 20,
  width: 20,
};
export default SvgEligibleAmount;
