import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_LEADERBOARD_GROUP_RULE = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderboardGroupRule(
    $LeaderboardId: ID!
    $RecurrenceId: ID!
    $GroupId: ID
  ) {
    getLeaderboardGroupRule(
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
      GroupId: $GroupId
    ) {
      ... on GetLeaderboardGroupRuleResponse {
        success
        message
        response
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useGetLeaderboardGroupRule = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_GROUP_RULE, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
