import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgArrowBackground = (props) => {
  const { backgroundColor, color, height, width, rotate, styles, ...rest } =
    props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...styles,
        transform: `rotate(${rotate}deg)`,
        transformOrigin: "50% 50%",
      }}
      {...rest}
    >
      <defs>
        <clipPath id="l6r3__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l6r3__a)" data-name="Group 8561">
        <g data-name="Group 8559" transform="rotate(180 568 218.5)">
          <circle
            cx={16}
            cy={16}
            r={16}
            fill={backgroundColor}
            data-name="Ellipse 149"
            transform="translate(1104 405)"
          />
          <path
            fill={color}
            d="m1118.408 415-1.408 1.41 4.574 4.59-4.574 4.59 1.408 1.41 5.992-6Z"
            data-name="\u21B3Color"
          />
        </g>
      </g>
    </svg>
  );
};

SvgArrowBackground.defaultProps = {
  backgroundColor: "#d1dde7",
  color: COLORS.PRIMARY_BLUE,
  height: 32,
  width: 32,
  rotate: 0,
  styles: {},
};
export default SvgArrowBackground;
