import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgCalculationType = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l3dn3_a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l3dn3_a)" data-name="calculation type">
        <g stroke={color} strokeWidth={0.1} data-name="Group 12642">
          <g data-name="Group 12638">
            <path
              fill={color}
              d="M18.689 0H2.243A2.3 2.3 0 0 0 0 2.243v19.436a2.3 2.3 0 0 0 2.243 2.243h14.951c1.2 0 3.738-2.542 3.738-3.738V2.243A2.3 2.3 0 0 0 18.689 0Zm0 21.679H2.243V9.718h16.446Zm0-14.2H2.243V2.243h16.446ZM5.083 14.951h1.794a.643.643 0 0 0 .6-.6v-1.792a.643.643 0 0 0-.6-.6H5.083a.643.643 0 0 0-.6.6v1.794a.643.643 0 0 0 .6.598Zm8.971 4.485h1.794a.643.643 0 0 0 .6-.6v-6.277a.643.643 0 0 0-.6-.6h-1.794a.643.643 0 0 0-.6.6v6.279a.643.643 0 0 0 .6.6Zm-8.971 0h1.794a.643.643 0 0 0 .6-.6v-1.792a.643.643 0 0 0-.6-.6H5.083a.643.643 0 0 0-.6.6v1.794a.643.643 0 0 0 .6.598Zm4.485-4.485h1.794a.643.643 0 0 0 .6-.6v-1.792a.643.643 0 0 0-.6-.6H9.569a.643.643 0 0 0-.6.6v1.794a.643.643 0 0 0 .6.598Zm0 4.485h1.794a.643.643 0 0 0 .6-.6v-1.792a.643.643 0 0 0-.6-.6H9.569a.643.643 0 0 0-.6.6v1.794a.643.643 0 0 0 .6.598Z"
            />
            <g fill="#fff" data-name="Ellipse 210" transform="translate(12 12)">
              <circle cx={6} cy={6} r={6} stroke="none" />
              <circle cx={6} cy={6} r={5.95} fill="none" />
            </g>
            <path
              fill={color}
              d="M17.96 13.49a4.47 4.47 0 1 0 4.47 4.47 4.475 4.475 0 0 0-4.47-4.47m0-1.49A5.96 5.96 0 1 1 12 17.96 5.96 5.96 0 0 1 17.96 12Z"
              data-name="Ellipse 210 - Outline"
            />
          </g>
          <path
            fill={color}
            d="M18.498 19.138a.559.559 0 0 0-.139-.4 1.26 1.26 0 0 0-.472-.27 5.57 5.57 0 0 1-.574-.249 1.83 1.83 0 0 1-.414-.281 1.142 1.142 0 0 1-.269-.38 1.267 1.267 0 0 1-.097-.52 1.146 1.146 0 0 1 .33-.846 1.393 1.393 0 0 1 .877-.385v-.654h.489v.663a1.276 1.276 0 0 1 .846.451 1.485 1.485 0 0 1 .306.97h-.883a.843.843 0 0 0-.151-.549.5.5 0 0 0-.4-.182.515.515 0 0 0-.388.142.541.541 0 0 0-.138.393.517.517 0 0 0 .134.373 1.482 1.482 0 0 0 .5.287 5.549 5.549 0 0 1 .6.277 1.733 1.733 0 0 1 .4.293 1.136 1.136 0 0 1 .248.373 1.282 1.282 0 0 1 .079.494 1.141 1.141 0 0 1-.324.84 1.421 1.421 0 0 1-.892.382v.608h-.487v-.605a1.47 1.47 0 0 1-.97-.445 1.433 1.433 0 0 1-.344-1h.884a.813.813 0 0 0 .175.554.63.63 0 0 0 .5.194.607.607 0 0 0 .419-.142.493.493 0 0 0 .155-.386Z"
            data-name="Path 7868"
          />
        </g>
      </g>
    </svg>
  );
};

SvgCalculationType.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 24,
  width: 24,
};
export default SvgCalculationType;
