import { Truncate } from "src/components/common/atoms";
import { useHistory } from "src/router";
import "./styles.scss";

const LinkCell = ({ value, row: { original }, column }) => {
  const formattedValue = column.formatValue(original);
  const history = useHistory();

  const handleClick = () => {
    history.push(formattedValue.link);
  };
  return (
    <button className="semi-bold fs-13 link-cell" onClick={handleClick}>
      <Truncate
        maxWidth={`${column.minWidth - 24}px`}
        text={formattedValue.linkText}
      />
      {formattedValue.linkDescription ? (
        <div className="fs-13 fc-grey regular">
          <Truncate
            maxWidth={`${column.minWidth - 24}px`}
            text={formattedValue.linkDescription}
          />
        </div>
      ) : null}
    </button>
  );
};

export default LinkCell;
