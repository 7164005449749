import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgRulesets = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l6x3cba">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l6x3cba)">
        <path
          fill={color}
          d="M24 11.998a1.5 1.5 0 0 0-.9-1.38l-3.732-1.62L23.1 7.379a1.509 1.509 0 0 0 0-2.759L12.875.183a2.191 2.191 0 0 0-1.747 0L.9 4.62a1.509 1.509 0 0 0 0 2.759l3.732 1.619L.9 10.62a1.51 1.51 0 0 0 0 2.76l3.732 1.618L.9 16.62a1.51 1.51 0 0 0 0 2.76l10.228 4.438a2.179 2.179 0 0 0 .872.18 2.209 2.209 0 0 0 .871-.181L23.1 19.381a1.51 1.51 0 0 0 0-2.76l-3.732-1.623 3.734-1.62a1.5 1.5 0 0 0 .898-1.38ZM11.975 2.245Zm.025.011 8.632 3.742-8.607 3.757-8.654-3.757Zm8.634 15.742-8.609 3.753-8.654-3.753 4.088-1.774 3.67 1.592a2.179 2.179 0 0 0 .871.182 2.209 2.209 0 0 0 .871-.181l3.67-1.592Zm-8.609-2.247-8.654-3.753 4.088-1.774 3.669 1.592a2.179 2.179 0 0 0 .872.182 2.209 2.209 0 0 0 .871-.181l3.67-1.592 4.093 1.773Z"
        />
      </g>
    </svg>
  );
};

SvgRulesets.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 24,
  width: 24,
};
export default SvgRulesets;
