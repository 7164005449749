import { t } from "i18next";
import React from "react";
import { BaseCard, LoadMoreButton, Shimmer } from "src/components/common/atoms";
import { range } from "src/helpers";
import { NoGoals, TemplateEmptyState } from "src/images";
import { MobileRulesetCard } from "../mobileRulesetCard";

const MobileRulesetLandingPageBody = ({
  loading,
  getTemplates,
  onFetchMore,
  fetching,
}) => {
  const templateDetailsResult =
    getTemplates?.response?.Templates?.length > 0
      ? getTemplates.response.Templates
      : [];

  const disabledLoadMore =
    getTemplates?.response?.Templates?.length === 0 ||
    getTemplates?.response?.Templates.length ===
      getTemplates?.response?.totalCount;

  if (!loading && !getTemplates?.response?.hasTemplates) {
    return (
      <div className="w-100 mt-5 flex-column-center">
        <TemplateEmptyState />
        <div className="fs-14 semi-bold mt-5">
          {t("RULEBOOK.MESSAGES.NO_RULESET_CREATED")}
        </div>
      </div>
    );
  }

  if (!loading && templateDetailsResult.length === 0) {
    return (
      <div className="w-100 mt-5 flex-column-center">
        <NoGoals />
        <div className="fs-14 mt-3 fc-grey1">
          {t("RULEBOOK.MESSAGES.NO_RESULTS_AVAILABLE")}
        </div>
      </div>
    );
  }

  if (!fetching && loading)
    return (
      <>
        {range(0, 6).map((i) => (
          <BaseCard className="mt-3 bg-white flex-between mobile-ruleset-card h-100">
            <div className="w-100">
              <div className="fs-14 semi-bold center">
                <Shimmer width="80%" />
              </div>
              <div className="mt-2 center fs-12 fc-grey1">
                <Shimmer width="25%" />
                <Shimmer width="25%" className="ml-3" />
              </div>
            </div>
            <Shimmer width="20%" />
          </BaseCard>
        ))}
      </>
    );

  return (
    <>
      <div>
        {templateDetailsResult.map((ruleset) => (
          <MobileRulesetCard {...ruleset} />
        ))}
      </div>
      {!disabledLoadMore && (
        <div className="w-100 mt-3 flex-center">
          <LoadMoreButton
            variant="secondary"
            loading={loading}
            disabled={disabledLoadMore}
            id="template-grid-show-more"
            onClick={onFetchMore}
            itemsLoaded={getTemplates.response.Templates.length}
            totalItems={getTemplates.response.totalCount}
          />
        </div>
      )}
    </>
  );
};

export default MobileRulesetLandingPageBody;
