import React from "react";
import { Dropdown } from "react-bootstrap";
import { FormButton, Menu, useMenu } from "src/components";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgSort } from "src/icons";
import "./payoutViewListFilters.scss";

const PayoutViewListFilters = ({
  selectedFilter,
  handleClick,
  filters = [],
}) => {
  const menu = useMenu();
  const handleItemClick = (filter) => {
    handleClick(filter);
    menu.handleClose();
  };
  return (
    <div className="payout-view-list-filter dashboard-filter">
      <div ref={menu.containerRef}>
        <FormButton
          onClick={menu.handleClick}
          className={menu.el ? "filter show" : "filter"}
          icon={
            <SvgSort color={menu.el ? COLORS.PRIMARY_BLUE : COLORS.GREY_2} />
          }
          variant="only-icon"
        />
      </div>
      <Menu el={menu.el} container={menu.containerRef.current}>
        {filters.map((filter, idx) => {
          return (
            <Dropdown.Item
              active={
                filter?.key === selectedFilter?.key &&
                filter.order === selectedFilter.order
              }
              className={classNames([
                "fs-12 py-2",
                filter?.key !== filters[idx + 1]?.key ? "border-b" : "",
              ])}
              onClick={() => handleItemClick(filter)}
            >
              {filter.label}
            </Dropdown.Item>
          );
        })}
      </Menu>
    </div>
  );
};

export default PayoutViewListFilters;
