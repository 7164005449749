import { SearchBar } from "src/components/common/atoms/searchBar";
import { SvgSearchThin } from "src/icons";

export const REWARDS_FILTERS_SEARCH = {
  label: "Search",
  key: "search",
  defaultValue: "",
  icon: SvgSearchThin,
  component: SearchBar,
  props: ({ filter, filtersState, handleChange }) => ({
    className: "mt-3",
    placeholder: "Search Rewards",
    search: filtersState[filter.key],
    setSearch: (value) => handleChange({ key: filter.key, value }),
  }),
};
