import { t } from "i18next";
import React from "react";
import { FormBadge } from "src/components/common";
import { Shimmer, Truncate } from "src/components/common/atoms";
import { DRILLDOWN_STATUS_TYPES } from "src/constants";
import { MobileAuditBadge } from "../mobileAuditBadge";
import { RulesetRulesDetailView } from "../rulesetRulesDetailView";
import { RulesetStatusBadge } from "../rulesetStatusBadge";

const LoadingRulesetDetails = () => {
  return (
    <div className="p-3 border-b">
      <Shimmer width="100%" />
      <div className="center my-3">
        <Shimmer width="70px" />
        <Shimmer width="70px" className="ml-2" />
      </div>
      <Shimmer width="100%" />
    </div>
  );
};

const RulesetDetailView = (props) => {
  const { loading, templateDetailsResults, showRulesetBadge = true } = props;

  return (
    <>
      {!loading ? (
        <div className="p-3 bg-white border-bottom-1 bc-grey4">
          <div className="fs-14 semi-bold">
            <Truncate maxWidth="300px" text={templateDetailsResults?.Name} />
          </div>
          <div className="mt-12 center">
            {showRulesetBadge && (
              <div className="mr-2">
                <RulesetStatusBadge status={templateDetailsResults?.Status} />
              </div>
            )}
            <span>
              <MobileAuditBadge auditType={templateDetailsResults?.AuditType} />
            </span>
          </div>
          <div className="mt-2">
            <FormBadge className="center bg-secondary-blue">
              <span className="fc-blue">
                {t("COMMON.LABELS.ACHIEVEMENT_BREAKDOWN")}{" "}
                {t("RULEBOOK.MESSAGES.IS")}{" "}
                <span className="semi-bold">
                  {templateDetailsResults?.DrilldownStatus ===
                  DRILLDOWN_STATUS_TYPES.ENABLED
                    ? t("RULEBOOK.LABELS.ENABLED")
                    : t("RULEBOOK.LABELS.DISABLED")}
                </span>
              </span>
            </FormBadge>
          </div>
          {templateDetailsResults?.Description ? (
            <div className="mt-3 fs-12 fc-grey1 center">
              {templateDetailsResults.Description}
            </div>
          ) : null}
        </div>
      ) : (
        <LoadingRulesetDetails />
      )}
      <RulesetRulesDetailView {...{ loading, templateDetailsResults }} />
    </>
  );
};

export default RulesetDetailView;
