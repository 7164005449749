import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgFilter = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill="none" d="M0 0h16v16H0z" data-name="Rectangle 2249" />
      <path fill={color} d="M15.5 1.5H.5l6 7.095V13.5l3 1.5V8.595Z" />
    </svg>
  );
};

SvgFilter.defaultProps = {
  color: COLORS.GREY_3,
  height: 16,
  width: 16,
};
export default SvgFilter;
