import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { LoadingTabCards } from "../loadingTabCards";
import { LoadingTable } from "../loadingTable";

export const LoadingPublishedManagersDropdown = () => {
  return (
    <div className="d-flex py-3 flex-column">
      <div className="fs-13 semi-bold w-100 pb-2">
        <Shimmer width="100px" />
      </div>
      <div className="fs-12 flex-center h-40 border-1 bc-grey12 bg-grey4 semi-bold fc-grey2 w-275 fs-1 br-4">
        <Shimmer width="100%" height="40px" />
      </div>
    </div>
  );
};

export const LoadingPublishedGoalView = () => {
  return (
    <div className="mt-4">
      <div className="my-4 flex-between">
        <LoadingTabCards />
        <div className="center">
          <div className="mr-2">
            <Shimmer width="60px" height="30px" />
          </div>
          <Shimmer width="100px" height="30px" />
        </div>
      </div>
      <div>
        <LoadingTable />
      </div>
    </div>
  );
};
