import { t } from "i18next";
import React, { useState } from "react";
import { DisplayFormula } from "src/apps/incentives/components";
import { standardToCustomCondition } from "src/apps/incentives/components/formulaBuilder/utils";
import { AchievementDetailsModal } from "src/components/container";
import { COLORS } from "src/constants";
import { SvgArrow } from "src/icons";
import { FormButton, Truncate } from "../..";
const ConditionListItem = (props) => {
  const {
    index,
    title,
    subtitle,
    templateId,
    showDrillDown,
    mobileProgramDetails,
    condition,
    Name,
    programId,
    userId,
    recurrenceId,
    onTemplateSelection,
  } = props;

  const [showAchievementDetails, setShowAchievementDetails] = useState(false);
  const TemplateId = condition?.TemplateId || templateId;
  const isUserPartOfGoal =
    condition?.GoalId && Boolean("goalValue" in condition);

  const allowDrillDown =
    showDrillDown &&
    TemplateId &&
    (condition?.GoalId ? isUserPartOfGoal : true);

  const modifiedCondition = standardToCustomCondition({ condition });

  return (
    <div className="fs-12 semi-bold border-1 bc-grey12 fc-grey1 condition-list-item justify-content-center px-12 py-3 d-flex flex-column br-8 mt-3">
      <span className="fc-black fs-14 pb-2 px-2">
        {index}. {Name || `Condition ${index}`}
      </span>
      <hr className="w-100 fc-grey py-2" />
      <div className="d-flex center pb-3">
        {title || subtitle ? (
          <div>
            <div className="fc-black">
              <Truncate text={title} width="160px" />
            </div>
            <div>{subtitle}</div>
          </div>
        ) : (
          <DisplayFormula
            condition={modifiedCondition}
            className="fc-black"
            variant="conditionList"
          />
        )}
      </div>
      <hr className="w-100 fc-grey pt-2" />
      <FormButton
        onClick={() => setShowAchievementDetails(true)}
        variant="normal-link"
        label={
          <div className="flex justify-content-between fc-grey1 view-achievement-details">
            <span>
              {t("RULEBOOK.LABELS.VIEW")}{" "}
              {t("INCENTIVES.COMMON.LABELS.ACHIEVEMENT_DETAILS")}
            </span>
            <SvgArrow rotate={-90} color={COLORS.GREY_1} />
          </div>
        }
      />
      {showAchievementDetails && (
        <AchievementDetailsModal
          show={setShowAchievementDetails}
          onHide={() => setShowAchievementDetails(false)}
          Name={
            <>
              {index}. {Name || `Condition ${index}`}
            </>
          }
          condition={modifiedCondition}
          programId={programId}
          userId={userId}
          recurrenceId={recurrenceId}
          onTemplateSelection={onTemplateSelection}
          allowDrillDown={allowDrillDown}
          TemplateId={TemplateId}
          mobileProgramDetails={mobileProgramDetails}
          isUserPartOfGoal={isUserPartOfGoal}
          goalValue={condition?.goalValue}
          isFormula={modifiedCondition?.IsFormula}
        />
      )}
    </div>
  );
};

export default ConditionListItem;
