import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgApprovers = (props) => {
  const { color, height, width, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="9cxg__a">
          <path d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#9cxg__a)">
        <g data-name="Group 11548" transform="translate(-1019.5 -103.866)">
          <g
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.5}
            transform="translate(1021 107)"
          >
            <path
              d="M17.558 19.753h0v-2.194a4.39 4.39 0 0 0-4.389-4.39H4.39A4.39 4.39 0 0 0 0 17.559v2.195h17.558"
              data-name="Path 980"
            />
            <circle
              cx={4.39}
              cy={4.39}
              r={4.39}
              data-name="Ellipse 156"
              transform="translate(4.39)"
            />
          </g>
          <circle
            cx={7}
            cy={7}
            r={7}
            fill="#fff"
            stroke="#fff"
            strokeWidth={3}
            data-name="Ellipse 206"
            transform="translate(1033 114.732)"
          />
          <g
            fill="#fff"
            stroke={color}
            strokeWidth={2}
            data-name="Ellipse 207"
            transform="translate(1033 114.732)"
          >
            <circle cx={7} cy={7} r={7} stroke="none" />
            <circle cx={7} cy={7} r={6} fill="none" />
          </g>
          <path
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth={1.8}
            d="m1037.543 122.209 1.453 1.276 3.709-3.664"
            data-name="Path 7427"
          />
        </g>
      </g>
    </svg>
  );
};

SvgApprovers.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 28,
  width: 28,
};

export default SvgApprovers;
