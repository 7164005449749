import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgSortAsc = (props) => {
  const { color, height, width, rotate, styles, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...styles,
        transform: `rotate(${rotate}deg)`,
        transformOrigin: "50% 50%",
      }}
      {...rest}
    >
      <defs>
        <clipPath id="lkkwk33__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#lkkwk33__a)">
        <g
          data-name="Group 7445"
          style={{
            isolation: "isolate",
          }}
        >
          <g data-name="Group 7446">
            <path
              fill={color}
              d="m4.75 10 3.26-4 3.24 4Z"
              data-name="Path 4482"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgSortAsc.defaultProps = {
  color: COLORS.GREY_1,
  height: 16,
  width: 16,
  rotate: 0,
  styles: {},
};
export default SvgSortAsc;
