import PropTypes from "prop-types";
import React from "react";
import { FormButton } from "src/components/common/formElements";
import { NavBar } from "src/components/layouts/navbar";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgBack } from "src/icons";

const NavigationProps = {
  title: PropTypes.string,
  icon: PropTypes.node,
  position: PropTypes.oneOf(["sticky"]),
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  showBackButton: PropTypes.bool,
};
const defaultNavigationProps = {
  title: "",
  icon: null,
  position: "sticky",
  backgroundColor: "#fff",
  className: "",
  children: null,
  showBackButton: true,
};

const Navigation = (props) => {
  const {
    title,
    icon,
    onClick,
    position,
    backgroundColor,
    className,
    children,
    showBackButton,
  } = props;

  return (
    <NavBar
      variant="default"
      position={position}
      className={classNames([
        "center px-3 py-2 border-bottom-1 bc-grey8",
        className,
      ])}
      style={{ backgroundColor }}
    >
      <div className="center">
        {showBackButton && (
          <FormButton className="mr-3" onClick={onClick} variant="link">
            {icon || <SvgBack color={COLORS.BLACK} />}
          </FormButton>
        )}
        <div className="semi-bold">{title}</div>
      </div>
      <div className="ml-auto">{children}</div>
    </NavBar>
  );
};

Navigation.propTypes = NavigationProps;
Navigation.defaultProps = defaultNavigationProps;

export default Navigation;
