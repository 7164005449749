import { useTranslation } from "react-i18next";
import { FormInput } from "src/components/common/formElements";
import { classNames } from "src/helpers";

const ApprovalRemarks = ({ value, handleChange, warning, className }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-2 semi-bold fs-14">
        {t("INCENTIVES.COMMON.LABELS.REMARKS")}
      </div>
      <FormInput
        as="textarea"
        id="add-remarks"
        className={classNames(["fs-13", warning ? "bc-red" : "", className])}
        label={t("INCENTIVES.COMMON.LABELS.REMARKS")}
        placeholder={t("INCENTIVES.COMMON.MESSAGES.ENTER_REMARKS")}
        regex=""
        maxLength={500}
        name="remarks"
        value={value}
        onChange={handleChange}
        required
      />
    </>
  );
};

export default ApprovalRemarks;
