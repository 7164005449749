import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgEdit = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M0 9.495v2.5h2.5l7.378-7.378-2.5-2.5L0 9.495Zm11.8-6.8a.668.668 0 0 0 0-.943L10.248.195a.668.668 0 0 0-.943 0l-1.22 1.22 2.5 2.5Z"
        data-name="Path 805"
      />
    </svg>
  );
};

SvgEdit.defaultProps = {
  color: COLORS.GREY_2,
  height: 12,
  width: 12,
};
export default SvgEdit;
