import { useEffect, useMemo } from "react";
import { useGetAssignedDrilldownUserAchievement } from "src/api/useGoal";
import { useRoleBasedContext } from "src/context";
import { isEmpty } from "src/helpers";
import { useParams } from "src/router";

const useGoalsAssignedViewAB = ({
  managerUserId,
  Recurrence,
  isDrillDown,
  setGoalUser,
  ShouldFetch,
  setShouldFetch,
  isRollUp,
  User,
}) => {
  const params = useParams();
  const { userDetails } = useRoleBasedContext();
  const {
    loading: usersAchievementLoading,
    error: usersAchievementError,
    data: usersAchievementData,
  } = useGetAssignedDrilldownUserAchievement({
    variables: {
      GoalId: params.id,
      RecurrenceId: Recurrence?.RecurrenceId,
      UserIds: [User?.UserID],
      directReportOnly: isDrillDown,
    },
    skip: User?.FullName === "All",
  });

  const usersAchievementDetails = useMemo(
    () =>
      isRollUp || isDrillDown
        ? usersAchievementData?.getOneAssignedGoal?.response?.Goal
            ?.RecurrenceReport?.UsersReport
        : [
            {
              RolledUpAchievement: User?.Achievement,
              GoalAchieved: User?.Achievement,
              GoalDistribution: User?.GoalValue,
              UserId: User?.UserID,
              IsManager: userDetails?.isManagingUsers,
            },
          ],
    [isRollUp, User, userDetails, usersAchievementData, isDrillDown]
  );

  let userDetailsWithAchievement;

  const getAchievement = (user) => {
    if (isDrillDown) {
      if (user?.FullName === "All" && usersAchievementDetails) {
        const user = usersAchievementDetails.find(
          (userDetails) => userDetails.UserId === managerUserId
        );
        return user?.RolledUpAchievement || user?.GoalAchieved;
      }
      return user?.IsManager && managerUserId === user?.UserId
        ? user?.GoalAchieved
        : user?.RolledUpAchievement || user?.GoalAchieved;
    }

    if (user?.FullName === "All") {
      return Recurrence?.GoalAchieved;
    }

    if (user?.IsManager && userDetails.id === user?.UserId)
      return user?.GoalAchieved;

    return user?.RolledUpAchievement || user?.GoalAchieved;
  };

  const getGoalValue = (user) => {
    if (user?.FullName === "All") return Recurrence?.CurrentUserDistribution;
    return user?.GoalDistribution;
  };

  userDetailsWithAchievement = {
    FullName: User?.FullName,
    UserID: User?.UserID,
    Achievement:
      User?.FullName !== "All"
        ? isEmpty(usersAchievementDetails)
          ? null
          : getAchievement(usersAchievementDetails[0])
        : getAchievement(User),
    GoalValue:
      User?.FullName !== "All"
        ? isEmpty(usersAchievementDetails)
          ? null
          : getGoalValue(usersAchievementDetails[0])
        : getGoalValue(User),
    IndividualDrilldown:
      usersAchievementDetails?.[0]?.IsManager &&
      userDetails.id === usersAchievementDetails[0]?.UserId,
  };

  useEffect(() => {
    if (
      ShouldFetch &&
      !isEmpty(usersAchievementDetails) &&
      User?.FullName !== "All" &&
      !usersAchievementLoading
    ) {
      setGoalUser(userDetailsWithAchievement);
      setShouldFetch(false);
    } else if (ShouldFetch && User?.FullName === "All") {
      setGoalUser(userDetailsWithAchievement);
      setShouldFetch(false);
    }
  }, [
    ShouldFetch,
    User.UserID,
    User.FullName,
    setGoalUser,
    setShouldFetch,
    usersAchievementDetails,
    usersAchievementLoading,
    userDetailsWithAchievement,
  ]);

  return {
    usersListWithAchievements: userDetailsWithAchievement,
    usersListWithAchievementsLoading: usersAchievementLoading,
    usersListWithAchievementsError: usersAchievementError,
  };
};

export default useGoalsAssignedViewAB;
