import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgUsers = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
        transform="translate(1.25 1.367)"
      >
        <path
          d="M18.909 21.273h0v-2.364a4.727 4.727 0 0 0-4.727-4.727H4.727A4.727 4.727 0 0 0 0 18.909v2.364h18.909"
          data-name="Path 980"
        />
        <circle
          cx={4.727}
          cy={4.727}
          r={4.727}
          data-name="Ellipse 156"
          transform="translate(4.727)"
        />
        <path
          d="M22.455 14.335A4.727 4.727 0 0 1 26 18.905v2.364h-2.918"
          data-name="Path 981"
        />
        <path d="M17.727.153a4.727 4.727 0 0 1 0 9.159" data-name="Path 982" />
      </g>
    </svg>
  );
};

SvgUsers.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 24,
  width: 28,
};
export default SvgUsers;
