import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { FormTooltip } from "src/components/common/formElements";
import { useRoleBasedContext } from "src/context";
import "./goalOwnerDropdown.scss";

const MobileGoalOwnerDropdown = (props) => {
  const {
    id,
    data,
    onChangeHandler,
    defaultOwner,
    Owner = [],
    hideGoalCount = true,
    preSelect = true,
    startAdornment = "Goal Owner",
  } = props;
  const DefaultOwner = Owner.length !== 0 ? Owner : defaultOwner;
  const [ownerChange, setOwnerChange] = useState(false);
  const { t } = useTranslation();

  const { userDetails } = useRoleBasedContext();

  const customStylesOwner = {
    container: (provided) => ({
      ...provided,
      fontSize: "12px",
      height: "40px",
    }),
    control: (provided) => ({ ...provided, width: "auto" }),
    menu: (provided) => ({ ...provided, width: "100%", padding: "0px" }),
    option: (provided, state) => ({
      ...provided,
      color: "black",
      cursor: "pointer",
      backgroundColor: state.isFocused ? "#f1fafe" : "",
      padding: "12px 16px",
    }),
  };

  const formatOptionLabel = ({
    FullName,
    EmailAddress,
    GoalCount,
    UserID,
    IsManagingUsers,
    isDisabled = false,
  }) => (
    <FormTooltip
      id="goal-owner-dropdown-tooltip"
      placement="bottom"
      text={t("MANAGED_GOALS.MESSAGES.CANNOT_CREATE_GOALS")}
      disable={!isDisabled}
    >
      <div
        className={` ${
          UserID ===
            (DefaultOwner.length
              ? DefaultOwner[0].UserID
              : DefaultOwner.UserID) && (!preSelect ? ownerChange : true)
            ? "fc-blue"
            : "black"
        } ${
          isDisabled ? "not-allowed fc-grey" : ""
        } d-flex justify-content-between`}
      >
        <div className="w-75">
          <div className="d-inline-flex">
            <p className="semi-bold text-truncate">{FullName}</p>
            {userDetails.id === UserID ? (
              <span className="fc-grey pl-1">({t("COMMON.LABELS.YOU")})</span>
            ) : null}
          </div>
          <div className="text-truncate">{EmailAddress}</div>
        </div>

        {hideGoalCount ? <div className="w-25 ml-2">{GoalCount}</div> : null}
      </div>
    </FormTooltip>
  );
  const formatGroupLabel = (data) =>
    data.label && (
      <div className="d-flex align-items-center">
        <div className="fs-14 pl-3 py-2 fc-grey white-space-wrap text-capitalize bg-grey w-100">
          <b>{data.label}</b>
        </div>
      </div>
    );

  const Menu = (props) => {
    return (
      <components.Menu {...props}>
        <div>
          <div className="d-flex justify-content-between bg-grey px-3 py-2 br-4">
            <div className="fc-grey w-75">
              {t("MANAGED_GOALS.LABELS.OWNER")} &{" "}
              {t("MANAGED_GOALS.LABELS.EMAIL")}
            </div>

            {hideGoalCount ? (
              <div className="fc-grey w-25 mr-2">
                {t("COMMON.BUTTONS.GOALS")}
              </div>
            ) : null}
          </div>
          <div>{props.children}</div>
        </div>
      </components.Menu>
    );
  };

  const customFilter = (option, searchText) => {
    if (option.data.FullName.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }
    return false;
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;

    const values = getValue();
    let valueLabel = "";

    if (values.length > 0)
      valueLabel += props.selectProps.getOptionLabel(values[0]);

    const childrenToRender = React.Children.toArray(children).filter(
      (child) =>
        ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );
    return (
      <components.ValueContainer {...props}>
        {childrenToRender}
        {!props.selectProps.inputValue && valueLabel ? (
          <div className="d-flex mln-3">
            <p className="fc-blue semi-bold max-w-230 text-truncate">
              <span className="fc-grey semi-bold">{startAdornment} :</span>{" "}
              {valueLabel}
            </p>
            {values[0].UserID === userDetails.id ? (
              <span className="normal fc-grey pl-1">
                ({t("COMMON.LABELS.YOU")})
              </span>
            ) : null}
          </div>
        ) : (
          false
        )}
      </components.ValueContainer>
    );
  };

  return (
    <Select
      classNamePrefix="drop-select"
      name="goalOwner"
      className="mobile-goal-owner-dropdown"
      styles={customStylesOwner}
      components={{ Menu, ValueContainer }}
      id={id || "managing_users"}
      defaultValue={
        preSelect
          ? DefaultOwner.length
            ? DefaultOwner[0]
            : DefaultOwner
          : null
      }
      onChange={(e) => {
        onChangeHandler(e);
        setOwnerChange(true);
      }}
      options={data}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      filterOption={customFilter}
      getOptionLabel={(option) => `${option.FullName} `}
    />
  );
};
export default MobileGoalOwnerDropdown;
