import { t } from "i18next";
import { lazy, Suspense } from "react";
import { FormButton } from "src/components";
import { useToggle } from "src/hooks";
import "./styles.scss";

const ProceedWithPayoutModal = lazy(() =>
  import(
    /*
  webpackChunkName: "proceed-with-payout-modal",
  webpackPrefetch: true
  */
    "./ProceedWithPayoutModal"
  )
);

function ProceedWithPayout({
  userName,
  pendingPayoutAmount,
  userId,
  timeFrequency,
  refetchUsers,
  reftechPrograms,
  refetchTotalPendingPayout,
  pendingPayoutRewards,
}) {
  const [modal, showAndHideModal] = useToggle();
  return (
    <div className="proceed-with-payout">
      <FormButton
        disabled={!pendingPayoutAmount && pendingPayoutRewards?.length < 1}
        variant="outline-primary"
        onClick={showAndHideModal}
      >
        {t("INCENTIVES.PAYOUT.LABELS.PROCEED_WITH_PAYOUT")}
      </FormButton>
      <Suspense fallback="...">
        {modal ? (
          <ProceedWithPayoutModal
            show={modal}
            onHide={showAndHideModal}
            userName={userName}
            pendingPayoutAmount={pendingPayoutAmount}
            pendingPayoutRewards={pendingPayoutRewards}
            userId={userId}
            timeFrequency={timeFrequency}
            refetchUsers={refetchUsers}
            reftechPrograms={reftechPrograms}
            refetchTotalPendingPayout={refetchTotalPendingPayout}
          />
        ) : null}
      </Suspense>
    </div>
  );
}

export default ProceedWithPayout;
