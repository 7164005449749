import React from "react";
import { Shimmer } from "src/components/common/atoms";
import "./loadingTabCards.scss";

const LoadingTabCards = () => (
  <div className="center">
    <div className="mr-2 shim-box w-280 p-20">
      <Shimmer width="80%" height="10px" />
      <div className="mt-3">
        <Shimmer width="100%" height="10px" />
      </div>
    </div>
    <div className="mr-2 shim-box w-400 p-20">
      <div className="flex-between">
        <div className="border-in-box pr-3">
          <div className="flex-between">
            <Shimmer width="25%" height="10px" />
            <Shimmer width="25%" height="10px" />
          </div>
          <div className="mt-3 w-250">
            <Shimmer width="100%" height="10px" />
          </div>
        </div>
        <div className="center">
          <div className="center flex-column">
            <div>
              <Shimmer width="40px" height="10px" />
            </div>
            <div className="mt-3">
              <Shimmer width="40px" height="10px" />
            </div>
          </div>
          <div className="center flex-column ml-2">
            <div>
              <Shimmer width="40px" height="10px" />
            </div>
            <div className="mt-3">
              <Shimmer width="40px" height="10px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LoadingTabCards;
