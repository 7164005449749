import PropTypes from "prop-types";
import React from "react";
import { FormInput } from "src/components/common/formElements";

const slabWeightagePropTypes = {
  slabIndex: PropTypes.number.isRequired,
  weightage: PropTypes.number.isRequired,
  setWeightage: PropTypes.func.isRequired,
};

export const SlabWeightage = ({
  slabIndex,
  weightage,
  setWeightage,
  placeholder,
}) => {
  return (
    <FormInput
      type="number"
      className="w-120 h-40 ml-3"
      placeholder={placeholder}
      value={weightage}
      onChange={(event) =>
        setWeightage({
          slabIndex,
          weightage: event.target.value,
        })
      }
      step="any"
    />
  );
};

SlabWeightage.propTypes = slabWeightagePropTypes;
