import { gql, useMutation } from "src/api/client";

const GET_APPROVAL_FILE_UPLOAD_URL_MUTATION = gql`
  mutation approvalFileUploadUrl($programId: ID!) {
    approvalFileUploadUrl(programId: $programId) {
      ... on ApprovalFileUploadUrlResponse {
        success
        message
        response {
          url
          fileId
        }
      }
    }
  }
`;

export const useGetApprovalFileUploadUrl = () => {
  return useMutation(GET_APPROVAL_FILE_UPLOAD_URL_MUTATION);
};
