import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'incentives.web.endUserFlowPage' */ "./web")
);

const MobilePage = lazy(() =>
  import(/* webpackChunkName: 'incentives.mobile.endUserFlowPage' */ "./mobile")
);

export const EndUserFlowPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "EndUserFlow",
  },
};
