import { gql, useQuery } from "src/api/client";
import {
  CORE_TEMPLATE_DETAILS_FRAGMENT,
  CORE_TEMPLATE_GOAL_RULE_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
} from "src/api/fragments";

// src/modules/templates/TemplateCreation.js  --  done!!
export const GET_TEMPLATE_CREATION_DETAILS_QUERY = gql`
  ${CORE_TEMPLATE_DETAILS_FRAGMENT}
  ${CORE_TEMPLATE_GOAL_RULE_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query GetTemplateDetails($TemplateId: ID!) {
    template(TemplateId: $TemplateId) {
      ... on SingleTemplateResponse {
        response {
          Template {
            ...coreTemplateDetailsFragment
            GoalsCount
            AuditType
            ModifiedBy
            AuditActivityStatus
            DrilldownStatus
            GoalRules {
              ...coreTemplateGoalRuleFragment
            }
          }
          validations {
            isAggregateFunctionAllowed
            isUnpublishedAllowed
            isDeleteAllowed
            isEditAllowed
          }
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetTemplateCreationDetails = (queryOptions) => {
  return useQuery(GET_TEMPLATE_CREATION_DETAILS_QUERY, queryOptions);
};
