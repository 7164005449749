import {
  MAXIMUM_INCENTIVE_CONDITION_VALUE,
  MINIMUM_INCENTIVE_CONDITION_VALUE,
  STATIC_REGEX_FOR_BETWEEN_FUNCTION,
} from "src/constants";
import formulaNamespace from "./formulaNamespace";

const {
  TOKEN_TYPE,
  OPERATOR_TOKEN_TYPE,
  FORMULA_OPERATOR_TYPE,
  FORMULA_OPERATORS,
} = formulaNamespace;

const staticTokenValidation = {
  minValue: {
    inclusive: true,
    value: MINIMUM_INCENTIVE_CONDITION_VALUE,
  },
  maxValue: {
    inclusive: true,
    value: MAXIMUM_INCENTIVE_CONDITION_VALUE,
  },
};

const getAllowedRangeText = ({ minValue, maxValue }) => {
  const hasMinValue = !Number.isNaN(Number(minValue.value));
  const hasMaxValue = !Number.isNaN(Number(maxValue.value));
  const minRangeOperator = minValue.inclusive ? ">=" : ">";
  const maxRangeOperator = maxValue.inclusive ? "<=" : ">";
  if (hasMinValue || hasMaxValue) {
    return `[${
      hasMinValue ? `minValue ${minRangeOperator} ${minValue.value}` : ""
    }${hasMaxValue && maxValue.value ? ", " : ""}${
      hasMaxValue ? `maxValue ${maxRangeOperator} ${maxValue.value}` : ""
    }]`;
  }
  return "";
};

const getPositionText = ({ token }) => {
  return token.index ? ` at position ${token.index + 1}.` : ".";
};

const displayValue = ({ token }) => {
  const { tokenType, value, fieldName, templateName, goalName, programName } =
    token;

  switch (tokenType) {
    case TOKEN_TYPE.OPERATOR:
      return FORMULA_OPERATORS[value].value;
    case TOKEN_TYPE.METRIC:
      return programName || goalName || templateName;
    case TOKEN_TYPE.FIELD:
      return fieldName;
    case TOKEN_TYPE.STATIC:
      return value;
    default:
      return value;
  }
};

const getValue = ({ token }) => {
  const { tokenType, value } = token;

  switch (tokenType) {
    case TOKEN_TYPE.OPERATOR:
      return FORMULA_OPERATORS[value].value;
    case TOKEN_TYPE.METRIC:
      return value;
    case TOKEN_TYPE.FIELD:
      return value;
    case TOKEN_TYPE.STATIC:
      return value;
    default:
      return value;
  }
};

const FORMULA_ERRORS = {
  IS_NOT_A_NUMBER: ({ token }) => ({
    code: "IS_NOT_A_NUMBER",
    message: `Expecting a number but found '${displayValue({
      token,
    })}'${getPositionText({ token })}`,
    token,
  }),
  EXPECTING_OPERAND: ({ token }) => ({
    code: "EXPECTING_OPERAND",
    message: `Expecting a 'constant', 'metric', 'field' or '(' but found '${displayValue(
      { token }
    )}'${getPositionText({ token })}`,
    token,
  }),
  NOT_MULTIPLIABLE_WITH_ZERO: ({ token }) => ({
    code: "NOT_MULTIPLIABLE_WITH_ZERO",
    message: `Cannot multiply with 0. Expecting a non-zero value but found 0${getPositionText(
      { token }
    )}`,
    token,
  }),
  NOT_DIVISIBLE_BY_ZERO: ({ token }) => ({
    code: "NOT_DIVISIBLE_BY_ZERO",
    message: `Cannot divide by 0. Expecting a non-zero value but found 0${getPositionText(
      { token }
    )}`,
    token,
  }),
  CONSTANT_NOT_IN_RANGE: ({ token }) => ({
    code: "CONSTANT_NOT_IN_RANGE",
    message: `Number not in range. ${getAllowedRangeText({
      minValue: staticTokenValidation.minValue,
      maxValue: staticTokenValidation.maxValue,
    })}`,
    token,
  }),
  EXPECTING_OPERATOR_OR_CLOSE_PAREN: ({ token }) => ({
    code: "EXPECTING_OPERATOR",
    message: `Expecting an operator but found '${displayValue({
      token,
    })}'${getPositionText({ token })}`,
    token,
  }),
  NOT_MORE_THAN_1_COMPARISON_OPERATORS: ({ token }) => ({
    code: "NOT_MORE_THAN_1_COMPARISON_OPERATORS",
    message: `Comparison Operators can't be used more than once in the expression.`,
    token,
  }),
  BETWEEN_USED_WITH_OPERATOR_OR_FUNCTION: ({ token }) => ({
    code: "BETWEEN_USED_WITH_OPERATOR_OR_FUNCTION",
    message: `'Between' function can't be used with any comparison operator or function in the expression`,
    token,
  }),
  STATIC_VALUE_FOR_BETWEEN: ({ token }) => ({
    code: "STATIC_VALUE_FOR_BETWEEN",
    message: `Expecting a expression in the format 'number AND number' but found '${displayValue(
      { token }
    )}'${getPositionText({ token })}`,
    token,
  }),
  BETWEEN_CONDITION_NOT_SATISFIED: ({ token }) => ({
    code: "BETWEEN_CONDITION_NOT_SATISFIED",
    message: `second number should be greater than first number in the expression 'number AND number' but found '${displayValue(
      { token }
    )}'${getPositionText({ token })}`,
    token,
  }),
};

const isOpenParentheses = ({ token }) => {
  return (
    token.tokenType === TOKEN_TYPE.OPERATOR &&
    token.operatorTokenType === OPERATOR_TOKEN_TYPE.PARENTHESIS &&
    token.value === FORMULA_OPERATOR_TYPE.OPEN_PAREN
  );
};

const isCloseParentheses = ({ token }) => {
  return (
    token.tokenType === TOKEN_TYPE.OPERATOR &&
    token.operatorTokenType === OPERATOR_TOKEN_TYPE.PARENTHESIS &&
    token.value === FORMULA_OPERATOR_TYPE.CLOSE_PAREN
  );
};

export const isVariable = ({ token }) => {
  return [TOKEN_TYPE.METRIC, TOKEN_TYPE.FIELD].includes(token.tokenType);
};

const isOperand = ({ token }) => {
  return (
    token.tokenType === TOKEN_TYPE.STATIC ||
    isVariable({ token }) ||
    isOpenParentheses({ token })
  );
};

export const isOperator = ({ token }) => {
  return [
    OPERATOR_TOKEN_TYPE.MATHEMATICAL_OPERATOR,
    OPERATOR_TOKEN_TYPE.COMPARISON_OPERATOR,
    OPERATOR_TOKEN_TYPE.FUNCTION,
  ].includes(token.operatorTokenType);
};

export const isMathematicalOperator = ({ token }) => {
  return token.operatorTokenType === OPERATOR_TOKEN_TYPE.MATHEMATICAL_OPERATOR;
};

export const isComparisonOperator = ({ token }) => {
  return token.operatorTokenType === OPERATOR_TOKEN_TYPE.COMPARISON_OPERATOR;
};

export const isFunctionOperator = ({ token }) => {
  return token.operatorTokenType === OPERATOR_TOKEN_TYPE.FUNCTION;
};

const isParanthesis = ({ token }) => {
  return token.operatorTokenType === OPERATOR_TOKEN_TYPE.PARENTHESIS;
};

const isValidMinimum = ({ token }) => {
  if (staticTokenValidation?.minValue) {
    const value = Number(staticTokenValidation.minValue.value);
    const { inclusive } = staticTokenValidation.minValue;
    const hasMinValue = !Number.isNaN(Number(value));
    if (hasMinValue) {
      if (inclusive) {
        return token.value >= value;
      }
      return token.value > value;
    }
    return true;
  }
  return true;
};

const isValidMaximum = ({ token }) => {
  if (staticTokenValidation?.maxValue) {
    const value = Number(staticTokenValidation?.maxValue?.value);
    const { inclusive } = staticTokenValidation.maxValue;
    const hasMaxValue = !Number.isNaN(Number(value));
    if (hasMaxValue) {
      if (inclusive) {
        return token.value <= value;
      }
      return token.value < value;
    }
    return true;
  }
  return true;
};

const isNumber = ({ token }) => {
  if (token.tokenType === TOKEN_TYPE.STATIC) {
    return !Number.isNaN(Number(token.value));
  }
  return true;
};

const isConstantInRange = ({ token }) => {
  if (token.tokenType === TOKEN_TYPE.STATIC) {
    return isValidMinimum({ token }) && isValidMaximum({ token });
  }
  return true;
};

const isNotZero = ({ token }) => {
  if (token.tokenType === TOKEN_TYPE.STATIC) {
    return token.value !== 0;
  }
  return true;
};

const isValidLastToken = ({ token }) => {
  return (
    token.tokenType === TOKEN_TYPE.STATIC ||
    isVariable({ token }) ||
    isCloseParentheses({ token })
  );
};

const areBracketsBalanced = (expression) => {
  const stack = [];
  let scanner = 0;
  while (scanner < expression.length) {
    const current = expression[scanner];
    if (current === "(" || current === "{" || current === "[") {
      stack.push(current);
    }
    if (stack.length === 0) return false;
    let previous;
    switch (current) {
      case ")":
        previous = stack.pop();
        if (previous === "{" || previous === "[") return false;
        break;

      case "]":
        previous = stack.pop();
        if (previous === "(" || previous === "{") return false;
        break;

      case "}":
        previous = stack.pop();
        if (previous === "(" || previous === "[") return false;
        break;

      default:
        break;
    }
    scanner++;
  }

  return !stack.length;
};

const isStaticStringOrConstantInRange = ({ token }) => {
  if (token.tokenType === TOKEN_TYPE.STATIC) {
    if (!isNaN(Number(token.value))) {
      return isValidMinimum({ token }) && isValidMaximum({ token });
    }
    return true;
  }
  return true;
};

const isMoreThan1ComparisonOperators = ({ prevTokens }) => {
  const comparisonOperators = prevTokens.filter((token) =>
    isComparisonOperator({ token })
  );
  return !comparisonOperators.length;
};

const isBetweenFunction = ({ token }) => {
  return token.value === FORMULA_OPERATOR_TYPE.BETWEEN;
};

const isBetweenFunctionUsedWithOperatorOrFunction = ({ prevTokens }) => {
  const operators = prevTokens.filter((token) =>
    isComparisonOperator({ token })
  );
  return !operators.length;
};

const isStaticValueForBetween = ({ token }) => {
  if (token.tokenType === TOKEN_TYPE.STATIC) {
    return (
      token.value.toString().match(STATIC_REGEX_FOR_BETWEEN_FUNCTION) &&
      token.fieldName === FORMULA_OPERATOR_TYPE.BETWEEN
    );
  }
  return false;
};

const isBetweenConditionSatisfied = ({ token }) => {
  if (token.tokenType === TOKEN_TYPE.STATIC) {
    const [firstNumber, secondNumber] = token.value.toLowerCase().split("and");
    return Number(firstNumber) < Number(secondNumber);
  }
  return true;
};

const isBetweenValuesInRange = ({ token }) => {
  if (token.tokenType === TOKEN_TYPE.STATIC) {
    const [firstNumber, secondNumber] = token.value.toLowerCase().split("and");
    return (
      isValidMinimum({ token: { value: firstNumber } }) &&
      isValidMaximum({ token: { value: secondNumber } })
    );
  }
  return true;
};

const TOKEN_RULES = {
  IS_OPERAND: {
    rule: isOperand,
    error: FORMULA_ERRORS.EXPECTING_OPERAND,
  },
  IS_STATIC_STRING_OR_CONSTANT_IN_RANGE: {
    rule: isStaticStringOrConstantInRange,
    error: FORMULA_ERRORS.CONSTANT_NOT_IN_RANGE,
  },
  IS_NUMBER: {
    rule: isNumber,
    error: FORMULA_ERRORS.EXPECTING_OPERAND,
  },
  IS_CONSTANT_IN_RANGE: {
    rule: isConstantInRange,
    error: FORMULA_ERRORS.CONSTANT_NOT_IN_RANGE,
  },
  IS_MULTIPLIABLE: {
    rule: isNotZero,
    error: FORMULA_ERRORS.NOT_MULTIPLIABLE_WITH_ZERO,
  },
  IS_DIVISIBLE: {
    rule: isNotZero,
    error: FORMULA_ERRORS.NOT_DIVISIBLE_BY_ZERO,
  },
  IS_OPERATOR_OR_CLOSE_PAREN: {
    rule: ({ token }) => isOperator({ token }) || isCloseParentheses({ token }),
    error: FORMULA_ERRORS.EXPECTING_OPERATOR_OR_CLOSE_PAREN,
  },
  IS_MORE_THAN_1_COMPARISON_OPERATORS: {
    rule: ({ prevTokens, token }) =>
      isComparisonOperator({ token })
        ? isMoreThan1ComparisonOperators({ prevTokens, token })
        : true,
    error: FORMULA_ERRORS.NOT_MORE_THAN_1_COMPARISON_OPERATORS,
  },
  IS_BETWEEN_USED_WITH_OPERATOR_OR_FUNCTION: {
    rule: ({ prevTokens, token }) =>
      isBetweenFunction({ token })
        ? isBetweenFunctionUsedWithOperatorOrFunction({ prevTokens })
        : true,
    error: FORMULA_ERRORS.BETWEEN_USED_WITH_OPERATOR_OR_FUNCTION,
  },
  IS_STATIC_VALUE_FOR_BETWEEN: {
    rule: ({ token }) => isStaticValueForBetween({ token }),
    error: FORMULA_ERRORS.STATIC_VALUE_FOR_BETWEEN,
  },
  IS_BETWEEN_CONDITION_SATISFIED: {
    rule: ({ token }) => isBetweenConditionSatisfied({ token }),
    error: FORMULA_ERRORS.BETWEEN_CONDITION_NOT_SATISFIED,
  },
  IS_BETWEEN_VALUES_IN_RANGE: {
    rule: ({ token }) => isBetweenValuesInRange({ token }),
    error: FORMULA_ERRORS.CONSTANT_NOT_IN_RANGE,
  },
  INVALID_INPUT: {
    rule: ({ token }) => token,
    error: () => ({
      message: "Invalid input.",
    }),
  },
  ARE_BRACKETS_BALANCED: {
    rule: areBracketsBalanced,
    error: {
      message: "Brackets are not balanced.",
    },
  },
  IS_VALID_LAST_TOKEN: {
    rule: isValidLastToken,
    error: {
      message: `Incomplete expression.`,
    },
  },
  NO_INPUT_AFTER_BETWEEN_STATIC_VALUE: {
    rule: () => false,
    error: () => ({
      message:
        "No Operator or Operand after static value for 'Between' function.",
    }),
  },
};

const MATHEMATICAL_OPERATOR_RULES = {
  [FORMULA_OPERATOR_TYPE.PLUS]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.MINUS]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.MULTIPLY]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
    TOKEN_RULES.IS_MULTIPLIABLE,
  ],
  [FORMULA_OPERATOR_TYPE.DIVIDE]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_DIVISIBLE,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
  ],
};

const COMARISION_OPERATOR_RULES = {
  [FORMULA_OPERATOR_TYPE.EQUAL]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_STATIC_STRING_OR_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.NOT_EQUAL]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_STATIC_STRING_OR_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.GREATER_THAN]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_STATIC_STRING_OR_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.LESS_THAN]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_STATIC_STRING_OR_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_STATIC_STRING_OR_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.LESS_THAN_OR_EQUAL]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_STATIC_STRING_OR_CONSTANT_IN_RANGE,
  ],
};

const FUNCTION_OPERATOR_RULES = {
  [FORMULA_OPERATOR_TYPE.BETWEEN]: [
    TOKEN_RULES.IS_STATIC_VALUE_FOR_BETWEEN,
    TOKEN_RULES.IS_BETWEEN_CONDITION_SATISFIED,
    TOKEN_RULES.IS_BETWEEN_VALUES_IN_RANGE,
  ],
};

const PARENTHESES_RULES = {
  [FORMULA_OPERATOR_TYPE.OPEN_PAREN]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.CLOSE_PAREN]: [TOKEN_RULES.IS_OPERATOR_OR_CLOSE_PAREN],
};

const STATIC_BETWEEN_VALUES_RULES = [
  TOKEN_RULES.IS_BETWEEN_USED_WITH_OPERATOR_OR_FUNCTION,
];

const CONSTANT_RULES = [
  TOKEN_RULES.IS_OPERATOR_OR_CLOSE_PAREN,
  TOKEN_RULES.IS_MORE_THAN_1_COMPARISON_OPERATORS,
  TOKEN_RULES.IS_BETWEEN_USED_WITH_OPERATOR_OR_FUNCTION,
];

const VARIABLE_RULES = [
  TOKEN_RULES.IS_OPERATOR_OR_CLOSE_PAREN,
  TOKEN_RULES.IS_MORE_THAN_1_COMPARISON_OPERATORS,
  TOKEN_RULES.IS_BETWEEN_USED_WITH_OPERATOR_OR_FUNCTION,
];

const FIRST_TOKEN_RULES = [
  TOKEN_RULES.IS_OPERAND,
  TOKEN_RULES.IS_NUMBER,
  TOKEN_RULES.IS_CONSTANT_IN_RANGE,
];

const executeRules = ({ rules, prevTokens, token }) => {
  for (let i = 0; i < rules.length; i++) {
    const { rule, error } = rules[i];
    if (!rule({ token, prevTokens })) {
      return error({ token, prevTokens });
    }
  }
};

const getTokenRules = ({ token }) => {
  if (isVariable({ token })) {
    return VARIABLE_RULES;
  }
  if (isParanthesis({ token })) {
    return PARENTHESES_RULES[token.value];
  }
  if (isMathematicalOperator({ token })) {
    return MATHEMATICAL_OPERATOR_RULES[token.value];
  }
  if (isComparisonOperator({ token })) {
    return COMARISION_OPERATOR_RULES[token.value];
  }
  if (isFunctionOperator({ token })) {
    return FUNCTION_OPERATOR_RULES[token.value];
  }
  if (isStaticValueForBetween({ token })) {
    return STATIC_BETWEEN_VALUES_RULES;
  }
  return CONSTANT_RULES;
};

const validateToken = ({ prevTokens, lastToken, nextToken }) => {
  let rules;

  if (lastToken && nextToken) {
    rules = getTokenRules({ token: lastToken });
  } else if (!nextToken) {
    rules = [TOKEN_RULES.INVALID_INPUT];
  } else {
    rules = FIRST_TOKEN_RULES;
  }
  return executeRules({ rules, prevTokens, token: nextToken });
};

const validateTokens = (tokens) => {
  for (let scanner = -1; scanner < tokens.length - 1; scanner++) {
    const currentToken = tokens[scanner];
    const nextToken = tokens[scanner + 1];
    const prevTokens = tokens.slice(0, scanner + 1);
    const tokenError = validateToken({
      prevTokens,
      lastToken: currentToken,
      nextToken,
    });
    if (tokenError) {
      return tokenError;
    }
  }
  return false;
};

const validateExpression = (tokens) => {
  const lastToken = tokens[tokens.length - 1];
  const expression = tokens.reduce(
    (pv, cv) => String(pv) + String(getValue({ token: cv })),
    ""
  );
  const tokenError = validateTokens(tokens);
  if (tokenError) {
    return tokenError;
  }
  if (!isValidLastToken({ token: lastToken })) {
    return {
      message: "Incomplete expression.",
    };
  }
  if (!areBracketsBalanced(`(${expression})`)) {
    return {
      message: "Brackets are not balanced",
    };
  }
  return false;
};

const formulaValidator = {
  validateToken,
  validateTokens,
  validateExpression,
};

export default formulaValidator;
