import React from "react";
import { useGetLeaderboardDashboardTotal } from "src/api/useLeaderboardDashboard";
import { FEATURE_TYPES } from "src/constants";
import DashboardHeroCard from "./DashboardHeroCard";
import "./dashboardHeroCardGroup.scss";
import { PROGRAMS_STATS_CARDS } from "./data";
const getTimeFrequency = (timeFrequency) => {
  if (!timeFrequency.startsAt || !timeFrequency.endsAt) {
    return null;
  }
  return {
    timeFrequency: {
      startsAt: timeFrequency.startsAt,
      endsAt: timeFrequency.endsAt,
    },
  };
};
const DashboardHeroCardGroup = ({
  loadingManagers,
  payloadUserId,
  filtersState,
  payloadForRewardsModal,
  isIncentive,
}) => {
  const { data, loading } = useGetLeaderboardDashboardTotal({
    variables: {
      userId: payloadUserId,
      FeatureType: isIncentive
        ? FEATURE_TYPES.INCENTIVE
        : FEATURE_TYPES.LEADERBOARD,
      query: {
        ...getTimeFrequency(filtersState.timeFrequency),
        allDateRange: filtersState.timeFrequency.value === "ALL",
      },
    },
    skip:
      !getTimeFrequency(filtersState.timeFrequency) || payloadUserId === null,
    fetchPolicy: "network-only",
  });

  const leaderboardDetails = data?.incentiveDashboardTotal?.response;

  return (
    <div className="dashboard-hero-wrapper">
      <div className="d-flex space-x-16 dashboard-hero-card-group">
        {PROGRAMS_STATS_CARDS(isIncentive).map((card) => {
          return (
            <DashboardHeroCard
              {...card}
              loading={loadingManagers || loading}
              amount={leaderboardDetails?.[card.key]}
              rewardsList={leaderboardDetails?.[card.rewardsKey]}
              payloadForRewardsModal={{
                ...payloadForRewardsModal,
                fieldName: card.fieldName,
                IsUserIdManager: payloadUserId ? true : undefined,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DashboardHeroCardGroup;
