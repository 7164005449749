import PropTypes from "prop-types";
import React, { useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  NonMonetaryLabel,
  Shimmer,
  Truncate,
} from "src/components/common/atoms";
import { CriteriaAccordion } from "src/components/common/molecules";
import { FIELD_NAMES } from "src/constants/nonMonetaryConstants";
import { checkValue } from "src/helpers";
import "./programCriteriaModal.scss";

const ProgramCriteriaModal = (props) => {
  const {
    loading,
    show,
    userName,
    onCancelClick,
    achievementsCount,
    achievedCriterias,
    totalEligibleIncentive,
    totalEligibleRewards,
    totalEligibleRewardCount,
    payloadForRewardsList,
    eligibleRewardIds,
    recurrences,
    selectedRecurrence,
    customType,
    programName,
    templates,
    goals,
    programId,
    userId,
    recurrenceId,
  } = props;
  const { t } = useTranslation();
  const [selected, setSelected] = useState(1);
  const handleClick = (idx) =>
    setSelected((prev) => (prev === idx ? null : idx));

  return (
    <Modal
      show={show}
      onHide={onCancelClick}
      centered
      dialogClassName="program-criteria-achieved-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t("INCENTIVES.COMMON.LABELS.PROGRAM_CRITERIA")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="fs-13 flex-between m-2 pb-3">
          <div className="d-flex align-items-start flex-column">
            <div className="fc-grey1">{t("COMMON.LABELS.USER")}</div>
            <div className="fs-14 semi-bold ">
              {loading ? <Shimmer width="85px" height="18px" /> : null}
              {!loading ? <Truncate maxWidth="180px" text={userName} /> : null}
            </div>
          </div>
          <div className="d-flex align-items-start flex-column">
            <div className="fc-grey1">
              {t("INCENTIVES.COMMON.LABELS.TOTAL_ELIGIBLE_INCENTIVE")}
            </div>
            <div className="fs-14 semi-bold">
              {" "}
              {loading ? <Shimmer width="85px" height="18px" /> : null}
              {!loading ? (
                <NonMonetaryLabel
                  amount={totalEligibleIncentive}
                  nonMonetaryRewards={totalEligibleRewards}
                  rewardCount={totalEligibleRewardCount}
                  className="d-flex"
                  maxCharCount={8}
                  rewardModalTitle={t(
                    "INCENTIVES.COMMON.LABELS.TOTAL_ELIGIBLE_INCENTIVE"
                  )}
                  payload={{
                    ...payloadForRewardsList,
                    rewardIds: eligibleRewardIds,
                    fieldName: FIELD_NAMES.ELIGIBLE,
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="d-flex align-items-start flex-column">
            <div className="fc-grey1">
              {t("INCENTIVES.COMMON.LABELS.NO_OF_CRITERIA_ACHIEVED")}
            </div>
            {loading ? <Shimmer width="85px" height="18px" /> : null}
            {!loading ? (
              <div className="fs-14 semi-bold">
                {checkValue(achievementsCount) ? achievementsCount : "--"}
              </div>
            ) : null}
          </div>
        </div>
        {loading ? <Shimmer width="100%" height="189px" /> : null}
        {!loading && achievedCriterias && achievedCriterias.length > 0 && (
          <Accordion defaultActiveKey="1">
            {achievedCriterias?.map((criteria, idx) => (
              <div className="pb-12">
                <CriteriaAccordion
                  handleClick={handleClick}
                  selectedIndex={idx + 1}
                  selected={idx + 1 === selected}
                  criteria={criteria}
                  payloadForRewardsList={payloadForRewardsList}
                  recurrences={recurrences}
                  selectedRecurrence={selectedRecurrence}
                  customType={customType}
                  programName={programName}
                  templates={templates}
                  goals={goals}
                  programId={programId}
                  userId={userId}
                  recurrenceId={recurrenceId}
                  userName={userName}
                />
              </div>
            ))}
          </Accordion>
        )}
      </Modal.Body>
    </Modal>
  );
};

ProgramCriteriaModal.propTypes = {
  show: PropTypes.bool,
  onCancelClick: PropTypes.func,
  type: PropTypes.oneOf(["TEMPLATE_BASED", "GOAL_BASED"]),
  userName: PropTypes.string,
  achievementsCount: PropTypes.number,
  totalEligibleIncentive: PropTypes.number,
  achievedCriterias: PropTypes.array,
};

ProgramCriteriaModal.defaultProps = {
  show: false,
  onCancelClick: () => {},
};

export default ProgramCriteriaModal;
