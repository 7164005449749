import { gql, useQuery } from "src/api/client";

const GET_RECOGNITION_BOARD_LIST = gql`
  query getRecognitionBoardList(
    $limit: Float
    $after: String
    $query: RecognitionBoardListQuery
  ) {
    getRecognitionBoardList(limit: $limit, after: $after, query: $query) {
      ... on RecognitionBoardListResponse {
        success
        response {
          recognitionBoardList {
            LeaderboardId
            Name
            IsGroupBased
            GroupId
            GroupName
            LastCompletedCycle
            LastUpdated
            Users {
              UserId
              Name
              Rank
              __typename
            }
            __typename
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
          totalCount
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useGetRecognitionBoardList = (queryOptions) => {
  return useQuery(GET_RECOGNITION_BOARD_LIST, queryOptions);
};
