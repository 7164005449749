import { t } from "i18next";
import React from "react";
import { FormButton, FormTooltip, Popover } from "src/components";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgSort } from "src/icons";
import "./styles.scss";

const SortBody = ({ sortOptions, selectedSort, setSelectedSort }) => {
  return (
    <div className="fs-12 sort-popover">
      {sortOptions.map((option, index) => (
        <FormTooltip
          text={t(
            "LEADERBOARD.MESSAGES.SORTING_AVAILABLE_ONLY_FOR_COMPLETED_CYCLE"
          )}
          disable={!option?.disabled}
          placement="bottom"
        >
          <FormButton
            key={index}
            className={classNames([
              "border-1 p-2 sort-option",
              selectedSort.key === option.key &&
              selectedSort.order === option.order
                ? "active"
                : "",
              option.showBorder && sortOptions.length - 1 !== index
                ? "border-bottom"
                : "",
              option?.disabled ? "fc-grey10" : "",
            ])}
            onClick={() => {
              if (
                (option.key !== selectedSort.key ||
                  option.order !== selectedSort.order) &&
                !option?.disabled
              )
                setSelectedSort(option);
            }}
            variant="normal-link"
            disabled={option?.disabled}
          >
            {option.label}
          </FormButton>
        </FormTooltip>
      ))}
    </div>
  );
};

const SortGrid = ({
  className,
  sortOptions,
  selectedSort,
  setSelectedSort,
  popoverClassName,
  height = 40,
  width = 40,
  disabled,
}) => {
  return (
    <Popover
      id="sort-grid"
      closedStateIcon={
        <div
          className={classNames([
            "closed-state-button flex center",
            disabled && "disable-sort",
          ])}
          style={{ width, height }}
        >
          <SvgSort
            rotate={90}
            color={disabled ? COLORS.GREY_10 : COLORS.PRIMARY_BLUE}
            height="16"
          />
        </div>
      }
      openedStateIcon={
        <div
          className={classNames([
            "closed-state-button flex center px-1 bg-blue",
          ])}
          style={{ width, height }}
        >
          <SvgSort rotate={90} color={COLORS.PRIMARY_BLUE} height="16" />
        </div>
      }
      popoverBody={
        <SortBody
          sortOptions={sortOptions}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
        />
      }
      hideOnClick
      width="250px"
      className={classNames([className, disabled && "disable-sort"])}
      popoverClassName={popoverClassName}
      disabled={disabled}
    />
  );
};

export default SortGrid;
