import { useEffect, useState } from "react";
import { FormCheck } from "src/components/common/formElements";

const SelectRowCell = ({
  row: { index, original },
  updateMyData,
  value,
  children,
  column,
}) => {
  const [checked, setChecked] = useState(value);
  useEffect(() => setChecked(value), [value]);
  const selectable = column.selectable ? column.selectable(original) : true;
  return (
    <div className="center">
      <FormCheck
        id={`check-${index}`}
        checked={checked}
        className="mr-3"
        style={{ visibility: selectable ? "visible" : "hidden" }}
        onClick={(event) => {
          event.stopPropagation();
          if (selectable) {
            updateMyData(original, !checked);
            setChecked((prev) => !prev);
          }
        }}
        tabIndex="-1"
      />
      {children}
    </div>
  );
};

export default SelectRowCell;
