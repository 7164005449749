import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const IMPORT_LEADERBOARD_UPLOAD_URL_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation getImportLeaderboardUploadUrl($LeaderboardId: ID!) {
    getImportLeaderboardUploadUrl(LeaderboardId: $LeaderboardId) {
      ... on LeaderboardUrlResponse {
        success
        response {
          url
          fileId
          __typename
        }
        __typename
      }
      ...errorResponseFragment
      __typename
    }
  }
`;

export const useGetUploadUrl = () => {
  return useMutation(IMPORT_LEADERBOARD_UPLOAD_URL_MUTATION);
};
