import React from "react";
const SvgGamification = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#p0lq__a)">
        <mask
          id="p0lq__b"
          width={20}
          height={20}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "alpha",
          }}
        >
          <path fill="#D9D9D9" d="M0 0h20v20H0z" />
        </mask>
        <g
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.4}
          mask="url(#p0lq__b)"
        >
          <path d="M11.63 8.11h2.148M6.261 8.11H8.41M7.335 7.037v2.147M12.973 4.6l-5.906.02A3.497 3.497 0 0 0 3.63 7.506l-1.1 5.645a1.88 1.88 0 0 0 3.18 1.658l2.9-3.209 4.363-.02a3.49 3.49 0 1 0 0-6.98" />
          <path d="m16.41 7.486 1.1 5.665a1.88 1.88 0 0 1-3.181 1.658l-2.9-3.222" />
        </g>
      </g>
      <defs>
        <clipPath id="p0lq__a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
SvgGamification.defaultProps = {
  color: "#309AFC",
  height: 24,
  width: 24,
};
export default SvgGamification;
