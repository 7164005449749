import React from "react";

const SvgAvatar36 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="jhjr__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .jhjr__cls-1{fill:#fff}.jhjr__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="jhjr__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="jhjr__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="jhjr__cls-2"
        />
        <path
          d="M81.4 104.91c-6.2 0-12.4-.55-18.56-1.65-.72-.13-1.24-.75-1.24-1.48V80.9c0-.73.52-1.35 1.24-1.48 12.32-2.2 24.8-2.2 37.12 0 .72.13 1.24.75 1.24 1.48v20.88c0 .73-.52 1.35-1.24 1.48-6.16 1.1-12.36 1.65-18.56 1.65Zm-16.8-4.39c11.15 1.85 22.44 1.85 33.6 0V82.16a102.402 102.402 0 0 0-33.6 0v18.36Zm35.1 1.26Z"
          className="jhjr__cls-1"
        />
        <path
          d="M99.69 87.05a1.497 1.497 0 0 1-.36-2.95c.12-.03 12.01-3.15 13.92-12.33.17-.81.96-1.33 1.77-1.16.81.17 1.33.96 1.16 1.77-2.3 11.03-15.56 14.49-16.13 14.63-.12.03-.25.04-.37.04ZM62.07 87.05c-.11 0-.22-.01-.33-.04-.48-.11-11.67-2.72-15.11-14.52-.23-.8.23-1.63 1.02-1.86.8-.23 1.63.23 1.86 1.02 2.93 10.06 12.48 12.34 12.88 12.43a1.498 1.498 0 0 1-.33 2.96Z"
          className="jhjr__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar36.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar36;
