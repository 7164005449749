import { t } from "i18next";
import React from "react";
import { useDownloadRecurrenceGoalExport } from "src/api/useGoal";
import { ConfirmationModal, toaster } from "src/components/common/atoms";

const ConfirmationModalBody = ({ index }) => (
  <div>
    <div className="fs-14 semi-bold">
      {t("COMMON.BUTTONS.EXCEL_DOWNLOAD_CONFIRMATION_TEXT")}{" "}
      <span className="fc-grey1">
        {t("MANAGED_GOALS.LABELS.CYCLE")} {index + 1}
      </span>
      ?
    </div>
    <div className="fs-13">
      {t("COMMON.BUTTONS.EXCEL_DOWNLOAD_CONFIRMATION_DESC")}
    </div>
  </div>
);

const ExportGridConfirmationModal = ({
  toggleModal,
  setToggleModal,
  index,
  exportVariables,
  fileName,
}) => {
  const [downloadExcel, { loading }] = useDownloadRecurrenceGoalExport();

  return (
    <ConfirmationModal
      show={toggleModal}
      dialogClassName="export-excel-modal"
      onClose={() => setToggleModal(false)}
      title={t("COMMON.BUTTONS.DOWNLOAD_SELECTED_CYCLE")}
      body={<ConfirmationModalBody {...{ index }} />}
      onConfirm={async () => {
        const res = await downloadExcel({ variables: exportVariables });
        const fileUrl = res?.data?.downloadRecurrenceGoalExport?.urlData;
        if (fileUrl) {
          const anchor = document.createElement("a");
          anchor.href = fileUrl?.url;
          anchor.download = fileName;
          anchor.click();
        } else {
          toaster({
            message: res?.data?.downloadRecurrenceGoalExport?.message,
            type: "error",
          });
        }
      }}
      loading={loading}
      confirmButtonText={t("COMMON.BUTTONS.DOWNLOAD_EXCEL_TEXT")}
      shimmerText={t("COMMON.BUTTONS.LOADING_DOWNLOAD_TEXT")}
      cancelButton
    />
  );
};

export default ExportGridConfirmationModal;
