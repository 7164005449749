import { CONDITION_OPERATOR } from "src/constants";
import { groupByConditionValid } from "../../utils";
import { formulaNamespace } from "./formulaParser";

const { TOKEN_TYPE, FORMULA_OPERATOR_TYPE, FORMULA_OPERATORS } =
  formulaNamespace;

export const tokenToString = (token) => {
  switch (token?.tokenType) {
    case TOKEN_TYPE.METRIC: {
      return null;
    }

    case TOKEN_TYPE.FIELD: {
      return null;
    }

    case TOKEN_TYPE.OPERATOR: {
      return FORMULA_OPERATORS[token?.value].value;
    }

    case TOKEN_TYPE.STATIC: {
      return token?.value;
    }

    default: {
      return token?.value;
    }
  }
};

const STANDARD_CONDITION_OPERATORS_TO_FORMULA_CONDITION_OPERATORS_MAP = {
  [CONDITION_OPERATOR.BETWEEN.Key]: FORMULA_OPERATOR_TYPE.BETWEEN,
  [CONDITION_OPERATOR.GREATER_THAN.Key]: FORMULA_OPERATOR_TYPE.GREATER_THAN,
  [CONDITION_OPERATOR.LESS_THAN.Key]: FORMULA_OPERATOR_TYPE.LESS_THAN,
  [CONDITION_OPERATOR.GREATER_THAN_OR_EQUAL_TO.Key]:
    FORMULA_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  [CONDITION_OPERATOR.LESS_THAN_OR_EQUAL_TO.Key]:
    FORMULA_OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  [CONDITION_OPERATOR.IS.Key]: FORMULA_OPERATOR_TYPE.EQUAL,
};

export const FORMULA_CONDITION_OPERATORS_TO_STANDARD_CONDITION_OPERATORS_MAP = {
  [FORMULA_OPERATOR_TYPE.BETWEEN]: CONDITION_OPERATOR.BETWEEN,
  [FORMULA_OPERATOR_TYPE.GREATER_THAN]: CONDITION_OPERATOR.GREATER_THAN,
  [FORMULA_OPERATOR_TYPE.LESS_THAN]: CONDITION_OPERATOR.LESS_THAN,
  [FORMULA_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL]:
    CONDITION_OPERATOR.GREATER_THAN_OR_EQUAL_TO,
  [FORMULA_OPERATOR_TYPE.LESS_THAN_OR_EQUAL]:
    CONDITION_OPERATOR.LESS_THAN_OR_EQUAL_TO,
  [FORMULA_OPERATOR_TYPE.EQUAL]: CONDITION_OPERATOR.IS,
};

export const formulaToPayload = (formula) => {
  return {
    Operand: formula?.tokenType,
    Value: formula?.value,
    FieldName: formula?.fieldName,
    Cycle: formula?.cycle,
    Type: formula?.type,
    ProgramName: formula?.programName,
    ...(formula?.goalName && {
      GoalName: formula?.goalName,
    }),
    ...(formula?.templateName && {
      TemplateName: formula?.templateName,
    }),
    ...(formula?.GoalAchievementType && {
      GoalAchievementType: formula?.GoalAchievementType,
    }),
    ...(formula?.SubGoalConditions &&
      formula?.ConditionPattern && {
        SubGoal: {
          ConditionPattern: formula?.ConditionPattern,
          SubGoalConditions: formula?.SubGoalConditions,
        },
      }),
    ...(formula?.ReporteesIncentiveValue && {
      ReporteesIncentiveValue: formula?.ReporteesIncentiveValue,
    }),
  };
};

export const payloadToFormula = (payload) => {
  return {
    tokenType: [TOKEN_TYPE.PARENTHESIS].includes(payload?.Operand)
      ? TOKEN_TYPE.OPERATOR
      : payload?.Operand,
    value: payload?.Value,
    fieldName: payload?.FieldName,
    cycle: payload?.Cycle,
    type: payload?.Type,
    operatorTokenType: FORMULA_OPERATORS[payload?.Value]?.operatorTokenType,
    programName: payload?.ProgramName,
    ...(payload?.GoalName && {
      goalName: payload?.GoalName,
    }),
    ...(payload?.TemplateName && {
      templateName: payload?.TemplateName,
    }),
    ...(payload?.GoalAchievementType && {
      GoalAchievementType: payload?.GoalAchievementType,
    }),
    ...(payload?.SubGoal && payload?.SubGoal),
    ...(payload?.ReporteesIncentiveValue && {
      ReporteesIncentiveValue: payload?.ReporteesIncentiveValue,
    }),
  };
};

export const standardToCustomCondition = ({ condition }) => {
  if (!condition || condition?.IsFormula)
    return {
      IsFormula: true,
      Formula: condition?.Formula?.map(payloadToFormula),
      Name: condition?.Formula?.Name,
    };
  const metric = {
    tokenType: TOKEN_TYPE.METRIC,
    value: condition?.GoalId || condition?.TemplateId,
    fieldName: condition?.GoalId ? "GoalId" : "TemplateId",
    ...(condition?.GoalId &&
      (condition?.GoalName || condition?.goalName) && {
        goalName: condition?.GoalName || condition?.goalName,
      }),
    ...(condition?.TemplateId &&
      (condition?.TemplateName || condition?.templateName) && {
        templateName: condition?.TemplateName || condition?.templateName,
      }),
    ...(condition?.achievedValue && {
      achievedValue: condition?.achievedValue,
    }),
    ...(condition.goalValue && {
      goalValue: condition?.goalValue,
    }),
    ...(groupByConditionValid(condition?.SubGoal) && condition?.SubGoal),
  };

  const value =
    STANDARD_CONDITION_OPERATORS_TO_FORMULA_CONDITION_OPERATORS_MAP[
      condition?.Operator?.Key
    ];

  const operator = {
    tokenType: TOKEN_TYPE.OPERATOR,
    operatorTokenType: FORMULA_OPERATORS[value].operatorTokenType,
    value,
  };

  const staticValue = {
    tokenType: TOKEN_TYPE.STATIC,
    value:
      condition?.Operator?.Value === FORMULA_OPERATOR_TYPE.BETWEEN
        ? `${condition?.Value?.startsAt} AND ${condition?.Value?.endsAt}`
        : condition?.Value,
    fieldName:
      condition?.Operator?.Value === FORMULA_OPERATOR_TYPE.BETWEEN
        ? FORMULA_OPERATOR_TYPE.BETWEEN
        : null,
  };

  return { IsFormula: false, Formula: [metric, operator, staticValue] };
};

export const getSubGoalEntityValuePair = (subGoal) => {
  const groupByEntities = [];
  const entityValuePair = subGoal?.reduce((acc, subGoal) => {
    groupByEntities.push(subGoal.Entity);
    acc[subGoal.Entity] = subGoal.Values;
    return acc;
  }, []);
  return [groupByEntities.join(","), entityValuePair];
};
