import { t } from "i18next";
import React from "react";
import { HeroBackground } from "src/components/common";
import { ACE_EMAIL_ID } from "src/constants";
import { classNames } from "src/helpers";
import { DesktopBackground } from "src/images";
import "./requestDemo.scss";

const RequestDemoWeb = ({
  logo,
  productImage,
  title,
  subTitle,
  demoLine,
  showEmail = true,
  orgInput,
}) => {
  return (
    <HeroBackground
      image={DesktopBackground}
      className={classNames(["desktop-video-container center"])}
    >
      <div className="page-container  m-4 d-flex align-self-center">
        <div className="w-50 pt-4 d-flex align-self-center flex-column">
          {logo}
          <p className="fs-30 semi-bold pt-4 pr-4 pb-2 fc-darker-grey">
            {title}
          </p>
          <p className="fs-20  py-4 fc-darker-grey">{subTitle}</p>
          <p className="fs-18  py-1 fc-black semi-bold">{demoLine}</p>
          {orgInput}
          {showEmail && (
            <p className="fs-18  pt-2 pb-4 fc-grey w-75">
              {t("DEMO.REACH_OUT_TO_ACCOUNT_MANAGER")}
              <span className="fc-black fs-18 semi-bold"> {ACE_EMAIL_ID}</span>
            </p>
          )}
        </div>
        <div className="w-50 d-flex align-items-center justify-content-end pt-4">
          {productImage}
        </div>
      </div>
    </HeroBackground>
  );
};

export default RequestDemoWeb;
