import { gql, useLazyQuery, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_INCENTIVE_TAB_VIEW_DETAILS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getProgram($ProgramId: ID!) {
    program(ProgramId: $ProgramId) {
      __typename
      ... on SingleProgramResponse {
        response {
          Program {
            ProgramId
            VisibilitySettings {
              ShowIncentiveHistory
              ShowCriteriaDetails
            }
            Name
            Description
            Status
            RecurrenceDefinition {
              FirstRecurrenceStartDate
              LastRecurrenceEndDate
              RecurrencePeriod
              RecurrenceCycleDuration
              CustomType
              CustomGap
              Recurrences {
                RecurrenceStartDate
                RecurrenceEndDate
              }
              __typename
            }
            Type
            IsRollup
            RollupType
            CurrentRecurrenceCycle
            RecurrenceTimeLeft
            RecurrenceDueText
            IsGoalsEnabled
            UsersCount
            CriteriaCount
            RecurrencesCount
            InGaps
            FileName
            DefaultBufferDays
            BufferDays
            DisableApprovalPayout
            LinkedProgramsCount
            UserSelectionType
            SelectedApprovers {
              ProgramId
              UserId
              Level
              Stage
              Type
              EmailAddress
              FirstName
              LastName
              StatusCode
            }
            CurrentRecurrenceRange {
              RecurrenceStartDate
            }
            FirstRecurrenceRange {
              RecurrenceStartDate
              RecurrenceEndDate
            }
            LastRecurrenceRange {
              RecurrenceStartDate
              RecurrenceEndDate
            }
            LinkedProgramsCount
            SelectedTemplates {
              TemplateId
              Name
              GoalAggregateFunction
              GoalRules {
                RuleId
                RuleName
                Entity
                RollUpFields
                Conditions
                CriteriaPattern
                GoalAggregateFunction
              }
              Description
              IsCustomFormula
              DrilldownStatus
              GroupBy
              RulesetType
              Formula
            }
            SelectedGoals {
              GoalId
              GoalName
              AggregateFunction
              TemplateId
              Recurrences
            }
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetIncentiveTabViewDetails = (queryOptions) => {
  return useQuery(GET_INCENTIVE_TAB_VIEW_DETAILS_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};

const GET_INCENTIVE_RECURRENCES_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getRecurrenceSummary($ProgramId: ID!) {
    getRecurrenceSummary(ProgramId: $ProgramId) {
      __typename
      ... on RecurrenceSummaryResponse {
        success
        message
        response {
          ProgramId
          RecurrenceId
          RecurrenceStartDate
          RecurrenceEndDate
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetIncentiveRecurrences = (queryOptions) => {
  return useQuery(GET_INCENTIVE_RECURRENCES_QUERY, queryOptions);
};

const GET_INCENTIVE_CRITERIA_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getIncentiveCriteria($ProgramId: ID!, $RecurrenceId: ID!, $UserId: ID) {
    getIncentiveCriteria(
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      UserId: $UserId
    ) {
      ... on GetIncentiveCriteriaResponse {
        response
        __typename
      }
      ...errorResponseFragment
      __typename
    }
  }
`;

export const useGetIncentiveCriteria = (queryOptions) => {
  return useLazyQuery(GET_INCENTIVE_CRITERIA_QUERY, queryOptions);
};

export const useGetDashboardIncentiveCriteria = (queryOptions) => {
  return useQuery(GET_INCENTIVE_CRITERIA_QUERY, queryOptions);
};
