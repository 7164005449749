import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Overlay from "react-bootstrap/Overlay";
import { useTranslation } from "react-i18next";
import { FormButton, FormTooltip } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import {
  SvgAfternoon,
  SvgEdit,
  SvgEvening,
  SvgLateEvening,
  SvgLateMorning,
  SvgMorning,
  SvgNight,
  SvgNoon,
} from "src/icons";
import "./multiSelectDropdown.scss";

export const defaultMultiSelectDropdownOptions = {
  MORNING: {
    icon: <SvgMorning />,
    label: "Morning",
    value: "MORNING",
    text: t("NUDGES.LABELS.LATEST_DATA_AVAILABLE_CLOSE_TO", {
      time: "09:00 am",
    }),
    mandatorySelection: false,
    checked: false,
  },
  LATE_MORNING: {
    icon: <SvgLateMorning />,
    label: "Late Morning",
    value: "LATE_MORNING",
    text: t("NUDGES.LABELS.LATEST_DATA_AVAILABLE_CLOSE_TO", {
      time: "11:00 am",
    }),
    mandatorySelection: false,
    checked: false,
  },
  NOON: {
    icon: <SvgNoon />,
    label: "Noon",
    value: "NOON",
    text: t("NUDGES.LABELS.LATEST_DATA_AVAILABLE_CLOSE_TO", {
      time: "1:00 pm",
    }),
    mandatorySelection: false,
    checked: false,
  },
  AFTERNOON: {
    icon: <SvgAfternoon />,
    label: "Afternoon",
    value: "AFTERNOON",
    text: t("NUDGES.LABELS.LATEST_DATA_AVAILABLE_CLOSE_TO", {
      time: "3:00 pm",
    }),
    mandatorySelection: false,
    checked: false,
  },
  EVENING: {
    icon: <SvgEvening />,
    label: "Evening",
    value: "EVENING",
    text: t("NUDGES.LABELS.LATEST_DATA_AVAILABLE_CLOSE_TO", {
      time: "5:00 pm",
    }),
    mandatorySelection: false,
    checked: false,
  },
  LATE_EVENING: {
    icon: <SvgLateEvening />,
    label: "Late Evening",
    value: "LATE_EVENING",
    text: t("NUDGES.LABELS.LATEST_DATA_AVAILABLE_CLOSE_TO", {
      time: "7:00 pm",
    }),
    mandatorySelection: false,
    checked: false,
  },
  NIGHT: {
    icon: <SvgNight />,
    label: "Night",
    value: "NIGHT",
    text: t("NUDGES.LABELS.LATEST_DATA_AVAILABLE_CLOSE_TO", {
      time: "9:00 pm",
    }),
    mandatorySelection: false,
    checked: false,
  },
};

export const defaultData = [
  "LATE_MORNING",
  "NOON",
  "AFTERNOON",
  "EVENING",
  "LATE_EVENING",
];

export const AllData = [
  "MORNING",
  "LATE_MORNING",
  "NOON",
  "AFTERNOON",
  "EVENING",
  "LATE_EVENING",
  "NIGHT",
];
export const defaultContainerStyle = {
  maxWidth: "100%",
};
export const defaultDropdownStyle = {
  maxWidth: "100%",
};

const MultiSelectDropdown = (props) => {
  const {
    data,
    multiSelectDropdownOptions,
    containerStyle,
    dropdownStyle,
    maxSelection,
    setNudgeTime,
    viewOnly = true,
    minSelection,
  } = props;

  const { t } = useTranslation();
  const [multiOptions, setMultiOptions] = useState(multiSelectDropdownOptions);
  const [selectedOptions, setSelectedOptions] = useState(data);
  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    const newMultiOptions = { ...multiOptions };
    for (const key of Object.keys(newMultiOptions)) {
      newMultiOptions[key].checked = false;
    }
    if (data) {
      const nudgeTimes = [...data];
      nudgeTimes.sort(
        (nudgeTime1, nudgeTime2) =>
          AllData.indexOf(nudgeTime1) - AllData.indexOf(nudgeTime2)
      );
      nudgeTimes.forEach((key) => {
        newMultiOptions[key].checked = true;
      });
      setMultiOptions(newMultiOptions);
      setSelectedOptions(nudgeTimes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (item) => {
    const checked = !multiOptions[item].checked;
    setMultiOptions({
      ...multiOptions,
      [item]: { ...multiOptions[item], checked },
    });
    if (checked) {
      const updatedList = [...selectedOptions, item];
      setNudgeTime(updatedList);
      setSelectedOptions(updatedList);
    } else {
      const updatedList = selectedOptions.filter((option) => option !== item);
      setNudgeTime(updatedList);
      setSelectedOptions(updatedList);
    }
  };

  return (
    <div className="multi-tag-selector w-100">
      <div
        className={`multi-tag-selector__container m-0 row ${
          viewOnly ? "pointer-none" : ""
        } ${showPopover ? "multi-tag-selector__container--open " : ""}`}
        style={
          containerStyle
            ? { ...defaultContainerStyle, ...containerStyle }
            : defaultContainerStyle
        }
        ref={target}
      >
        <div className="multi-tag-selector__tag-list ">
          {selectedOptions &&
            selectedOptions.map((item, index) => (
              <div
                className="multi-tag-selector__tag d-flex justify-content-between align-items-center"
                key={index}
              >
                {multiOptions[item] && (
                  <>
                    <span className="multi-tag-selector__tag-icon d-flex align-items-center">
                      {multiOptions[item]?.icon}
                    </span>
                    <span className="multi-tag-selector__tag-text ml-1">
                      {multiOptions[item]?.label}
                    </span>
                  </>
                )}
              </div>
            ))}
        </div>
        {!viewOnly && (
          <FormButton
            className="multi-tag-selector__edit "
            variant="icon-only"
            onClick={() => setShowPopover(true)}
          >
            <SvgEdit className="multi-tag-selector__edit--normal" />
            <SvgEdit
              color={COLORS.PRIMARY_BLUE}
              className="multi-tag-selector__edit--active"
            />
          </FormButton>
        )}
      </div>
      {!viewOnly && (
        <Overlay
          target={target.current}
          show={showPopover}
          placement="bottom"
          rootClose
          onExited={() => setShowPopover(false)}
          onHide={() => setShowPopover(false)}
          onToggle={() => setShowPopover((prev) => !prev)}
        >
          {({ style, ...props }) => (
            <div
              {...props}
              style={{
                position: "relative",
                ...style,
                zIndex: 1055,
              }}
            >
              <div
                className="multi-tag-dropdown__container"
                style={
                  dropdownStyle
                    ? { ...defaultDropdownStyle, ...dropdownStyle }
                    : defaultDropdownStyle
                }
              >
                <div className="multi-tag-dropdown__header d-flex justify-content-start px-3 py-2 fs-12">
                  <div className="multi-tag-dropdown__header-selected fc-grey">
                    {selectedOptions?.length}/{maxSelection}{" "}
                    {t("MANAGED_GOALS.LABELS.SELECTED")}
                  </div>
                </div>
                <div className="multi-tag-dropdown__body px-3 pb-3">
                  {multiOptions &&
                    Object.keys(multiOptions).map((item, index) => {
                      const isGreater =
                        !multiOptions[item]?.checked &&
                        selectedOptions.length >= maxSelection;

                      const isLesser =
                        multiOptions[item]?.checked &&
                        selectedOptions.length <= minSelection;

                      const isMandatory = multiOptions[item].mandatorySelection;
                      const showTooltip = isLesser || isGreater;
                      return (
                        <div
                          className="d-flex justify-content-start align-items-center py-2 border-b"
                          key={index}
                        >
                          <FormTooltip
                            id="multiselect-tooltip"
                            placement="bottom"
                            text={
                              isLesser
                                ? t(
                                    "MANAGED_GOALS.MESSAGES.MIN_SELECT_OPTIONS",
                                    {
                                      minSelection,
                                    }
                                  )
                                : t(
                                    "MANAGED_GOALS.MESSAGES.MAX_SELECT_OPTIONS",
                                    {
                                      maxSelection,
                                    }
                                  )
                            }
                            disable={!showTooltip}
                          >
                            <div className="mr-2">
                              <input
                                checked={multiOptions[item]?.checked}
                                disabled={isGreater}
                                type="checkbox"
                                onChange={() =>
                                  !isMandatory &&
                                  !isLesser &&
                                  handleChange(item)
                                }
                              />
                            </div>
                          </FormTooltip>
                          <div>
                            <div className="fs-12">
                              {multiOptions[item]?.label}
                            </div>
                            <div className="fc-grey fs-10">
                              {multiOptions[item]?.text}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </Overlay>
      )}
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  data: PropTypes.array,
  multiSelectDropdownOptions: PropTypes.object,
  containerStyle: PropTypes.object,
  dropdownStyle: PropTypes.object,
  maxSelection: PropTypes.number,
  minSelection: PropTypes.number,
};

MultiSelectDropdown.defaultProps = {
  data: defaultData,
  multiSelectDropdownOptions: defaultMultiSelectDropdownOptions,
  containerStyle: defaultContainerStyle,
  dropdownStyle: defaultDropdownStyle,
  maxSelection: 3,
  minSelection: 1,
};

export default MultiSelectDropdown;
