import React from "react";

const SvgAvatar27 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="a5ad__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .a5ad__cls-1{fill:#fff}.a5ad__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="a5ad__Layer_1-2">
        <path
          d="M162.8 81.4c0 7.6-1.04 14.96-3 21.94-.52 1.87-1.1 3.72-1.76 5.54-3.97 11.08-10.29 21.05-18.35 29.32a81.436 81.436 0 0 1-21.97 16.06c-1.55.78-3.13 1.51-4.74 2.18a80.4 80.4 0 0 1-8.39 3.01c-1.5.44-3.02.85-4.56 1.2a81.113 81.113 0 0 1-18.63 2.15c-5.34 0-10.55-.51-15.6-1.5-2.58-.5-5.11-1.12-7.6-1.86-13.5-4-25.53-11.42-35.1-21.24C8.8 123.52 0 103.5 0 81.4c0-8.73 1.37-17.14 3.93-25.02.3-.99.64-1.96 1-2.93a80.83 80.83 0 0 1 13.39-23.49c.63-.79 1.29-1.56 1.97-2.32C35.19 10.69 57.05 0 81.4 0c44.96 0 81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.75}
          className="a5ad__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.07)"
        />
        <path
          d="M139.69 138.19c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C39.4 126.13 59.57 119 81.4 119s41.99 7.12 58.29 19.19Z"
          className="a5ad__cls-2"
        />
        <path
          d="M109.79 86.51c-5.96 0-10.82-4.85-10.82-10.82 0-.83.67-1.5 1.5-1.5h18.64c.83 0 1.5.67 1.5 1.5 0 5.96-4.85 10.82-10.82 10.82Zm-7.67-9.32c.7 3.6 3.88 6.32 7.67 6.32s6.97-2.72 7.67-6.32h-15.35ZM82.68 86.51c-5.96 0-10.82-4.85-10.82-10.82 0-.83.67-1.5 1.5-1.5H92c.83 0 1.5.67 1.5 1.5 0 5.96-4.85 10.82-10.82 10.82Zm-7.67-9.32c.7 3.6 3.88 6.32 7.67 6.32s6.97-2.72 7.67-6.32H75Z"
          className="a5ad__cls-1"
        />
        <path
          d="M99.55 76.87c-2-1.58-4.69-1.57-6.6-.01l-1.89-2.33a8.186 8.186 0 0 1 10.28-.06l-1.79 2.41ZM51.86 83.96a1.5 1.5 0 0 1-1.44-1.92c1.35-4.63 5.66-7.86 10.48-7.86h12.47c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H60.9c-3.49 0-6.62 2.34-7.6 5.7-.19.66-.79 1.08-1.44 1.08ZM61.28 160.37c-.77-.19-1.53-.4-2.29-.61.09-.49.53-2.01.61-2.5.14-.79.29-1.58.42-2.37l2.48-14.52 5.24-30.73.99-5.77c.16-.93 1.02-1.68 1.62-1.68.08 0 .15.01.21.04.24.1.51.2.78.3l3.58 1.35c2.63.99 5.25 1.98 7.88 2.98 1.65.61 3.28 1.23 4.9 1.85l21.86 8.27.4.15h.61l.73-.02h10.52l.53-.15c.31-.11.58-.2.85-.29.3-.1.61-.2.91-.31 4.35-1.44 8.71-2.9 13.06-4.38 2.19-.73 11.29-3.79 11.29-3.79 2.43-.81 4.83-1.61 7.24-2.43 0 0 3.26-1.27 4.01-1.51-.33 1.1-.68 2.16-1.04 3.19 0 0-5.49 2.01-6.56 2.37-.95.32-1.91.64-2.87.96-.96.32-1.93.64-2.89.97l-13.28 4.45-10.45 3.51-.1.59c-.17 1.02-.36 2.04-.54 3.07-.14.76-.27 1.52-.41 2.28l-2.48 14.52-2.52 14.7c-.85.42-1.7.82-2.55 1.2l6.15-35.94h-6.91c-.43 0-.86.01-1.3.02l-1.68.03-.14.82c-.13.77-.27 1.54-.41 2.32-.14.78-.28 1.55-.41 2.33l-5.57 34.07c-.86.25-1.67.46-2.44.66l6.67-40.54-.93-.36c-.11-.05-.23-.09-.34-.13-1.77-.67-3.57-1.35-5.37-2.04-2.05-.77-4.1-1.55-6.15-2.33l-3.75-1.42c-2.12-.8-4.23-1.6-6.35-2.4-1.2-.46-2.4-.91-3.61-1.37l-2.84-1.08-7.85-2.97-.21 1.19c-.11.65-.23 1.3-.35 1.95-.11.61-.23 1.23-.33 1.83l-2.47 14.51-6.18 35.15Z"
          className="a5ad__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar27.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar27;
