import { lazy } from "react";

const WebPage = lazy(() =>
  import(
    /* webpackChunkName: 'leaderboards.web.myBoardsDetailViewPage' */ "./web"
  )
);
const MobilePage = lazy(() =>
  import(
    /* webpackChunkName: 'leaderboards.mobile.myBoardsDetailViewPage' */ "./mobile"
  )
);
export const MyBoardsDetailViewPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "My Boards Detail View",
  },
};
