import { useEffect, useState } from "react";
import {
  formulaEvaluator as evaluator,
  formulaTokenizer as tokenizer,
  formulaValidator as validator,
} from "./formulaParser";

const useFormulaBuilder = ({
  initialFormula = [],
  config,
  onSave,
  onEdit,
  disabled,
}) => {
  const [formula, changeFormula] = useState(initialFormula);
  const [error, setError] = useState();
  const [tokens, setTokens] = useState([]);
  const usedNamespace = tokenizer.getUsedNamespace(config);
  const menuList = config.map((item) => ({
    ...item,
    ...usedNamespace[item.type],
  }));

  useEffect(() => {
    if (initialFormula?.length === 0) {
      changeFormula([]);
      setTokens([]);
      setError(null);
      return;
    }
    const newTokens = [];
    changeFormula(initialFormula);
    initialFormula.forEach((value, index) => {
      const token = tokenizer.getToken({
        value,
        namespace: usedNamespace,
        index,
      });
      newTokens.push(token);
    });
    if (newTokens.length) {
      const expressionError = validator.validateExpression(newTokens);
      setError(expressionError || null);
    }
    setTokens(newTokens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormula.join(""), JSON.stringify(config)]);

  const pushFormula = (value) => {
    const newValue = value.trim();
    if (newValue === "") {
      return false;
    }
    const token = tokenizer.getToken({
      value: newValue,
      namespace: usedNamespace,
      index: tokens.length,
    });
    const tokenError = validator.validateToken({
      lastToken: tokens[tokens.length - 1],
      nextToken: token,
    });
    if (tokenError) {
      setError(tokenError);
      return false;
    }
    setTokens((prevTokens) => [...prevTokens, token]);
    changeFormula((prevFormula) => [...prevFormula, String(token.value)]);
    setError(null);
    return true;
  };

  const popFormula = () => {
    const newFormula = [...formula];
    const newTokens = [...tokens];
    const removedItem = newFormula.pop();
    newTokens.pop();
    changeFormula(newFormula);
    setTokens(newTokens);
    return removedItem;
  };
  const handleSave = () => {
    const expressionError = validator.validateExpression(tokens);
    if (expressionError) {
      setError(expressionError);
      return false;
    }
    const evaluationError = evaluator.evaluatePostfix({
      tokens: evaluator.toPostfix(tokens),
    });
    if (evaluationError) {
      setError(evaluationError);
      return false;
    }
    setError(null);
    onSave(formula, setError);
  };

  const handleEdit = () => {
    onEdit();
  };

  return {
    menuList,
    error,
    setError,
    formula,
    disabled,
    actions: {
      pushFormula,
      popFormula,
      handleSave,
      handleEdit,
    },
  };
};

export default useFormulaBuilder;
