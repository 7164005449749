import { gql, useQuery } from "src/api/client";

const GET_APPROVAL_TOTALS = gql`
  query approvalTotals($UserId: ID, $FeatureType: String!) {
    approvalTotals(UserId: $UserId, FeatureType: $FeatureType) {
      ... on ApprovalTotalsResponse {
        success
        message
        response {
          totalApprovedAmount

          totalRejectedAmount

          totalApprovalPending
          TotalApprovedRewards {
            RewardCount
            RewardCategory
          }
          TotalRejectedRewards {
            RewardCount
            RewardCategory
          }
          TotalApprovalPendingRewards {
            RewardCount
            RewardCategory
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetApprovalTotals = (queryOptions) => {
  return useQuery(GET_APPROVAL_TOTALS, queryOptions);
};
