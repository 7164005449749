import { Fragment } from "react";
import { FormTooltip, GroupByLabel, Truncate } from "src/components";
import {
  FORMULA_METRIC_TYPE,
  GOAL_ACHIEVEMENT_TYPE,
  REPORTEES_TYPE,
} from "src/constants";
import { classNames } from "src/helpers";
import { formulaItemStyles } from "./FormulaItem";
import { RecurrenceItem } from "./formulaMenu/data";
import { formulaNamespace } from "./formulaParser";
import { FORMULA_OPERATORS } from "./formulaParser/formulaNamespace";

const { TOKEN_TYPE, FORMULA_OPERATOR_TYPE } = formulaNamespace;

const CustomBreadcrumb = ({ items }) => {
  return (
    <div className="d-flex space-x-6 flex-wrap">
      {items.map((item, index) => {
        return (
          <Fragment key={index}>
            <span className="fs-13 semi-bold flex">{item}</span>
            {index < items.length - 1 && items[index + 1] && (
              <span className="fc-grey1">&gt;</span>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

const FormulaItemMetric = ({ item }) => {
  switch (item?.fieldName) {
    case "GoalId": {
      const breadCrumbItems = [
        <Truncate maxWidth="250px" text={item?.goalName} />,
        item?.GoalAchievementType && (
          <Truncate
            maxWidth="250px"
            text={
              GOAL_ACHIEVEMENT_TYPE[item?.GoalAchievementType] ||
              REPORTEES_TYPE[item?.GoalAchievementType]
            }
          />
        ),
        item?.SubGoalConditions && <GroupByLabel groupByCondition={item} />,
      ];
      return (
        <span className="fs-13 semi-bold">
          <CustomBreadcrumb
            items={breadCrumbItems.filter((item) => Boolean(item))}
          />
        </span>
      );
    }
    case "TemplateId": {
      const breadCrumbItems = [
        <Truncate maxWidth="250px" text={item?.templateName} />,
        item?.SubGoalConditions && <GroupByLabel groupByCondition={item} />,
      ];

      return (
        <span className="fs-13 semi-bold flex">
          <CustomBreadcrumb
            items={breadCrumbItems.filter((item) => Boolean(item))}
          />
        </span>
      );
    }
    case "RecurrenceId": {
      const type = item?.goalName
        ? "GOAL"
        : item?.templateName
        ? "TEMPLATE"
        : "CALCULATED_INCENTIVE";

      switch (type) {
        case "GOAL": {
          const breadCrumbItems = [
            <Truncate maxWidth="250px" text={item?.programName} />,
            <Truncate maxWidth="250px" text={item?.goalName} />,
            <RecurrenceItem {...{ ...item?.cycle }} />,

            item?.GoalAchievementType && (
              <Truncate
                maxWidth="250px"
                text={
                  GOAL_ACHIEVEMENT_TYPE[item?.GoalAchievementType] ||
                  REPORTEES_TYPE[item?.GoalAchievementType]
                }
              />
            ),
            item?.SubGoalConditions && <GroupByLabel groupByCondition={item} />,
          ];
          return (
            <CustomBreadcrumb
              items={breadCrumbItems.filter((item) => Boolean(item))}
            />
          );
        }
        case "TEMPLATE": {
          const breadCrumbItems = [
            <Truncate maxWidth="250px" text={item?.programName} />,
            <Truncate maxWidth="250px" text={item?.templateName} />,
            <RecurrenceItem {...{ ...item?.cycle }} />,
            item?.SubGoalConditions && <GroupByLabel groupByCondition={item} />,
          ];
          return (
            <CustomBreadcrumb
              items={breadCrumbItems.filter((item) => Boolean(item))}
            />
          );
        }
        case "CALCULATED_INCENTIVE": {
          const breadCrumbItems = [
            <Truncate maxWidth="250px" text={item?.programName} />,
            FORMULA_METRIC_TYPE[item?.type],
            item?.ReporteesIncentiveValue && (
              <span>{item?.ReporteesIncentiveValue}</span>
            ),
            <RecurrenceItem {...{ ...item?.cycle }} />,
          ];
          return (
            <CustomBreadcrumb
              items={breadCrumbItems.filter((item) => Boolean(item))}
            />
          );
        }
        default:
          return null;
      }
    }
    default:
      return null;
  }
};

const FormulaItemField = ({ item }) => {
  return <span className="semi-bold">{item?.fieldName}</span>;
};

const FormulaItemOperator = ({ item }) => {
  const value =
    item?.value === FORMULA_OPERATOR_TYPE.BETWEEN
      ? FORMULA_OPERATORS[item?.value].label
      : FORMULA_OPERATORS[item?.value].value;

  return (
    <FormTooltip text={FORMULA_OPERATORS[item?.value].label}>
      <span className="semi-bold">{value}</span>
    </FormTooltip>
  );
};

const FormulaItemStatic = ({ item }) => {
  if (item?.fieldName === FORMULA_OPERATOR_TYPE.BETWEEN) {
    const [min, max] = item?.value.split("AND");
    return (
      <span className="semi-bold">
        {min} <span className="fc-grey1">to</span> {max}
      </span>
    );
  }
  return <span className="semi-bold">{item?.value}</span>;
};

const components = {
  [TOKEN_TYPE.METRIC]: FormulaItemMetric,
  [TOKEN_TYPE.FIELD]: FormulaItemField,
  [TOKEN_TYPE.OPERATOR]: FormulaItemOperator,
  [TOKEN_TYPE.STATIC]: FormulaItemStatic,
  [TOKEN_TYPE.PARENTHESIS]: FormulaItemOperator,
};

const ConditionListFormulaItem = ({ item, className }) => {
  const { styles } = formulaItemStyles(item);

  const Component = components[item?.tokenType];

  return (
    <div
      style={styles}
      className={classNames(["fs-13 px-2 py-1 br-8 m-1", className])}
    >
      <Component item={item} />
    </div>
  );
};
export default ConditionListFormulaItem;
