import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgNudge = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <g>
        <path fill="none" d="M0 0h32v32H0z" />
        <path
          fill={color}
          d="M21.527 20.913a1.629 1.629 0 0 1-.2-.013H10.6a1.611 1.611 0 0 1-1.459-.879A1.54 1.54 0 0 1 9.3 18.4l.578-.77A7.969 7.969 0 0 0 11.1 13.4a4.949 4.949 0 0 1 3.143-4.527A2.21 2.21 0 0 1 16 8a2.13 2.13 0 0 1 1.784.964A4.782 4.782 0 0 1 20.9 13.5a7.969 7.969 0 0 0 1.222 4.23l.578.77a1.521 1.521 0 0 1 .164 1.6 1.481 1.481 0 0 1-1.337.813ZM11.425 18.9H20.5q-.022-.029-.041-.059A9.99 9.99 0 0 1 18.9 13.5a2.768 2.768 0 0 0-2.043-2.73 1 1 0 0 1-.686-.6C16.1 10 16.036 10 16 10a.243.243 0 0 0-.206.147 1 1 0 0 1-.6.507A2.982 2.982 0 0 0 13.1 13.4a9.99 9.99 0 0 1-1.559 5.341l-.041.059Z"
        />
        <path
          fill={color}
          d="M16 23.231a2.739 2.739 0 0 1-2.769-2.769.692.692 0 1 1 1.384 0c0 .789 1.874-.117 2.769 0a.692.692 0 0 1 1.384 0A2.739 2.739 0 0 1 16 23.231Z"
        />
        <path
          fill={color}
          d="M7.3 13a1 1 0 0 1-.506-.138l-2.4-1a1 1 0 0 1 1.013-1.724l2.4 1A1 1 0 0 1 7.3 13ZM4.9 24a1 1 0 0 0 .506-.138l2.4-1a1 1 0 1 0-1.013-1.724l-2.4 1A1 1 0 0 0 4.9 24Z"
        />
        <path
          fill={color}
          d="M6 18H3a1 1 0 0 1-1-1 1 1 0 0 1 1-1h3a1 1 0 0 1 1 1 1 1 0 0 1-1 1Z"
        />
        <g fill={color}>
          <path d="M24.7 13a1 1 0 0 0 .506-.138l2.4-1a1 1 0 0 0-1.013-1.724l-2.4 1A1 1 0 0 0 24.7 13ZM27.1 24a1 1 0 0 1-.506-.138l-2.4-1a1 1 0 1 1 1.013-1.724l2.4 1A1 1 0 0 1 27.1 24Z" />
          <path d="M26 18h3a1 1 0 0 0 1-1 1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1 1 1 0 0 0 1 1Z" />
        </g>
      </g>
    </svg>
  );
};
SvgNudge.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 32,
  width: 32,
};
export default SvgNudge;
