import { useEffect, useRef } from "react";
import { FixedSizeList } from "src/components/common/molecules/virtual";
import { classNames } from "src/helpers";
import "./indicators.scss";

function Indicators({ itemSpan, itemCount, activeIndex }) {
  const listRef = useRef();
  const indicatorCount = Math.ceil(itemCount / itemSpan);

  useEffect(() => {
    if (indicatorCount > 5 && listRef?.current) {
      if (activeIndex === indicatorCount - 1) {
        listRef.current.scrollLeft = listRef.current.scrollHeight;
      }
      if (activeIndex <= 2) {
        listRef.current.scrollTo(0, 0);
      } else if (activeIndex % 6 >= 3) {
        listRef.current.scrollTo(listRef.current.scrollLeft + 10, 0);
      }
    }
  }, [activeIndex, indicatorCount]);

  return (
    <FixedSizeList
      outerRef={listRef}
      width={50}
      itemSize={10}
      height={10}
      layout="horizontal"
      itemCount={indicatorCount}
      className="flex indicators"
      style={{ overflow: "hidden", scrollBehavior: "smooth" }}
    >
      {({ index, style }) => {
        return (
          <div key={index} style={{ margin: 2, ...style }}>
            <div
              className={classNames([
                "indicator",
                index === activeIndex ? "active" : "",
              ])}
            />
          </div>
        );
      }}
    </FixedSizeList>
  );
}

export default Indicators;
