import { memo } from "react";
import { ConditionPatternConfig } from "src/components";
import { SvgCondition } from "src/icons";

const ConditionPattern = ({
  conditions,
  conditionPattern,
  setConditionPattern,
  disabled,
  criteriaIndex,
}) => {
  return (
    <div>
      <hr className="mt-4 mb-3" />
      <div className="pr-3">
        <SvgCondition className="mr-3 ml-2" />
        <span className="fs-13 fc-black semi-bold">Condition Pattern</span>
        <span className="fs-12 fc-grey ml-2">
          Interchange AND/OR operators, and group the conditions using braces.
        </span>
        <div className="mt-3" style={{ marginLeft: 40 }}>
          <ConditionPatternConfig
            inputClassName="item-body"
            condition={conditions}
            conditionPattern={conditionPattern}
            setConditionPattern={setConditionPattern}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ConditionPattern, (prevProps, nextProps) => {
  return (
    prevProps.conditions.length === nextProps.conditions.length &&
    prevProps.criteriaIndex === nextProps.criteriaIndex
  );
});
