import { t } from "i18next";
import React from "react";
import { FormButton } from "src/components/common/formElements";
import { useBackToTop } from "src/hooks";
import { SvgBack } from "src/icons";
import "./backToTop.scss";

const BackToTop = ({ modalRef }) => {
  const { showScroll, scrollTop } = useBackToTop({ modalRef });
  return (
    <div className="flex-center">
      <FormButton
        onClick={scrollTop}
        variant="link"
        className="back-to-top transition-all center"
        style={{ display: showScroll ? "flex" : "none" }}
      >
        <SvgBack rotate={90} width="13" height="13" />
        <span className="ml-1 fs-13">{t("COMMON.LABELS.BACK_TO_TOP")}</span>
      </FormButton>
    </div>
  );
};

export default BackToTop;
