import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgEvening = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      role="img"
      width={width}
      height={height}
      {...rest}
    >
      <defs>
        <style>
          {`\n      .cls-1, .cls-2 {\n        fill: ${color};\n      }\n\n      .cls-2 {\n        fill-rule: evenodd;\n      }\n    `}
        </style>
      </defs>
      <g id="Color_Off" data-name="Color Off">
        <path
          className="cls-2"
          d="M5.5916,1.9876V.6975h.8585V1.9876h-.8585Zm2.4225,.4185l.6447-1.1175,.745,.4301-.6463,1.1167-.7434-.4292Zm1.0172,4.3113c.1073-1.5931-1.0972-2.9716-2.6903-3.0789-.1063-.0072-.2128-.0084-.3192-.0038-1.6146-.0505-2.9644,1.2174-3.015,2.832-.0026,.0836-.0016,.1673,.0029,.2508v-.2467s6.0151-.0271,6.02,0c-.0099,.0164,0,.4457,0,.2467h.0016Zm.8601,0c0-2.1374-1.7327-3.8701-3.8701-3.8701s-3.8701,1.7327-3.8701,3.8701c-.0019,.1436,.0294,.2857,.0913,.4153,0-.0206,7.5445,.0074,7.5412,0,.0797-.1231,.1169-.269,.1061-.4153h.0016Zm.8585-.4284h1.2902v.8585h-1.2902v-.8585Zm.2681-2.952l-1.1175,.6447,.4301,.745,1.1175-.6447-.4301-.745ZM2.6379,1.7188l.6447,1.1167,.7466-.4292-.6471-1.1175-.7442,.4301Zm-.9292,3.0079l-1.1175-.6471,.4301-.745,1.1167,.6455-.4292,.7466Zm-.4185,1.5624H0v.8585H1.2902v-.8585Z"
        />
      </g>
      <rect className="cls-1" x={0.0005} y={8.125} width={12} height={0.9004} />
      <rect
        className="cls-1"
        x={1.0005}
        y={10.0029}
        width={10.0381}
        height={0.9004}
      />
    </svg>
  );
};

SvgEvening.defaultProps = {
  width: 12,
  height: 12,
  color: COLORS.GREY_2,
};

export default SvgEvening;
