export const AUTHENTICATION = {
  AUTH_HEADER: "Authorization",
  X_ATTRIBUTES: "X-Attributes",
  USER_ATTRIBUTES: "User-Context",
  ORG_CODE: "OrgCode",
  PLATFORM: "Platform",
};

export const TIMEZONES = [
  "Pacific/Samoa",
  "US/Hawaii",
  "US/Alaska",
  "US/Pacific",
  "US/Arizona",
  "Canada/Mountain",
  "US/Mountain",
  "America/Mexico_City",
  "US/Central",
  "America/Guatemala",
  "Canada/Saskatchewan",
  "US/East-Indiana",
  "America/Bogota",
  "US/Eastern",
  "America/Manaus",
  "America/Caracas",
  "America/Santiago",
  "Canada/Atlantic",
  "Canada/Newfoundland",
  "America/Argentina/Buenos_Aires",
  "America/Godthab",
  "America/Bahia",
  "Etc/GMT-2",
  "Atlantic/Cape_Verde",
  "Atlantic/Azores",
  "Africa/Casablanca",
  "Europe/London",
  "Europe/Amsterdam",
  "Africa/Algiers",
  "Europe/Brussels",
  "Europe/Sarajevo",
  "Europe/Belgrade",
  "Africa/Windhoek",
  "Africa/Harare",
  "Asia/Jerusalem",
  "Europe/Athens",
  "Europe/Kiev",
  "Egypt",
  "Europe/Minsk",
  "Europe/Moscow",
  "Africa/Nairobi",
  "Asia/Baghdad",
  "Asia/Kuwait",
  "Asia/Tehran",
  "Asia/Tbilisi",
  "Asia/Baku",
  "Asia/Yerevan",
  "Asia/Muscat",
  "Asia/Kabul",
  "Asia/Karachi",
  "Asia/Yekaterinburg",
  "Asia/Kolkata",
  "Asia/Katmandu",
  "Asia/Colombo",
  "Asia/Almaty",
  "Asia/Dhaka",
  "Asia/Rangoon",
  "Asia/Bangkok",
  "Asia/Krasnoyarsk",
  "Australia/Perth",
  "Asia/Taipei",
  "Asia/Singapore",
  "Asia/Irkutsk",
  "Asia/Chongqing",
  "Asia/Yakutsk",
  "Asia/Tokyo",
  "Asia/Seoul",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Pacific/Guam",
  "Asia/Vladivostok",
  "Australia/Hobart",
  "Australia/Brisbane",
  "Australia/Canberra",
  "Asia/Magadan",
  "Pacific/Auckland",
  "Pacific/Fiji",
  "Pacific/Tongatapu",
];

export const LANGUAGES = ["en-US", "es-ES", "hi-IN", "id-ID", "pt-BR", "vi-VN"];

export const ROUTES = {
  GOALS: "goals",
  MANAGED_BY_ME: "managed-by-me",
  ASSIGNED_TO_ME: "assigned-to-me",
  GOAL_CRUD: "configuregoal",
  MANAGED_GOAL_VIEW: "teamgoaldetails",
  ASSIGNED_GOAL_VIEW: "mygoaldetails",
  TEMPLATE_CRUD: "configuregoaltemplate",
  NUDGES: "nudges",
  LEADERBOARD_CRUD: "configure-leaderboard",
  MY_BOARDS: "my-boards",
  SETTINGS: "settings",
};

export const PAGINATION_LIMIT = 9;
export const COLORS = {
  PRIMARY_BLUE: "#309afc",
  SECONDARY_BLUE: "#e5f6ff",
  BLUE_1: "#7FAEDF",
  BLUE_2: "#e7f5fe",
  BLUE_3: "#2e95f5",
  BLUE_4: "#0177F0",
  PRIMARY_ORANGE: "#e67e22",
  PRIMARY_BLACK: "#1d1d1d",
  SECONDARY_BLACK: "#151b26",
  GREY_1: "#788aa0",
  GREY_2: "#a0aec0",
  GREY_3: "#d1dde8",
  GREY_4: "#e8eef3",
  GREY_5: "#f1f5f8",
  GREY_6: "#f5f8fa",
  GREY_7: "#e5eaef",
  GREY_8: "#e3eaf0",
  GREY_9: "#c7d5e3",
  GREY_10: "#b6c1cd",
  GREY_11: "#ecebeb",
  GREY_12: "#c8d5e3",
  CARD_RED: "#ed2121",
  CARD_ORANGE: "#eb7d1e",
  CARD_YELLOW: "#e6ba00",
  CARD_YELLOW_GREEN: "#c0d00f",
  CARD_GREEN: "#27ae60",
  WHITE: "#ffffff",
  BLACK: "#000000",
  RED: "#e74c3c",
  SECONDARY_RED: "#fff0ed",
};

export const ACE_EMAIL_ID = "ace@leadsquared.com";

export const DATE_FORMAT = "do MMM yyyy";
export const DATE_FORMAT_WITH_TIME = "do MMM yyyy h:mm a";
export const DATE_FORMAT_WITH_AFTER_TIME = "h:mm aaa, do MMM yyyy";
export const DATE_FORMAT_WITH_WEEKDAY = "E do MMM yyyy";
export const DATE_FORMAT_WITH_FULL_WEEKDAY = "EEEE do MMM yyyy";
export const DATE_FORMAT_PAYLOAD = "yyyy-MM-dd"; // ! do not change, BE date payload Format
export const DATE_FORMAT_WITH_TIME_PAYLOAD = "yyyy-MM-dd HH:mm:ss"; // ! do not change, BE date payload Format

export const SORT_COLUMN = {
  sortType: (rowA, rowB, id, desc) => {
    if (!desc) {
      if (
        typeof rowA.original[id] !== "string" ||
        typeof rowB.original[id] !== "string"
      ) {
        if (rowB.original[id] === null) return 1;
        if (rowA.original[id] === null) return -1;
        return rowA.original[id] < rowB.original[id] ? -1 : 1;
      }
      return String(rowA.original[id]).toLowerCase() <
        String(rowB.original[id]).toLowerCase()
        ? -1
        : 1;
    }

    if (
      typeof rowA.original[id] !== "string" ||
      typeof rowB.original[id] !== "string"
    ) {
      if (rowB.original[id] === null) return 1;
      if (rowA.original[id] === null) return -1;
      return rowA.original[id] > rowB.original[id] ? 1 : -1;
    }
    return String(rowA.original[id]).toLowerCase() >
      String(rowB.original[id]).toLowerCase()
      ? 1
      : -1;
  },
};

export const ALL_RECURRENCE_PERIODS = [
  "DAILY",
  "WEEKLY",
  "BIWEEKLY",
  "MONTHLY",
  "QUARTERLY",
  "YEARLY",
  "CUSTOM",
  "INFINITE",
];

export const FORMAT_RECURRENCE_PERIOD = {
  DAILY: {
    lowercase: "daily",
    capitalized: "Daily",
    uppercase: "DAILY",
  },
  WEEKLY: {
    lowercase: "weekly",
    capitalized: "Weekly",
    uppercase: "WEEKLY",
  },
  BIWEEKLY: {
    lowercase: "biweekly",
    capitalized: "Biweekly",
    uppercase: "BIWEEKLY",
  },
  MONTHLY: {
    lowercase: "monthly",
    capitalized: "Monthly",
    uppercase: "MONTHLY",
  },
  QUARTERLY: {
    lowercase: "quarterly",
    capitalized: "Quarterly",
    uppercase: "QUARTERLY",
  },
  YEARLY: {
    lowercase: "yearly",
    capitalized: "Yearly",
    uppercase: "YEARLY",
  },
  CUSTOM: {
    lowercase: "custom",
    capitalized: "Custom",
    uppercase: "CUSTOM",
    UNIFORM: {
      lowercase: "uniform",
      capitalized: "Uniform",
      uppercase: "UNIFORM",
    },
    NONUNIFORM: {
      lowercase: "non-uniform",
      capitalized: "Non-Uniform",
      uppercase: "NONUNIFORM",
    },
  },
  INFINITE: {
    lowercase: "infinite",
    capitalized: "Infinite",
    uppercase: "INFINITE",
  },
};
export const FEATURE_TYPES = {
  GOAL: "GOAL",
  INCENTIVE: "INCENTIVE",
  LEADERBOARD: "LEADERBOARD",
  SETTINGS: "SETTINGS",
  NUDGES: "NUDGES",
};

export const AVATARS_COLOR_COMBINATIONS = [
  {
    backgroundColor: "#6BA1FF", // Blue
    avatarColor: "#234580",
  },
  {
    backgroundColor: "#FF5C5C", // Red
    avatarColor: "#800000",
  },
  {
    backgroundColor: "#61B58A", // Green
    avatarColor: "#0D5A36",
  },
  {
    backgroundColor: "#916BB8", // Purple
    avatarColor: "#4C355F",
  },
  {
    backgroundColor: "#FFB066", // Pale Orange
    avatarColor: "#805833",
  },
  {
    backgroundColor: "#6C9ED8", // Steel Blue
    avatarColor: "#163E6D",
  },
  {
    backgroundColor: "#F29F05", // Orange
    avatarColor: "#5B3C02",
  },
  {
    backgroundColor: "#FF7A48", // Blush Orange
    avatarColor: "#803D24",
  },
  {
    backgroundColor: "#69BEC7", // Dull Green
    avatarColor: "#034A51",
  },
  {
    backgroundColor: "#E98FFF", // Magenta
    avatarColor: "#754880",
  },
  {
    backgroundColor: "#ABBF63", // Olive Green
    avatarColor: "#566032",
  },
  {
    backgroundColor: "#05C7F2", // Aqua Blue
    avatarColor: "#036479",
  },
  {
    backgroundColor: "#B5674A", // Brown
    avatarColor: "#3D2219",
  },
  {
    backgroundColor: "#F773B1", // Pink
    avatarColor: "#790B3F",
  },
  {
    backgroundColor: "#00E5CF", // Teal Blue
    avatarColor: "#007368",
  },
];

export const ROLLUP_TYPES = [
  {
    label: "Consider Selected Users",
    value: "SELECTEDUSERS",
    description:
      "Selected users achievement will be cumulative of individual performance as well as hierarchy.",
    disabledfor: { [FEATURE_TYPES.LEADERBOARD]: "ALL" },
  },
  {
    label: "Consider All Users",
    value: "ALLUSERS",
    description:
      "All users achievement will be cumulative of individual performance as well as hierarchy.",
    disabledfor: {},
  },
];

export const ENVIRONMENT = {
  LOCAL: "local",
  DEVELOPMENT: "development",
  TEST: "test",
  STAGE: "stage-ap-southeast-1",
  PROD_MUMBAI: "prod-ap-south-1",
  PROD_SGP: "prod-ap-southeast-1",
  PROD_US: "prod-us-east-1",
  OACE_DEV: "oace-dev",
  OACE_TEST: "oace-test",
  OACE_STAGE: "oace-stage-ap-south-1",
  OACE_PROD_MUMBAI: "oace-prod-ap-south-1",
};

export const FILE_NAME_LENGTH_LIMIT = 30;
