import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCreateGoal, useUpdateGoalStatus } from "src/api/useGoal";
import { GoalOwnerDropdown } from "src/components/base";
import { FormButton } from "src/components/common/formElements";
import { APPS, CREATE_PROGRAM_REGEX } from "src/constants";
import { useRoleBasedContext } from "src/context";
import { errorText, isEmpty } from "src/helpers";
import { withRouter } from "src/router";
import { ButtonShimmer } from "../buttonShimmer";
import { CreateInput } from "../createInput";
import { toaster } from "../toaster";
import "./createGoalModal.scss";

const CreateGoalModal = (props) => {
  const {
    defaultOwner,
    status,
    goalName,
    goalDescription,
    onShowClick,
    onCancelClick,
    preSelect,
    onSuccess,
    history,
    goalStatus,
    goalID,
    data,
    refetch,
  } = props;

  const { t } = useTranslation();
  const { userDetails } = useRoleBasedContext();
  const [chosenGoalOwner, setChosenGoalOwner] = useState(
    userDetails.role !== "Administrator"
      ? userDetails.id
      : preSelect
      ? defaultOwner.length
        ? defaultOwner[0]
        : defaultOwner
      : {}
  );

  const [nameValue, setNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [warning, setWarning] = useState(false);
  const [createGoal, { loading, error }] = useCreateGoal();
  const [UpdateGoal, { loading: updationLoading, error: updationError }] =
    useUpdateGoalStatus();
  useEffect(() => {
    !nameValue && setNameValue(goalName || "");
    !descriptionValue && setDescriptionValue(goalDescription || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultOwner,
    goalDescription,
    goalName,
    preSelect,
    userDetails.id,
    userDetails.role,
  ]);

  if (error) {
    toaster({ message: errorText.CREATE_GOAL.ERROR, type: "error" });
    return <></>;
  }

  if (updationError) {
    toaster({ message: errorText.UPDATE_GOAL.ERROR, type: "error" });
    return <></>;
  }

  const disabledValidation = (goalOwner) => {
    if (
      userDetails.role === "Administrator" &&
      status === "create" &&
      isEmpty(goalOwner)
    ) {
      return true;
    }

    if (warning || nameValue?.trim() === "") {
      return true;
    }
    if (status === "update") {
      if (
        goalName === nameValue?.trim() &&
        goalDescription === descriptionValue?.trim()
      )
        return true;
    }
    return false;
  };

  const onSubmit = () => {
    if (status === "create") {
      const UserId =
        userDetails.role !== "Administrator"
          ? `${userDetails.id}`
          : chosenGoalOwner.UserID === undefined
          ? defaultOwner[0]?.UserID !== undefined
            ? defaultOwner[0].UserID
            : defaultOwner.UserID
          : chosenGoalOwner.UserID;

      createGoal({
        variables: {
          UserId,
          Name: nameValue || "",
          Description: descriptionValue || "",
        },
      }).then(
        (result) => {
          if (result.data?.createGoal?.__typename === "SingleGoalResponse") {
            toaster({
              message: result.data?.createGoal?.message,
              type: "info",
            });
            onSuccess();
            history.push(
              APPS.GOALS.ROUTES.GOAL_CRUD.link({
                params: {
                  id: result.data.createGoal.response.Goal.GoalId,
                  userId: UserId,
                },
              })
            );
          } else {
            toaster({
              message: result.data?.createGoal?.message,
              type: "error",
            });
          }
        },
        (error) => {
          toaster({
            message: errorText.CREATE_GOAL.ERROR,
            type: "error",
          });
          console.error("Create error: ", error);
        }
      );
    } else {
      UpdateGoal({
        variables: {
          Name: nameValue,
          Description: descriptionValue,
          GoalId: goalID,
          Status: goalStatus || "DRAFT",
        },
      }).then(
        (result) => {
          if (result.data?.updateGoal?.__typename === "SingleGoalResponse") {
            toaster({
              message: result.data?.updateGoal?.message,
              type: "info",
            });
            onSuccess();
            refetch({
              variables: {
                Name: nameValue,
                Description: descriptionValue,
                GoalId: goalID,
              },
            });
          } else {
            toaster({
              message: result.data?.updateGoal?.message,
              type: "error",
            });
          }
        },
        (error) => {
          toaster({
            message: errorText.UPDATE_GOAL.ERROR,
            type: "error",
          });
          console.error("update error: ", error);
        }
      );
    }
  };

  return (
    <Modal
      dialogClassName="create-goal-modal"
      show={onShowClick}
      onHide={onCancelClick}
      centered
    >
      <Modal.Header closeButton>
        <span className="fs-18 semi-bold">
          {status === "create"
            ? t("MANAGED_GOALS.BUTTONS.CREATE_GOAL")
            : t("MANAGED_GOALS.LABELS.UPDATE_GOAL")}
        </span>
      </Modal.Header>
      <Modal.Body className="modal_body">
        <div className="p-4">
          {userDetails.role === "Administrator" && status === "create" ? (
            <div className="pb-4">
              <p className="semi-bold fs-14 pt-1 pb-2">
                {t("MANAGED_GOALS.LABELS.GOAL_OWNER")}
              </p>
              <GoalOwnerDropdown
                data={data}
                hideGoalCount={false}
                defaultOwner={defaultOwner}
                Owner={chosenGoalOwner}
                onChangeHandler={(selectedOption) =>
                  setChosenGoalOwner(selectedOption)
                }
                preSelect={preSelect}
              />
            </div>
          ) : null}
          <CreateInput
            disabled
            id="ace-create-Name-input"
            label={t("MANAGED_GOALS.LABELS.GOAL_NAME")}
            placeholder={t("MANAGED_GOALS.MESSAGES.ENTER_GOAL_NAME")}
            height="60px"
            maxLength={100}
            warning={warning}
            regex={CREATE_PROGRAM_REGEX}
            setWarning={setWarning}
            value={nameValue}
            setValue={setNameValue}
          />
          <CreateInput
            disabled
            as="textarea"
            id="ace-create-description-input"
            label={t("MANAGED_GOALS.LABELS.GOAL_DESCRIPTION")}
            placeholder={t("MANAGED_GOALS.MESSAGES.ENTER_GOAL_DESCRIPTION")}
            regex={false}
            maxLength={500}
            height="100px"
            value={descriptionValue}
            setValue={setDescriptionValue}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          onClick={onCancelClick}
          id="cancel-goal-btn"
          label={t("COMMON.BUTTONS.CANCEL")}
          variant="outline-primary"
        />

        <div className="d-inline-block">
          {loading || updationLoading ? (
            <ButtonShimmer
              className="br-2 fs-14"
              height={40}
              width="auto"
              text={
                status === "create"
                  ? t("COMMON.BUTTONS.CREATING")
                  : t("COMMON.BUTTONS.UPDATING")
              }
            />
          ) : (
            <FormButton
              id="create-goal-btn"
              className="fs-14"
              disabled={disabledValidation(chosenGoalOwner)}
              onClick={onSubmit}
              type="submit"
              label={
                status === "create"
                  ? t("MANAGED_GOALS.BUTTONS.CREATE_GOAL")
                  : t("MANAGED_GOALS.LABELS.UPDATE_GOAL")
              }
            />
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(CreateGoalModal);
