import { t } from "i18next";
import { useEffect } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { FormButton } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { classNames, getPluralText } from "src/helpers";
import {
  SvgAttachment,
  SvgDelete,
  SvgDownload,
  SvgError,
  SvgTick,
} from "src/icons";
import { FILE_FORMATS, FileInput, useFilePicker } from "../filePicker";
import { Truncate } from "../truncate";
import "./styles.scss";

const FilePickerError = ({
  errorCount,
  erroredFileUrl,
  handleBulkAction,
  handleRemove,
  buttonText,
  buttonDisabled,
}) => (
  <div className="flex-center flex-column h-100">
    <div className="center mb-3">
      <div className="center">
        <SvgError />
        <div className="fs-13 ml-2">
          <span className="semi-bold">
            {getPluralText(
              errorCount,
              t("COMMON.BUTTONS.ERROR"),
              t("COMMON.BUTTONS.ERRORS")
            )}
          </span>{" "}
          {t("COMMON.BUTTONS.FOUND")}
        </div>
      </div>
      <a
        href={erroredFileUrl}
        type="button"
        className="center fc-primary-blue ml-3 bg-transparent border-0"
      >
        <SvgDownload />
        <div className="fs-13 ml-2 mr-3 semi-bold">
          {t("MANAGED_GOALS.BUTTONS.DOWNLOAD_ERROR")}
        </div>
      </a>
      <FormButton
        variant="link"
        icon={<SvgDelete />}
        label={t("INCENTIVES.COMMON.LABELS.REMOVE_FILE")}
        onClick={handleRemove}
      />
    </div>
    <div>
      <FormButton onClick={handleBulkAction} disabled={buttonDisabled}>
        {buttonText}
      </FormButton>
    </div>
  </div>
);

const FilePickerSuccess = ({ buttonText, handleBulkAction, handleRemove }) => (
  <div className="flex-center flex-column h-100">
    <div className="center mb-3">
      <SvgTick backgroundColor="#2ecc71" />
      <div className="fs-13 ml-2 mr-3">File imported successfully</div>
      <FormButton
        onClick={handleRemove}
        variant="link"
        icon={<SvgDelete />}
        label={t("INCENTIVES.COMMON.LABELS.REMOVE_FILE")}
      />
    </div>
    <div>
      <FormButton onClick={handleBulkAction}>{buttonText}</FormButton>
    </div>
  </div>
);

const BulkAction = ({
  className,
  show,
  handleClose,
  title,
  handleUpload,
  placeholder,
  children,
  isOnSuccess,
  filePickerProps,
  isOnError,
  errorProps,
  handleCancel,
  filePickerSuccessProps,
  fileError,
}) => {
  const filePicker = useFilePicker({
    noClick: true,
    maxSize: (filePickerProps?.maxSize || 5) * 1000000,
    multiple: false,
    accept: [FILE_FORMATS.XLSX].join(","),
  });
  const { acceptedFiles } = filePicker;

  useEffect(() => {
    if (filePicker.screens.isOnUpload && handleUpload) {
      handleUpload({ acceptedFiles: [...acceptedFiles] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePicker.screens.isOnUpload]);

  const handleCancelUpload = () => {
    handleCancel();
    filePicker.removeFile();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={classNames(["bulk-action", className])}
    >
      <Modal.Header className="fs-18 semi-bold" closeButton>
        {title}
      </Modal.Header>
      <Modal.Body className="mt-32">
        <div className="body-container mx-5">
          <div className="placeholder-image">{placeholder}</div>
          {children}
        </div>
        <div
          className="bg-grey mt-5 mb-5 mx-4 br-4 dropzone-container overflow-hidden"
          style={{ height: 152 }}
        >
          <FileInput
            rootProps={filePicker.getRootProps()}
            inputProps={filePicker.getInputProps()}
          >
            {filePicker.screens.isOnLoad && !fileError ? (
              <div className="on-load flex-column-center h-100">
                <FormButton
                  onClick={filePicker.open}
                  icon={
                    <SvgAttachment
                      height="15"
                      color={COLORS.WHITE}
                      className="mr-2"
                    />
                  }
                >
                  {t("MANAGED_GOALS.BUTTONS.CHOOSE_FILE_IMPORT")}
                </FormButton>
                <div className="caption fs-12 fc-grey mt-2">
                  {t("MANAGED_GOALS.BUTTONS.DRAG_AND_DROP_FILE")}
                </div>
              </div>
            ) : null}
            {filePicker.screens.isOnDrop && !fileError ? (
              <div
                className="on-drop flex-column-center h-100"
                style={{ backgroundColor: "#E5F6FF" }}
              >
                <SvgAttachment color={COLORS.GREY_2} />
                <div className="fs-12 fc-grey">
                  {t("MANAGED_GOALS.BUTTONS.DROP_FILE")}
                </div>
              </div>
            ) : null}
            {!isOnSuccess && !fileError && filePicker.screens.isOnUpload ? (
              <div className="on-upload flex-column-center h-100">
                <div className="center fs-12 fc-grey">
                  {t("MANAGED_GOALS.BUTTONS.SCANNING")}{" "}
                  <strong className="ml-1">
                    <Truncate
                      text={filePicker.acceptedFiles[0].name}
                      maxWidth="300px"
                    />
                  </strong>
                </div>
                <div className="center mt-32">
                  <ProgressBar
                    className="w-180 mr-2"
                    striped
                    animated
                    variant="primary"
                    now={40}
                    style={{ height: 6 }}
                  />
                  <FormButton variant="link" onClick={handleCancelUpload}>
                    {t("COMMON.BUTTONS.CANCEL")}
                  </FormButton>
                </div>
              </div>
            ) : null}
            {filePicker.screens.isOnError || fileError ? (
              <div className="on-error flex-column-center h-100">
                <div className="fs-13 center">
                  <SvgError className="mr-2" />{" "}
                  {filePicker.screens.isOnError
                    ? filePicker.fileError.message
                    : fileError}
                </div>
                <FormButton
                  variant="outline-primary"
                  className="mt-4"
                  onClick={filePicker.open}
                >
                  {t("MANAGED_GOALS.BUTTONS.IMPORT_FILE")}
                </FormButton>
                <div className="fs-12 fc-grey">
                  {t("MANAGED_GOALS.BUTTONS.DRAG_AND_DROP_FILE")}
                </div>
              </div>
            ) : null}
            {!filePicker.screens.isOnDrop &&
            !filePicker.screens.isOnError &&
            !fileError &&
            !isOnError &&
            isOnSuccess ? (
              <FilePickerSuccess
                handleRemove={filePicker.removeFile}
                {...filePickerSuccessProps}
              />
            ) : null}
            {!filePicker.screens.isOnError && isOnError ? (
              <FilePickerError
                handleRemove={filePicker.removeFile}
                {...errorProps}
              />
            ) : null}
          </FileInput>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BulkAction;
