import { gql, useQuery } from "src/api/client";

export const GET_TASK_FIELDS = gql`
  query getTaskType {
    getTaskType {
      Category
      Name
      ColumnConfiguration
      Id
    }
  }
`;

export const useGetTaskFields = (queryOptions) => {
  return useQuery(GET_TASK_FIELDS, queryOptions);
};
