import React, { useEffect, useRef, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useGetSelectionTemplates } from "src/api/useTemplate";
import {
  LoadMoreButton,
  SearchBar,
  Truncate,
  toaster,
} from "src/components/common/atoms";
import { FormCheck, FormTooltip } from "src/components/common/formElements";
import {
  COLORS,
  FORMAT_AGGREGATE_FUNCTION,
  PAGINATION_LIMIT,
  RULESET_TYPES,
} from "src/constants";
import { errorText, isEmpty } from "src/helpers";
import {
  SvgAggregate,
  SvgCustomFormula,
  SvgGroupBy,
  SvgInfo,
  SvgStandardFormula,
} from "src/icons";
import { NoTemplates } from "src/images";
import { LoadingTemplateList } from "../../loading";
import SelectedTemplateContainer from "./SelectedTemplateContainer";
import TemplateDisplayContainer from "./TemplateDisplayContainer";
import "./templateSelectModal.scss";

const TemplateList = (props) => {
  const {
    displayTemplate,
    selectedTemplate,
    onTemplateSelect,
    isMultiSelect,
    tempTemplate,
    multipleTemplates,
    setMultipleTemplates,
    maxAllowedTemplates,
    isViewMode,
    ExcludeGoalAggregateFunction = null,
    excludeTemplateDisclaimerText = "",
    templateDetails = [],
  } = props;

  const getTemplateIdList = (templateArray) =>
    templateArray?.map((templateObj) => templateObj?.TemplateId);

  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const ShimmerRef = useRef();
  const {
    data: { getTemplates } = { getTemplates: [] },
    loading,
    error,
    refetch,
    fetchMore,
  } = useGetSelectionTemplates({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    variables: {
      Query: { Status: "PUBLISHED", Search: search },
      After: null,
      Limit: isViewMode ? selectedTemplate.length : PAGINATION_LIMIT,
      IncludeTemplates: isMultiSelect
        ? getTemplateIdList(selectedTemplate)
        : undefined,
      ExcludeTemplates: isMultiSelect
        ? undefined
        : !isEmpty(selectedTemplate)
        ? selectedTemplate[0]?.TemplateId
        : undefined,
      ExcludeGoalAggregateFunction: ExcludeGoalAggregateFunction?.length
        ? ExcludeGoalAggregateFunction
        : undefined,
    },
    skip: !isEmpty(templateDetails),
  });
  const [fetchingGoals, setFetchingGoals] = useState(false);
  const templateList = !isEmpty(templateDetails)
    ? templateDetails
    : getTemplates?.response?.Templates;
  useEffect(() => {
    setFetchingGoals(false);
    if (!isEmpty(getTemplates)) {
      refetch({
        Limit: PAGINATION_LIMIT,
        Query: { Status: "PUBLISHED", Search: search },
        After: null,
        IncludeTemplates: isMultiSelect
          ? getTemplateIdList(selectedTemplate)
          : undefined,
        ExcludeTemplates: isMultiSelect
          ? undefined
          : !isEmpty(selectedTemplate)
          ? selectedTemplate[0]?.TemplateId
          : undefined,
        ExcludeGoalAggregateFunction: ExcludeGoalAggregateFunction?.length
          ? ExcludeGoalAggregateFunction
          : undefined,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const onFetchMore = () => {
    const { endCursor } = getTemplates.response.pageInfo;
    // for multi-select the maxAllowedTemplate count
    // should not exceed the limit specified for lazyload
    setFetchingGoals(true);
    fetchMore({
      variables: {
        Limit: PAGINATION_LIMIT,
        Query: { Status: "PUBLISHED", Search: search },
        After: endCursor,
        ExcludeTemplates: isMultiSelect
          ? getTemplateIdList(selectedTemplate)
          : !isEmpty(selectedTemplate)
          ? selectedTemplate[0]?.TemplateId
          : undefined,
        IncludeTemplates: undefined,
        ExcludeGoalAggregateFunction: ExcludeGoalAggregateFunction?.length
          ? ExcludeGoalAggregateFunction
          : undefined,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.getTemplates.response.Templates = [
          ...prevResult.getTemplates.response.Templates,
          ...fetchMoreResult.getTemplates.response.Templates,
        ];
        return fetchMoreResult;
      },
    });
    ShimmerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (getTemplates?.getTemplates?.__typename === "ErrorResponse") {
    toaster({
      message: getTemplates.getTemplates?.message,
      type: "error",
    });
    return <></>;
  }

  if (error) {
    toaster({
      message: errorText.GET_TEMPLATES.ERROR,
      type: "error",
    });
    return <></>;
  }
  const handleCheckBoxSelection = (template) => {
    const newMultipleTemplates = [...multipleTemplates];
    const elmIndex = getTemplateIdList(newMultipleTemplates).indexOf(
      template?.TemplateId
    );
    if (elmIndex !== -1) {
      if (multipleTemplates.length > 1) {
        newMultipleTemplates.splice(elmIndex, 1);
      }
    } else if (multipleTemplates.length < maxAllowedTemplates) {
      newMultipleTemplates.push(template);
    }
    setMultipleTemplates(newMultipleTemplates);
  };
  const templateDisplayList =
    templateList?.length > 0 &&
    templateList.map((template, idx) => {
      const isSelectedTemplate = getTemplateIdList(multipleTemplates)?.includes(
        template?.TemplateId
      );
      return (
        <div key={idx}>
          <ListGroup.Item
            key={idx}
            id={`template-${idx}`}
            action
            onClick={() => onTemplateSelect(template)}
            className="p-3"
            active={
              isMultiSelect
                ? isViewMode
                  ? template?.TemplateId === tempTemplate?.TemplateId
                  : isSelectedTemplate
                : template?.TemplateId === displayTemplate?.TemplateId
            }
          >
            <div className="d-flex">
              {isMultiSelect && !isViewMode && (
                <div className="pt-1 pr-2">
                  <FormTooltip
                    text={`You cannot select more than ${maxAllowedTemplates} rulesets to a program.`}
                    placement="bottom"
                    disable={
                      !(
                        !isSelectedTemplate &&
                        multipleTemplates.length >= maxAllowedTemplates
                      )
                    }
                  >
                    <FormCheck
                      id={idx + 1}
                      checked={isSelectedTemplate}
                      onClick={(e) => handleCheckBoxSelection(template)}
                      disabled={
                        !isSelectedTemplate &&
                        multipleTemplates.length >= maxAllowedTemplates
                      }
                    />
                  </FormTooltip>
                </div>
              )}
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="fs-14 semi-bold mb-1 template-name text-overflow flex center">
                    <Truncate id={idx} maxWidth="200px" text={template.Name} />
                    {template?.RulesetType === RULESET_TYPES.ADVANCED && (
                      <span className=" fs-12 fc-grey1 normal ml-1">
                        ({t("RULEBOOK.LABELS.ADVANCED_RULESET")})
                      </span>
                    )}
                  </span>
                </div>
                <div className="center">
                  <div className="fs-12 center mr-2">
                    {template?.IsCustomFormula ? (
                      <SvgCustomFormula
                        width={12}
                        height={12}
                        color={COLORS.GREY_2}
                      />
                    ) : (
                      <SvgStandardFormula
                        width={12}
                        height={12}
                        color={COLORS.GREY_2}
                      />
                    )}
                    <span className="ml-2 fc-black">
                      {template?.IsCustomFormula
                        ? "Custom Formula"
                        : "Standard Output"}
                    </span>
                  </div>
                  {template?.GroupBy && (
                    <span className="fs-12 ml-2 fc-grey">
                      | <SvgGroupBy className="mx-2" />
                      {t("COMMON.LABELS.GROUP_BY")}
                    </span>
                  )}
                </div>

                {FORMAT_AGGREGATE_FUNCTION[template?.GoalAggregateFunction] && (
                  <div className="fs-12 center normal mb-7 fc-grey text-overflow">
                    <SvgAggregate
                      width={12}
                      height={12}
                      color={COLORS.GREY_2}
                    />
                    <span className="ml-2">
                      {!template?.IsCustomFormula
                        ? FORMAT_AGGREGATE_FUNCTION[
                            template?.GoalAggregateFunction
                          ]?.displayFormat
                        : "Multiple"}
                    </span>
                    <span className="ml-1">
                      {t("MANAGED_GOALS.MESSAGES.FUNCTION")}
                    </span>
                  </div>
                )}
                <div className="fs-13 fc-black text-overflow">
                  <Truncate
                    id={idx}
                    maxWidth="300px"
                    text={template.Description}
                  />
                </div>
              </div>
            </div>
          </ListGroup.Item>
        </div>
      );
    });
  const totalCountOfTemplates =
    isMultiSelect && search === ""
      ? getTemplates?.response?.totalCount + selectedTemplate?.length
      : getTemplates?.response?.totalCount;

  const disclaimerContent = (text) => (
    <div className="d-flex bg-blue py-2">
      <div>
        <SvgInfo backgroundColor="none" color={COLORS.GREY_1} />
      </div>
      <div className="fs-13 fc-grey">
        <span className="semi-bold">Note:</span> {text}
      </div>
    </div>
  );
  return (
    <>
      <div className="template-selection-list-container">
        {ExcludeGoalAggregateFunction?.length
          ? excludeTemplateDisclaimerText &&
            disclaimerContent(excludeTemplateDisclaimerText)
          : null}
        {!isMultiSelect && selectedTemplate && (
          <SelectedTemplateContainer
            selectedTemplate={selectedTemplate[0]}
            onTemplateSelect={onTemplateSelect}
            displayTemplate={displayTemplate}
          />
        )}
        {!isViewMode && (
          <div className="search-template-container" id="find-template">
            <SearchBar
              id="search bar"
              placeholder={
                isMultiSelect
                  ? t("MANAGED_GOALS.MESSAGES.SEARCH_TEMPLATES_NAME")
                  : t("MANAGED_GOALS.MESSAGES.SEARCH_TEMPLATE_NAME")
              }
              search={search}
              setSearch={setSearch}
            />
          </div>
        )}
        <ListGroup
          className={`${
            !isEmpty(selectedTemplate) && !isMultiSelect
              ? "list-group-position"
              : "list-group-position-empty"
          }`}
        >
          <div>
            {loading && (!fetchingGoals || getTemplates === undefined) ? (
              <div className="mb-2">
                <LoadingTemplateList />
              </div>
            ) : (
              <div className="mb-2">{templateDisplayList}</div>
            )}

            {!loading &&
              getTemplates?.response?.Templates?.length === 0 &&
              getTemplates?.response?.hasTemplates && (
                <div className="flex-center flex-column h-100">
                  <div>
                    <NoTemplates />
                  </div>
                  <div>{t("RULEBOOK.MESSAGES.NO_TEMPLATES_FOUND")}.</div>
                </div>
              )}
            {getTemplates?.response?.Templates?.length !== 0 &&
              getTemplates?.response?.Templates?.length !==
                totalCountOfTemplates &&
              !isViewMode && (
                <div className="w-100 flex-center" ref={ShimmerRef}>
                  {!loading ? (
                    <LoadMoreButton
                      disabled={
                        getTemplates.response.Templates.length ===
                        totalCountOfTemplates
                      }
                      id="select-template-show-more"
                      onClick={onFetchMore}
                      itemsLoaded={getTemplates.response.Templates.length}
                      totalItems={totalCountOfTemplates}
                    />
                  ) : (
                    <div className="mb-300 fc-black fs-12 px-4 py-2 w-25 bg-grey flex-center">
                      {t("COMMON.MESSAGES.LOADING")}
                    </div>
                  )}
                </div>
              )}
          </div>
        </ListGroup>
      </div>
      <TemplateDisplayContainer
        displayTemplate={
          tempTemplate ||
          (isEmpty(selectedTemplate) ? null : selectedTemplate[0])
        }
        defaultTemplate={
          isMultiSelect && selectedTemplate && templateList
            ? templateList[0]
            : null
        }
        loading={loading && (!fetchingGoals || getTemplates === undefined)}
      />
    </>
  );
};

export default React.memo(TemplateList);
