import { t } from "i18next";
import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { FormBadge } from "src/components/common/formElements";
import { MobileRulesGrid } from "src/components/common/organisms";
import RulesetFormula from "src/components/pages/rulesets/components/rulesetFormula/RulesetFormula";
import { RULESET_TYPES } from "src/constants";
import { SvgGroupBy } from "src/icons";
import { NoResults } from "src/images";
import "./rulesetRulesDetailsView.scss";

const LoadingRulesetFormula = () => {
  return (
    <div className="px-3">
      <Shimmer width="50%" />
      <Shimmer width="100%" height="30px" className="mt-3" />
    </div>
  );
};

const RulesetRulesDetailView = (props) => {
  const { loading, templateDetailsResults } = props;

  return (
    <>
      <div className="p-3">
        <div className="center">
          <div className="fs-12 semi-bold"> {t("COMMON.LABELS.RULES")}</div>
          <FormBadge className="ml-1 bg-secondary-blue fc-grey1 semi-bold">
            {templateDetailsResults?.GoalRules?.length}
          </FormBadge>
        </div>
        <div className="mt-12 fs-12 fc-grey1 center">
          {t("RULEBOOK.MESSAGES.EMPTY_RULEBOOK_DESCRIPTION")}
        </div>
      </div>
      {templateDetailsResults?.GroupBy && (
        <div className="flex-column mb-3 px-3">
          <span className="space-x-3 center mb-3">
            <SvgGroupBy width="16" height="16" />
            <span className="semi-bold">{t("COMMON.LABELS.GROUP_BY")}</span>
          </span>
          <div className="fs-14">
            {templateDetailsResults?.GroupBy.split(",").join(" & ")}
          </div>
        </div>
      )}
      {!loading ? (
        templateDetailsResults?.GoalRules?.length >= 1 ? (
          <div className="px-3 mobile-custom-formula">
            <RulesetFormula
              isCustomFormula={templateDetailsResults.IsCustomFormula}
              formula={templateDetailsResults.Formula}
              formulaRules={templateDetailsResults.GoalRules}
              editStatus={false}
              templateDetails={templateDetailsResults}
              setAllowSave={() => {}}
            />
          </div>
        ) : null
      ) : (
        <LoadingRulesetFormula />
      )}
      {templateDetailsResults &&
      templateDetailsResults?.RulesetType !== RULESET_TYPES.ADVANCED ? (
        <div className="mt-4 px-3">
          <MobileRulesGrid templateDetails={templateDetailsResults} />
        </div>
      ) : (
        <div className="flex-column-center w-100 mt-5">
          <NoResults />
          <div className="fs-13 fc-grey mt-2 text-center">
            {t("RULEBOOK.MESSAGES.HIDING_RULESET_DETAILS")}
          </div>
        </div>
      )}
    </>
  );
};

export default RulesetRulesDetailView;
