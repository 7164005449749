import { t } from "i18next";
import { Modal } from "react-bootstrap";
import { FormButton } from "src/components";

function DeleteConfirmationModal({
  show,
  handleClose,
  heading,
  children,
  handleDelete,
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="semi-bold fs-16" closeButton>
        {heading}
      </Modal.Header>
      <Modal.Body className="mx-4 my-3 fs-13">
        {show ? children : null}
        <div className="mt-4 d-flex">
          <FormButton
            type="button"
            label={t("MANAGED_GOALS.BUTTONS.YES_DELETE")}
            onClick={handleDelete}
            className="mr-3"
          />
          <FormButton
            type="button"
            label={t("COMMON.BUTTONS.CANCEL")}
            variant="outline-primary"
            onClick={handleClose}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteConfirmationModal;
