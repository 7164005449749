import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { LoadingTabCards } from "../loadingTabCards";
import "./loadingAssignedToMe.scss";

const LoadingAssignedToMe = () => (
  <div className="mt-4">
    <div className="flex-between border-b">
      <div>
        <Shimmer width="100px" height="10px" />
        <div className="mt-3 mb-4">
          <Shimmer width="200px" height="10px" />
        </div>
      </div>
      <div className="box-border-assigned">
        <Shimmer width="200px" height="10px" />
      </div>
    </div>
    <div className="mt-4">
      <LoadingTabCards />
    </div>
    <div className="mt-5">
      <Shimmer width="80px" height="10px" />
      <div className="mt-3 mb-4">
        <Shimmer width="300px" height="10px" />
      </div>
    </div>
  </div>
);

export default LoadingAssignedToMe;
