import { gql, useQuery } from "src/api/client";

export const GET_OPPORTUNITY_FIELDS = gql`
  query getOpportunityTypes {
    getOpportunityTypes {
      EventType
      ActivityEvent
      DisplayName
      Fields
      AuditActivity
    }
  }
`;

export const useGetOpportunityFields = (queryOptions) => {
  return useQuery(GET_OPPORTUNITY_FIELDS, queryOptions);
};
