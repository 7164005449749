import { DATE_FORMAT } from "src/constants";
import { format, isToday, isYesterday } from "src/helpers";

function formatMobileTimelineDate(d) {
  if (isToday(d)) {
    // Today | 05:20 PM
    return `Today | ${format(d, "hh:mm a")}`;
  }

  if (isYesterday(d)) {
    // Yesterday | 05:20 PM
    return `Yesterday | ${format(d, "hh:mm a")}`;
  }

  // 27 Jul 2022 | 05:20 PM
  return format(d, `${DATE_FORMAT} | hh:mm a`);
}

export default formatMobileTimelineDate;
