import React from "react";

const SvgAvatar30 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="kxu6__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .kxu6__cls-1{fill:#fff}.kxu6__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="kxu6__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-9.77 10.04-22.12 17.56-35.98 21.5-.71.2-1.42.4-2.14.57-.76.2-1.52.39-2.29.55-.7.17-1.41.31-2.12.45-5.1 1-10.37 1.53-15.76 1.53-22.86 0-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="kxu6__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2a81.305 81.305 0 0 1-31.93 20.23c-.56.2-1.13.38-1.69.57-.6.18-1.2.37-1.8.54-.56.16-1.12.32-1.68.47a81.328 81.328 0 0 1-21.19 2.79c-22.86 0-43.52-9.43-58.3-24.6 16.3-12.06 36.47-19.19 58.3-19.19 7.27 0 14.37.79 21.19 2.3.56.12 1.12.25 1.68.39.6.13 1.2.28 1.8.44.57.14 1.13.3 1.69.46a97.512 97.512 0 0 1 31.93 15.6Z"
          className="kxu6__cls-2"
        />
        <path
          d="M81.4 67.62c-1.29 0-2.56-.34-3.68-.98a7.375 7.375 0 0 1-3.68-6.37V52.6h-.02c-4.95-2.8-8.08-8.06-8.17-13.74.03-4.08 1.63-7.91 4.5-10.81s6.69-4.53 10.77-4.6h.27c3.97 0 7.76 1.5 10.65 4.22 3.08 2.92 4.86 7.02 4.89 11.25-.12 5.67-3.25 10.92-8.17 13.73h-.02v7.61c0 2.62-1.41 5.06-3.68 6.37-1.12.64-2.39.98-3.68.98Zm-4.53-6.81c.17 1.09.72 2.1 1.54 2.84.82.74 1.88 1.17 2.99 1.22 1.11-.05 2.17-.48 2.99-1.22.82-.74 1.37-1.74 1.54-2.84v-.04h-9.06v.04Zm9.27-2.66v-2.08h-9.46v2.08h9.46Zm0-4.7v-1.64c-.02-.48.24-.94.66-1.18 3.85-1.82 6.54-5.4 7.23-9.6a12.9 12.9 0 0 0-3.8-11.4 12.95 12.95 0 0 0-8.82-3.5h-.82c-3.24.25-6.26 1.69-8.51 4.06a13.103 13.103 0 0 0-3.59 8.73 12.583 12.583 0 0 0 7.5 11.62c.48.31.71.78.69 1.26v1.64h9.46Z"
          className="kxu6__cls-1"
        />
        <path
          d="M81.4 67.12c-1.2 0-2.39-.32-3.43-.92a6.875 6.875 0 0 1-3.43-5.93v-7.96l-.27-.15c-4.79-2.7-7.83-7.8-7.92-13.31.03-3.93 1.58-7.65 4.36-10.45 2.78-2.8 6.48-4.38 10.42-4.45h.26c3.85 0 7.51 1.45 10.31 4.09 2.98 2.82 4.7 6.79 4.73 10.89-.12 5.47-3.15 10.57-7.92 13.29l-.27.15v7.89c0 2.44-1.31 4.71-3.43 5.93-1.04.6-2.22.92-3.43.92Zm-5.02-6.23a5.29 5.29 0 0 0 1.7 3.13c.91.81 2.08 1.29 3.3 1.35h.05a5.26 5.26 0 0 0 3.3-1.35c.91-.81 1.51-1.93 1.7-3.13l.1-.61H76.29l.1.61Zm-.21-2.23h10.46v-3.08H76.17v3.08Zm-.39-7.65c.26.17.41.47.39.78v2.17h10.46v-2.14c-.01-.32.14-.6.4-.75 3.95-1.87 6.76-5.59 7.47-9.95.71-4.36-.76-8.78-3.94-11.84a13.51 13.51 0 0 0-9.16-3.64h-.85a13.613 13.613 0 0 0-12.57 13.27c-.02 2.58.7 5.08 2.1 7.24a13.01 13.01 0 0 0 5.7 4.86ZM81.4 19.77c-.35 0-.68-.14-.93-.38-.24-.24-.38-.58-.38-.93v-7.25c0-.72.59-1.31 1.31-1.31s1.31.59 1.31 1.31v7.25c0 .34-.14.68-.38.93a1.3 1.3 0 0 1-.93.38Z"
          className="kxu6__cls-1"
        />
        <path
          d="M81.4 19.27c-.22 0-.42-.08-.57-.24a.82.82 0 0 1-.24-.57v-7.25c0-.45.36-.81.81-.81s.81.36.81.81v7.25a.82.82 0 0 1-.81.81ZM54.35 39.3c-.72 0-1.31-.59-1.31-1.31s.59-1.31 1.31-1.31h7.25c.72 0 1.31.59 1.31 1.31s-.59 1.31-1.31 1.31h-7.25Z"
          className="kxu6__cls-1"
        />
        <path
          d="M54.35 38.8c-.45 0-.81-.36-.81-.81s.36-.81.81-.81h7.25c.45 0 .81.36.81.81s-.36.81-.81.81h-7.25ZM101.2 39.3c-.35 0-.68-.14-.93-.38a1.314 1.314 0 0 1 .93-2.24h7.25c.72 0 1.31.59 1.31 1.31s-.59 1.31-1.31 1.31h-7.25Z"
          className="kxu6__cls-1"
        />
        <path
          d="M101.2 38.8c-.22 0-.42-.08-.57-.24a.807.807 0 0 1 .57-1.38h7.25c.45 0 .81.36.81.81s-.36.81-.81.81h-7.25ZM67.44 25.55c-.35 0-.68-.15-.92-.4l-5.19-5.13c-.28-.21-.47-.55-.5-.93-.03-.38.11-.75.38-1.02.25-.25.58-.38.93-.38.47.03.82.22 1.05.52l5.17 5.1c.26.23.4.56.4.92s-.15.68-.4.92c-.23.26-.57.4-.92.4Z"
          className="kxu6__cls-1"
        />
        <path
          d="M67.44 25.05c-.21 0-.41-.09-.55-.24l-5.21-5.15-.03-.02a.784.784 0 0 1-.32-.59c-.02-.23.07-.46.24-.63a.82.82 0 0 1 .57-.24h.06c.23.02.45.14.59.32l.02.03 5.2 5.13c.17.15.25.35.25.56s-.09.41-.24.55l-.03.03c-.14.15-.34.24-.55.24ZM95.35 25.54c-.34 0-.67-.15-.91-.39-.25-.23-.4-.56-.4-.92s.15-.68.4-.92l5.2-5.13c.2-.28.55-.47.93-.5h.02c.43 0 .76.14 1 .38.27.27.41.64.38 1.02-.03.38-.22.73-.52.96l-5.17 5.1c-.24.24-.56.39-.91.39h-.02Z"
          className="kxu6__cls-1"
        />
        <path
          d="M95.36 25.04c-.21 0-.41-.09-.56-.24-.17-.16-.26-.36-.26-.57s.09-.41.24-.55l5.21-5.14.02-.03c.14-.19.36-.31.59-.32h.06c.22 0 .42.08.57.24a.817.817 0 0 1-.09 1.22l-.03.02-5.19 5.13c-.15.16-.35.24-.57.25Z"
          className="kxu6__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar30.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar30;
