import {
  ApprovalRequestPage,
  DashboardUserDetailViewPage,
  MobileDashboardPage,
  PayoutRequestPage,
} from "src/components";
import { ApprovalPage } from "./approval";
import { ApprovalDetailViewPage } from "./approvalDetailView";
import { DashboardPage } from "./dashboard";
import { DashboardBoardDetailViewPage } from "./dashboardBoardDetailView";
import { DashboardLeaderboardDetailsPage } from "./dashboardLeaderboardDetails";
import { LandingPage } from "./landing";
import { LeaderboardCrudPage } from "./leaderboardCrud";
import { ManageLeaderboardPage } from "./manageLeaderboard";
import { MyBoardsPage } from "./myBoards";
import { MyBoardsDetailViewPage } from "./myBoardsDetailView";
import { PayoutPage } from "./payout";
import { PayoutDetailViewPage } from "./payoutDetailView";
import { PublishedLeaderboardsViewPage } from "./publishedLeaderboardsView";
import { RequestDemoPage } from "./requestDemo";
import { RulesetsPage } from "./rulesets";

export const Pages = {
  ApprovalDetailViewPage,
  ApprovalPage,
  ApprovalRequestPage,
  DashboardLeaderboardDetailsPage,
  DashboardPage,
  LandingPage,
  LeaderboardCrudPage,
  ManageLeaderboardPage,
  MyBoardsDetailViewPage,
  MyBoardsPage,
  PayoutDetailViewPage,
  PayoutPage,
  PayoutRequestPage,
  PublishedLeaderboardsViewPage,
  RequestDemoPage,
  RulesetsPage,
  DashboardBoardDetailViewPage,
  DashboardUserDetailViewPage,
  MobileDashboardPage,
};
