import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormButton, FormTooltip } from "src/components/common/formElements";
import NonMonetaryRewardDetails from "src/components/common/nonMonetary/nonMonetaryRewardDetails/NonMonetaryRewardDetails";
import { capitalize, classNames, isEmpty } from "src/helpers";
import { RewardsDetailsFlatlistModal } from "../../nonMonetary";
import { AmountLabel } from "../amountLabel";
import "./nonMonetary.scss";

const NonMonetaryLabel = ({
  amount,
  nonMonetaryRewards = [],
  className,
  rewardCount = 0,
  rewardCategoryList = false,
  formatNonMonetary,
  rewardModalTitle,
  payload,
  nonMonetaryClassName,
  maxCharCount,
  hideTooltip = false,
  showMobileFlatlistModal,
}) => {
  const [showRewardDetailsModal, setShowRewardDetailsModal] = useState(false);

  const tooltipContentCategoriesList = (rewards) => {
    return (
      <div className="d-flex flex-wrap">
        {rewards.map((reward, index) => (
          <div className="d-flex mr-1" key={`${reward.RewardCategory - index}`}>
            {reward.RewardCount} {capitalize(reward.RewardCategory)}
            {index === rewards.length - 1 ? "" : ","}
          </div>
        ))}
      </div>
    );
  };

  const formattedNonMonetaryList = (
    rewards,
    rewardCount,
    rewardCategoryList
  ) => {
    if (formatNonMonetary) {
      return formatNonMonetary(payload);
    }
    if (rewardCategoryList) {
      return (
        <FormTooltip
          text={tooltipContentCategoriesList(rewards)}
          placement="bottom-start"
          disable={hideTooltip}
        >
          {formattedNonMonetaryCategoriesList(
            rewards,
            nonMonetaryClassName,
            maxCharCount
          )}
        </FormTooltip>
      );
    }
    return (
      <>
        {formattedNonMonetaryRewards(
          rewards,
          rewardCount,
          nonMonetaryClassName
        )}
        {showMobileFlatlistModal ? (
          <RewardsDetailsFlatlistModal
            show={showRewardDetailsModal}
            handleClose={() => setShowRewardDetailsModal(false)}
            title={rewardModalTitle}
            payload={payload}
          />
        ) : (
          <NonMonetaryRewardDetails
            show={showRewardDetailsModal}
            handleClose={() => setShowRewardDetailsModal(false)}
            title={rewardModalTitle}
            payload={payload}
          />
        )}
      </>
    );
  };

  const formattedNonMonetaryRewards = (
    rewards,
    rewardCount,
    nonMonetaryClassName
  ) => {
    if (rewardCount === 1) {
      return (
        <FormButton
          className={classNames([
            "pl-1 truncate-rewards fc-blue pointer ",
            isEmpty(payload) ? "" : "text-decorate",
            nonMonetaryClassName,
          ])}
          onClick={
            isEmpty(payload) ? () => {} : () => setShowRewardDetailsModal(true)
          }
          data-testid="reward-flat-list"
          variant="normal-link"
          id="reward-flat-list"
        >
          {rewards[0].RewardName}
        </FormButton>
      );
    }
    return (
      <FormButton
        className={classNames([
          "pl-1 fc-blue pointer",
          isEmpty(payload) ? "" : "text-decorate",
          nonMonetaryClassName,
        ])}
        onClick={
          isEmpty(payload) ? () => {} : () => setShowRewardDetailsModal(true)
        }
        data-testid="reward-flat-list"
        variant="normal-link"
        id="reward-flat-list"
      >
        {rewards[0].RewardName.substring(0, maxCharCount)}
        ... +{rewardCount - 1}
      </FormButton>
    );
  };

  const formattedNonMonetaryCategoriesList = (
    rewards,
    nonMonetaryClassName,
    maxCharCount
  ) => {
    if (rewards.length === 1) {
      return (
        <FormButton
          className={classNames([
            "pl-1 truncate-rewards fc-blue pointer",
            isEmpty(payload) ? "" : "text-decorate",
            nonMonetaryClassName,
          ])}
          onClick={
            isEmpty(payload) ? () => {} : () => setShowRewardDetailsModal(true)
          }
          data-testid="category-list"
          variant="normal-link"
          id="category-list"
        >
          {rewards[0].RewardCount} {capitalize(rewards[0].RewardCategory)}
        </FormButton>
      );
    }
    return (
      <FormButton
        className={classNames([
          "pl-1 fc-blue pointer center text-nowrap",
          isEmpty(payload) ? "" : "text-decorate",
          nonMonetaryClassName,
        ])}
        onClick={
          isEmpty(payload) ? () => {} : () => setShowRewardDetailsModal(true)
        }
        data-testid="category-list"
        variant="normal-link"
        id="category-list"
      >
        <span>
          {`${rewards[0].RewardCount} ${capitalize(
            rewards[0].RewardCategory
          )}`.substring(0, maxCharCount)}
        </span>
        <span className={className}>... +{rewards.length - 1}</span>
      </FormButton>
    );
  };

  if (
    (amount === null || amount === undefined || amount === 0) &&
    nonMonetaryRewards?.length > 0
  ) {
    return (
      <div data-testid="nonmonetary">
        {formattedNonMonetaryList(
          nonMonetaryRewards,
          rewardCount,
          rewardCategoryList
        )}
      </div>
    );
  }
  if (
    amount !== null &&
    amount !== undefined &&
    nonMonetaryRewards?.length === 0
  ) {
    return (
      <div data-testid="monetary" className={className}>
        <AmountLabel amount={amount} />
      </div>
    );
  }
  if (
    amount !== null &&
    amount !== undefined &&
    nonMonetaryRewards?.length > 0
  ) {
    return (
      <div
        className={classNames(["align-items-center", className])}
        data-testid="monetary-and-nonmonetary"
      >
        <span className="d-flex">
          <AmountLabel amount={amount} className="pr-1" /> &{" "}
        </span>
        {formattedNonMonetaryList(
          nonMonetaryRewards,
          rewardCount,
          rewardCategoryList
        )}
      </div>
    );
  }
  return <div data-testid="empty-label"> --</div>;
};

NonMonetaryLabel.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  nonMonetaryRewards: PropTypes.array,
  className: PropTypes.string,
  rewardCount: PropTypes.number,
  rewardCategoryList: PropTypes.bool,
  formatNonMonetary: PropTypes.func,
  rewardModalTitle: PropTypes.string,
  payload: PropTypes.object,
  nonMonetaryClassName: PropTypes.string,
  maxCharCount: PropTypes.number,
};

NonMonetaryLabel.defaultProps = {
  maxCharCount: 15,
};

export default NonMonetaryLabel;
