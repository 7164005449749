import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgMonetaryRewardApproval = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g>
        <path fill="#fff" d="M0 0h24v24H0z" />
        <path
          fill={color}
          d="M21.995 5.302a9.8 9.8 0 0 0-3.853-.743c-4.076 0-8.153 2.063-12.229 2.063a10.042 10.042 0 0 1-3.066-.454 1.132 1.132 0 0 0-.343-.054 1.053 1.053 0 0 0-1.067 1.053v10.5a1.053 1.053 0 0 0 .624.975 9.8 9.8 0 0 0 3.853.744c4.076 0 8.153-2.064 12.229-2.064a10.042 10.042 0 0 1 3.066.454 1.132 1.132 0 0 0 .343.054 1.053 1.053 0 0 0 1.067-1.053V6.278a1.054 1.054 0 0 0-.624-.976ZM3.026 7.876a11.518 11.518 0 0 0 2.076.3 2.121 2.121 0 0 1-2.076 1.701Zm0 9.432v-1.581a2.117 2.117 0 0 1 2.111 2.032 8 8 0 0 1-2.111-.45Zm9-2.159a2.949 2.949 0 0 1-2.646-3.176 2.949 2.949 0 0 1 2.648-3.177 2.949 2.949 0 0 1 2.648 3.177 2.949 2.949 0 0 1-2.648 3.177Zm9 .919a11.379 11.379 0 0 0-1.8-.279 2.114 2.114 0 0 1 1.8-1.631Zm0-7.814a2.114 2.114 0 0 1-1.847-2.037 7.956 7.956 0 0 1 1.847.419Z"
        />
      </g>
    </svg>
  );
};

SvgMonetaryRewardApproval.defaultProps = {
  color: COLORS.GREY_1,
  height: 24,
  width: 24,
};

export default SvgMonetaryRewardApproval;
