import { gql, useQuery } from "src/api/client";

const GET_PROGRAM_LIST_BY_CUSTOM_REWARD_ID = gql`
  query getProgramListByCustomRewardId($RewardId: ID!) {
    getProgramListByCustomRewardId(RewardId: $RewardId) {
      ... on CustomRewardHistoryResponse {
        success
        message
        response {
          CustomRewardHistoryList
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useGetProgramListByCustomRewardId = (queryOptions) => {
  return useQuery(GET_PROGRAM_LIST_BY_CUSTOM_REWARD_ID, queryOptions);
};
