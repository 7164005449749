import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgStatus = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M1.804 3.814h2.412v5.33a3.216 3.216 0 0 0 .804 6.327c.197 0 .393-.02.587-.056.112.037.23.056.35.056.871 0 .871-.804 1.74-.804.868 0 .872.804 1.74.804s.873-.804 1.745-.804c.872 0 .868.804 1.74.804.627 0 .804-.402 1.178-.647a1.206 1.206 0 0 0 .567-1.025V3.009A2.01 2.01 0 0 0 12.657 1H3.01A2.01 2.01 0 0 0 1 3.01a.804.804 0 0 0 .804.804Zm.804 8.441A2.412 2.412 0 0 1 4.59 9.883h.052c.124-.023.25-.037.378-.04a2.412 2.412 0 1 1-2.412 2.412ZM12.657 1.804a1.206 1.206 0 0 1 1.206 1.206v10.789a.4.4 0 0 1-.19.341c-.15.1-.291.212-.421.338-.201.18-.217.189-.33.189-.112 0-.128 0-.325-.19a1.942 1.942 0 0 0-2.814 0c-.197.182-.217.19-.326.19-.108 0-.124 0-.321-.19-.35-.368-.827-.588-1.335-.614.126-.224.226-.461.298-.708l.024-.096c.024-.094.043-.19.056-.285a.948.948 0 0 0 0-.113 3.12 3.12 0 0 0 .056-.406c0-.414-.08-.823-.237-1.206h4.659a.402.402 0 0 0 0-.804h-5.04a.423.423 0 0 0-.077 0 3.264 3.264 0 0 0-.965-.804h6.082a.402.402 0 1 0 0-.804H6.226a.402.402 0 0 0-.402.402c.001.039.01.077.024.113a3.144 3.144 0 0 0-.828-.113V3.01c0-.416-.092-.828-.27-1.205h7.907Zm-9.96.04h.084a1.23 1.23 0 0 1 .229-.04c.319 0 .625.125.852.35.166.17.28.383.33.615.012.08.02.18.024.24H1.804a1.206 1.206 0 0 1 .892-1.165Z"
      />
      <path
        fill={color}
        d="M10.245 4.216h2.412a.402.402 0 0 0 0-.804h-2.412a.402.402 0 1 0 0 .804ZM10.245 5.824h2.412a.402.402 0 0 0 0-.804h-2.412a.402.402 0 1 0 0 .804ZM10.245 7.431h2.412a.402.402 0 0 0 0-.804h-2.412a.402.402 0 1 0 0 .804ZM6.628 7.431h1.608a.804.804 0 0 0 .803-.803V4.216a.804.804 0 0 0-.803-.804H6.628a.804.804 0 0 0-.804.804v2.412a.804.804 0 0 0 .804.803Zm0-3.215h1.608v2.412H6.628V4.216Z"
      />
      <path
        fill={color}
        stroke={color}
        strokeWidth={0.1}
        d="M5.558 12.657a.134.134 0 0 1-.134-.134v-.67a.67.67 0 1 0-.804 0v.67a.134.134 0 0 1-.134.134c-.621 0-.841.804-.536.804h2.144c.306 0 .087-.804-.536-.804Zm-1.45.536a.402.402 0 0 1 .378-.268.402.402 0 0 0 .402-.402c0-.804.023-.804-.067-.86a.402.402 0 1 1 .402 0c-.092.053-.067.102-.067.86a.402.402 0 0 0 .402.402.402.402 0 0 1 .38.268h-1.83Z"
      />
      <path
        fill={color}
        d="M5.96 13.595H4.084a.134.134 0 1 0 0 .268H5.96a.134.134 0 1 0 0-.268Z"
      />
    </svg>
  );
};

SvgStatus.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 18,
  width: 18,
};

export default SvgStatus;
