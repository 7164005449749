import React from "react";

const SvgAvatar17 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="p54z__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .p54z__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="p54z__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74 0 .02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-9.15 8.74-20.34 15.38-32.8 19.1-1.09.33-2.2.64-3.32.91-1.56.41-3.13.76-4.73 1.06-1.04.2-2.1.38-3.16.53-2.29.35-4.62.6-6.97.74-1.23.09-2.48.14-3.73.16-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-1.24-.02-2.48-.07-3.71-.16-2.36-.14-4.69-.39-6.98-.74-1.08-.15-2.14-.33-3.2-.54-1.58-.3-3.15-.64-4.69-1.04-1.11-.28-2.22-.58-3.31-.91a81.306 81.306 0 0 1-32.83-19.11c-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="p54z__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.085)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="p54z__cls-2"
        />
        <path
          d="m101.99 110.35-.36-.06-.06-.36c-.93-6.34-6.58-8.03-9.76-8.03-1.77 0-4.4.59-6.52 2.27l-.47.37-.28-.53c-1.4-2.63-3.94-4.19-6.79-4.19s-5.42 1.55-6.81 4.06l-.17.3-.35-.05c-.48-.08-.97-.12-1.44-.12-4.86 0-7.87 4.04-7.87 7.78v.5h-.5c-4.31 0-7.82 3.51-7.83 7.76l5.25 39.34c1.09.33 2.2.63 3.31.91l-4.87-36.53h6.74l1.47 19.89-.07.13c-2.19 4-1.85 8.34.94 12.22l.08.11.4 5.22c1.06.21 2.12.39 3.2.54l-.18-2.39.85.54c1.87 1.19 4.01 2.07 6.31 2.59 1.23.09 2.47.14 3.71.16.49.02.99.02 1.48.02s.99 0 1.48-.02c1.25-.02 2.5-.07 3.73-.16 2.3-.52 4.44-1.4 6.31-2.59l.84-.53-.18 2.38c1.06-.15 2.12-.33 3.16-.53l.39-5.16.08-.11c2.86-3.91 3.2-8.3.94-12.37l-.08-.13 1.49-19.81h6.78l-4.87 36.52c1.12-.27 2.23-.58 3.32-.91l5.23-39.27c0-3.18-1.69-8.83-8.03-9.76Zm-28.13-5.13c.88-1.41 2.33-2.24 3.89-2.24 2.41 0 4.49 2.07 4.63 4.63l.03.56-.57-.04c-.13 0-.27-.01-.4-.01-1.49 0-3.18.52-4.41 1.36l-.54.36-.21-.61a7.814 7.814 0 0 0-2.32-3.37l-.33-.28.23-.36Zm7.55 54.91c-8.31 0-15.06-4.89-15.06-10.9s6.75-10.9 15.06-10.9 15.06 4.89 15.06 10.9-6.76 10.9-15.06 10.9Zm-15.09-36.36h13.51v11.52l-.46.04c-4.24.38-8.17 1.86-11.06 4.17l-.74.59-1.25-16.32Zm28.93 16.32-.74-.59c-2.89-2.31-6.82-3.79-11.07-4.17l-.45-.04v-11.52H96.5l-1.25 16.32Zm11.62-19.48H55.95v-.5c0-2.57 2.09-4.66 4.66-4.66h6.19c-.62-.83-1.4-1.54-2.29-2.08l-.21-.12-.03-.25c-.01-.1-.28-2.56 1.16-4.28.81-.96 1.97-1.5 3.5-1.59 2.57 0 4.66 2.09 4.66 4.66v3.66h3.18c.26-2.33 2.24-4.16 4.64-4.16s4.38 1.83 4.63 4.16h3.17a7.823 7.823 0 0 0-3.4-5.95l-.33-.23.15-.37c1.47-3.6 5.4-3.83 6.18-3.85 1.13.02 6.74.41 6.74 6.74v1.58h1.58c6.33 0 6.72 5.61 6.74 6.74v.5Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar17.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar17;
