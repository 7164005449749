import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgNonMonetaryRewardApproval = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g>
        <path fill="#fff" d="M0 0h24v24H0z" />
        <path
          fill={color}
          d="M9.336 9.941a3.5 3.5 0 0 1 1.476-2.662 1.065 1.065 0 0 0-.96-.609h-.99l1.03-.732a.527.527 0 0 0 .124-.742l-.312-.433a.536.536 0 0 0-.742-.119l-1.074.762.384-1.019a.531.531 0 0 0-.315-.686l-.5-.186a.538.538 0 0 0-.695.313l-.668 1.764-.668-1.767a.538.538 0 0 0-.691-.313l-.5.186a.528.528 0 0 0-.315.681l.386 1.019-1.078-.754a.539.539 0 0 0-.748.123l-.312.433a.53.53 0 0 0 .124.742l1.03.737h-.986A1.068 1.068 0 0 0 1.262 7.74v11.719a1.068 1.068 0 0 0 1.074 1.065h5.668a2.1 2.1 0 0 1-.3-1.065v-7.458a2.134 2.134 0 0 1 1.632-2.06Zm-.557 9.518a1.068 1.068 0 0 0 1.074 1.065h5.369v-4.261H8.779Zm7.517 1.065h5.366a1.068 1.068 0 0 0 1.074-1.065v-3.2h-6.44Zm5.366-9.588h-.685a2.422 2.422 0 0 0 .148-.792 2.388 2.388 0 0 0-2.419-2.4c-1.614 0-2.547 1.588-2.95 2.507-.406-.919-1.339-2.507-2.95-2.507a2.388 2.388 0 0 0-2.419 2.4 2.338 2.338 0 0 0 .148.792h-.682a1.068 1.068 0 0 0-1.074 1.065v3.2h6.443v-4.265h.513l.027-.007.023.007h.513v4.262h6.443v-3.2a1.068 1.068 0 0 0-1.079-1.062Zm-9.128 0a1.263 1.263 0 0 1-.242-.16.814.814 0 0 1-.3-.633.784.784 0 0 1 .809-.806c.628 0 1.195.912 1.493 1.6Zm6.688-.157a1.262 1.262 0 0 1-.242.16h-1.765c.3-.676.866-1.6 1.493-1.6a.784.784 0 0 1 .809.806.814.814 0 0 1-.295.634Z"
        />
      </g>
    </svg>
  );
};

SvgNonMonetaryRewardApproval.defaultProps = {
  color: COLORS.GREY_1,
  height: 24,
  width: 24,
};

export default SvgNonMonetaryRewardApproval;
