import { t } from "i18next";
import React from "react";
import {
  COLORS,
  FORMAT_AGGREGATE_FUNCTION,
  REGEX_FOR_VALID_UUID,
} from "src/constants";
import { capitalize } from "src/helpers";
import { SvgDraft, SvgTick, SvgUnpublish } from "src/icons";

export const formatRules = (rules) => {
  const newRules = {};
  rules?.forEach((rule) => {
    newRules[rule.RuleId] = {
      ruleName: rule.RuleName,
    };
  });
  return newRules;
};

export const parseRuleIdToRuleName = (arr, formulaRules) => {
  const formula = arr.map((item) => {
    if (formulaRules[item]) {
      item = formulaRules[item].ruleName;
    }
    return item;
  });
  return formula.filter((item) => !item.match(REGEX_FOR_VALID_UUID));
};

export const formatFormulaToTokens = (formula, rules, isCustomFormula) => {
  const formulaRules = formatRules(rules);
  if (!formula) {
    return;
  }
  if (isCustomFormula) {
    const arr = formula?.trim()?.split(" ");
    return parseRuleIdToRuleName(arr, formulaRules);
  }
  const arr = formula
    .replaceAll("(", "( ")
    .replaceAll(")", " )")
    .replaceAll("+", " + ")
    .split(" ");
  return parseRuleIdToRuleName(arr, formulaRules);
};

export const getVariableRules = ({ rules }) => {
  const variableRules = [];
  const formulaRules = formatRules(rules);
  Object.keys(formulaRules).forEach((ruleId) => {
    variableRules.push({
      label: formulaRules[ruleId].ruleName,
      value: formulaRules[ruleId].ruleName,
    });
  });
  return variableRules;
};

export const getRulesetFormula = (formula, rules) => {
  const newFormula = [];
  const formulaRules = formatRules(rules);
  formula.forEach((token) => {
    const ruleId = Object.keys(formulaRules).find((ruleId) => {
      return formulaRules[ruleId].ruleName === token;
    });
    if (ruleId) {
      newFormula.push(ruleId);
    } else {
      newFormula.push(token);
    }
  });
  return newFormula.join(" ");
};

export const areAllRulesUsed = ({ rules, formula }) => {
  const formulaRules = formatRules(rules);
  const rulesetFormula = getRulesetFormula(formula, rules);
  return Object.keys(formulaRules).every((ruleId) => {
    return rulesetFormula.includes(ruleId);
  });
};

export const getFormulaRuleCount = ({ formula, rule }) => {
  let count = 0;
  formula.forEach((item) => {
    if (item === rule.RuleName) {
      count += 1;
    }
  });
  return count;
};

export const statusFormatter = ({ value, row: { original } }) => {
  switch (value) {
    case "PUBLISHED":
      return (
        <span className="d-flex align-items-center">
          <span className="mr-6 d-flex align-items-center">
            <SvgTick backgroundColor="#2ecc71" />
          </span>{" "}
          <span>{capitalize(value)}</span>
          <span className="ml-2 bg-grey px-6 br-8 fc-grey fs-11 normal">
            <span className="semi-bold">{original?.GoalRules?.length}</span>{" "}
            <span>
              {original?.GoalRules?.length > 1
                ? t("RULEBOOK.LABELS.RULES")
                : t("RULEBOOK.LABELS.RULE")}
            </span>
          </span>
        </span>
      );

    case "UNPUBLISHED":
      return (
        <span className="d-flex align-items-center">
          <span className="mr-6 d-flex align-items-center">
            {" "}
            <SvgUnpublish color={COLORS.GREY_2} />
          </span>{" "}
          <span>{capitalize(value)}</span>
          <span className="ml-2 bg-grey px-6 br-8 fc-grey fs-11 normal">
            <span className="semi-bold">{original?.GoalRules?.length}</span>{" "}
            <span>
              {original?.GoalRules?.length > 1
                ? t("RULEBOOK.LABELS.RULES")
                : t("RULEBOOK.LABELS.RULE")}
            </span>
          </span>
        </span>
      );

    case "DRAFT":
      return (
        <span className="fc-grey fs-12">
          <span className="mr-6">
            <SvgDraft />
          </span>{" "}
          {capitalize(value)}
        </span>
      );
    default:
      return null;
  }
};

export const numFormatter = ({ value, row: { original } }) => {
  if (original.Status === "DRAFT") {
    return <span className="fc-grey">-</span>;
  }
  return value;
};

export const aggregateFormatter = ({ value }) =>
  FORMAT_AGGREGATE_FUNCTION[value] ? (
    value === "CUSTOM" ? (
      "Multiple"
    ) : (
      FORMAT_AGGREGATE_FUNCTION[value]?.displayFormat
    )
  ) : (
    <span className="fc-grey">-</span>
  );

export const auditFormatter = ({ value }) =>
  `${value}` === "STICKY" ? "Sticky ON" : "Sticky OFF";
