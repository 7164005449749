import { gql, useMutation } from "src/api/client";

const VALIDATE_CUSTOM_REWARD_BULK_UPDATE_FILE = gql`
  mutation validateCustomRewardBulkUpdateFile($FileId: Float!) {
    validateCustomRewardBulkUpdateFile(FileId: $FileId) {
      ... on CustomRewardValidateResponse {
        success
        message
        response {
          downloadUrl
          errorCount
        }
      }
      ... on ErrorResponse {
        message
        __typename
      }
    }
  }
`;

export const useValidateCustomRewardBulkUpdateFile = () => {
  return useMutation(VALIDATE_CUSTOM_REWARD_BULK_UPDATE_FILE);
};
