import { classNames } from "src/helpers";
import "./glassDropdownItem.scss";

const GlassDropdownItem = ({
  onClick,
  children,
  handleMenuClose,
  selected,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames([
        "glass-dropdown-item",
        selected ? "selected" : "",
      ])}
    >
      <div className="glass-dropdown-item-content">{children}</div>
    </button>
  );
};

export default GlassDropdownItem;
