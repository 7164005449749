import { gql, useMutation } from "src/api/client";

const INSERT_CUSTOM_REWARD_BULK_CREATE_RECORDS = gql`
  mutation insertCustomRewardBulkCreateRecords($FileId: Float!) {
    insertCustomRewardBulkCreateRecords(FileId: $FileId) {
      ... on CustomRewardValidateResponse {
        success
        message
        response {
          downloadUrl
          errorCount
        }
      }
      ... on ErrorResponse {
        message
        __typename
      }
    }
  }
`;

export const useInsertCustomRewardBulkCreateRecords = () => {
  return useMutation(INSERT_CUSTOM_REWARD_BULK_CREATE_RECORDS);
};
