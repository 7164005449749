import { gql, useQuery } from "src/api/client";
import {
  CORE_RECURRENCES_ACHIEVEMENTS_FIELDS_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
} from "src/api/fragments";

// src/modules/assignedView/mobile/AssignedToMeDetailMobileView.js
// src/modules/publisedGoalView/GoalView.js
const GET_GOAL_ASSIGNED_RECURRENCES_ACHIEVEMENTS_QUERY = gql`
  ${CORE_RECURRENCES_ACHIEVEMENTS_FIELDS_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query getRecurrencesAchievements(
    $GoalId: ID!
    $UserId: ID
    $isAssignedView: Boolean
  ) {
    goalRecurrencesAchievements(
      GoalId: $GoalId
      UserId: $UserId
      isAssignedView: $isAssignedView
    ) {
      __typename
      ... on RecurrencesResponse {
        success
        response {
          GoalAchievedCount
          RecurrencesAchievements {
            AvgSum
            AvgCount
            ...coreRecurrencesAchievementsFieldsFragment
            GoalDistribution
            IsUserPresent
            CurrentUserDistribution
          }
        }
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;
export const useGetAssignedGoalRecurrencesAchievements = (queryOptions) => {
  return useQuery(
    GET_GOAL_ASSIGNED_RECURRENCES_ACHIEVEMENTS_QUERY,
    queryOptions
  );
};

const GET_GOAL_PUBLISHED_RECURRENCES_ACHIEVEMENTS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getRecurrencesAchievements($GoalId: ID!, $UserId: ID, $Query: JSON) {
    goalRecurrencesAchievements(
      GoalId: $GoalId
      UserId: $UserId
      Query: $Query
    ) {
      __typename
      ... on RecurrencesResponse {
        success
        response {
          CurrentRecurrenceAchievements {
            AvgCount
            AvgSum
            GoalAchieved
            GoalDistribution
            GoalId
            GoalUsersCount
            RecurrenceEndDate
            RecurrenceGoalValue
            RecurrenceId
            RecurrenceStartDate
            RecurrenceType
            UsersAchievedCount
          }
          RecurrencesAchievements {
            AvgCount
            AvgSum
            GoalAchieved
            GoalDistribution
            GoalId
            GoalUsersCount
            RecurrenceEndDate
            RecurrenceGoalValue
            RecurrenceId
            RecurrenceStartDate
            RecurrenceType
            UsersAchievedCount
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;
export const useGetPublishedGoalRecurrencesAchievements = (queryOptions) => {
  return useQuery(
    GET_GOAL_PUBLISHED_RECURRENCES_ACHIEVEMENTS_QUERY,
    queryOptions
  );
};

const GET_GOAL_ACHIEVEMENT_RECURRENCES_ACHIEVEMENTS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getRecurrencesAchievements(
    $GoalId: ID!
    $UserId: ID
    $Query: JSON
    $isDrilldownView: Boolean
  ) {
    goalRecurrencesAchievements(
      GoalId: $GoalId
      UserId: $UserId
      Query: $Query
      isDrilldownView: $isDrilldownView
    ) {
      __typename
      ... on RecurrencesResponse {
        success
        response {
          CurrentRecurrenceAchievements {
            AvgCount
            AvgSum
            GoalAchieved
            GoalDistribution
            GoalId
            GoalUsersCount
            RecurrenceEndDate
            RecurrenceGoalValue
            RecurrenceId
            RecurrenceStartDate
            RecurrenceType
            UsersAchievedCount
          }
          RecurrencesAchievements {
            AvgCount
            AvgSum
            GoalAchieved
            GoalDistribution
            GoalId
            GoalUsersCount
            RecurrenceEndDate
            RecurrenceGoalValue
            RecurrenceId
            RecurrenceStartDate
            RecurrenceType
            UsersAchievedCount
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;
export const useGetGoalAchievementRecurrencesAchievements = (queryOptions) => {
  return useQuery(
    GET_GOAL_ACHIEVEMENT_RECURRENCES_ACHIEVEMENTS_QUERY,
    queryOptions
  );
};
