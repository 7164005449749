import { t } from "i18next";

export const CUSTOM_REWARD_STATUSES = () => [
  {
    label: t("MANAGED_GOALS.MESSAGES.ALL"),
    value: "ALL",
  },
  {
    label: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.ACTIVATED"),
    value: true,
  },
  {
    label: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.DEACTIVATED"),
    value: false,
  },
];

export const NON_MONETARY_CATEGORIES = {
  TRAVEL: "TRAVEL",
  GADGET: "GADGET",
  VOUCHER: "VOUCHER",
  LEARNING: "LEARNING",
  OTHERS: "OTHERS",
};

export const CUSTOM_REWARD_CATEGORY_OPTIONS = () => [
  {
    label: t("MANAGED_GOALS.MESSAGES.ALL"),
    value: "ALL",
  },
  {
    label: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.TRAVEL"),
    value: "TRAVEL",
  },
  {
    label: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.GADGET"),
    value: "GADGET",
  },
  {
    label: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.VOUCHER"),
    value: "VOUCHER",
  },
  {
    label: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.LEARNING"),
    value: "LEARNING",
  },
  {
    label: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.OTHERS"),
    value: "OTHERS",
  },
];

export const MODULES = () => ({
  INCENTIVE: t("INCENTIVES.COMMON.LABELS.INCENTIVES"),
  LEADERBOARD: t("LEADERBOARD.COMMON.LABELS.LEADERBOARD"),
});

export const MODULE_OPTIONS = () => [
  {
    label: t("MANAGED_GOALS.MESSAGES.ALL"),
    value: "ALL",
  },
  {
    label: t("INCENTIVES.COMMON.LABELS.INCENTIVES"),
    value: "INCENTIVE",
  },
  {
    label: t("LEADERBOARD.COMMON.LABELS.LEADERBOARD"),
    value: "LEADERBOARD",
  },
];

export const CYCLE_STATUSES = () => [
  {
    label: t("MANAGED_GOALS.MESSAGES.ALL"),
    value: "ALL",
  },
  {
    label: t("MANAGED_GOALS.LABELS.DRAFT"),
    value: "DRAFT",
  },
  {
    label: t("COMMON.LABELS.YET_TO_START"),
    value: "YETTOSTART",
  },
  {
    label: t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.IN_PROGRESS"),
    value: "INPROGRESS",
  },
  {
    label: t("COMMON.LABELS.UNPUBLISHED"),
    value: "UNPUBLISHED",
  },
];

export const PROGRAM_STATUSES = {
  DRAFT: "DRAFT",
  INPROGRESS: "INPROGRESS",
  YETTOSTART: "YETTOSTART",
  UNPUBLISHED: "UNPUBLISHED",
};

export const CUSTOM_REWARD_TEMPLATE = {
  RewardId: undefined,
  RewardName: "",
  RewardCategory: "",
  RewardValue: null,
  RewardLink: "",
  Description: "",
  IsActive: true,
};

export const REWARD_STATUS = {
  DELETE: "DELETE",
};

export const MAX_ALLOWED_REWARDS = 100;
