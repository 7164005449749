import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

// scr/modules/goalCRUD/goalModals/PublishConfirmationModal.js
// src/modules/publishedGoalView/goalViewHeader/unpublishGoal.js
const UPDATE_GOAL_STATUS_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation UpdateGoal(
    $GoalId: ID!
    $Status: GoalStatusEnum
    $Name: NotEmpty
    $Description: String
  ) {
    updateGoal(
      GoalId: $GoalId
      Status: $Status
      Name: $Name
      Description: $Description
    ) {
      ... on SingleGoalResponse {
        response {
          Goal {
            Name
            Description
            Status
          }
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useUpdateGoalStatus = (queryOptions) => {
  return useMutation(UPDATE_GOAL_STATUS_MUTATION, queryOptions);
};
