import { t } from "i18next";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import { Trans } from "react-i18next";
import { FILE_ERROR_CODES, Image, Truncate } from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { SvgAttachment, SvgDelete, SvgError, SvgTick } from "src/icons";
import "./styles.scss";

export const OnLoad = ({ title, hintText, buttonText, handleClick, Icon }) => {
  return (
    <div>
      <div className="file-container onload image-upload mb-4">
        {Icon}
        <div className="flex-column center mt-3">
          <div className="fs-13 mb-12">{title}</div>
          <div className="fs-12 fc-grey">{hintText}</div>
        </div>
        <FormButton
          id="choose-file"
          variant="outline-primary"
          className="mt-4"
          label={buttonText}
          onClick={handleClick}
          icon={<SvgAttachment />}
        />
      </div>
      <div className="fs-12 fc-grey1 text-center">
        {t(
          "SETTINGS.CUSTOM_REWARDS_CONFIG.MESSAGES.MAXIMUM_ALLOWED_DIMENSIONS_FOR_IMAGE_UPLOAD"
        )}
      </div>
    </div>
  );
};

OnLoad.propTypes = {
  title: PropTypes.string,
  hintText: PropTypes.string,
  buttonText: PropTypes.string,
  handleClick: PropTypes.func,
  Icon: PropTypes.element,
};

export const OnDrop = ({ hintText }) => {
  return (
    <div>
      <div className="file-container ondrop mb-4">
        <SvgAttachment color={COLORS.GREY_2} className="mb-1" />
        <div className="fs-12 light grey1">{hintText}</div>
      </div>
      <div className="fs-12 fc-grey1 text-center">
        {t(
          "SETTINGS.CUSTOM_REWARDS_CONFIG.MESSAGES.MAXIMUM_ALLOWED_DIMENSIONS_FOR_IMAGE_UPLOAD"
        )}
      </div>
    </div>
  );
};

OnDrop.propTypes = {
  hintText: PropTypes.string,
};

export const OnUpload = ({ fileName, handleCancel }) => {
  return (
    <div>
      <div className="file-container onupload mb-4">
        <div className="fs-12 light grey1">
          <Truncate maxWidth="400px" text={fileName} />
        </div>
        <div className="flex-center mt-1">
          <ProgressBar
            className="w-180"
            striped
            animated
            variant="success"
            now={40}
          />
          <FormButton
            id="upload-remove"
            onClick={handleCancel}
            className="fs-10 p-0 ml-3"
            variant="link"
            label={t("COMMON.BUTTONS.CANCEL")}
          />
        </div>
      </div>
      <div className="fs-12 fc-grey1 text-center">
        {t(
          "SETTINGS.CUSTOM_REWARDS_CONFIG.MESSAGES.MAXIMUM_ALLOWED_DIMENSIONS_FOR_IMAGE_UPLOAD"
        )}
      </div>
    </div>
  );
};

OnUpload.propTypes = {
  fileName: PropTypes.string,
  handleCancel: PropTypes.func,
};

export const OnSuccess = ({
  fileName,
  imageUrl,
  handleRemove,
  isNewUpload,
}) => {
  return (
    <div>
      <div className="file-container onsuccess mb-4">
        <Image src={imageUrl} alt="reward-icon" />
      </div>
      <div className="d-flex flex-column align-items-center fs-12">
        <div className="mb-1 flex-center">
          <SvgTick backgroundColor="#2ecc71" className="mr-2" />
          {isNewUpload ? (
            <div className="center">
              {t("INCENTIVES.COMMON.LABELS.FILE")}
              <span className="mx-1 semi-bold">
                <Truncate text={fileName} maxWidth="200px" />
              </span>
              {t("INCENTIVES.COMMON.LABELS.UPLOADED_SUCCESSFULLY")}
            </div>
          ) : null}
          {!isNewUpload ? (
            <div className="semi-bold">
              <Truncate text={fileName} maxWidth="200px" />
            </div>
          ) : null}
        </div>
        <FormButton
          id="success-remove"
          className="fs-10 p-0"
          variant="link"
          label={t("SETTINGS.CUSTOM_REWARDS_CONFIG.BUTTONS.REMOVE_FILE")}
          icon={<SvgDelete />}
          onClick={handleRemove}
        />
      </div>
    </div>
  );
};

OnSuccess.propTypes = {
  fileName: PropTypes.string,
  handleRemove: PropTypes.func,
  isNewUpload: PropTypes.bool,
};

export const OnError = ({ fileName, error, handleRemove }) => {
  return (
    <div>
      <div className="file-container onerror mb-4">
        <div className="center fs-12 fc-grey1">
          {FILE_ERROR_CODES.FILE_DIMENSION_MISMATCH === error.code
            ? t("SETTINGS.CUSTOM_REWARDS_CONFIG.MESSAGES.DIMENSIONS_ARE_HIGH")
            : null}
          {FILE_ERROR_CODES.FILE_TOO_LARGE === error.code ? (
            <div className="center fs-12 fc-grey1">
              {t("INCENTIVES.COMMON.LABELS.FILE")}{" "}
              {t("INCENTIVES.COMMON.LABELS.SIZE_EXCEEDS")}
            </div>
          ) : null}
          {[
            FILE_ERROR_CODES.DOUBLE_DOT_EXTENSION,
            FILE_ERROR_CODES.FILE_NAME_TOO_LARGE,
            FILE_ERROR_CODES.FILE_INVALID_TYPE,
          ].includes(error.code)
            ? error.message
            : null}
        </div>
      </div>
      <div className="d-flex flex-column align-items-center fs-12">
        {FILE_ERROR_CODES.FILE_DIMENSION_MISMATCH === error.code ? (
          <div className="mb-12">
            <div className="mb-2 flex-center">
              <SvgError className="mr-2" />
              <Trans i18nKey="SETTINGS.CUSTOM_REWARDS_CONFIG.MESSAGES.UNABLE_TO_UPLOAD_FILE">
                File{" "}
                <span className="semi-bold mx-1">
                  <Truncate text={fileName} maxWidth="200px" />
                </span>{" "}
                could not be uploaded
              </Trans>
            </div>
            <div>{error.message}</div>
          </div>
        ) : null}
        {[
          FILE_ERROR_CODES.FILE_NAME_TOO_LARGE,
          FILE_ERROR_CODES.FILE_INVALID_TYPE,
        ].includes(error.code) ? (
          <div className="mb-1 flex-center">
            <SvgError className="mr-2" />
            <div className="semi-bold">{error.message}</div>
          </div>
        ) : null}
        {FILE_ERROR_CODES.FILE_TOO_LARGE === error.code ? (
          <div className="mb-2 flex-center">
            <SvgError className="mr-2" />
            <div className="center">
              {t("INCENTIVES.COMMON.LABELS.FILE")}
              <span className="semi-bold mx-1">
                <Truncate text={fileName} maxWidth="200px" />
              </span>
              {t("INCENTIVES.COMMON.LABELS.SIZE_EXCEEDS")}
            </div>
          </div>
        ) : null}
        {FILE_ERROR_CODES.DOUBLE_DOT_EXTENSION === error.code ? (
          <div className="mb-12">
            <div className="mb-2 flex-center">
              <SvgError className="mr-2" />
              <Trans i18nKey="SETTINGS.CUSTOM_REWARDS_CONFIG.MESSAGES.UNABLE_TO_UPLOAD_FILE">
                File{" "}
                <span className="semi-bold mx-1">
                  <Truncate text={fileName} maxWidth="200px" />
                </span>{" "}
                could not be uploaded
              </Trans>
            </div>
          </div>
        ) : null}
        <FormButton
          id="error-remove"
          className="fs-10 p-0"
          variant="link"
          label={t("SETTINGS.CUSTOM_REWARDS_CONFIG.BUTTONS.REMOVE_FILE")}
          icon={<SvgDelete />}
          onClick={handleRemove}
        />
      </div>
    </div>
  );
};

OnError.propTypes = {
  fileName: PropTypes.string,
  error: PropTypes.object,
  handleRemove: PropTypes.func,
};
