import React from "react";
import { useTranslation } from "react-i18next";

const RuleUsageContent = (props) => {
  const { color, currentCount, limit, message, dateTime } = props;
  const { t } = useTranslation();

  return (
    <div>
      <p className={`${color} fs-12`}>
        <span className="semi-bold">{currentCount}</span>/{limit} {message}
      </p>

      <p className="fc-dark-grey fs-12">
        {t("RULEBOOK.LABELS.AS_OF")} {dateTime}
      </p>
    </div>
  );
};

export default RuleUsageContent;
