import { gql, useQuery } from "src/api/client";

const GET_APPROVAL_USERS_QUERY = gql`
  query usersForApproval(
    $programId: ID
    $recurrenceId: ID
    $GroupId: ID
    $recurrenceStatus: String
    $after: String
    $limit: Float
    $query: JSON
    $sort: ApprovalSort!
  ) {
    usersForApproval(
      programId: $programId
      recurrenceId: $recurrenceId
      GroupId: $GroupId
      recurrenceStatus: $recurrenceStatus
      after: $after
      limit: $limit
      query: $query
      sort: $sort
    ) {
      ... on UsersForApprovalResponse {
        success
        message
        response {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
          loggedInUserStage
          totalStages
          users {
            FullName
            UserId
            EmailAddress
            AchievementsCount
            AchievementsMetadata
            EligibleBenefit
            EligibleRewardIds
            ApprovedBenefit
            RejectedBenefit
            ApprovalStatus
            ApprovedRewardsCount
            EligibleRewardsCount
            RejectedRewardsCount
            EligibleRewards {
              RewardName
            }
            ApprovedRewards {
              RewardName
            }
            RejectedRewards {
              RewardName
            }
            NextApprover {
              userId
              fullName
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useGetApprovalUsers = (queryOptions) => {
  return useQuery(GET_APPROVAL_USERS_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
