import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { FormBadge } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { SvgError } from "src/icons";
import "./mobileAuditBadge.scss";

const MobileAuditBadge = ({ auditType }) => {
  const audit = auditType === "NON_STICKY" ? "Non Sticky" : "Sticky";
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <FormBadge
        onClick={() => setShowModal(true)}
        className="center bg-secondary-blue"
      >
        <span className="fc-blue">{audit}</span>
        <SvgError className="ml-1" rotate={180} color={COLORS.PRIMARY_BLUE} />
      </FormBadge>
      <Modal
        className="mobile-audit-badge-modal"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        backdropClassName="mobile-audit-badge-modal-backdrop"
      >
        <Modal.Header className="fs-14 semi-bold" closeButton>
          {t("RULEBOOK.LABELS.AUDIT_TYPE")}: {audit}
        </Modal.Header>
        <Modal.Body className="px-3 pb-3 fs-12">
          {auditType === "STICKY" ? (
            <Trans
              i18nKey="RULEBOOK.MESSAGES.ACHIEVEMENT_CALCULATION_SHOULD_HAPPEN"
              t={t}
            >
              Achievement calculation should happen on{" "}
              <span className="semi-bold">sequence of actions or changes</span>{" "}
              in entities.
            </Trans>
          ) : (
            <Trans
              i18nKey="RULEBOOK.MESSAGES.ACHIEVEMENT_CALCULATION_WILL_HAPPEN"
              t={t}
            >
              Achievement calculation will happen on the{" "}
              <span className="semi-bold">current stage </span>of the entities.
            </Trans>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MobileAuditBadge;
