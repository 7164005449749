import React from "react";
import { Footer, Header, Shimmer } from "src/components";
import { AuthProvider } from "src/context";
import { getOrgCode } from "src/helpers";
import { useLogin } from "src/hooks";
import AceApp from "./AceApp";
import "./OpenAceApp.scss";
import OpenAceFallback from "./OpenAceFallback";

const OpenAceAppBody = (props) => {
  const { isOpenAceUser, isPageLoading, children, isLoggedIn } = props;

  if (isPageLoading) {
    return (
      <div className="w-100 page-container py-2">
        <Shimmer width="100%" height="600px" />
      </div>
    );
  }

  if (isLoggedIn) {
    return (
      <AuthProvider>
        <AceApp {...{ isLoggedIn, isOpenAceUser }}>{children}</AceApp>
      </AuthProvider>
    );
  }

  return <OpenAceFallback />;
};

const OpenAceApp = ({ isOpenAceUser, children }) => {
  const { isPageLoading, isLoggedIn } = useLogin();

  return (
    <div className="open-ace-app">
      <Header {...{ isPageLoading, isLoggedIn }} />
      <div className="open-ace-app-body">
        <OpenAceAppBody
          {...{ isOpenAceUser, isPageLoading, children, isLoggedIn }}
        />
      </div>
      {isLoggedIn && !isPageLoading && (
        <Footer shortCode={getOrgCode(localStorage.getItem("access_token"))} />
      )}
    </div>
  );
};

export default OpenAceApp;
