import PropTypes from "prop-types";
import React from "react";
import { COLORS } from "src/constants";
import { classNames, getRankColor } from "src/helpers";
import DotBadge from "../dotBadge/DotBadge";
import "./rankBadge.scss";

const RankBadgeProps = {
  size: PropTypes.string,
  outlineWidth: PropTypes.string,
  outlineColor: PropTypes.string,
  rank: PropTypes.number,
};
const defaultRankBadgeProps = {
  size: 5,
  outlineWidth: "2px",
  outlineColor: COLORS.WHITE,
  rank: 0,
};

const RankBadge = (props) => {
  const { size, outline, outlineWidth, outlineColor, rank } = props;
  return (
    <DotBadge
      size={size}
      outlineProps={{ outline, outlineWidth, outlineColor }}
      bg={false}
      className={classNames(["rank-badge", getRankColor(rank)])}
    >
      {rank}
    </DotBadge>
  );
};

RankBadge.propTypes = RankBadgeProps;
RankBadge.defaultProps = defaultRankBadgeProps;

export default RankBadge;
