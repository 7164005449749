import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormButton } from "src/components";

export const ErrorModal = (props) => {
  const { t } = useTranslation();
  const getHeader = () => {
    return props.warning
      ? t("RULEBOOK.MESSAGES.UPDATE_CONDITION_PATTERN")
      : t("RULEBOOK.MESSAGES.TROUBLE_UPDATING_CONDITION");
  };
  const getBody = () => {
    if (props.warning) {
      return t("RULEBOOK.MESSAGES.UPDATE_CONDITION_SAVE");
    }
    return (
      <>
        {t("RULEBOOK.MESSAGES.CONDITIONS_ERROR")} :
        <div className="mt-2 p-2 condition-definition">
          {props.errors &&
            props.errors.map((error, index) => (
              <div key={index} className="p-1">
                {index + 1}. {error}
              </div>
            ))}
        </div>
      </>
    );
  };

  return (
    <Modal
      show={props.errorModal}
      onHide={() => props.showErrorModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-16">{getHeader()}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-13 p-4">{getBody()}</Modal.Body>
      {!props.cancelButtonOff && (
        <Modal.Footer className="border-top-0 align-self-start">
          <FormButton
            id="error-modal"
            className="fs-12"
            onClick={() => props.showErrorModal(!props.errorModal)}
            variant="outline-primary"
            label={t("COMMON.BUTTONS.CLOSE")}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
};
