import React from "react";

const SvgMenu = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 13 10"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M0 .926C0 .415.466 0 1.04 0h.01c.575 0 1.04.415 1.04.926 0 .511-.465.926-1.04.926h-.01C.466 1.852 0 1.437 0 .926Zm3.12 0C3.12.415 3.586 0 4.16 0h7.8c.574 0 1.04.415 1.04.926 0 .511-.466.926-1.04.926h-7.8c-.574 0-1.04-.415-1.04-.926ZM0 5c0-.511.466-.926 1.04-.926h.01c.575 0 1.04.415 1.04.926 0 .511-.465.926-1.04.926h-.01C.466 5.926 0 5.51 0 5Zm3.12 0c0-.511.466-.926 1.04-.926h7.8c.574 0 1.04.415 1.04.926 0 .511-.466.926-1.04.926h-7.8c-.574 0-1.04-.415-1.04-.926ZM0 9.074c0-.511.466-.926 1.04-.926h.01c.575 0 1.04.415 1.04.926 0 .511-.465.926-1.04.926h-.01C.466 10 0 9.585 0 9.074Zm3.12 0c0-.511.466-.926 1.04-.926h7.8c.574 0 1.04.415 1.04.926 0 .511-.466.926-1.04.926h-7.8c-.574 0-1.04-.415-1.04-.926Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
SvgMenu.defaultProps = {
  color: "#309AFC",
  height: "10px",
  width: "10px",
};
export default SvgMenu;
