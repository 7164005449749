import React from "react";
import { useTranslation } from "react-i18next";
import { FormButton, NonMonetaryLabel } from "src/components";
import { ApprovedIncentive } from "src/images";
import CardHeader from "./CardHeader";
import "./styles.scss";

const ProgramDetailsCard = (props) => {
  const { t } = useTranslation();
  const {
    status = "6th cycle in progress",
    amount,
    nonMonetaryRewards,
    rewardCount,
    detailsContainer,
    title,
    cycleCount,
    RecurrenceTimeLeft,
    onClickHandler,
    linkedProgramCount,
  } = props;
  return (
    <div className="side-banner-card row mb-3 mx-0 bg-white">
      <FormButton
        className=" px-0 pointer w-100 pb-3"
        onClick={onClickHandler}
        variant="normal-link"
      >
        <CardHeader
          icon={<ApprovedIncentive width="60px" height="60px" />}
          amount={
            <NonMonetaryLabel
              amount={amount}
              nonMonetaryRewards={
                nonMonetaryRewards.filter((reward) => reward?.RewardName) || []
              }
              rewardCount={rewardCount}
              nonMonetaryClassName={` ${
                rewardCount > 0 ? "stat-nonmonetary fs-12" : ""
              }`}
              className="fs-18 semi-bold"
            />
          }
          description={t("INCENTIVES.COMMON.LABELS.ELIGIBLE_INCENTIVE")}
          title={title}
          cycleCount={cycleCount}
          recurrenceTimeLeft={RecurrenceTimeLeft}
          status={status}
          linkedProgramCount={linkedProgramCount}
        />
      </FormButton>

      <div className="w-100">
        <hr className="mx-3" />
        {detailsContainer}
      </div>
    </div>
  );
};

export default ProgramDetailsCard;
