import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_MANAGERS_IN_GOAL_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getManagersInGoal($goalId: ID!) {
    managersInGoal(goalId: $goalId) {
      ... on ManagersInGoalResponse {
        success
        message
        response {
          UserID
          ManagerName
          isActive
          EmailAddress
        }
        __typename
      }
      ...errorResponseFragment
      __typename
    }
  }
`;

export const useGetManagersInGoal = (queryOptions) => {
  return useQuery(GET_MANAGERS_IN_GOAL_QUERY, queryOptions);
};
