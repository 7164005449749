import React from "react";

const SvgPartiallyTick = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      id="i83f__Layer_1"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .i83f__clqs-1{fill:none;stroke:#ebb93e;stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px}\n        "
          }
        </style>
      </defs>
      <path
        d="M7 .362a6.637 6.637 0 1 1 0 13.275M7 13.637c-.277 0-.55-.017-.819-.05"
        className="i83f__clqs-1"
      />
      <path
        d="M4.497 13.15A6.64 6.64 0 0 1 5.317.577"
        style={{
          strokeDasharray: "0 0 1.7438 1.7438",
          fill: "none",
          stroke: color,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".75px",
        }}
      />
      <path
        d="M6.181.412C6.45.38 6.723.362 7 .362M3.493 7.854l1.557 1.7 5.378-4.955"
        className="i83f__clqs-1"
      />
    </svg>
  );
};
SvgPartiallyTick.defaultProps = {
  color: "#ebb93e",
  height: 14,
  width: 14,
};
export default SvgPartiallyTick;
