import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const CREATE_LEADERBOARD_PROGRAM_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation createLeaderboard($Name: NotEmpty!, $Description: String) {
    createLeaderboard(Name: $Name, Description: $Description) {
      __typename
      ... on SingleLeaderboardResponse {
        response {
          Leaderboard {
            LeaderboardId
            __typename
          }
          __typename
        }
        message
        success
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useCreateLeaderboardProgram = () => {
  return useMutation(CREATE_LEADERBOARD_PROGRAM_MUTATION);
};
