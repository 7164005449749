import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgArrow = (props) => {
  const { color, height, width, rotate, styles, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...styles,
        transform: `rotate(${rotate}deg)`,
        transformOrigin: "50% 50%",
      }}
      {...rest}
    >
      <g>
        <path fill="none" d="M12 0v12H0V0z" />
        <path fill="none" d="M12 0v12H0V0z" />
        <path
          fill={color}
          d="M2.287 4.629a.75.75 0 0 1 1.06 0L5.999 7.28l2.652-2.651a.75.75 0 0 1 1.06 1.06l-3.18 3.182a.751.751 0 0 1-1.061 0L2.287 5.689a.749.749 0 0 1 0-1.06Z"
        />
      </g>
    </svg>
  );
};

SvgArrow.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 16,
  width: 16,
  rotate: 0,
  styles: {},
};
export default SvgArrow;
