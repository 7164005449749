import React from "react";
import { useGetTemplateViewDetails } from "src/api/useTemplate";
import { ModalContainer } from "src/components/container";
import { RulesetDetailView } from "src/components/pages/rulesets";
import "./rulesetInfoModalContainer.scss";

const RulesetInfoModalContainer = ({ show, onHide, templateId }) => {
  const { loading, data: templateDetails } = useGetTemplateViewDetails({
    variables: { TemplateId: templateId },
  });

  const templateDetailsResults = templateDetails?.template?.response?.Template;

  if (show)
    return (
      <ModalContainer
        title="Ruleset Used"
        disableFooter
        show={show}
        onHide={onHide}
        modalClassName="incentives-popover-ruleset-info"
      >
        <RulesetDetailView
          showRulesetBadge={false}
          loading={loading}
          templateDetailsResults={templateDetailsResults}
        />
      </ModalContainer>
    );

  return null;
};

export default RulesetInfoModalContainer;
