import { gql, useMutation } from "src/api/client";

const VALIDATE_CUSTOM_REWARD_BULK_CREATE_FILE = gql`
  mutation validateCustomRewardBulkCreateFile($FileId: Float!) {
    validateCustomRewardBulkCreateFile(FileId: $FileId) {
      ... on CustomRewardValidateResponse {
        success
        message
        response {
          downloadUrl
          errorCount
        }
      }
      ... on ErrorResponse {
        message
        __typename
      }
    }
  }
`;

export const useValidateCustomRewardBulkCreateFile = () => {
  return useMutation(VALIDATE_CUSTOM_REWARD_BULK_CREATE_FILE);
};
