import axios from "axios";
import { CLIENT_ID, GET_TOKEN_URL, REDIRECT_URI } from "src/constants";
import { decodeBase64 } from "src/helpers";
import { useIsLoggedIn } from "src/hooks";

export const useGetToken = () => {
  const { setIsLoggedIn } = useIsLoggedIn();

  const getToken = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get("code") ?? "";
      const codeVerifier = localStorage.getItem("code_verifier");
      const orgCode = decodeBase64(code).split("OrgCode")[1].trim();

      if (!codeVerifier) {
        return Promise.reject("Code verifier not found");
      }

      if (!code) {
        return Promise.reject("Org Code not found");
      }

      const formData = {
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        grant_type: "authorization_code",
        code_verifier: codeVerifier,
        org_code: orgCode,
        code,
      };

      const { data } = await axios
        .create({ baseURL: REDIRECT_URI })
        .post(GET_TOKEN_URL, formData, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("id_token", data.id_token);
      setIsLoggedIn(true);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return getToken;
};
