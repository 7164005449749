import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { classNames } from "src/helpers";
import { SvgDragAndDrop } from "src/icons";
import "./rearrangeColumns.scss";

const ColumnItems = ({
  id,
  className,
  activationConstraint,
  children,
  labelComponent,
  dragIconAtTheBack = true,
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transition,
    transform,
    isDragging,
  } = useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    touchAction: "manipulation",
  };

  return (
    <li ref={setNodeRef}>
      <div
        className={`drag-column-item fs-12 ${
          isDragging ? "current" : ""
        } ${className}`}
        activationConstraint={activationConstraint}
        {...attributes}
        style={style}
      >
        {children ? (
          children
        ) : (
          <div
            className={classNames([
              "d-flex",
              !dragIconAtTheBack && "flex-row-reverse flex-end",
            ])}
          >
            {labelComponent}
            {!isDragging && (
              <span
                className={classNames([dragIconAtTheBack && "ml-auto"])}
                {...listeners}
                style={{ touchAction: "none" }}
              >
                <SvgDragAndDrop />
              </span>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default ColumnItems;
