import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { useViewport } from "src/hooks";

const LoadingFilters = () => {
  const { isMobile } = useViewport();

  if (isMobile)
    return (
      <div className="center mt-4">
        <Shimmer width="38px" height="40px" />
        <Shimmer className="ml-3" width="38px" height="40px" />
      </div>
    );

  return (
    <div className="mt-4 flex-between w-100">
      <div className="center w-100">
        <div>
          <Shimmer width="60px" height="10px" />
          <Shimmer className="mt-2" width="150px" height="40px" />
        </div>
        <div className="ml-3">
          <Shimmer width="60px" height="10px" />
          <Shimmer className="mt-2" width="150px" height="40px" />
        </div>
        <div className="ml-3">
          <Shimmer width="60px" height="10px" />
          <Shimmer className="mt-2" width="150px" height="40px" />
        </div>
        <div className="ml-auto">
          <Shimmer width="118px" height="40px" />
        </div>
      </div>
    </div>
  );
};

export default LoadingFilters;
