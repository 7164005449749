import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { LoadingMobileHeader } from "../loadingMobileHeader";
import { LoadingTable } from "../loadingTable";

const LoadingPublishedGoalMobileView = () => {
  return (
    <div>
      <LoadingMobileHeader />
      <div className="mx-3">
        <div className="my-3">
          <Shimmer width="60%" />
        </div>
        <div className="bg-shimmer p-3 mb-3">
          <Shimmer width="40%" />
        </div>
        <div className="bg-shimmer p-3 mb-3">
          <Shimmer width="60%" />
          <div className="mt-3">
            <Shimmer width="100%" />
          </div>
        </div>
        <div className="bg-shimmer p-3 mb-3">
          <div className="flex-between">
            <Shimmer width="30%" />
            <Shimmer width="30%" />
          </div>
          <div className="mt-3">
            <Shimmer width="100%" />
          </div>
        </div>
        <div className="center mb-3">
          <div>
            <Shimmer width="40px" height="40px" />
          </div>
          <div className="ml-3">
            <Shimmer width="40px" height="40px" />
          </div>
          <div className="ml-3">
            <Shimmer width="40px" height="40px" />
          </div>
        </div>
        <div>
          <LoadingTable />
        </div>
      </div>
    </div>
  );
};

export default LoadingPublishedGoalMobileView;
