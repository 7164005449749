import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgTarget = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 21"
      role="img"
      {...rest}
    >
      <path
        id="_3827997_business_management_target_icon"
        data-name="3827997_business_management_target_icon"
        d="M21.479,7.21l1.242-1.24a.489.489,0,0,0,.157-.521.522.522,0,0,0-.522-.323H19.747v-2.6A.521.521,0,0,0,19.423,2a.5.5,0,0,0-.564.115L17.659,3.4A10.337,10.337,0,0,0,12.441,2,10.477,10.477,0,1,0,21.479,7.21ZM19.225,6.168h1.868L19.006,8.252h-2.39V5.866L18.7,3.783V5.647A.521.521,0,0,0,19.225,6.168Zm-.522,6.25a6.263,6.263,0,1,1-3.131-5.4V8.554l-.584.583a4.163,4.163,0,1,0,.741.74l.584-.583h1.534A6.241,6.241,0,0,1,18.7,12.418Zm-3.131,0a3.153,3.153,0,1,1-1.325-2.542l-2.171,2.177a.516.516,0,1,0,.731.729l2.181-2.167A3.121,3.121,0,0,1,15.572,12.418Zm-3.131,9.375a9.378,9.378,0,1,1,4.4-17.635L15.729,5.283a.489.489,0,0,0-.157.365v.187a7.286,7.286,0,1,0,3.465,3.458h.188a.491.491,0,0,0,.365-.156l1.127-1.115a9.375,9.375,0,0,1-8.276,13.771Z"
        transform="translate(-1.904 -1.96)"
        fill={color}
      />
    </svg>
  );
};

SvgTarget.defaultProps = {
  width: 21,
  height: 21,
  color: COLORS.GREY_1,
};

export default SvgTarget;
