import PropTypes from "prop-types";
import React from "react";
import { HighlightCard } from "src/components/common/atoms";

const ToggleCards = (props) => {
  const {
    disabled,
    title,
    cards,
    selected,
    setSelected,
    handleClick,
    selectionType,
  } = props;

  return (
    <div>
      <h2 className="fs-14 semi-bold mb-3">{title}</h2>
      <div className="d-flex space-x-16">
        {cards.map(({ cardTitle, cardDescription }, index) => (
          <div className="w-100">
            <HighlightCard
              key={index}
              index={index}
              disabled={disabled}
              cardTitle={cardTitle}
              cardDescription={cardDescription}
              isSelected={selected === index}
              setSelected={setSelected}
              selectionType={selectionType}
              handleClick={() => handleClick(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

ToggleCards.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      cardTitle: PropTypes.string,
      cardDescription: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.number,
  setSelected: PropTypes.func,
  selectionType: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

ToggleCards.defaultProps = {
  title: "",
  selected: 0,
  setSelected: () => {},
  selectionType: "checkbox",
  handleClick: () => {},
};

export default ToggleCards;
