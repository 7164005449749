import React from "react";
import { GOAL_AGGREGATION_TYPES } from "src/constants";
import { classNames, getPluralText } from "src/helpers";

const MobileAchievementCardRight = (props) => {
  const { usersCount, daysRemaining, goalAggConfig, isAssignedToMeView } =
    props;
  return (
    <>
      {usersCount !== null &&
        goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED &&
        !isAssignedToMeView && (
          <div className="achievement-card-right-users">
            <div className="fs-16 semi-bold">{usersCount}</div>
            <div className="fs-12 fc-white">
              {getPluralText(usersCount, "User", "Users", true)}
            </div>
          </div>
        )}
      {daysRemaining !== null && (
        <div
          className={classNames([
            usersCount !== null &&
            goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED &&
            !isAssignedToMeView
              ? "ml-3"
              : "",
            "achievement-card-right-days",
          ])}
        >
          <div className="fs-16 semi-bold min-w-max">{daysRemaining}</div>
          <div className="fs-12 fc-white">Remaining</div>
        </div>
      )}
    </>
  );
};

export default MobileAchievementCardRight;
