import { gql, useQuery } from "src/api/client";

export const GET_USER_SUB_GOAL_REPORT_QUERY = gql`
  query getUserSubGoalReport(
    $GoalId: ID!
    $UserId: ID!
    $RecurrenceId: ID!
    $Query: JSON
  ) {
    getUserSubGoalReport(
      GoalId: $GoalId
      UserId: $UserId
      RecurrenceId: $RecurrenceId
      Query: $Query
    ) {
      ... on SubGoalUserReportResponse {
        response {
          UserId
          GoalId
          RecurrenceId
          GoalDistribution
          CreatedBy
          ModifiedBy
          IsDelete
          GoalAchieved
          AvgSum
          AvgCount
          IndividualAvgSum
          IndividualAvgCount
          RolledUpAchievement
          ManagerUserId
          IsManager
          StatusCode
          IsRemovedFromHierarchy
          SubGoalsDistributions
        }
      }
    }
  }
`;

export const useGetUserSubGoalReport = (queryOptions) => {
  return useQuery(GET_USER_SUB_GOAL_REPORT_QUERY, queryOptions);
};
