import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgReportees = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l63u3__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l63u3__a)" data-name="User - Normal">
        <path fill="none" d="M0 0h16v16H0z" data-name="Rectangle 2966" />
        <path
          fill={color}
          d="M14.2 12.9a.9.9 0 0 0-.9-.9h-.6a.9.9 0 0 0-.9.9.6.6 0 0 1-.6.6.6.6 0 0 1-.6-.6 2.1 2.1 0 0 1 2.1-2.1h.6a2.1 2.1 0 0 1 2.1 2.1.6.6 0 0 1-.6.6.6.6 0 0 1-.6-.6Zm-9.6 0a.9.9 0 0 0-.9-.9h-.6a.9.9 0 0 0-.9.9.6.6 0 0 1-.6.6.6.6 0 0 1-.6-.6 2.1 2.1 0 0 1 2.1-2.1h.6a2.1 2.1 0 0 1 2.1 2.1.6.6 0 0 1-.6.6.6.6 0 0 1-.6-.6Zm4.8-3a.9.9 0 0 0-.9-.9h-.6a.9.9 0 0 0-.9.9.6.6 0 0 1-.6.6.6.6 0 0 1-.6-.6 2.1 2.1 0 0 1 2.1-2.1h.6a2.1 2.1 0 0 1 2.1 2.1.6.6 0 0 1-.6.6.6.6 0 0 1-.6-.6ZM11 8a2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2Zm1.2 0a.8.8 0 0 0 .8.8.8.8 0 0 0 .8-.8.8.8 0 0 0-.8-.8.8.8 0 0 0-.8.8ZM1.4 8a2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2Zm1.2 0a.8.8 0 0 0 .8.8.8.8 0 0 0 .8-.8.8.8 0 0 0-.8-.8.8.8 0 0 0-.8.8Zm3.6-3a2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2Zm1.2 0a.8.8 0 0 0 .8.8A.8.8 0 0 0 9 5a.8.8 0 0 0-.8-.8.8.8 0 0 0-.8.8Z"
          data-name="Union 77"
        />
      </g>
    </svg>
  );
};

SvgReportees.defaultProps = {
  color: COLORS.GREY_1,
  height: 16,
  width: 16,
};
export default SvgReportees;
