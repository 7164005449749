import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgLateMorning = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      role="img"
      {...rest}
    >
      <path
        id="Color_Off"
        data-name="Color=Off"
        d="M7.571,3.286V2h.857V3.286ZM9.986,3.7l.643-1.113.742.429-.643,1.113ZM8,11A3,3,0,1,0,5,8,3,3,0,0,0,8,11Zm0,.857A3.857,3.857,0,1,0,4.143,8,3.857,3.857,0,0,0,8,11.857Zm4.714-4.286H14v.857H12.714Zm.268-2.943-1.113.643.429.742,1.113-.643ZM4.629,3.018l.643,1.113L6.014,3.7,5.371,2.59Zm-.926,3L2.59,5.371l.429-.742,1.113.643Zm3.868,6.7V14h.857V12.714ZM3.286,7.571H2v.857H3.286Zm-.7,3.057L3.7,9.986l.429.742-1.113.643Zm2.682,1.24-.643,1.113.742.429L6.014,12.3Zm5.357,1.542L9.986,12.3l.742-.429.643,1.113Zm1.24-2.682,1.113.643.429-.742L12.3,9.986Z"
        transform="translate(-2 -2)"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgLateMorning.defaultProps = {
  width: 12,
  height: 12,
  color: COLORS.GREY_2,
};

export default SvgLateMorning;
