import React from "react";
import { COLORS } from "src/constants";
const SvgStandardCondition = (props) => {
  const {
    color = COLORS.PRIMARY_BLUE,
    height = 20,
    width = 20,
    ...rest
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="ouro__a">
          <path d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#ouro__a)">
        <g>
          <path fill="none" d="M.001 0h20v20h-20z" />
          <g fill={color} stroke={color} strokeWidth={0.3}>
            <path d="m17.493 13.835-6.711-5.559A1.266 1.266 0 0 0 8.721 9.42l1.137 8.482a1.267 1.267 0 0 0 .932 1.055 1.283 1.283 0 0 0 .321.043 1.26 1.26 0 0 0 1.021-.518 6 6 0 0 1 4.593-2.4 1.271 1.271 0 0 0 .769-2.242Zm-6.41 4a.086.086 0 0 1-.073-.085L9.873 9.268a.093.093 0 0 1 .054-.108.106.106 0 0 1 .054-.015.116.116 0 0 1 .066.027l6.7 5.559a.105.105 0 0 1-.066.186 7.17 7.17 0 0 0-5.486 2.872.093.093 0 0 1-.112.044Z" />
            <path d="M8.427 4.21h1.16v3.012h-1.16z" />
            <path d="M4.372 8.149h3.012v1.16H4.372z" />
            <path d="M3.285 12.1a.073.073 0 0 1-.073-.073v-8.9a.073.073 0 0 1 .073-.073h10.608a.073.073 0 0 1 .073.073v6.067l1.16.959v-7.03a1.238 1.238 0 0 0-1.233-1.229H3.285a1.232 1.232 0 0 0-1.229 1.229v8.907a1.232 1.232 0 0 0 1.229 1.229h4.682l-.155-1.16Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgStandardCondition;
