import React, { createContext, useState } from "react";

export const GoalContext = createContext();

export const GoalProvider = ({ ...props }) => {
  const [breadcrumbList, setBreadcrumbList] = useState([]);

  return (
    <GoalContext.Provider
      value={{
        setAchievementRollUp: [breadcrumbList, setBreadcrumbList],
      }}
    >
      {props.children}
    </GoalContext.Provider>
  );
};
