import React from "react";

const SvgApproved = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="lz33__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#lz33__a)" data-name="Group 12980">
        <g
          fill={backgroundColor}
          stroke={color}
          strokeWidth={2}
          data-name="Ellipse 193"
        >
          <circle cx={12} cy={12} r={12} stroke="none" />
          <circle cx={12} cy={12} r={11} fill="none" />
        </g>
        <g data-name="Group 3570">
          <path
            fill={color}
            d="m9.693 17.16-3.646-3.646a.154.154 0 0 1 0-.223L7.4 11.94a.154.154 0 0 1 .223 0L9.8 14.123l6.075-6.075a.154.154 0 0 1 .223 0L17.454 9.4a.154.154 0 0 1 0 .223L9.916 17.16a.154.154 0 0 1-.223 0Z"
            data-name="Path 580"
          />
        </g>
      </g>
    </svg>
  );
};

SvgApproved.defaultProps = {
  backgroundColor: "#e8fff3",
  color: "#27ae60",
  height: 24,
  width: 24,
};
export default SvgApproved;
