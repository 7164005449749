import React, { useState } from "react";
import { isEqual } from "src/helpers";
import { ModalContainer } from "../modalContainer";
import FilterAccordion from "./FilterAccordion";

const FilterContainer = (props) => {
  const { show, onHide, options, filter, title, setFilter } = props;
  const [selected, setSelected] = useState(filter);

  const handleOnChange = ({ key, value }) => {
    setSelected((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const onApplyFilterClick = () => {
    setFilter(selected);
    onHide();
  };

  const getFilterValues = () => {
    const defaultFilters = {};
    const selectedFilters = {};
    const appliedFilterValues = {};
    options.forEach((option) => {
      selectedFilters[option.key] = selected[option.key];
      defaultFilters[option.key] = option.defaultValue;
      appliedFilterValues[option.key] = filter[option.key];
    });
    return {
      defaultFilters,
      selectedFilters,
      appliedFilterValues,
    };
  };
  const filterData = getFilterValues();
  const onResetClick = () => {
    setSelected((prevState) => {
      return {
        ...prevState,
        ...filterData.defaultFilters,
      };
    });
  };

  const disableResetButton = isEqual(
    filterData.defaultFilters,
    filterData.selectedFilters
  );

  const disableApplyFilterClick = isEqual(
    filterData.selectedFilters,
    filterData.appliedFilterValues
  );

  return (
    <ModalContainer
      show={show}
      onHide={onHide}
      title={title}
      onApplyFilterClick={onApplyFilterClick}
      disableApplyFilterClick={disableApplyFilterClick}
      onResetClick={onResetClick}
      disableReset={disableResetButton}
    >
      <FilterAccordion
        {...{
          options,
          filter: selected,
          handleOnChange,
        }}
      />
    </ModalContainer>
  );
};

export default FilterContainer;
