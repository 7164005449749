import { gql, useQuery } from "src/api/client";

export const OPPORTUNITY_ENABLED = gql`
  query isOpportunityEnabled {
    isOpportunityEnabled
  }
`;

export const useIsOpportunityEnabled = (queryOptions) => {
  return useQuery(OPPORTUNITY_ENABLED, queryOptions);
};
