import React, { lazy } from "react";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'incentives.web.incentiveCrudPage' */ "./web")
);

export const IncentiveCrudPage = {
  web: WebPage,
  mobile: () => <Redirect to={APPS.INCENTIVES.ROUTES.LANDING.path} />,
  meta: {
    title: "“incentive",
  },
};
