import React from "react";

const BannerCardListItem = (props) => {
  const { title, description, rightContent } = props;
  return (
    <div className="w-100 flex-between pb-3 border-bottom-1 bc-grey3">
      <div>
        <div className="fs-14 semi-bold">{title}</div>
        <div className="fs-13 fc-grey1">{description}</div>
      </div>
      <div className="fs-18 semi-bold">{rightContent}</div>
    </div>
  );
};

export default BannerCardListItem;
