import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgCondition = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M13 16a1 1 0 0 1-1-1v-1H8a1 1 0 0 1-1-1V9H4V7h3V3a1 1 0 0 1 1-1h4V1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V4H9v8h3v-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1ZM1 12a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1Z"
        data-name="Union 72"
      />
    </svg>
  );
};

SvgCondition.defaultProps = {
  color: COLORS.GREY_1,
  height: 16,
  width: 16,
};
export default SvgCondition;
