import React from "react";
import {
  AmountLabel,
  BaseCard,
  RewardIcon,
  Truncate,
} from "src/components/common/atoms";
import { capitalize } from "src/helpers";
import "./rewardsFlatlistCard.scss";

const RewardsFlatlistCard = (props) => {
  const { reward } = props;

  return (
    <BaseCard className="p-3 position-relative">
      <div className="center pb-3 border-b">
        <div>
          {(reward?.CustomRewardImageUrl || reward?.RewardCategory) && (
            <RewardIcon
              src={reward?.CustomRewardImageUrl}
              rewardCategory={reward?.RewardCategory}
              width={54}
              height={54}
              className="mr-3"
            />
          )}
        </div>
        <div>
          <div className="fs-14 semi-bold">
            <Truncate
              text={reward.RewardName}
              maxWidth="250px"
              tooltipPlacement="bottom-start"
            />
          </div>
          <div className="fs-12 fc-grey">
            <Truncate
              text={reward.Description}
              maxWidth="250px"
              tooltipPlacement="bottom-start"
            />
          </div>
        </div>
      </div>
      <div className="flex-between mt-2">
        <div className="bg-secondary-blue br-4 fs-12 semi-bold px-2 fc-blue">
          {capitalize(reward.RewardCategory)}
        </div>
        <div className="fs-12 semi-bold">
          <AmountLabel amount={reward.RewardValue} />
        </div>
      </div>
      {reward.RewardsCount > 1 && (
        <div className="px-2 fs-12 semi-bold rewards-flat-count">
          x{reward.RewardsCount}
        </div>
      )}
    </BaseCard>
  );
};

export default RewardsFlatlistCard;
