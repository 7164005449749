import { t } from "i18next";
import { Modal } from "react-bootstrap";
import {
  ConditionValue,
  formatAmount,
  FormButton,
  SlabIndex,
  StatsItem,
  Truncate,
} from "src/components";
import { REWARD_TYPE } from "src/constants";
import { classNames, getPercentage } from "src/helpers";
import { SvgApproved } from "src/icons";
import "./slabRewardSummaryModal.scss";

function SlabRewardSummaryModal({
  show,
  handleClose,
  title,
  templateName,
  templateType,
  totalSlabs,
  slabs,
  rewardType,
  slabsAchieved,
  achievedValue,
  totalReward,
  achievement = false,
  goalTarget,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="slab-reward-modal-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-18 semi-bold">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div
          className={classNames([
            "mb-30",
            achievedValue ? "flex-between" : "flex",
          ])}
        >
          <StatsItem title={templateType} className="w-120">
            <div className="fs-13 semi-bold mt-1">
              <Truncate maxWidth="120px" text={templateName} />
            </div>
          </StatsItem>
          <StatsItem
            title={
              slabsAchieved
                ? t("INCENTIVES.PROGRAM.LABELS.SLABS_ACHIEVED")
                : t("INCENTIVES.PROGRAM.LABELS.SLABS")
            }
            className="ml-3 w-120"
          >
            <span className="fs-13 semi-bold">
              {achievement ? `${slabsAchieved || 0}/${totalSlabs}` : totalSlabs}
            </span>
          </StatsItem>
          {achievement ? (
            <StatsItem
              title={t("MANAGED_GOALS.LABELS.ACHIEVEMENT")}
              className="ml-3 w-120"
            >
              <span className="fs-13 semi-bold">
                {achievedValue === null || achievedValue === undefined ? (
                  "-"
                ) : (
                  <ConditionValue value={formatAmount(achievedValue)} />
                )}
              </span>
            </StatsItem>
          ) : null}
          {achievement && goalTarget ? (
            <StatsItem
              title={t("MANAGED_GOALS.LABELS.ACHIEVED")}
              className="ml-3 w-120"
            >
              <span className="fs-13 semi-bold">
                {achievedValue === null ||
                achievedValue === undefined ||
                goalTarget === null ||
                goalTarget === undefined ? (
                  "-"
                ) : (
                  <ConditionValue
                    includePercentage
                    value={getPercentage(achievedValue, goalTarget)}
                  />
                )}
              </span>
            </StatsItem>
          ) : null}
          {achievement ? (
            <StatsItem
              title={t("INCENTIVES.PROGRAM.LABELS.TOTAL_REWARD")}
              className="ml-3 w-120"
            >
              <span className="fs-13 semi-bold">
                {totalReward === null || totalReward === undefined ? (
                  "-"
                ) : (
                  <ConditionValue value={totalReward} />
                )}
              </span>
            </StatsItem>
          ) : null}
        </div>
        <div className="slab-summary">
          <div className="bg-grey p-3 center h-40 fs-12 semi-bold fc-grey">
            <div className="w-35">{t("INCENTIVES.PROGRAM.LABELS.SLAB")}</div>
            <div className="w-140 ml-4">
              {t("INCENTIVES.PROGRAM.LABELS.SLAB_RANGE")}
            </div>
            <div className="w-120 ml-auto">
              {rewardType === REWARD_TYPE.PERCENTAGE_SLAB.type
                ? t("INCENTIVES.PROGRAM.LABELS.PERCENTAGE")
                : null}
              {rewardType === REWARD_TYPE.MULTIPLIER_SLAB.type
                ? t("INCENTIVES.PROGRAM.LABELS.MULTIPLIER")
                : null}
            </div>
            {achievement ? (
              <div className="w-120">
                {t("INCENTIVES.PROGRAM.LABELS.ACTUAL_REWARD")}
              </div>
            ) : null}
          </div>
          <div className="body fs-13 pt-0">
            {slabs.map((slab, slabIndex) => {
              return (
                <>
                  <div
                    className={classNames([
                      "center p-3",
                      achievement
                        ? slab.IsSlabAchieved
                          ? "achieved"
                          : ""
                        : "summary",
                    ])}
                  >
                    <div className="w-35">
                      <SlabIndex value={slabIndex + 1} />
                    </div>
                    <div className="summary-item w-140 ml-4">
                      {slab.MinValue} - {slab.MaxValue} {goalTarget ? "%" : ""}
                    </div>
                    <div className="summary-item w-120 ml-auto">
                      <ConditionValue value={slab.RewardWeightage} />
                      {rewardType === REWARD_TYPE.PERCENTAGE_SLAB.type
                        ? "%"
                        : ""}
                    </div>
                    {achievement ? (
                      <div className="summary-item w-120 flex-between semi-bold">
                        {slab.RewardAchieved ? (
                          <ConditionValue value={slab.RewardAchieved} />
                        ) : (
                          "-"
                        )}
                        {slab.IsSlabAchieved ? (
                          <SvgApproved width="16px" height="16px" />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <hr className="w-100" />
                </>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton onClick={handleClose} label={t("COMMON.BUTTONS.CLOSE")} />
      </Modal.Footer>
    </Modal>
  );
}

export default SlabRewardSummaryModal;
