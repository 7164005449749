import { gql, useQuery } from "src/api/client";

const GET_USER_METRIC_ACHIEVEMENTS = gql`
  query userMetricAchievements(
    $ProgramId: ID!
    $RecurrenceId: ID!
    $UserId: ID!
    $Value: String
    $FieldName: String
    $Type: String
    $GoalAchievementType: String
    $ReporteesIncentiveValue: Float
    $SubGoal: SubGoalInput
    $Operand: CriteriaOperandEnum!
  ) {
    userMetricAchievements(
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      UserId: $UserId
      Value: $Value
      FieldName: $FieldName
      Type: $Type
      GoalAchievementType: $GoalAchievementType
      ReporteesIncentiveValue: $ReporteesIncentiveValue
      SubGoal: $SubGoal
      Operand: $Operand
    ) {
      ... on ErrorResponse {
        message
        __typename
      }
      ... on UserMetricAchievementsResponse {
        success
        message
        response {
          userMetricAchievement
          __typename
        }
      }
    }
  }
`;

export const useGetUserMetricAchievements = (queryOptions) => {
  return useQuery(GET_USER_METRIC_ACHIEVEMENTS, queryOptions);
};
