import PropTypes from "prop-types";
import React from "react";
import "./mobileFilterBar.scss";
import MobileFilterBarFilters from "./MobileFilterBarFilters";
import MobileFilterBarIcons from "./MobileFilterBarIcons";

const MobileFilterBarProps = {
  sectionIcon: PropTypes.node,
  sectionTitle: PropTypes.string,
  filtersToggleState: PropTypes.object,
  filtersState: PropTypes.object,
  filters: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  toggleFilter: PropTypes.func,
};
const defaultMobileFilterBarProps = {
  sectionIcon: null,
  sectionTitle: null,
  filtersToggleState: {},
  filtersState: {},
  filters: [],
  handleChange: () => {},
  toggleFilter: () => {},
};

const MobileFilterBar = (props) => {
  const {
    sectionIcon,
    sectionTitle,
    filtersToggleState,
    filtersState,
    filters,
    handleChange,
    handleBulkUpdate,
    toggleFilter,
  } = props;

  return (
    <>
      <div className="center">
        {sectionIcon || sectionTitle ? (
          <div className="center">
            {sectionIcon && <div>{sectionIcon}</div>}
            {sectionTitle && (
              <div className="ml-2 semi-bold">{sectionTitle}</div>
            )}
          </div>
        ) : null}
        <div className="ml-auto">
          <MobileFilterBarIcons
            {...{ filtersToggleState, filtersState, filters, toggleFilter }}
          />
        </div>
      </div>
      <div className="mt-2">
        <MobileFilterBarFilters
          {...{
            filtersToggleState,
            filtersState,
            filters,
            handleChange,
            handleBulkUpdate,
            toggleFilter,
          }}
        />
      </div>
    </>
  );
};

MobileFilterBar.propTypes = MobileFilterBarProps;
MobileFilterBar.defaultProps = defaultMobileFilterBarProps;

export default MobileFilterBar;
