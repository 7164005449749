import React from "react";

const SvgMailIcon = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M17.588 3.568C16.58 1.412 14.404.092 12.058.006A1.017 1.017 0 0 0 11.955 0H7.13c-2.17 0-4.2.469-5.662 2.222C.231 3.708 0 5.423 0 7.272v10.097a.84.84 0 0 0 .827.827h16.539a.839.839 0 0 0 .827-.827v-6.632c0-2.27.384-5.052-.605-7.169Zm-15.6.938c.852-2.087 2.813-2.849 4.91-2.849h5.057c1.922.04 3.678 1.21 4.324 3.053.294.84.26 1.693.26 2.565v.998H1.653c-.01-1.236-.138-2.61.335-3.767Zm5.382 5.42h3.45v1.548H7.37V9.927Zm-4.462 6.616H1.654V9.926h4.062v2.375a.84.84 0 0 0 .827.83h5.106a.828.828 0 0 0 .827-.827V9.926h4.063v6.616H2.91Z"
      />
    </svg>
  );
};
SvgMailIcon.defaultProps = {
  color: "#309AFC",
  height: "19px",
  width: "19px",
};
export default SvgMailIcon;
