import { range } from "src/helpers";
import { Shimmer } from "../shimmer";

const LoadingTimeline = () => {
  return (
    <>
      {range(0, 1).map((i) => {
        return (
          <div
            key={i}
            style={{ position: "relative", opacity: i % 2 === 0 ? 0.4 : 1 }}
          >
            <div className="d-flex br-4">
              <div className="d-flex flex-column align-items-center">
                <Shimmer
                  width="24px"
                  height="26.58px"
                  className="rounded-circle"
                />
                <div className="timeline-divider" />
              </div>
              <div className="ml-3 w-100 timeline-data-container">
                <div className="flex-between mb-12">
                  <Shimmer width="113px" height="22px" />
                  <Shimmer width="91px" height="20px" />
                </div>
                <Shimmer className="mb-3" height="45px" width="100%" />
                <Shimmer className="mb-12" height="21px" width="224px" />
                <Shimmer className="mb-12" height="80px" width="100%" />
                <Shimmer className="mb-12" height="16px" width="100px" />
                <hr className="mb-3" />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default LoadingTimeline;
