import { gql, useQuery } from "src/api/client";

const GET_PAYOUT_PROGRAMS_QUERY = gql`
  query payoutPrograms(
    $limit: Float
    $query: JSON
    $sortBy: JSON
    $after: String
    $FeatureType: String!
  ) {
    payoutPrograms(
      limit: $limit
      query: $query
      sortBy: $sortBy
      after: $after
      FeatureType: $FeatureType
    ) {
      ... on PayoutProgramsResponse {
        success
        message
        response {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          payoutPrograms {
            programId
            name
            recurrencePeriod
            pendingRequests
            totalCycles
            pendingPayoutAmount
            PendingPayoutRewards {
              RewardCount
              RewardCategory
            }
          }
        }
      }
    }
  }
`;

export const useGetPayoutPrograms = (queryOptions) => {
  return useQuery(GET_PAYOUT_PROGRAMS_QUERY, queryOptions);
};
