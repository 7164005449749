import React from "react";
import { classNames } from "src/helpers";

const SvgAlert = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <span
      style={{
        backgroundColor,
        height: backgroundColor ? height : "auto",
        width: backgroundColor ? width : "auto",
        borderRadius: "50%",
        padding: backgroundColor ? "12px" : "0px",
      }}
      className={classNames([
        backgroundColor ? "flex-center" : "",
        "svg-alert-container center",
      ])}
    >
      <svg
        {...rest}
        width={backgroundColor ? Number(width) - 24 : width}
        height={backgroundColor ? Number(height) - 24 : height}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(8.954 9.627)">
          <circle
            cx={7.05}
            cy={6.37}
            r={12.7}
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 2,
            }}
          />
          <path
            d="M5.68 12.12c.01-.76.62-1.38 1.38-1.38.76 0 1.38.62 1.38 1.38s-.62 1.38-1.38 1.38c-.76-.01-1.37-.62-1.38-1.38zm.34-5.06L5.74 2.1 5.65.78C5.61.1 6.05-.53 6.71-.73c.65-.16 1.32.17 1.6.78.09.22.14.46.13.69-.03.7-.09 1.39-.13 2.08-.06 1.07-.13 2.14-.18 3.2-.03.35-.03.66-.03 1.01-.02.56-.48 1-1.04 1-.55.02-1.01-.41-1.04-.97z"
            style={{
              fill: color,
              stroke: color,
              strokeWidth: 0.5442,
            }}
          />
        </g>
      </svg>
    </span>
  );
};

SvgAlert.defaultProps = {
  backgroundColor: "#fff5e5",
  color: "#f39c29",
  height: 18,
  width: 18,
};
export default SvgAlert;
