import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";

const MultiselectDropdown = (props) => {
  const {
    allowSelectAll,
    allOption = {
      DisplayName: "Select all",
      ActivityEvent: "*",
    },
    includeSelectAll,
    options,
    onChange,
  } = props;

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "225px",
      paddingLeft: "1rem",
      paddingTop: "0px !important",
      fontSize: "12px",
      marginLeft: "-4px",
    }),
    menu: (provided) => ({
      ...provided,
      borderTop: "1px solid #309afc",
      borderRadius: "0 4px 4px 4px !important",
      marginTop: "-1px !important",
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "0 4px 4px 0 !important",
      height: "40px",
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "20px",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "#fff !important",
    }),
  };

  const handleChange = (selected, event) => {
    if (
      event.action === "select-option" &&
      (selected[selected.length - 1].ActivityEvent ===
        allOption.ActivityEvent ||
        selected.length === options.length)
    ) {
      return onChange([allOption, ...options], event);
    }

    if (event.action === "deselect-option") {
      if (event.option.ActivityEvent === allOption.ActivityEvent) {
        return onChange([], event);
      }
      const result = selected.filter(
        (option) => option.ActivityEvent !== allOption.ActivityEvent
      );
      return onChange(result, event);
    }

    return onChange(selected, event);
  };

  if (allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={includeSelectAll ? [allOption, ...options] : options}
        styles={props.styles || customStyles}
        onChange={handleChange}
      />
    );
  }

  return <ReactSelect {...props} />;
};

MultiselectDropdown.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    DisplayName: PropTypes.string,
    ActivityEvent: PropTypes.string,
  }),
  includeSelectAll: PropTypes.bool,
};

MultiselectDropdown.defaultProps = {
  includeSelectAll: true,
};

export default MultiselectDropdown;
