import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { AmountLabel, FormField, FormTooltip } from "src/components/common";
import { COLORS } from "src/constants";
import {
  classNames,
  getCycleIntervalString,
  getOrdinal,
  getUserTimezone,
} from "src/helpers";
import { SvgApprovedAmount, SvgInfo } from "src/icons";
import "./pendingPayoutCard.scss";

const Ordinal = ({ number }) => {
  return (
    <span>
      {number}
      <sup>{getOrdinal(number, true)}</sup>
    </span>
  );
};

function HighlightedLabel({ text, errorText, className, children }) {
  return (
    <div className={classNames([className, "highlighted-label"])}>
      <div className="p-3 br-4">
        <label className="fs-13 semi-bold mb-2">{text}</label>
        {children}
        <div className="label-error fs-12 fc-red-warn mt-1">
          {errorText}&nbsp;
        </div>
      </div>
    </div>
  );
}

const PendingPayoutCard = (props) => {
  const {
    RecurrenceId,
    PendingPayout,
    Percentage,
    AvailableForPayout,
    index,
    payoutAmount,
    referenceId,
    handleAmountChange,
    handleReferenceIdChange,
    validateForm,
    RecurrenceIndex,
    RecurrenceStartDate,
    RecurrenceEndDate,
  } = props;

  const isIndianTimezone = getUserTimezone();

  return (
    <div
      className={classNames([
        "br-6 border-left-4 p-3",
        Boolean(payoutAmount?.error) ? "pb-5" : "",
        AvailableForPayout
          ? "bc-primary-blue bg-secondary-blue"
          : "bc-grey2 bg-grey24",
      ])}
    >
      <div
        className={classNames([
          "center space-x-16 pb-3",
          AvailableForPayout ? "border-bottom-1 bc-grey3" : "",
        ])}
      >
        <SvgApprovedAmount width={26} height={25} />
        <div>
          <div>
            <span className="fs-12 semi-bold">
              <Ordinal number={index} />
              <span className="ml-1">
                {t("COMMON.LABELS.SCHEDULED_PENDING_AMOUNT")}
              </span>
            </span>
            {!AvailableForPayout && (
              <FormTooltip
                text={
                  <Trans
                    i18nKey="COMMON.LABELS.PAYOUT_SCHEDULED_FOR"
                    values={{
                      RecurrenceIndex,
                      dateString: getCycleIntervalString(
                        RecurrenceStartDate,
                        RecurrenceEndDate
                      ),
                    }}
                  >
                    This payout is scheduled to
                    <span className="fc-grey semi-bold">
                      #{RecurrenceIndex}:
                    </span>
                    <span className="semi-bold">
                      {getCycleIntervalString(
                        RecurrenceStartDate,
                        RecurrenceEndDate
                      )}
                    </span>
                    cycle. Please come back after the completion of the buffer
                    days of the mentioned cycle to do the payout.
                  </Trans>
                }
                placement="top"
                disable={AvailableForPayout}
              >
                <SvgInfo color={COLORS.GREY_2} backgroundColor="none" />
              </FormTooltip>
            )}
          </div>
          <div className="center space-x-8">
            <span className="fs-16 semi-bold">
              <AmountLabel amount={PendingPayout} />
            </span>
            <span className="fs-12 fc-grey1 font-italic">
              ({Percentage}% of approved incentive)
            </span>
          </div>
        </div>
      </div>
      {Boolean(AvailableForPayout) && (
        <div className="center payout-pending">
          <HighlightedLabel
            className="mr-3 w-50"
            text={t("INCENTIVES.PAYOUT.LABELS.PAYOUT_AMOUNT")}
            errorText={payoutAmount?.error}
          >
            <FormField
              startAdornment={!isIndianTimezone ? "$" : "₹"}
              type="number"
              placeholder={t("INCENTIVES.COMMON.MESSAGES.ENTER_AMOUNT")}
              name="payoutAmount"
              value={payoutAmount?.value}
              error={Boolean(payoutAmount?.error)}
              onInput={(e) => handleAmountChange(e, RecurrenceId)}
              onBlur={() => payoutAmount?.error && validateForm()}
              step="any"
              min={0}
              max={PendingPayout}
              required
            />
          </HighlightedLabel>
          <HighlightedLabel
            className="w-50"
            text={t("INCENTIVES.PAYOUT.LABELS.REFERENCE_ID")}
            errorText={referenceId.error}
          >
            <FormField
              placeholder={t("INCENTIVES.COMMON.MESSAGES.ENTER_ID")}
              name="referenceId"
              value={referenceId.value}
              error={Boolean(referenceId.error)}
              onChange={(e) =>
                !Number.isNaN(payoutAmount.value) &&
                payoutAmount.value !== 0 &&
                handleReferenceIdChange(e, RecurrenceId)
              }
              onBlur={() => referenceId.error && validateForm()}
              maxLength={30}
            />
          </HighlightedLabel>
        </div>
      )}
    </div>
  );
};

export default PendingPayoutCard;
