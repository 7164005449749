import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormInput } from "src/components/common/formElements";
import { checkValue } from "src/helpers";

const slabValuePropTypes = {
  activeSlabIndex: PropTypes.number.isRequired,
  slabIndex: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  setMinValue: PropTypes.func.isRequired,
  setMaxValue: PropTypes.func.isRequired,
  showPercentage: PropTypes.bool,
};

let maxValueFocused = false;
let minValueFocused = false;

const FormTooltip = ({ text, placement, disable, children }) => {
  return (
    <OverlayTrigger
      show={!disable}
      placement={placement}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

export const SlabValue = ({
  activeSlabIndex,
  slabIndex,
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
  options,
  showPercentage,
}) => {
  const maxValueRef = useRef();
  const minValueRef = useRef();
  const handleChange = (event, setter) => {
    const { value } = event.target;
    setter(value);
  };

  const handleMinValueFocus = () => {
    maxValueFocused = false;
    minValueFocused = true;
  };

  const handleMaxValueFocus = () => {
    maxValueFocused = true;
    minValueFocused = false;
  };

  useEffect(() => {
    if (maxValueRef?.current && maxValueFocused) {
      maxValueRef.current.focus();
    }
    if (minValueRef?.current && minValueFocused) {
      minValueRef.current.focus();
    }
  }, [minValue, maxValue]);

  return (
    <div className="slab-value flex">
      <FormTooltip
        placement="bottom"
        disable={!checkValue(minValue) || minValue >= options.minSlabValue}
        text={t("INCENTIVES.PROGRAM.MESSAGES.ADD_SLAB_MIN_VALUE_TOOLTIP", {
          minSlabValue: `${options.minSlabValue}`,
        })}
      >
        <div className="center mr-3">
          <FormInput
            ref={minValueRef}
            type="number"
            placeholder="Min Value"
            value={minValue}
            onChange={(event) => handleChange(event, setMinValue)}
            className="w-140"
            disabled={slabIndex > 0 || activeSlabIndex !== slabIndex}
            onFocus={handleMinValueFocus}
            step="any"
          />
          {showPercentage && <span className="ml-2 fs-14 fc-grey1">%</span>}
        </div>
      </FormTooltip>
      <FormTooltip
        placement="bottom"
        disable={!checkValue(maxValue) || maxValue > minValue}
        text={t("INCENTIVES.PROGRAM.MESSAGES.ADD_SLAB_MAX_VALUE_TOOLTIP")}
      >
        <div className="center">
          <FormInput
            ref={maxValueRef}
            type="number"
            placeholder="Max Value"
            value={maxValue}
            onChange={(event) => handleChange(event, setMaxValue)}
            className="w-140"
            disabled={
              !checkValue(minValue) ||
              minValue < options.minSlabValue ||
              activeSlabIndex !== slabIndex
            }
            onFocus={handleMaxValueFocus}
            step="any"
          />
          {showPercentage && <span className="ml-2 fs-14 fc-grey1">%</span>}
        </div>
      </FormTooltip>
    </div>
  );
};

SlabValue.propTypes = slabValuePropTypes;
