import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { ROLLUP_VALUES } from "src/constants";
import { classNames } from "src/helpers";
import "../tooltip.scss";

const NumFormatTooltip = (props) => {
  const {
    children,
    achieved,
    goalValue,
    isIndianTimezone,
    showOnlyGoalValue,
    showPercentage,
    placement,
    showRollupInfo,
    isRollUp,
    RollupType,
    heading,
  } = props;
  const { t } = useTranslation();
  const renderTooltip = (props) => (
    <Tooltip id="tooltip-bottom" {...props}>
      <div className="flex flex-column flex-wrap min-w-min">
        {heading ? <span className="min-w-150">{heading}</span> : null}
        <div
          className={classNames([
            "min-w-max pb-1",
            showRollupInfo ? "border-bottom-1 bc-grey3" : "",
          ])}
        >
          <p className="d-flex w-100">
            <span className="fc-grey w-65">
              {t("MANAGED_GOALS.LABELS.GOAL_VALUE")}{" "}
            </span>
            <span className="semi-bold">
              {goalValue != null
                ? isIndianTimezone
                  ? goalValue.toLocaleString("en-IN", { currency: "INR" })
                  : goalValue.toLocaleString("en-US", { currency: "USD" })
                : "- -"}
            </span>
          </p>
          {showOnlyGoalValue ? null : (
            <>
              <p className="d-flex w-100">
                <span className="fc-grey w-65">
                  {t("MANAGED_GOALS.MESSAGES.ACHIEVED")}{" "}
                </span>
                <span className="semi-bold">
                  {achieved != null
                    ? isIndianTimezone
                      ? achieved.toLocaleString("en-IN", { currency: "INR" })
                      : achieved.toLocaleString("en-US", { currency: "USD" })
                    : 0}
                </span>
              </p>
              {showPercentage && (
                <p className="d-flex w-100">
                  <span className="fc-grey w-65">
                    {t("MANAGED_GOALS.LABELS.PERCENTAGE")}
                  </span>
                  <span className="semi-bold">
                    {goalValue != null
                      ? goalValue && achieved
                        ? `${parseFloat((achieved / goalValue) * 100).toFixed(
                            2
                          )}%`
                        : "0%"
                      : "- -"}
                  </span>
                </p>
              )}
            </>
          )}
        </div>
        {showRollupInfo && (
          <div className="min-w-200 fc-grey1 mt-1">
            {isRollUp ? (
              RollupType === ROLLUP_VALUES[0] ? (
                <span>
                  <Trans
                    i18nKey="COMMON.MESSAGES.ROLL_UP_SELECTED_USERS_TOOLTIP_TEXT"
                    t={t}
                  >
                    <b>Roll Up : </b>Cumulative achievement of individual and
                    <b>selected users</b> under hierarchy.
                  </Trans>
                </span>
              ) : (
                <span>
                  <Trans
                    i18nKey="COMMON.MESSAGES.ROLL_UP_ALL_USERS_TOOLTIP_TEXT"
                    t={t}
                  >
                    <b>Roll Up : </b>Cumulative achievement of individual and
                    <b>all users</b> under hierarchy.
                  </Trans>
                </span>
              )
            ) : (
              <span>
                <Trans i18nKey="COMMON.MESSAGES.NON_ROLL_UP_TOOLTIP_TEXT" t={t}>
                  <b>Non Roll Up : </b>Achievement is computated based on
                  individual performance.
                </Trans>
              </span>
            )}
          </div>
        )}
      </div>
    </Tooltip>
  );

  return (
    <OverlayTrigger placement={placement} overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  );
};

NumFormatTooltip.propTypes = {
  children: PropTypes.node,
  achieved: PropTypes.number,
  goalValue: PropTypes.number,
  isIndianTimezone: PropTypes.bool,
  showOnlyGoalValue: PropTypes.bool,
  progressBar: PropTypes.bool,
  showRollupInfo: PropTypes.bool,
  isRollUp: PropTypes.bool,
};

NumFormatTooltip.defaultProps = {
  achieved: 0,
  goalValue: 0,
  isIndianTimezone: false,
  showOnlyGoalValue: false,
  progressBar: false,
  showPercentage: true,
  placement: "bottom",
  showRollupInfo: false,
  isRollUp: false,
};

export default NumFormatTooltip;
