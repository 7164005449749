import { lazy } from "react";

const WebPage = lazy(() =>
  import(
    /* webpackChunkName: 'leaderboards.web.dashboardLeaderboardDetailsPage' */ "./web"
  )
);

const MobilePage = lazy(() =>
  import(
    /* webpackChunkName: 'leaderboards.mobile.dashboardLeaderboardDetailsPage' */ "./mobile"
  )
);

export const DashboardLeaderboardDetailsPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "Dashboard Leaderboard Details",
  },
};
