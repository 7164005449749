import { LEADERBOARD_BADGES } from "src/constants/leaderboardBadges";
import { classNames } from "src/helpers";

const getClass = (position) => {
  if (parseInt(position) > 99) return "three-digit";
  if (parseInt(position) > 9) return "two-digit";
  return "one-digit";
};

export const getBadge = ({ position, recurrencePeriod }) => {
  switch (position) {
    case 1: {
      const Component =
        LEADERBOARD_BADGES[recurrencePeriod.toUpperCase()].RANKED.GOLD;
      return <Component className="trophy" />;
    }
    case 2: {
      const Component =
        LEADERBOARD_BADGES[recurrencePeriod.toUpperCase()].RANKED.SILVER;
      return <Component className="trophy" />;
    }
    case 3: {
      const Component =
        LEADERBOARD_BADGES[recurrencePeriod.toUpperCase()].RANKED.BRONZE;
      return <Component className="trophy" />;
    }
    default: {
      const Component =
        LEADERBOARD_BADGES[recurrencePeriod.toUpperCase()].RANKED.GENERIC;
      return (
        <>
          <Component className="trophy" />
          <span className={classNames(["rank rank-white", getClass(position)])}>
            {position}
          </span>
          <span className={classNames(["rank rank-black", getClass(position)])}>
            {position}
          </span>
        </>
      );
    }
  }
};
