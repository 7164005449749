import { gql, useQuery } from "src/api/client";
import { useIsOpenAceUser } from "src/hooks";

const GET_LEADERBOARD_USER_ATTRIBUTES_QUERY = gql`
  query getUserAttributesData {
    getUserAttributesData {
      TotalUserAttributes
      UserAttributes {
        DisplayName
        SchemaName
        CoreField
      }
    }
  }
`;
const GET_LEADERBOARD_OACE_USER_ATTRIBUTES_QUERY = gql`
  query {
    getUserBaseData {
      UserAttributes {
        DisplayName
        DataType
        SchemaName
      }
    }
  }
`;

export const useGetUserAttributes = (queryOptions) => {
  const { isOpenAceUser } = useIsOpenAceUser();

  const query = isOpenAceUser
    ? GET_LEADERBOARD_OACE_USER_ATTRIBUTES_QUERY
    : GET_LEADERBOARD_USER_ATTRIBUTES_QUERY;

  const queryResult = useQuery(query, {
    ...queryOptions,
    skip: queryOptions?.skip,
  });

  const apiName = isOpenAceUser ? "getUserBaseData" : "getUserAttributesData";

  return {
    ...queryResult,
    data: {
      ...queryResult?.data,
      getUserAttributesData: queryResult?.data?.[apiName],
    },
  };
};
