import React from "react";
import { COLORS } from "src/constants/generalConstants";
const SvgGroupBy = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M0 .5A.5.5 0 0 1 .5 0h4.333a.5.5 0 0 1 .5.5v4.333a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5zm6.667 0a.5.5 0 0 1 .5-.5H11.5a.5.5 0 0 1 .5.5v4.333a.5.5 0 0 1-.5.5H7.167a.5.5 0 0 1-.5-.5zM0 7.167a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 .5.5V11.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5zm8.667-.3c0-.11.09-.2.2-.2H9.8c.11 0 .2.09.2.2v1.6c0 .11.09.2.2.2h1.6c.11 0 .2.09.2.2V9.8a.2.2 0 0 1-.2.2h-1.6a.2.2 0 0 0-.2.2v1.6a.2.2 0 0 1-.2.2h-.933a.2.2 0 0 1-.2-.2v-1.6a.2.2 0 0 0-.2-.2h-1.6a.2.2 0 0 1-.2-.2v-.933c0-.11.09-.2.2-.2h1.6a.2.2 0 0 0 .2-.2zM8.2 1.333a.2.2 0 0 0-.2.2V3.8c0 .11.09.2.2.2h2.267a.2.2 0 0 0 .2-.2V1.533a.2.2 0 0 0-.2-.2zm-6.667 0a.2.2 0 0 0-.2.2V3.8c0 .11.09.2.2.2H3.8a.2.2 0 0 0 .2-.2V1.533a.2.2 0 0 0-.2-.2zm0 6.667a.2.2 0 0 0-.2.2v2.267c0 .11.09.2.2.2H3.8a.2.2 0 0 0 .2-.2V8.2a.2.2 0 0 0-.2-.2z"
      />
    </svg>
  );
};
SvgGroupBy.defaultProps = {
  color: COLORS.GREY_2,
  height: 12,
  width: 12,
};
export default SvgGroupBy;
