import { gql, useLazyQuery, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_LEADERBOARD_TAB_VIEW_DETAILS_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderboardDetails($LeaderboardId: ID!) {
    getLeaderboardDetails(LeaderboardId: $LeaderboardId) {
      __typename
      ... on SingleLeaderboardResponse {
        response {
          Leaderboard {
            LeaderboardId
            Name
            Description
            Status
            Type
            IsRollup
            InGaps
            SelectedTemplates {
              TemplateId
              Name
              GoalAggregateFunction
              GoalRules {
                GoalAggregateFunction
              }
              __typename
            }
            RecurrenceDefinition {
              FirstRecurrenceStartDate
              LastRecurrenceEndDate
              RecurrencePeriod
              RecurrenceCycleDuration
              CustomType
              CustomGap
              Recurrences {
                RecurrenceStartDate
                RecurrenceEndDate
              }
            }
            CurrentRecurrenceCycle
            RecurrenceTimeLeft
            RecurrenceDueText
            IsGoalsEnabled
            UsersCount
            RecurrencesCount
            DefaultBufferDays
            BufferDays
            CurrentRecurrenceRange {
              RecurrenceStartDate
              RecurrenceEndDate
              __typename
            }
            FirstRecurrenceRange {
              RecurrenceStartDate
              RecurrenceEndDate
              __typename
            }
            LastRecurrenceRange {
              RecurrenceStartDate
              RecurrenceEndDate
              __typename
            }
            SelectedGoals {
              GoalId
              GoalName
              AggregateFunction
              TemplateId
              Recurrences
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetLeaderboardTabViewDetails = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_TAB_VIEW_DETAILS_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};

const GET_LEADERBOARD_RECURRENCES_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getRecurrenceSummary($LeaderboardId: ID!) {
    getRecurrenceSummary(LeaderboardId: $LeaderboardId) {
      __typename
      ... on RecurrenceSummaryResponse {
        success
        message
        response {
          LeaderboardId
          RecurrenceId
          RecurrenceStartDate
          RecurrenceEndDate
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetLeaderboardRecurrences = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_RECURRENCES_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};

const GET_LEADERBOARD_CRITERIA_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderboardCriteria($LeaderboardId: ID!, $RecurrenceId: ID!) {
    getLeaderboardCriteria(
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
    ) {
      ... on GetLeaderboardCriteriaResponse {
        response
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetLeaderboardCriteria = (queryOptions) => {
  return useLazyQuery(GET_LEADERBOARD_CRITERIA_QUERY, queryOptions);
};
