import { classNames } from "src/helpers";
import { getBadge } from "./data";
import "./leaderboardBadge.scss";

const LeaderboardBadge = (props) => {
  const { position, recurrencePeriod, className } = props;

  if (!position || !recurrencePeriod) return null;

  return (
    <span className={classNames(["leaderboard-badge", className])}>
      {getBadge({ position, recurrencePeriod })}
    </span>
  );
};

export default LeaderboardBadge;
