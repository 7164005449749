import * as React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgMorning = (props) => {
  const { width, height, color, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14.643 7.844"
      role="img"
      {...rest}
    >
      <path
        id="Color_Off"
        data-name="Color=Off"
        d="M8.8,3.569V2H9.844V3.569Zm2.946.509.784-1.359.906.523L12.65,4.6Zm1.237,5.243a3.516,3.516,0,0,0-3.66-3.749A3.557,3.557,0,0,0,5.66,9.321v-.3s7.315-.033,7.321,0C12.969,9.041,12.981,9.563,12.981,9.321Zm1.046,0a4.706,4.706,0,0,0-9.413,0,1.137,1.137,0,0,0,.111.505c0-.025,9.175.009,9.171,0A.818.818,0,0,0,14.027,9.321ZM15.073,8.8h1.569V9.844H15.073Zm.326-3.59-1.359.784.523.906,1.359-.784ZM5.208,3.242,5.992,4.6,6.9,4.078,6.113,2.719ZM4.078,6.9,2.719,6.113l.523-.906L4.6,5.992ZM3.569,8.8H2V9.844H3.569Z"
        transform="translate(-2 -2)"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgMorning.defaultProps = {
  width: 14,
  height: 8,
  color: COLORS.GREY_2,
};

export default SvgMorning;
