import React from "react";

const SvgAvatar31 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="cl9d__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .cl9d__cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:3px}.cl9d__cls-3{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="cl9d__Layer_1-2">
        <path
          d="M162.8 81.4c0 18.46-6.14 35.47-16.5 49.12-.64.85-1.3 1.68-1.99 2.5-1.46 1.79-3 3.52-4.62 5.18-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6a80.875 80.875 0 0 1-5.34-6.06c-.66-.82-1.31-1.66-1.94-2.52C5.87 116.12 0 99.45 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="cl9d__cls-3"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M146.3 130.52c-.64.85-1.3 1.68-1.99 2.5-2.5-4.3-5.89-8.11-9.99-11.12-7.6-5.57-17.53-7.77-27.25-6.02l-25.92 4.66-26.78-4.7c-9.67-1.7-19.55.5-27.12 6.04a36.766 36.766 0 0 0-9.49 10.26c-.66-.82-1.31-1.66-1.94-2.52a39.5 39.5 0 0 1 9.66-10.16c8.22-6.01 18.94-8.41 29.4-6.58l26.26 4.61 25.4-4.56c10.52-1.89 21.29.5 29.56 6.55 4.12 3.02 7.57 6.79 10.2 11.04Z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="cl9d__cls-3"
        />
        <path
          d="m57.06 133.36 8.26-8.25H96l8.49 8.49-23.11 23.11-24.32-23.35z"
          className="cl9d__cls-1"
        />
        <path
          d="m70.01 145.79 7.55-20.68h7.15l7.58 20.68M73.18 137.1h15.93"
          className="cl9d__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar31.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar31;
