import { useFilePicker } from "./useFilePicker";

export const useImagePicker = (initialState) => {
  initialState.getFilesFromEvent = async (event) => {
    const files = event.target.files || event.dataTransfer.files;
    const promises = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const promise = new Promise((resolve, reject) => {
        if (initialState.accept?.split(",").includes(file.type)) {
          const image = new Image();
          let url;
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        } else {
          resolve(file);
        }
      });
      promises.push(promise);
    }
    return await Promise.all(promises);
  };

  const filePicker = useFilePicker(initialState);
  return filePicker;
};
