import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_LEADERBOARD_RULE = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderboardRule($LeaderboardId: ID!, $RecurrenceId: ID!) {
    getLeaderboardRule(
      LeaderboardId: $LeaderboardId
      RecurrenceId: $RecurrenceId
    ) {
      ... on GetLeaderboardRuleResponse {
        success
        message
        response
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useGetLeaderboardRule = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_RULE, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
