import { t } from "i18next";
import React, { useState } from "react";
import {
  FormButton,
  NonMonetaryLabel,
  RewardsDetailModal,
  Shimmer,
} from "src/components";
import "./dashboardHeroCard.scss";

const DashboardHeroCard = ({
  icon,
  label,
  amount,
  loading,
  rewardsList,
  payloadForRewardsModal,
}) => {
  const [showRewardGridModal, setShowRewardGridModal] = useState(false);

  if (loading)
    return (
      <div className="dashboard-hero-card-shimmer">
        <Shimmer height="100%" width="100%" />
      </div>
    );

  return (
    <div className="dashboard-hero-card mr-2 d-flex flex-column align-items-center">
      <div className="mt-4 flex-center dashabord-hero-card-icon">{icon}</div>
      <div className="fs-13 fc-white">{t(label)}</div>
      <FormButton
        onClick={() => rewardsList?.length > 0 && setShowRewardGridModal(true)}
        variant="link"
      >
        <NonMonetaryLabel
          amount={amount}
          nonMonetaryRewards={rewardsList}
          rewardCategoryList
          className="fc-white fs-16 semi-bold"
          nonMonetaryClassName="fc-white semi-bold"
          hideTooltip
        />
      </FormButton>
      {showRewardGridModal && (
        <RewardsDetailModal
          show={showRewardGridModal}
          handleClose={() => setShowRewardGridModal(false)}
          payload={payloadForRewardsModal}
        />
      )}
    </div>
  );
};

export default DashboardHeroCard;
