import React from "react";

const SvgAvatar32 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="fvc4__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .fvc4__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="fvc4__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="fvc4__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="fvc4__cls-2"
        />
        <path
          d="M70.24 33.39c-.16-.91.34-1.62 1.25-1.79l6.06-1.08c.91-.16 1.62.34 1.79 1.25s-.34 1.62-1.25 1.79l-6.06 1.08c-.91.16-1.62-.34-1.79-1.25Zm44.61 31.16 1.29 7.27c.13.76-.21 1.44-.94 1.73-6.3 2.37-12.86 4.16-19.68 5.37-9.24 1.64-18.53 2.19-27.09 1.52-.15.03-24.96 10.84-24.96 10.84-1.31.55-2.8.97-4.16 1.21-7.88 1.4-16.29-1.95-20.77-8.66-.51-.94-.18-2.16.84-2.34 12.88-2.29 23.05-7.53 25.47-8.9C44.41 55.16 57 38.86 73.82 35.87c20.24-3.59 37.48 8.61 41.04 28.67Zm-66.94 8.6c5.33 1.87 11.22 3.32 17.58 3.91l-2.04-11.52c-1.61-9.09.7-18.1 6.14-25.32-13.08 4.67-22.38 18.35-21.67 32.92Zm14.32 6.68c-5.93-.82-11.49-2.18-16.37-4.13l-.15.03c-3.15 1.65-11.91 6.02-23.34 8.52 4.84 5.24 13.01 7.23 19.71 4.48l20.15-8.89Zm32.75-3.94c3.64-.65 7.1-1.42 10.53-2.34l-.19-1.06c-.78-4.39 1.73-8.75 5.84-10.26-4.35-16.58-19.03-26.17-36.36-23.41A29.602 29.602 0 0 0 66.47 65l2.18 12.28c8.41.7 17.39.2 26.33-1.39Zm17.92-4.74-1.05-5.91c-2.47 1.06-4.01 3.84-3.52 6.57l.13.76c1.49-.42 2.98-.84 4.44-1.41Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar32.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar32;
