import { useReducer } from "react";
import { checkValue, isBefore } from "src/helpers";
import { FILTER_DATA_TYPES, OPERATORS } from "../data";

const INITIAL_STATE = {
  Attribute: null,
  Operator: null,
  SubOperator: null,
  Value: null,
};

const ACTIONS = {
  SET_ATTRIBUTE: "SET_ATTRIBUTE",
  SET_OPERATOR: "SET_OPERATOR",
  SET_SUB_OPERATOR: "SET_SUB_OPERATOR",
  SET_VALUE: "SET_VALUE",
};

const conditionReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_ATTRIBUTE: {
      const { Attribute } = payload;

      if (state.Attribute?.SchemaName === Attribute?.SchemaName) {
        return state;
      }

      return {
        ...state,
        Attribute,
        Operator: null,
        SubOperator: null,
        Value: null,
      };
    }
    case ACTIONS.SET_OPERATOR: {
      const { Operator } = payload;
      if (state?.Operator?.value === Operator?.value) {
        return state;
      }
      return {
        ...state,
        Operator,
        SubOperator: null,
        Value: null,
      };
    }
    case ACTIONS.SET_SUB_OPERATOR: {
      const { SubOperator } = payload;
      if (state?.SubOperator?.value === SubOperator?.value) {
        return state;
      }

      return {
        ...state,
        SubOperator,
        Value: null,
      };
    }
    case ACTIONS.SET_VALUE: {
      const { Value } = payload;
      return {
        ...state,
        Value,
      };
    }

    default:
      return state;
  }
};

const useConditionReducer = ({ initialState }) => {
  const [condition, dispatch] = useReducer(conditionReducer, {
    ...INITIAL_STATE,
    ...initialState,
  });

  const setAttribute = (Attribute) => {
    dispatch({ type: ACTIONS.SET_ATTRIBUTE, payload: { Attribute } });
  };

  const setOperator = (Operator) => {
    dispatch({ type: ACTIONS.SET_OPERATOR, payload: { Operator } });
  };

  const setSubOperator = (SubOperator) => {
    dispatch({ type: ACTIONS.SET_SUB_OPERATOR, payload: { SubOperator } });
  };

  const setValue = (Value) => {
    dispatch({ type: ACTIONS.SET_VALUE, payload: { Value } });
  };

  const isOperatorFieldAllowed = () => {
    return Boolean(condition?.Attribute?.SchemaName);
  };

  const isSubOperatorFieldAllowed = () => {
    return condition?.Operator;
  };

  const isConditionValid = () => {
    if (!condition?.Attribute?.DataType) return false;
    switch (condition?.Attribute?.DataType) {
      case FILTER_DATA_TYPES.STRING: {
        if (
          [OPERATORS.IS_EMPTY, OPERATORS.IS_NOT_EMPTY].includes(
            condition?.Operator?.value
          )
        ) {
          return Boolean(
            condition?.Attribute?.SchemaName && condition?.Operator?.value
          );
        }
        return Boolean(
          condition?.Attribute?.SchemaName &&
            condition?.Operator &&
            condition?.Value
        );
      }

      case FILTER_DATA_TYPES.NUMBER: {
        return checkValue(condition?.Value)
          ? typeof condition?.Value === "object"
            ? Boolean(
                checkValue(condition?.Value?.StartValue) &&
                  condition?.Value?.EndValue &&
                  condition?.Value?.StartValue < condition?.Value?.EndValue
              )
            : true
          : false;
      }

      case FILTER_DATA_TYPES.DATE: {
        if (Boolean(condition?.SubOperator) && !condition?.SubOperator?.field)
          return condition?.SubOperator.value;
        return checkValue(condition?.Value)
          ? typeof condition?.Value === "object"
            ? condition?.Value?.StartValue &&
              condition?.Value?.EndValue &&
              isBefore(
                new Date(condition?.Value?.StartValue),
                new Date(condition?.Value?.EndValue)
              )
            : true
          : false;
      }

      case FILTER_DATA_TYPES.BOOLEAN: {
        return Boolean(condition?.Operator?.value);
      }

      default:
        return false;
    }
  };

  return {
    condition,
    actions: {
      setAttribute,
      setOperator,
      setSubOperator,
      setValue,
    },
    validations: {
      isOperatorFieldAllowed,
      isSubOperatorFieldAllowed,
      isConditionValid,
    },
  };
};

export default useConditionReducer;
