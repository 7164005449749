import React from "react";

const SvgAvatar19 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="bmjz__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .bmjz__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="bmjz__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.71-.68-1.41-1.38-2.1-2.08l-.05-.05C8.78 123.47 0 103.48 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="bmjz__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="bmjz__cls-2"
        />
        <path
          d="M126.84 52.07V40.08h3.17c.72 0 1.3-.58 1.3-1.3s-.58-1.3-1.3-1.3h-5.47V0h-4v37.48H115.07c-.72 0-1.3.58-1.3 1.3s.58 1.3 1.3 1.3h3.17v11.99c-9.12 1.97-15.99 10.1-15.99 19.82v2.76c0 .72.58 1.3 1.3 1.3h5.76v10.62c0 .02.01.04.01.07.15 7.17 6.01 12.96 13.22 12.96s13.03-5.77 13.2-12.93c0-.04.02-.07.02-.11 0-.02-.01-.04-.01-.06V75.94h5.77c.72 0 1.3-.58 1.3-1.3v-2.76c0-9.71-6.86-17.83-15.99-19.81Zm-2.59-11.99v11.59c-1.13-.09-2.26-.1-3.41 0V40.08h3.41ZM122.54 97c-5.35 0-9.77-3.98-10.51-9.14h16.34c.72 0 1.3-.58 1.3-1.3s-.58-1.3-1.3-1.3h-16.46v-4.85h16.46c.72 0 1.3-.58 1.3-1.3s-.58-1.3-1.3-1.3h-16.46v-4.85h16.46c.72 0 1.3-.58 1.3-1.3s-.58-1.3-1.3-1.3h-16.34c.09-.61.22-1.21.41-1.79 1.4-4.25 5.43-7.35 10.11-7.35 5.32 0 9.78 4 10.52 9.14h-5.19c-.72 0-1.3.58-1.3 1.3s.58 1.3 1.3 1.3h5.3v4.85h-5.3c-.72 0-1.3.58-1.3 1.3s.58 1.3 1.3 1.3h5.3v4.85h-5.3c-.72 0-1.3.58-1.3 1.3s.58 1.3 1.3 1.3h5.18c-.74 5.16-5.16 9.14-10.52 9.14Zm17.7-23.65h-4.47v-1.69c-.13-7.17-6.06-13.01-13.23-13.01-4.92 0-9.27 2.76-11.54 6.82a13.21 13.21 0 0 0-1.68 6.21v1.67h-4.46v-1.46c0-9.06 6.84-16.53 15.63-17.56.13-.02.25-.03.38-.05.56-.05 1.12-.09 1.68-.09s1.12.03 1.67.08c.14.01.29.03.43.05 8.77 1.04 15.6 8.51 15.6 17.56v1.46Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar19.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar19;
