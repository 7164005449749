import { gql, useLazyQuery, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_USERS_REPORT_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getUserReport(
    $GoalId: ID!
    $RecurrenceId: ID
    $IsRecurrenceBased: Boolean
    $Sort: GoalContributionSort
    $UserId: ID
    $Limit: Float
    $After: String
    $Query: userReportQueryInput
    $userAttributes: [String]
  ) {
    getUserReport(
      GoalId: $GoalId
      RecurrenceId: $RecurrenceId
      IsRecurrenceBased: $IsRecurrenceBased
      Sort: $Sort
      UserId: $UserId
      Limit: $Limit
      After: $After
      Query: $Query
      userAttributes: $userAttributes
    ) {
      ... on UserReportResponse {
        message
        success
        response {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          userReport
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetUsersReport = (queryOptions) => {
  return useQuery(GET_USERS_REPORT_QUERY, queryOptions);
};

export const useGetLazyUsersReport = (queryOptions) => {
  return useLazyQuery(GET_USERS_REPORT_QUERY, queryOptions);
};
