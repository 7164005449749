import React from "react";

const SvgAvatar13 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="a1hy__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .a1hy__cls-1{fill:#fff}.a1hy__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="a1hy__Layer_1-2">
        <path
          d="M162.8 81.27v.13c0 6.8-.84 13.41-2.42 19.73-.25 1.05-.54 2.1-.85 3.13-3.76 12.91-10.65 24.49-19.79 33.88-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-9.15 8.74-20.34 15.38-32.8 19.1-1.09.33-2.2.64-3.32.91-1.56.41-3.13.76-4.73 1.06-1.04.2-2.1.38-3.16.53-2.29.35-4.62.6-6.97.74-1.23.09-2.48.14-3.73.16-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-1.24-.02-2.48-.07-3.71-.16-2.36-.14-4.69-.39-6.98-.74-1.08-.15-2.14-.33-3.2-.54-1.58-.3-3.15-.64-4.69-1.04-1.11-.28-2.22-.58-3.31-.91a81.306 81.306 0 0 1-32.83-19.11c-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4c0-.98.01-1.96.06-2.93 0-.17 0-.33.02-.5.02-.79.07-1.57.13-2.34.01-.17.02-.33.04-.5.54-7.31 2.06-14.35 4.43-20.99.07-.2.14-.39.21-.59.32-.88.65-1.75 1.01-2.61.07-.17.14-.35.22-.52C18.29 20.82 47.41 0 81.4 0c43.64 0 79.25 34.33 81.3 77.46.07 1.26.1 2.53.1 3.81Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.77}
          className="a1hy__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.085)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="a1hy__cls-2"
        />
        <path
          d="M127.5 93c0-1.85.65-3.67 1.81-5.12 1.55-1.9 3.85-3 6.32-3 .13 0 .26.02.4.04l.37.04c.56.06 1.1-.2 1.42-.66s.37-1.05.13-1.56c-.5-1.09-.76-2.23-.76-3.39 0-4.48 3.64-8.12 8.12-8.12 4.07 0 7.51 3.07 8.01 7.14a1.549 1.549 0 0 0 1.97 1.31c.9-.25 1.6-.36 2.26-.36 1.95 0 3.81.72 5.25 1.95 0-1.28-.03-2.55-.1-3.81-1.56-.82-3.33-1.27-5.15-1.27-.48 0-.96.04-1.46.11-1.34-4.77-5.71-8.2-10.78-8.2-6.2 0-11.25 5.05-11.25 11.25 0 .84.09 1.67.28 2.48-2.91.33-5.58 1.77-7.46 4.08-1.62 2-2.51 4.52-2.51 7.09 0 6.21 5.05 11.26 11.25 11.26h23.91c.31-1.03.6-2.08.85-3.13h-24.75c-4.48 0-8.13-3.65-8.13-8.13ZM32.14 69.55c0 4.92-4.01 8.92-8.93 8.92H.06c0-.17 0-.33.02-.5.02-.79.07-1.57.13-2.34.01-.17.02-.33.04-.5h22.96c3.07 0 5.58-2.5 5.58-5.58 0-1.29-.45-2.54-1.25-3.52a5.509 5.509 0 0 0-4.33-2.07c-.05 0-.09.01-.14.01l-.39.05c-.06 0-.11.01-.16.01-.65 0-1.24-.39-1.52-.98-.91-1.97-2.89-3.25-5.05-3.25-.45 0-.93.08-1.56.25a1.633 1.633 0 0 1-1.37-.23c-.41-.27-.67-.7-.73-1.18-.35-2.79-2.71-4.9-5.5-4.9-.59 0-1.18.1-1.74.28l-.37.12c.07-.2.14-.39.21-.59.32-.88.65-1.75 1.01-2.61.07-.17.14-.35.22-.52.22-.01.45-.02.67-.02 3.81 0 7.21 2.48 8.42 6.09.25-.03.49-.04.74-.04 3.05 0 5.91 1.59 7.53 4.17 2.61.09 5.02 1.27 6.67 3.29 1.28 1.6 1.99 3.6 1.99 5.64Z"
          className="a1hy__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar13.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar13;
