import React from "react";

const SvgAvatar50 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="v0wi__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .v0wi__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="v0wi__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={43.26}
          className="v0wi__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 76.585)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="v0wi__cls-2"
        />
        <path
          d="M113.33 40.85a1.58 1.58 0 0 0-1.57-1.39c-.06 0-.12 0-.19.01l-14.08 1.66-.57-4.81-2-28.63a3.816 3.816 0 0 0-1.36-2.61c-.67-.55-1.52-.85-2.38-.85-.15 0-.29 0-.44.03l-38.28 4.5c-1 .12-1.93.65-2.55 1.45-.61.8-.88 1.84-.73 2.85l4.69 28.29.57 4.83-14.08 1.66c-.86.1-1.49.89-1.38 1.75l.43 3.62c.74 3.25 3.06 5.83 6.25 6.93l2.28.52 1.53.06c.13.04.27.05.41.05.06 0 .12 0 .19-.01h.1l.57.01v-.09l53.61-6.31v.08l.58-.15.19-.02c.18-.02.36-.07.52-.15l1.51-.39 2.16-1.13c2.78-1.77 4.44-4.79 4.43-8.15l-.44-3.61Zm-70.9 10.89-.11-.93 68.05-8.01.1.87.03 2.07c-.26 1.62-1.38 4.46-5.97 5.37l-.1.02-54.38 6.4h-.13c-.16.01-.31.01-.47.01-4.31 0-5.98-2.41-6.56-3.76l-.47-2.05ZM59.4 11.2l2.12-.33 27.3-3.21 2.32-.27c.14 0 .27.05.38.13.12.1.2.25.22.4l1.88 27.12-36.83 4.33-4.46-26.76c-.01-.15.03-.29.14-.42.1-.13.24-.21.4-.23l6.54-.77Zm6.25 33.67-.39-3.29 28.69-3.38.39 3.29-28.69 3.38Zm-2.41.35-5.62.66-.38-3.29 5.61-.66.39 3.29Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar50.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar50;
