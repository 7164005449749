import { gql, useQuery } from "src/api/client";

const GET_SHOWCASE_LIST = gql`
  query getMyShowcaseList {
    getMyShowcaseList {
      ... on MyShowcaseListResponse {
        success
        response {
          myShowcaseList {
            BadgeType
            Count
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetShowcase = (queryOptions) => {
  return useQuery(GET_SHOWCASE_LIST, queryOptions);
};
