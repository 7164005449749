import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgEmail = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      data-name="Group 11076"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill="none"
        d="M.001 0H14v13.999H.001z"
        data-name="Rectangle 2773"
      />
      <path
        fill={color}
        d="M11.9 1.402H2.1a2.1 2.1 0 0 0-2.1 2.1v7a2.1 2.1 0 0 0 2.1 2.1h9.8a2.1 2.1 0 0 0 2.1-2.1v-7a2.1 2.1 0 0 0-2.1-2.1Zm-9.8 1.4h9.8a.7.7 0 0 1 .7.7L7 6.916 1.4 3.502a.7.7 0 0 1 .7-.7Zm10.5 7.7a.7.7 0 0 1-.7.7H2.1a.7.7 0 0 1-.7-.7v-5.4l5.236 3.2a.7.7 0 0 0 .7 0l5.264-3.2Z"
      />
    </svg>
  );
};

SvgEmail.defaultProps = {
  color: COLORS.GREY_1,
  height: 14,
  width: 14,
};
export default SvgEmail;
