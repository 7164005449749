import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import { capitalize } from "src/helpers";
import { SvgAttachment } from "src/icons";

const CustomisableDropdown = (props) => {
  const {
    options,
    onChange,
    defaultSelection,
    isOptionDisabled,
    formatOptionLabel,
  } = props;
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "100%",
      paddingTop: "0px !important",
      fontSize: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      borderTop: "1px solid #309afc",
      marginTop: "-1px !important",
      width: "100%",
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "20px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black",
      cursor: "pointer",
      backgroundColor: state.isFocused ? "#F5F8FA" : "",
      padding: "12px 16px",
    }),
  };

  const defaultOptionLabel = ({ RewardName, RewardCategory }) => (
    <div className="d-flex w-100 ">
      <div className="pr-2">
        <SvgAttachment />
      </div>
      <div className=" w-100">
        <div className="fs-13 normal">{RewardName}</div>
        <div className="fs-10 fc-grey">{capitalize(RewardCategory)}</div>
      </div>
    </div>
  );

  const customFilter = (option, searchText) => {
    return (
      option.data.RewardName?.toLowerCase().includes(
        searchText.toLowerCase()
      ) ||
      option.data.RewardCategory?.toLowerCase().includes(
        searchText.toLowerCase()
      )
    );
  };

  return (
    <Select
      id="custom-dropdown"
      classNamePrefix="drop-select"
      isOptionDisabled={isOptionDisabled}
      styles={customStyles}
      placeholder="custom rewards"
      value={defaultSelection}
      onChange={(e) => onChange(e)}
      options={options}
      formatOptionLabel={formatOptionLabel || defaultOptionLabel}
      filterOption={customFilter}
    />
  );
};

CustomisableDropdown.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

CustomisableDropdown.defaultProps = {
  options: [],
};

export default CustomisableDropdown;
