import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgDelete = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g fill="none" data-name="Group 375">
        <path d="M0 0h24v24H0Z" data-name="Path 22" />
        <g
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path d="M5 7.111h14" data-name="Path 33" />
          <path
            d="M17.445 7.111V18a1.556 1.556 0 0 1-1.556 1.556H8.112A1.556 1.556 0 0 1 6.556 18V7.111m2.333 0V5.556A1.556 1.556 0 0 1 10.445 4h3.111a1.556 1.556 0 0 1 1.556 1.556v1.555"
            data-name="Path 34"
          />
          <path d="M10.444 11v4.667" data-name="Line 54" />
          <path d="M13.556 11v4.667" data-name="Line 55" />
        </g>
      </g>
    </svg>
  );
};

SvgDelete.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 24,
  width: 24,
};
export default SvgDelete;
