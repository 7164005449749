import React from "react";

const SvgAvatar42 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="rm3z__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .rm3z__cls-1{fill:none}.rm3z__cls-2{fill:#fff}.rm3z__cls-3{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="rm3z__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="rm3z__cls-3"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="rm3z__cls-3"
        />
        <path
          d="m49.09 17.57 2.74-.01-1.68-2.17.83-2.61-2.58.93-2.23-1.6.09 2.74-2.21 1.63 2.63.76.87 2.6 1.54-2.27zM80.9 36.23l-1.96 1.05-30.12 16.07-1.96 1.04 1.91 3.59 34.04-18.16-1.91-3.59z"
          className="rm3z__cls-1"
        />
        <path
          d="m81.74 33.49-3.19 1.7-23.56-16.87-2.58-3.33 1.76-5.51-5.44 1.96-4.7-3.37.18 5.78-4.66 3.43 5.56 1.61 1.33 4 .19 6.31.28 9.21.41 13.44-3.19 1.7 3.82 7.17 37.62-20.07-3.82-7.17ZM52.86 19.58l1.78.99 2.72 1.95 1.65 1.18 2.72 1.95 1.65 1.18 2.72 1.95 1.65 1.18 2.72 1.95 1.65 1.18 4.4 3.15-10.18 5.43-3.82 2.04-6.3 3.36-3.82 2.04-3.12 1.66-.09-2.89-.11-3.48-.18-5.73-.11-3.48-.18-5.74-.11-3.48-.12-3.83 1.74-2.56 2.69-.02Zm-6.18-2.34-2.63-.76 2.21-1.62-.09-2.74 2.23 1.6 2.58-.93-.83 2.61 1.68 2.17-2.74.02-1.54 2.27-.86-2.6Zm.18 37.15 1.96-1.04 30.12-16.07 1.96-1.04 1.91 3.58-34.04 18.16-1.91-3.58ZM95.2 80.29c-.46 0-.83.37-.83.83v4.29c0 .46.37.83.83.83h10.04c1.56 0 2.98.93 3.61 2.36.13.3.43.5.76.5h9.83c.46 0 .83-.37.83-.83v-.8c2.53-.22 10.73-.93 13.95-1.18 12.14-.95 20.14-7.09 21.42-16.42a15.4 15.4 0 0 0-3.02-11.48c-2.51-3.3-6.15-5.42-10.25-5.98-6.88-.93-13.22 3.89-14.16 10.76-.76 5.59 3.17 10.75 8.75 11.51 2.21.3 4.4-.28 6.18-1.63a8.312 8.312 0 0 0 3.22-5.52c.25-1.81-.23-3.61-1.33-5.06a6.835 6.835 0 0 0-4.52-2.64c-3.08-.41-5.93 1.75-6.35 4.83a4.685 4.685 0 0 0 4 5.27 3.89 3.89 0 0 0 2.89-.76c.83-.63 1.37-1.55 1.51-2.58a.831.831 0 0 0-.71-.93.826.826 0 0 0-.93.71c-.08.6-.39 1.12-.87 1.49-.48.36-1.07.52-1.66.44a3.014 3.014 0 0 1-2.58-3.4c.3-2.17 2.31-3.7 4.48-3.41 2.83.39 4.82 3 4.44 5.83a6.644 6.644 0 0 1-2.58 4.42 6.65 6.65 0 0 1-4.95 1.3c-4.68-.64-7.97-4.96-7.33-9.64.81-5.96 6.32-10.15 12.29-9.34 3.67.5 6.92 2.4 9.16 5.34a13.75 13.75 0 0 1 2.7 10.25c-.69 5.06-3.62 9.09-8.26 11.71-.03 0-.07 0-.1.02-4.27 1.59-21.49.84-25.36.65v-.9c0-.46-.37-.83-.83-.83H95.23Zm38.89 4.35c-3.16.25-11.13.94-13.82 1.17v-2.12c2.07.1 7.38.34 12.76.34 2.01 0 4.03-.03 5.89-.12-1.52.34-3.13.6-4.84.73Zm-38.06-2.69h22.58v5.49h-8.49a5.625 5.625 0 0 0-4.89-2.86h-9.21v-2.63Z"
          className="rm3z__cls-2"
        />
      </g>
    </svg>
  );
};
SvgAvatar42.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar42;
