import React from "react";
import { useGetGoalTemplateDetails } from "src/api/useTemplate";
import { TemplateDetailModal, toaster } from "src/components/common";
import { RULESET_TYPES } from "src/constants";
import { errorText } from "src/helpers";

const TemplateDetailModalWrapper = ({ templateId, onHide }) => {
  const {
    data: tempalteData,
    loading: templateDetailsLoading,
    error: templateDetailsError,
  } = useGetGoalTemplateDetails({
    variables: { TemplateId: templateId },
  });

  const templateDetails = tempalteData?.template?.response?.Template || [];

  if (templateDetailsError) {
    toaster({ type: "error", message: errorText.GET_TEMPLATE_DETAIL.ERROR });
    return null;
  }

  return (
    <TemplateDetailModal
      name={templateDetails?.Name}
      goalAggregateFunction={templateDetails?.GoalAggregateFunction}
      description={templateDetails?.Description}
      goalRules={templateDetails?.GoalRules}
      isCustomFormula={templateDetails?.IsCustomFormula}
      formula={templateDetails?.Formula}
      onShowClick
      onCancelClick={onHide}
      loading={templateDetailsLoading}
      isAdvancedRuleset={
        templateDetails?.RulesetType === RULESET_TYPES.ADVANCED
      }
      groupBy={templateDetails?.GroupBy}
    />
  );
};

export default TemplateDetailModalWrapper;
