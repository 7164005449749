import React from "react";

const SvgCompletedLg = (props) => {
  const { height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx={18} cy={18} r={18} fill="#D0FDD7" opacity={0.7} />
      <circle cx={17.906} cy={17.91} r={13} fill="#57C96B" />
      <path
        stroke="#F5F5F5"
        strokeWidth={3}
        d="m10.309 17.727 5.379 4.91L24.055 15"
      />
    </svg>
  );
};
SvgCompletedLg.defaultProps = {
  height: 36,
  width: 36,
};
export default SvgCompletedLg;
