import React from "react";

const SvgThumbsUp = (props) => {
  const { color, height, width, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 13h2.165V4.417H0V13ZM2.686 13V4.686H4.27C6.806 4.05 7.341 0 7.341 0h1.33c1.343 2.54-.261 4.686-.261 4.686h3.58c.557 0 1.01.476 1.01 1.06 0 .292-.116.556-.295.745a.992.992 0 0 1-.714.31h-.625c.556 0 1.005.471 1.005 1.055 0 .291-.111.556-.295.75a1.015 1.015 0 0 1-.595.305 1.082 1.082 0 0 1 .222 1.665.992.992 0 0 1-.714.31h-.509c.556 0 1.006.471 1.006 1.055 0 .291-.112.556-.296.75a.973.973 0 0 1-.71.309H2.686Z"
        fill={color}
      />
    </svg>
  );
};

SvgThumbsUp.defaultProps = {
  color: "#2CC66D",
  height: 16,
  width: 16,
};

export default SvgThumbsUp;
