import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgConfigure = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill={color} d="M0 0h4v16H0z" data-name="Rectangle 2789" />
      <path fill={color} d="M6 0h4v16H6z" data-name="Rectangle 2790" />
      <path fill={color} d="M12 0h4v16h-4z" data-name="Rectangle 2791" />
    </svg>
  );
};

SvgConfigure.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 16,
  width: 16,
};
export default SvgConfigure;
