import { t } from "i18next";
import { Trans } from "react-i18next";
import { AddSlabsButton, AddSlabsModal } from "src/components";
import {
  MAXIMUM_INCENTIVE_SLABS_ALLOWED,
  MAXIMUM_INCENTIVE_SLAB_VALUE,
  MINIMUM_INCENTIVE_SLABS_ALLOWED,
  MINIMUM_INCENTIVE_SLAB_VALUE,
  MINIMUM_INCENTIVE_SLAB_WEIGHTAGE,
  REWARD_TYPE,
} from "src/constants";
import { useToggle } from "src/hooks";

const MODAL_TITLE = ({ t }) => ({
  [REWARD_TYPE.PERCENTAGE_SLAB.type]: t(
    "INCENTIVES.PROGRAM.LABELS.ADD_PERCENTAGE_SLAB"
  ),
  [REWARD_TYPE.MULTIPLIER_SLAB.type]: t(
    "INCENTIVES.PROGRAM.LABELS.ADD_MULTIPLIER_SLAB"
  ),
});

const WEIGHTAGE_PLACEHOLDER = {
  [REWARD_TYPE.PERCENTAGE_SLAB.type]: t(
    "INCENTIVES.PROGRAM.MESSAGES.REWARD_PERCENTAGE"
  ),
  [REWARD_TYPE.MULTIPLIER_SLAB.type]: t(
    "INCENTIVES.PROGRAM.MESSAGES.REWARD_MULTIPLIER"
  ),
};

const AddRewardSlabs = ({
  rewardType,
  slabs,
  handleAddSlabs,
  isValueInMinMaxRange,
  showPercentage,
}) => {
  const [show, showOrHideModal] = useToggle();
  const newSlabs = slabs.map((slab) => {
    return {
      minValue: slab.MinValue,
      maxValue: slab.MaxValue,
      weightage: slab.RewardWeightage,
    };
  });

  const handleAllowChange = (value) => {
    return isValueInMinMaxRange({
      value,
      minValue: MINIMUM_INCENTIVE_SLAB_VALUE,
      maxValue: MAXIMUM_INCENTIVE_SLAB_VALUE,
    });
  };

  return (
    <div>
      <AddSlabsButton onClick={showOrHideModal} edit={newSlabs.length}>
        {newSlabs.length
          ? t("INCENTIVES.PROGRAM.MESSAGES.NUMBER_OF_SLABS_ADDED", {
              totalSlabs: newSlabs.length,
            })
          : t("INCENTIVES.PROGRAM.LABELS.ADD_SLABS")}
      </AddSlabsButton>
      {show ? (
        <AddSlabsModal
          show={show}
          handleClose={showOrHideModal}
          title={MODAL_TITLE({ t })[rewardType]}
          note={
            <Trans
              values={{ minSlabs: MINIMUM_INCENTIVE_SLABS_ALLOWED }}
              i18nKey="INCENTIVES.PROGRAM.MESSAGES.ADD_REWARD_SLABS_NOTE"
            >
              <span className="semi-bold">Note</span>: The Max value should{" "}
              <b>always be greater than</b> the Min value. From the 2nd slab,
              The minimum value will be the maximum of the previous slab. A
              minimum of <strong>2 Slabs are required</strong> to save.
            </Trans>
          }
          slabs={newSlabs}
          minSlabs={MINIMUM_INCENTIVE_SLABS_ALLOWED}
          maxSlabs={MAXIMUM_INCENTIVE_SLABS_ALLOWED}
          minSlabValue={MINIMUM_INCENTIVE_SLAB_VALUE}
          maxSlabValue={MAXIMUM_INCENTIVE_SLAB_VALUE}
          minSlabWeightage={MINIMUM_INCENTIVE_SLAB_WEIGHTAGE}
          handleSave={handleAddSlabs}
          weightagePlaceholder={WEIGHTAGE_PLACEHOLDER[rewardType]}
          allowChange={handleAllowChange}
          showPercentage={showPercentage}
        />
      ) : null}
    </div>
  );
};

export default AddRewardSlabs;
