import { t } from "i18next";
import { lazy, Suspense, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import {
  useDownloadPayoutRawExcel,
  useImportPayoutUploadUrl,
  useProcessPayoutImport,
  useValidatePayoutImport,
} from "src/api/usePayout";
import { useUploadFileToS3 } from "src/api/useUploadFileToS3";
import { FormButton, StatsItem, toaster } from "src/components";
import { classNames } from "src/helpers";
import { useToggle } from "src/hooks";
import { SvgAttachment, SvgDownload } from "src/icons";
import "./payoutUsingExcel.scss";

const BulkAction = lazy(() =>
  import(
    /*
    webpackChunkName: "bulk.action",
    webpackPrefetch: true
    */
    "src/components/common/atoms/bulkAction"
  )
);

function PayoutUsingExcel({
  className,
  programId,
  timeFrequency,
  pendingRequests,
  disabled,
  refetchUsers,
  refetchPrograms,
  refetchTotalPendingPayout,
  featureType,
}) {
  const [modal, showAndHideModal] = useToggle();
  const [downloadPayoutRawExcel] = useDownloadPayoutRawExcel();
  const [importPayoutUploadUrl] = useImportPayoutUploadUrl();
  const [validatePayoutImport, { data }] = useValidatePayoutImport();
  const [processPayoutImport] = useProcessPayoutImport();
  const { uploadFileToS3, cancelUpload } = useUploadFileToS3();
  const [fileDetails, setFileDetails] = useState({
    fileId: null,
    fileName: null,
    fileError: "",
  });
  const [downloadUrl, setDownloadUrl] = useState();

  useEffect(() => {
    if (modal) {
      downloadPayoutRawExcel({
        variables: {
          programId,
          timeFrequency,
          ...featureType,
        },
      }).then((result) => {
        setDownloadUrl(result?.data?.downloadPayoutRawExcel.response?.url);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const handleRemove = () => {
    setFileDetails({
      fileId: null,
      fileName: null,
      fileError: "",
    });
  };

  const handleUpload = ({ acceptedFiles }) => {
    if (fileDetails.fileName || fileDetails.fileError) {
      handleRemove();
    }
    importPayoutUploadUrl({
      variables: {
        programId,
      },
    }).then(async (result) => {
      const presignedUrl = result?.data?.importPayoutUploadUrl?.response?.url;
      await uploadFileToS3({
        presignedUrl,
        file: acceptedFiles[0],
      }).then(() => {
        validatePayoutImport({
          variables: {
            fileId: result?.data?.importPayoutUploadUrl?.response?.fileId,
            timeFrequency,
            programId,
          },
        })
          .then((validationResult) => {
            if (
              validationResult.data.validatePayoutImport.__typename ===
              "ErrorResponse"
            ) {
              setFileDetails({
                fileName: null,
                fileId: null,
                fileError: validationResult.data.validatePayoutImport.message,
              });
            } else {
              toaster({
                message: "File uploaded successfully.",
                type: "info",
              });
              setFileDetails({
                fileName: acceptedFiles[0].name,
                fileId: result?.data?.importPayoutUploadUrl?.response?.fileId,
                fileError: "",
              });
            }
          })
          .catch((error) => {});
      });
    });
  };

  const handleProcessPayout = () => {
    processPayoutImport({
      variables: {
        programId,
        timeFrequency,
        fileId: fileDetails.fileId,
      },
    }).then(() => {
      refetchUsers && refetchUsers();
      refetchPrograms && refetchPrograms();
      refetchTotalPendingPayout && refetchTotalPendingPayout();
      toaster({
        message: "Payout processed successfully.",
        type: "info",
      });
      showAndHideModal();
    });
  };

  return (
    <div className={classNames([className, "payout-using-excel"])}>
      <FormButton
        className="action-btn"
        variant="outline-primary"
        onClick={showAndHideModal}
        disabled={disabled}
      >
        <SvgAttachment className="mr-2" />{" "}
        {t("INCENTIVES.PAYOUT.LABELS.PAYOUT_USING_EXCEL")}
      </FormButton>
      <Suspense fallback="...">
        {modal && downloadUrl ? (
          <BulkAction
            className="payout-using-excel-modal"
            show={Boolean(modal)}
            handleClose={showAndHideModal}
            title={t("INCENTIVES.PAYOUT.LABELS.PAYOUT_USING_EXCEL")}
            handleUpload={handleUpload}
            isOnSuccess={Boolean(fileDetails.fileName)}
            totalCount={pendingRequests}
            fileError={fileDetails.fileError}
            isOnError={Boolean(
              data?.validatePayoutImport?.response?.errorCount
            )}
            handleCancel={cancelUpload}
            errorProps={{
              handleBulkAction: handleProcessPayout,
              errorCount: data?.validatePayoutImport?.response?.errorCount,
              erroredFileUrl: data?.validatePayoutImport?.response?.downloadUrl,
              totalCount: pendingRequests,
              buttonDisabled:
                data?.validatePayoutImport?.response?.errorCount ===
                pendingRequests,
              buttonText: t("MANAGED_GOALS.BUTTONS.DISCARD_ERRORS_PAYOUT"),
            }}
            filePickerSuccessProps={{
              handleBulkAction: handleProcessPayout,
              buttonText: "Complete Payout",
            }}
          >
            <div className="flex-between mt-32">
              <div className="fs-13 w-50">
                <Trans
                  i18nKey="INCENTIVES.PAYOUT.MESSAGES.DOWNLOAD_USING_EXCEL"
                  t={t}
                >
                  Download the Excel sheet with <strong>Pending payout</strong>
                  requests for <strong>Last 30 Days</strong>. Give incentive
                  amounts and import the file back for payout.
                </Trans>
              </div>
              <div className="center border-l">
                <StatsItem
                  title={t("INCENTIVES.COMMON.LABELS.PENDING_REQUESTS")}
                  className="ml-4"
                >
                  <div className="fs-14 semi-bold">{pendingRequests}</div>
                </StatsItem>
                <FormButton
                  variant="outline-primary"
                  className="ml-4"
                  as="a"
                  href={downloadUrl}
                  download
                >
                  <SvgDownload className="mr-2" />
                  {t("INCENTIVES.PAYOUT.LABELS.DOWNLOAD_EXCEL_FILE")}
                </FormButton>
              </div>
            </div>
          </BulkAction>
        ) : null}
      </Suspense>
    </div>
  );
}

export default PayoutUsingExcel;
