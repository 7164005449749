import { gql, useQuery } from "src/api/client";

const GET_APPROVAL_ACHIEVEMENTS_QUERY = gql`
  query userAchievements(
    $programId: ID!
    $recurrenceId: ID
    $recurrenceStatus: String
    $userId: ID
    $userAssignedView: Boolean
    $includeTemplates: Boolean
  ) {
    userAchievements(
      programId: $programId
      recurrenceId: $recurrenceId
      recurrenceStatus: $recurrenceStatus
      userId: $userId
      userAssignedView: $userAssignedView
      includeTemplates: $includeTemplates
    ) {
      ... on UserAchievementsResponse {
        success
        message
        response {
          Templates {
            TemplateId
            Name
            Description
            Status
            GoalAggregateFunction
            CreatedBy
            IsCustomFormula
            Formula
            DrilldownStatus
            GoalRules {
              RuleId
              RuleName
              Entity
              RollUpFields
              Conditions
              CriteriaPattern
              GoalAggregateFunction
              __typename
            }
            __typename
          }
          SelectedGoals {
            GoalId
            GoalName
            TemplateId
          }
          userId
          userName
          totalEligibleBenefit
          achievementsCount
          type
          achievementsMetadata
          totalEligibleReward {
            RewardId
            RewardName
            RewardCategory
            ImageUrl
            __typename
          }
          totalEligibleRewardCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const useGetApprovalAchievements = (queryOptions) => {
  return useQuery(GET_APPROVAL_ACHIEVEMENTS_QUERY, queryOptions);
};
