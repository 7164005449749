import { AVATARS_COLOR_COMBINATIONS } from "src/constants";
import { isEmpty } from "src/helpers/vendors";
import * as AVATARS from "src/icons/avatars";

const AVATAR_LIST = Object.keys(AVATARS).map((i) => AVATARS[i]);

export const getAvatar = (id) => {
  if (isEmpty(id))
    return {
      AvatarIcon: AVATAR_LIST[0],
      color: AVATARS_COLOR_COMBINATIONS[0],
    };

  // adding Unicode code of each character
  const avatarHash = id
    .split("")
    .reduce((acc, char, index) => acc + char.charCodeAt(0) * (index + 1), 0);

  const colorHash = Math.abs(
    id
      .split("")
      .reduce(
        (prevHash, currVal, index) =>
          currVal.charCodeAt(0) * (index + 1) + ((prevHash << 5) - prevHash),
        0
      )
  );

  return {
    AvatarIcon: AVATAR_LIST[avatarHash % AVATAR_LIST.length],
    color:
      AVATARS_COLOR_COMBINATIONS[colorHash % AVATARS_COLOR_COMBINATIONS.length],
  };
};
