import { gql } from "./client";

export const PAGE_INFO_FRAGMENT = gql`
  fragment pageInfoFragment on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const ERROR_RESPONSE_FRAGMENT = gql`
  fragment errorResponseFragment on ErrorResponse {
    message
  }
`;

export const CORE_GOAL_DETAILS_FRAGMENT = gql`
  fragment coreGoalDetailsFragment on Goal {
    Name
    GoalId
    Description
    Status
    GoalValue
    TotalCycles
    CurrentRecurrenceCycle
    RecurrenceTimeLeft
  }
`;

export const CORE_ASSIGNED_GOAL_DETAILS_FRAGMENT = gql`
  fragment coreAssignedGoalDetailsFragment on AssignedGoal {
    Name
    GoalId
    Description
    Status
    GoalValue
    TotalCycles
    CurrentRecurrenceCycle
    RecurrenceTimeLeft
  }
`;

export const CORE_GOAL_RECURRENCE_REPORT_FRAGEMENT = gql`
  fragment coreGoalRecurrenceReportFragment on RecurrenceReport {
    RecurrenceId
    RecurrenceType
    RecurrenceStartDate
    RecurrenceEndDate
    RecurrenceGoalValue
    GoalAchieved
    RecurrenceDays
    Status
  }
`;

export const CORE_TEMPLATE_DETAILS_FRAGMENT = gql`
  fragment coreTemplateDetailsFragment on Template {
    TemplateId
    Name
    Description
    Status
    GoalAggregateFunction
    CreatedBy
    IsCustomFormula
    Formula
    RulesetType
    GroupBy
  }
`;

export const CORE_TEMPLATE_GOAL_RULE_FRAGMENT = gql`
  fragment coreTemplateGoalRuleFragment on GoalRule {
    RuleId
    RuleName
    Entity
    RollUpFields
    Conditions
    CriteriaPattern
    GoalAggregateFunction
  }
`;

export const CORE_INCENTIVE_PROGRAM_FILEDS_FRAGMENT = gql`
  fragment coreIncentiveProgramFields on Program {
    ProgramId
    Name
    Description
    Status
    RecurrenceDefinition {
      FirstRecurrenceStartDate
      RecurrencePeriod
      RecurrenceCycleDuration
      CustomType
      CustomGap
      LastRecurrenceEndDate
      __typename
    }
    Type
    IsRollup
    UserSelectionType
    FileName
    CreatedBy
    ModifiedBy
    CreatedBy
    RecurrencesCount
  }
`;

export const CORE_LEADERBOARD_PROGRAM_FIELDS_FRAGMENT = gql`
  fragment coreLeaderboardFields on Leaderboard {
    LeaderboardId
    Name
    Description
    Status
    RecurrenceDefinition {
      FirstRecurrenceStartDate
      RecurrencePeriod
      RecurrenceCycleDuration
      CustomType
      CustomGap
      LastRecurrenceEndDate
      __typename
    }
    Type
    IsRollup
    UserSelectionType
    CreatedBy
    ModifiedBy
    CreatedBy
    RecurrencesCount
    __typename
  }
`;

export const CORE_RECURRENCES_ACHIEVEMENTS_FIELDS_FRAGMENT = gql`
  fragment coreRecurrencesAchievementsFieldsFragment on RecurrencesAchievement {
    RecurrenceId
    RecurrenceType
    RecurrenceStartDate
    RecurrenceEndDate
    RecurrenceGoalValue
    GoalAchieved
    GoalId
  }
`;
