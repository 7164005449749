import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgBack = (props) => {
  const { color, height, width, rotate, styles, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      style={{
        ...styles,
        transform: `rotate(${rotate}deg)`,
        transformOrigin: "50% 50%",
      }}
      {...rest}
    >
      <defs>
        <clipPath id="elk4__a">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="Back button blue">
        <g clipPath="url(#elk4__a)" data-name="Group 8731">
          <path fill="none" d="M0 0h22v22H0z" data-name="Rectangle 3121" />
          <path
            fill={color}
            d="m131.636 91.352-5 4.652a1.574 1.574 0 0 1-2.135 0l-5.024-4.652a1.347 1.347 0 0 1 0-2 1.609 1.609 0 0 1 2.154 0l2.4 2.236V81.519a1.529 1.529 0 0 1 3.051 0v10.065l2.4-2.236a1.609 1.609 0 0 1 2.154 0 1.347 1.347 0 0 1 0 2.004Z"
            data-name="Component 102"
            style={{
              isolation: "isolate",
            }}
            transform="rotate(90 106.91 -7.643)"
          />
        </g>
      </g>
    </svg>
  );
};

SvgBack.defaultProps = {
  color: COLORS.PRIMARY_BLUE,
  height: 22,
  width: 22,
  rotate: 0,
  styles: {},
};
export default SvgBack;
