import React from "react";
import { Modal } from "react-bootstrap";
import { FormButton } from "src/components/common/formElements";
import { Navigation } from "src/components/layouts/navbar/variants";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgClose } from "src/icons";
import "./modalContainer.scss";

const ModalContainer = (props) => {
  const {
    show,
    onHide,
    title,
    modalClassName,
    onResetClick,
    disableReset,
    disableFooter = false,
    children,
    applyText = "Apply Filters",
    onApplyFilterClick,
    disableApplyFilterClick,
    showCloseButton = false,
    showBackButton = true,
    modalChildrenClassName,
  } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={classNames(["modal-container", modalClassName])}
      dialogClassName="modal-container-dialog"
    >
      <Navigation
        title={title}
        backgroundColor={COLORS.WHITE}
        onClick={() => onHide()}
        showBackButton={showBackButton}
      >
        {onResetClick && (
          <FormButton
            id="reset-filter"
            disabled={disableReset}
            onClick={onResetClick}
            className={classNames([
              "reset-btn",
              disableReset ? "disabled" : "",
            ])}
            variant="link"
          >
            Reset
          </FormButton>
        )}
        {showCloseButton && (
          <FormButton
            id="close-button"
            disabled={!showCloseButton}
            onClick={() => onHide()}
            variant="link"
          >
            <SvgClose />
          </FormButton>
        )}
      </Navigation>
      <div
        className={classNames([
          "modal-container-children",
          !disableFooter ? "active-footer" : "",
          modalChildrenClassName,
        ])}
      >
        {children}
      </div>
      {!disableFooter ? (
        <div className="mt-auto py-3 px-4 flex-between filter-container-footer">
          <FormButton
            className="w-50"
            variant="outline-primary"
            onClick={() => onHide()}
          >
            Cancel
          </FormButton>
          <FormButton
            className="w-50 ml-3"
            onClick={onApplyFilterClick}
            disabled={disableApplyFilterClick}
          >
            {applyText}
          </FormButton>
        </div>
      ) : null}
    </Modal>
  );
};
export default ModalContainer;
