import { useEffect, useLayoutEffect, useState } from "react";

export const formatTime = (value) => {
  return `${value}`.length === 1 ? `0${value}` : value;
};

export function useTimer({ duration }) {
  const [currentDuration, setCurrentDuration] = useState(duration);

  useEffect(() => {
    setCurrentDuration(duration);
  }, [duration]);

  useLayoutEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDuration((prevCurrentDuration) => prevCurrentDuration - 1);
    }, 1000);
    return () => window.clearInterval(intervalId);
  }, [duration]);

  const days = Math.floor(currentDuration / (3600 * 24));
  const hours = Math.floor((currentDuration % (3600 * 24)) / 3600);
  const minutes = Math.floor((currentDuration % 3600) / 60);
  const seconds = Math.floor(currentDuration % 60);

  return { days, hours, minutes, seconds };
}
