import { useLazyQuery as useApolloLazyQuery } from "@apollo/client";

const defaultQueryOptions = {};

export const useLazyQuery = (query, queryOptions) => {
  return useApolloLazyQuery(query, {
    ...defaultQueryOptions,
    ...queryOptions,
  });
};
