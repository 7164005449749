import React from "react";
import { Image } from "src/components";
import SilverDailyImg from "./SilverDaily.svg";
import SilverDailyWithoutRankImg from "./SilverDailyWithoutRank.svg";
import SilverMonthlyImg from "./SilverMonthly.svg";
import SilverMonthlyWithoutRankImg from "./SilverMonthlyWithoutRank.svg";
import SilverQuarterlyImg from "./SilverQuarterly.svg";
import SilverWeeklyImg from "./SilverWeekly.svg";
import SilverWeeklyWithoutRankImg from "./SilverWeeklyWithoutRank.svg";
import SilverYearlyImg from "./SilverYearly.svg";
import SilverYearlyWithoutRankImg from "./SilverYearlyWithoutRank.svg";

export const SilverDaily = (props) => (
  <Image src={SilverDailyImg} alt="SilverDailyImg" {...props} />
);

export const SilverWeekly = (props) => (
  <Image src={SilverWeeklyImg} alt="SilverWeeklyImg" {...props} />
);

export const SilverMonthly = (props) => (
  <Image src={SilverMonthlyImg} alt="SilverMonthlyImg" {...props} />
);

export const SilverQuarterly = (props) => (
  <Image src={SilverQuarterlyImg} alt="SilverQuarterlyImg" {...props} />
);

export const SilverYearly = (props) => (
  <Image src={SilverYearlyImg} alt="SilverYearlyImg" {...props} />
);

export const SilverDailyWithoutRank = (props) => (
  <Image
    src={SilverDailyWithoutRankImg}
    alt="SilverDailyWithoutRankImg"
    {...props}
  />
);

export const SilverWeeklyWithoutRank = (props) => (
  <Image
    src={SilverWeeklyWithoutRankImg}
    alt="SilverWeeklyWithoutRankImg"
    {...props}
  />
);

export const SilverMonthlyWithoutRank = (props) => (
  <Image
    src={SilverMonthlyWithoutRankImg}
    alt="SilverMonthlyWithoutRankImg"
    {...props}
  />
);

export const SilverQuarterlyWithoutRank = (props) => (
  <Image
    src={SilverQuarterlyImg}
    alt="SilverQuarterlyWithoutRankImg"
    {...props}
  />
);

export const SilverYearlyWithoutRank = (props) => (
  <Image
    src={SilverYearlyWithoutRankImg}
    alt="SilverYearlyWithoutRankImg"
    {...props}
  />
);
