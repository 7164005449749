import { useReducer } from "react";
import { DEFAULT_DRILLDOWN_ATTRIBUTES } from "src/config/userAttributes";
import { isEqual, isUndefined } from "src/helpers";

const achievementBreakDownAttributes = (initialState) => {
  return {
    Recurrence: !isUndefined(initialState?.Recurrence?.RecurrenceId)
      ? { ...initialState.Recurrence, Fetched: true }
      : null,
    User: !isUndefined(initialState?.User?.UserID)
      ? { ...initialState.User, Fetched: true }
      : null,
    Rule: !isUndefined(initialState?.Rule?.RuleId)
      ? { ...initialState.Rule, Fetched: true }
      : null,
    Search: initialState.Search || "",
    FieldsOrder:
      initialState.FieldsOrder ||
      DEFAULT_DRILLDOWN_ATTRIBUTES.filter(
        ({ SchemaName }) => SchemaName !== "ruleName"
      ).map(({ SchemaName }) => SchemaName),
  };
};

const ACTIONS = {
  SET_FIELDS_ORDER: "setFieldsOrder",
  SET_GOAL_USER: "setGoalUser",
  SET_SELECTED_RECURRENCE: "setSelectedRecurrence",
  SET_SELECTED_RULE: "setSelectedRule",
  SET_SEARCH: "setSearch",
  SET_SHOULD_FETCH: "setShouldFetch",
};

const attributesReducer = (attributes, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_FIELDS_ORDER: {
      if (isEqual(attributes.FieldsOrder, payload.fieldsOrder))
        return attributes;
      return {
        ...attributes,
        FieldsOrder: payload.fieldsOrder,
        ShouldFetch: true,
      };
    }
    case ACTIONS.SET_GOAL_USER: {
      if (isEqual(attributes.User, payload.goalUser)) return attributes;
      return {
        ...attributes,
        User: { ...payload.goalUser, Fetched: true },
        ShouldFetch: true,
      };
    }
    case ACTIONS.SET_SELECTED_RECURRENCE: {
      if (
        isEqual(
          attributes.Recurrence?.RecurrenceId,
          payload.selectedRecurrence?.RecurrenceId
        )
      )
        return attributes;
      return {
        ...attributes,
        Recurrence: { ...payload.selectedRecurrence, Fetched: true },
        ShouldFetch: true,
      };
    }
    case ACTIONS.SET_SELECTED_RULE: {
      if (isEqual(attributes.Rule?.RuleId, payload.selectedRule?.RuleId))
        return attributes;
      return {
        ...attributes,
        Rule: { ...payload.selectedRule, Fetched: true },
        ShouldFetch: true,
      };
    }
    case ACTIONS.SET_SEARCH: {
      if (isEqual(attributes.Search, payload.search)) return attributes;
      return {
        ...attributes,
        Search: payload.search,
        ShouldFetch: true,
      };
    }
    case ACTIONS.SET_SHOULD_FETCH: {
      return {
        ...attributes,
        ShouldFetch: payload.shouldFetch,
      };
    }
    default:
      return attributes;
  }
};

const useAchievementBreakdownReducer = ({ initialState }) => {
  const [attributes, dispatch] = useReducer(attributesReducer, {
    ...achievementBreakDownAttributes(initialState),
    ShouldFetch: false,
  });

  const setFieldsOrder = (fieldsOrder) => {
    dispatch({
      type: ACTIONS.SET_FIELDS_ORDER,
      payload: { fieldsOrder },
    });
  };

  const setGoalUser = (goalUser) => {
    dispatch({
      type: ACTIONS.SET_GOAL_USER,
      payload: { goalUser },
    });
  };

  const setSelectedRecurrence = (selectedRecurrence) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_RECURRENCE,
      payload: { selectedRecurrence },
    });
  };

  const setSelectedRule = (selectedRule) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_RULE,
      payload: { selectedRule },
    });
  };

  const setSearch = (search) => {
    dispatch({
      type: ACTIONS.SET_SEARCH,
      payload: { search },
    });
  };

  const setShouldFetch = (shouldFetch) => {
    dispatch({
      type: ACTIONS.SET_SHOULD_FETCH,
      payload: { shouldFetch },
    });
  };

  const alreadyFetched =
    attributes.Recurrence?.Fetched &&
    attributes.User?.Fetched &&
    attributes.Rule?.Fetched;

  return {
    alreadyFetched,
    attributes,
    actions: {
      setFieldsOrder,
      setGoalUser,
      setSelectedRecurrence,
      setSelectedRule,
      setSearch,
      setShouldFetch,
    },
  };
};

export default useAchievementBreakdownReducer;
