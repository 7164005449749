import PropTypes from "prop-types";
import { useReducer } from "react";
import {
  LEADERBOARD_USERS_AND_GROUPS,
  LEADERBOARD_USERS_AND_GROUPS_PROPTYPES,
} from "../pages/leaderboardCrud/web/selectUsersAndGroups/data";

const ACTIONS = {
  SET_SELECTION: "setSelection",
  EDIT_SELECTED_RECURRENCE: "setCurrentRecurrence",
  SET_GROUPS: "setGroups",
  EDIT_GROUPS: "editGroups",
  EDIT_SELECTED_GROUP: "editSelectedGroup",
  USERSLIST_FILTER: "usersListFilter",
  SET_SELECTED_USERS: "setSelectedUsers",
  ADD_SELECTED_USER: "addSelectedUser",
  REMOVE_SELECTED_USER: "removeSelectedUser",
  RESET_SELECTED_USERS: "resetSelectedUsers",
  SAVE_SELECTED_USERS: "saveSelectedUsers",
  EDIT_SEARCH: "editSearch",
  EDIT_CALLBACK_KEY: "editCallbackKey",
};

const usersAndGroupsReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_SELECTION: {
      return {
        ...state,
        formData: {
          Selection: payload.Selection,
        },
      };
    }
    case ACTIONS.EDIT_SELECTED_RECURRENCE: {
      return {
        ...state,
        selectedUsersCountDiff: 0,
        isSelectedUsersEdited: false,
        formData: {
          ...state.formData,
          SelectedRecurrence: payload.SelectedRecurrence,
          Search: "",
          Groups: [],
          SelectedGroup: null,
        },
      };
    }
    case ACTIONS.SET_GROUPS: {
      return {
        ...state,
        formData: {
          ...state.formData,
          Groups: payload.Groups,
        },
      };
    }
    case ACTIONS.EDIT_GROUPS: {
      let newState = { ...state };
      if (payload.Groups) {
        newState = {
          ...newState,
          formData: {
            ...newState.formData,
            SelectedGroup: payload.Groups[0],
          },
        };
      }
      return {
        ...newState,
        usersFilter: "All",
        selectedUsersCountDiff: 0,
        isSelectedUsersEdited: false,
        formData: {
          ...newState.formData,
          Groups: payload.Groups,
          Search: "",
        },
      };
    }
    case ACTIONS.EDIT_SELECTED_GROUP: {
      return {
        ...state,
        usersFilter: "All",
        formData: {
          ...state.formData,
          SelectedGroup: payload.SelectedGroup,
        },
      };
    }
    case ACTIONS.USERSLIST_FILTER: {
      return {
        ...state,
        ...payload,
      };
    }
    case ACTIONS.SET_SELECTED_USERS: {
      return {
        ...state,
        formData: {
          ...state.formData,
          SelectedUsers: payload.SelectedUsers,
        },
      };
    }
    case ACTIONS.ADD_SELECTED_USER: {
      return {
        ...state,
        isSelectedUsersEdited: true,
        selectedUsersCountDiff: state.selectedUsersCountDiff + 1,
        formData: {
          ...state.formData,
          SelectedUsers: [...state.formData.SelectedUsers, payload.userId],
        },
      };
    }
    case ACTIONS.REMOVE_SELECTED_USER: {
      return {
        ...state,
        isSelectedUsersEdited: true,
        selectedUsersCountDiff: state.selectedUsersCountDiff - 1,
        formData: {
          ...state.formData,
          SelectedUsers: state.formData.SelectedUsers.filter(
            (userID) => userID !== payload.userId
          ),
        },
      };
    }
    case ACTIONS.RESET_SELECTED_USERS: {
      return {
        ...state,
        isSelectedUsersEdited: false,
        selectedUsersCountDiff: 0,
        formData: {
          ...state.formData,
          SelectedUsers: payload.selectedUsers || [],
        },
      };
    }
    case ACTIONS.SAVE_SELECTED_USERS: {
      return {
        ...state,
        isSelectedUsersEdited: false,
      };
    }
    case ACTIONS.EDIT_SEARCH: {
      return {
        ...state,
        formData: {
          ...state.formData,
          Search: payload.Search,
        },
      };
    }
    case ACTIONS.EDIT_CALLBACK_KEY: {
      return {
        ...state,
        callbackFnKey: payload.key,
        callbackFnParams: payload.params,
      };
    }
    default:
      return state;
  }
};

const useUsersAndGroups = ({ initialState }) => {
  const [usersAndGroups, dispatch] = useReducer(usersAndGroupsReducer, {
    ...LEADERBOARD_USERS_AND_GROUPS(initialState),
    isSelectedUsersEdited: false,
    usersFilter: "All",
    selectedUsersCountDiff: 0,
    callbackFnKey: "setFilter",
    callbackFnParams: ["Selected"],
  });

  const selectionActions = {
    edit: function editSelection(selection) {
      dispatch({
        type: ACTIONS.SET_SELECTION,
        payload: {
          Selection: selection,
        },
      });
    },
  };

  const selectedRecurrenceActions = {
    edit: function editSelectedRecurrence(recurrence) {
      dispatch({
        type: ACTIONS.EDIT_SELECTED_RECURRENCE,
        payload: {
          SelectedRecurrence: recurrence,
        },
      });
    },
  };

  const groupsActions = {
    create: function createGroups(Groups) {
      dispatch({
        type: ACTIONS.SET_GROUPS,
        payload: {
          Groups,
        },
      });
    },
    edit: function editGroups(Groups) {
      dispatch({
        type: ACTIONS.EDIT_GROUPS,
        payload: {
          Groups,
        },
      });
    },
  };

  const selectedGroupActions = {
    edit: function editSelectedGroup(selectedGroup) {
      dispatch({
        type: ACTIONS.EDIT_SELECTED_GROUP,
        payload: {
          SelectedGroup: selectedGroup,
        },
      });
    },
  };

  const selectedUsersActions = {
    create: function createSelectedUsers(users) {
      dispatch({
        type: ACTIONS.SET_SELECTED_USERS,
        payload: {
          SelectedUsers: users,
        },
      });
    },
    // selecteduser=["cvfve","fvrefvre","rvfnjvjknjn"]
    add: function addUser(userId) {
      dispatch({
        type: ACTIONS.ADD_SELECTED_USER,
        payload: {
          userId,
        },
      });
    },
    remove: function removeUser(userId) {
      dispatch({
        type: ACTIONS.REMOVE_SELECTED_USER,
        payload: {
          userId,
        },
      });
    },
    save: function saveSelectedUsers() {
      dispatch({
        type: ACTIONS.SAVE_SELECTED_USERS,
      });
    },
    reset: function resetSelectedUsers(selectedUsers) {
      dispatch({
        type: ACTIONS.RESET_SELECTED_USERS,
        payload: {
          selectedUsers,
        },
      });
    },
  };

  const usersActions = {
    handleFilterChange: function usersListFilter(usersFilter) {
      dispatch({
        type: ACTIONS.USERSLIST_FILTER,
        payload: {
          usersFilter,
        },
      });
    },
  };

  const searchActions = {
    edit: function editSearch(search) {
      dispatch({
        type: ACTIONS.EDIT_SEARCH,
        payload: {
          Search: search,
        },
      });
    },
  };

  const callbackActions = {
    edit: function editKey(key, params) {
      dispatch({
        type: ACTIONS.EDIT_CALLBACK_KEY,
        payload: {
          key,
          params,
        },
      });
    },
  };

  return {
    usersAndGroups,
    actions: {
      selectionActions,
      selectedRecurrenceActions,
      groupsActions,
      selectedGroupActions,
      selectedUsersActions,
      usersActions,
      searchActions,
      callbackActions,
    },
  };
};

useUsersAndGroups.propTypes = {
  initialState: PropTypes.shape(LEADERBOARD_USERS_AND_GROUPS_PROPTYPES),
};

export default useUsersAndGroups;
