import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgFilterModern = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M3 5h14.75M4.76 10h11.23M7.656 15h5.438"
      />
    </svg>
  );
};
SvgFilterModern.defaultProps = {
  color: COLORS.GREY_2,
  height: 20,
  width: 21,
};
export default SvgFilterModern;
