import React from "react";
import { Modal } from "react-bootstrap";
import { Shimmer } from "src/components/common/atoms";

const LoadingModal = () => (
  <>
    <Modal.Header closeButton className="goal-rule-header ">
      <Modal.Title className="fs-18">
        <Shimmer width="100px" height="10px" />
      </Modal.Title>
    </Modal.Header>
    <div className="row">
      <div className="col-12">
        <Modal.Body>
          <div className="p-4 border-b">
            <div className="center">
              <div>
                <Shimmer width="80px" height="10px" />
                <div className="mt-2">
                  <Shimmer width="160px" height="10px" />
                </div>
              </div>
              <div className="mx-3">
                <Shimmer width="80px" height="10px" />
                <div className="mt-2">
                  <Shimmer width="160px" height="10px" />
                </div>
              </div>
              <div className="mx-3">
                <Shimmer width="80px" height="10px" />
                <div className="mt-2">
                  <Shimmer width="160px" height="10px" />
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 flex-between">
            <div>
              <Shimmer width="400px" height="10px" />
              <div className="d-flex flex-column mt-5">
                <Shimmer width="100px" height="10px" />
                <div className="mt-2 dropdown-shimmer-box">
                  <Shimmer width="200px" height="10px" />
                </div>
              </div>
            </div>
            <div className="w-25 shimmer-summary" />
          </div>
        </Modal.Body>
      </div>
    </div>
    <Modal.Footer>
      <Shimmer width="80px" height="40px" />
    </Modal.Footer>
  </>
);

export default LoadingModal;
