import { gql, useQuery } from "src/api/client";
import {
  CORE_ASSIGNED_GOAL_DETAILS_FRAGMENT,
  CORE_GOAL_RECURRENCE_REPORT_FRAGEMENT,
  CORE_TEMPLATE_GOAL_RULE_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
} from "src/api/fragments";

// src/modules/assignedView/MyGoalView.js
const GET_ASSIGNED_GOAL_VIEW_QUERY = gql`
  ${CORE_ASSIGNED_GOAL_DETAILS_FRAGMENT}
  ${CORE_GOAL_RECURRENCE_REPORT_FRAGEMENT}
  ${CORE_TEMPLATE_GOAL_RULE_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query getOneAssignedGoal(
    $GoalId: ID!
    $UserId: ID
    $RecurrenceId: ID
    $Sort: GoalContributionSort
    $Limit: Float
    $After: String
    $Query: assignedGoalQueryInput
    $userAttributes: [String]
  ) {
    getOneAssignedGoal(
      GoalId: $GoalId
      UserId: $UserId
      RecurrenceId: $RecurrenceId
      Sort: $Sort
      Limit: $Limit
      After: $After
      Query: $Query
      userAttributes: $userAttributes
    ) {
      __typename
      ... on OneAssignedGoalResponse {
        success
        message
        response {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          Goal {
            ...coreAssignedGoalDetailsFragment
            GoalAggConfig
            GoalType
            RecurrenceType
            RecurrenceDueText
            RecurrenceDefinition
            FutureCycleDuration
            InGaps
            CreatedBy
            GoalAchieved
            RecurrenceCount
            RecurrenceStartDate
            IsUserPresent
            LastUpdatedOn
            IsRollUp
            RollupType
            IsDynamicTablePresent
            GoalOwner {
              FirstName
              LastName
              FullName
              UserID
              __typename
            }
            RecurrenceReport {
              ...coreGoalRecurrenceReportFragment
              RecurrenceType
              Status
              UsersReport
              __typename
            }
            Template {
              GoalRules {
                ...coreTemplateGoalRuleFragment
                TemplateId
                __typename
              }
              RulesetType
              GoalAggregateFunction
              Name
              Status
              Formula
              IsCustomFormula
              DrilldownStatus
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetAssignedGoalView = (queryOptions) => {
  return useQuery(GET_ASSIGNED_GOAL_VIEW_QUERY, queryOptions);
};

const GET_ACHIEVEMENT_ASSIGNED_GOAL_DETAILS_QUERY = gql`
  ${CORE_ASSIGNED_GOAL_DETAILS_FRAGMENT}
  ${CORE_GOAL_RECURRENCE_REPORT_FRAGEMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query getOneAssignedGoal(
    $GoalId: ID!
    $UserId: ID
    $RecurrenceId: ID
    $Sort: GoalContributionSort
    $directReportOnly: Boolean
    $Limit: Float
    $After: String
    $Query: assignedGoalQueryInput
    $userAttributes: [String]
    $UserIds: [ID]
  ) {
    getOneAssignedGoal(
      GoalId: $GoalId
      UserId: $UserId
      RecurrenceId: $RecurrenceId
      Sort: $Sort
      directReportOnly: $directReportOnly
      Limit: $Limit
      After: $After
      Query: $Query
      userAttributes: $userAttributes
      UserIds: $UserIds
    ) {
      __typename
      ... on OneAssignedGoalResponse {
        success
        message
        response {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          Goal {
            ...coreAssignedGoalDetailsFragment
            GoalAggConfig
            GoalType
            RecurrenceType
            RecurrenceDueText
            RecurrenceCount
            GoalOwner {
              FirstName
              LastName
              UserID
            }
            IsRollUp
            RollupType
            RecurrenceDefinition
            Template {
              Name
              Status
              GoalAggregateFunction
              Formula
              IsCustomFormula
            }
            RecurrenceReport {
              ...coreGoalRecurrenceReportFragment
              RecurrenceType
              UsersReport
            }
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetAchievementAssignedGoalDetails = (queryOptions) => {
  return useQuery(GET_ACHIEVEMENT_ASSIGNED_GOAL_DETAILS_QUERY, queryOptions);
};

const GET_ASSIGNED_DRILLDOWN_USER_ACHIEVEMENT_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getOneAssignedGoal(
    $GoalId: ID!
    $UserId: ID
    $RecurrenceId: ID
    $Sort: GoalContributionSort
    $directReportOnly: Boolean
    $Query: assignedGoalQueryInput
    $userAttributes: [String]
    $UserIds: [ID]
  ) {
    getOneAssignedGoal(
      GoalId: $GoalId
      UserId: $UserId
      RecurrenceId: $RecurrenceId
      Sort: $Sort
      directReportOnly: $directReportOnly
      userAttributes: $userAttributes
      UserIds: $UserIds
      Query: $Query
    ) {
      __typename
      ... on OneAssignedGoalResponse {
        success
        message
        response {
          Goal {
            RecurrenceReport {
              UsersReport
            }
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetAssignedDrilldownUserAchievement = (queryOptions) => {
  return useQuery(GET_ASSIGNED_DRILLDOWN_USER_ACHIEVEMENT_QUERY, queryOptions);
};

const GET_ASSIGNED_GOAL_RECURRENCE_DETAILS = gql`
  ${CORE_ASSIGNED_GOAL_DETAILS_FRAGMENT}
  ${CORE_GOAL_RECURRENCE_REPORT_FRAGEMENT}
  ${CORE_TEMPLATE_GOAL_RULE_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query getOneAssignedGoal(
    $GoalId: ID!
    $UserId: ID
    $RecurrenceId: ID
    $Sort: GoalContributionSort
    $Limit: Float
    $After: String
    $Query: assignedGoalQueryInput
  ) {
    getOneAssignedGoal(
      GoalId: $GoalId
      UserId: $UserId
      RecurrenceId: $RecurrenceId
      Sort: $Sort
      Limit: $Limit
      After: $After
      Query: $Query
    ) {
      __typename
      ... on OneAssignedGoalResponse {
        success
        message
        response {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          Goal {
            ...coreAssignedGoalDetailsFragment
            GoalAggConfig
            GoalType
            RecurrenceType
            RecurrenceDueText
            RecurrenceDefinition
            FutureCycleDuration
            InGaps
            CreatedBy
            GoalAchieved
            RecurrenceCount
            RecurrenceStartDate
            IsUserPresent
            LastUpdatedOn
            IsRollUp
            RollupType
            IsDynamicTablePresent
            GoalOwner {
              FirstName
              LastName
              FullName
              UserID
              __typename
            }
            RecurrenceReport {
              ...coreGoalRecurrenceReportFragment
              RecurrenceType
              Status
              __typename
            }
            Template {
              GoalRules {
                ...coreTemplateGoalRuleFragment
                TemplateId
                __typename
              }
              GoalAggregateFunction
              Name
              Status
              Formula
              IsCustomFormula
              DrilldownStatus
              RulesetType
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetAssignedGoalRecurrenceDetails = (queryOptions) => {
  return useQuery(GET_ASSIGNED_GOAL_RECURRENCE_DETAILS, queryOptions);
};
