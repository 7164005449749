import React from "react";
import { useTranslation } from "react-i18next";
import { FormBadge } from "src/components";
import { COLORS } from "src/constants";
import { classNames, isEmpty } from "src/helpers";
import { SvgClock, SvgInProgress, SvgTick, SvgUnpublish } from "src/icons";

const IncentiveBadge = (props) => {
  const { isProgramYetToStart, programData, className } = props;
  const { t } = useTranslation();
  const completed =
    isEmpty(programData?.CurrentRecurrenceCycle) &&
    isEmpty(programData?.RecurrenceTimeLeft) &&
    programData?.Status === "PUBLISHED";

  const computeBadgeText = () => {
    let text;
    if (programData?.Status === "UNPUBLISHED") {
      return (
        <span className="bg-grey-primary p-2 br-4">
          <SvgUnpublish color={COLORS.GREY_1} className="mr-1" />
          <span className="fc-grey1">
            <b>{t("COMMON.LABELS.UNPUBLISHED")}</b>
          </span>
        </span>
      );
    }
    if (completed) {
      text = (
        <>
          <SvgTick color="#2ecc71" backgroundColor="none" className="mr-1" />
          <span>
            <b>
              {programData.RecurrencesCount === 1 ? (
                t("INCENTIVES.COMMON.MESSAGES.ALL_CYCLES_ARE_COMPLETED")
              ) : (
                <>
                  {t("MANAGED_GOALS.MESSAGES.ALL")}{" "}
                  {programData.RecurrencesCount}{" "}
                  {programData.RecurrencesCount > 1
                    ? t("MANAGED_GOALS.MESSAGES.CYCLES")
                    : t("MANAGED_GOALS.LABELS.CYCLE")}{" "}
                  {t("INCENTIVES.COMMON.MESSAGES.CYCLE_OF_PROGRAM_COMPLETED")}
                </>
              )}
            </b>
          </span>
        </>
      );
    } else if (isProgramYetToStart) {
      text = (
        <>
          <SvgClock backgroundColor="none" color="#2ecc71" />
          <b className="mx-1">
            &nbsp;{programData?.CurrentRecurrenceCycle?.split(" ")[0]}
          </b>
          {t("INCENTIVES.COMMON.MESSAGES.CYCLE_OF_PROGRAM_STARTS_IN")} &nbsp;
          <b>{programData?.RecurrenceDueText}</b>
        </>
      );
    } else if (programData?.InGaps) {
      const str = programData?.CurrentRecurrenceCycle?.split(" ");
      const first = str[0];
      const secondLast = str[str.length - 2];
      const last = str[str.length - 1];
      text = (
        <>
          <SvgClock width="18" height="18" color="#2ecc71" className="mr-1" />{" "}
          <b>&nbsp;{first}&nbsp;</b>{" "}
          {t("INCENTIVES.COMMON.MESSAGES.CYCLE_OF_PROGRAM_STARTS_IN")}
          &nbsp;
          <b>
            {secondLast} {last}
          </b>
        </>
      );
    } else {
      text = (
        <>
          <SvgInProgress color="#2ecc71" className="mr-1" />
          <span>
            <b>{programData?.CurrentRecurrenceCycle?.split(" ")[0]}</b>{" "}
            {t("INCENTIVES.COMMON.MESSAGES.CYCLE_OF_PROGRAM")}{" "}
            <b>{t("MANAGED_GOALS.MESSAGES.IN_PROGRESS")}</b>
          </span>
        </>
      );
    }
    return text;
  };
  return (
    <div>
      <FormBadge
        className={classNames([
          "bg-secondary-blue fc-black",
          className || "h-40",
        ])}
      >
        {computeBadgeText()}
      </FormBadge>
    </div>
  );
};

export default IncentiveBadge;
