import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

const MobileProgramFilter = (props) => {
  const {
    options,
    value,
    handleChange,
    startAdornment = "Program Name",
  } = props;

  const { t } = useTranslation();

  const customStylesOwner = {
    container: (provided) => ({
      ...provided,
      fontSize: "12px",
      height: "40px",
    }),
    control: (provided) => ({ ...provided, width: "auto" }),
    menu: (provided) => ({ ...provided, width: "100%", padding: "0px" }),
    option: (provided, state) => ({
      ...provided,
      color: "black",
      cursor: "pointer",
      backgroundColor: state.isFocused ? "#f1fafe" : "",
      padding: "12px 16px",
    }),
  };

  const formatOptionLabel = (props) => (
    <div className="d-flex justify-content-between ">
      <div className="w-70">
        <div className="d-flex">
          <p className="semi-bold text-truncate">{props.programName}</p>
        </div>
      </div>
      <div className="w-30  justify-content-center d-flex">
        {props.pendingRequests}
      </div>
    </div>
  );

  const Menu = (props) => {
    return (
      <components.Menu {...props}>
        <div>
          <div className="d-flex justify-content-between bg-grey px-3 py-2 br-4">
            <div className="fc-grey w-70">{startAdornment}</div>

            <div className="fc-grey w-30 mr-2">
              {t("INCENTIVES.COMMON.LABELS.PENDING_REQUESTS")}
            </div>
          </div>
          <div>{props.children}</div>
        </div>
      </components.Menu>
    );
  };

  const customFilter = (option, searchText) => {
    if (
      option.data.programName.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;

    const values = getValue();
    let valueLabel = "";

    if (values.length > 0)
      valueLabel += props.selectProps.getOptionLabel(values[0]);

    const childrenToRender = React.Children.toArray(children).filter(
      (child) =>
        ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );
    return (
      <components.ValueContainer {...props}>
        {childrenToRender}
        {!props.selectProps.inputValue && valueLabel ? (
          <div className="d-flex mln-3">
            <p className="max-w-230 text-truncate">
              <span className="fc-grey semi-bold">{startAdornment} :</span>{" "}
              {valueLabel}
            </p>
          </div>
        ) : (
          false
        )}
      </components.ValueContainer>
    );
  };

  return (
    <Select
      classNamePrefix="drop-select"
      styles={customStylesOwner}
      components={{ Menu, ValueContainer }}
      id="program-filter"
      defaultValue={value}
      onChange={(e) => {
        handleChange(e);
      }}
      options={options}
      formatOptionLabel={formatOptionLabel}
      filterOption={customFilter}
      getOptionLabel={(option) => `${option.programName} `}
    />
  );
};
export default MobileProgramFilter;
