import React from "react";
import { SvgArrow } from "src/icons";
import { InfiniteLoad } from "../infiniteLoad";
import "./ticker.scss";
import useTicker from "./useTicker";

const Ticker = ({ label, itemCount, handleNext, handleBack, children }) => {
  const ticker = useTicker({ itemCount });

  return (
    <div className="flex ticker center">
      {label}
      <div className="w-100 ticker-container" ref={ticker.tickerRef}>
        <div className="ticker-content" ref={ticker.tickerContentRef}>
          {children}
          {ticker.playing ? (
            <InfiniteLoad
              bottomOffset="-50%"
              container={ticker.tickerRef.current}
              horizontal
              handleLoadMore={handleNext}
            />
          ) : null}
        </div>
      </div>
      <div className="flex">
        <button
          type="button"
          className="ticker-btn mr-1"
          onClick={ticker.onBack}
          disabled={!ticker.playing}
        >
          <SvgArrow className="back" />
        </button>
        <button
          disabled={ticker.isAtEnd || !ticker.playing}
          type="button"
          className="ticker-btn"
          onClick={ticker.onNext}
        >
          <SvgArrow className="next" />
        </button>
      </div>
    </div>
  );
};

export default Ticker;
