import { STATIC_REGEX_FOR_BETWEEN_FUNCTION } from "src/constants";
import { checkValue } from "src/helpers";
import formulaNamespace from "./formulaNamespace";

const {
  TOKEN_TYPE,
  FORMULA_OPERATOR_TYPE,
  FORMULA_OPERATOR_SYMBOLS_MAP,
  FORMULA_OPERATORS,
} = formulaNamespace;

const getOperator = ({ value }) => {
  if (typeof value === "string") {
    const newValue = value.trim().toUpperCase();
    const operator = FORMULA_OPERATOR_SYMBOLS_MAP[newValue];
    if (operator) {
      return {
        tokenType: TOKEN_TYPE.OPERATOR,
        operatorTokenType: FORMULA_OPERATORS[operator].operatorTokenType,
        value: operator,
      };
    }
  }

  return undefined;
};

const getStatic = ({ value }) => {
  if (typeof value === "string") {
    const newValue = value.trim();
    if (newValue.match(STATIC_REGEX_FOR_BETWEEN_FUNCTION)) {
      const [firstNumber, secondNumber] = newValue.toLowerCase().split("and");
      return {
        tokenType: TOKEN_TYPE.STATIC,
        value: `${firstNumber.trim()} AND ${secondNumber.trim()}`,
        fieldName: FORMULA_OPERATOR_TYPE.BETWEEN,
      };
    }
    if (checkValue(newValue)) {
      return {
        tokenType: TOKEN_TYPE.STATIC,
        value: newValue,
      };
    }
  }

  return undefined;
};

export const getToken = ({ value, index }) => {
  const token = value?.tokenType
    ? value
    : getOperator({ value }) || getStatic({ value });

  return token ? { ...token, index } : token;
};

const formulaTokenizer = {
  getToken,
};

export default formulaTokenizer;
