import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  addDays,
  classNames,
  customHeading,
  differenceInDays,
  endOfDay,
  getPluralText,
  groupByTimePeriod,
  heading,
  startOfDay,
} from "src/helpers";
import "./recurrenceList.scss";

const RecurrenceList = (props) => {
  const {
    as,
    customType,
    recurrences,
    recurrenceType,
    handleClick,
    clickable,
    hoverable,
    optionLabelColor,
    optionBadgeColor,
    formatOptionLabelBefore,
    formatOptionLabelAfter,
    customClassName,
  } = props;

  const objPeriod = groupByTimePeriod(recurrences, recurrenceType);
  const periods = Object.keys(objPeriod)
    .map((i) => parseFloat(i))
    .sort();

  return periods.map((obj, idx) => (
    <ul key={idx}>
      <li
        className={`fs-12 semi-bold pt-3 pb-2 fc-grey-primary recurrence-list__title ${
          formatOptionLabelBefore ? "recurrence-list__title--has-icon" : ""
        }`}
      >
        {recurrenceType?.toLowerCase() === "custom"
          ? customHeading(obj)
          : heading(objPeriod[obj][0])}
      </li>
      {objPeriod[obj].map((item, idx) => (
        <Dropdown.Item
          as={as}
          key={idx}
          eventKey={item.RecurrenceId}
          className={`m-0 p-0 recurrence-list__item${
            hoverable ? "--hover" : ""
          } ${
            clickable ? "cursor-pointer" : "cursor-default"
          } center pr-12 flex-start`}
          onClick={clickable ? () => handleClick(item) : undefined}
        >
          {formatOptionLabelBefore && (
            <div className="recurrence-list__item-icon flex-center mr-2">
              {formatOptionLabelBefore({ ...item })}
            </div>
          )}
          <div
            className={classNames([
              "flex-between w-100 fs-12 recurrence-list__item-text py-12 pr-12",
              optionLabelColor ? optionLabelColor({ ...item }) : "",
              customClassName && `${customClassName}__item-text`,
            ])}
          >
            <div className="center recurrence-list__item-wrapper">
              <div className="recurrence-list__item-index">#{item.idx}:</div>
              <div className="ml-2 w-180">{item.value}</div>
              {recurrenceType?.toUpperCase() === "CUSTOM" &&
                customType === "NONUNIFORM" && (
                  <div
                    className={`fs-11 mr-2 br-13 px-2 py-1 non_uniform_days_badge ${
                      optionBadgeColor ? optionBadgeColor({ ...item }) : ""
                    }`}
                  >
                    {getPluralText(
                      differenceInDays(
                        endOfDay(addDays(item.RecurrenceEndDate, 1)),
                        startOfDay(item.RecurrenceStartDate)
                      ),
                      t("INCENTIVES.COMMON.MESSAGES.DAY"),
                      t("INCENTIVES.COMMON.MESSAGES.DAYS")
                    )}
                  </div>
                )}
            </div>
            {formatOptionLabelAfter && (
              <div className="fs-14 semi-bold">
                {formatOptionLabelAfter({ ...item })}
              </div>
            )}
          </div>
        </Dropdown.Item>
      ))}
    </ul>
  ));
};

RecurrenceList.propTypes = {
  as: PropTypes.string,
  recurrences: PropTypes.array.isRequired,
  recurrenceType: PropTypes.string.isRequired,
  clickable: PropTypes.bool,
  hoverable: PropTypes.bool,
  handleClick: PropTypes.func,
  optionLabelColor: PropTypes.func,
  formatOptionLabelBefore: PropTypes.func,
  formatOptionLabelAfter: PropTypes.func,
};

RecurrenceList.defaultProps = {
  clickable: false,
  hoverable: false,
};

export default RecurrenceList;
