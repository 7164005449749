import { APPS } from "src/constants";
import { useRbac } from "src/hooks";
import { Redirect, Route, Switch } from "src/router";
import { Pages } from "./pages";

export function Routes() {
  const { webMenu, mobileMenu } = useRbac();

  return (
    <Switch>
      <Route
        path={APPS.ACE.ROUTES.ERROR.path}
        page={Pages.ErrorPage}
        isPage
        exact
      />
      <Route
        path={APPS.ACE.ROUTES.ROOT.path}
        page={{
          web: () => <Redirect to={webMenu[0]?.route} />,
          mobile: () => <Redirect to={mobileMenu[0]?.route} />,
        }}
        isPage
      />
    </Switch>
  );
}
