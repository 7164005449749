import React from "react";
import PropTypes from "prop-types";
import "./shimmer.scss";

const DEFAULT_DURATION_MS = 1600;
const DEFAULT_HEIGHT = "8px";
const DEFAULT_WIDTH = "100%";

const calcShimmerStyle = (width, height, duration = DEFAULT_DURATION_MS) => {
  // ? split the letters into another string from width
  const widthUnit = width.toString().replace(/\d+/g, "").trim();
  const widthNumber = width.toString().replace(/\D+/g, "").trim();
  const heightUnit = height.toString().replace(/\d+/g, "").trim();
  const heightNumber = height.toString().replace(/\D+/g, "").trim();
  return {
    backgroundSize: `${
      widthNumber * 10
    }${widthUnit} ${heightNumber}${heightUnit}`,
    animationDuration: `${(duration / 1000).toFixed(1)}s`,
  };
};

const Shimmer = (props) => {
  const { className, duration, height, width } = props;
  const shimmerStyle = calcShimmerStyle(width, height, duration);
  const style = { ...shimmerStyle, height, width };
  return <div className={`shimmer br-8 ${className || ""}`} style={style} />;
};

Shimmer.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
};
Shimmer.defaultProps = {
  duration: DEFAULT_DURATION_MS,
  height: DEFAULT_HEIGHT,
  width: DEFAULT_WIDTH,
};

export default Shimmer;
