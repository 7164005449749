import React from "react";
import { Shimmer } from "src/components/common/atoms";

const LoadingGoalCreation = () => {
  return (
    <div className=" container-fluid d-flex justify-content-between">
      <div className="mx-4 mt-240 w-100">
        <div className="pt-3">
          <Shimmer width="25%" height="20px" />
        </div>
        <div className="pt-5 ">
          <Shimmer width="50%" height="10px" />
        </div>
        <div className="pt-3 mb-5 ">
          <Shimmer width="50%" height="10px" />
        </div>
        <div>
          <Shimmer width="200px" height="40px" />
        </div>
      </div>
    </div>
  );
};

export default LoadingGoalCreation;
