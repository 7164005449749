import { useCallback, useEffect, useState } from "react";

const useBackToTop = ({ modalRef }) => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    const scrollTopValue = modalRef?.current
      ? modalRef.current.scrollTop
      : window.pageYOffset;

    if (!showScroll && scrollTopValue > 400) {
      setShowScroll(true);
    } else if (showScroll && scrollTopValue <= 400) {
      setShowScroll(false);
    }
  }, [modalRef, showScroll]);

  useEffect(() => {
    let currentModalRef;
    if (modalRef?.current) {
      currentModalRef = modalRef.current;
      currentModalRef.addEventListener("scroll", checkScrollTop);
    }
    return () => {
      if (currentModalRef) {
        currentModalRef.removeEventListener("scroll", checkScrollTop);
      }
    };
  }, [modalRef?.current?.scrollTop, modalRef, showScroll, checkScrollTop]);

  const scrollTop = () => {
    if (modalRef) {
      modalRef.current.scrollTo({ top: 0, behavior: "smooth" });
    } else window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return { showScroll, scrollTop };
};

export default useBackToTop;
