import { t } from "i18next";
import Spinner from "react-bootstrap/Spinner";
import { FormButton } from "src/components/common/formElements";
import { classNames } from "src/helpers";
import { SvgError, SvgTick } from "src/icons";
import { Truncate } from "../../truncate";
import "./styles.scss";

export const OnLoad = ({ handleClick, Icon, disabled }) => {
  return (
    <FormButton
      className={classNames([
        "center",
        disabled ? "opacity-50 cursor-not-allowed" : "",
      ])}
      onClick={!disabled ? () => handleClick() : undefined}
      variant="link"
    >
      {Icon}
      <div className={classNames(["fs-12 ml-2"])}>
        {t("INCENTIVES.COMMON.MESSAGES.SELECT_FILE_TO_UPLOAD")}
      </div>
    </FormButton>
  );
};

export const OnUpload = ({ fileName, handleCancel }) => {
  return (
    <div className="center">
      <Spinner animation="grow" variant="primary" size="sm" />
      <div className="fs-12 semi-bold light ml-2 center">
        <span className="mr-1">{t("COMMON.BUTTONS.UPLOADING")}</span>
        <Truncate maxWidth="180px" text={fileName} />
      </div>
      <FormButton
        id="upload-cancel"
        onClick={handleCancel}
        className="p-0 ml-1"
        variant="link"
      >
        <span className="fs-12">{t("COMMON.BUTTONS.CANCEL")}</span>
      </FormButton>
    </div>
  );
};

export const OnSuccess = ({ fileName, handleRemove }) => {
  return (
    <div className="center">
      <SvgTick backgroundColor="#2ecc71" className="mr-2" />
      <span className="fs-12 semi-bold center">
        <Truncate text={fileName} maxWidth="200px" />
        <span className="ml-1">{t("COMMON.BUTTONS.UPLOADED")}</span>
      </span>
      <FormButton
        id="success-remove"
        className="p-0 ml-1"
        variant="link"
        onClick={handleRemove}
      >
        <span className="fs-12">{t("COMMON.BUTTONS.REMOVE")}</span>
      </FormButton>
    </div>
  );
};

export const OnError = ({ fileName, error, handleRemove }) => {
  return (
    <div className="center">
      <SvgError className="mr-2" />
      <div className="fs-12 semi-bold">
        <Truncate maxWidth="200px" text={error.message} />
      </div>
      <FormButton
        id="error-remove"
        className="p-0 ml-1"
        variant="link"
        onClick={handleRemove}
      >
        <span className="fs-12">{t("COMMON.BUTTONS.REMOVE")}</span>
      </FormButton>
    </div>
  );
};
