import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { range } from "src/helpers";
import {
  applyStyles,
  getCustomRowRange,
  getHeader,
  getRow,
  GLOBAL_STYLES,
  HEADER_STYLES,
} from "./data";
import { excelRowStyles } from "./excelRowStyles";

export const downloadExcel = async ({
  columns,
  data,
  index,
  recurrence,
  userId,
  goalAggConfig,
  fileName,
  isIndianTimezone,
  workSheetName,
}) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(workSheetName, {
    views: [{ state: "frozen", ySplit: 1, xSplit: 1 }],
  });

  worksheet.columns = getHeader({ columns, recurrence, index, goalAggConfig });

  data.forEach((rowData) => {
    worksheet.addRow(getRow({ rowData, isIndianTimezone, userId }));
  });

  // Row styles
  excelRowStyles({ data, worksheet, goalAggConfig, columns, userId });

  // Header styles
  getCustomRowRange(columns.length, 1).map((key) => {
    const cell = worksheet.getCell(key);
    return applyStyles(cell, HEADER_STYLES);
  });

  // Global styles
  range(0, data.length).forEach((i) => {
    getCustomRowRange(columns.length, i + 1).map((key) => {
      const cell = worksheet.getCell(key);
      return applyStyles(cell, GLOBAL_STYLES);
    });
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return saveAs(blob, `${fileName}.xlsx`);
  });
};
