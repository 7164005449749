import { lazy } from "react";

const WebPage = lazy(() =>
  import(
    /* webpackChunkName: 'incentives.web.dashboardIncentiveDetailsPage' */ "./web"
  )
);

const MobilePage = lazy(() =>
  import(
    /* webpackChunkName: 'incentives.mobile.dashboardIncentiveDetailsPage' */ "./mobile"
  )
);

export const DashboardIncentiveDetailsPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "dashboard-incentive-details",
  },
};
