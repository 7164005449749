import { t } from "i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AceGrid,
  ConfigureColumns,
  FormButton,
  LoadingTable,
  NumberCell,
  Truncate,
} from "src/components/common";
import {
  ATTRIBUTES_FORMATTERS,
  DRILLDOWN_ATTRIBUTES,
} from "src/config/userAttributes";
import { DATE_FORMAT, SORT_COLUMN } from "src/constants";
import {
  checkValue,
  format,
  getFullName,
  getLeadDetailsUrl,
} from "src/helpers";
import { SvgLink } from "src/icons";
import { NoResults } from "src/images";

const NameFormatter = ({ row: { original } }) => {
  return (
    <div className="fs-13">
      <Truncate
        width="150px"
        text={getFullName(original.UserFirstName, original.UserLastName)}
      />
      <div className=" fc-grey">
        <Truncate width="150px" text={original.EmailAddress} />
      </div>
    </div>
  );
};

const DateFormatter = ({ value }) =>
  value ? format(new Date(Date.parse(value)), DATE_FORMAT) : "";

const AchievementBreakDownTable = (props) => {
  const {
    usersAttributeList,
    fieldsOrder,
    setFieldsOrder,
    tableData,
    dataNotAvailable,
    gridLoading,
    handleDoneButton,
    isSalesUser,
    hideBreakdownTable,
    userNotPartOfRecurrence,
  } = props;

  const handleOpenNewTab = (url) => {
    window.open(url, "_blank");
  };

  const LeadNameFormatter = useCallback(({ row: { original } }) => {
    return (
      <FormButton
        onClick={() => handleOpenNewTab(getLeadDetailsUrl(original.ProspectId))}
        variant="link"
        className="w-auto flex-between"
      >
        <Truncate
          text={getFullName(
            original.ProspectFirstName,
            original.ProspectLastName
          )}
          maxWidth="180px"
        />
        <SvgLink className="ml-1" />
      </FormButton>
    );
  }, []);

  const fixedColumns = useMemo(
    () => [
      {
        Header: t("COMMON.BUTTONS.NAME"),
        accessor: "UserFirstName",
        className: "user-column",
        width: 200,
        sticky: "left",
        Cell: NameFormatter,
      },
      {
        Header: t("COMMON.LABELS.LEAD_NAME"),
        accessor: "ProspectFirstName",
        width: 200,
        Cell: LeadNameFormatter,
      },
      {
        Header: t("COMMON.LABELS.FIELD"),
        accessor: "EntityValue",
        width: 100,
        Cell: NumberCell,
      },
      {
        Header: t("COMMON.LABELS.DATE"),
        accessor: "EntityDate",
        width: 150,
        Cell: DateFormatter,
      },
    ],
    [LeadNameFormatter]
  );
  const filterFieldsOrder = useCallback(
    (field) => {
      return isSalesUser
        ? DRILLDOWN_ATTRIBUTES.map(({ SchemaName }) => SchemaName).includes(
            field
          )
        : true;
    },
    [isSalesUser]
  );
  const updatedColumns = useMemo(() => {
    return fieldsOrder?.filter(filterFieldsOrder).map((attr) => {
      return {
        Header: usersAttributeList[attr]?.DisplayName,
        accessor: attr,
        width: 200,
        ...SORT_COLUMN,
        Cell: ATTRIBUTES_FORMATTERS[attr]
          ? ATTRIBUTES_FORMATTERS[attr]
          : ({ value }) => <>{checkValue(value) ? `${value}` : ""}</>,
      };
    });
  }, [fieldsOrder, filterFieldsOrder, usersAttributeList]);

  const [columns, setColumns] = useState(fixedColumns);

  useEffect(() => {
    setColumns([...fixedColumns, ...updatedColumns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedColumns]);

  if (gridLoading) {
    return (
      <div className="p-4">
        <LoadingTable height="400px" />
      </div>
    );
  }
  return (
    <div className="position-relative m-4">
      <AceGrid
        id="roll-up-table"
        height="400px"
        columns={columns}
        data={hideBreakdownTable ? [] : tableData}
        className="break-down-table simple-grid"
        userNotFound={
          userNotPartOfRecurrence
            ? t("MANAGED_GOALS.LABELS.NOT_PART_OF_RECURRENCE")
            : dataNotAvailable
            ? t("COMMON.MESSAGES.CONTACT_DATA_TEAM")
            : t("COMMON.MESSAGES.DATA_NOT_FOUND")
        }
        noOfRightFixedColumns={fixedColumns.length}
        noResultsIcon={<NoResults />}
        noResultsIconClassName="no-results-achievement-breakdown"
      />
      {!isSalesUser && !hideBreakdownTable && tableData?.length ? (
        <ConfigureColumns
          onShowClick
          fieldsOrder={fieldsOrder}
          setFieldsOrder={setFieldsOrder}
          handleDoneButton={handleDoneButton}
          usersAttributeList={usersAttributeList}
        />
      ) : null}
    </div>
  );
};
export default AchievementBreakDownTable;
