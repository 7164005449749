import {
  SvgGamification,
  SvgGoal,
  SvgIncentives,
  SvgLeaderboard,
  SvgNudge,
} from "src/icons";
import {
  AngelOne,
  ClixCapital,
  CollegeVidya,
  Damsungroup,
  Deekshalearning,
  Edubridgeindia,
  Fairmoney,
  Hdfc,
  HeroHousingFinance,
  Inmakes,
  Medall,
  Oxbridgeacademy,
  Pharmeasyone,
  Proptiger,
  Rcm,
  RichField,
  Thomsondigital,
  UnextLearningPrivateLimited,
  XylemLearningPrivateLimited,
} from "src/images/pngs/customersPng";

export const LANDING_PAGE_PRODUCT_LIST = [
  { icon: SvgGoal, title: "COMMON.BUTTONS.GOALS" },
  { icon: SvgIncentives, title: "INCENTIVES.COMMON.LABELS.INCENTIVES" },
  { icon: SvgLeaderboard, title: "LEADERBOARD.COMMON.LABELS.LEADERBOARD" },
  { icon: SvgNudge, title: "NUDGES.LABELS.NUDGES" },
  { icon: SvgGamification, title: "COMMON.BUTTONS.GAMIFICATION" },
];

export const CUSTOMERS = [
  AngelOne,
  Hdfc,
  CollegeVidya,
  RichField,
  HeroHousingFinance,
  Damsungroup,
  Deekshalearning,
  Edubridgeindia,
  Fairmoney,
  Inmakes,
  Medall,
  Oxbridgeacademy,
  Pharmeasyone,
  Proptiger,
  Rcm,
  Thomsondigital,
  UnextLearningPrivateLimited,
  XylemLearningPrivateLimited,
  ClixCapital,
];
