import React from "react";

const SvgAvatar33 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="r0io__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .r0io__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="r0io__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.07-8.78 42.07-23.06 56.74-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08-14.26 13.63-33.5 22.13-54.71 22.5-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-21.21-.37-40.45-8.87-54.72-22.5-.71-.68-1.41-1.38-2.1-2.08l-.05-.05C8.78 123.47 0 103.48 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="r0io__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="r0io__cls-2"
        />
        <path
          d="m122.5 127.28-8.91-13.04c-.29-.43-.79-.64-1.26-.65l-32.21-.34h-.03l-29.62.34c-.48 0-.96.22-1.26.65l-8.9 13.04-17.26 10.87.05.05c.69.7 1.39 1.4 2.1 2.08l16.09-10.13 23.28 17.23 2.14-.42 7.59-12.58 5.62 9.76v18.66h2.96v-18.66l5.62-9.76 7.6 12.58 2.14.42 23.27-17.23 16.09 10.13 2.15-2.12v-.02l-17.25-10.86Zm-57.48 16.76-21.64-16.02 7.45-10.91 21.7 14.49-7.51 12.44Zm22.44-14.11-.12.56-5.94 10.3-5.93-10.29-.13-.57-20.12-13.44 24.89-.29 27.47.3-20.12 13.43Zm10.33 14.11-7.51-12.44 21.7-14.49 7.45 10.91-21.64 16.02Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar33.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar33;
