import React, { useState } from "react";
import { useGetGoalTemplateDetails } from "src/api/useTemplate";
import { CreateGoalTemplateModal } from "src/components";
import {
  CreateGoalModal,
  Popover,
  Truncate,
  toaster,
} from "src/components/common/atoms";
import { TemplateDetailModal } from "src/components/common/layouts";
import { errorText } from "src/helpers";
import PopoverContent from "./popoverContent";

function GoalTemplatePopover(props) {
  const {
    goal,
    type,
    tabIndex,
    hideDetails = false,
    editAccess = true,
    hideEditOption = false,
    recurrenceList,
    id,
    refetch,
  } = props;

  const {
    data: templateDetails,
    error: templateDetailsError,
    refetch: refetchTemplate,
  } = useGetGoalTemplateDetails({
    skip: !editAccess || !goal.TemplateId,
    variables: { TemplateId: goal.TemplateId },
  });

  const templateDetailsResults = templateDetails?.template?.response?.Template;

  const [templateDetailModalShow, setTemplateDetailModalshow] = useState(false);
  const [showGoalCreateModal, setGoalCreateModalShow] = useState(false);
  const [templateCreateModal, setTemplateCreateModal] = useState(false);

  if (templateDetails?.template?.__typename === "ErrorResponse") {
    toaster({
      message: templateDetails.template?.message,
      type: "error",
    });
    return null;
  }

  if (templateDetailsError) {
    toaster({
      message: errorText.GET_TEMPLATE_DETAIL.ERROR,
      type: "error",
    });
    return null;
  }

  const name = !editAccess
    ? goal?.Name
    : goal
    ? goal?.Name
    : templateDetailsResults
    ? templateDetailsResults?.Name
    : "";
  const desc = !editAccess
    ? goal.Description
    : goal
    ? goal?.Description
    : templateDetailsResults
    ? templateDetailsResults?.Description
    : "";

  const popoverTriggerButton = (
    <Popover
      id={id}
      popoverBody={
        <PopoverContent
          goal={goal}
          desc={desc}
          name={name}
          hideDetails={hideDetails}
          setTemplateDetailModalshow={setTemplateDetailModalshow}
          hideEditOption={hideEditOption}
          templateDetailsResults={templateDetailsResults}
          templateDetailModalShow={templateDetailModalShow}
          setTemplateCreateModal={setTemplateCreateModal}
          setGoalCreateModalShow={setGoalCreateModalShow}
          type={type}
          recurrenceList={recurrenceList}
        />
      }
    />
  );

  return [
    <div className="fs-16 semi-bold center space-x-8">
      <Truncate id="popover-name" maxWidth="300px" text={name} />
      <div className="position-relative">{popoverTriggerButton}</div>
    </div>,

    templateDetailModalShow && (
      <TemplateDetailModal
        name={templateDetailsResults?.Name}
        goalAggregateFunction={templateDetailsResults?.GoalAggregateFunction}
        description={templateDetailsResults?.Description}
        goalRules={templateDetailsResults?.GoalRules}
        isCustomFormula={templateDetailsResults?.IsCustomFormula}
        formula={templateDetailsResults?.Formula}
        onShowClick
        onCancelClick={() => setTemplateDetailModalshow(false)}
        groupBy={templateDetailsResults?.GroupBy}
      />
    ),
    showGoalCreateModal && (
      <CreateGoalModal
        tabIndex={1}
        onShowClick
        onCancelClick={() => setGoalCreateModalShow(false)}
        onSuccess={() => {
          setGoalCreateModalShow(false);
        }}
        status="update"
        goalID={`${goal?.GoalId}`}
        goalName={goal?.Name}
        goalDescription={goal?.Description}
        goalStatus={goal?.Status}
        refetch={refetch}
      />
    ),
    templateCreateModal && (
      <CreateGoalTemplateModal
        tabIndex={tabIndex}
        onShowClick
        onCancelClick={() => setTemplateCreateModal(false)}
        status="update"
        templateStatus={templateDetailsResults.Status}
        templateID={`${templateDetailsResults.TemplateId}`}
        templateName={templateDetailsResults.Name}
        templateDescription={templateDetailsResults.Description}
        onSuccess={() => setTemplateCreateModal(false)}
        refetch={refetchTemplate}
      />
    ),
  ];
}

export default GoalTemplatePopover;
