import { RequestDemoPage } from "src/components";
import { AssignedGoalViewPage } from "./assignedGoalView";
import { AssignedToMePage } from "./assignedToMe";
import { GoalCrudPage } from "./goalCrud";
import { LandingPage } from "./landing";
import { ManagedByMePage } from "./managedByMe";
import { PublishedGoalViewPage } from "./publishedGoalView";
import { RulesetsPage } from "./rulesets";

export const Pages = {
  AssignedGoalViewPage,
  AssignedToMePage,
  GoalCrudPage,
  LandingPage,
  ManagedByMePage,
  PublishedGoalViewPage,
  RequestDemoPage,
  RulesetsPage,
};
