import { gql, useQuery } from "src/api/client";

const GET_DASHBOARD_PROGRAM_USER_ACHIEVEMENTS = gql`
  query dashboardProgramUserAchievements(
    $UserId: ID
    $ProgramId: ID
    $RecurrenceId: ID
    $limit: Float
    $after: String
    $query: PayoutUsersQuery
    $GroupId: ID
  ) {
    dashboardProgramUserAchievements(
      UserId: $UserId
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      limit: $limit
      after: $after
      query: $query
      GroupId: $GroupId
    ) {
      ... on DashboardUserAchievementResponse {
        success
        message
        response {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
          totalCount
          totalUsersCount
          dashboardUserAchievements {
            UserID
            FullName
            EmailAddress
            Templates
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useGetDashboardLeaderboardUserAchievements = (queryOptions) => {
  return useQuery(GET_DASHBOARD_PROGRAM_USER_ACHIEVEMENTS, queryOptions);
};
