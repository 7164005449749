import { gql, useQuery } from "src/api/client";

const GET_WALL_OF_LEGENDS_LIST = gql`
  query getWallOfLegendsList {
    getWallOfLegendsList {
      ... on WallOfLegendsListResponse {
        success
        response {
          wallOfLegendsList {
            CycleType
            Users {
              Name
              UserId
              IsEqual
            }
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useWallOfLegends = (queryOptions) => {
  return useQuery(GET_WALL_OF_LEGENDS_LIST, queryOptions);
};
