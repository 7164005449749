import { gql, useQuery } from "src/api/client";

const GET_TOP_PERFORMERS_LIST = gql`
  query getTopPerformers($ProgramId: ID!) {
    getTopPerformers(ProgramId: $ProgramId) {
      ... on TopPerformersResponse {
        success
        response {
          Users {
            Name
            Rank
            UserId
          }
          totalCount
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetTopPerformers = (queryOptions) => {
  return useQuery(GET_TOP_PERFORMERS_LIST, queryOptions);
};
