import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgEllipsis = (props) => {
  const { color, height, width, rotate, styles, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...styles,
        transform: `rotate(${rotate}deg)`,
        transformOrigin: "50% 50%",
      }}
      {...rest}
    >
      <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2302" />
      <g fill={color} data-name="More" transform="translate(10 3)">
        <circle cx={2} cy={2} r={2} data-name="Ellipse 1" />
        <circle
          cx={2}
          cy={2}
          r={2}
          data-name="Ellipse 23"
          transform="translate(0 7)"
        />
        <circle
          cx={2}
          cy={2}
          r={2}
          data-name="Ellipse 24"
          transform="translate(0 14)"
        />
      </g>
    </svg>
  );
};

SvgEllipsis.defaultProps = {
  color: COLORS.GREY_2,
  height: 24,
  width: 24,
  rotate: 0,
  styles: {},
};
export default SvgEllipsis;
