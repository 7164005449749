import { REGEX_FOR_VALID_VARIABLE_NAME } from "src/constants";
import formulaNamespace from "./formulaNamespace";

const { TOKEN_TYPE, FORMULA_OPERATORS, FORMULA_PARENTHESES, FORMULA_TOKENS } =
  formulaNamespace;

const getNamespaceValue = ({ value, values = [] }) => {
  return values.find((item) => {
    return item.value === value;
  });
};

const getVariable = ({ value, namespace }) => {
  if (
    namespace &&
    namespace.values &&
    getNamespaceValue({ value, values: namespace.values })
  ) {
    return {
      type: TOKEN_TYPE.VARIABLE,
      value,
    };
  }

  if (!namespace.values && value.match(REGEX_FOR_VALID_VARIABLE_NAME)) {
    return {
      type: TOKEN_TYPE.VARIABLE,
      value,
    };
  }

  return undefined;
};

const getOperator = ({ value, namespace }) => {
  const operator = FORMULA_OPERATORS[value];
  if (!namespace || !operator) {
    return undefined;
  }

  if (
    namespace.values &&
    !getNamespaceValue({ value: operator.value, values: namespace.values })
  ) {
    return undefined;
  }

  return {
    type: TOKEN_TYPE.OPERATOR,
    value: operator.value,
  };
};

const getConstant = ({ value, namespace }) => {
  if (!namespace) {
    return undefined;
  }
  const constant = Number(value);
  if (!Number.isNaN(constant)) {
    return {
      type: TOKEN_TYPE.CONSTANT,
      value: constant,
      validations: namespace.validations,
    };
  }
  return NaN;
};

const getParentheses = ({ value, namespace }) => {
  const parentheses = FORMULA_PARENTHESES[value];
  if (!namespace || !parentheses) {
    return undefined;
  }
  if (
    namespace.values &&
    !getNamespaceValue({ value: parentheses.value, values: namespace.values })
  ) {
    return undefined;
  }
  return {
    type: TOKEN_TYPE.PARENTHESIS,
    value: parentheses.value,
  };
};

export const getUsedNamespace = (config = []) => {
  const usedNamespace = {};
  config.forEach((item) => {
    if (TOKEN_TYPE[item.type]) {
      usedNamespace[item.type] = { ...item };
      if (FORMULA_TOKENS[item.type].values && item.values) {
        usedNamespace[item.type].values = item.values
          .filter((value) => {
            return FORMULA_TOKENS[item.type].values[value.type];
          })
          .map((value) => ({
            ...value,
            value: FORMULA_TOKENS[item.type].values[value.type].value,
          }));
      }
    }
  });
  return usedNamespace;
};

export const getToken = ({ value, namespace, index }) => {
  const newValue = value.trim();
  if (newValue === "") {
    return undefined;
  }
  const token =
    getVariable({
      value: newValue,
      namespace: namespace[TOKEN_TYPE.VARIABLE],
    }) ||
    getOperator({
      value: newValue,
      namespace: namespace[TOKEN_TYPE.OPERATOR],
    }) ||
    getParentheses({
      value: newValue,
      namespace: namespace[TOKEN_TYPE.PARENTHESIS],
    }) ||
    getConstant({ value: newValue, namespace: namespace[TOKEN_TYPE.CONSTANT] });
  return token ? { ...token, index } : token;
};

const formulaTokenizer = {
  getUsedNamespace,
  getToken,
};

export default formulaTokenizer;
