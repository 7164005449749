import { gql, useQuery } from "src/api/client";
import {
  CORE_GOAL_DETAILS_FRAGMENT,
  ERROR_RESPONSE_FRAGMENT,
} from "src/api/fragments";

// src/modules/landingPage/managedByMe/Goals.js
const GET_ALL_MANAGED_GOALS_QUERY = gql`
  ${CORE_GOAL_DETAILS_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
  query getGoals($UserId: ID, $Limit: Float, $Query: JSON, $After: String) {
    getGoals(UserId: $UserId, Limit: $Limit, Query: $Query, After: $After) {
      ... on AllGoalResponse {
        success
        message
        response {
          totalCount
          hasGoals
          Goals {
            ...coreGoalDetailsFragment
            GoalAggConfig
            GoalType
            GoalAchieved
            RecurrenceDefinition
            CurrentRecurrence
            FutureCycleDuration
            AvgSum
            AvgCount
            AvgUserCount
            IsRollUp
            GoalUsersCount
            UsersAchievedCount
            InGaps
            Template {
              GoalAggregateFunction
            }
          }
          pageInfo {
            endCursor
          }
        }
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetAllManagedGoals = (queryOptions) => {
  return useQuery(GET_ALL_MANAGED_GOALS_QUERY, queryOptions);
};

export const allManagedGoalsMocks = [
  {
    request: {
      query: GET_ALL_MANAGED_GOALS_QUERY,
      variables: {
        After: null,
        Limit: 9,
        Query: { GoalStatus: "INPROGRESS" },
        UserId: "c06ade8c-146c-11ec-9cb6-02939bdb3318",
      },
    },
    result: {
      data: {
        getGoals: {
          success: true,
          message: "Success",
          response: {
            totalCount: 4,
            hasGoals: true,
            Goals: [
              {
                GoalId: "90d22bd0-3811-42ac-9fa8-3ca1bad12598",
                Name: "goal name satiya 3",
                Description: "",
                Status: "PUBLISHED",
                GoalType: "EFFORT",
                GoalValue: 232,
                GoalAchieved: null,
                RecurrenceDefinition: {
                  FirstRecurrenceStartDate: "2022-01-01",
                  LastRecurrenceEndDate: "2022-12-31",
                  RecurrencePeriod: "QUARTERLY",
                },
                TotalCycles: "4 Quarterly cycles",
                CurrentRecurrenceCycle: "2nd cycle in progress",
                RecurrenceTimeLeft: "12 days remaining",
                Template: {
                  GoalAggregateFunction: "SUM",
                  __typename: "Template",
                },
                CurrentRecurrence: "2 of 4",
                __typename: "Goal",
              },
              {
                GoalId: "d69a1261-75ea-48d2-bf12-b1902e9fc3a2",
                Name: "Dummy",
                Description: "",
                Status: "PUBLISHED",
                GoalType: "EFFORT",
                GoalValue: 600,
                GoalAchieved: null,
                RecurrenceDefinition: {
                  FirstRecurrenceStartDate: "2022-05-25",
                  LastRecurrenceEndDate: "2022-07-05",
                  RecurrencePeriod: "WEEKLY",
                },
                TotalCycles: "6 Weekly cycles",
                CurrentRecurrenceCycle: "4th cycle in progress",
                RecurrenceTimeLeft: "3 days remaining",
                Template: {
                  GoalAggregateFunction: "COUNT",
                  __typename: "Template",
                },
                CurrentRecurrence: "4 of 6",
                __typename: "Goal",
              },
              {
                GoalId: "208a30db-5a49-4a64-804a-8b4b771649ac",
                Name: "Testing Rollup",
                Description: "",
                Status: "PUBLISHED",
                GoalType: "EFFORT",
                GoalValue: null,
                GoalAchieved: null,
                RecurrenceDefinition: {
                  FirstRecurrenceStartDate: "2022-05-01",
                  LastRecurrenceEndDate: "2022-12-31",
                  RecurrencePeriod: "MONTHLY",
                },
                TotalCycles: "8 Monthly cycles",
                CurrentRecurrenceCycle: "2nd cycle in progress",
                RecurrenceTimeLeft: "12 days remaining",
                Template: {
                  GoalAggregateFunction: "DISTINCT_COUNT",
                  __typename: "Template",
                },
                CurrentRecurrence: "2 of 8",
                __typename: "Goal",
              },
              {
                GoalId: "9002ca9d-71e4-4f0c-9e1d-5b81db8c119a",
                Name: "testing assigned-to-me",
                Description: "",
                Status: "PUBLISHED",
                GoalType: "EFFORT",
                GoalValue: null,
                GoalAchieved: null,
                RecurrenceDefinition: {
                  FirstRecurrenceStartDate: "2022-05-01",
                  LastRecurrenceEndDate: "2022-08-07",
                  RecurrencePeriod: "DAILY",
                },
                TotalCycles: "99 Daily cycles",
                CurrentRecurrenceCycle: "50th cycle in progress",
                RecurrenceTimeLeft: "1 day remaining",
                Template: {
                  GoalAggregateFunction: "COUNT",
                  __typename: "Template",
                },
                CurrentRecurrence: "50 of 99",
                __typename: "Goal",
              },
            ],
            pageInfo: {
              endCursor:
                "WyIyMDIyLTA1LTExVDA2OjA4OjUzLjA5NloiLCI5MDAyY2E5ZC03MWU0LTRmMGMtOWUxZC01YjgxZGI4YzExOWEiXQ==",
              __typename: "PageInfo",
            },
            __typename: "GoalResponse",
          },
          __typename: "AllGoalResponse",
        },
      },
    },
  },
];
