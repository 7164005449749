import React from "react";

const SvgAvatar48 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="x8ej__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .x8ej__cls-1{fill:#fff}.x8ej__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="x8ej__Layer_1-2">
        <path
          d="M162.8 81.4a81.686 81.686 0 0 1-.37 7.78c-.07.78-.16 1.56-.26 2.33-.09.75-.2 1.5-.31 2.24 0 .03-.01.06-.01.09-.1.66-.21 1.32-.33 1.98-.12.7-.25 1.39-.4 2.07-.14.69-.29 1.38-.45 2.06-3.41 14.64-10.78 27.76-20.93 38.19-.01.02-.03.04-.05.06-.69.7-1.39 1.4-2.1 2.08a81.014 81.014 0 0 1-27.23 17.21 80.58 80.58 0 0 1-27.48 5.29c-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-9.19-.16-18.01-1.84-26.21-4.82-.86-.31-1.72-.63-2.57-.98-9.72-3.89-18.5-9.6-25.94-16.7-.44-.42-.88-.86-1.32-1.29-.27-.27-.52-.53-.78-.79-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.76}
          className="x8ej__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.085)"
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="x8ej__cls-2"
        />
        <path
          d="M88.43 126.86c-.22-.22-.52-.34-.83-.34h-3.49v-3.5c0-.32-.13-.61-.35-.82-.22-.23-.51-.35-.83-.35h-4.66c-.64 0-1.17.53-1.17 1.17v3.5h-3.5c-.64 0-1.16.52-1.16 1.16v4.67c0 .32.11.61.34.83.21.22.52.35.82.35h3.5v3.49c0 .31.12.61.35.83.21.21.51.34.82.34h4.66c.32 0 .61-.13.83-.34.22-.22.35-.52.35-.83v-3.49h3.49c.31 0 .61-.13.83-.35.21-.22.34-.51.34-.83v-4.67c0-.3-.13-.6-.34-.82Zm-1.99 4.33h-3.51c-.64 0-1.16.52-1.16 1.16v3.5h-2.33v-3.5c0-.3-.13-.61-.34-.82a1.19 1.19 0 0 0-.83-.34h-3.51v-2.34h3.51c.31 0 .6-.12.83-.33.21-.23.34-.52.34-.84v-3.5h2.33v3.5c0 .32.13.61.34.84.23.21.52.33.82.33h3.51v2.34ZM122.76 120.54c-.66-.66-1.55-1.02-2.48-1.02s-1.82.36-2.48 1.02c-.65.66-1.02 1.55-1.02 2.48a3.496 3.496 0 0 0 3.5 3.5c.93 0 1.82-.37 2.48-1.03s1.02-1.54 1.02-2.47-.37-1.82-1.02-2.48Zm-1.65 3.3c-.34.34-.84.43-1.28.25s-.72-.6-.72-1.07c0-.64.52-1.17 1.17-1.17.47 0 .9.29 1.08.72.18.44.08.94-.25 1.27ZM122.76 134.54c-.66-.65-1.55-1.01-2.48-1.01s-1.82.36-2.48 1.01c-.65.67-1.02 1.56-1.02 2.48s.37 1.82 1.02 2.48c.66.65 1.55 1.02 2.48 1.02s1.82-.37 2.48-1.02c.65-.66 1.02-1.55 1.02-2.48s-.37-1.81-1.02-2.48Zm-1.65 3.31c-.23.21-.52.34-.83.34-.47 0-.9-.29-1.08-.73-.18-.42-.08-.93.25-1.26.34-.34.85-.44 1.27-.26.44.18.73.61.73 1.08 0 .31-.13.61-.34.83ZM115.75 127.55c-.66-.66-1.55-1.03-2.47-1.03s-1.82.37-2.47 1.03c-.66.65-1.03 1.54-1.03 2.47s.37 1.82 1.03 2.48c.65.65 1.54 1.03 2.47 1.03s1.81-.38 2.47-1.03c.66-.66 1.03-1.55 1.03-2.48s-.37-1.82-1.03-2.47Zm-1.65 3.29c-.33.34-.83.44-1.26.26-.44-.18-.73-.61-.73-1.08 0-.65.52-1.17 1.17-1.17.47 0 .9.29 1.08.73.18.43.08.93-.26 1.26ZM129.75 127.55c-.65-.66-1.54-1.03-2.47-1.03s-1.82.37-2.47 1.03c-.65.65-1.03 1.54-1.03 2.47s.38 1.82 1.03 2.48c.65.65 1.55 1.03 2.47 1.03s1.82-.38 2.47-1.03c.67-.66 1.03-1.55 1.03-2.48s-.36-1.82-1.03-2.47Zm-1.65 3.29c-.32.34-.83.44-1.26.26-.44-.18-.72-.61-.72-1.08 0-.65.52-1.17 1.16-1.17.47 0 .9.29 1.08.73.18.43.08.93-.26 1.26Z"
          className="x8ej__cls-1"
        />
        <path
          d="M100.45 89.18h61.98c.07-.78.14-1.56.19-2.34h-62.17c-.94 0-1.83.38-2.49 1.03-.65.65-1.02 1.55-1.02 2.47v17.5H94.6c-.64 0-1.16.53-1.16 1.18v3.49H81.77c-9 0-16.34 7.86-16.34 17.51s7.34 17.51 16.34 17.51c6.18 0 12.03-4.2 14.78-10.51h7.78c2.75 6.31 8.6 10.51 14.78 10.51 9.01 0 16.34-7.85 16.34-17.51s-7.33-17.51-16.34-17.51h-11.67v-3.49c0-.32-.12-.61-.34-.83a1.16 1.16 0 0 0-.82-.35h-2.34v-14h57.91s.01-.06.01-.09c.11-.74.22-1.49.31-2.24h-59.4c-.64 0-1.16.52-1.16 1.17v15.16h-2.34v-17.5c0-.64.52-1.16 1.18-1.16Zm18.66 25.67c7.72 0 14 6.8 14 15.17s-6.28 15.17-14 15.17c-5.46 0-10.65-3.92-12.91-9.76-.18-.45-.61-.74-1.09-.74h-9.34c-.48 0-.91.29-1.09.74-2.26 5.84-7.45 9.76-12.91 9.76-7.72 0-14-6.8-14-15.17s6.28-15.17 14-15.17h37.34Zm-14-4.67v2.33h-9.34v-2.33h9.34Z"
          className="x8ej__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar48.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar48;
