import PropTypes from "prop-types";
import React from "react";
import { COLORS } from "src/constants";
import { getAvatar } from "src/helpers";
import Avatar from "../../Avatar";
import RankBadge from "../rankBadge/RankBadge";

const UserAvatar = ({
  userId,
  imgProps,
  rank,
  border,
  borderWidth,
  borderColor,
  className,
}) => {
  const {
    AvatarIcon,
    color: { backgroundColor, avatarColor },
  } = getAvatar(userId);

  return (
    <Avatar
      src={
        <AvatarIcon
          backgroundColor={backgroundColor}
          avatarColor={avatarColor}
          style={{ "--avatar-dark": avatarColor }}
          {...imgProps}
        />
      }
      badgeProps={{
        showBadge: rank,
        badge: (
          <RankBadge size="18px" rank={rank} className="mt-1" outline={false} />
        ),
        position: "bottom-end",
      }}
      borderProps={{
        border,
        borderWidth,
        borderColor,
      }}
      className={className}
    />
  );
};

UserAvatar.propTypes = {
  userId: PropTypes.string.isRequired,
  imgProps: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  rank: PropTypes.number,
  border: PropTypes.bool,
  borderWidth: PropTypes.string,
  borderColor: PropTypes.string,
  className: PropTypes.string,
};

UserAvatar.defaultProps = {
  userId: "",
  imgProps: {
    width: "40px",
    height: "40px",
  },
  rank: null,
  border: false,
  borderWidth: "2px",
  borderColor: COLORS.WHITE,
  className: "",
};

export default UserAvatar;
