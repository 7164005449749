import React from "react";
import { FormButton } from "src/components/common";
import { SvgDelete, SvgRoundedAdd, SvgUnderlineEdit } from "src/icons";

const SubGoalCrud = ({
  handleAdd,
  handleDelete,
  handleEdit,
  enableAdd = false,
  enableDelete = true,
  enableEdit = true,
}) => {
  return (
    <div className="flex gap-2 ml-2 center">
      {enableEdit && (
        <FormButton
          icon={<SvgUnderlineEdit height={14} width={14} />}
          className="bg-blue p-2 br-4"
          variant="link"
          onClick={handleEdit}
        />
      )}
      {enableAdd && (
        <FormButton
          icon={<SvgRoundedAdd height={14} width={14} />}
          className="bg-blue p-2 ml-2 br-4"
          variant="link"
          onClick={handleAdd}
        />
      )}
      {enableDelete && (
        <FormButton
          icon={<SvgDelete height={16} width={16} />}
          className="bg-blue p-2 ml-2 br-4"
          variant="link"
          onClick={handleDelete}
        />
      )}
    </div>
  );
};

export default SubGoalCrud;
