import React from "react";
import { Shimmer } from "src/components/common/atoms";

const LoadingGoalCreationTabs = () => (
  <div className="container-fluid pt-3  tab-box d-flex justify-content-between pb-3 pl-45 pr-45">
    <div>
      <Shimmer width="80px" height="10px" />
      <div className="pt-14">
        <Shimmer width="200px" height="10px" />
      </div>
    </div>
    <div className="d-flex pt-3">
      <div>
        <Shimmer width="100px" height="10px" />
      </div>
      <div className="pl-45">
        <Shimmer width="100px" height="10px" />
      </div>
    </div>
    <div className="d-flex">
      <div className="mr-3">
        <Shimmer width="40px" height="40px" />
      </div>
      <div className="mr-3">
        <Shimmer width="70px" height="40px" />
      </div>
      <div>
        <Shimmer width="70px" height="40px" />
      </div>
    </div>
  </div>
);

export default LoadingGoalCreationTabs;
