import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const DELETE_LEADERBOARD_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation deleteLeaderboard($LeaderboardId: ID!) {
    deleteLeaderboard(LeaderboardId: $LeaderboardId) {
      ... on deleteLeaderboardResponse {
        success
        message
        response
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
      __typename
    }
  }
`;

export const useDeleteLeaderboard = () => {
  return useMutation(DELETE_LEADERBOARD_MUTATION);
};
