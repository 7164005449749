import PropTypes from "prop-types";
import React from "react";
import { FormButton } from "src/components/common/formElements";
import { classNames } from "src/helpers";
import { useViewport } from "src/hooks";
import "./emptyStatePage.scss";

const EmptyStatePage = (props) => {
  const {
    id,
    title,
    description,
    buttonText,
    buttonAction,
    descriptionImage,
    aboveDescription,
    belowDescription,
    besideButton,
    className,
  } = props;

  const { isMobile } = useViewport();
  if (isMobile) {
    return (
      <div
        id={id}
        className={classNames([
          "flex-center flex-column-reverse w-100 p-4",
          className,
        ])}
      >
        <div className="flex-column-start mt-4">
          {aboveDescription && aboveDescription}
          <div className="w-100 m-lg-0">
            {title && <h1 className="title fs-22 semi-bold">{title}</h1>}
            {description && (
              <p className="fs-16 fc-grey description">{description}</p>
            )}
          </div>
          {belowDescription && belowDescription}
        </div>
        {descriptionImage && <div className="mt-32">{descriptionImage}</div>}
      </div>
    );
  }

  return (
    <div id={id} className={classNames(["flex-between w-100 p-4", className])}>
      <div className="flex-column-start">
        {aboveDescription && aboveDescription}
        <div className="w-100 m-lg-0">
          {title && <h1 className="title fs-22 semi-bold">{title}</h1>}
          {description && (
            <p className="fs-16 fc-grey description">{description}</p>
          )}
          {buttonText && (
            <div className="d-flex">
              <FormButton
                id="action-btn"
                label={buttonText}
                onClick={buttonAction}
              />
              {besideButton && <div className="ml-3">{besideButton}</div>}
            </div>
          )}
        </div>
        {belowDescription && belowDescription}
      </div>
      {descriptionImage && <div className="mt-32">{descriptionImage}</div>}
    </div>
  );
};

EmptyStatePage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
  descriptionImage: PropTypes.node,
  aboveDescription: PropTypes.node,
  belowDescription: PropTypes.node,
  id: PropTypes.string,
  besideButton: PropTypes.node,
};
EmptyStatePage.defaultProps = {
  title: "",
  description: "",
  buttonText: "",
  buttonAction: () => {},
  descriptionImage: null,
  id: "",
  besideButton: null,
};

export default EmptyStatePage;
