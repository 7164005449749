import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import {
  AddItem,
  DeleteConfirmationModal,
  Item,
  ItemBody,
  ItemCount,
} from "src/apps/incentives/components";
import { FormButton } from "src/components";
import {
  INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES,
  MAXIMUM_INCENTIVE_CRITERIA_REWARDS_ALLOWED,
} from "src/constants";
import { getAlphabetForCriteria } from "src/helpers";
import { useToggle } from "src/hooks";
import { groupByConditionValid } from "../../utils";
import { ConditionTypeModal } from "../conditionTypeModal";
import { DisplayReward } from "../formulaBuilder";
import "./styles.scss";

export const RewardNotFound = ({ onAdd, criteriaIndex }) => {
  const [showRewardTypeModal, toggleRewardTypeModal] = useToggle(false);
  const [rewardType, setRewardType] = useState(
    INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
  );

  const handleClick = () => {
    onAdd({ criteriaIndex, rewardIndex: 0, rewardType });
  };
  return (
    <>
      <FormButton
        id="add-reward"
        variant="outline-primary"
        onClick={toggleRewardTypeModal}
        label="Add Reward"
        className="reward404 fs-12 fc-grey"
      />
      <ConditionTypeModal
        show={showRewardTypeModal}
        onHide={toggleRewardTypeModal}
        selected={
          rewardType === INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
            ? 0
            : 1
        }
        setSelected={setRewardType}
        handleClick={(index) =>
          setRewardType(
            index === 0
              ? INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
              : INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.FORMULA
          )
        }
        onDoneClick={() => {
          handleClick();
          toggleRewardTypeModal();
        }}
        title={t("INCENTIVES.COMMON.LABELS.SELECT_REWARD_TYPE")}
        desc={t("INCENTIVES.COMMON.LABELS.REWARD_TYPE")}
      />
    </>
  );
};

const EmptyReward = () => {
  const isEmpty = true;
  return (
    <Item className="mb-3" isEmpty={isEmpty}>
      <ItemBody isEmpty={isEmpty} className="empty-reward">
        <div className="fs-12 fc-grey">
          {t("INCENTIVES.PROGRAM.LABELS.REWARD_ADDED_HERE")}
        </div>
      </ItemBody>
    </Item>
  );
};

export const RewardItem = ({
  reward,
  criteriaIndex,
  rewardIndex,
  rewardActions,
  handleDeleteClick,
  selectedTemplates,
  totalRewards,
  type,
  selectedGoals,
  disabled,
}) => {
  const [showRewardTypeModal, toggleRewardTypeModal] = useToggle(false);
  const [rewardType, setRewardType] = useState(
    INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
  );

  const isSlabTypeReward = rewardActions.isSlabTypeReward(reward.RewardType);

  if (!rewardActions?.isRewardFulfilled(reward)) {
    return <EmptyReward />;
  }

  const handleEdit = () => {
    rewardActions.edit({ criteriaIndex, rewardIndex });
  };
  const handleDelete = () => {
    handleDeleteClick(rewardIndex);
  };
  const handleAdd = () => {
    rewardActions.add({
      criteriaIndex,
      rewardIndex: rewardIndex + 1,
      rewardType,
    });
  };
  const subGoals = reward?.IsFormula
    ? reward?.Formula?.find((formula) => formula.hasOwnProperty("SubGoal"))
        ?.SubGoal
    : reward?.SubGoal;
  const isGroupBy = groupByConditionValid(subGoals);
  return (
    <Item className="mb-3">
      <ItemBody
        className="mr-3"
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        hideButtons={disabled}
        showGroupBy={isGroupBy}
      >
        <DisplayReward
          {...{
            reward,
            isSlabTypeReward,
            selectedGoals,
            selectedTemplates,
            type,
            isCrudView: true,
          }}
        />
      </ItemBody>
      {!disabled && (
        <>
          <AddItem
            disabled={
              totalRewards.length === MAXIMUM_INCENTIVE_CRITERIA_REWARDS_ALLOWED
            }
            handleAdd={toggleRewardTypeModal}
            toolTipText={t("INCENTIVES.PROGRAM.LABELS.REWARD_TOOLTIP", {
              maxReward: MAXIMUM_INCENTIVE_CRITERIA_REWARDS_ALLOWED,
            })}
          />
          <ConditionTypeModal
            show={showRewardTypeModal}
            onHide={toggleRewardTypeModal}
            selected={
              rewardType ===
              INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
                ? 0
                : 1
            }
            setSelected={setRewardType}
            handleClick={(index) =>
              setRewardType(
                index === 0
                  ? INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.STANDARD
                  : INCENTIVE_CRITERIA_CONDITION_OR_REWARD_TYPES.FORMULA
              )
            }
            onDoneClick={() => {
              handleAdd();
              toggleRewardTypeModal();
            }}
            title={t("INCENTIVES.COMMON.LABELS.SELECT_REWARD_TYPE")}
            desc={t("INCENTIVES.COMMON.LABELS.REWARD_TYPE")}
          />
        </>
      )}
    </Item>
  );
};

export const RewardList = ({
  rewards,
  rewardActions,
  criteriaIndex,
  selectedTemplates,
  fulfilledRewards,
  disabled,
  selectedGoals,
  type,
}) => {
  const [deleteRewardIndex, setDeleteRewardIndex] = useState(null);

  const handleClose = () => {
    setDeleteRewardIndex(null);
  };

  const handleDeleteClick = (rewardIndex) => {
    // casting to String because rewardIndex could be 0
    setDeleteRewardIndex(String(rewardIndex));
  };

  const handleDeleteReward = () => {
    rewardActions.delete({
      criteriaIndex,
      rewardIndex: Number(deleteRewardIndex),
    });
    handleClose();
  };

  return (
    <>
      <div className="mb-3">
        <hr className="my-3" />
        <div className="flex-between">
          <div>
            <span className="fs-13 fc-black semi-bold">
              {t("INCENTIVES.MY_INCENTIVES.LABELS.REWARDS")}
            </span>
            <span className="fs-12 fc-grey ml-2">
              <Trans
                i18nKey="INCENTIVES.PROGRAM.MESSAGES.SETUP_MULTIPLE_REWARDS"
                t={t}
              >
                Setup multiple rewards to your program criteria based on your
                use case. Multiple rewards will be joined with <b>AND</b>{" "}
                operator.
              </Trans>
            </span>
          </div>
          <ItemCount
            className="mr-3"
            fulfilledItems={fulfilledRewards}
            maximumLimit={MAXIMUM_INCENTIVE_CRITERIA_REWARDS_ALLOWED}
            itemName="Rewards"
          />
        </div>
      </div>
      {rewards.length === 0 ? (
        <RewardNotFound
          onAdd={rewardActions.add}
          criteriaIndex={criteriaIndex}
        />
      ) : null}
      <div className="reward-list">
        {rewards.map((reward, rewardIndex) => {
          return (
            <RewardItem
              reward={reward}
              criteriaIndex={criteriaIndex}
              rewardIndex={rewardIndex}
              rewardActions={rewardActions}
              handleDeleteClick={handleDeleteClick}
              selectedTemplates={selectedTemplates}
              totalRewards={rewards}
              disabled={disabled}
              type={type}
              selectedGoals={selectedGoals}
            />
          );
        })}
      </div>
      <DeleteConfirmationModal
        show={Boolean(deleteRewardIndex)}
        handleClose={handleClose}
        heading="Delete Reward"
        handleDelete={handleDeleteReward}
      >
        <div className="fs-14 semi-bold">
          {t("INCENTIVES.PROGRAM.LABELS.REWARD_WARNING")}{" "}
          {Number(deleteRewardIndex) + 1}{" "}
          {t("INCENTIVES.PROGRAM.LABELS.FOR_PROGRAM_CRITERIA")}{" "}
          {getAlphabetForCriteria(criteriaIndex)}?
        </div>
        <div className="fs-13 mt-2">
          {t("RULEBOOK.MESSAGES.ACTION_CANNOT_BE_DONE")}
        </div>
      </DeleteConfirmationModal>
    </>
  );
};
