export const errorText = {
  GRAPHQL: {
    ERROR: "Some error occurred. Please connect with admin",
  },
  GET_GOALS: {
    ERROR: "Something went wrong, while fetching goals",
  },
  CREATE_GOAL: {
    ERROR: "Something went wrong with Goal Creation.",
  },
  UPDATE_GOAL: {
    ERROR: "Something went wrong, while updating Goal.",
  },
  DELETE_GOAL: {
    ERROR: "Something went wrong, while deleting Goal.",
  },
  GET_GOAL_DETAIL: {
    ERROR: "Something went wrong, while fetching Goal Details.",
  },
  GET_RECURRENCES_ACHIEVEMENTS: {
    ERROR: "Something went Wrong ,While fetching Goal Details.",
  },
  CREATE_GOAL_RULE: {
    ERROR: "Something went wrong, while creating Rule.",
  },
  UPDATE_GOAL_RULE: {
    ERROR: "Something went wrong, while updating Rule.",
  },
  DELETE_GOAL_RULE: {
    ERROR: "Something went wrong, while deleting Rule.",
  },
  CREATE_TEMPLATE: {
    ERROR: "Something went wrong, while creating Ruleset.",
  },
  UPDATE_TEMPLATE: {
    ERROR: "Something went wrong, while updating Ruleset.",
  },
  DELETE_TEMPLATE: {
    ERROR: "Something went wrong, while deleting Ruleset.",
  },
  GET_TEMPLATES: {
    ERROR: "Something went wrong, while fetching Templates.",
  },
  GET_TEMPLATE_DETAIL: {
    ERROR: "Something went wrong, while fetching Ruleset Details.",
  },
  GET_ASSIGNED_GOALS: {
    ERROR: "Something went wrong, while fetching Assigned Goals.",
  },
  GET_GOAL_RECURRENCE: {
    ERROR: "Something went wrong, while fetching Goal Recurrence.",
  },
  GET_USER_LIST: {
    ERROR: "Something went wrong, while fetching User List.",
  },
  CREATE_DISTRIBUTION: {
    ERROR: "Something went wrong, while creating Distribution.",
  },
  REMOVE_DISTRIBUTION: {
    ERROR: "Something went wrong, while removing Distribution.",
  },
  GET_LEAD_FIELDS: {
    ERROR: "Something went wrong, while fetching Lead Fields.",
  },
  GET_ACTIVITY_FIELDS: {
    ERROR: "Something went wrong, while fetching Activity Fields.",
  },
  GET_OPPORTUNITY_FIELDS: {
    ERROR: "Something went wrong, while fetching Opportunity Fields.",
  },
  GET_TASK_FIELDS: {
    ERROR: "Something went wrong, while fetching Task Fields.",
  },
  CREATE_INCENTIVE: {
    ERROR: "Something went wrong, while creating Incentive.",
  },
  DELETE_PROGRAM: {
    ERROR: "Something went wrong, while deleting Program.",
  },
  CREATE_LEADERBOARD: {
    ERROR: "Something went wrong, while creating Leaderboard.",
  },
  UPDATE_LEADERBOARD: {
    ERROR: "Something went wrong, while updating Leaderboard.",
  },
  FETCHING_LEADERBOARD_RULE: {
    ERROR: "Something went wrong, while fetching Leaderboard Rules",
  },
  FETCHING_CUSTOM_REWARDS: {
    ERROR: "Something went wrong, while fetching Rewards",
  },
  FETCHING_CUSTOM_REWARD_HISTORY: {
    ERROR: "Something went wrong, while fetching Reward History",
  },
  FETCHING_REWARD_DEPENDENT_ITEMS: {
    ERROR: "Something went wrong, while fetching Reward Dependent Items",
  },
  SAVE_REWARD: {
    ERROR: "Something went wrong, while saving the Reward.",
  },
  DELETE_REWARD: {
    ERROR: "Something went wrong, while deleting the Reward.",
  },
  GET_USER_ATTRIBUTES: {
    ERROR: "Something went wrong, while fetching user attributes",
  },
  GET_DRILLDOWN_DATA: {
    ERROR: "Something went wrong, while fetching the drilldown data",
  },
  GET_USERS_ACHIEVEMENTS: {
    ERROR: "Something went wrong, while fetching users achievement",
  },
  GET_LEADERBOARD_ACHIEVEMENTS_DETAILS: {
    ERROR: "Something went wrong, while fetching user achievement details",
  },
  GET_LEADERBOARD_GROUPS: {
    ERROR: "Something went wrong, while fetching group details",
  },
  GET_LEADERBOARD_CONFIG: {
    ERROR: "Something went wrong, while fetching leaderboard config details",
  },
  GET_LEADERBOARD_TOP_PERFORMERS: {
    ERROR: "Something went wrong, while fetching Top performers list",
  },
  GET_LEADERBOARD_MY_TROPHIES: {
    ERROR: "Something went wrong, while fetching MyTrophies list",
  },
  CLONE_PROGRAM: {
    ERROR: "Something went wrong, while cloning",
  },
};

export const successText = {
  FILE_UPLOAD: {
    SUCCESS: "File uploaded successfully.",
  },
  REWARD_REQUEST: {
    FORWARD_SUCCESSFUL: "Reward requests have been forwarded successfully.",
    FORWARD_TO_PAYOUT: "The Reward request has been forwarded to Payout.",
    FORWARD_TO_NEXT_STAGE:
      "The Reward request has been forwarded to the next stage.",
    REJECT: "The Reward request has been rejected.",
    SENT_BACK: "The Reward request has been sent back to the previous stage.",
  },
  PAYOUT: {
    SUCCESS: "The Payout has been successful",
    USER_PAYOUT_SUCCESSFUL: (userName) =>
      `Payout for the user ${userName} has been successful`,
    PROCESSED_SUCCESSFULLY: "Payout processed successfully.",
    SENT_BACK_TO_APPROVAL: "The Payout request has been sent back to approval.",
  },
  CUSTOM_REWARDS: {
    CREATED: "Reward has been created.",
    UPDATED: "Reward has been updated.",
    CREATED_SUCCESSFULLY: "Rewards created successfully.",
    UPDATED_SUCCESSFULLY: "Rewards updated successfully.",
    DELETED: (name) => `The reward ${name} has been deleted.`,
    ACTIVATED: "Reward has been activated.",
    DEACTIVATED: "Reward has been deactivated.",
  },
};
