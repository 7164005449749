import PropTypes from "prop-types";
import React from "react";
import { FormTooltip } from "src/components/common/formElements";
import { CONDITION_OPERATOR } from "src/constants";
import { NumFormatter, getUserTimezone } from "src/helpers";
import "./conditionOperator.scss";

export const variants = ["primary", "secondary"];

export const ConditionValue = ({ value, includePercentage, type }) => {
  const isIndianTimezone = getUserTimezone();
  const formattedTooltipValue = value.toLocaleString(
    !isIndianTimezone ? "en-US" : "en-IN",
    { roundingPriority: "morePrecision" }
  );

  return (
    <span className="condition-value">
      <FormTooltip
        text={
          includePercentage
            ? `${formattedTooltipValue}%`
            : formattedTooltipValue
        }
      >
        {includePercentage
          ? `${NumFormatter(value, isIndianTimezone)}%`
          : NumFormatter(value, isIndianTimezone)}
      </FormTooltip>
    </span>
  );
};

const Between = ({ value, type }) => {
  const { startsAt, endsAt } = value;
  return (
    <div className="condition_operator">
      <span className="mr-1">Between</span>
      <ConditionValue value={startsAt} type={type} />
      <span className="mx-1">to</span>
      <ConditionValue value={endsAt} type={type} />
    </div>
  );
};

const GreaterThan = ({ value, type }) => (
  <div className="condition_operator">
    <span className="mr-1">Greater than</span>
    <ConditionValue value={value} type={type} />
  </div>
);

const LessThan = ({ value, type }) => (
  <div className="condition_operator">
    <span className="mr-1">Less than</span>
    <ConditionValue value={value} type={type} />
  </div>
);

const GreaterThanEqualTo = ({ value, type }) => (
  <div className="condition_operator">
    <span className="mr-1">Greater than or Equals</span>
    <ConditionValue value={value} type={type} />
  </div>
);

const LessThanEqualTo = ({ value, type }) => (
  <div className="condition_operator">
    <span className="mr-1">Less than or Equals</span>
    <ConditionValue value={value} type={type} />
  </div>
);

const EqualTo = ({ value, type }) => (
  <div className="condition_operator">
    <span className="mr-1">Equals to</span>
    <ConditionValue value={value} type={type} />
  </div>
);

const components = {
  [CONDITION_OPERATOR.BETWEEN.Key]: Between,
  [CONDITION_OPERATOR.GREATER_THAN.Key]: GreaterThan,
  [CONDITION_OPERATOR.LESS_THAN.Key]: LessThan,
  [CONDITION_OPERATOR.GREATER_THAN_OR_EQUAL_TO.Key]: GreaterThanEqualTo,
  [CONDITION_OPERATOR.LESS_THAN_OR_EQUAL_TO.Key]: LessThanEqualTo,
  [CONDITION_OPERATOR.IS.Key]: EqualTo,
};

const ConditionOperator = (props) => {
  const { operator, value, type, variant, includePercentage } = props;
  const Component = components[operator];
  if (!Component) return null;

  return (
    <span className={`${variant}`}>
      <Component
        value={value}
        type={type}
        includePercentage={includePercentage}
      />
    </span>
  );
};

ConditionOperator.propTypes = {
  operator: PropTypes.string.isRequired,
  value: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.oneOf(variants).isRequired,
};

ConditionOperator.defaultProps = {
  variant: "primary",
};

export default ConditionOperator;
