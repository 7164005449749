import PropTypes from "prop-types";
import React from "react";
import Form from "react-bootstrap/Form";
import { classNames } from "src/helpers";
import "./formCheck.scss";

const FormCheck = (props) => {
  const { id, type, label, className, disabled, checked, onClick, ...rest } =
    props;
  return (
    <Form.Check
      {...rest}
      className={classNames(["w-100 fs-12", className || ""])}
      checked={checked}
      id={id}
      type={type}
      label={label}
      custom
      disabled={disabled}
      onClick={onClick}
    />
  );
};

FormCheck.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["checkbox", "radio", "switch"]).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

FormCheck.defaultProps = {
  type: "checkbox",
  disabled: false,
  checked: false,
};

export default FormCheck;
