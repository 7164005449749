import PropTypes from "prop-types";

export const recurrenceDetails = {
  data: {
    getRecurrenceSummary: {
      __typename: "RecurrenceSummaryResponse",
      success: true,
      message: "Success",
      response: {
        Recurrences: [
          {
            ProgramId: "06c76559-eeb6-416c-9697-1b5e7fec1468",
            RecurrenceId: "fb9912d0-315c-4a6a-8f41-06e979c42bf1",
            RecurrenceStartDate: 1661990400000,
            RecurrenceEndDate: 1661990400000,
            RecurrenceType: "DAILY",
            SelectedUsersCount: 10,
            GroupsCount: 10,
            TotalUsers: 22,
            RecurrenceStatus: "COMPLETED",
            __typename: "RecurrenceSummary",
          },
          {
            ProgramId: "06c76559-eeb6-416c-9697-1b5e7fec1468",
            RecurrenceId: "335930f1-cb57-4d01-b06c-376d602d0b39",
            RecurrenceStartDate: 1662076800000,
            RecurrenceEndDate: 1662076800000,
            RecurrenceType: "DAILY",
            SelectedUsersCount: 10,
            GroupsCount: 10,
            TotalUsers: 22,
            __typename: "RecurrenceSummary",
          },
          {
            ProgramId: "06c76559-eeb6-416c-9697-1b5e7fec1468",
            RecurrenceId: "d192e2b3-fa47-4a0b-a187-86f4e58f2a5f",
            RecurrenceStartDate: 1662163200000,
            RecurrenceEndDate: 1662163200000,
            RecurrenceType: "DAILY",
            SelectedUsersCount: 10,
            GroupsCount: 10,
            TotalUsers: 22,
            __typename: "RecurrenceSummary",
          },
        ],
        CurrentRecurrence: {
          ProgramId: "06c76559-eeb6-416c-9697-1b5e7fec1468",
          RecurrenceEndDate: 1667347200000,
          RecurrenceId: "82e3582d-b012-499f-9ac9-28401a5b90a3",
          RecurrenceStartDate: 1667347200000,
          RecurrenceType: "DAILY",
          SelectedUsersCount: 10,
          GroupsCount: 10,
          TotalUsers: 22,
          RecurrenceStatus: "INPROGRESS",
          Processed: false,
          __typename: "RecurrenceSummary",
        },
        __typename: "RecurrencesSummary",
      },
    },
  },
};
export const getAllUsersList = {
  data: {
    getAllUsers: {
      success: true,
      message: "Success",
      response: {
        Users: [
          {
            UserID: "72ab1c82-f1e8-11ec-b340-0aa51ee1d108",
            FirstName: "Administrator 1 ACG",
            MiddleName: null,
            LastName: "",
            EmailAddress: "testing@test.test",
            IsDefaultOwner: 0,
            IsAdministrator: true,
            IsAgencyUser: false,
            IsBillingUser: false,
            StatusCode: 0,
            StatusReason: 0,
            DeletionStatusCode: 0,
            CreatedBy: "0e768135-8f04-11ec-83af-0aa51ee1d108",
            CreatedOn: "2022-06-22T10:32:00.000Z",
            ModifiedBy: "30a25336-5989-11eb-95ed-0a5142c8570a",
            ModifiedOn: "2022-06-22T16:13:12.000Z",
            Role: "Administrator",
            IsPhoneCallAgent: 0,
            UserType: 0,
            ManagerUserId: null,
            IsEmailSender: 1,
            Designation: null,
            Team: null,
            Department: null,
            SalesRegions: null,
            Skills: null,
            AvailabilityStatus: "offline",
            OfficeLocationName: null,
            Address: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
            TelephonyAgentId: null,
            WorkDayTemplateId: null,
            HolidayCalendarId: null,
            TeamId: "31890c57-5989-11eb-9da4-02939bdb3318",
            IsCheckInEnabled: false,
            IsCheckedIn: false,
            LastCheckedOn: null,
            CheckinCheckoutHistoryId: null,
            Groups: null,
            AutoUserId: 212,
            ViewAllLeadsOfGroup: 0,
            ModifyAllLeadsOfGroup: 0,
            TimeZone: "Asia/Kolkata",
            DateFormat: "dd/mm/yyyy",
          },
          {
            UserID: "0bdf3bc7-f1ea-11ec-b340-0aa51ee1d108",
            FirstName: "Administrator 2 ACG",
            MiddleName: null,
            LastName: "",
            EmailAddress: "testing@test.test",
            IsDefaultOwner: 0,
            IsAdministrator: true,
            IsAgencyUser: false,
            IsBillingUser: false,
            StatusCode: 0,
            StatusReason: 0,
            DeletionStatusCode: 0,
            CreatedBy: "0e768135-8f04-11ec-83af-0aa51ee1d108",
            CreatedOn: "2022-06-22T10:43:26.000Z",
            ModifiedBy: "30a25336-5989-11eb-95ed-0a5142c8570a",
            ModifiedOn: "2022-06-22T17:47:11.000Z",
            Role: "Administrator",
            IsPhoneCallAgent: 0,
            UserType: 0,
            ManagerUserId: null,
            IsEmailSender: 1,
            Designation: null,
            Team: null,
            Department: null,
            SalesRegions: null,
            Skills: null,
            AvailabilityStatus: "offline",
            OfficeLocationName: null,
            Address: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
            TelephonyAgentId: null,
            WorkDayTemplateId: null,
            HolidayCalendarId: null,
            TeamId: "31890c57-5989-11eb-9da4-02939bdb3318",
            IsCheckInEnabled: false,
            IsCheckedIn: false,
            LastCheckedOn: null,
            CheckinCheckoutHistoryId: null,
            Groups: null,
            AutoUserId: 213,
            ViewAllLeadsOfGroup: 0,
            ModifyAllLeadsOfGroup: 0,
            TimeZone: "Asia/Kolkata",
            DateFormat: "dd/mm/yyyy",
          },
          {
            UserID: "551540a4-f1ea-11ec-b340-0aa51ee1d108",
            FirstName: "Administrator 3 ACG",
            MiddleName: null,
            LastName: "",
            EmailAddress: "testing@test.test",
            IsDefaultOwner: 0,
            IsAdministrator: true,
            IsAgencyUser: false,
            IsBillingUser: false,
            StatusCode: 0,
            StatusReason: 0,
            DeletionStatusCode: 0,
            CreatedBy: "0e768135-8f04-11ec-83af-0aa51ee1d108",
            CreatedOn: "2022-06-22T10:45:29.000Z",
            ModifiedBy: "30a25336-5989-11eb-95ed-0a5142c8570a",
            ModifiedOn: "2022-06-22T17:48:49.000Z",
            Role: "Administrator",
            IsPhoneCallAgent: 0,
            UserType: 0,
            ManagerUserId: null,
            IsEmailSender: 1,
            Designation: null,
            Team: null,
            Department: null,
            SalesRegions: null,
            Skills: null,
            AvailabilityStatus: "offline",
            OfficeLocationName: null,
            Address: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
            TelephonyAgentId: null,
            WorkDayTemplateId: null,
            HolidayCalendarId: null,
            TeamId: "31890c57-5989-11eb-9da4-02939bdb3318",
            IsCheckInEnabled: false,
            IsCheckedIn: false,
            LastCheckedOn: null,
            CheckinCheckoutHistoryId: null,
            Groups: null,
            AutoUserId: 214,
            ViewAllLeadsOfGroup: 0,
            ModifyAllLeadsOfGroup: 0,
            TimeZone: "Asia/Kolkata",
            DateFormat: "dd/mm/yyyy",
          },
          {
            UserID: "6dcbac3c-f1ea-11ec-b340-0aa51ee1d108",
            FirstName: "Administrator 4 ACG",
            MiddleName: null,
            LastName: "",
            EmailAddress: "testing@test.test",
            IsDefaultOwner: 0,
            IsAdministrator: true,
            IsAgencyUser: false,
            IsBillingUser: false,
            StatusCode: 0,
            StatusReason: 0,
            DeletionStatusCode: 0,
            CreatedBy: "0e768135-8f04-11ec-83af-0aa51ee1d108",
            CreatedOn: "2022-06-22T10:46:10.000Z",
            ModifiedBy: "30a25336-5989-11eb-95ed-0a5142c8570a",
            ModifiedOn: "2022-06-22T17:51:38.000Z",
            Role: "Administrator",
            IsPhoneCallAgent: 0,
            UserType: 0,
            ManagerUserId: null,
            IsEmailSender: 1,
            Designation: null,
            Team: null,
            Department: null,
            SalesRegions: null,
            Skills: null,
            AvailabilityStatus: "offline",
            OfficeLocationName: null,
            Address: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
            TelephonyAgentId: null,
            WorkDayTemplateId: null,
            HolidayCalendarId: null,
            TeamId: "31890c57-5989-11eb-9da4-02939bdb3318",
            IsCheckInEnabled: false,
            IsCheckedIn: false,
            LastCheckedOn: null,
            CheckinCheckoutHistoryId: null,
            Groups: null,
            AutoUserId: 215,
            ViewAllLeadsOfGroup: 0,
            ModifyAllLeadsOfGroup: 0,
            TimeZone: "Asia/Kolkata",
            DateFormat: "dd/mm/yyyy",
          },
          {
            UserID: "4b8623b7-f8c4-11eb-98ac-0a6953825ea8",
            FirstName: "Admin Role",
            MiddleName: null,
            LastName: "No Goals",
            EmailAddress: "testing@test.test",
            IsDefaultOwner: 0,
            IsAdministrator: true,
            IsAgencyUser: false,
            IsBillingUser: false,
            StatusCode: 0,
            StatusReason: 0,
            DeletionStatusCode: 0,
            CreatedBy: "76747e74-d802-11eb-893d-0a6953825ea8",
            CreatedOn: "2021-08-09T09:15:52.000Z",
            ModifiedBy: "76747e74-d802-11eb-893d-0a6953825ea8",
            ModifiedOn: "2021-08-09T09:29:04.000Z",
            Role: "Administrator",
            IsPhoneCallAgent: 0,
            UserType: 0,
            ManagerUserId: null,
            IsEmailSender: 1,
            Designation: null,
            Team: null,
            Department: null,
            SalesRegions: null,
            Skills: null,
            AvailabilityStatus: "offline",
            OfficeLocationName: null,
            Address: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
            TelephonyAgentId: null,
            WorkDayTemplateId: null,
            HolidayCalendarId: null,
            TeamId: "31890c57-5989-11eb-9da4-02939bdb3318",
            IsCheckInEnabled: false,
            IsCheckedIn: false,
            LastCheckedOn: null,
            CheckinCheckoutHistoryId: null,
            Groups: null,
            AutoUserId: 53,
            ViewAllLeadsOfGroup: 0,
            ModifyAllLeadsOfGroup: 0,
            TimeZone: "Asia/Kolkata",
            DateFormat: "dd/mm/yyyy",
          },
          {
            UserID: "0e768135-8f04-11ec-83af-0aa51ee1d108",
            FirstName: "Alok",
            MiddleName: null,
            LastName: "Sahu",
            EmailAddress: "testing@test.test",
            IsDefaultOwner: 0,
            IsAdministrator: true,
            IsAgencyUser: false,
            IsBillingUser: false,
            StatusCode: 0,
            StatusReason: 0,
            DeletionStatusCode: 0,
            CreatedBy: "495d3107-1519-11ec-9cb6-02939bdb3318",
            CreatedOn: "2022-02-16T14:10:12.000Z",
            ModifiedBy: "0e768135-8f04-11ec-83af-0aa51ee1d108",
            ModifiedOn: "2022-04-26T11:29:19.000Z",
            Role: "Administrator",
            IsPhoneCallAgent: 0,
            UserType: 0,
            ManagerUserId: "495d3107-1519-11ec-9cb6-02939bdb3318",
            IsEmailSender: 1,
            Designation: null,
            Team: null,
            Department: null,
            SalesRegions: null,
            Skills: null,
            AvailabilityStatus: "offline",
            OfficeLocationName: null,
            Address: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
            TelephonyAgentId: null,
            WorkDayTemplateId: null,
            HolidayCalendarId: null,
            TeamId: "31890c57-5989-11eb-9da4-02939bdb3318",
            IsCheckInEnabled: false,
            IsCheckedIn: false,
            LastCheckedOn: null,
            CheckinCheckoutHistoryId: null,
            Groups: null,
            AutoUserId: 110,
            ViewAllLeadsOfGroup: 0,
            ModifyAllLeadsOfGroup: 0,
            TimeZone: "Asia/Kolkata",
            DateFormat: "dd/mm/yyyy",
          },
          {
            UserID: "ebcbe8e7-9ac8-11ec-83af-0aa51ee1d108",
            FirstName: "alok sales reportee",
            MiddleName: null,
            LastName: "",
            EmailAddress: "testing@test.test",
            IsDefaultOwner: 0,
            IsAdministrator: true,
            IsAgencyUser: false,
            IsBillingUser: false,
            StatusCode: 0,
            StatusReason: 0,
            DeletionStatusCode: 0,
            CreatedBy: "0e768135-8f04-11ec-83af-0aa51ee1d108",
            CreatedOn: "2022-03-03T13:37:08.000Z",
            ModifiedBy: "30a25336-5989-11eb-95ed-0a5142c8570a",
            ModifiedOn: "2022-03-03T13:37:09.000Z",
            Role: "Administrator",
            IsPhoneCallAgent: 0,
            UserType: 0,
            ManagerUserId: "0e768135-8f04-11ec-83af-0aa51ee1d108",
            IsEmailSender: 1,
            Designation: null,
            Team: null,
            Department: null,
            SalesRegions: null,
            Skills: null,
            AvailabilityStatus: "offline",
            OfficeLocationName: null,
            Address: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
            TelephonyAgentId: null,
            WorkDayTemplateId: null,
            HolidayCalendarId: null,
            TeamId: "31890c57-5989-11eb-9da4-02939bdb3318",
            IsCheckInEnabled: false,
            IsCheckedIn: false,
            LastCheckedOn: null,
            CheckinCheckoutHistoryId: null,
            Groups: null,
            AutoUserId: 202,
            ViewAllLeadsOfGroup: 0,
            ModifyAllLeadsOfGroup: 0,
            TimeZone: "Asia/Kolkata",
            DateFormat: "dd/mm/yyyy",
          },
          {
            UserID: "9e5049b1-8fe3-11ec-83af-0aa51ee1d108",
            FirstName: "alok sales user",
            MiddleName: null,
            LastName: "",
            EmailAddress: "testing@test.test",
            IsDefaultOwner: 0,
            IsAdministrator: false,
            IsAgencyUser: false,
            IsBillingUser: false,
            StatusCode: 0,
            StatusReason: 0,
            DeletionStatusCode: 0,
            CreatedBy: "0e768135-8f04-11ec-83af-0aa51ee1d108",
            CreatedOn: "2022-02-17T16:50:31.000Z",
            ModifiedBy: "6dcbac3c-f1ea-11ec-b340-0aa51ee1d108",
            ModifiedOn: "2022-06-22T18:13:43.000Z",
            Role: "Sales_User",
            IsPhoneCallAgent: 0,
            UserType: 0,
            ManagerUserId: "551540a4-f1ea-11ec-b340-0aa51ee1d108",
            IsEmailSender: 1,
            Designation: null,
            Team: null,
            Department: null,
            SalesRegions: null,
            Skills: null,
            AvailabilityStatus: "offline",
            OfficeLocationName: null,
            Address: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
            TelephonyAgentId: null,
            WorkDayTemplateId: null,
            HolidayCalendarId: null,
            TeamId: "31890c57-5989-11eb-9da4-02939bdb3318",
            IsCheckInEnabled: false,
            IsCheckedIn: false,
            LastCheckedOn: null,
            CheckinCheckoutHistoryId: null,
            Groups: null,
            AutoUserId: 112,
            ViewAllLeadsOfGroup: 0,
            ModifyAllLeadsOfGroup: 0,
            TimeZone: "Asia/Kolkata",
            DateFormat: "dd/mm/yyyy",
          },
          {
            UserID: "6633cc04-01b6-11ed-98d3-0227404e3384",
            FirstName: "Amandeep",
            MiddleName: null,
            LastName: "Singh",
            EmailAddress: "testing@test.test",
            IsDefaultOwner: 0,
            IsAdministrator: true,
            IsAgencyUser: false,
            IsBillingUser: false,
            StatusCode: 0,
            StatusReason: 0,
            DeletionStatusCode: 0,
            CreatedBy: "e84912d7-92f4-11ec-83af-0aa51ee1d108",
            CreatedOn: "2022-07-12T13:14:02.000Z",
            ModifiedBy: "30a25336-5989-11eb-95ed-0a5142c8570a",
            ModifiedOn: "2022-07-12T13:14:08.000Z",
            Role: "Administrator",
            IsPhoneCallAgent: 0,
            UserType: 0,
            ManagerUserId: null,
            IsEmailSender: 1,
            Designation: null,
            Team: null,
            Department: null,
            SalesRegions: null,
            Skills: null,
            AvailabilityStatus: "offline",
            OfficeLocationName: null,
            Address: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
            TelephonyAgentId: null,
            WorkDayTemplateId: null,
            HolidayCalendarId: null,
            TeamId: "31890c57-5989-11eb-9da4-02939bdb3318",
            IsCheckInEnabled: false,
            IsCheckedIn: false,
            LastCheckedOn: null,
            CheckinCheckoutHistoryId: null,
            Groups: null,
            AutoUserId: 226,
            ViewAllLeadsOfGroup: 0,
            ModifyAllLeadsOfGroup: 0,
            TimeZone: "Asia/Kolkata",
            DateFormat: "dd/mm/yyyy",
          },
        ],
        totalCount: 225,
        pageInfo: {
          endCursor:
            "WyJBbWFuZGVlcCIsIjY2MzNjYzA0LTAxYjYtMTFlZC05OGQzLTAyMjc0MDRlMzM4NCJd",
          hasNextPage: true,
          hasPreviousPage: false,
          __typename: "PageInfo",
        },
        __typename: "AllUsers",
      },
      __typename: "AllUsersResponse",
    },
  },
};
export const getGroupsList = {
  data: {
    getGroups: {
      success: true,
      message: "Success",
      response: {
        groups: [
          {
            id: "3bfea455-50f8-4a2b-8d11-f239cbdb3658",
            Name: "Kolkata",
          },
          {
            id: "3bfea455-50f8-4a2b-8d11-f239cbdb3650",
            Name: "Bangalore",
          },
          {
            id: "3bfea455-50f8-4a2b-8d11-f239cbdb3658",
            Name: "Delhi",
          },
        ],
        totalCount: 225,
        pageInfo: {
          endCursor:
            "WyJBbWFuZGVlcCIsIjY2MzNjYzA0LTAxYjYtMTFlZC05OGQzLTAyMjc0MDRlMzM4NCJd",
          hasNextPage: true,
          hasPreviousPage: false,
          __typename: "PageInfo",
        },
        __typename: "Groups",
      },
      __typename: "GroupsResponse",
    },
  },
};

export const LEADERBOARD_USERS_AND_GROUPS = (initialState) => ({
  formData: {
    Selection: "MANUAL",
    Approvers: initialState.Approvers || [],
    CurrentRecurrence: initialState.CurrentRecurrence || null,
    Groups: initialState.Groups || [],
    SelectedGroup: initialState.SelectedGroup || null,
    SelectedUsers: initialState.SelectedUsers || [],
    SelectedRecurrencesToCopyUsers:
      initialState.SelectedRecurrencesToCopyUsers || [],
    Search: "",
  },
});

export const LEADERBOARD_USERS_AND_GROUPS_PROPTYPES = {
  formData: PropTypes.object,
};
