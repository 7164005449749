import React from "react";
const SvgIncentives = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#p0lq__a)">
        <mask
          id="p0lq__b"
          width={20}
          height={20}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "alpha",
          }}
        >
          <path fill="#D9D9D9" d="M0 0h20v20H0z" />
        </mask>
        <g fill="#309AFC" mask="url(#p0lq__b)">
          <path
            stroke="#309AFC"
            strokeWidth={0.4}
            d="M10 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Zm0 13.846A6.353 6.353 0 0 1 3.654 10C3.654 6.5 6.5 3.654 10 3.654c3.5 0 6.346 2.847 6.346 6.346 0 3.5-2.847 6.346-6.346 6.346Z"
          />
          <path d="M12.885 11.231a1.83 1.83 0 0 0-.316-1.071 1.724 1.724 0 0 0-.789-.642c-.336-.133-.911-.29-1.708-.467-.512-.113-.663-.209-.738-.28a.562.562 0 0 1-.175-.413c0-.237.114-.637.881-.637.394 0 .714.112.952.333.079.073.148.162.207.259l.002.003.012.022a.155.155 0 0 0 .135.078h1.228c.087 0 .157-.07.157-.156a.777.777 0 0 0-.045-.168c-.327-.855-.97-1.348-1.962-1.513V5.87a.241.241 0 0 0-.241-.24h-.963a.24.24 0 0 0-.238.24v.736c-.592.121-1.042.36-1.339.71-.333.4-.496.842-.496 1.354 0 .46.147.854.449 1.21.3.353 1.05.653 2.232.895.34.068.576.152.722.253a.599.599 0 0 1 .267.513c0 .287-.171.626-.984.626a1.76 1.76 0 0 1-.713-.13 1.233 1.233 0 0 1-.643-.618l-.008-.014-.004-.01a.155.155 0 0 0-.137-.085H7.272a.157.157 0 0 0-.157.151c.002.024.013.072.012.074.325 1.06 1.031 1.664 2.156 1.85v.745c0 .132.108.24.24.24h.962a.241.241 0 0 0 .24-.24v-.743c.645-.105 1.162-.337 1.539-.696.417-.395.62-.874.62-1.46" />
        </g>
      </g>
      <defs>
        <clipPath id="p0lq__a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
SvgIncentives.defaultProps = {
  color: "",
  height: 24,
  width: 24,
};
export default SvgIncentives;
