import { gql, useQuery } from "src/api/client";

const GET_LEADERBOARDS_SUMMARY = gql`
  query payoutProgramsSummaryForUser($FeatureType: String!) {
    payoutProgramsSummaryForUser(FeatureType: $FeatureType) {
      ... on PayoutProgramsSummaryForUserResponse {
        success
        response {
          programId
          programName
          pendingRequests
        }
      }
    }
  }
`;

export const useGetPayoutLeaderboardsSummaryForUser = (queryOptions) => {
  return useQuery(GET_LEADERBOARDS_SUMMARY, queryOptions);
};
