import React from "react";
import { classNames } from "src/helpers";

const FilterButton = ({ onClick, active, children }) => {
  return (
    <button
      className={classNames([
        "fs-14 semi-bold flex-center",
        active ? "active" : "",
      ])}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default FilterButton;
