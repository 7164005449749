import React from "react";
import { useTranslation } from "react-i18next";
import { HeroBackground, NonMonetaryLabel } from "src/components/common/atoms";
import { ApprovedIncentive, SideBlueBanner } from "src/images";
import SideBannerCardLeft from "./SideBannerCardLeft";
import "./sideBannerCard.scss";

const SideBannerCard = (props) => {
  const { t } = useTranslation();
  const {
    status = "6th cycle in progress",
    amount,
    nonMonetaryRewards,
    rewardCount,
    detailsContainer,
    title,
    cycleCount,
    RecurrenceTimeLeft,
    onClickHandler,
    linkedProgramsCount,
  } = props;
  return (
    <div className="side-banner-card row mb-3 mx-0">
      <div
        className="col-3 p-0 side-banner-card__left pointer"
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
      >
        <HeroBackground
          image={SideBlueBanner}
          className="p-4 side-banner-card__background"
        >
          <SideBannerCardLeft
            icon={<ApprovedIncentive width="100px" height="100px" />}
            amount={
              <NonMonetaryLabel
                amount={amount}
                nonMonetaryRewards={
                  nonMonetaryRewards.filter((reward) => reward?.RewardName) ||
                  []
                }
                rewardCount={rewardCount}
                nonMonetaryClassName={`fc-white lh-1 ${
                  rewardCount > 0 ? "font-italic" : ""
                }`}
              />
            }
            description={t("INCENTIVES.COMMON.LABELS.ELIGIBLE_INCENTIVE")}
            title={title}
            cycleCount={cycleCount}
            linkedProgramsCount={linkedProgramsCount}
          />
        </HeroBackground>
      </div>
      <div className="col-9 p-0 side-banner-card__right">
        <div className="py-2 pl-4 pr-0 border-bottom-1 bc-grey8 side-banner-card__right--header flex-between">
          <div>{status}</div>

          {RecurrenceTimeLeft && (
            <div className="ribbon">
              <span className="p-2 fs-13 semi-bold fc-primary-blue">
                {RecurrenceTimeLeft}
              </span>
            </div>
          )}
        </div>
        <div>{detailsContainer}</div>
      </div>
    </div>
  );
};

export default SideBannerCard;
