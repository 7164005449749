import { t } from "i18next";
import React from "react";
import { NetworkStatus } from "src/api/client";
import { useGetNonMonetaryRewardsProgramDetails } from "src/api/useCustomRewardConfig";
import { LoadMoreButton, Shimmer } from "src/components";
import { PAGINATION_LIMIT } from "src/constants";
import { range } from "src/helpers";
import { NoResults } from "src/images";
import RewardsDetailsCard from "./RewardsDetailsCard";

const RewardCardBody = ({ show, getQuery }) => {
  const {
    data: { nonMonetaryRewardsProgramDetails } = {
      nonMonetaryRewardsProgramDetails: {},
    },
    loading,
    networkStatus = NetworkStatus.ready,
    fetchMore = () => {},
  } = useGetNonMonetaryRewardsProgramDetails({
    variables: {
      Limit: PAGINATION_LIMIT,
      Query: getQuery(),
      After: null,
    },
    skip: !show,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const rewardsList = nonMonetaryRewardsProgramDetails?.response?.rewardsList;
  const totalCount = nonMonetaryRewardsProgramDetails?.response?.totalCount;

  const onFetchMore = () => {
    const { endCursor } = nonMonetaryRewardsProgramDetails?.response?.pageInfo;
    fetchMore({
      variables: {
        Limit: PAGINATION_LIMIT,
        After: endCursor,
        Query: getQuery(),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        fetchMoreResult.nonMonetaryRewardsProgramDetails.response.rewardsList =
          [
            ...prev.nonMonetaryRewardsProgramDetails.response.rewardsList,
            ...fetchMoreResult.nonMonetaryRewardsProgramDetails.response
              .rewardsList,
          ];
        return fetchMoreResult;
      },
    });
  };

  if (
    networkStatus !== NetworkStatus.ready &&
    networkStatus !== NetworkStatus.fetchMore
  ) {
    return (
      <div className="py-3 space-y-16">
        {range(1, PAGINATION_LIMIT).map(() => (
          <Shimmer width="100%" height="213px" />
        ))}
      </div>
    );
  }

  if (rewardsList?.length === 0) {
    return (
      <div className="flex-column-center mt-5">
        <NoResults />
        <div className="fs-14 fc-grey mt-2 text-center">
          {t("LEADERBOARD.COMMON.LABELS.NO_RESULTS_TO_SHOW")}
        </div>
      </div>
    );
  }

  const disabledLoadMore = rewardsList?.length === totalCount;

  return (
    <div className="py-3 space-y-16">
      {rewardsList?.map((reward) => (
        <RewardsDetailsCard {...reward} />
      ))}
      {!disabledLoadMore && (
        <div className="w-100 my-3 flex-center">
          <LoadMoreButton
            variant="secondary"
            loading={loading}
            disabled={rewardsList.length === totalCount}
            id="reward-history-grid-show-more"
            onClick={onFetchMore}
            itemsLoaded={rewardsList.length}
            totalItems={totalCount}
          />
        </div>
      )}
    </div>
  );
};

export default RewardCardBody;
