import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import "./styles.scss";

const getStyles = (el) => {
  if (!el) {
    return {};
  }
  return {
    position: "absolute",
    top: el.offsetTop + el.clientHeight,
    left: el.offsetLeft,
  };
};

const MenuPropTypes = {
  el: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  container: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
};

const defaultMenuProps = {
  container: document.body,
};

const CustomMenu = ({
  el,
  isOpen,
  children,
  container,
  applyStyles = getStyles,
}) => {
  return ReactDOM.createPortal(
    isOpen ? (
      <div className="custom-menu" style={applyStyles(el)}>
        {children}
      </div>
    ) : null,
    container || document.body
  );
};

export default CustomMenu;

CustomMenu.propTypes = MenuPropTypes;
CustomMenu.defaultProps = defaultMenuProps;
