import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { RecurrenceList } from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import { COLORS, CUSTOM_CYCLE_TYPES } from "src/constants";
import {
  NumFormatter,
  addDays,
  capitalize,
  classNames,
  differenceInDays,
  endOfDay,
  getColor,
  getCycleIntervalString,
  getIndex,
  getPluralText,
  getRecurrenceStatus,
  getUserTimezone,
  startOfDay,
} from "src/helpers";
import { SvgArrow, SvgCalendar, SvgLive, SvgTick } from "src/icons";
import { RecurrenceTable } from "../recurrenceTable";
import "./recurrenceDropdown.scss";

const getBadgeColor = (recurrence) => {
  const { recurrenceStatus } = recurrence;
  switch (recurrenceStatus) {
    case "INPROGRESS":
      return "bg-blue2 semi-bold fc-black";
    case "COMPLETED":
      return "bg-grey6 fc-grey1";
    default:
      return "bg-blue2 fc-black";
  }
};

const CustomMenu = (props) => {
  const {
    recurrences,
    selectedCycle,
    handleClick,
    formatOptionLabelBefore,
    formatOptionLabelAfter,
    customType,
  } = props;
  const getRecurrenceColor = (recurrence) => {
    const { RecurrenceId, recurrenceStatus } = recurrence;
    if (RecurrenceId === selectedCycle?.RecurrenceId) return "fc-blue-primary";
    switch (recurrenceStatus) {
      case "INPROGRESS":
        return "fc-blue-primary";
      case "COMPLETED":
        return "fc-grey-primary";
      default:
        return "";
    }
  };

  return (
    <RecurrenceList
      recurrences={recurrences}
      recurrenceType={selectedCycle?.RecurrenceType}
      hoverable
      clickable
      handleClick={(recurrence) => handleClick(recurrence)}
      optionLabelColor={getRecurrenceColor}
      optionBadgeColor={getBadgeColor}
      formatOptionLabelBefore={formatOptionLabelBefore}
      formatOptionLabelAfter={formatOptionLabelAfter}
      customType={customType}
    />
  );
};
CustomMenu.propTypes = {
  recurrences: PropTypes.array,
  selectedCycle: PropTypes.object,
  handleClick: PropTypes.func,
  formatOptionLabelBefore: PropTypes.func,
  formatOptionLabelAfter: PropTypes.func,
};

export const CustomTitle = (props) => {
  const {
    recurrences,
    selectedCycle,
    formatTitleIcon,
    customType,
    className,
    showIndicator = false,
    readOnly,
  } = props;
  const { RecurrenceStartDate, RecurrenceEndDate, RecurrenceType, idx } =
    selectedCycle;

  return (
    <div
      className={`recurrence-dropdown-title flex-between fc-black regular ${className}`}
    >
      <div>
        <div className="flex-start fs-11">
          <div className="recurrence-dropdown-title__icon flex-start">
            {formatTitleIcon && formatTitleIcon({ ...selectedCycle })}
          </div>
          <div className="ml-1 semi-bold">
            <span>{`#${idx} `}</span>
            <span className="fc-grey-primary">
              {`
                of ${recurrences?.length} ${
                RecurrenceType === "CUSTOM"
                  ? CUSTOM_CYCLE_TYPES[customType]
                  : capitalize(RecurrenceType)
              } ${
                recurrences?.length > 1
                  ? t("MANAGED_GOALS.MESSAGES.CYCLES")
                  : t("MANAGED_GOALS.LABELS.ROLL_UP_CYCLE")
              }
                `}
            </span>
            {RecurrenceType === "CUSTOM" && (
              <span className="fs-11 ml-1 fc-grey1 semi-bold br-13 px-2 py-1 days_badge">
                {getPluralText(
                  differenceInDays(
                    endOfDay(addDays(RecurrenceEndDate, 1)),
                    startOfDay(RecurrenceStartDate)
                  ),
                  t("INCENTIVES.COMMON.MESSAGES.DAY"),
                  t("INCENTIVES.COMMON.MESSAGES.DAYS")
                )}
              </span>
            )}
          </div>
        </div>
        <div className="fs-13 semi-bold flex-start">
          <div className="mr-2">
            <SvgCalendar />
          </div>
          <div>
            {getCycleIntervalString(
              RecurrenceStartDate,
              RecurrenceEndDate,
              RecurrenceType
            )}
          </div>
        </div>
      </div>
      {(recurrences.length !== 1 || showIndicator) && !readOnly && (
        <div className="center ml-2">
          <SvgArrow color={COLORS.GREY_2} className="down-grey" />
          <SvgArrow className="down-blue" />
        </div>
      )}
    </div>
  );
};
CustomTitle.propTypes = {
  recurrences: PropTypes.array,
  selectedCycle: PropTypes.object,
  formatTitleIcon: PropTypes.func,
  customType: PropTypes.string,
};

const getIcon = (recurrence) => {
  const { recurrenceStatus } = recurrence;
  switch (recurrenceStatus) {
    case "INPROGRESS":
      return <SvgLive />;
    case "COMPLETED":
      return <SvgTick color="#2ecc71" backgroundColor="none" />;
    default:
      return false;
  }
};

const formatOptionLabelAfter = (recurrence) => {
  const { recurrenceStatus, RecurrenceGoalValue, GoalAchieved } = recurrence;
  const isIndianTimezone = getUserTimezone();
  const percentage = () =>
    !(RecurrenceGoalValue === null || RecurrenceGoalValue === 0)
      ? parseFloat((GoalAchieved / RecurrenceGoalValue) * 100).toFixed(1)
      : null;

  if (recurrenceStatus === "YETTOSTART") return <></>;
  if (RecurrenceGoalValue === null || RecurrenceGoalValue === 0)
    return <div className="ml-auto fc-grey">- -</div>;

  return (
    <span className="ml-auto" style={{ color: getColor(percentage()) }}>
      {`${NumFormatter(percentage(), isIndianTimezone)}%`}
    </span>
  );
};

const RecurrenceDropdown = (props) => {
  const {
    customType,
    recurrences,
    selectedRecurrence,
    customTitle,
    customMenu,
    formatTitleIcon,
    formatOptionLabelBefore,
    formatOptionLabelAfter,
    leftChildren,
    rightChildren,
    handleChange,
    handleSelect,
    handlePrev,
    handleNext,
    id,
    columns,
    customMenuClassName,
    variant,
    alignTableRight,
    menuStyles,
    width,
    isBackgroundGrey,
    getOptionLabel,
    showDropdownForSingleRecurrence,
    readOnly,
  } = props;

  const selectedCycle = {
    ...selectedRecurrence,
    idx: getIndex(recurrences, selectedRecurrence) + 1,
    recurrenceStatus: getRecurrenceStatus(selectedRecurrence),
  };

  const prevRecurrenceIdx = getIndex(recurrences, selectedRecurrence) - 1;
  const prevRecurrence = recurrences && {
    ...recurrences[prevRecurrenceIdx],
    idx: prevRecurrenceIdx + 1,
    recurrenceStatus: getRecurrenceStatus(recurrences[prevRecurrenceIdx]),
  };
  const nextRecurrenceIdx = getIndex(recurrences, selectedRecurrence) + 1;
  const nextRecurrence = recurrences && {
    ...recurrences[nextRecurrenceIdx],
    idx: nextRecurrenceIdx + 1,
    recurrenceStatus: getRecurrenceStatus(recurrences[nextRecurrenceIdx]),
  };
  const disabledPrev = getIndex(recurrences, selectedRecurrence) === 0;
  const disabledNext =
    getIndex(recurrences, selectedRecurrence) === recurrences.length - 1;

  return (
    recurrences && (
      <div className="recurrence-dropdown d-flex">
        {recurrences?.length !== 1 && !readOnly && (
          <FormButton
            id="recurrence-dropdown-left"
            variant="link"
            className={`${
              isBackgroundGrey && "bg-grey-secondary"
            } recurrence-dropdown__btn recurrence-dropdown__left-btn px-2`}
            icon={<SvgArrow rotate={90} />}
            disabled={disabledPrev}
            disabledIcon={
              disabledPrev && (
                <SvgArrow rotate={90} styles={{ opacity: ".5" }} />
              )
            }
            onClick={() => {
              if (handlePrev) handlePrev(prevRecurrence);
              else handleChange(prevRecurrence);
            }}
          />
        )}
        {leftChildren && leftChildren}
        {variant === "table" ? (
          <RecurrenceTable
            width={width}
            menuStyles={menuStyles}
            alignRight={alignTableRight}
            recurrences={recurrences}
            columns={columns}
            selectedCycle={selectedCycle}
            customType={customType}
            handleClick={handleSelect || handleChange}
            formatOptionLabelBefore={formatOptionLabelBefore}
            formatTitleIcon={formatTitleIcon}
            optionLabelColor={({ RecurrenceId }) =>
              RecurrenceId === selectedRecurrence?.RecurrenceId
                ? "fc-primary-blue"
                : ""
            }
            getOptionLabel={getOptionLabel}
          />
        ) : showDropdownForSingleRecurrence || recurrences?.length !== 1 ? (
          <Dropdown id={id} className="recurrence-dropdown__dropdown">
            <Dropdown.Toggle
              variant="outline-secondary"
              className={classNames([
                "br-0 recurrence-dropdown__toggle",
                isBackgroundGrey ? "bg-grey-secondary" : "",
                readOnly ? "readonly cursor-default" : "hoverable",
              ])}
              disabled={readOnly}
            >
              {customTitle ? (
                customTitle({ recurrences, selectedCycle, customType })
              ) : (
                <CustomTitle
                  recurrences={recurrences}
                  selectedCycle={selectedCycle}
                  formatTitleIcon={formatTitleIcon}
                  customType={customType}
                  showIndicator={showDropdownForSingleRecurrence}
                  readOnly={readOnly}
                />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className={`recurrence-dropdown__menu ${customMenuClassName}`}
            >
              {customMenu ? (
                customMenu({
                  recurrences,
                  selectedCycle,
                })
              ) : (
                <CustomMenu
                  recurrences={recurrences}
                  selectedCycle={selectedCycle}
                  customType={customType}
                  handleClick={handleSelect || handleChange}
                  formatOptionLabelBefore={formatOptionLabelBefore}
                  formatOptionLabelAfter={
                    formatOptionLabelAfter
                      ? (value) =>
                          formatOptionLabelAfter({ ...value, customType })
                      : undefined
                  }
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <div
            className={`recurrence-dropdown__dropdown br-0 ${
              isBackgroundGrey && "bg-grey-secondary"
            } recurrence-dropdown__toggle center px-3`}
          >
            {customTitle ? (
              customTitle({ recurrences, selectedCycle })
            ) : (
              <CustomTitle
                recurrences={recurrences}
                selectedCycle={selectedCycle}
                formatTitleIcon={formatTitleIcon}
                customType={customType}
              />
            )}
          </div>
        )}
        {rightChildren && rightChildren}
        {recurrences?.length !== 1 && !readOnly && (
          <FormButton
            id="recurrence-dropdown-right"
            variant="link"
            className={`${
              isBackgroundGrey && "bg-grey-secondary"
            } recurrence-dropdown__btn recurrence-dropdown__right-btn px-2`}
            icon={<SvgArrow rotate={-90} />}
            disabled={disabledNext}
            disabledIcon={
              disabledNext && (
                <SvgArrow rotate={-90} styles={{ opacity: ".5" }} />
              )
            }
            onClick={() => {
              if (handleNext) handleNext(nextRecurrence);
              else handleChange(nextRecurrence);
            }}
          />
        )}
      </div>
    )
  );
};

RecurrenceDropdown.propTypes = {
  recurrences: PropTypes.array.isRequired,
  selectedRecurrence: PropTypes.object.isRequired,
  customTitle: PropTypes.func,
  customMenu: PropTypes.func,
  formatTitleIcon: PropTypes.func,
  formatOptionLabelBefore: PropTypes.func,
  formatOptionLabelAfter: PropTypes.func,
  leftChildren: PropTypes.node,
  rightChildren: PropTypes.node,
  handleSelect: PropTypes.func,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
  id: PropTypes.string,
  menuStyles: PropTypes.object,
  alignTableRight: PropTypes.bool,
  isBackgroundGrey: PropTypes.bool,
  readOnly: PropTypes.bool,
};

RecurrenceDropdown.defaultProps = {
  recurrences: [],
  customType: "UNIFORM",
  formatOptionLabelAfter,
  formatOptionLabelBefore: getIcon,
  formatTitleIcon: getIcon,
  menuStyles: {},
  alignTableRight: false,
  isBackgroundGrey: true,
  showDropdownForSingleRecurrence: false,
  readOnly: false,
};

export default RecurrenceDropdown;
