import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgMeasure = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      data-name="Group 6873"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8ZM8 2a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6Z"
        data-name="Subtraction 25"
      />
      <path
        fill={color}
        d="M11.5 1.937s-1.218 6.11-1.768 7.062a2 2 0 0 1-3.464-2c.55-.952 5.232-5.062 5.232-5.062Z"
        data-name="Union 71"
      />
      <rect
        width={12}
        height={2}
        fill={color}
        data-name="Rectangle 2889"
        rx={1}
        transform="translate(2 11)"
      />
    </svg>
  );
};

SvgMeasure.defaultProps = {
  color: COLORS.GREY_1,
  height: 16,
  width: 16,
};
export default SvgMeasure;
