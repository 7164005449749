import { gql, useQuery } from "src/api/client";

const GET_PAYOUT_HISTORY_QUERY = gql`
  query payoutHistory($userId: ID, $programId: ID, $recurrenceId: ID) {
    payoutHistory(
      userId: $userId
      programId: $programId
      recurrenceId: $recurrenceId
    ) {
      ... on PayoutHistoryResponse {
        success
        message
        response {
          payoutHistory {
            status
            timeStamp
            referenceId
            payoutDone
            pendingPayout
            PendingRewardsCount
            PaidOutRewardsCount
            PendingRewards {
              RewardId
              RewardName
            }
            PaidOutRewards {
              RewardName
            }
          }
        }
      }
      ... on ErrorResponse {
        message
      }
      __typename
    }
  }
`;

export const useGetPayoutHistory = (queryOptions) => {
  return useQuery(GET_PAYOUT_HISTORY_QUERY, queryOptions);
};
