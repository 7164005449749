import React from "react";
import { Modal } from "react-bootstrap";
import { BackToTop, LoadMoreButton, errorToaster } from "src/components/common";
import { errorText } from "src/helpers";
import { ModalContainer } from "../../modalContainer";
import useAchievementbreakdown from "../hooks/useAchievementbreakdown";
import MobileAchievementBreakDownHeader from "./MobileAchievementBreakDownHeader";
import MobileAchievementBreakdownGrid from "./MobileAchievementBreakdownGrid";

const MobileAchievementBreakdown = ({
  show,
  onHide,
  title,
  currentRecurrence,
  recurrenceList,
  templateId,
  templates,
  selectedGoalUser,
  customType = "",
  managerUserId,
  isIncentive,
  managersDropdownList,
  isDrillDown = false,
  moduleName,
  programId,
  formatOptionLabelAfter,
  goalAggConfig,
  isRollUp,
}) => {
  const {
    attributes,
    actions,
    templateDetails,
    userAttributes,
    achievementDrilldown,
    usersListWithAchievements,
    isSalesUser,
    modalRef,
  } = useAchievementbreakdown({
    selectedGoalUser,
    currentRecurrence,
    templateId,
    templates,
    managerUserId,
    isIncentive,
    managersDropdownList,
    isDrillDown,
    moduleName,
    programId,
    isRollUp,
  });

  const { Recurrence, User, FieldsOrder, Rule } = attributes;
  const {
    setFieldsOrder,
    setGoalUser,
    setSelectedRecurrence,
    setSelectedRule,
    setSearch,
  } = actions;
  if (usersListWithAchievements.error) {
    errorToaster({
      message: errorText.GET_USERS_ACHIEVEMENTS.ERROR,
      type: "error",
    });
  }
  if (userAttributes.error) {
    errorToaster({
      message: errorText.GET_USER_ATTRIBUTES.ERROR,
      type: "error",
    });
  }
  if (templateDetails.error) {
    errorToaster({
      message: errorText.GET_TEMPLATE_DETAIL.ERROR,
      type: "error",
    });
  }
  if (achievementDrilldown.error) {
    errorToaster({
      message: errorText.GET_DRILLDOWN_DATA.ERROR,
      type: "error",
    });
  }
  return (
    <ModalContainer
      show={show}
      onHide={onHide}
      showBackButton={false}
      disableFooter
      showCloseButton
      title={title}
    >
      <Modal.Body className="overflow-y-auto mh-100" ref={modalRef}>
        <div className="p-3">
          <MobileAchievementBreakDownHeader
            {...{
              recurrenceList,
              selectedRecurrence: Recurrence,
              setSelectedRecurrence,
              usersDetailsList: usersListWithAchievements.data,
              selectedGoalUser: User,
              setGoalUser,
              usersAchievementLoading: usersListWithAchievements.loading,
              templateLoading: templateDetails.loading,
              templateDetails: templateDetails.data,
              setSelectedRule,
              hasMoreUsers: usersListWithAchievements.hasMoreUsers,
              onFetchMore: usersListWithAchievements.onFetchMore,
              goalValue: User?.GoalValue,
              goalAchieved: User?.Achievement,
              customType,
              selectedRule: Rule,
              formatOptionLabelAfter,
              isSalesUser,
              goalAggConfig,
              moduleName,
            }}
          />
          <MobileAchievementBreakdownGrid
            {...{
              usersAttributeList: userAttributes.data,
              gridLoading: achievementDrilldown.firstFetchLoading,
              gridError: achievementDrilldown.error,
              userAttributesLoading: userAttributes.loading,
              fieldsOrder: FieldsOrder,
              setFieldsOrder,
              tableData: achievementDrilldown.data,
              setSearch,
              handleDoneButton: userAttributes.handleDoneButton,
              isSalesUser,
              dataNotAvailable: achievementDrilldown.dataNotAvailable,
              userNotPartOfRecurrence:
                achievementDrilldown.userNotPartOfRecurrence,
              hideBreakdownTable: achievementDrilldown.hideBreakdownTable,
              isIncentive,
              hideUserDropDown: usersListWithAchievements.hideUserDropDown,
            }}
          />
        </div>
        {!achievementDrilldown.disabledLoadMore &&
          !achievementDrilldown.hideBreakdownTable && (
            <div
              ref={achievementDrilldown.loadMoreButtonRef}
              className="w-100 my-3 flex-center"
            >
              <LoadMoreButton
                variant="secondary"
                loading={
                  achievementDrilldown.firstFetchLoading ||
                  achievementDrilldown.nextFetchLoading
                }
                disabled={achievementDrilldown.disabledLoadMore}
                id="achievement-breakdown-mobile-show-more"
                onClick={achievementDrilldown.onFetchMore}
                itemsLoaded={achievementDrilldown.itemsLoaded}
                totalItems={achievementDrilldown.totalItems}
              />
            </div>
          )}
        <BackToTop modalRef={modalRef} />
      </Modal.Body>
    </ModalContainer>
  );
};

export default MobileAchievementBreakdown;
