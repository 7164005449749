import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgError = (props) => {
  const { color, height, width, rotate, styles, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...styles,
        transform: `rotate(${rotate}deg)`,
        transformOrigin: "50% 50%",
      }}
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M8 0a8 8 0 1 0 8 8 8.024 8.024 0 0 0-8-8Zm.8 12H7.2v-1.6h1.6V12Zm0-3.2H7.2V4h1.6v4.8Z"
      />
    </svg>
  );
};

SvgError.defaultProps = {
  color: COLORS.RED,
  height: 16,
  width: 16,
  rotate: 0,
  styles: {},
};
export default SvgError;
