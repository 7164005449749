import React from "react";
import { useTranslation } from "react-i18next";
import { Truncate } from "src/components/common/atoms";
import { COLORS, FORMAT_AGGREGATE_FUNCTION } from "src/constants";
import { classNames } from "src/helpers";
import { SvgAggregate, SvgCustomFormula, SvgStandardFormula } from "src/icons";
import { FormButton } from "../../formElements";

const SelectedTemplateContainer = (props) => {
  const { selectedTemplate, onTemplateSelect, displayTemplate } = props;
  const { t } = useTranslation();
  return (
    <>
      {selectedTemplate && (
        <FormButton
          className={classNames([
            displayTemplate &&
            displayTemplate.TemplateId === selectedTemplate.TemplateId
              ? "bg-grey"
              : "",
            "px-3 pt-3 pb-2 border-dark-grey pointer normal w-100",
          ])}
          onClick={() => onTemplateSelect(selectedTemplate)}
          variant="link"
        >
          <p className="fs-13 fc-grey semi-bold flex-start">
            {t("MANAGED_GOALS.LABELS.RULESET_BEING_USED")}:
          </p>
          <p
            className={`${
              displayTemplate &&
              displayTemplate.TemplateId === selectedTemplate.TemplateId
                ? "fc-blue"
                : "fc-black"
            } fs-13 semi-bold`}
          >
            <Truncate
              id="selected-template-name"
              maxWidth="300px"
              text={selectedTemplate.Name}
            />
          </p>
          <div className="fs-12 center">
            {selectedTemplate.IsCustomFormula ? (
              <SvgCustomFormula width={12} height={12} color={COLORS.GREY_2} />
            ) : (
              <SvgStandardFormula
                width={12}
                height={12}
                color={COLORS.GREY_2}
              />
            )}
            <span className="ml-2 fc-black">
              {selectedTemplate.IsCustomFormula
                ? "Custom Formula"
                : "Standard Output"}
            </span>
          </div>
          <div className="fs-11 center semi-bold fc-grey mb">
            <SvgAggregate width={12} height={12} color={COLORS.GREY_2} />
            <span className="ml-2">
              {!selectedTemplate.IsCustomFormula
                ? FORMAT_AGGREGATE_FUNCTION[
                    selectedTemplate.GoalAggregateFunction
                  ]?.displayFormat
                : "Multiple"}{" "}
              {t("MANAGED_GOALS.MESSAGES.FUNCTION")}
            </span>
          </div>
        </FormButton>
      )}
    </>
  );
};
export default SelectedTemplateContainer;
