import { gql, useQuery } from "src/api/client";

const GET_USER_APPROVAL_DETAILS_QUERY = gql`
  query getUserApprovalDetails(
    $programId: ID!
    $recurrenceId: ID!
    $recurrenceStatus: String
    $userId: ID!
    $GroupId: ID
  ) {
    getUserApprovalDetails(
      programId: $programId
      recurrenceId: $recurrenceId
      recurrenceStatus: $recurrenceStatus
      userId: $userId
      GroupId: $GroupId
    ) {
      ... on UserApprovalDetailsResponse {
        response {
          programName
          allowEdit
          isApprovedByAdmin
          showEmptyState
          eligibleAmount
          achievementsCount
          achievementsMetadata
          approvalStatus
          approvedAmount
          rejectedAmount
          remarks
          fileName
          downloadUrl
          action
          currentStage
          isBufferCompleted
          ApprovedRewardsCount
          RejectedRewardsCount
          EligibleRewardsCount
          ApprovedRewards {
            RewardId
            RewardName
            ImageUrl
            RewardCategory
            Count
          }
          EligibleRewards {
            RewardId
            RewardName
            ImageUrl
            RewardCategory
            Count
          }
          RejectedRewards {
            RewardId
            RewardName
            ImageUrl
            RewardCategory
          }
          currentStageApprover {
            userId
            fullName
          }
          approvers
          currentUser {
            userId
            fullName
            emailAddress
          }
          nextUser {
            userId
            fullName
          }
          previousUser {
            userId
            fullName
          }
        }
      }
    }
  }
`;

export const useGetUserApprovalDetails = (queryOptions) => {
  return useQuery(GET_USER_APPROVAL_DETAILS_QUERY, queryOptions);
};
