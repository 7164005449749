import formulaNamespace from "./formulaNamespace";

const {
  TOKEN_TYPE,
  FORMULA_OPERATOR_TYPE,
  FORMULA_PARENTHESES,
  FORMULA_PARENTHESES_TYPE,
} = formulaNamespace;

const getAllowedRangeText = ({ minValue, maxValue }) => {
  const hasMinValue = !Number.isNaN(Number(minValue.value));
  const hasMaxValue = !Number.isNaN(Number(maxValue.value));
  const minRangeOperator = minValue.inclusive ? ">=" : ">";
  const maxRangeOperator = maxValue.inclusive ? "<=" : ">";
  if (hasMinValue || hasMaxValue) {
    return `[${
      hasMinValue ? `minValue ${minRangeOperator} ${minValue.value}` : ""
    }${hasMaxValue && maxValue.value ? ", " : ""}${
      hasMaxValue ? `maxValue ${maxRangeOperator} ${maxValue.value}` : ""
    }]`;
  }
  return "";
};

const getPositionText = (token) => {
  return token.index ? ` at position ${token.index + 1}.` : ".";
};

const FORMULA_ERRORS = {
  EXPECTING_OPERAND: (token) => ({
    code: "EXPECTING_OPERAND",
    message: `Expecting a 'constant', 'variable' or '(' but found '${
      token.value
    }'${getPositionText(token)}`,
    token,
  }),
  NOT_MULTIPLIABLE_WITH_ZERO: (token) => ({
    code: "NOT_MULTIPLIABLE_WITH_ZERO",
    message: `Cannot multiply with 0. Expecting a non-zero value but found 0${getPositionText(
      token
    )}`,
    token,
  }),
  NOT_DIVISIBLE_BY_ZERO: (token) => ({
    code: "NOT_DIVISIBLE_BY_ZERO",
    message: `Cannot divide by 0. Expecting a non-zero value but found 0${getPositionText(
      token
    )}`,
    token,
  }),
  CONSTANT_NOT_IN_RANGE: (token) => ({
    code: "CONSTANT_NOT_IN_RANGE",
    message: `Number not in range. ${getAllowedRangeText({
      minValue: token.validations.minValue,
      maxValue: token.validations.maxValue,
    })}`,
    token,
  }),
  EXPECTING_OPERATOR_OR_CLOSE_PAREN: (token) => ({
    code: "EXPECTING_OPERATOR",
    message: `Expecting an operator but found '${token.value}'${getPositionText(
      token
    )}`,
    token,
  }),
};

const isOpenParentheses = (token) => {
  return (
    token.type === TOKEN_TYPE.PARENTHESIS &&
    token.value ===
      FORMULA_PARENTHESES[FORMULA_PARENTHESES_TYPE.OPEN_PAREN].value
  );
};

const isCloseParentheses = (token) => {
  return (
    token.type === TOKEN_TYPE.PARENTHESIS &&
    token.value ===
      FORMULA_PARENTHESES[FORMULA_PARENTHESES_TYPE.CLOSE_PAREN].value
  );
};

const isOperand = (token) => {
  return (
    token.type === TOKEN_TYPE.CONSTANT ||
    token.type === TOKEN_TYPE.VARIABLE ||
    isOpenParentheses(token)
  );
};

const isOperator = (token) => {
  return token.type === TOKEN_TYPE.OPERATOR;
};

const isValidMinimum = (token, maxValue) => {
  if (token?.validations?.minValue) {
    const value = Number(token.validations.minValue.value);
    const { inclusive } = token.validations.minValue;
    const hasMinValue = !Number.isNaN(Number(value));
    if (hasMinValue) {
      if (inclusive) {
        return token.value >= value;
      }
      return token.value > value;
    }
    return true;
  }
  return true;
};

const isValidMaximum = (token) => {
  if (token?.validations?.maxValue) {
    const value = Number(token.validations?.maxValue?.value);
    const { inclusive } = token.validations.maxValue;
    const hasMaxValue = !Number.isNaN(Number(value));
    if (hasMaxValue) {
      if (inclusive) {
        return token.value <= value;
      }
      return token.value < value;
    }
    return true;
  }
  return true;
};

const isConstantInRange = (token) => {
  if (token.type === TOKEN_TYPE.CONSTANT && token.validations) {
    return isValidMinimum(token) && isValidMaximum(token);
  }
  return true;
};

const isNotZero = (token) => {
  if (token.type === TOKEN_TYPE.CONSTANT) {
    return token.value !== 0;
  }
  return true;
};

const isValidLastToken = (token) => {
  return (
    token.type === TOKEN_TYPE.CONSTANT ||
    token.type === TOKEN_TYPE.VARIABLE ||
    isCloseParentheses(token)
  );
};

const areBracketsBalanced = (expression) => {
  const stack = [];
  let scanner = 0;
  while (scanner < expression.length) {
    const current = expression[scanner];
    if (current === "(" || current === "{" || current === "[") {
      stack.push(current);
    }
    if (stack.length === 0) return false;
    let previous;
    switch (current) {
      case ")":
        previous = stack.pop();
        if (previous === "{" || previous === "[") return false;
        break;

      case "]":
        previous = stack.pop();
        if (previous === "(" || previous === "{") return false;
        break;

      case "}":
        previous = stack.pop();
        if (previous === "(" || previous === "[") return false;
        break;

      default:
        break;
    }
    scanner++;
  }

  return !stack.length;
};

const TOKEN_RULES = {
  IS_OPERAND: {
    rule: isOperand,
    error: FORMULA_ERRORS.EXPECTING_OPERAND,
  },
  IS_CONSTANT_IN_RANGE: {
    rule: isConstantInRange,
    error: FORMULA_ERRORS.CONSTANT_NOT_IN_RANGE,
  },
  IS_MULTIPLIABLE: {
    rule: isNotZero,
    error: FORMULA_ERRORS.NOT_MULTIPLIABLE_WITH_ZERO,
  },
  IS_DIVISIBLE: {
    rule: isNotZero,
    error: FORMULA_ERRORS.NOT_DIVISIBLE_BY_ZERO,
  },
  IS_OPERATOR_OR_CLOSE_PAREN: {
    rule: (token) => isOperator(token) || isCloseParentheses(token),
    error: FORMULA_ERRORS.EXPECTING_OPERATOR_OR_CLOSE_PAREN,
  },
  INVALID_INPUT: {
    rule: (token) => token,
    error: (token) => ({
      message: "Invalid input.",
    }),
  },
  ARE_BRACKETS_BALANCED: {
    rule: areBracketsBalanced,
    error: {
      message: "Brackets are not balanced.",
    },
  },
  IS_VALID_LAST_TOKEN: {
    rule: isValidLastToken,
    error: {
      message: `Incomplete expression. `,
    },
  },
};

const OPERATOR_RULES = {
  [FORMULA_OPERATOR_TYPE.PLUS]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.MINUS]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
  ],
  [FORMULA_OPERATOR_TYPE.MULTIPLY]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
    TOKEN_RULES.IS_MULTIPLIABLE,
  ],
  [FORMULA_OPERATOR_TYPE.DIVIDE]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
    TOKEN_RULES.IS_DIVISIBLE,
  ],
};

const PARENTHESES_RULES = {
  [FORMULA_PARENTHESES_TYPE.OPEN_PAREN]: [
    TOKEN_RULES.IS_OPERAND,
    TOKEN_RULES.IS_CONSTANT_IN_RANGE,
  ],
  [FORMULA_PARENTHESES_TYPE.CLOSE_PAREN]: [
    TOKEN_RULES.IS_OPERATOR_OR_CLOSE_PAREN,
  ],
};

const CONSTANT_RULES = [TOKEN_RULES.IS_OPERATOR_OR_CLOSE_PAREN];

const VARIABLE_RULES = [TOKEN_RULES.IS_OPERATOR_OR_CLOSE_PAREN];

const FIRST_TOKEN_RULES = [
  TOKEN_RULES.IS_OPERAND,
  TOKEN_RULES.IS_CONSTANT_IN_RANGE,
];

const executeRules = ({ rules, token }) => {
  for (let i = 0; i < rules.length; i++) {
    const { rule, error } = rules[i];
    if (!rule(token)) {
      return error(token);
    }
  }
};

const getTokenRules = (token) => {
  if (token.type === TOKEN_TYPE.OPERATOR) {
    return OPERATOR_RULES[token.value];
  }
  if (token.type === TOKEN_TYPE.PARENTHESIS) {
    return PARENTHESES_RULES[token.value];
  }
  if (token.type === TOKEN_TYPE.VARIABLE) {
    return VARIABLE_RULES;
  }
  return CONSTANT_RULES;
};

const validateToken = ({ lastToken, nextToken }) => {
  let rules;

  if (lastToken && nextToken) {
    rules = getTokenRules(lastToken);
  } else if (!nextToken) {
    rules = [TOKEN_RULES.INVALID_INPUT];
  } else {
    rules = FIRST_TOKEN_RULES;
  }
  return executeRules({ rules, token: nextToken });
};

const validateTokens = (tokens) => {
  for (let scanner = -1; scanner < tokens.length - 1; scanner++) {
    const currentToken = tokens[scanner];
    const nextToken = tokens[scanner + 1];
    const tokenError = validateToken({ lastToken: currentToken, nextToken });
    if (tokenError) {
      return tokenError;
    }
  }
  return false;
};

const validateExpression = (tokens) => {
  const lastToken = tokens[tokens.length - 1];
  const expression = tokens.reduce(
    (pv, cv) => String(pv) + String(cv.value),
    ""
  );
  const tokenError = validateTokens(tokens);
  if (tokenError) {
    return tokenError;
  }
  if (!isValidLastToken(lastToken)) {
    return {
      message: "Incomplete expression.",
    };
  }
  if (!areBracketsBalanced(`(${expression})`)) {
    return {
      message: "Brackets are not balanced",
    };
  }
  return false;
};

const formulaValidator = {
  validateToken,
  validateTokens,
  validateExpression,
};

export default formulaValidator;
