import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgUser = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      data-name="Group 11073"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 9109">
        <path fill="none" d="M0 0h14v14H0z" data-name="Rectangle 2966" />
      </g>
      <g data-name="Group 9119">
        <g data-name="Group 9117">
          <g data-name="Group 9109">
            <path fill="none" d="M0 0h14v14H0z" data-name="Rectangle 2966" />
          </g>
          <g
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="bevel"
            data-name="Path 7407"
            style={{
              isolation: "isolate",
            }}
          >
            <path d="M5.218 8.03h3.568a4.61 4.61 0 0 1 4.461 4.745 1.245 1.245 0 0 1-.178.69.928.928 0 0 1-.593.259H1.367a.765.765 0 0 1-.486-.356 1.116 1.116 0 0 1-.124-.593A4.61 4.61 0 0 1 5.218 8.03Z" />
            <path
              fill={color}
              d="M5.218 9.43c-1.548 0-2.831 1.262-3.033 2.895h9.635c-.202-1.633-1.486-2.895-3.034-2.895H5.218m0-1.4h3.568c2.464 0 4.461 2.125 4.461 4.745 0 0 .015.453-.178.69-.193.238-.593.26-.593.26H1.366s-.328-.064-.485-.357c-.158-.293-.124-.593-.124-.593 0-2.62 1.997-4.745 4.46-4.745Z"
            />
          </g>
        </g>
        <path
          fill={color}
          d="M6.999 7.926a3.819 3.819 0 1 0-3.817-3.818 3.819 3.819 0 0 0 3.817 3.818Zm0-6.364a2.546 2.546 0 1 1-2.545 2.546 2.549 2.549 0 0 1 2.545-2.546Z"
        />
      </g>
    </svg>
  );
};

SvgUser.defaultProps = {
  color: COLORS.GREY_1,
  height: 14,
  width: 14,
};
export default SvgUser;
