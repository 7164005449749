import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { FormInput } from "src/components/common/formElements";
import { classNames } from "src/helpers";
import RewardCard from "../rewardCard/RewardCard";
import "./rewardCardWithReferenceId.scss";

const RewardCardWithReferenceId = (props) => {
  const { handleNonMonetaryChange, reward, showError } = props;

  return (
    <div
      className={classNames([
        "w-100 reward-card-with-reference-id",
        reward.isSelected ? "bg-blue6" : "",
      ])}
    >
      <RewardCard
        className="bg-transparent"
        isChecked={reward.isSelected}
        reward={reward}
        currCount={reward.paidOutCount}
        handleRewardCheck={() => {
          handleNonMonetaryChange(
            reward.pos,
            !reward.isSelected,
            !reward.isSelected === false ? "" : reward.referenceId,
            !reward.isSelected === false ? 1 : reward.paidOutCount
          );
        }}
        handleCounterChanges={(e) => {
          handleNonMonetaryChange(
            reward.pos,
            reward.isSelected,
            reward.referenceId,
            e
          );
        }}
      />
      <div className="center ml-5 mr-3 border-top-1 bc-grey12 py-3">
        <FormInput
          id={`${reward.pos}-reference-id`}
          className={classNames([
            "fs-13 h-32 w-180",
            reward.isSelected ? "" : "disabled",
            showError && reward.isSelected && !reward.referenceId
              ? "bc-red"
              : "",
          ])}
          value={reward.referenceId}
          placeholder={t("LEADERBOARD.PAYOUT.LABELS.ENTER_REFERENCE_ID")}
          onChange={(e) => {
            reward.isSelected &&
              handleNonMonetaryChange(
                reward.pos,
                reward.isSelected,
                e.target.value,
                reward.paidOutCount
              );
          }}
        />
        {showError && reward.isSelected && !reward.referenceId && (
          <div className="fs-12 fc-red-warn ml-3">
            {t("INCENTIVES.PAYOUT.ERRORS.ERROR_1")}
          </div>
        )}
      </div>
    </div>
  );
};

RewardCardWithReferenceId.propTypes = {
  handleNonMonetaryRewardChange: PropTypes.func,
  handleReferenceIdChange: PropTypes.func,
  reward: PropTypes.object,
  position: PropTypes.number,
};

RewardCardWithReferenceId.defaultProps = {
  handleNonMonetaryRewardChange: () => {},
  handleReferenceIdChange: () => {},
  reward: {
    RewardId: "33",
    RewardName: "ipad",
    RewardCategory: "VOUCHER",
    CustomRewardImageUrl: "url",
    referenceId: "1234",
    isSelected: true,
    paidOutCount: 0,
    pos: 0,
  },
};

export default RewardCardWithReferenceId;
