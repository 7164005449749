import { APPS, ROLES, USER_ROLES } from "src/constants";
import { useRoleBasedContext } from "src/context";

const useSettingsRbac = () => {
  const {
    userDetails: { role },
  } = useRoleBasedContext();

  const DEFAULT_REDIRECTION = {
    [USER_ROLES.ADMIN]: APPS.SETTINGS.ROUTES.ROOT.path,
    [USER_ROLES.SALES_USER]: APPS.ACE.ROUTES.ROOT.path,
  };

  const getRole = () => {
    if (role === ROLES.ADMIN) {
      return USER_ROLES.ADMIN;
    }

    return USER_ROLES.SALES_USER;
  };

  const ACCESS_ROUTES = {
    [USER_ROLES.ADMIN]: [APPS.SETTINGS.ROUTES.CUSTOM_REWARDS.path],
    [USER_ROLES.SALES_USER]: [],
  };

  const settingsRbac = (route) => {
    return {
      hasAccess: ACCESS_ROUTES[getRole()].includes(route),
      redirect: DEFAULT_REDIRECTION[getRole()],
    };
  };

  return {
    settingsRbac,
    DEFAULT_LOGGED_IN_USER_REDIRECTION: DEFAULT_REDIRECTION[getRole()],
  };
};

export default useSettingsRbac;
