import React from "react";
const SvgAddSubGoal = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width={28} height={28} fill={color} rx={4} />
      <g stroke="#309AFC" strokeWidth={2}>
        <path d="M14 8v12M20 14H8" />
      </g>
    </svg>
  );
};
SvgAddSubGoal.defaultProps = {
  color: "#EEF7FF",
  height: 28,
  width: 28,
};
export default SvgAddSubGoal;
