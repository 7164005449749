import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateDownloadUrl } from "src/api/useIncentive";
import { getPublishedProgramStatus } from "src/apps/incentives/utils";
import {
  FormButton,
  Popover,
  TemplateSelectModal,
  Truncate,
} from "src/components";
import { COLORS } from "src/constants";
import { formatRecurrencePeriod, getFullName } from "src/helpers";
import { SvgDownload, SvgEdit } from "src/icons";
import { UpdateProgramModal } from "../createOrUpdateProgram";

const IncentivePopoverContent = (props) => {
  const {
    disabled,
    programData,
    refetch,
    allowEdit = true,
    templateDetails = [],
  } = props;
  const { t } = useTranslation();
  const [showProgramModal, setShowProgramModal] = useState();
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [createProgramFileDownloadUrl] = useCreateDownloadUrl();
  useEffect(() => {
    if (programData.FileName) {
      createProgramFileDownloadUrl({
        variables: {
          ProgramId: programData.ProgramId,
        },
      }).then(
        (result) => {
          setDownloadUrl(
            result?.data?.createProgramFileDownloadUrl?.response?.url
          );
        },
        (error) => {
          console.error("Create error: ", error);
        }
      );
    } else {
      setDownloadUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programData.FileName]);

  const publishedProgramStatus = getPublishedProgramStatus({
    startDate: programData?.RecurrenceDefinition?.FirstRecurrenceStartDate,
    endDate: programData?.RecurrenceDefinition?.LastRecurrenceEndDate,
  });

  const selectedApprovers = [...(programData.SelectedApprovers || [])].sort(
    (a, b) => Number(a.Stage) - Number(b.Stage)
  );

  return (
    <>
      <Popover
        id="menu"
        popperId="menu-id"
        width="250px"
        popoverBody={
          <div className="goal-template-info-popover">
            <div className="fs-12 semi-bold px-3 pt-3 pb-2 long-text-break">
              {programData.Name}
            </div>
            <p className="fs-11 long-text-break px-3 mb-2">
              {programData.Description}
            </p>
            {downloadUrl && (
              <div className="my-2 mx-1 d-flex">
                <a
                  className="fc-blue px-2 fs-12 pointer flex-center"
                  href={downloadUrl}
                >
                  <SvgDownload className="mr-1" />
                  {t("INCENTIVES.COMMON.LABELS.DOWNLOAD_ATTACHMENT")}
                </a>
              </div>
            )}

            <hr />
            {publishedProgramStatus !== "COMPLETED" && allowEdit ? (
              <>
                <FormButton
                  id="change-info"
                  variant="link"
                  className="fs-12 fc-primary-blue mx-3 my-2"
                  disabled={disabled}
                  onClick={() => setShowProgramModal(true)}
                  icon={
                    <SvgEdit color={COLORS.PRIMARY_BLUE} className="pr-1" />
                  }
                >
                  <span className="fc-primary-blue normal">
                    {t("INCENTIVES.COMMON.LABELS.CHANGE_PROGRAM_NAME_AND_DESC")}
                  </span>
                </FormButton>
                <hr />
              </>
            ) : null}

            {programData?.RecurrenceDefinition?.RecurrencePeriod ? (
              <div className="p-3 d-flex justify-content-between">
                <div className="fs-11">
                  <p className="fc-grey pb-1">
                    {t("MANAGED_GOALS.LABELS.ROLL_UP_CYCLE")}
                  </p>
                  <p className="fs-12">
                    {formatRecurrencePeriod({
                      recurrencePeriod:
                        programData.RecurrenceDefinition?.RecurrencePeriod,
                      customType: programData.RecurrenceDefinition?.CustomType,
                    })}
                  </p>
                </div>
                <div className="fs-11">
                  <p className="fc-grey pb-1">
                    {t("MANAGED_GOALS.LABELS.TOTAL_NO_OF_CYCLES")}{" "}
                  </p>
                  <p className="fs-12">{programData.RecurrencesCount}</p>
                </div>
              </div>
            ) : null}

            <hr />
            {programData?.SelectedTemplates?.length ? (
              <div className="p-3 d-flex w-100">
                <div className="fs-11 w-100">
                  <p className="fc-grey pb-1">
                    {t("RULEBOOK.LABELS.RULESETS_USED")}
                  </p>
                  <div className="fs-12 d-flex align-items-center justify-content-between">
                    <p className="fs-12 semi-bold">
                      {programData.SelectedTemplates.length}{" "}
                      {t("RULEBOOK.LABELS.RULESETS_SELECTED")}
                    </p>
                    <FormButton
                      id="view-info"
                      variant="link"
                      className="fs-12 fc-blue"
                      onClick={() => {
                        setShowTemplateModal(true);
                      }}
                    >
                      <span className="fc-primary-blue normal">
                        {t("RULEBOOK.LABELS.VIEW")}
                      </span>
                    </FormButton>
                  </div>
                </div>
              </div>
            ) : null}

            <hr />
            {selectedApprovers.length ? (
              <div className="p-3 d-flex w-100">
                <div className="fs-11 w-100">
                  <p className="fc-grey pb-1">
                    {t("INCENTIVES.COMMON.LABELS.APPROVERS")}
                  </p>
                  <div className="fs-12">
                    {selectedApprovers.map((approver) => (
                      <p className="fs-12 d-flex">
                        {approver.Type === "MANUAL" ? (
                          <span className="semi-bold ">
                            <Truncate
                              id="approver-name"
                              text={getFullName(
                                approver.FirstName,
                                approver.LastName
                              )}
                              maxWidth="130px"
                            />{" "}
                          </span>
                        ) : (
                          <p>
                            <span className="semi-bold mr-1">
                              {t("INCENTIVES.COMMON.LABELS.LEVEL")}{" "}
                              {approver.Level}
                            </span>
                            {t("INCENTIVES.COMMON.LABELS.REPORTING_MANAGER")}
                          </p>
                        )}
                        <span className="ml-1 fc-grey">
                          {t("INCENTIVES.COMMON.LABELS.STAGE")} {approver.Stage}
                        </span>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        }
      />
      {showProgramModal ? (
        <UpdateProgramModal
          show={showProgramModal}
          handleClose={() => setShowProgramModal(false)}
          programDetails={programData}
          refetch={refetch}
        />
      ) : null}

      {showTemplateModal && programData?.SelectedTemplates && (
        <TemplateSelectModal
          onShowClick={showTemplateModal}
          onCancelClick={() => {
            setShowTemplateModal(false);
          }}
          isViewMode
          selectedTemplate={programData?.SelectedTemplates.map(
            ({ GoalRules, ...template }) => template
          )}
          templateDetails={templateDetails}
          isMultiSelect
        />
      )}
    </>
  );
};

export default IncentivePopoverContent;
