import PropTypes from "prop-types";
import { useReducer } from "react";
import { checkValue } from "src/helpers";

const SLAB = {
  minValue: undefined,
  maxValue: undefined,
  weightage: undefined,
};

const SLAB_PROPTYPES = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  weightage: PropTypes.number.isRequired,
};

const SLAB_ACTIONS = {
  ADD_SLAB: "addSlab",
  SET_MIN_VALUE: "setMinValue",
  SET_MAX_VALUE: "setMaxValue",
  SET_WEIGHTAGE: "setWeightage",
  REMOVE_SLAB: "removeSlab",
};

const slabReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SLAB_ACTIONS.ADD_SLAB: {
      const { slabs } = state;
      if (state.activeSlabIndex !== -1) {
        payload.slab.minValue = slabs[state.activeSlabIndex].maxValue;
      }
      slabs[state.activeSlabIndex + 1] = payload.slab;
      return { ...state, activeSlabIndex: state.activeSlabIndex + 1 };
    }
    case SLAB_ACTIONS.REMOVE_SLAB: {
      const { slabs } = state;
      slabs.splice(state.activeSlabIndex, 1);
      state.slabs = slabs;
      return { ...state, activeSlabIndex: state.activeSlabIndex - 1 };
    }
    case SLAB_ACTIONS.SET_MIN_VALUE: {
      const { slabs } = state;
      slabs[state.activeSlabIndex].minValue = payload.minValue;
      state.slabs = slabs;
      return {
        ...state,
      };
    }
    case SLAB_ACTIONS.SET_MAX_VALUE: {
      const { slabs } = state;
      slabs[state.activeSlabIndex].maxValue = payload.maxValue;
      state.slabs = slabs;
      return {
        ...state,
      };
    }
    case SLAB_ACTIONS.SET_WEIGHTAGE: {
      const { slabs } = state;
      slabs[payload.slabIndex].weightage = payload.weightage;
      state.slabs = slabs;
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

const useSlabStructurePropTypes = {
  slabs: PropTypes.arrayOf(PropTypes.shape(SLAB_PROPTYPES)),
  options: PropTypes.shape({
    minSlabs: PropTypes.number,
    maxSlabs: PropTypes.number,
    minSlabValue: PropTypes.number,
    minSlabWeightage: PropTypes.number,
    allowChange: PropTypes.func,
  }),
};

const useSlabStructureDefaultProps = {
  slabs: [],
  options: {
    minSlabs: 2,
    maxSlabs: 10,
    minSlabValue: 1,
    maxSlabValue: 999999999,
    minSlabWeightage: 0,
    allowChange: () => {},
  },
};

const useSlabStructure = (
  slabs = [],
  options = useSlabStructureDefaultProps.options
) => {
  const [state, dispatch] = useReducer(slabReducer, {
    slabs,
    activeSlabIndex: slabs.length - 1,
  });

  const addSlab = () => {
    if (state.slabs.length < options.maxSlabs) {
      dispatch({
        type: SLAB_ACTIONS.ADD_SLAB,
        payload: {
          slab: { ...SLAB },
        },
      });
    }
  };

  const setMinValue = (minValue) => {
    dispatch({
      type: SLAB_ACTIONS.SET_MIN_VALUE,
      payload: {
        minValue: minValue !== "" ? Number(minValue) : undefined,
      },
    });
  };

  const setMaxValue = (maxValue) => {
    dispatch({
      type: SLAB_ACTIONS.SET_MAX_VALUE,
      payload: {
        maxValue: maxValue !== "" ? Number(maxValue) : undefined,
      },
    });
  };

  const setWeightage = ({ slabIndex, weightage }) => {
    dispatch({
      type: SLAB_ACTIONS.SET_WEIGHTAGE,
      payload: {
        slabIndex,
        weightage: weightage !== "" ? Number(weightage) : undefined,
      },
    });
  };

  const removeSlab = () => {
    dispatch({
      type: SLAB_ACTIONS.REMOVE_SLAB,
    });
  };

  const isSlabItemComplete = (slabIndex) => {
    const slab = state.slabs[slabIndex];
    return (
      checkValue(slab.minValue) &&
      checkValue(slab.maxValue) &&
      checkValue(slab.weightage) &&
      slab.weightage >= options.minSlabWeightage &&
      slab.maxValue > slab.minValue
    );
  };

  const isSlabStructureFulfilled = () => {
    return (
      state.slabs.length >= options.minSlabs &&
      !state.slabs.find((slab, idx) => !isSlabItemComplete(idx))
    );
  };

  const isSlabStructureComplete = () => {
    return (
      state.slabs.length === options.maxSlabs &&
      !state.slabs.find((slab, idx) => !isSlabItemComplete(idx))
    );
  };

  return {
    slabs: state.slabs,
    activeSlabIndex: state.activeSlabIndex,
    options,
    slabActions: {
      addSlab,
      removeSlab,
      setMinValue,
      setMaxValue,
      setWeightage,
      isSlabItemComplete,
      isSlabStructureFulfilled,
      isSlabStructureComplete,
    },
  };
};

useSlabStructure.propTypes = useSlabStructurePropTypes;

useSlabStructure.defaultProps = useSlabStructureDefaultProps;

export default useSlabStructure;
