import React from "react";

const SvgAvatar6 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="qvnc__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .qvnc__cls-1{fill:#fff}.qvnc__cls-2{fill:var(--avatar-dark)}.qvnc__cls-4{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}\n        "
          }
        </style>
      </defs>
      <g id="qvnc__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-9.77 10.04-22.12 17.56-35.98 21.5-.71.2-1.42.4-2.14.57-.76.2-1.52.39-2.29.55-.7.17-1.41.31-2.12.45-5.1 1-10.37 1.53-15.76 1.53-22.86 0-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="qvnc__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2a81.305 81.305 0 0 1-31.93 20.23c-.56.2-1.13.38-1.69.57-.6.18-1.2.37-1.8.54-.56.16-1.12.32-1.68.47a81.328 81.328 0 0 1-21.19 2.79c-22.86 0-43.52-9.43-58.3-24.6 16.3-12.06 36.47-19.19 58.3-19.19 7.27 0 14.37.79 21.19 2.3.56.12 1.12.25 1.68.39.6.13 1.2.28 1.8.44.57.14 1.13.3 1.69.46a97.512 97.512 0 0 1 31.93 15.6Z"
          className="qvnc__cls-2"
        />
        <path
          d="M43.94 71.71a38.605 38.605 0 0 0 15.02 30.51l2.55 1.81c1.35.87 2.76 1.66 4.21 2.36l3.85 1.59c3.48 1.21 7.13 1.87 10.81 1.97.35 0 .71.01 1.06.01 4.63 0 9.22-.84 13.54-2.51l5.26-2.54c.44-.25.87-.52 1.3-.79l2.58-1.8c1.21-.93 2.36-1.92 3.45-2.98a37.167 37.167 0 0 0 11.35-26.88V59.8H44.03l-.11 11.9Zm61.76 25.7a34.953 34.953 0 0 1-6.88 5.22c-.11.04-.21.1-.3.17a34.707 34.707 0 0 1-18.07 4.45c-11.2-.31-21.15-6.19-27.31-14.97 6.38-4.55 22.3-15.01 28.29-15.01s21.65 10.32 28.5 15.17a35.827 35.827 0 0 1-4.24 4.96Zm10.54-24.95a34.53 34.53 0 0 1-4.83 17.72c-4.78-3.37-22.64-15.6-29.97-15.6S55.53 87.3 51.68 90.02a35.655 35.655 0 0 1-5.03-18.31v-9.2h69.59v9.96ZM8.62 88.71l16.66 26.38-3.87 2.46c-.51.32-.74.94-.57 1.51.17.58.7.97 1.3.97.26 0 .51-.07.73-.21l3.85-2.46 7.2 11.4 1.36 2.11 12.9-6.15L47 122.9l-8.37-13.13 3.97-2.53c.63-.4.81-1.23.41-1.86-.4-.63-1.23-.81-1.86-.41l-3.97 2.51-15.42-24.21-1.34-2.1c-1-1.57-2.57-2.67-4.39-3.07-1.81-.4-3.71-.07-5.27.93-1.57 1-2.67 2.57-3.07 4.39-.4 1.81-.07 3.71.93 5.28Zm20.37 27.21 7.37-4.69 8.15 12.78-8.12 3.59L29 115.91Zm-18.66-31.9c.25-1.11.92-2.08 1.88-2.7.96-.61 2.13-.82 3.24-.58 1.12.24 2.09.93 2.71 1.89L25.4 94l1.44 2.26 1.45 2.28 6.63 10.4-7.36 4.69-6.58-10.43-1.44-2.3-6.58-10.4h.01l-1.44-2.29-.61-.96c-.61-.96-.82-2.13-.58-3.24Z"
          className="qvnc__cls-1"
        />
        <path
          d="m58.29 71.18 12.15 2.18M105.46 71.18l-12.14 2.18"
          className="qvnc__cls-4"
        />
        <path
          d="M155.1 83.43a7.026 7.026 0 0 0-3.07-4.39c-1.56-1-3.46-1.33-5.27-.93-1.81.4-3.39 1.51-4.39 3.07l-1.34 2.1-15.42 24.21-3.97-2.51c-.63-.4-1.46-.22-1.86.41-.4.63-.22 1.46.41 1.86l3.97 2.53-8.37 13.13-1.18 1.82 12.9 6.15 1.36-2.11 7.2-11.4 3.85 2.46c.22.14.47.21.73.21.6 0 1.13-.39 1.3-.97a1.33 1.33 0 0 0-.57-1.51l-3.87-2.46 16.66-26.38c1-1.57 1.33-3.46.93-5.28Zm-28.69 44.17-8.12-3.59 8.15-12.78 7.37 4.69-7.39 11.69Zm25.47-40.34-.61.96-1.44 2.29h.01l-6.58 10.41-1.44 2.3-6.58 10.43-7.36-4.69 6.63-10.4 1.45-2.28 1.44-2.26 7.24-11.37c.61-.97 1.59-1.65 2.71-1.89a4.3 4.3 0 0 1 3.24.58c.96.61 1.64 1.58 1.88 2.7s.04 2.28-.58 3.24Z"
          className="qvnc__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar6.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar6;
