import React, { useLayoutEffect, useState } from "react";
import { FormButton } from "src/components";
import { classNames } from "src/helpers";

const MultilineTruncate = ({ children, height, className }) => {
  const [isOverflown, setIsOverflown] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [node, setNode] = useState(null);

  useLayoutEffect(() => {
    if (node !== null) {
      const child = node?.childNodes[0];
      if (node && child) {
        const childHeight = child.offsetHeight;
        const containerHeight = node.offsetHeight;
        if (childHeight > containerHeight) {
          setIsOverflown(true);
          setToggle(true);
        } else {
          setIsOverflown(false);
          setToggle(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  return (
    <div>
      <div
        ref={setNode}
        style={{
          ...(height && isOverflown && { height }),
          ...(isOverflown && { overflowY: "hidden" }),
        }}
      >
        {children}
      </div>
      {toggle && (
        <FormButton
          className={classNames([
            "cursor-pointer  fs-12 show-more-button",
            className,
          ])}
          onClick={() => setIsOverflown((prev) => !prev)}
          variant="normal-link"
        >
          <span className="text-underline">
            {isOverflown ? "See More" : "See Less"}
          </span>
        </FormButton>
      )}
    </div>
  );
};

MultilineTruncate.defaultProps = {
  height: "40px",
};

export default MultilineTruncate;
