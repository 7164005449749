import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

// src/modules/templates/rules/goalRuleModal/AddGoalRuleModal.js
const UPDATE_GOAL_RULE_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation updateGoalRule(
    $RuleId: ID!
    $TemplateId: ID!
    $Entity: Entity!
    $RollUpFields: RollUpFields!
    $Conditions: [Condition]
    $CriteriaPattern: String!
  ) {
    updateGoalRule(
      RuleId: $RuleId
      TemplateId: $TemplateId
      Entity: $Entity
      RollUpFields: $RollUpFields
      Conditions: $Conditions
      CriteriaPattern: $CriteriaPattern
    ) {
      ... on SingleGoalRuleResponse {
        response {
          GoalRule {
            RuleId
            TemplateId
            Entity
            RollUpFields
            Conditions
            CriteriaPattern
          }
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useUpdateGoalRule = () => {
  return useMutation(UPDATE_GOAL_RULE_MUTATION);
};
