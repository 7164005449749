import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgSortModern = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M5.839 15.722a.762.762 0 0 1-.875.222.73.73 0 0 1-.241-.164l-3-3.008a.75.75 0 1 1 1.059-1.061L4.5 13.444V3.75a.75.75 0 0 1 1.5 0v9.684l1.722-1.718a.75.75 0 0 1 1.059 1.061l-2.942 2.945ZM13.339 2.278a.762.762 0 0 0-.875-.222.732.732 0 0 0-.241.164l-3 3.008a.75.75 0 1 0 1.055 1.061L12 4.556v9.694a.75.75 0 1 0 1.5 0V4.566l1.722 1.718a.75.75 0 1 0 1.059-1.061l-2.942-2.945Z"
      />
    </svg>
  );
};
SvgSortModern.defaultProps = {
  color: COLORS.GREY_2,
  height: 18,
  width: 18,
};
export default SvgSortModern;
