import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import { FormTooltip } from "src/components/common/formElements";

const CycleDurationDropdown = (props) => {
  const {
    recurrencePeriod,
    onChange,
    disabled,
    disabledCycleDurationTip,
    id,
    cycleDurationLabel,
    cycleDurationOptions,
  } = props;

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      paddingTop: "0px !important",
      fontSize: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      borderTop: "1px solid #309afc",
      marginTop: "-1px !important",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#309afc" : "#000",
      cursor: "pointer",
      backgroundColor: state.isFocused ? "#f1fafe" : "",
      padding: "7px 16px",
    }),
  };

  const formatOptionLabel = ({ label, subText, isDisabled }) => (
    <div className={`flex-between ${isDisabled ? "fc-grey" : "fc-black"}`}>
      <span className="fs-14">{label}</span>
      {subText && <span className="fc-12 font-italic">{subText}</span>}
    </div>
  );

  return (
    <>
      {cycleDurationLabel && (
        <label className="semi-bold fs-14 pl-0 mb-13">
          {cycleDurationLabel}
        </label>
      )}
      <FormTooltip
        id="last-cycle-range-tip"
        disable={!disabledCycleDurationTip}
        text={disabledCycleDurationTip}
        placement="right"
      >
        <Select
          id={id}
          classNamePrefix="drop-select"
          isDisabled={disabled}
          styles={customStyles}
          placeholder={t("MANAGED_GOALS.LABELS.SELECT_PERIOD")}
          value={
            recurrencePeriod
              ? cycleDurationOptions.find(
                  (item) => item.value === recurrencePeriod
                )
              : null
          }
          onChange={(e) =>
            e.value === recurrencePeriod ? undefined : onChange(e.value)
          }
          options={cycleDurationOptions}
          formatOptionLabel={formatOptionLabel}
        />
      </FormTooltip>
    </>
  );
};

CycleDurationDropdown.propTypes = {
  recurrencePeriod: PropTypes.string,
  isDistinct: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  displayNote: PropTypes.string,
};

export default CycleDurationDropdown;
