import { ApolloProvider } from "@apollo/client";
import i18next from "i18next";
import React, { useEffect } from "react";
import { useAppApolloClient } from "src/config/apolloClient";
import { isEmpty, removeLoadingIconFromParent } from "src/helpers";
import { AuthGate } from "./AuthGate";

const AceApp = ({ children, isOpenAceUser, isLoggedIn }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const extractValueFromParam = (paramName, defaultValue = "") => {
    const paramValue = urlParams.get(paramName);
    return !isEmpty(paramValue) ? paramValue : defaultValue;
  };

  const pendoURLData = extractValueFromParam("PendoData");
  const locale = extractValueFromParam("Culture", "en-US");

  const apolloClient = useAppApolloClient({ isOpenAceUser });

  i18next.changeLanguage(locale);

  useEffect(() => {
    removeLoadingIconFromParent();
  });

  return (
    <ApolloProvider client={apolloClient}>
      <AuthGate
        {...{
          isOpenAceUser,
          isLoggedIn,
          pendoURLData,
        }}
      >
        {children}
      </AuthGate>
    </ApolloProvider>
  );
};

export default AceApp;
