import { gql, useQuery } from "src/api/client";

const GET_USER_PAYOUT_DETAILS_QUERY = gql`
  query userPayoutDetails(
    $userId: ID!
    $programId: ID!
    $recurrenceId: ID!
    $query: PayoutUsersQuery!
  ) {
    userPayoutDetails(
      userId: $userId
      programId: $programId
      recurrenceId: $recurrenceId
      query: $query
    ) {
      ... on UserPayoutDetailsResponse {
        success
        message
        response {
          fullName
          cycle {
            RecurrenceStartDate
            RecurrenceEndDate
          }
          programName
          approvedBenefit
          totalPayoutDone
          payoutStatus
          referrenceId
          pendingPayout
          PendingRewardsCount
          PaidOutRewardsCount
          ApprovedRewardsCount
          RecurrenceIndex
          PayoutSchedule {
            RecurrenceId
            PendingPayout
            PayoutDone
            Percentage
            RecurrenceIndex
            RecurrenceStartDate
            RecurrenceEndDate
            BufferDays
            AvailableForPayout
          }
          PendingRewards {
            RewardId
            RewardName
            RewardCategory
            ImageUrl
            Count
          }
          PaidOutRewards {
            RewardId
            RewardName
            RewardCategory
            ImageUrl
            Count
          }
          ApprovedRewards {
            RewardId
            RewardName
            RewardCategory
            ImageUrl
          }
          RejectedRewards {
            RewardId
            RewardName
            RewardCategory
            ImageUrl
          }
          previousRequest {
            programId
            recurrenceId
            userId
          }
          nextRequest {
            programId
            recurrenceId
            userId
          }
        }
      }
    }
  }
`;

export const useGetUserPayoutDetails = (queryOptions) => {
  return useQuery(GET_USER_PAYOUT_DETAILS_QUERY, queryOptions);
};
