import { gql, useQuery } from "src/api/client";

const GET_CUSTOM_REWARD_DELETE_BLOCKING_ITEMS = gql`
  query getCustomRewardDeleteBlockingItems(
    $RewardId: ID
    $Before: String
    $After: String
    $Limit: Float
    $FeatureType: String
  ) {
    getCustomRewardDeleteBlockingItems(
      RewardId: $RewardId
      Before: $Before
      After: $After
      Limit: $Limit
      FeatureType: $FeatureType
    ) {
      ... on CustomRewardDeleteBlockingResponse {
        success
        message
        response {
          Incentives
          Leaderboard
          IncentiveCount
          LeaderboardCount
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useGetCustomRewardDeleteBlockingItems = (queryOptions) => {
  return useQuery(GET_CUSTOM_REWARD_DELETE_BLOCKING_ITEMS, queryOptions);
};
