import React from "react";
import { classNames } from "src/helpers";
import Body from "./Body";
import Header from "./Header";
import Overlay from "./Overlay";
import "./leaderboardGrid.scss";

const LeaderboardGrid = ({ className, children }) => (
  <div className={classNames(["leaderboard-grid", className])}>{children}</div>
);

export default Object.assign(LeaderboardGrid, {
  Header,
  Body,
  Overlay,
});
