import { useTranslation } from "react-i18next";
import { useCreateProgramFileUploadURL } from "src/api/useIncentive";
import { useUploadFileToS3 } from "src/api/useUploadFileToS3";
import { DropZone, FILE_FORMATS, toaster } from "src/components";
import "./programInformation.scss";

const ALLOWED_FILE_SIZE_IN_MB = 15;

const ProgramInformation = ({
  programId,
  name,
  value,
  label,
  tip,
  setDisable,
  setFileDetails,
}) => {
  const { t } = useTranslation();
  const [createUploadURL] = useCreateProgramFileUploadURL();
  const { uploadFileToS3, cancelUpload } = useUploadFileToS3();

  const handleRemove = () => {
    setDisable(false);
    setFileDetails({
      name,
      value: {
        FileName: null,
        FileId: null,
      },
    });
  };

  const handleCancelUpload = () => {
    cancelUpload();
    setDisable(false);
  };

  const handleUpload = ({ acceptedFiles }) => {
    setDisable(true);
    if (value.FileName) {
      handleRemove();
    }
    createUploadURL({
      variables: {
        ProgramId: programId,
      },
    }).then((result) => {
      const presignedUrl =
        result?.data?.createProgramFileUploadUrl?.response?.url;
      uploadFileToS3({
        presignedUrl,
        file: acceptedFiles[0],
      }).then(() => {
        setDisable(false);
        toaster({
          message: "File uploaded successfully.",
          type: "info",
        });
        setFileDetails({
          name,
          value: {
            FileName: acceptedFiles[0].name,
            FileId: result?.data?.createProgramFileUploadUrl?.response?.fileId,
          },
        });
      });
    });
  };

  return (
    <>
      <div className="fs-14 w-100 center">
        <span className="semi-bold">
          {t("INCENTIVES.COMMON.LABELS.UPLOAD_INCENTIVE_INFORMATION")}
        </span>
        <span className="fc-grey ml-1">
          ({t("INCENTIVES.COMMON.LABELS.OPTIONAL")})
        </span>
      </div>
      <div className="fs-12 fc-grey mt-2 mb-3">
        {t("INCENTIVES.COMMON.LABELS.UPLOAD_INCENTIVE_INFORMATION_NOTE")}
      </div>
      <div className="incentive-creation-modal">
        <DropZone
          fileName={value.FileName}
          maxSize={ALLOWED_FILE_SIZE_IN_MB}
          accept={[
            FILE_FORMATS.PDF,
            FILE_FORMATS.DOC,
            FILE_FORMATS.XLSX,
            FILE_FORMATS.PPT,
          ]}
          OnLoadProps={{
            title: t("INCENTIVES.COMMON.LABELS.FILE_PICKER_TITLE"),
            hintText: t("INCENTIVES.COMMON.LABELS.FILE_PICKER_HINT", {
              size: ALLOWED_FILE_SIZE_IN_MB,
            }),
            buttonText: t("INCENTIVES.COMMON.LABELS.FILE_PICKER_BUTTON"),
          }}
          handleUpload={handleUpload}
          handleRemove={handleRemove}
          isOnSuccess={Boolean(value.FileName)}
          handleCancel={handleCancelUpload}
          onError={() => setDisable(true)}
        />
      </div>
    </>
  );
};

export default ProgramInformation;
