import { gql, useQuery } from "src/api/client";

const GET_APPROVAL_ACHIEVEMENTS_QUERY = gql`
  query userAchievements(
    $programId: ID!
    $recurrenceId: ID
    $recurrenceStatus: String
    $userId: ID
  ) {
    userAchievements(
      programId: $programId
      recurrenceId: $recurrenceId
      recurrenceStatus: $recurrenceStatus
      userId: $userId
    ) {
      ... on UserAchievementsResponse {
        success
        message
        response {
          userId
          userName
          totalEligibleBenefit
          achievementsCount
          type
          achievementsMetadata
        }
      }
    }
  }
`;

export const useGetApprovalAchievements = (queryOptions) => {
  return useQuery(GET_APPROVAL_ACHIEVEMENTS_QUERY, queryOptions);
};
