import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgOwnerNoHierarchy = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 8714">
        <path fill="none" d="M0 0h16v16H0Z" data-name="Path 4790" />
        <g data-name="Group 8703">
          <path
            fill={backgroundColor}
            d="M6.998 14.998H5.539v-.006H1.998a1 1 0 0 1-1-1v-1a3.989 3.989 0 0 1 3.053-3.889l.582 1.165a3.561 3.561 0 0 0-1.939 3.281H5.7A5.031 5.031 0 0 0 7 14.992ZM5.676 8.476a3.858 3.858 0 1 1 4.99-2.437 5.017 5.017 0 0 0-1.4.007 2.571 2.571 0 1 0-2.732 1.337 4.956 4.956 0 0 0-.861 1.093Z"
            data-name="Subtraction 37"
          />
          <g data-name="351983_error_icon">
            <path
              fill="none"
              d="M4.998 5.998h10v10h-10Z"
              data-name="Path 4785"
            />
            <g fill="none" data-name="Subtraction 36">
              <path d="M9.997 6.665a4.333 4.333 0 1 0 4.333 4.333 4.333 4.333 0 0 0-4.333-4.333Z" />
              <path
                fill={color}
                d="M9.997 8.165a2.836 2.836 0 0 0-2.833 2.833 2.836 2.836 0 0 0 2.833 2.833 2.836 2.836 0 0 0 2.833-2.833 2.836 2.836 0 0 0-2.833-2.833m0-1.5a4.333 4.333 0 1 1 0 8.666 4.333 4.333 0 0 1 0-8.666Z"
              />
            </g>
            <path
              fill={color}
              d="m12.096 8.632 1 1.732-5.196 3-1-1.732z"
              data-name="Rectangle 3691"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgOwnerNoHierarchy.defaultProps = {
  backgroundColor: COLORS.GREY_1,
  color: COLORS.RED,
  height: 16,
  width: 16,
};
export default SvgOwnerNoHierarchy;
