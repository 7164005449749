import { gql, useMutation } from "src/api/client";

// src/goalCrud/distributeGoal/importModal/importFooters/DragDrop.js
export const GOAL_UPLOAD_URL_MUTATION = gql`
  mutation getImportGoalUploadUrl($GoalId: ID!) {
    getImportGoalUploadUrl(GoalId: $GoalId) {
      message
      urlData {
        url
        fileId
      }
    }
  }
`;

export const useGoalUploadURL = () => {
  return useMutation(GOAL_UPLOAD_URL_MUTATION);
};
