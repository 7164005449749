import { t } from "i18next";
import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import {
  MobileFilterBarFilters,
  MobileFilterBarIcons,
  useFilter,
} from "src/components/base";
import { BackToTop } from "src/components/common/atoms";
import { ModalContainer } from "src/components/container";
import RewardsFlatlistBody from "./RewardsFlatlistBody";
import { REWARDS_FLAT_LIST_FILTERS } from "./data";

const RewardsDetailsFlatlistModal = (props) => {
  const { show, handleClose, title, payload } = props;
  const modalRef = useRef(null);

  const {
    fieldName,
    featureType,
    userId,
    programId,
    recurrenceId,
    criteriaId,
    historyId,
    rewardIds,
    Rank,
    GroupId,
    IsUserIdManager,
  } = payload || {};

  const { filtersState, ...rest } = useFilter(REWARDS_FLAT_LIST_FILTERS);

  const getQuery = () => {
    return {
      FieldName: fieldName,
      UserId: userId,
      ProgramId: programId,
      RecurrenceId: recurrenceId,
      FeatureType: featureType,
      CriteriaId: criteriaId,
      HistoryId: historyId,
      RewardIds: rewardIds,
      Rank,
      GroupId,
      Search: filtersState.search,
      IsUserIdManager,
    };
  };

  return show ? (
    <ModalContainer
      show={show}
      onHide={handleClose}
      title={`${title} - ${t("NON_MONETARY.LABELS.NON_MONETARY_REWARD_DETAILS")}
`}
      disableFooter
      showCloseButton
      showBackButton={false}
    >
      <Modal.Body className="overflow-y-auto mh-100" ref={modalRef}>
        <div className="p-3">
          <div className="flex-between">
            <div className="fs-14 semi-bold">Rewards</div>
            <div>
              <MobileFilterBarIcons
                filters={REWARDS_FLAT_LIST_FILTERS}
                filtersState={filtersState}
                {...rest}
              />
            </div>
          </div>
          <MobileFilterBarFilters
            filters={REWARDS_FLAT_LIST_FILTERS}
            filtersState={filtersState}
            {...rest}
          />
          <div className="mt-3">
            <RewardsFlatlistBody {...{ show, getQuery }} />
          </div>
        </div>
        <BackToTop modalRef={modalRef} />
      </Modal.Body>
    </ModalContainer>
  ) : (
    <></>
  );
};

export default RewardsDetailsFlatlistModal;
