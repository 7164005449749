import { gql, useMutation } from "src/api/client";

const BULK_APPROVAL_FILE_UPLOAD_URL_MUTATION = gql`
  mutation bulkApprovalFileUploadUrl($programId: ID, $recurrenceId: ID) {
    bulkApprovalFileUploadUrl(
      programId: $programId
      recurrenceId: $recurrenceId
    ) {
      ... on ApprovalFileUploadUrlResponse {
        success
        message
        response {
          url
          fileId
        }
      }
    }
  }
`;

export const useBulkApprovalFileUploadUrl = () => {
  return useMutation(BULK_APPROVAL_FILE_UPLOAD_URL_MUTATION);
};
