import React from "react";

const SvgYetToStartSm = (props) => {
  const { height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 15 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <ellipse
        cx={7.009}
        cy={7}
        fill="#E5BC98"
        opacity={0.6}
        rx={7.009}
        ry={7}
      />
      <ellipse cx={7.012} cy={7} fill="#E28D0C" rx={4.988} ry={4.982} />
    </svg>
  );
};
SvgYetToStartSm.defaultProps = {
  height: 14,
  width: 14,
};
export default SvgYetToStartSm;
