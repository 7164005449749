import { gql, useQuery } from "src/api/client";

const GET_DASHBOARD_PROGRAM_USERS = gql`
  query dashboardProgramUsers(
    $UserId: ID
    $ProgramId: ID
    $RecurrenceId: ID
    $Status: String
    $limit: Float
    $after: String
    $query: PayoutUsersQuery
    $GroupId: ID
  ) {
    dashboardProgramUsers(
      UserId: $UserId
      ProgramId: $ProgramId
      GroupId: $GroupId
      RecurrenceId: $RecurrenceId
      Status: $Status
      limit: $limit
      after: $after
      query: $query
    ) {
      ... on DashboardUsersResponse {
        success
        message
        response {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
          totalCount
          totalUsersCount
          dashboardUsers {
            userId
            fullName
            emailAddress
            totalApprovedAmount
            totalEligibleAmount
            totalPaidOut
            totalPendingPayout
            Status
            TotalEligibleRewardIds
            TotalPaidOutRewards {
              RewardId
              RewardName
            }
            TotalApprovedRewards {
              RewardId
              RewardName
            }
            TotalEligibleRewards {
              RewardId
              RewardName
            }
            TotalPendingPayoutRewards {
              RewardId
              RewardName
            }
            TotalPaidOutRewardsCount
            TotalApprovedRewardsCount
            TotalEligibleRewardsCount
            TotalPendingPayoutRewardsCount
            GroupId
            GroupName
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        message
        __typename
      }
      __typename
    }
  }
`;

export const useGetDashboardLeaderboardUsers = (queryOptions) => {
  return useQuery(GET_DASHBOARD_PROGRAM_USERS, queryOptions);
};
