import React from "react";
import { FormButton } from "src/components/common";
import { APPS } from "src/constants";
import { ErrorPage } from "src/images";
import { useHistory } from "src/router";

const AceErrorPage = () => {
  const history = useHistory();
  return (
    <div className="h-screen flex-center">
      <div className="position-relative flex-column space-y-16">
        <div className="flex-center">
          <ErrorPage />
        </div>
        <div className="fs-24 semi-bold flex-center">Something went wrong</div>
        <div>
          <span className="fs-16 fc-grey1 flex-center text-center">
            Please try login again.
          </span>
        </div>
        <div className="flex-center">
          <FormButton onClick={() => history.push(APPS.ACE.ROUTES.ROOT.path)}>
            <span>Go to homepage</span>
          </FormButton>
        </div>
      </div>
    </div>
  );
};

export default AceErrorPage;
