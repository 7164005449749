import { t } from "i18next";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormButton } from "src/components";
import CompleteProgramModal from "./CompleteProgramModal";

const ClickAwayProgramModal = ({
  show,
  formId,
  clickAwayAction,
  onHide,
  viewSubmittable,
  viewSubmitting,
  activeStepName,
}) => {
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    onHide && onHide();
    setOpen(false);
  };

  const handleDiscard = () => {
    clickAwayAction();
  };

  return (
    <>
      {show && viewSubmittable && !viewSubmitting ? (
        <Modal show={open} onHide={handleClose}>
          <Modal.Header className="semi-bold" closeButton>
            {t("MANAGED_GOALS.LABELS.UNSAVED_CHANGES")}{" "}
          </Modal.Header>
          <Modal.Body className="mx-4 my-3 fs-13">
            {t("MANAGED_GOALS.MESSAGES.UNSAVED_CHANGES_CYCLE")}
          </Modal.Body>
          <Modal.Footer>
            <FormButton
              label={t("COMMON.BUTTONS.CANCEL")}
              variant="outline-primary"
              onClick={handleClose}
              id="incentive-click-away-cancel"
            />
            <FormButton
              label={t("MANAGED_GOALS.BUTTONS.DISCARD_AND_PROCEED")}
              variant="outline-primary"
              id="incentive-click-away-discard"
              onClick={handleDiscard}
            />
            {formId ? (
              <FormButton
                type="submit"
                name="save"
                form={formId}
                label={t("MANAGED_GOALS.BUTTONS.SAVE_CHANGES")}
                variant="outline-primary"
                id="incentive-click-away-save"
              />
            ) : null}
          </Modal.Footer>
        </Modal>
      ) : null}
      {show && !viewSubmittable && !viewSubmitting ? (
        <CompleteProgramModal
          show={open}
          handleClose={handleClose}
          handleDiscard={handleDiscard}
          heading={t("MANAGED_GOALS.MESSAGES.COMPLETE_PROGRAM_DETAILS")}
        >
          {t("MANAGED_GOALS.MESSAGES.DETAILS_INCOMPLETE", {
            activeStepName: activeStepName?.replace("&", "and"),
          })}
        </CompleteProgramModal>
      ) : null}
    </>
  );
};

export default ClickAwayProgramModal;
