import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  FormButton,
  FormInput,
  FormTooltip,
} from "src/components/common/formElements";
import { COLORS, REGEX_FOR_CREATE_NUMBERS, maxCharLimit } from "src/constants";
import { FIELD_NAMES } from "src/constants/nonMonetaryConstants";
import { classNames, debounce, getPluralText, isEqual } from "src/helpers";
import {
  SvgAttachment,
  SvgBack,
  SvgEdit,
  SvgErrorToast,
  SvgForward,
  SvgMonetaryRewardApproval,
  SvgNonMonetaryRewardApproval,
  SvgSuccessToast,
} from "src/icons";
import { NonMonetaryRewardDetails, RewardCard } from "../../nonMonetary";
import { AmountLabel, getAmountMaximumBase } from "../amountLabel";
import { ConfirmationModal } from "../confirmationModal";
import { FILE_FORMATS, FilePickerButton } from "../filePicker";
import { NonMonetaryLabel } from "../monetaryAndNonMonetaryLabel";
import { MultilineTruncate } from "../multilineTruncate";
import "./approveAmount.scss";

const CONFIRMATION_TYPES = {
  PROCEED: "PROCEED",
  FORWARD: "FORWARD",
  BACKWARD: "BACKWARD",
  REJECT: "REJECT",
};

const InputForCurrency = ({
  id,
  currency,
  width,
  placeholder,
  text,
  regex,
  onChange,
  warning,
  onBlur,
  disableIcon,
  disabled,
}) => {
  return (
    <div
      className={classNames([
        "px-2 py-1 br-4",
        warning ? "border-1 bc-red" : "",
      ])}
      style={{
        width: width || "100%",
        backgroundColor: disabled ? "#e9ecef" : COLORS.WHITE,
      }}
    >
      <div className="input-group center">
        {!disableIcon && <div className="fs-13 fc-grey1">{currency}</div>}
        <FormInput
          id={id}
          type="number"
          className="fs-13 border-0"
          value={text}
          regex={regex}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const ApproveAmount = ({
  eligibleAmount,
  approvedAmount,
  remarks,
  attachment,
  attachmentUrl,
  currentStage,
  lastStage,
  isReadOnly,
  currency,
  fileName,
  eligibleRewardsCount,
  handleSendForward,
  handleSendBackward,
  handleUpload,
  eligibleRewards = [],
  rejectedRewards = [],
  approvedRewards = [],
  handleRemove,
  handleCancel,
  disable,
  type,
  payloadForRewardsList,
  formattedEligibleRewards,
  approvedRewardCount,
  rejectedRewardCount,
  disableApprovalPayout = false,
}) => {
  const { t } = useTranslation();

  const [rewardsList, setRewardsList] = useState(formattedEligibleRewards);

  const [currApprovedAmount, setCurrApprovedAmount] = useState(
    approvedAmount === null ? "" : approvedAmount
  );
  const [currRemarks, setCurrRemarks] = useState("");
  const [showEditButton, setShowEditButton] = useState(false);
  const [showInputWarning, setShowInputWarning] = useState(false);
  const [showTextareaWarning, setShowTextareaWarning] = useState(false);
  const [remarksFocused, setRemarksFocused] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [showRewardDetailsModal, setShowRewardDetailsModal] = useState(false);
  const cancelButtonRef = useRef(null);

  const CONFIRMATION_TYPES_TITLE =
    type === "LEADERBOARD"
      ? {
          PROCEED: "LEADERBOARD.COMMON.MESSAGES.PROCEED_REWARD_REQUEST",
          FORWARD: "LEADERBOARD.COMMON.MESSAGES.FORWARD_REWARD",
          BACKWARD: "LEADERBOARD.COMMON.MESSAGES.SEND_BACK_REWARD",
          REJECT: "LEADERBOARD.COMMON.MESSAGES.PROCEED_REWARD_REQUEST",
        }
      : {
          PROCEED: "INCENTIVES.COMMON.MESSAGES.PROCEED_INCENTIVE_REQUEST",
          FORWARD: "INCENTIVES.COMMON.MESSAGES.FORWARD_INCENTIVE",
          BACKWARD: "INCENTIVES.COMMON.MESSAGES.SEND_BACK_INCENTIVE",
          REJECT: "INCENTIVES.COMMON.MESSAGES.PROCEED_INCENTIVE_REQUEST",
        };

  const CONFIRMATION_TYPES_BUTTON =
    type === "LEADERBOARD"
      ? {
          PROCEED: "LEADERBOARD.COMMON.MESSAGES.YES_PROCEED_TO_PAYOUT",
          FORWARD: "LEADERBOARD.COMMON.MESSAGES.YES_PROCEED",
          BACKWARD: "LEADERBOARD.COMMON.MESSAGES.YES_PROCEED",
          REJECT: "LEADERBOARD.COMMON.MESSAGES.YES_PROCEED_TO_REJECT",
        }
      : {
          PROCEED: "INCENTIVES.COMMON.MESSAGES.YES_PROCEED_TO_PAYOUT",
          FORWARD: "INCENTIVES.COMMON.MESSAGES.YES_PROCEED",
          BACKWARD: "INCENTIVES.COMMON.MESSAGES.YES_PROCEED",
          REJECT: "INCENTIVES.COMMON.MESSAGES.YES_PROCEED_TO_REJECT",
        };

  const CONFIRMATION_BODY_TITLE =
    type === "LEADERBOARD"
      ? {
          PROCEED: "LEADERBOARD.COMMON.MESSAGES.PROCEED_WITH_REWARD_REQUEST",
          FORWARD:
            "LEADERBOARD.COMMON.MESSAGES.FORWARD_THE_REWARD_REQUEST_TITLE",
          BACKWARD:
            "LEADERBOARD.COMMON.MESSAGES.SEND_BACK_THE_REWARD_REQUEST_TITLE",
          REJECT: "LEADERBOARD.COMMON.MESSAGES.PROCEED_WITH_REWARD_REQUEST",
        }
      : {
          PROCEED: "INCENTIVES.COMMON.MESSAGES.PROCEED_WITH_INCENTIVE_REQUEST",
          FORWARD:
            "INCENTIVES.COMMON.MESSAGES.FORWARD_THE_INCENTIVE_REQUEST_TITLE",
          BACKWARD:
            "INCENTIVES.COMMON.MESSAGES.SEND_BACK_THE_INCENTIVE_REQUEST_TITLE",
          REJECT: "INCENTIVES.COMMON.MESSAGES.PROCEED_WITH_INCENTIVE_REQUEST",
        };

  const CONFIRMATION_BODY_DESCRIPTION =
    type === "LEADERBOARD"
      ? {
          PROCEED: "LEADERBOARD.COMMON.MESSAGES.TEMPLATE_PROCEED_MESSAGE",
          FORWARD:
            "LEADERBOARD.COMMON.MESSAGES.FORWARD_THE_REWARD_REQUEST_DESCRIPTION",
          BACKWARD:
            "LEADERBOARD.COMMON.MESSAGES.SEND_BACK_THE_REWARD_REQUEST_DESCRIPTION",
        }
      : {
          PROCEED: "INCENTIVES.COMMON.MESSAGES.TEMPLATE_PROCEED_MESSAGE",
          FORWARD:
            "INCENTIVES.COMMON.MESSAGES.FORWARD_THE_INCENTIVE_REQUEST_DESCRIPTION",
          BACKWARD:
            "INCENTIVES.COMMON.MESSAGES.SEND_BACK_THE_INCENTIVE_REQUEST_DESCRIPTION",
        };

  useEffect(() => {
    setCurrRemarks("");
    setConfirmationType("");
  }, [currentStage]);
  const formatApprovalMessage = (approvedAmount, rewardLength) => {
    if (
      (approvedAmount || approvedAmount === 0) &&
      (rewardLength > 0 || eligibleRewards.length > 0)
    ) {
      return (
        <div className="d-flex fc-black fs-14 semi-bold mx-1">
          <AmountLabel amount={approvedAmount} />
          <div className="pl-1">
            &{" "}
            {getPluralText(
              rewardLength,
              t("NON_MONETARY.LABELS.NON_MONETARY_REWARD"),
              t("LEADERBOARD.LABELS.NON_MONETARY_REWARDS")
            )}
          </div>
        </div>
      );
    }
    if (approvedAmount || approvedAmount === 0) {
      return (
        <span className="fc-black fs-14 semi-bold mx-1">
          <AmountLabel amount={approvedAmount} />
        </span>
      );
    }
    return (
      <span className="fc-black fs-14 semi-bold mx-1">
        {getPluralText(
          rewardLength,
          t("NON_MONETARY.LABELS.NON_MONETARY_REWARD"),
          t("LEADERBOARD.LABELS.NON_MONETARY_REWARDS")
        )}
      </span>
    );
  };
  const ConfirmationModalBody = ({
    confirmationType,
    approvedAmount,
    rewards,
  }) => {
    const approvedRewardIds = [];
    rewards.forEach((elm) =>
      [...Array(elm.approvedCount)].forEach(() =>
        approvedRewardIds.push(elm.RewardId)
      )
    );
    const { t } = useTranslation();
    if (confirmationType === CONFIRMATION_TYPES.PROCEED) {
      return (
        <div className="pb-4">
          <div className="fs-14 semi-bold fc-primary-black mb-3">
            {t(CONFIRMATION_BODY_TITLE[confirmationType])}
          </div>
          <div className="d-flex fs-13 fc-primary-black center flex-wrap">
            {t(`${type}.COMMON.MESSAGES.APPROVED_REWARD`)}
            {formatApprovalMessage(approvedAmount, approvedRewardIds.length)}
            <Trans
              i18nKey={t(`${type}.COMMON.MESSAGES.WILL_BE_SENT_TO_PAYOUT`)}
            >
              will be
              <span className="fc-black fs-13 semi-bold ml-1">
                sent to Payout.
              </span>
            </Trans>
          </div>
        </div>
      );
    } else if (confirmationType === CONFIRMATION_TYPES.REJECT) {
      return (
        <div>
          <div className="fs-14 semi-bold fc-primary-black mb-3">
            {t(CONFIRMATION_BODY_TITLE[confirmationType])}
          </div>
          <div className="fs-13 fc-primary-black  ">
            <Trans
              i18nKey={t(
                `${type}.COMMON.MESSAGES.REJECTED_REWARD_TEMPLATE_MESSAGE`
              )}
            >
              <div>
                The status will be changed to
                <span className="fc-black fs-13 semi-bold ml-1 mr-1">
                  Rejected,
                </span>
                and it
                <b className="ml-1">will not move to payout</b>
              </div>
              <div className="center">
                since the approved value is
                <span className="ml-1">
                  {formatApprovalMessage(
                    approvedAmount,
                    approvedRewardIds.length
                  )}
                </span>
              </div>
            </Trans>
          </div>
        </div>
      );
    }

    return (
      <div className="pb-4">
        <div className="fs-14 semi-bold fc-primary-black mb-3">
          {t(CONFIRMATION_BODY_TITLE[confirmationType])}
        </div>
        <div className="fs-13 fc-primary-black">
          {t(CONFIRMATION_BODY_DESCRIPTION[confirmationType])}
        </div>
      </div>
    );
  };
  const getRejectedAmount = () => {
    const base = getAmountMaximumBase();
    if (currApprovedAmount) {
      return (eligibleAmount * base - currApprovedAmount * base) / base;
    }
    return eligibleAmount;
  };

  const rewardsListArray = Object.values(rewardsList);
  const selectedRewards = rewardsListArray.filter((item) => item.isSelected);

  const getApprovalPayload = () => {
    const approvedRewardIds = [];
    selectedRewards.forEach((elm) =>
      [...Array(elm.approvedCount)].forEach(() =>
        approvedRewardIds.push(elm.RewardId)
      )
    );

    const rejectedRewardIds = [];
    rewardsListArray.forEach((elm) =>
      [
        ...Array(elm.isSelected ? elm.Count - elm.approvedCount : elm.Count),
      ].forEach(() => rejectedRewardIds.push(elm.RewardId))
    );
    return {
      approvedAmount: currApprovedAmount ? Number(currApprovedAmount) : 0,
      rejectedAmount: getRejectedAmount() || 0,
      remarks: currRemarks,
      approvedRewards: approvedRewardIds || [],
      rejectedRewards: rejectedRewardIds || [],
    };
  };
  const checkIfRejected = () => {
    if (eligibleRewardsCount > 0 && eligibleAmount) {
      return (
        getApprovalPayload().rejectedAmount === eligibleAmount &&
        eligibleRewardsCount === getApprovalPayload().rejectedRewards.length
      );
    }
    if (eligibleRewardsCount > 0) {
      return (
        eligibleRewardsCount === getApprovalPayload().rejectedRewards.length
      );
    }
    return getApprovalPayload().rejectedAmount === eligibleAmount;
  };
  const handleConfirmation = () => {
    if (confirmationType === CONFIRMATION_TYPES.BACKWARD) {
      return handleSendBackward({
        remarks: currRemarks,
      });
    }
    handleSendForward({
      ...getApprovalPayload(),
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = useCallback(
    debounce(() => {
      if (approvedAmount) {
        setShowEditButton(true);
        if (currApprovedAmount === "") {
          setCurrApprovedAmount(approvedAmount);
        }
        setShowTextareaWarning(!currRemarks);
      }
    }, 500),
    [currApprovedAmount]
  );

  const resetCurrApprovedAmount = () => {
    setCurrApprovedAmount(approvedAmount);
    setShowInputWarning(false);
    setShowEditButton(true);
  };

  useEffect(() => {
    if (approvedAmount) {
      resetCurrApprovedAmount();
    }
    if (eligibleRewards.length > 0) {
      setRewardsList(formattedEligibleRewards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedAmount, approvedRewards]);

  const handleRemarkBlur = () => {
    setRemarksFocused(false);
    if (showTextareaWarning && currRemarks) {
      setShowTextareaWarning(false);
    }
  };

  const showCommittedAmount = ({ label, amount, isClickableCard }) => {
    return (
      <FormButton
        className={`${
          isClickableCard
            ? "bg-grey6 w-216 px-3 pt-2 pb-3 br-4 cursor-pointer text-start"
            : ""
        }`}
        onClick={() => {
          isClickableCard && setShowEditButton(false);
        }}
        variant="link"
      >
        <div className="mb-2 semi-bold fs-14 fc-black ">{label}</div>
        <div className="d-flex">
          <div className="center rejected-amount-value semi-bold fs-18 fc-black">
            {amount < 0 && "- "}
            {amount === "" ? (
              "- -"
            ) : (
              <AmountLabel className="ml-2" amount={amount} />
            )}
          </div>
          {isClickableCard && (
            <div className="ml-3 center">
              <SvgEdit color={COLORS.PRIMARY_BLUE} />
              <div className="ml-2 fs-13 semi-bold fc-blue">
                {t("INCENTIVES.COMMON.MESSAGES.EDIT")}
              </div>
            </div>
          )}
        </div>
      </FormButton>
    );
  };

  const showApprovedAmount = () => {
    const showCancelButton = !showEditButton && approvedAmount !== undefined;

    if (showEditButton) {
      return showCommittedAmount({
        label: t("INCENTIVES.COMMON.LABELS.APPROVED_AMOUNT"),
        amount: currApprovedAmount,
        isClickableCard: true,
      });
    }

    return (
      <div className="pt-2 pb-3 px-3 br-4 w-216 approved-amount">
        <div className="d-flex flex-column">
          <div className="mb-2 semi-bold fs-14">
            {t("INCENTIVES.COMMON.LABELS.APPROVED_AMOUNT")}
          </div>
          <div className="center">
            <InputForCurrency
              id="input-for-currency"
              width="128px"
              placeholder={t("INCENTIVES.COMMON.MESSAGES.ENTER_AMOUNT")}
              text={currApprovedAmount}
              regex={REGEX_FOR_CREATE_NUMBERS}
              warning={showInputWarning}
              currency={currency}
              onBlur={handleClickOutside}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  Number.isNaN(value) ||
                  (value >= 0 &&
                    value.toString().match(REGEX_FOR_CREATE_NUMBERS))
                ) {
                  if (value > eligibleAmount) {
                    setShowInputWarning(true);
                    !showInputWarning && setCurrApprovedAmount(value);
                  } else {
                    setShowInputWarning(false);
                    setCurrApprovedAmount(!Number.isNaN(value) ? value : "");
                  }
                }
              }}
              disabled={disableApprovalPayout}
            />
            {showCancelButton && (
              <div ref={cancelButtonRef}>
                <FormButton
                  id="cancel-btn"
                  variant="link"
                  label={t("INCENTIVES.COMMON.LABELS.CANCEL")}
                  className={classNames([
                    "ml-3 semi-bold fs-13 fc-blue",
                    disableApprovalPayout
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer",
                  ])}
                  onClick={
                    disableApprovalPayout
                      ? undefined
                      : () => resetCurrApprovedAmount()
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const checkIfMandatoryItemsPresent = () => {
    if (
      eligibleAmount &&
      (currApprovedAmount === null || currApprovedAmount === "")
    ) {
      setShowInputWarning(true);
    }
    setShowTextareaWarning(!currRemarks);

    if (eligibleAmount) {
      return (
        currApprovedAmount !== null &&
        currApprovedAmount !== "" &&
        currApprovedAmount <= eligibleAmount &&
        currRemarks
      );
    }
    return currRemarks;
  };

  if (isReadOnly) {
    return (
      <div className="border bc-grey3 br-8 px-4 pt-3 pb-4">
        <div className="d-flex pb-3 border-dark-grey">
          <div className="d-flex flex-column pr-4 w-50">
            <div className="fc-grey1 mb-2 fs-13 semi-bold">
              {t("INCENTIVES.COMMON.LABELS.APPROVED_AMOUNT")}
            </div>
            <div className="d-flex">
              <SvgSuccessToast width={20} height={20} className="mt-1 mr-2" />
              <div className="fc-card-green fs-18 semi-bold">
                <NonMonetaryLabel
                  amount={approvedAmount}
                  nonMonetaryRewards={approvedRewards}
                  rewardCount={approvedRewardCount || 0}
                  rewardModalTitle={t(
                    "INCENTIVES.MY_INCENTIVES.LABELS.APPROVED"
                  )}
                  payload={{
                    ...payloadForRewardsList,
                    fieldName: FIELD_NAMES.APPROVED,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column pl-4 w-50 border-l">
            <div className="fc-grey1 mb-2 fs-13 semi-bold">
              {t("INCENTIVES.COMMON.LABELS.REJECTED_AMOUNT")}
            </div>
            <div className="d-flex">
              <SvgErrorToast width={20} height={20} className="mt-1 mr-2" />
              <div className="fc-card-red fs-18 semi-bold">
                <NonMonetaryLabel
                  amount={getRejectedAmount() || 0}
                  nonMonetaryRewards={rejectedRewards}
                  rewardCount={rejectedRewardCount || 0}
                  rewardModalTitle={t(
                    "INCENTIVES.MY_INCENTIVES.LABELS.REJECTED"
                  )}
                  payload={{
                    ...payloadForRewardsList,
                    fieldName: FIELD_NAMES.REJECTED,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 fs-13 semi-bold">
          {t("INCENTIVES.COMMON.LABELS.REMARKS")}
        </div>
        <div className="mt-4 mb-3 fs-12">
          <MultilineTruncate>
            <p className="long-text-break">{remarks}</p>
          </MultilineTruncate>
        </div>
        {attachment ? (
          <div className="center fc-blue semi-bold fs-12 pt-1">
            <SvgAttachment />
            <a href={attachmentUrl} className="ml-2">
              {attachment}
            </a>
          </div>
        ) : null}
      </div>
    );
  }
  const handleNonMonetaryChange = (pos, isSelected, count = 1) => {
    const updatedRewardsObj = { ...rewardsList };
    updatedRewardsObj[pos] = {
      ...updatedRewardsObj[pos],
      isSelected,
      approvedCount: count,
    };
    setRewardsList(updatedRewardsObj);
  };

  const cardsHeader = () => (
    <div className="w-100 center fs-12 fc-grey1 semi-bold reward-cards-header border-bottom-1 bc-grey3 bg-grey6 py-12">
      <div className="pl-3 w-40 mr-4">{t("LEADERBOARD.LABELS.REWARD")}</div>
      <div className="w-30 mr-4">
        {t("LEADERBOARD.DASHBOARD.LABELS.ELIGIBLE_REWARDS")}
      </div>
      <div className="ml-2">
        {t("LEADERBOARD.MY_LEADERBOARDS.LABELS.APPROVED")}
      </div>
    </div>
  );

  const cards = (
    <div
      className={`border-1 bc-grey3 br-4 ${
        eligibleRewards.length > 0 ? "mt-4" : ""
      }`}
    >
      {cardsHeader()}
      {rewardsListArray?.map((reward, index) => {
        return (
          <div
            className={`w-100 ${
              index === eligibleRewards.length - 1
                ? ""
                : "border-bottom-1 bc-grey12"
            }`}
          >
            <RewardCard
              isChecked={reward.isSelected}
              reward={reward}
              currCount={reward.approvedCount}
              handleRewardCheck={() => {
                handleNonMonetaryChange(
                  reward.pos,
                  !reward.isSelected,
                  !reward.isSelected === false ? 1 : reward.approvedCount
                );
              }}
              handleCounterChanges={(e) => {
                handleNonMonetaryChange(reward.pos, reward.isSelected, e);
              }}
              disabled={disableApprovalPayout}
            />
          </div>
        );
      })}
    </div>
  );
  return (
    <div>
      <div className="pl-3 committed">
        {eligibleAmount ? (
          <div className="d-flex mb-2 amount-committed">
            <div className="d-flex flex-column">
              <div className="center mb-12">
                <SvgMonetaryRewardApproval />
                <div className="fs-14 semi-bold ml-2">
                  {t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.MONETARY_REWARDS")}
                </div>
              </div>
              <div className="center">
                {showApprovedAmount()}
                <div className="pl-5">
                  {showCommittedAmount({
                    label: t("INCENTIVES.COMMON.LABELS.REJECTED_AMOUNT"),
                    amount: currApprovedAmount !== "" ? getRejectedAmount() : 0,
                  })}
                </div>
              </div>
              {/* {showInputWarning && ( */}
              <div
                className="fs-12 mt-2 fc-red-warn"
                style={{ visibility: showInputWarning ? "visible" : "hidden" }}
              >
                {currApprovedAmount > eligibleAmount
                  ? t(
                      "INCENTIVES.COMMON.MESSAGES.AMOUNT_CANT_BE_MORE_THAN_ELIGIBLE_AMOUNT"
                    )
                  : t("INCENTIVES.COMMON.MESSAGES.AMOUNT_CANT_BE_EMPTY")}
              </div>
            </div>
          </div>
        ) : null}
        {eligibleRewards.length > 0 && (
          <div>
            <div className="d-flex justify-content-between">
              <div className="center">
                <SvgNonMonetaryRewardApproval />
                <div className="fs-14 semi-bold ml-2">
                  {t(
                    "SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.NON_MONETARY_REWARDS"
                  )}
                </div>
              </div>
              <FormButton
                variant="link"
                label={t("LEADERBOARD.APPROVAL.LABELS.SHOW_REWARD_DETAILS")}
                className="normal"
                onClick={() => setShowRewardDetailsModal(true)}
              />
            </div>
            <div className="mt-2 fc-grey1 fs-13">
              {t(
                "LEADERBOARD.APPROVAL.LABELS.SELECT_TO_APPROVE_AND_UNSELECTED_ONES_GET_REJECTED"
              )}
            </div>
            <div>{cards}</div>
          </div>
        )}
        <div className="mt-3 remarks-wrapper">
          <div className="flex-between center">
            <div className="mb-2 semi-bold fs-14">
              {t("INCENTIVES.COMMON.LABELS.REMARKS")}
            </div>
            <div
              className={`fs-12 fc-grey ${
                maxCharLimit === currRemarks.length && "remarks-error"
              }`}
            >
              {maxCharLimit - currRemarks.length}{" "}
              {t("COMMON.LABELS.CHARACTERS_REMAINING")}
            </div>
          </div>
          <div
            className={`remarks ${remarksFocused && "focus"} ${
              showTextareaWarning && "error"
            }`}
          >
            <FormInput
              as="textarea"
              id="add-remarks"
              className="fs-13"
              label={t("INCENTIVES.COMMON.LABELS.REMARKS")}
              placeholder={t("INCENTIVES.COMMON.MESSAGES.ENTER_REMARKS")}
              regex=""
              maxLength={maxCharLimit}
              height="80px"
              value={currRemarks}
              onChange={(e) => {
                setCurrRemarks(e.target.value);
              }}
              onFocus={() => setRemarksFocused(true)}
              onBlur={handleRemarkBlur}
              required
              disabled={disableApprovalPayout}
            />
          </div>
        </div>
        <div className="center mt-1 pb-3 justify-content-between attachment">
          <FilePickerButton
            fileName={fileName}
            maxSize={15}
            accept={[
              FILE_FORMATS.PDF,
              FILE_FORMATS.DOC,
              FILE_FORMATS.XLSX,
              FILE_FORMATS.PPT,
            ]}
            handleUpload={handleUpload}
            handleRemove={handleRemove}
            handleCancel={handleCancel}
            isOnSuccess={Boolean(fileName)}
            disabled={disableApprovalPayout}
          />
          {showTextareaWarning && (
            <div className="fs-12 fc-red-warn">
              {t("INCENTIVES.COMMON.MESSAGES.ENTER_REMARKS_WARNING")}
            </div>
          )}
        </div>
        <div className="d-flex pt-3">
          {currentStage > 1 && (
            <FormTooltip
              disable={!disableApprovalPayout}
              text={
                <Trans i18nKey="INCENTIVES.PROGRAM.LABELS.DISABLED_APPROVAL_PAYOUT_TOOLTIP">
                  You cannot approve incentive as the <b>approval-payout</b>
                  flow has been disabled for this program.
                </Trans>
              }
            >
              <FormButton
                id="secondary-btn"
                className="mr-3"
                disabled={
                  disableApprovalPayout ||
                  disable ||
                  (![null, undefined].includes(approvedAmount) &&
                    approvedAmount !== Number(currApprovedAmount)) ||
                  !isEqual(formattedEligibleRewards, rewardsList)
                }
                label={`${t("INCENTIVES.COMMON.MESSAGES.SEND_BACK")} ${
                  currentStage - 1
                }`}
                onClick={() => {
                  if (checkIfMandatoryItemsPresent()) {
                    setConfirmationType(CONFIRMATION_TYPES.BACKWARD);
                  }
                }}
                variant="outline-primary"
                icon={
                  <SvgBack
                    color={
                      disable || approvedAmount !== Number(currApprovedAmount)
                        ? COLORS.GREY_3
                        : COLORS.PRIMARY_BLUE
                    }
                  />
                }
              />
            </FormTooltip>
          )}
          <FormTooltip
            disable={!disableApprovalPayout}
            text={
              <Trans i18nKey="INCENTIVES.PROGRAM.LABELS.DISABLED_APPROVAL_PAYOUT_TOOLTIP">
                You cannot approve incentive as the <b>approval-payout</b>
                flow has been disabled for this program.
              </Trans>
            }
          >
            <FormButton
              id="primary-btn"
              disabled={disable || disableApprovalPayout}
              className="forward-incentive"
              label={
                currentStage === lastStage
                  ? t("INCENTIVES.COMMON.MESSAGES.PROCEED")
                  : `${t("INCENTIVES.COMMON.MESSAGES.FORWARD")} ${
                      currentStage + 1
                    }`
              }
              onClick={() => {
                if (checkIfMandatoryItemsPresent()) {
                  setConfirmationType(
                    currentStage === lastStage
                      ? checkIfRejected()
                        ? CONFIRMATION_TYPES.REJECT
                        : CONFIRMATION_TYPES.PROCEED
                      : CONFIRMATION_TYPES.FORWARD
                  );
                }
              }}
              variant="primary"
              reverse
              icon={<SvgForward backgroundColor="none" />}
            />
          </FormTooltip>
        </div>
        <ConfirmationModal
          show={Boolean(confirmationType)}
          onClose={() => setConfirmationType("")}
          dialogClassName="confirm-select-template"
          title={t(CONFIRMATION_TYPES_TITLE[confirmationType])}
          body={
            <ConfirmationModalBody
              confirmationType={confirmationType}
              approvedAmount={currApprovedAmount}
              rewards={selectedRewards}
            />
          }
          onConfirm={handleConfirmation}
          confirmButtonText={t(CONFIRMATION_TYPES_BUTTON[confirmationType])}
          cancelButton
          loading={disable}
          shimmerText={t("INCENTIVES.COMMON.MESSAGES.YES_PROCEED")}
        />
        <NonMonetaryRewardDetails
          show={showRewardDetailsModal}
          handleClose={() => setShowRewardDetailsModal(false)}
          payload={{
            ...payloadForRewardsList,
            fieldName: FIELD_NAMES.ELIGIBLE,
          }}
        />
      </div>
    </div>
  );
};

ApproveAmount.propTypes = {
  eligibleAmount: PropTypes.number.isRequired,
  approvedAmount: PropTypes.number,
  approvedRewards: PropTypes.array,
  eligibleRewards: PropTypes.array,
  remarks: PropTypes.string,
  attachment: PropTypes.string,
  currentStage: PropTypes.number.isRequired,
  lastStage: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool,
  currency: PropTypes.string,
  payloadForRewardsList: PropTypes.object,
};

ApproveAmount.defaultProps = {
  approvedRewards: [],
  eligibleRewards: [],
  eligibleAmount: 0,
  currentStage: 1,
  lastStage: 3,
  isReadOnly: false,
  currency: "₹",
  payloadForRewardsList: {},
};

export default ApproveAmount;
