import React from "react";
import { Shimmer } from "src/components/common/atoms";
import LoadingPayoutGrid from "./LoadingPayoutGrid";
import LoadingPayoutViewList from "./LoadingPayoutViewList";

const LoadingPayoutView = (props) => {
  const { hideHeader = false } = props;
  return (
    <div>
      {!hideHeader && (
        <div className="d-flex align-items-end my-3">
          <div>
            <Shimmer width="70px" height="10px" />
            <div className="mt-2">
              <Shimmer width="150px" height="40px" />
            </div>
          </div>
          <div className="mx-3">
            <Shimmer width="150px" height="10px" />
            <div className="mt-2">
              <Shimmer width="100px" height="30px" />
            </div>
          </div>
          <div className="ml-auto">
            <div className="mt-2">
              <Shimmer width="150px" height="40px" />
            </div>
          </div>
        </div>
      )}
      <hr />
      <div className="d-flex">
        <div className="w-336 d-flex flex-column border-right-1 bc-grey3">
          {!hideHeader && (
            <>
              <div className="w-336 py-3 border-bottom-1 bc-grey3">
                <Shimmer width="70px" height="20px" />
              </div>
              <div className="w-336 py-3 pr-3 border-bottom-1 bc-grey3">
                <Shimmer width="100%" height="40px" />
              </div>
            </>
          )}
          <div className="w-336">
            <LoadingPayoutViewList />
          </div>
        </div>
        <LoadingPayoutGrid />
      </div>
    </div>
  );
};

export default LoadingPayoutView;
