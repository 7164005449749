import React from "react";

const SvgUpload = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 46"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="bh33__a">
          <path d="M0 0h46v46H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#bh33__a)" data-name="add attachment - upload">
        <g
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          data-name="Group 11360"
        >
          <path d="M23.01 22.994v18" data-name="Path 7421" />
          <path
            d="M39.79 35.774a10 10 0 0 0-4.78-18.78h-2.52a16 16 0 1 0-27.48 14.6"
            data-name="Path 7422"
          />
          <path d="m31.01 30.994-8-8-8 8" data-name="Path 7423" />
        </g>
      </g>
    </svg>
  );
};

SvgUpload.defaultProps = {
  color: "#8a9caf",
  height: 46,
  width: 46,
};
export default SvgUpload;
