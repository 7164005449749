import React from "react";

const SvgAvatar38 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="gept__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .gept__cls-1{fill:#fff}.gept__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="gept__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="gept__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="gept__cls-2"
        />
        <path
          d="m106.77 57.12-3.62-3.62a.91.91 0 0 0-.64-.27h-2.72v-2.72c0-.24-.1-.47-.27-.64l-3.62-3.62a.91.91 0 0 0-.64-.27H89.3l-1.55-1.55a.91.91 0 0 0-.64-.27H72.62a.9.9 0 0 0-.57.2l-4.28 3.42h-3.3c-.24 0-.47.1-.64.27l-5.43 5.43a.91.91 0 0 0-.27.64v8.15c0 .21.07.42.21.58l4.53 5.43c.17.21.43.33.7.33h9.59l3.36 3.36c.17.17.4.27.64.27h7.74l11.5 13.27c.17.2.42.31.69.31a.91.91 0 0 0 .91-.91v-6.34h4.53c.24 0 .47-.1.64-.27l3.62-3.62c.17-.17.27-.4.27-.64v-16.3c0-.24-.1-.47-.27-.64Zm-1.55 16.57-3.09 3.09h-4.15v-1.81c0-.24-.1-.47-.27-.64l-2.45-2.45v-2.87l2.19-2.19h2.34v-1.81h-2.72c-.24 0-.47.1-.64.27L93.71 68a.91.91 0 0 0-.27.64v1.81h-2.72v1.81h2.72c0 .24.1.47.27.64l2.45 2.45v7.16l-8.88-10.25h1.64v-1.81h-11.4l-3.09-3.09v-4.68l3.09-3.09h10.49c.24 0 .47-.1.64-.27l2.72-2.72-1.28-1.28-2.45 2.45H77.15c-.24 0-.47.1-.64.27l-3.62 3.62a.91.91 0 0 0-.27.64v4.53h-8.63l-4.05-4.86v-7.45l4.9-4.9h2.87l2.19 2.19v2.34h1.81v-2.72c0-.24-.1-.47-.27-.64l-2-2L72.93 46h13.8l.91.91-2.08 2.08a.91.91 0 0 0-.27.64v2.72h1.81v-2.34l2.19-2.19h5.59l3.09 3.09v2.34h-1.81c-.24 0-.47.1-.64.27l-1.55 1.55h-.53v1.81h.91c.24 0 .47-.1.64-.27l1.55-1.55h5.59l3.09 3.09V73.7Z"
          className="gept__cls-1"
        />
        <path
          d="M89.45 64.1h-9.59v1.81h9.96c.24 0 .47-.1.64-.27l2.72-2.72-1.28-1.28-2.45 2.45ZM65.64 57.12l-1.81 1.81a.91.91 0 0 0-.27.64v1.81h1.81v-1.44l1.28-1.28h5.06v-1.81h-5.43c-.24 0-.47.1-.64.27ZM100.7 59.57h-3.62v1.81h3.25l1.28 1.28v1.44h1.81v-1.81c0-.24-.1-.47-.27-.64l-1.81-1.81a.91.91 0 0 0-.64-.27ZM78.32 49.87l-1.81 1.81a.91.91 0 0 0-.27.64v1.81h1.81v-1.44l1.28-1.28h1.44V49.6h-1.81c-.24 0-.47.1-.64.27ZM99.53 69.8l-1.28 1.28 1.81 1.81c.17.17.4.27.64.27h1.81v-1.81h-1.44l-1.55-1.55Z"
          className="gept__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar38.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar38;
