import React from "react";
import { Image } from "src/components";
import ManagedByMeEmptyStateImg from "./ManagedByMeEmptyState.svg";

const ManagedByMeEmptyState = (props) => (
  <Image
    src={ManagedByMeEmptyStateImg}
    alt="ManagedByMeEmptyStateImg"
    {...props}
  />
);

export default ManagedByMeEmptyState;
