import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgGoalOwner = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <defs>
        <clipPath id="wppl__a">
          <path d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#wppl__a)">
        <g>
          <path fill="none" d="M0 0h12v12H0z" />
          <path
            fill={color}
            d="M6 6.625a2.5 2.5 0 0 0 2.5-2.5V1l-1.25.625L6 1l-1.25.625L3.5 1v3.125a2.5 2.5 0 0 0 2.5 2.5ZM4.438 3.5h3.125v.625a1.563 1.563 0 0 1-3.125 0ZM7.75 6.938a1.911 1.911 0 0 0-.625.122 3.392 3.392 0 0 1-2.242 0 1.912 1.912 0 0 0-.629-.122 2.625 2.625 0 0 0-2.629 2.625v.5a.938.938 0 0 0 .938.937h6.875a.938.938 0 0 0 .938-.938v-.5A2.625 2.625 0 0 0 7.75 6.938Zm1.688 3.125H2.563v-.5A1.69 1.69 0 0 1 4.25 7.875a1.02 1.02 0 0 1 .32.069 4.327 4.327 0 0 0 2.86 0 1.021 1.021 0 0 1 .32-.069 1.69 1.69 0 0 1 1.688 1.688Z"
          />
        </g>
      </g>
    </svg>
  );
};
SvgGoalOwner.defaultProps = {
  color: COLORS.GREY_2,
  height: 12,
  width: 12,
};
export default SvgGoalOwner;
