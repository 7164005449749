import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { NetworkStatus } from "src/api/client";
import { useGetNonMonetaryRewardsList } from "src/api/useCustomRewardConfig";
import {
  AmountLabel,
  LoadMoreButton,
  RewardIcon,
  SearchBar,
  Shimmer,
  Truncate,
  toaster,
} from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import { PAGINATION_LIMIT } from "src/constants";
import { capitalize, errorText } from "src/helpers";
import { NoResults } from "src/images";
import "./nonMonetaryRewardDetails.scss";

const NonMonetaryRewardDetails = ({ show, handleClose, title, payload }) => {
  const {
    fieldName,
    featureType,
    userId,
    programId,
    recurrenceId,
    criteriaId,
    historyId,
    rewardIds,
    Rank,
    GroupId,
    IsUserIdManager,
  } = payload || {};
  const [search, setSearch] = useState("");
  const ShimmerRef = useRef();

  const getQuery = () => {
    return {
      FieldName: fieldName,
      UserId: userId,
      ProgramId: programId,
      RecurrenceId: recurrenceId,
      FeatureType: featureType,
      CriteriaId: criteriaId,
      HistoryId: historyId,
      RewardIds: rewardIds,
      Rank,
      GroupId,
      Search: search,
      IsUserIdManager,
    };
  };

  const {
    data: { nonMonetaryRewardsList } = { nonMonetaryRewardsList: [] },
    loading,
    error,
    networkStatus,
    fetchMore,
  } = useGetNonMonetaryRewardsList({
    variables: {
      Limit: PAGINATION_LIMIT,
      After: null,
      Query: getQuery(),
    },
    skip: !show,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const rewardList = nonMonetaryRewardsList?.response?.rewardsList;
  const totalCount = nonMonetaryRewardsList?.response?.totalCount;

  const onFetchMore = () => {
    const { endCursor } = nonMonetaryRewardsList?.response?.pageInfo;
    fetchMore({
      variables: {
        Limit: PAGINATION_LIMIT,
        After: endCursor,
        Query: getQuery(),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        fetchMoreResult.nonMonetaryRewardsList.response.rewardsList = [
          ...prev.nonMonetaryRewardsList.response.rewardsList,
          ...fetchMoreResult.nonMonetaryRewardsList.response.rewardsList,
        ];
        return fetchMoreResult;
      },
    });
  };

  const renderRewardList = (rewardList) => {
    return (
      <div className="nonmonetary-reward-list">
        {rewardList?.map((reward) => {
          return (
            <div className="p-3 br-4 border-1 bc-grey3 position-relative">
              {reward?.RewardsCount > 1 && (
                <div className="reward-count bg-blue6 fs-12 semi-bold fc-secondary-black">
                  x{reward.RewardsCount}
                </div>
              )}
              <div className="d-flex mb-3">
                {(reward?.CustomRewardImageUrl || reward?.RewardCategory) && (
                  <RewardIcon
                    src={reward?.CustomRewardImageUrl}
                    rewardCategory={reward?.RewardCategory}
                    width={54}
                    height={54}
                    className="mr-3"
                  />
                )}
                <div className="d-flex flex-column justify-content-center reward-details">
                  <div className="mb-1 fs-14 semi-bold fc-secondary-black">
                    <Truncate
                      text={reward.RewardName}
                      maxWidth="300px"
                      tooltipPlacement="bottom-start"
                    />
                  </div>
                  <div className="fs-12 fc-grey1 reward-description">
                    {reward.Description}
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="mr-5">
                  <div className="mb-1 fs-12 fc-grey1">
                    {t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.REWARD_CATEGORY")}
                  </div>
                  <div className="fs-13 semi-bold fc-secondary-black">
                    {capitalize(reward.RewardCategory)}
                  </div>
                </div>
                <div className="mr-5">
                  <div className="mb-1 fs-12 fc-grey1">
                    {t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.REWARD_VALUE")}
                  </div>
                  <div className="fs-13 semi-bold fc-secondary-black">
                    <AmountLabel amount={reward.RewardValue} />
                  </div>
                </div>
                <div>
                  <div className="mb-1 fs-12 fc-grey1">
                    {t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.REWARD_LINK")}
                  </div>
                  <div className="fs-13 semi-bold fc-secondary-black">
                    {reward.RewardLink ? (
                      <a
                        href={reward.RewardLink}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <Truncate text={reward.RewardLink} maxWidth={350} />
                      </a>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderRewardsLoader = () => {
    return (
      <div className="nonmonetary-reward-list">
        {Array(2)
          .fill("")
          ?.map(() => {
            return (
              <div className="p-3 br-4 border-1 bc-grey3">
                <div className="center mb-3">
                  <div className="mr-3">
                    <Shimmer width="54px" height="54px" />
                  </div>
                  <div className="w-100">
                    <div className="mb-1 fs-14 semi-bold fc-secondary-black">
                      <Shimmer width="100%" height="20px" />
                    </div>
                    <Shimmer width="100%" height="32px" />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mr-5">
                    <div className="mb-1 fs-12 fc-grey1">
                      {t(
                        "SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.REWARD_CATEGORY"
                      )}
                    </div>
                    <div className="fs-13 semi-bold fc-secondary-black">
                      <Shimmer width="100%" height="17px" />
                    </div>
                  </div>
                  <div className="mr-5">
                    <div className="mb-1 fs-12 fc-grey1">
                      {t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.REWARD_VALUE")}
                    </div>
                    <div className="fs-13 semi-bold fc-secondary-black">
                      <Shimmer width="100%" height="17px" />
                    </div>
                  </div>
                  <div>
                    <div className="mb-1 fs-12 fc-grey1">
                      {t("SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.REWARD_LINK")}
                    </div>
                    <Shimmer width="100%" height="17px" />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  if (nonMonetaryRewardsList?.__typename === "ErrorResponse") {
    toaster({
      message: nonMonetaryRewardsList?.message,
      type: "error",
    });
    return <></>;
  }

  if (error) {
    toaster({
      message: errorText.FETCHING_CUSTOM_REWARD_HISTORY.ERROR,
      type: "error",
    });
    return <></>;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="reward-history-modal max-w-820"
    >
      <Modal.Header className="fs-18 semi-bold" closeButton>
        {title ? `${title} - ` : ""}
        {t("NON_MONETARY.LABELS.NON_MONETARY_REWARD_DETAILS")}
      </Modal.Header>
      <Modal.Body className="h-490 overflow-y-auto">
        <div className="px-4 py-3">
          <div className="mb-3 d-flex justify-content-between align-items-center fs-13">
            <div>{t("NON_MONETARY.LABELS.DETAILED_VIEW")}</div>
            <SearchBar
              placeholder={t(
                "SETTINGS.CUSTOM_REWARDS_CONFIG.LABELS.SEARCH_REWARDS"
              )}
              search={search}
              setSearch={setSearch}
              width={200}
              id="reward-history-search-bar"
            />
          </div>
          {networkStatus !== NetworkStatus.ready &&
          networkStatus !== NetworkStatus.fetchMore ? (
            renderRewardsLoader()
          ) : rewardList?.length > 0 ? (
            renderRewardList(rewardList)
          ) : (
            <div className="flex-column-center mt-5">
              <NoResults />
              <div className="fs-14 fc-grey mt-2 text-center">
                {t("LEADERBOARD.COMMON.LABELS.NO_RESULTS_TO_SHOW")}
              </div>
            </div>
          )}
          {rewardList?.length !== 0 &&
            Number.isInteger(totalCount) &&
            rewardList?.length !== totalCount && (
              <div
                className="d-flex w-100 align-items-center justify-content-center pt-3"
                ref={ShimmerRef}
              >
                {!loading ? (
                  <LoadMoreButton
                    disabled={rewardList.length === totalCount}
                    id="reward-history-grid-show-more"
                    onClick={onFetchMore}
                    itemsLoaded={rewardList.length}
                    totalItems={totalCount}
                  />
                ) : (
                  <div className="fc-black fs-12 px-4 py-2 bg-grey align-self-center d-flex justify-content-center">
                    {t("COMMON.MESSAGES.LOADING")}
                  </div>
                )}
              </div>
            )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          id="close-btn"
          variant="outline-primary"
          onClick={handleClose}
          label={t("COMMON.BUTTONS.CLOSE")}
        />
      </Modal.Footer>
    </Modal>
  );
};

NonMonetaryRewardDetails.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  payload: PropTypes.object.isRequired,
};

NonMonetaryRewardDetails.defaultProps = {
  title: "",
  show: false,
  handleClose: () => {},
  payload: {},
};

export default NonMonetaryRewardDetails;
