import { useTranslation } from "react-i18next";
import { FormButton } from "src/components";
import { classNames } from "src/helpers";
import { SvgPublish, SvgUnpublish } from "src/icons";
import "./publishIncentiveButton.scss";

const buttonLabel = {
  PUBLISHED: "COMMON.LABELS.UNPUBLISH",
  DRAFT: "COMMON.LABELS.PUBLISH",
  UNPUBLISHED: "COMMON.LABELS.PUBLISH",
};

const buttonIcons = {
  PUBLISHED: SvgUnpublish,
  DRAFT: SvgPublish,
};

const PublishIncentiveButton = ({ status, disabled, form, className }) => {
  const { t } = useTranslation();
  const ButtonIcon = buttonIcons[status] || SvgUnpublish;
  return (
    <FormButton
      id="incentive-publish-status-updation"
      type="submit"
      name="publish"
      className={classNames([
        "status-btn",
        status,
        disabled ? "disabled" : "",
        className,
      ])}
      icon={<ButtonIcon />}
      label={t(buttonLabel[status])}
      variant={status === "UNPUBLISHED" ? "primary" : "outline-primary"}
      disabled={disabled}
      form={form}
    />
  );
};

export default PublishIncentiveButton;
