import { gql, useQuery } from "src/api/client";

const GET_ASSIGNED_LEADERBOARD_USER_RECURRENCES = gql`
  query assignedIncentiveUserRecurrences($ProgramId: ID, $UserId: ID) {
    assignedIncentiveUserRecurrences(ProgramId: $ProgramId, UserId: $UserId) {
      ... on assignedIncentiveUserRecurrencesResponse {
        success
        message
        response {
          IncentiveUserRecurrences {
            RecurrenceId
            ProgramId
            RecurrenceStartDate
            RecurrenceEndDate
            CurrentRecurrence
            ApprovalStatus
            PayoutStatus
            Status
          }
          CurrentRecurrence {
            RecurrenceId
            ProgramId
            RecurrenceStartDate
            RecurrenceEndDate
            CurrentRecurrence
            ApprovalStatus
            PayoutStatus
            Status
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetAssignedUserRecurrences = (queryOptions) => {
  return useQuery(GET_ASSIGNED_LEADERBOARD_USER_RECURRENCES, queryOptions);
};
