import React from "react";
const SvgRefresh = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="v1xz__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#v1xz__a)">
        <path
          fill={color}
          d="M8 16a7.721 7.721 0 0 1-5.675-2.325A7.721 7.721 0 0 1 0 8a7.721 7.721 0 0 1 2.325-5.675A7.721 7.721 0 0 1 8 0a7.917 7.917 0 0 1 3.3.713A7.622 7.622 0 0 1 14 2.75V0h2v7H9V5h4.2a5.836 5.836 0 0 0-2.187-2.2A5.928 5.928 0 0 0 8 2a5.786 5.786 0 0 0-4.25 1.75A5.786 5.786 0 0 0 2 8a5.786 5.786 0 0 0 1.75 4.25A5.786 5.786 0 0 0 8 14a5.863 5.863 0 0 0 3.475-1.1A5.809 5.809 0 0 0 13.65 10h2.1a7.9 7.9 0 0 1-2.85 4.325A7.759 7.759 0 0 1 8 16"
        />
      </g>
    </svg>
  );
};
SvgRefresh.defaultProps = {
  color: "#a0aec0",
  height: 16,
  width: 16,
};
export default SvgRefresh;
