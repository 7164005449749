import { gql, useQuery } from "src/api/client";

const GET_APPROVAL_LEADERBOARDS_SUMMARY = gql`
  query approvalProgramsSummary($FeatureType: String!) {
    approvalProgramsSummary(FeatureType: $FeatureType) {
      ... on ApprovalProgramsSummaryResponse {
        success
        response {
          programId
          programName
          pendingRequests
        }
      }
    }
  }
`;

export const useGetApprovalLeaderboardsSummary = (queryOptions) => {
  return useQuery(GET_APPROVAL_LEADERBOARDS_SUMMARY, queryOptions);
};
