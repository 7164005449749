import { t } from "i18next";
import PropTypes from "prop-types";
import { FormButton } from "src/components/common/formElements";
import { NoResults } from "src/images";

const addFirstSlabPropTypes = {
  addSlab: PropTypes.func.isRequired,
};

export const AddFirstSlab = ({ addSlab }) => {
  return (
    <div className="add-first-slab flex-column-center mt-5">
      <NoResults />
      <div className="fs-14 fc-grey mb-30">
        {t("INCENTIVES.PROGRAM.MESSAGES.ADD_FIRST_SLAB_DESCRIPTION")}
      </div>
      <FormButton
        variant="outline-primary"
        label={t("INCENTIVES.PROGRAM.LABELS.ADD_FIRST_SLAB")}
        onClick={addSlab}
      />
    </div>
  );
};

AddFirstSlab.propTypes = addFirstSlabPropTypes;
