import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgPlus = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill="none" d="M0 0h16v16H0z" data-name="Rectangle 136" />
      <path
        fill={color}
        d="M7 14V9H2V7h5V2h2v5h5v2H9v5Z"
        data-name="Union 16"
      />
    </svg>
  );
};

SvgPlus.defaultProps = {
  color: COLORS.GREY_3,
  height: 16,
  width: 16,
};
export default SvgPlus;
