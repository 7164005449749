import React, { lazy } from "react";
import { APPS } from "src/constants";
import { Redirect } from "src/router";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'leaderboards.web.approvalPage' */ "./web")
);

export const ApprovalPage = {
  web: WebPage,
  mobile: () => <Redirect to={APPS.LEADERBOARDS.ROUTES.LANDING.path} />,
  meta: {
    title: "Approval",
  },
};
