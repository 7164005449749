import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

// src/modules/goalCRUD/tabView/headerContent/tabButtons/CloseButton.js
// src/goalCRUD/tabView/headerContent/tabButtons/NextButton.js
const UPDATE_GOAL_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation UpdateGoal(
    $GoalId: ID!
    $TemplateId: ID
    $GoalType: String
    $IsRollUp: Boolean
    $RecurrenceDefinition: RecurrenceDefinition
    $Status: GoalStatusEnum
    $SelectedUsers: [ID]
    $GoalAggConfig: JSON
    $RollupType: RollupTypeEnum
  ) {
    updateGoal(
      GoalId: $GoalId
      TemplateId: $TemplateId
      GoalType: $GoalType
      IsRollUp: $IsRollUp
      RecurrenceDefinition: $RecurrenceDefinition
      Status: $Status
      SelectedUsers: $SelectedUsers
      GoalAggConfig: $GoalAggConfig
      RollupType: $RollupType
    ) {
      ... on SingleGoalResponse {
        response {
          Goal {
            GoalId
            Name
            Description
            RecurrenceDefinition
            Status
            SelectedUsers
            GoalAggConfig
            RollupType
          }
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;
export const useUpdateGoal = () => {
  return useMutation(UPDATE_GOAL_MUTATION);
};
