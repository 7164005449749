import { useQuery as useApolloQuery } from "@apollo/client";

const defaultQueryOptions = {};

export const useQuery = (query, queryOptions) => {
  return useApolloQuery(query, {
    ...defaultQueryOptions,
    ...queryOptions,
  });
};
