import React from "react";
import { COLORS, FIELD_NAMES } from "src/constants";
import {
  SvgApprovedAmount,
  SvgEligibleAmount,
  SvgPendingAmount,
  SvgPendingPayout,
} from "src/icons";
export const PROGRAMS_STATS_CARDS = (isIncentive) => [
  {
    label: isIncentive
      ? "INCENTIVES.COMMON.LABELS.ELIGIBLE_INCENTIVE"
      : "LEADERBOARD.MY_LEADERBOARDS.LABELS.ELIGIBLE",
    key: "TotalEligibleAmount",
    rewardsKey: "TotalEligibleRewards",
    icon: <SvgEligibleAmount color={COLORS.WHITE} width="20px" height="20px" />,
    fieldName: FIELD_NAMES.ELIGIBLE,
  },
  {
    label: isIncentive
      ? "INCENTIVES.COMMON.LABELS.APPROVED_INCENTIVE"
      : "LEADERBOARD.MY_LEADERBOARDS.LABELS.APPROVED",
    key: "TotalApprovedAmount",
    rewardsKey: "TotalApprovedRewards",
    icon: <SvgApprovedAmount color={COLORS.WHITE} width="20px" height="20px" />,
    fieldName: FIELD_NAMES.APPROVED,
  },
  {
    label: "INCENTIVES.MY_INCENTIVES.MESSAGES.TOTAL_PAYOUT_DONE",
    key: "TotalPaidOut",
    rewardsKey: "TotalPaidOutRewards",
    icon: <SvgPendingPayout color={COLORS.WHITE} width="20px" height="20px" />,
    fieldName: FIELD_NAMES.PAIDOUT,
  },
  {
    label: "INCENTIVES.MY_INCENTIVES.MESSAGES.PENDING_FOR_PAYOUT",
    key: "TotalPayoutPending",
    rewardsKey: "TotalPayoutPendingRewards",
    icon: <SvgPendingAmount color={COLORS.WHITE} width="20px" height="20px" />,
    fieldName: FIELD_NAMES.PAYOUT_PENDING,
  },
];
