import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormButton } from "src/components/common/formElements";
import "./infoModal.scss";

const InfoModal = ({
  show,
  onHide,
  modalTitle,
  copyText,
  icon,
  iconAlignment,
  className,
  copyTextClassName,
  iconClassName,
}) => {
  const { t } = useTranslation();

  const modalContent = () => {
    return (
      <>
        <Modal.Header closeButton>
          <div className="fs-16 fc-secondary-black semi-bold">{modalTitle}</div>
        </Modal.Header>
        <Modal.Body
          className={`d-flex justify-content-between pt-4 px-4 ${
            iconAlignment === "bottom" ? "flex-column" : "flex-column-reverse"
          }`}
        >
          <div className={`mb-4 ${copyTextClassName}`}>{copyText}</div>
          <div className={`mb-4 ${iconClassName}`}>{icon}</div>
        </Modal.Body>
        <Modal.Footer>
          <FormButton
            id="close-info-modal"
            data-testid="close-info-modal"
            variant="outline-primary"
            onClick={() => onHide()}
            label={t("COMMON.BUTTONS.CLOSE")}
          />
        </Modal.Footer>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName={className}>
      {modalContent()}
    </Modal>
  );
};

InfoModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  modalTitle: PropTypes.string,
  copyText: PropTypes.element,
  icon: PropTypes.element,
  iconAlignment: PropTypes.string,
  className: PropTypes.string,
  copyTextClassName: PropTypes.string,
  iconClassName: PropTypes.string,
};

InfoModal.defaultProps = {
  show: true,
  onHide: () => {},
  iconAlignment: "bottom",
  copyTextClassName: "",
  iconClassName: "",
};

export default InfoModal;
