import React from "react";
const SvgArrowGreen = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 46 46"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M2.335 7.419a.75.75 0 0 1-.67-1.342zm20.821 11.204.473.582zm17.406 23.95a.75.75 0 0 1-1.058.065l-5.06-4.467a.75.75 0 1 1 .992-1.125l4.498 3.972 3.971-4.498a.75.75 0 0 1 1.125.993zM1.665 6.077c7.315-3.658 16.314-3.526 21.778-.974 2.727 1.275 4.753 3.247 5.011 5.829.258 2.575-1.284 5.394-4.825 8.273l-.946-1.164c3.397-2.762 4.46-5.138 4.279-6.96-.182-1.816-1.64-3.443-4.154-4.619-5.021-2.345-13.538-2.51-20.473.957zm21.964 13.128c-2.519 2.048-4.297 3.14-5.456 3.491-.538.163-1.22.251-1.72-.166-.536-.445-.49-1.13-.386-1.57.223-.944 1.018-2.175 2.081-3.34 1.087-1.188 2.538-2.401 4.195-3.283 1.655-.881 3.555-1.453 5.516-1.294 1.98.16 3.951 1.059 5.728 2.99 3.505 3.809 6.27 11.645 7.162 25.997l-1.498.093c-.888-14.298-3.636-21.672-6.768-25.075-1.541-1.675-3.17-2.383-4.745-2.51-1.594-.129-3.205.332-4.69 1.123-1.484.79-2.803 1.889-3.792 2.971-1.012 1.108-1.596 2.107-1.73 2.674-.074.316.048.206-.114.07-.13-.106-.141.026.326-.115.852-.259 2.433-1.178 4.945-3.22z"
      />
    </svg>
  );
};
SvgArrowGreen.defaultProps = {
  color: "#2FA207",
  height: 46,
  width: 46,
};
export default SvgArrowGreen;
