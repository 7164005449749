import { t } from "i18next";
import { lazy, Suspense, useState } from "react";
import {
  FormTooltip,
  LoadingCriteriaList,
  NavBar,
  NavItem,
} from "src/components";
import { classNames } from "src/helpers";
import "./styles.scss";

const CriteriaAndAchievements = lazy(() =>
  import(
    /* webpackChunkName: 'program.history' */ "./criteriaAndAchievements/CriteriaAndAchievements"
  )
);

const ProgramHistory = lazy(() =>
  import(
    /* webpackChunkName: 'program.history' */ "./programHistory/ProgramHistory"
  )
);

const TABS = ({ t }) => [
  {
    label: t("INCENTIVES.MY_INCENTIVES.LABELS.CRITERIA_AND_ACHIEVEMENTS"),
    component: CriteriaAndAchievements,
  },
  {
    label: t("INCENTIVES.MY_INCENTIVES.LABELS.INCENTIVES_HISTORY"),
    component: ProgramHistory,
  },
];

const ProgramDetails = ({
  className,
  programDetails,
  programId,
  recurrenceId,
  recurrenceStatus,
  userId,
  setLastUpdatedData,
  setUserName,
  userName,
  recurrences,
  selectedRecurrence,
  hideIncentiveHistory,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className={classNames(["program-details", className])}>
      <NavBar position="sticky">
        {TABS({ t }).map((tab, idx) => {
          const disableIncentiveHistory = idx === 1 && hideIncentiveHistory;
          return (
            <NavItem
              className={classNames([
                "fs-14 semi-bold",
                disableIncentiveHistory && "hide-incentive-history",
              ])}
              active={idx === activeTab}
              onClick={() => setActiveTab(idx)}
              disabled={disableIncentiveHistory}
            >
              <FormTooltip
                disable={!disableIncentiveHistory}
                text={t(
                  "INCENTIVES.COMMON.MESSAGES.INCENTIVE_HISTORY_HAS_BEEN_HIDDEN"
                )}
                placement="bottom"
              >
                {tab.label}
              </FormTooltip>
            </NavItem>
          );
        })}
      </NavBar>
      <div className="tab-content">
        <Suspense fallback={<LoadingCriteriaList />}>
          {activeTab === 0 ? (
            <CriteriaAndAchievements
              programId={programId}
              recurrenceId={recurrenceId}
              recurrenceStatus={recurrenceStatus}
              userId={userId}
              programDetails={programDetails}
              setLastUpdatedData={setLastUpdatedData}
              setUserName={setUserName}
              userName={userName}
              recurrences={recurrences}
              selectedRecurrence={selectedRecurrence}
              programName={programDetails?.Name}
            />
          ) : null}
          {!hideIncentiveHistory && activeTab === 1 ? (
            <ProgramHistory
              programId={programId}
              recurrenceId={recurrenceId}
              recurrenceStatus={recurrenceStatus}
              userId={userId}
            />
          ) : null}
        </Suspense>
      </div>
    </div>
  );
};

export default ProgramDetails;
