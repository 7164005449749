import React from "react";
import { useTranslation } from "react-i18next";
import { TemplateDetailContent } from "src/components/common/organisms";
import { RULESET_TYPES } from "src/constants";
import { isEmpty } from "src/helpers";
import { SelectTemplateModalEmptyState } from "src/images";
import { LoadingTemplateDetails } from "../../loading";

const TemplateDisplayContainer = (props) => {
  const { loading, displayTemplate, defaultTemplate } = props;
  const showTemplate =
    !isEmpty(displayTemplate) && displayTemplate?.GoalRules === undefined
      ? defaultTemplate
      : displayTemplate;
  const { t } = useTranslation();
  return (
    <>
      {loading ? (
        <LoadingTemplateDetails />
      ) : (
        <>
          {!isEmpty(showTemplate) ? (
            <TemplateDetailContent
              name={showTemplate?.Name}
              goalAggregateFunction={showTemplate?.GoalAggregateFunction}
              description={showTemplate?.Description}
              goalRules={showTemplate?.GoalRules}
              isCustomFormula={showTemplate?.IsCustomFormula}
              formula={showTemplate?.Formula}
              isAdvancedRuleset={
                showTemplate?.RulesetType === RULESET_TYPES.ADVANCED
              }
              groupBy={showTemplate?.GroupBy}
            />
          ) : (
            <div className="fs-13 empty-list-container">
              <SelectTemplateModalEmptyState height="100%" />
              <div className="Goal-Modal-Text d-flex justify-content-center">
                {t("MANAGED_GOALS.MESSAGES.GOAL_TEMPLATE_DESCRIPTION")}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TemplateDisplayContainer;
