import React, { useEffect } from "react";
import { useGetIncentiveManagers } from "src/api/useIncentiveDashboard";
import {
  DashboardHeroCardGroup,
  MobileFilterBarFilters,
  MobileFilterBarIcons,
  MobileGoalOwnerDropdown,
  Shimmer,
  useFilter,
} from "src/components";
import { APPS, FEATURE_TYPES, ROLES } from "src/constants";
import { useRoleBasedContext } from "src/context";
import { getStartAndEndDate, isRouteActive } from "src/helpers";
import { useGetSearchParams } from "src/hooks";
import { useHistory } from "src/router";
import MobileDashboardBody from "./MobileDashboardBody";
import { DASHBOARD_LANDING_PAGE_FILTERS } from "./data";
import "./index.scss";

const allOption = {
  EmailAddress: "",
  FirstName: "All",
  FullName: "All Managers",
  IsManagingUsers: true,
  LastName: "",
  StatusCode: 0,
  UserID: "ALL",
};

function MobileDashboardPage() {
  const { userDetails } = useRoleBasedContext();
  const { timeFrequency, managerId } = useGetSearchParams({
    timeFrequency: {
      label: "Last 30 Days",
      value: 30,
      ...getStartAndEndDate(30),
    },
    managerId: userDetails.id,
  });

  const history = useHistory();

  const isIncentive = isRouteActive({
    pathname: history.location.pathname,
    route: APPS.INCENTIVES.ROUTES.DASHBOARD.path,
  });

  const defaultValue = {
    owner: null,
    timeFrequency,
  };
  const { filtersState, handleChange, ...rest } = useFilter(
    DASHBOARD_LANDING_PAGE_FILTERS,
    defaultValue
  );

  const {
    data: { incentiveManagers } = { incentiveManagers: [] },
    loading: loadingManagers,
  } = useGetIncentiveManagers({
    fetchPolicy: "network-only",
    skip: userDetails.role !== ROLES.ADMIN,
    notifyOnChangeProps: true,
  });

  const managersList = incentiveManagers?.response?.map((user) => {
    return {
      ...user,
      FullName: user.Managers,
    };
  });

  const defaultManager = managersList?.find(
    (user) => user.UserID === userDetails.id
  );

  useEffect(() => {
    if (managersList && !filtersState.owner) {
      const newOwner =
        managersList.find((manager) => {
          return manager.UserID === managerId;
        }) ||
        defaultManager ||
        allOption;

      handleChange({ key: "owner", value: newOwner });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerId, managersList, filtersState.owner]);

  const formattedUserData = () => {
    const loggedInUser = managersList?.filter(
      (user) => user.UserID === userDetails.id
    );
    const activeUsers = managersList?.filter(
      (user) => !user.StatusCode && user.UserID !== userDetails.id
    );
    const deactivatedUsers = managersList?.filter(
      (user) => user.StatusCode && user.GoalCount !== 0
    );

    const formattedData = [
      allOption,
      {
        label: "",
        options: [...loggedInUser, ...activeUsers],
      },

      {
        label: "deactivated users",
        options: deactivatedUsers,
      },
    ];
    return formattedData;
  };

  const payloadUserId =
    userDetails.role === ROLES.ADMIN
      ? !filtersState.owner
        ? null
        : filtersState.owner?.UserID
        ? filtersState.owner.UserID === "ALL"
          ? undefined
          : filtersState.owner.UserID
        : userDetails.id
      : userDetails.id;

  const payloadForRewardsModal = {
    userId: payloadUserId,
    featureType: isIncentive
      ? FEATURE_TYPES.INCENTIVE
      : FEATURE_TYPES.LEADERBOARD,
    TimeFrequency:
      filtersState.timeFrequency?.startsAt && filtersState.timeFrequency?.endsAt
        ? {
            StartsAt: filtersState.timeFrequency.startsAt,
            EndsAt: filtersState.timeFrequency.endsAt,
          }
        : null,
    AllDateRange: filtersState.timeFrequency.value === "ALL",
  };

  return (
    <div className="px-3">
      <div className="incentives-mobile-filters">
        <MobileFilterBarIcons
          filters={DASHBOARD_LANDING_PAGE_FILTERS}
          filtersState={filtersState}
          handleChange={handleChange}
          {...rest}
        />
      </div>
      <div>
        <MobileFilterBarFilters
          filters={DASHBOARD_LANDING_PAGE_FILTERS}
          filtersState={filtersState}
          handleChange={handleChange}
          {...rest}
        />
      </div>
      {userDetails.role === ROLES.ADMIN && (
        <div className="mt-3">
          {!loadingManagers && managersList && filtersState.owner ? (
            <MobileGoalOwnerDropdown
              id="nudges_goal_owner"
              startAdornment="Manager"
              data={formattedUserData()}
              Owner={filtersState.owner}
              onChangeHandler={(newOwner) =>
                handleChange({ key: "owner", value: newOwner })
              }
              preSelect
              hideGoalCount={false}
            />
          ) : (
            <Shimmer width="100%" height="40px" />
          )}
        </div>
      )}
      <div className="mt-3">
        <DashboardHeroCardGroup
          {...{
            loadingManagers,
            payloadUserId,
            filtersState,
            payloadForRewardsModal,
            isIncentive,
          }}
        />
      </div>
      <div className="mt-3">
        <MobileDashboardBody
          {...{ payloadUserId, filtersState, isIncentive }}
        />
      </div>
    </div>
  );
}

export default MobileDashboardPage;
