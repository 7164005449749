import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const INSERT_LEADERBOARD_IMPORT_RECORDS_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation insertLeaderboardImportRecords(
    $LeaderboardId: ID!
    $FileId: Float!
  ) {
    insertLeaderboardImportRecords(
      LeaderboardId: $LeaderboardId
      FileId: $FileId
    ) {
      ... on insertLeaderboardImportRecordsResponse {
        success
        message
        response
        __typename
      }
      ...errorResponseFragment
      __typename
    }
  }
`;

export const useInsertLeaderboardImportRecords = () => {
  return useMutation(INSERT_LEADERBOARD_IMPORT_RECORDS_MUTATION);
};
