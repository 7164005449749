import { gql, useQuery } from "src/api/client";

export const GET_SYSTEM_CONFIG_DETAILS_QUERY = gql`
  query getSystemConfigDetails {
    getSystemConfigDetails {
      goalRulesCount
      validityTime
    }
  }
`;

export const useGetSystemConfigDetails = (queryOptions) => {
  return useQuery(GET_SYSTEM_CONFIG_DETAILS_QUERY, queryOptions);
};
