import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getTemplateId } from "src/apps/incentives/utils";
import { ConditionList, NonMonetaryLabel } from "src/components/common/atoms";
import NonMonetaryRewardDetails from "src/components/common/nonMonetary/nonMonetaryRewardDetails/NonMonetaryRewardDetails";
import { AchievementBreakDown } from "src/components/container";
import {
  APPS,
  COLORS,
  DRILLDOWN_STATUS_TYPES,
  FIELD_NAMES,
  PROGRAM_STATUS_TYPES,
} from "src/constants";
import { getAlphabetForCriteria } from "src/helpers";
import { useShowDrillDown } from "src/hooks";
import { SvgArrow } from "src/icons";
import { FormButton } from "../../formElements";

const CriteriaAccordion = (props) => {
  const {
    selected,
    handleClick,
    criteria,
    selectedIndex,
    payloadForRewardsList,
    recurrences,
    selectedRecurrence,
    customType,
    programName,
    templates,
    goals,
    programId,
    userId,
    recurrenceId,
    userName,
  } = props;
  const [rewardDetailsModal, setRewardDetailsModal] = useState({
    show: false,
    details: {},
    title: "",
  });
  const { t } = useTranslation();
  const { showDrillDown } = useShowDrillDown();

  const {
    CriteriaId: criteriaId,
    eligibleRewards,
    eligibleRewardsCount,
    eligibleRewardIds,
    eligibleIncentive: eligibleBenefit,
    Criteria,
  } = criteria;

  const AccordionContent = (rewards) => {
    const { Conditions } = rewards;
    const [breakdownModal, setBreakdownModal] = useState({
      show: false,
      achievedValue: null,
      metric: null,
      isFormula: null,
    });
    const [selectedTemplateId, setSelectedTemplateId] = useState(
      getTemplateId(Conditions[0])
    );

    const selectedTemplate = templates.find(
      (template) => template.TemplateId === selectedTemplateId
    );

    const enableDrilldown =
      selectedTemplate?.DrilldownStatus === DRILLDOWN_STATUS_TYPES.ENABLED &&
      showDrillDown &&
      selectedRecurrence?.RecurrenceStatus !== PROGRAM_STATUS_TYPES.YETTOSTART;

    return (
      <div className="px-3">
        <ConditionList
          data={Conditions}
          onTemplateSelection={({
            achievedValue,
            templateId,
            metric,
            isFormula,
          }) => {
            setBreakdownModal({ show: true, achievedValue, metric, isFormula });
            setSelectedTemplateId(templateId);
          }}
          templates={templates}
          showDrillDown={enableDrilldown}
          goals={goals}
          as="Accordion"
          programId={programId}
          userId={userId}
          recurrenceId={recurrenceId}
        />

        {selectedTemplate?.DrilldownStatus === DRILLDOWN_STATUS_TYPES.ENABLED &&
          selectedRecurrence?.Status !== PROGRAM_STATUS_TYPES.YETTOSTART &&
          breakdownModal.show &&
          showDrillDown &&
          recurrences &&
          selectedRecurrence && (
            <AchievementBreakDown
              show={breakdownModal.show}
              onHide={() =>
                setBreakdownModal({
                  show: false,
                  achievedValue: null,
                  metric: null,
                  isFormula: null,
                })
              }
              recurrenceList={recurrences}
              currentRecurrence={selectedRecurrence}
              selectedGoalUser={{
                UserID: payloadForRewardsList.userId,
                Achievement: breakdownModal.achievedValue,
                Type: APPS.INCENTIVES.ROUTES.APPROVAL.name,
                metric: breakdownModal.metric,
                isFormula: breakdownModal.isFormula,
                userName,
              }}
              programId={payloadForRewardsList.programId}
              customType={customType}
              title={programName}
              isIncentive
              moduleName={APPS.INCENTIVES.ROUTES.MY_INCENTIVES_DETAIL_VIEW.name}
              templateId={selectedTemplate?.TemplateId}
              templates={selectedTemplate}
            />
          )}
      </div>
    );
  };

  const formatNonMonetary = (rewards, rewardCount, payload, title) => {
    const handleClick = (e) => {
      e.stopPropagation();
      setRewardDetailsModal({
        show: true,
        details: payload,
        title,
      });
    };

    if (rewardCount === 1) {
      return (
        <FormButton
          className="pl-1 truncate-rewards fc-blue pointer text-decorate"
          onClick={handleClick}
          variant="link"
        >
          {rewards[0].RewardName}
        </FormButton>
      );
    }
    return (
      <FormButton
        className="pl-1 fc-blue pointer text-decorate fs-15"
        onClick={handleClick}
        variant="link"
      >
        {rewards[0].RewardName?.substring(0, 8)}
        ... +{rewardCount - 1}
      </FormButton>
    );
  };

  const AccordionTitle = ({ sequenceId }) => {
    const alphabet = getAlphabetForCriteria(sequenceId);
    return (
      <div id={criteriaId} className="row w-100 m-0">
        <div className="col-4 p-0 d-flex flex-column align-items-start">
          <div className="fs-12 fc-grey1">
            {t("INCENTIVES.COMMON.LABELS.PROGRAM_CRITERIA")}
          </div>
          <div className="fs-15 semi-bold flex-start">{alphabet}</div>
        </div>
        <div className="col-5 p-0 d-flex flex-column align-items-start">
          <div className="fs-12 fc-grey1">
            {t("INCENTIVES.COMMON.LABELS.ELIGIBLE_INCENTIVE")}
          </div>
          <div className="fs-15 semi-bold flex-start">
            <NonMonetaryLabel
              amount={eligibleBenefit}
              nonMonetaryRewards={eligibleRewards}
              rewardCount={eligibleRewardsCount}
              className="d-flex "
              formatNonMonetary={(payload) =>
                formatNonMonetary(
                  eligibleRewards,
                  eligibleRewardsCount,
                  payload,
                  `${t(
                    "INCENTIVES.COMMON.LABELS.PROGRAM_CRITERIA"
                  )} ${alphabet}`
                )
              }
              payload={{
                ...payloadForRewardsList,
                criteriaId,
                rewardIds: eligibleRewardIds,
                fieldName: FIELD_NAMES.ELIGIBLE,
              }}
            />
          </div>
        </div>
        <div className="col-3 p-0 flex-end flex-end">
          <SvgArrow
            color={COLORS.GREY_2}
            rotate={selected ? 180 : 0}
            styles={{
              transition: "all 0.3s",
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Accordion.Toggle
        variant="link"
        eventKey={String(selectedIndex)}
        className={`border-1 px-4 py-3 bg-white w-100 br-4 ${
          selected ? "br-b-0 border-bottom-0 bc-primary-blue" : "bc-grey11"
        }`}
        onClick={() => handleClick(selectedIndex)}
      >
        <AccordionTitle sequenceId={criteria?.sequenceId} />
      </Accordion.Toggle>
      <Accordion.Collapse
        className={`br-4 br-t-0 border-top-0 border-1  ${
          selected ? "bc-primary-blue" : "bc-grey11"
        }`}
        eventKey={String(selectedIndex)}
      >
        <div className="p-0 py-3 space-y-16">
          {[Criteria].map((reward, idx) => (
            <AccordionContent key={idx} {...reward} criteriaId={criteriaId} />
          ))}
        </div>
      </Accordion.Collapse>
      <NonMonetaryRewardDetails
        show={rewardDetailsModal.show}
        handleClose={() => setRewardDetailsModal({ show: false })}
        title={rewardDetailsModal.title}
        payload={{ ...payloadForRewardsList, ...rewardDetailsModal.details }}
      />
    </>
  );
};

export default CriteriaAccordion;
