import { DATE_FORMAT } from "src/constants";
import { format, isSameDay } from "src/helpers/vendors";

export const getCycleInterval = (startDate, endDate) => {
  const startDateString = format(new Date(startDate), DATE_FORMAT);
  if (isSameDay(startDate, endDate)) return startDateString;
  const endDateString = format(new Date(endDate), DATE_FORMAT);

  return `${startDateString} - ${endDateString}`;
};
