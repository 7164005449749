import { gql, useMutation } from "src/api/client";

const DOWNLOAD_BULK_APPROVAL_RAW_EXCEL_MUTATION = gql`
  mutation downloadBulkApprovalRawExcel(
    $programId: ID
    $recurrenceId: ID
    $GroupId: ID
    $query: JSON
  ) {
    downloadBulkApprovalRawExcel(
      programId: $programId
      recurrenceId: $recurrenceId
      GroupId: $GroupId
      query: $query
    ) {
      ... on ApprovalFileUploadUrlResponse {
        success
        message
        response {
          url
          fileId
        }
      }
    }
  }
`;

export const useDownloadBulkApprovalRawExcel = () => {
  return useMutation(DOWNLOAD_BULK_APPROVAL_RAW_EXCEL_MUTATION);
};
