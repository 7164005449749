import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgClose = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      {...rest}
    >
      <g>
        <g data-name="close">
          <path
            fill={color}
            fillRule="evenodd"
            d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7z"
          />
        </g>
      </g>
    </svg>
  );
};

SvgClose.defaultProps = {
  color: COLORS.GREY_2,
  height: 14,
  width: 14,
};
export default SvgClose;
