import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import "./styles.scss";

const getStyles = (el) => ({
  position: "absolute",
  top: el.offsetTop + el.clientHeight,
  left: el.offsetLeft,
});

const MenuPropTypes = {
  el: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  container: PropTypes.node,
};

const defaultMenuProps = {
  container: document.body,
};

export const Menu = ({ el, children, container, applyStyles = getStyles }) => {
  return ReactDOM.createPortal(
    el ? (
      <div className="menu" style={applyStyles(el)}>
        {children}
      </div>
    ) : null,
    container || document.body
  );
};

Menu.propTypes = MenuPropTypes;
Menu.defaultProps = defaultMenuProps;
