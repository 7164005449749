import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgCross = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill={backgroundColor} d="M7 0a7 7 0 1 0 7 7 7 7 0 0 0-7-7Z" />
      <path
        fill={color}
        d="M9.747 8.53a.86.86 0 1 1-1.215 1.215L7.004 8.202l-1.532 1.54A.86.86 0 1 1 4.254 8.53l1.54-1.528-1.54-1.536a.86.86 0 0 1 1.215-1.215l1.535 1.541 1.532-1.54a.86.86 0 1 1 1.215 1.215L8.204 7.002Z"
      />
    </svg>
  );
};

SvgCross.defaultProps = {
  backgroundColor: COLORS.GREY_2,
  color: COLORS.WHITE,
  height: 14,
  width: 14,
};
export default SvgCross;
