import PropTypes from "prop-types";
import React from "react";
import { Gadgets, Learning, Others, Travel, Vouchers } from "src/images";
import { Image } from "../image";
import "./rewardIcon.scss";

const CUSTOM_REWARD_CATEGORY_IMAGES = {
  TRAVEL: Travel,
  GADGET: Gadgets,
  VOUCHER: Vouchers,
  LEARNING: Learning,
  OTHERS: Others,
};

const RewardIcon = ({ src, alt, className, width, height, rewardCategory }) => {
  const DefaultIcon = CUSTOM_REWARD_CATEGORY_IMAGES[rewardCategory] || Others;

  const url = src ? `${src}?timestamp=${Date.now()}` : null;

  return (
    <div
      className={`reward-icon br-4 ${className || ""}`}
      style={{
        width,
        height,
      }}
    >
      {url && <Image src={url} alt={alt} /> ? (
        <Image src={url} alt={alt} />
      ) : (
        <DefaultIcon />
      )}
    </div>
  );
};

RewardIcon.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rewardCategory: PropTypes.string.isRequired,
};

RewardIcon.defaultProps = {
  width: 35,
  height: 35,
  alt: "custom-reward-icon",
};

export default RewardIcon;
