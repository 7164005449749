import { gql, useQuery } from "src/api/client";
import { PAGE_INFO_FRAGMENT } from "src/api/fragments";

const GET_TOP_PERFORMERS_DETAILS_LIST = gql`
  ${PAGE_INFO_FRAGMENT}
  query getTopPerformersDetails(
    $ProgramId: ID!
    $limit: Float
    $after: String!
  ) {
    getTopPerformersDetails(
      ProgramId: $ProgramId
      limit: $limit
      after: $after
    ) {
      ... on TopPerformersDetailsResponse {
        response {
          totalCount
          Users
          pageInfo {
            ...pageInfoFragment
          }
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetTopPerformersDetails = (queryOptions) => {
  return useQuery(GET_TOP_PERFORMERS_DETAILS_LIST, queryOptions);
};
