import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Shimmer } from "src/components/common/atoms";
import { FormButton } from "src/components/common/formElements";
import { Indicators, LazyLoad } from "src/components/common/molecules";
import { capitalize, classNames, range } from "src/helpers";
import { SvgArrow } from "src/icons";
import { RecognitionBoardEmptyState } from "src/images";
import RecognitionBoardCard from "./RecognitionBoardCard";
import { CYCLES_BUTTONS, ITEMS_TO_SHOW } from "./data";
import "./recognitionBoard.scss";

const RecognitionBoard = (props) => {
  const {
    recognitionList = [],
    period,
    setPeriod,
    totalCount,
    handleFetchMore,
    loading,
    hasNextPage,
    isMobile = false,
  } = props;
  const recognitionBoardRef = useRef(null);
  const [index, setIndex] = useState(0);
  const numberOfCardsInRow = isMobile ? 2 : 3;
  useEffect(() => {
    setIndex(0);
  }, [period]);

  useEffect(() => {
    if (index !== 0 && recognitionList?.length < totalCount && hasNextPage) {
      handleFetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);
  const mappedArray = recognitionList.reduce((acc, item, index) => {
    if (index % numberOfCardsInRow === 0) {
      const group = {
        items: [item],
      };
      acc.push(group);
    } else {
      acc[acc.length - 1].items.push(item);
    }
    return acc;
  }, []);

  const toggleCarousel = (direction) => {
    let idx = index;

    const [min, max] = [0, mappedArray.length - 1];

    if (direction === "next") {
      idx++;
    } else if (direction === "prev") {
      idx--;
    }

    if (idx > max) {
      // at max, start from top
      idx = 0;
    }

    if (idx < min) {
      // at min, start from max
      idx = max;
    }
    setIndex(idx);
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const itemsVisible =
    (index + 1) * ITEMS_TO_SHOW > totalCount
      ? totalCount % ((index + 1) * ITEMS_TO_SHOW)
      : (index + 1) * ITEMS_TO_SHOW;

  return (
    <div className={classNames(["recognition-board", isMobile && "p-3"])}>
      <div className="fs-18 semi-bold mb-3">
        {t("LEADERBOARD.LABELS.RECOGNITION_BOARD")}
      </div>
      <div className="flex-between m-0">
        <div className="p-0 flex-start">
          {!loading ? (
            <>
              <div className="fs-14 semi-bold">{capitalize(period)}</div>
              {recognitionList.length > 0 && (
                <div className="fc-grey1 fs-13 semi-bold ml-2">
                  {itemsVisible}/{totalCount}
                </div>
              )}
            </>
          ) : (
            <Shimmer width="100px" />
          )}
        </div>
        {!loading ? (
          <Indicators itemSpan={3} itemCount={totalCount} activeIndex={index} />
        ) : (
          <Shimmer width="40px" />
        )}
        {!loading ? (
          recognitionList.length > 0 && (
            <div className="p-0 flex-end recognition-board-buttons">
              <FormButton
                variant="link"
                className="center br-2 bg-white mr-1 recognition-board-button"
                onClick={() => toggleCarousel("prev")}
              >
                <SvgArrow className="w-100" height={12} rotate={90} />
              </FormButton>
              <FormButton
                variant="link"
                className="center br-2 bg-white ml-1 recognition-board-button"
                onClick={() => toggleCarousel("next")}
              >
                <SvgArrow className="w-100" height={12} rotate={-90} />
              </FormButton>
            </div>
          )
        ) : (
          <div className="center">
            <Shimmer width="24px" height="24px" />
            <Shimmer width="24px" height="24px" className="ml-1" />
          </div>
        )}
      </div>
      <div className="mt-4 flex-between recognition-board-cycles">
        {CYCLES_BUTTONS.map(({ id, icon }) => {
          const Icon = icon;
          return (
            <FormButton
              key={id}
              onClick={() => setPeriod(id)}
              className={classNames([
                "cursor-pointer flex-center recognition-board-cycle-item",
                id === period ? "active" : "",
              ])}
              variant="normal-link"
            >
              <Icon />
            </FormButton>
          );
        })}
      </div>
      <div
        ref={recognitionBoardRef}
        className="mt-24 recognition-board-card-wrapper"
      >
        {recognitionList.length > 0 ? (
          <Carousel
            indicators={false}
            controls={false}
            activeIndex={index}
            onSelect={handleSelect}
          >
            {mappedArray.map(({ items }) => (
              <Carousel.Item>
                {!loading ? (
                  <>
                    {items.map((item) => (
                      <LazyLoad container={recognitionBoardRef.current}>
                        <RecognitionBoardCard
                          currentCycle={item?.LastCompletedCycle}
                          name={item?.Name}
                          status={item?.Status}
                          groupName={item?.GroupName}
                          lastUpdated={item?.LastUpdated}
                          users={item?.Users}
                          isMobile={isMobile}
                        />
                      </LazyLoad>
                    ))}
                  </>
                ) : (
                  <>
                    {range(0, 2).map(() => (
                      <Shimmer width="100%" height="90px" />
                    ))}
                  </>
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <div className="pt-5 flex-center flex-column">
            <RecognitionBoardEmptyState height="137px" width="205px" />
            <span className="fs-13 semi-bold fc-grey1 mt-3">
              {t("LEADERBOARD.MESSAGES.NO_RECENT_WINNERS_TO_SHOW_IN")}{" "}
              {capitalize(period)} {t("LEADERBOARD.LABELS.LEADERBOARDS")}
            </span>
            <span className="fs-12 fc-grey1 mt-2">
              {t(
                "LEADERBOARD.MESSAGES.WINNERS_FROM_RECENT_CYCLES_WILL_BE_DISPLAYED_HERE"
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecognitionBoard;
