import { t } from "i18next";
import React from "react";
import { NetworkStatus } from "src/api/client";
import { useGetNonMonetaryRewardsList } from "src/api/useCustomRewardConfig";
import {
  LoadMoreButton,
  Shimmer,
  errorToaster,
} from "src/components/common/atoms";
import { PAGINATION_LIMIT } from "src/constants";
import { errorText, range } from "src/helpers";
import { NoResults } from "src/images";
import RewardsFlatlistCard from "./RewardsFlatlistCard";

const RewardsFlatlistBody = ({ show, getQuery }) => {
  const {
    data: { nonMonetaryRewardsList } = { nonMonetaryRewardsList: [] },
    loading,
    error,
    networkStatus,
    fetchMore,
  } = useGetNonMonetaryRewardsList({
    variables: {
      Limit: PAGINATION_LIMIT,
      After: null,
      Query: getQuery(),
    },
    skip: !show,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const rewardList = nonMonetaryRewardsList?.response?.rewardsList;
  const totalCount = nonMonetaryRewardsList?.response?.totalCount;

  const onFetchMore = () => {
    const { endCursor } = nonMonetaryRewardsList?.response?.pageInfo;
    fetchMore({
      variables: {
        Limit: PAGINATION_LIMIT,
        After: endCursor,
        Query: getQuery(),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        fetchMoreResult.nonMonetaryRewardsList.response.rewardsList = [
          ...prev.nonMonetaryRewardsList.response.rewardsList,
          ...fetchMoreResult.nonMonetaryRewardsList.response.rewardsList,
        ];
        return fetchMoreResult;
      },
    });
  };

  if (nonMonetaryRewardsList?.__typename === "ErrorResponse") {
    errorToaster({
      message: nonMonetaryRewardsList?.message,
    });
    return null;
  }

  if (error) {
    errorToaster({
      message: errorText.FETCHING_CUSTOM_REWARD_HISTORY.ERROR,
    });
    return null;
  }

  if (!loading && !rewardList?.length) {
    return (
      <div className="flex-column-center py-4">
        <NoResults />
        <div className="mt-3 fs-14 fc-grey1">
          {t("LEADERBOARD.COMMON.LABELS.NO_RESULTS_TO_SHOW")}
        </div>
      </div>
    );
  }

  if (
    networkStatus !== NetworkStatus.ready &&
    networkStatus !== NetworkStatus.fetchMore
  ) {
    return (
      <div className="space-y-16">
        {range(0, PAGINATION_LIMIT).map(() => (
          <Shimmer width="100%" height="140px" />
        ))}
      </div>
    );
  }

  const disableLoadMore = rewardList.length === totalCount;

  return (
    <>
      <div className="space-y-16">
        {rewardList.map((reward) => (
          <RewardsFlatlistCard reward={reward} />
        ))}
      </div>
      {!disableLoadMore && (
        <LoadMoreButton
          loading={loading}
          disabled={rewardList.length === totalCount}
          id="reward-history-grid-show-more"
          onClick={onFetchMore}
          itemsLoaded={rewardList.length}
          totalItems={totalCount}
        />
      )}
    </>
  );
};

export default RewardsFlatlistBody;
