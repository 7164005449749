import React from "react";

const SvgAvatar40 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="ko1l__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {"\n            .ko1l__cls-2{fill:var(--avatar-dark)}\n        "}
        </style>
      </defs>
      <g id="ko1l__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="ko1l__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.395 72.075)"
        />
        <path
          d="M139.69 138.2c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="ko1l__cls-2"
        />
        <path
          d="M54.99 128.21c.31 2.49.99 4.92 2.01 7.21.19.38.58.62 1 .62 7.07.18 13.96-2.2 19.4-6.71h8.14a29.256 29.256 0 0 0 19.4 6.71c.42 0 .81-.24 1-.62 1.02-2.29 1.7-4.72 2.01-7.21.52-2.81 0-8.94 0-8.94-.31-2.49-.99-4.92-2.01-7.21-.19-.38-.58-.62-1-.62a29.22 29.22 0 0 0-19.4 6.71H77.4a29.256 29.256 0 0 0-19.4-6.71c-.42 0-.81.24-1 .62-1.02 2.29-1.7 4.72-2.01 7.21 0 0-.83 4.96 0 8.94Zm23.13-7.82h6.71v6.71h-6.71v-6.71Zm-21.24 3.35c0-3.43.62-6.84 1.83-10.05 6.26-.01 12.33 2.15 17.17 6.12v2.57a19.58 19.58 0 0 0-12.29-4.23c-.62 0-1.12.5-1.12 1.12s.5 1.12 1.12 1.12c4.59-.03 9 1.75 12.29 4.94v2.34a26.945 26.945 0 0 1-17.17 6.12 28.514 28.514 0 0 1-1.83-10.05Zm47.35 10.05c-6.26.01-12.33-2.15-17.17-6.12v-2.34c3.29-3.2 7.71-4.97 12.29-4.94.62 0 1.12-.5 1.12-1.12s-.5-1.12-1.12-1.12a19.5 19.5 0 0 0-12.29 4.23v-2.57a26.945 26.945 0 0 1 17.17-6.12 28.539 28.539 0 0 1 0 20.11Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </svg>
  );
};
SvgAvatar40.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar40;
