import { gql, useMutation } from "src/api/client";

// src/modules/goalCrud/tabView/headerContent/tabButtons/CloseButton.js
export const REMOVE_USER_DISTRIBUTION_MUTATION = gql`
  mutation resetDistribution($GoalId: ID!, $ModifiedBy: ID!) {
    resetDistribution(GoalId: $GoalId, ModifiedBy: $ModifiedBy) {
      success
      message
      response
    }
  }
`;

export const useRemoveGoalDistribution = () => {
  return useMutation(REMOVE_USER_DISTRIBUTION_MUTATION);
};
