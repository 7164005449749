import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgAchievements = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <defs>
        <clipPath id="vclf__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#vclf__a)">
        <g>
          <path fill="none" d="M0 0h16v16H0z" />
          <g>
            <path
              fill={color}
              d="m6.63 5.144.9-.136.4-.85a.192.192 0 0 1 .352 0l.4.85.9.136a.208.208 0 0 1 .11.351l-.65.662.153.935a.2.2 0 0 1-.286.216l-.8-.441-.8.441a.2.2 0 0 1-.286-.216l.153-.935-.65-.662a.208.208 0 0 1 .104-.351Z"
            />
            <path
              fill="none"
              stroke={color}
              strokeWidth={1.2}
              d="M3.56 5.874V2.842a.658.658 0 0 1 .658-.658h7.566a.658.658 0 0 1 .658.658v3.314A3.58 3.58 0 0 1 9.56 9.667a.512.512 0 0 0-.411.5v1.554a.658.658 0 0 0 .658.658h.987a.658.658 0 0 1 .658.658v.658H4.876v-.658a.658.658 0 0 1 .658-.658h.986a.658.658 0 0 0 .658-.658v-1.512a.551.551 0 0 0-.452-.542A3.856 3.856 0 0 1 3.56 5.874Z"
            />
            <path
              fill={color}
              d="M3.56 16a1.316 1.316 0 0 1 1.316-1.316h6.579A1.316 1.316 0 0 1 12.771 16H3.56Z"
            />
            <path
              fill="none"
              stroke={color}
              strokeWidth={1.2}
              d="M3.557 3.5H1.868a.611.611 0 0 0-.611.611v1.034a2.631 2.631 0 0 0 2.631 2.631"
            />
            <path
              fill="none"
              stroke={color}
              strokeWidth={1.2}
              d="M12.441 3.5h1.692a.611.611 0 0 1 .611.611v1.034a2.631 2.631 0 0 1-2.632 2.631"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
SvgAchievements.defaultProps = {
  color: COLORS.GREY_2,
  height: 16,
  width: 16,
};
export default SvgAchievements;
