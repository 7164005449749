import { gql, useMutation } from "src/api/client";

const CREATE_PROGRAM_FILE_DOWNLOAD_URL_MUTATION = gql`
  mutation createProgramFileDownloadUrl($ProgramId: ID!) {
    createProgramFileDownloadUrl(ProgramId: $ProgramId) {
      ... on ProgramUrlResponse {
        success
        message
        response {
          url
          fileId
        }
      }
    }
  }
`;

export const useCreateDownloadUrl = () => {
  return useMutation(CREATE_PROGRAM_FILE_DOWNLOAD_URL_MUTATION);
};
