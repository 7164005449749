import { lazy } from "react";

const WebPage = lazy(() =>
  import(/* webpackChunkName: 'incentives.web.payoutRequestPage' */ "./web")
);

const MobilePage = lazy(() =>
  import(
    /* webpackChunkName: 'incentives.mobile.payoutRequestPage' */ "./mobile"
  )
);

export const PayoutRequestPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "payout-request-page",
  },
};
