import React from "react";

const SvgAvatar20 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="qi4w__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.8"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .qi4w__cls-1{fill:#fff}.qi4w__cls-2{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="qi4w__Layer_1-2">
        <path
          d="M162.8 81.4c0 22.1-8.8 42.12-23.11 56.8-9.77 10.04-22.12 17.56-35.98 21.5-.71.2-1.42.4-2.14.57-.76.2-1.52.39-2.29.55-.7.17-1.41.31-2.12.45-5.1 1-10.37 1.53-15.76 1.53-22.86 0-43.52-9.43-58.3-24.6C8.8 123.52 0 103.5 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.08}
          y={38.76}
          className="qi4w__cls-2"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.4 72.08)"
        />
        <path
          d="M139.69 138.2a81.305 81.305 0 0 1-31.93 20.23c-.56.2-1.13.38-1.69.57-.6.18-1.2.37-1.8.54-.56.16-1.12.32-1.68.47a81.328 81.328 0 0 1-21.19 2.79c-22.86 0-43.52-9.43-58.3-24.6 16.3-12.06 36.47-19.19 58.3-19.19 7.27 0 14.37.79 21.19 2.3.56.12 1.12.25 1.68.39.6.13 1.2.28 1.8.44.57.14 1.13.3 1.69.46a97.512 97.512 0 0 1 31.93 15.6Z"
          className="qi4w__cls-2"
        />
        <path
          d="M52.75 52.24a6.51 6.51 0 0 0-5.21 6.38v.5l-4.49 2.24c-.44.22-.72.68-.72 1.17v15.63c0 .5.28.95.72 1.17l4.49 2.24v.5a6.52 6.52 0 0 0 6.51 6.51h18.23c.56 0 1.06-.36 1.23-.89l1.01-3.02h13.75l1.01 3.02c.18.53.67.89 1.23.89h18.23a6.52 6.52 0 0 0 6.51-6.51v-.5l4.49-2.24c.44-.22.72-.68.72-1.17V62.53c0-.5-.28-.95-.72-1.17l-4.49-2.24v-.5a6.52 6.52 0 0 0-6.51-6.51V24.76c0-1.4-.74-2.69-1.95-3.38a3.903 3.903 0 0 0-5.86 3.38v27.35H90.51c-.56 0-1.06.36-1.23.89l-1.01 3.02H74.52L73.51 53a1.3 1.3 0 0 0-1.23-.89H60.56l-7.81.13Zm-7.81 25.11V63.33l2.6-1.3v16.62l-2.6-1.3Zm58.61-52.59c0-.72.58-1.3 1.3-1.3s1.3.58 1.3 1.3v27.35h-2.6V24.76Zm9.12 57.31c0 1.04-.41 2.03-1.14 2.76s-1.73 1.14-2.76 1.14H91.48l-1.01-3.02a1.3 1.3 0 0 0-1.23-.89H73.61c-.56 0-1.06.36-1.23.89l-1.01 3.02H54.08c-1.04 0-2.03-.41-2.76-1.14s-1.14-1.73-1.14-2.76V58.63c0-1.04.41-2.03 1.14-2.76s1.73-1.14 2.76-1.14h17.29l1.01 3.02c.18.53.67.89 1.23.89h15.63c.56 0 1.06-.36 1.23-.89l1.01-3.02h17.29c1.04 0 2.03.41 2.76 1.14s1.14 1.73 1.14 2.76v23.44Zm5.21-4.71-2.6 1.3V62.04l2.6 1.3v14.02Z"
          className="qi4w__cls-1"
        />
        <path
          d="M104.84 72.14H56.87a1.799 1.799 0 1 1 0-3.6h47.97a1.799 1.799 0 1 1 0 3.6Z"
          className="qi4w__cls-1"
        />
      </g>
    </svg>
  );
};
SvgAvatar20.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar20;
