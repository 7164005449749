import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgAdmin = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="l633__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#l633__a)">
        <g>
          <path fill="none" d="M0 0h16v16H0z" />
          <path
            fill={color}
            stroke="#fff"
            strokeWidth={0.1}
            d="M8.508 12.889H1.2v-.64a2.164 2.164 0 0 1 2.16-2.16 9.789 9.789 0 0 0 2.24.4 6.2 6.2 0 0 0 2-.36 4.586 4.586 0 0 1 .153-1.23 14.436 14.436 0 0 1-2.153.39c-1.178 0-1.52-.4-2.24-.4A3.361 3.361 0 0 0 0 12.25v.64a1.2 1.2 0 0 0 1.2 1.2h8.551a4.808 4.808 0 0 1-1.243-1.201ZM5.6 8.489a3.6 3.6 0 1 0-3.6-3.6 3.6 3.6 0 0 0 3.6 3.6Zm0-6a2.4 2.4 0 1 1-2.4 2.4 2.4 2.4 0 0 1 2.4-2.4Zm9.663 8.133a2.933 2.933 0 0 0 0-1.065l.645-.373a.181.181 0 0 0 .083-.213 3.752 3.752 0 0 0-.83-1.435.182.182 0 0 0-.225-.035l-.645.373a2.935 2.935 0 0 0-.923-.533v-.744a.182.182 0 0 0-.143-.178 3.772 3.772 0 0 0-1.655 0 .182.182 0 0 0-.143.178v.745a2.935 2.935 0 0 0-.923.533l-.645-.373a.183.183 0 0 0-.225.035 3.752 3.752 0 0 0-.83 1.435.184.184 0 0 0 .083.213l.645.373a2.933 2.933 0 0 0 0 1.065l-.645.373a.181.181 0 0 0-.083.213 3.771 3.771 0 0 0 .83 1.435.182.182 0 0 0 .225.035l.645-.373a2.935 2.935 0 0 0 .923.533v.745a.182.182 0 0 0 .143.178 3.772 3.772 0 0 0 1.655 0 .182.182 0 0 0 .143-.178v-.745a2.935 2.935 0 0 0 .923-.533l.645.373a.183.183 0 0 0 .225-.035 3.752 3.752 0 0 0 .83-1.435.184.184 0 0 0-.083-.213Zm-2.863.68a1.213 1.213 0 1 1 1.214-1.213 1.214 1.214 0 0 1-1.214 1.213Z"
          />
        </g>
      </g>
    </svg>
  );
};

SvgAdmin.defaultProps = {
  color: COLORS.GREY_2,
  height: 16,
  width: 16,
};
export default SvgAdmin;
