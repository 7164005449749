import { t } from "i18next";
import React from "react";
import { GOAL_AGGREGATION_TYPES } from "src/constants";
import { classNames } from "src/helpers";
import AchievementCardLeft from "./AchievementCardLeft";
import AchievementCardRight from "./AchievementCardRight";
import "./achievementCard.scss";

const AchievementCard = (props) => {
  const {
    recurrenceStatus,
    status,
    days,
    isAssignedToMeView,
    goalAggConfig,
    goalValue,
    goalAchieved,
    goalUsersCount,
    usersAchievedCount,
    isUserPresent,
    isRollUp,
    RollupType,
    onGoalUserChange,
    showDrillDown,
    setShowSubGoalModal,
  } = props;

  const usersCount =
    goalAggConfig === GOAL_AGGREGATION_TYPES.TARGET_BASED && !isAssignedToMeView
      ? goalUsersCount
      : null;

  const daysRemaining =
    status === "PUBLISHED" && recurrenceStatus === "INPROGRESS" ? days : null;

  return (
    <div className="center position-relative">
      <div className="br-4 center achievement-card">
        <div className="w-100 flex align-items-start flex-column achievement-card-left">
          <AchievementCardLeft
            {...{
              status,
              goalValue,
              goalAchieved,
              recurrenceStatus,
              goalAggConfig,
              isAssignedToMeView,
              goalUsersCount,
              usersAchievedCount,
              isUserPresent,
              isRollUp,
              RollupType,
              onGoalUserChange,
              showDrillDown,
              setShowSubGoalModal,
            }}
          />
        </div>
        {(usersCount !== null || daysRemaining !== null) && (
          <div className="border-left-1 bc-grey9 center achievement-card-right">
            <AchievementCardRight
              {...{
                usersCount,
                daysRemaining,
                goalAggConfig,
                isAssignedToMeView,
              }}
            />
          </div>
        )}
      </div>
      <div
        className={classNames([
          "semi-bold fs-11 roll-up-text",
          isRollUp ? "roll-up" : "non-roll-up",
        ])}
      >
        {isRollUp ? (
          <>{t("COMMON.MESSAGES.ROLL_UP")}</>
        ) : (
          <>
            <span>{t("COMMON.MESSAGES.NON")}</span>
            <br />
            <span>{t("COMMON.MESSAGES.ROLL_UP")}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default AchievementCard;
