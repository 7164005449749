import { gql, useQuery } from "src/api/client";

const GET_STANDINGS_LIST = gql`
  query getLeaderboardStandings($Limit: Float, $After: String) {
    getLeaderboardStandings(limit: $Limit, after: $After) {
      ... on LeaderboardStandingsResponse {
        success
        response {
          LeaderboardStandings {
            LeaderboardId
            Name
            IsGroupBased
            GroupId
            GroupName
            CycleType
            Users {
              UserId
              Name
              Rank
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useLeaderboardStandings = (queryOptions) => {
  return useQuery(GET_STANDINGS_LIST, queryOptions);
};
