import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_NON_MONETARY_REWARDS_DROPDOWN_LIST = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query nonMonetaryRewardsDropdownList(
    $Query: RewardsProgramDetailsQuery
    $Before: String
    $After: String
    $Limit: Float
  ) {
    nonMonetaryRewardsDropdownList(
      Query: $Query
      Before: $Before
      After: $After
      Limit: $Limit
    ) {
      ... on NonMonetaryRewardsDropdownListResponse {
        response {
          rewardsList {
            RewardId
            RewardName
          }
          totalCount
          __typename
        }
        message
        success
      }
      ...errorResponseFragment
    }
  }
`;

export const useGetNonMonetaryRewardsDropdownList = (queryOptions) => {
  return useQuery(GET_NON_MONETARY_REWARDS_DROPDOWN_LIST, queryOptions);
};
