import { APPS, ROLES, USER_ROLES } from "src/constants";
import { useRoleBasedContext } from "src/context";
import { useViewport } from "src/hooks";

const useNudgesRbac = () => {
  const {
    userDetails: { role, isManagingUsers },
    isFeatureEnabled,
  } = useRoleBasedContext();

  const { isMobile } = useViewport();

  const DEFAULT_REDIRECTION = {
    [USER_ROLES.ADMIN]: isMobile
      ? APPS.GOALS.ROUTES.MANAGED_BY_ME.path
      : APPS.NUDGES.ROUTES.PROGRESS_NUDGE.path,
    [USER_ROLES.SALES_MANAGER]: APPS.NUDGES.ROUTES.PROGRESS_NUDGE.path,
    [USER_ROLES.SALES_USER]: APPS.NUDGES.ROUTES.PROGRESS_NUDGE.path,
  };

  const getRole = () => {
    if (role === ROLES.ADMIN) {
      return USER_ROLES.ADMIN;
    }

    if (isManagingUsers) {
      return USER_ROLES.SALES_MANAGER;
    }

    return USER_ROLES.SALES_USER;
  };

  const ACCESS_ROUTES = {
    [USER_ROLES.SALES_USER]: [],
    [USER_ROLES.SALES_MANAGER]: [
      APPS.NUDGES.ROUTES.PROGRESS_NUDGE.path,
      APPS.NUDGES.ROUTES.NO_PROGRESS_NUDGE.path,
      APPS.NUDGES.ROUTES.MILESTONE_NUDGE.path,
    ],
    [USER_ROLES.ADMIN]: [
      APPS.NUDGES.ROUTES.PROGRESS_NUDGE.path,
      APPS.NUDGES.ROUTES.NO_PROGRESS_NUDGE.path,
      APPS.NUDGES.ROUTES.MILESTONE_NUDGE.path,
    ],
  };

  const nudgesRbac = (route) => {
    switch (route) {
      case APPS.NUDGES.ROUTES.ROOT.path:
      case APPS.NUDGES.ROUTES.LANDING.path: {
        return {
          hasAccess: isFeatureEnabled.Goals,
          redirect: APPS.NUDGES.ROUTES.REQUEST_DEMO.path,
        };
      }

      case APPS.NUDGES.ROUTES.REQUEST_DEMO.path: {
        return {
          hasAccess: !isFeatureEnabled.Goals,
          redirect: APPS.NUDGES.ROUTES.ROOT.path,
        };
      }

      default: {
        return {
          hasAccess: ACCESS_ROUTES[getRole()].includes(route),
          redirect: DEFAULT_REDIRECTION[getRole()],
        };
      }
    }
  };

  return {
    nudgesRbac,
    DEFAULT_LOGGED_IN_USER_REDIRECTION: DEFAULT_REDIRECTION[getRole()],
  };
};

export default useNudgesRbac;
