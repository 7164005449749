import { gql, useQuery } from "src/api/client";

export const GET_GOAL_ACHIEVEMENT_DRILLDOWN_QUERY = gql`
  query fetchGoalDrilldown(
    $RecurrenceId: ID
    $UserId: ID
    $RuleId: ID
    $After: Int
    $Limit: Int
    $Search: String
    $ManagerId: ID
    $UserAttributesNames: [String]
    $IndividualDrilldown: Boolean
  ) {
    fetchGoalDrilldown(
      RecurrenceId: $RecurrenceId
      UserId: $UserId
      RuleId: $RuleId
      After: $After
      Limit: $Limit
      Search: $Search
      ManagerId: $ManagerId
      UserAttributesNames: $UserAttributesNames
      IndividualDrilldown: $IndividualDrilldown
    ) {
      ... on GoalDrillDownResponse {
        success
        message
        response {
          Users
          NextPageExists
          TotalUsers
          After
          LastUpdated
        }
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useGetGoalDrilldown = (queryOptions) => {
  return useQuery(GET_GOAL_ACHIEVEMENT_DRILLDOWN_QUERY, queryOptions);
};
