import { t } from "i18next";

export const getNonUniformCycleItemValidation = ({
  programEndDate,
  endDate,
  disabledMinDelete,
  maxAdd,
  disableAdd,
  isDraft,
  isBeforeCycle,
  isLastCycle,
  moduleName,
}) => {
  if (isDraft) {
    return {
      EDIT: {
        DISABLED: false,
        TOOLTIP_TEXT: t("COMMON.MESSAGES.TOOLTIP_TEXT.EDIT.ALLOWED", {
          moduleName: moduleName.toLowerCase(),
        }),
      },
      DELETE: {
        DISABLED: disabledMinDelete,
        TOOLTIP_TEXT: disabledMinDelete
          ? t("COMMON.MESSAGES.TOOLTIP_TEXT.DELETE.MIN_CYCLE", {
              moduleName: moduleName.toLowerCase(),
            })
          : t("COMMON.MESSAGES.TOOLTIP_TEXT.DELETE.ALLOWED", {
              moduleName: moduleName.toLowerCase(),
            }),
      },
      ADD: {
        DISABLED: disableAdd || maxAdd,
        TOOLTIP_TEXT: disableAdd
          ? t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.NO_GAP", {
              moduleName: moduleName.toLowerCase(),
            })
          : maxAdd
          ? t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.MAX_LIMIT", {
              moduleName: moduleName.toLowerCase(),
            })
          : t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.ALLOWED", {
              moduleName: moduleName.toLowerCase(),
            }),
      },
    };
  }

  if (isBeforeCycle === true) {
    return {
      EDIT: {
        DISABLED: true,
        TOOLTIP_TEXT: t("COMMON.MESSAGES.TOOLTIP_TEXT.EDIT.NOT_ALLOWED", {
          moduleName: moduleName.toLowerCase(),
        }),
      },
      DELETE: {
        DISABLED: true,
        TOOLTIP_TEXT: t("COMMON.MESSAGES.TOOLTIP_TEXT.DELETE.NOT_ALLOWED", {
          moduleName: moduleName.toLowerCase(),
        }),
      },
      ADD: {
        DISABLED: true,
        TOOLTIP_TEXT: t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.NOT_ALLOWED", {
          moduleName: moduleName.toLowerCase(),
        }),
      },
    };
  }

  if (isLastCycle === true) {
    return {
      EDIT: {
        DISABLED: programEndDate === endDate && maxAdd,
        TOOLTIP_TEXT:
          programEndDate === endDate && maxAdd
            ? t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.MAX_LIMIT", {
                moduleName: moduleName.toLowerCase(),
              })
            : t("COMMON.MESSAGES.TOOLTIP_TEXT.EDIT.ALLOWED", {
                moduleName: moduleName.toLowerCase(),
              }),
      },
      DELETE: {
        DISABLED: true,
        TOOLTIP_TEXT: t("COMMON.MESSAGES.TOOLTIP_TEXT.DELETE.NOT_ALLOWED", {
          moduleName: moduleName.toLowerCase(),
        }),
      },
      ADD: {
        DISABLED: disableAdd || maxAdd,
        TOOLTIP_TEXT: maxAdd
          ? t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.MAX_LIMIT", {
              moduleName: moduleName.toLowerCase(),
            })
          : disableAdd
          ? t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.NO_GAP", {
              moduleName: moduleName.toLowerCase(),
            })
          : t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.ALLOWED", {
              moduleName: moduleName.toLowerCase(),
            }),
      },
    };
  }

  return {
    EDIT: {
      DISABLED: false,
      TOOLTIP_TEXT: t("COMMON.MESSAGES.TOOLTIP_TEXT.EDIT.ALLOWED", {
        moduleName: moduleName.toLowerCase(),
      }),
    },
    DELETE: {
      DISABLED: false,
      TOOLTIP_TEXT: t("COMMON.MESSAGES.TOOLTIP_TEXT.DELETE.ALLOWED", {
        moduleName: moduleName.toLowerCase(),
      }),
    },
    ADD: {
      DISABLED: disableAdd || maxAdd,
      TOOLTIP_TEXT: maxAdd
        ? t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.MAX_LIMIT", {
            moduleName: moduleName.toLowerCase(),
          })
        : disableAdd
        ? t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.NO_GAP", {
            moduleName: moduleName.toLowerCase(),
          })
        : t("COMMON.MESSAGES.TOOLTIP_TEXT.ADD.ALLOWED", {
            moduleName: moduleName.toLowerCase(),
          }),
    },
  };
};
