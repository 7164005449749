import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgMobile = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      data-name="Group 11074"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill="none" d="M0 0h14v14H0z" data-name="Rectangle 2773" />
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.623.344H4.375a1.915 1.915 0 0 0-1.968 1.862v9.938a1.915 1.915 0 0 0 1.968 1.862h5.248a1.915 1.915 0 0 0 1.968-1.862V2.206A1.915 1.915 0 0 0 9.623.344ZM7.983 12.76H6.015a.311.311 0 1 1 0-.621h1.968a.311.311 0 1 1 0 .621Zm-4.264-1.862h6.56V2.206h-6.56Z"
      />
    </svg>
  );
};

SvgMobile.defaultProps = {
  color: COLORS.GREY_1,
  height: 14,
  width: 14,
};
export default SvgMobile;
