import { t } from "i18next";
import { FormButton } from "src/components";
import { SvgEdit } from "src/icons";
import { ErrorModal } from "./ErrorModal";
import "./incentivesRuleConfig.scss";
import { useConditionPattern } from "./useConditionPattern";

const ConditionPatternConfig = ({
  condition,
  conditionPattern,
  setConditionPattern,
  disabled,
  criteriaIndex,
}) => {
  const { state, actions } = useConditionPattern({
    conditions: condition,
    criteriaIndex,
    conditionPattern,
    setConditionPattern,
  });

  const { conditions, showErrorModal, pattern, editAllowed, error } = state;

  const {
    handleInputChange,
    handleEdit,
    handleOnInputCancel,
    handleOnUpdatePattern,
    setShowErrorModal,
  } = actions;

  return (
    <div>
      {conditions.length > 1 ? (
        <div className="form-group fs-13 flex-column">
          <div className="center">
            <input
              value={pattern}
              disabled={editAllowed}
              type="text"
              id="criteriaInput"
              className="form-control footer-input shadow-none fs-12"
              onChange={(e) => handleInputChange(e.target.value)}
            />
            {!disabled && editAllowed && (
              <FormButton
                variant="link"
                id="edit-pattern-btn"
                onClick={() => handleEdit()}
                className="ml-2"
                icon={<SvgEdit />}
                label={`${t("COMMON.BUTTONS.EDIT")} ${t(
                  "RULEBOOK.LABELS.PATTERN"
                )}`}
              />
            )}
            {!editAllowed && (
              <>
                <FormButton
                  variant="link"
                  id="update-pattern-btn"
                  onClick={(e) => handleOnUpdatePattern(e)}
                  label={t("COMMON.BUTTONS.UPDATE")}
                  className="px-3"
                />
                <FormButton
                  variant="link"
                  id="cancel-pattern-btn"
                  onClick={() => handleOnInputCancel()}
                  label={t("COMMON.BUTTONS.CANCEL")}
                />
              </>
            )}
          </div>
          <ErrorModal
            errorModal={showErrorModal}
            showErrorModal={setShowErrorModal}
            errors={error}
            cancelButtonOff
          />
        </div>
      ) : null}
    </div>
  );
};

export default ConditionPatternConfig;
