import { t } from "i18next";
import React from "react";
import { Modal } from "react-bootstrap";
import { FormButton } from "src/components/common/formElements";
import { useToggle } from "src/hooks";
import "./customFormulaExamplesModal.scss";

function CustomFormulaExamplesModal({ text, img }) {
  const [openModal, openOrHideModal] = useToggle();
  return (
    <>
      <FormButton variant="link" onClick={openOrHideModal}>
        {text}
      </FormButton>
      <Modal
        show={openModal}
        onHide={openOrHideModal}
        className="formula-examples-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-16">
            {t("COMMON.LABELS.FORMULA_EXAMPLES")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{img}</Modal.Body>
        <Modal.Footer>
          <FormButton variant="outline-primary" onClick={openOrHideModal}>
            {t("COMMON.BUTTONS.CLOSE")}
          </FormButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomFormulaExamplesModal;
