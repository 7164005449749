import React from "react";
import { Shimmer } from "src/components/common/atoms";
import { LoadingMobileHeader } from "../loadingMobileHeader";
import { LoadingTabs } from "../loadingTabs";

const LoadingAssignedToMeMobile = () => (
  <>
    <LoadingMobileHeader />
    <div className="mx-3">
      <div className="my-3">
        <Shimmer width="60%" />
      </div>
      <div className="bg-shimmer p-3 mb-3">
        <Shimmer width="40%" />
      </div>
      <div className="bg-shimmer p-3 mb-3">
        <Shimmer width="60%" />
        <div className="mt-3">
          <Shimmer width="100%" />
        </div>
      </div>
      <div className="bg-shimmer p-3 mb-3">
        <div className="d-flex justify-content-between">
          <Shimmer width="30%" />
          <Shimmer width="30%" />
        </div>
        <div className="mt-3">
          <Shimmer width="100%" />
        </div>
      </div>
      <div className="w-100">
        <LoadingTabs tabs={2} />
      </div>
    </div>
  </>
);

export default LoadingAssignedToMeMobile;
