import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const ADD_APPROVERS_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation addApprovers($ProgramId: ID!, $Approvers: [ApproverInput]!) {
    addApprovers(Approvers: $Approvers, ProgramId: $ProgramId) {
      ... on AddApproverResponse {
        message
      }
      ...errorResponseFragment
    }
  }
`;

export const useAddApprovers = () => {
  return useMutation(ADD_APPROVERS_MUTATION);
};
