import { t } from "i18next";
import React from "react";
import { Modal } from "react-bootstrap";
import { DisplayFormula } from "src/apps/incentives/components";
import { ConditionBreakdown, FormButton } from "src/components/common";
import "./styles.scss";
const AchievementDetailsModal = ({
  show,
  onHide,
  Name,
  condition,
  programId,
  userId,
  recurrenceId,
  TemplateId,
  allowDrillDown,
  onTemplateSelection,
  mobileProgramDetails,
  isUserPartOfGoal,
  goalValue,
  isFormula,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="achievement-details-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t("INCENTIVES.COMMON.LABELS.ACHIEVEMENT_DETAILS_FOR")}
          <span className="ml-1 fc-grey1">{Name}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bg-grey5 p-3">
          <DisplayFormula
            condition={condition}
            className="fc-black"
            variant="conditionList"
          />
        </div>
        <div className="my-3 px-3">
          <ConditionBreakdown
            condition={condition.Formula}
            programId={programId}
            userId={userId}
            recurrenceId={recurrenceId}
            onTemplateSelection={onTemplateSelection}
            allowDrillDown={allowDrillDown}
            TemplateId={TemplateId}
            mobileProgramDetails={mobileProgramDetails}
            isUserPartOfGoal={isUserPartOfGoal}
            goalValue={condition?.goalValue}
            isFormula={isFormula}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          label={t("COMMON.BUTTONS.CLOSE")}
          variant="outline-primary"
          onClick={onHide}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AchievementDetailsModal;
