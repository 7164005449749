import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const GET_LEADERBOARD_GROUP_LIST_QUERY = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderBoardGroupList($SelectedSchema: String!) {
    getLeaderBoardGroupList(SelectedSchema: $SelectedSchema) {
      ... on LeaderboardGroupListResponse {
        message
        success
        response
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;

export const useGetLeaderboardGroupList = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_GROUP_LIST_QUERY, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
