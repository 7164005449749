import { gql, useMutation } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

const UPDATE_LEADERBOARD_ATTRIBUTES_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation updateLeaderboard(
    $LeaderboardId: ID!
    $Type: LeaderboardTypeEnum
    $IsRollup: Boolean
    $Templates: [ID]
    $BufferDays: [Float]
    $SelectedGoals: [SelectedGoalInput]
    $RecurrenceDefinition: RecurrenceDefinition
    $RollupType: RollupTypeEnum
  ) {
    updateLeaderboard(
      LeaderboardId: $LeaderboardId
      Type: $Type
      IsRollup: $IsRollup
      Templates: $Templates
      BufferDays: $BufferDays
      SelectedGoals: $SelectedGoals
      RecurrenceDefinition: $RecurrenceDefinition
      RollupType: $RollupType
    ) {
      __typename
      ... on SingleLeaderboardResponse {
        success
        message
        response {
          Leaderboard {
            LeaderboardId
            RecurrenceDefinition {
              FirstRecurrenceStartDate
              LastRecurrenceEndDate
              RecurrencePeriod
            }
            BufferDays
            Type
            IsRollup
            SelectedTemplates {
              TemplateId
              Name
              Description
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;

const UPDATE_LEADERBOARD_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation updateLeaderboard(
    $Name: String
    $Description: String
    $LeaderboardId: ID!
  ) {
    updateLeaderboard(
      Name: $Name
      Description: $Description
      LeaderboardId: $LeaderboardId
    ) {
      __typename
      ... on SingleLeaderboardResponse {
        success
        message
        response {
          Leaderboard {
            LeaderboardId
            Name
            Description
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;
export const useUpdateLeaderboard = () => {
  return useMutation(UPDATE_LEADERBOARD_MUTATION);
};

export const useUpdateLeaderboardAttributes = () => {
  return useMutation(UPDATE_LEADERBOARD_ATTRIBUTES_MUTATION);
};

const UPDATE_LEADERBOARD_CRITERIA_MUTATION = gql`
  mutation addLeaderboardCriteria(
    $ProgramId: ID!
    $Criteria: [CriteriaInput]!
    $RecurrenceId: ID!
    $Type: String!
  ) {
    addLeaderboardCriteria(
      Criteria: $Criteria
      ProgramId: $ProgramId
      RecurrenceId: $RecurrenceId
      Type: $Type
    ) {
      ... on AddCriteriaResponse {
        message
      }
      ... on ErrorResponse {
        message
      }
    }
  }
`;

export const useUpdateLeaderboardCriteria = () => {
  return useMutation(UPDATE_LEADERBOARD_CRITERIA_MUTATION);
};

const UPDATE_LEADERBOARD_STATUS_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation updateLeaderboard($LeaderboardId: ID!, $Status: StatusEnum) {
    updateLeaderboard(LeaderboardId: $LeaderboardId, Status: $Status) {
      __typename
      ... on SingleLeaderboardResponse {
        success
        message
        response {
          Leaderboard {
            LeaderboardId
            Status
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;
export const useUpdateLeaderboardStatus = () => {
  return useMutation(UPDATE_LEADERBOARD_STATUS_MUTATION);
};

const UPDATE_LEADERBOARD_PROGRAM_FILE_MUTATION = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  mutation updateProgram(
    $ProgramId: ID!
    $Name: NotEmpty
    $Description: String
    $FileName: FileName
    $FileId: Float
  ) {
    updateProgram(
      ProgramId: $ProgramId
      Name: $Name
      Description: $Description
      FileName: $FileName
      FileId: $FileId
    ) {
      __typename
      ... on SingleProgramResponse {
        response {
          Program {
            ProgramId
            FileName
          }
          __typename
        }
        __typename
      }
      ...errorResponseFragment
    }
  }
`;

export const useUpdateProgramFile = () => {
  return useMutation(UPDATE_LEADERBOARD_PROGRAM_FILE_MUTATION);
};
