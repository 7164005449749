import { t } from "i18next";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useGetUserBaseData } from "src/api/useUser";
import {
  FormButton,
  SelectFilter,
  Shimmer,
  SlideDrawerModal,
} from "src/components/common";
import { COLORS } from "src/constants";
import { classNames, isEmpty, isEqual } from "src/helpers";
import { useIsOpenAceUser } from "src/hooks";
import { useUpdateUserPreferences } from "src/hooks/useUserPreferences";
import { SvgRefresh, SvgSave } from "src/icons";
import { UsersNotSelected } from "src/images";
import AddorEditCondition from "./AddorEditCondition";
import DisplayCondition from "./DisplayCondition";
import { ConditionPattern } from "./conditionPattern";
import { FILTERS } from "./data";
import useAdvanceConditions from "./hooks/useAdvanceFilters";
import {
  DeleteFilterModal,
  NameFilterModal,
  RenameFilterModal,
} from "./modals";
import "./styles.scss";
import {
  ValueContainer,
  formatOptionLabel,
  getFilteredUserFieldOptions,
  getUserFieldDisplayName,
  mapFilters,
} from "./utils";

const EmptyState = ({ handleAdd }) => {
  return (
    <div className="flex-column center align-items-center">
      <UsersNotSelected />
      <FormButton
        label={t("COMMON.LABELS.ADD_FILTER")}
        onClick={handleAdd}
        className="px-4 mx-2"
      />
    </div>
  );
};

const AdvancedFiltersModal = ({
  show,
  onHide,
  modalTitle,
  onApply,
  submitButtonText,
  setFilterConditions,
  descriptionText,
  supportMultipleFilters,
  currentFilter,
  userFieldOptions,
  showDrawer = false,
  readOnly,
  onSubmit,
  clearBtn,
  customUserAttributes = [],
}) => {
  const { updateGoalsConfig, userPreferences } = useUpdateUserPreferences();

  const savedFilters = JSON.parse(
    JSON.stringify(
      userPreferences?.goalsConfig?.PublishedGoalView?.AdvancedFilters ?? [
        currentFilter,
      ]
    )
  );
  const currentFilterFromSaved = savedFilters?.findIndex(
    (filter) => filter?.id === currentFilter?.id
  );

  if (currentFilterFromSaved < 0 && supportMultipleFilters && currentFilter) {
    //new Filter is added but not saved
    savedFilters?.splice(savedFilters?.length, 1, {
      ...currentFilter,
      FilterName: "",
      id: currentFilter?.id,
    });
  } else if (
    //Changes in already saved filter
    !isEqual(
      currentFilter?.FilterConditions,
      savedFilters?.[currentFilterFromSaved]?.FilterConditions
    ) ||
    !isEqual(
      currentFilter?.ConditionPattern,
      savedFilters?.[currentFilterFromSaved]?.ConditionPattern
    )
  ) {
    savedFilters?.splice(currentFilterFromSaved, 1, {
      ...currentFilter,
      id: currentFilter?.id,
    });
  }
  const filterList = isEmpty(savedFilters) ? [currentFilter] : savedFilters;

  const currentFilterIndex =
    savedFilters?.length && currentFilter?.FilterConditions?.length
      ? filterList?.findIndex(({ id }) => id === currentFilter?.id)
      : 0;

  const { isOpenAceUser } = useIsOpenAceUser();

  const { data: getUserBaseData, loading } = useGetUserBaseData({
    skip: isOpenAceUser,
  });

  const userBaseData = getUserBaseData?.getUserBaseData?.UserAttributes;

  let userFieldOptionsWithDisplayName = [];
  const displayNameSchemeNameMap = getUserFieldDisplayName([
    ...userFieldOptions,
    ...customUserAttributes,
  ]);
  if (!loading) {
    userFieldOptionsWithDisplayName = !isOpenAceUser
      ? getFilteredUserFieldOptions(displayNameSchemeNameMap, userBaseData)
      : structuredClone(userFieldOptions);

    if (customUserAttributes) {
      userFieldOptionsWithDisplayName.push(...customUserAttributes);
    }
  }

  const { state, actions } = useAdvanceConditions({
    showDrawer,
    filters: supportMultipleFilters
      ? !currentFilter
        ? [...filterList, { ...FILTERS[0], id: savedFilters?.length }]
        : filterList
      : [currentFilter],
    selectedFilterIndex: supportMultipleFilters
      ? !currentFilter
        ? savedFilters?.length
        : currentFilterIndex
      : 0,
    updateGoalsConfig,
    userPreferences,
  });

  const {
    filters,
    selectedFilterIndex,
    selectedConditionIndex,
    drawerOpen,
    disableAdd,
    filterRef,
  } = state;

  const currentFilterConditions =
    filters?.[selectedFilterIndex]?.FilterConditions;

  const disableFilterSave =
    !currentFilterConditions ||
    currentFilterConditions?.length < 1 ||
    (!filters?.[selectedFilterIndex]?.isFilterEdited &&
      filters?.[selectedFilterIndex]?.FilterName) ||
    drawerOpen;

  const { filterActions, conditionActions } = actions;

  const {
    updateFilter,
    renameFilter,
    deleteFilter,
    setSelectedFilterIndex,
    addFilter,
  } = filterActions;

  const {
    handleAddCondition,
    setConditionPattern,
    deleteCondition,
    setdrawerOpen,
    handleUpdateClick,
    handleAddClick,
    handleClose,
    handleUpdateCondition,
  } = conditionActions;

  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    index: -1,
  });
  const [showRenameModal, setShowRenameModal] = useState({
    show: false,
    index: -1,
  });

  const [showNameModal, setShowNameModal] = useState({
    show: false,
    index: -1,
  });

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      scrollable
      dialogClassName="advanced-filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3 h-75vh">
        <div className="flex w-100 justify-content-between center">
          <span className="fs-16">{descriptionText}</span>
          {supportMultipleFilters && savedFilters?.length ? (
            <div className="border-l pl-3 flex align-items-center fs-13 w-25">
              <span className="flex min-w-max">
                {t("COMMON.LABELS.SAVED_FILTERS")}
              </span>
              <SelectFilter
                className="ml-3 w-70"
                placeholder={t("COMMON.LABELS.SELECT_FILTER")}
                options={filters
                  .filter((Filters) => Filters?.FilterName)
                  .map((Filters, index) => ({
                    ...Filters,
                    label: Filters?.FilterName,
                    value: index,
                  }))}
                onChange={({ value }) => {
                  setSelectedFilterIndex({ index: value });
                }}
                value={mapFilters(
                  filters[selectedFilterIndex],
                  selectedFilterIndex
                )}
                formatOptionLabel={({ label, value }) =>
                  formatOptionLabel({
                    label,
                    setShowDeleteModal,
                    setShowRenameModal,
                    value,
                    filterRef,
                  })
                }
                components={{ ValueContainer }}
                customProps={{ supportMultipleFilters }}
                selectRef={filterRef}
              />
            </div>
          ) : null}
        </div>
        <div className="flex flex-column display-container-advanced-filter">
          <>
            {!currentFilterConditions?.length ? (
              <div className="flex-center h-100 display-condition-list-empty-state">
                <EmptyState handleAdd={() => handleAddClick({ index: 0 })} />
              </div>
            ) : (
              <DisplayCondition
                conditions={currentFilterConditions}
                handleAdd={handleAddClick}
                handleDelete={deleteCondition}
                handleUpdate={handleUpdateClick}
                selectedConditionIndex={selectedConditionIndex}
                setdrawerOpen={setdrawerOpen}
                disableAdd={disableAdd || readOnly}
                readOnly={readOnly}
                displayNameSchemeNameMap={displayNameSchemeNameMap}
              />
            )}
          </>
          {currentFilterConditions?.length ? (
            <div className="mt-auto">
              <ConditionPattern
                conditions={currentFilterConditions}
                conditionPattern={
                  currentFilterConditions?.length > 1 &&
                  !isEmpty(currentFilterConditions[1])
                    ? filters?.[selectedFilterIndex]?.ConditionPattern
                    : ""
                }
                setConditionPattern={(conditionPattern) =>
                  setConditionPattern({ conditionPattern })
                }
                criteriaIndex={selectedFilterIndex}
                disabled={readOnly}
                hideConditionPattern={currentFilterConditions?.length < 2}
              />
            </div>
          ) : null}
        </div>
        {drawerOpen && !readOnly ? (
          <SlideDrawerModal show={drawerOpen}>
            {!loading ? (
              <AddorEditCondition
                selectedCondition={
                  currentFilterConditions?.[selectedConditionIndex]
                }
                conditionIndex={selectedConditionIndex}
                setdrawerOpen={setdrawerOpen}
                userFieldOptions={userFieldOptions}
                handleAdd={handleAddCondition}
                handleUpdate={handleUpdateCondition}
                handleClose={handleClose}
                userFieldOptionsWithDisplayName={
                  userFieldOptionsWithDisplayName
                }
              />
            ) : (
              <Shimmer width="100%" height="100%" />
            )}
          </SlideDrawerModal>
        ) : null}
        {showDeleteModal.show && (
          <DeleteFilterModal
            selectedFilter={filters[showDeleteModal?.index]}
            show={showDeleteModal.show}
            onClose={() => setShowDeleteModal({ show: false, index: -1 })}
            handleDelete={deleteFilter}
            selectedIndex={showDeleteModal?.index}
          />
        )}
        {showRenameModal.show && (
          <RenameFilterModal
            show={showRenameModal.show}
            selectedFilter={filters[showRenameModal?.index]}
            onClose={() => setShowRenameModal({ show: false, index: -1 })}
            handleEdit={renameFilter}
            selectedIndex={showRenameModal?.index}
          />
        )}
        {showNameModal.show && (
          <NameFilterModal
            show={showNameModal.show}
            selectedFilter={filters[showNameModal?.index]}
            onClose={() => setShowNameModal({ show: false, index: -1 })}
            handleSave={addFilter}
          />
        )}
      </Modal.Body>
      <Modal.Footer
        className={classNames([
          supportMultipleFilters ? "flex justify-content-between" : "",
        ])}
      >
        {supportMultipleFilters && (
          <div className="center space-x-8">
            <FormButton
              label={t("COMMON.LABELS.SAVE_AS_QUICK_FILTER")}
              variant="link"
              icon={
                <SvgSave
                  color={
                    disableFilterSave ? COLORS.GREY_10 : COLORS.PRIMARY_BLUE
                  }
                />
              }
              onClick={() => {
                setShowNameModal({ show: true, index: selectedFilterIndex });
              }}
              disabled={disableFilterSave}
              className={classNames([disableFilterSave && "disableSaveFilter"])}
            />
            {filters[selectedFilterIndex]?.FilterName ? (
              <FormButton
                label={t("COMMON.LABELS.UPDATE_QUICK_FILTER")}
                variant="link"
                icon={
                  <SvgRefresh
                    color={
                      disableFilterSave ? COLORS.GREY_10 : COLORS.PRIMARY_BLUE
                    }
                    height="20"
                    width="20"
                    rotate={90}
                  />
                }
                className={classNames([
                  "border-l pl-3 p-1 ml-3",
                  disableFilterSave && "disableSaveFilter",
                ])}
                onClick={() => {
                  if (filters[selectedFilterIndex]?.FilterName)
                    updateFilter({ index: selectedFilterIndex });
                  else
                    setShowNameModal({
                      show: true,
                      index: selectedFilterIndex,
                    });
                }}
                disabled={disableFilterSave}
              />
            ) : null}
          </div>
        )}
        <div className="space-x-8">
          {clearBtn && (
            <FormButton
              label={t("COMMON.MESSAGES.CLEAR_FILTERS")}
              onClick={() => {
                if (setFilterConditions) setFilterConditions(null);
                onHide();
              }}
              disabled={currentFilter === null}
              className={classNames([
                "fc-blue h-40",
                currentFilter === null ? "disableSaveFilter" : "",
              ])}
              variant="link"
            />
          )}
          <FormButton
            label={
              readOnly ? t("COMMON.BUTTONS.CLOSE") : t("COMMON.BUTTONS.CANCEL")
            }
            onClick={onHide}
            variant="outline-primary"
          />
          {!readOnly ? (
            <FormButton
              label={submitButtonText}
              onClick={() => {
                setFilterConditions(filters?.[selectedFilterIndex]);
                if (onSubmit) onSubmit(filters?.[selectedFilterIndex]);
                onHide();
              }}
              disabled={drawerOpen || !currentFilterConditions?.length}
            />
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AdvancedFiltersModal;
