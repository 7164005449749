import PropTypes from "prop-types";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { FormButton, FormTooltip } from "src/components/common/formElements";
import { classNames } from "src/helpers";
import { ButtonShimmer } from "../buttonShimmer";
import "./confirmationModal.scss";

const ConfirmationModal = (props) => {
  const {
    show,
    onClose,
    dialogClassName,
    title,
    body,
    onConfirm,
    confirmButtonText,
    confirmButtonHide,
    confirmButtonDisabled,
    showConfirmButtonToolTip = true,
    cancelButton,
    illustrationsIcon,
    loading,
    shimmerText,
    customButtonLayout,
    addButtonsInfooter = false,
  } = props;
  const { t } = useTranslation();
  const buttonLayout = (
    <>
      {!confirmButtonHide &&
        (loading ? (
          <ButtonShimmer className="fs-13 mr-3" text={shimmerText} />
        ) : (
          <FormTooltip
            text={t(
              "SETTINGS.CUSTOM_REWARDS_CONFIG.MESSAGES.DELETION_NOT_ALLOWED_CURRENTLY"
            )}
            disable={showConfirmButtonToolTip ? !confirmButtonDisabled : true}
          >
            <FormButton
              id="primary-btn"
              className="mr-3"
              label={confirmButtonText}
              onClick={onConfirm}
              disabled={confirmButtonDisabled}
            />
          </FormTooltip>
        ))}
      {cancelButton && (
        <FormButton
          id="secondary-btn"
          label={t("COMMON.BUTTONS.CANCEL")}
          onClick={onClose}
          variant="outline-primary"
          disabled={loading}
        />
      )}
    </>
  );
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      className="confirmation-modal"
      backdropClassName="confirmation-modal-backdrop"
      dialogClassName={classNames([
        dialogClassName || (illustrationsIcon ? "confirmation-popup" : ""),
      ])}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-18">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-4">
        <div className="w-100 flex-between row mx-0 ">
          <div
            className={`${illustrationsIcon ? "col-6 px-0" : "col-12 px-0"}`}
          >
            <span className="fs-13">{body}</span>
            {!addButtonsInfooter && (
              <div className="mt-3 flex-start">
                {customButtonLayout || buttonLayout}
              </div>
            )}
          </div>
          {illustrationsIcon && (
            <div className="col-6 px-0 flex-center">{illustrationsIcon}</div>
          )}
        </div>
      </Modal.Body>
      {addButtonsInfooter && (
        <Modal.Footer>
          <div className="mt-3 flex-start">
            {customButtonLayout || buttonLayout}
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  dialogClassName: PropTypes.string,
  confirmButtonHide: PropTypes.bool,
  onConfirm: PropTypes.func,
  confirmButtonText: PropTypes.string,
  cancelButton: PropTypes.bool,
  illustrationsIcon: PropTypes.element,
};
ConfirmationModal.defaultProps = {
  loading: false,
  confirmButtonHide: false,
};

export default ConfirmationModal;
