import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { FormButton } from "src/components/common/formElements";
import { SvgAdmin, SvgEdit } from "src/icons";
import "./tagSelector.scss";

export const defaultTagSelectorOptions = [
  { label: "1 day", value: "1 day" },
  { label: "2 days", value: "2 days" },
  { label: "3 days", value: "3 days" },
  { label: "4 days", value: "4 days" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "13px",
    height: "40px",
  }),
};

const TagSelector = (props) => {
  const {
    icon,
    value,
    titleContainerChildren,
    modalTitle,
    modalOptions,
    modalSelectTitle,
    modalPlaceholder,
    modalBodyChildren,
    modalSelectProps,
    onClose,
    handleApply,
    applyButtonText,
    cancelButtonText,
  } = props;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const titleContent = (
    <div className="d-flex justify-content-start align-items-center">
      {icon && (
        <div className="tile-container__tile-icon d-flex align-items-center">
          {icon}
        </div>
      )}
      {value && (
        <div className="tile-container__tile-body ml-2 fs-12">{value}</div>
      )}
    </div>
  );

  return (
    <>
      <FormButton
        className="tiles-container"
        onClick={() => setShow(!show)}
        variant="link"
      >
        {titleContainerChildren || titleContent}
        <div className="tile-container__edit">
          <SvgEdit />
        </div>
      </FormButton>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="tag-selector-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <div className="fs-13 semi-bold">{modalSelectTitle}</div>
          <div className="d-flex justify-content-start align-items-center mt-3">
            <div className="custom-week-selector">
              <Select
                placeholder={modalPlaceholder}
                options={modalOptions}
                styles={customStyles}
                classNamePrefix="drop-select"
                {...modalSelectProps}
              />
            </div>
            {modalBodyChildren && modalBodyChildren}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <FormButton
            id="cancel-btn"
            variant="outline-primary"
            onClick={handleClose}
            label={cancelButtonText}
          />
          <FormButton
            id="apply-btn"
            onClick={handleApply}
            label={applyButtonText}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

TagSelector.propTypes = {
  icon: PropTypes.node,
  value: PropTypes.string,
  titleContainerChildren: PropTypes.node,
  modalTitle: PropTypes.string.isRequired,
  modalOptions: PropTypes.arrayOf({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  modalSelectTitle: PropTypes.string,
  modalPlaceholder: PropTypes.string,
  modalBodyChildren: PropTypes.node,
  modalSelectProps: PropTypes.object,
  onClose: PropTypes.func,
  handleApply: PropTypes.func.isRequired,
  applyButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

TagSelector.defaultProps = {
  icon: <SvgAdmin />,
  value: "Quarter",
  modalTitle: "Tag Selector",
  modalOptions: defaultTagSelectorOptions,
  modalSelectTitle: "Choose Option",
  modalPlaceholder: "Please Select",
  modalSelectProps: {},
  onClose: () => {},
  handleApply: () => {},
  cancelButtonText: t("COMMON.BUTTONS.CANCEL"),
  applyButtonText: "Apply",
};

export default TagSelector;
