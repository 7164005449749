import PropTypes from "prop-types";
import React, { useState } from "react";
import { default as ReactSelect } from "react-select";
import { FormCheck } from "src/components";
import "./style.scss";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "225px",
    paddingTop: "0px !important",
    fontSize: "12px",
    marginLeft: "-4px",
  }),
  menu: (provided) => ({
    ...provided,
    border: "1px solid #309afc",
    borderRadius: "4px !important",
    marginTop: "0px !important",
    zIndex: "1000",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0 4px 4px 0 !important",
    height: "45px",
    backgroundColor: state.isFocused ? "white" : "transparent",
    border: "none",
    width: "225px",
  }),
  singleValue: (provided) => ({
    ...provided,
    height: "20px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "black",
    cursor: "pointer",
    backgroundColor: state.isFocused ? "#f1fafe" : "",
    padding: "12px 20px",
  }),
  group: (provided) => ({
    ...provided,
    padding: "0px",
  }),
  groupHeading: (provided) => ({
    ...provided,
    fontSize: "13px",
    fontWeight: "600",
    textTransform: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};
const getFlatOptionList = (options) =>
  options.map((option) => option.options).flat();

const formatGroupLabel = (data) => data?.Category;

const formatOptionLabel = (option) => option?.Name;

const GroupMultiSelectDropdown = (props) => {
  const {
    allowSelectAll,
    allOption = {
      DisplayName: "All",
      ActivityEvent: "*",
    },
    includeSelectAll,
    options,
    onChange,
    getOptionLabel,
    getGroupLabel,
    value,
    components,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleChange = (selected, event) => {
    const flatOptionList = getFlatOptionList(options);
    if (
      event.action === "select-option" &&
      (selected[selected.length - 1].ActivityEvent ===
        allOption.ActivityEvent ||
        selected.length === flatOptionList.length)
    ) {
      return onChange([allOption, ...flatOptionList], event);
    }

    if (event.action === "deselect-option") {
      if (event.option.ActivityEvent === allOption.ActivityEvent) {
        return onChange([], event);
      }
      const result = selected.filter(
        (option) => option.ActivityEvent !== allOption.ActivityEvent
      );
      return onChange(result, event);
    }

    return onChange(selected, event);
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className="center custom-option p-2 ml-3">
      <FormCheck
        checked={value.some(
          (option) =>
            option.ActivityEvent === data.ActivityEvent ||
            option.ActivityEvent === allOption.ActivityEvent
        )}
        className="check-box"
      />
      <span>{label}</span>
    </div>
  );
  const Components = { Option: CustomOption, ...components };

  if (allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        value={
          value.some(
            (option) => option.ActivityEvent === allOption.ActivityEvent
          )
            ? [allOption, ...getFlatOptionList(options)]
            : value
        }
        options={includeSelectAll ? [allOption, ...options] : options}
        styles={{ ...customStyles, ...props.styles }}
        onChange={handleChange}
        getOptionLabel={getOptionLabel || formatOptionLabel}
        formatGroupLabel={getGroupLabel || formatGroupLabel}
        components={Components}
        isMenuOpen={isMenuOpen}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuOpen}
      />
    );
  }

  return <ReactSelect {...props} />;
};

GroupMultiSelectDropdown.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    DisplayName: PropTypes.string,
    ActivityEvent: PropTypes.string,
  }),
  includeSelectAll: PropTypes.bool,
};

GroupMultiSelectDropdown.defaultProps = {
  includeSelectAll: true,
};

export default GroupMultiSelectDropdown;
