import { gql, useMutation } from "src/api/client";

const COPY_USERS_TO_OTHER_DISTRIBUTION_MUTATION = gql`
  mutation copyIncentiveUserToOtherCycles(
    $SourceRecurrenceId: ID!
    $DestinationRecurrenceIds: [ID]!
  ) {
    copyIncentiveUserToOtherCycles(
      SourceRecurrenceId: $SourceRecurrenceId
      DestinationRecurrenceIds: $DestinationRecurrenceIds
    ) {
      ... on UserSelectionResponse {
        success
        message
        response
      }
      __typename
    }
  }
`;

export const useCopyUserstoOtherCycles = () => {
  return useMutation(COPY_USERS_TO_OTHER_DISTRIBUTION_MUTATION);
};
