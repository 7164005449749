import { t } from "i18next";
import { FormTooltip } from "src/components/common/formElements";
import { GOAL_AGGREGATION_TYPES } from "src/constants";
import { NumFormatter, getColor, getUserTimezone } from "src/helpers";
import { SvgUser, SvgUserUnavailable } from "src/icons";

const IsUserPresent = ({ recurrence }) => {
  return (
    recurrence?.IsUserPresent === false && (
      <div className="fc-grey center">
        <FormTooltip
          id="my-goal-view-tooltip"
          placement="bottom"
          text={t("MANAGED_GOALS.MESSAGES.NOT_PART_OF_CYCLE")}
          disable={recurrence?.IsUserPresent}
        >
          <SvgUserUnavailable height="18" width="18" />
        </FormTooltip>
      </div>
    )
  );
};

export const formatOptionLabelAfter = ({
  recurrence,
  goalAggConfig,
  isAssignedToMeView,
}) => {
  const isIndianTimezone = getUserTimezone();
  const {
    recurrenceStatus,
    GoalAchieved,
    GoalDistribution,
    GoalUsersCount,
    UsersAchievedCount,
    CurrentUserDistribution,
  } = recurrence;
  const RecurrenceGoalValue = isAssignedToMeView
    ? CurrentUserDistribution
    : GoalDistribution;
  const cardView = isAssignedToMeView ? "ASSIGNED_TO_ME" : "MANAGED_BY_ME";

  if (cardView === "ASSIGNED_TO_ME" && !recurrence?.IsUserPresent)
    return <IsUserPresent {...{ recurrence }} />;

  switch (recurrenceStatus) {
    case "YETTOSTART": {
      return null;
    }
    case "INPROGRESS":
    case "COMPLETED": {
      switch (goalAggConfig) {
        case GOAL_AGGREGATION_TYPES.TARGET_BASED: {
          if (RecurrenceGoalValue === null || RecurrenceGoalValue === 0)
            return <span className="fc-grey">- -</span>;
          const percentage = (
            ((GoalAchieved || 0) / RecurrenceGoalValue) *
            100
          ).toFixed(2);
          return (
            <>
              <span style={{ color: getColor(percentage) }}>
                {NumFormatter(percentage, isIndianTimezone)}%
              </span>
            </>
          );
        }
        case GOAL_AGGREGATION_TYPES.MAINTAIN_GREATER_THAN_EQUAL:
        case GOAL_AGGREGATION_TYPES.MAINTAIN_LESS_THAN_EQUAL:
        case GOAL_AGGREGATION_TYPES.AVG_GREATER_THAN_EQUAL:
        case GOAL_AGGREGATION_TYPES.AVG_LESS_THAN_EQUAL: {
          switch (cardView) {
            case "ASSIGNED_TO_ME": {
              if (RecurrenceGoalValue === null || RecurrenceGoalValue === 0) {
                return <span className="fc-grey">- -</span>;
              }

              return (
                <span className="fc-black">
                  {NumFormatter(GoalAchieved, isIndianTimezone)}
                </span>
              );
            }
            case "MANAGED_BY_ME": {
              if (UsersAchievedCount === null || GoalUsersCount === 0) {
                return <span className="fc-grey">- -</span>;
              }
              const percentage =
                GoalUsersCount === null ||
                GoalUsersCount === 0 ||
                UsersAchievedCount === null
                  ? 0
                  : (
                      ((UsersAchievedCount || 0) / GoalUsersCount) *
                      100
                    ).toFixed(2);
              return (
                <span className="flex-center">
                  <>
                    <SvgUser width="16" height="16" />
                    <span className="flex-center ml-1 pl-1 fs-16 fc-grey2 semi-bold">
                      <span
                        className="fc-black"
                        style={{ color: getColor(percentage) }}
                      >
                        {NumFormatter(UsersAchievedCount, isIndianTimezone)}
                      </span>
                      <span>/</span>
                      <span>
                        {NumFormatter(GoalUsersCount, isIndianTimezone)}
                      </span>
                    </span>
                  </>
                </span>
              );
            }
            default: {
              return null;
            }
          }
        }

        default: {
          return null;
        }
      }
    }
    default: {
      return null;
    }
  }
};
