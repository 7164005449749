import { formulaValidator as validator } from ".";
import formulaNamespace from "./formulaNamespace";

const {
  TOKEN_TYPE,
  FORMULA_OPERATORS,
  FORMULA_PARENTHESES,
  FORMULA_PARENTHESES_TYPE,
} = formulaNamespace;

const orderOperators = (operators, nextToken) => {
  if (operators.length === 0) {
    return false;
  }
  const lastOperator = operators[operators.length - 1];
  return (
    FORMULA_OPERATORS[lastOperator.value]?.precedence >=
    FORMULA_OPERATORS[nextToken.value]?.precedence
  );
};

const operate = ({ left, operator, right }) => {
  switch (operator) {
    case "+":
      return left + right;
    case "-":
      return left - right;
    case "*":
      return left * right;
    case "/":
      return left / right;
    default:
      break;
  }
};

export const toPostfix = (tokens) => {
  const operators = [];
  const output = [];

  for (let scanner = 0; scanner < tokens.length; scanner++) {
    const currentToken = tokens[scanner];

    if (
      currentToken.type === TOKEN_TYPE.CONSTANT ||
      currentToken.type === TOKEN_TYPE.VARIABLE
    ) {
      output.push(currentToken);
      continue;
    }

    if (currentToken.type === TOKEN_TYPE.OPERATOR) {
      while (orderOperators(operators, currentToken)) {
        output.push(operators.pop());
      }
      operators.push(currentToken);
      continue;
    }

    if (
      currentToken.value ===
      FORMULA_PARENTHESES[FORMULA_PARENTHESES_TYPE.OPEN_PAREN].value
    ) {
      operators.push(currentToken);
      continue;
    }

    if (
      currentToken.value ===
      FORMULA_PARENTHESES[FORMULA_PARENTHESES_TYPE.CLOSE_PAREN].value
    ) {
      while (
        operators[operators.length - 1].value !==
        FORMULA_PARENTHESES[FORMULA_PARENTHESES_TYPE.OPEN_PAREN].value
      ) {
        output.push(operators.pop());
      }
      operators.pop();
      continue;
    }
  }
  output.push(...operators.reverse());
  return output;
};

const isOperable = ({ left, operator, right }) => {
  const tokenError =
    validator.validateToken({ lastToken: left, nextToken: operator }) ||
    validator.validateToken({ lastToken: operator, nextToken: right });

  if (tokenError) {
    tokenError.message = `Evaluation Error: ${tokenError.message}`;
    return tokenError;
  }
  return false;
};

const assignIfVariable = (token, weights = []) => {
  if (token.type === TOKEN_TYPE.VARIABLE && typeof token.value === "string") {
    const variableWeightIdx = weights.indexOf(token.value);
    if (variableWeightIdx === -1) {
      weights.push(token.value);
      token.value = weights.length;
    } else {
      token.value = variableWeightIdx + 1;
    }
  }
  return token;
};

export const evaluatePostfix = ({ tokens, handleOperation = operate }) => {
  const stack = [];
  const weights = [];
  for (let scanner = 0; scanner < tokens.length; scanner++) {
    const currentToken = tokens[scanner];

    if (currentToken.type === TOKEN_TYPE.OPERATOR) {
      let right = stack.pop();
      let left = stack.pop();
      left = assignIfVariable(left, weights);
      right = assignIfVariable(right, weights);
      if (
        left.type === TOKEN_TYPE.VARIABLE &&
        right.type === TOKEN_TYPE.CONSTANT &&
        left.value === right.value
      ) {
        left.value = right.value * (left.value + 1);
      }
      if (
        right.type === TOKEN_TYPE.CONSTANT &&
        left.type === TOKEN_TYPE.VARIABLE &&
        right.value === left.value
      ) {
        right.value = left.value * (right.value + 1);
      }
      const error = isOperable({
        left: { ...left, type: TOKEN_TYPE.CONSTANT },
        operator: currentToken,
        right: { ...right, type: TOKEN_TYPE.CONSTANT },
      });
      if (error) {
        return error;
      }
      const result = {
        type: TOKEN_TYPE.CONSTANT,
        value: handleOperation({
          left: left.value,
          operator: currentToken.value,
          right: right.value,
        }),
      };
      if (
        left.type === TOKEN_TYPE.VARIABLE ||
        right.type === TOKEN_TYPE.VARIABLE
      ) {
        result.type = TOKEN_TYPE.VARIABLE;
        if (left.value !== right.value) {
          result.value = `${left.value}${currentToken.value}${right.value}`;
        }
      }
      stack.push(result);
      continue;
    }

    stack.push(currentToken);
  }
  return false;
};

const formulaEvaluator = {
  toPostfix,
  evaluatePostfix,
};

export default formulaEvaluator;
