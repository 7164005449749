import { classNames } from "src/helpers";
import { SvgInfo } from "src/icons";
import "./note.scss";

const Note = ({ className, children }) => {
  return (
    <div className={classNames(["note", className])}>
      <SvgInfo className="note-icon" />
      <div className="center">{children}</div>
    </div>
  );
};

export default Note;
