const SvgCurvedLine = (props) => {
  const { color, height, width, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 13 66"
      fill="none"
    >
      <path
        d="M1 0L0.999999 58C0.999998 61.866 4.13401 65 8 65L13 65"
        stroke={color}
      />
    </svg>
  );
};
SvgCurvedLine.defaultProps = {
  color: "#C7D5E3",
  height: "66",
  width: "13",
};
export default SvgCurvedLine;
