import React from "react";
import { classNames } from "src/helpers";
import { BronzeStrip, GenericStrip, GoldStrip, SilverStrip } from "src/images";
import { HeroBackground } from "../heroBackground";
import { LeaderboardBadge } from "../leaderboardBadge";
import "./leaderboardGridCard.scss";

const getBackgroundImage = (position) => {
  switch (position) {
    case 1:
      return GoldStrip;
    case 2:
      return SilverStrip;
    case 3:
      return BronzeStrip;
    default:
      return GenericStrip;
  }
};

const LeaderboardGridCard = (props) => {
  const { position, recurrencePeriod, trophy, myRank, className, children } =
    props;

  if (trophy) {
    return (
      <HeroBackground
        className={classNames([
          "ml-3 leaderboard-grid-trophy-card br-8",
          myRank ? "my-rank-border" : "",
          className,
        ])}
        image={getBackgroundImage(position)}
      >
        <div className="leaderboard-grid-trophy-card-badge">
          <LeaderboardBadge {...{ position, recurrencePeriod }} />
        </div>
        <div className="leaderboard-grid-trophy-card-body">{children}</div>
        {myRank && (
          <div className="my-rank-label semi-bold flex-center">MY RANK</div>
        )}
      </HeroBackground>
    );
  }

  return (
    <div
      className={classNames([
        "leaderboard-grid-card br-8",
        myRank ? "my-rank-border" : "",
        className,
      ])}
    >
      <div className="pl-3 leaderboard-grid-card-body w-100">{children}</div>
      {myRank && (
        <div className="my-rank-label semi-bold flex-center">MY RANK</div>
      )}
    </div>
  );
};

export default LeaderboardGridCard;
