import { useEffect, useMemo } from "react";
import { useGetUserMetricAchievements } from "src/api/useIncentiveDashboard";
import { useGetUserDetails } from "src/api/useUser";
import { FIXED_USER_ATTRIBUTES } from "src/config/userAttributes";
import { GOAL_ACHIEVEMENT_TYPE } from "src/constants";
import { getFullName } from "src/helpers";
const useMyIncentivesAB = ({
  User,
  programId,
  Recurrence,
  templateId,
  setGoalUser,
  isSalesUser,
  isIncentiveApprover,
}) => {
  const isSalesUserAndApprover = isIncentiveApprover && isSalesUser;
  const { metric, isFormula } = User;

  const goalAchievementType =
    !isFormula && metric?.fieldName === "GoalId"
      ? Object.keys(GOAL_ACHIEVEMENT_TYPE)[1]
      : metric?.GoalAchievementType;

  const { data, loading, error } = useGetUserMetricAchievements({
    variables: {
      Value: metric?.value,
      FieldName: metric?.fieldName,
      ProgramId: programId,
      RecurrenceId: Recurrence?.RecurrenceId,
      UserId: User?.UserID,
      Type: metric?.type,
      GoalAchievementType: goalAchievementType,
      ReporteesIncentiveValue: metric?.ReporteesIncentiveValue,
      SubGoal: {
        ConditionPattern: metric?.ConditionPattern,
        SubGoalConditions: metric?.SubGoalConditions,
        Id: metric?.Id,
      },
      Operand: metric?.tokenType,
    },
    skip: !Recurrence?.RecurrenceId || !User?.UserID || !programId,
    notifyOnNetworkStatusChange: true,
  });

  const { loading: usersDetailsLoading, data: usersDetailsData } =
    useGetUserDetails({
      variables: {
        UserIds: [User?.UserID],
        userAttributesNames: FIXED_USER_ATTRIBUTES,
      },
      notifyOnNetworkStatusChange: true,
      skip: !User?.UserID || isSalesUser || isSalesUserAndApprover,
    });
  const userDetails = usersDetailsData?.getUsersDetails[0]?.node;

  const achievedValue =
    data?.userMetricAchievements?.response?.userMetricAchievement;

  const usersListWithAchievements = useMemo(
    () => [
      {
        FullName: isSalesUserAndApprover
          ? User?.userName
          : getFullName(userDetails?.FirstName, userDetails?.LastName),
        UserID: User?.UserID,
        Achievement: achievedValue,
        Type: User?.Type,
        isFormula: User?.isFormula,
        metric: User?.metric,
      },
    ],
    [
      User?.Type,
      User?.UserID,
      User?.isFormula,
      User?.metric,
      User?.userName,
      achievedValue,
      isSalesUserAndApprover,
      userDetails?.FirstName,
      userDetails?.LastName,
    ]
  );
  useEffect(() => {
    if (usersListWithAchievements?.length)
      setGoalUser(usersListWithAchievements[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Recurrence.RecurrenceId, usersListWithAchievements]);

  return {
    usersListWithAchievements,
    usersListWithAchievementsLoading: usersDetailsLoading || loading,
    usersListWithAchievementsError: error,
  };
};
export default useMyIncentivesAB;
