import { t } from "i18next";
import React, { useState } from "react";
import { useGetUserAttributes } from "src/api/useUser";
import { Avatar, DotBadge } from "src/components/base";
import { AdvancedFiltersModal } from "src/components/container";
import { GOALS_CUSTOM_USER_FIELDS } from "src/components/container/advancedFiltersModal/data";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { SvgFilterModern } from "src/icons";
import { FormButton } from "../../formElements";

const AdvancedFilters = ({ SelectedFilter, className, setSelectedFilter }) => {
  const [showFilter, setShowFilter] = useState(false);
  const { data } = useGetUserAttributes();
  return (
    <div className={classNames([className])}>
      <FormButton
        variant="link"
        onClick={() => setShowFilter(true)}
        className="border-1 py-2 px-2 bc-primary-blue"
      >
        <Avatar
          src={<SvgFilterModern color={COLORS.PRIMARY_BLUE} />}
          badgeProps={{
            showBadge: SelectedFilter?.FilterConditions?.length > 0,
            badge: (
              <DotBadge
                className={classNames(["mr-1 mb-1"])}
                outlineProps={{
                  outlineColor: "#f7f8fa",
                  outlineWidth: "2px",
                  outline: true,
                }}
              />
            ),
            position: "bottom-end",
          }}
        />
      </FormButton>
      {showFilter && (
        <AdvancedFiltersModal
          show={showFilter}
          onHide={() => setShowFilter(false)}
          modalTitle={t("COMMON.LABELS.ADVANCED_FILTERS")}
          submitButtonText={t("COMMON.BUTTONS.APPLY_FILTERS")}
          setFilterConditions={setSelectedFilter}
          descriptionText={t("COMMON.MESSAGES.ADVANCED_FILTERS_DESCRIPTION")}
          supportMultipleFilters
          currentFilter={SelectedFilter}
          userFieldOptions={data?.getUserAttributesData?.UserAttributes}
          clearBtn
          customUserAttributes={GOALS_CUSTOM_USER_FIELDS}
        />
      )}
    </div>
  );
};

export default AdvancedFilters;
