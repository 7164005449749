import { useReducer } from "react";
import {
  ruleReducer,
  RULES_REDUCER_ACTIONS,
} from "../pages/leaderboardCrud/web/selectRewardsAndTargets/useRules/rulesReducer";

export const RULES = {
  winners: 3,
  groupTargets: [],
  leaderboardTarget: null,
  tieBreakers: [],
  ranks: [],
};
const DEFAULT_STATE = {
  recurrenceId: "",
  isEdited: false,
  selectedRankIndex: 0,
  minTarget: null,
  mandatoryTarget: false,
  selectedRecurrence: null,
  ...RULES,
};

const useRules = (initialState) => {
  const [rules, dispatch] = useReducer(ruleReducer, {
    ...DEFAULT_STATE,
    ...initialState,
  });
  const selectedRecurrenceActions = {
    /**
     * Updates recurrenceId.
     */
    edit: function editRecurrence(recurrence) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.EDIT_RECURRENCE,
        payload: recurrence,
      });
    },
  };

  const winnerActions = {
    /**
     * Updates winner.
     */
    edit: function editWinner(winners) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.EDIT_WINNERS,
        payload: winners,
      });
    },
  };
  const mandatoryTargetActions = {
    /**
     * Updates min target value.
     */
    edit: function editMandatoryTarget(mandatoryTarget) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.EDIT_MANDATORY_TARGET,
        payload: mandatoryTarget,
      });
    },
  };

  const minTargetActions = {
    /**
     * Updates min target value.
     */
    edit: function editMinTarget(minTarget) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.EDIT_MIN_TARGET,
        payload: minTarget,
      });
    },
  };

  const targetActions = {
    editGroupTargets: function editGroupTargets(groupsTargets) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.EDIT_GROUP_TARGETS,
        payload: groupsTargets,
      });
    },
    editLeaderboardTarget: function editTarget(leaderboardTarget) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.EDIT_LEADERBOARD_TARGET,
        payload: leaderboardTarget,
      });
    },
    /**
     * Copies target configuration from one group to others.
     * @param {String} fromGroupId - group UUID
     * @param {Array.<String>} toGroupIds - list of group UIID
     */
    copy: function copyTarget(fromGroupId, toGroupIds) {},
    /**
     * Resets current group's configuration.
     * @param {String} groupId - group UUID
     */
    reset: function resetTargetById(groupId) {},
    /**
     * Deletes group target configuration.
     */
    deleteGroupTargets: function deleteGroupTargets() {
      dispatch({
        type: RULES_REDUCER_ACTIONS.DELETE_GROUP_TARGETS,
      });
    },
    deleteLeaderboardTarget: function deleteLeaderboardTarget() {
      dispatch({
        type: RULES_REDUCER_ACTIONS.DELETE_LEADERBOARD_TARGET,
      });
    },
  };

  const tieBreakerActions = {
    /**
     * Edits a tie breaker.
     */
    edit: function editTieBreaker(tieBreakers) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.EDIT_TIE_BREAKERS,
        payload: tieBreakers,
      });
    },
    /**
     * Deletes a tie breaker.
     */
    delete: function deleteTieBreaker() {
      dispatch({
        type: RULES_REDUCER_ACTIONS.DELETE_TIE_BREAKERS,
      });
    },
  };

  const rankActions = {
    /**
     * Creates new rank.
     */
    create: function createRank(payload) {
      dispatch({ type: RULES_REDUCER_ACTIONS.CREATE_RANK, payload });
    },
    setRanks: function setRanks(payload) {
      dispatch({ type: RULES_REDUCER_ACTIONS.SET_RANKS, payload });
    },
    setIndex: function setSelectedRankIndex(rankIndex) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.SET_SELECTED_RANK_INDEX,
        payload: rankIndex,
      });
    },
    /**
     * Retrieves a rank by index.
     * @param {Number} rankIndex
     */
    get: function getRank(rankIndex) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.GET_RANK,
        payload: rankIndex,
      });
    },
    /**
     * Retrieves all ranks.
     */
    getAll: function getAllRanks() {
      dispatch({
        type: RULES_REDUCER_ACTIONS.GET_ALL_RANKS,
      });
    },
    /**
     * Updates rank by index.
     * @param {Number} rankIndex
     */
    edit: function editRank(index, rankInfo) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.EDIT_RANK,
        payload: { index, rankInfo },
      });
    },
    /**
     * Deletes a rank by index.
     * @param {Number} rankIndex
     */
    delete: function deleteRank(rankIndex) {
      dispatch({
        type: RULES_REDUCER_ACTIONS.DELETE_RANK,
        payload: rankIndex,
      });
    },
  };

  return {
    ...rules,
    actions: {
      selectedRecurrenceActions,
      minTarget: minTargetActions,
      mandatoryTarget: mandatoryTargetActions,
      winners: winnerActions,
      targets: targetActions,
      tieBreakers: tieBreakerActions,
      ranks: rankActions,
    },
  };
};

export default useRules;
