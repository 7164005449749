import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgAggregate = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={color}
        d="M14.927 16H1a1 1 0 0 1-1-1V1a1 1 0 1 1 2 0v1h2v-.5a1.5 1.5 0 0 1 3 0V2h7V1a1 1 0 1 1 2 0v14a1 1 0 0 1-1 1ZM14 14v-3h-2v.5a1.5 1.5 0 0 1-3 0V11H7v.5a1.5 1.5 0 0 1-3 0V11H2v3Zm0-5V4H7v.5a1.5 1.5 0 1 1-3 0V4H2v5h2v-.5a1.5 1.5 0 0 1 3 0V9h2v-.5a1.5 1.5 0 0 1 3 0V9Z"
      />
    </svg>
  );
};

SvgAggregate.defaultProps = {
  color: COLORS.GREY_1,
  height: 16,
  width: 16,
};
export default SvgAggregate;
