import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import FilterAccordionItem from "./FilterAccordionItem";

const FilterAccordion = ({ options, filter, handleOnChange, children }) => {
  const [activeKey, setActiveKey] = useState(0);

  return (
    <Accordion defaultActiveKey={String(activeKey)}>
      {options.map((option, index) => (
        <FilterAccordionItem
          option={option}
          active={activeKey === index}
          eventKey={index}
          filter={filter[option.key]}
          handleOnChange={handleOnChange}
          handleClick={(idx) =>
            setActiveKey((prev) => (prev === idx ? null : idx))
          }
          children={children}
        />
      ))}
    </Accordion>
  );
};

export default FilterAccordion;
