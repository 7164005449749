import { gql, useMutation } from "src/api/client";

const PAYOUT_FILE_UPLOAD_URL_MUTATION = gql`
  mutation payoutFileUploadUrl($programId: ID!) {
    payoutFileUploadUrl(programId: $programId) {
      ... on PayoutFileUploadUrlResponse {
        success
        message
        response {
          url
          fileId
        }
      }
    }
  }
`;

export const usePayoutFileUploadUrl = () => {
  return useMutation(PAYOUT_FILE_UPLOAD_URL_MUTATION);
};
