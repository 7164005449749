import { useEffect } from "react";
import { useTransition, TRANSITIONS } from "./useTransition";

export function Transition({ in: inn, nodeRef, children, timeout }) {
  const transition = useTransition({ in: inn, timeout });

  useEffect(() => {
    if (nodeRef.current) {
      if (transition.state === TRANSITIONS.ENTER) {
        transition.handleEnter(nodeRef.current);
      }
      if (transition.state === TRANSITIONS.EXIT) {
        transition.handleExit(nodeRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transition.state]);

  return transition.state ? children : null;
}
