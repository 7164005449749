import { generateRandomKey } from "./utils";

export const FILTER_DATA_TYPES = {
  STRING: "STRING",
  NUMBER: "NUMBER",
  DATE: "DATE",
  BOOLEAN: "BOOLEAN",
};

export const OPERATORS = {
  IS: "IS",
  IS_NOT: "IS_NOT",
  CONTAINS: "CONTAINS",
  DOES_NOT_CONTAIN: "DOES_NOT_CONTAIN",
  STARTS_WITH: "STARTS_WITH",
  ENDS_WITH: "ENDS_WITH",
  IS_EMPTY: "IS_EMPTY",
  IS_NOT_EMPTY: "IS_NOT_EMPTY",
  TRUE: "TRUE",
  FALSE: "FALSE",
  IS_EQUAL_TO: "IS_EQUAL_TO",
  IS_NOT_EQUAL_TO: "IS_NOT_EQUAL_TO",
  GREATER_THAN_EQUALS: "GREATER_THAN_EQUALS",
  GREATER_THAN: "GREATER_THAN",
  LESS_THAN: "LESS_THAN",
  LESS_THAN_EQUALS: "LESS_THAN_EQUALS",
  BETWEEN: "BETWEEN",
  AFTER: "AFTER",
  BEFORE: "BEFORE",
};

export const SUB_OPERATORS = {
  ALL_TIME: "ALL_TIME",
  YESTERDAY: "YESTERDAY",
  TODAY: "TODAY",
  TOMORROW: "TOMORROW",
  LAST_WEEK: "LAST_WEEK",
  THIS_WEEK: "THIS_WEEK",
  NEXT_WEEK: "NEXT_WEEK",
  LAST_MONTH: "LAST_MONTH",
  THIS_MONTH: "THIS_MONTH",
  NEXT_MONTH: "NEXT_MONTH",
  LAST_YEAR: "LAST_YEAR",
  THIS_YEAR: "THIS_YEAR",
  NEXT_YEAR: "NEXT_YEAR",
  THIS_CYCLE: "THIS_CYCLE",
  N_DAYS: "N_DAYS",
  WITHIN_LAST_N_DAYS: "WITHIN_LAST_N_DAYS",
  WITHIN_NEXT_N_DAYS: "WITHIN_NEXT_N_DAYS",
  CUSTOM_DATE: "CUSTOM_DATE",
  CUSTOM_DATE_RANGE: "CUSTOM_DATE_RANGE",
  EQUAL_TO_CYCLE_START_DATE: "EQUAL_TO_CYCLE_START_DATE",
  LESS_THAN_CYCLE_START_DATE: "LESS_THAN_CYCLE_START_DATE",
  LESS_THAN_OR_EQUAL_TO_CYCLE_START_DATE:
    "LESS_THAN_OR_EQUAL_TO_CYCLE_START_DATE",
  GREATER_THAN_CYCLE_START_DATE: "GREATER_THAN_CYCLE_START_DATE",
  GREATER_THAN_OR_EQUAL_TO_CYCLE_START_DATE:
    "GREATER_THAN_OR_EQUAL_TO_CYCLE_START_DATE",
  EQUAL_TO_CYCLE_END_DATE: "EQUAL_TO_CYCLE_END_DATE",
  LESS_THAN_CYCLE_END_DATE: "LESS_THAN_CYCLE_END_DATE",
  LESS_THAN_OR_EQUAL_TO_CYCLE_END_DATE: "LESS_THAN_OR_EQUAL_TO_CYCLE_END_DATE",
  GREATER_THAN_CYCLE_END_DATE: "GREATER_THAN_CYCLE_END_DATE",
  GREATER_THAN_OR_EQUAL_TO_CYCLE_END_DATE:
    "GREATER_THAN_OR_EQUAL_TO_CYCLE_END_DATE",
};

export const FIELDS = {
  TEXT_BOX: "TextBox",
  DYNAMIC_DROP_DOWN: "DynamicDropDown",
  NUMBER_BOX: "NumberBox",
  NUMBER_BOX_RANGE: "NumberBoxRange",
  SEARCHABLE_DROPDOWN: "SearchableDropdown",
  DATE_PICKER: "DatePicker",
  DATE_RANGE_PICKER: "DateRangePicker",
};

export const DYNAMIC_DROP_DOWN_FIELDS = [
  "City",
  "State",
  "Country",
  "SalesRegions",
  "Department",
  "Team",
  "Designation",
  "Groups",
  "Role",
  "TimeZone",
];

export const FILTERS_MODEL = {
  [FILTER_DATA_TYPES.STRING]: {
    [OPERATORS.IS]: {
      label: "is",
      value: OPERATORS.IS,
      field: (attribute) =>
        DYNAMIC_DROP_DOWN_FIELDS.includes(attribute)
          ? FIELDS.DYNAMIC_DROP_DOWN
          : FIELDS.TEXT_BOX,
      options: null,
    },
    [OPERATORS.IS_NOT]: {
      label: "is not",
      value: OPERATORS.IS_NOT,
      field: (attribute) =>
        DYNAMIC_DROP_DOWN_FIELDS.includes(attribute)
          ? FIELDS.DYNAMIC_DROP_DOWN
          : FIELDS.TEXT_BOX,
      options: null,
    },
    [OPERATORS.CONTAINS]: {
      label: "contains",
      value: OPERATORS.CONTAINS,
      field: FIELDS.TEXT_BOX,
      options: null,
    },
    [OPERATORS.DOES_NOT_CONTAIN]: {
      label: "does not contain",
      value: OPERATORS.DOES_NOT_CONTAIN,
      field: FIELDS.TEXT_BOX,
      options: null,
    },
    [OPERATORS.STARTS_WITH]: {
      label: "starts with",
      value: OPERATORS.STARTS_WITH,
      field: FIELDS.TEXT_BOX,
      options: null,
    },
    [OPERATORS.ENDS_WITH]: {
      label: "ends with",
      value: OPERATORS.ENDS_WITH,
      field: FIELDS.TEXT_BOX,
      options: null,
    },
    [OPERATORS.IS_EMPTY]: {
      label: "is empty",
      value: OPERATORS.IS_EMPTY,
      field: null,
      options: null,
    },
    [OPERATORS.IS_NOT_EMPTY]: {
      label: "is not empty",
      value: OPERATORS.IS_NOT_EMPTY,
      field: null,
      options: null,
    },
  },
  [FILTER_DATA_TYPES.NUMBER]: {
    [OPERATORS.IS_EQUAL_TO]: {
      label: "is equal to",
      value: OPERATORS.IS_EQUAL_TO,
      field: FIELDS.NUMBER_BOX,
      options: null,
    },
    [OPERATORS.IS_NOT_EQUAL_TO]: {
      label: "is not equal to",
      value: OPERATORS.IS_NOT_EQUAL_TO,
      field: FIELDS.NUMBER_BOX,
      options: null,
    },
    [OPERATORS.GREATER_THAN_EQUALS]: {
      label: "greater than or equal to",
      value: OPERATORS.GREATER_THAN_EQUALS,
      field: FIELDS.NUMBER_BOX,
      options: null,
    },
    [OPERATORS.GREATER_THAN]: {
      label: "greater than",
      value: OPERATORS.GREATER_THAN,
      field: FIELDS.NUMBER_BOX,
      options: null,
    },
    [OPERATORS.LESS_THAN]: {
      label: "less than",
      value: OPERATORS.LESS_THAN,
      field: FIELDS.NUMBER_BOX,
      options: null,
    },
    [OPERATORS.LESS_THAN_EQUALS]: {
      label: "less than or equal to",
      value: OPERATORS.LESS_THAN_EQUALS,
      field: FIELDS.NUMBER_BOX,
      options: null,
    },
    [OPERATORS.BETWEEN]: {
      label: "between",
      value: OPERATORS.BETWEEN,
      field: FIELDS.NUMBER_BOX_RANGE,
      options: null,
    },
  },
  [FILTER_DATA_TYPES.DATE]: {
    [OPERATORS.IS]: {
      label: "is",
      value: OPERATORS.IS,
      field: FIELDS.SEARCHABLE_DROPDOWN,
      options: {
        [SUB_OPERATORS.ALL_TIME]: {
          label: "All time",
          value: SUB_OPERATORS.ALL_TIME,
          field: null,
        },
        [SUB_OPERATORS.YESTERDAY]: {
          label: "Yesterday",
          value: SUB_OPERATORS.YESTERDAY,
          field: null,
        },
        [SUB_OPERATORS.TODAY]: {
          label: "Today",
          value: SUB_OPERATORS.TODAY,
          field: null,
        },
        [SUB_OPERATORS.TOMORROW]: {
          label: "Tomorrow",
          value: SUB_OPERATORS.TOMORROW,
          field: null,
        },
        [SUB_OPERATORS.LAST_WEEK]: {
          label: "Last Week",
          value: SUB_OPERATORS.LAST_WEEK,
          field: null,
        },
        [SUB_OPERATORS.THIS_WEEK]: {
          label: "This Week",
          value: SUB_OPERATORS.THIS_WEEK,
          field: null,
        },
        [SUB_OPERATORS.NEXT_WEEK]: {
          label: "Next Week",
          value: SUB_OPERATORS.NEXT_WEEK,
          field: null,
        },
        [SUB_OPERATORS.LAST_MONTH]: {
          label: "Last Month",
          value: SUB_OPERATORS.LAST_MONTH,
          field: null,
        },
        [SUB_OPERATORS.THIS_MONTH]: {
          label: "This Month",
          value: SUB_OPERATORS.THIS_MONTH,
          field: null,
        },
        [SUB_OPERATORS.NEXT_MONTH]: {
          label: "Next Month",
          value: SUB_OPERATORS.NEXT_MONTH,
          field: null,
        },
        [SUB_OPERATORS.LAST_YEAR]: {
          label: "Last Year",
          value: SUB_OPERATORS.LAST_YEAR,
          field: null,
        },
        [SUB_OPERATORS.THIS_YEAR]: {
          label: "This Year",
          value: SUB_OPERATORS.THIS_YEAR,
          field: null,
        },
        [SUB_OPERATORS.NEXT_YEAR]: {
          label: "Next Year",
          value: SUB_OPERATORS.NEXT_YEAR,
          field: null,
        },
        [SUB_OPERATORS.THIS_CYCLE]: {
          label: "Between This Cycle",
          value: SUB_OPERATORS.THIS_CYCLE,
          field: null,
        },
        [SUB_OPERATORS.WITHIN_LAST_N_DAYS]: {
          label: "Within Last N Days",
          value: SUB_OPERATORS.WITHIN_LAST_N_DAYS,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.WITHIN_NEXT_N_DAYS]: {
          label: "Within Next N Days",
          value: SUB_OPERATORS.WITHIN_NEXT_N_DAYS,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.CUSTOM_DATE]: {
          label: "Custom Date",
          value: SUB_OPERATORS.CUSTOM_DATE,
          field: FIELDS.DATE_PICKER,
        },
        [SUB_OPERATORS.CUSTOM_DATE_RANGE]: {
          label: "Custom Date Range",
          value: SUB_OPERATORS.CUSTOM_DATE_RANGE,
          field: FIELDS.DATE_RANGE_PICKER,
        },
        [SUB_OPERATORS.EQUAL_TO_CYCLE_START_DATE]: {
          label: "Equal to Cycle Start Date",
          value: SUB_OPERATORS.EQUAL_TO_CYCLE_START_DATE,
          field: null,
        },
        [SUB_OPERATORS.LESS_THAN_CYCLE_START_DATE]: {
          label: "Less Than Cycle Start Date",
          value: SUB_OPERATORS.LESS_THAN_CYCLE_START_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_START_DATE]: {
          label: "Less Than or Equal to Cycle Start Date",
          value: SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_START_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.GREATER_THAN_CYCLE_START_DATE]: {
          label: "Greater Than Cycle Start Date",
          value: SUB_OPERATORS.GREATER_THAN_CYCLE_START_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_START_DATE]: {
          label: "Greater Than or Equal to Cycle Start Date",
          value: SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_START_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.EQUAL_TO_CYCLE_END_DATE]: {
          label: "Equal to Cycle End Date",
          value: SUB_OPERATORS.EQUAL_TO_CYCLE_END_DATE,
          field: null,
        },
        [SUB_OPERATORS.LESS_THAN_CYCLE_END_DATE]: {
          label: "Less Than Cycle End Date",
          value: SUB_OPERATORS.LESS_THAN_CYCLE_END_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_END_DATE]: {
          label: "Less Than or Equal to Cycle End Date",
          value: SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_END_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.GREATER_THAN_CYCLE_END_DATE]: {
          label: "Greater Than Cycle End Date",
          value: SUB_OPERATORS.GREATER_THAN_CYCLE_END_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_END_DATE]: {
          label: "Greater Than or Equal to Cycle End Date",
          value: SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_END_DATE,
          field: FIELDS.NUMBER_BOX,
        },
      },
    },
    [OPERATORS.IS_NOT]: {
      label: "is not",
      value: OPERATORS.IS_NOT,
      field: FIELDS.SEARCHABLE_DROPDOWN,
      options: {
        [SUB_OPERATORS.YESTERDAY]: {
          label: "Yesterday",
          value: SUB_OPERATORS.YESTERDAY,
          field: null,
        },
        [SUB_OPERATORS.TODAY]: {
          label: "Today",
          value: SUB_OPERATORS.TODAY,
          field: null,
        },
        [SUB_OPERATORS.TOMORROW]: {
          label: "Tomorrow",
          value: SUB_OPERATORS.TOMORROW,
          field: null,
        },
        [SUB_OPERATORS.LAST_WEEK]: {
          label: "Last Week",
          value: SUB_OPERATORS.LAST_WEEK,
          field: null,
        },
        [SUB_OPERATORS.THIS_WEEK]: {
          label: "This Week",
          value: SUB_OPERATORS.THIS_WEEK,
          field: null,
        },
        [SUB_OPERATORS.NEXT_WEEK]: {
          label: "Next Week",
          value: SUB_OPERATORS.NEXT_WEEK,
          field: null,
        },
        [SUB_OPERATORS.LAST_MONTH]: {
          label: "Last Month",
          value: SUB_OPERATORS.LAST_MONTH,
          field: null,
        },
        [SUB_OPERATORS.THIS_MONTH]: {
          label: "This Month",
          value: SUB_OPERATORS.THIS_MONTH,
          field: null,
        },
        [SUB_OPERATORS.NEXT_MONTH]: {
          label: "Next Month",
          value: SUB_OPERATORS.NEXT_MONTH,
          field: null,
        },
        [SUB_OPERATORS.LAST_YEAR]: {
          label: "Last Year",
          value: SUB_OPERATORS.LAST_YEAR,
          field: null,
        },
        [SUB_OPERATORS.THIS_YEAR]: {
          label: "This Year",
          value: SUB_OPERATORS.THIS_YEAR,
          field: null,
        },
        [SUB_OPERATORS.NEXT_YEAR]: {
          label: "Next Year",
          value: SUB_OPERATORS.NEXT_YEAR,
          field: null,
        },
        [SUB_OPERATORS.THIS_CYCLE]: {
          label: "Between This Cycle",
          value: SUB_OPERATORS.THIS_CYCLE,
          field: null,
        },
        [SUB_OPERATORS.WITHIN_LAST_N_DAYS]: {
          label: "Within Last N Days",
          value: SUB_OPERATORS.WITHIN_LAST_N_DAYS,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.WITHIN_NEXT_N_DAYS]: {
          label: "Within Next N Days",
          value: SUB_OPERATORS.WITHIN_NEXT_N_DAYS,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.CUSTOM_DATE]: {
          label: "Custom Date",
          value: SUB_OPERATORS.CUSTOM_DATE,
          field: FIELDS.DATE_PICKER,
        },
        [SUB_OPERATORS.CUSTOM_DATE_RANGE]: {
          label: "Custom Date Range",
          value: SUB_OPERATORS.CUSTOM_DATE_RANGE,
          field: FIELDS.DATE_RANGE_PICKER,
        },
        [SUB_OPERATORS.EQUAL_TO_CYCLE_START_DATE]: {
          label: "Equal to Cycle Start Date",
          value: SUB_OPERATORS.EQUAL_TO_CYCLE_START_DATE,
          field: null,
        },
        [SUB_OPERATORS.LESS_THAN_CYCLE_START_DATE]: {
          label: "Less Than Cycle Start Date",
          value: SUB_OPERATORS.LESS_THAN_CYCLE_START_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_START_DATE]: {
          label: "Less Than or Equal to Cycle Start Date",
          value: SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_START_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.GREATER_THAN_CYCLE_START_DATE]: {
          label: "Greater Than Cycle Start Date",
          value: SUB_OPERATORS.GREATER_THAN_CYCLE_START_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_START_DATE]: {
          label: "Greater Than or Equal to Cycle Start Date",
          value: SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_START_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.EQUAL_TO_CYCLE_END_DATE]: {
          label: "Equal to Cycle End Date",
          value: SUB_OPERATORS.EQUAL_TO_CYCLE_END_DATE,
          field: null,
        },
        [SUB_OPERATORS.LESS_THAN_CYCLE_END_DATE]: {
          label: "Less Than Cycle End Date",
          value: SUB_OPERATORS.LESS_THAN_CYCLE_END_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_END_DATE]: {
          label: "Less Than or Equal to Cycle End Date",
          value: SUB_OPERATORS.LESS_THAN_OR_EQUAL_TO_CYCLE_END_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.GREATER_THAN_CYCLE_END_DATE]: {
          label: "Greater Than Cycle End Date",
          value: SUB_OPERATORS.GREATER_THAN_CYCLE_END_DATE,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_END_DATE]: {
          label: "Greater Than or Equal to Cycle End Date",
          value: SUB_OPERATORS.GREATER_THAN_OR_EQUAL_TO_CYCLE_END_DATE,
          field: FIELDS.NUMBER_BOX,
        },
      },
    },
    [OPERATORS.AFTER]: {
      label: "after",
      value: OPERATORS.AFTER,
      field: FIELDS.SEARCHABLE_DROPDOWN,
      options: {
        [SUB_OPERATORS.YESTERDAY]: {
          label: "Yesterday",
          value: SUB_OPERATORS.YESTERDAY,
          field: null,
        },
        [SUB_OPERATORS.TODAY]: {
          label: "Today",
          value: SUB_OPERATORS.TODAY,
          field: null,
        },
        [SUB_OPERATORS.TOMORROW]: {
          label: "Tomorrow",
          value: SUB_OPERATORS.TOMORROW,
          field: null,
        },
        [SUB_OPERATORS.THIS_WEEK]: {
          label: "This Week",
          value: SUB_OPERATORS.THIS_WEEK,
          field: null,
        },
        [SUB_OPERATORS.THIS_MONTH]: {
          label: "This Month",
          value: SUB_OPERATORS.THIS_MONTH,
          field: null,
        },
        [SUB_OPERATORS.THIS_YEAR]: {
          label: "This Year",
          value: SUB_OPERATORS.THIS_YEAR,
          field: null,
        },
        [SUB_OPERATORS.N_DAYS]: {
          label: "N Days",
          value: SUB_OPERATORS.N_DAYS,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.CUSTOM_DATE]: {
          label: "Custom Date",
          value: SUB_OPERATORS.CUSTOM_DATE,
          field: FIELDS.DATE_PICKER,
        },
        [SUB_OPERATORS.THIS_CYCLE]: {
          label: "This Cycle",
          value: SUB_OPERATORS.THIS_CYCLE,
          field: null,
        },
      },
    },
    [OPERATORS.BEFORE]: {
      label: "before",
      value: OPERATORS.BEFORE,
      field: FIELDS.SEARCHABLE_DROPDOWN,
      options: {
        [SUB_OPERATORS.YESTERDAY]: {
          label: "Yesterday",
          value: SUB_OPERATORS.YESTERDAY,
          field: null,
        },
        [SUB_OPERATORS.TODAY]: {
          label: "Today",
          value: SUB_OPERATORS.TODAY,
          field: null,
        },
        [SUB_OPERATORS.TOMORROW]: {
          label: "Tomorrow",
          value: SUB_OPERATORS.TOMORROW,
          field: null,
        },
        [SUB_OPERATORS.THIS_WEEK]: {
          label: "This Week",
          value: SUB_OPERATORS.THIS_WEEK,
          field: null,
        },
        [SUB_OPERATORS.THIS_MONTH]: {
          label: "This Month",
          value: SUB_OPERATORS.THIS_MONTH,
          field: null,
        },
        [SUB_OPERATORS.THIS_YEAR]: {
          label: "This Year",
          value: SUB_OPERATORS.THIS_YEAR,
          field: null,
        },
        [SUB_OPERATORS.N_DAYS]: {
          label: "N Days",
          value: SUB_OPERATORS.N_DAYS,
          field: FIELDS.NUMBER_BOX,
        },
        [SUB_OPERATORS.CUSTOM_DATE]: {
          label: "Custom Date",
          value: SUB_OPERATORS.CUSTOM_DATE,
          field: FIELDS.DATE_PICKER,
        },
        [SUB_OPERATORS.THIS_CYCLE]: {
          label: "This Cycle",
          value: SUB_OPERATORS.THIS_CYCLE,
          field: null,
        },
      },
    },
  },
  [FILTER_DATA_TYPES.BOOLEAN]: {
    [OPERATORS.TRUE]: {
      label: "true",
      value: OPERATORS.TRUE,
      field: null,
      options: null,
    },
    [OPERATORS.FALSE]: {
      label: "false",
      value: OPERATORS.FALSE,
      field: null,
      options: null,
    },
  },
};

export const FILTERS = [
  {
    FilterName: "",
    FilterConditions: [],
    ConditionPattern: "",
    id: generateRandomKey(),
  },
];

export const CONDITIONS = [
  {
    Attribute: "",
    Operator: "",
    Value: "",
    DataType: "",
  },
];

export const GOALS_CUSTOM_USER_FIELDS = [
  {
    DataType: "NUMBER",
    DisplayName: "Goal Value",
    SchemaName: "GoalDistribution",
  },
  {
    DataType: "NUMBER",
    DisplayName: "Achieved %",
    SchemaName: "AchievedPercentage",
  },
  {
    DataType: "NUMBER",
    DisplayName: "Achieved Value",
    SchemaName: "Achievement",
  },
];
