import React from "react";
import { Shimmer } from "src/components/common/atoms";

const LoadingCriteriaIncentive = () => (
  <div className="w-100 d-flex page-container mt-4">
    <div className="w-75 mr-5">
      <div className="flex-between">
        <div className="w-50">
          <Shimmer width="200px" height="10px" />
          <div className="mt-3">
            <Shimmer width="80%" height="10px" />
            <div className="mt-2">
              <Shimmer width="80%" height="10px" />
            </div>
          </div>
        </div>
        <div className="w-50 flex-end">
          <Shimmer width="300px" height="80px" />
        </div>
      </div>
      <div className="flex-between mt-4">
        <div className="w-50">
          <Shimmer width="220px" height="30px" />
        </div>
        <div className="w-50 flex-end">
          <Shimmer width="200px" height="40px" />
        </div>
      </div>
      <div className="mt-3">
        <Shimmer width="200px" height="40px" />
      </div>
    </div>
    <div className="w-25">
      <Shimmer width="100%" height="500px" />
    </div>
  </div>
);

export default LoadingCriteriaIncentive;
