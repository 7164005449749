import React from "react";
import { Image } from "src/components";
import LeaderboardGridYetToStartImg from "./LeaderboardGridYetToStart.png";

const LeaderboardGridYetToStart = (props) => (
  <Image
    src={LeaderboardGridYetToStartImg}
    alt="LeaderboardGridYetToStartImg"
    {...props}
  />
);

export default LeaderboardGridYetToStart;
