import { t } from "i18next";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import { FormButton } from "src/components/common/formElements";
import { COLORS } from "src/constants";
import { SvgAttachment, SvgDelete, SvgError, SvgTick } from "src/icons";
import { Truncate } from "../../truncate";
import { FILE_ERROR_CODES } from "../useFilePicker";
import "./styles.scss";

export const OnLoad = ({ title, hintText, buttonText, handleClick, Icon }) => {
  return (
    <div className="file-container onload">
      {Icon}
      <div className="ml-3">
        <div className="fs-13 semi-bold">{title}</div>
        <div className="fs-12 light fc-grey">{hintText}</div>
      </div>
      <FormButton
        id="choose-file"
        variant="outline-primary"
        label={buttonText}
        className="ml-auto"
        onClick={handleClick}
        icon={<SvgAttachment />}
      />
    </div>
  );
};

OnLoad.propTypes = {
  title: PropTypes.string,
  hintText: PropTypes.string,
  buttonText: PropTypes.string,
};

export const OnDrop = ({ hintText }) => {
  return (
    <div className="file-container ondrop">
      <SvgAttachment color={COLORS.GREY_2} className="mb-1" />
      <div className="fs-12 light grey1">{hintText}</div>
    </div>
  );
};

export const OnUpload = ({ fileName, handleCancel }) => {
  return (
    <div className="file-container onupload">
      <div className="fs-12 light grey1">
        <Truncate maxWidth="400px" text={fileName} />
      </div>
      <div className="flex-center mt-1">
        <ProgressBar
          className="w-180"
          striped
          animated
          variant="success"
          now={40}
        />
        <FormButton
          id="upload-remove"
          onClick={handleCancel}
          className="fs-10 p-0 ml-3"
          variant="link"
          label={t("COMMON.BUTTONS.CANCEL")}
        />
      </div>
    </div>
  );
};

export const OnSuccess = ({ fileName, handleRemove, isNewUpload }) => {
  return (
    <div className="file-container onsuccess">
      <div className="fs-12 mb-1 flex-center">
        <SvgTick backgroundColor="#2ecc71" className="mr-2" />
        {isNewUpload ? (
          <div className="center">
            {t("INCENTIVES.COMMON.LABELS.FILE")}
            <span className="mx-1 semi-bold">
              <Truncate text={fileName} maxWidth="200px" />
            </span>
            {t("INCENTIVES.COMMON.LABELS.UPLOADED_SUCCESSFULLY")}
          </div>
        ) : null}
        {!isNewUpload ? (
          <div className="semi-bold">
            <Truncate text={fileName} maxWidth="200px" />
          </div>
        ) : null}
      </div>
      <FormButton
        id="success-remove"
        className="fs-10 p-0"
        variant="link"
        label={t("COMMON.BUTTONS.REMOVE")}
        icon={<SvgDelete />}
        onClick={handleRemove}
      />
    </div>
  );
};

export const OnError = ({ fileName, error, handleRemove }) => {
  return (
    <div className="file-container onerror">
      <div className="fs-12 mb-1 flex-center">
        <SvgError className="mr-2" />
        {FILE_ERROR_CODES.FILE_INVALID_TYPE === error.code ? (
          <div className="semi-bold">{error.message}</div>
        ) : null}
        {FILE_ERROR_CODES.FILE_TOO_LARGE === error.code ? (
          <div className="center">
            {t("INCENTIVES.COMMON.LABELS.FILE")}
            <span className="semi-bold mx-1">
              <Truncate text={fileName} maxWidth="200px" />
            </span>
            {t("INCENTIVES.COMMON.LABELS.SIZE_EXCEEDS")}
          </div>
        ) : null}
      </div>
      <FormButton
        id="error-remove"
        className="fs-10 p-0 ml-3"
        variant="link"
        label={t("COMMON.BUTTONS.REMOVE")}
        icon={<SvgDelete />}
        onClick={handleRemove}
      />
    </div>
  );
};
