import { gql, useQuery } from "src/api/client";
import { ERROR_RESPONSE_FRAGMENT } from "src/api/fragments";

export const GET_LEADERBOARD_MATCHING_GROUPS = gql`
  ${ERROR_RESPONSE_FRAGMENT}
  query getLeaderboardMatchingGroups(
    $LeaderboardId: ID!
    $SourceRecurrenceId: ID!
  ) {
    getLeaderboardMatchingGroups(
      LeaderboardId: $LeaderboardId
      SourceRecurrenceId: $SourceRecurrenceId
    ) {
      __typename
      ... on LeaderboardMatchingGroupsResponse {
        success
        message
        response {
          Recurrences {
            LeaderboardId
            RecurrenceId
            RecurrenceStartDate
            RecurrenceEndDate
            RecurrenceType
            GroupsCount
            UserIdentifier
            IsGroupConfigured
            MatchingGroups
            RecurrenceStatus
            __typename
          }
          SourceRecurrence {
            LeaderboardId
            RecurrenceId
            RecurrenceStartDate
            RecurrenceEndDate
            RecurrenceType
            GroupsCount
            UserIdentifier
            IsGroupConfigured
            MatchingGroups
            MatchingGroupList {
              GroupId
              GroupName
              GroupTarget
              RewardCount
            }
            RecurrenceStatus
            __typename
          }
          __typename
        }
        __typename
      }
      ... on ErrorResponse {
        ...errorResponseFragment
        __typename
      }
    }
  }
`;

export const useGetMatchingGroups = (queryOptions) => {
  return useQuery(GET_LEADERBOARD_MATCHING_GROUPS, {
    ...queryOptions,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    awaitrefetchqueries: true,
  });
};
