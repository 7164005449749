import React, { createContext, useContext } from "react";
import { useClarityScript } from "src/hooks";

const RoleBasedContext = createContext();

const RoleBasedProvider = ({
  children,
  userDetails,
  maxAllowedGoalRules,
  isFeatureEnabled,
  orgCode,
  role,
  orgDisplayName,
  orgUniqueName,
  userId,
}) => {
  useClarityScript({
    OrgCode: orgCode,
    UserId: userId,
    Role: role,
    OrgDisplayName: orgDisplayName,
    OrgUniqueName: orgUniqueName,
  });

  return (
    <RoleBasedContext.Provider
      value={{
        userDetails,
        maxAllowedGoalRules,
        isFeatureEnabled,
      }}
    >
      {children}
    </RoleBasedContext.Provider>
  );
};

const useRoleBasedContext = () => {
  const ctx = useContext(RoleBasedContext);
  if (!ctx) {
    throw new Error("useRoleBasedContext must be inside RoleBasedProvider");
  }
  return ctx;
};

export { RoleBasedProvider, useRoleBasedContext };
