import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useCreateIncentiveProgram,
  useUpdateProgramFile,
} from "src/api/useIncentive";
import { FormButton, Truncate, toaster } from "src/components";
import { APPS } from "src/constants";
import { errorText } from "src/helpers";
import { useHistory } from "src/router";
import ProgramDescription from "./ProgramDescription";
import ProgramInformation from "./ProgramInformation";
import ProgramName from "./ProgramName";

const CreateProgramModal = ({ show, handleClose, refetch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, setState] = useState({
    Name: "",
    Description: "",
    fileDetails: {
      FileName: null,
      FileId: null,
    },
  });
  const [disable, setDisable] = useState(true);
  const [
    createProgram,
    {
      data: createProgramData,
      error: createProgramError,
      loading: createProgramLoading,
    },
  ] = useCreateIncentiveProgram();

  const [updateProgramFile] = useUpdateProgramFile();

  const handleChange = ({ name, value }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const newProgramId =
    createProgramData?.createProgram.response?.Program.ProgramId;

  const handleSkip = () => {
    history.push(
      APPS.INCENTIVES.ROUTES.INCENTIVE_CRUD.link({
        params: {
          programId: newProgramId,
        },
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createProgram({
      variables: {
        Name: state.Name,
        Description: state.Description,
      },
    }).then((result) => {
      setDisable(false);
    });
  };

  const handleProceed = () => {
    updateProgramFile({
      variables: {
        ProgramId: newProgramId,
        FileName: state.fileDetails.FileName,
        FileId: state.fileDetails.FileId,
      },
    }).then((result) => {
      history.push(
        APPS.INCENTIVES.ROUTES.INCENTIVE_CRUD.link({
          params: {
            programId: newProgramId,
          },
        })
      );
    });
  };

  useEffect(() => {
    if (newProgramId) {
      refetch && refetch();
      setDisable(true);
      toaster({
        message: createProgramData.createProgram.message,
        type: "info",
      });
    }
  }, [createProgramData, newProgramId, refetch]);

  if (createProgramError) {
    toaster({ message: errorText.CREATE_INCENTIVE.ERROR, type: "error" });
  }

  useEffect(() => {
    if (createProgramLoading && !disable) {
      setDisable(true);
    }
  }, [createProgramLoading, disable]);

  const isDisabled = Boolean(
    disable || !state.Name || state.Name.trim() === ""
  );

  return (
    <Modal
      onHide={handleClose}
      dialogClassName="cu-incentive-modal"
      show={show}
      style={{ minWidth: "720px" }}
    >
      <Modal.Header closeButton className="fs-18 semi-bold">
        {t("INCENTIVES.COMMON.LABELS.CREATE_INCENTIVE_PROGRAM")}
        {newProgramId ? (
          <>
            <span className="mx-1">-</span>
            <span className="fc-grey fs-18 semi-bold">
              <Truncate maxWidth="300px" text={state.Name} />
            </span>
          </>
        ) : null}
      </Modal.Header>
      <Modal.Body className="p-4" style={{ minHeight: "302px" }}>
        {!newProgramId ? (
          <form id="incentive-form" onSubmit={handleSubmit}>
            <ProgramName
              name="Name"
              value={state.Name}
              handleChange={handleChange}
              label={t("INCENTIVES.COMMON.LABELS.INCENTIVE_PROGRAM")}
              placeholder={t(
                "INCENTIVES.COMMON.LABELS.ENTER_INCENTIVE_PROGRAM_NAME"
              )}
              setDisable={setDisable}
            />
            <ProgramDescription
              name="Description"
              value={state.Description}
              handleChange={handleChange}
              label={t("INCENTIVES.COMMON.LABELS.INCENTIVE_DESCRIPTION")}
              placeholder={t(
                "INCENTIVES.COMMON.LABELS.ENTER_INCENTIVE_PROGRAM_DESCRIPTION"
              )}
            />
          </form>
        ) : null}
        {newProgramId ? (
          <ProgramInformation
            programId={newProgramId}
            name="fileDetails"
            value={state.fileDetails}
            label={t("INCENTIVES.COMMON.LABELS.UPLOAD_INCENTIVE_INFORMATION")}
            tip={t("INCENTIVES.COMMON.LABELS.UPLOAD_INCENTIVE_INFORMATION_TIP")}
            setDisable={setDisable}
            setFileDetails={handleChange}
          />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        {!newProgramId ? (
          <>
            <FormButton
              id="close-incentive-form"
              variant="outline-primary"
              label={t("COMMON.BUTTONS.CANCEL")}
              onClick={handleClose}
              className="mr-3"
            />
            <FormButton
              id="create-incentive-form"
              label={t("COMMON.BUTTONS.CREATE")}
              type="submit"
              form="incentive-form"
              disabled={isDisabled}
            />
          </>
        ) : null}
        {newProgramId ? (
          <FormButton
            id="close-incentive-form"
            variant="primary"
            label={
              disable || state.fileDetails.FileName
                ? t("MANAGED_GOALS.BUTTONS.PROCEED")
                : t("MANAGED_GOALS.BUTTONS.PROCEED_WITHOUT_ATTACHMENT")
            }
            onClick={
              disable || state.fileDetails.FileName ? handleProceed : handleSkip
            }
            className="mr-3"
            disabled={disable}
          />
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateProgramModal;
