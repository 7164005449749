import { gql, useMutation } from "src/api/client";

// src/goalCrud/distributeGoal/importModal/importFooters/DragDrop.js
export const INSERT_GOAL_IMPORT_REPORT_MUTATION = gql`
  mutation insertImportRecords($GoalId: ID!, $FileId: Float!) {
    insertImportRecords(GoalId: $GoalId, FileId: $FileId) {
      message
    }
  }
`;

export const useInsertGoalImportReport = () => {
  return useMutation(INSERT_GOAL_IMPORT_REPORT_MUTATION);
};
