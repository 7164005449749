import { SvgCarter, SvgEmail, SvgManager, SvgMobile, SvgUser } from "src/icons";
import {
  TeamCarterPreview,
  TeamEmailPreview,
  TeamMobilePreview,
  UserCarterPreview,
  UserEmailPreview,
  UserMobilePreview,
} from "src/images";

export const ROLES_FOR_PREVIEW = {
  Manager: {
    Icon: SvgManager,
    Label: "Manager",
  },
  User: {
    Icon: SvgUser,
    Label: "User",
  },
};

export const PLATFORMS_FOR_PREVIEW = {
  Mobile: {
    Icon: SvgMobile,
    Label: "Mobile",
  },
  Carter: {
    Icon: SvgCarter,
    Label: "Carter",
  },
  Email: {
    Icon: SvgEmail,
    Label: "Email",
  },
};

export const ITEMS_FOR_PREVIEW = {
  Manager: {
    Mobile: () => <TeamMobilePreview />,
    Carter: () => <TeamCarterPreview />,
    Email: () => <TeamEmailPreview />,
    Text: "Team daily summary",
    Description:
      "The sales manager will get each goal summary of his team daily.",
  },
  User: {
    Mobile: () => <UserMobilePreview />,
    Carter: () => <UserCarterPreview />,
    Email: () => <UserEmailPreview />,
    Text: "User daily summary",
    Description: "The sales user will get their assigned goal summaries daily.",
  },
};
