import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Calendar } from "src/components";
import { MAX_DATE, MIN_DATE } from "src/constants";

function DateRange({
  startDate,
  endDate,
  onChange,
  leftCalendarClass,
  rightCalendarClass,
  showTo = true,
  minDate,
  maxDate,
}) {
  const [range, setRange] = useState({
    startDate: startDate ? new Date(startDate) : "",
    endDate: endDate ? new Date(endDate) : "",
  });

  const handleChange = ({ key, value }) => {
    setRange((prevRange) => ({
      ...prevRange,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (range.startDate && range.endDate) {
      onChange(range);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.startDate, range.endDate]);

  return (
    <div className="center">
      <Calendar
        placeholder={t("INCENTIVES.DASHBOARD.LABELS.SELECT_START_DATE")}
        selected={range.startDate}
        minDate={minDate || MIN_DATE}
        onChange={(value) => {
          handleChange({ key: "startDate", value });
          handleChange({ key: "endDate", value: null });
        }}
        maxDate={maxDate || MAX_DATE}
        className={leftCalendarClass}
      />
      {showTo && <span className="mx-3 fs-12">To</span>}
      <Calendar
        placeholder={t("INCENTIVES.DASHBOARD.LABELS.SELECT_END_DATE")}
        selected={range.endDate}
        disabled={!range.startDate}
        onChange={(value) => handleChange({ key: "endDate", value })}
        openToDate={range.startDate}
        minDate={range.startDate}
        maxDate={maxDate || MAX_DATE}
        className={rightCalendarClass}
      />
    </div>
  );
}

export default DateRange;
