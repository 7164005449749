import { t } from "i18next";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useCloneIncentiveProgram } from "src/api/useIncentive/mutations/useCloneIncentiveProgram";
import { useCloneLeaderboard } from "src/api/useLeaderboardConfig";
import ProgramName from "src/apps/incentives/components/createOrUpdateProgram/ProgramName";
import { ButtonShimmer, FormButton, toaster } from "src/components";
import { APPS } from "src/constants";
import { errorText } from "src/helpers";
import { useHistory } from "src/router";
import "./styles.scss";

const CloneProgramModal = ({
  show,
  handleClose,
  isIncentive = false,
  programName,
  programId,
}) => {
  const [disable, setDisable] = useState(true);
  const [name, setName] = useState("");
  const isDisabled = Boolean(disable || !name || name.trim() === " ");
  const history = useHistory();
  const [
    cloneProgram,
    { error: cloneProgramError, loading: cloneProgramLoading },
  ] = useCloneIncentiveProgram();

  const [
    cloneLeaderboard,
    { error: cloneLeaderboardError, loading: cloneLeaderboardLoading },
  ] = useCloneLeaderboard();

  if (cloneProgramError || cloneLeaderboardError) {
    toaster({
      message: errorText.CLONE_PROGRAM.ERROR,
      type: "error",
    });
  }

  const handleSubmit = () => {
    if (isIncentive) {
      cloneProgram({
        variables: {
          ProgramId: programId,
          Name: name,
        },
      }).then(() => {
        toaster({
          type: "success",
          message: t("TOASTERS.MESSAGES.CLONE_PROGRAM.SUCCESS"),
        });
        handleClose();
        history.push(APPS.INCENTIVES.ROUTES.MANAGE_PROGRAM.link({}));
      });
    } else {
      cloneLeaderboard({
        variables: {
          LeaderboardId: programId,
          Name: name,
        },
      }).then(() => {
        toaster({
          message: t("TOASTERS.MESSAGES.CLONE_LEADERBOARD.SUCCESS"),
          type: "success",
        });
        handleClose();
        history.push(APPS.LEADERBOARDS.ROUTES.MANAGE_LEADERBOARD.link({}));
      });
    }
  };

  return (
    <Modal
      onHide={handleClose}
      dialogClassName="clone-program-modal"
      show={show}
      centered
    >
      <Modal.Header closeButton className="fs-18 semi-bold">
        {isIncentive
          ? t("INCENTIVES.COMMON.LABELS.DUPLICATE_PROGRAM")
          : t("LEADERBOARD.LABELS.DUPLICATE_LEADERBOARD")}
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="fs-14 semi-bold">
          {t("COMMON.MESSAGES.ARE_YOU_SURE_TO_DUPLICATE")}{" "}
          <span className="fc-grey1">{programName}</span>?
        </div>
        <div className="fs-13 my-3">
          <Trans
            i18nKey={
              isIncentive
                ? "INCENTIVES.COMMON.MESSAGES.DUPLICATE_PROGRAM_DESCRIPTION"
                : "LEADERBOARD.COMMON.MESSAGES.DUPLICATE_LEADERBOARD_DESCRIPTION"
            }
          ></Trans>
        </div>
        <ProgramName
          name="Name"
          value={name}
          handleChange={({ value }) => setName(value)}
          placeholder={t("COMMON.LABELS.ENTER_NAME")}
          setDisable={setDisable}
          label={null}
        />
      </Modal.Body>
      <Modal.Footer>
        <>
          <FormButton
            variant="outline-primary"
            label={t("COMMON.BUTTONS.CANCEL")}
            onClick={handleClose}
            className="mr-3"
          />
          {cloneProgramLoading || cloneLeaderboardLoading ? (
            <ButtonShimmer
              text={
                isIncentive
                  ? t("INCENTIVES.COMMON.LABELS.DUPLICATE_PROGRAM")
                  : t("LEADERBOARD.LABELS.DUPLICATE_LEADERBOARD")
              }
            />
          ) : (
            <FormButton
              label={
                isIncentive
                  ? t("INCENTIVES.COMMON.LABELS.DUPLICATE_PROGRAM")
                  : t("LEADERBOARD.LABELS.DUPLICATE_LEADERBOARD")
              }
              disabled={isDisabled}
              onClick={handleSubmit}
            />
          )}
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default CloneProgramModal;
