import React from "react";
import { FormButton } from "src/components/common";
import { Shimmer } from "src/components/common/atoms";
import { COLORS } from "src/constants";
import { classNames } from "src/helpers";
import { Avatar, DotBadge } from "../avatar";
import { isActiveFilter } from "./data";

const MobileFilterBarIcons = (props) => {
  const { filtersToggleState, filtersState, filters, toggleFilter, loading } =
    props;

  if (loading && filters?.length > 0) {
    return (
      <div className="center space-x-8">
        {filters.map(({ key }) => (
          <Shimmer key={key} width="36px" height="36px" />
        ))}
      </div>
    );
  }

  return (
    <div className="center space-x-8">
      {filters.map((filter, index) => {
        const Icon = filter?.icon;
        const isSearchOpen =
          filter.key.toLowerCase() === "search" &&
          filtersToggleState[filter.key];
        const activeFilter =
          !isSearchOpen &&
          isActiveFilter({
            filtersState,
            filter,
          });

        if (filter?.icon)
          return (
            <FormButton
              className={classNames([
                "filter-icon py-2",
                isSearchOpen ? "open-search-icon" : "",
              ])}
              key={index}
              onClick={() => toggleFilter(filter.key)}
              variant="link"
            >
              <Avatar
                src={
                  <Icon
                    color={isSearchOpen ? COLORS.PRIMARY_BLUE : COLORS.GREY_2}
                  />
                }
                badgeProps={{
                  showBadge: activeFilter,
                  badge: (
                    <DotBadge
                      className={classNames([
                        filter.key.toLowerCase() === "search"
                          ? "search-dot-badge"
                          : "mr-1 mb-1",
                      ])}
                      outlineProps={{
                        outlineColor: "#f7f8fa",
                        outlineWidth: "2px",
                        outline: true,
                      }}
                    />
                  ),
                  position:
                    filter.key.toLowerCase() === "search"
                      ? "top-end"
                      : "bottom-end",
                }}
              />
            </FormButton>
          );
        return null;
      })}
    </div>
  );
};

export default MobileFilterBarIcons;
