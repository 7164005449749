import React from "react";
import { Image } from "src/components";
import XylemLearningPrivateLimitedPng from "./XylemLearningPrivateLimited.png";

const XylemLearningPrivateLimited = (props) => (
  <Image
    src={XylemLearningPrivateLimitedPng}
    alt="XylemLearningPrivateLimited"
    {...props}
  />
);
export default XylemLearningPrivateLimited;
