import { FilterContainer } from "src/components/container/filterContainer";
import { CYCLES, LEADERBOARD_CYCLES_OPTIONS, RANKS } from "src/constants";
import { SvgFilterModern } from "src/icons";

export const MY_SHOWCASE_FILTER = ({ t }) => [
  {
    label: t("COMMON.LABELS.FILTER"),
    key: "filter",
    icon: SvgFilterModern,
    component: FilterContainer,
    props: ({
      filter,
      filtersState,
      filtersToggleState,
      toggleFilter,
      handleBulkUpdate,
    }) => ({
      title: t("COMMON.LABELS.FILTERS"),
      show: filtersToggleState[filter.key],
      onHide: () => toggleFilter(filter.key),
      options: filter.filters,
      filter: filtersState,
      setFilter: (value) => {
        handleBulkUpdate(value);
      },
    }),
    filters: [
      {
        label: t("LEADERBOARD.PAYOUT.LABELS.CYCLE_TYPE"),
        key: "cycleType",
        defaultValue: LEADERBOARD_CYCLES_OPTIONS[0],
        options: CYCLES,
      },
      {
        label: t("LEADERBOARD.LABELS.RANK"),
        defaultValue: RANKS({ t })[0],
        key: "rank",
        options: RANKS({ t }),
      },
    ],
  },
];
