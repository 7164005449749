import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgManagerDeactivated = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 8714">
        <path fill="none" d="M0 0h16v16H0Z" data-name="Path 4790" />
        <g data-name="Group 8704">
          <path fill="none" d="M0 0h16v16H0z" data-name="Rectangle 2966" />
          <path
            fill={backgroundColor}
            d="M5.2 13.5a.6.6 0 0 1-.6-.6.9.9 0 0 0-.9-.9h-.6a.9.9 0 0 0-.9.9.6.6 0 0 1-1.2 0 2.1 2.1 0 0 1 2.1-2.1h.6a2.1 2.1 0 0 1 2.1 2.1.6.6 0 0 1-.6.6Zm.836-3.121a.6.6 0 0 1-.234-.479 2.112 2.112 0 0 1 1.191-1.891 4.966 4.966 0 0 0-.955 2.37Zm-2.641-.378a2 2 0 1 1 2-2A2 2 0 0 1 3.4 10Zm0-2.8a.8.8 0 1 0 .8.8A.8.8 0 0 0 3.4 7.2Zm11.6.8a5.025 5.025 0 0 0-2.768-1.847A2 2 0 0 1 15 8ZM8.001 6.993a2 2 0 1 1 1.844-.862 4.953 4.953 0 0 0-1.844.862ZM8.194 4.2a.8.8 0 1 0 .8.8.8.8 0 0 0-.794-.8Z"
            data-name="Subtraction 38"
          />
          <g data-name="351983_error_icon">
            <path fill="none" d="M6 6h10v10H6Z" data-name="Path 4785" />
            <path
              fill={color}
              d="M10.996 15.336a4.334 4.334 0 1 1 4.336-4.335 4.34 4.34 0 0 1-4.336 4.335Zm0-3.376a.819.819 0 1 0 .82.819.818.818 0 0 0-.82-.819Zm0-3.542a.925.925 0 0 0-.924.925 1.053 1.053 0 0 0 .065.346l.74 1.817a.132.132 0 0 0 .119.08.149.149 0 0 0 .127-.08c.039-.098.733-1.815.739-1.839a.859.859 0 0 0 .063-.326.926.926 0 0 0-.929-.923Z"
              data-name="Subtraction 36"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgManagerDeactivated.defaultProps = {
  backgroundColor: COLORS.GREY_1,
  color: COLORS.PRIMARY_ORANGE,
  height: 16,
  width: 16,
};
export default SvgManagerDeactivated;
