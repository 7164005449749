import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgSettings = (props) => {
  const { color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <clipPath id="sn9j__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g fill="none" clipPath="url(#sn9j__a)">
        <path d="M0 0h16v16H0z" />
        <g
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
          transform="translate(-2.675 -2.244)"
        >
          <circle
            cx={2.229}
            cy={2.229}
            r={2.229}
            transform="translate(8.446 8.015)"
          />
          <path d="m17.227 7.1-.557-.965a1.114 1.114 0 0 0-1.522-.408l-.293.169a1.672 1.672 0 0 1-2.508-1.448v-.334A1.114 1.114 0 0 0 11.233 3h-1.115A1.114 1.114 0 0 0 9 4.114v.339A1.672 1.672 0 0 1 6.5 5.9l-.3-.168a1.114 1.114 0 0 0-1.522.408l-.555.96a1.114 1.114 0 0 0 .408 1.522l.293.169a1.672 1.672 0 0 1 0 2.9l-.293.169a1.114 1.114 0 0 0-.408 1.522l.557.965a1.114 1.114 0 0 0 1.522.408l.293-.169A1.672 1.672 0 0 1 9 16.035v.339a1.114 1.114 0 0 0 1.114 1.114h1.114a1.114 1.114 0 0 0 1.114-1.114v-.339a1.672 1.672 0 0 1 2.508-1.448l.293.169a1.114 1.114 0 0 0 1.522-.408l.557-.965a1.114 1.114 0 0 0-.408-1.522l-.293-.169a1.672 1.672 0 0 1 0-2.9l.293-.169a1.114 1.114 0 0 0 .413-1.523Z" />
        </g>
      </g>
    </svg>
  );
};

SvgSettings.defaultProps = {
  color: COLORS.GREY_2,
  height: 16,
  width: 16,
};

export default SvgSettings;
