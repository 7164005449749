import ConditionListFormulaItem from "./ConditionListFormulaItem";
import FormulaItem from "./FormulaItem";
import SecondaryFormulaItem from "./SecondaryFormulaItem";
import "./displayFormula.scss";

const DisplayFormula = ({
  condition,
  variant,
  subText = false,
  className,
  ...rest
}) => {
  const formula = condition?.Formula;

  if (variant === "secondary") {
    return (
      <p className="fc-black">
        {formula?.map((formulaItem) => (
          <>
            <SecondaryFormulaItem item={formulaItem} />
            &nbsp;
          </>
        ))}
      </p>
    );
  } else if (variant === "conditionList") {
    return (
      <div className="display-formula center">
        {formula?.map((formulaItem) => (
          <ConditionListFormulaItem item={formulaItem} className={className} />
        ))}
      </div>
    );
  }
  return (
    <div className="flex justify-content-between">
      <div className="display-formula center">
        {formula?.map((formulaItem) => (
          <FormulaItem item={formulaItem} className={className} />
        ))}
        {subText && (
          <span className="font-italic fs-12 fc-grey">(Monetary)</span>
        )}
      </div>
    </div>
  );
};

export default DisplayFormula;
