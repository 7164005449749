import { t } from "i18next";
import React from "react";
import { LoadingTemplateDetails } from "src/components/common/loading";
import { DisplayFormula } from "src/components/common/molecules";
import { COLORS } from "src/constants";
import { formatFormulaToTokens } from "src/helpers";
import { SvgCustomFormula, SvgGroupBy, SvgStandardFormula } from "src/icons";
import { NoResults } from "src/images";
import { RulesGrid } from "../rulesGrid";

const TemplateDetailContent = (props) => {
  const {
    name,
    goalAggregateFunction,
    description,
    goalRules,
    isCustomFormula,
    formula,
    loading,
    isAdvancedRuleset,
    groupBy,
  } = props;

  const templateDetails = {
    GoalRules: goalRules,
    GoalAggregateFunction: goalAggregateFunction,
  };

  if (loading) return <LoadingTemplateDetails />;

  return (
    <div className="template-container w-100 position-relative">
      <span>
        {name && (
          <>
            <span className="fs-16 fc-black semi-bold mb-10 long-text-break">
              {name}
            </span>
            {isAdvancedRuleset && (
              <span className="fs-16 fc-grey1 ml-1">
                ({t("RULEBOOK.LABELS.ADVANCED_RULESET")})
              </span>
            )}
          </>
        )}
      </span>
      {description && (
        <p className="fs-13 fc-black mb-10 long-text-break">{description}</p>
      )}

      <p className="fs-12 center flex-column align-items-start">
        {groupBy && (
          <span className="center">
            <SvgGroupBy className="mr-1" />
            {t("COMMON.LABELS.GROUP_BY")}:
            <span className="fs-14 ml-1 semi-bold">
              {groupBy.split(",").join(" & ")}
            </span>
          </span>
        )}
        {isCustomFormula ? (
          <div className="d-flex align-item-start">
            <div className="center h-38">
              <div>
                <SvgCustomFormula
                  width={12}
                  height={12}
                  color={COLORS.GREY_2}
                />
              </div>
              <div className="ml-2 mr-1 min-w-max">
                {t("COMMON.MESSAGES.CUSTOM_FORMULA")}
                {goalRules.length >= 1 ? ":" : ""}
              </div>
            </div>
            <div>
              <DisplayFormula
                formula={formatFormulaToTokens(
                  formula,
                  goalRules,
                  isCustomFormula
                )}
              />
            </div>
          </div>
        ) : (
          !isAdvancedRuleset && (
            <div className="center">
              <div className="center">
                <SvgStandardFormula
                  width={12}
                  height={12}
                  color={COLORS.GREY_2}
                />
                <span className="ml-2 mr-1">
                  {t("COMMON.MESSAGES.STANDART_OUTPUT")}
                  {goalRules.length >= 1 ? ":" : ""}
                </span>
              </div>
              {goalRules.length >= 1 && (
                <DisplayFormula
                  formula={formatFormulaToTokens(
                    formula,
                    goalRules,
                    isCustomFormula
                  )}
                />
              )}
            </div>
          )
        )}
      </p>

      {!isAdvancedRuleset && <RulesGrid templateDetails={templateDetails} />}
      {isAdvancedRuleset && (
        <div className="flex-column-center w-100 mt-5">
          <NoResults />
          <div className="fs-14 fc-grey mt-2 text-center">
            {t("RULEBOOK.MESSAGES.HIDING_RULESET_DETAILS")}
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateDetailContent;
