import { lazy } from "react";
import { MobileDashboardPage as MobilePage } from "src/components";
const WebPage = lazy(() =>
  import(/* webpackChunkName: 'incentives.web.dashboardPage' */ "./web")
);

export const DashboardPage = {
  web: WebPage,
  mobile: MobilePage,
  meta: {
    title: "Dashboard",
  },
};
