import { gql, useQuery } from "src/api/client";

const GET_ALL_USERS_QUERY = gql`
  query getAllUsers(
    $userAttributesNames: [String]!
    $query: JSON
    $Limit: Float
    $After: String
    $RecurrenceId: ID
  ) {
    getAllUsers(
      userAttributesNames: $userAttributesNames
      query: $query
      Limit: $Limit
      After: $After
      RecurrenceId: $RecurrenceId
    ) {
      ... on AllUsersResponse {
        success
        message
        response {
          Users
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            __typename
          }
          __typename
        }
      }
      __typename
    }
  }
`;

export const useGetAllUsers = (queryOptions) => {
  return useQuery(GET_ALL_USERS_QUERY, queryOptions);
};
