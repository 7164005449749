import { isKeyEventBackspace, isKeyEventEnter } from "src/helpers";
import "./tagInput.scss";

function TagInput({
  inputRef,
  onChange,
  onFocus,
  onEnter,
  onDelete,
  showPlaceholder,
  placeholder,
}) {
  const handleChange = (event) => {
    event.target.style.width = `${3 + event.target.value.length}ch`;
    event.target.style.height = "30px";
    event.target.style.height = `${event.target.scrollHeight}px`;
    onChange && onChange(event);
  };

  const handleKeyDown = (event) => {
    if (isKeyEventEnter(event)) {
      event.preventDefault();
      if (onEnter(event.target.value)) {
        event.target.value = "";
        event.target.style.width = "2ch";
        event.target.style.height = "30px";
      }
    }
    if (isKeyEventBackspace(event) && event.target.value === "") {
      onDelete();
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        className="tag-input border-0"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        style={{
          zIndex: 1,
          backgroundColor: "transparent",
          width: "3ch",
          height: "30px",
        }}
      />
      {showPlaceholder ? placeholder : null}
    </>
  );
}

export default TagInput;
