import { t } from "i18next";
import { useCallback, useEffect, useReducer } from "react";
import { isEmpty } from "src/helpers";
import {
  addSpace,
  allowedOperator,
  createPattern,
  errorConditions,
  invalidFormat,
  isBalanced,
  operatorMisplaced,
  replaceSpecialCharacter,
  specialCharacter,
} from "./utils";

const INITIAL_STATE = {
  conditions: [],
  errorModal: false,
  pattern: "(1)",
  editAllowed: true,
  lastUpdatedPattern: "(1)",
  error: [],
  criteriaIndex: -1,
};

const ACTIONS = {
  SET_CONDITIONS: "SET_CONDITIONS",
  SET_SHOW_ERROR_MODAL: "SET_SHOW_ERROR_MODAL",
  SET_PATTERN: "SET_PATTERN",
  SET_EDIT_ALLOWED: "SET_EDIT_ALLOWED",
  SET_LAST_UPDATED_PATTERN: "SET_LAST_UPDATED_PATTERN",
  SET_ERROR: "SET_ERROR",
  SET_CRITERIA_INDEX: "SET_CRITERIA_INDEX",
};

const conditionPatternReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_CONDITIONS: {
      return {
        ...state,
        conditions: payload.conditions,
      };
    }
    case ACTIONS.SET_SHOW_ERROR_MODAL: {
      return {
        ...state,
        showErrorModal: payload.showErrorModal,
      };
    }
    case ACTIONS.SET_PATTERN: {
      return {
        ...state,
        pattern: payload.pattern,
      };
    }
    case ACTIONS.SET_EDIT_ALLOWED: {
      return {
        ...state,
        editAllowed: payload.editAllowed,
      };
    }
    case ACTIONS.SET_LAST_UPDATED_PATTERN: {
      return {
        ...state,
        lastUpdatedPattern: payload.lastUpdatedPattern,
      };
    }
    case ACTIONS.SET_CRITERIA_INDEX: {
      return {
        ...state,
        criteriaIndex: payload.criteriaIndex,
      };
    }
    case ACTIONS.SET_ERROR: {
      return {
        ...state,
        error: payload.error,
      };
    }
    default:
      return state;
  }
};

export const useConditionPattern = ({
  conditions,
  criteriaIndex,
  setConditionPattern,
  conditionPattern,
}) => {
  const [state, dispatch] = useReducer(conditionPatternReducer, {
    ...INITIAL_STATE,
    conditions,
    criteriaIndex,
    pattern: conditionPattern || "(1)",
    lastUpdatedPattern: conditionPattern || "(1)",
  });

  const setError = (error) => {
    dispatch({ type: ACTIONS.SET_ERROR, payload: { error } });
  };

  const setconditions = (conditions) => {
    dispatch({
      type: ACTIONS.SET_CONDITIONS,
      payload: { conditions },
    });
  };

  const setCriteriaIndex = (criteriaIndex) => {
    dispatch({
      type: ACTIONS.SET_CRITERIA_INDEX,
      payload: { criteriaIndex },
    });
  };

  const setEditAllowed = (editAllowed) => {
    dispatch({ type: ACTIONS.SET_EDIT_ALLOWED, payload: { editAllowed } });
  };

  const setPattern = useCallback((pattern) => {
    dispatch({ type: ACTIONS.SET_PATTERN, payload: { pattern } });
  }, []);

  const setLastUpdatedPattern = (lastUpdatedPattern) => {
    dispatch({
      type: ACTIONS.SET_LAST_UPDATED_PATTERN,
      payload: { lastUpdatedPattern },
    });
  };

  const setShowErrorModal = (showErrorModal) => {
    dispatch({
      type: ACTIONS.SET_SHOW_ERROR_MODAL,
      payload: { showErrorModal },
    });
  };

  useEffect(() => {
    if (
      conditions?.length !== state?.conditions?.length &&
      conditions?.length > 0 &&
      state?.criteriaIndex === criteriaIndex
    ) {
      setPattern(createPattern(conditions));
      setLastUpdatedPattern(createPattern(conditions));
      setconditions(conditions);
      if (state?.editAllowed) {
        setConditionPattern(createPattern(conditions));
      }
    }
  }, [
    conditions,
    criteriaIndex,
    setConditionPattern,
    setPattern,
    state?.conditions,
    state?.criteriaIndex,
    state?.editAllowed,
  ]);

  useEffect(() => {
    if (state?.criteriaIndex !== criteriaIndex) {
      setCriteriaIndex(criteriaIndex);
      setPattern(conditionPattern);
      setconditions(conditions);
    }
  }, [
    conditionPattern,
    criteriaIndex,
    setPattern,
    state?.criteriaIndex,
    conditions,
  ]);

  const handleInputChange = (conditionPattern) => {
    const updatedConditionPattern = replaceSpecialCharacter(conditionPattern);
    setPattern(updatedConditionPattern);
  };

  const handleOnInputCancel = () => {
    setEditAllowed(true);
    setPattern(state.lastUpdatedPattern);
  };

  const handleOnUpdatePattern = () => {
    const e = state?.pattern;
    const error = [];
    if (isEmpty(e.trim())) {
      error.push(t("RULEBOOK.ERRORS.ERROR_1"));
    } else {
      const errConditions = errorConditions(e, state?.conditions);

      if (errConditions.missing) {
        error.push(t("RULEBOOK.ERRORS.ERROR_2"));
      }
      if (errConditions.duplicate) {
        error.push(t("RULEBOOK.ERRORS.ERROR_3"));
      }
      if (errConditions.invalid) {
        error.push(t("RULEBOOK.ERRORS.ERROR_4"));
      }
      if (!isBalanced(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_5"));
      }
      if (specialCharacter(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_6"));
      }
      if (allowedOperator(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_7"));
      }
      if (operatorMisplaced(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_8"));
      }
      if (invalidFormat(e)) {
        error.push(t("RULEBOOK.ERRORS.ERROR_9"));
      }
    }
    setError(error);

    if (error.length > 0) {
      setShowErrorModal(true);
      return;
    }
    const modifiedPattern = addSpace(state?.pattern)
      .trim()
      .toUpperCase()
      .replace(/\s{2,}/g, " ");
    setPattern(modifiedPattern);
    setConditionPattern(modifiedPattern);
    setEditAllowed(!state?.editAllowed);
    setLastUpdatedPattern(modifiedPattern);
  };

  const handleEdit = () => {
    setEditAllowed(!state.editAllowed);
  };

  return {
    state,
    actions: {
      handleInputChange,
      handleOnInputCancel,
      handleOnUpdatePattern,
      handleEdit,
      setShowErrorModal,
    },
  };
};
