import React from "react";

const SvgAvatar47 = (props) => {
  const { backgroundColor, height, width, ...rest } = props;
  return (
    <svg
      id="yf68__Layer_2"
      width={width}
      height={height}
      viewBox="0 0 162.8 162.81"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>
          {
            "\n            .yf68__cls-1{fill:none}.yf68__cls-2{fill:#fff}.yf68__cls-3{fill:var(--avatar-dark)}\n        "
          }
        </style>
      </defs>
      <g id="yf68__Layer_1-2">
        <path
          d="M162.8 81.4c0 21.5-8.34 41.04-21.98 55.6-.35.39-.71.76-1.08 1.14-.01.02-.03.04-.05.06-.36.36-.72.73-1.08 1.09l-1.02.99c-12.97 12.39-30.05 20.55-48.99 22.19-1.23.12-2.47.2-3.72.25-.66.03-1.33.05-2 .06-.49.02-.99.02-1.48.02s-.99 0-1.48-.02c-.44-.01-.88-.02-1.32-.04-1.18-.03-2.34-.1-3.5-.2-18.34-1.39-34.99-8.87-47.91-20.43-.67-.59-1.34-1.21-1.99-1.83-.44-.42-.88-.86-1.32-1.29s-.78-.79-.78-.79c-.02-.02-.02-.03-.05-.05-.55-.57-1.1-1.15-1.63-1.73a79.157 79.157 0 0 1-3.78-4.44c-.89-1.11-1.75-2.25-2.58-3.42C5.58 115.25 0 98.99 0 81.4 0 36.44 36.44 0 81.4 0s81.4 36.44 81.4 81.4Z"
          style={{
            fill: backgroundColor,
          }}
        />
        <path
          d="M139.7 138.21c-14.77 15.17-35.43 24.6-58.29 24.6s-43.52-9.43-58.3-24.6c16.3-12.06 36.47-19.19 58.3-19.19s41.99 7.12 58.29 19.19Z"
          className="yf68__cls-3"
        />
        <rect
          width={66.64}
          height={66.64}
          x={48.09}
          y={38.77}
          className="yf68__cls-3"
          rx={33.32}
          ry={33.32}
          transform="rotate(-90 81.405 72.085)"
        />
        <path
          d="M114.72 65.93H47.39A1.507 1.507 0 0 1 46 63.85l3.75-9.04c.23-.56.78-.92 1.39-.92h60.48c.64 0 1.21.41 1.42 1.01l3.11 9.04c.16.46.08.96-.2 1.36s-.74.63-1.22.63Zm-65.09-3h62.99l-2.08-6.04H52.13l-2.51 6.04Z"
          className="yf68__cls-2"
        />
        <path
          d="M131.1 99.16c-.37 0-.74-.14-1.02-.4l-3.45-3.22a16.85 16.85 0 0 1-5.34-12.69l.02-.91c.06-2.74-.68-5.41-2.15-7.73l-5.7-8.97c-.38-.61-.29-1.4.24-1.89.52-.49 1.32-.55 1.9-.13l10.3 7.37a9.042 9.042 0 0 1 3.78 7.35v8.44c0 2.31 4.07 5.01 6.64 6.16.47.21.8.66.87 1.17.07.52-.14 1.03-.54 1.36l-4.61 3.76c-.28.23-.61.34-.95.34Zm-10.98-29.02 1.57 2.47a16.82 16.82 0 0 1 2.62 9.4l-.02.91c-.09 3.94 1.51 7.74 4.39 10.42l2.49 2.33 1.83-1.49c-2.52-1.47-6.32-4.27-6.32-7.81v-8.44c0-1.94-.94-3.78-2.52-4.9l-4.04-2.89Z"
          className="yf68__cls-2"
        />
        <path
          d="M58.07 113.93c5.3 14.4 12.03 29.03 19.98 43.55 1.03-1.8 2.07-3.63 3.09-5.48-8.07-14.75-14.88-29.41-20.29-43.64l-2.78 5.57ZM82.93 148.74c5.63-10.38 11.09-21.58 15.94-33.33H67c4.52 10.94 9.86 22.1 15.93 33.33Z"
          className="yf68__cls-1"
        />
        <path
          d="m113.85 119.03-7.63-15.27c-.08-.15-.18-.28-.3-.4-.04-.03-.07-.06-.11-.09-.11-.09-.24-.17-.38-.23-.03-.01-.04-.04-.07-.04-.02-.01-.03-.01-.04-.01a1.64 1.64 0 0 0-.5-.09h-4.5s-3.05 1.92-7.76 3.12h10c-.79 2.11-1.6 4.19-2.43 6.26H65.74c-.84-2.1-1.65-4.18-2.42-6.26h7.56c-4.71-1.2-7.77-3.12-7.77-3.12h-2.07c-.17 0-.33.04-.49.09-.01.01-.03 0-.04.01-.03.01-.05.03-.08.04-.13.06-.26.14-.38.23-.03.03-.07.06-.1.09-.12.12-.23.25-.3.41l-7.63 15.26-28.15 18.26c-.07.05-.12.11-.18.16-.04.04-.08.06-.11.1-.12.14-.22.29-.29.46 0 .01-.13.34-.14.35l.72.62c.44.44.88.88 1.32 1.3.65.62 1.32 1.24 1.99 1.83l-.39-2.51 27.3-18.21c.23-.15.41-.35.54-.6l1.52-3.06c5.4 14.22 12.13 28.63 20.06 42.91-.37.64-.75 1.27-1.12 1.9 1.16.1 2.32.17 3.5.2.21-.34.41-.69.61-1.03.1-.12.17-.25.23-.39 1.57-2.7 3.14-5.47 4.7-8.3.08-.09.12-.2.17-.31 7.46-13.53 14.69-28.5 20.71-44.35l2.79 5.57c-6.6 17.91-14.7 34.39-22.93 48.79 1.25-.05 2.49-.13 3.72-.25 7.53-13.36 14.92-28.43 21.11-44.73l1.53 3.05c.12.25.3.45.53.6l26.84 17.9c.36-.36.72-.73 1.08-1.09.02-.02.04-.04.05-.06.37-.38.73-.75 1.08-1.14l-26.97-17.97Zm-35.8 38.45c-7.95-14.52-14.68-29.15-19.98-43.55l2.78-5.57c5.41 14.23 12.22 28.89 20.29 43.64-1.02 1.85-2.06 3.68-3.09 5.48Zm4.88-8.74c-6.07-11.23-11.41-22.39-15.93-33.33h31.87c-4.85 11.75-10.31 22.95-15.94 33.33Z"
          className="yf68__cls-2"
        />
      </g>
    </svg>
  );
};
SvgAvatar47.defaultProps = {
  backgroundColor: "#ff7c00",
  height: 162,
  width: 162,
};
export default SvgAvatar47;
