import { t } from "i18next";
import React from "react";
import Select, { components } from "react-select";
import { Shimmer, Truncate } from "src/components/common";
import { COLORS } from "src/constants";
import { SvgArrow } from "src/icons";
const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    fontSize: "12px",
    border: "none",
    borderRadius: "6px",
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 2px 4px rgba(38, 65, 106, 0.02)",
  }),
  menu: (provided) => ({
    ...provided,
    borderTop: "1px solid #309afc",
    borderRadius: "0px !important",
    marginTop: "-1px !important",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "0px",
    backgroundColor: COLORS.WHITE,
    height: "40px",
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
  singleValue: (provided) => ({
    ...provided,
    height: "20px",
    color: "#309afc",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? "#309AFC" : "",
    backgroundColor: COLORS.WHITE,
  }),
};
const ValueContainer = ({ children, ...props }) => {
  const { getValue } = props;

  const values = getValue();
  let valueLabel = "";

  if (values.length > 0)
    valueLabel += props.selectProps.getOptionLabel(values[0]);

  const childrenToRender = React.Children.toArray(children).filter(
    (child) =>
      ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
  );
  return (
    <components.ValueContainer {...props}>
      <div className="center">
        <span className="semi-bold fc-grey">
          {t("RULEBOOK.LABELS.GOAL_RULE")}:
        </span>
        <div className="d-inline-flex align-items-center">
          {childrenToRender}
          {!props.selectProps.inputValue && valueLabel ? (
            <div className="fc-blue fs-14 semi-bold ml-2">
              <Truncate text={valueLabel} width="250px" />
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </components.ValueContainer>
  );
};
const DropdownIndicator = (props) => {
  return (
    <div className="d-flex  mr-2">
      <SvgArrow color={COLORS.PRIMARY_BLUE} />
    </div>
  );
};

const defaultValue = {
  RuleName: "All",
  RuleId: "",
};

const MobileRulesDropDown = (props) => {
  const { rules, setSelectedRule, loading, selectedRule } = props;

  const rulesOptions = [defaultValue, ...rules];

  return rulesOptions.length > 2 ? (
    <div className="w-100">
      <Select
        classNamePrefix="drop-select"
        className="manager-dropdown mr-2 "
        options={rulesOptions}
        components={{ ValueContainer, DropdownIndicator }}
        value={selectedRule}
        styles={customStyles}
        onChange={setSelectedRule}
        getOptionLabel={(option) => option.RuleName}
      />
    </div>
  ) : (
    <div className="d-flex align-self-center fs-13 ">
      <span className="fc-grey1 flex-start semi-bold mb-1">
        {t("RULEBOOK.LABELS.GOAL_RULE")}:
      </span>

      {loading ? (
        <Shimmer width="150px" height="20px" className="ml-2" />
      ) : (
        rulesOptions[1] && (
          <span className="semi-bold fs-14 ml-4 w-100">
            <Truncate text={rulesOptions[1]?.RuleName} width="180px" />
          </span>
        )
      )}
    </div>
  );
};
export default MobileRulesDropDown;
