import React from "react";
import { COLORS } from "src/constants/generalConstants";

const SvgTick = (props) => {
  const { backgroundColor, color, height, width, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g data-name="Group 3569">
        <circle
          cx={8}
          cy={8}
          r={8}
          fill={backgroundColor}
          data-name="Ellipse 27"
        />
      </g>
      <g data-name="Group 3570">
        <path
          fill={color}
          d="M6.481 11.761 2.85 8.13a.119.119 0 0 1 0-.172l1.046-1.046a.119.119 0 0 1 .172 0l2.5 2.5 4.7-4.7a.119.119 0 0 1 .172 0l1.046 1.046a.119.119 0 0 1 0 .172l-5.833 5.831a.119.119 0 0 1-.172 0Z"
          data-name="Path 580"
        />
      </g>
    </svg>
  );
};

SvgTick.defaultProps = {
  backgroundColor: COLORS.PRIMARY_BLUE,
  color: COLORS.WHITE,
  height: 16,
  width: 16,
};
export default SvgTick;
